import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ReisekostenabrechnungenServiceModule } from '..';
import { ReisekostenabrechnungenListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungenServiceModule
  ],
  declarations: [ReisekostenabrechnungenListComponent],
  exports: [ReisekostenabrechnungenListComponent],

})
export class ReisekostenabrechnungenListComponentModule { }
