import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { FinanzenCommandsModule } from './commands';
import { FinanzenComponentsModule } from './components';
import { FinanzenPage, FinanzenPageResolver, ReisekostenabrechnungPage, ReisekostenabrechnungPageResolver } from './pages';
import { FinanzenQuerysModule, RechnungsVorlagenService, ReisekostenabrechnungenService, ReisekostenabrechnungService } from './querys';
import { FinanzenSettingsModule, ReisekostenabrechnungStatiPage, ReisekostenabrechnungStatiPageResolver, ReisekostenabrechnungStatiService, ReisekostenabrechnungStatiServiceModule } from './settings';

const routes: Routes = [
  {
    path: 'finanzen',
    children: [
      {
        path: 'finanzen',
        component: FinanzenPage,
        resolve: {
          querys: FinanzenPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['finanzen'],
            roles: ['GET_REISEKOSTENABRECHNUNGEN'],
          },
        },
      },
      {
        path: 'reisekostenabrechnung',
        component: ReisekostenabrechnungPage,
        resolve: {
          querys: ReisekostenabrechnungPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['finanzen'],
            roles: ['GET_PROJEKT', 'GET_REISEKOSTENABRECHNUNG', 'GET_TOUR', 'DELETE_REISEKOSTENABRECHNUNG', 'POST_REISEKOSTENABRECHNUNG'],
          },
        },
      },
    ],
  },
  {
    path: 'finanzen',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'reisekostenabrechnung-stati',
            component: ReisekostenabrechnungStatiPage,
            resolve: {
              querys: ReisekostenabrechnungStatiPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_REISEKOSTENABRECHNUNG_STATI'],
                  write: ['POST_REISEKOSTENABRECHNUNG_STATUS', 'DELETE_REISEKOSTENABRECHNUNG_STATUS'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [FinanzenPage, ReisekostenabrechnungPage, ReisekostenabrechnungStatiPage],
  imports: [
    LibsCommonAngularModule,
    FinanzenSettingsModule,
    FinanzenComponentsModule,
    FinanzenCommandsModule,
    FinanzenQuerysModule,
    ReisekostenabrechnungStatiServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [FinanzenSettingsModule, FinanzenComponentsModule, FinanzenCommandsModule, FinanzenQuerysModule, ReisekostenabrechnungStatiServiceModule],
})
export class FinanzenModule {
  static forRoot(): ModuleWithProviders<FinanzenModule> {
    return {
      ngModule: FinanzenModule,
      providers: [RechnungsVorlagenService, ReisekostenabrechnungService, ReisekostenabrechnungenService, ReisekostenabrechnungStatiService],
    };
  }
}
