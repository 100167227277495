import { NurErforderlichPipe } from './dokumenten-anforderungen/dokumenten-anforderungen.component';
import { NgModule } from '@angular/core';
import {
  DateienComponentModule,
  DokumentenAnforderungenComponentModule
 } from '.';

@NgModule({
  imports: [
    DateienComponentModule,
    DokumentenAnforderungenComponentModule
  ],
  exports: [
    DateienComponentModule,
    DokumentenAnforderungenComponentModule
  ]
})
export class DokumenteComponentsModule { }
