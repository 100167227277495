import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { BerichtsVorlagenSorterConfig } from 'fa-kt.types';
import { BerichtsVorlagenService } from '../service';

@Component({
  selector: 'fa-kt-berichts-vorlagen-sorter',
  templateUrl: './berichts-vorlagen-sorter.component.html',
  styleUrls: ['./berichts-vorlagen-sorter.component.css'],
})
export class BerichtsVorlagenSorterComponent {
  public BerichtsVorlagenSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public berichtsVorlagen: BerichtsVorlagenService,
  ) {
    this.BerichtsVorlagenSorterConfig = BerichtsVorlagenSorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    /*    update >> */
  }
}
