import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ReisekostenabrechnungServiceModule } from '..';
import { ReisekostenabrechnungSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungServiceModule
  ],
  declarations: [ReisekostenabrechnungSorterComponent],
  exports: [ReisekostenabrechnungSorterComponent],

})
export class ReisekostenabrechnungSorterComponentModule { }
