import { DecimalPipe } from '@angular/common';
import { Component, Input, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, Validators } from '@angular/forms';
import { Params } from '@angular/router';

import Docxtemplater from 'docxtemplater';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import PizZip from 'pizzip';

import { BasePostCommandComponent, ISitemapPage } from 'pbc.angular';
import { toInitials } from 'pbc.functions';
import { ISelection } from 'pbc.types';

import { IPostReisekostenabrechnungRequest, IPostReisekostenabrechnungResponse, IReisekostenabrechnung, ISonstigePosition } from 'fa-kt.types';
import { round } from 'lodash';
import { BehaviorSubject, combineLatest, debounceTime } from 'rxjs';
import { TourenSelectService } from '../../../../besichtigungen';
import { MitarbeiterService } from '../../../../personen';
import { PostReisekostenabrechnungCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-reisekostenabrechnung',
  templateUrl: './post-reisekostenabrechnung.component.html',
  styleUrls: ['./post-reisekostenabrechnung.component.css'],
})
export class PostReisekostenabrechnungCommandComponent extends BasePostCommandComponent<IPostReisekostenabrechnungRequest, IPostReisekostenabrechnungResponse> implements OnInit, OnDestroy {
  description = {
    context: 'FINANZEN',
    command: 'POST_REISEKOSTENABRECHNUNG',
  };
  contentKey: keyof IPostReisekostenabrechnungRequest = 'reisekostenabrechnung';

  public _tour: ISitemapPage;
  selection$ = new BehaviorSubject<ISelection[]>([]);

  @Input() set herunterladen(date: Date) {
    if (date) this.download();
  }

  constructor(
    public postReisekostenabrechnung: PostReisekostenabrechnungCommandService,
    public mitarbeiter: MitarbeiterService,
    public touren: TourenSelectService,
  ) {
    super();
    this._tour = this.sitemap['BESICHTIGUNGEN'].Pages['TOUR'];
    this.form = this.fb.group({
      reisekostenabrechnung: this.fb.group({
        _createdAt: [null, []],
        id: [null, []],
        bezeichnung: [null, [Validators.required]],
        mitarbeiter: [null, [Validators.required]],
        touren: [[], []],
        isVeranstaltung: [null, []],
        veranstaltung: [null, []],
        von: [null, []],
        bis: [null, []],
        reisekostenabrechnungStatus: [null, [Validators.required]],
        tagesgeldAcht: [null, []],
        tagesgeldVierundzwanzig: [null, []],
        anUndAbreisetag: [null, []],
        fruehstuecke: [null, []],
        mittagessen: [null, []],
        abendessen: [null, []],
        uebernachtungspauschale: [null, []],
        normaleKm: [null, [Validators.required]],
        mitnahmeentschaedigung: [null, []],
        mitnahme: [null, []],
        sonstigePositionen: this.fb.array([]),
        sonstigeSumme: [null, []],
        summe: [null, []],
        antrag: [null, []],
      }),
    });
    this.patch();
  }

  async prepare(): Promise<void> {
    await this.postReisekostenabrechnung.prepare();
  }

  async request(payload: IPostReisekostenabrechnungRequest): Promise<IPostReisekostenabrechnungResponse | undefined> {
    return this.postReisekostenabrechnung.request(payload);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.reisekostenabrechnungSonstigePositionen?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (sonstigePositionen) => {
      this.reisekostenabrechnungSonstigeSumme?.patchValue((sonstigePositionen as ISonstigePosition[]).reduce((sum, { summe }) => sum + summe, 0));
    });
    this.reisekostenabrechnungVon?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (von) => {
      if (!von) return;
      if (this.form.get('reisekostenabrechnung.bezeichnung')?.value) return;
      this.form.get('reisekostenabrechnung.bezeichnung')?.patchValue(`VA-${(new Date(von) || new Date()).toLocaleDateString()}`);
    });
    combineLatest([this.reisekostenabrechnungTouren?.valueChanges, this.touren.selection$])
      .pipe(debounceTime(10), takeUntilDestroyed(this.destroyedRef))
      .subscribe(([touren, shapes]) => {
        const selected = shapes.filter(({ value }) => (touren || []).includes(value));
        if (!this.form.get('reisekostenabrechnung.isVeranstaltung')?.value && shapes?.length && touren?.length === selected?.length) {
          let normaleKm = 0;
          selected.forEach(({ km }) => (normaleKm = normaleKm + (km || 0)));
          this.reisekostenabrechnungNormaleKm?.patchValue(round(normaleKm, 2));
        }
        if (selected[0] && !this.form.get('reisekostenabrechnung.bezeichnung')?.value) this.form.get('reisekostenabrechnung.bezeichnung')?.patchValue(`RK-${selected[0].date}`);
      });
    this.reisekostenabrechnungMitarbeiter.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (mitarbeiter) => {
      if (!mitarbeiter) this.touren.selection$.next([]);
      else await this.touren.request({ mitarbeiter });
    });
    combineLatest([this.touren.selection$, this.reisekostenabrechnungTouren.valueChanges])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([selection, touren]) => {
        if (!selection?.length) return;
        touren = touren || [];
        this.selection$.next(selection.filter(({ value, abgerechnet, am }) => touren.includes(value) || (!abgerechnet && new Date(am).getFullYear() > 2023)));
      });
    this.reisekostenabrechnungMitarbeiter?.patchValue(this.reisekostenabrechnungMitarbeiter?.value);
    this.reisekostenabrechnungTouren?.patchValue(this.reisekostenabrechnungTouren.value);
  }

  getTour(id: string): ISelection | undefined {
    return this.touren.selection$.getValue()?.find(({ value }) => value === id);
  }

  public async navigateToTour(queryParams: Params = {}) {
    await this.router.navigate(this._tour.url, { queryParams });
  }

  get sonstigePositionen(): FormArray {
    return this.form.get('reisekostenabrechnung.sonstigePositionen') as FormArray;
  }

  addSonstigePosition() {
    this.sonstigePositionen.push(
      this.fb.group({
        beschreibung: ['', Validators.required],
        summe: [0, [Validators.required]],
      }),
    );
  }

  removeSonstigePosition(index: number) {
    this.sonstigePositionen.removeAt(index);
  }

  override patch(value?: IPostReisekostenabrechnungRequest) {
    super.patch(value);
    while (this.sonstigePositionen.length !== 0) {
      this.sonstigePositionen.removeAt(0);
    }
    let sonstigePositionen: ISonstigePosition[] = [];
    if (typeof value?.reisekostenabrechnung.sonstigePositionen === 'string')
      sonstigePositionen.push({
        beschreibung: value.reisekostenabrechnung.sonstigePositionen as string,
        summe: value.reisekostenabrechnung.sonstigeSumme || 0,
      });
    else sonstigePositionen = value?.reisekostenabrechnung.sonstigePositionen || ([] as ISonstigePosition[]);
    sonstigePositionen.forEach((sonstigePosition: ISonstigePosition) => {
      this.sonstigePositionen.push(
        this.fb.group({
          beschreibung: [sonstigePosition.beschreibung, Validators.required],
          summe: [sonstigePosition.summe, [Validators.required]],
        }),
      );
    });
  }

  get reisekostenabrechnungId() {
    return this.form.get('reisekostenabrechnung.id');
  }
  get reisekostenabrechnungMitarbeiter() {
    return this.form.get('reisekostenabrechnung.mitarbeiter');
  }
  get reisekostenabrechnungTouren() {
    return this.form.get('reisekostenabrechnung.touren');
  }
  get reisekostenabrechnungIsVeranstaltung() {
    return this.form.get('reisekostenabrechnung.isVeranstaltung');
  }
  get reisekostenabrechnungVon() {
    return this.form.get('reisekostenabrechnung.von');
  }
  get reisekostenabrechnungReisekostenabrechnungStatus() {
    return this.form.get('reisekostenabrechnung.reisekostenabrechnungStatus');
  }
  get reisekostenabrechnungTagesgeldAcht() {
    return this.form.get('reisekostenabrechnung.tagesgeldAcht');
  }
  get reisekostenabrechnungTagesgeldVierundzwanzig() {
    return this.form.get('reisekostenabrechnung.tagesgeldVierundzwanzig');
  }
  get reisekostenabrechnungAnUndAbreisetag() {
    return this.form.get('reisekostenabrechnung.anUndAbreisetag');
  }
  get reisekostenabrechnungFruehstuecke() {
    return this.form.get('reisekostenabrechnung.fruehstuecke');
  }
  get reisekostenabrechnungMittagessen() {
    return this.form.get('reisekostenabrechnung.mittagessen');
  }
  get reisekostenabrechnungAbendessen() {
    return this.form.get('reisekostenabrechnung.abendessen');
  }
  get reisekostenabrechnungUebernachtungspauschale() {
    return this.form.get('reisekostenabrechnung.uebernachtungspauschale');
  }
  get reisekostenabrechnungNormaleKm() {
    return this.form.get('reisekostenabrechnung.normaleKm');
  }
  get reisekostenabrechnungMitnahmeentschaedigung() {
    return this.form.get('reisekostenabrechnung.mitnahmeentschaedigung');
  }
  get reisekostenabrechnungSonstigePositionen() {
    return this.form.get('reisekostenabrechnung.sonstigePositionen');
  }
  get reisekostenabrechnungSonstigeSumme() {
    return this.form.get('reisekostenabrechnung.sonstigeSumme');
  }
  get reisekostenabrechnungSumme() {
    return this.form.get('reisekostenabrechnung.summe');
  }
  get reisekostenabrechnungAntrag() {
    return this.form.get('reisekostenabrechnung,antrag');
  }

  async download() {
    this.$loading.next(true);
    const blob = await this.files.get('fa-kt-apps/Reisekostenabrechnung.docx');
    const reisekostenabrechnung: IReisekostenabrechnung = this.form.getRawValue().reisekostenabrechnung;

    const zip = new PizZip((await this.files.readFileAsync(blob as File)) as unknown as any);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      delimiters: { start: '{', end: '}' },
    });

    const isVeranstaltung = reisekostenabrechnung.isVeranstaltung;

    const tagesgeldAcht = reisekostenabrechnung.tagesgeldAcht || 0;
    const tagesgeldAchtSumme = tagesgeldAcht * 14;

    const tagesgeldVierundzwanzig = reisekostenabrechnung.tagesgeldVierundzwanzig || 0;
    const tagesgeldVierundzwanzigSumme = tagesgeldVierundzwanzig * 28;

    const anUndAbreisetag = reisekostenabrechnung.anUndAbreisetag || 0;
    const anUndAbreisetagSumme = anUndAbreisetag * 14;

    const fruehstuecke = reisekostenabrechnung.fruehstuecke || 0;
    const fruehstueckeSumme = fruehstuecke * 5.6;

    const mittagessen = reisekostenabrechnung.mittagessen || 0;
    const mittagessenSumme = mittagessen * 11.2;

    const abendessen = reisekostenabrechnung.abendessen || 0;
    const abendessenSumme = abendessen * 11.2;

    const uebernachtungspauschale = reisekostenabrechnung.uebernachtungspauschale || 0;
    const uebernachtungspauschaleSumme = uebernachtungspauschale * 20;

    const normaleKm = reisekostenabrechnung.normaleKm || 0;
    const normaleKmSumme = normaleKm * 0.3;

    const mitnahmeentschaedigung = reisekostenabrechnung.mitnahme ? reisekostenabrechnung.normaleKm || 0 : reisekostenabrechnung.mitnahmeentschaedigung || 0;
    const mitnahmeentschaedigungSumme = mitnahmeentschaedigung * 0.02;

    const sonstigeSumme = reisekostenabrechnung.sonstigeSumme || 0;

    const number = new DecimalPipe('de-DE');

    const sonstigePositionen = (reisekostenabrechnung.sonstigePositionen as ISonstigePosition[])
      ?.map((position) => {
        return `-  ${position.beschreibung} (${number.transform(position.summe, '0.2-2', 'de')} €)`;
      })
      .join('\n');

    const betrag =
      tagesgeldAchtSumme +
      tagesgeldVierundzwanzigSumme +
      anUndAbreisetagSumme -
      fruehstueckeSumme -
      mittagessenSumme -
      abendessenSumme +
      uebernachtungspauschaleSumme +
      normaleKmSumme +
      mitnahmeentschaedigungSumme +
      sonstigeSumme;

    let tourenLink = '';
    const touren = this.touren.selection$
      .getValue()
      .filter(({ value }) => reisekostenabrechnung.touren?.includes(value!))
      .map((shape) => {
        let tour = shape.besichtigungen.join('\n');
        tour = 'Am ' + shape.date! + ' ' + shape.time! + ' von ' + shape.start + ': ' + number.transform(shape.km, '0.2-2', 'de') + 'km\n\n' + tour + '\n'; //
        tourenLink = tourenLink + 'https://apps.fa-kt-valuation.de/besichtigungen/tour?id=' + shape.value + '\n';
        return tour;
      })
      .join('\n\n');

    const veranstaltungsZeitangabe =
      reisekostenabrechnung.von && reisekostenabrechnung.bis ? ` vom ${new Date(reisekostenabrechnung.von).toLocaleString()} bis ${new Date(reisekostenabrechnung.bis).toLocaleString()}` : '';

    const mitarbeiter = this.mitarbeiter.response$.getValue()?.mitarbeiter.find((m) => m.mitarbeiter.id === reisekostenabrechnung.mitarbeiter)?.mitarbeiter;
    const objekt = {
      mitarbeiterAnzeigename: mitarbeiter?.anzeigename,
      mitarbeiterEmail: mitarbeiter?.email,
      mitarbeiterStrasse: (mitarbeiter?.addresse?.strasse || '') + ' ' + (mitarbeiter?.addresse?.extra || ''),
      mitarbeiterAnschrift: (mitarbeiter?.addresse?.plz || '') + ' ' + (mitarbeiter?.addresse?.gemeinde_stadt || ''),
      datum: moment(new Date()).format('DD.MM.yyyy HH:mm'),
      id: reisekostenabrechnung.bezeichnung,
      description: isVeranstaltung
        ? 'Diese Reisekostenabrechnung betrifft die folgende Veranstaltung' + veranstaltungsZeitangabe
        : 'Diese Reisekostenabrechnung betrifft die folgenden Touren & Besichtigungen',
      content: isVeranstaltung ? reisekostenabrechnung.veranstaltung : touren,
      tagesgeldAcht,
      tagesgeldAchtSumme: number.transform(tagesgeldAchtSumme, '0.2-2', 'de'),
      tagesgeldVierundzwanzig,
      tagesgeldVierundzwanzigSumme: number.transform(tagesgeldVierundzwanzigSumme, '0.2-2', 'de'),
      anUndAbreisetag,
      anUndAbreisetagSumme: number.transform(anUndAbreisetagSumme, '0.2-2', 'de'),
      fruehstuecke,
      fruehstueckeSumme: number.transform(fruehstueckeSumme, '0.2-2', 'de'),
      mittagessen,
      mittagessenSumme: number.transform(mittagessenSumme, '0.2-2', 'de'),
      abendessen,
      abendessenSumme: number.transform(abendessenSumme, '0.2-2', 'de'),
      uebernachtungspauschale: uebernachtungspauschale,
      uebernachtungspauschaleSumme: number.transform(uebernachtungspauschaleSumme, '0.2-2', 'de'),
      normaleKm: number.transform(normaleKm, '0.2-2', 'de'),
      normaleKmSumme: number.transform(normaleKmSumme, '0.2-2', 'de'),
      mitnahmeentschaedigung: number.transform(mitnahmeentschaedigung, '0.2-2', 'de'),
      mitnahmeentschaedigungSumme: number.transform(mitnahmeentschaedigungSumme, '0.2-2', 'de'),
      sonstigePositionen: sonstigePositionen,
      sonstigeSumme: number.transform(sonstigeSumme, '0.2-2', 'de'),
      betrag: number.transform(betrag, '0.2-2', 'de'),
      tourenLink: tourenLink || '',
    };
    doc.render(objekt);
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    const kuerzel = toInitials(mitarbeiter?.anzeigename);
    const rechnungsdatum = this.yymmdd(new Date(reisekostenabrechnung._createdAt || new Date()));
    this.files.downloadBlob(out, `${rechnungsdatum}_${kuerzel} ${reisekostenabrechnung.bezeichnung}.docx`);
    this.$loading.next(false);
  }

  yymmdd(date: Date): string {
    return date.getFullYear().toString().substring(2) + (date.getMonth() + 1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0');
  }

  get isDev() {
    return isDevMode();
  }
}
