import { Injectable } from '@angular/core';

import { IDeleteRahmenAnpassungRequest, IDeleteRahmenAnpassungResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteRahmenAnpassungCommandService extends DeleteCommandService<IDeleteRahmenAnpassungRequest, IDeleteRahmenAnpassungResponse> {
  readonly route = 'zeiten/rahmen-anpassung';
}
