import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostRolleCommandServiceModule } from '..';
import { PostRolleCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostRolleCommandServiceModule
  ],
  declarations: [PostRolleCommandComponent],
  exports: [PostRolleCommandComponent],

})
export class PostRolleCommandComponentModule { }
