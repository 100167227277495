import { NgModule } from '@angular/core';

import {
  PostAbteilungCommandServiceModule,
  PostAbteilungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostAbteilungCommandServiceModule,
    PostAbteilungCommandComponentModule
  ],
  exports: [
    PostAbteilungCommandServiceModule,
    PostAbteilungCommandComponentModule
  ]
})
export class PostAbteilungCommandModule { }
