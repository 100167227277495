<!---
  <div formGroupName="feld">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="schluessel">{{ command.Inputs.SCHLUESSEL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SchluesselErrorTip">
        <ng-template #SchluesselErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SCHLUESSEL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="schluessel" id="schluessel" [placeholder]="command.Inputs.SCHLUESSEL.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="feldKategorie">{{ command.Inputs.FELD_KATEGORIE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FeldKategorieErrorTip">
        <ng-template #FeldKategorieErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FELD_KATEGORIE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="feldKategorie" id="feldKategorie" [shapes]="postFeld.feldFeldKategorie | async" [placeholder]="command.Inputs.FELD_KATEGORIE.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="feldUnterkategorie">{{ command.Inputs.FELD_UNTERKATEGORIE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FeldUnterkategorieErrorTip">
        <ng-template #FeldUnterkategorieErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FELD_UNTERKATEGORIE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="feldUnterkategorie" id="feldUnterkategorie" [shapes]="postFeld.feldFeldUnterkategorie | async" [placeholder]="command.Inputs.FELD_UNTERKATEGORIE.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="art">{{ command.Inputs.ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ArtErrorTip">
        <ng-template #ArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="art" id="art" [shapes]="postFeld.feldArt | async" [placeholder]="command.Inputs.ART.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="format">{{ command.Inputs.FORMAT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FormatErrorTip">
        <ng-template #FormatErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FORMAT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="format" id="format" [placeholder]="command.Inputs.FORMAT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="voreinstellung">{{ command.Inputs.VOREINSTELLUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VoreinstellungErrorTip">
        <ng-template #VoreinstellungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VOREINSTELLUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="voreinstellung" id="voreinstellung" [shapes]="postFeld.feldVoreinstellung | async" [placeholder]="command.Inputs.VOREINSTELLUNG.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->

    <div formGroupName="feld">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="feldKategorie">{{ command.Inputs.FELD_KATEGORIE.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FeldKategorieErrorTip">
          <ng-template #FeldKategorieErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FELD_KATEGORIE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="feldKategorie"
            id="feldKategorie"
            [shapes]="postFeld.feldFeldKategorie | async"
            [placeholder]="command.Inputs.FELD_KATEGORIE.placeholder"
            cdkFocusInitial
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="feldUnterkategorie">{{ command.Inputs.FELD_UNTERKATEGORIE.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FeldUnterkategorieErrorTip">
          <ng-template #FeldUnterkategorieErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FELD_UNTERKATEGORIE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            [filter]="feldFeldKategorie.value"
            formControlName="feldUnterkategorie"
            id="feldUnterkategorie"
            [shapes]="postFeld.feldFeldUnterkategorie | async"
            [placeholder]="command.Inputs.FELD_UNTERKATEGORIE.placeholder"
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title }}</nz-form-label>
        <nz-form-control [nzSm]="7" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" />
        </nz-form-control>
        <nz-form-control [nzOffset]="1" [nzSm]="4" [nzXs]="24" [nzErrorTip]="SchluesselErrorTip">
          <ng-template #SchluesselErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SCHLUESSEL.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            <ng-container *ngIf="control.hasError('invalidName')">Keine Sonderzeichen erlaubt</ng-container>
          </ng-template>
          <input nz-input formControlName="schluessel" id="schluessel" [placeholder]="command.Inputs.SCHLUESSEL.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="art">{{ command.Inputs.ART.title }}</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="ArtErrorTip">
          <ng-template #ArtErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ART.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="art" id="art" [shapes]="(postFeld.shapes$ | async)?.['feld.art']" [placeholder]="command.Inputs.ART.placeholder" required="true"></pbc-select-value>
        </nz-form-control>
        <ng-container *ngIf="feldArt?.value !== 'zahl' && feldArt?.value !== 'datum'">
          <input class="cdk-visually-hidden" formControlName="format" id="format-hidden" />
        </ng-container>
        <ng-container *ngIf="feldArt?.value === 'zahl' || feldArt?.value === 'datum'">
          <nz-form-label [nzOffset]="1" [nzSm]="2" [nzXs]="24" [nzTooltipTitle]="FormatTooltip" nzFor="format">{{ command.Inputs.FORMAT.title }}</nz-form-label>
          <ng-template #FormatTooltip>
            <ng-container *ngIf="feldArt?.value === 'zahl'">
              <a href="https://angular.io/api/common/DecimalPipe#digitsinfo" target="_blank">Mehr Infos</a>
            </ng-container>
            <ng-container *ngIf="feldArt?.value === 'datum'">
              <a href="https://angular.io/api/common/DatePipe#pre-defined-format-options" target="_blank">Mehr Infos</a>
            </ng-container>
          </ng-template>
          <nz-form-control [nzSm]="4" [nzXs]="24" [nzErrorTip]="FormatErrorTip">
            <ng-template #FormatErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FORMAT.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input formControlName="format" id="format" [placeholder]="command.Inputs.FORMAT?.placeholder" />
          </nz-form-control>
        </ng-container>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="format">Vorgabewert</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VoreinstellungErrorTip">
          <ng-template #VoreinstellungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VOREINSTELLUNG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <ng-container [ngSwitch]="feldArt.value">
            <ng-container *ngSwitchCase="'option'">
              <button
                nz-button
                *ngFor="let option of optionen | order: 'order'"
                [nzType]="feldVoreinstellung.value === option.option ? 'primary' : 'default'"
                type="button"
                nzShape="round"
                (click)="feldVoreinstellung?.patchValue(option.option)"
              >
                {{ option.option }}
              </button>
              <button *ngIf="feldVoreinstellung?.value" nz-button nzType="dashed" nzShape="round" (click)="feldVoreinstellung?.patchValue(undefined)">
                <span nz-icon nzType="close" nzTheme="outline"></span>
              </button>
            </ng-container>
            <ng-container *ngSwitchCase="'optionPlus'">
              <button
                nz-button
                *ngFor="let option of optionen | order: 'order'"
                [nzType]="feldVoreinstellung.value === option.option ? 'primary' : 'default'"
                type="button"
                nzShape="round"
                (click)="feldVoreinstellung?.patchValue(option.option)"
              >
                {{ option.option }}
              </button>
              <button *ngIf="feldVoreinstellung?.value" nz-button nzType="dashed" nzShape="round" (click)="feldVoreinstellung?.patchValue(undefined)">
                <span nz-icon nzType="close" nzTheme="outline"></span>
              </button>
            </ng-container>
            <ng-container *ngSwitchCase="'mehrfachauswahlPlus'">
              <button
                nz-button
                *ngFor="let option of optionen | order: 'order'"
                [nzType]="hasValue(option.option) ? 'primary' : 'dashed'"
                type="button"
                nzShape="round"
                (click)="toggleValues(option.option)"
              >
                {{ option.option }}
              </button>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <pbc-multi-value [mode]="feldArt.value" formControlName="voreinstellung" id="voreinstellung" [placeholder]="'Vorgabewert'"></pbc-multi-value>
            </ng-container>
          </ng-container>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            Feld Speichern
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
