<nz-spin [nzSpinning]="$loading | async">
  <input class="cdk-visually-hidden" id="_kommuniziertVon" />
  <input class="cdk-visually-hidden" id="_kommuniziertBis" />

  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="besichtigung">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="tour" id="tour" />
      <input class="cdk-visually-hidden" formControlName="order" id="order" />
      <input class="cdk-visually-hidden" formControlName="mitarbeiter" id="mitarbeiter" />
      <input class="cdk-visually-hidden" formControlName="rueckfahrt" id="rueckfahrt" />

      <input class="cdk-visually-hidden" formControlName="projekt" id="projekt" />
      <input class="cdk-visually-hidden" formControlName="objekt" id="objekt" />
      <input class="cdk-visually-hidden" formControlName="gutachten" id="gutachten" />

      <input class="cdk-visually-hidden" formControlName="fahrtstrecke" id="fahrtstrecke" />
      <input class="cdk-visually-hidden" formControlName="fahrtzeit" id="fahrtzeit" />
      <input class="cdk-visually-hidden" formControlName="fahrtzeitOhneVerkehr" id="fahrtzeitOhneVerkehr" />

      <input class="cdk-visually-hidden" formControlName="start" id="start" />
      <input class="cdk-visually-hidden" formControlName="ende" id="ende" />
      <input class="cdk-visually-hidden" formControlName="fahrtVon" id="fahrtVon" />
      <input class="cdk-visually-hidden" formControlName="fahrtBis" id="fahrtBis" />
      <input class="cdk-visually-hidden" formControlName="von" id="von" />

      <input class="cdk-visually-hidden" formControlName="abgerechnet" id="abgerechnet" />
      <input class="cdk-visually-hidden" formControlName="route" id="route" />

      <input class="cdk-visually-hidden" formControlName="fahrtstreckeZumStandort" id="fahrtstreckeZumStandort" />
      <input class="cdk-visually-hidden" formControlName="fahrtzeitZumStandort" id="fahrtzeitZumStandort" />

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="besichtigungsStatus">Status</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BesichtigungsStatusErrorTip">
          <ng-template #BesichtigungsStatusErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BESICHTIGUNGS_STATUS.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="besichtigungsStatus"
            id="besichtigungsStatus"
            [shapes]="postBesichtigung.besichtigungenBesichtigungsStatus | async"
            [placeholder]="command.Inputs.BESICHTIGUNGS_STATUS.placeholder"
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>

      <br />

      <nz-form-item *ngIf="form.errors?.hasTimeConflict">
        <nz-form-control [nzOffset]="8" [nzSm]="12">
          <nz-alert nzType="warning" nzDescription="Die Besichtigung hat Zeitkonflikte" nzShowIcon></nz-alert>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control [nzOffset]="4" [nzSm]="1">
          <button (click)="takeFahrtBis()" nz-button nzType="link" type="button" nz-tooltip [nzTooltipTitle]="' + 0 Minuten'">
            <span nz-icon nzType="right" nzTheme="outline"></span>
          </button>
        </nz-form-control>
        <nz-form-control [nzSm]="1">
          <button
            (click)="takeFahrtBis(konstanten.tourenplanung_vorbereitungszeit)"
            nz-button
            nzType="link"
            type="button"
            nz-tooltip
            [nzTooltipTitle]="' + ' + konstanten.tourenplanung_vorbereitungszeit + ' Minuten'"
          >
            <span nz-icon nzType="field-time" nzTheme="outline"></span>
          </button>
        </nz-form-control>
        <nz-form-label [nzSm]="2" nzRequired> Von </nz-form-label>
        <nz-form-control [nzSm]="5" [nzErrorTip]="KommuniziertVonErrorTip">
          <ng-template #KommuniziertVonErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte hinterlegen</ng-container>
            <ng-container *ngIf="control.hasError('hasTimeConflict')">Es gibt ein Problem mit der Planung</ng-container>
          </ng-template>
          <nz-time-picker
            formControlName="kommuniziertVon"
            nzFormat="HH:mm"
            [nzMinuteStep]="15"
            [nzDisabledHours]="disabledHours('fahrtBis')"
            [nzDisabledMinutes]="disabledMinutes('fahrtBis')"
            [nzDisabledSeconds]="disabledSeconds"
            [nzHideDisabledOptions]="true"
            [nzNowText]="' '"
            [nzAllowEmpty]="false"
          ></nz-time-picker>
        </nz-form-control>
        <nz-form-control [nzSm]="1">
          <button (click)="takeKommuniziertVon()" nz-button nzType="link" type="button" nz-tooltip [nzTooltipTitle]="' + ' + minuten + ' Minuten'">
            <span nz-icon nzType="right" nzTheme="outline"></span>
          </button>
        </nz-form-control>
        <nz-form-label [nzSm]="2" nzRequired> Bis </nz-form-label>
        <nz-form-control [nzSm]="4" [nzErrorTip]="KommuniziertBisErrorTip">
          <ng-template #KommuniziertBisErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte hinterlegen</ng-container>
            <ng-container *ngIf="control.hasError('hasTimeConflict')">Es gibt ein Problem mit der Planung</ng-container>
          </ng-template>
          <nz-time-picker
            formControlName="kommuniziertBis"
            nzFormat="HH:mm"
            [nzMinuteStep]="15"
            [nzDisabledHours]="disabledHours('_kommuniziertVon')"
            [nzDisabledMinutes]="disabledMinutes('_kommuniziertVon')"
            [nzDisabledSeconds]="disabledSeconds"
            [nzHideDisabledOptions]="true"
            [nzNowText]="' '"
            [nzAllowEmpty]="false"
          ></nz-time-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzOffset]="10" [nzSm]="6" nzRequired> Abfahrt </nz-form-label>
        <nz-form-control [nzSm]="4" [nzErrorTip]="BisErrorTip">
          <ng-template #BisErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte hinterlegen</ng-container>
            <ng-container *ngIf="control.hasError('hasTimeConflict')">Es gibt ein Problem mit der Planung</ng-container>
          </ng-template>
          <nz-time-picker
            formControlName="bis"
            nzFormat="HH:mm"
            [nzMinuteStep]="15"
            [nzDisabledHours]="disabledHours('_kommuniziertBis')"
            [nzDisabledMinutes]="disabledMinutes('_kommuniziertBis')"
            [nzDisabledSeconds]="disabledSeconds"
            [nzHideDisabledOptions]="true"
            [nzNowText]="' '"
            [nzAllowEmpty]="false"
          ></nz-time-picker>
        </nz-form-control>
        <nz-form-control [nzSm]="1">
          <button (click)="takeKommuniziertBis()" nz-button nzType="link" type="button" nz-tooltip [nzTooltipTitle]="' + 0 Minuten'">
            <span nz-icon nzType="enter" nzTheme="outline"></span>
          </button>
        </nz-form-control>
        <nz-form-control [nzSm]="1">
          <button
            (click)="takeKommuniziertBis(konstanten.tourenplanung_nachbereitungszeit)"
            nz-button
            nzType="link"
            type="button"
            nz-tooltip
            [nzTooltipTitle]="' + ' + konstanten.tourenplanung_nachbereitungszeit + ' Minuten'"
          >
            <span nz-icon nzType="field-time" nzTheme="outline"></span>
          </button>
        </nz-form-control>
      </nz-form-item>

      <br />

      <nz-form-item>
        <nz-form-label [nzOffset]="2" [nzSm]="6"> Wiedervorlage </nz-form-label>
        <nz-form-control [nzSm]="4">
          <nz-date-picker formControlName="wiedervorlage" nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
        <nz-form-label [nzOffset]="2" [nzSm]="2">Aktion</nz-form-label>
        <nz-form-control [nzSm]="4">
          <nz-select formControlName="aktion">
            <nz-option nzLabel="-" [nzValue]="undefined"></nz-option>
            <nz-option nzLabel="Nachhaken" nzValue="Nachhaken"></nz-option>
            <nz-option nzLabel="E-Mail schreiben" nzValue="E-Mail schreiben"></nz-option>
            <nz-option nzLabel="Kontaktieren" nzValue="Kontaktieren"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzOffset]="2" [nzSm]="6">Chronik</nz-form-label>
        <nz-form-control [nzSm]="12">
          <textarea formControlName="kommentar" nz-input placeholder="Kommentar" rows="4"></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzOffset]="2" [nzSm]="6">App eingekauft</nz-form-label>
        <nz-form-control [nzSm]="12">
          <label formControlName="app" nz-checkbox></label>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          Speichern
          <ng-template #SubmitTooltip> Besichtigung & Tour speichern </ng-template>
        </button>
        <br />
        <br />
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
