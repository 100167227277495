import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostTagCommandShapes, IPostTagRequest, IPostTagResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostTagCommandService extends PostCommandService<IPostTagRequest, IPostTagResponse, IPostTagCommandShapes> {
  route = 'zeiten/tag';
}
