import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IHerausgebersResponse } from '.';

export interface IHerausgebersQueryFilter extends IFilter<IHerausgebersResponse> {}

export const HerausgebersQueryFilterConfig: IFilterConfig<IHerausgebersResponse> = [];

export interface IHerausgebersQueryShapes extends IShape {}

export type IHerausgebersQueryField = 'herausgebers.id' | 'herausgebers.name' | 'herausgebers.website' | 'herausgebers.outlookKontaktes' | 'herausgebers.kommentar' | '';
