import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { TourStatiQuerySorterConfig } from 'fa-kt.types';
import { TourStatiService } from '../service';

@Component({
  selector: 'fa-kt-tour-stati-sorter',
  templateUrl: './tour-stati-sorter.component.html',
  styleUrls: ['./tour-stati-sorter.component.css'],
})
export class TourStatiSorterComponent {
  TourStatiSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public tourStati: TourStatiService,
  ) {
    this.TourStatiSorterConfig = TourStatiQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }
}
