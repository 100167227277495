import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostGutachtenStatusCommandServiceModule } from '..';
import { PostGutachtenStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostGutachtenStatusCommandServiceModule
  ],
  declarations: [PostGutachtenStatusCommandComponent],
  exports: [PostGutachtenStatusCommandComponent],

})
export class PostGutachtenStatusCommandComponentModule { }
