import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteKundeCommandServiceModule } from '..';
import { DeleteKundeCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteKundeCommandServiceModule
  ],
  declarations: [DeleteKundeCommandComponent],
  exports: [DeleteKundeCommandComponent],

})
export class DeleteKundeCommandComponentModule { }
