import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostReisekostenabrechnungStatusCommandServiceModule } from '..';
import { PostReisekostenabrechnungStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostReisekostenabrechnungStatusCommandServiceModule
  ],
  declarations: [PostReisekostenabrechnungStatusCommandComponent],
  exports: [PostReisekostenabrechnungStatusCommandComponent],

})
export class PostReisekostenabrechnungStatusCommandComponentModule { }
