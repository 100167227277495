import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteBewertungsAnlassCommandServiceModule } from '..';
import { DeleteBewertungsAnlassCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteBewertungsAnlassCommandServiceModule
  ],
  declarations: [DeleteBewertungsAnlassCommandComponent],
  exports: [DeleteBewertungsAnlassCommandComponent],

})
export class DeleteBewertungsAnlassCommandComponentModule { }
