import { Component } from '@angular/core';
import { ArchivService } from '../service';

@Component({
  selector: 'fa-kt-saved-archiv-filters',
  templateUrl: './saved-archiv-filters.component.html',
  styleUrls: ['./saved-archiv-filters.component.css'],
})
export class SavedArchivFiltersComponent {
  constructor(public archiv: ArchivService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
