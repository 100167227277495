import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SettingsQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IObjektArtsQueryFilter, IObjektArtsQueryShapes, IObjektArtsQuerySorter, IObjektArtsRequest, IObjektArtsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ObjektArtsService extends SettingsQueryService<IObjektArtsRequest, IObjektArtsResponse, IObjektArtsQueryShapes, IObjektArtsQueryFilter, IObjektArtsQuerySorter> {
  contentKey: keyof IObjektArtsResponse = 'objektArts';
  route = 'objekte/settings/objekt-arts';
  override dependencies = ['objekte/written-objekt-art', 'objekte/removed-objekt-art', 'objekte/written-objekt-nutzung', 'objekte/removed-objekt-nutzung'];

  public initialenIsUnique(control: AbstractControl | null): (initialen: string) => boolean {
    return (initialen: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (initialen) {
        const response = this.response$.getValue();
        if (response && response.objektArts) {
          isUnique = response.objektArts.filter((oa) => oa.id !== id && oa.initialen === initialen).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    };
  }

  async getObjektArtsObjektNutzung(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.objektArtsObjektNutzung.pipe(first()).subscribe((objektArtsObjektNutzung) => resolve(objektArtsObjektNutzung.find((a) => a.value === value)));
    });
  }

  get objektArtsObjektNutzung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['objektArts.objektNutzung'] as ISelection[]) : [])));
  }
}
