import { NgModule } from '@angular/core';

import {
  DeleteKundeCommandServiceModule,
  DeleteKundeCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteKundeCommandServiceModule,
    DeleteKundeCommandComponentModule
  ],
  exports: [
    DeleteKundeCommandServiceModule,
    DeleteKundeCommandComponentModule
  ]
})
export class DeleteKundeCommandModule { }
