import { NgModule } from '@angular/core';

import {
  PostZeitCommandComponentModule, PostZeitCommandServiceModule
} from '.';

@NgModule({
  imports: [
    PostZeitCommandServiceModule,
    PostZeitCommandComponentModule
  ],
  exports: [
    PostZeitCommandServiceModule,
    PostZeitCommandComponentModule
  ]
})
export class PostZeitCommandModule { }
