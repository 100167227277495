import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ErforderlichesDokumentsServiceModule } from '..';
import { ErforderlichesDokumentsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ErforderlichesDokumentsServiceModule
  ],
  declarations: [ErforderlichesDokumentsListComponent],
  exports: [ErforderlichesDokumentsListComponent],

})
export class ErforderlichesDokumentsListComponentModule { }
