import { Component } from '@angular/core';
import { TourenService } from '../service';

@Component({
  selector: 'fa-kt-saved-touren-filters',
  templateUrl: './saved-touren-filters.component.html',
  styleUrls: ['./saved-touren-filters.component.css'],
})
export class SavedTourenFiltersComponent {
  constructor(public touren: TourenService) {}
}
