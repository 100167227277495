import { NgModule } from '@angular/core';
import {
  ProjekteFilterComponentModule,
  ProjekteListComponentModule,
  ProjekteSorterComponentModule,
  SavedProjekteFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    ProjekteFilterComponentModule,
    ProjekteListComponentModule,
    ProjekteSorterComponentModule,
    SavedProjekteFiltersComponentModule
  ],
  exports: [
    ProjekteFilterComponentModule,
    ProjekteListComponentModule,
    ProjekteSorterComponentModule,
    SavedProjekteFiltersComponentModule
  ],
})
export class ProjekteQueryModule { }
