import { NgModule } from '@angular/core';

import { BereichsQueryModule, DeleteBereichCommandModule, PostBereichCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostBereichCommandModule, DeleteBereichCommandModule, BereichsQueryModule],
  exports: [PostBereichCommandModule, DeleteBereichCommandModule, BereichsQueryModule],
})
export class BereichsSettingsModule {}
