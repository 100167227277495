import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IBereichSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  kommentar?: ISorterExpression;
  areal?: ISorterExpression;
}

export const BereichSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('areal', 'Areal')
];
