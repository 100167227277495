import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenGruppesServiceModule } from '..';
import { KundenGruppesFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenGruppesServiceModule
  ],
  declarations: [KundenGruppesFilterComponent],
  exports: [KundenGruppesFilterComponent],

})
export class KundenGruppesFilterComponentModule { }
