import { NgModule } from '@angular/core';

import { ReisekostenabrechnungStatiSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [ReisekostenabrechnungStatiSettingsModule],
  exports: [ReisekostenabrechnungStatiSettingsModule],
})
export class FinanzenSettingsModule {}
