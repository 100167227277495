import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { GeoCommandsModule } from './commands';
import { GeoComponentsModule } from './components';
import { GeoQuerysModule } from './querys';
import { BereichsPage, BereichsPageResolver, BereichsService, BereichsServiceModule, GeoSettingsModule, OrtKategoriesPage, OrtKategoriesPageResolver, OrtsPage, OrtsPageResolver } from './settings';

const routes: Routes = [
  {
    path: 'geo',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'orts',
            component: OrtsPage,
            resolve: {
              querys: OrtsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: [],
                  write: [],
                },
              },
            },
          },
          {
            path: 'ort-kategories',
            component: OrtKategoriesPage,
            resolve: {
              querys: OrtKategoriesPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: [],
                  write: [],
                },
              },
            },
          },
          {
            path: 'bereichs',
            component: BereichsPage,
            resolve: {
              querys: BereichsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_BEREICHS'],
                  write: ['POST_BEREICH', 'DELETE_BEREICH'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [OrtsPage, OrtKategoriesPage, BereichsPage],
  imports: [LibsCommonAngularModule, GeoSettingsModule, GeoComponentsModule, GeoCommandsModule, GeoQuerysModule, BereichsServiceModule, RouterModule.forChild(routes)],
  exports: [GeoSettingsModule, GeoComponentsModule, GeoCommandsModule, GeoQuerysModule, BereichsServiceModule],
})
export class GeoModule {
  static forRoot(): ModuleWithProviders<GeoModule> {
    return {
      ngModule: GeoModule,
      providers: [BereichsService],
    };
  }
}
