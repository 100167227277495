import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBereichCommandServiceModule } from '..';
import { PostBereichCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBereichCommandServiceModule
  ],
  declarations: [PostBereichCommandComponent],
  exports: [PostBereichCommandComponent],

})
export class PostBereichCommandComponentModule { }
