import { NgModule } from '@angular/core';

import {
  PostTemplateCommandServiceModule,
  PostTemplateCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostTemplateCommandServiceModule,
    PostTemplateCommandComponentModule
  ],
  exports: [
    PostTemplateCommandServiceModule,
    PostTemplateCommandComponentModule
  ]
})
export class PostTemplateCommandModule { }
