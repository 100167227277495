import { NgModule } from '@angular/core';

import {
  PostHerausgeberCommandServiceModule,
  PostHerausgeberCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostHerausgeberCommandServiceModule,
    PostHerausgeberCommandComponentModule
  ],
  exports: [
    PostHerausgeberCommandServiceModule,
    PostHerausgeberCommandComponentModule
  ]
})
export class PostHerausgeberCommandModule { }
