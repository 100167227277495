import { IFilter, IFilterConfig, ISelection, IShape, ISingleReferenceFilter, SingleReferenceFilterConfig } from 'pbc.types';

import { IKundenGruppe } from 'fa-kt.types';
import { IKundenArtsResponse } from '.';

export interface IKundenArtsQueryFilter extends IFilter<IKundenArtsResponse> {
  'kundenArts.kundenGruppe'?: ISingleReferenceFilter<IKundenGruppe>;
}

export const KundenArtsQueryFilterConfig: IFilterConfig<IKundenArtsResponse> = [
  SingleReferenceFilterConfig<IKundenGruppe>('kundenArts.kundenGruppe', 'KUNDEN.Models.KUNDEN_ART.Propertys.KUNDEN_GRUPPE.title'),
];

export interface IKundenArtsQueryShapes extends IShape {
  'kundenArts.kundenGruppe': ISelection[];
}

export type IKundenArtsQueryField = 'kundenArts.id' | 'kundenArts.kundenGruppe' | 'kundenArts.name' | 'kundenArts.beschreibung' | '';
