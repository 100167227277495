import { NgModule } from '@angular/core';

import {
  PostDurchgangCommandServiceModule,
  PostDurchgangCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostDurchgangCommandServiceModule,
    PostDurchgangCommandComponentModule
  ],
  exports: [
    PostDurchgangCommandServiceModule,
    PostDurchgangCommandComponentModule
  ]
})
export class PostDurchgangCommandModule { }
