import { Component } from '@angular/core';
import { BerichtService } from '../service';

@Component({
  selector: 'fa-kt-saved-bericht-filters',
  templateUrl: './saved-bericht-filters.component.html',
  styleUrls: ['./saved-bericht-filters.component.css'],
})
export class SavedBerichtFiltersComponent {
  constructor(public bericht: BerichtService) {}
}
