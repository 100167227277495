import { IFilter, IFilterConfig } from "pbc.types";

import { IStandort } from '.';

export interface IStandortFilter extends IFilter<IStandort> {}

export const StandortFilterConfig: IFilterConfig<IStandort> = [];

export type IStandortField =
  'standort.id'|
  'standort.name'|
  'standort.addresse'|
  'standort.dateipfad'
;
