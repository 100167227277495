import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IKundenGruppesResponse } from '.';

export interface IKundenGruppesQueryFilter extends IFilter<IKundenGruppesResponse> {}

export const KundenGruppesQueryFilterConfig: IFilterConfig<IKundenGruppesResponse> = [];

export interface IKundenGruppesQueryShapes extends IShape {}

export type IKundenGruppesQueryField = 'kundenGruppes.id' | 'kundenGruppes.name' | 'kundenGruppes.beschreibung' | '';
