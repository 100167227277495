import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KonstantesServiceModule } from '..';
import { KonstantesSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KonstantesServiceModule
  ],
  declarations: [KonstantesSorterComponent],
  exports: [KonstantesSorterComponent],

})
export class KonstantesSorterComponentModule { }
