import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { SettingsQueryService } from 'pbc.angular';

import { IZeitArtsQueryFilter, IZeitArtsQueryShapes, IZeitArtsQuerySorter, IZeitArtsRequest, IZeitArtsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ZeitArtsService extends SettingsQueryService<IZeitArtsRequest, IZeitArtsResponse, IZeitArtsQueryShapes, IZeitArtsQueryFilter, IZeitArtsQuerySorter> {
  contentKey: keyof IZeitArtsResponse = 'zeitArts';
  route = 'zeiten/settings/zeit-arts';
  override dependencies = ['zeiten/written-zeit-art', 'zeiten/removed-zeit-art'];

  static instance: ZeitArtsService;

  constructor() {
    super();
    if (!ZeitArtsService.instance) ZeitArtsService.instance = this;
    return ZeitArtsService.instance;
  }

  public nameIsUnique(control: AbstractControl | null): (name: string) => boolean {
    return (name: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (name) {
        const response = this.response$.getValue();
        if (response && response.zeitArts) {
          isUnique = response.zeitArts.filter((za) => za.id !== id && za.name === name).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    };
  }
}
