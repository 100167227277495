import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenArtsServiceModule } from '..';
import { KundenArtsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenArtsServiceModule
  ],
  declarations: [KundenArtsListComponent],
  exports: [KundenArtsListComponent],

})
export class KundenArtsListComponentModule { }
