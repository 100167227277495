import { Injectable } from '@angular/core';

import { IDeleteObjektRequest, IDeleteObjektResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteObjektCommandService extends DeleteCommandService<IDeleteObjektRequest, IDeleteObjektResponse> {
  readonly route = 'objekte/objekt';
}
