import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { RechnungenComponent } from '.';
import { MitarbeiterQueryModule } from '../../../personen';
import { DeleteRechnungCommandModule, DeleteRechnungsVorlageCommandModule, PostRechnungCommandModule, PostRechnungenHochzaehlenCommandModule, PostRechnungsVorlageCommandModule } from '../../commands';
import { rechnungsVorlagenQueryModule } from '../../querys';

@NgModule({
  imports: [
    LIB_CommonModule,
    MitarbeiterQueryModule,
    rechnungsVorlagenQueryModule,
    DeleteRechnungCommandModule,
    PostRechnungCommandModule,
    PostRechnungenHochzaehlenCommandModule,
    PostRechnungsVorlageCommandModule,
    DeleteRechnungsVorlageCommandModule,
  ],
  declarations: [RechnungenComponent],
  exports: [RechnungenComponent],
})
export class RechnungenComponentModule {}
