import { Component } from '@angular/core';
import { DashboardService } from '../service';

@Component({
  selector: 'fa-kt-saved-dashboard-filters',
  templateUrl: './saved-dashboard-filters.component.html',
  styleUrls: ['./saved-dashboard-filters.component.css'],
})
export class SavedDashboardFiltersComponent {
  constructor(public dashboard: DashboardService) {}
}
