import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth';
import { ResolverLoadingService } from './common';

@Injectable({
  providedIn: 'root',
})
export abstract class BasePageResolver<T> implements Resolve<T> {
  auth = inject(AuthService);
  loading = inject(ResolverLoadingService);

  constructor() {}

  abstract resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<T>;
}
