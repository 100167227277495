import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostOrdneFeldOptionenCommandServiceModule } from '..';
import { PostOrdneFeldOptionenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostOrdneFeldOptionenCommandServiceModule
  ],
  declarations: [PostOrdneFeldOptionenCommandComponent],
  exports: [PostOrdneFeldOptionenCommandComponent],

})
export class PostOrdneFeldOptionenCommandComponentModule { }
