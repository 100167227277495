import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IBereichsQueryFilter, IBereichsQueryShapes, IBereichsQuerySorter, IBereichsRequest, IBereichsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class BereichsService extends SettingsQueryService<IBereichsRequest, IBereichsResponse, IBereichsQueryShapes, IBereichsQueryFilter, IBereichsQuerySorter> {
  contentKey: keyof IBereichsResponse = 'bereichs';
  route = 'geo/settings/bereichs';
  override dependencies = ['geo/written-bereich', 'geo/removed-bereich'];
}
