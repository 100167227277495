import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { DokumentenAnforderungenComponent, NurErforderlichPipe } from '.';
import { PostDokumentHinterlegenCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostDokumentHinterlegenCommandModule],
  declarations: [NurErforderlichPipe, DokumentenAnforderungenComponent],
  exports: [DokumentenAnforderungenComponent],
})
export class DokumentenAnforderungenComponentModule {}
