import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { RechnungsVorlagenListComponent } from '.';
import { RechnungsVorlagenServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, RechnungsVorlagenServiceModule],
  declarations: [RechnungsVorlagenListComponent],
  exports: [RechnungsVorlagenListComponent],
})
export class RechnungsVorlagenListComponentModule {}
