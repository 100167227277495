import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DateiArtsServiceModule } from '..';
import { DateiArtsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DateiArtsServiceModule
  ],
  declarations: [DateiArtsSorterComponent],
  exports: [DateiArtsSorterComponent],

})
export class DateiArtsSorterComponentModule { }
