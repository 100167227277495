import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Workbook } from 'exceljs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, OrderPipe, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { IErforderlichesDokument, IKunde, IPostDokumentHinterlegenRequest, IPostDokumentHinterlegenResponse, IProjekt } from 'fa-kt.types';
import { PostDokumentHinterlegenCommandService } from '../../commands';

@Pipe({ name: 'nurErforderliche' })
export class NurErforderlichPipe implements PipeTransform {
  transform(value: IErforderlichesDokument[], set = false): IErforderlichesDokument[] {
    if (!set) return value;
    return value.filter((row) => row.erforderlich);
  }
}

@Component({
  selector: 'fa-kt-dokumenten-anforderungen',
  templateUrl: './dokumenten-anforderungen.component.html',
  styleUrls: ['./dokumenten-anforderungen.component.css'],
})
export class DokumentenAnforderungenComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postDokumentHinterlegen: BehaviorSubject<IPostDokumentHinterlegenRequest | null> = new BehaviorSubject<IPostDokumentHinterlegenRequest | null>(null);

  erforderlich = false;
  @Input() projekt: IProjekt | undefined = undefined;
  @Input() kunde: IKunde | undefined = undefined;
  @Input() gutachten: string | undefined;
  @Input() disabled = true;
  @Input() set dokumentenAnforderungen(dokumentenAnforderungen: IErforderlichesDokument[]) {
    if (!dokumentenAnforderungen) return;
    this._dokumentenAnforderungen = dokumentenAnforderungen
      .map((dokument) => this.gespeicherte.find((d) => d.id === dokument.id) || dokument)
      .map((d) => ({ ...d, fortschritt: d.fortschritt || 0 }))
      .sort((d) => d.order)
      .reverse();
  }
  get dokumentenAnforderungen() {
    return this._dokumentenAnforderungen;
  }
  private _dokumentenAnforderungen: IErforderlichesDokument[] = [];
  @Input() set gespeicherte(gespeicherte: IErforderlichesDokument[]) {
    let set = !this._gespeicherte?.length;
    this._gespeicherte = gespeicherte;
    if (this._gespeicherte?.length && set) this.dokumentenAnforderungen = this.dokumentenAnforderungen;
  }
  get gespeicherte() {
    return this._gespeicherte;
  }
  _gespeicherte: IErforderlichesDokument[] = [];

  @Output() gespeicherteChange = new EventEmitter<IErforderlichesDokument[]>();

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postDokumentHinterlegen: PostDokumentHinterlegenCommandService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        this.gespeicherteChange.subscribe((gespeicherte) => (this.gespeicherte = gespeicherte)),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostDokumentHinterlegen(response?: IPostDokumentHinterlegenResponse) {
    this.gespeicherteChange.emit([]);
  }

  public save(dokument: IErforderlichesDokument) {
    let gespeicherte = this.gespeicherte;
    if (gespeicherte.map(({ id }) => id).includes(dokument.id)) gespeicherte = gespeicherte.map((d) => (d.id === dokument.id ? dokument : d));
    else gespeicherte.push(dokument);
    this.gespeicherteChange.emit(gespeicherte);
  }

  /* << methods    */

  async download() {
    const [blob] = await Promise.all([this.files.get('fa-kt-apps/Dokumente.xlsx')]);
    if (!blob) {
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Dokumente';
    const worksheet = workbook.getWorksheet(sheetName);

    (new OrderPipe().transform(this._dokumentenAnforderungen, 'order') as IErforderlichesDokument[]).forEach((dokument, i) => {
      worksheet.getCell('A' + (i + 2)).value = dokument.name;
      worksheet.getCell('B' + (i + 2)).value = dokument.erforderlich ? 'Ja' : 'Nein';
      worksheet.getCell('C' + (i + 2)).value = dokument.erhaltenAm;
      worksheet.getCell('D' + (i + 2)).value = dokument.dokumentVom;
      worksheet.getCell('E' + (i + 2)).value = (dokument.fortschritt || 0) / 100;
    });

    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      this.projekt?.nummer + '|' + this.projekt?.bezeichnung + '_Dokumentenübersicht_' + new Date().toLocaleDateString() + '.xlsx',
    );
  }
  /*    methods >> */

  public async submitPostDokumentHinterlegen(request?: IPostDokumentHinterlegenRequest) {
    const payload = request ? request : this.$postDokumentHinterlegen.getValue();
    if (payload) {
      try {
        this.$loading.next(true);
        const response = await this.postDokumentHinterlegen.request(payload);
        await this.finishedPostDokumentHinterlegen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
