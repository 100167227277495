import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "pbc.angular";

import { SollIstService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    SollIstService,
  ],
  exports: []
})
export class SollIstServiceModule { }
