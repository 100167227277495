import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TageSorterComponent } from '.';
import { TageServiceModule } from '../..';

@NgModule({
  imports: [LIB_CommonModule, TageServiceModule],
  declarations: [TageSorterComponent],
  exports: [TageSorterComponent],
})
export class TageSorterComponentModule {}
