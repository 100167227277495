import { Component, OnDestroy, OnInit } from '@angular/core';
import { Data, Params } from '@angular/router';

import { BehaviorSubject, Subscription } from 'rxjs';

import { BasePage, ISitemapPage } from 'pbc.angular';

import { IDeleteKundeRequest, IDeleteKundeResponse, IPostKundenFinanzenRequest, IPostKundenFinanzenResponse, IPostKundeRequest, IPostKundeResponse } from 'fa-kt.types';
import { IKundePageResolverResult } from '.';
import { ProjekteService } from '../../../projekte';
import { DeleteKundeCommandService, PostKundeCommandService, PostKundenFinanzenCommandService } from '../../commands';
import { KundeService } from '../../querys';

@Component({
  selector: 'fa-kt-kunde-page',
  templateUrl: './kunde.page.html',
  styleUrls: ['./kunde.page.css'],
})
export class KundePage extends BasePage implements OnInit, OnDestroy {
  description = { context: 'KUNDEN', page: 'KUNDE' };
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postKunde: BehaviorSubject<IPostKundeRequest | null> = new BehaviorSubject<IPostKundeRequest | null>(null);
  public readonly $deleteKunde: BehaviorSubject<IDeleteKundeRequest | null> = new BehaviorSubject<IDeleteKundeRequest | null>(null);
  public readonly $postKundenFinanzen: BehaviorSubject<IPostKundenFinanzenRequest | null> = new BehaviorSubject<IPostKundenFinanzenRequest | null>(null);

  public _kunden: ISitemapPage;
  public _projekte: ISitemapPage;

  public readonly $tab = new BehaviorSubject<'kunde' | 'kommunikation' | 'anforderungen' | 'fonds' | 'abteilungen' | 'honorar-vereinbarungen'>('kunde');
  public readonly $id = new BehaviorSubject<string | undefined>(undefined);
  public readonly $creating = new BehaviorSubject<boolean>(true);
  public readonly $name = new BehaviorSubject<string | undefined>(undefined);
  public readonly $website = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    public kunde: KundeService,
    public postKunde: PostKundeCommandService,
    public deleteKunde: DeleteKundeCommandService,
    public postKundenFinanzen: PostKundenFinanzenCommandService,
    public projekte: ProjekteService,
  ) {
    super();
    this._kunden = this.sitemap.KUNDEN.Pages.KUNDEN;
    this._projekte = this.sitemap.PROJEKTE.Pages.PROJEKTE;
  }

  override async ngOnInit() {
    super.ngOnInit();
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { tab: 'kunde' },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {
          this.$tab.next(params.tab);
          if (params.id) this.$id.next(params.id);
        }),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IKundePageResolverResult = data.querys;
          /* << resolver    */
          if (querys.kunde && querys.kunde.kunde) {
            const kunde = querys.kunde.kunde;
            const id = kunde.id;
            if (!id || id === 'new') {
              this.$postKunde.next({ kunde });
            }
          }
        }),
        this.$id.subscribe(async (id) => {
          const creating = !id || id === 'new';
          this.$creating.next(creating);
          if (creating) return;
          await this.kunde.request({ id });
        }),
        this.kunde.result$.subscribe((result) => {
          this.page.title = 'Kunde' + (result?.kunde?.name ? ': "' + result?.kunde?.name + '"' : '');
          this.titleRef.setTitle(this.page.title);
          if (!result) return;
          this.$postKunde.next({ kunde: result.kunde });
          this.$deleteKunde.next({ id: result.kunde.id });
          this.$name.next(result.kunde.name);
          this.$website.next(result.kunde.website);
        }),
        /*    subscriptions >> */
      ],
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setKunde(id: string) {
    /* << set-kunde    */
    /*    set-kunde >> */
  }

  async finishedPostKunde(response?: IPostKundeResponse) {
    this.navigateToKunde({ id: response.kunde.id, tab: 'kunde' });
  }

  async finishedDeleteKunde(response?: IDeleteKundeResponse) {
    this.kunde.payload$.next(undefined);
    this.kunde.result$.next(undefined);
    this.navigateToKunden();
  }

  async finishedPostKundenFinanzen(response?: IPostKundenFinanzenResponse) {}

  public async navigateToKunde(queryParams: Params = {}) {
    await this.router.navigate(this.page.url, { queryParams });
  }

  public async navigateToKunden(queryParams: Params = {}) {
    await this.router.navigate(this._kunden.url, { queryParams });
  }

  public async navigateToProjekte(queryParams: Params = {}) {
    await this.router.navigate(this._projekte.url, { queryParams });
  }

  public async setProjekte() {
    this.projekte.filter$.next({ ...(this.projekte.filter$.getValue() || {}), 'projekt.kunde': { operator: 'ist', value: [this.$id.getValue()] } });
    await this.navigateToProjekte();
  }

  back() {
    this.navigateToKunden();
  }

  public async submitPostKunde(request?: IPostKundeRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postKunde.getValue();
    if (payload) {
      try {
        const response = await this.postKunde.request(payload);
        await this.finishedPostKunde(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteKunde(request?: IDeleteKundeRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteKunde.getValue();
    if (payload) {
      try {
        const response = await this.deleteKunde.request(payload);
        await this.finishedDeleteKunde(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostKundenFinanzen(request?: IPostKundenFinanzenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postKundenFinanzen.getValue();
    if (payload) {
      try {
        const response = await this.postKundenFinanzen.request(payload);
        await this.finishedPostKundenFinanzen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
