import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { IDeleteProjektRequest, IDeleteProjektResponse } from 'fa-kt.types';
import { DeleteProjektCommandService } from '../service';

@Component({
  selector: 'fa-kt-delete-projekt',
  templateUrl: './delete-projekt.component.html',
  styleUrls: ['./delete-projekt.component.css'],
})
export class DeleteProjektCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IDeleteProjektRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IDeleteProjektRequest> = new EventEmitter<IDeleteProjektRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IDeleteProjektResponse> = new EventEmitter<IDeleteProjektResponse>();

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public deleteProjekt: DeleteProjektCommandService /* << more    */ /*    more >> */,
  ) {
    this.command = sitemap.PROJEKTE.Commands.DELETE_PROJEKT;
    this.form = this.fb.group({
      id: [null, [Validators.required]],
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IDeleteProjektRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.id?.valueChanges.subscribe(async (id) => {
        /* << on-id-change    */
        /*    on-id-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IDeleteProjektRequest) {
    if (value) this.form.patchValue(value);
    else this.form.reset();
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      const response: IDeleteProjektResponse = await this.deleteProjekt.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get id() {
    return this.form.controls.id;
  }
}
