import { Injectable } from '@angular/core';

import { DetailQueryService } from 'pbc.angular';

import { IObjektQueryShapes, IObjektRequest, IObjektResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ObjektService extends DetailQueryService<IObjektRequest, IObjektResponse, IObjektQueryShapes> {
  route = 'objekte/objekt';
  override dependencies = ['geo/written-bereich', 'geo/removed-bereich', 'objekte/objekt-gespeichert'];
  static instance: ObjektService;

  constructor() {
    super();
    if (!ObjektService.instance) ObjektService.instance = this;
    return ObjektService.instance;
  }
}
