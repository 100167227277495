import { Injectable } from '@angular/core';

import { IPostOrdneFeldOptionenCommandShapes, IPostOrdneFeldOptionenRequest, IPostOrdneFeldOptionenResponse } from 'fa-kt.types';
import { PostCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class PostOrdneFeldOptionenCommandService extends PostCommandService<IPostOrdneFeldOptionenRequest, IPostOrdneFeldOptionenResponse, IPostOrdneFeldOptionenCommandShapes> {
  readonly route = 'felder/ordne-feld-optionen';
}
