import { NgModule } from '@angular/core';
import {
  StandortsFilterComponentModule,
  StandortsSorterComponentModule,
  StandortsListComponentModule
} from '.';

@NgModule({
  imports: [
    StandortsFilterComponentModule,
    StandortsSorterComponentModule,
    StandortsListComponentModule
  ],
  exports: [
    StandortsFilterComponentModule,
    StandortsSorterComponentModule,
    StandortsListComponentModule
  ],
})
export class StandortsQueryModule { }