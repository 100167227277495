import { NgModule } from '@angular/core';
import {
  KundenFilterComponentModule,
  KundenListComponentModule,
  KundenSorterComponentModule,
  SavedKundenFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    KundenFilterComponentModule,
    KundenListComponentModule,
    KundenSorterComponentModule,
    SavedKundenFiltersComponentModule
  ],
  exports: [
    KundenFilterComponentModule,
    KundenListComponentModule,
    KundenSorterComponentModule,
    SavedKundenFiltersComponentModule
  ],
})
export class KundenQueryModule { }
