import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IVorlage, IVorlagenResponseRow } from 'fa-kt.types';
import { VorlagenService } from '../service';

@Component({
  selector: 'fa-kt-vorlagen-list',
  templateUrl: './vorlagen-list.component.html',
  styleUrls: ['./vorlagen-list.component.css'],
})
export class VorlagenListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public vorlagen: VorlagenService) {}

  public set(vorlagen: IVorlagenResponseRow) {
    this.select.emit(vorlagen.vorlage.id);
  }

  hasDivider(vorlage: IVorlage): boolean {
    if (!vorlage) return false;
    return (
      (vorlage.fuerBewertungsAnlaesse?.length ? 1 : 0) +
        (vorlage.fuerObjektArten?.length ? 1 : 0) +
        (vorlage.fuerKundenGruppen?.length ? 1 : 0) +
        (vorlage.fuerKundenArten?.length ? 1 : 0) +
        (vorlage.fuerKunden?.length ? 1 : 0) >
      1
    );
  }
}
