import { Component } from '@angular/core';
import { BerichteService } from '../service';

@Component({
  selector: 'fa-kt-saved-berichte-filters',
  templateUrl: './saved-berichte-filters.component.html',
  styleUrls: ['./saved-berichte-filters.component.css'],
})
export class SavedBerichteFiltersComponent {
  constructor(public berichte: BerichteService) {}
}
