import { Injectable } from '@angular/core';

import { IDeleteFotoKategorieRequest, IDeleteFotoKategorieResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteFotoKategorieCommandService extends DeleteCommandService<IDeleteFotoKategorieRequest, IDeleteFotoKategorieResponse> {
  readonly route = 'besichtigungen/settings/foto-kategorie';
}
