import { NgModule } from '@angular/core';

import {
  DeleteErforderlichesDokumentCommandServiceModule,
  DeleteErforderlichesDokumentCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteErforderlichesDokumentCommandServiceModule,
    DeleteErforderlichesDokumentCommandComponentModule
  ],
  exports: [
    DeleteErforderlichesDokumentCommandServiceModule,
    DeleteErforderlichesDokumentCommandComponentModule
  ]
})
export class DeleteErforderlichesDokumentCommandModule { }
