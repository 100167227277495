import { Pipe, PipeTransform } from "@angular/core";

import { IAddress } from "pbc.types";

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  constructor() {
  }

  transform(address?: IAddress): string {
    if (!address){ return '' };
    return `${address.strasse ? address.strasse + ', ' : ''}${ address.plz ? address.plz + ' ' : ''}${ address.land ? address.land : ''}`;
  }

}
