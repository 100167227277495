import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IKundenRequest, IKundenResponse } from 'fa-kt.types';
import { KundenService } from '../../querys';

export interface IKundenPageResolverResult {
  kunden?: IKundenResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class KundenPageResolver implements Resolve<IKundenPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private kunden: KundenService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IKundenPageResolverResult> {
    this.loading.resolving();
    const requests = {
      kunden: {} as IKundenRequest,
    };

    const log = (error: any) => console.error(error);
    const [kunden] = await Promise.all([this.kunden.request(requests.kunden).catch(log)]);
    this.loading.resolved();
    return {
      kunden,
    };
  }
}
