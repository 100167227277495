import { NgModule } from '@angular/core';

import { DeleteErforderlichesDokumentCommandModule, PostErforderlichesDokumentCommandModule } from './commands';
import { ErforderlichesDokumentsQueryModule } from './query';

@NgModule({
  declarations: [],
  imports: [PostErforderlichesDokumentCommandModule, DeleteErforderlichesDokumentCommandModule, ErforderlichesDokumentsQueryModule],
  exports: [PostErforderlichesDokumentCommandModule, DeleteErforderlichesDokumentCommandModule, ErforderlichesDokumentsQueryModule],
})
export class ErforderlichesDokumentsSettingsModule {}
