<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="felder.search$.next($event)" [search]="felder.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-felder-filter [style.width]="'100%'"></fa-kt-felder-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-felder-sorter [style.width]="'100%'"></fa-kt-felder-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-felder-list [style.width]="'100%'" (select)="setFelder($event)" [loading]="$loading | async"></fa-kt-felder-list>


  <fa-kt-post-eintrag [style.width]="'100%'" [value]="$postEintrag | async" (finished)="finishedPostEintrag($event)"></fa-kt-post-eintrag>



-->
<!--- << custom    -->
<nz-row>
  <nz-col nzSpan="4">
    <nz-select style="width: 100%" nzShowSearch nzAllowClear nzPlaceHolder="Kein Formular angewendet" [ngModel]="$formular | async" (ngModelChange)="changeFormular($event)">
      <nz-option *ngFor="let formular of $formulare | async" [nzLabel]="formular.name" [nzValue]="formular"></nz-option>
    </nz-select>
  </nz-col>
  <nz-col nzSpan="1"></nz-col>
  <nz-col nzSpan="14">
    <pbc-search-bar (searchChange)="$search.next($event)" [search]="$search | async"></pbc-search-bar>
  </nz-col>
  <nz-col nzSpan="1"></nz-col>
  <nz-col nzSpan="4">
    <button nz-button [disabled]="eintraege.length === 0" nzType="primary" nzBlock (click)="saveEintraege()">
      {{ eintraege.length }} Einträge speichern
      <i nz-icon nzType="save" nzTheme="outline"></i>
    </button>
  </nz-col>
  <nz-col nzSpan="24">
    <nz-spin [nzSpinning]="$loading | async" style="width: 100%">
      <nz-tabset>
        <nz-tab *ngFor="let kategorie of $kategorien | async; let indexK = index" [nzTitle]="kategorieName" nzCentered nzSize="large">
          <ng-template #kategorieName>
            <h2 nz-title [style.color]="kategorie.color">
              <i nz-icon [nzType]="kategorie.icon" nzTheme="outline"></i>
              {{ kategorie.name }}
            </h2>
          </ng-template>
          <ng-template nz-tab>
            <nz-row>
              <nz-col nzSpan="24" *ngFor="let unterkategorie of kategorie.unterkategorien; let indexU = index">
                <nz-row>
                  <nz-col nzSpan="24">
                    <h4 nz-title [style.color]="unterkategorie.color">
                      <i nz-icon [nzType]="unterkategorie.icon" nzTheme="outline"></i>
                      {{ unterkategorie.name }}
                    </h4>
                  </nz-col>
                  <nz-col nzSpan="24" *ngFor="let feld of unterkategorie.felder; let indexF = index">
                    <nz-row [style.backgroundColor]="indexF % 2 === 0 ? 'gainsboro' : ''">
                      <nz-col nzSpan="10">
                        <h5 nz-typography style="padding: 8px">
                          {{ feld.name }}
                        </h5>
                        <!-- <nz-span nz-typography nzType="secondary">{{ feld.schluessel }}</nz-span> -->
                      </nz-col>
                      <nz-col nzSpan="14" style="padding: 8px">
                        <ng-container [ngSwitch]="feld.art">
                          <ng-container *ngSwitchCase="'option'">
                            <button
                              nz-button
                              *ngFor="let option of feld.feldOptionen"
                              [disabled]="disabled"
                              [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                              type="button"
                              nzShape="round"
                              (click)="submitEintrag(feld, option.option)"
                            >
                              {{ option.option }}
                            </button>
                          </ng-container>
                          <ng-container *ngSwitchCase="'optionPlus'">
                            <button
                              nz-button
                              *ngFor="let option of feld.feldOptionen"
                              [disabled]="disabled"
                              [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                              type="button"
                              nzShape="round"
                              (click)="submitEintrag(feld, option.option)"
                            >
                              {{ option.option }}
                            </button>
                            <pbc-multi-value width="124px" [disabled]="disabled" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                          </ng-container>
                          <ng-container *ngSwitchCase="'mehrfachauswahlPlus'">
                            <button
                              nz-button
                              *ngFor="let option of feld.feldOptionen"
                              [disabled]="disabled"
                              [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'dashed'"
                              type="button"
                              nzShape="round"
                              (click)="toggleValues(feld, option)"
                            >
                              {{ option.option }}
                            </button>
                            <pbc-multi-value [value]="feld.eintrag?.wertExtra" (blurred)="submitExtraEintrag(feld, $event)" width="124px" [disabled]="disabled"></pbc-multi-value>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <pbc-multi-value [disabled]="disabled" [mode]="feld.art" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                          </ng-container>
                        </ng-container>
                      </nz-col>
                    </nz-row>
                  </nz-col>
                </nz-row>
                <nz-row>
                  <nz-col nzSpan="24">
                    <br />
                    <br />
                  </nz-col>
                </nz-row>
              </nz-col>
            </nz-row>
          </ng-template>
        </nz-tab>
      </nz-tabset>
      <ng-container *ngIf="($unzugeordnete | async).length > 0">
        <nz-divider nzText="Sonstige"></nz-divider>
        <nz-col nzSpan="24" *ngFor="let feld of $unzugeordnete | async | order: 'schluessel'">
          <nz-row>
            <nz-col nzSpan="10">
              <h5 nz-typography style="padding: 8px">
                {{ feld.name }}
              </h5>
            </nz-col>
            <nz-col nzSpan="14">
              <ng-container [ngSwitch]="feld.art">
                <ng-container *ngSwitchCase="'option'">
                  <button
                    nz-button
                    *ngFor="let option of feld.feldOptionen"
                    [disabled]="disabled"
                    [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                    type="button"
                    nzShape="round"
                    (click)="submitEintrag(feld, option.option)"
                  >
                    {{ option.option }}
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="'optionPlus'">
                  <button
                    nz-button
                    *ngFor="let option of feld.feldOptionen"
                    [disabled]="disabled"
                    [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                    type="button"
                    nzShape="round"
                    (click)="submitEintrag(feld, option.option)"
                  >
                    {{ option.option }}
                  </button>
                  <pbc-multi-value width="124px" [disabled]="disabled" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                </ng-container>
                <ng-container *ngSwitchCase="'mehrfachauswahlPlus'">
                  <button
                    nz-button
                    *ngFor="let option of feld.feldOptionen"
                    [disabled]="disabled"
                    [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'dashed'"
                    type="button"
                    nzShape="round"
                    (click)="toggleValues(feld, option)"
                  >
                    {{ option.option }}
                  </button>
                  <pbc-multi-value [value]="feld.eintrag?.wertExtra" (blurred)="submitExtraEintrag(feld, $event)" width="124px" [disabled]="disabled"></pbc-multi-value>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <pbc-multi-value [disabled]="disabled" [mode]="feld.art" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                </ng-container>
              </ng-container>
            </nz-col>
          </nz-row>
        </nz-col>
      </ng-container>
    </nz-spin>
  </nz-col>
  <nz-col nzSpan="20"> </nz-col>
  <nz-col nzSpan="4">
    <button nz-button [disabled]="eintraege.length === 0" nzType="primary" (click)="saveEintraege()">
      {{ eintraege.length }} Einträge speichern
      <i nz-icon nzType="save" nzTheme="outline"></i>
    </button>
  </nz-col>
</nz-row>
<!---    custom >> -->
