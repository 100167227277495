import { Component } from '@angular/core';
import { FelderService } from '../service';

@Component({
  selector: 'fa-kt-saved-felder-filters',
  templateUrl: './saved-felder-filters.component.html',
  styleUrls: ['./saved-felder-filters.component.css'],
})
export class SavedFelderFiltersComponent {
  constructor(public felder: FelderService) {}
}
