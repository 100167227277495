import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

// import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { AppEnvironment, APP_BUILD, APP_CONFIG, APP_VERSION } from '../types';

@Injectable()
export class MonitoringService {
  environment = inject(APP_CONFIG) as AppEnvironment;
  version = inject(APP_VERSION) as string;
  build = inject(APP_BUILD) as string;
  // @Inject(APP_CONFIG) public environment: AppEnvironment,
  // @Inject(APP_VERSION) public version: String,
  // @Inject(APP_BUILD) public build: String,

  appInsights: ApplicationInsights;
  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
    const telemetryInitializer = (envelope: any) => {
      envelope.tags['ai.cloud.role'] = this.environment.stage + '-app';
      envelope.tags['ai.cloud.roleInstance'] = `${this.environment.stage} - ${this.version} - (${this.build})`;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);

    // const angularPlugin = new AngularPlugin();
    // this.appInsights = new ApplicationInsights({
    //   config: {
    //     instrumentationKey: this.environment.appInsights.instrumentationKey,
    //     extensions: [angularPlugin],
    //     extensionConfig: {
    //       [angularPlugin.identifier]: {
    //         router: this.router,
    //         errorServices: [],
    //       },
    //     },
    //     loggingLevelConsole: 1,
    //     autoTrackPageVisitTime: true,
    //     enableAutoRouteTracking: true,
    //     disableFetchTracking: false,
    //     enableCorsCorrelation: true,
    //     enableRequestHeaderTracking: true,
    //     enableResponseHeaderTracking: true,
    //   },
    // });
    // this.appInsights.loadAppInsights();
  }
  setUser(id?: string) {
    if (id) this.appInsights.setAuthenticatedUserContext(id);
    else this.appInsights.clearAuthenticatedUserContext();
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    console.debug(name, properties);
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    console.error(exception);
    if (this.environment.production) this.appInsights.trackException({ exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }
}

export class AzureInsightsErrorHandler extends ErrorHandler {
  monitoring = inject(MonitoringService);

  override handleError(error: any) {
    this.monitoring.logException(error);
  }
}
