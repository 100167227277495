import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { RechnungsVorlagenSorterComponent } from '.';
import { RechnungsVorlagenServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, RechnungsVorlagenServiceModule],
  declarations: [RechnungsVorlagenSorterComponent],
  exports: [RechnungsVorlagenSorterComponent],
})
export class RechnungsVorlagenSorterComponentModule {}
