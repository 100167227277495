import { NgModule } from '@angular/core';

import {
  PostKalkulationHochladenCommandServiceModule,
  PostKalkulationHochladenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostKalkulationHochladenCommandServiceModule,
    PostKalkulationHochladenCommandComponentModule
  ],
  exports: [
    PostKalkulationHochladenCommandServiceModule,
    PostKalkulationHochladenCommandComponentModule
  ]
})
export class PostKalkulationHochladenCommandModule { }
