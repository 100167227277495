import { NgModule } from '@angular/core';
import {
  ArchivFilterComponentModule,
  ArchivListComponentModule,
  ArchivSorterComponentModule,
  SavedArchivFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    ArchivFilterComponentModule,
    ArchivListComponentModule,
    ArchivSorterComponentModule,
    SavedArchivFiltersComponentModule
  ],
  exports: [
    ArchivFilterComponentModule,
    ArchivListComponentModule,
    ArchivSorterComponentModule,
    SavedArchivFiltersComponentModule
  ],
})
export class ArchivQueryModule { }
