import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ReisekostenabrechnungServiceModule } from '..';
import { ReisekostenabrechnungDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungServiceModule
  ],
  declarations: [ReisekostenabrechnungDetailComponent],
  exports: [ReisekostenabrechnungDetailComponent],

})
export class ReisekostenabrechnungDetailComponentModule { }
