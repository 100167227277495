import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { OffeneGutachtenQuerySorterConfig } from 'fa-kt.types';
import { OffeneGutachtenService } from '../service';

@Component({
  selector: 'fa-kt-offene-gutachten-sorter',
  templateUrl: './offene-gutachten-sorter.component.html',
  styleUrls: ['./offene-gutachten-sorter.component.css'],
})
export class OffeneGutachtenSorterComponent {
  public OffeneGutachtenSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public offeneGutachten: OffeneGutachtenService,
  ) {
    this.OffeneGutachtenSorterConfig = OffeneGutachtenQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }
}
