import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteStandortCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteStandortCommandService],
  exports: []
})
export class DeleteStandortCommandServiceModule { }
