import Tagify from '@yaireo/tagify';
import { IFeld } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { checkUndefined, cssStyle, defaults, destroy, init, Option, parse, PlaceholderTool, show, toggleVisibility, toggleVisibilityTagify, toOption } from '.';

export class ConditionTool {
  public static isInline = true;
  public static title = 'Bedingung';
  public static shortcut = 'CMD+B';
  static delimiters = {
    start: '{% ',
    end: ' %}',
  };

  static tag = 'MARK';
  static class = 'fakt-condition';
  static frame = 'condition-frame';

  api: EditorJS.API;
  _state: boolean;

  conditions$: BehaviorSubject<IFeld[]>;

  frame: HTMLDivElement;
  button: HTMLButtonElement;

  path: { element?: HTMLInputElement; tagify?: Tagify } = {};
  operator: { element?: HTMLInputElement; tagify?: Tagify } = {};
  condition: { element?: HTMLInputElement; tagify?: Tagify } = {};
  inverse: { element?: HTMLInputElement } = {};

  constructor({ api, config }) {
    this.api = api;
    this._state = false;
    this.conditions$ = config.conditions;
  }

  static get sanitize() {
    return {
      mark: (el: HTMLElement) => ({
        class: el.className,
        style: el.style.cssText,
        'data-id': el.getAttribute('data-id'),
        'data-type': el.getAttribute('data-type'),
        'data-path': el.getAttribute('data-path'),
        'data-operator': el.getAttribute('data-operator'),
        'data-condition': el.getAttribute('data-condition'),
        'data-inverse': el.getAttribute('data-inverse'),
      }),
    };
  }

  static create(path = 'PLATZHALTER', operator = 'gesetzt', condition: string = undefined, inverse = false, id?: string) {
    const mark = document.createElement(this.tag);
    mark.classList.add(this.class);
    mark.contentEditable = 'false';
    mark.style.cssText = 'background: #FCAEAE; ' + cssStyle;
    mark.setAttribute('data-id', id || uuidv4());
    mark.setAttribute('data-type', 'condition');
    mark.setAttribute('data-path', path);
    mark.setAttribute('data-operator', operator);
    mark.setAttribute('data-condition', checkUndefined(condition, undefined));
    mark.setAttribute('data-inverse', inverse.toString());
    return this.update(mark);
  }

  static get(mark: HTMLElement) {
    return {
      id: mark.getAttribute('data-id'),
      path: checkUndefined(mark.getAttribute('data-path'), undefined),
      operator: checkUndefined(mark.getAttribute('data-operator'), undefined),
      condition: checkUndefined(mark.getAttribute('data-condition'), undefined)?.split(','),
      inverse: mark.getAttribute('data-inverse') === 'true',
    };
  }

  static update(mark: HTMLElement): HTMLElement {
    mark.id = mark.getAttribute('data-id');
    const { path, operator, condition, inverse } = ConditionTool.get(mark);
    let CONDITION = condition?.map((v) => `'${v}'`).join(',');
    mark.innerText = this.delimiters.start + `if ${inverse ? 'not ' : ''}${path} | ${operator}${operator !== 'gesetzt' && CONDITION ? '(' + CONDITION + ')' : ''}` + this.delimiters.end;
    return mark;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  checkState() {
    const mark = this.api.selection.findParentTag(ConditionTool.tag, ConditionTool.class);
    const others = [PlaceholderTool.class, ElseConditionTool.class, ElseTool.class, EndTool.class]
      .map((conditionClass) => this.api.selection.findParentTag(PlaceholderTool.tag, conditionClass))
      .filter((el) => !!el);
    this.state = !!mark;
    this.button = toggleVisibility(this.button, others.length === 0);
    setTimeout(() => {
      if (this.state) this.showActions(mark);
      else this.hideActions();
    }, 100);
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML =
      '<svg width="20" height="18" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" /><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z" /></svg>';
    this.button.classList.add(this.api.styles.inlineToolButton);
    return this.button;
  }

  surround(range) {
    if (this.state) this.unwrap(range);
    else if (range.endContainer.id !== ConditionTool.frame) this.wrap(range);
  }

  wrap(range) {
    const selectedText: DocumentFragment = range.extractContents();
    const mark = ConditionTool.create();
    range.insertNode(document.createTextNode(' '));
    range.insertNode(EndTool.create(mark.id));
    range.insertNode(ElseTool.create(mark.id));
    range.insertNode(selectedText);
    range.insertNode(mark);
    this.api.selection.expandToTag(mark);
  }

  unwrap(range?) {
    const me = this.api.selection.findParentTag(ConditionTool.tag, ConditionTool.class);
    const elif = me.parentElement.querySelectorAll(`${ElseConditionTool.tag}[id*='${me.id}'].${ElseConditionTool.class}`);
    const el = me.parentElement.querySelectorAll(`${ElseTool.tag}[id*='${me.id}'].${ElseTool.class}`);
    const end = me.parentElement.querySelectorAll(`${EndTool.tag}[id*='${me.id}'].${EndTool.class}`);
    if (me) me.remove();
    if (elif.length) elif.forEach((elif) => elif.remove());
    if (el.length) el.forEach((el) => el.remove());
    if (end.length) end.forEach((end) => end.remove());
  }

  renderActions() {
    this.frame = document.createElement('div');
    this.frame.id = ConditionTool.frame;
    this.frame.style.cssText = 'margin-bottom: 16px; margin-left: 16px; margin-right: 16px; min-width: 300px';

    this.path.element = document.createElement('input');
    this.path.element.id = 'path-input';
    this.path.element.className = this.api.styles.input;
    this.frame.appendChild(this.path.element);

    this.operator.element = document.createElement('input');
    this.operator.element.id = 'operator-input';
    this.operator.element.className = this.api.styles.input;
    this.frame.appendChild(this.operator.element);

    this.condition.element = document.createElement('input');
    this.condition.element.id = 'condition-input';
    this.condition.element.className = this.api.styles.input;
    this.condition.element.style.marginTop = '16px';
    this.frame.appendChild(this.condition.element);

    this.inverse.element = document.createElement('input');
    this.inverse.element.type = 'checkbox';
    this.inverse.element.id = 'inverse-checkbox';
    this.inverse.element.style.marginTop = '16px';
    this.frame.appendChild(this.inverse.element);

    const label = document.createElement('label');
    label.setAttribute('for', 'inverse-checkbox');
    label.innerText = 'Invertieren';
    label.style.cssText = 'margin-left: 8px;';
    this.frame.appendChild(label);

    return toggleVisibility(this.frame, false);
  }

  showActions(mark) {
    const whitelist: Option[] =
      this.conditions$.getValue()?.map(({ name, schluessel, art, format, _search, feldOptionen }) => ({
        label: name,
        value: schluessel,
        art,
        format,
        optionen: feldOptionen?.map(({ option }) => toOption(option)) || [],
        searchBy: _search,
      })) || [];
    let { id, path, operator, condition, inverse } = ConditionTool.get(mark);
    let currentPath = whitelist.find(({ value }) => value === path) || whitelist[0];

    let operators = defaults[currentPath.art];
    let currentOperator = toOption(operator);

    let conditions = currentPath.optionen || [];
    let currentConditions = condition?.map(toOption) || [];

    this.inverse.element.addEventListener(
      'click',
      () => {
        mark.setAttribute('data-inverse', this.inverse.element.checked);
        mark = ConditionTool.update(mark);
      },
      { passive: false },
    );
    this.inverse.element.checked = inverse;
    this.inverse.element.hidden = false;

    this.condition = init(
      this.condition,
      id + '-condition',
      currentConditions,
      (value) => {
        currentConditions = parse(value) || currentConditions;
        mark.setAttribute('data-condition', currentConditions.map(({ value }) => value).join(','));
        mark = ConditionTool.update(mark);
      },
      conditions,
      false,
      false,
    );
    this.condition.tagify = toggleVisibilityTagify(this.condition.tagify, ['hat', 'alle'].includes(currentOperator?.value));

    this.operator = init(
      this.operator,
      id + '-operator',
      currentOperator,
      (value) => {
        const last = currentOperator?.value;
        currentOperator = parse(value)?.shift() || currentOperator;
        if (currentOperator?.value === last) return;

        this.condition.tagify = toggleVisibilityTagify(this.condition.tagify, ['hat', 'alle'].includes(currentOperator?.value));

        mark.setAttribute('data-operator', currentOperator?.value);
        mark = ConditionTool.update(mark);
      },
      operators,
      true,
    );

    this.path = init(
      this.path,
      id + '-path',
      currentPath,
      (value) => {
        const last = currentPath?.value;
        currentPath = parse(value)?.shift() || currentPath;
        if (currentPath?.value === last) return;

        operators = defaults[currentPath.art];
        if (!operators.map(({ value }) => value).includes(currentOperator.value)) currentOperator = operators[0];
        this.operator = show(this.operator, currentOperator, operators);

        conditions = currentPath.optionen || [];
        if (!currentConditions || !currentConditions.map(({ value }) => value).every((c) => conditions.map(({ value }) => value).includes(c))) currentConditions = [];
        this.condition = show(this.condition, currentConditions, conditions);
        this.condition.tagify = toggleVisibilityTagify(this.condition.tagify, ['hat', 'alle'].includes(currentOperator?.value));

        mark.setAttribute('data-path', currentPath?.value);
        mark.setAttribute('data-operator', currentOperator?.value);
        mark.setAttribute('data-condition', currentConditions.map(({ value }) => value).join(','));
        mark = ConditionTool.update(mark);
      },
      whitelist,
      true,
    );

    this.frame = toggleVisibility(this.frame, true);
  }

  hideActions() {
    [this.path, this.operator, this.condition, this.inverse] = [this.path, this.operator, this.condition].map(destroy);
    this.frame = toggleVisibility(this.frame, false);
  }

  clear() {
    this.hideActions();
  }
}

export class ElseConditionTool {
  public static isInline = true;
  public static title = 'Sonst Bedingung';
  public static shortcut = 'CMD+E';
  static delimiters = {
    start: '{% ',
    end: ' %}',
  };

  static tag = 'MARK';
  static class = 'fakt-else-condition';
  static frame = 'else-condition-frame';

  api: EditorJS.API;
  _state: boolean;

  conditions$: BehaviorSubject<IFeld[]>;

  frame: HTMLDivElement;
  button: HTMLButtonElement;

  path: { element?: HTMLInputElement; tagify?: Tagify } = {};
  operator: { element?: HTMLInputElement; tagify?: Tagify } = {};
  condition: { element?: HTMLInputElement; tagify?: Tagify } = {};
  inverse: { element?: HTMLInputElement } = {};

  constructor({ api, config }) {
    this.api = api;
    this._state = false;
    this.conditions$ = config.conditions;
  }

  static get sanitize() {
    return {
      mark: (el: HTMLElement) => ({
        class: el.className,
        style: el.style.cssText,
        'data-id': el.getAttribute('data-id'),
        'data-type': el.getAttribute('data-type'),
        'data-path': el.getAttribute('data-path'),
        'data-operator': el.getAttribute('data-operator'),
        'data-condition': el.getAttribute('data-condition'),
        'data-inverse': el.getAttribute('data-inverse'),
      }),
    };
  }

  static create(path = 'PLATZHALTER', operator = 'gesetzt', condition: string = undefined, inverse = false, id?: string) {
    const mark = document.createElement(this.tag);
    mark.classList.add(this.class);
    mark.contentEditable = 'false';
    mark.style.cssText = 'background: #FCAEAE; ' + cssStyle;
    mark.setAttribute('data-id', id || uuidv4());
    mark.setAttribute('data-type', 'else-condition');
    mark.setAttribute('data-path', path);
    mark.setAttribute('data-operator', operator);
    mark.setAttribute('data-condition', checkUndefined(condition, undefined));
    mark.setAttribute('data-inverse', inverse.toString());
    return this.update(mark);
  }

  static get(mark: HTMLElement) {
    return {
      id: mark.getAttribute('data-id'),
      path: checkUndefined(mark.getAttribute('data-path'), undefined),
      operator: checkUndefined(mark.getAttribute('data-operator'), undefined),
      condition: checkUndefined(mark.getAttribute('data-condition'), undefined)?.split(','),
      inverse: mark.getAttribute('data-inverse') === 'true',
    };
  }

  static update(mark: HTMLElement): HTMLElement {
    mark.id = mark.getAttribute('data-id');
    const { path, operator, condition, inverse } = ElseConditionTool.get(mark);
    let CONDITION = condition?.map((v) => `'${v}'`).join(',');
    mark.innerText = this.delimiters.start + `elseif ${inverse ? 'not ' : ''}${path} | ${operator}${operator !== 'gesetzt' && CONDITION ? '(' + CONDITION + ')' : ''}` + this.delimiters.end;
    return mark;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  checkState() {
    function get(el: HTMLElement) {
      if (!el?.getAttribute) return undefined;
      return {
        type: el.getAttribute('data-type'),
        id: el.getAttribute('data-id'),
      };
    }
    const selection = window.getSelection();
    let previous = get(selection.anchorNode.previousSibling as HTMLElement);
    let next = get(selection.anchorNode.nextSibling as HTMLElement);
    if (next?.id !== previous?.id) {
      next = undefined;
      previous = undefined;
    }

    const mark = this.api.selection.findParentTag(ElseConditionTool.tag, ElseConditionTool.class);
    const others = [PlaceholderTool.class, ConditionTool.class, ElseTool.class, EndTool.class]
      .map((conditionClass) => this.api.selection.findParentTag(PlaceholderTool.tag, conditionClass))
      .filter((el) => !!el);

    this.state = !!mark;
    this.button = toggleVisibility(this.button, (!!mark && others.length === 0) || (['condition', 'else-condition'].includes(previous?.type) && ['else', 'end'].includes(next?.type)));
    setTimeout(() => {
      if (this.state) this.showActions(mark);
      else this.hideActions();
    }, 100);
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML =
      '<svg width="20" height="18" viewBox="64 64 896 896" focusable="false"><path d="M764 280.9c-14-30.6-33.9-58.1-59.3-81.6C653.1 151.4 584.6 125 512 125s-141.1 26.4-192.7 74.2c-25.4 23.6-45.3 51-59.3 81.7-14.6 32-22 65.9-22 100.9v27c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-27c0-99.5 88.6-180.4 197.6-180.4s197.6 80.9 197.6 180.4c0 40.8-14.5 79.2-42 111.2-27.2 31.7-65.6 54.4-108.1 64-24.3 5.5-46.2 19.2-61.7 38.8a110.85 110.85 0 00-23.9 68.6v31.4c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-31.4c0-15.7 10.9-29.5 26-32.9 58.4-13.2 111.4-44.7 149.3-88.7 19.1-22.3 34-47.1 44.3-74 10.7-27.9 16.1-57.2 16.1-87 0-35-7.4-69-22-100.9zM512 787c-30.9 0-56 25.1-56 56s25.1 56 56 56 56-25.1 56-56-25.1-56-56-56z" /></svg>';
    this.button.classList.add(this.api.styles.inlineToolButton);
    return this.button;
  }

  surround(range) {
    if (this.state) this.unwrap(range);
    else if (range.endContainer.id !== ElseConditionTool.frame) this.wrap(range);
  }

  wrap(range) {
    const selectedText: DocumentFragment = range.extractContents();
    const previous = window.getSelection().anchorNode.previousSibling as HTMLElement;
    const previousType = previous?.getAttribute('data-type');
    let previousMark: {
      id: string;
      path: any;
      operator: any;
      condition: any;
      inverse: boolean;
    };
    if (previousType === 'condition') {
      previousMark = ConditionTool.get(previous);
    } else if (previousType === 'else-condition') {
      previousMark = ElseConditionTool.get(previous);
    }
    const mark = ElseConditionTool.create(previousMark?.path, previousMark?.operator, previousMark?.condition, !previousMark?.inverse, previousMark?.id);
    range.insertNode(selectedText);
    range.insertNode(mark);
    this.api.selection.expandToTag(mark);
  }

  unwrap(range?) {
    const me = this.api.selection.findParentTag(ElseConditionTool.tag, ElseConditionTool.class);
    if (me) me.remove();
  }

  renderActions() {
    this.frame = document.createElement('div');
    this.frame.id = ElseConditionTool.frame;
    this.frame.style.cssText = 'margin-bottom: 16px; margin-left: 16px; margin-right: 16px; min-width: 300px';

    this.path.element = document.createElement('input');
    this.path.element.id = 'path-input';
    this.path.element.className = this.api.styles.input;
    this.frame.appendChild(this.path.element);

    this.operator.element = document.createElement('input');
    this.operator.element.id = 'operator-input';
    this.operator.element.className = this.api.styles.input;
    this.frame.appendChild(this.operator.element);

    this.condition.element = document.createElement('input');
    this.condition.element.id = 'condition-input';
    this.condition.element.className = this.api.styles.input;
    this.condition.element.style.marginTop = '16px';
    this.frame.appendChild(this.condition.element);

    this.inverse.element = document.createElement('input');
    this.inverse.element.type = 'checkbox';
    this.inverse.element.id = 'inverse-checkbox';
    this.inverse.element.style.marginTop = '16px';
    this.frame.appendChild(this.inverse.element);

    const label = document.createElement('label');
    label.setAttribute('for', 'inverse-checkbox');
    label.innerText = 'Invertieren';
    label.style.cssText = 'margin-left: 8px;';
    this.frame.appendChild(label);

    return toggleVisibility(this.frame, false);
  }

  showActions(mark) {
    const whitelist: Option[] =
      this.conditions$.getValue()?.map(({ name, schluessel, art, format, _search, feldOptionen }) => ({
        label: name,
        value: schluessel,
        art,
        format,
        optionen: feldOptionen?.map(({ option }) => toOption(option)) || [],
        searchBy: _search,
      })) || [];
    let { id, path, operator, condition, inverse } = ElseConditionTool.get(mark);
    let currentPath = whitelist.find(({ value }) => value === path) || whitelist[0];

    let operators = defaults[currentPath.art];
    let currentOperator = toOption(operator);

    let conditions = currentPath.optionen || [];
    let currentConditions = condition?.map(toOption) || [];

    this.inverse.element.addEventListener(
      'click',
      () => {
        mark.setAttribute('data-inverse', this.inverse.element.checked);
        mark = ElseConditionTool.update(mark);
      },
      { passive: false },
    );
    this.inverse.element.checked = inverse;
    this.inverse.element.hidden = false;

    this.condition = init(
      this.condition,
      id + '-condition',
      currentConditions,
      (value) => {
        currentConditions = parse(value) || currentConditions;
        mark.setAttribute('data-condition', currentConditions.map(({ value }) => value).join(','));
        mark = ElseConditionTool.update(mark);
      },
      conditions,
      false,
      false,
    );
    this.condition.tagify = toggleVisibilityTagify(this.condition.tagify, ['hat', 'alle'].includes(currentOperator?.value));

    this.operator = init(
      this.operator,
      id + '-operator',
      currentOperator,
      (value) => {
        const last = currentOperator?.value;
        currentOperator = parse(value)?.shift() || currentOperator;
        if (currentOperator?.value === last) return;

        this.condition.tagify = toggleVisibilityTagify(this.condition.tagify, ['hat', 'alle'].includes(currentOperator?.value));

        mark.setAttribute('data-operator', currentOperator?.value);
        mark = ElseConditionTool.update(mark);
      },
      operators,
      true,
    );

    this.path = init(
      this.path,
      id + '-path',
      currentPath,
      (value) => {
        const last = currentPath?.value;
        currentPath = parse(value)?.shift() || currentPath;
        if (currentPath?.value === last) return;

        operators = defaults[currentPath.art];
        if (!operators.map(({ value }) => value).includes(currentOperator.value)) currentOperator = operators[0];
        this.operator = show(this.operator, currentOperator, operators);

        conditions = currentPath.optionen || [];
        if (!currentConditions?.map(({ value }) => value).every((c) => conditions.map(({ value }) => value).includes(c))) currentConditions = [];
        this.condition = show(this.condition, currentConditions, conditions);
        this.condition.tagify = toggleVisibilityTagify(this.condition.tagify, ['hat', 'alle'].includes(currentOperator?.value));

        mark.setAttribute('data-path', currentPath?.value);
        mark.setAttribute('data-operator', currentOperator?.value);
        mark.setAttribute('data-condition', currentConditions.map(({ value }) => value).join(','));
        mark = ElseConditionTool.update(mark);
      },
      whitelist,
      true,
    );

    this.frame = toggleVisibility(this.frame, true);
  }

  hideActions() {
    [this.path, this.operator, this.condition, this.inverse] = [this.path, this.operator, this.condition].map(destroy);
    this.frame = toggleVisibility(this.frame, false);
  }

  clear() {
    this.hideActions();
  }
}

export class ElseTool {
  public static isInline = true;
  public static title = 'Sonst';
  public static shortcut = 'CMD+E';
  static delimiters = {
    start: '{% ',
    content: 'else',
    end: ' %}',
  };

  static tag = 'MARK';
  static class = 'fakt-condition-else';

  api: EditorJS.API;
  _state: boolean;

  button: HTMLButtonElement;

  constructor({ api, config }) {
    this.api = api;
    this._state = false;
  }

  static get sanitize() {
    return {
      mark: (el: HTMLElement) => ({
        class: el.className,
        style: el.style.cssText,
        'data-id': el.getAttribute('data-id'),
        'data-type': el.getAttribute('data-type'),
      }),
    };
  }

  checkState() {
    this.state = !!this.api.selection.findParentTag(ElseTool.tag, ElseTool.class);
    this.button = toggleVisibility(this.button, false);
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  static create(id?: string) {
    const mark = document.createElement(this.tag);
    mark.classList.add(this.class);
    mark.contentEditable = 'false';
    mark.style.cssText = 'background: #FCAEAE; ' + cssStyle;
    mark.setAttribute('data-id', id || uuidv4());
    mark.setAttribute('data-type', 'else');
    mark.id = mark.getAttribute('data-id');
    mark.innerText = this.delimiters.start + this.delimiters.content + this.delimiters.end;
    return mark;
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML =
      '<svg width="20" height="18" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" /><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z" /></svg>';
    this.button.classList.add(this.api.styles.inlineToolButton);
    return this.button;
  }

  surround(range) {
    if (this.state) this.unwrap(range);
    else this.wrap(range);
  }

  wrap(range) {
    const mark = ElseTool.create();
    range.insertNode(mark);
  }

  unwrap(range) {
    const mark = this.api.selection.findParentTag(ElseTool.tag, ElseTool.class);
    mark?.remove();
  }

  renderActions() {
    return document.createElement('div');
  }

  showActions(mark) {}

  hideActions() {}

  clear() {
    this.hideActions();
  }
}

export class EndTool {
  public static isInline = true;
  public static title = 'Ende';
  public static shortcut = 'CMD+E';
  static delimiters = {
    start: '{% ',
    content: 'endif',
    end: ' %}',
  };

  static tag = 'MARK';
  static class = 'fakt-condition-end';

  api: EditorJS.API;
  _state: boolean;

  button: HTMLButtonElement;

  constructor({ api, config }) {
    this.api = api;
    this._state = false;
  }

  static get sanitize() {
    return {
      mark: (el: HTMLElement) => ({
        class: el.className,
        style: el.style.cssText,
        'data-id': el.getAttribute('data-id'),
        'data-type': el.getAttribute('data-type'),
      }),
    };
  }

  static create(id?: string) {
    const mark = document.createElement(this.tag);
    mark.classList.add(this.class);
    mark.contentEditable = 'false';
    mark.style.cssText = 'background: #FCAEAE; ' + cssStyle;
    mark.setAttribute('data-id', id || uuidv4());
    mark.setAttribute('data-type', 'end');
    mark.id = mark.getAttribute('data-id');
    mark.innerText = this.delimiters.start + this.delimiters.content + this.delimiters.end;
    return mark;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  checkState() {
    this.state = !!this.api.selection.findParentTag(EndTool.tag, EndTool.class);
    this.button = toggleVisibility(this.button, false);
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML =
      '<svg width="20" height="18" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" /><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z" /></svg>';
    this.button.classList.add(this.api.styles.inlineToolButton);
    return this.button;
  }

  surround(range) {
    if (this.state) this.unwrap(range);
    else this.wrap(range);
  }

  wrap(range) {
    const mark = ElseTool.create();
    range.insertNode(mark);
  }

  unwrap(range) {
    const me = this.api.selection.findParentTag(EndTool.tag, EndTool.class);
    const condition = me.parentElement.querySelector(`${ConditionTool.tag}[id*='${me.id}'].${ConditionTool.class}`);
    const elif = me.parentElement.querySelectorAll(`${ElseConditionTool.tag}[id*='${me.id}'].${ElseConditionTool.class}`);
    const el = me.parentElement.querySelector(`${ElseTool.tag}[id*='${me.id}'].${ElseTool.class}`);
    if (me) me.remove();
    if (condition) condition.remove();
    if (elif.length) elif.forEach((elif) => elif.remove());
    if (el) el.remove();
  }

  renderActions() {
    return document.createElement('div');
  }

  showActions(mark) {}

  hideActions() {}

  clear() {
    this.hideActions();
  }
}

export const ConditionClasses = [ConditionTool.class, ElseConditionTool.class, ElseTool.class, EndTool.class];
