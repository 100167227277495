import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteDateiArtCommandServiceModule } from '..';
import { DeleteDateiArtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteDateiArtCommandServiceModule
  ],
  declarations: [DeleteDateiArtCommandComponent],
  exports: [DeleteDateiArtCommandComponent],

})
export class DeleteDateiArtCommandComponentModule { }
