import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { LIB_HttpsModule } from '../';

import { AddressInputComponent, AtlasMapComponent } from './components';
import { AddressPipe } from './pipes';
import { AtlasService } from './services';

@NgModule({
  imports: [
    CommonModule,
    LIB_HttpsModule,
    NzSpinModule,
    ReactiveFormsModule,
    NzGridModule,
    NzAutocompleteModule,
    FormsModule,
    NzFormModule,
    NzInputModule,
    NzCollapseModule,
    NzTypographyModule,
    NzIconModule,
    NzButtonModule,
  ],
  declarations: [AddressInputComponent, AtlasMapComponent, AddressPipe],
  exports: [AddressInputComponent, AtlasMapComponent, AddressPipe],
})
export class LIB_GeoModule {
  static forRoot(): ModuleWithProviders<LIB_GeoModule> {
    return {
      ngModule: LIB_GeoModule,
      providers: [AtlasService],
    };
  }
}
