import { Component } from '@angular/core';
import { UmkreissucheService } from '../service';

@Component({
  selector: 'fa-kt-saved-umkreissuche-filters',
  templateUrl: './saved-umkreissuche-filters.component.html',
  styleUrls: ['./saved-umkreissuche-filters.component.css'],
})
export class SavedUmkreissucheFiltersComponent {
  constructor(public umkreissuche: UmkreissucheService) {}
}
