import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteErforderlichesDokumentCommandServiceModule } from '..';
import { DeleteErforderlichesDokumentCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteErforderlichesDokumentCommandServiceModule
  ],
  declarations: [DeleteErforderlichesDokumentCommandComponent],
  exports: [DeleteErforderlichesDokumentCommandComponent],

})
export class DeleteErforderlichesDokumentCommandComponentModule { }
