import { NgModule } from '@angular/core';
import {
  ZeitArtsFilterComponentModule, ZeitArtsListComponentModule, ZeitArtsSorterComponentModule
} from '.';

@NgModule({
  imports: [
    ZeitArtsFilterComponentModule,
    ZeitArtsSorterComponentModule,
    ZeitArtsListComponentModule
  ],
  exports: [
    ZeitArtsFilterComponentModule,
    ZeitArtsSorterComponentModule,
    ZeitArtsListComponentModule
  ],
})
export class ZeitArtsQueryModule { }