import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import clone from 'lodash/clone';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ISitemap, SITEMAP } from '../../../common';

interface IPageAccess {
  label: string;
  value: string;
  checked: boolean;
}

interface IContextAccess {
  title: string;
  checked: boolean;
  indeterminate: boolean;
  pages: IPageAccess[];
  settings: IPageAccess[];
}

@Component({
  selector: 'pbc-page-authenticator',
  templateUrl: './page-authenticator.component.html',
  styleUrls: ['./page-authenticator.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PageAuthenticatorComponent,
    },
  ],
})
export class PageAuthenticatorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private readonly subscriptions: Subscription[] = [];

  onChange = (value: string[]) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  public $pages = new BehaviorSubject<IContextAccess[]>([]);
  public filtered$ = new BehaviorSubject<IContextAccess[]>([]);
  readonly search$ = new BehaviorSubject<string>('');

  @Input() set authorizedRoutes(authorizedRoutes: string[]) {
    this.checkPage(authorizedRoutes || []);
  }
  @Output() authorizedRoutesChange = new EventEmitter<any>();

  constructor(@Inject(SITEMAP) private sitemap: ISitemap) {
    this.$pages.next(
      Object.values(this.sitemap)
        .filter((context) => context && Object.keys(context.Pages).length + Object.keys(context.Settings).length > 0)
        .map((context) => {
          return {
            title: context.emoji + ' ' + context.title,
            checked: false,
            indeterminate: false,
            pages: Object.entries(context.Pages).map(([name, page]) => ({
              label: page.emoji + ' ' + page.title,
              value: page.url.join('/').toLowerCase().substr(1),
              checked: false,
            })),
            settings: Object.entries(context.Settings).map(([name, setting]) => ({
              label: setting.emoji + ' ' + setting.title,
              value: setting.url.join('/').toLowerCase().substr(1),
              checked: false,
            })),
          };
        }),
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      ...[
        this.$pages.subscribe((pages) => {
          const authorizedRoutes = ([] as IPageAccess[]).concat(...pages.map((context) => [...context.pages, ...context.settings].filter((p) => p.checked))).map((p) => p.value);
          this.authorizedRoutesChange.emit(authorizedRoutes);
          this.onChange(authorizedRoutes);
        }),
        combineLatest([this.$pages, this.search$.pipe(debounceTime(200), distinctUntilChanged())]).subscribe(([settings, search]) => {
          if (!search || search.length === 0) {
            this.filtered$.next(settings);
          } else {
            search = search.toLowerCase();
            this.filtered$.next(
              settings
                .slice()
                .map((panel) => clone(panel))
                .filter((panel) => {
                  if (panel.title.toLowerCase().indexOf(search) < 0) {
                    panel.pages = panel.pages.filter((page) => (page.label + page.value).toLowerCase().indexOf(search) >= 0);
                    panel.settings = panel.settings.filter((page) => (page.label + page.value).toLowerCase().indexOf(search) >= 0);
                  }
                  return panel;
                })
                .filter((panel) => panel.pages.length + panel.settings.length > 0),
            );
          }
        }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  updateContext(context: IContextAccess, $event: boolean): void {
    context.pages = context.pages.map((item) => ({
      ...item,
      checked: $event,
    }));
    context.settings = context.settings.map((item) => ({
      ...item,
      checked: $event,
    }));
    context.checked = $event;
    context.indeterminate = false;
    this.$pages.next(this.$pages.getValue().map((ctx) => (ctx.title !== context.title ? ctx : context)));
    this.onTouched();
  }

  updatePage(context: IContextAccess, page: IPageAccess, $event: boolean): void {
    page.checked = $event;
    context.pages = context.pages.map((p) => (p.value !== page.value ? p : page));
    context.settings = context.settings.map((p) => (p.value !== page.value ? p : page));
    const pages: IPageAccess[] = [...context.pages, ...context.settings];
    if (pages.every((item) => !item.checked)) {
      context.checked = false;
      context.indeterminate = false;
    } else if (pages.every((item) => item.checked)) {
      context.checked = true;
      context.indeterminate = false;
    } else {
      context.indeterminate = true;
    }
    this.$pages.next(this.$pages.getValue().map((ctx) => (ctx.title !== context.title ? ctx : context)));
    this.onTouched();
  }

  private checkPage(authorizedRoutes: string[]) {
    const contexts = this.$pages.getValue().map((ctx) => ({
      ...ctx,
      pages: ctx.pages.map((p) => ({ ...p, checked: authorizedRoutes.includes(p.value) }) as IPageAccess),
      settings: ctx.settings.map((p) => ({ ...p, checked: authorizedRoutes.includes(p.value) })),
    }));
    this.$pages.next(
      contexts.map((context) => {
        const pages: IPageAccess[] = [...context.pages, ...context.settings];
        if (pages.every((item) => !item.checked)) {
          context.checked = false;
          context.indeterminate = false;
        } else if (pages.every((item) => item.checked)) {
          context.checked = true;
          context.indeterminate = false;
        } else {
          context.indeterminate = true;
        }
        return context;
      }),
    );
  }

  writeValue(value: string[]) {
    this.authorizedRoutes = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
