import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IFormularePageResolverResult } from '.';
import { FormulareService } from '../../querys';

@Component({
  selector: 'fa-kt-formulare-page',
  templateUrl: './formulare.page.html',
  styleUrls: ['./formulare.page.css'],
})
export class FormularePage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public _felder: ISitemapPage;
  public _feld: ISitemapPage;
  public _formular: ISitemapPage;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public formulare: FormulareService,
  ) {
    this.page = this.sitemap.FORMULARE.Pages.FORMULARE;
    this._felder = sitemap.FELDER.Pages.FELDER;
    this._feld = sitemap.FELDER.Pages.FELD;
    this._formular = sitemap.FORMULARE.Pages.FORMULAR;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IFormularePageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setFormulare(id: string) {
    /* << set-formulare    */
    await this.navigateToFormular({ id });
    /*    set-formulare >> */
  }

  public async navigateToFelder(queryParams: Params = {}) {
    await this.router.navigate(this._felder.url, { queryParams });
  }
  public async navigateToFeld(queryParams: Params = {}) {
    await this.router.navigate(this._feld.url, { queryParams });
  }
  public async navigateToFormular(queryParams: Params = {}) {
    await this.router.navigate(this._formular.url, { queryParams });
  }

  back() {
    window.history.back();
  }
}
