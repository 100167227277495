import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { BehaviorSubject } from 'rxjs';

import { BaseComponent } from 'pbc.angular';

import { IObjekt } from 'fa-kt.types';
import { ObjekteService } from '../../..';

@Component({
  selector: 'fa-kt-objekte',
  templateUrl: './objekte.component.html',
  styleUrls: ['./objekte.component.css'],
})
export class ObjekteComponent extends BaseComponent implements OnInit, OnDestroy {
  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() objekt: IObjekt | undefined;

  constructor(
    @Inject(NZ_MODAL_DATA) objekt: IObjekt | undefined,
    public objekte: ObjekteService,
  ) {
    super();
    this.openObjekt(objekt);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.objekte.request({}).catch(console.error);
  }

  public async setObjekte(id: string) {
    const objekte = this.objekte.result$.getValue();
    const objekt = objekte?.objekte.find((o) => o.objekt.id === id);
    this.openObjekt(objekt?.objekt);
  }

  public openObjekt(objekt?: IObjekt) {
    this.objekt = objekt;
  }

  emit(objekt?: IObjekt) {
    this.modalRef.destroy(objekt);
  }
}
