<nz-row *ngIf="($value | async)?.id" [style.marginTop]="margin ? '24px' : '0px'" [style.marginBottom]="margin ? '8px' : '0px'">
  <nz-col nzSpan="24" style="width: 100%; text-align: center">
    <span nz-typography nzType="secondary" (click)="extended = !extended">
      <span
        nz-icon
        [nzType]="extended ? 'plus' : 'edit'"
        nzTheme="outline"
        style="margin-right: 8px; cursor: pointer"
        nz-tooltip
        [nzTooltipTitle]="tooltip"
        [nzTooltipColor]="'white'"
        nzTooltipPlacement="left"
      ></span>
      <ng-template #tooltip>
        <mark nz-typography nzCopyable [nzCopyText]="debug"><code>debug</code></mark>
        <span nz-typography>{{ extended ? 'Erstellt' : 'Zuletzt aktualisiert' }}</span>
      </ng-template>
      <ng-container *ngIf="!extended && ($updator | async)?.value">
        {{ ($value | async)?._updatedAt | date: 'EEEE, dd.MM.yyyy HH:mm' : 'de-DE' }}
        <nz-avatar
          #update
          style="margin-left: 8px"
          *ngIf="($updator | async)?.initialen"
          [nzShape]="'square'"
          [nzSize]="'small'"
          [nzText]="($updator | async)?.initialen"
          [nzSrc]="($updator | async)?.avatar"
          nz-tooltip
          [nzTooltipTitle]="($updator | async)?.label"
        ></nz-avatar>
      </ng-container>
      <ng-container *ngIf="extended && ($creator | async)?.value">
        {{ ($value | async)?._createdAt | date: 'EEEE, dd.MM.yyyy HH:mm' : 'de-DE' }}
        <nz-avatar
          style="margin-left: 8px"
          *ngIf="($creator | async)?.initialen"
          [nzShape]="'square'"
          [nzSize]="'small'"
          [nzText]="($creator | async)?.initialen"
          [nzSrc]="($creator | async)?.avatar"
          nz-tooltip
          [nzTooltipTitle]="($creator | async)?.label"
        ></nz-avatar>
      </ng-container>
    </span>
  </nz-col>
</nz-row>
