import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostHerausgeberCommandServiceModule } from '..';
import { PostHerausgeberCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostHerausgeberCommandServiceModule
  ],
  declarations: [PostHerausgeberCommandComponent],
  exports: [PostHerausgeberCommandComponent],

})
export class PostHerausgeberCommandComponentModule { }
