<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="dashboard.search$.next($event)" [search]="dashboard.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-dashboard-filter [style.width]="'100%'"></fa-kt-dashboard-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-dashboard-sorter [style.width]="'100%'"></fa-kt-dashboard-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-dashboard-list [style.width]="'100%'" (select)="setDashboard($event)" [loading]="$loading | async"></fa-kt-dashboard-list>



  <fa-kt-erinnerungen [style.width]="'100%'" ></fa-kt-erinnerungen>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="kunde" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="projekt" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="tour" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="durchgang" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <nz-tabset style="width: 100%" nzCentered>
        <nz-tab [nzTitle]="ErinnerungenTitle">
          <ng-template #ErinnerungenTitle>
            <h2><span nz-icon nzType="bell" nzTheme="outline"></span> {{($erinnerungen | async)?.length}} Erinnerungen</h2>
          </ng-template>
          <nz-col nzSpan="24">
            <nz-space>
              <label *nzSpaceItem nz-checkbox [ngModel]="$mine | async" (ngModelChange)="$mine.next($event)">
                <i nz-icon nzType="user" nzTheme="outline"></i>
                Nur Meine
              </label>
            </nz-space>
          </nz-col>
          <nz-col nzSpan="24">
            <nz-list *ngIf="dashboard.result$ | async" nzSize="large" [nzLoading]="$loading | async">
              <nz-list-item *ngFor="let erinnerung of $erinnerungen | async">
                <nz-row style="width: 100%">
                  <nz-col nzSpan="20" (click)="openErinnerung(erinnerung)">
                    <nz-comment
                      [nzAuthor]="erinnerung.verfasser | type:(dashboard.erinnerungenVerfasser | async)"
                      [nzDatetime]="erinnerung.datetime + ' (' + (erinnerung._createdAt | date:'dd.MM.yyyy')+ ')'"
                    >
                      <nz-avatar
                        nz-comment-avatar
                        [nzText]="getMitarbeiterText(dashboard.getErinnerungenVerfasser(erinnerung.verfasser)?.label)"
                        [nzSrc]="erinnerung.verfasser | type:(dashboard.erinnerungenVerfasser | async):'avatar'"
                      ></nz-avatar>
                      <nz-comment-content>
                        <h3 *ngIf="erinnerung.projektName">📖 {{ erinnerung.projektName }}</h3>
                        <h3 *ngIf="erinnerung.kundeName">👋 {{ erinnerung.kundeName }}</h3>
                        <p>{{ erinnerung.kommentar }}</p>
                      </nz-comment-content>
                      <nz-comment-action>
                        <nz-date-picker [ngModel]="erinnerung.am" nzDisabled nzFormat="dd.MM.yyyy"></nz-date-picker>
                      </nz-comment-action>
                      <nz-comment-action>
                        <pbc-user-selection [users]="erinnerung.mitarbeiter" [disabled]="true"></pbc-user-selection>
                      </nz-comment-action>
                    </nz-comment>
                  </nz-col>
                  <nz-col nzSpan="2">
                    <button
                      nz-button
                      nzType="default"
                      type="button"
                      nzDanger
                      style="margin-top: 44px"
                      (click)="abmelden(erinnerung)"
                      [nzLoading]="$loading | async"
                      nz-tooltip
                      [nzTooltipTitle]="AbmeldenTooltip"
                    >
                      <i nz-icon nzType="user-delete" nzTheme="outline"></i>
                      <ng-template #AbmeldenTooltip> Mich aus der Erinnerung entfernen </ng-template>
                    </button>
                  </nz-col>
                  <nz-col nzSpan="2" (click)="openErinnerung(erinnerung)">
                    <button nz-button nzType="primary" nzShape="circle" style="margin-top: 44px">
                      <i nz-icon nzType="right" nzTheme="outline"></i>
                    </button>
                  </nz-col>
                </nz-row>
              </nz-list-item>
            </nz-list>
          </nz-col>
        </nz-tab>
        <nz-tab [nzTitle]="TourenTitle">
          <ng-template #TourenTitle>
            <h2><span nz-icon nzType="car" nzTheme="outline"></span> {{(((dashboard.result$ | async)?.tour)?.length || 0)}} Touren</h2>
          </ng-template>
          <nz-col nzSpan="24">
            <nz-list *ngIf="dashboard.result$ | async" nzSize="large" [nzLoading]="$loading | async">
              <nz-list-item *ngFor="let tour of (dashboard.result$ | async)?.tour" (click)="openTour(tour)">
                <nz-col nzSpan="22">
                  <nz-list-item-meta>
                    <nz-list-item-meta-title>
                      <a (click)="openTour(tour)">
                        <strong> {{ tour?.am | date:'dd.MM.yyyy HH:mm' }} </strong>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span [style.color]="tour?.tourStatus | type:(dashboard.tourTourStatus | async):'color'">
                          <i nz-icon [nzType]="tour?.tourStatus | type:(dashboard.tourTourStatus | async):'icon'" nzTheme="outline"></i>
                          {{ tour?.tourStatus | type:(dashboard.tourTourStatus | async) }}
                        </span>
                      </a>
                    </nz-list-item-meta-title>
                    <ul nz-list-item-actions>
                      <nz-list-item-action>
                        <button nz-button nzType="primary" nzShape="circle">
                          <i nz-icon nzType="right" nzTheme="outline"></i>
                        </button>
                      </nz-list-item-action>
                    </ul>
                  </nz-list-item-meta>
                  <ul nz-list-item-actions></ul>
                </nz-col>
                <nz-col nzSpan="2">
                  <button nz-button nzType="primary" nzShape="circle" (click)="openTour(tour)" style="margin-top: 44px">
                    <i nz-icon nzType="right" nzTheme="outline"></i>
                  </button>
                </nz-col>
              </nz-list-item>
            </nz-list>
          </nz-col>
        </nz-tab>
        <nz-tab [nzTitle]="NumbersTitle">
          <ng-template #NumbersTitle>
            <h2><span nz-icon nzType="rise" nzTheme="outline"></span> Zahlen</h2>
          </ng-template>
          <nz-spin [nzSpinning]="$loading | async" style="min-height: 1200px">
            <nz-row>
              <nz-col nzSpan="20">
                <pbc-select-value
                  *ngIf="auth.$isFinanzen | async"
                  [(value)]="selectedMitarbeiter"
                  (valueChange)="selectMitarbeiter($event)"
                  [shapes]="meta.select$ | async"
                  [required]="true"
                ></pbc-select-value>
              </nz-col>
              <nz-col nzSpan="4">
                <nz-radio-group [ngModel]="guv$ | async" (ngModelChange)="guv$.next($event)" style="float: right">
                  <label nz-radio-button [nzValue]="false"><span nz-icon nzType="euro" nzTheme="outline"></span> Anteile</label>
                  <label nz-radio-button [nzValue]="true"><span nz-icon nzType="stock" nzTheme="outline"></span> Erfolg</label>
                </nz-radio-group>
              </nz-col>
              <nz-col nzSpan="24">
                <br />
                <br />
                <br />
                <br />
              </nz-col>
              <nz-col nzSpan="24">
                <ngx-charts-bar-vertical-stacked
                  [customColors]="mainCustomColors"
                  [results]="overview$ | async"
                  [gradient]="gradient"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel"
                  [xAxisLabel]="xAxisLabel"
                  [yAxisLabel]="yAxisLabel"
                  [animations]="animations"
                  (select)="onSelect($event)"
                  (activate)="onActivate($event)"
                >
                  <ng-template #tooltipTemplate let-model="model">
                    <h4 nz-title style="color: white">{{model.value | currency:'':'':'1.2-2' }} € in {{model.name}}</h4>
                  </ng-template>
                </ngx-charts-bar-vertical-stacked>
              </nz-col>
              <nz-col *ngIf="(month$ | async)?.length > 0" nzSpan="24">
                <br />
                <br />
                <br />
                <br />
              </nz-col>
              <nz-col *ngIf="(month$ | async)?.length > 0" nzSpan="24" style="height: 30px">
                <nz-space style="float: right">
                  <ng-container *ngIf="selected$ | async">
                    <button *nzSpaceItem nz-button nzType="dashed" nzShape="round" (click)="clear()"><span nz-icon nzType="close" nzTheme="outline"></span> Schließen</button>
                  </ng-container>
                  <button *nzSpaceItem nz-button nzType="primary" nzShape="round" (click)="navigateToProjekt({ id: selected$.getValue() })">
                    Projekt öffnen <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                  </button>
                </nz-space>
              </nz-col>
              <nz-col nzSpan="24" *ngIf="(project$ | async)?.length > 0">
                <br />
                <br />
              </nz-col>
              <nz-col nzSpan="24" *ngIf="(project$ | async)?.length > 0" style="height: 120px">
                <h4 nz-title>Projekt: {{ projektLabel }}</h4>
                <ngx-charts-bar-horizontal-stacked
                  [customColors]="oneCustomColors"
                  [results]="project$ | async"
                  [gradient]="gradient"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel"
                  [xAxisLabel]="xAxisLabel"
                  [yAxisLabel]="yAxisLabel"
                  (select)="clickedZeiten($event)"
                >
                  <ng-template #tooltipTemplate let-model="model">
                    <h4 nz-typography style="color: white">{{model.value | currency:'':'':'1.2-2' }} € {{ model.series === 'Erfolg' ? '' : model.series + ' für ' }}{{ model.name }}</h4>
                  </ng-template>
                </ngx-charts-bar-horizontal-stacked>
              </nz-col>
              <nz-col nzSpan="24" *ngIf="(month$ | async)?.length > 0">
                <br />
                <br />
                <br />
                <br />
              </nz-col>
              <nz-col nzSpan="24" *ngIf="(month$ | async)?.length > 0" style="height: 120px">
                <h4 nz-title>Monat: {{ monthLabel }}</h4>
                <ngx-charts-bar-horizontal-stacked
                  [customColors]="oneCustomColors"
                  [results]="month$ | async"
                  [gradient]="gradient"
                  [xAxis]="showXAxis"
                  [yAxis]="showYAxis"
                  [legend]="showLegend"
                  [showXAxisLabel]="showXAxisLabel"
                  [showYAxisLabel]="showYAxisLabel"
                  [xAxisLabel]="xAxisLabel"
                  [yAxisLabel]="yAxisLabel"
                  (select)="clickedZeiten($event, true)"
                >
                  <ng-template #tooltipTemplate let-model="model">
                    <h4 nz-typography style="color: white">{{model.value | currency:'':'':'1.2-2' }} € {{ model.series === 'Erfolg' ? '' : model.series + ' für ' }}{{ model.name }}</h4>
                  </ng-template>
                </ngx-charts-bar-horizontal-stacked>
              </nz-col>
            </nz-row>
          </nz-spin>
        </nz-tab>
      </nz-tabset>
    </nz-col>

    <!---    page-content >> -->
  </nz-row>
</nz-content>
