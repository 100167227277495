import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IBerichteQuerySorter extends ISorter {
  'bericht.id'?: ISorterExpression;
  'bericht.excel'?: ISorterExpression;
  'bericht.art'?: ISorterExpression;
  'bericht.herausgeber'?: ISorterExpression;
  'bericht.quelle'?: ISorterExpression;
  'bericht.jahr'?: ISorterExpression;
  'bericht.quartal'?: ISorterExpression;
  'bericht.monat'?: ISorterExpression;
  'bericht.kommentar'?: ISorterExpression;
}

export const BerichteQuerySorterConfig: ISorterConfig = [
  SorterConfig('bericht.id', 'MARKT.Models.BERICHT.Propertys.ID.title'), 
  SorterConfig('bericht.excel', 'MARKT.Models.BERICHT.Propertys.EXCEL.title'), 
  SorterConfig('bericht.art', 'MARKT.Models.BERICHT.Propertys.ART.title'), 
  SorterConfig('bericht.herausgeber', 'MARKT.Models.BERICHT.Propertys.HERAUSGEBER.title'), 
  SorterConfig('bericht.quelle', 'MARKT.Models.BERICHT.Propertys.QUELLE.title'), 
  SorterConfig('bericht.jahr', 'MARKT.Models.BERICHT.Propertys.JAHR.title'), 
  SorterConfig('bericht.quartal', 'MARKT.Models.BERICHT.Propertys.QUARTAL.title'), 
  SorterConfig('bericht.monat', 'MARKT.Models.BERICHT.Propertys.MONAT.title'), 
  SorterConfig('bericht.kommentar', 'MARKT.Models.BERICHT.Propertys.KOMMENTAR.title'), 
];
