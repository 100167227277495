<!--



-->
<!--- << custom    -->
<nz-ribbon [nzText]="count" style="padding-right: 18px">
  <ng-content>

  </ng-content>
</nz-ribbon>
<!---    custom >> -->
