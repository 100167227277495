import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektStatiServiceModule } from '..';
import { ProjektStatiFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektStatiServiceModule
  ],
  declarations: [ProjektStatiFilterComponent],
  exports: [ProjektStatiFilterComponent],

})
export class ProjektStatiFilterComponentModule { }
