import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IKundenQuerySorter extends ISorter {
  'kunde.id'?: ISorterExpression;
  'kunde.name'?: ISorterExpression;
  'kunde.initialen'?: ISorterExpression;
  'kunde.kundenGruppe'?: ISorterExpression;
  'kunde.kundenArt'?: ISorterExpression;
  'kunde.addresse'?: ISorterExpression;
  'kunde.website'?: ISorterExpression;
  'kunde.outlookKontakte'?: ISorterExpression;
  'kunde.kontaktNotizen'?: ISorterExpression;
  'kunde.anforderungenNotizen'?: ISorterExpression;
  'kunde.rechnungNotizen'?: ISorterExpression;
  'kunde.fahrtkostenAbrechnung'?: ISorterExpression;
  'kunde.steuerId'?: ISorterExpression;
  'kunde.fahrtkostenAbRadius'?: ISorterExpression;
  'kunde.fahrtkostenProKm'?: ISorterExpression;
  'kunde.fahrtkostenProStunde'?: ISorterExpression;
  'kunde.skonto'?: ISorterExpression;
  'kunde.zahlungsziel'?: ISorterExpression;
  'kunde.tageOhneSkonto'?: ISorterExpression;
  'kunde.aktiv'?: ISorterExpression;
  'kunde.dateipfad'?: ISorterExpression;
  'kunde.loraName'?: ISorterExpression;
  'kunde.unterlagenEmail'?: ISorterExpression;
}

export const KundenQuerySorterConfig: ISorterConfig = [
  SorterConfig('kunde.id', 'KUNDEN.Models.KUNDE.Propertys.ID.title'), 
  SorterConfig('kunde.name', 'KUNDEN.Models.KUNDE.Propertys.NAME.title'), 
  SorterConfig('kunde.initialen', 'KUNDEN.Models.KUNDE.Propertys.INITIALEN.title'), 
  SorterConfig('kunde.kundenGruppe', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_GRUPPE.title'), 
  SorterConfig('kunde.kundenArt', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.title'), 
  SorterConfig('kunde.addresse', 'KUNDEN.Models.KUNDE.Propertys.ADDRESSE.title'), 
  SorterConfig('kunde.website', 'KUNDEN.Models.KUNDE.Propertys.WEBSITE.title'), 
  SorterConfig('kunde.outlookKontakte', 'KUNDEN.Models.KUNDE.Propertys.OUTLOOK_KONTAKTE.title'), 
  SorterConfig('kunde.kontaktNotizen', 'KUNDEN.Models.KUNDE.Propertys.KONTAKT_NOTIZEN.title'), 
  SorterConfig('kunde.anforderungenNotizen', 'KUNDEN.Models.KUNDE.Propertys.ANFORDERUNGEN_NOTIZEN.title'), 
  SorterConfig('kunde.rechnungNotizen', 'KUNDEN.Models.KUNDE.Propertys.RECHNUNG_NOTIZEN.title'), 
  SorterConfig('kunde.fahrtkostenAbrechnung', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_ABRECHNUNG.title'), 
  SorterConfig('kunde.steuerId', 'KUNDEN.Models.KUNDE.Propertys.STEUER_ID.title'), 
  SorterConfig('kunde.fahrtkostenAbRadius', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.title'), 
  SorterConfig('kunde.fahrtkostenProKm', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_KM.title'), 
  SorterConfig('kunde.fahrtkostenProStunde', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.title'), 
  SorterConfig('kunde.skonto', 'KUNDEN.Models.KUNDE.Propertys.SKONTO.title'), 
  SorterConfig('kunde.zahlungsziel', 'KUNDEN.Models.KUNDE.Propertys.ZAHLUNGSZIEL.title'), 
  SorterConfig('kunde.tageOhneSkonto', 'KUNDEN.Models.KUNDE.Propertys.TAGE_OHNE_SKONTO.title'), 
  SorterConfig('kunde.aktiv', 'KUNDEN.Models.KUNDE.Propertys.AKTIV.title'), 
  SorterConfig('kunde.dateipfad', 'KUNDEN.Models.KUNDE.Propertys.DATEIPFAD.title'), 
  SorterConfig('kunde.loraName', 'KUNDEN.Models.KUNDE.Propertys.LORA_NAME.title'), 
  SorterConfig('kunde.unterlagenEmail', 'KUNDEN.Models.KUNDE.Propertys.UNTERLAGEN_EMAIL.title'), 
];
