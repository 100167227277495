import { IBesichtigung, IMitarbeiter, IProjekteResponseRow, ITour, TimeConflict } from 'fa-kt.types';
import moment from 'moment-timezone';

export function calculateProjektGutachtenVerteilung(
  row: IProjekteResponseRow,
  mitarbeiterInnen: IMitarbeiter[],
  { pruefung, besichtigung, preview }: { pruefung: number; besichtigung: number; preview: boolean } = { pruefung: 0.1, besichtigung: 0.1, preview: false },
): { [mitarbeiterIn: string]: { ausGutachten: number; ausBesichtigung: number; ausPrüfung: number; verhandeltedHonorar?: boolean } } {
  row.projekt.kostenNetto = row.rechnungen?.reduce((n, { nettoSumme }) => n + (isNaN(nettoSumme) ? 0 : nettoSumme), 0) || 0;

  let verhandeltedHonorar: boolean = false;
  if (preview && (!row.projekt.kostenNetto || row.projekt.kostenNetto === 0) && row.projekt.verhandeltesHonorar && row.projekt.verhandeltesHonorar !== 0) {
    verhandeltedHonorar = true;
    row.projekt.kostenNetto = row.projekt.verhandeltesHonorar * (1 - (row.projekt.nachlass && row.projekt.nachlass !== 0 ? row.projekt.nachlass! / 100 : 0));
  }
  const debug = false; // row.projekt?.id?.toString() === '8249';

  const hasBesichtigung = !row.projekt.keineBesichtigungErforderlich;
  const besichtigungsSatz = hasBesichtigung ? besichtigung : 0;
  const preufungsSatz = hasBesichtigung ? pruefung : pruefung + besichtigung / 2;
  const gutachtenerstellungsSatz = 1 - preufungsSatz - besichtigungsSatz;

  const gutachtenZeiten = row.zeiten?.filter((z) => z.zeitUnterart?.toString() === '56') || [];
  const kostenFuerGutachtenNachZeiten = gutachtenZeiten.reduce((n, { kosten }) => n + (!kosten || isNaN(kosten) ? 0 : kosten), 0) || 0;

  const besichtigungenZeiten = row.zeiten?.filter((z) => z.zeitUnterart?.toString() === '2') || [];
  const kostenFuerBesichtigungenNachZeiten = besichtigungenZeiten.reduce((n, { kosten }) => n + (!kosten || isNaN(kosten) ? 0 : kosten), 0) || 0;

  const pruefungZeiten = row.zeiten?.filter((z) => z.zeitUnterart?.toString() === '57') || [];
  const kostenFuerPruefungNachZeiten = pruefungZeiten.reduce((n, { kosten }) => n + (!kosten || isNaN(kosten) ? 0 : kosten), 0) || 0;

  if (debug)
    console.log(
      row.projekt.kostenNetto,
      { hasBesichtigung, besichtigungsSatz, preufungsSatz, gutachtenerstellungsSatz },
      { kostenFuerGutachtenNachZeiten, kostenFuerBesichtigungenNachZeiten, kostenFuerPruefungNachZeiten },
    );

  const result: { [mitarbeiterIn: string]: { ausGutachten: number; ausBesichtigung: number; ausPrüfung: number; verhandeltedHonorar?: boolean } } = {};

  mitarbeiterInnen.forEach((ma) => {
    let pruefungAnteil;
    if (row.projekt.pruefungAnteile?.filter(({ anteil }) => !!anteil).length) {
      pruefungAnteil = (row.projekt.pruefungAnteile?.find((ga) => ga.mitarbeiterIn === ma.id)?.anteil || 0) / 100;
      if (debug && pruefungAnteil) console.log(ma.email, 'pruefung.anteil');
    } else if (kostenFuerPruefungNachZeiten === 0) {
      pruefungAnteil = row.projekt.pruefung === ma.id! ? 1 : 0;
      if (debug && pruefungAnteil) console.log(ma.email, 'prüfung.voll');
    } else {
      const kostenFuerPruefungNachZeitenProMA = pruefungZeiten?.filter((z) => z.mitarbeiter === ma.id).reduce((n, { kosten }) => n + (!kosten || isNaN(kosten) ? 0 : kosten), 0) || 0;
      pruefungAnteil = kostenFuerPruefungNachZeitenProMA / kostenFuerPruefungNachZeiten;
      if (debug && pruefungAnteil) console.log(ma.email, 'prüfung.zeit', kostenFuerPruefungNachZeitenProMA, '/', kostenFuerPruefungNachZeiten, '=', pruefungAnteil);
    }
    const ausPrüfung = (row.projekt.kostenNetto || 0) * preufungsSatz * pruefungAnteil;

    let besichtigungenAnteil;
    if (row.projekt.besichtigungenAnteile?.filter(({ anteil }) => !!anteil).length) {
      besichtigungenAnteil = (row.projekt.besichtigungenAnteile?.find((ga) => ga.mitarbeiterIn === ma.id)?.anteil || 0) / 100;
      if (debug && besichtigungenAnteil) console.log(ma.email, 'besichtigungen.anteil');
    } else if (kostenFuerBesichtigungenNachZeiten === 0) {
      besichtigungenAnteil = row.projekt.besichtigungen?.includes(ma.id!) ? 1 / (row.projekt.besichtigungen?.length || 0) : 0;
      if (debug && besichtigungenAnteil) console.log(ma.email, 'besichtigungen.voll', 1, '/', row.projekt.besichtigungen?.length, '=', besichtigungenAnteil);
    } else {
      const kostenFuerBesichtigungenNachZeitenProMA = besichtigungenZeiten?.filter((z) => z.mitarbeiter === ma.id).reduce((n, { kosten }) => n + (!kosten || isNaN(kosten) ? 0 : kosten), 0) || 0;
      besichtigungenAnteil = kostenFuerBesichtigungenNachZeitenProMA / kostenFuerBesichtigungenNachZeiten;
      if (debug && besichtigungenAnteil) console.log(ma.email, 'besichtigungen.zeit', kostenFuerBesichtigungenNachZeitenProMA, '/', kostenFuerBesichtigungenNachZeiten, '=', besichtigungenAnteil);
    }
    const ausBesichtigung = (row.projekt.kostenNetto || 0) * besichtigungsSatz * besichtigungenAnteil;

    let gutachtenAnteil = 0;
    if (row.projekt.gutachtenAnteile?.filter(({ anteil }) => !!anteil).length) {
      gutachtenAnteil = (row.projekt.gutachtenAnteile?.find((ga) => ga.mitarbeiterIn === ma.id)?.anteil || 0) / 100;
      if (debug && gutachtenAnteil) console.log(ma.email, 'gutachten.anteil');
    } else if (kostenFuerGutachtenNachZeiten === 0) {
      gutachtenAnteil = row.projekt.gutachten?.includes(ma.id!) ? 1 / (row.projekt.gutachten?.length || 0) : 0;
      if (debug && gutachtenAnteil) console.log(ma.email, 'gutachten.voll', 1, '/', row.projekt.gutachten?.length, '=', gutachtenAnteil);
    } else {
      const kostenFuerGutachtenNachZeitenProMA = gutachtenZeiten?.filter((z) => z.mitarbeiter === ma.id).reduce((n, { kosten }) => n + (!kosten || isNaN(kosten) ? 0 : kosten), 0) || 0;
      gutachtenAnteil = kostenFuerGutachtenNachZeitenProMA / kostenFuerGutachtenNachZeiten;
      if (debug && gutachtenAnteil) console.log(ma.email, 'gutachten.zeit', kostenFuerGutachtenNachZeitenProMA, '/', kostenFuerGutachtenNachZeiten, '=', gutachtenAnteil);
    }

    const ausGutachten = (row.projekt?.kostenNetto || 0) * gutachtenerstellungsSatz * gutachtenAnteil;

    result[ma.id!] = { ausGutachten, ausBesichtigung, ausPrüfung, verhandeltedHonorar };

    if (debug && ausGutachten + ausBesichtigung + ausPrüfung > 0)
      console.log(ma.email, ausGutachten + ausBesichtigung + ausPrüfung, { ausGutachten, ausBesichtigung, ausPrüfung, verhandeltedHonorar });
  });
  if (debug) console.log(result);
  return result;
}

export function hasTimeConflict(besichtigung: IBesichtigung, tour?: ITour): TimeConflict {
  const fahrtBis = new Date(besichtigung.fahrtBis!);
  const kommuniziertVon = alignDates(fahrtBis, new Date(besichtigung.kommuniziertVon!));
  const kommuniziertBis = alignDates(fahrtBis, new Date(besichtigung.kommuniziertBis!));
  const bis = alignDates(fahrtBis, new Date(besichtigung.bis!));
  const kommuniziertVonIsBeforeFahrtBis = isBefore(kommuniziertVon, fahrtBis);
  const kommuniziertBisIsBeforeKommuniziertVon = isBefore(kommuniziertBis, kommuniziertVon);
  const bisIsBeforeKommuniziertBis = isBefore(bis, kommuniziertBis);
  const negativerAufenthalt = isBefore(new Date(besichtigung.bis!), new Date(besichtigung.von!));
  const noTourMatch = tour && noDateMatch(new Date(besichtigung.kommuniziertBis!), new Date(tour.am));
  // console.log({ kommuniziertVonIsBeforeFahrtBis, kommuniziertBisIsBeforeKommuniziertVon, bisIsBeforeKommuniziertBis }, { fahrtBis, kommuniziertVon, kommuniziertBis, bis });
  return kommuniziertVonIsBeforeFahrtBis || kommuniziertBisIsBeforeKommuniziertVon || bisIsBeforeKommuniziertBis || negativerAufenthalt || noTourMatch
    ? {
        kommuniziertVonIsBeforeFahrtBis,
        kommuniziertBisIsBeforeKommuniziertVon,
        bisIsBeforeKommuniziertBis,
        negativerAufenthalt,
        noTourMatch,
      }
    : false;
}

export function isBefore(a: Date, b: Date) {
  return moment(a).utc().diff(moment(b).utc(), 'minutes') < 0;
}

export function noDateMatch(a: Date, b: Date) {
  return moment(a).utc().diff(moment(b).utc(), 'days') !== 0;
}

export function getErfolgColor(erfolg: number): string {
  return erfolg > 100 ? 'rgb(255 0 0/' + 100 + '%)' : 'rgb(0 128 0/' + (100 - erfolg) + '%)';
}
export function getErfolg(projekt: { nachStunden?: number; anteil?: number }): number {
  return Math.round(((projekt?.nachStunden || 0) / (projekt?.anteil || 0) || 0) * 100);
}

export function alignDates(original: Date, toChange: Date): Date {
  original = new Date(original);
  return moment(new Date(toChange))
    .set({
      year: original.getFullYear(),
      month: original.getMonth(),
      date: original.getDate(),
    })
    .toDate();
}
