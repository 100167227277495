import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { UmkreissucheServiceModule } from '..';
import { UmkreissucheSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    UmkreissucheServiceModule
  ],
  declarations: [UmkreissucheSorterComponent],
  exports: [UmkreissucheSorterComponent],

})
export class UmkreissucheSorterComponentModule { }
