import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FotoKategorienFilterComponent } from '.';
import { FotoKategorienServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    FotoKategorienServiceModule
  ],
  declarations: [FotoKategorienFilterComponent],
  exports: [FotoKategorienFilterComponent],

})
export class FotoKategorienFilterComponentModule { }
