import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { ITourenQueryFilter, ITourenQueryShapes, ITourenQuerySorter, ITourenRequest, ITourenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class TourenService extends ListQueryService<ITourenRequest, ITourenResponse, ITourenQueryShapes, ITourenQueryFilter, ITourenQuerySorter> {
  static instance: TourenService;

  contentKey: keyof ITourenResponse = 'touren';
  route = 'besichtigungen/touren';
  override dependencies = [
    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'besichtigungen/written-tour-status',
    'besichtigungen/removed-tour-status',
    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/rechnung-erstellt',
    'formulare/durchgang-gestartet',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
  ];

  constructor() {
    super();
    if (!TourenService.instance) TourenService.instance = this;
    return TourenService.instance;
  }

  getBesichtigungenBesichtigungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigungen.besichtigungsStatus']?.find((v) => v.value === value);
  }

  get besichtigungenBesichtigungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigungen.besichtigungsStatus'] as ISelection[]) : [])));
  }

  getTourMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.mitarbeiter']?.find((v) => v.value === value);
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.mitarbeiter'] as ISelection[]) : [])));
  }

  getBesichtigungenMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigungen.mitarbeiter']?.find((v) => v.value === value);
  }

  get besichtigungenMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigungen.mitarbeiter'] as ISelection[]) : [])));
  }

  getReisekostenerstattungMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenerstattung.mitarbeiter']?.find((v) => v.value === value);
  }

  get reisekostenerstattungMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenerstattung.mitarbeiter'] as ISelection[]) : [])));
  }

  getReisekostenerstattungReisekostenabrechnungStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenerstattung.reisekostenabrechnungStatus']?.find((v) => v.value === value);
  }

  get reisekostenerstattungReisekostenabrechnungStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenerstattung.reisekostenabrechnungStatus'] as ISelection[]) : [])));
  }

  getReisekostenerstattungTouren(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenerstattung.touren']?.find((v) => v.value === value);
  }

  get reisekostenerstattungTouren(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenerstattung.touren'] as ISelection[]) : [])));
  }

  getTourTourStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.tourStatus']?.find((v) => v.value === value);
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.tourStatus'] as ISelection[]) : [])));
  }
}
