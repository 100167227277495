import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { isEqual } from 'lodash';
import {
  FilterType,
  IArrayConfig,
  IArrayReferenceConfig,
  IBooleanConfig,
  IDateConfig,
  IDateValue,
  IEnumConfig,
  IFilter,
  IFilterConfig,
  IGeoRangeConfig,
  IGeoRangeValue,
  INumberConfig,
  INumberValue,
  ISelection,
  ISingleReferenceConfig,
} from 'pbc.types';

@Component({
  selector: 'pbc-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  subscriptions: Subscription[] = [];

  FilterType = FilterType;

  @Input() set config(config: IFilterConfig<any>) {
    this.$config.next(config);
  }
  $config = new BehaviorSubject<IFilterConfig<any>>([]);
  _config: IFilterConfig<any> | undefined;

  $unused = new BehaviorSubject<ISelection[]>([]);
  $newFilterField: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  filter$: BehaviorSubject<IFilterConfig<any>> = new BehaviorSubject<IFilterConfig<any>>([]);
  _filter: IFilterConfig<any> = [];

  @Input() set filter(filter: IFilter<any> | undefined) {
    if (!filter) return;
    const config = this.$config.getValue();
    const state = Object.entries(filter).map(([key, filter]) => ({ filter, config: config?.find((f) => f.key === key) }));
    const filter$ = state
      .filter((entry: any) => entry.config && entry.filter)
      .map((entry) => ({ ...entry.config, value: entry.filter!.value, operator: entry.filter!.operator, readonly: entry.filter.readonly })) as IFilterConfig<any>;
    if (filter && (!this._current || !isEqual(filter, this._current) || !isEqual(filter$, this.filter$.getValue()))) {
      this.filter$.next(filter$);
    }
    this._current = filter;
  }
  @Output() filterChange: EventEmitter<IFilter<any>> = new EventEmitter<IFilter<any>>();
  _current: IFilter<any> = {};

  constructor(
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private message: NzMessageService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      ...[
        this.filter$.subscribe((filters) => {
          const newFilter = filters.reduce((o, filter) => ({ ...o, [filter.key]: { value: filter.value, operator: filter.operator, readonly: filter.readonly } }), {});
          this.filterChange.emit(newFilter);
        }),
        this.$config.subscribe((config) => (this._config = config)),
        this.filter$.subscribe((filter) => (this._filter = filter)),
        combineLatest([this.$config, this.filter$]).subscribe(([config, filter]) => {
          if (!config) {
            return this.$unused.next([]);
          }
          this.$unused.next(config.filter((x) => filter.map((s) => s.key).indexOf(x.key) < 0).map((s) => ({ label: s.title, value: s.key })));
        }),
        this.$newFilterField.subscribe((key: string | null) => {
          if (!key) {
            return;
          }
          this.createFilter(key);
          this.$newFilterField.next(null);
        }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  createFilter(key: string) {
    if (!this._config) {
      return;
    }
    const filter = this._config.find((f) => f.key === key);
    this.filter$.next(
      this.filter$
        .getValue()
        .concat([filter as any])
        .map((unit, index) => ({ ...unit, index })),
    );
    const selections = document.getElementById(key + '-value');
    if (selections && selections.focus) {
      selections.focus();
    }
  }

  removeFilter(key: string) {
    this.filter$.next(
      this.filter$
        .getValue()
        .filter((f) => f.key !== key)
        .map((unit, index) => ({ ...unit, index })),
    );
  }

  emitOperator(operator: 'in' | '>' | '>=' | '=' | '<' | '<=' | 'hat' | 'alle' | 'nicht' | 'nicht alle' | 'ist', i: number) {
    this._filter[i].operator = operator;
    if (this._filter[i].operator === 'in') {
      if (this._filter[i].type === FilterType.Number) {
        this._filter[i].value = { min: 0, max: 0 };
      } else if (this._filter[i].type === FilterType.Date) {
        this._filter[i].value = { min: new Date(), max: new Date() };
      }
    } else if ((this._filter[i].value as IDateValue).min) {
      if (this._filter[i].type === FilterType.Boolean) {
        this._filter[i].value = true;
      } else if (this._filter[i].type === FilterType.Number) {
        this._filter[i].value = 0;
      } else {
        this._filter[i].value = '';
      }
    }
    this.emit();
  }

  emitValue(value: any, i: number, field?: 'min' | 'max') {
    if (field) {
      (this._filter[i].value as IDateValue)[field] = value;
    } else {
      this._filter[i].value = value;
    }
    this.emit();
  }

  emit() {
    this.filter$.next(this._filter);
  }

  onDateRangePickerChange(i: number, $event: Date[]) {
    if (this._filter[i].value && (this._filter[i].value as IDateValue)?.min && (this._filter[i].value as IDateValue)?.max) {
      (this._filter[i].value as IDateValue).min = $event[0];
      (this._filter[i].value as IDateValue).max = $event[1];
    }
    this.emit();
  }

  isWide(filter: INumberConfig | IArrayConfig | IEnumConfig | IDateConfig | IGeoRangeConfig | IBooleanConfig | ISingleReferenceConfig<any> | IArrayReferenceConfig<any>) {
    return filter.type !== FilterType.Boolean;
  }

  getMinMax(value?: string | boolean | INumberValue | IDateValue | IGeoRangeValue | null) {
    if (!value) return undefined;
    return [(value as { min: unknown })?.min, (value as { max: unknown })?.max];
  }
  getMin(value?: string | boolean | INumberValue | IDateValue | IGeoRangeValue | null) {
    if (!value) return undefined;
    return (value as { min: unknown })?.min;
  }
  getMax(value?: string | boolean | INumberValue | IDateValue | IGeoRangeValue | null) {
    if (!value) return undefined;
    return (value as { max: unknown })?.max;
  }
}
