import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostOrtKategorieCommandShapes, IPostOrtKategorieRequest, IPostOrtKategorieResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostOrtKategorieCommandService extends PostCommandService<IPostOrtKategorieRequest, IPostOrtKategorieResponse, IPostOrtKategorieCommandShapes> {
  readonly route = 'geo/settings/ort-kategorie';
}
