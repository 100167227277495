import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, combineLatest, first, startWith } from 'rxjs';

import { BasePostCommandComponent } from 'pbc.angular';
import { toInitials } from 'pbc.functions';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IAbteilung, IFond, IPersonenAnteil, IPostProjektRequest, IPostProjektResponse, IProjekteResponseRow } from 'fa-kt.types';
import { MitarbeiterQueryModule, SollIstService } from '../../../..';
import { KundeService, PostAbteilungCommandComponent, PostFondCommandComponent, PostKundeCommandComponent } from '../../../../kunden';
import { ProjektService, ProjekteService } from '../../../querys';
import { PostProjektCommandService } from '../service';
import { groupBy, uniq } from 'lodash';

@Component({
  selector: 'fa-kt-post-projekt',
  templateUrl: './post-projekt.component.html',
  styleUrls: ['./post-projekt.component.css'],
})
export class PostProjektCommandComponent extends BasePostCommandComponent<IPostProjektRequest, IPostProjektResponse> implements OnInit, OnDestroy {
  description = { context: 'PROJEKTE', command: 'POST_PROJEKT' };
  contentKey: keyof IPostProjektRequest = 'projekt';

  async prepare(): Promise<void> {
    await this.postProjekt.prepare();
  }
  async request(payload: IPostProjektRequest): Promise<IPostProjektResponse> {
    return this.postProjekt.request(payload);
  }

  @Input() set row(row: IProjekteResponseRow) {
    this.row$.next(row);
  }
  init$ = new BehaviorSubject<boolean>(false);
  row$ = new BehaviorSubject<IProjekteResponseRow | undefined>(undefined);
  public gutachten$ = new BehaviorSubject<{ id: string; minuten?: number }[]>([]);
  gesamtGutachtenAnteile$ = new BehaviorSubject<number | undefined>(undefined);
  public besichtigungen$ = new BehaviorSubject<{ id: string; minuten?: number }[]>([]);
  gesamtBesichtigungenAnteile$ = new BehaviorSubject<number | undefined>(undefined);
  public pruefung$ = new BehaviorSubject<{ id: string; minuten?: number }[]>([]);
  gesamtPruefungAnteile$ = new BehaviorSubject<number | undefined>(undefined);

  gesamtPartnerInnenAnteile$ = new BehaviorSubject<number | undefined>(undefined);

  @Output() kundeChanged = new EventEmitter<string | undefined>();
  @Output() abteilungChanged = new EventEmitter<string | undefined>();

  constructor(
    public projekte: ProjekteService,
    public projekt: ProjektService,
    public kunden: KundeService,
    public postProjekt: PostProjektCommandService,
    public sollIst: SollIstService,
    public route: ActivatedRoute,
  ) {
    super();
    this.form = this.fb.group({
      projekt: this.fb.group({
        id: [null, []],
        bezeichnung: [null, [Validators.required]],
        nummer: [null, [Validators.required]],
        projektZaehler: [null, []],
        projektArt: [null, [Validators.required]],
        projektStatus: [null, [Validators.required]],
        bewertungsAnlass: [null, [Validators.required]],
        standort: [null, [Validators.required]],
        beauftragung: [null, []],
        deadline: [null, []],
        kundenAnfertigung: [null, []],
        keineBesichtigungErforderlich: [null, []],
        besichtigungen: [[], []],
        besichtigungenAnteile: this.fb.array([]),
        gutachten: [[], []],
        gutachtenAnteile: this.fb.array([]),
        pruefung: [null, []],
        pruefungAnteile: this.fb.array([]),
        partnerInnen: [[], []],
        partnerInnenAnteile: this.fb.array([]),
        kunde: [null, [Validators.required]],
        abteilung: [null, []],
        fond: [null, []],
        bank: [null, []],
        objektArt: [null, [Validators.required]],
        nachlass: [null, []],
        kundenZeichen: [null, []],
        kontakteInOutlook: [[], []],
        dateiVerzeichnis: [null, []],
        kommentar: [null, []],
        notizenFuerRechnung: [null, []],
        fortschritt: [null, []],
        stunden: [null, []],
        kostenNachStunden: [null, []],
        verhandeltesHonorar: [null, []],
        kosten: [null, []],
        kostenNetto: [null, []],
        kostenBeglichen: [null, []],
        aktiv: [null, []],
        darlehensnehmer: [null, []],
        notizenBesichtigung: [null, []],
        kreditsachbearbeiterVorname: [null, []],
        kreditsachbearbeiterName: [null, []],
        kreditsachbearbeiterTelefon: [null, []],
        kreditsachbearbeiterEMail: [null, []],
      }),
    });
    this.patch();
  }

  override ngOnInit() {
    super.ngOnInit();

    this.row$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((row) => {
      if (!row?.zeiten?.length) return;
      this.gutachten$.next(
        Object.values(
          groupBy(
            row.zeiten
              .filter(({ zeitUnterart }) => zeitUnterart?.toString() === '56')
              .map(({ mitarbeiter, stunden, minuten }) => ({ id: mitarbeiter, minuten: (stunden || 0) * 60 + (minuten || 0) }))
              .filter(({ id, minuten }) => !!id && !!minuten),
            ({ id }) => id,
          ),
        ).map((e) => ({ id: e[0].id, minuten: e.reduce((x, { minuten }) => x + minuten, 0) })),
      );
      this.besichtigungen$.next(
        Object.values(
          groupBy(
            row.zeiten
              .filter(({ zeitUnterart }) => zeitUnterart?.toString() === '2')
              .map(({ mitarbeiter, stunden, minuten }) => ({ id: mitarbeiter, minuten: (stunden || 0) * 60 + (minuten || 0) }))
              .filter(({ id, minuten }) => !!id && !!minuten),
            ({ id }) => id,
          ),
        ).map((e) => ({ id: e[0].id, minuten: e.reduce((x, { minuten }) => x + minuten, 0) })),
      );
      this.pruefung$.next(
        Object.values(
          groupBy(
            row.zeiten
              .filter(({ zeitUnterart }) => zeitUnterart?.toString() === '57')
              .map(({ mitarbeiter, stunden, minuten }) => ({ id: mitarbeiter, minuten: (stunden || 0) * 60 + (minuten || 0) }))
              .filter(({ id, minuten }) => !!id && !!minuten),
            ({ id }) => id,
          ),
        ).map((e) => ({ id: e[0].id, minuten: e.reduce((x, { minuten }) => x + minuten, 0) })),
      );
    });

    this.projektKunde?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (kunde) => {
      const original = this.projektNummer?.value;
      let selection = await this.postProjekt.getProjektKunde(kunde);
      kunde = selection?.label?.split(' |')[0];
      const jahr = new Date().getFullYear().toString().slice(2);
      if ((!original || (original && original.includes(jahr))) && kunde) this.projektNummer?.patchValue(jahr + '-' + kunde + '-' + this.projektProjektZaehler.value);
    });

    combineLatest([this.gutachten$, this.gutachten?.valueChanges?.pipe(startWith([])), this.init$])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([zeiten]) => {
        const gutachten = this.gutachten?.value;
        const anteile = this.gutachtenAnteile?.value || [];
        this.patchGutachtenAnteile(
          uniq([...(zeiten?.map(({ id }) => id) || []), ...(gutachten || [])]).map((mitarbeiterIn) => ({
            mitarbeiterIn,
            anteil: anteile.find((ga) => ga.mitarbeiterIn === mitarbeiterIn)?.anteil || undefined,
          })),
        );
      });
    this.gutachtenAnteile?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((gutachtenAnteile) => {
      let gesamt = 0;
      gutachtenAnteile?.forEach((anteil) => (gesamt += anteil.anteil));
      this.gesamtGutachtenAnteile$.next(gesamt);
    });

    combineLatest([this.pruefung$, this.pruefung?.valueChanges?.pipe(startWith(undefined)), this.init$])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([zeiten]) => {
        const pruefung = this.pruefung?.value;
        const anteile = this.pruefungAnteile?.value || [];
        this.patchPruefungAnteile(
          uniq([...(zeiten?.map(({ id }) => id) || []), pruefung])
            .filter((s) => !!s)
            .map((mitarbeiterIn) => ({
              mitarbeiterIn,
              anteil: anteile.find((ga) => ga.mitarbeiterIn === mitarbeiterIn)?.anteil || undefined,
            })),
        );
      });
    this.pruefungAnteile?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((pruefungAnteile) => {
      let gesamt = 0;
      pruefungAnteile?.forEach((anteil) => (gesamt += anteil.anteil));
      this.gesamtPruefungAnteile$.next(gesamt);
    });

    combineLatest([this.besichtigungen$, this.besichtigungen?.valueChanges?.pipe(startWith([])), this.init$])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([zeiten]) => {
        const besichtigungen = this.besichtigungen?.value;
        const anteile = this.besichtigungenAnteile?.value || [];
        this.patchBesichtigungenAnteile(
          uniq([...(zeiten?.map(({ id }) => id) || []), ...(besichtigungen || [])]).map((mitarbeiterIn) => ({
            mitarbeiterIn,
            anteil: anteile.find((ga) => ga.mitarbeiterIn === mitarbeiterIn)?.anteil || undefined,
          })),
        );
      });
    this.besichtigungenAnteile?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((besichtigungenAnteile) => {
      let gesamt = 0;
      besichtigungenAnteile?.forEach((anteil) => (gesamt += anteil.anteil));
      this.gesamtBesichtigungenAnteile$.next(gesamt);
    });

    this.partnerInnen?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((partnerInnen) => {
      const anteile = this.partnerInnenAnteile?.value || [];
      this.patchPartnerInnenAnteile(
        (partnerInnen || []).map((mitarbeiterIn) => ({
          mitarbeiterIn,
          anteil: anteile.find((ga) => ga.mitarbeiterIn === mitarbeiterIn)?.anteil || undefined,
        })),
      );
    });
    this.partnerInnenAnteile?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((partnerInnenAnteile) => {
      let gesamt = 0;
      partnerInnenAnteile?.forEach((anteil) => (gesamt += anteil.anteil));
      this.gesamtPartnerInnenAnteile$.next(gesamt);
    });
  }

  override patch(value?: IPostProjektRequest) {
    if (value?.projekt?.dateiVerzeichnis) {
      value.projekt.dateiVerzeichnis = value.projekt.dateiVerzeichnis.replace('F:\\', 'C:\\FAKTOD\\FAKT\\');
      if (new Date(value.projekt?._createdAt as Date).getFullYear() - 2 > new Date().getFullYear())
        value.projekt.dateiVerzeichnis = value.projekt.dateiVerzeichnis.replace('F:\\', 'O:\\').replace('OneDrive:', 'O:\\');
    }
    super.patch(value);
    if (value?.projekt?.gutachtenAnteile?.length) this.patchGutachtenAnteile(value.projekt.gutachtenAnteile);
    if (value?.projekt?.besichtigungenAnteile?.length) this.patchBesichtigungenAnteile(value.projekt.besichtigungenAnteile);
    if (value?.projekt?.pruefungAnteile?.length) this.patchPruefungAnteile(value.projekt.pruefungAnteile);
    if (value?.projekt?.partnerInnenAnteile) this.patchPartnerInnenAnteile(value.projekt.partnerInnenAnteile);
    this.init$.next(true);
  }

  createKunde(name: string) {
    const kunde = { name, initialen: toInitials(name) };
    this.modal
      .create({
        nzTitle: 'Neuen Kunden hinzufügen',
        nzContent: PostKundeCommandComponent,
        nzData: { kunde },
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: [],
      })
      .afterClose.pipe(first())
      .subscribe(async (result) => {
        await this.postProjekt.prepare();
        this.kunde?.patchValue(result?.kunde?.id);
      });
  }

  createBank(name: string) {
    const kunde = { name, initialen: toInitials(name) };
    this.modal
      .create({
        nzTitle: 'Neuen Kunden hinzufügen',
        nzContent: PostKundeCommandComponent,
        nzData: { kunde },
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: [],
      })
      .afterClose.pipe(first())
      .subscribe(async (result) => {
        await this.postProjekt.prepare();
        this.bank?.patchValue(result?.kunde?.id);
      });
  }

  createAbteilung(name: string) {
    const kunde = this.kunde?.value;
    if (!kunde) {
      return;
    }
    const abteilung: IAbteilung = { name, kunde, aktiv: true };
    this.modal
      .create({
        nzTitle: 'Neue Abteilung hinzufügen',
        nzContent: PostAbteilungCommandComponent,
        nzData: { abteilung },
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: [],
      })
      .afterClose.pipe(first())
      .subscribe(async (result) => {
        await this.postProjekt.prepare();
        this.abteilung?.patchValue(result?.abteilung?.id);
      });
  }

  createFond(name: string) {
    const kunde = this.kunde?.value;
    if (!kunde) return;
    const fond: IFond = { name, kunde, aktiv: true };
    this.modal
      .create({
        nzTitle: 'Neuen Fond hinzufügen',
        nzContent: PostFondCommandComponent,
        nzData: { fond },
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: [],
      })
      .afterClose.pipe(first())
      .subscribe(async (result) => {
        await this.postProjekt.prepare();
        this.fond?.patchValue(result?.fond?.id);
      });
  }

  async openDateipfad(pfad: string) {
    if (!pfad?.length) return;
    this.$loading.next(true);
    await this.files.openDirectory(pfad, this.auth.$token.getValue());
    this.$loading.next(false);
  }

  async openDateien() {
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { tab: 'dateien' },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  getMinuten(arr: Array<{ id: string; minuten?: number }>, ma: string): number {
    return arr?.find(({ id }) => id === ma)?.minuten;
  }

  get kunde() {
    return this.form.controls.projekt.get('kunde');
  }
  get bank() {
    return this.form.controls.projekt.get('bank');
  }
  get fond() {
    return this.form.controls.projekt.get('fond');
  }
  get abteilung() {
    return this.form.controls.projekt.get('abteilung');
  }
  get dateiVerzeichnis() {
    return this.form.controls.projekt.get('dateiVerzeichnis');
  }
  get projektId() {
    return this.form.controls.projekt.get('id');
  }
  get projektBezeichnung() {
    return this.form.controls.projekt.get('bezeichnung');
  }
  get projektNummer() {
    return this.form.controls.projekt.get('nummer');
  }
  get projektProjektZaehler() {
    return this.form.controls.projekt.get('projektZaehler');
  }
  get projektProjektArt() {
    return this.form.controls.projekt.get('projektArt');
  }
  get projektProjektStatus() {
    return this.form.controls.projekt.get('projektStatus');
  }
  get projektStandort() {
    return this.form.controls.projekt.get('standort');
  }
  get projektBeauftragung() {
    return this.form.controls.projekt.get('beauftragung');
  }
  get projektDeadline() {
    return this.form.controls.projekt.get('deadline');
  }
  get projektKundenAnfertigung() {
    return this.form.controls.projekt.get('kundenAnfertigung');
  }
  get projektKeineBesichtigungErforderlich() {
    return this.form.controls.projekt.get('keineBesichtigungErforderlich');
  }
  get projektKunde() {
    return this.form.controls.projekt.get('kunde');
  }
  get projektAbteilung() {
    return this.form.controls.projekt.get('abteilung');
  }
  get projektFond() {
    return this.form.controls.projekt.get('fond');
  }
  get projektBank() {
    return this.form.controls.projekt.get('bank');
  }
  get projektObjektArt() {
    return this.form.controls.projekt.get('objektArt');
  }
  get projektNachlass() {
    return this.form.controls.projekt.get('nachlass');
  }
  get projektKundenZeichen() {
    return this.form.controls.projekt.get('kundenZeichen');
  }
  get projektKontakteInOutlook() {
    return this.form.controls.projekt.get('kontakteInOutlook');
  }
  get projektDateiVerzeichnis() {
    return this.form.controls.projekt.get('dateiVerzeichnis');
  }
  get projektKommentar() {
    return this.form.controls.projekt.get('kommentar');
  }
  get projektNotizenFuerRechnung() {
    return this.form.controls.projekt.get('notizenFuerRechnung');
  }
  get projektFortschritt() {
    return this.form.controls.projekt.get('fortschritt');
  }
  get projektStunden() {
    return this.form.controls.projekt.get('stunden');
  }
  get projektKostenNachStunden() {
    return this.form.controls.projekt.get('kostenNachStunden');
  }
  get projektVerhandeltesHonorar() {
    return this.form.controls.projekt.get('verhandeltesHonorar');
  }
  get projektKosten() {
    return this.form.controls.projekt.get('kosten');
  }
  get projektKostenBeglichen() {
    return this.form.controls.projekt.get('kostenBeglichen');
  }
  get projektAktiv() {
    return this.form.controls.projekt.get('aktiv');
  }
  get projektDarlehensnehmer() {
    return this.form.controls.projekt.get('darlehensnehmer');
  }
  get projektNotizenBesichtigung() {
    return this.form.controls.projekt.get('notizenBesichtigung');
  }
  get projektKreditsachbearbeiterVorname() {
    return this.form.controls.projekt.get('kreditsachbearbeiterVorname');
  }
  get projektKreditsachbearbeiterName() {
    return this.form.controls.projekt.get('kreditsachbearbeiterName');
  }
  get projektKreditsachbearbeiterTelefon() {
    return this.form.controls.projekt.get('kreditsachbearbeiterTelefon');
  }
  get projektKreditsachbearbeiterEMail() {
    return this.form.controls.projekt.get('kreditsachbearbeiterEMail');
  }
  get besichtigungen() {
    return this.form.get('projekt.besichtigungen');
  }
  get besichtigungenAnteile() {
    return this.form.get('projekt.besichtigungenAnteile') as FormArray;
  }
  get gutachten() {
    return this.form.get('projekt.gutachten');
  }
  get gutachtenAnteile() {
    return this.form.get('projekt.gutachtenAnteile') as FormArray;
  }
  get pruefung() {
    return this.form.get('projekt.pruefung');
  }
  get pruefungAnteile() {
    return this.form.get('projekt.pruefungAnteile') as FormArray;
  }
  get partnerInnen() {
    return this.form.get('projekt.partnerInnen');
  }
  get partnerInnenAnteile() {
    return this.form.get('projekt.partnerInnenAnteile') as FormArray;
  }

  newGutachtenAnteil(gutachtenAnteil: IPersonenAnteil): FormGroup {
    return this.fb.group({
      mitarbeiterIn: gutachtenAnteil.mitarbeiterIn,
      anteil: gutachtenAnteil.anteil,
    });
  }

  patchGutachtenAnteile(gutachtenAnteile: IPersonenAnteil[] = []) {
    this.gutachtenAnteile.clear();
    if (gutachtenAnteile.length < 2) {
      this.gesamtGutachtenAnteile$.next(undefined);
      return;
    }
    gutachtenAnteile.map((ga) => this.newGutachtenAnteil(ga)).forEach((control) => this.gutachtenAnteile?.push(control));
    let gesamt = 0;
    gutachtenAnteile.forEach((anteil) => (gesamt += anteil.anteil || 0));
    this.gesamtGutachtenAnteile$.next(gesamt === 0 ? undefined : gesamt);
  }

  newPruefungAnteil(pruefungAnteil: IPersonenAnteil): FormGroup {
    return this.fb.group({
      mitarbeiterIn: pruefungAnteil.mitarbeiterIn,
      anteil: pruefungAnteil.anteil,
    });
  }

  patchPruefungAnteile(pruefungAnteile: IPersonenAnteil[] = []) {
    this.pruefungAnteile.clear();
    if (pruefungAnteile.length < 2) {
      -this.gesamtPartnerInnenAnteile$.next(undefined);
      return;
    }
    pruefungAnteile.map((ga) => this.newPruefungAnteil(ga)).forEach((control) => this.pruefungAnteile?.push(control));
    let gesamt = 0;
    pruefungAnteile.forEach((anteil) => (gesamt += anteil.anteil || 0));
    this.gesamtPruefungAnteile$.next(gesamt === 0 ? undefined : gesamt);
  }

  newBesichtigungenAnteil(besichtigungenAnteil: IPersonenAnteil): FormGroup {
    return this.fb.group({
      mitarbeiterIn: besichtigungenAnteil.mitarbeiterIn,
      anteil: besichtigungenAnteil.anteil,
    });
  }

  patchBesichtigungenAnteile(besichtigungenAnteile: IPersonenAnteil[] = []) {
    this.besichtigungenAnteile.clear();
    if (besichtigungenAnteile.length < 2) {
      this.gesamtBesichtigungenAnteile$.next(undefined);
      return;
    }
    besichtigungenAnteile.map((ga) => this.newBesichtigungenAnteil(ga)).forEach((control) => this.besichtigungenAnteile?.push(control));
    let gesamt = 0;
    besichtigungenAnteile.forEach((anteil) => (gesamt += anteil.anteil || 0));
    this.gesamtBesichtigungenAnteile$.next(gesamt === 0 ? undefined : gesamt);
  }

  newPartnerInnenAnteil(partnerInnenAnteil: IPersonenAnteil): FormGroup {
    return this.fb.group({
      mitarbeiterIn: partnerInnenAnteil.mitarbeiterIn,
      anteil: partnerInnenAnteil.anteil,
    });
  }

  patchPartnerInnenAnteile(partnerInnenAnteile: IPersonenAnteil[] = []) {
    this.partnerInnenAnteile.clear();
    if (partnerInnenAnteile.length < 2) {
      this.gesamtPartnerInnenAnteile$.next(undefined);
      return;
    }
    partnerInnenAnteile.map((ga) => this.newPartnerInnenAnteil(ga)).forEach((control) => this.partnerInnenAnteile?.push(control));
    let gesamt = 0;
    partnerInnenAnteile.forEach((anteil) => (gesamt += anteil.anteil || 0));
    this.gesamtPartnerInnenAnteile$.next(gesamt === 0 ? undefined : gesamt);
  }

  showKommentar(): boolean {
    const until = new Date();
    until.setFullYear(2023, 1, 17);
    return new Date(this.projektBeauftragung?.value) < until;
  }
}
