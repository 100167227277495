import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostZahlungseingangCommandShapes, IPostZahlungseingangRequest, IPostZahlungseingangResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostZahlungseingangCommandService extends PostCommandService<IPostZahlungseingangRequest, IPostZahlungseingangResponse, IPostZahlungseingangCommandShapes> {
  readonly route = 'finanzen/zahlungseingang';
  override dependencies = [];

  getZahlungseingangRechnung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zahlungseingang.rechnung']?.find((v) => v.value === value);
  }

  get zahlungseingangRechnung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zahlungseingang.rechnung'] as ISelection[]) : [])));
  }
}
