import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ITourenResponseRow } from 'fa-kt.types';
import { TourenService } from '../service';

@Component({
  selector: 'fa-kt-touren-list',
  templateUrl: './touren-list.component.html',
  styleUrls: ['./touren-list.component.css'],
})
export class TourenListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public touren: TourenService) {}

  public set(touren: ITourenResponseRow) {}
}
