import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IGutachtenStatiResponse } from 'fa-kt.types';
import { PostGutachtenStatusCommandService } from '../commands';
import { GutachtenStatiService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class GutachtenStatiPageResolver implements Resolve<IGutachtenStatiResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private gutachtenStati: GutachtenStatiService,
    private postGutachtenStatus: PostGutachtenStatusCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IGutachtenStatiResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [gutachtenStati] = await Promise.all([this.gutachtenStati.request({}).catch(log), this.postGutachtenStatus.prepare(true).catch(log)]);
    this.loading.resolved();
    return gutachtenStati;
  }
}
