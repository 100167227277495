import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BasePostCommandComponent } from 'pbc.angular';

import { IPostKundeRequest, IPostKundeResponse } from 'fa-kt.types';
import { PostKundeCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-kunde',
  templateUrl: './post-kunde.component.html',
  styleUrls: ['./post-kunde.component.css'],
})
export class PostKundeCommandComponent extends BasePostCommandComponent<IPostKundeRequest, IPostKundeResponse> implements OnInit, OnDestroy {
  description = { context: 'KUNDEN', command: 'POST_KUNDE' };
  contentKey: keyof IPostKundeRequest = 'kunde';

  constructor(public postKunde: PostKundeCommandService) {
    super();
    this.form = this.fb.group({
      kunde: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        initialen: [null, [Validators.required]],
        kundenGruppe: [null, [Validators.required]],
        kundenArt: [null, []],
        addresse: [],
        website: [null, []],
        outlookKontakte: [[], []],
        kontaktNotizen: [null, []],
        anforderungenNotizen: [null, []],
        rechnungNotizen: [null, []],
        fahrtkostenAbrechnung: [null, []],
        steuerId: [null, []],
        fahrtkostenAbRadius: [null, []],
        fahrtkostenProKm: [null, []],
        fahrtkostenProStunde: [null, []],
        skonto: [null, []],
        zahlungsziel: [null, []],
        tageOhneSkonto: [null, []],
        aktiv: [null, []],
        dateipfad: [null, []],
        loraName: [null, []],
        unterlagenEmail: [null, []],
      }),
    });
    this.patch();
    try {
      if (this.data) this.patch(this.data);
    } catch (e) {}
  }

  async prepare(): Promise<void> {
    await this.postKunde.prepare();
  }

  async request(payload: IPostKundeRequest): Promise<IPostKundeResponse> {
    return this.postKunde.request(payload);
  }

  get letzteId() {
    return this.form.controls.kunde.get('letzteId');
  }
  get initialen() {
    return this.form.controls.kunde.get('initialen');
  }
  get website() {
    return this.form.controls.kunde.get('website');
  }
  get name() {
    return this.form.controls.kunde.get('name');
  }
  get kundenGruppe() {
    return this.form.controls.kunde.get('kundenGruppe');
  }
  get kundenGruppeValue() {
    return this.kundenGruppe?.value || '*';
  }
  get kundenArt() {
    return this.form.controls.kunde.get('kundenArt');
  }
  get fahrtkostenAbrechnung() {
    return this.form.controls.kunde.get('fahrtkostenAbrechnung');
  }
  /*    methods >> */

  get kundeId() {
    return this.form.controls.kunde.get('id');
  }
  get kundeName() {
    return this.form.controls.kunde.get('name');
  }
  get kundeInitialen() {
    return this.form.controls.kunde.get('initialen');
  }
  get kundeKundenGruppe() {
    return this.form.controls.kunde.get('kundenGruppe');
  }
  get kundeKundenArt() {
    return this.form.controls.kunde.get('kundenArt');
  }
  get kundeAddresse() {
    return this.form.controls.kunde.get('addresse');
  }
  get kundeWebsite() {
    return this.form.controls.kunde.get('website');
  }
  get kundeOutlookKontakte() {
    return this.form.controls.kunde.get('outlookKontakte');
  }
  get kundeKontaktNotizen() {
    return this.form.controls.kunde.get('kontaktNotizen');
  }
  get kundeAnforderungenNotizen() {
    return this.form.controls.kunde.get('anforderungenNotizen');
  }
  get kundeRechnungNotizen() {
    return this.form.controls.kunde.get('rechnungNotizen');
  }
  get kundeFahrtkostenAbrechnung() {
    return this.form.controls.kunde.get('fahrtkostenAbrechnung');
  }
  get kundeSteuerId() {
    return this.form.controls.kunde.get('steuerId');
  }
  get kundeFahrtkostenAbRadius() {
    return this.form.controls.kunde.get('fahrtkostenAbRadius');
  }
  get kundeFahrtkostenProKm() {
    return this.form.controls.kunde.get('fahrtkostenProKm');
  }
  get kundeFahrtkostenProStunde() {
    return this.form.controls.kunde.get('fahrtkostenProStunde');
  }
  get kundeSkonto() {
    return this.form.controls.kunde.get('skonto');
  }
  get kundeZahlungsziel() {
    return this.form.controls.kunde.get('zahlungsziel');
  }
  get kundeTageOhneSkonto() {
    return this.form.controls.kunde.get('tageOhneSkonto');
  }
  get kundeAktiv() {
    return this.form.controls.kunde.get('aktiv');
  }
  get kundeDateipfad() {
    return this.form.controls.kunde.get('dateipfad');
  }
  get kundeLoraName() {
    return this.form.controls.kunde.get('loraName');
  }
  get kundeUnterlagenEmail() {
    return this.form.controls.kunde.get('unterlagenEmail');
  }
}
