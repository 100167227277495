import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostHerausgeberCommandShapes, IPostHerausgeberRequest, IPostHerausgeberResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostHerausgeberCommandService extends PostCommandService<IPostHerausgeberRequest, IPostHerausgeberResponse, IPostHerausgeberCommandShapes> {
  readonly route = 'markt/settings/herausgeber';
}
