<!---
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="formular">{{ command.Inputs.FORMULAR.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.FORMULAR.hint">
      <pbc-select-value formControlName="formular" id="formular" [shapes]="postFormularKopieren.formular | async" [placeholder]="command.Inputs.FORMULAR.placeholder" ></pbc-select-value>
    </nz-form-control>
  </nz-form-item>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <input class="cdk-visually-hidden" formControlName="formular" id="formular"/>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="copy" nzTheme="outline"></i>
          Kopieren
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
