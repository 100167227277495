import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IKonstantesQuerySorter extends ISorter {
   id?: ISorterExpression;
   art?: ISorterExpression;
   inhalt?: ISorterExpression;
   kommentar?: ISorterExpression;
}

export const KonstantesQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'EINSTELLUNGEN.Models.KONSTANTE.Propertys.ID.title'),
   SorterConfig('art', 'EINSTELLUNGEN.Models.KONSTANTE.Propertys.ART.title'),
   SorterConfig('inhalt', 'EINSTELLUNGEN.Models.KONSTANTE.Propertys.INHALT.title'),
   SorterConfig('kommentar', 'EINSTELLUNGEN.Models.KONSTANTE.Propertys.KOMMENTAR.title'),
];
