<cdk-virtual-scroll-viewport *ngIf="tage$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let tag of tage$ | async | reverse" (click)="set(entry)">
      <nz-list-item-meta>
        <nz-list-item-meta-avatar>
          <nz-avatar
            [nzSrc]="tag.mitarbeiter | type: (meta.users$ | async) : 'avatar' | safe: 'url'"
            [nzText]="tag.mitarbeiter | type: (meta.users$ | async) : 'initialen'"
            nz-tooltip
            [nzTooltipTitle]="tag.mitarbeiter | type: (meta.users$ | async) : 'label'"
          >
          </nz-avatar>
        </nz-list-item-meta-avatar>
        <nz-list-item-meta-title>
          <a nz-popover [nzPopoverContent]="tag.zugeordnet ? TagesGraph : undefined" nzPopoverPlacement="right">
            {{ tag.start | date: 'dd.MM.yyyy HH:mm' }}
            <ng-container *ngIf="!tag.zugeordnet">
              <nz-divider nzType="vertical"></nz-divider>
              <i nz-icon nzTheme="outline" nzType="warning" style="color: orange" nz-tooltip nzTooltipTitle="Tag wurde noch nicht abgeschlossen"></i>
            </ng-container>
            <nz-divider nzType="vertical"></nz-divider>
            {{ tag.minuten.arbeit || 0 | formatHours }} <span nz-typography nzType="secondary">Arbeit</span>
            <nz-divider nzType="vertical"></nz-divider>
            {{ tag.minuten.pause || 0 | formatHours }} <span nz-typography nzType="secondary">Pause</span>
            <nz-divider nzType="vertical"></nz-divider>
            <ng-container *ngIf="tag.keineGesetzlichePause">
              <nz-divider nzType="vertical"></nz-divider>
              <i nz-icon nzTheme="outline" nzType="alert" style="color: red" nz-tooltip nzTooltipTitle="Keine Pause nach 6 Stunden Arbeit"></i>
            </ng-container>
            <ng-template #TagesGraph>
              <fa-kt-tages-graph [tag]="tag"></fa-kt-tages-graph>
            </ng-template>
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <!--- << extra    -->
          <!---    extra >> -->
          <!-- <a><i nz-icon nzType="edit" nzTheme="outline"></i></a> -->
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
