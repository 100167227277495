import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FelderServiceModule } from '..';
import { SavedFelderFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FelderServiceModule
  ],
  declarations: [SavedFelderFiltersComponent],
  exports: [SavedFelderFiltersComponent],

})
export class SavedFelderFiltersComponentModule { }
