import { NgModule } from '@angular/core';
import {
  BerichteComponentModule,
  DurchsuchenComponentModule,
  ZeilenComponentModule
 } from '.';

@NgModule({
  imports: [
    BerichteComponentModule,
    DurchsuchenComponentModule,
    ZeilenComponentModule
  ],
  exports: [
    BerichteComponentModule,
    DurchsuchenComponentModule,
    ZeilenComponentModule
  ]
})
export class MarktComponentsModule { }
