import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TageFilterComponent } from '.';
import { TageServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, TageServiceModule],
  declarations: [TageFilterComponent],
  exports: [TageFilterComponent],
})
export class TageFilterComponentModule {}
