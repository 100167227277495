<!--

  <fa-kt-post-feld-option [style.width]="'100%'" [value]="$postFeldOption | async" (finished)="finishedPostFeldOption($event)"></fa-kt-post-feld-option>
    
  <fa-kt-delete-feld [style.width]="'100%'" [value]="$deleteFeld | async" (finished)="finishedDeleteFeld($event)"></fa-kt-delete-feld>
    
  <fa-kt-post-ordne-feld-optionen [style.width]="'100%'" [value]="$postOrdneFeldOptionen | async" (finished)="finishedPostOrdneFeldOptionen($event)"></fa-kt-post-ordne-feld-optionen>
    
  <fa-kt-post-feld [style.width]="'100%'" [value]="$postFeld | async" (finished)="finishedPostFeld($event)"></fa-kt-post-feld>
    
  <fa-kt-delete-feld-option [style.width]="'100%'" [value]="$deleteFeldOption | async" (finished)="finishedDeleteFeldOption($event)"></fa-kt-delete-feld-option>
    

  <fa-kt-kalkulationen [style.width]="'100%'" ></fa-kt-kalkulationen>


-->
<!--- << custom    -->
<p> felder works!</p> 
<!---    custom >> -->
