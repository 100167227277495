<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="tour.search$.next($event)" [search]="tour.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-tour-filter [style.width]="'100%'"></fa-kt-tour-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-tour-sorter [style.width]="'100%'"></fa-kt-tour-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-tour-list [style.width]="'100%'" (select)="setTour($event)" [loading]="loading$ | async"></fa-kt-tour-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="offene-gutachten.search$.next($event)" [search]="offene-gutachten.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-offene-gutachten-filter [style.width]="'100%'"></fa-kt-offene-gutachten-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-offene-gutachten-sorter [style.width]="'100%'"></fa-kt-offene-gutachten-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-offene-gutachten-list [style.width]="'100%'" (select)="setOffeneGutachten($event)" [loading]="loading$ | async"></fa-kt-offene-gutachten-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="formulare.search$.next($event)" [search]="formulare.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-formulare-filter [style.width]="'100%'"></fa-kt-formulare-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-formulare-sorter [style.width]="'100%'"></fa-kt-formulare-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-formulare-list [style.width]="'100%'" (select)="setFormulare($event)" [loading]="loading$ | async"></fa-kt-formulare-list>


  <fa-kt-delete-tour [style.width]="'100%'"  [value]="$deleteTour | async" (finished)="finishedDeleteTour($event)"></fa-kt-delete-tour>

  <fa-kt-post-ordne-besichtigungen [style.width]="'100%'"  [value]="$postOrdneBesichtigungen | async" (finished)="finishedPostOrdneBesichtigungen($event)"></fa-kt-post-ordne-besichtigungen>

  <fa-kt-delete-besichtigung [style.width]="'100%'"  [value]="$deleteBesichtigung | async" (finished)="finishedDeleteBesichtigung($event)"></fa-kt-delete-besichtigung>

  <fa-kt-post-tour [style.width]="'100%'"  [value]="$postTour | async" (finished)="finishedPostTour($event)"></fa-kt-post-tour>

  <fa-kt-post-durchgang [style.width]="'100%'"  [value]="$postDurchgang | async" (finished)="finishedPostDurchgang($event)"></fa-kt-post-durchgang>

  <fa-kt-post-besichtigung [style.width]="'100%'"  [value]="$postBesichtigung | async" (finished)="finishedPostBesichtigung($event)"></fa-kt-post-besichtigung>



    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="touren" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="tour" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="durchgang" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-tags *ngIf="($tour | async)?.abgerechnet">
    <nz-tag [nzColor]="'blue'">Abgerechnet</nz-tag>
  </nz-page-header-tags>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <fa-kt-delete-tour [style.width]="'100%'" [value]="$deleteTour | async" (finished)="finishedDeleteTour($event)"></fa-kt-delete-tour>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <nz-col nzLg="16" nzMd="24">
      <fa-kt-post-tour [style.width]="'100%'" [value]="$postTour | async" (finished)="finishedPostTour($event)"></fa-kt-post-tour>
      <nz-divider id="besichtigungen" nzText="Besichtigungen" nzOrientation="left"></nz-divider>
      <nz-spin [nzSpinning]="loading$ | async" style="padding-right: 16px">
        <cdk-virtual-scroll-viewport [itemSize]="75" style="height: calc((100vh - 235px) / 2); width: 100%">
          <ul nz-list (cdkDropListDropped)="drop($event)" cdkDropList>
            <li nz-list-item *cdkVirtualFor="let row of $besichtigungen | async; let i = index" cdkDrag [cdkDragDisabled]="row.besichtigung.rueckfahrt">
              <nz-list-item-meta style="cursor: pointer" (click)="editBesichtigung(row)">
                <nz-list-item-meta-avatar>
                  <nz-avatar
                    [nzIcon]="row.besichtigung.rueckfahrt ? 'car' : row.besichtigung.besichtigungsStatus | type:(tour.besichtigungenBesichtigungsStatus | async):'icon'"
                    [style.backgroundColor]="row.besichtigung.rueckfahrt ? '' : row.besichtigung.besichtigungsStatus | type:(tour.besichtigungenBesichtigungsStatus | async):'color'"
                    nz-tooltip
                    [nzTooltipTitle]="row.besichtigung.rueckfahrt ? undefined : row.besichtigung.wiedervorlage ? (row.besichtigung.wiedervorlage | date:'dd.MM.yyyy') + ' - ' + row.besichtigung.aktion : row.besichtigung.besichtigungsStatus | type:(tour.besichtigungenBesichtigungsStatus | async)"
                  >
                  </nz-avatar>
                </nz-list-item-meta-avatar>
                <nz-list-item-meta-title>
                  <nz-row>
                    <nz-col nzSpan="24" [style.color]="row.color">
                      <strong> {{ row.besichtigung?.ende?.strasse }}, {{ row.besichtigung?.ende?.plz }}</strong>
                      <span *ngIf="!row.besichtigung.rueckfahrt" style="margin-left: 16px">{{ row.projekt?.kunde | type: (offeneGutachten.projektKunde | async)}}</span>
                    </nz-col>
                    <nz-col nzSpan="24">
                      <i [style.color]="row.hasTimeConflicts ? 'red' : ''"
                        >Ankunft {{ row.besichtigung?.fahrtBis | date:"HH:mm":'':'' }}
                        <ng-container *ngIf="!row.besichtigung.rueckfahrt"> - {{ row.besichtigung?.bis | date:"HH:mm":'':'' }} Abfahrt</ng-container></i
                      >
                    </nz-col>
                    <!-- <nz-col nzSpan="24" *ngIf="!row.besichtigung.rueckfahrt">
                  <i [style.color]="row.hasTimeConflicts ? 'red' : ''"
                    >Von {{ row.besichtigung?.kommuniziertVon | date:"HH:mm":'':'' }} - {{ row.besichtigung?.kommuniziertBis | date:"HH:mm":'':'' }} Bis</i
                  >
                </nz-col> -->
                    <nz-col nzSpan="24"> {{ row.besichtigung?.fahrtstrecke / 1000 | number:"1.0-2":'de' }} km, {{ 60 * row.besichtigung?.fahrtzeit / 60 / 60 | number:"1.0-0":'de' }} Min. </nz-col>
                  </nz-row>
                </nz-list-item-meta-title>
                <nz-list-item-meta-description *ngIf="!row.besichtigung.rueckfahrt">
                  <span *ngIf="row.besichtigung.wiedervorlage || row.besichtigung.aktion">
                    <i nz-icon nzTheme="outline" nzType="alert" style="color: red"></i> {{ row.besichtigung.wiedervorlage ? (row.besichtigung.wiedervorlage | date:'dd.MM.yyyy') : ''}} {{
                    row.besichtigung.aktion }}<br
                  /></span>
                  <span *ngIf="row.besichtigung.kommentar">
                    <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                    {{ row.besichtigung.kommentar }}
                  </span>
                </nz-list-item-meta-description>
              </nz-list-item-meta>
              <ul nz-list-item-actions>
                <nz-list-item-action>
                  <a (click)="openAddress(row.besichtigung.ende)"><span nz-icon nzType="compass" nzTheme="outline"></span></a>
                </nz-list-item-action>
              </ul>
            </li>
          </ul>
        </cdk-virtual-scroll-viewport>
      </nz-spin>
    </nz-col>
    <nz-col nzLg="8" nzMd="24">
      <pbc-atlas-map
        height="calc(100vh - 280px)"
        width="100%"
        mode="addresses"
        [routes]="$routes | async"
        [coordinateArray]="$coordinates | async "
        (clicked)="editBesichtigung($event)"
      ></pbc-atlas-map>
    </nz-col>
    <nz-col nzSpan="24">
      <pbc-meta [value]="($postTour | async)?.tour"></pbc-meta>
    </nz-col>
  </nz-row>
</nz-content>
