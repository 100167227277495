import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { SavedRechnungsVorlagenFiltersComponent } from '.';
import { RechnungsVorlagenServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, RechnungsVorlagenServiceModule],
  declarations: [SavedRechnungsVorlagenFiltersComponent],
  exports: [SavedRechnungsVorlagenFiltersComponent],
})
export class SavedRechnungsVorlagenFiltersComponentModule {}
