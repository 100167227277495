import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFormularQuerySorter extends ISorter {
  'formular.id'?: ISorterExpression;
  'formular.name'?: ISorterExpression;
  'formular.initialen'?: ISorterExpression;
  'formular.objektArten'?: ISorterExpression;
  'formular.bewertungsAnlaesse'?: ISorterExpression;
  'formular.kundenArten'?: ISorterExpression;
  'formular.kunden'?: ISorterExpression;
  'formular.ohneFelder'?: ISorterExpression;
  'formular.kommentar'?: ISorterExpression;
}

export const FormularQuerySorterConfig: ISorterConfig = [
  SorterConfig('formular.id', 'FORMULARE.Models.FORMULAR.Propertys.ID.title'), 
  SorterConfig('formular.name', 'FORMULARE.Models.FORMULAR.Propertys.NAME.title'), 
  SorterConfig('formular.initialen', 'FORMULARE.Models.FORMULAR.Propertys.INITIALEN.title'), 
  SorterConfig('formular.objektArten', 'FORMULARE.Models.FORMULAR.Propertys.OBJEKT_ARTEN.title'), 
  SorterConfig('formular.bewertungsAnlaesse', 'FORMULARE.Models.FORMULAR.Propertys.BEWERTUNGS_ANLAESSE.title'), 
  SorterConfig('formular.kundenArten', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN_ARTEN.title'), 
  SorterConfig('formular.kunden', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN.title'), 
  SorterConfig('formular.ohneFelder', 'FORMULARE.Models.FORMULAR.Propertys.OHNE_FELDER.title'), 
  SorterConfig('formular.kommentar', 'FORMULARE.Models.FORMULAR.Propertys.KOMMENTAR.title'), 
];
