import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { IGutachtenStatus } from 'fa-kt.types';
import { ActionService, APP_TITLE, IPageState, ISitemap, ISitemapModel, ISitemapPage, SITEMAP } from 'pbc.angular';
import { GutachtenStatiService } from '../query';

@Component({
  selector: 'fa-kt-gutachten-stati',
  templateUrl: './gutachten-stati.page.html',
  styleUrls: ['./gutachten-stati.page.css'],
})
export class GutachtenStatiPage implements OnInit, OnDestroy {
  readonly page: ISitemapPage;
  readonly model: ISitemapModel;

  readonly subscriptions: Subscription[] = [];

  readonly $state: BehaviorSubject<IPageState> = new BehaviorSubject<IPageState>(null);
  readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly $gutachtenStatus: BehaviorSubject<{ gutachtenStatus: {} | IGutachtenStatus } | null> = new BehaviorSubject<{ gutachtenStatus: {} | IGutachtenStatus } | null>(null);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    private route: ActivatedRoute,
    private router: Router,
    private actions: ActionService,
    private modal: NzModalService,
    private message: NzMessageService,
    public gutachtenStati: GutachtenStatiService,
  ) {
    this.page = this.sitemap.GUTACHTEN.Settings.GUTACHTEN_STATI;
    this.model = this.sitemap.GUTACHTEN.Models.GUTACHTEN_STATUS;
    /* << constructor    */
    /*    constructor >> */
  }

  ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.actions.subscribe({ key: 'Zurück', action: () => window.history.back() });
    this.actions.subscribe({ key: `${this.model.single} erstellen`, action: () => this.create() });
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => this.$state.next(params['id'])),
        this.$state.subscribe((state: IPageState) => this.sync(state)),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    this.$loading.next(false);
    this.actions.unsubscribe('Zurück');
    this.actions.unsubscribe(`${this.model.single} erstellen`);
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  sync(state: IPageState) {
    this.$loading.next(true);
    if (state === 'new') {
      this.$gutachtenStatus.next({ gutachtenStatus: {} });
    } else if (state) {
      const gutachtenStati = this.gutachtenStati.response$.getValue();
      const gutachtenStatus = gutachtenStati ? gutachtenStati.gutachtenStati.find((gs: IGutachtenStatus) => gs.id === state) : null;
      this.$gutachtenStatus.next(gutachtenStatus ? { gutachtenStatus } : null);
    } else {
      this.$gutachtenStatus.next(null);
    }
    this.$loading.next(false);
  }

  async create() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id: 'new' },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async set(id: string) {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async reset() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  back() {
    window.history.back();
  }
}
