import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostDateiArtCommandShapes, IPostDateiArtRequest, IPostDateiArtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostDateiArtCommandService extends PostCommandService<IPostDateiArtRequest, IPostDateiArtResponse, IPostDateiArtCommandShapes> {
  readonly route = 'dokumente/settings/datei-art';
}
