<!---
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="excel">{{ command.Inputs.EXCEL.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.EXCEL.hint">
      <input nz-input formControlName="excel" id="excel" [placeholder]="command.Inputs.EXCEL.placeholder"/>
    </nz-form-control>
  </nz-form-item>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <nz-form-item style="width: 100%;">
      <pbc-file-upload style="width: 100%;" formControlName="excel" id="excel" (uploaded)="submit()" [icon]="'file-excel'" [text]="command.Inputs.EXCEL.title" [hint]="command.Inputs.EXCEL.hint"></pbc-file-upload>
    </nz-form-item>
<!---    custom >> -->
<!---
<button nz-button nzType="primary"
  type="submit"
  [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
  nz-tooltip [nzTooltipTitle]="SubmitTooltip">
    {{ command.translation }}
  <ng-template #SubmitTooltip>
  </ng-template>
</button>
 -->
  </form>
</nz-spin>
