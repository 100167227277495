import { Component, Inject } from '@angular/core';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IZeitArtsQueryShapes, ZeitArtsQueryFilterConfig } from 'fa-kt.types';
import { ZeitArtsService } from '../service';

@Component({
  selector: 'fa-kt-zeit-arts-filter',
  templateUrl: './zeit-arts-filter.component.html',
  styleUrls: ['./zeit-arts-filter.component.css'],
})
export class ZeitArtsFilterComponent {
  readonly subscriptions: Subscription[] = [];

  ZeitArtsFilterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public zeitArts: ZeitArtsService,
  ) {
    this.ZeitArtsFilterConfig = ZeitArtsQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.zeitArts.shapes$.subscribe((shapes: IZeitArtsQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.ZeitArtsFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.ZeitArtsFilterConfig[index].selections = shapes;
  }
}
