import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BehaviorSubject, combineLatest } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { toInitials } from 'pbc.functions';
import { ISelection } from 'pbc.types';
import { BaseComponent } from '../../../base.components';

@Component({
  selector: 'pbc-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserSelectionComponent,
    },
  ],
})
export class UserSelectionComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  onChange = (value: string[]) => {};
  onTouched = () => {};
  touched = false;
  @Input() disabled = false;

  selected$ = new BehaviorSubject<ISelection[]>([]);
  possible$ = new BehaviorSubject<ISelection[]>([]);

  values$ = new BehaviorSubject<string[]>([]);

  group: string | undefined;
  user: string | undefined;

  @Input() set value(users: string[]) {
    this.values$.next(users || []);
  }
  @Output() valueChange = new EventEmitter<string[]>();

  @Input() placeholder = 'Auswahl';
  @Input() placeholderGroups = 'Auswahl';
  @Input() mode: 'avatar' = 'avatar';
  @Input() selectGroups = true;

  override ngOnInit(): void {
    super.ngOnInit();
    combineLatest([this.meta.select$, this.values$])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([users, values]) => {
        this.selected$.next(users.filter((s) => values.includes(s.value!)));
        this.possible$.next(users.filter((s) => !values.includes(s.value!)));
      });
  }

  getMitarbeiterText(label: string, asInitialen = false) {
    if (!asInitialen || !label) return label;
    return toInitials(label.split('(')[0]);
  }

  addUser(id: string) {
    if (this.disabled) return;
    const users = this.values$.getValue();
    users.push(id);
    this.writeValue(users);
    this.user = undefined;
  }

  removeUser(id: string | string[]) {
    if (this.disabled) return;
    let users = this.values$.getValue();
    users = users.filter((u) => u !== id);
    this.writeValue(users);
    this.user = undefined;
  }

  addGroup(ids: string) {
    if (this.disabled) return;
    const selection = this.possible$.getValue();
    const members = selection.filter((s) => (s.rolle as string[])?.includes(ids));
    const users = this.values$.getValue();
    users.push(...members.filter((m) => !users.includes(m.value!)).map((m) => m.value!));
    this.writeValue(users);
    this.group = undefined;
  }

  writeValue(value: string[]) {
    this.value = value;
    this.valueChange.emit(value);
    this.onChange(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
