import { Component } from '@angular/core';
import { VorlageService } from '../service';
@Component({
  selector: 'fa-kt-saved-vorlage-filters',
  templateUrl: './saved-vorlage-filters.component.html',
  styleUrls: ['./saved-vorlage-filters.component.css'],
})
export class SavedVorlageFiltersComponent {
  constructor(public vorlage: VorlageService) {}
}
