import { Injectable } from '@angular/core';

import { IDeleteFeldKategorieRequest, IDeleteFeldKategorieResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteFeldKategorieCommandService extends DeleteCommandService<IDeleteFeldKategorieRequest, IDeleteFeldKategorieResponse> {
  readonly route = 'felder/settings/feld-kategorie';
}
