import { Component } from '@angular/core';
import { BerichtsVorlagenService } from '../service';

@Component({
  selector: 'fa-kt-saved-berichts-vorlagen-filters',
  templateUrl: './saved-berichts-vorlagen-filters.component.html',
  styleUrls: ['./saved-berichts-vorlagen-filters.component.css'],
})
export class SavedBerichtsVorlagenFiltersComponent {
  constructor(public berichtsVorlagen: BerichtsVorlagenService) {}
}
