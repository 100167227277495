import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostTemplateCommandShapes, IPostTemplateRequest, IPostTemplateResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostTemplateCommandService extends PostCommandService<IPostTemplateRequest, IPostTemplateResponse, IPostTemplateCommandShapes> {
  readonly route = 'markt/settings/template';

  override async request(payload: IPostTemplateRequest): Promise<IPostTemplateResponse | undefined> {
    return this.http.upload<IPostTemplateResponse>(this.route, payload, 'markt-templates');
  }
}
