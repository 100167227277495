import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektServiceModule } from '..';
import { ProjektSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektServiceModule
  ],
  declarations: [ProjektSorterComponent],
  exports: [ProjektSorterComponent],

})
export class ProjektSorterComponentModule { }
