import { NgModule } from '@angular/core';

import {
  DeleteRechnungCommandServiceModule,
  DeleteRechnungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteRechnungCommandServiceModule,
    DeleteRechnungCommandComponentModule
  ],
  exports: [
    DeleteRechnungCommandServiceModule,
    DeleteRechnungCommandComponentModule
  ]
})
export class DeleteRechnungCommandModule { }
