import { Injectable } from '@angular/core';
import { IPostDokumentHinterlegenCommandShapes, IPostDokumentHinterlegenRequest, IPostDokumentHinterlegenResponse } from 'fa-kt.types';
import { PostCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class PostDokumentHinterlegenCommandService extends PostCommandService<IPostDokumentHinterlegenRequest, IPostDokumentHinterlegenResponse, IPostDokumentHinterlegenCommandShapes> {
  readonly route = 'dokumente/dokument-hinterlegen';
}
