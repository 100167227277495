import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { round } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { BehaviorSubject, combineLatest, distinctUntilChanged, skip, Subscription } from 'rxjs';

import { BasePage } from 'pbc.angular';
import { getArbeitstage } from 'pbc.types';

import { IDeleteZeitRequest, IDeleteZeitResponse, IKrankheit, IMitarbeiter, IPostZeitRequest, IPostZeitResponse, IRahmenAnpassung, ITag, IUrlaub, IUrlaubsKonto } from 'fa-kt.types';
import { KonstantesService } from '../../../einstellungen';
import { MitarbeiterService } from '../../../personen';
import { ProjekteSelectService } from '../../../projekte';
import {
  DeleteKrankheitCommandService,
  DeleteRahmenAnpassungCommandService,
  DeleteUrlaubCommandService,
  DeleteZeitCommandService,
  PostKrankheitCommandService,
  PostRahmenAnpassungCommandService,
  PostUrlaubCommandService,
  PostZeitCommandService,
} from '../../commands';
import { HeuteService } from '../../heute.service';
import { TageService, KrankheitenService, KrankheitService, RahmenAnpassungenService, RahmenAnpassungService, UrlaubeService, UrlaubService, ZeitenService } from '../../querys';

@Component({
  selector: 'fa-kt-zeiten-page',
  templateUrl: './zeiten.page.html',
  styleUrls: ['./zeiten.page.css'],
})
export class ZeitenPage extends BasePage implements OnInit, OnDestroy {
  description = { context: 'ZEITEN', page: 'ZEITEN' };

  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postZeit: BehaviorSubject<IPostZeitRequest | null> = new BehaviorSubject<IPostZeitRequest | null>(null);
  public readonly $deleteZeit: BehaviorSubject<IDeleteZeitRequest | null> = new BehaviorSubject<IDeleteZeitRequest | null>(null);

  public readonly $tab = new BehaviorSubject<'zeiten' | 'tage' | 'urlaube' | 'krankheiten' | 'rahmen-anpassungen' | 'konten'>('zeiten');

  stunden_pro_tag: number;
  standard_unterstunden_rahmen: number;
  standard_überstunden_rahmen: number;
  standard_urlaubs_tage: number;

  jahr = new Date().getFullYear();
  $id = new BehaviorSubject<string>('');
  $minuten = new BehaviorSubject<number | undefined>(undefined);
  $stunden = new BehaviorSubject<number | undefined>(undefined);

  $urlaubID = new BehaviorSubject<string>('');
  $urlaub = new BehaviorSubject<IUrlaub | undefined>(undefined);
  $urlaubstage = new BehaviorSubject<number>(0);
  $offeneUrlaube = new BehaviorSubject<number>(0);

  $keineGesetzlichePause = new BehaviorSubject<number>(0);

  urlaub$ = new BehaviorSubject<IUrlaubsKonto>(undefined);

  $krankheitID = new BehaviorSubject<string>('');
  $krankheit = new BehaviorSubject<IKrankheit | undefined>(undefined);
  $krankheitstage = new BehaviorSubject<number>(0);
  $offeneKrankheiten = new BehaviorSubject<number>(0);

  $rahmenAnpassungID = new BehaviorSubject<string>('');
  $rahmenAnpassung = new BehaviorSubject<IRahmenAnpassung | undefined>(undefined);
  $offeneRahmenAnpassungen = new BehaviorSubject<number>(0);

  mitarbeiter$ = new BehaviorSubject<Array<IMitarbeiter>>([]);
  tage$ = new BehaviorSubject<Array<ITag>>([]);

  constructor(
    public heute: HeuteService,
    public mitarbeiter: MitarbeiterService,
    public projekte: ProjekteSelectService,
    public konstanten: KonstantesService,
    public zeiten: ZeitenService,
    public postZeit: PostZeitCommandService,
    public deleteZeit: DeleteZeitCommandService,
    public krankheiten: KrankheitenService,
    public krankheit: KrankheitService,
    public rahmenAnpassungen: RahmenAnpassungenService,
    public rahmenAnpassung: RahmenAnpassungService,
    public urlaube: UrlaubeService,
    public urlaub: UrlaubService,
    public postKrankheit: PostKrankheitCommandService,
    public deleteKrankheit: DeleteKrankheitCommandService,
    public postUrlaub: PostUrlaubCommandService,
    public deleteUrlaub: DeleteUrlaubCommandService,
    public postRahmenAnpassung: PostRahmenAnpassungCommandService,
    public deleteRahmenAnpassung: DeleteRahmenAnpassungCommandService,
    public tage: TageService,
  ) {
    super();
    this.page = this.sitemap[this.description.context]?.Pages[this.description.page];
  }

  override async ngOnInit() {
    await super.ngOnInit();
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {
          if (!params.tab) return this.router.navigate(this.page.breadcrumbs, { queryParams: { tab: 'zeiten' }, queryParamsHandling: 'merge' });
          this.$tab.next(params.tab);
          if (params.id) this.$id.next(params.id);
          if (params.urlaub) this.$urlaubID.next(params.urlaub);
          if (params.krankheit) this.$krankheitID.next(params.krankheit);
          if (params.rahmenAnpassung) this.$rahmenAnpassungID.next(params.rahmenAnpassung);
          return undefined;
        }),
        this.tage.result$.subscribe((tage) => {
          if (!tage?.tage) return;
          this.$keineGesetzlichePause.next(tage.tage.filter(({ tag: { keineGesetzlichePause } }) => keineGesetzlichePause).length);
        }),
        combineLatest([this.meta.users$, this.tage.result$]).subscribe(([users, tage]) => {
          if (!tage?.tage) return;
          this.tage$.next(
            tage?.tage
              ?.filter(
                ({ tag: { mitarbeiter } }) =>
                  users.find(({ value }) => value === mitarbeiter)?.aktiv &&
                  !['cbe8a646-d91d-4120-be25-9e5dc23c7df7', 'f55a7362-a259-4fdf-bc25-c674dd7e5b12', 'c281b9f5-cc7a-4230-99b4-134813818c4d'].includes(mitarbeiter),
              )
              .map(({ tag }) => tag),
          );
        }),
        this.projekte.selection$.subscribe((selection) => {
          const shapes = this.zeiten.shapes$.getValue();
          if (!selection || !shapes) return;
          shapes['zeit.projekt'] = selection;
          this.zeiten.shapes$.next(shapes);
        }),
        this.$id.pipe(skip(1), distinctUntilChanged()).subscribe((id) => this.router.navigate(this.page.breadcrumbs, { queryParams: { id, tab: 'zeiten' }, queryParamsHandling: 'merge' })),
        this.$urlaubID.pipe(distinctUntilChanged()).subscribe(async (urlaub) => {
          urlaub = urlaub?.length ? urlaub : undefined;
          if (urlaub === 'new') {
            this.$urlaub.next({
              mitarbeiter: this.auth.$id.getValue(),
              art: 'Urlaub',
              von: new Date(),
              bis: moment().add(7, 'days').toDate(),
            });
          } else if (urlaub?.length) this.$urlaub.next(await this.urlaub.request({ urlaub }).then((res) => res?.urlaub || undefined));
          else this.$urlaub.next(undefined);
          const tab = urlaub?.length ? 'urlaube' : this.$tab.getValue() || 'zeiten';
          this.router.navigate(this.page.breadcrumbs, { queryParams: { urlaub, tab, krankheit: undefined, rahmenAnpassung: undefined }, queryParamsHandling: 'merge' });
        }),
        this.$krankheitID.pipe(distinctUntilChanged()).subscribe(async (krankheit) => {
          krankheit = krankheit?.length ? krankheit : undefined;
          if (krankheit === 'new') {
            this.$krankheit.next({
              mitarbeiter: this.auth.$id.getValue(),
              von: moment().subtract(7, 'days').toDate(),
              bis: new Date(),
            });
          } else if (krankheit?.length) this.$krankheit.next(await this.krankheit.request({ krankheit }).then((res) => res?.krankheit || undefined));
          else this.$krankheit.next(undefined);
          const tab = krankheit?.length ? 'krankheiten' : this.$tab.getValue() || 'zeiten';
          this.router.navigate(this.page.breadcrumbs, { queryParams: { krankheit, tab, urlaub: undefined, rahmenAnpassung: undefined }, queryParamsHandling: 'merge' });
        }),
        this.$rahmenAnpassungID.pipe(distinctUntilChanged()).subscribe(async (rahmenAnpassung) => {
          rahmenAnpassung = rahmenAnpassung?.length ? rahmenAnpassung : undefined;
          if (rahmenAnpassung === 'new') {
            const stunden = this.router.routerState.snapshot.root.queryParamMap.get('stunden');
            this.$rahmenAnpassung.next({
              mitarbeiter: this.auth.$id.getValue(),
              stunden: stunden ? Number(stunden) : undefined,
            });
          } else if (rahmenAnpassung?.length) this.$rahmenAnpassung.next(await this.rahmenAnpassung.request({ rahmenAnpassung }).then((res) => res?.rahmenAnpassung || undefined));
          else this.$rahmenAnpassung.next(undefined);
          const tab = rahmenAnpassung?.length ? 'rahmen-anpassungen' : this.$tab.getValue() || 'zeiten';
          this.router.navigate(this.page.breadcrumbs, { queryParams: { rahmenAnpassung, tab, urlaub: undefined, krankheit: undefined }, queryParamsHandling: 'merge' });
        }),
        this.konstanten.response$.subscribe((result) => {
          this.stunden_pro_tag = Number(result?.konstantes.find(({ art }) => art === 'stunden_pro_tag')?.inhalt) || 8;
          this.standard_unterstunden_rahmen = Number(result?.konstantes.find(({ art }) => art === 'standard_unterstunden_rahmen')?.inhalt) || 4;
          this.standard_überstunden_rahmen = Number(result?.konstantes.find(({ art }) => art === 'standard_überstunden_rahmen')?.inhalt) || 20;
          this.standard_urlaubs_tage = Number(result?.konstantes.find(({ art }) => art === 'standard_urlaubs_tage')?.inhalt) || 20;
        }),
        this.mitarbeiter.result$.subscribe((result) => {
          if (!result) return;
          this.mitarbeiter$.next(
            result.mitarbeiter
              .filter(
                ({ mitarbeiter: { id, inaktiv } }) =>
                  !inaktiv && !['cbe8a646-d91d-4120-be25-9e5dc23c7df7', 'f55a7362-a259-4fdf-bc25-c674dd7e5b12', 'c281b9f5-cc7a-4230-99b4-134813818c4d'].includes(id),
              )
              .map(({ mitarbeiter }) => mitarbeiter),
          );
        }),
        this.zeiten.result$.subscribe((result) => {
          if (!result) return;
          const stunden = result.zeiten.map((row) => row.zeit).reduce((n, { stunden }) => n + (!stunden || isNaN(stunden) ? 0 : stunden), 0);
          const minuten = stunden * 60 + result.zeiten.map((row) => row.zeit).reduce((n, { minuten }) => n + (!minuten || isNaN(minuten) ? 0 : minuten), 0);
          this.$stunden.next(Math.floor(minuten / 60));
          this.$minuten.next(round(minuten % 60, 0));
        }),
        this.urlaube.result$.subscribe((result) => {
          if (!result) return;
          this.$urlaubstage.next(result.urlaube.map((row) => row.urlaub).reduce((n, { von, bis }) => n + (getArbeitstage(von, bis) || 1), 0));
          this.$offeneUrlaube.next(result.urlaube.filter(({ urlaub: { angenommen } }) => !angenommen).length);
        }),
        this.krankheiten.result$.subscribe((result) => {
          if (!result) return;
          this.$krankheitstage.next(result.krankheiten.map((row) => row.krankheit).reduce((n, { von, bis }) => n + (getArbeitstage(von, bis) || 1), 0));
          this.$offeneKrankheiten.next(result.krankheiten.filter(({ krankheit: { bescheinigt } }) => !bescheinigt).length);
        }),
        this.rahmenAnpassungen.result$.subscribe((result) => {
          if (!result) return;
          this.$offeneRahmenAnpassungen.next(result.rahmenAnpassungen.filter(({ rahmenAnpassung: { angenommen } }) => !angenommen).length);
        }),
        this.heute.response$.subscribe((heute) => {
          this.urlaub$.next(heute?.urlaub);
        }),
      ],
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setZeiten(id: string) {
    this.$id.next(id);
  }

  async finishedPostZeit(response?: IPostZeitResponse) {}

  async finishedDeleteZeit(response?: IDeleteZeitResponse) {}

  back() {
    window.history.back();
  }

  get jahre(): number[] {
    const current = new Date().getFullYear();
    const jahre: number[] = [current];
    for (let i = 1; i < 30; i++) {
      jahre.push(current - i);
    }
    return jahre;
  }

  public async submitPostZeit(request?: IPostZeitRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postZeit.getValue();
    if (payload) {
      try {
        const response = await this.postZeit.request(payload);
        await this.finishedPostZeit(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteZeit(request?: IDeleteZeitRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteZeit.getValue();
    if (payload) {
      try {
        const response = await this.deleteZeit.request(payload);
        await this.finishedDeleteZeit(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  async loadZeiten(jahr: number, force = false) {
    const person = this.auth.$id.getValue();
    await Promise.all([
      this.tage.request({ jahr, person: this.auth.$isFinanzen.getValue() ? undefined : person }, force),
      this.zeiten.request({ jahr, person: this.auth.$isFinanzen.getValue() ? undefined : person }, force),
      this.urlaube.request({ jahr, person: this.auth.$isUrlaubsAdmin.getValue() ? undefined : person }, force),
      this.krankheiten.request({ jahr, person: this.auth.$isOM.getValue() ? undefined : person }, force),
      this.rahmenAnpassungen.request({ person: this.auth.$isOM.getValue() || this.auth.$isPartner.getValue() ? undefined : person }, force),
    ]);
  }

  getArbeitstage(von: Date, bis: Date) {
    return getArbeitstage(von, bis);
  }

  async createKrankheit() {
    this.$krankheitID.next('new');
  }

  async openKrankheit(id?: string) {
    if (!id) this.krankheit.payload$.next(undefined);
    this.$krankheitID.next(id);
  }

  async removeKrankheit() {
    await this.deleteKrankheit.request({ id: this.$krankheitID.getValue() });
    await this.openKrankheit();
  }

  async createUrlaub() {
    this.$urlaubID.next('new');
  }

  async openUrlaub(id?: string) {
    if (!id) this.urlaub.payload$.next(undefined);
    this.$urlaubID.next(id);
  }

  async removeUrlaub() {
    await this.deleteUrlaub.request({ id: this.$urlaubID.getValue() });
    await this.openUrlaub();
  }

  async createRahmenAnpassung() {
    this.$rahmenAnpassungID.next('new');
  }

  async openRahmenAnpassung(id?: string) {
    if (!id) this.rahmenAnpassung.payload$.next(undefined);
    this.$rahmenAnpassungID.next(id);
  }

  async removeRahmenAnpassung() {
    await this.deleteRahmenAnpassung.request({ id: this.$rahmenAnpassungID.getValue() });
    await this.openRahmenAnpassung();
  }
}
