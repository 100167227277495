import { Component } from '@angular/core';
import { RahmenAnpassungenService } from '.';

@Component({
  selector: 'fa-kt-rahmen-anpassungen-presets',
  template: `
    <pbc-filter-preset
      [filter]="rahmenAnpassungen.preset$ | async"
      [filters]="rahmenAnpassungen.presets$ | async"
      [name]="rahmenAnpassungen.search$ | async"
      (select)="rahmenAnpassungen.setFilter($event)"
      (save)="rahmenAnpassungen.writeFilter($event)"
      (delete)="rahmenAnpassungen.deleteFilter($event)"
    ></pbc-filter-preset>
  `,
})
export class RahmenAnpassungenPresetsComponent {
  constructor(public rahmenAnpassungen: RahmenAnpassungenService) {}
}
