import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BasePostCommandComponent } from 'pbc.angular';

import { IPostFondRequest, IPostFondResponse } from 'fa-kt.types';
import { PostFondCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-fond',
  templateUrl: './post-fond.component.html',
  styleUrls: ['./post-fond.component.css'],
})
export class PostFondCommandComponent extends BasePostCommandComponent<IPostFondRequest, IPostFondResponse> implements OnInit, OnDestroy {
  description = { context: 'KUNDEN', command: 'POST_FOND' };
  contentKey: keyof IPostFondRequest = 'fond';

  constructor(public postFond: PostFondCommandService) {
    super();
    this.form = this.fb.group({
      fond: this.fb.group({
        id: [null, []],
        kunde: [null, [Validators.required]],
        name: [null, [Validators.required]],
        aktiv: [null, []],
      }),
    });
    this.patch();
    try {
      if (this.data) this.patch(this.data);
    } catch (e) {}
  }

  async prepare(): Promise<void> {
    await this.postFond.prepare();
  }

  async request(payload: IPostFondRequest): Promise<IPostFondResponse> {
    return this.postFond.request(payload);
  }

  get fondId() {
    return this.form.controls.fond.get('id');
  }
  get fondKunde() {
    return this.form.controls.fond.get('kunde');
  }
  get fondName() {
    return this.form.controls.fond.get('name');
  }
  get fondAktiv() {
    return this.form.controls.fond.get('aktiv');
  }
}
