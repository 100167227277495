import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostFondCommandShapes, IPostFondRequest, IPostFondResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostFondCommandService extends PostCommandService<IPostFondRequest, IPostFondResponse, IPostFondCommandShapes> {
  readonly route = 'kunden/fond';
}
