import { NgModule } from '@angular/core';

import { FilterPipe, LIB_CommonModule, SearchPipe, SorterPipe } from 'pbc.angular';

import { RechnungsVorlagenService } from '.';

@NgModule({
  imports: [LIB_CommonModule],
  providers: [RechnungsVorlagenService, SearchPipe, FilterPipe, SorterPipe],
  exports: [],
})
export class RechnungsVorlagenServiceModule {}
