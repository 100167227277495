import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostReaktivierenCommandShapes, IPostReaktivierenRequest, IPostReaktivierenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostReaktivierenCommandService extends PostCommandService<IPostReaktivierenRequest, IPostReaktivierenResponse, IPostReaktivierenCommandShapes> {
  readonly route = 'projekte/reaktivieren';
}
