import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pbc-search-bar',
  template: `
    <nz-row>
      <nz-col nzSpan="24">
        <nz-input-group [nzPrefix]="prefixIconSearch" [nzSuffix]="suffixIconClear" style="width: 100%" nzBorderless>
          <ng-template #prefixIconSearch>
            <i nz-icon nzType="search"></i>
          </ng-template>
          <ng-template #suffixIconClear>
            <i *ngIf="_search" nz-icon nzType="close-circle" (click)="clear()"></i>
          </ng-template>
          <input nz-input nzBorderless type="text" (focus)="focused.emit()" [ngModel]="_search" (ngModelChange)="searchChange.emit($event)" [placeholder]="placeholder" cdkFocusInitial />
        </nz-input-group>
      </nz-col>
    </nz-row>
  `,
  styles: [],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder = 'Suche'; //  ⌨💨

  @Input() set search(term: string | undefined | null) {
    this._search = term || '';
  }
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  _search = '';

  @Output() focused: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  clear() {
    this._search = '';
    this.searchChange.emit(this._search || '');
  }
}
