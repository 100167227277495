import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { IMitarbeiterQueryShapes, IMitarbeiterResponse, MitarbeiterQueryFilterConfig } from 'fa-kt.types';

import { MitarbeiterService } from '../service';

@Component({
  selector: 'fa-kt-mitarbeiter-filter',
  templateUrl: './mitarbeiter-filter.component.html',
  styleUrls: ['./mitarbeiter-filter.component.css'],
})
export class MitarbeiterFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public MitarbeiterFilterConfig: IFilterConfig<IMitarbeiterResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public mitarbeiter: MitarbeiterService,
  ) {
    this.MitarbeiterFilterConfig = MitarbeiterQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    this.MitarbeiterFilterConfig = this.MitarbeiterFilterConfig.map((filter) =>
      filter.key === 'mitarbeiter.isAdmin' ? { ...filter, title: 'Administrator (Mitarbeiter)' } : filter.key === 'rollen.isAdmin' ? { ...filter, title: 'Administrator (Rolle)' } : filter,
    );
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.mitarbeiter.shapes$.subscribe((shapes: IMitarbeiterQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.MitarbeiterFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.MitarbeiterFilterConfig[index].selections = shapes;
  }
}
