import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { IProjektArt } from 'fa-kt.types';
import { ActionService, APP_TITLE, IPageState, ISitemap, ISitemapModel, ISitemapPage, SITEMAP } from 'pbc.angular';
import { ProjektArtsService } from '../query';

@Component({
  selector: 'fa-kt-projekt-arts',
  templateUrl: './projekt-arts.page.html',
  styleUrls: ['./projekt-arts.page.css'],
})
export class ProjektArtsPage implements OnInit, OnDestroy {
  readonly page: ISitemapPage;
  readonly model: ISitemapModel;

  readonly subscriptions: Subscription[] = [];

  readonly $state: BehaviorSubject<IPageState> = new BehaviorSubject<IPageState>(null);
  readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly $projektArt: BehaviorSubject<{ projektArt: {} | IProjektArt } | null> = new BehaviorSubject<{ projektArt: {} | IProjektArt } | null>(null);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    private route: ActivatedRoute,
    private router: Router,
    private actions: ActionService,
    private modal: NzModalService,
    private message: NzMessageService,
    public projektArts: ProjektArtsService,
  ) {
    this.page = this.sitemap.PROJEKTE.Settings.PROJEKT_ARTS;
    this.model = this.sitemap.PROJEKTE.Models.PROJEKT_ART;
    /* << constructor    */
    /*    constructor >> */
  }

  ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.actions.subscribe({ key: 'Zurück', action: () => window.history.back() });
    this.actions.subscribe({ key: `${this.model.single} erstellen`, action: () => this.create() });
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => this.$state.next(params['id'])),
        this.$state.subscribe((state: IPageState) => this.sync(state)),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    this.$loading.next(false);
    this.actions.unsubscribe('Zurück');
    this.actions.unsubscribe(`${this.model.single} erstellen`);
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  sync(state: IPageState) {
    this.$loading.next(true);
    if (state === 'new') {
      this.$projektArt.next({ projektArt: {} });
    } else if (state) {
      const projektArts = this.projektArts.response$.getValue();
      const projektArt = projektArts ? projektArts.projektArts.find((pa: IProjektArt) => pa.id === state) : null;
      this.$projektArt.next(projektArt ? { projektArt } : null);
    } else {
      this.$projektArt.next(null);
    }
    this.$loading.next(false);
  }

  async create() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id: 'new' },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async set(id: string) {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async reset() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  back() {
    window.history.back();
  }
}
