import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SettingsQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import {
  IErforderlichesDokumentsQueryFilter,
  IErforderlichesDokumentsQueryShapes,
  IErforderlichesDokumentsQuerySorter,
  IErforderlichesDokumentsRequest,
  IErforderlichesDokumentsResponse,
} from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ErforderlichesDokumentsService extends SettingsQueryService<
  IErforderlichesDokumentsRequest,
  IErforderlichesDokumentsResponse,
  IErforderlichesDokumentsQueryShapes,
  IErforderlichesDokumentsQueryFilter,
  IErforderlichesDokumentsQuerySorter
> {
  contentKey: keyof IErforderlichesDokumentsResponse = 'erforderlichesDokuments';
  route = 'dokumente/settings/erforderliches-dokuments';
  override dependencies = [
    'dokumente/written-erforderliches-dokument',
    'dokumente/removed-erforderliches-dokument',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
  ];

  async getErforderlichesDokumentsGutachten(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.erforderlichesDokumentsGutachten.pipe(first()).subscribe((erforderlichesDokumentsGutachten) => resolve(erforderlichesDokumentsGutachten.find((a) => a.value === value)));
    });
  }

  get erforderlichesDokumentsGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erforderlichesDokuments.gutachten'] as ISelection[]) : [])));
  }
}
