import { NgModule } from '@angular/core';
import {
  ObjektArtsFilterComponentModule,
  ObjektArtsSorterComponentModule,
  ObjektArtsListComponentModule
} from '.';

@NgModule({
  imports: [
    ObjektArtsFilterComponentModule,
    ObjektArtsSorterComponentModule,
    ObjektArtsListComponentModule
  ],
  exports: [
    ObjektArtsFilterComponentModule,
    ObjektArtsSorterComponentModule,
    ObjektArtsListComponentModule
  ],
})
export class ObjektArtsQueryModule { }