import { IFilter, IFilterConfig } from 'pbc.types';

import { IGutachten } from '.';

export interface IGutachtenFilter extends IFilter<IGutachten> {}

export const GutachtenFilterConfig: IFilterConfig<IGutachten> = [];

export type IGutachtenField =
  | 'gutachten.id'
  | 'gutachten.projekt'
  | 'gutachten.objekt'
  | 'gutachten.bewertungsStatus'
  | 'gutachten.objektNutzung'
  | 'gutachten.objektArt'
  | 'gutachten.sicherheitscheck'
  | 'gutachten.abgabeDraft'
  | 'gutachten.abgabeFinal'
  | 'gutachten.erbbaurecht'
  | 'gutachten.wohnenJRoE'
  | 'gutachten.wohnenJRoEFlaeche'
  | 'gutachten.gewerbeJRoE'
  | 'gutachten.gewerbeJRoEFlaeche'
  | 'gutachten.bueroJRoE'
  | 'gutachten.bueroJRoEFlaeche'
  | 'gutachten.handelJRoE'
  | 'gutachten.handelJRoEFlaeche'
  | 'gutachten.lagerJRoE'
  | 'gutachten.lagerJRoEFlaeche'
  | 'gutachten.sonstigesJRoE'
  | 'gutachten.sonstigesJRoEFlaeche'
  | 'gutachten.baujahr'
  | 'gutachten.modernisierung'
  | 'gutachten.marktwert'
  | 'gutachten.stichtagMarktwert'
  | 'gutachten.kaufpreis'
  | 'gutachten.stichtagKaufpreis'
  | 'gutachten.fortschritt'
  | 'gutachten.aktiv'
  | 'gutachten.kommentar'
  | 'gutachten.tourenplanung';
