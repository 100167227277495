import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import {
  IBerichtsVorlagenRequest,
  IBerichtsVorlagenResponse,
  IFelderRequest,
  IFelderResponse,
  IGutachtenRequest,
  IGutachtenResponse,
  IVorlagenRequest,
  IVorlagenResponse,
  IVorlageRequest,
  IVorlageResponse,
} from 'fa-kt.types';
import { PostTourCommandService } from '../../../besichtigungen';
import { ErforderlichesDokumentsService } from '../../../dokumente';
import { FelderService } from '../../../felder';
import { FormulareService } from '../../../formulare';
import { OrtKategoriesService, OrtsService } from '../../../geo';
import { PostKundeCommandService } from '../../../kunden';
import { StandortsService } from '../../../personen';
import { ProjekteService } from '../../../projekte';
import { PostVorlageCommandService } from '../../commands';
import { BerichtsVorlagenService, VorlagenService, VorlageService } from '../../querys';

export interface IVorlagePageResolverResult {
  berichtsVorlagen?: IBerichtsVorlagenResponse | void;
  felder?: IFelderResponse | void;
  gutachten?: IGutachtenResponse | void;
  vorlage?: IVorlageResponse | void;
  vorlagen?: IVorlagenResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class VorlagePageResolver implements Resolve<IVorlagePageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private berichtsVorlagen: BerichtsVorlagenService,
    private felder: FelderService,
    private vorlage: VorlageService,
    private vorlagen: VorlagenService,
    private postVorlage: PostVorlageCommandService,
    private postKunde: PostKundeCommandService,
    private postTour: PostTourCommandService,
    private formulare: FormulareService,
    public projekte: ProjekteService,
    public orts: OrtsService,
    public ortKategories: OrtKategoriesService,
    public standorte: StandortsService,
    public erforderlicheDokumente: ErforderlichesDokumentsService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IVorlagePageResolverResult> {
    this.loading.resolving();
    const requests = {
      berichtsVorlagen: {} as IBerichtsVorlagenRequest,
      felder: {} as IFelderRequest,
      gutachten: {} as IGutachtenRequest,
      vorlage: {} as IVorlageRequest,
      vorlagen: {} as IVorlagenRequest,
    };
    const log = (error: any) => console.error(error);
    const id = route.queryParams['id'];
    requests.vorlage.id = id;
    if (requests.vorlage.id === 'new') {
      this.vorlage.result$.next(undefined);
      await Promise.all([
        this.berichtsVorlagen.request(requests.berichtsVorlagen).catch(log),
        this.felder.request(requests.felder).catch(log),
        this.projekte.request(this.projekte.payload$.getValue() || {}).catch(log),
        this.vorlagen.request(requests.vorlagen).catch(log),
        this.postVorlage.prepare().catch(log),
        this.formulare.request({}).catch(log),
        this.orts.request({}).catch(log),
        this.ortKategories.request({}).catch(log),
        this.standorte.request({}).catch(log),
        this.postKunde.prepare().catch(log),
        this.postTour.prepare().catch(log),
        this.erforderlicheDokumente.request({}).catch(log),
      ]);
      this.loading.resolved();
      return {
        vorlage: {
          vorlage: {},
        } as unknown as IVorlageResponse,
      };
    }
    const [berichtsVorlagen, felder, vorlage, vorlagen] = await Promise.all([
      this.berichtsVorlagen.request(requests.berichtsVorlagen).catch(log),
      this.felder.request(requests.felder).catch(log),
      this.vorlage.request(requests.vorlage).catch(log),
      this.vorlagen.request(requests.vorlagen).catch(log),
      this.postVorlage.prepare().catch(log),
      this.formulare.request({}).catch(log),
      this.projekte.request(this.projekte.payload$.getValue() || {}).catch(log),
      this.orts.request({}).catch(log),
      this.ortKategories.request({}).catch(log),
      this.standorte.request({}).catch(log),
      this.postKunde.prepare().catch(log),
      this.postTour.prepare().catch(log),
      this.erforderlicheDokumente.request({}).catch(log),
    ]);
    this.loading.resolved();
    return {
      berichtsVorlagen,
      felder,
      vorlage,
      vorlagen,
    };
  }
}
