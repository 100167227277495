import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BewertungsAnlasssServiceModule } from '..';
import { BewertungsAnlasssFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BewertungsAnlasssServiceModule
  ],
  declarations: [BewertungsAnlasssFilterComponent],
  exports: [BewertungsAnlasssFilterComponent],

})
export class BewertungsAnlasssFilterComponentModule { }
