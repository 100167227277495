import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { TexteCommandsModule } from './commands';
import { TexteComponentsModule } from './components';
import { GeneratorService } from './generator.service';
import { VorlagenPage, VorlagenPageResolver, VorlagePage, VorlagePageResolver } from './pages';
import { BerichtsVorlagenService, TexteQuerysModule, VorlagenService, VorlageService } from './querys';

const routes: Routes = [
  {
    path: 'texte',
    children: [
      {
        path: 'vorlagen',
        component: VorlagenPage,
        resolve: {
          querys: VorlagenPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['texte'],
            roles: ['GET_VORLAGEN'],
          },
        },
      },
      {
        path: 'vorlage',
        component: VorlagePage,
        resolve: {
          querys: VorlagePageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['texte'],
            roles: [
              'GET_BERICHTS_VORLAGEN',
              'GET_FELDER',
              'GET_GUTACHTEN',
              'GET_PROJEKTE',
              'GET_VORLAGE',
              'GET_VORLAGEN',
              'DELETE_BERICHTS_VORLAGE',
              'DELETE_VORLAGE',
              'POST_BERICHTS_VORLAGE',
              'POST_VORLAGE',
            ],
          },
        },
      },
    ],
  },
];

@NgModule({
  declarations: [VorlagenPage, VorlagePage],
  imports: [LibsCommonAngularModule, TexteComponentsModule, TexteCommandsModule, TexteQuerysModule, RouterModule.forChild(routes)],
  exports: [TexteComponentsModule, TexteCommandsModule, TexteQuerysModule],
})
export class TexteModule {
  static forRoot(): ModuleWithProviders<TexteModule> {
    return {
      ngModule: TexteModule,
      providers: [GeneratorService, BerichtsVorlagenService, VorlageService, VorlagenService],
    };
  }
}
