<!---
  <div formGroupName="tour">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzRequired nzFor="tourStatus">{{ command.Inputs.TOUR_STATUS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourStatusErrorTip">
        <ng-template #TourStatusErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOUR_STATUS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="tourStatus" id="tourStatus" [shapes]="postTour.tourTourStatus | async" [placeholder]="command.Inputs.TOUR_STATUS.placeholder" cdkFocusInitial required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzRequired nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
        <ng-template #MitarbeiterErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="postTour.tourMitarbeiter | async" [placeholder]="command.Inputs.MITARBEITER.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzRequired nzFor="am">{{ command.Inputs.AM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AmErrorTip">
        <ng-template #AmErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="am" id="am"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="startIstPrivatAdresse">{{ command.Inputs.START_IST_PRIVAT_ADRESSE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StartIstPrivatAdresseErrorTip">
        <ng-template #StartIstPrivatAdresseErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.START_IST_PRIVAT_ADRESSE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="startIstPrivatAdresse" id="startIstPrivatAdresse"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="endeIstPrivatAdresse">{{ command.Inputs.ENDE_IST_PRIVAT_ADRESSE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="EndeIstPrivatAdresseErrorTip">
        <ng-template #EndeIstPrivatAdresseErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ENDE_IST_PRIVAT_ADRESSE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="endeIstPrivatAdresse" id="endeIstPrivatAdresse"></label>
      </nz-form-control>
    </nz-form-item>
    <pbc-address-input formControlName="start" id="start" required="false" ></pbc-address-input>
    <pbc-address-input formControlName="ende" id="ende" required="false" ></pbc-address-input>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="strecke">{{ command.Inputs.STRECKE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StreckeErrorTip">
        <ng-template #StreckeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STRECKE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="strecke" id="strecke" [nzPlaceHolder]="command.Inputs.STRECKE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="kosten">{{ command.Inputs.KOSTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KostenErrorTip">
        <ng-template #KostenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOSTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="kosten" id="kosten" [nzPlaceHolder]="command.Inputs.KOSTEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="abgerechnet">{{ command.Inputs.ABGERECHNET.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbgerechnetErrorTip">
        <ng-template #AbgerechnetErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABGERECHNET.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="abgerechnet" id="abgerechnet"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label  [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <div formGroupName="tour">
      <input class="cdk-visually-hidden" formControlName="id" id="id1" />
      <input class="cdk-visually-hidden" formControlName="start" id="start" />
      <input class="cdk-visually-hidden" formControlName="ende" id="ende" />
      <input class="cdk-visually-hidden" formControlName="kosten" id="kosten" />
      <input class="cdk-visually-hidden" formControlName="strecke" id="strecke" />
      <input class="cdk-visually-hidden" formControlName="abgerechnet" id="abgerechnet" />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzRequired nzFor="mitarbeiter">Besi. + Ver.</nz-form-label>
        <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
          <ng-template #MitarbeiterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="mitarbeiter"
            id="mitarbeiter"
            [shapes]="postTour.tourMitarbeiter | async"
            [placeholder]="command.Inputs.MITARBEITER.placeholder"
            required="true"
          ></pbc-select-value>
        </nz-form-control>
        <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="VerantwortlichErrorTip">
          <ng-template #VerantwortlichErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="verantwortlich" id="verantwortlich" [shapes]="postTour.tourMitarbeiter | async" [placeholder]="'Verantwortlich'"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzRequired nzFor="am">Am + Status</nz-form-label>
        <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="AmErrorTip">
          <ng-template #AmErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte ein Datum hinterlegen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="dd.MM.yyyy HH:mm" formControlName="am" id="am-date"></nz-date-picker>
        </nz-form-control>
        <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="TourStatusErrorTip">
          <ng-template #TourStatusErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOUR_STATUS.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="tourStatus"
            id="tourStatus"
            [shapes]="postTour.tourTourStatus | async"
            [placeholder]="command.Inputs.TOUR_STATUS.placeholder"
            cdkFocusInitial
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="startIstPrivatAdresse">Privatadressen</nz-form-label>
        <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="PrivatAdresseErrorTip">
          <ng-template #PrivatAdresseErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.START_IST_PRIVAT_ADRESSE?.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="startIstPrivatAdresse" id="startIstPrivatAdresse">
            <span> {{ tourStartIstPrivatAdresse?.value ? mitarbeiterAdresse : standortAdresse }}</span>
          </label>
        </nz-form-control>
        <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="PrivatAdresseErrorTip">
          <ng-template #PrivatAdresseErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ENDE_IST_PRIVAT_ADRESSE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="endeIstPrivatAdresse" id="endeIstPrivatAdresse">
            <span> {{ tourEndeIstPrivatAdresse?.value ? mitarbeiterAdresse : standortAdresse }}</span>
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="0" [nzSm]="5" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title }}</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Tour speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            {{ command.translation }}
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
