import { NgModule } from '@angular/core';

import { DeleteKundenGruppeCommandModule, KundenGruppesQueryModule, PostKundenGruppeCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostKundenGruppeCommandModule, DeleteKundenGruppeCommandModule, KundenGruppesQueryModule],
  exports: [PostKundenGruppeCommandModule, DeleteKundenGruppeCommandModule, KundenGruppesQueryModule],
})
export class KundenGruppesSettingsModule {}
