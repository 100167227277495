import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IRechnungsVorlagenQueryFilter, IRechnungsVorlagenQueryShapes, IRechnungsVorlagenQuerySorter, IRechnungsVorlagenRequest, IRechnungsVorlagenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class RechnungsVorlagenService extends ListQueryService<
  IRechnungsVorlagenRequest,
  IRechnungsVorlagenResponse,
  IRechnungsVorlagenQueryShapes,
  IRechnungsVorlagenQueryFilter,
  IRechnungsVorlagenQuerySorter
> {
  contentKey: keyof IRechnungsVorlagenResponse = 'rechnungsVorlagen';
  route = 'finanzen/rechnungs-vorlagen';
  override dependencies = ['finanzen/rechnungs-vorlage-geloescht', 'finanzen/rechnungs-vorlage-gespeichert'];
}
