import { NgModule } from '@angular/core';

import {
  DeleteStandortCommandServiceModule,
  DeleteStandortCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteStandortCommandServiceModule,
    DeleteStandortCommandComponentModule
  ],
  exports: [
    DeleteStandortCommandServiceModule,
    DeleteStandortCommandComponentModule
  ]
})
export class DeleteStandortCommandModule { }
