import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteGutachtenStatusRequest, IDeleteGutachtenStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteGutachtenStatusCommandService extends DeleteCommandService<IDeleteGutachtenStatusRequest, IDeleteGutachtenStatusResponse> {
  readonly route = 'gutachten/settings/gutachten-status';
}
