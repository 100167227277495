import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DetailQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IProfilQueryShapes, IProfilRequest, IProfilResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ProfilService extends DetailQueryService<IProfilRequest, IProfilResponse, IProfilQueryShapes> {
  route = 'personen/profil';
  override dependencies = [
    'personen/written-rolle',
    'personen/removed-rolle',
    'personen/written-standort',
    'personen/removed-standort',
    'personen/mitarbeiter-aktualisiert',
    'personen/azure-active-directory-synchronisiert',
    'zeiten/urlaub-gespeichert',
    'zeiten/urlaub-entfernt',
    'zeiten/krankheit-gespeichert',
    'zeiten/krankheit-entfernt',
    'zeiten/rahmen-anpassung-gespeichert',
    'zeiten/rahmen-anpassung-entfernt',
    'zeiten/zeit-gespeichert',
    'zeiten/zeit-entfernt',
  ];

  getMitarbeiterRollen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['mitarbeiter.rollen']?.find((v) => v.value === value);
  }

  get mitarbeiterRollen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['mitarbeiter.rollen'] as ISelection[]) : [])));
  }

  getMitarbeiterStandort(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['mitarbeiter.standort']?.find((v) => v.value === value);
  }

  get mitarbeiterStandort(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['mitarbeiter.standort'] as ISelection[]) : [])));
  }
}
