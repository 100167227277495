import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { BewertungsAnlasssQuerySorterConfig } from 'fa-kt.types';
import { BewertungsAnlasssService } from '../service';

@Component({
  selector: 'fa-kt-bewertungs-anlasss-sorter',
  templateUrl: './bewertungs-anlasss-sorter.component.html',
  styleUrls: ['./bewertungs-anlasss-sorter.component.css'],
})
export class BewertungsAnlasssSorterComponent {
  BewertungsAnlasssSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public bewertungsAnlasss: BewertungsAnlasssService,
  ) {
    this.BewertungsAnlasssSorterConfig = BewertungsAnlasssQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    /*    update >> */
  }
}
