import { NgModule } from '@angular/core';

import {
  PostOrtCommandServiceModule,
  PostOrtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostOrtCommandServiceModule,
    PostOrtCommandComponentModule
  ],
  exports: [
    PostOrtCommandServiceModule,
    PostOrtCommandComponentModule
  ]
})
export class PostOrtCommandModule { }
