import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteObjektNutzungCommandServiceModule } from '..';
import { DeleteObjektNutzungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteObjektNutzungCommandServiceModule
  ],
  declarations: [DeleteObjektNutzungCommandComponent],
  exports: [DeleteObjektNutzungCommandComponent],

})
export class DeleteObjektNutzungCommandComponentModule { }
