import { NgModule } from '@angular/core';
import {
  ExtraVereinbarungenComponentModule,
  HonorarVereinbarungenComponentModule,
  RechnungenComponentModule,
  RechnungszaehlerComponentModule,
  ZahlungseingaengeComponentModule
 } from '.';

@NgModule({
  imports: [
    ExtraVereinbarungenComponentModule,
    HonorarVereinbarungenComponentModule,
    RechnungenComponentModule,
    RechnungszaehlerComponentModule,
    ZahlungseingaengeComponentModule
  ],
  exports: [
    ExtraVereinbarungenComponentModule,
    HonorarVereinbarungenComponentModule,
    RechnungenComponentModule,
    RechnungszaehlerComponentModule,
    ZahlungseingaengeComponentModule
  ]
})
export class FinanzenComponentsModule { }
