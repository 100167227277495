import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IRechnungZaehlerSorter extends ISorter {
  id?: ISorterExpression;
  zaehler?: ISorterExpression;
}

export const RechnungZaehlerSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('zaehler', 'Zaehler')
];
