import { IFilter, IFilterConfig } from "pbc.types";

import { IErforderlichesDokument } from '.';

export interface IErforderlichesDokumentFilter extends IFilter<IErforderlichesDokument> {}

export const ErforderlichesDokumentFilterConfig: IFilterConfig<IErforderlichesDokument> = [];

export type IErforderlichesDokumentField =
  'erforderlichesDokument.id'|
  'erforderlichesDokument.original'|
  'erforderlichesDokument.gutachten'|
  'erforderlichesDokument.name'|
  'erforderlichesDokument.erforderlich'|
  'erforderlichesDokument.fortschritt'|
  'erforderlichesDokument.kommentar'
;
