import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { ZeitUnterartsQuerySorterConfig } from 'fa-kt.types';
import { ZeitUnterartsService } from '../service';

@Component({
  selector: 'fa-kt-zeit-unterarts-sorter',
  templateUrl: './zeit-unterarts-sorter.component.html',
  styleUrls: ['./zeit-unterarts-sorter.component.css'],
})
export class ZeitUnterartsSorterComponent {
  ZeitUnterartsSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public zeitUnterarts: ZeitUnterartsService,
  ) {
    this.ZeitUnterartsSorterConfig = ZeitUnterartsQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    /*    update >> */
  }
}
