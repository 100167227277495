<nz-row nzJustify="end">
  <nz-col *ngIf="!($sortable | async)" nzFlex>
    <button nz-button nzType="primary" (click)="reorder()">
      <i nz-icon nzType="save" nzTheme="outline"></i>
      Reihenfolge übernehmen
    </button>
  </nz-col>
</nz-row>
<nz-row>
  <nz-col nzSpan="24">
    <nz-list *ngIf="feldKategories.result$ | async" nzSize="large" [nzLoading]="$loading | async" cdkDropList (cdkDropListDropped)="reorder($event)">
      <nz-list-item *ngFor="let feldKategorie of (feldKategories.result$ | async).feldKategories" (click)="select.emit(feldKategorie)" cdkDrag class="drag-container">
        <nz-list-item-meta [nzDescription]="ListDescription">
          <ng-template #ListDescription>
            <!--- << description    -->
            <!---    description >> -->
          </ng-template>
          <nz-list-item-meta-title>
            <a>
              <!--- << content    -->
              <pbc-color-icon [color]="feldKategorie.color" [icon]="feldKategorie.icon"></pbc-color-icon>
              <b>{{ feldKategorie.initialen }}</b>
              <nz-divider nzType="vertical"></nz-divider>
              <span>{{ feldKategorie.name }}</span>
              <ng-container *ngIf="feldKategorie.inApp">
                <nz-divider nzType="vertical"></nz-divider>
                <span nz-typography nzType="secondary">In App</span>
              </ng-container>
              <!---    content >> -->
            </a>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
            <a style="margin-left: 16px" *ngIf="$sortable | async" cdkDragHandle><i nz-icon nzType="menu" nzTheme="outline"></i></a>
          </nz-list-item-action>
        </ul>
      </nz-list-item>
    </nz-list>
  </nz-col>
</nz-row>
