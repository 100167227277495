import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { RollesServiceModule } from '..';
import { RollesListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    RollesServiceModule
  ],
  declarations: [RollesListComponent],
  exports: [RollesListComponent],

})
export class RollesListComponentModule { }
