import { NgModule } from '@angular/core';
import { 
  IchQueryModule,
  MitarbeiterQueryModule,
  ProfilQueryModule
} from '.';

@NgModule({
  declarations: [],
  imports: [
    IchQueryModule,
    MitarbeiterQueryModule,
    ProfilQueryModule
  ],
  exports: [
    IchQueryModule,
    MitarbeiterQueryModule,
    ProfilQueryModule
  ]
})
export class PersonenQuerysModule { }
