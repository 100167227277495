import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { orderBy } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, Feld, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import {
  IAbschnitt,
  IDeleteAbschnittRequest,
  IDeleteAbschnittResponse,
  IPostAbschnittRequest,
  IPostAbschnittResponse,
  IPostSortiereAbschnitteRequest,
  IPostSortiereAbschnitteResponse,
} from 'fa-kt.types';
import { DeleteAbschnittCommandService, PostAbschnittCommandService, PostSortiereAbschnitteCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-abschnitte',
  templateUrl: './abschnitte.component.html',
  styleUrls: ['./abschnitte.component.css'],
})
export class AbschnitteComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postAbschnitt: BehaviorSubject<IPostAbschnittRequest | null> = new BehaviorSubject<IPostAbschnittRequest | null>(null);
  public readonly $deleteAbschnitt: BehaviorSubject<IDeleteAbschnittRequest | null> = new BehaviorSubject<IDeleteAbschnittRequest | null>(null);
  public readonly $postSortiereAbschnitte: BehaviorSubject<IPostSortiereAbschnitteRequest | null> = new BehaviorSubject<IPostSortiereAbschnitteRequest | null>(null);

  @Input() vorlage: string = '';
  @Input() abschnitte: IAbschnitt[] = [];
  @Input() object: any = {};
  @Input() felder: Feld[] = [];
  @Input() set projekte(projekte: ISelection[]) {
    this.$projekte.next(projekte);
    if (projekte[0]) {
      this.$projekt.next(projekte[0].value);
    }
  }
  @Input() set projekt(projekt: string) {
    this.$projekt.next(projekt);
  }
  @Output() projektChange = new EventEmitter<string>();
  $projekte = new BehaviorSubject<ISelection[]>([]);
  $projekt = new BehaviorSubject<string | undefined>(undefined);
  @Input() set gutachtens(gutachten: ISelection[]) {
    this.$gutachtens.next(gutachten);
    if (gutachten[0]) {
      this.$gutachten.next(gutachten[0].value);
    }
  }
  @Input() set gutachten(gutachten: string) {
    this.$gutachten.next(gutachten);
  }
  @Output() gutachtenChange = new EventEmitter<string>();
  $gutachtens = new BehaviorSubject<ISelection[]>([]);
  $gutachten = new BehaviorSubject<string | undefined>(undefined);

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postAbschnitt: PostAbschnittCommandService,
    public deleteAbschnitt: DeleteAbschnittCommandService,
    public postSortiereAbschnitte: PostSortiereAbschnitteCommandService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        this.$projekt.subscribe((id) => this.projektChange.emit(id)),
        this.$gutachten.subscribe((id) => this.gutachtenChange.emit(id)),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostAbschnitt(response?: IPostAbschnittResponse) {
    /* << after post-abschnitt    */
    await this.openAbschnitt(null);
    /*    after post-abschnitt >> */
  }

  async finishedDeleteAbschnitt(response?: IDeleteAbschnittResponse) {
    /* << after delete-abschnitt    */
    await this.openAbschnitt(null);
    /*    after delete-abschnitt >> */
  }

  async finishedPostSortiereAbschnitte(response?: IPostSortiereAbschnitteResponse) {
    /* << after post-sortiere-abschnitte    */
    /*    after post-sortiere-abschnitte >> */
  }

  /* << methods    */

  async reorder($event?: CdkDragDrop<string[]>) {
    const request = {
      abschnitte: orderBy(this.abschnitte, 'order'),
    };
    if (request.abschnitte && $event) {
      moveItemInArray(request.abschnitte, $event.previousIndex, $event.currentIndex);
      request.abschnitte = request.abschnitte.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.submitPostSortiereAbschnitte(request);
    }
  }

  public async openAbschnitt(abschnitt: IAbschnitt | null | any) {
    if (!abschnitt) {
      this.$postAbschnitt.next(null);
      this.$deleteAbschnitt.next(null);
      return;
    }
    if (!abschnitt.vorlage && this.vorlage) {
      abschnitt.vorlage = this.vorlage;
    }
    this.$postAbschnitt.next({ abschnitt });
    this.$deleteAbschnitt.next({ abschnitt: abschnitt.id });
  }
  /*    methods >> */

  public async submitPostAbschnitt(request?: IPostAbschnittRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postAbschnitt.getValue();
    if (payload) {
      try {
        const response = await this.postAbschnitt.request(payload);
        await this.finishedPostAbschnitt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteAbschnitt(request?: IDeleteAbschnittRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteAbschnitt.getValue();
    if (payload) {
      try {
        const response = await this.deleteAbschnitt.request(payload);
        await this.finishedDeleteAbschnitt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostSortiereAbschnitte(request?: IPostSortiereAbschnitteRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postSortiereAbschnitte.getValue();
    if (payload) {
      try {
        const response = await this.postSortiereAbschnitte.request(payload);
        await this.finishedPostSortiereAbschnitte(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
