import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey } from '@ant-design/colors';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface ColorGroup { preview?: string, colors: string[] }

@Component({
  selector: 'pbc-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ColorPickerComponent
    }
  ]
})
export class ColorPickerComponent implements OnInit, OnDestroy, ControlValueAccessor  {

  onChange = (color: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  visible = false;

  readonly groups: Array<ColorGroup> = [
  ]
  group: ColorGroup|undefined;

  @Input() set color(color: string|null) {
    this._color = color ? color : '#FFF';
  }
  _color = '#FFF';

  @Output()
  colorChange: EventEmitter<string> = new EventEmitter()

  constructor() {
    [red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey].forEach(color => {
      this.groups.push({ preview: color.primary, colors: color });
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public setGroup(group: ColorGroup|undefined): void {
    this.group = group && group.colors ? group : undefined;
    if (this.group?.preview) { this.setColor(this.group?.preview, true); }
  }


  public setColor(color: string, visible = false): void {
    this.color = color;
    this.colorChange.emit(this.color);
    this.markAsTouched();
    this.onChange(color);
    this.visible = visible;
  }



  writeValue(color: string) {
    this.color = color;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
