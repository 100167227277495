import { IFilter, IFilterConfig } from "pbc.types";

import { IRolle } from '.';

export interface IRolleFilter extends IFilter<IRolle> {}

export const RolleFilterConfig: IFilterConfig<IRolle> = [];

export type IRolleField =
  'rolle.id'|
  'rolle.name'|
  'rolle.beschreibung'|
  'rolle.finanzen'
;
