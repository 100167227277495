import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { round } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { formatHourDuration } from 'pbc.functions';

import { IAzureMapsRoute, IAzureMapsSuggestion, IAzureRoutesBatchResult, ITourenSummary, ITourenSummaryBatchRequest, ITourenSummaryBatchResponse } from 'pbc.types';
import { HttpService, MetaService, MonitoringService, SecretService } from '../../..';

@Injectable({
  providedIn: 'root',
})
export class AtlasService {
  private key: string | null = null;

  public readonly bundeslandKennzahlLength = 2;
  public readonly regierungsbezirkKennzahlLength = 1;
  public readonly kreisKennzahlLength = 2;
  public readonly verbandKennzahlLength = 4;
  public readonly gemeindeKennzahlLength = 3;
  public readonly keyPattern = /^[0-9]{1,4}$/;
  public readonly plzPattern = /^[0-9]{4,6}$/;

  constructor(
    private http: HttpService,
    private httpClient: HttpClient,
    private secrets: SecretService,
    private meta: MetaService,
    private monitoring: MonitoringService,
  ) {
    this.secrets.$secrets.subscribe((secrets) => {
      if (secrets && secrets.maps) {
        this.key = secrets.maps.key;
      }
    });
  }

  cached: { [query: string]: IAzureMapsRoute } = {};

  async getRouteSummariesInBatch(touren: ITourenSummaryBatchRequest): Promise<ITourenSummaryBatchResponse> {
    if (!this.key || !touren?.length) return undefined;
    const request = {
      batchItems: [],
    };
    for (const { besichtigungen } of touren) {
      for (const { start, ende, datum } of besichtigungen)
        request.batchItems.push({
          query: `query=${start.latitude},${start.longitude}%3A${ende.latitude},${ende.longitude}&departAt=${moment(datum).utc().toISOString()}&language=de-DE&computeTravelTimeFor=all&traffic=true`,
        });
    }
    const location = (await new Promise((resolve, reject) => {
      this.httpClient.post('https://atlas.microsoft.com/route/directions/batch/json?api-version=1.0&subscription-key=' + this.key, request, { observe: 'response' }).subscribe({
        next: (data) => {
          const location = data?.headers?.get('Location');
          if (location) resolve(location);
        },
        error: (error) => {
          this.monitoring.logException(error);
          reject(undefined);
        },
      });
    })) as string;
    const result: IAzureRoutesBatchResult = await this.http.get<IAzureRoutesBatchResult>(location).catch(() => undefined);
    if (!result) return undefined;

    const response: ITourenSummaryBatchResponse = {
      strecke: 0,
      fahrtzeit: 0,
    };
    let i = 0;
    for (const { tour, label, beginn, besichtigungen } of touren) {
      response[tour] = {
        strecke: 0,
        fahrtzeit: 0,
      };
      const labels: string[] = [beginn];
      for (const { label: objekt, id } of besichtigungen) {
        const {
          summary: { lengthInMeters, travelTimeInSeconds },
        } = result.batchItems[i].response.routes[0];
        const strecke = (lengthInMeters || 0) / 1000;
        const fahrtzeit = (travelTimeInSeconds || 0) / 60 / 60;
        const label = objekt + ` (${round(strecke, 2)} km, ${formatHourDuration(fahrtzeit)})`;
        response[tour][id] = {
          label,
          strecke,
          fahrtzeit,
        };
        labels.push(label);
        (response[tour] as ITourenSummary).strecke += strecke;
        (response[tour] as ITourenSummary).fahrtzeit += fahrtzeit;
        i++;
      }
      const { strecke, fahrtzeit } = response[tour] as ITourenSummary;
      (response[tour] as ITourenSummary).label = label + ` (${round(strecke, 2)}km, ${formatHourDuration(fahrtzeit)}): ` + labels.join(' ⇥ ');
      response.strecke += strecke;
      response.fahrtzeit += fahrtzeit;
    }
    return response;
  }

  async getRoutesInBatch(
    request: {
      route: string;
      color: string;
      object: any;
    }[],
  ): Promise<IAzureMapsRoute[]> {
    if (!this.key || !request?.length) return [];
    const queries = request.map((r) => {
      const searchParams = new URL(r.route).searchParams;
      searchParams.delete('subscription-key');
      searchParams.delete('language');
      searchParams.delete('api-version');
      return {
        query: searchParams.toString(),
        ...r,
      };
    });
    let cacheables = queries.filter(({ query }) => !!this.cached[query]);
    cacheables.forEach((q) => queries.splice(queries.indexOf(q), 1));
    cacheables = cacheables.map((existing) => ({ ...existing, ...this.cached[existing.query] }));
    if (!queries.length) return cacheables;
    const location = (await new Promise((resolve, reject) => {
      this.httpClient
        .post(
          'https://atlas.microsoft.com/route/directions/batch/json?api-version=1.0&subscription-key=' + this.key,
          {
            batchItems: queries.map(({ query }) => ({
              query,
            })),
          },
          { observe: 'response' },
        )
        .subscribe({
          next: (data) => {
            const location = data?.headers?.get('Location');
            if (location) resolve(location);
          },
          error: (error) => {
            this.monitoring.logException(error);
            reject(undefined);
          },
        });
    })) as string;
    if (!location) return cacheables;
    const result: IAzureRoutesBatchResult = await this.http.get<IAzureRoutesBatchResult>(location).catch(() => undefined);
    result?.batchItems?.forEach(({ response }, i) => (this.cached[queries[i].query] = response.routes[0]));
    return [...cacheables, ...(result?.batchItems?.map(({ response }, i) => ({ color: queries[i]?.color, object: queries[i]?.object, ...response.routes[0] })) || [])];
  }

  async suggestByTerm(query: string, types = ['Street', 'Address Range', 'Point Address']): Promise<IAzureMapsSuggestion[]> {
    if (!this.key || !query) {
      return [];
    }
    const countrySet =
      'AT,BE,BG,DK,DE,EE,FI,FR,GR,IE,IT,HR,LV,LT,LU,MT,NL,PL,PT,RO,SE,SK,SI,ES,CZ,HU,CY,CH,' + 'MC,IS,LI,NO,' + 'AL,ME,MK,RS,TR,' + 'AD,BY,BA,MD,MC,RU,SM,UA,VA,UK' + 'USA,JPN,CHN,AUS,BRA,SGP';
    const language = 'de-DE';
    let suggestions: IAzureMapsSuggestion[] = [];
    try {
      // , { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}}
      const result: any = await this.http.get(
        `https://atlas.microsoft.com/search/address/json?subscription-key=${this.key}&api-version=1.0&query=${query}&typeahead=true&countrySet=${countrySet}&language=${language}&extendedPostalCodesFor=PAD`,
      );
      if (result && result.results) {
        suggestions = result.results
          .filter((entry: any) => types.length === 0 || types.includes(entry.type))
          .map((entry: any) => ({ ...entry, address: { ...entry.address, streetAddress: entry.address.streetName + ' ' + (entry.address.streetNumber || '') } }));
      }
    } catch (e) {
      console.error(e);
    }
    return suggestions;
  }

  async suggestByCoordinates(longitude: number, latitude: number): Promise<IAzureMapsSuggestion[]> {
    if (!this.key || (!latitude && !longitude)) {
      return [];
    }
    const language = 'de-DE';
    let suggestions: IAzureMapsSuggestion[] = [];
    try {
      const result: any = await this.http.get(
        `https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${this.key}&api-version=1.0&query=${latitude},${longitude}&language=${language}`,
      );
      if (result && result.addresses) {
        suggestions = result.addresses
          .filter((entry: any) => entry.address.streetName) //  && entry.address.streetNumber
          .map((entry: any) => ({
            position: { lon: entry.position.split(',')[1], lat: entry.position.split(',')[0] },
            address: { ...entry.address, streetAddress: entry.address.streetName + ' ' + entry.address.streetNumber },
          }));
      }
    } catch (e) {
      console.error(e);
    }
    return suggestions;
  }

  async suggestRegionalSchluessel(suchbegriff: string) {
    const result: any = await this.http.get(`https://ags-ars.api.vsm.nrw/orte?suchbegriff=` + encodeURIComponent(suchbegriff), { headers: { Accept: 'application/json' } });
    if (result && result.daten) {
      const schluessel = result.daten.filter((e: any) => e.regionalschluessel);
      if (schluessel && schluessel.length > 0) {
        const regionalSchluessel = schluessel[0].regionalschluessel;
        const bundeslandKennzahl = regionalSchluessel.substr(0, this.bundeslandKennzahlLength);
        const regierungsbezirkKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength, this.regierungsbezirkKennzahlLength);
        const kreisKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength + this.regierungsbezirkKennzahlLength, this.kreisKennzahlLength);
        const verbandKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength + this.regierungsbezirkKennzahlLength + this.kreisKennzahlLength, this.verbandKennzahlLength);
        const gemeindeKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength + this.regierungsbezirkKennzahlLength + this.kreisKennzahlLength + this.verbandKennzahlLength);
        return {
          regionalSchluessel,
          bundeslandKennzahl,
          regierungsbezirkKennzahl,
          kreisKennzahl,
          verbandKennzahl,
          gemeindeKennzahl,
        };
      }
    }
    return null;
  }
}
