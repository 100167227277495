import { Component } from '@angular/core';

import get from 'lodash/get';
import { BehaviorSubject } from 'rxjs';

import { BaseFilterComponent } from 'pbc.angular';

import { ITourStatiQueryShapes, TourStatiQueryFilterConfig } from 'fa-kt.types';
import { TourStatiService } from '../service';

@Component({
  selector: 'fa-kt-tour-stati-filter',
  templateUrl: './tour-stati-filter.component.html',
  styleUrls: ['./tour-stati-filter.component.css'],
})
export class TourStatiFilterComponent extends BaseFilterComponent<ITourStatiQueryShapes> {
  shapes$: BehaviorSubject<ITourStatiQueryShapes>;
  config;

  constructor(public tourStati: TourStatiService) {
    super();
    this.shapes$ = this.tourStati.shapes$;
    this.config = TourStatiQueryFilterConfig.map((filter) => ({ ...filter, title: get(this.sitemap, filter.title, filter.title) as string }));
  }
}
