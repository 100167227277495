import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { toInitials } from 'pbc.functions';
import { ICommandRequest, ICommandResponse, IModel, IQueryRequest, IQueryResponse, ISelection, IShape } from 'pbc.types';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

import { AuthService } from './auth';
import {
  ActionService,
  AppEnvironment,
  APP_CONFIG,
  APP_TITLE,
  CustomFormatter,
  DeviceConfigService,
  IPageState,
  ISitemap,
  ISitemapCommand,
  ISitemapModel,
  ISitemapPage,
  MetaService,
  MonitoringService,
  SecretService,
  SITEMAP,
} from './common';
import { FileService } from './files';
import { HttpService } from './https';

@Component({ template: `` })
export abstract class BaseComponent implements OnInit, OnDestroy {
  readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public sitemap = inject(SITEMAP) as ISitemap;
  injector = inject(Injector);
  destroyedRef = inject(DestroyRef);
  changeRef = inject(ChangeDetectorRef);
  viewContainerRef = inject(ViewContainerRef);
  title = inject(APP_TITLE) as string;
  titleRef = inject(Title);
  route = inject(ActivatedRoute);
  router = inject(Router);
  modal = inject(NzModalService);
  message = inject(NzMessageService);
  actions = inject(ActionService);
  auth = inject(AuthService);
  public files = inject(FileService);
  http = inject(HttpService);
  monitoring = inject(MonitoringService);
  meta = inject(MetaService);
  device = inject(DeviceConfigService);

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  ngOnDestroy(): void {}
  ngOnInit(): void {}
}

@Component({ template: `` })
export abstract class BasePage extends BaseComponent implements OnInit, OnDestroy {
  abstract description: {
    context: string;
    page: string;
  };
  page: ISitemapPage;

  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.page = this.sitemap[this.description.context]?.Pages[this.description.page];
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
  }
}

@Component({ template: `` })
export abstract class BaseFilterComponent<Shapes extends IShape> implements OnInit, OnDestroy {
  destroyedRef = inject(DestroyRef);
  meta = inject(MetaService) as MetaService;
  sitemap = inject(SITEMAP) as ISitemap;

  abstract config;
  abstract shapes$: BehaviorSubject<Shapes | undefined>;

  ngOnInit(): void {
    this.shapes$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((shapes: Shapes | undefined) => {
      if (!shapes) {
        return;
      }
      Object.entries(shapes)
        .filter(([key]) => key !== 'id' && (shapes as any)[key])
        .forEach(([key]) => this.shape(key, (shapes as any)[key]));
    });
  }
  ngOnDestroy(): void {}

  shape(field: string, shapes: ISelection[]): void {
    const index = this.config.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.config[index].selections = shapes;
  }
}

@Component({
  template: '',
})
export abstract class PostModelCommandComponent<Request extends ICommandRequest, Response extends ICommandResponse> implements OnInit, OnDestroy {
  CustomFormatter = CustomFormatter;

  abstract description: {
    context: string;
    command: string;
    model: string;
  };
  command!: ISitemapCommand;
  model!: ISitemapModel;

  form!: FormGroup;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() valid = new EventEmitter<boolean>();
  @Output() finished = new EventEmitter<Response>();

  @Input() set value(value: Request) {
    this.patch(value);
  }

  destroyedRef = inject(DestroyRef);
  sitemap = inject(SITEMAP) as ISitemap;
  fb = inject(FormBuilder);
  actions = inject(ActionService);
  message = inject(NzMessageService);

  constructor() {}

  ngOnInit() {
    this.command = this.sitemap[this.description.context].Commands[this.description.command];
    this.model = this.sitemap[this.description.context].Models[this.description.model];
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((value: any) => {
      this.valid.emit(this.form.valid);
      if (this.form.valid) this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
      else this.actions.unsubscribe(this.command.translation);
    });
    // TODO: standard operations for form
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
  }

  private patch(value?: Request) {
    if (!value) this.form.reset();
    else this.form.patchValue(value);
    this.update();
  }

  private update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  abstract request(payload: Request): Promise<Response | undefined>;

  async submit() {
    this.update();
    if (!this.form.valid) return;
    this.$loading.next(true);
    try {
      const payload = this.form.getRawValue() as Request;
      const response = await this.request(payload);
      this.finished.next(response!);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        console.error(validation);
        // TODO
      }
    }
    this.$loading.next(false);
  }
}

@Component({
  template: '',
})
export abstract class BaseModelPage<Model extends IModel, Request extends IQueryRequest, Response extends IQueryResponse, PostCommandRequest extends ICommandRequest> implements OnInit, OnDestroy {
  page!: ISitemapPage;
  model!: ISitemapModel;
  abstract description: {
    context: string;
    page: string;
    model: string;
  };

  abstract contentKey: keyof Response;
  abstract valueKey: keyof PostCommandRequest;
  abstract response$: BehaviorSubject<Response | undefined>;

  readonly $state = new BehaviorSubject<IPageState>(null);
  public readonly $loading = new BehaviorSubject<boolean>(false);
  public readonly $model = new BehaviorSubject<{ [key: string]: {} | Model } | undefined>(undefined);

  destroyedRef = inject(DestroyRef);
  sitemap = inject(SITEMAP);
  title = inject(APP_TITLE);
  titleRef = inject(Title);
  route = inject(ActivatedRoute);
  router = inject(Router);
  actions = inject(ActionService);
  modal = inject(NzModalService);
  message = inject(NzMessageService);

  constructor() {}

  ngOnInit() {
    this.page = this.sitemap[this.description.context].Settings[this.description.page];
    this.model = this.sitemap[this.description.context].Models[this.description.model];
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.actions.subscribe({ key: 'Zurück', action: () => window.history.back() });
    this.actions.subscribe({ key: `${this.model.single} erstellen`, action: () => this.create() });
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((params: Params) => this.$state.next(params['id']));
    this.$state.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((state: IPageState) => this.sync(state));
  }

  ngOnDestroy() {
    this.$loading.next(false);
    this.actions.unsubscribe('Zurück');
    this.actions.unsubscribe(`${this.model.single} erstellen`);
  }

  sync(state: IPageState) {
    this.$loading.next(true);
    if (state === 'new') this.$model.next({ value: {} });
    else if (state) {
      const response = this.response$.getValue();
      const value = ((response?.[this.contentKey] || []) as Model[]).find((ts: Model) => ts.id === state);
      this.$model.next(value ? { [this.valueKey]: value } : undefined);
    } else this.$model.next(undefined);
    this.$loading.next(false);
  }

  async create() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id: 'new' },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async set(id: string) {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async reset() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  back() {
    window.history.back();
  }
}

@Component({
  template: '',
})
export abstract class BasePostCommandComponent<Request extends IQueryRequest, Response extends IQueryResponse> implements OnInit, OnDestroy {
  CustomFormatter = CustomFormatter;

  abstract description: {
    context: string;
    command: string;
  };
  abstract contentKey: keyof Request;

  command!: ISitemapCommand;
  form!: FormGroup;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() valid = new EventEmitter<boolean>();

  @Input() set value(value: Request) {
    this.$initial.next(value);
    this.patch(value);
  }
  $initial = new BehaviorSubject<Request | undefined>(undefined);
  @Output() valueChange = new EventEmitter<Request>();
  @Output() finished = new EventEmitter<Response>();

  public sitemap = inject(SITEMAP);
  environment: AppEnvironment = inject(APP_CONFIG) as AppEnvironment;
  injector = inject(Injector);
  destroyedRef = inject(DestroyRef);
  router = inject(Router);
  secrets = inject(SecretService);
  fb = inject(FormBuilder);
  actions = inject(ActionService);
  message = inject(NzMessageService);
  files = inject(FileService);
  viewContainerRef = inject(ViewContainerRef);
  modal = inject(NzModalService);
  auth = inject(AuthService);
  https = inject(HttpService);
  meta = inject(MetaService);

  get modalRef() {
    return this.injector.get(NzModalRef);
  }
  get data(): undefined | Partial<Request> {
    return this.injector.get(NZ_MODAL_DATA);
  }

  constructor() {}

  ngOnInit() {
    this.command = this.sitemap[this.description.context].Commands[this.description.command];
    this.prepare().catch((e) => console.error(e));
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((value: any) => {
      this.valid.emit(this.form.valid);
      if (this.form.valid) this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
      else this.actions.unsubscribe(this.command.translation);
      this.valueChange.emit(this.form.getRawValue());
    });
    this.form
      .get(this.contentKey.toString() + '.name')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef), debounceTime(1500), distinctUntilChanged())
      .subscribe((name: string) => {
        const initialen = this.form.get(this.contentKey.toString() + '.initialen');
        if (initialen && (!initialen.touched || !initialen.value)) {
          initialen?.patchValue(toInitials(name));
        }
      });
    try {
      if (this.modalRef) this.finished.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((result) => this.modalRef?.destroy(result));
    } catch (e) {}
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
  }

  patch(value?: Partial<Request>) {
    if (!value) this.form.reset();
    else this.form.patchValue(value);
    this.update();
  }

  update() {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }

  abstract prepare(): Promise<void>;
  abstract request(payload: Request): Promise<Response | undefined>;

  async submit() {
    this.update();
    if (!this.form.valid) return;
    this.$loading.next(true);
    try {
      const payload = this.form.getRawValue() as Request;
      const response = await this.request(payload);
      this.finished.next(response!);
    } catch (error: any) {
      for (const validation of error?.error?.errors || []) {
        const field = this.form.get(this.contentKey.toString() + '.' + validation.path);
        if (field) field.setErrors({ server: error.error.action === 'validate' ? '"' + validation.value + '" wird schon verwendet' : error.error.message });
        else this.message.warning('Die Validierung für "' + validation.value + '" ist fehlgeschlagen');
      }
    }
    this.$loading.next(false);
  }
}
