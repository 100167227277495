import { Component } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Params } from '@angular/router';
import { IReisekostenabrechnungenRequest } from 'fa-kt.types';
import { BasePage, ISitemapPage } from 'pbc.angular';
import { ISelection } from 'pbc.types';
import { ReisekostenabrechnungenService } from '../../querys';

@Component({
  selector: 'fa-kt-finanzen-page',
  templateUrl: './finanzen.page.html',
  styleUrls: ['./finanzen.page.css'],
})
export class FinanzenPage extends BasePage {
  description = { context: 'FINANZEN', page: 'FINANZEN' };

  public _reisekostenabrechnung: ISitemapPage;
  jahr = new Date().getFullYear();
  $touren = new BehaviorSubject<ISelection[]>([]);

  constructor(public reisekostenabrechnungen: ReisekostenabrechnungenService) {
    super();
    this._reisekostenabrechnung = this.sitemap.FINANZEN.Pages.REISEKOSTENABRECHNUNG;
  }

  public async setReisekostenabrechnungen(id: string) {
    /* << set-reisekostenabrechnungen    */
    this.navigateToReisekostenabrechnung({ id });
    /*    set-reisekostenabrechnungen >> */
  }

  public async navigateToReisekostenabrechnung(queryParams: Params = {}) {
    await this.router.navigate(this._reisekostenabrechnung.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  async loadReisekostenabrechnungen(jahr: number) {
    const request: IReisekostenabrechnungenRequest = { jahr };
    if (!this.auth.$isAdmin.getValue()) request.mitarbeiter = this.auth.$id.getValue();
    await this.reisekostenabrechnungen.request(request);
  }

  get jahre(): number[] {
    const current = new Date().getFullYear();
    const jahre: number[] = [current];
    for (let i = 1; i < 30; i++) {
      jahre.push(current - i);
    }
    return jahre;
  }
}
