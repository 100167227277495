import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IDashboardQuerySorter extends ISorter {
  'erinnerungen.id'?: ISorterExpression;
  'erinnerungen.projekt'?: ISorterExpression;
  'erinnerungen.gutachten'?: ISorterExpression;
  'erinnerungen.kunde'?: ISorterExpression;
  'erinnerungen.kommentar'?: ISorterExpression;
  'erinnerungen.am'?: ISorterExpression;
  'erinnerungen.mitarbeiter'?: ISorterExpression;
  'erinnerungen.verfasser'?: ISorterExpression;
  'tour.id'?: ISorterExpression;
  'tour.tourStatus'?: ISorterExpression;
  'tour.mitarbeiter'?: ISorterExpression;
  'tour.am'?: ISorterExpression;
  'tour.startIstPrivatAdresse'?: ISorterExpression;
  'tour.endeIstPrivatAdresse'?: ISorterExpression;
  'tour.start'?: ISorterExpression;
  'tour.ende'?: ISorterExpression;
  'tour.strecke'?: ISorterExpression;
  'tour.kosten'?: ISorterExpression;
  'tour.abgerechnet'?: ISorterExpression;
  'tour.kommentar'?: ISorterExpression;
}

export const DashboardQuerySorterConfig: ISorterConfig = [
  SorterConfig('erinnerungen.id', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.ID.title'), 
  SorterConfig('erinnerungen.projekt', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.PROJEKT.title'), 
  SorterConfig('erinnerungen.gutachten', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.GUTACHTEN.title'), 
  SorterConfig('erinnerungen.kunde', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.KUNDE.title'), 
  SorterConfig('erinnerungen.kommentar', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.KOMMENTAR.title'), 
  SorterConfig('erinnerungen.am', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.AM.title'), 
  SorterConfig('erinnerungen.mitarbeiter', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.MITARBEITER.title'), 
  SorterConfig('erinnerungen.verfasser', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.VERFASSER.title'), 
  SorterConfig('tour.id', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ID.title'), 
  SorterConfig('tour.tourStatus', 'BESICHTIGUNGEN.Models.TOUR.Propertys.TOUR_STATUS.title'), 
  SorterConfig('tour.mitarbeiter', 'BESICHTIGUNGEN.Models.TOUR.Propertys.MITARBEITER.title'), 
  SorterConfig('tour.am', 'BESICHTIGUNGEN.Models.TOUR.Propertys.AM.title'), 
  SorterConfig('tour.startIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START_IST_PRIVAT_ADRESSE.title'), 
  SorterConfig('tour.endeIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE_IST_PRIVAT_ADRESSE.title'), 
  SorterConfig('tour.start', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START.title'), 
  SorterConfig('tour.ende', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE.title'), 
  SorterConfig('tour.strecke', 'BESICHTIGUNGEN.Models.TOUR.Propertys.STRECKE.title'), 
  SorterConfig('tour.kosten', 'BESICHTIGUNGEN.Models.TOUR.Propertys.KOSTEN.title'), 
  SorterConfig('tour.abgerechnet', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ABGERECHNET.title'), 
  SorterConfig('tour.kommentar', 'BESICHTIGUNGEN.Models.TOUR.Propertys.KOMMENTAR.title'), 
];
