import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { ObjekteComponent } from '.';
import { ObjekteQueryModule } from '../../querys';
import { ObjektComponentModule } from '../objekt/objekt.component.module';

@NgModule({
  imports: [LIB_CommonModule, ObjekteQueryModule, ObjektComponentModule],
  declarations: [ObjekteComponent],
  exports: [ObjekteComponent],
})
export class ObjekteComponentModule {}
