<nz-page-header [nzTitle]="row?.objekt?.name" (nzOnCancel)="modalRef?.destroy()">
  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item *ngIf="kunde">
      <a style="line-height: 22px" (click)="navigateToKunde(kunde.id)">🧐 Kunde: {{ kunde?.name }}</a></nz-breadcrumb-item
    >
    <nz-breadcrumb-item *ngIf="row?.projekt">
      <a (click)="navigateToProjekt(row.gutachten)">📖 Projekt: {{ row?.projekt?.nummer }} {{ row?.projekt?.bezeichnung }}</a>
    </nz-breadcrumb-item>
  </nz-breadcrumb>
  <nz-page-header-tags *ngIf="row?.besichtigung?.abgerechnet">
    <nz-tag [nzColor]="'blue'">Abgerechnet</nz-tag>
  </nz-page-header-tags>
  <nz-page-header-extra>
    <nz-space>
      <button *nzSpaceItem nz-button nzType="dashed" nz-tooltip nzTooltipTitle="In Google Maps öffnen" (click)="openAddress(row?.objekt?.addresse)">
        <span nz-icon nzType="compass" nzTheme="outline"></span>
      </button>
      <fa-kt-delete-besichtigung *nzSpaceItem (finished)="modalRef?.destroy()" [style.width]="'100%'" [value]="$deleteBesichtigung | async"></fa-kt-delete-besichtigung>
    </nz-space>
  </nz-page-header-extra>
  <nz-page-header-content *ngIf="row">
    <nz-descriptions nzSize="small" [nzColumn]="3">
      <nz-descriptions-item nzTitle="Losfahren" [nzSpan]="2">
        <span *ngIf="!row.hasTimeConflicts?.noTourMatch">{{ row.besichtigung.fahrtVon | date: 'HH:mm' : '' : 'de' }} Uhr</span>
        <span
          *ngIf="row.hasTimeConflicts?.noTourMatch"
          [style.color]="'red'"
          nz-tooltip
          nzTooltipColor="red"
          nzTooltipTitle="Die Daten der Besichtigung passen nicht zu der Tour. Bitte setzen Sie die Zeiten der vorherigen Besichtigung neu."
          >{{ row.besichtigung.fahrtVon | date: 'HH:mm' : '' : 'de' }} Uhr</span
        >
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Besichtigung" [nzSpan]="1">
        <span [style.color]="besichtigungeszeit === 0 ? 'red' : ''" nz-tooltip [nzTooltipTitle]="minuten + ' Minuten für Besichtigung von ' + objektArt?.name + ' geplant.'"
          >{{ besichtigungeszeit }} Minuten <span nz-icon nzType="question-circle" nzTheme="outline"></span
        ></span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Ankunft" [nzSpan]="2">
        <span [style.color]="row.hasTimeConflicts?.kommuniziertVonIsBeforeFahrtBis ? 'red' : ''">{{ row.besichtigung.fahrtBis | date: 'HH:mm' : '' : 'de' }} Uhr</span>
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Aufenthalt" [nzSpan]="1">
        <span nz-tooltip [style.color]="row.hasTimeConflicts?.negativerAufenthalt ? 'red' : ''" [nzTooltipTitle]="Aufenthalt"
          >{{ aufenthaltszeit }} Minuten <span nz-icon nzType="question-circle" nzTheme="outline"></span
        ></span>
        <ng-template #Aufenthalt>
          <span style="color: white" nz-typography>{{ vorbereitungszeit || 0 }} Minuten Vorbereitung</span> <br />
          <span style="color: white" nz-typography>{{ nachbereitungszeit || 0 }} Minuten Nachbereitung</span><br />
        </ng-template>
      </nz-descriptions-item>

      <nz-descriptions-item nzTitle="Kontakt" [nzSpan]="1" *ngIf="row.gutachten?.besichtigung_kontakt">
        <span nz-typography nzCopyable [nzContent]="row.gutachten?.besichtigung_kontakt">{{ row.gutachten?.besichtigung_kontakt }}</span></nz-descriptions-item
      >
      <nz-descriptions-item nzTitle="Email" [nzSpan]="2" *ngIf="row.gutachten?.besichtigung_email"
        ><a style="line-height: 22px" [href]="'mailto:' + row.gutachten?.besichtigung_email">{{ row.gutachten?.besichtigung_email }} <i nz-icon nzTheme="outline" nzType="mail"></i></a
      ></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Festnetz" [nzSpan]="1" *ngIf="row.gutachten?.besichtigung_festnetz"
        ><a style="line-height: 22px" [href]="'tel:' + row.gutachten?.besichtigung_festnetz">{{ row.gutachten?.besichtigung_festnetz }}<i nz-icon nzTheme="outline" nzType="phone"></i></a
      ></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Mobil" [nzSpan]="1" *ngIf="row.gutachten?.besichtigung_mobil">
        <a style="line-height: 22px" [href]="'tel:' + row.gutachten?.besichtigung_mobil">{{ row.gutachten?.besichtigung_mobil }}<i nz-icon nzTheme="outline" nzType="phone"></i></a>
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-page-header-content>
</nz-page-header>
<nz-row>
  <nz-col nzSpan="24">
    <nz-spin [nzSpinning]="loading$ | async">
      <nz-tabset>
        <nz-tab [nzTitle]="Besichtigung">
          <ng-template #Besichtigung>
            <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
            Besichtigung
          </ng-template>
          <fa-kt-post-besichtigung
            [minuten]="minuten"
            [tour]="tour"
            (finished)="modalRef?.destroy()"
            (valueChange)="setZeiten($event?.besichtigung, true)"
            [value]="$postBesichtigung | async"
          ></fa-kt-post-besichtigung>
          <pbc-meta [value]="($postBesichtigung | async)?.besichtigung"></pbc-meta>
        </nz-tab>
        <nz-tab [nzTitle]="EMails">
          <ng-template #EMails>
            <i nz-icon nzType="mail" nzTheme="outline"></i>
            Vorlagen
          </ng-template>

          <nz-row>
            <nz-col nzSpan="24">
              <nz-radio-group [(ngModel)]="emailStyle" nzButtonStyle="solid">
                <label nz-radio-button nzValue="terminvorschlag">Terminvorschlag</label>
                <label nz-radio-button nzValue="terminbestaetigung">Terminbestätigung</label>
              </nz-radio-group>
              <br />
              <br />
            </nz-col>

            <nz-col nzSpan="24" *ngIf="emailStyle === 'terminvorschlag'">
              <div [innerText]="terminvorschlag()"></div>
              <br />
              <br />
              <a nz-button nzType="primary" [href]="'mailto:' + email + '?subject=Terminvorschlag für Besichtigung ' + adresse + ' am ' + datum + '&body=' + terminvorschlag(true)" target="_blank">
                <i nz-icon nzType="mail" nzTheme="outline"></i>
                In E-Mail Programm öffnen</a
              >
            </nz-col>

            <nz-col nzSpan="24" *ngIf="emailStyle === 'terminbestaetigung'">
              <div [innerText]="terminbestaetigung()"></div>
              <br />
              <br />
              <a
                nz-button
                nzType="primary"
                [href]="'mailto:' + email + '?subject=Terminbestätigung für Besichtigung ' + adresse + ' am ' + datum + '&body=' + terminbestaetigung(true)"
                target="_blank"
              >
                <i nz-icon nzType="mail" nzTheme="outline"></i>
                In E-Mail Programm öffnen</a
              >
            </nz-col>
          </nz-row>
        </nz-tab>
      </nz-tabset>
    </nz-spin>
  </nz-col>
</nz-row>
