import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IZeitArtsResponse } from '.';

export interface IZeitArtsQueryFilter extends IFilter<IZeitArtsResponse> {}

export const ZeitArtsQueryFilterConfig: IFilterConfig<IZeitArtsResponse> = [];

export interface IZeitArtsQueryShapes extends IShape {}

export type IZeitArtsQueryField = 'zeitArts.id' | 'zeitArts.name' | 'zeitArts.beschreibung' | '';
