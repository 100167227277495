import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenArtsServiceModule } from '..';
import { KundenArtsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenArtsServiceModule
  ],
  declarations: [KundenArtsSorterComponent],
  exports: [KundenArtsSorterComponent],

})
export class KundenArtsSorterComponentModule { }
