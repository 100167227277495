import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { toInitials } from 'pbc.functions';

import { IArchivResponseRow } from 'fa-kt.types';
import { ArchivService } from '../service';

@Component({
  selector: 'fa-kt-archiv-list',
  templateUrl: './archiv-list.component.html',
  styleUrls: ['./archiv-list.component.css'],
})
export class ArchivListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  /* << props    */
  @Output() copied: EventEmitter<string> = new EventEmitter<string>();
  @Output() resetted: EventEmitter<string> = new EventEmitter<string>();
  /*    props >> */

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public archiv: ArchivService) {}

  public set(archiv: IArchivResponseRow) {
    /* << custom    */
    this.select.emit(archiv.projekt.id);
    /*    custom >> */
  }

  /* << functions    */

  public copy(archiv: IArchivResponseRow) {
    this.copied.emit(archiv.projekt.id);
  }

  public reset(archiv: IArchivResponseRow) {
    this.resetted.emit(archiv.projekt.id);
  }
  /*    functions >> */

  getColorByFortschritt(fortschritt: number): string {
    if (!fortschritt || fortschritt === 0) {
      return 'red';
    } else if (fortschritt !== 100) {
      return 'orange';
    } else {
      return 'green';
    }
  }

  getMitarbeiterText(mitarbeiter: string, asInitialen = false) {
    const label = this.archiv.getProjektGutachten(mitarbeiter)?.label;
    if (!asInitialen || !label) return label;
    return toInitials(label.split('(')[0]);
  }
}
