import { NgModule } from '@angular/core';

import {
  DeleteTourStatusCommandServiceModule,
  DeleteTourStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteTourStatusCommandServiceModule,
    DeleteTourStatusCommandComponentModule
  ],
  exports: [
    DeleteTourStatusCommandServiceModule,
    DeleteTourStatusCommandComponentModule
  ]
})
export class DeleteTourStatusCommandModule { }
