import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { ActionSearchFilterPipe } from '../../pipes';
import { ActionService } from '../../services';
import { IAction } from '../../types';

@Component({
  selector: 'pbc-hotkeys',
  templateUrl: './action-selector.component.html',
  styleUrls: ['./action-selector.component.css'],
})
export class ActionSelectorComponent implements OnDestroy, OnInit {
  subscriptions: Subscription[] = [];
  actionSearch: ActionSearchFilterPipe;

  $actions: BehaviorSubject<Array<IAction>> = new BehaviorSubject<Array<IAction>>([]);
  $action: BehaviorSubject<IAction | null> = new BehaviorSubject<IAction | null>(null);

  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(public actions: ActionService) {
    this.actionSearch = new ActionSearchFilterPipe();
  }

  ngOnInit() {
    this.subscriptions.push(
      ...[
        combineLatest([this.actions.$actions, this.search$]).subscribe((search) => {
          const actions = this.actionSearch.transform(
            search[0].map((action) => ({ ...action, selected: false })),
            search[1],
          );
          if (actions[0]) {
            actions[0].selected = true;
            this.$action.next(actions[0]);
          } else {
            this.$action.next(null);
          }
          this.$actions.next(actions);
        }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  up() {
    this.move(-1);
  }

  down() {
    this.move(1);
  }

  private move(change: number) {
    let actions = this.$actions.getValue();
    const selected = actions.find((action) => action.selected);
    if (!selected) {
      return;
    }
    const index = actions.indexOf(selected) + change;
    // if (actions.length > index) { return };
    actions = actions.map((action) => ({ ...action, selected: false }));
    actions[index].selected = true;
    this.$action.next(actions[index]);
    this.$actions.next(actions);
  }

  searched($event: string) {
    this.search$.next($event);
  }

  actionTracker(index: number, action: IAction) {
    return action.key;
  }

  submit() {
    if (this.$action.getValue()) {
      this.actions.call(this.$action.getValue() as IAction);
    }
  }

  execute(action: IAction) {
    this.actions.call(action);
  }
}
