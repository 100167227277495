import { NgModule } from '@angular/core';
import {
  ObjektNutzungsFilterComponentModule,
  ObjektNutzungsSorterComponentModule,
  ObjektNutzungsListComponentModule
} from '.';

@NgModule({
  imports: [
    ObjektNutzungsFilterComponentModule,
    ObjektNutzungsSorterComponentModule,
    ObjektNutzungsListComponentModule
  ],
  exports: [
    ObjektNutzungsFilterComponentModule,
    ObjektNutzungsSorterComponentModule,
    ObjektNutzungsListComponentModule
  ],
})
export class ObjektNutzungsQueryModule { }