import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BerichtsVorlagenFilterComponent } from '.';
import { BerichtsVorlagenServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, BerichtsVorlagenServiceModule],
  declarations: [BerichtsVorlagenFilterComponent],
  exports: [BerichtsVorlagenFilterComponent],
})
export class BerichtsVorlagenFilterComponentModule {}
