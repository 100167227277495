import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektArtsServiceModule } from '..';
import { ObjektArtsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektArtsServiceModule
  ],
  declarations: [ObjektArtsSorterComponent],
  exports: [ObjektArtsSorterComponent],

})
export class ObjektArtsSorterComponentModule { }
