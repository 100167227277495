import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IDashboardQueryFilter, IDashboardQueryShapes, IDashboardQuerySorter, IDashboardRequest, IDashboardResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends ListQueryService<IDashboardRequest, IDashboardResponse, IDashboardQueryShapes, IDashboardQueryFilter, IDashboardQuerySorter> {
  contentKey: keyof IDashboardResponse = 'erinnerungen';
  route = 'projekte/dashboard';
  override dependencies = [
    'besichtigungen/written-tour-status',
    'besichtigungen/removed-tour-status',
    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert',
  ];

  static instance: DashboardService;

  constructor() {
    super();
    if (!DashboardService.instance) DashboardService.instance = this;
    return DashboardService.instance;
  }

  getErinnerungenMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerungen.mitarbeiter']?.find((v) => v.value === value);
  }

  get erinnerungenMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerungen.mitarbeiter'] as ISelection[]) : [])));
  }

  getErinnerungenVerfasser(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerungen.verfasser']?.find((v) => v.value === value);
  }

  get erinnerungenVerfasser(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerungen.verfasser'] as ISelection[]) : [])));
  }

  getTourMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.mitarbeiter']?.find((v) => v.value === value);
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.mitarbeiter'] as ISelection[]) : [])));
  }

  getTourTourStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.tourStatus']?.find((v) => v.value === value);
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.tourStatus'] as ISelection[]) : [])));
  }
}
