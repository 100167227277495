import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ArchivServiceModule } from '..';
import { SavedArchivFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ArchivServiceModule
  ],
  declarations: [SavedArchivFiltersComponent],
  exports: [SavedArchivFiltersComponent],

})
export class SavedArchivFiltersComponentModule { }
