import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IZeitArtsResponse } from 'fa-kt.types';
import { PostZeitArtCommandService } from '../commands';
import { ZeitArtsService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class ZeitArtsPageResolver implements Resolve<IZeitArtsResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private zeitArts: ZeitArtsService,
    private postZeitArt: PostZeitArtCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IZeitArtsResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [zeitArts] = await Promise.all([this.zeitArts.request({}).catch(log), this.postZeitArt.prepare(true).catch(log)]);
    this.loading.resolved();
    return zeitArts;
  }
}
