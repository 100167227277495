import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { SettingsQueryService } from 'pbc.angular';

import { IKonstante, IKonstantesQueryFilter, IKonstantesQueryShapes, IKonstantesQuerySorter, IKonstantesRequest, IKonstantesResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class KonstantesService extends SettingsQueryService<IKonstantesRequest, IKonstantesResponse, IKonstantesQueryShapes, IKonstantesQueryFilter, IKonstantesQuerySorter> {
  contentKey: keyof IKonstantesResponse = 'konstantes';
  route = 'einstellungen/settings/konstantes';
  override dependencies = ['einstellungen/written-konstante', 'einstellungen/removed-konstante'];

  public artIsUnique(control: AbstractControl | null): (art: string) => boolean {
    return (art: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (art) {
        const response = this.response$.getValue();
        if (response && response.konstantes) {
          isUnique = response.konstantes.filter((k) => k.id !== id && k.art === art).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    };
  }

  get projekt_dauer(): number {
    const constants = (this.response$.getValue()?.[this.contentKey] as IKonstante[]) || [];
    return Number(constants.find((c) => c.art === 'projekt_dauer_in_tagen')?.inhalt) || 14;
  }

  get gutachten_dauer(): number {
    const constants = (this.response$.getValue()?.[this.contentKey] as IKonstante[]) || [];
    return Number(constants.find((c) => c.art === 'gutachten_dauer_in_tagen')?.inhalt) || 14;
  }

  get tourenplanung_vorbereitungszeit(): number {
    const constants = (this.response$.getValue()?.[this.contentKey] as IKonstante[]) || [];
    return Number(constants.find((c) => c.art === 'tourenplanung_vorbereitungszeit_in_minuten')?.inhalt) || 30;
  }

  get tourenplanung_nachbereitungszeit(): number {
    const constants = (this.response$.getValue()?.[this.contentKey] as IKonstante[]) || [];
    return Number(constants.find((c) => c.art === 'tourenplanung_nachbereitungszeit_in_minuten')?.inhalt) || 30;
  }
}
