import { NgModule } from '@angular/core';

import {
  PostAbmeldenCommandServiceModule,
  PostAbmeldenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostAbmeldenCommandServiceModule,
    PostAbmeldenCommandComponentModule
  ],
  exports: [
    PostAbmeldenCommandServiceModule,
    PostAbmeldenCommandComponentModule
  ]
})
export class PostAbmeldenCommandModule { }
