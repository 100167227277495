import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IVorlagenQueryFilter, IVorlagenQueryShapes, IVorlagenQuerySorter, IVorlagenRequest, IVorlagenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class VorlagenService extends ListQueryService<IVorlagenRequest, IVorlagenResponse, IVorlagenQueryShapes, IVorlagenQueryFilter, IVorlagenQuerySorter> {
  contentKey: keyof IVorlagenResponse = 'vorlagen';
  route = 'texte/vorlagen';
  override dependencies = ['texte/vorlage-entfernt', 'texte/vorlage-gespeichert', 'texte/vorlage-kopiert'];

  getVorlageFuerBewertungsAnlaesse(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerBewertungsAnlaesse']?.find((v) => v.value === value);
  }

  get vorlageFuerBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerBewertungsAnlaesse'] as ISelection[]) : [])));
  }

  getVorlageFuerKunden(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerKunden']?.find((v) => v.value === value);
  }

  get vorlageFuerKunden(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerKunden'] as ISelection[]) : [])));
  }

  getVorlageFuerKundenArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerKundenArten']?.find((v) => v.value === value);
  }

  get vorlageFuerKundenArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerKundenArten'] as ISelection[]) : [])));
  }

  getVorlageFuerKundenGruppen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerKundenGruppen']?.find((v) => v.value === value);
  }

  get vorlageFuerKundenGruppen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerKundenGruppen'] as ISelection[]) : [])));
  }

  getVorlageFuerObjektArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vorlage.fuerObjektArten']?.find((v) => v.value === value);
  }

  get vorlageFuerObjektArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vorlage.fuerObjektArten'] as ISelection[]) : [])));
  }
}
