import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { RechnungsVorlagenFilterComponent } from '.';
import { RechnungsVorlagenServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, RechnungsVorlagenServiceModule],
  declarations: [RechnungsVorlagenFilterComponent],
  exports: [RechnungsVorlagenFilterComponent],
})
export class RechnungsVorlagenFilterComponentModule {}
