import { IFilter, IFilterConfig } from "pbc.types";

import { IProjektZaehler } from '.';

export interface IProjektZaehlerFilter extends IFilter<IProjektZaehler> {}

export const ProjektZaehlerFilterConfig: IFilterConfig<IProjektZaehler> = [];

export type IProjektZaehlerField =
  'projektZaehler.id'|
  'projektZaehler.zaehler'
;
