import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostBerichtCommandShapes, IPostBerichtRequest, IPostBerichtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostBerichtCommandService extends PostCommandService<IPostBerichtRequest, IPostBerichtResponse, IPostBerichtCommandShapes> {
  readonly route = 'markt/bericht';
  override dependencies = ['markt/written-herausgeber', 'markt/removed-herausgeber'];

  getBerichtHerausgeber(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['bericht.herausgeber']?.find((v) => v.value === value);
  }

  get berichtHerausgeber(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['bericht.herausgeber'] as ISelection[]) : [])));
  }
}
