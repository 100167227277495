import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IMitarbeiterQueryFilter, IMitarbeiterQueryShapes, IMitarbeiterQuerySorter, IMitarbeiterRequest, IMitarbeiterResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class MitarbeiterService extends ListQueryService<IMitarbeiterRequest, IMitarbeiterResponse, IMitarbeiterQueryShapes, IMitarbeiterQueryFilter, IMitarbeiterQuerySorter> {
  contentKey: keyof IMitarbeiterResponse = 'mitarbeiter';
  static instance: MitarbeiterService;

  route = 'personen/mitarbeiter';
  override dependencies = [
    'personen/written-rolle',
    'personen/removed-rolle',
    'personen/written-standort',
    'personen/removed-standort',
    'personen/mitarbeiter-aktualisiert',
    'personen/azure-active-directory-synchronisiert',
    'zeiten/urlaub-gespeichert',
    'zeiten/urlaub-entfernt',
    'zeiten/krankheit-gespeichert',
    'zeiten/krankheit-entfernt',
    'zeiten/rahmen-anpassung-gespeichert',
    'zeiten/rahmen-anpassung-entfernt',
    'zeiten/zeit-gespeichert',
    'zeiten/zeit-entfernt',
  ];

  constructor() {
    super();
    if (!MitarbeiterService.instance) {
      MitarbeiterService.instance = this;
    }
    return MitarbeiterService.instance;
  }

  getMitarbeiterRollen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['mitarbeiter.rollen']?.find((v) => v.value === value);
  }

  get mitarbeiterRollen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['mitarbeiter.rollen'] as ISelection[]) : [])));
  }

  getMitarbeiterStandort(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['mitarbeiter.standort']?.find((v) => v.value === value);
  }

  get mitarbeiterStandort(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['mitarbeiter.standort'] as ISelection[]) : [])));
  }
}
