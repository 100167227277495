import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostAbschnittCommandShapes, IPostAbschnittRequest, IPostAbschnittResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostAbschnittCommandService extends PostCommandService<IPostAbschnittRequest, IPostAbschnittResponse, IPostAbschnittCommandShapes> {
  readonly route = 'texte/abschnitt';

  getAbschnittVorlage(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['abschnitt.vorlage']?.find((v) => v.value === value);
  }

  get abschnittVorlage(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['abschnitt.vorlage'] as ISelection[]) : [])));
  }
}
