import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostTourStatusCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostTourStatusCommandService],
  exports: []
})
export class PostTourStatusCommandServiceModule { }
