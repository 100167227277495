import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteObjektCommandServiceModule } from '..';
import { DeleteObjektCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteObjektCommandServiceModule
  ],
  declarations: [DeleteObjektCommandComponent],
  exports: [DeleteObjektCommandComponent],

})
export class DeleteObjektCommandComponentModule { }
