import { NgModule } from '@angular/core';
import {
  DateiArtsFilterComponentModule,
  DateiArtsSorterComponentModule,
  DateiArtsListComponentModule
} from '.';

@NgModule({
  imports: [
    DateiArtsFilterComponentModule,
    DateiArtsSorterComponentModule,
    DateiArtsListComponentModule
  ],
  exports: [
    DateiArtsFilterComponentModule,
    DateiArtsSorterComponentModule,
    DateiArtsListComponentModule
  ],
})
export class DateiArtsQueryModule { }