import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IHerausgeber } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { HerausgebersService } from '../service';

@Component({
  selector: 'fa-kt-herausgebers-list',
  templateUrl: './herausgebers-list.component.html',
  styleUrls: ['./herausgebers-list.component.css'],
})
export class HerausgebersListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IHerausgeber> = new EventEmitter<IHerausgeber>();

  constructor(public herausgebers: HerausgebersService) {}

  ngOnDestroy() {}
}
