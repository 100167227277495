import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { BerichtsVorlagenQueryFilterConfig, IBerichtsVorlagenQueryShapes, IBerichtsVorlagenResponse } from 'fa-kt.types';
import { BerichtsVorlagenService } from '../service';

@Component({
  selector: 'fa-kt-berichts-vorlagen-filter',
  templateUrl: './berichts-vorlagen-filter.component.html',
  styleUrls: ['./berichts-vorlagen-filter.component.css'],
})
export class BerichtsVorlagenFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public BerichtsVorlagenFilterConfig: IFilterConfig<IBerichtsVorlagenResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public berichtsVorlagen: BerichtsVorlagenService,
  ) {
    this.BerichtsVorlagenFilterConfig = BerichtsVorlagenQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.berichtsVorlagen.shapes$.subscribe((shapes: IBerichtsVorlagenQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.BerichtsVorlagenFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.BerichtsVorlagenFilterConfig[index].selections = shapes;
  }
}
