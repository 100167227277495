import { NgModule } from '@angular/core';

import {
  DeleteAbteilungCommandServiceModule,
  DeleteAbteilungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteAbteilungCommandServiceModule,
    DeleteAbteilungCommandComponentModule
  ],
  exports: [
    DeleteAbteilungCommandServiceModule,
    DeleteAbteilungCommandComponentModule
  ]
})
export class DeleteAbteilungCommandModule { }
