import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFeldOptionCommandServiceModule } from '..';
import { DeleteFeldOptionCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFeldOptionCommandServiceModule
  ],
  declarations: [DeleteFeldOptionCommandComponent],
  exports: [DeleteFeldOptionCommandComponent],

})
export class DeleteFeldOptionCommandComponentModule { }
