import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostReisekostenabrechnungCommandShapes, IPostReisekostenabrechnungRequest, IPostReisekostenabrechnungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostReisekostenabrechnungCommandService extends PostCommandService<IPostReisekostenabrechnungRequest, IPostReisekostenabrechnungResponse, IPostReisekostenabrechnungCommandShapes> {
  readonly route = 'finanzen/reisekostenabrechnung';
  override dependencies = ['finanzen/written-reisekostenabrechnung-status', 'finanzen/removed-reisekostenabrechnung-status'];

  getReisekostenabrechnungMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenabrechnung.mitarbeiter']?.find((v) => v.value === value);
  }

  get reisekostenabrechnungMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenabrechnung.mitarbeiter'] as ISelection[]) : [])));
  }

  getReisekostenabrechnungReisekostenabrechnungStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenabrechnung.reisekostenabrechnungStatus']?.find((v) => v.value === value);
  }

  get reisekostenabrechnungReisekostenabrechnungStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenabrechnung.reisekostenabrechnungStatus'] as ISelection[]) : [])));
  }

  getReisekostenabrechnungTouren(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenabrechnung.touren']?.find((v) => v.value === value);
  }

  get reisekostenabrechnungTouren(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenabrechnung.touren'] as ISelection[]) : [])));
  }
}
