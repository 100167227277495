import { DatePipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';

import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';

import { BaseComponent, ISitemapPage } from 'pbc.angular';

import { hasTimeConflict } from 'fa-kt.functions';
import { Besichtigung, IBesichtigung, IDeleteBesichtigungRequest, IGutachten, IKunde, IObjektArt, IPostBesichtigungRequest, IProjektArt, ITour } from 'fa-kt.types';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { IAddress } from 'pbc.types';
import { KundenService } from '../../../kunden';
import { ObjektArtsService } from '../../../objekte';
import { ProjektArtsService } from '../../../projekte';

@Component({
  selector: 'fa-kt-besichtigung',
  templateUrl: './besichtigung.component.html',
  styleUrls: ['./besichtigung.component.css'],
})
export class BesichtigungComponent extends BaseComponent {
  row?: Besichtigung;
  @Input() set besichtigung(row: Besichtigung | undefined) {
    this.$postBesichtigung.next({ besichtigung: row?.besichtigung });
    this.$deleteBesichtigung.next({ besichtigung: row?.besichtigung?.id });
    this.row = row;
    this.objektArt = this.objektArten.result$.getValue()?.objektArts?.find((r) => r.id === row?.gutachten?.objektArt);
    this.projektArt = this.projektArten.result$.getValue()?.projektArts?.find((r) => r.id === row?.projekt?.projektArt);
    this.kunde = this.kunden.result$.getValue()?.kunden?.find((k) => k.kunde.id === row?.projekt?.kunde)?.kunde;
    this.sachverständiger = this.meta.users$
      .getValue()
      ?.find((u) => u.value === row.besichtigung?.mitarbeiter)
      ?.label?.split(' (')
      ?.shift();
    this.email = row?.gutachten?.besichtigung_email;
    this.ansprechpartner = row?.gutachten?.besichtigung_kontakt || 'ANSPRECHPARTNER';
    this.adresse = row?.besichtigung?.ende?.plz + ', ' + row?.besichtigung?.ende?.strasse;
    this.setTag(row?.besichtigung?.von);
    this.setZeiten(row?.besichtigung);
  }
  tour?: ITour;
  kunde?: IKunde;
  objektArt?: IObjektArt;
  projektArt: IProjektArt;
  adresse?: string;
  sachverständiger?: string;
  email?: string;
  ansprechpartner?: string;
  datum?: string;
  tag: string;
  vorbereitungszeit?: number;
  nachbereitungszeit?: number;
  aufenthaltszeit?: number;
  besichtigungeszeit?: number;
  gesamteZeit?: number;

  get minuten() {
    return this.objektArt?.besichtigungsZeit || 30;
  }

  emailStyle = 'terminvorschlag';

  _projekt: ISitemapPage;
  _kunde: ISitemapPage;

  public readonly $postBesichtigung = new BehaviorSubject<IPostBesichtigungRequest | undefined>(undefined);
  public readonly $deleteBesichtigung = new BehaviorSubject<IDeleteBesichtigungRequest | undefined>(undefined);

  constructor(
    @Inject(NZ_MODAL_DATA) data: { besichtigung: Besichtigung; tour: ITour },
    public objektArten: ObjektArtsService,
    public projektArten: ProjektArtsService,
    public kunden: KundenService,
  ) {
    super();
    this._projekt = this.sitemap['PROJEKTE'].Pages['PROJEKT'];
    this._kunde = this.sitemap['KUNDEN'].Pages['KUNDE'];
    this.tour = data.tour;
    this.besichtigung = cloneDeep(data.besichtigung);
  }

  async navigateToProjekt(gutachten: IGutachten) {
    await this.router.navigate(this._projekt.url, { queryParams: { id: gutachten.projekt, gutachten: gutachten.id, tab: 'gutachten', detail: 'gutachten' } });
  }

  async navigateToKunde(id: string) {
    await this.router.navigate(this._kunde.url, { queryParams: { id } });
  }

  setZeiten(besichtigung?: IBesichtigung, refresh = false) {
    if (!besichtigung) return;
    const { fahrtVon, von, kommuniziertVon, kommuniziertBis, bis } = besichtigung;
    this.besichtigungeszeit = moment(new Date(kommuniziertBis)).diff(moment(new Date(kommuniziertVon)), 'minutes') || 0;
    this.aufenthaltszeit = moment(new Date(bis)).diff(moment(new Date(von)), 'minutes') || 0;
    this.vorbereitungszeit = moment(new Date(kommuniziertVon)).diff(moment(new Date(von)), 'minutes') || 0;
    this.nachbereitungszeit = moment(new Date(bis)).diff(moment(new Date(kommuniziertBis)), 'minutes') || 0;
    this.gesamteZeit = moment(new Date(bis)).diff(moment(new Date(fahrtVon)), 'minutes') || 0;
    if (this.row) this.row.hasTimeConflicts = hasTimeConflict(besichtigung, this.tour);
    if (refresh) this.changeRef.detectChanges();
  }

  setTag(am: Date) {
    this.datum = new Date(am as Date).toLocaleDateString();
    switch (new Date(am as Date).getDay()) {
      case 0:
        this.tag = 'Sonntag';
        break;
      case 1:
        this.tag = 'Montag';
        break;
      case 2:
        this.tag = 'Dienstag';
        break;
      case 3:
        this.tag = 'Mittwoch';
        break;
      case 4:
        this.tag = 'Donnerstag';
        break;
      case 5:
        this.tag = 'Freitag';
        break;
      case 6:
        this.tag = 'Samstag';
        break;
    }
  }

  openAddress(addresse?: IAddress) {
    const url = `https://www.google.com/maps/place/${addresse?.latitude},${addresse?.longitude}`;
    window.open(url, '_blank')?.focus();
  }

  terminvorschlag(asHTML = false) {
    const datum = new Date(this.tour?.am as Date).toLocaleDateString();
    const pipe = new DatePipe('de');
    let von, bis;
    try {
      von = pipe.transform(new Date(this.row?.besichtigung?.kommuniziertVon as Date), 'HH:mm');
      bis = pipe.transform(new Date(this.row?.besichtigung?.kommuniziertBis as Date), 'HH:mm');
    } catch (e: any) {
      von = 'VON';
      bis = 'BIS';
    }
    const objektArt = this.objektArt?.name || 'OBJEKTART';
    const gutachtenArt = this.projektArt?.name || 'PROJEKTART';
    const kunde = this.kunde?.name || 'KUNDE';

    let message = `Sehr geehrter/geehrte Herr/Frau ${this.ansprechpartner}.

wie Sie dem anliegenden Schreiben (Anlage Legitimationsschreiben) entnehmen können, wurde die FA|KT Valuation durch die ${kunde} beauftragt, für Ihr vorbenanntes Objekt ein ${gutachtenArt}  zu erstellen.
Dieser Auftrag beinhaltet auch eine Innen- und Außenbesichtigung Ihrer Immobilie und findet in der Regel zusammen mit weiteren Objektbesichtigungen im Umfeld statt.

In diesem Zusammenhang kann die Besichtigung der Immobilie am ${this.tag}, ${datum} zwischen ${von} – ${bis} Uhr erfolgen.

Für die Besichtigung Ihrer Immobilie haben wir einen Zeitraum von ca. ${this.minuten} Min eingeplant. Es sollten alle im Grundbuch eingetragenen Flächen mit den nachfolgenden Bereichen erfasst werden

• ${objektArt}: Wohn- und Nutzflächen (inkl. Flur, Keller, Dachgeschoss, Heizungsanlage, den Außenbereich mit ggf. Stellplätzen/Garagen und Nebengebäuden etc.

Wir werden nach Möglichkeit Fotos anfertigen, aber dabei selbstverständlich besonders persönliche oder sensible Bereiche aussparen. Die Fotos dienen ausschließlich der Dokumentation und der Gutachtenbearbeitung mit Schwerpunkt auf Bauweise, Ausstattung, Funktionalität, Zustand und Instandhaltungsstau. Die Gutachten werden ausschließlich der kreditierenden Bank zur Verfügung gestellt, die Fotos werden nach den gesetzlichen Bestimmungen aufbewahrt bzw. gelöscht.

Bitte bestätigen Sie uns den Termin unter der Telefonnummer 040/30 06 87 49-50 oder schreiben uns eine E-Mail an besichtigung@fa-kt-valuation.de.

Über eine baldige Rückantwort freuen wir uns.

Vielen Dank im Voraus für Ihre Unterstützung.`;

    if (asHTML) {
      message = message.split('\n').join('%0D%0A');
    }
    return message;
  }

  terminbestaetigung(asHTML = false) {
    const pipe = new DatePipe('de');
    let von, bis;
    try {
      von = pipe.transform(new Date(this.row?.besichtigung?.kommuniziertVon as Date), 'HH:mm');
      bis = pipe.transform(new Date(this.row?.besichtigung?.kommuniziertBis as Date), 'HH:mm');
    } catch (e: any) {
      von = 'VON';
      bis = 'BIS';
    }

    let message = `Sehr geehrter/geehrte Herr/Frau ${this.ansprechpartner},
vielen Dank für die Bestätigung des Termins.
Gerne wird unser/-e Sachverständiger/-in ${this.sachverständiger} die Besichtigung am ${this.tag}, ${this.datum} zwischen ${von} – ${bis} Uhr durchführen.

Für Rückfragen stehen wir Ihnen gerne zur Verfügung.';
    `;
    if (asHTML) {
      message = message.split('\n').join('%0D%0A');
    }
    return message;
  }
}
