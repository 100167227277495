import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'pbc-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImageUploadComponent,
    },
  ],
})
export class ImageUploadComponent implements OnInit, OnDestroy, ControlValueAccessor {
  onChange = (src: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  visible = false;

  constructor(private modal: NzModalService) {}

  image: string = '';
  event: any;

  @Input() set src(url: string | null) {
    this.Src = url ? url : undefined;
  }
  Src: string | undefined;
  @Input()
  text: string = '';
  @Input()
  color: string = '#000';

  @Output()
  uploaded: EventEmitter<string> = new EventEmitter();

  @ViewChild('FileInput')
  fileInput!: ElementRef;

  @ViewChild('ImageCropper', { static: false })
  imageCropper: TemplateRef<ImageCropperComponent>;

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  uploadedImage(event: any): void {
    this.event = event;
    if (this.event) {
      this.modal.confirm({
        nzTitle: 'Avatar hochladen',
        nzContent: this.imageCropper,
        nzOkText: 'Verwenden',
        nzOnOk: () => this.finished(),
      });
    }
  }

  async toDataURL(url): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  async imageCropped(event: ImageCroppedEvent) {
    if (event?.objectUrl) this.image = await this.toDataURL(event.objectUrl);
  }

  finished() {
    this.uploaded.emit(this.image);
    this.markAsTouched();
    this.onChange(this.image);
    this.writeValue(this.image);
    this.image = undefined;
    this.event = undefined;
    this.fileInput.nativeElement.value = '';
  }

  reset() {
    this.uploaded.emit(undefined);
    this.markAsTouched();
    this.onChange(undefined);
    this.writeValue(undefined);
    this.image = undefined;
    this.event = undefined;
    this.fileInput.nativeElement.value = '';
  }

  writeValue(src: string) {
    this.src = src;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
