import { NgModule } from '@angular/core';
import {
  FeldKategoriesFilterComponentModule,
  FeldKategoriesSorterComponentModule,
  FeldKategoriesListComponentModule
} from '.';

@NgModule({
  imports: [
    FeldKategoriesFilterComponentModule,
    FeldKategoriesSorterComponentModule,
    FeldKategoriesListComponentModule
  ],
  exports: [
    FeldKategoriesFilterComponentModule,
    FeldKategoriesSorterComponentModule,
    FeldKategoriesListComponentModule
  ],
})
export class FeldKategoriesQueryModule { }