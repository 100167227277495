import { Component } from '@angular/core';
import { TourService } from '../service';

@Component({
  selector: 'fa-kt-saved-tour-filters',
  templateUrl: './saved-tour-filters.component.html',
  styleUrls: ['./saved-tour-filters.component.css'],
})
export class SavedTourFiltersComponent {
  constructor(public tour: TourService) {}
}
