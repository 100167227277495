<button nz-button type="button" nzType="default" nzShape="circle"
nz-popover nzPopoverTrigger="click" [nzPopoverTitle]="undefined" [nzPopoverContent]="IconPickerContent" [nzPopoverVisible]="visible && !disabled" (nzPopoverVisibleChange)="term = ''; visible = $event; markAsTouched()">
  <i nz-icon [nzType]="_icon"></i>
</button>

<ng-template #IconPickerContent>
  <div  class="selection-popover">
    <input nz-input [(ngModel)]="term"  style="margin-bottom: 12px"/>
    <br>
    <cdk-virtual-scroll-viewport itemSize="7" style="height: 300px ">
      <nz-list nzBorderless>
        <nz-list-item style="padding: 6px" (click)="setIcon('')">
          <span nz-typography>Keins</span>
        </nz-list-item>
        <nz-list-item *cdkVirtualFor="let icon of icons | search:term" (click)="setIcon(icon.name)" style="padding: 6px">
          <i nz-icon [nzType]="icon.name"></i>
          <span nz-typography><mark>{{ icon.name }}</mark></span>
        </nz-list-item>
      </nz-list>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>
