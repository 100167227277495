import { NgModule } from '@angular/core';

import {
  DeleteZeitArtCommandComponentModule, DeleteZeitArtCommandServiceModule
} from '.';

@NgModule({
  imports: [
    DeleteZeitArtCommandServiceModule,
    DeleteZeitArtCommandComponentModule
  ],
  exports: [
    DeleteZeitArtCommandServiceModule,
    DeleteZeitArtCommandComponentModule
  ]
})
export class DeleteZeitArtCommandModule { }
