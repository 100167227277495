import { IFilter, IFilterConfig } from "pbc.types";

import { IFeldUnterkategorie } from '.';

export interface IFeldUnterkategorieFilter extends IFilter<IFeldUnterkategorie> {}

export const FeldUnterkategorieFilterConfig: IFilterConfig<IFeldUnterkategorie> = [];

export type IFeldUnterkategorieField =
  'feldUnterkategorie.id'|
  'feldUnterkategorie.feldKategorie'|
  'feldUnterkategorie.name'|
  'feldUnterkategorie.initialen'|
  'feldUnterkategorie.kommentar'
;
