import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostRechnungsVorlageCommandShapes, IPostRechnungsVorlageRequest, IPostRechnungsVorlageResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostRechnungsVorlageCommandService extends PostCommandService<IPostRechnungsVorlageRequest, IPostRechnungsVorlageResponse, IPostRechnungsVorlageCommandShapes> {
  readonly route = 'finanzen/rechnungs-vorlage';

  override async request(payload: IPostRechnungsVorlageRequest): Promise<IPostRechnungsVorlageResponse> {
    return (await this.http.upload<IPostRechnungsVorlageResponse>(this.route, payload, 'rechnungs-vorlagen')) as unknown as IPostRechnungsVorlageResponse;
  }
}
