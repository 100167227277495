<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="tour.search$.next($event)" [search]="tour.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-tour-filter [style.width]="'100%'"></fa-kt-tour-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-tour-sorter [style.width]="'100%'"></fa-kt-tour-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-tour-list [style.width]="'100%'" (select)="setTour($event)" [loading]="$loading | async"></fa-kt-tour-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="reisekostenabrechnung.search$.next($event)" [search]="reisekostenabrechnung.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-reisekostenabrechnung-filter [style.width]="'100%'"></fa-kt-reisekostenabrechnung-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-reisekostenabrechnung-sorter [style.width]="'100%'"></fa-kt-reisekostenabrechnung-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-reisekostenabrechnung-list [style.width]="'100%'" (select)="setReisekostenabrechnung($event)" [loading]="$loading | async"></fa-kt-reisekostenabrechnung-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="projekt.search$.next($event)" [search]="projekt.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-projekt-filter [style.width]="'100%'"></fa-kt-projekt-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-projekt-sorter [style.width]="'100%'"></fa-kt-projekt-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-projekt-list [style.width]="'100%'" (select)="setProjekt($event)" [loading]="$loading | async"></fa-kt-projekt-list>


  <fa-kt-delete-reisekostenabrechnung [style.width]="'100%'"  [value]="$deleteReisekostenabrechnung | async" (finished)="finishedDeleteReisekostenabrechnung($event)"></fa-kt-delete-reisekostenabrechnung>

  <fa-kt-post-reisekostenabrechnung [style.width]="'100%'"  [value]="$postReisekostenabrechnung | async" (finished)="finishedPostReisekostenabrechnung($event)"></fa-kt-post-reisekostenabrechnung>



    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="finanzen" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <nz-space>
      <button *nzSpaceItem nz-button nzType="default" type="button" (click)="download()" [nzLoading]="$loading | async" [disabled]="!($valid | async)">
        <i nz-icon nzType="download" nzTheme="outline"></i>
        Herunterladen
      </button>
      <fa-kt-delete-reisekostenabrechnung
        *nzSpaceItem
        [style.width]="'100%'"
        [value]="$deleteReisekostenabrechnung | async"
        (finished)="finishedDeleteReisekostenabrechnung($event)"
      ></fa-kt-delete-reisekostenabrechnung>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row nzJustify="center">
    <nz-col nzSpan="24">
      <!--- << page-content    -->
      <fa-kt-post-reisekostenabrechnung
        (valid)="$valid.next($event)"
        [herunterladen]="$download | async"
        [style.width]="'100%'"
        [value]="$postReisekostenabrechnung | async"
        (finished)="finishedPostReisekostenabrechnung($event)"
      ></fa-kt-post-reisekostenabrechnung>
    </nz-col>
    <nz-col nzSpan="4">
      <pbc-meta [value]="($postReisekostenabrechnung | async)?.reisekostenabrechnung"></pbc-meta>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
