import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';

import { Workbook } from 'exceljs';
import { orderBy } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';

import { BasePage, ISitemapPage } from 'pbc.angular';

import { IPostReferenzenHochladenRequest, IPostReferenzenHochladenResponse } from 'fa-kt.types';
import { FelderService, FormulareService, PostReferenzenHochladenCommandService } from '../../..';

@Component({
  selector: 'fa-kt-felder-page',
  templateUrl: './felder.page.html',
  styleUrls: ['./felder.page.css'],
})
export class FelderPage extends BasePage implements OnInit, OnDestroy {
  description = { context: 'FELDER', page: 'FELDER' };
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postReferenzenHochladen: BehaviorSubject<IPostReferenzenHochladenRequest | null> = new BehaviorSubject<IPostReferenzenHochladenRequest | null>(null);

  public _feld: ISitemapPage;

  constructor(
    public felder: FelderService,
    public formulare: FormulareService,
    public postReferenzenHochladen: PostReferenzenHochladenCommandService,
  ) {
    super();
    this._feld = this.sitemap['FELDER'].Pages['FELD'];
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setFelder(id: string) {
    /* << set-felder    */
    await this.navigateToFeld({ id });
    /*    set-felder >> */
  }

  async finishedPostReferenzenHochladen(response?: IPostReferenzenHochladenResponse) {
    /* << after post-referenzen-hochladen    */
    /*    after post-referenzen-hochladen >> */
  }

  public async navigateToFeld(queryParams: Params = {}) {
    await this.router.navigate(this._feld.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  public async submitPostReferenzenHochladen(request?: IPostReferenzenHochladenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postReferenzenHochladen.getValue();
    if (payload) {
      try {
        const response = await this.postReferenzenHochladen.request(payload);
        await this.finishedPostReferenzenHochladen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  numberToColumnName(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;

    let s = '';
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
  }

  async export() {
    this.$loading.next(true);
    const [blob, formulareQuery] = await Promise.all([this.files.get('fa-kt-apps/Referenzfelder.xlsx'), this.formulare.request({})]);
    if (!blob) {
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Referenzen';
    const worksheet = workbook.getWorksheet(sheetName);
    if (!worksheet) {
      this.$loading.next(false);
      this.message.warning('Das Tabellenblatt "Referenzen" konnte nicht gefunden werden');
      return;
    }

    const headerStyle = worksheet.getCell('F1').style;
    const standardwertHeader = worksheet.getCell('F1').value;
    const optionenHeader = worksheet.getCell('G1').value;
    const dotdotdotHeader = worksheet.getCell('H1').value;

    const formulare = orderBy(formulareQuery.formulare, (row) => row.formular.name).map((row, i) => ({ ...row.formular, row: this.numberToColumnName(i + 5) }));
    const formulareCount = formulare.length;
    formulare.forEach((formular) => {
      worksheet.getCell(formular.row + '1').style = headerStyle;
      worksheet.getCell(formular.row + '1').value = formular.name;
    });

    const standardwertHeaderRow = this.numberToColumnName(formulareCount + 5 + 0);
    worksheet.getCell(standardwertHeaderRow + '1').style = headerStyle;
    worksheet.getCell(standardwertHeaderRow + '1').value = standardwertHeader;

    const optionenHeaderRow = this.numberToColumnName(formulareCount + 5 + 1);
    worksheet.getCell(optionenHeaderRow + '1').style = headerStyle;
    worksheet.getCell(optionenHeaderRow + '1').value = optionenHeader;

    const dotdotdotHeaderRow = this.numberToColumnName(formulareCount + 5 + 2);
    worksheet.getCell(dotdotdotHeaderRow + '1').style = headerStyle;
    worksheet.getCell(dotdotdotHeaderRow + '1').value = dotdotdotHeader;

    this.felder.result$.getValue()?.felder?.forEach((feld, i) => {
      worksheet.getCell('A' + (i + 2)).value = feld.feld.schluessel;
      worksheet.getCell('B' + (i + 2)).value = feld.feld.name;
      worksheet.getCell('C' + (i + 2)).value = feld.feld.art;
      worksheet.getCell('D' + (i + 2)).value = feld.feld.format;
      worksheet.getCell('E' + (i + 2)).value = feld.feld.kommentar;

      formulare.forEach((formular) => {
        worksheet.getCell(formular.row + (i + 2)).value = formular.felder?.includes(feld.feld.id) ? 'x' : null;
      });

      worksheet.getCell(standardwertHeaderRow + (i + 2)).value =
        !feld.feld.voreinstellung || JSON.stringify(feld.feld.voreinstellung) === '[]' || (feld.feld.voreinstellung.length && feld.feld.voreinstellung.length === 0) ? '' : feld.feld.voreinstellung;
      feld.feldOptionen?.forEach((option, j) => {
        worksheet.getCell(this.numberToColumnName(j + formulareCount + 5 + 1) + (i + 2)).value = option.option;
      });
    });

    await this.files.downloadBlob(new Blob([await workbook.xlsx.writeBuffer()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Export der Felder.xlsx');
  }
}
