import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostKundeCommandServiceModule } from '..';
import { PostKundeCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostKundeCommandServiceModule
  ],
  declarations: [PostKundeCommandComponent],
  exports: [PostKundeCommandComponent],

})
export class PostKundeCommandComponentModule { }
