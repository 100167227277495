import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BasePostCommandComponent, CustomValidators } from 'pbc.angular';

import { IPostFeldRequest, IPostFeldResponse } from 'fa-kt.types';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IFeldOption } from 'fa-kt.types';
import { toInitials } from 'pbc.functions';
import { PostFeldCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-feld',
  templateUrl: './post-feld.component.html',
  styleUrls: ['./post-feld.component.css'],
})
export class PostFeldCommandComponent extends BasePostCommandComponent<IPostFeldRequest, IPostFeldResponse> implements OnInit, OnDestroy {
  description = { context: 'FELDER', command: 'POST_FELD' };
  contentKey: keyof IPostFeldRequest = 'feld';
  async prepare(): Promise<void> {
    await this.postFeld.prepare();
  }
  async request(payload: IPostFeldRequest): Promise<IPostFeldResponse> {
    return this.postFeld.request(payload);
  }
  @Input() optionen: IFeldOption[] = [];

  constructor(public postFeld: PostFeldCommandService) {
    super();
    this.form = this.fb.group({
      feld: this.fb.group({
        id: [null, []],
        schluessel: [null, [Validators.required, CustomValidators.isNotSpecial]],
        name: [null, [Validators.required]],
        kommentar: [null, []],
        feldKategorie: [null, [Validators.required]],
        feldUnterkategorie: [null, [Validators.required]],
        art: [null, [Validators.required]],
        format: [null, []],
        voreinstellung: [null, []],
      }),
    });
    this.patch();
  }

  override ngOnInit() {
    super.ngOnInit();
    combineLatest([
      this.feldFeldKategorie?.valueChanges.pipe(distinctUntilChanged()),
      this.feldFeldUnterkategorie?.valueChanges.pipe(distinctUntilChanged()),
      this.feldName?.valueChanges.pipe(debounceTime(600), distinctUntilChanged()),
    ])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(() => {
        if (this.feldSchluessel.value || !this.feldFeldKategorie?.value || !this.feldFeldUnterkategorie?.value || !this.feldName?.value) return;
        const shapes = this.postFeld.shapes$.getValue();
        const feldKategories = shapes?.['feld.feldKategorie'];
        const feldKategorie = feldKategories?.find((fk) => fk.value === this.feldFeldKategorie?.value);
        const kategorie = feldKategorie?.label?.split(' |')[0];
        const feldUnterkategories = shapes?.['feld.feldUnterkategorie'];
        const feldUnterkategorie = feldUnterkategories?.find((fk) => fk.value === this.feldFeldUnterkategorie?.value);
        const unterkategorie = feldUnterkategorie?.label?.split(' |')[0];
        const name = this.feldName.value;
        if (kategorie && unterkategorie && name) this.feldSchluessel?.patchValue(kategorie + '_' + unterkategorie + '_' + toInitials(name, true));
      });
    this.feldSchluessel.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((schluessel) => {
      if (!schluessel) return;
      const replaced = schluessel.replace(/\s/g, '');
      if (schluessel !== replaced) this.feldSchluessel.patchValue(replaced);
    });
  }

  hasValue(option: string) {
    let state = this.feldVoreinstellung?.value;
    if (Array.isArray(state)) {
      return state.includes(option);
    } else {
      return state === option;
    }
  }

  toggleValues(option: string) {
    let state = this.feldVoreinstellung?.value;
    state = Array.isArray(state) ? state : [state];
    state = state.includes(option) ? state.filter((s: string) => s !== option) : [...state, option];
    state = state.filter((option: string) => option);
    this.feldVoreinstellung?.patchValue(state);
  }

  get feldId() {
    return this.form.controls.feld.get('id');
  }
  get feldSchluessel() {
    return this.form.controls.feld.get('schluessel');
  }
  get feldName() {
    return this.form.controls.feld.get('name');
  }
  get feldKommentar() {
    return this.form.controls.feld.get('kommentar');
  }
  get feldFeldKategorie() {
    return this.form.controls.feld.get('feldKategorie');
  }
  get feldFeldUnterkategorie() {
    return this.form.controls.feld.get('feldUnterkategorie');
  }
  get feldArt() {
    return this.form.controls.feld.get('art');
  }
  get feldFormat() {
    return this.form.controls.feld.get('format');
  }
  get feldVoreinstellung() {
    return this.form.controls.feld.get('voreinstellung');
  }
}
