import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFormularCommandServiceModule } from '..';
import { DeleteFormularCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFormularCommandServiceModule
  ],
  declarations: [DeleteFormularCommandComponent],
  exports: [DeleteFormularCommandComponent],

})
export class DeleteFormularCommandComponentModule { }
