import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IHerausgeberSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  website?: ISorterExpression;
  outlookKontaktes?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const HerausgeberSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('website', 'Website'),
  SorterConfig('outlookKontaktes', 'Outlook Kontaktes'),
  SorterConfig('kommentar', 'Kommentar')
];
