import { IFilter, IFilterConfig } from "pbc.types";

import { IBereich } from '.';

export interface IBereichFilter extends IFilter<IBereich> {}

export const BereichFilterConfig: IFilterConfig<IBereich> = [];

export type IBereichField =
  'bereich.id'|
  'bereich.name'|
  'bereich.kommentar'|
  'bereich.areal'
;
