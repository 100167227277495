import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IIchResponse } from '.';

export interface IIchQueryFilter extends IFilter<IIchResponse> {}

export const IchQueryFilterConfig: IFilterConfig<IIchResponse> = [];

export interface IIchQueryShapes extends IShape {}

export type IIchQueryField = '';
