import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitenFilterComponent } from '.';
import { ZeitenServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitenServiceModule
  ],
  declarations: [ZeitenFilterComponent],
  exports: [ZeitenFilterComponent],

})
export class ZeitenFilterComponentModule { }
