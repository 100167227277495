import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteBereichRequest, IDeleteBereichResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteBereichCommandService extends DeleteCommandService<IDeleteBereichRequest, IDeleteBereichResponse> {
  readonly route = 'geo/settings/bereich';
}
