<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <button nz-button nzType="primary" (click)="create()" [nzLoading]="$loading | async" [disabled]="$loading | async">
      <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
      Neu
    </button>
  </nz-page-header-extra>
</nz-page-header>
<nz-content>
  <nz-row>
    <nz-col nzSpan="24">
      <pbc-search-bar [style.width]="'100%'" (searchChange)="ortKategories.search$.next($event)" [search]="ortKategories.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan="24">
      <nz-list nzSize="large" [nzLoading]="$loading | async">
        <nz-list-item *ngFor="let ortKategorie of (ortKategories.result$ | async)?.ortKategories" (click)="set(ortKategorie.id)">
          <nz-list-item-meta [nzDescription]="ListDescription">
            <ng-template #ListDescription>
              <!--- << description    -->
              <!---    description >> -->
            </ng-template>
            <nz-list-item-meta-title>
              <a>
                <!--- << content    -->
                {{ ortKategorie.name }}
                <!---    content >> -->
              </a>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
            </nz-list-item-action>
          </ul>
        </nz-list-item>
      </nz-list>
    </nz-col>
  </nz-row>
</nz-content>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="reset()" [nzVisible]="$ortKategorie | async">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> 📝 Bearbeitung </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb>
        <pbc-breadcrumbs [pages]="page.breadcrumbs" [extra]="model.emoji + ' ' + model.single"></pbc-breadcrumbs>
      </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-delete-ort-kategorie [value]="$ortKategorie | async" [loading]="$loading | async" (finished)="reset()"></fa-kt-delete-ort-kategorie>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-post-ort-kategorie [value]="$ortKategorie | async" [loading]="$loading | async" (finished)="reset()" [style.width]="'100%'"></fa-kt-post-ort-kategorie>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter> </ng-template>
</nz-modal>
