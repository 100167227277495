import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface ITemplateSorter extends ISorter {
  id?: ISorterExpression;
  datei?: ISorterExpression;
  name?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const TemplateSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('datei', 'Datei'),
  SorterConfig('name', 'Name'),
  SorterConfig('kommentar', 'Kommentar')
];
