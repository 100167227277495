import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostGutachtenStatusCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostGutachtenStatusCommandService],
  exports: []
})
export class PostGutachtenStatusCommandServiceModule { }
