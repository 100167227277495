import { Injectable } from '@angular/core';

import { IDeleteKrankheitRequest, IDeleteKrankheitResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteKrankheitCommandService extends DeleteCommandService<IDeleteKrankheitRequest, IDeleteKrankheitResponse> {
  readonly route = 'zeiten/krankheit';
}
