import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostRolleCommandShapes, IPostRolleRequest, IPostRolleResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostRolleCommandService extends PostCommandService<IPostRolleRequest, IPostRolleResponse, IPostRolleCommandShapes> {
  readonly route = 'personen/settings/rolle';
}
