import { IFilter, IFilterConfig } from "pbc.types";

import { IReisekostenabrechnung } from '.';

export interface IReisekostenabrechnungFilter extends IFilter<IReisekostenabrechnung> {}

export const ReisekostenabrechnungFilterConfig: IFilterConfig<IReisekostenabrechnung> = [];

export type IReisekostenabrechnungField =
  'reisekostenabrechnung.id'|
  'reisekostenabrechnung.mitarbeiter'|
  'reisekostenabrechnung.touren'|
  'reisekostenabrechnung.reisekostenabrechnungStatus'|
  'reisekostenabrechnung.tagesgeldAcht'|
  'reisekostenabrechnung.tagesgeldVierundzwanzig'|
  'reisekostenabrechnung.anUndAbreisetag'|
  'reisekostenabrechnung.fruehstuecke'|
  'reisekostenabrechnung.mittagessen'|
  'reisekostenabrechnung.abendessen'|
  'reisekostenabrechnung.uebernachtungspauschale'|
  'reisekostenabrechnung.normaleKm'|
  'reisekostenabrechnung.mitnahmeentschaedigung'|
  'reisekostenabrechnung.sonstigePositionen'|
  'reisekostenabrechnung.sonstigeSumme'|
  'reisekostenabrechnung.summe'|
  'reisekostenabrechnung.antrag'
;
