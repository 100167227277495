import { ArrayReferenceFilterConfig, IArrayReferenceFilter, IFilter, IFilterConfig, ISelection, IShape } from 'pbc.types';

import { IBewertungsAnlass, IFeld, IKunde, IKundenArt, IObjektArt } from 'fa-kt.types';
import { IFormulareResponse } from '.';

export interface IFormulareQueryFilter extends IFilter<IFormulareResponse> {
  'formular.objektArten'?: IArrayReferenceFilter<IObjektArt>;
  'formular.bewertungsAnlaesse'?: IArrayReferenceFilter<IBewertungsAnlass>;
  'formular.kundenArten'?: IArrayReferenceFilter<IKundenArt>;
  'formular.kunden'?: IArrayReferenceFilter<IKunde>;
  'formular.ohneFelder'?: IArrayReferenceFilter<IFeld>;
}

export const FormulareQueryFilterConfig: IFilterConfig<IFormulareResponse> = [
  ArrayReferenceFilterConfig<IObjektArt>('formular.objektArten', 'FORMULARE.Models.FORMULAR.Propertys.OBJEKT_ARTEN.title'),
  ArrayReferenceFilterConfig<IBewertungsAnlass>('formular.bewertungsAnlaesse', 'FORMULARE.Models.FORMULAR.Propertys.BEWERTUNGS_ANLAESSE.title'),
  ArrayReferenceFilterConfig<IKundenArt>('formular.kundenArten', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN_ARTEN.title'),
  ArrayReferenceFilterConfig<IKunde>('formular.kunden', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN.title'),
  ArrayReferenceFilterConfig<IFeld>('formular.felder', 'Felder'),
];

export interface IFormulareQueryShapes extends IShape {
  'formular.objektArten': ISelection[];
  'formular.bewertungsAnlaesse': ISelection[];
  'formular.kundenArten': ISelection[];
  'formular.kunden': ISelection[];
  'formular.felder': ISelection[];
}

export type IFormulareQueryField =
  | 'formular.id'
  | 'formular.name'
  | 'formular.initialen'
  | 'formular.objektArten'
  | 'formular.bewertungsAnlaesse'
  | 'formular.kundenArten'
  | 'formular.kunden'
  | 'formular.ohneFelder'
  | 'formular.kommentar'
  | '';
