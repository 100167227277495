import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostErinnerungCommandShapes, IPostErinnerungRequest, IPostErinnerungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostErinnerungCommandService extends PostCommandService<IPostErinnerungRequest, IPostErinnerungResponse, IPostErinnerungCommandShapes> {
  readonly route = 'kommunikation/erinnerung';
  override dependencies = ['personen/mitarbeiter-aktualisiert', 'personen/azure-active-directory-synchronisiert'];

  getErinnerungMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerung.mitarbeiter']?.find((v) => v.value === value);
  }

  get erinnerungMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerung.mitarbeiter'] as ISelection[]) : [])));
  }

  getErinnerungVerfasser(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerung.verfasser']?.find((v) => v.value === value);
  }

  get erinnerungVerfasser(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerung.verfasser'] as ISelection[]) : [])));
  }
}
