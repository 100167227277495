import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostFotoKategorieCommandShapes, IPostFotoKategorieRequest, IPostFotoKategorieResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostFotoKategorieCommandService extends PostCommandService<IPostFotoKategorieRequest, IPostFotoKategorieResponse, IPostFotoKategorieCommandShapes> {
  readonly route = 'besichtigungen/settings/foto-kategorie';
}
