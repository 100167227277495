import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FeldUnterkategoriesServiceModule } from '..';
import { FeldUnterkategoriesListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldUnterkategoriesServiceModule
  ],
  declarations: [FeldUnterkategoriesListComponent],
  exports: [FeldUnterkategoriesListComponent],

})
export class FeldUnterkategoriesListComponentModule { }
