import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import format from 'date-fns/format';
import getQuarter from 'date-fns/getQuarter';
import { Workbook } from 'exceljs';
import { BehaviorSubject } from 'rxjs';

import { BaseComponent, ISitemapPage } from 'pbc.angular';
import { join, numberToColumn, toDate } from 'pbc.functions';

import { calculateProjektGutachtenVerteilung } from 'fa-kt.functions';
import {
  IAuswertungenProjekterfolgResponse,
  IAuswertungenRechnungsausgaengeResponse,
  IAuswertungenZeitenResponse,
  IGutachten,
  IKonstantesResponse,
  IMitarbeiter,
  IProjekteResponseRow,
} from 'fa-kt.types';
import { isDate, orderBy } from 'lodash';
import { KonstantesService } from '../../../index';
import { KundenService } from '../../../kunden/querys';
import { MitarbeiterService } from '../../../personen/querys';
import { ProjekteService } from '../../../projekte/querys';
import { ZeitenService } from '../../../zeiten/querys';

@Component({
  selector: 'fa-kt-auswertungen-page',
  templateUrl: './auswertungen.page.html',
  styleUrls: ['./auswertungen.page.css'],
})
export class AuswertungenPage extends BaseComponent implements OnInit, OnDestroy {
  public readonly page: ISitemapPage;

  public readonly $loading = new BehaviorSubject<string | undefined>(undefined);

  data: { title: string; click: Function }[] = [];

  constructor(
    public projekte: ProjekteService,
    public mitarbeiter: MitarbeiterService,
    public kunden: KundenService,
    public zeiten: ZeitenService,
    public konstanten: KonstantesService,
  ) {
    super();
    this.page = this.sitemap['AUSWERTUNGEN'].Pages['AUSWERTUNGEN'];
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.auth.$isFinanzen.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((isFinanzen) => {
      if (isFinanzen) {
        this.data = [
          {
            title: 'Aufträge',
            click: () => this.downloadAuftraege(),
          },
          {
            title: 'Gutachten',
            click: () => this.downloadGutachten(),
          },
          {
            title: 'Rechnungsausgänge',
            click: () => this.downloadRechnungsausgaenge(),
          },
          {
            title: 'Zeiten',
            click: () => this.downloadZeiterfassung(),
          },
        ];
      } else {
        this.data = [
          {
            title: 'Aufträge',
            click: () => this.downloadAuftraege(),
          },
        ];
      }
    });
  }

  back() {
    window.history.back();
  }

  async downloadAuftraege() {
    this.$loading.next('Daten werden vom Server geladen');

    const [blob, projekte, mitarbeiter, konstanten] = await Promise.all([
      this.files.get('fa-kt-apps/GF_Auftrag.xlsx'),
      this.projekte.request({ inklusiveInaktive: true, loadAll: true }),
      this.mitarbeiter.request({}),
      this.konstanten.request({}),
      this.kunden.request({}),
    ]).catch((error) => {
      this.$loading.next(undefined);
      this.message.error('Etwas ist schief gelaufen: ' + JSON.stringify(error));
      return [undefined, undefined];
    });
    if (!blob || !projekte) return;

    this.$loading.next('Daten werden berechnet und in die Excel geschrieben');

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Aufträge';
    const worksheet = workbook.getWorksheet(sheetName);
    if (!worksheet) {
      this.$loading.next(undefined);
      this.message.error('Etwas ist schief gelaufen. Bitte kontaktieren Sie den Administrator');
      return;
    }
    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + format(new Date(), 'dd.MM.yyyy mm:hh');
    let i = 6;

    let j = 62;
    const headerStyle = worksheet.getCell(numberToColumn(j) + 5).style;
    const me = this.auth.$id.getValue();
    const mitarbeiterList = (
      this.auth.$isFinanzen.getValue()
        ? orderBy(
            orderBy(
              mitarbeiter?.mitarbeiter.filter(
                (m) => !['FA|KT', 'Admin'].some((blocked) => (m.mitarbeiter.anzeigename === 'FA|KT Office Management' ? false : m.mitarbeiter.anzeigename.includes(blocked))),
              ),
              ({ mitarbeiter: { anzeigename } }) => anzeigename,
            ),
            ({ mitarbeiter: { inaktiv } }) => !!inaktiv,
          )
        : mitarbeiter?.mitarbeiter.filter((m) => m.mitarbeiter.id === me)
    )?.map((m) => {
      j++;
      return {
        ...m.mitarbeiter,
        column: numberToColumn(j),
      };
    });

    mitarbeiterList?.forEach((ma) => {
      worksheet.getCell(ma.column + 5).style = headerStyle;
      worksheet.getCell(ma.column + 5).value = `${ma.anzeigename}${ma.inaktiv ? ' (Inaktiv)' : ''}`;
    });

    j++;
    const unzugeordnetColumn = numberToColumn(j);
    worksheet.getCell(unzugeordnetColumn + 5).style = headerStyle;
    worksheet.getCell(unzugeordnetColumn + 5).value = 'Keine Zuordnung möglich';

    const pruefung = Number((konstanten as IKonstantesResponse)?.konstantes.find(({ art }) => art === 'anteilsberechnung_pruefung')?.inhalt || 0.1);
    const besichtigung = Number((konstanten as IKonstantesResponse)?.konstantes.find(({ art }) => art === 'anteilsberechnung_besichtigung')?.inhalt || 0.1);

    const numFmt = worksheet.getCell('BF6').numFmt;

    (this.auth.$isFinanzen.getValue()
      ? projekte.projekte
      : projekte.projekte.filter((row) => row.projekt.gutachten?.includes(me as string) || row.projekt.besichtigungen?.includes(me as string) || row.projekt.pruefung === me)
    )
      .reverse()
      .forEach((row) => {
        const besichtigungenMA =
          row.projekt?.besichtigungen && !row.projekt.keineBesichtigungErforderlich
            ? join(
                row.projekt.besichtigungen.map(
                  (besichtigung) =>
                    this.meta.users$
                      .getValue()
                      .find(({ value }) => value === besichtigung)
                      ?.label?.split(' (')
                      .shift() as string,
                ),
              )
            : '';

        const gutachtenMA = row.projekt?.gutachten
          ? join(
              row.projekt.gutachten.map(
                (besichtigung) =>
                  this.meta.users$
                    .getValue()
                    .find(({ value }) => value === besichtigung)
                    ?.label?.split(' (')
                    .shift() as string,
              ),
            )
          : '';
        const pruefungsMA = row.projekt?.pruefung
          ? (this.projekte
              .getProjektPruefung(row.projekt?.pruefung)
              ?.label?.split(' (')
              .shift() as string)
          : '';
        const partnerMA = row.projekt?.partnerInnen
          ? join(
              row.projekt?.partnerInnen.map(
                (partnerIn) =>
                  this.meta.users$
                    .getValue()
                    .find(({ value }) => value === partnerIn)
                    ?.label?.split(' (')
                    .shift() as string,
              ),
            )
          : '';

        worksheet.getCell('B' + i).value = row.projekt?.projektZaehler;
        worksheet.getCell('C' + i).value = toDate(new Date(row.projekt?._createdAt as Date));
        worksheet.getCell('D' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.projekt?._createdBy as string))?.label || row.projekt?._createdBy;
        worksheet.getCell('E' + i).value = toDate(new Date(row.projekt?._updatedAt as Date));
        worksheet.getCell('F' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.projekt?._updatedBy as string))?.label || row.projekt?._updatedBy;
        worksheet.getCell('G' + i).value = '';
        worksheet.getCell('H' + i).value = row.projekt?.nummer + ' | ' + row.projekt?.bezeichnung;
        worksheet.getCell('I' + i).value = new Date(row.projekt?._createdAt as Date)?.getFullYear();

        worksheet.getCell('J' + i).value = row.kunde?.initialen;

        worksheet.getCell('K' + i).value = row.projekt?.nummer;
        worksheet.getCell('L' + i).value = row.projekt?.bezeichnung;
        worksheet.getCell('M' + i).value = this.projekte
          .getProjektProjektArt(row.projekt?.projektArt!)
          ?.label?.split(' | ')
          .shift();
        worksheet.getCell('N' + i).value = this.projekte
          .getProjektProjektArt(row.projekt?.projektArt!)
          ?.label?.split(' | ')
          .pop();
        worksheet.getCell('O' + i).value = this.projekte.getProjektProjektStatus(row.projekt?.projektStatus as string)?.label;

        worksheet.getCell('P' + i).value = row.projekt?.deadline ? toDate(new Date(row.projekt?.deadline as Date)) : null;
        worksheet.getCell('Q' + i).value = row.projekt?.deadline ? new Date(row.projekt?.deadline as Date).getFullYear() : null;
        worksheet.getCell('R' + i).value = row.projekt?.deadline ? getQuarter(new Date(row.projekt?.deadline as Date)) : null;
        worksheet.getCell('S' + i).value = row.projekt?.deadline ? new Date(row.projekt?.deadline as Date).getMonth() + 1 : null;

        const rechnung = row.rechnungen?.[0];
        worksheet.getCell('T' + i).value = rechnung ? toDate(new Date(rechnung?.datum as Date)) : null;
        worksheet.getCell('U' + i).value = rechnung ? new Date(rechnung?.datum as Date).getFullYear() : null;
        worksheet.getCell('V' + i).value = rechnung ? getQuarter(new Date(rechnung?.datum as Date)) : null;
        worksheet.getCell('W' + i).value = rechnung ? new Date(rechnung?.datum as Date).getMonth() + 1 : null;

        worksheet.getCell('X' + i).value = this.projekte.getGutachtenBewertungsAnlass(row.projekt.bewertungsAnlass as string)?.label;

        worksheet.getCell('Y' + i).value = this.projekte.getProjektStandort(row.projekt.standort as string)?.label;

        worksheet.getCell('Z' + i).value = pruefungsMA;
        worksheet.getCell('AA' + i).value = gutachtenMA;
        worksheet.getCell('AB' + i).value = besichtigungenMA;
        worksheet.getCell('AC' + i).value = partnerMA;

        worksheet.getCell('AD' + i).value = row.projekt?.beauftragung ? toDate(new Date(row.projekt?.beauftragung as Date)) : null;
        worksheet.getCell('AE' + i).value = row.projekt?.deadline ? toDate(new Date(row.projekt?.deadline as Date)) : null;

        worksheet.getCell('AF' + i).value = this.kunden.getKundeKundenArt(row.kunde?.kundenArt as string)?.label;
        worksheet.getCell('AG' + i).value = row.kunde?.name;
        worksheet.getCell('AH' + i).value = row.kunde?.addresse?.plz;
        worksheet.getCell('AI' + i).value = row.kunde?.addresse?.gemeinde_stadt;
        worksheet.getCell('AM' + i).value = row.kunde?.addresse?.strasse;

        worksheet.getCell('AN' + i).value = row.kunde?.id;
        worksheet.getCell('AO' + i).value = this.projekte.getProjektBank(row.projekt?.bank as string)?.label;
        worksheet.getCell('AP' + i).value = row.gutachten[0]?.besichtigung_kontakt;
        worksheet.getCell('AQ' + i).value = row.gutachten[0]?.besichtigung_festnetz;
        worksheet.getCell('AR' + i).value = row.gutachten[0]?.besichtigung_mobil;
        worksheet.getCell('AS' + i).value = row.gutachten[0]?.besichtigung_email;

        // worksheet.getCell('AT' + i).value = null; // TODO row.abteilungen?.[0]?.name;
        worksheet.getCell('AU' + i).value = rechnung?.ansprechpartner;

        worksheet.getCell('AV' + i).value = row.projekt?.kundenAnfertigung ? 'Ja' : 'Nein';

        worksheet.getCell('AW' + i).value = row.vermerke?.length;

        worksheet.getCell('AX' + i).value = row.gutachten.length;
        worksheet.getCell('AY' + i).value = this.projekte.getGutachtenBewertungsStatus(row.gutachten[0]?.bewertungsStatus)?.label;

        worksheet.getCell('AZ' + i).value = row.besichtigungen?.length
          ? toDate(new Date(row.besichtigungen[0]?.von as Date))
          : row.gutachten?.[0]?.datum_besichtigung
          ? toDate(new Date(row.gutachten?.[0]?.datum_besichtigung))
          : null;

        worksheet.getCell('BA' + i).value = (row.projekt?.fortschritt || 0) / 100;
        // worksheet.getCell('BB' + i).value = null; // TODO row.dokumente;

        const stunden = row.zeiten?.reduce((n, { stunden }) => n + (!stunden || isNaN(stunden) ? 0 : stunden), 0);
        const minuten = row.zeiten?.reduce((n, { minuten }) => n + (!minuten || isNaN(minuten) ? 0 : minuten), 0);
        worksheet.getCell('BC' + i).value = (stunden || 0) + (minuten || 0) / 60;
        worksheet.getCell('BD' + i).value = row.zeiten?.reduce((n, { kosten }) => n + (!kosten || isNaN(kosten) ? 0 : kosten), 0);

        row.projekt.kostenNetto = row.rechnungen?.reduce((n, { nettoSumme }) => n + (isNaN(nettoSumme) ? 0 : nettoSumme), 0) || 0;
        row.projekt.kosten = row.rechnungen?.reduce((n, { finaleSumme }) => n + (isNaN(finaleSumme) ? 0 : finaleSumme), 0) || 0;

        worksheet.getCell('BE' + i).value = row.projekt?.verhandeltesHonorar;
        worksheet.getCell('BF' + i).value = row.projekt?.kostenNetto || 0;
        worksheet.getCell('BG' + i).value = Number(row.projekt?.verhandeltesHonorar || 0) - Number(row.projekt?.kostenNetto || 0);
        worksheet.getCell('BH' + i).value = row.projekt?.kosten || 0;
        worksheet.getCell('BI' + i).value = row.projekt.kostenBeglichen;
        worksheet.getCell('BJ' + i).value = Number(row.projekt?.kosten || 0) - Number(row.projekt.kostenBeglichen || 0);

        worksheet.getCell('BK' + i).value = row.projekt?.kommentar?.split('\n').join(' ');

        const verteilung = calculateProjektGutachtenVerteilung(row, mitarbeiterList as IMitarbeiter[], { pruefung, besichtigung, preview: false });
        let unzugeordnet = row.projekt?.kostenNetto || 0;
        mitarbeiterList?.forEach((ma) => {
          const anteil = (verteilung[ma.id].ausBesichtigung || 0) + (verteilung[ma.id].ausGutachten || 0) + (verteilung[ma.id].ausPrüfung || 0) || 0;
          worksheet.getCell(ma.column + i).value = anteil;
          worksheet.getCell(ma.column + i).numFmt = numFmt;
          unzugeordnet -= anteil;
        });
        worksheet.getCell(unzugeordnetColumn + i).value = unzugeordnet < -0.1 || unzugeordnet > 0.1 ? unzugeordnet : 0;
        worksheet.getCell(unzugeordnetColumn + i).numFmt = numFmt;

        i++;
      });
    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      new Date().toLocaleDateString() + '_Export der Aufträge.xlsx',
    );
    this.$loading.next(undefined);
  }

  async downloadGutachten() {
    this.$loading.next('Bericht wird erstellt');
    const [blob, projekte] = await Promise.all([this.files.get('fa-kt-apps/GF_Gutachten.xlsx'), this.projekte.request({ inklusiveInaktive: true, loadAll: true }), this.kunden.request({})]).catch(
      (error) => {
        this.$loading.next(undefined);
        this.message.error('Etwas ist schief gelaufen: ' + JSON.stringify(error));
        return [undefined, undefined];
      },
    );
    if (!blob || !projekte) return;

    this.$loading.next('Daten werden in die Excel geschrieben');

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Gutachten';
    const worksheet = workbook.getWorksheet(sheetName);
    if (!worksheet) {
      this.$loading.next(undefined);
      this.message.error('Etwas ist schief gelaufen. Bitte kontaktieren Sie den Administrator');
      return;
    }

    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + format(new Date(), 'dd.MM.yyyy mm:hh');
    let i = 6;

    const gutachtenList: Array<
      IProjekteResponseRow & {
        entry: IGutachten;
      }
    > = [];
    projekte.projekte.forEach((row) =>
      row.gutachten.forEach((entry) => {
        gutachtenList.push({
          ...row,
          entry,
        });
      }),
    );

    gutachtenList.forEach((row) => {
      const besichtigungen =
        row.projekt?.besichtigungen && !row.projekt.keineBesichtigungErforderlich
          ? join(
              row.projekt.besichtigungen.map(
                (besichtigung) =>
                  this.meta.users$
                    .getValue()
                    .find(({ value }) => value === besichtigung)
                    ?.label?.split(' (')
                    .shift() as string,
              ),
            )
          : '';
      const gutachten = row.projekt?.gutachten
        ? join(
            row.projekt.gutachten.map(
              (gutachten) =>
                this.meta.users$
                  .getValue()
                  .find(({ value }) => value === gutachten)
                  ?.label?.split(' (')
                  .shift() as string,
            ),
          )
        : '';
      const pruefung = row.projekt?.pruefung
        ? (this.meta.users$
            .getValue()
            .find(({ value }) => value === row.projekt?.pruefung)
            ?.label?.split(' (')
            .shift() as string)
        : '';

      worksheet.getCell('B' + i).value = row.entry.id;
      worksheet.getCell('C' + i).value = toDate(new Date(row.entry._createdAt as Date));
      worksheet.getCell('D' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.entry?._createdBy as string))?.label || row.entry._createdBy;
      worksheet.getCell('E' + i).value = toDate(new Date(row.entry._updatedAt as Date));
      worksheet.getCell('F' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.projekt?._updatedBy as string))?.label || row.entry._updatedBy;

      const objekt = row.objekte?.find((o) => o.id === row.entry.objekt);

      worksheet.getCell('G' + i).value = objekt?.addresse.plz + ' ' + objekt?.addresse.gemeinde_stadt + ', ' + objekt?.addresse.strasse + ' (' + objekt?.addresse.bundesland + ')';
      worksheet.getCell('H' + i).value = objekt?.addresse.bundesland;
      worksheet.getCell('I' + i).value = objekt?.addresse.plz;
      worksheet.getCell('J' + i).value = objekt?.addresse.gemeinde_stadt;
      worksheet.getCell('K' + i).value = objekt?.addresse.ort_stadt_teil;
      worksheet.getCell('L' + i).value = objekt?.addresse.strasse;
      worksheet.getCell('M' + i).value = objekt?.addresse.extra;
      worksheet.getCell('N' + i).value = objekt?.addresse.latitude && objekt?.addresse.longitude ? 'vorhanden' : 'nicht vorhanden';
      worksheet.getCell('O' + i).value = objekt?.addresse.latitude;
      worksheet.getCell('P' + i).value = objekt?.addresse.longitude;
      worksheet.getCell('Q' + i).value = objekt?.addresse.land;

      worksheet.getCell('R' + i).value = row.projekt.nummer;
      worksheet.getCell('S' + i).value = row.projekt.projektZaehler;
      worksheet.getCell('T' + i).value = new Date(row.projekt._createdAt as Date).getFullYear();
      worksheet.getCell('U' + i).value = row.projekt.nummer + ' | ' + row.projekt.bezeichnung;

      worksheet.getCell('V' + i).value = this.projekte.getProjektProjektArt(row.projekt?.projektArt as string)?.label;
      worksheet.getCell('W' + i).value = this.projekte.getProjektProjektStatus(row.projekt?.projektStatus as string)?.label;
      worksheet.getCell('X' + i).value = this.projekte.getGutachtenBewertungsAnlass(row.projekt.bewertungsAnlass as string)?.label;

      worksheet.getCell('Y' + i).value = pruefung;
      worksheet.getCell('Z' + i).value = gutachten;
      worksheet.getCell('AA' + i).value = besichtigungen;

      worksheet.getCell('AB' + i).value = row.kunde?.name;
      worksheet.getCell('AC' + i).value = row.kunde?.id;
      worksheet.getCell('AD' + i).value = this.projekte.getProjektBank(row.projekt?.bank as string)?.label;
      worksheet.getCell('AE' + i).value = this.kunden.getKundeKundenArt(row.kunde?.kundenArt as string)?.label;

      worksheet.getCell('AF' + i).value = this.projekte.getGutachtenBewertungsStatus(row.entry.bewertungsStatus)?.label;

      worksheet.getCell('AG' + i).value = row.besichtigungen?.length
        ? toDate(new Date(row.besichtigungen[0].von as Date))
        : row.entry.datum_besichtigung
        ? toDate(new Date(row.entry.datum_besichtigung))
        : null;
      worksheet.getCell('AH' + i).value = row.entry.besichtigung_kontakt;
      worksheet.getCell('AI' + i).value = row.entry.besichtigung_email;
      worksheet.getCell('AJ' + i).value = row.entry.besichtigung_festnetz;
      worksheet.getCell('AK' + i).value = row.entry.besichtigung_mobil;
      worksheet.getCell('AL' + i).value = row.besichtigungen?.length ? row.besichtigungen[0].kommentar?.split('\n').join(' ') : null;

      worksheet.getCell('AM' + i).value = isDate(row.entry.abgabeDraft) ? toDate(new Date(row.entry.abgabeDraft as Date)) : null;
      // worksheet.getCell('AN' + i).value = isDate(row.gutachten.abgabeFinal) ? toDate(new Date(row.gutachten.abgabeFinal as Date)) : null;

      worksheet.getCell('AO' + i).value = this.projekte.getGutachtenObjektNutzung(row.entry.objektNutzung as string)?.label;
      worksheet.getCell('AP' + i).value = this.projekte.getGutachtenObjektArt(row.entry.objektArt as string)?.label;

      worksheet.getCell('AQ' + i).value = row.entry.baujahr ? row.entry.baujahr : null;
      worksheet.getCell('AR' + i).value = row.entry.modernisierung ? row.entry.modernisierung : null;
      worksheet.getCell('AS' + i).value = row.entry.erbbaurecht ? 'Ja' : 'Nein';
      worksheet.getCell('AT' + i).value = row.entry.stichtagMarktwert ? toDate(new Date(row.entry.stichtagMarktwert as Date)) : null;
      worksheet.getCell('AU' + i).value = row.entry.marktwert ? row.entry.marktwert : null;
      worksheet.getCell('AV' + i).value =
        (row.entry.wohnenJRoEFlaeche ? row.entry.wohnenJRoEFlaeche : 0) +
        (row.entry.gewerbeJRoEFlaeche ? row.entry.gewerbeJRoEFlaeche : 0) +
        (row.entry.bueroJRoEFlaeche ? row.entry.bueroJRoEFlaeche : 0) +
        (row.entry.handelJRoEFlaeche ? row.entry.handelJRoEFlaeche : 0) +
        (row.entry.lagerJRoEFlaeche ? row.entry.lagerJRoEFlaeche : 0) +
        (row.entry.sonstigesJRoEFlaeche ? row.entry.sonstigesJRoEFlaeche : 0);
      worksheet.getCell('AW' + i).value = row.entry.wohnenJRoEFlaeche ? row.entry.wohnenJRoEFlaeche : null;
      worksheet.getCell('AX' + i).value = row.entry.gewerbeJRoEFlaeche ? row.entry.gewerbeJRoEFlaeche : null;
      worksheet.getCell('AY' + i).value = row.entry.sonstigesJRoEFlaeche ? row.entry.sonstigesJRoEFlaeche : null;
      worksheet.getCell('AZ' + i).value =
        (row.entry.wohnenJRoE ? row.entry.wohnenJRoE : 0) +
        (row.entry.gewerbeJRoE ? row.entry.gewerbeJRoE : 0) +
        (row.entry.bueroJRoE ? row.entry.bueroJRoE : 0) +
        (row.entry.handelJRoE ? row.entry.handelJRoE : 0) +
        (row.entry.lagerJRoE ? row.entry.lagerJRoE : 0) +
        (row.entry.sonstigesJRoE ? row.entry.sonstigesJRoE : 0);
      worksheet.getCell('BA' + i).value = row.entry.wohnenJRoE ? row.entry.wohnenJRoE : null;
      worksheet.getCell('BB' + i).value = row.entry.gewerbeJRoE ? row.entry.gewerbeJRoE : null;
      worksheet.getCell('BC' + i).value = row.entry.sonstigesJRoE ? row.entry.sonstigesJRoE : null;

      i++;
    });

    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      new Date().toLocaleDateString() + '_Export der Gutachten.xlsx',
    );
    this.$loading.next(undefined);
  }

  async downloadRechnungsausgaenge() {
    this.$loading.next('Daten werden geladen');

    const [blob, data] = await Promise.all([
      this.files.get('fa-kt-apps/GF_Rechnungsausgang.xlsx'),
      this.http.get<IAuswertungenRechnungsausgaengeResponse>('auswertungen/rechnungsausgaenge'),
      this.projekte.request({}),
    ]).catch((error) => {
      this.message.error('Etwas ist schief gelaufen: ' + JSON.stringify(error));
      return [];
    });
    if (!blob) {
      this.$loading.next(undefined);
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Rechnungsausgänge';
    const worksheet = workbook.getWorksheet(sheetName);

    if (!worksheet) {
      this.$loading.next(undefined);
      this.message.error('Etwas ist schief gelaufen. Bitte kontaktieren Sie den Administrator');
      return;
    }

    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + format(new Date(), 'dd.MM.yyyy mm:hh');
    let i = 6;

    (data as IAuswertungenRechnungsausgaengeResponse).rows.reverse().forEach((row) => {
      worksheet.getCell('B' + i).value = row.rechnung?.id;
      worksheet.getCell('C' + i).value = toDate(new Date(row.rechnung?._createdAt as Date));
      worksheet.getCell('D' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.projekt?._createdBy as string))?.label || row.projekt?._createdBy;
      worksheet.getCell('E' + i).value = toDate(new Date(row.rechnung?._updatedAt as Date));
      worksheet.getCell('F' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.projekt?._updatedBy as string))?.label || row.projekt?._updatedBy;

      worksheet.getCell('G' + i).value = row.rechnung?.bezeichnung;
      worksheet.getCell('H' + i).value = new Date(row.rechnung?._createdAt as Date).getFullYear();
      worksheet.getCell('I' + i).value = row.projekt?.projektZaehler;

      worksheet.getCell('J' + i).value = row.projekt?.nummer + ' | ' + row.projekt?.bezeichnung;
      worksheet.getCell('K' + i).value = row.rechnung?.rechnungZaehler;
      worksheet.getCell('L' + i).value = new Date(row.rechnung?._createdAt as Date);
      worksheet.getCell('M' + i).value = row.kunde?.name;
      worksheet.getCell('N' + i).value = row.rechnung.plz;
      worksheet.getCell('O' + i).value = row.rechnung.ort;
      worksheet.getCell('P' + i).value = row.rechnung.addresse;

      worksheet.getCell('Q' + i).value = row.abteilung?.name;
      worksheet.getCell('R' + i).value = row.abteilung?.ansprache;

      worksheet.getCell('S' + i).value = row.kunde?.initialen;

      worksheet.getCell('T' + i).value = row.rechnung.bruttoSumme;
      worksheet.getCell('U' + i).value = row.rechnung.honorarVereinbarungSumme;
      worksheet.getCell('V' + i).value = row.rechnung.fahrtenSumme;
      worksheet.getCell('W' + i).value = row.rechnung.auslagenSumme;
      worksheet.getCell('X' + i).value = row.rechnung.sonstigeKosten;
      worksheet.getCell('Y' + i).value = row.rechnung.nettoSumme;
      worksheet.getCell('Z' + i).value = row.rechnung.mwstSatz / 100;
      worksheet.getCell('AA' + i).value = row.rechnung.mwstSumme;
      worksheet.getCell('AB' + i).value = row.rechnung.finaleSumme;
      worksheet.getCell('AC' + i).value = row.rechnung.auslagenOhneMwstSumme;
      worksheet.getCell('AD' + i).value = (Number(row.rechnung.finaleSumme) || 0) + (Number(row.rechnung.auslagenOhneMwstSumme) || 0);

      const summe = row.zahlungseingaenge.reduce((n, { summe }) => n + (isNaN(summe) ? 0 : summe), 0);
      worksheet.getCell('AI' + i).value = summe;
      const zahlungseingang = row.zahlungseingaenge[0];
      worksheet.getCell('AJ' + i).value = zahlungseingang?.datum ? toDate(new Date(zahlungseingang?.datum as Date)) : null;

      worksheet.getCell('AK' + i).value = (Number(row.rechnung.finaleSumme) || 0) + (Number(row.rechnung.auslagenOhneMwstSumme) || 0) - (summe || 0);

      i++;
    });

    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      'Export der Rechnungsausgänge.xlsx',
    );
    this.$loading.next(undefined);
  }

  async downloadZeiterfassung() {
    this.$loading.next('Bericht wird erstellt');

    const [blob, data, projekte] = await Promise.all([
      this.files.get('fa-kt-apps/GF_Zeiterfassung.xlsx'),
      this.http.get<IAuswertungenZeitenResponse>('auswertungen/zeiten'),
      this.projekte.request({ inklusiveInaktive: true }),
      this.zeiten.request({ jahr: new Date().getFullYear() }),
    ]).catch((error) => {
      this.message.error('Etwas ist schief gelaufen: ' + JSON.stringify(error));
      return [];
    });
    if (!blob || !data || !projekte) {
      this.$loading.next(undefined);
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Zeiterfassung';
    const worksheet = workbook.getWorksheet(sheetName);
    if (!worksheet) {
      this.$loading.next(undefined);
      this.message.error('Etwas ist schief gelaufen. Bitte kontaktieren Sie den Administrator');
      return;
    }

    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + format(new Date(), 'dd.MM.yyyy mm:hh');
    let i = 6;

    data.rows.reverse().forEach((row) => {
      worksheet.getCell('B' + i).value = row.zeit?.id;
      worksheet.getCell('C' + i).value = toDate(new Date(row.zeit?._createdAt as Date));
      worksheet.getCell('D' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.zeit?._createdBy as string))?.label || row.zeit?._createdBy;
      worksheet.getCell('E' + i).value = toDate(new Date(row.zeit?._updatedAt as Date));
      worksheet.getCell('F' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.zeit?._updatedBy as string))?.label || row.zeit?._updatedBy;

      worksheet.getCell('G' + i).value = this.meta.users$.getValue().find(({ value }) => value === (row.zeit?.mitarbeiter as string))?.label;
      worksheet.getCell('H' + i).value = new Date(row.zeit.datum as Date);
      worksheet.getCell('I' + i).value = this.zeiten.getZeitZeitArt(row.zeit.zeitArt as string)?.label;
      worksheet.getCell('J' + i).value = this.zeiten.getZeitZeitUnterart(row.zeit.zeitUnterart as string)?.label;
      worksheet.getCell('K' + i).value = this.zeiten.getZeitKunde(row.zeit.kunde as string)?.label;
      const projekt = projekte.projekte?.find((p) => p.projekt.id === row.zeit.projekt)?.projekt;
      worksheet.getCell('L' + i).value = projekt ? projekt.nummer + ' | ' + projekt.bezeichnung : null;
      worksheet.getCell('M' + i).value = (!row.zeit.stunden || isNaN(row.zeit.stunden) ? 0 : row.zeit.stunden) + (!row.zeit.minuten || isNaN(row.zeit.minuten) ? 0 : row.zeit.minuten) / 60;
      worksheet.getCell('N' + i).value = row.zeit.kosten;
      worksheet.getCell('O' + i).value = row.zeit.kommentar?.split('\n').join(' ');

      i++;
    });

    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      'Export der Zeiterfassung.xlsx',
    );
    this.$loading.next(undefined);
  }

  async downloadProjekterfolg() {
    this.$loading.next('Bericht wird erstellt');

    const [blob, data] = await Promise.all([this.files.get('fa-kt-apps/GF_Projekterfolg.xlsx'), this.http.get('auswertungen/projekterfolg')]);
    if (!blob) {
      this.$loading.next(undefined);
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Projekterfolg';
    const worksheet = workbook.getWorksheet(sheetName);
    if (!worksheet) {
      this.$loading.next(undefined);
      this.message.error('Etwas ist schief gelaufen. Bitte kontaktieren Sie den Administrator');
      return;
    }

    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + format(new Date(), 'dd.MM.yyyy mm:hh');
    let i = 6;

    (data as IAuswertungenProjekterfolgResponse).rows.forEach((row) => {
      const besichtigungen =
        row.projekt?.besichtigungen && !row.projekt.keineBesichtigungErforderlich
          ? join(
              row.projekt.besichtigungen.map(
                (besichtigung) =>
                  this.meta.users$
                    .getValue()
                    .find(({ value }) => value === besichtigung)
                    ?.label?.split(' (')
                    .shift() as string,
              ),
            )
          : '';
      const gutachten = row.projekt?.gutachten
        ? join(
            row.projekt.gutachten.map(
              (gutachten) =>
                this.meta.users$
                  .getValue()
                  .find(({ value }) => value === gutachten)
                  ?.label?.split(' (')
                  .shift() as string,
            ),
          )
        : '';
      const pruefung = row.projekt?.pruefung
        ? (this.meta.users$
            .getValue()
            .find(({ value }) => value === row.projekt?.pruefung)
            ?.label?.split(' (')
            .shift() as string)
        : '';

      row.zeiten.forEach((zeit) => {
        worksheet.getCell('B' + i).value = row.projekt?.nummer;
        worksheet.getCell('C' + i).value = row.projekt?.bezeichnung;

        worksheet.getCell('D' + i).value = row.objekt?.addresse?.land;

        worksheet.getCell('E' + i).value = this.projekte.getProjektProjektArt(row.projekt.projektArt as string)?.label;
        worksheet.getCell('F' + i).value = this.projekte.getProjektProjektStatus(row.projekt.projektStatus as string)?.label;

        worksheet.getCell('G' + i).value = new Date(row.projekt?.beauftragung as Date);

        worksheet.getCell('H' + i).value = row.kunde?.initialen;
        worksheet.getCell('I' + i).value = row.kunde?.name;

        worksheet.getCell('J' + i).value = pruefung;
        worksheet.getCell('K' + i).value = gutachten;

        worksheet.getCell('L' + i).value = new Date(row.projekt?.beauftragung as Date).getFullYear();
        worksheet.getCell('M' + i).value = getQuarter(new Date(row.projekt?.beauftragung as Date));
        worksheet.getCell('N' + i).value = new Date(row.projekt?.beauftragung as Date).getMonth() + 1;

        worksheet.getCell('O' + i).value = Number(zeit.kosten) * -1;
        worksheet.getCell('P' + i).value = 'Stundenaufwand';
        worksheet.getCell('Q' + i).value = this.projekte.getProjektPruefung(zeit.mitarbeiter as string)?.label;

        i++;
      });
      row.rechnungen.forEach((rechnung) => {
        worksheet.getCell('B' + i).value = row.projekt?.nummer;
        worksheet.getCell('C' + i).value = row.projekt?.bezeichnung;

        worksheet.getCell('D' + i).value = row.objekt?.addresse?.land;

        worksheet.getCell('E' + i).value = this.projekte.getProjektProjektArt(row.projekt.projektArt as string)?.label;
        worksheet.getCell('F' + i).value = this.projekte.getProjektProjektStatus(row.projekt.projektStatus as string)?.label;

        worksheet.getCell('G' + i).value = new Date(row.projekt?.beauftragung as Date);

        worksheet.getCell('H' + i).value = row.kunde?.initialen;
        worksheet.getCell('I' + i).value = row.kunde?.name;

        worksheet.getCell('J' + i).value = pruefung;
        worksheet.getCell('K' + i).value = gutachten;

        worksheet.getCell('L' + i).value = new Date(row.projekt?.beauftragung as Date).getFullYear();
        worksheet.getCell('M' + i).value = getQuarter(new Date(row.projekt?.beauftragung as Date));
        worksheet.getCell('N' + i).value = new Date(row.projekt?.beauftragung as Date).getMonth() + 1;

        worksheet.getCell('O' + i).value = rechnung.finaleSumme;
        worksheet.getCell('P' + i).value = 'Rechnungsausgang';
        worksheet.getCell('Q' + i).value = '-';

        i++;
      });
    });

    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      'Export des Projekterfolges.xlsx',
    );
    this.$loading.next(undefined);
  }
}
