import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FeldUnterkategoriesServiceModule } from '..';
import { FeldUnterkategoriesSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldUnterkategoriesServiceModule
  ],
  declarations: [FeldUnterkategoriesSorterComponent],
  exports: [FeldUnterkategoriesSorterComponent],

})
export class FeldUnterkategoriesSorterComponentModule { }
