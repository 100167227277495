import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  DateFilterConfig,
  GeoRangeFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IDateFilter,
  IFilter,
  IFilterConfig,
  IGeoRangeFilter,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IKundenArt, IKundenGruppe, IMitarbeiter } from 'fa-kt.types';
import { IKundeResponse } from '.';

export interface IKundeQueryFilter extends IFilter<IKundeResponse> {
  'kunde.kundenGruppe'?: ISingleReferenceFilter<IKundenGruppe>;
  'kunde.kundenArt'?: ISingleReferenceFilter<IKundenArt>;
  'kunde.addresse'?: IGeoRangeFilter;
  'kunde.fahrtkostenAbRadius'?: INumberFilter;
  'kunde.fahrtkostenProStunde'?: INumberFilter;
  'kunde.skonto'?: INumberFilter;
  'kunde.zahlungsziel'?: INumberFilter;
  'kunde.tageOhneSkonto'?: INumberFilter;
  'honorarVereinbarungen.lohnGewerbe'?: INumberFilter;
  'honorarVereinbarungen.lohnSpezial'?: INumberFilter;
  'extraVereinbarungen.kosten'?: INumberFilter;
  'vermerke.verfasser'?: ISingleReferenceFilter<IMitarbeiter>;
  'vermerke.externSichtbar'?: IBooleanFilter;
  'erinnerungen.am'?: IDateFilter;
  'erinnerungen.mitarbeiter'?: IArrayReferenceFilter<IMitarbeiter>;
  'erinnerungen.verfasser'?: ISingleReferenceFilter<IMitarbeiter>;
}

export const KundeQueryFilterConfig: IFilterConfig<IKundeResponse> = [
  SingleReferenceFilterConfig<IKundenGruppe>('kunde.kundenGruppe', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_GRUPPE.title'),
  SingleReferenceFilterConfig<IKundenArt>('kunde.kundenArt', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.title'),
  GeoRangeFilterConfig('kunde.addresse', 'KUNDEN.Models.KUNDE.Propertys.ADDRESSE.title'),
  NumberFilterConfig('kunde.fahrtkostenAbRadius', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.title'),
  NumberFilterConfig('kunde.fahrtkostenProStunde', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.title'),
  NumberFilterConfig('kunde.skonto', 'KUNDEN.Models.KUNDE.Propertys.SKONTO.title'),
  NumberFilterConfig('kunde.zahlungsziel', 'KUNDEN.Models.KUNDE.Propertys.ZAHLUNGSZIEL.title'),
  NumberFilterConfig('kunde.tageOhneSkonto', 'KUNDEN.Models.KUNDE.Propertys.TAGE_OHNE_SKONTO.title'),
  NumberFilterConfig('honorarVereinbarungen.lohnGewerbe', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.LOHN_GEWERBE.title'),
  NumberFilterConfig('honorarVereinbarungen.lohnSpezial', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.LOHN_SPEZIAL.title'),
  NumberFilterConfig('extraVereinbarungen.kosten', 'FINANZEN.Models.EXTRA_VEREINBARUNG.Propertys.KOSTEN.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('vermerke.verfasser', 'KOMMUNIKATION.Models.VERMERK.Propertys.VERFASSER.title'),
  BooleanFilterConfig('vermerke.externSichtbar', 'KOMMUNIKATION.Models.VERMERK.Propertys.EXTERN_SICHTBAR.title'),
  DateFilterConfig('erinnerungen.am', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.AM.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('erinnerungen.mitarbeiter', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.MITARBEITER.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('erinnerungen.verfasser', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.VERFASSER.title'),
];

export interface IKundeQueryShapes extends IShape {
  'kunde.kundenGruppe': ISelection[];
  'kunde.kundenArt': ISelection[];
  'kunde.fahrtkostenAbrechnung': ISelection[];
  'extraVereinbarungen.pro': ISelection[];
  'vermerke.verfasser': ISelection[];
  'erinnerungen.mitarbeiter': ISelection[];
  'erinnerungen.verfasser': ISelection[];
}

export type IKundeQueryField =
  | 'kunde.id'
  | 'kunde.name'
  | 'kunde.initialen'
  | 'kunde.kundenGruppe'
  | 'kunde.kundenArt'
  | 'kunde.addresse'
  | 'kunde.website'
  | 'kunde.outlookKontakte'
  | 'kunde.kontaktNotizen'
  | 'kunde.anforderungenNotizen'
  | 'kunde.rechnungNotizen'
  | 'kunde.fahrtkostenAbrechnung'
  | 'kunde.steuerId'
  | 'kunde.fahrtkostenAbRadius'
  | 'kunde.fahrtkostenProKm'
  | 'kunde.fahrtkostenProStunde'
  | 'kunde.skonto'
  | 'kunde.zahlungsziel'
  | 'kunde.tageOhneSkonto'
  | 'kunde.aktiv'
  | 'kunde.dateipfad'
  | 'kunde.loraName'
  | 'kunde.unterlagenEmail'
  | 'abteilungen.id'
  | 'abteilungen.kunde'
  | 'abteilungen.name'
  | 'abteilungen.ansprache'
  | 'abteilungen.outlookKontakte'
  | 'abteilungen.website'
  | 'abteilungen.adresse'
  | 'abteilungen.aktiv'
  | 'fonds.id'
  | 'fonds.kunde'
  | 'fonds.name'
  | 'fonds.aktiv'
  | 'honorarVereinbarungen.id'
  | 'honorarVereinbarungen.kunde'
  | 'honorarVereinbarungen.bisMarktwert'
  | 'honorarVereinbarungen.lohnWohnen'
  | 'honorarVereinbarungen.lohnGewerbe'
  | 'honorarVereinbarungen.lohnSpezial'
  | 'extraVereinbarungen.id'
  | 'extraVereinbarungen.kunde'
  | 'extraVereinbarungen.name'
  | 'extraVereinbarungen.beschreibung'
  | 'extraVereinbarungen.pro'
  | 'extraVereinbarungen.kosten'
  | 'vermerke.id'
  | 'vermerke.projekt'
  | 'vermerke.gutachten'
  | 'vermerke.kunde'
  | 'vermerke.antwortAuf'
  | 'vermerke.verfasser'
  | 'vermerke.kommentar'
  | 'vermerke.externSichtbar'
  | 'erinnerungen.id'
  | 'erinnerungen.projekt'
  | 'erinnerungen.gutachten'
  | 'erinnerungen.kunde'
  | 'erinnerungen.kommentar'
  | 'erinnerungen.am'
  | 'erinnerungen.mitarbeiter'
  | 'erinnerungen.verfasser'
  | '';
