import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';

import { blue, cyan, geekblue, gold, green, grey, lime, magenta, purple, red, volcano, yellow } from '@ant-design/colors';
import { orderBy, sortBy } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { BehaviorSubject, combineLatest, debounceTime } from 'rxjs';

import { AtlasService, BasePage, ISitemapPage, SecretService } from 'pbc.angular';
import { toInitials } from 'pbc.functions';
import { IAddress, IAzureMapsPosition, IAzureMapsRoute } from 'pbc.types';

import {
  Besichtigung,
  IBesichtigung,
  IDeleteBesichtigungRequest,
  IDeleteBesichtigungResponse,
  IDeleteTourRequest,
  IDeleteTourResponse,
  IMitarbeiterResponseRow,
  IOffeneGutachtenResponseRow,
  IPostBesichtigungRequest,
  IPostBesichtigungResponse,
  IPostDurchgangRequest,
  IPostDurchgangResponse,
  IPostOrdneBesichtigungenRequest,
  IPostStartTourRequest,
  IPostStartTourResponse,
  IPostTourRequest,
  IPostTourResponse,
  ITour,
  ITourenResponseRow,
} from 'fa-kt.types';
import { FormulareService, PostDurchgangCommandService } from '../../../formulare';
import { KundenService } from '../../../kunden';
import { ObjektArtsService } from '../../../objekte';
import { MitarbeiterService } from '../../../personen';
import { ProjektArtsService } from '../../../projekte';
import {
  DeleteBesichtigungCommandService,
  DeleteTourCommandService,
  PostBesichtigungCommandService,
  PostOrdneBesichtigungenCommandService,
  PostStartTourCommandService,
  PostTourCommandService,
} from '../../commands';
import { BesichtigungComponent } from '../../components';
import { OffeneGutachtenService, TourenService, TourService } from '../../querys';

interface OffeneGutachtenResponseRow extends IOffeneGutachtenResponseRow {
  color?: string;
}

interface TourenRow extends ITourenResponseRow {
  hasWiedervorlage?: boolean;
  index?: number;
  color?: string;
  selectable?: boolean;
}

@Component({
  selector: 'fa-kt-touren-page',
  templateUrl: './touren.page.html',
  styleUrls: ['./touren.page.css'],
})
export class TourenPage extends BasePage implements OnInit, OnDestroy {
  description = { context: 'BESICHTIGUNGEN', page: 'TOUREN' };

  hasTour = false;
  hasMap = true;
  hasList = true;
  $resetMap = new BehaviorSubject<Date>(new Date());

  public readonly $deleteTour: BehaviorSubject<IDeleteTourRequest | null> = new BehaviorSubject<IDeleteTourRequest | null>(null);
  public readonly $postOrdneBesichtigungen: BehaviorSubject<IPostOrdneBesichtigungenRequest | null> = new BehaviorSubject<IPostOrdneBesichtigungenRequest | null>(null);
  public readonly $deleteBesichtigung: BehaviorSubject<IDeleteBesichtigungRequest | null> = new BehaviorSubject<IDeleteBesichtigungRequest | null>(null);
  public readonly $postTour: BehaviorSubject<IPostTourRequest | null> = new BehaviorSubject<IPostTourRequest | null>(null);
  public readonly $postDurchgang: BehaviorSubject<IPostDurchgangRequest | null> = new BehaviorSubject<IPostDurchgangRequest | null>(null);
  public readonly $postStartTour: BehaviorSubject<IPostStartTourRequest | null> = new BehaviorSubject<IPostStartTourRequest | null>(null);
  public readonly $postBesichtigung: BehaviorSubject<IPostBesichtigungRequest | null> = new BehaviorSubject<IPostBesichtigungRequest | null>(null);

  public readonly search$ = new BehaviorSubject<string>('');

  public _tour: ISitemapPage;
  public _projekt: ISitemapPage;
  public _durchgang: ISitemapPage;

  colors = [red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey];

  $offeneGutachten = new BehaviorSubject<OffeneGutachtenResponseRow[]>([]);
  $offenesGutachten = new BehaviorSubject<OffeneGutachtenResponseRow | undefined>(undefined);
  $alleMitarbeiter = new BehaviorSubject<IMitarbeiterResponseRow[]>([]);
  $mitarbeiter = new BehaviorSubject<IMitarbeiterResponseRow | undefined>(undefined);
  $alleTouren = new BehaviorSubject<TourenRow[]>([]);

  $id = new BehaviorSubject<string | undefined>(undefined);
  $tour = new BehaviorSubject<TourenRow | undefined>(undefined);
  $tourBesichtigungen = new BehaviorSubject<Besichtigung[]>([]);

  $coordinates = new BehaviorSubject<IAzureMapsPosition[]>([]);
  $routes = new BehaviorSubject<IAzureMapsRoute[]>([]);

  constructor(
    public touren: TourenService,
    public tour: TourService,
    public mitarbeiter: MitarbeiterService,
    public offeneGutachten: OffeneGutachtenService,
    public formulare: FormulareService,
    public deleteTour: DeleteTourCommandService,
    public postOrdneBesichtigungen: PostOrdneBesichtigungenCommandService,
    public deleteBesichtigung: DeleteBesichtigungCommandService,
    public postTour: PostTourCommandService,
    public postDurchgang: PostDurchgangCommandService,
    public postStartTour: PostStartTourCommandService,
    public postBesichtigung: PostBesichtigungCommandService,
    public objektArten: ObjektArtsService,
    public projektArten: ProjektArtsService,
    public kunden: KundenService,
    public secrets: SecretService,
    private atlas: AtlasService,
  ) {
    super();
    this._tour = this.sitemap['BESICHTIGUNGEN'].Pages['TOUR'];
    this._projekt = this.sitemap['PROJEKTE'].Pages['PROJEKT'];
    this._durchgang = this.sitemap['FORMULARE'].Pages['DURCHGANG'];
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((params: Params) => {
      if (params['id'] !== 'new') this.$id.next(params['id']);
    });
    this.search$.pipe(takeUntilDestroyed(this.destroyedRef), debounceTime(this.device.debounceTimes.S)).subscribe((search) => {
      this.offeneGutachten.search$.next(search);
      this.touren.search$.next(search);
    });
    this.$offenesGutachten.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((offenesGutachten) => {
      if ((offenesGutachten?.projekt?.besichtigungen?.length || 0) > 0) {
        const mitarbeiter = this.$alleMitarbeiter.getValue().find((m) => m.mitarbeiter.id === offenesGutachten?.projekt?.besichtigungen?.[0]);
        this.$mitarbeiter.next(mitarbeiter);
      }
    });
    this.mitarbeiter.response$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((result) => {
      if (!result) return this.$alleMitarbeiter.next([]);
      let mitarbeiter = sortBy(
        result.mitarbeiter.filter((m) => m.mitarbeiter.tourenplanung),
        'mitarbeiter.anzeigename',
      ).map((row, index) => {
        row.mitarbeiter.initialen = toInitials(row.mitarbeiter.anzeigename);
        row.mitarbeiter.color = this.colors[index % 11][2];
        row.mitarbeiter.colorIndex = index % 11;
        return row;
      });
      const me = mitarbeiter.find((m) => m.mitarbeiter.id === this.auth.$id.getValue());
      if (me?.mitarbeiter?.id) this.$mitarbeiter.next(me);
      this.$alleMitarbeiter.next(mitarbeiter);
    });
    combineLatest([this.$alleMitarbeiter, this.$mitarbeiter, this.touren.result$, this.$tour])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([alleMitarbeiter, mitarbeiter, touren, tour]) => {
        if (!touren?.touren || !alleMitarbeiter) return;
        let result: ITourenResponseRow[] = touren.touren;
        if (tour && !mitarbeiter) {
          this.$mitarbeiter.next(alleMitarbeiter.find((ma) => ma.mitarbeiter.id === tour.tour.mitarbeiter));
          return;
        }
        if (mitarbeiter) {
          result = result.filter((t) => mitarbeiter.mitarbeiter.id === t?.tour?.mitarbeiter);
        }
        this.$alleTouren.next(
          result
            .filter(({ tour }) => tour)
            .map((tour, i) => ({
              ...tour,
              hasWiedervorlage: tour.besichtigungen?.some(({ besichtigung }) => besichtigung.wiedervorlage || besichtigung.aktion),
              color: this.colors[alleMitarbeiter.find((row) => row.mitarbeiter.id === tour.tour.mitarbeiter)?.mitarbeiter?.colorIndex as number][(i % 6) + 4],
            })),
        );
      });
    combineLatest([this.offeneGutachten.result$, this.$offenesGutachten, this.$alleMitarbeiter, this.$mitarbeiter, this.$alleTouren])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([result, offenesGutachten, alleMitarbeiter, mitarbeiter, alleTouren]) => {
        if (!result) return;
        let offeneGutachten = result.offeneGutachten;
        if (mitarbeiter) offeneGutachten = offeneGutachten.filter((row) => row.projekt?.besichtigungen?.includes(mitarbeiter?.mitarbeiter?.id as string));
        const offeneGutachtenRows: OffeneGutachtenResponseRow[] = [];

        const coordinates: IAzureMapsPosition[] = [];
        offeneGutachten.forEach((row, i) => {
          const MA = alleMitarbeiter.find((m) => row.projekt?.besichtigungen?.includes(m.mitarbeiter.id!));
          const color = MA?.mitarbeiter.color;
          offeneGutachtenRows.push({
            ...row,
            color,
          });
          coordinates.push({
            lat: row.objekt?.addresse.latitude as number,
            lon: row.objekt?.addresse.longitude as number,
            object: { ...row, color, type: 'offenesGutachten' },
            color,
            type: offenesGutachten?.gutachten?.id === row?.gutachten.id ? 'selected' : 'open',
          });
        });
        this.$offeneGutachten.next(offeneGutachtenRows);

        alleTouren.forEach((tour, i) => {
          const MA = alleMitarbeiter.find((m) => tour.tour.mitarbeiter === m.mitarbeiter.id);
          const color = this.colors[MA?.mitarbeiter.colorIndex as number][(i % 6) + 6];
          tour.besichtigungen?.forEach((besichtigung, i) => {
            if (i === 0) {
              coordinates.push({
                lat: besichtigung?.besichtigung?.start?.latitude as number,
                lon: besichtigung?.besichtigung?.start?.longitude as number,
                object: {},
                color,
                secondaryColor: tour.color,
                type: 'home',
              });
            }
            coordinates.push({
              lat: besichtigung?.besichtigung?.ende?.latitude as number,
              lon: besichtigung?.besichtigung?.ende?.longitude as number,
              object: !besichtigung.besichtigung.rueckfahrt ? { ...besichtigung, type: 'besichtigung' } : {},
              color,
              secondaryColor: tour.color,
              type: besichtigung.besichtigung.rueckfahrt ? 'home' : 'default',
            });
          });
        });
        this.$coordinates.next(coordinates);
      });
    this.$alleTouren.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (touren) => {
      this.$routes.next(
        await this.atlas.getRoutesInBatch(
          touren
            .filter(({ besichtigungen }) => besichtigungen?.length)
            .map((tour) =>
              tour.besichtigungen.map(({ besichtigung }) => {
                return {
                  route: besichtigung.route,
                  color: tour.color,
                  object: { type: 'tour', ...tour },
                };
              }),
            )
            .flat(),
        ),
      );
    });
    combineLatest([this.$id, this.touren.response$])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(async ([tour, touren]) => {
        if (!tour) {
          this.tour.result$.next(undefined);
          this.$tour.next(undefined);
          this.$resetMap.next(new Date());
          this.hasTour = false;
        } else {
          this.loading$.next(true);
          this.$tour.next((touren?.touren || []).find((r) => r.tour.id === tour));
          await this.tour.request({ id: tour as string }).catch(console.error);
          this.hasTour = true;
          this.$resetMap.next(new Date());
          this.loading$.next(false);
        }
      });
    combineLatest([this.tour.result$])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([result]) => {
        this.$postTour.next(result);
        this.$deleteTour.next({ id: result?.tour?.id });
        this.$tourBesichtigungen.next(result?.besichtigungen || []);
      });
  }

  getColumnWidth() {
    let count = 0;
    if (this.hasTour) count++;
    if (this.hasMap) count++;
    if (this.hasList) count++;
    return 24 / count;
  }

  public async setTour(id: string) {
    await this.navigateToTour({ id });
  }

  async finishedDeleteTour(response?: IDeleteTourResponse) {
    this.tour.payload$.next(undefined);
    await this.openTour();
  }

  async finishedPostStartTour(response?: IPostStartTourResponse) {
    this.$offenesGutachten.next(undefined);
    this.openTour(response?.tour?.id);
  }

  async finishedPostTour(response: IPostTourResponse) {}

  async finishedDeleteBesichtigung(response?: IDeleteBesichtigungResponse) {}

  async finishedpostDurchgang(response?: IPostDurchgangResponse) {}

  async finishedPostBesichtigung(response?: IPostBesichtigungResponse) {
    this.$offenesGutachten.next(undefined);
  }

  public async navigateToTour(queryParams: Params = {}) {
    await this.router.navigate(this._tour.url, { queryParams });
  }
  public async navigateToProjekt(queryParams: Params = {}) {
    await this.router.navigate(this._projekt.url, { queryParams });
  }
  public async navigateToDurchgang(queryParams: Params = {}) {
    await this.router.navigate(this._durchgang.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  async startNewTour() {
    const row = this.$offenesGutachten.getValue();
    if (!row) return;
    const tourStati = orderBy(this.postStartTour.shapes$.getValue()?.['tour.tourStatus'], 'order');
    const stati = orderBy(this.postBesichtigung.shapes$.getValue()?.['besichtigungen.besichtigungsStatus'], 'order');
    const am = moment().utc().add(7, 'days').set('hours', 8).set('minutes', 0).set('seconds', 0).utc().toDate();
    const tour: ITour = {
      id: '',
      tourStatus: tourStati?.[0]?.value!,
      mitarbeiter: this.$mitarbeiter.getValue()?.mitarbeiter.id!,
      am,
    };
    const besichtigung: IBesichtigung = {
      id: '',
      tour: '',
      order: 1,
      gutachten: row?.gutachten.id,
      projekt: row?.gutachten.projekt,
      objekt: row?.gutachten?.objekt,
      ende: row.objekt.addresse,
      start: row.objekt.addresse,
      besichtigungsStatus: stati?.[0].value as string,
      kommentar: '',
    };
    try {
      this.loading$.next(true);
      const response = await this.postStartTour.request({ tour, besichtigung });
      this.finishedPostStartTour(response);
    } catch (error) {
      this.monitoring.logException(error);
      this.message.warning('Etwas ist schief gelaufen');
    }
    this.loading$.next(false);
  }

  async addBesichtigung(order: number) {
    const row = this.$offenesGutachten.getValue();
    const tour = this.$id.getValue();
    if (!row || !tour) return;
    const stati = this.postBesichtigung.shapes$.getValue()?.['besichtigungen.besichtigungsStatus'];
    const besichtigung: IBesichtigung = {
      id: '',
      tour,
      order,
      ende: row.objekt.addresse,
      start: row.objekt.addresse,
      besichtigungsStatus: stati?.[0].value,
      gutachten: row?.gutachten.id,
      projekt: row?.gutachten.projekt,
      objekt: row?.gutachten?.objekt,
      kommentar: '',
    };
    try {
      this.loading$.next(true);
      const response = await this.postBesichtigung.request({ besichtigung: [...this.$tourBesichtigungen.getValue().map(({ besichtigung }) => besichtigung), besichtigung] });
      this.finishedPostBesichtigung(response);
    } catch (error) {
      this.monitoring.logException(error);
      this.message.warning('Etwas ist schief gelaufen');
    }
    this.loading$.next(false);
  }

  async drop(event: CdkDragDrop<string[]>) {
    const besichtigungen = this.$tourBesichtigungen.getValue();
    try {
      this.loading$.next(true);
      moveItemInArray(besichtigungen, event.previousIndex, event.currentIndex);
      this.$tourBesichtigungen.next(besichtigungen);
      await this.postOrdneBesichtigungen.request({
        besichtigungen: besichtigungen.map(({ besichtigung }) => besichtigung),
      });
    } catch (error) {
      this.monitoring.logException(error);
      this.message.warning('Etwas ist schief gelaufen');
    }
    this.loading$.next(false);
  }

  public async submitDeleteTour(request?: IDeleteTourRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$deleteTour.getValue();
    if (payload) {
      try {
        const response = await this.deleteTour.request(payload);
        await this.finishedDeleteTour(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  clickedMap($event: any) {
    if ($event?.type === 'offenesGutachten') {
      this.$offenesGutachten.next($event);
    } else if ($event?.type === 'besichtigung') {
      this.openTour($event.besichtigung.tour);
      this.editBesichtigung($event);
    } else if ($event?.type === 'tour') {
      this.openTour($event.tour.id);
    }
  }

  openGutachten(besichtigung: OffeneGutachtenResponseRow) {
    if (!besichtigung.objekt?.addresse.latitude || !besichtigung.objekt?.addresse.longitude) return;
    this.$offenesGutachten.next(besichtigung);
  }

  async openTour(id?: string) {
    this.$tour.next(undefined);
    this.$id.next(id);
  }

  editBesichtigung(besichtigung: Besichtigung) {
    if (besichtigung.besichtigung.rueckfahrt) return;
    this.modal.create({
      nzContent: BesichtigungComponent,
      nzData: { besichtigung, tour: this.$tour.getValue()?.tour },
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: [],
    });
  }

  trackByIndex(_: number, data: Besichtigung): string {
    return data.besichtigung?.id as string;
  }

  openAddress(addresse: IAddress) {
    const url = `https://www.google.com/maps/place/${addresse?.latitude},${addresse?.longitude}`;
    window.open(url, '_blank')?.focus();
  }

  getMitarbeiterText(id: string, asInitialen = false) {
    const label = (this.mitarbeiter.response$.getValue()?.mitarbeiter || []).find((m) => m.mitarbeiter.id === id)?.mitarbeiter.anzeigename || '';
    if (!asInitialen || !label) return label;
    return toInitials(label);
  }

  resetMap() {
    this.$resetMap.next(new Date());
  }
}
