import { DatePipe, DecimalPipe, formatDate, formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { trimUndefined } from 'compress-json';

import Docxtemplater from 'docxtemplater';
import {
  IAbschnitt,
  IBerichtsVorlage,
  IFullAbteilung,
  IFullBesichtigung,
  IFullFond,
  IFullGutachten,
  IFullKunde,
  IFullObjekt,
  IFullProjekt,
  IFullStandort,
  IFullTour,
  IGutachtenResponse,
  IProjekteResponseRow,
} from 'fa-kt.types';
import { saveAs } from 'file-saver';
import { convert } from 'html-to-text';
import { cloneDeep, orderBy, round, unset } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Feld, FileService } from 'pbc.angular';
import { compileWithNunjucks, formatAddress, INunjucksOptions, join, propertiesToArray } from 'pbc.functions';
import { IQueryResponse } from 'pbc.types';
import PizZip from 'pizzip';
import typia from 'typia';
import { PostTourCommandService, TourService } from '../besichtigungen';
import { ErforderlichesDokumentsService } from '../dokumente';
import { OrtKategoriesService, OrtsService } from '../geo';
import { GutachtenService } from '../gutachten';
import { KundeService, PostKundeCommandService } from '../kunden';
import { StandortsService } from '../personen';
import { ProjekteService, ProjektService } from '../projekte';
import { DeleteAbschnittCommandService, PostAbschnittCommandService, PostSortiereAbschnitteCommandService } from './commands';

@Injectable({
  providedIn: 'root',
})
export class GeneratorService {
  constructor(
    public files: FileService,
    public message: NzMessageService,
    private postKunde: PostKundeCommandService,
    private postTour: PostTourCommandService,
    private tour: TourService,
    public orts: OrtsService,
    public ortKategories: OrtKategoriesService,
    public standorte: StandortsService,
    public postAbschnitt: PostAbschnittCommandService,
    public deleteAbschnitt: DeleteAbschnittCommandService,
    public postSortiereAbschnitte: PostSortiereAbschnitteCommandService,
    public projekt: ProjektService,
    public gutachten: GutachtenService,
    public kunde: KundeService,
    public projekte: ProjekteService,
    public erforderlicheDokumente: ErforderlichesDokumentsService,
  ) {}

  nunjucks: INunjucksOptions = {
    options: {},
    filters: {
      gesetzt: (value) => !!value,
      hat: (value, ...args) => args?.some((a) => value?.includes(a)) || false,
      alle: (value, ...args) => args?.every((a) => value?.includes(a)) || false,
      zahl: (value, format) => (typeof value === 'number' ? formatNumber(value, 'de-DE', format) : ''),
      datum: (value, format) => (value ? formatDate(value, format, 'de-DE') : ''),
    },
  };

  async setObjekt(projekt: IProjekteResponseRow, alleFelder: Feld[], gutachtenID?: string): Promise<any> {
    if (!projekt || !gutachtenID || !projekt) return {};
    projekt = cloneDeep(projekt);
    let gutachten = await this.gutachten.request({ gutachten: gutachtenID });
    if (!gutachten) return {};
    gutachten = cloneDeep(gutachten);

    const standorte = this.standorte.result$.getValue();
    const orts = this.orts.result$.getValue();
    const ortKategories = this.ortKategories.result$.getValue();

    const obj: any = {};

    const gutachtenGeo = {
      latitude: gutachten?.objekt.addresse.latitude,
      longitude: gutachten?.objekt.addresse.longitude,
    };
    obj.gis = {};
    ortKategories.ortKategories.forEach((ok) => {
      const orte = orderBy(
        orts.orts
          .filter(({ ortKategorie }) => ortKategorie === ok.id)
          .map(({ name, ort }) => {
            Object.entries(ort).forEach(([key, value]) => (ort[key] = value || undefined));
            trimUndefined(ort);
            return {
              name,
              ort,
              addresse: formatAddress(ort),
              distanz: this.calculateDistance(gutachtenGeo, {
                latitude: ort.latitude,
                longitude: ort.longitude,
              }),
            };
          }),
        'distance',
      );
      obj.gis[ok.name] = orte.shift() || {};
    });

    const {
      projekt: PROJEKT,
      abteilung,
      fond,
    } = this.projekte.translate<IProjekteResponseRow>(projekt, [
      'kontakteInOutlook',
      'dateiVerzeichnis',
      'bank',
      'kunde',
      'kostenNachStunden',
      'verhandeltesHonorar',
      'stunden',
      'kostenNetto',
      'kosten',
      'kostenBeglichen',
      'gutachtenAnteile',
      'partnerInnenAnteile',
      'nachlass',
    ]);

    obj.projekt = PROJEKT;
    obj.abteilung = abteilung;
    obj.fond = fond;

    const {
      gutachten: GUTACHTEN,
      objekt,
      besichtigung,
      tour,
    } = this.gutachten.translate<IGutachtenResponse>(gutachten, [
      'kontakteInOutlook',
      'dateiVerzeichnis',
      'bank',
      'kunde',
      'anspracheOutlookKontakts',
      'besichtigung.gutachten',
      'besichtigung.projekt',
      'besichtigung.objekt',
      'besichtigung.order',
      'besichtigung.route',
    ]);

    obj.gutachten = GUTACHTEN;
    obj.objekt = objekt;
    obj.besichtigung = besichtigung;
    obj.tour = this.tour.translate({ tour } as IQueryResponse, [], this.postTour.shapes$.getValue())?.tour;
    obj.standort = this.standorte.translate(standorte).standorts.find((s) => s.id === projekt?.projekt.standort);
    obj.gutachten.objekt = objekt;
    obj.gutachten.projekt = obj.projekt;
    if (obj.besichtigung && obj.tour) obj.besichtigung.tour = obj.tour;

    if (projekt?.projekt?.bank) {
      let bank = await this.kunde.request({
        id: projekt?.projekt?.bank as string,
      });
      if (bank) bank = this.kunde.translate(bank, ['outlookKontakte'], this.postKunde.shapes$.getValue());
      obj.bank = bank.kunde;
    }

    if (projekt?.projekt?.kunde) {
      let kunde = await this.kunde.request({
        id: projekt?.projekt?.kunde as string,
      });
      if (kunde) kunde = this.kunde.translate(kunde, ['outlookKontakte'], this.postKunde.shapes$.getValue());
      obj.kunde = kunde.kunde;
    }

    if (gutachten?.erforderlicheDokumente) {
      gutachten?.erforderlicheDokumente.forEach((dokument) => {
        obj[dokument.name] = dokument.fortschritt === 100;
      });
    }

    propertiesToArray(obj)
      .filter((feld: string) => feld?.includes('._') || feld?.[feld?.length - 1] === '.' || feld.toLowerCase().includes('kosten'))
      .forEach((feld) => unset(obj, feld));

    alleFelder.forEach((feld) => {
      let wert: string = '';
      const eintrag = gutachten?.eintraege.find(({ feld: id }) => id === feld.id);
      if (eintrag?.wert || eintrag?.wertExtra) {
        eintrag.wert = Array.isArray(eintrag.wert)
          ? eintrag.wert.map((wert) => this.convertWert(wert, feld.art, feld.format, feld.schluessel))
          : this.convertWert(eintrag.wert, feld.art, feld.format, feld.schluessel);

        const wertExtra = this.convertWert(eintrag.wert.wertExtra, feld.art, feld.format, feld.schluessel);

        wert = Array.isArray(eintrag.wert) ? join([...eintrag.wert, wertExtra]) : eintrag.wert;
      }
      obj[feld.schluessel] = wert || feld.voreinstellung || '';
    });

    return obj;
  }

  setVirtual(alleFelder: Feld[]) {
    const orts = this.orts.result$.getValue();
    const ortKategories = this.ortKategories.result$.getValue();

    const obj: any = {};

    const gutachtenGeo = {
      latitude: 1,
      longitude: 1,
    };
    obj.gis = {};
    ortKategories.ortKategories.forEach((ok) => {
      const orte = orderBy(
        orts.orts
          .filter(({ ortKategorie }) => ortKategorie === ok.id)
          .map(({ name, ort }) => {
            Object.entries(ort).forEach(([key, value]) => (ort[key] = value || undefined));
            trimUndefined(ort);
            return {
              name,
              ort,
              addresse: formatAddress(ort),
              distanz: this.calculateDistance(gutachtenGeo, {
                latitude: ort.latitude,
                longitude: ort.longitude,
              }),
            };
          }),
        'distance',
      );
      obj.gis[ok.name] = orte.shift() || {};
    });

    obj.projekt = typia.random<IFullProjekt>();
    obj.abteilung = typia.random<IFullAbteilung>();
    obj.fond = typia.random<IFullFond>();

    obj.gutachten = typia.random<IFullGutachten>();
    obj.objekt = typia.random<IFullObjekt>();
    obj.besichtigung = typia.random<IFullBesichtigung>();
    obj.tour = typia.random<IFullTour>();
    obj.standort = typia.random<IFullStandort>();
    obj.gutachten.objekt = obj.objekt;
    obj.gutachten.projekt = obj.projekt;
    obj.besichtigung.tour = obj.tour;

    obj.bank = typia.random<IFullKunde>();
    obj.kunde = typia.random<IFullKunde>();

    this.erforderlicheDokumente.response$.getValue()?.erforderlichesDokuments.forEach((dokument) => {
      obj[dokument.name] = dokument.fortschritt === 100;
    });

    propertiesToArray(obj)
      .filter((feld: string) => feld?.includes('._') || feld?.[feld?.length - 1] === '.' || feld.toLowerCase().includes('kosten'))
      .forEach((feld) => unset(obj, feld));

    alleFelder.forEach((feld) => (obj[feld.schluessel] = feld.voreinstellung || this.getDefault(feld.art)));

    return obj;
  }

  getDefault(art: 'datum' | 'haken' | 'zahl' | 'text' | 'option' | 'optionPlus' | 'mehrfachauswahlPlus'): any {
    switch (art) {
      case 'datum':
        return new Date();
      case 'haken':
        return true;
      case 'zahl':
        return 1;
      default:
        return true;
    }
  }

  private calculateDistance(point1: { latitude: number; longitude: number }, point2: { latitude: number; longitude: number }): number {
    const R = 6371; // km
    var dLat = this.toRad(point2.latitude - point1.latitude);
    var dLon = this.toRad(point2.longitude - point1.longitude);
    var lat1 = this.toRad(point1.latitude);
    var lat2 = this.toRad(point2.latitude);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return round(d, 2);
  }

  private toRad(value: number) {
    return (value * Math.PI) / 180;
  }

  convertWert(wert: any, art: any, format: any, label: string): any {
    if (!wert) return '';
    const date = new DatePipe('de-DE');
    const number = new DecimalPipe('de-DE');
    if (wert && wert.toLowerCase && wert.toLowerCase() === 'ja') wert = true;
    if (wert && wert.toLowerCase && wert.toLowerCase() === 'nein') wert = false;
    try {
      switch (art) {
        case 'zahl':
          if (!format || format?.includes('#') || format?.toLowerCase()?.includes('zahl') || format?.toLowerCase()?.includes('text') || format?.toLowerCase()?.includes('datum')) format = '0.2-2';
          if (format.includes('%')) {
            format = format.split('%').join('');
            wert = wert * 100;
          }
          wert = number.transform(wert, format, 'de-DE');
          break;
        case 'datum':
          wert = date.transform(wert, format, 'de-DE');
          break;
        case 'text':
          wert = wert?.toString().split('\r\n').join(`
`);
      }
    } catch (e) {
      console.error(label, e);
    }
    return wert;
  }

  async run(objekt: any, abschnitte: IAbschnitt[], vorlage: IBerichtsVorlage) {
    try {
      const blob = (await this.files.get(vorlage.datei as string)) as File;
      objekt = {
        ...(objekt.gis || {}),
        ...(objekt.projekt || {}),
        ...(objekt.abteilung || {}),
        ...(objekt.fond || {}),
        ...(objekt.gutachten || {}),
        ...(objekt.objekt || {}),
        ...(objekt.standort || {}),
        ...(objekt.bank || {}),
        ...(objekt.kunde || {}),
        ...(objekt.besichtigung || {}),
        ...(objekt || {}),
      };
      abschnitte.forEach((abschitt: IAbschnitt) => {
        try {
          objekt[abschitt.platzhalter as string] = convert(compileWithNunjucks(abschitt.text, objekt, this.nunjucks));
        } catch (e: any) {
          console.error(abschitt.name, e);
          this.message.error(abschitt.name + ': ' + e);
        }
      });

      const template = (await this.files.readFileAsync(blob)) as Buffer;
      const zip = new PizZip(template);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
        delimiters: { start: '{{', end: '}}' },
      });
      try {
        doc.render(objekt);
      } catch (error: any) {
        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map((error: any) => {
              return error.properties.explanation;
            })
            .join('\n');
          console.error('errorMessages', errorMessages);
        }
        throw error;
      }
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        compression: 'DEFLATE',
      });
      saveAs(out, objekt.nummer + ' | ' + objekt.bezeichnung + '.docx');
    } catch (e: any) {
      console.error(e);
      this.message.error(e);
    }
  }
}
