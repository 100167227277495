import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IStandortsQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   addresse?: ISorterExpression;
   dateipfad?: ISorterExpression;
}

export const StandortsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'PERSONEN.Models.STANDORT.Propertys.ID.title'),
   SorterConfig('name', 'PERSONEN.Models.STANDORT.Propertys.NAME.title'),
   SorterConfig('addresse', 'PERSONEN.Models.STANDORT.Propertys.ADDRESSE.title'),
   SorterConfig('dateipfad', 'PERSONEN.Models.STANDORT.Propertys.DATEIPFAD.title'),
];
