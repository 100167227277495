import { NgModule } from '@angular/core';

import {
  DeleteBesichtigungCommandServiceModule,
  DeleteBesichtigungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteBesichtigungCommandServiceModule,
    DeleteBesichtigungCommandComponentModule
  ],
  exports: [
    DeleteBesichtigungCommandServiceModule,
    DeleteBesichtigungCommandComponentModule
  ]
})
export class DeleteBesichtigungCommandModule { }
