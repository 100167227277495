import { IFilter, IFilterConfig } from "pbc.types";

import { IBericht } from '.';

export interface IBerichtFilter extends IFilter<IBericht> {}

export const BerichtFilterConfig: IFilterConfig<IBericht> = [];

export type IBerichtField =
  'bericht.id'|
  'bericht.excel'|
  'bericht.art'|
  'bericht.herausgeber'|
  'bericht.quelle'|
  'bericht.jahr'|
  'bericht.quartal'|
  'bericht.monat'|
  'bericht.kommentar'
;
