<!---
  <div formGroupName="kalkulation">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="gutachten">{{ command.Inputs.GUTACHTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
        <ng-template #GutachtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GUTACHTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="gutachten" id="gutachten" [shapes]="postKalkulationHochladen.kalkulationGutachten | async" [placeholder]="command.Inputs.GUTACHTEN.placeholder" cdkFocusInitial required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datei">{{ command.Inputs.DATEI.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateiErrorTip">
        <ng-template #DateiErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEI.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="datei" id="datei" [shapes]="postKalkulationHochladen.kalkulationDatei | async" [placeholder]="command.Inputs.DATEI.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="kalkulation">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="gutachten" id="gutachten"/>
      <nz-form-item style="width: 100%;">
        <pbc-file-upload style="width: 100%;" formControlName="datei" id="datei" (uploaded)="submit()" [icon]="'file-excel'" [text]="command.Inputs.DATEI.title" [hint]="command.Inputs.DATEI.hint"></pbc-file-upload>
      </nz-form-item>
      </div>
<!---    custom >> -->
<!---
<button nz-button nzType="primary"
  type="submit"
  [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
  nz-tooltip [nzTooltipTitle]="SubmitTooltip">
    {{ command.translation }}
  <ng-template #SubmitTooltip>
  </ng-template>
</button>
 -->
  </form>
</nz-spin>
