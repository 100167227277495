import { NgModule } from '@angular/core';

import {
  PostVorlageCommandServiceModule,
  PostVorlageCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostVorlageCommandServiceModule,
    PostVorlageCommandComponentModule
  ],
  exports: [
    PostVorlageCommandServiceModule,
    PostVorlageCommandComponentModule
  ]
})
export class PostVorlageCommandModule { }
