<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubitle">
  <ng-template #PageTitle> 💻️ Das Gerät </ng-template>
  <ng-template #PageSubitle> </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="['admin']"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra> </nz-page-header-extra>
  <nz-page-header-content> </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <nz-col nzSpan="24" style="padding: 32px">
      <pbc-offline-cache></pbc-offline-cache>
    </nz-col>
    <nz-col nzSpan="24" style="padding: 32px">
      <pbc-device-config></pbc-device-config>
    </nz-col>
    <nz-col nzSpan="24" style="padding: 32px">
      <h3 nz-title>Dienste</h3>
      <pbc-health></pbc-health>
    </nz-col>
    <nz-col nzSpan="24" style="padding: 32px">
      <span style="float: right; margin-bottom: 16px" nz-typography nzType="secondary"><code>{{version}}-{{ stage }} ({{build}})</code></span>
    </nz-col>
  </nz-row>
</nz-content>
