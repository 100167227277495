import { GeoRangeFilterConfig, IFilter, IFilterConfig, IGeoRangeFilter, INumberFilter, ISelection, IShape, ISingleReferenceFilter, NumberFilterConfig, SingleReferenceFilterConfig } from 'pbc.types';

import { IKundenArt, IKundenGruppe } from 'fa-kt.types';
import { IKundenResponse } from '.';

export interface IKundenQueryFilter extends IFilter<IKundenResponse> {
  'kunde.kundenGruppe'?: ISingleReferenceFilter<IKundenGruppe>;
  'kunde.kundenArt'?: ISingleReferenceFilter<IKundenArt>;
  'kunde.addresse'?: IGeoRangeFilter;
  'kunde.fahrtkostenAbRadius'?: INumberFilter;
  'kunde.fahrtkostenProStunde'?: INumberFilter;
  'kunde.skonto'?: INumberFilter;
  'kunde.zahlungsziel'?: INumberFilter;
  'kunde.tageOhneSkonto'?: INumberFilter;
}

export const KundenQueryFilterConfig: IFilterConfig<IKundenResponse> = [
  SingleReferenceFilterConfig<IKundenGruppe>('kunde.kundenGruppe', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_GRUPPE.title'),
  SingleReferenceFilterConfig<IKundenArt>('kunde.kundenArt', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.title'),
  GeoRangeFilterConfig('kunde.addresse', 'KUNDEN.Models.KUNDE.Propertys.ADDRESSE.title'),
  NumberFilterConfig('kunde.fahrtkostenAbRadius', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.title'),
  NumberFilterConfig('kunde.fahrtkostenProStunde', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.title'),
  NumberFilterConfig('kunde.skonto', 'KUNDEN.Models.KUNDE.Propertys.SKONTO.title'),
  NumberFilterConfig('kunde.zahlungsziel', 'KUNDEN.Models.KUNDE.Propertys.ZAHLUNGSZIEL.title'),
  NumberFilterConfig('kunde.tageOhneSkonto', 'KUNDEN.Models.KUNDE.Propertys.TAGE_OHNE_SKONTO.title'),
];

export interface IKundenQueryShapes extends IShape {
  'kunde.kundenGruppe': ISelection[];
  'kunde.kundenArt': ISelection[];
  'kunde.fahrtkostenAbrechnung': ISelection[];
}

export type IKundenQueryField =
  | 'kunde.id'
  | 'kunde.name'
  | 'kunde.initialen'
  | 'kunde.kundenGruppe'
  | 'kunde.kundenArt'
  | 'kunde.addresse'
  | 'kunde.website'
  | 'kunde.outlookKontakte'
  | 'kunde.kontaktNotizen'
  | 'kunde.anforderungenNotizen'
  | 'kunde.rechnungNotizen'
  | 'kunde.fahrtkostenAbrechnung'
  | 'kunde.steuerId'
  | 'kunde.fahrtkostenAbRadius'
  | 'kunde.fahrtkostenProKm'
  | 'kunde.fahrtkostenProStunde'
  | 'kunde.skonto'
  | 'kunde.zahlungsziel'
  | 'kunde.tageOhneSkonto'
  | 'kunde.aktiv'
  | 'kunde.dateipfad'
  | 'kunde.loraName'
  | 'kunde.unterlagenEmail'
  | '';
