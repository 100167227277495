import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostBewertungsAnlassCommandShapes, IPostBewertungsAnlassRequest, IPostBewertungsAnlassResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostBewertungsAnlassCommandService extends PostCommandService<IPostBewertungsAnlassRequest, IPostBewertungsAnlassResponse, IPostBewertungsAnlassCommandShapes> {
  readonly route = 'gutachten/settings/bewertungs-anlass';
}
