import { NgModule } from '@angular/core';

import {
  DeleteDateiArtCommandServiceModule,
  DeleteDateiArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteDateiArtCommandServiceModule,
    DeleteDateiArtCommandComponentModule
  ],
  exports: [
    DeleteDateiArtCommandServiceModule,
    DeleteDateiArtCommandComponentModule
  ]
})
export class DeleteDateiArtCommandModule { }
