import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IEintragSorter extends ISorter {
  id?: ISorterExpression;
  gutachten?: ISorterExpression;
  feld?: ISorterExpression;
  wert?: ISorterExpression;
  wertExtra?: ISorterExpression;
  akkusativ?: ISorterExpression;
  nominativ?: ISorterExpression;
  dativ?: ISorterExpression;
  genitiv?: ISorterExpression;
  achtung?: ISorterExpression;
}

export const EintragSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('feld', 'Feld'),
  SorterConfig('wert', 'Wert'),
  SorterConfig('wertExtra', 'Wert Extra'),
  SorterConfig('akkusativ', 'Akkusativ'),
  SorterConfig('nominativ', 'Nominativ'),
  SorterConfig('dativ', 'Dativ'),
  SorterConfig('genitiv', 'Genitiv'),
  SorterConfig('achtung', 'Achtung')
];
