import { NgModule } from '@angular/core';

import {
  PostOrdneBesichtigungenCommandServiceModule,
  PostOrdneBesichtigungenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostOrdneBesichtigungenCommandServiceModule,
    PostOrdneBesichtigungenCommandComponentModule
  ],
  exports: [
    PostOrdneBesichtigungenCommandServiceModule,
    PostOrdneBesichtigungenCommandComponentModule
  ]
})
export class PostOrdneBesichtigungenCommandModule { }
