<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="bericht.search$.next($event)" [search]="bericht.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-bericht-filter [style.width]="'100%'"></fa-kt-bericht-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-bericht-sorter [style.width]="'100%'"></fa-kt-bericht-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-bericht-list [style.width]="'100%'" (select)="setBericht($event)" [loading]="$loading | async"></fa-kt-bericht-list>


  <fa-kt-delete-bericht [style.width]="'100%'"  [value]="$deleteBericht | async" (finished)="finishedDeleteBericht($event)"></fa-kt-delete-bericht>
    
  <fa-kt-post-bericht [style.width]="'100%'"  [value]="$postBericht | async" (finished)="finishedPostBericht($event)"></fa-kt-post-bericht>
    

  <fa-kt-durchsuchen [style.width]="'100%'" ></fa-kt-durchsuchen>

  <fa-kt-zeilen [style.width]="'100%'" ></fa-kt-zeilen>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="marktBerichte" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space style="min-width: 260px">
      <a (click)="download()" *nzSpaceItem nz-tooltip [nzTooltipTitle]="'Quelle ' + (bericht.result$ | async)?.bericht?.excel + ' herunterladen'">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>
        Herunterladen
      </a>
      <fa-kt-delete-bericht *nzSpaceItem [style.width]="'100%'" [value]="$deleteBericht | async" (finished)="finishedDeleteBericht($event)"></fa-kt-delete-bericht>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <fa-kt-post-bericht [style.width]="'100%'" [value]="$postBericht | async" (finished)="finishedPostBericht($event)"></fa-kt-post-bericht>
    </nz-col>
    <nz-col nzSpan="24">
      <fa-kt-zeilen [zeilen]="(bericht.result$ | async)?.zeilen" [bereiche]="bericht.zeilenBereiche | async" [style.width]="'100%'"></fa-kt-zeilen>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
