import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');

import { registerLocaleData } from '@angular/common';
import { isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import de from '@angular/common/locales/de';
registerLocaleData(de);

import { MsalRedirectComponent } from '@azure/msal-angular';

const language = navigator.language || (navigator as any).userLanguage;
const locale_id = language.split('_')[0];

import { APP_BUILD, APP_CONFIG, APP_TITLE, APP_VERSION, LibsCommonAngularModule, SITEMAP } from 'pbc.angular';
import { AppBuild, AppTitle, AppVersion, Sitemap } from './app.config';

import { environment } from '../environments';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

import {
  AuswertungenModule,
  BesichtigungenModule,
  DokumenteModule,
  EinstellungenModule,
  FelderModule,
  FinanzenModule,
  FormulareModule,
  GeoModule,
  GutachtenModule,
  KommunikationModule,
  KundenModule,
  MarktModule,
  ObjekteModule,
  PersonenModule,
  ProjekteModule,
  TexteModule,
  ZeitenModule,
} from 'fa-kt.angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LibsCommonAngularModule,
    AuswertungenModule.forRoot(),
    BesichtigungenModule.forRoot(),
    DokumenteModule.forRoot(),
    EinstellungenModule.forRoot(),
    FelderModule.forRoot(),
    FinanzenModule.forRoot(),
    FormulareModule.forRoot(),
    GeoModule.forRoot(),
    GutachtenModule.forRoot(),
    KommunikationModule.forRoot(),
    KundenModule.forRoot(),
    MarktModule.forRoot(),
    ObjekteModule.forRoot(),
    PersonenModule.forRoot(),
    ProjekteModule.forRoot(),
    TexteModule.forRoot(),
    ZeitenModule.forRoot(),
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: locale_id },
    { provide: APP_TITLE, useValue: AppTitle },
    { provide: APP_CONFIG, useValue: environment },
    { provide: APP_BUILD, useValue: AppBuild },
    { provide: APP_VERSION, useValue: AppVersion },
    { provide: SITEMAP, useValue: Sitemap },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
