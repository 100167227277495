import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IPostMitarbeiterRequest, IPostMitarbeiterResponse } from 'fa-kt.types';
import { IIchPageResolverResult } from '.';
import { PostMitarbeiterCommandService } from '../../commands';
import { ProfilService } from '../../querys';

@Component({
  selector: 'fa-kt-ich-page',
  templateUrl: './ich.page.html',
  styleUrls: ['./ich.page.css'],
})
export class IchPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postMitarbeiter: BehaviorSubject<IPostMitarbeiterRequest | null> = new BehaviorSubject<IPostMitarbeiterRequest | null>(null);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public profil: ProfilService,
    public postMitarbeiter: PostMitarbeiterCommandService,
  ) {
    this.page = this.sitemap.PERSONEN.Pages.ICH;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IIchPageResolverResult = data.querys;
          /* << resolver    */
          if (querys && querys.profil) {
            this.$postMitarbeiter.next(querys.profil);
          }
          /*    resolver >> */
        }),
        /* << subscriptions    */
        this.auth.$id.subscribe(async (id) => {
          if (id && this.$postMitarbeiter.getValue()?.mitarbeiter?.id !== id) {
            const ich = await this.profil.request({ id });
            if (ich) {
              this.$postMitarbeiter.next(ich);
            }
          }
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setProfil(id: string) {
    /* << set-profil    */
    /*    set-profil >> */
  }

  async finishedPostMitarbeiter(response?: IPostMitarbeiterResponse) {
    /* << after post-mitarbeiter    */
    /*    after post-mitarbeiter >> */
  }

  back() {
    window.history.back();
  }

  public async submitPostMitarbeiter(request?: IPostMitarbeiterRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postMitarbeiter.getValue();
    if (payload) {
      try {
        const response = await this.postMitarbeiter.request(payload);
        await this.finishedPostMitarbeiter(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
