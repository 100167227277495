import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DetailQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IBerichtQueryShapes, IBerichtRequest, IBerichtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class BerichtService extends DetailQueryService<IBerichtRequest, IBerichtResponse, IBerichtQueryShapes> {
  route = 'markt/bericht';
  override dependencies = [
    'geo/written-bereich',
    'geo/removed-bereich',
    'markt/written-herausgeber',
    'markt/removed-herausgeber',
    'markt/bericht-gespeichert',
    'markt/bericht-hochgeladen',
    'markt/zeile-korrigiert',
  ];

  getZeilenBereiche(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeilen.bereiche']?.find((v) => v.value === value);
  }

  get zeilenBereiche(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeilen.bereiche'] as ISelection[]) : [])));
  }

  getBerichtHerausgeber(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['bericht.herausgeber']?.find((v) => v.value === value);
  }

  get berichtHerausgeber(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['bericht.herausgeber'] as ISelection[]) : [])));
  }
}
