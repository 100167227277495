import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { IEintrag, IPostEintragRequest, IPostEintragResponse } from 'fa-kt.types';
import { PostEintragCommandService } from '../service';

import { IFeldOption } from 'fa-kt.types';

@Component({
  selector: 'fa-kt-post-eintrag',
  templateUrl: './post-eintrag.component.html',
  styleUrls: ['./post-eintrag.component.css'],
})
export class PostEintragCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostEintragRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostEintragRequest> = new EventEmitter<IPostEintragRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostEintragResponse> = new EventEmitter<IPostEintragResponse>();

  @Input() mode: 'datum' | 'haken' | 'zahl' | 'text' | 'option' | 'optionPlus' = 'text';
  @Input() optionen: IFeldOption[] = [];

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postEintrag: PostEintragCommandService /* << more    */ /*    more >> */,
  ) {
    this.command = sitemap.GUTACHTEN.Commands.POST_EINTRAG;
    this.form = this.fb.group({
      eintrag: this.fb.group({
        id: [null, []],
        gutachten: [null, [Validators.required]],
        feld: [null, [Validators.required]],
        wert: [null, [Validators.required]],
        wertExtra: [null, []],
        akkusativ: [null, []],
        nominativ: [null, []],
        dativ: [null, []],
        genitiv: [null, []],
        achtung: [null, []],
      }),
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postEintrag.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostEintragRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.eintragId?.valueChanges.subscribe(async (id) => {
        /* << on-eintrag-id-change    */
        /*    on-eintrag-id-change >> */
      }) as Subscription,
      this.eintragGutachten?.valueChanges.subscribe(async (gutachten) => {
        /* << on-eintrag-gutachten-change    */
        /*    on-eintrag-gutachten-change >> */
      }) as Subscription,
      this.eintragFeld?.valueChanges.subscribe(async (feld) => {
        /* << on-eintrag-feld-change    */
        /*    on-eintrag-feld-change >> */
      }) as Subscription,
      this.eintragWert?.valueChanges.subscribe(async (wert) => {
        /* << on-eintrag-wert-change    */
        /*    on-eintrag-wert-change >> */
      }) as Subscription,
      this.eintragWertExtra?.valueChanges.subscribe(async (wertExtra) => {
        /* << on-eintrag-wert-extra-change    */
        /*    on-eintrag-wert-extra-change >> */
      }) as Subscription,
      this.eintragAkkusativ?.valueChanges.subscribe(async (akkusativ) => {
        /* << on-eintrag-akkusativ-change    */
        /*    on-eintrag-akkusativ-change >> */
      }) as Subscription,
      this.eintragNominativ?.valueChanges.subscribe(async (nominativ) => {
        /* << on-eintrag-nominativ-change    */
        /*    on-eintrag-nominativ-change >> */
      }) as Subscription,
      this.eintragDativ?.valueChanges.subscribe(async (dativ) => {
        /* << on-eintrag-dativ-change    */
        /*    on-eintrag-dativ-change >> */
      }) as Subscription,
      this.eintragGenitiv?.valueChanges.subscribe(async (genitiv) => {
        /* << on-eintrag-genitiv-change    */
        /*    on-eintrag-genitiv-change >> */
      }) as Subscription,
      this.eintragAchtung?.valueChanges.subscribe(async (achtung) => {
        /* << on-eintrag-achtung-change    */
        /*    on-eintrag-achtung-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostEintragRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.eintrag) {
        value.eintrag = cleanObject(value.eintrag);
      }
      this.form.patchValue(value);
      /* << custom    */
      if (!Array.isArray(value)) {
        if (!(value.eintrag as IEintrag).wert) {
          this.eintragWert?.patchValue('');
        }
        if (!(value.eintrag as IEintrag).akkusativ) {
          this.eintragAkkusativ?.patchValue('');
        }
        if (!(value.eintrag as IEintrag).dativ) {
          this.eintragDativ?.patchValue('');
        }
        if (!(value.eintrag as IEintrag).genitiv) {
          this.eintragGenitiv?.patchValue('');
        }
        if (!(value.eintrag as IEintrag).nominativ) {
          this.eintragNominativ?.patchValue('');
        }
      }
      /*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.eintrag) {
        payload.eintrag = cleanObject(payload.eintrag);
      }
      const response: IPostEintragResponse = await this.postEintrag.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get eintragId() {
    return this.form.controls.eintrag.get('id');
  }
  get eintragGutachten() {
    return this.form.controls.eintrag.get('gutachten');
  }
  get eintragFeld() {
    return this.form.controls.eintrag.get('feld');
  }
  get eintragWert() {
    return this.form.controls.eintrag.get('wert');
  }
  get eintragWertExtra() {
    return this.form.controls.eintrag.get('wertExtra');
  }
  get eintragAkkusativ() {
    return this.form.controls.eintrag.get('akkusativ');
  }
  get eintragNominativ() {
    return this.form.controls.eintrag.get('nominativ');
  }
  get eintragDativ() {
    return this.form.controls.eintrag.get('dativ');
  }
  get eintragGenitiv() {
    return this.form.controls.eintrag.get('genitiv');
  }
  get eintragAchtung() {
    return this.form.controls.eintrag.get('achtung');
  }
}
