import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { IObjekteRequest, IObjekteResponse } from 'fa-kt.types';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IProjekteRequest, IProjekteResponse, IZeitArtsRequest, IZeitArtsResponse, IZeitUnterartsRequest, IZeitUnterartsResponse } from 'fa-kt.types';
import { ObjekteService } from '../../../objekte';
import { ZeitArtsService, ZeitUnterartsService } from '../../../zeiten';
import { PostProjektCommandService } from '../../commands';
import { ProjekteService } from '../../querys';
import { ProjekteFilterService } from './projekte.page';

export interface IProjektePageResolverResult {
  projekte?: IProjekteResponse | void;
  zeitArts: IZeitArtsResponse | void;
  zeitUnterarts: IZeitUnterartsResponse | void;
  objekte: IObjekteResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class ProjektePageResolver implements Resolve<IProjektePageResolverResult> {
  get projekteFilterService(): ProjekteFilterService {
    return this.injector.get(ProjekteFilterService);
  }

  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private projekte: ProjekteService,
    private zeitArts: ZeitArtsService,
    private zeitUnterarts: ZeitUnterartsService,
    private objekte: ObjekteService,
    private postProjekt: PostProjektCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IProjektePageResolverResult> {
    this.loading.resolving();
    const requests = {
      projekte: {} as IProjekteRequest,
      zeitArts: {} as IZeitArtsRequest,
      zeitUnterarts: {} as IZeitUnterartsRequest,
      objekte: {} as IObjekteRequest,
    };
    /* << custom    */
    requests.projekte = this.projekte.payload$.getValue() || {};
    /*    custom >> */
    const log = (error: any) => console.error(error);
    const [projekte, zeitArts, zeitUnterarts, objekte] = await Promise.all([
      this.projekte.request(requests.projekte).catch(log),
      this.zeitArts.request(requests.zeitArts).catch(log),
      this.zeitUnterarts.request(requests.zeitUnterarts).catch(log),
      this.objekte.request(requests.objekte).catch(log),
      this.postProjekt.prepare().catch(log),
    ]);
    this.loading.resolved();
    return {
      projekte,
      zeitArts,
      zeitUnterarts,
      objekte,
    };
  }
}
