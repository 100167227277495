import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostFeldCommandServiceModule } from '..';
import { PostFeldCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFeldCommandServiceModule
  ],
  declarations: [PostFeldCommandComponent],
  exports: [PostFeldCommandComponent],

})
export class PostFeldCommandComponentModule { }
