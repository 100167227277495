import { IFilter, IFilterConfig } from 'pbc.types';
import { IBesichtigung } from './';

export interface IBesichtigungFilter extends IFilter<IBesichtigung> {}

export const BesichtigungFilterConfig: IFilterConfig<IBesichtigung> = [];

export type IBesichtigungField =
  | 'besichtigung.id'
  | 'besichtigung.projekt'
  | 'besichtigung.tour'
  | 'besichtigung.gutachten'
  | 'besichtigung.objekt'
  | 'besichtigung.besichtigungsStatus'
  | 'besichtigung.mitarbeiter'
  | 'besichtigung.kommentar'
  | 'besichtigung.rueckfahrt'
  | 'besichtigung.von'
  | 'besichtigung.bis'
  | 'besichtigung.start'
  | 'besichtigung.ende'
  | 'besichtigung.aufenthalt'
  | 'besichtigung.fahrtzeit'
  | 'besichtigung.fahrtstrecke'
  | 'besichtigung.fahrtzeitOhneVerkehr'
  // 'besichtigung.kosten'|
  | 'besichtigung.abgerechnet'
  | 'besichtigung.route';
