import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { IReisekostenabrechnungResponse, ReisekostenabrechnungQueryFilterConfig } from 'fa-kt.types';
import { ReisekostenabrechnungService } from '../service';

@Component({
  selector: 'fa-kt-reisekostenabrechnung-filter',
  templateUrl: './reisekostenabrechnung-filter.component.html',
  styleUrls: ['./reisekostenabrechnung-filter.component.css'],
})
export class ReisekostenabrechnungFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public ReisekostenabrechnungFilterConfig: IFilterConfig<IReisekostenabrechnungResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public reisekostenabrechnung: ReisekostenabrechnungService,
  ) {
    this.ReisekostenabrechnungFilterConfig = ReisekostenabrechnungQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.ReisekostenabrechnungFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.ReisekostenabrechnungFilterConfig[index].selections = shapes;
  }
}
