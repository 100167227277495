import { NgModule } from '@angular/core';

import {
  DeleteOrtKategorieCommandServiceModule,
  DeleteOrtKategorieCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteOrtKategorieCommandServiceModule,
    DeleteOrtKategorieCommandComponentModule
  ],
  exports: [
    DeleteOrtKategorieCommandServiceModule,
    DeleteOrtKategorieCommandComponentModule
  ]
})
export class DeleteOrtKategorieCommandModule { }
