import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SettingsQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IKundenArtsQueryFilter, IKundenArtsQueryShapes, IKundenArtsQuerySorter, IKundenArtsRequest, IKundenArtsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class KundenArtsService extends SettingsQueryService<IKundenArtsRequest, IKundenArtsResponse, IKundenArtsQueryShapes, IKundenArtsQueryFilter, IKundenArtsQuerySorter> {
  contentKey: keyof IKundenArtsResponse = 'kundenArts';
  route = 'kunden/settings/kunden-arts';
  override dependencies = ['kunden/written-kunden-art', 'kunden/removed-kunden-art', 'kunden/written-kunden-gruppe', 'kunden/removed-kunden-gruppe'];

  async getKundenArtsKundenGruppe(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.kundenArtsKundenGruppe.pipe(first()).subscribe((kundenArtsKundenGruppe) => resolve(kundenArtsKundenGruppe.find((a) => a.value === value)));
    });
  }

  get kundenArtsKundenGruppe(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kundenArts.kundenGruppe'] as ISelection[]) : [])));
  }
}
