import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostTourCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostTourCommandService],
  exports: []
})
export class PostTourCommandServiceModule { }
