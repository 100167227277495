import { Component } from '@angular/core';
import { VorlagenService } from '../service';

@Component({
  selector: 'fa-kt-saved-vorlagen-filters',
  templateUrl: './saved-vorlagen-filters.component.html',
  styleUrls: ['./saved-vorlagen-filters.component.css'],
})
export class SavedVorlagenFiltersComponent {
  constructor(public vorlagen: VorlagenService) {}
}
