import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { ProjekteQuerySorterConfig } from 'fa-kt.types';
import { orderBy } from 'lodash';
import { ProjekteService } from '../service';

@Component({
  selector: 'fa-kt-projekte-sorter',
  templateUrl: './projekte-sorter.component.html',
  styleUrls: ['./projekte-sorter.component.css'],
})
export class ProjekteSorterComponent {
  public ProjekteSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public projekte: ProjekteService,
  ) {
    this.ProjekteSorterConfig = ProjekteQuerySorterConfig.filter((sorter) =>
      [
        'projekt.aktiv',
        'projekt.projektZaehler',
        'projekt.bezeichnung',
        'projekt.nummer',
        'projekt.projektArt',
        'projekt.projektStatus',
        'projekt.standort',
        'projekt.beauftragung',
        'projekt.deadline',
        'projekt.keineBesichtigungErforderlich',
        'projekt.besichtigungen',
        'projekt.gutachten',
        'projekt.pruefung',
        'projekt.kunde',
        'projekt.abteilung',
        'projekt.fond',
        'projekt.bank',
        'projekt.fortschritt',
        'gutachten.objektArt',
        'gutachten.bewertungsStatus',
        'projekt.bewertungsAnlass',
        'besichtigungen.besichtigungsStatus',
        'besichtigungen.von',
        'projekt.kostenNachStunden',
        'projekt.kosten',
        'projekt.kostenBeglichen',
      ].includes(sorter.key),
    ).map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    this.ProjekteSorterConfig = orderBy(this.ProjekteSorterConfig, 'title');
    /*    update >> */
  }
}
