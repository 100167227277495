import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { BasePageResolver } from 'pbc.angular';

import { IFormulareRequest, IFormulareResponse, IMitarbeiterRequest, IMitarbeiterResponse, IOffeneGutachtenRequest, IOffeneGutachtenResponse, ITourenRequest, ITourenResponse } from 'fa-kt.types';
import { FormulareService, PostDurchgangCommandService } from '../../../formulare';
import { KundenService } from '../../../kunden';
import { ObjektArtsService } from '../../../objekte';
import { MitarbeiterService, StandortsService } from '../../../personen';
import { ProjektArtsService } from '../../../projekte';
import { PostBesichtigungCommandService, PostStartTourCommandService, PostTourCommandService } from '../../commands';
import { OffeneGutachtenService, TourenService } from '../../querys';

export interface ITourenPageResolverResult {
  touren?: ITourenResponse | void;
  mitarbeiter?: IMitarbeiterResponse | void;
  offeneGutachten?: IOffeneGutachtenResponse | void;
  formulare?: IFormulareResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class TourenPageResolver extends BasePageResolver<ITourenPageResolverResult> {
  constructor(
    private touren: TourenService,
    private mitarbeiter: MitarbeiterService,
    private offeneGutachten: OffeneGutachtenService,
    private formulare: FormulareService,
    private postTour: PostTourCommandService,
    private postDurchgang: PostDurchgangCommandService,
    private postStartTour: PostStartTourCommandService,
    private postBesichtigung: PostBesichtigungCommandService,
    private kunden: KundenService,
    private objektArten: ObjektArtsService,
    private projektArten: ProjektArtsService,
    private standorts: StandortsService,
  ) {
    super();
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ITourenPageResolverResult> {
    this.loading.resolving();
    const requests = {
      touren: { inklusiveInaktive: false } as ITourenRequest,
      mitarbeiter: {} as IMitarbeiterRequest,
      offeneGutachten: {} as IOffeneGutachtenRequest,
      formulare: {} as IFormulareRequest,
    };

    const log = (error: any) => console.error(error);
    const [touren, mitarbeiter, offeneGutachten, formulare] = await Promise.all([
      this.touren.request(requests.touren).catch(log),
      this.mitarbeiter.request(requests.mitarbeiter).catch(log),
      this.offeneGutachten.request(requests.offeneGutachten).catch(log),
      this.formulare.request(requests.formulare).catch(log),
      this.kunden.request({}).catch(console.error),
      this.objektArten.request({}).catch(console.error),
      this.projektArten.request({}).catch(console.error),
      this.postStartTour.prepare().catch(console.error),
      this.postTour.prepare().catch(console.error),
      this.postBesichtigung.prepare().catch(console.error),
      this.postDurchgang.prepare().catch(console.error),
      this.standorts.request({}).catch(console.error),
    ]);
    this.loading.resolved();
    return {
      touren,
      mitarbeiter,
      offeneGutachten,
      formulare,
    };
  }
}
