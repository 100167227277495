import { Component } from '@angular/core';
import { DurchsuchenService } from '../service';

@Component({
  selector: 'fa-kt-saved-durchsuchen-filters',
  templateUrl: './saved-durchsuchen-filters.component.html',
  styleUrls: ['./saved-durchsuchen-filters.component.css'],
})
export class SavedDurchsuchenFiltersComponent {
  constructor(public durchsuchen: DurchsuchenService) {}
}
