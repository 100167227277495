import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostAbteilungCommandShapes, IPostAbteilungRequest, IPostAbteilungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostAbteilungCommandService extends PostCommandService<IPostAbteilungRequest, IPostAbteilungResponse, IPostAbteilungCommandShapes> {
  readonly route = 'kunden/abteilung';
}
