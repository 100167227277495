import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DateiArtsServiceModule } from '..';
import { DateiArtsFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DateiArtsServiceModule
  ],
  declarations: [DateiArtsFilterComponent],
  exports: [DateiArtsFilterComponent],

})
export class DateiArtsFilterComponentModule { }
