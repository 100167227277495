<!---
  <div formGroupName="zahlungseingang">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="projekt">{{ command.Inputs.PROJEKT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektErrorTip">
        <ng-template #ProjektErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="projekt" id="projekt" [shapes]="postZahlungseingang.zahlungseingangProjekt | async" [placeholder]="command.Inputs.PROJEKT.placeholder" cdkFocusInitial required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
        <ng-template #KundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="kunde" id="kunde" [shapes]="postZahlungseingang.zahlungseingangKunde | async" [placeholder]="command.Inputs.KUNDE.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="rechnung">{{ command.Inputs.RECHNUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RechnungErrorTip">
        <ng-template #RechnungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.RECHNUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="rechnung" id="rechnung" [shapes]="postZahlungseingang.zahlungseingangRechnung | async" [placeholder]="command.Inputs.RECHNUNG.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="summe">{{ command.Inputs.SUMME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SummeErrorTip">
        <ng-template #SummeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SUMME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="summe" id="summe" [nzPlaceHolder]="command.Inputs.SUMME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datum">{{ command.Inputs.DATUM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
        <ng-template #DatumErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATUM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="datum" id="datum"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <div formGroupName="zahlungseingang">
      <input class="cdk-visually-hidden" formControlName="id" id="id" />
      <input class="cdk-visually-hidden" formControlName="projekt" id="projekt" />
      <input class="cdk-visually-hidden" formControlName="kunde" id="kunde" />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="rechnung">{{ command.Inputs.RECHNUNG.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RechnungErrorTip">
          <ng-template #RechnungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.RECHNUNG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="rechnung"
            id="rechnung"
            [shapes]="selection$ | async"
            [filter]="projekt"
            [placeholder]="command.Inputs.RECHNUNG.placeholder"
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="summe">{{ command.Inputs.SUMME.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SummeErrorTip">
          <ng-template #SummeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SUMME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="summe" id="summe" [placeholder]="command.Inputs.SUMME.placeholder" /> €
        </nz-form-control>
      </nz-form-item>
      <br />
      <br />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datum">{{ command.Inputs.DATUM.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
          <ng-template #DatumErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATUM.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="datum" id="datum" nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            Zahlungseingang speichern
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
