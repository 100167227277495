import { NgModule } from '@angular/core';
import {
  ProjektArtsFilterComponentModule,
  ProjektArtsSorterComponentModule,
  ProjektArtsListComponentModule
} from '.';

@NgModule({
  imports: [
    ProjektArtsFilterComponentModule,
    ProjektArtsSorterComponentModule,
    ProjektArtsListComponentModule
  ],
  exports: [
    ProjektArtsFilterComponentModule,
    ProjektArtsSorterComponentModule,
    ProjektArtsListComponentModule
  ],
})
export class ProjektArtsQueryModule { }