import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { PostImportCommandModule } from '../../commands';

import { ImportComponent } from '.';

@NgModule({
  imports: [LIB_CommonModule, PostImportCommandModule],
  declarations: [ImportComponent],
  exports: [ImportComponent],
})
export class ImportComponentModule {}
