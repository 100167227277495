import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektNutzungsServiceModule } from '..';
import { ObjektNutzungsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektNutzungsServiceModule
  ],
  declarations: [ObjektNutzungsListComponent],
  exports: [ObjektNutzungsListComponent],

})
export class ObjektNutzungsListComponentModule { }
