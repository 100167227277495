import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostFormularCommandShapes, IPostFormularRequest, IPostFormularResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostFormularCommandService extends PostCommandService<IPostFormularRequest, IPostFormularResponse, IPostFormularCommandShapes> {
  readonly route = 'formulare/formular';
  override dependencies = [
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
  ];

  getFormularBewertungsAnlaesse(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.bewertungsAnlaesse']?.find((v) => v.value === value);
  }

  get formularBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.bewertungsAnlaesse'] as ISelection[]) : [])));
  }

  getFormularOhneFelder(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.ohneFelder']?.find((v) => v.value === value);
  }

  get formularOhneFelder(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.ohneFelder'] as ISelection[]) : [])));
  }

  getFormularKunden(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.kunden']?.find((v) => v.value === value);
  }

  get formularKunden(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.kunden'] as ISelection[]) : [])));
  }

  getFormularKundenArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.kundenArten']?.find((v) => v.value === value);
  }

  get formularKundenArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.kundenArten'] as ISelection[]) : [])));
  }

  getFormularObjektArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.objektArten']?.find((v) => v.value === value);
  }

  get formularObjektArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.objektArten'] as ISelection[]) : [])));
  }
}
