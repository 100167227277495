import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface ITourQuerySorter extends ISorter {
  'tour.id'?: ISorterExpression;
  'tour.tourStatus'?: ISorterExpression;
  'tour.mitarbeiter'?: ISorterExpression;
  'tour.am'?: ISorterExpression;
  'tour.startIstPrivatAdresse'?: ISorterExpression;
  'tour.endeIstPrivatAdresse'?: ISorterExpression;
  'tour.start'?: ISorterExpression;
  'tour.ende'?: ISorterExpression;
  'tour.strecke'?: ISorterExpression;
  'tour.kosten'?: ISorterExpression;
  'tour.abgerechnet'?: ISorterExpression;
  'tour.kommentar'?: ISorterExpression;
  'besichtigungen.id'?: ISorterExpression;
  'besichtigungen.projekt'?: ISorterExpression;
  'besichtigungen.tour'?: ISorterExpression;
  'besichtigungen.gutachten'?: ISorterExpression;
  'besichtigungen.objekt'?: ISorterExpression;
  'besichtigungen.besichtigungsStatus'?: ISorterExpression;
  'besichtigungen.mitarbeiter'?: ISorterExpression;
  'besichtigungen.kommentar'?: ISorterExpression;
  'besichtigungen.rueckfahrt'?: ISorterExpression;
  'besichtigungen.von'?: ISorterExpression;
  'besichtigungen.bis'?: ISorterExpression;
  'besichtigungen.start'?: ISorterExpression;
  'besichtigungen.ende'?: ISorterExpression;
  'besichtigungen.aufenthalt'?: ISorterExpression;
  'besichtigungen.fahrtzeit'?: ISorterExpression;
  'besichtigungen.fahrtstrecke'?: ISorterExpression;
  'besichtigungen.fahrtzeitOhneVerkehr'?: ISorterExpression;
  'besichtigungen.kosten'?: ISorterExpression;
  'besichtigungen.abgerechnet'?: ISorterExpression;
  'besichtigungen.route'?: ISorterExpression;
  'durchgaenge.id'?: ISorterExpression;
  'durchgaenge.formular'?: ISorterExpression;
  'durchgaenge.gutachten'?: ISorterExpression;
  'durchgaenge.projekt'?: ISorterExpression;
  'durchgaenge.besichtigung'?: ISorterExpression;
  'reisekostenerstattung.id'?: ISorterExpression;
  'reisekostenerstattung.mitarbeiter'?: ISorterExpression;
  'reisekostenerstattung.touren'?: ISorterExpression;
  'reisekostenerstattung.reisekostenabrechnungStatus'?: ISorterExpression;
  'reisekostenerstattung.tagesgeldAcht'?: ISorterExpression;
  'reisekostenerstattung.tagesgeldVierundzwanzig'?: ISorterExpression;
  'reisekostenerstattung.anUndAbreisetag'?: ISorterExpression;
  'reisekostenerstattung.fruehstuecke'?: ISorterExpression;
  'reisekostenerstattung.mittagessen'?: ISorterExpression;
  'reisekostenerstattung.abendessen'?: ISorterExpression;
  'reisekostenerstattung.uebernachtungspauschale'?: ISorterExpression;
  'reisekostenerstattung.normaleKm'?: ISorterExpression;
  'reisekostenerstattung.mitnahmeentschaedigung'?: ISorterExpression;
  'reisekostenerstattung.sonstigePositionen'?: ISorterExpression;
  'reisekostenerstattung.sonstigeSumme'?: ISorterExpression;
  'reisekostenerstattung.summe'?: ISorterExpression;
  'reisekostenerstattung.antrag'?: ISorterExpression;
}

export const TourQuerySorterConfig: ISorterConfig = [
  SorterConfig('tour.id', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ID.title'), 
  SorterConfig('tour.tourStatus', 'BESICHTIGUNGEN.Models.TOUR.Propertys.TOUR_STATUS.title'), 
  SorterConfig('tour.mitarbeiter', 'BESICHTIGUNGEN.Models.TOUR.Propertys.MITARBEITER.title'), 
  SorterConfig('tour.am', 'BESICHTIGUNGEN.Models.TOUR.Propertys.AM.title'), 
  SorterConfig('tour.startIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START_IST_PRIVAT_ADRESSE.title'), 
  SorterConfig('tour.endeIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE_IST_PRIVAT_ADRESSE.title'), 
  SorterConfig('tour.start', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START.title'), 
  SorterConfig('tour.ende', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE.title'), 
  SorterConfig('tour.strecke', 'BESICHTIGUNGEN.Models.TOUR.Propertys.STRECKE.title'), 
  SorterConfig('tour.kosten', 'BESICHTIGUNGEN.Models.TOUR.Propertys.KOSTEN.title'), 
  SorterConfig('tour.abgerechnet', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ABGERECHNET.title'), 
  SorterConfig('tour.kommentar', 'BESICHTIGUNGEN.Models.TOUR.Propertys.KOMMENTAR.title'), 
  SorterConfig('besichtigungen.id', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ID.title'), 
  SorterConfig('besichtigungen.projekt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.PROJEKT.title'), 
  SorterConfig('besichtigungen.tour', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.TOUR.title'), 
  SorterConfig('besichtigungen.gutachten', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.GUTACHTEN.title'), 
  SorterConfig('besichtigungen.objekt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.OBJEKT.title'), 
  SorterConfig('besichtigungen.besichtigungsStatus', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BESICHTIGUNGS_STATUS.title'), 
  SorterConfig('besichtigungen.mitarbeiter', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.MITARBEITER.title'), 
  SorterConfig('besichtigungen.kommentar', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.KOMMENTAR.title'), 
  SorterConfig('besichtigungen.rueckfahrt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.RUECKFAHRT.title'), 
  SorterConfig('besichtigungen.von', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.VON.title'), 
  SorterConfig('besichtigungen.bis', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BIS.title'), 
  SorterConfig('besichtigungen.start', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.START.title'), 
  SorterConfig('besichtigungen.ende', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ENDE.title'), 
  SorterConfig('besichtigungen.aufenthalt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.AUFENTHALT.title'), 
  SorterConfig('besichtigungen.fahrtzeit', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT.title'), 
  SorterConfig('besichtigungen.fahrtstrecke', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTSTRECKE.title'), 
  SorterConfig('besichtigungen.fahrtzeitOhneVerkehr', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT_OHNE_VERKEHR.title'), 
  SorterConfig('besichtigungen.kosten', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.KOSTEN.title'), 
  SorterConfig('besichtigungen.abgerechnet', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ABGERECHNET.title'), 
  SorterConfig('besichtigungen.route', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ROUTE.title'), 
  SorterConfig('durchgaenge.id', 'FORMULARE.Models.DURCHGANG.Propertys.ID.title'), 
  SorterConfig('durchgaenge.formular', 'FORMULARE.Models.DURCHGANG.Propertys.FORMULAR.title'), 
  SorterConfig('durchgaenge.gutachten', 'FORMULARE.Models.DURCHGANG.Propertys.GUTACHTEN.title'), 
  SorterConfig('durchgaenge.projekt', 'FORMULARE.Models.DURCHGANG.Propertys.PROJEKT.title'), 
  SorterConfig('durchgaenge.besichtigung', 'FORMULARE.Models.DURCHGANG.Propertys.BESICHTIGUNG.title'), 
  SorterConfig('reisekostenerstattung.id', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ID.title'), 
  SorterConfig('reisekostenerstattung.mitarbeiter', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITARBEITER.title'), 
  SorterConfig('reisekostenerstattung.touren', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TOUREN.title'), 
  SorterConfig('reisekostenerstattung.reisekostenabrechnungStatus', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.REISEKOSTENABRECHNUNG_STATUS.title'), 
  SorterConfig('reisekostenerstattung.tagesgeldAcht', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_ACHT.title'), 
  SorterConfig('reisekostenerstattung.tagesgeldVierundzwanzig', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_VIERUNDZWANZIG.title'), 
  SorterConfig('reisekostenerstattung.anUndAbreisetag', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.AN_UND_ABREISETAG.title'), 
  SorterConfig('reisekostenerstattung.fruehstuecke', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.FRUEHSTUECKE.title'), 
  SorterConfig('reisekostenerstattung.mittagessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITTAGESSEN.title'), 
  SorterConfig('reisekostenerstattung.abendessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ABENDESSEN.title'), 
  SorterConfig('reisekostenerstattung.uebernachtungspauschale', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.UEBERNACHTUNGSPAUSCHALE.title'), 
  SorterConfig('reisekostenerstattung.normaleKm', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.NORMALE_KM.title'), 
  SorterConfig('reisekostenerstattung.mitnahmeentschaedigung', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITNAHMEENTSCHAEDIGUNG.title'), 
  SorterConfig('reisekostenerstattung.sonstigePositionen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SONSTIGE_POSITIONEN.title'), 
  SorterConfig('reisekostenerstattung.sonstigeSumme', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SONSTIGE_SUMME.title'), 
  SorterConfig('reisekostenerstattung.summe', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SUMME.title'), 
  SorterConfig('reisekostenerstattung.antrag', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ANTRAG.title'), 
];
