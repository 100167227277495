import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostRechnungRunterzaehlenCommandShapes, IPostRechnungRunterzaehlenRequest, IPostRechnungRunterzaehlenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostRechnungRunterzaehlenCommandService extends PostCommandService<IPostRechnungRunterzaehlenRequest, IPostRechnungRunterzaehlenResponse, IPostRechnungRunterzaehlenCommandShapes> {
  readonly route = 'finanzen/rechnung-runterzaehlen';
}
