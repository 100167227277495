import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { KrankheitenQuerySorterConfig } from 'fa-kt.types';
import { KrankheitenService } from '.';

@Component({
  selector: 'fa-kt-krankheiten-sorter',
  template: `<pbc-sorter [config]="KrankheitenSorterConfig" [sorter]="krankheiten.sorter$ | async" (sorterChange)="krankheiten.sorter$.next($event)"></pbc-sorter>`,
})
export class KrankheitenSorterComponent {
  public KrankheitenSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public krankheiten: KrankheitenService,
  ) {
    this.KrankheitenSorterConfig = KrankheitenQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }
}
