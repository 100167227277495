import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostProjekteHochzaehlenCommandServiceModule } from '..';
import { PostProjekteHochzaehlenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostProjekteHochzaehlenCommandServiceModule
  ],
  declarations: [PostProjekteHochzaehlenCommandComponent],
  exports: [PostProjekteHochzaehlenCommandComponent],

})
export class PostProjekteHochzaehlenCommandComponentModule { }
