import { NgModule } from '@angular/core';

import {
  PostKundenFinanzenCommandServiceModule,
  PostKundenFinanzenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostKundenFinanzenCommandServiceModule,
    PostKundenFinanzenCommandComponentModule
  ],
  exports: [
    PostKundenFinanzenCommandServiceModule,
    PostKundenFinanzenCommandComponentModule
  ]
})
export class PostKundenFinanzenCommandModule { }
