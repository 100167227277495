import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IGutachtenStatiQueryFilter, IGutachtenStatiQueryShapes, IGutachtenStatiQuerySorter, IGutachtenStatiRequest, IGutachtenStatiResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class GutachtenStatiService extends SettingsQueryService<IGutachtenStatiRequest, IGutachtenStatiResponse, IGutachtenStatiQueryShapes, IGutachtenStatiQueryFilter, IGutachtenStatiQuerySorter> {
  contentKey: keyof IGutachtenStatiResponse = 'gutachtenStati';
  route = 'gutachten/settings/gutachten-stati';
  override dependencies = ['gutachten/written-gutachten-status', 'gutachten/removed-gutachten-status'];
}
