import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitUnterartsListComponent } from '.';
import { ZeitUnterartsServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitUnterartsServiceModule
  ],
  declarations: [ZeitUnterartsListComponent],
  exports: [ZeitUnterartsListComponent],

})
export class ZeitUnterartsListComponentModule { }
