<!--

  <fa-kt-post-objekt [style.width]="'100%'" [value]="$postObjekt | async" (finished)="finishedPostObjekt($event)"></fa-kt-post-objekt>
    
  <fa-kt-delete-objekt [style.width]="'100%'" [value]="$deleteObjekt | async" (finished)="finishedDeleteObjekt($event)"></fa-kt-delete-objekt>
    


-->
<!--- << custom    -->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" nzBackIcon (nzBack)="closed.emit()">
  <ng-template #PageTitle>
    {{ ($postObjekt | async)?.objekt?.name }}
  </ng-template>
  <ng-template #PageSubtitle> {{ !($postObjekt | async)?.objekt ? 'Neues' : '' }} Objekt </ng-template>
  <nz-page-header-extra>
    <nz-space>
      <div *nzSpaceItem>
        <fa-kt-delete-objekt [style.width]="'100%'" [value]="$deleteObjekt | async" (finished)="finishedDeleteObjekt($event)"></fa-kt-delete-objekt>
      </div>
    </nz-space>
  </nz-page-header-extra>
  <nz-page-header-content> </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <fa-kt-post-objekt [style.width]="'100%'" [value]="$postObjekt | async" (finished)="finishedPostObjekt($event)"></fa-kt-post-objekt>
  </nz-row>
</nz-content>

<!---    custom >> -->
