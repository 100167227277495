import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IBereich } from 'fa-kt.types';
import { BereichsService } from '../service';

@Component({
  selector: 'fa-kt-bereichs-list',
  templateUrl: './bereichs-list.component.html',
  styleUrls: ['./bereichs-list.component.css'],
})
export class BereichsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IBereich> = new EventEmitter<IBereich>();

  constructor(public bereichs: BereichsService) {}

  ngOnDestroy() {}
}
