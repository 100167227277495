import { IFilter, IFilterConfig } from "pbc.types";

import { IReisekostenabrechnungStatus } from '.';

export interface IReisekostenabrechnungStatusFilter extends IFilter<IReisekostenabrechnungStatus> {}

export const ReisekostenabrechnungStatusFilterConfig: IFilterConfig<IReisekostenabrechnungStatus> = [];

export type IReisekostenabrechnungStatusField =
  'reisekostenabrechnungStatus.id'|
  'reisekostenabrechnungStatus.name'|
  'reisekostenabrechnungStatus.kommentar'
;
