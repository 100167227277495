import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IKonstanteSorter extends ISorter {
  id?: ISorterExpression;
  art?: ISorterExpression;
  inhalt?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const KonstanteSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('art', 'Art'),
  SorterConfig('inhalt', 'Inhalt'),
  SorterConfig('kommentar', 'Kommentar')
];
