import { BooleanFilterConfig, IBooleanFilter, IFilter, IFilterConfig, IShape, ISingleReferenceFilter } from 'pbc.types';

import { IRolle } from 'fa-kt.types';
import { IRollesResponse } from '.';

export interface IRollesQueryFilter extends IFilter<IRollesResponse> {
  'rolles.finanzen'?: ISingleReferenceFilter<IRolle>;

  'rolles.isAdmin'?: IBooleanFilter;
}

export const RollesQueryFilterConfig: IFilterConfig<IRollesResponse> = [
  BooleanFilterConfig('rolles.finanzen', 'PERSONEN.Models.ROLLE.Propertys.FINANZEN.title'),

  BooleanFilterConfig('rolles.isAdmin', 'Administrator'),
];

export interface IRollesQueryShapes extends IShape {}

export type IRollesQueryField = 'rolles.id' | 'rolles.name' | 'rolles.beschreibung' | 'rolles.finanzen' | '';
