<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="formulare.search$.next($event)" [search]="formulare.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-formulare-filter [style.width]="'100%'"></fa-kt-formulare-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-formulare-sorter [style.width]="'100%'"></fa-kt-formulare-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-formulare-list [style.width]="'100%'" (select)="setFormulare($event)" [loading]="$loading | async"></fa-kt-formulare-list>




    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="felder" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="feld" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="formular" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <button *nzSpaceItem nz-button nzType="primary" (click)="setFormulare('new')">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Formular hinzufügen
      </button>
      <button *nzSpaceItem nz-button nzType="default" (click)="navigateToFelder()">{{ _felder.emoji }} {{ _felder.title }}</button>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <nz-row>
      <nz-col nzSpan="24">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="formulare.search$.next($event)" [search]="formulare.search$ | async"></pbc-search-bar>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-formulare-filter [style.width]="'100%'"></fa-kt-formulare-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-formulare-sorter [style.width]="'100%'"></fa-kt-formulare-sorter>
      </nz-col>
    </nz-row>
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <fa-kt-formulare-list [style.width]="'100%'" (select)="setFormulare($event)" [loading]="$loading | async"></fa-kt-formulare-list>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
