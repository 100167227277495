import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IBesichtigungenResponse } from './';

export interface IBesichtigungenQueryFilter extends IFilter<IBesichtigungenResponse> {}

export const BesichtigungenQueryFilterConfig: IFilterConfig<IBesichtigungenResponse> = [];

export interface IBesichtigungenQueryShapes extends IShape {}

export type IBesichtigungenQueryField = '';
