import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostZeitArtCommandShapes, IPostZeitArtRequest, IPostZeitArtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostZeitArtCommandService extends PostCommandService<IPostZeitArtRequest, IPostZeitArtResponse, IPostZeitArtCommandShapes> {
  readonly route = 'zeiten/settings/zeit-art';
}
