import { IFilter, IFilterConfig } from "pbc.types";

import { IKonstante } from './';

export interface IKonstanteFilter extends IFilter<IKonstante> {}

export const KonstanteFilterConfig: IFilterConfig<IKonstante> = [];

export type IKonstanteField =
  'konstante.id'|
  'konstante.art'|
  'konstante.inhalt'|
  'konstante.kommentar'
;
