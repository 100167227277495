import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FileService } from './services/file.service';

@NgModule({
  providers: [FileService],
  imports: [HttpClientModule, CommonModule],
})
export class LIB_FilesModule {}
