import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostHonorarVereinbarungCommandShapes, IPostHonorarVereinbarungRequest, IPostHonorarVereinbarungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostHonorarVereinbarungCommandService extends PostCommandService<IPostHonorarVereinbarungRequest, IPostHonorarVereinbarungResponse, IPostHonorarVereinbarungCommandShapes> {
  readonly route = 'finanzen/honorar-vereinbarung';
}
