import { Injectable } from '@angular/core';

import { DetailQueryService } from 'pbc.angular';

import { IKrankheitQueryShapes, IKrankheitRequest, IKrankheitResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class KrankheitService extends DetailQueryService<IKrankheitRequest, IKrankheitResponse, IKrankheitQueryShapes> {
  route = 'zeiten/krankheit';
  override dependencies = ['zeiten/krankheit-gespeichert', 'zeiten/krankheit-entfernt'];
}
