import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { UrlaubeFilterComponent } from './urlaube-filter.component';
import { UrlaubePresetsComponent } from './urlaube-presets.component';
import { UrlaubeSorterComponent } from './urlaube-sorter.component';
import { UrlaubeService } from './urlaube.service';

@NgModule({
  imports: [LIB_CommonModule],
  declarations: [UrlaubePresetsComponent, UrlaubeSorterComponent, UrlaubeFilterComponent],
  providers: [UrlaubeService],
  exports: [UrlaubePresetsComponent, UrlaubeSorterComponent, UrlaubeFilterComponent],
})
export class UrlaubeQueryModule {}
