import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostVorlageCommandServiceModule } from '..';
import { PostVorlageCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostVorlageCommandServiceModule
  ],
  declarations: [PostVorlageCommandComponent],
  exports: [PostVorlageCommandComponent],

})
export class PostVorlageCommandComponentModule { }
