import { NgModule } from '@angular/core';

import { DeleteTemplateCommandModule, PostTemplateCommandModule, TemplatesQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostTemplateCommandModule, DeleteTemplateCommandModule, TemplatesQueryModule],
  exports: [PostTemplateCommandModule, DeleteTemplateCommandModule, TemplatesQueryModule],
})
export class TemplatesSettingsModule {}
