import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { IBesichtigungsStatus } from 'fa-kt.types';
import { BaseComponent, IPageState, ISitemapModel, ISitemapPage } from 'pbc.angular';
import { BesichtigungsStatiService } from '../query';

@Component({
  selector: 'fa-kt-besichtigungs-stati',
  templateUrl: './besichtigungs-stati.page.html',
  styleUrls: ['./besichtigungs-stati.page.css'],
})
export class BesichtigungsStatiPage extends BaseComponent implements OnInit {
  readonly page: ISitemapPage;
  readonly model: ISitemapModel;

  readonly $state: BehaviorSubject<IPageState> = new BehaviorSubject<IPageState>(null);
  readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly $besichtigungsStatus: BehaviorSubject<{ besichtigungsStatus: {} | IBesichtigungsStatus } | null> = new BehaviorSubject<{ besichtigungsStatus: {} | IBesichtigungsStatus } | null>(null);

  constructor(public besichtigungsStati: BesichtigungsStatiService) {
    super();
    this.page = this.sitemap['BESICHTIGUNGEN'].Settings['BESICHTIGUNGS_STATI'];
    this.model = this.sitemap['BESICHTIGUNGEN'].Models['BESICHTIGUNGS_STATUS'];
  }

  override ngOnInit() {
    super.ngOnInit();
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.actions.subscribe({ key: 'Zurück', action: () => window.history.back() });
    this.actions.subscribe({ key: `${this.model.single} erstellen`, action: () => this.create() });
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((params: Params) => this.$state.next(params['id']));
    this.$state.subscribe((state: IPageState) => this.sync(state));
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.$loading.next(false);
    this.actions.unsubscribe('Zurück');
    this.actions.unsubscribe(`${this.model.single} erstellen`);
  }

  sync(state: IPageState) {
    this.$loading.next(true);
    if (state === 'new') {
      this.$besichtigungsStatus.next({ besichtigungsStatus: {} });
    } else if (state) {
      const besichtigungsStati = this.besichtigungsStati.response$.getValue();
      const besichtigungsStatus = besichtigungsStati ? besichtigungsStati.besichtigungsStati.find((bs: IBesichtigungsStatus) => bs.id === state) : null;
      this.$besichtigungsStatus.next(besichtigungsStatus ? { besichtigungsStatus } : null);
    } else {
      this.$besichtigungsStatus.next(null);
    }
    this.$loading.next(false);
  }

  async create() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id: 'new' },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async set(id: string) {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { id },
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  async reset() {
    this.$loading.next(true);
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
    this.$loading.next(false);
  }

  back() {
    window.history.back();
  }
}
