import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFeldOptionSorter extends ISorter {
  id?: ISorterExpression;
  feld?: ISorterExpression;
  option?: ISorterExpression;
  akkusativ?: ISorterExpression;
  nominativ?: ISorterExpression;
  dativ?: ISorterExpression;
  genitiv?: ISorterExpression;
  standard?: ISorterExpression;
}

export const FeldOptionSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('feld', 'Feld'),
  SorterConfig('option', 'Option'),
  SorterConfig('akkusativ', 'Akkusativ'),
  SorterConfig('nominativ', 'Nominativ'),
  SorterConfig('dativ', 'Dativ'),
  SorterConfig('genitiv', 'Genitiv'),
  SorterConfig('standard', 'Standard')
];
