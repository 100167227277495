import { NgModule } from '@angular/core';

import { LIB_CommonModule } from 'pbc.angular';

import { PostFormularCommandServiceModule } from '..';
import { FormulareComponentsModule } from '../../../components';
import { PostFormularCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFormularCommandServiceModule,
    FormulareComponentsModule,
  ],
  declarations: [PostFormularCommandComponent],
  exports: [PostFormularCommandComponent],

})
export class PostFormularCommandComponentModule { }
