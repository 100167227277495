import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IBesichtigungsStatus } from 'fa-kt.types';
import { PostBesichtigungsStatusCommandService } from '../../commands';
import { BesichtigungsStatiService } from '../service';

@Component({
  selector: 'fa-kt-besichtigungs-stati-list',
  templateUrl: './besichtigungs-stati-list.component.html',
  styleUrls: ['./besichtigungs-stati-list.component.css'],
})
export class BesichtigungsStatiListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IBesichtigungsStatus> = new EventEmitter<IBesichtigungsStatus>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public besichtigungsStati: BesichtigungsStatiService,
    public besichtigungsStatusPost: PostBesichtigungsStatusCommandService,
  ) {
    this.sortable$ = this.besichtigungsStati.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const besichtigungsStati = this.besichtigungsStati.result$.getValue();
    if (besichtigungsStati && besichtigungsStati.besichtigungsStati) {
      if ($event) {
        moveItemInArray(besichtigungsStati.besichtigungsStati, $event.previousIndex, $event.currentIndex);
      }
      besichtigungsStati.besichtigungsStati = besichtigungsStati.besichtigungsStati.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.besichtigungsStatusPost.request({ besichtigungsStatus: besichtigungsStati.besichtigungsStati });
    }
  }
}
