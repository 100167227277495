import { NgModule } from '@angular/core';
import {
  VorlageFilterComponentModule,
  VorlageDetailComponentModule,
  VorlageSorterComponentModule,
  SavedVorlageFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    VorlageFilterComponentModule,
    VorlageDetailComponentModule,
    VorlageSorterComponentModule,
    SavedVorlageFiltersComponentModule
  ],
  exports: [
    VorlageFilterComponentModule,
    VorlageDetailComponentModule,
    VorlageSorterComponentModule,
    SavedVorlageFiltersComponentModule
  ],
})
export class VorlageQueryModule { }
