import { Component } from '@angular/core';
import { KrankheitenService } from '.';

@Component({
  selector: 'fa-kt-krankheiten-presets',
  template: `
    <pbc-filter-preset
      [filter]="krankheiten.preset$ | async"
      [filters]="krankheiten.presets$ | async"
      [name]="krankheiten.search$ | async"
      (select)="krankheiten.setFilter($event)"
      (save)="krankheiten.writeFilter($event)"
      (delete)="krankheiten.deleteFilter($event)"
    ></pbc-filter-preset>
  `,
})
export class KrankheitenPresetsComponent {
  constructor(public krankheiten: KrankheitenService) {}
}
