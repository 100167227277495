import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektServiceModule } from '..';
import { ProjektFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektServiceModule
  ],
  declarations: [ProjektFilterComponent],
  exports: [ProjektFilterComponent],

})
export class ProjektFilterComponentModule { }
