import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IObjektNutzungsQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const ObjektNutzungsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'OBJEKTE.Models.OBJEKT_NUTZUNG.Propertys.ID.title'),
   SorterConfig('name', 'OBJEKTE.Models.OBJEKT_NUTZUNG.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'OBJEKTE.Models.OBJEKT_NUTZUNG.Propertys.BESCHREIBUNG.title'),
];
