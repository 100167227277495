import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektServiceModule } from '..';
import { ProjektDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektServiceModule
  ],
  declarations: [ProjektDetailComponent],
  exports: [ProjektDetailComponent],

})
export class ProjektDetailComponentModule { }
