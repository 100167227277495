import { NgModule } from '@angular/core';
import {
  FeldUnterkategoriesFilterComponentModule,
  FeldUnterkategoriesSorterComponentModule,
  FeldUnterkategoriesListComponentModule
} from '.';

@NgModule({
  imports: [
    FeldUnterkategoriesFilterComponentModule,
    FeldUnterkategoriesSorterComponentModule,
    FeldUnterkategoriesListComponentModule
  ],
  exports: [
    FeldUnterkategoriesFilterComponentModule,
    FeldUnterkategoriesSorterComponentModule,
    FeldUnterkategoriesListComponentModule
  ],
})
export class FeldUnterkategoriesQueryModule { }