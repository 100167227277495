import { Injectable } from '@angular/core';

import { IDeleteFeldOptionRequest, IDeleteFeldOptionResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteFeldOptionCommandService extends DeleteCommandService<IDeleteFeldOptionRequest, IDeleteFeldOptionResponse> {
  readonly route = 'felder/feld-option';
}
