import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IZeitenQuerySorter extends ISorter {
  'zeit.id'?: ISorterExpression;
  'zeit.mitarbeiter'?: ISorterExpression;
  'zeit.projekt'?: ISorterExpression;
  'zeit.gutachten'?: ISorterExpression;
  'zeit.kunde'?: ISorterExpression;
  'zeit.zeitArt'?: ISorterExpression;
  'zeit.zeitUnterart'?: ISorterExpression;
  'zeit.datum'?: ISorterExpression;
  'zeit.stunden'?: ISorterExpression;
  'zeit.minuten'?: ISorterExpression;
  'zeit.kommentar'?: ISorterExpression;
  'zeit.kosten'?: ISorterExpression;
}

export const ZeitenQuerySorterConfig: ISorterConfig = [
  SorterConfig('zeit.id', 'ZEITEN.Models.ZEIT.Propertys.ID.title'),
  SorterConfig('zeit.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  SorterConfig('zeit.projekt', 'ZEITEN.Models.ZEIT.Propertys.PROJEKT.title'),
  SorterConfig('zeit.gutachten', 'ZEITEN.Models.ZEIT.Propertys.GUTACHTEN.title'),
  SorterConfig('zeit.kunde', 'ZEITEN.Models.ZEIT.Propertys.KUNDE.title'),
  SorterConfig('zeit.zeitArt', 'ZEITEN.Models.ZEIT.Propertys.ZEIT_ART.title'),
  SorterConfig('zeit.zeitUnterart', 'ZEITEN.Models.ZEIT.Propertys.ZEIT_UNTERART.title'),
  SorterConfig('zeit.datum', 'ZEITEN.Models.ZEIT.Propertys.DATUM.title'),
  SorterConfig('zeit.stunden', 'ZEITEN.Models.ZEIT.Propertys.STUNDEN.title'),
  SorterConfig('zeit.minuten', 'ZEITEN.Models.ZEIT.Propertys.MINUTEN.title'),
  SorterConfig('zeit.kommentar', 'ZEITEN.Models.ZEIT.Propertys.KOMMENTAR.title'),
  SorterConfig('zeit.kosten', 'ZEITEN.Models.ZEIT.Propertys.KOSTEN.title'),
];
