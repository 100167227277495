import { Component, OnDestroy, OnInit } from '@angular/core';
import { Data, Params } from '@angular/router';

import { Workbook } from 'exceljs';
import { BehaviorSubject, combineLatest, distinctUntilChanged } from 'rxjs';

import { BasePage, ISitemapPage } from 'pbc.angular';

import {
  IAbteilung,
  IBerichtsVorlage,
  IDeleteProjektRequest,
  IDeleteProjektResponse,
  IGutachtensResponseRow,
  IObjekt,
  IPostArchivierenRequest,
  IPostArchivierenResponse,
  IPostKopierenRequest,
  IPostKopierenResponse,
  IPostProjekteHochzaehlenRequest,
  IPostProjekteHochzaehlenResponse,
  IPostProjektRequest,
  IPostProjektResponse,
  IPostReaktivierenRequest,
  IPostReaktivierenResponse,
  IProjekteResponseRow,
  IProjektResponse,
  IRechnung,
  IVorlage,
  IZahlungseingang,
  IZeitenQueryFilter,
} from 'fa-kt.types';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { calculateProjektGutachtenVerteilung, getErfolg, getErfolgColor } from 'fa-kt.functions';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { IProjektPageResolverResult } from '.';
import { SollIstService } from '../../../auswertungen';
import { BesichtigungenSelectService } from '../../../besichtigungen';
import { FelderService } from '../../../felder';
import { GutachtenService, GutachtensService } from '../../../gutachten';
import { ObjekteService } from '../../../objekte';
import { BerichtsVorlagenService, GeneratorService, VorlagenService, VorlageService } from '../../../texte';
import { ZeitenService } from '../../../zeiten/querys';
import {
  DeleteProjektCommandService,
  PostArchivierenCommandService,
  PostKopierenCommandService,
  PostProjektCommandService,
  PostProjekteHochzaehlenCommandService,
  PostReaktivierenCommandService,
} from '../../commands';
import { ProjekteSelectService, ProjekteService, ProjektService } from '../../querys';
import { MitarbeiterService } from '../../../personen';
import { orderBy } from 'lodash';

export interface From {
  emailAddress?: FromEmailAddress;
}

export interface FromEmailAddress {
  name?: string;
  address?: string;
}

export interface ReplyTo {
  emailAddress?: ReplyToEmailAddress;
}

export interface ReplyToEmailAddress {
  name?: string;
}

export interface Resource {
  '@odata.type'?: string;
  createdDateTime?: Date;
  lastModifiedDateTime?: Date;
  changeKey?: string;
  receivedDateTime?: Date;
  sentDateTime?: Date;
  hasAttachments?: boolean;
  internetMessageId?: string;
  subject?: string;
  bodyPreview?: string;
  importance?: string;
  parentFolderId?: string;
  conversationId?: string;
  isRead?: boolean;
  isDraft?: boolean;
  webLink?: string;
  inferenceClassification?: string;
}

interface Vorlage extends IVorlage {
  berichtsVorlagen: IBerichtsVorlage[];
}

@Component({
  selector: 'fa-kt-projekt-page',
  templateUrl: './projekt.page.html',
  styleUrls: ['./projekt.page.css'],
})
export class ProjektPage extends BasePage implements OnInit, OnDestroy {
  description = { context: 'PROJEKTE', page: 'PROJEKT' };
  public readonly $postArchivieren: BehaviorSubject<IPostArchivierenRequest | null> = new BehaviorSubject<IPostArchivierenRequest | null>(null);
  public readonly $postKopieren: BehaviorSubject<IPostKopierenRequest | null> = new BehaviorSubject<IPostKopierenRequest | null>(null);
  public readonly $deleteProjekt: BehaviorSubject<IDeleteProjektRequest | null> = new BehaviorSubject<IDeleteProjektRequest | null>(null);
  public readonly $postProjekt: BehaviorSubject<IPostProjektRequest | null> = new BehaviorSubject<IPostProjektRequest | null>(null);
  public readonly $postProjekteHochzaehlen: BehaviorSubject<IPostProjekteHochzaehlenRequest | null> = new BehaviorSubject<IPostProjekteHochzaehlenRequest | null>(null);
  public readonly $postReaktivieren: BehaviorSubject<IPostReaktivierenRequest | null> = new BehaviorSubject<IPostReaktivierenRequest | null>(null);

  public _projekt: ISitemapPage;
  public _archiv: ISitemapPage;
  public _umkreissuche: ISitemapPage;
  public _projekte: ISitemapPage;
  public _recherche: ISitemapPage;
  public _vorlage: ISitemapPage;
  public _zeiten: ISitemapPage;

  public readonly $id = new BehaviorSubject<string | undefined>(undefined);
  public readonly $creating = new BehaviorSubject<boolean>(false);
  public readonly $tab = new BehaviorSubject<'projekt' | 'gutachten' | 'finanzen' | 'kommunikation' | 'e-mails' | 'dateien'>('projekt');
  public readonly $gutachten = new BehaviorSubject<IGutachtensResponseRow | undefined>(undefined);
  public readonly $isArchiv = new BehaviorSubject<boolean>(false);
  public readonly $abteilung = new BehaviorSubject<IAbteilung | undefined>(undefined);
  public readonly $valid = new BehaviorSubject<boolean>(true);

  public readonly search$ = new BehaviorSubject<string>('');
  public readonly $standortPfad = new BehaviorSubject<string>('');
  public readonly $standort = new BehaviorSubject<string>('');
  public readonly $kundePfad = new BehaviorSubject<string>('');
  public readonly $kunde = new BehaviorSubject<string>('');

  public readonly $vorlagen = new BehaviorSubject<Vorlage[]>([]);

  hideSollIst = false;
  row$ = new BehaviorSubject<IProjekteResponseRow | undefined>(undefined);

  constructor(
    public gutachtens: GutachtensService,
    public objekte: ObjekteService,
    public sollIst: SollIstService,
    public projekt: ProjektService,
    public projekte: ProjekteService,
    public projekteSelect: ProjekteSelectService,
    public besichtigungenSelect: BesichtigungenSelectService,
    public postArchivieren: PostArchivierenCommandService,
    public postKopieren: PostKopierenCommandService,
    public deleteProjekt: DeleteProjektCommandService,
    public postProjekt: PostProjektCommandService,
    public postProjekteHochzaehlen: PostProjekteHochzaehlenCommandService,
    public postReaktivieren: PostReaktivierenCommandService,
    public vorlagen: VorlagenService,
    public berichtsVorlagen: BerichtsVorlagenService,
    public generator: GeneratorService,
    public zeiten: ZeitenService,
    private felder: FelderService,
    public vorlage: VorlageService,
    public mitarbeiter: MitarbeiterService,
  ) {
    super();
    this._recherche = this.sitemap.MARKT.Pages.RECHERCHE;
    this._projekt = this.sitemap.PROJEKTE.Pages.PROJEKT;
    this._archiv = this.sitemap.PROJEKTE.Pages.ARCHIV;
    this._umkreissuche = this.sitemap.GUTACHTEN.Pages.UMKREISSUCHE;
    this._projekte = this.sitemap.PROJEKTE.Pages.PROJEKTE;
    this._vorlage = this.sitemap.TEXTE.Pages.VORLAGE;
    this._zeiten = this.sitemap.ZEITEN.Pages.ZEITEN;
  }

  override async ngOnInit() {
    super.ngOnInit();
    if (!this.router.routerState.snapshot.root.queryParams.tab) {
      await this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: { tab: 'projekt' },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
    // combineLatest([this.auth.$isAdmin, this.auth.$isFinanzen, this.$id]).subscribe(async ([admin, finanzen, projekt]) => {
    //   if ((!admin && !finanzen) || !projekt) return;
    //   const rows = await this.projekte.request({ projekt, loadAll: true, inklusiveInaktive: true }, false, true);
    //   this.row$.next(rows?.projekte?.shift());
    // });
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((params: Params) => {
      this.$tab.next(params.tab);
      this.$id.next(params.id);
    });
    this.route.data.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (data: Data) => {
      const querys: IProjektPageResolverResult = data.querys;
      if (!querys.projekt) await this.navigateToProjekte();
      else if (querys.projekt.projekt && !querys.projekt.projekt.id) this.$postProjekt.next({ projekt: querys.projekt.projekt });
    });
    this.$id.pipe(takeUntilDestroyed(this.destroyedRef), distinctUntilChanged()).subscribe((projekt) => {
      if (projekt && projekt !== 'new') {
        this.projekt.request({ id: projekt });
        this.gutachtens.request({ projekt });
        this.sollIst.request({ mitarbeiter: this.auth.$id.getValue(), projekt });
        this.besichtigungenSelect.request({ projekt });
        this.$creating.next(false);
      } else {
        this.sollIst.result$.next(undefined);
        this.$creating.next(true);
      }
    });
    this.projekt.result$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (result) => {
      this.page.title = result ? 'Projekt: ' + result?.projekt?.bezeichnung : 'Projekt';
      this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
      // if (this.$postProjekt.getValue()?.projekt.id !== result?.projekt?.id || this.$postProjekt.getValue()?.projekt._updatedAt !== result?.projekt?._updatedAt)
      this.$isArchiv.next(result?.projekt?.aktiv === false);
      if (result) {
        this.$postProjekt.next({ projekt: result.projekt });
        this.$postKopieren.next({ id: result.projekt?.id });
        this.$postArchivieren.next({ id: result.projekt?.id });
        this.$postReaktivieren.next({ id: result.projekt?.id });
        this.$deleteProjekt.next({ id: result.projekt?.id });

        this.search$.next(result.projekt?.dateiVerzeichnis?.split('\\').pop());

        const standort = result.standort;
        this.$standortPfad.next(standort?.dateipfad);
        this.$standort.next(standort?.name);

        const kunde = result.kunde;
        this.$kundePfad.next(kunde?.dateipfad);
        this.$kunde.next(kunde?.name);

        this.$abteilung.next(result.abteilung);
      }
    });
    this.gutachtens.result$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((result) => {
      if (!result?.gutachten) return;
      const next = result.gutachten[0];
      if (!next) return this.$gutachten.next(next);
      const current = this.$gutachten.getValue()?.gutachten;
      if (current?.projekt !== next?.gutachten.projekt) return this.$gutachten.next(next);
    });
    combineLatest([this.vorlagen.response$, this.berichtsVorlagen.response$, this.projekt.result$, this.$gutachten])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([vorlagen, berichtsVorlagen, result, row]) => {
        if (!vorlagen?.vorlagen?.length || !result?.projekt?.id || !this.hasVorlagen) return;
        const { projekt, kunde } = result;

        // const { id } = vorlage.vorlage;
        // this.$berichtsVorlagen.next(
        //   berichtsVorlagen?.berichtsVorlagen
        //     .filter(({ berichtsVorlage }) => !berichtsVorlage.vorlagen?.length || berichtsVorlage.vorlagen.includes(id))
        //     .map(({ berichtsVorlage }) => berichtsVorlage),
        // );

        const next = vorlagen?.vorlagen
          .filter(({ vorlage }) => {
            const { fuerBewertungsAnlaesse, fuerKunden, fuerKundenArten, fuerKundenGruppen, fuerObjektArten } = vorlage;
            return (
              (!fuerBewertungsAnlaesse?.length || fuerBewertungsAnlaesse.includes(projekt.bewertungsAnlass)) &&
              (!fuerKunden?.length || fuerKunden.includes(projekt.kunde) || fuerKunden.includes(projekt.bank)) &&
              (!fuerKundenArten?.length || fuerKundenArten.includes(kunde.kundenArt)) &&
              (!fuerKundenGruppen?.length || fuerKundenGruppen.includes(kunde.kundenGruppe)) &&
              (!row?.gutachten || !fuerObjektArten?.length || fuerObjektArten.includes(row?.gutachten?.objektArt))
            );
          })
          .map(({ vorlage }) => ({
            ...vorlage,
            berichtsVorlagen: berichtsVorlagen.berichtsVorlagen
              .filter(({ berichtsVorlage: { vorlagen } }) => !vorlagen?.length || vorlagen.includes(vorlage.id))
              .map(({ berichtsVorlage }) => berichtsVorlage),
          }))
          .filter(({ berichtsVorlagen }) => berichtsVorlagen.length);

        this.$vorlagen.next(next);
      });
  }

  public async reload() {
    const projekt = this.$id.getValue();
    if (!projekt || projekt === 'new') return;
    await Promise.all([
      this.projekt.request({ id: projekt }, true),
      this.gutachtens.request({ projekt }, true),
      this.sollIst.request({ mitarbeiter: this.auth.$id.getValue(), projekt }, true),
      this.besichtigungenSelect.request({ projekt }, true),
    ]);
    this.message.info('Aktualisiert');
  }

  public async setGutachten(id: string) {}

  public async setObjekte(id: string) {}

  public async setProjekt(id: string) {
    await this.navigateToProjekt({ id, tab: 'projekt' });
  }

  public async setProjekte(id: string) {}

  async finishedPostArchivieren(response?: IPostArchivierenResponse) {
    await this.navigateToProjekte();
  }

  async finishedPostKopieren(response?: IPostKopierenResponse) {
    await this.setProjekt(response.id.slice(0, '766ff267-376b-447c-b327-b0b806593f6f'.length));
  }

  async finishedDeleteProjekt(response?: IDeleteProjektResponse) {
    await this.navigateToProjekte();
  }

  async finishedPostProjekt(response?: IPostProjektResponse) {
    this.setProjekt(response.projekt?.id);
  }

  async finishedPostProjekteHochzaehlen(response?: IPostProjekteHochzaehlenResponse) {}

  async finishedPostReaktivieren(response?: IPostReaktivierenResponse) {}

  public async navigateToProjekt(queryParams: Params = {}) {
    await this.router.navigate(this._projekt.url, { queryParams });
  }
  public async navigateToArchiv(queryParams: Params = {}) {
    await this.router.navigate(this._archiv.url, { queryParams });
  }
  public async navigateToUmkreissuche(queryParams: Params = {}) {
    await this.router.navigate(this._umkreissuche.url, { queryParams });
  }
  public async navigateToRecherche(queryParams: Params = {}) {
    await this.router.navigate(this._recherche.url, { queryParams });
  }
  public async navigateToProjekte(queryParams: Params = {}) {
    await this.router.navigate(this._projekte.url, { queryParams });
  }

  async back() {
    if (!this.$valid.getValue()) {
      await this.modal.confirm({
        nzTitle: 'Es sind nicht gespeicherte Bewertungen/Dokumente im Speicher. Wenn das Projekt geschlossen wird, gehen diese verloren.',
        nzOkText: 'Trotzdem schließen',
        nzOnOk: () => window.history.back(),
        nzOnCancel: async () =>
          await this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: { tab: 'gutachten' },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          }),
        nzCancelText: 'Bleiben',
      });
    } else window.history.back();
  }

  get hasVorlagen(): boolean {
    return this.auth.access(this._vorlage.url.join('/'));
  }

  public async openVorlage(id?: string) {
    if (!id) return;
    const projekt = this.$id.getValue();
    const gutachten = this.$gutachten.getValue()?.id;
    await this.router.navigate(this._vorlage.url, { queryParams: { id, projekt, gutachten } });
  }

  async searchUmkreis() {
    const objekt: IObjekt | undefined = this.objekte.result$.getValue()?.objekte.find((o) => o.objekt.id === this.$gutachten.getValue()?.gutachten?.objekt)?.objekt;
    await this.navigateToUmkreissuche({ latitude: objekt?.addresse.latitude as number, longitude: objekt?.addresse.longitude as number, distance: 100, inklusiveInaktive: true });
  }

  async recherche() {
    const projekt = this.projekt.result$.getValue()?.projekt;
    const row = this.$gutachten.getValue();
    const objekt: IObjekt | undefined = this.objekte.result$.getValue()?.objekte.find((o) => o.objekt.id === row?.gutachten.objekt)?.objekt;

    let zeileObjektart = this.gutachtens.getGutachtenObjektArt(row?.gutachten.objektArt as string)?.label;
    if (zeileObjektart) {
      zeileObjektart = zeileObjektart.split(' | ').pop();
    }
    const request = {
      stichtag: new Date().toISOString(),
      zeileLand: objekt?.addresse?.land,
      zeileBundesland: objekt?.addresse?.bundesland,
      zeileKreisStadt: objekt?.addresse?.kreis,
      zeileGemeindeBezirk: objekt?.addresse?.regierungsbezirk,
      zeileOrtsStadtTeil: objekt?.addresse?.ort_stadt_teil,
      zeileGemeindeschluessel: objekt?.addresse?.regionalSchluessel,
      zeilePlz: objekt?.addresse?.plz,
      zeileAssetklasse: projekt?.objektArt,
      zeileObjektart,
      baujahr: row?.gutachten.baujahr,
      flaeche: row?.gutachten.flaeche,
    };
    await this.navigateToRecherche(request);
  }

  async navigate(iterator: number) {
    const current = this.$id.getValue();
    const projekte = this.projekteSelect.selection$.getValue();
    if (!projekte || !current) return;
    let index = projekte.map((p) => p.value).indexOf(current) + iterator;
    if (index < 0) index = projekte.length - 1;
    if (index >= projekte.length) index = 0;
    const next = projekte[index];
    await this.setProjekt(next.value);
  }

  async exportVermerke() {
    this.loading$.next(true);

    const [blob] = await Promise.all([this.files.get('fa-kt-apps/Vermerke.xlsx')]);
    if (!blob) {
      this.loading$.next(false);
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Vermerke';
    const worksheet = workbook.getWorksheet(sheetName);
    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
    let i = 6;

    this.projekt.result$
      .getValue()
      ?.vermerke?.filter((v) => v.externSichtbar)
      .forEach((vermerk) => {
        worksheet.getCell('B' + i).value = vermerk.id;
        worksheet.getCell('C' + i).value = new Date(vermerk._createdAt as Date).toLocaleDateString();
        worksheet.getCell('D' + i).value = this.projekt.getVermerkeVerfasser(vermerk.verfasser)?.label;
        worksheet.getCell('E' + i).value = vermerk.kommentar;
        i++;
      });

    const projekt = this.projekt.result$.getValue()?.projekt;

    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      'Vermerke "' + projekt?.nummer + ' | ' + projekt?.bezeichnung + '".xlsx',
    );
    this.loading$.next(false);
  }

  calculateSumme(rechnungen?: IRechnung[], zahlungseingaenge?: IZahlungseingang[], verhandeltesHonorar?: number): number {
    let summe = 0;
    if (verhandeltesHonorar && !isNaN(verhandeltesHonorar)) {
      rechnungen?.forEach((r) => (summe = summe + (r.nettoSumme || Number((r as any).summe))));
      zahlungseingaenge?.forEach((z) => (summe = summe - z.summe));
      return verhandeltesHonorar - summe;
    }
    rechnungen?.forEach((r) => (summe = summe + (r.finaleSumme || Number((r as any).summe))));
    zahlungseingaenge?.forEach((z) => (summe = summe - z.summe));
    return summe * (!verhandeltesHonorar && !rechnungen?.length ? -1 : 1);
  }
  /*    methods >> */

  public async submitPostArchivieren(request?: IPostArchivierenRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postArchivieren.getValue();
    if (payload) {
      try {
        const response = await this.postArchivieren.request(payload);
        await this.finishedPostArchivieren(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitPostKopieren(request?: IPostKopierenRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postKopieren.getValue();
    if (payload) {
      try {
        const response = await this.postKopieren.request(payload);
        await this.finishedPostKopieren(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitDeleteProjekt(request?: IDeleteProjektRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$deleteProjekt.getValue();
    if (payload) {
      try {
        const response = await this.deleteProjekt.request(payload);
        await this.finishedDeleteProjekt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitPostProjekt(request?: IPostProjektRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postProjekt.getValue();
    if (payload) {
      try {
        const response = await this.postProjekt.request(payload);
        await this.finishedPostProjekt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitPostProjekteHochzaehlen(request?: IPostProjekteHochzaehlenRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postProjekteHochzaehlen.getValue();
    if (payload) {
      try {
        const response = await this.postProjekteHochzaehlen.request(payload);
        await this.finishedPostProjekteHochzaehlen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitPostReaktivieren(request?: IPostReaktivierenRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postReaktivieren.getValue();
    if (payload) {
      try {
        const response = await this.postReaktivieren.request(payload);
        await this.finishedPostReaktivieren(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  getErfolgColor(projekt): string {
    return getErfolgColor(getErfolg(projekt));
  }

  async openZeiten() {
    this.loading$.next(true);
    const projekt = this.projekt.result$.getValue()?.projekt;
    if (!projekt) return;
    const jahr = moment(projekt?.deadline).year();
    const shapes: IZeitenQueryFilter = this.zeiten.filter$.getValue() || {};
    shapes['zeit.mitarbeiter'] = { operator: 'ist', value: [this.auth.$id.getValue()] };
    shapes['zeit.projekt'] = { operator: 'ist', value: [projekt.id] };
    this.zeiten.filter$.next(shapes);
    await this.zeiten.request({ ...(this.zeiten.payload$.getValue() || {}), jahr });
    this.loading$.next(false);
    await this.router.navigate(this._zeiten.url);
  }

  async run(vorlage: Vorlage, berichtsVorlage: IBerichtsVorlage) {
    this.loading$.next(true);
    const { abschnitte } = await this.vorlage.request({ id: vorlage.id });
    const projekt = this.projekte.result$.getValue()?.projekte.find((row) => row.projekt.id === this.$id.getValue());
    const objekt = await this.generator.setObjekt(
      projekt,
      this.felder.response$.getValue()?.felder.map(({ feld, feldOptionen }) => ({
        ...feld,
        feldOptionen,
      })),
      this.$gutachten.getValue()?.gutachten.id,
    );
    await this.generator.run(objekt, abschnitte, berichtsVorlage);
    this.loading$.next(false);
  }

  // private async searchEmails(queryString: string) {
  //   const search = await this.http.post<GraphEmailResult>('https://graph.microsoft.com/v1.0/search/query', {
  //     requests: [
  //       {
  //         entityTypes: ['message'],
  //         query: {
  //           queryString: queryString,
  //         },
  //       },
  //     ],
  //   });
  //   const emails = search.value?.pop()?.hitsContainers || [];
  //   this.$emails.next(emails as GraphEmail[]);
  // }

  showFinanzen(entry: IProjektResponse): boolean {
    const user = this.auth.$id.getValue();
    const finanzen = this.auth.$isFinanzen.getValue();
    return finanzen || entry.projekt.gutachten?.includes(user); //  || entry.projekt.besichtigungen?.includes(user) || entry.projekt.pruefung === user;
  }

  public async test() {
    const projekt = this.$id.getValue();
    let [mas, projekte] = await Promise.all([this.mitarbeiter.request({}).then((mas) => mas.mitarbeiter.map(({ mitarbeiter }) => mitarbeiter)), this.projekte.request({ projekt, loadAll: true })]);
    const row = projekte.projekte.find((row) => row.id === projekt);
    if (!row) return alert('NOT FOUND');
    mas = orderBy(
      orderBy(
        mas.filter((m) => !['FA|KT', 'Admin'].some((blocked) => (m.anzeigename === 'FA|KT Office Management' ? false : m.anzeigename.includes(blocked)))),
        ({ anzeigename }) => anzeigename,
      ),
      ({ inaktiv }) => !!inaktiv,
    );
    calculateProjektGutachtenVerteilung(row, mas, { pruefung: 0.1, besichtigung: 0.1, preview: false });
  }
}
