import { Component } from '@angular/core';
import { FormularService } from '../service';

@Component({
  selector: 'fa-kt-saved-formular-filters',
  templateUrl: './saved-formular-filters.component.html',
  styleUrls: ['./saved-formular-filters.component.css'],
})
export class SavedFormularFiltersComponent {
  constructor(public formular: FormularService) {}
}
