<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="felder.search$.next($event)" [search]="felder.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-felder-filter [style.width]="'100%'"></fa-kt-felder-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-felder-sorter [style.width]="'100%'"></fa-kt-felder-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-felder-list [style.width]="'100%'" (select)="setFelder($event)" [loading]="$loading | async"></fa-kt-felder-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="gutachten.search$.next($event)" [search]="gutachten.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-gutachten-filter [style.width]="'100%'"></fa-kt-gutachten-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-gutachten-sorter [style.width]="'100%'"></fa-kt-gutachten-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-gutachten-list [style.width]="'100%'" (select)="setGutachten($event)" [loading]="$loading | async"></fa-kt-gutachten-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="vorlagen.search$.next($event)" [search]="vorlagen.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-vorlagen-filter [style.width]="'100%'"></fa-kt-vorlagen-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-vorlagen-sorter [style.width]="'100%'"></fa-kt-vorlagen-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-vorlagen-list [style.width]="'100%'" (select)="setVorlagen($event)" [loading]="$loading | async"></fa-kt-vorlagen-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="vorlage.search$.next($event)" [search]="vorlage.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-vorlage-filter [style.width]="'100%'"></fa-kt-vorlage-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-vorlage-sorter [style.width]="'100%'"></fa-kt-vorlage-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-vorlage-list [style.width]="'100%'" (select)="setVorlage($event)" [loading]="$loading | async"></fa-kt-vorlage-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="projekte.search$.next($event)" [search]="projekte.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-projekte-filter [style.width]="'100%'"></fa-kt-projekte-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-projekte-sorter [style.width]="'100%'"></fa-kt-projekte-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-projekte-list [style.width]="'100%'" (select)="setProjekte($event)" [loading]="$loading | async"></fa-kt-projekte-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="berichts-vorlagen.search$.next($event)" [search]="berichts-vorlagen.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-berichts-vorlagen-filter [style.width]="'100%'"></fa-kt-berichts-vorlagen-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-berichts-vorlagen-sorter [style.width]="'100%'"></fa-kt-berichts-vorlagen-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-berichts-vorlagen-list [style.width]="'100%'" (select)="setBerichtsVorlagen($event)" [loading]="$loading | async"></fa-kt-berichts-vorlagen-list>


  <fa-kt-delete-vorlage [style.width]="'100%'"  [value]="$deleteVorlage | async" (finished)="finishedDeleteVorlage($event)"></fa-kt-delete-vorlage>

  <fa-kt-delete-berichts-vorlage [style.width]="'100%'"  [value]="$deleteBerichtsVorlage | async" (finished)="finishedDeleteBerichtsVorlage($event)"></fa-kt-delete-berichts-vorlage>

  <fa-kt-post-berichts-vorlage [style.width]="'100%'"  [value]="$postBerichtsVorlage | async" (finished)="finishedPostBerichtsVorlage($event)"></fa-kt-post-berichts-vorlage>

  <fa-kt-post-vorlage [style.width]="'100%'"  [value]="$postVorlage | async" (finished)="finishedPostVorlage($event)"></fa-kt-post-vorlage>


  <fa-kt-abschnitte [style.width]="'100%'" ></fa-kt-abschnitte>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="vorlage" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="vorlagen" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <nz-space>
      <nz-input-group *nzSpaceItem [nzAddOnBefore]="PageLeft" [nzAddOnAfter]="PageRight">
        <ng-template #PageLeft>
          <a (click)="navigate(-1)">
            <i nz-icon nzType="left" nzTheme="outline"></i>
          </a>
        </ng-template>
        <nz-select style="width: 50%" nzShowSearch [ngModel]="$projekt | async" (ngModelChange)="$projekt.next($event)" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let projekt of $projekte | async" [nzValue]="projekt.value" [nzLabel]="projekt.label"></nz-option>
        </nz-select>
        <nz-select style="width: 50%" nzShowSearch [ngModel]="$gutachten | async" (ngModelChange)="$gutachten.next($event)" [ngModelOptions]="{standalone: true}">
          <nz-option *ngFor="let gutachten of $gutachtens | async" [nzValue]="gutachten.value" [nzLabel]="gutachten.label"></nz-option>
        </nz-select>
        <ng-template #PageRight>
          <a (click)="navigate(1)">
            <i nz-icon nzType="right" nzTheme="outline"></i>
          </a>
        </ng-template>
      </nz-input-group>

      <nz-button-group *nzSpaceItem>
        <button nz-button nzType="primary" (click)="vorlageVisible = !vorlageVisible">Vorlage bearbeiten <span nz-icon nzType="edit" nzTheme="outline"></span></button>
        <button *ngIf="!(creating$ | async)" nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="more" nzPlacement="bottomRight" nzTrigger="click" [nzClickHide]="false">
          <i nz-icon nzType="ellipsis"></i>
        </button>
        <nz-dropdown-menu #more="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item [nzDisabled]="creating$ | async">
              <nz-select
                style="width: 100%; min-width: 375px"
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="Kein Formular angewendet"
                [ngModel]="$formular | async"
                (ngModelChange)="$formular.next($event)"
              >
                <nz-option *ngFor="let formular of $formulare | async" [nzLabel]="formular.name" [nzValue]="formular"></nz-option>
              </nz-select>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item *ngFor="let berichtsVorlage of $berichtsVorlagen | async">
              <a (click)="openBerichtsVorlage(berichtsVorlage)" style="margin-right: 8px">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </a>
              <span nz-typography nzType="primary" (click)="run(berichtsVorlage)">
                <i nz-icon nzType="download" nzTheme="outline"></i>
                {{ berichtsVorlage.name }}
              </span>
            </li>
            <li nz-menu-item *ngIf="!(creating$ | async)">
              <pbc-file-upload
                style="width: 100%; min-width: 375px; display: block"
                (uploaded)="neueBerichtsVorlage($event)"
                text="Word Vorlage hochladen"
                hint="Word Vorlage mit Platzhaltern auswählen"
              ></pbc-file-upload>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item [nzDisabled]="(creating$ | async) || !($projekt | async)" (click)="openProjekt()">📗 Gutachten öffnen <span nz-icon nzType="arrow-right" nzTheme="outline"></span></li>
            <li nz-menu-divider></li>
            <li nz-menu-item [nzDisabled]="creating$ | async" (click)="submitPostVorlageKopieren()">
              <i nz-icon nzType="copy" nzTheme="outline" style="margin-right: 8px"></i>
              Vorlage kopieren
            </li>
            <li nz-menu-item [nzDisabled]="creating$ | async" nz-popconfirm [nzPopconfirmTitle]="'Vorlage wirklich löschen?'" [nzIcon]="DeleteIcon" (nzOnConfirm)="submitDeleteVorlage()">
              <i nz-icon nzType="delete" nzTheme="outline" style="color: red; margin-right: 8px"></i>
              Vorlage löschen
              <ng-template #DeleteIcon>
                <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              </ng-template>
            </li>
          </ul>
        </nz-dropdown-menu>
      </nz-button-group>
    </nz-space>
  </nz-page-header-extra>
</nz-page-header>

<nz-content [ngSwitch]="view$ | async" style="min-height: calc(100vh - 230px)">
  <ng-container *ngSwitchCase="'abschnitt'">
    <nz-page-header nzTitle="Abschnitt" nzBackIcon="close-circle" (nzBack)="view$.next('preview')">
      <nz-page-header-extra>
        <nz-space>
          <fa-kt-delete-abschnitt *nzSpaceItem [style.width]="'100%'" [value]="$deleteAbschnitt | async" (finished)="finishedDeleteAbschnitt($event)"></fa-kt-delete-abschnitt>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-row nzJustify="center">
      <nz-col nzSpan="24">
        <fa-kt-post-abschnitt
          [alleFelder]="$alleFelder | async"
          [felder]="($formular | async)?.felder || []"
          [object]="$object | async"
          [virtual]="$virtual | async"
          [style.width]="'100%'"
          [value]="$postAbschnitt | async"
          [nunjucks]="generator.nunjucks"
          (finished)="finishedPostAbschnitt($event)"
        ></fa-kt-post-abschnitt>
      </nz-col>
      <nz-row nzSpan="4">
        <pbc-meta [value]="($postAbschnitt | async)?.abschnitt"></pbc-meta>
      </nz-row>
    </nz-row>
  </ng-container>
  <nz-row *ngSwitchDefault>
    <nz-col nzSpan="24">
      <p *ngFor="let error of errors">
        <nz-alert nzType="error" [nzMessage]="error">
          <ng-template #error>
            <code>{{ error | json }}</code>
          </ng-template>
        </nz-alert>
      </p>
    </nz-col>
    <nz-col nzSpan="24">
      <nz-list nzSize="large" [nzLoading]="$loading | async" cdkDropList (cdkDropListDropped)="reorder($event)">
        <nz-list-item *ngFor="let row of $abschnitte | async" (click)="openAbschnitt(row.abschnitt)" cdkDrag>
          <nz-list-item-meta>
            <nz-list-item-meta-title>
              <a> {{ row.abschnitt.name }} </a>
              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography nzType="secondary">{{ "{{" }}{{ row.abschnitt.platzhalter }}{{ "}}" }}</span>
            </nz-list-item-meta-title>
            <nz-list-item-meta-description>
              <div [innerHTML]="row.rendered | safe" [style.color]="row.fehler ? 'red' : ''"></div>
            </nz-list-item-meta-description>
          </nz-list-item-meta>
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <a cdkDragHandle><span nz-icon nzType="menu" nzTheme="outline"></span></a>
            </nz-list-item-action>
          </ul>
        </nz-list-item>
        <nz-list-item>
          <button nz-button [disabled]="creating$ | async" (click)="openAbschnitt({})" nzType="dashed">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Abschnitt hinzufügen
          </button>
        </nz-list-item>
      </nz-list>
    </nz-col>
  </nz-row>
</nz-content>

<nz-modal [(nzVisible)]="vorlageVisible" (nzOnCancel)="vorlageVisible = false">
  <ng-container *nzModalTitle>
    <nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle">
      <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
      <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb>
        <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
      </nz-breadcrumb>
      <nz-page-header-extra>
        <nz-space>
          <div *nzSpaceItem style="min-width: 120px">
            <fa-kt-post-vorlage-kopieren [style.width]="'100%'" [value]="$postVorlageKopieren | async" (finished)="finishedPostVorlageKopieren($event)"></fa-kt-post-vorlage-kopieren>
          </div>
          <div *nzSpaceItem style="min-width: 120px">
            <fa-kt-delete-vorlage [style.width]="'100%'" [value]="$deleteVorlage | async" (finished)="finishedDeleteVorlage($event)"></fa-kt-delete-vorlage>
          </div>
        </nz-space>
      </nz-page-header-extra>
      <nz-page-header-content> </nz-page-header-content>
    </nz-page-header>
  </ng-container>
  <ng-container *nzModalContent>
    <fa-kt-post-vorlage [style.width]="'100%'" [value]="$postVorlage | async" (finished)="finishedPostVorlage($event)"></fa-kt-post-vorlage>
  </ng-container>
  <ng-container *nzModalFooter>
    <pbc-meta [value]="($postVorlage | async)?.vorlage"></pbc-meta>
  </ng-container>
</nz-modal>

<nz-modal [(nzVisible)]="berichtsVorlageVisible" (nzOnCancel)="berichtsVorlageVisible = false"
  ><ng-container *nzModalTitle>
    <nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle">
      <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
      <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb>
        <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
      </nz-breadcrumb>
      <nz-page-header-extra>
        <nz-space>
          <button *nzSpaceItem nz-button nzType="dashed" [disabled]="!($deleteBerichtsVorlage | async).berichtsVorlage" (click)="download($postBerichtsVorlage.getValue()?.berichtsVorlage?.datei)">
            <i nz-icon nzType="download" nzTheme="outline"></i>
            Herunterladen
          </button>
          <div *nzSpaceItem>
            <fa-kt-delete-berichts-vorlage [style.width]="'100%'" [value]="$deleteBerichtsVorlage | async" (finished)="finishedDeleteBerichtsVorlage($event)"></fa-kt-delete-berichts-vorlage>
          </div>
        </nz-space>
      </nz-page-header-extra>
      <nz-page-header-content> </nz-page-header-content>
    </nz-page-header>
  </ng-container>
  <ng-container *nzModalContent>
    <fa-kt-post-berichts-vorlage
      [shapes]="$vorlagen | async"
      [style.width]="'100%'"
      [value]="$postBerichtsVorlage | async"
      (finished)="finishedPostBerichtsVorlage($event)"
    ></fa-kt-post-berichts-vorlage>
  </ng-container>
  <ng-container *nzModalFooter>
    <pbc-meta [value]="($postBerichtsVorlage | async)?.berichtsVorlage"></pbc-meta>
  </ng-container>
</nz-modal>
