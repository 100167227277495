import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteKundenGruppeRequest, IDeleteKundenGruppeResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteKundenGruppeCommandService extends DeleteCommandService<IDeleteKundenGruppeRequest, IDeleteKundenGruppeResponse> {
  readonly route = 'kunden/settings/kunden-gruppe';
}
