import { NgModule } from '@angular/core';

import { DeleteZeitArtCommandModule, PostZeitArtCommandModule, ZeitArtsQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostZeitArtCommandModule, DeleteZeitArtCommandModule, ZeitArtsQueryModule],
  exports: [PostZeitArtCommandModule, DeleteZeitArtCommandModule, ZeitArtsQueryModule],
})
export class ZeitArtsSettingsModule {}
