import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FotoKategorienSorterComponent } from '.';
import { FotoKategorienServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    FotoKategorienServiceModule
  ],
  declarations: [FotoKategorienSorterComponent],
  exports: [FotoKategorienSorterComponent],

})
export class FotoKategorienSorterComponentModule { }
