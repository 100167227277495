import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BasePostCommandComponent } from 'pbc.angular';

import { IPostAbteilungRequest, IPostAbteilungResponse } from 'fa-kt.types';
import { PostAbteilungCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-abteilung',
  templateUrl: './post-abteilung.component.html',
  styleUrls: ['./post-abteilung.component.css'],
})
export class PostAbteilungCommandComponent extends BasePostCommandComponent<IPostAbteilungRequest, IPostAbteilungResponse> implements OnInit, OnDestroy {
  description = { context: 'KUNDEN', command: 'POST_ABTEILUNG' };
  contentKey: keyof IPostAbteilungRequest = 'abteilung';

  async prepare(): Promise<void> {
    await this.postAbteilung.prepare();
  }
  async request(payload: IPostAbteilungRequest): Promise<IPostAbteilungResponse> {
    return await this.postAbteilung.request(payload);
  }

  @Input() businessHomePage = '';
  @Input() companyName = '';

  constructor(public postAbteilung: PostAbteilungCommandService /* << more    */ /*    more >> */) {
    super();
    this.form = this.fb.group({
      abteilung: this.fb.group({
        id: [null, []],
        kunde: [null, [Validators.required]],
        name: [null, [Validators.required]],
        ansprache: [null, []],
        outlookKontakte: [[], []],
        website: [null, []],
        adresse: [],
        aktiv: [null, []],
      }),
    });
    this.patch();
    try {
      if (this.data) this.patch(this.data);
    } catch (e) {}
  }

  get name() {
    return this.form.controls.abteilung.get('name');
  }
  get website() {
    return this.form.controls.abteilung.get('website');
  }

  get abteilungId() {
    return this.form.controls.abteilung.get('id');
  }
  get abteilungKunde() {
    return this.form.controls.abteilung.get('kunde');
  }
  get abteilungName() {
    return this.form.controls.abteilung.get('name');
  }
  get abteilungAnsprache() {
    return this.form.controls.abteilung.get('ansprache');
  }
  get abteilungOutlookKontakte() {
    return this.form.controls.abteilung.get('outlookKontakte');
  }
  get abteilungWebsite() {
    return this.form.controls.abteilung.get('website');
  }
  get abteilungAdresse() {
    return this.form.controls.abteilung.get('adresse');
  }
  get abteilungAktiv() {
    return this.form.controls.abteilung.get('aktiv');
  }
}
