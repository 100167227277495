import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BereichsServiceModule } from '..';
import { BereichsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BereichsServiceModule
  ],
  declarations: [BereichsListComponent],
  exports: [BereichsListComponent],

})
export class BereichsListComponentModule { }
