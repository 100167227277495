import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IBerichtQuerySorter extends ISorter {
  'bericht.id'?: ISorterExpression;
  'bericht.excel'?: ISorterExpression;
  'bericht.art'?: ISorterExpression;
  'bericht.herausgeber'?: ISorterExpression;
  'bericht.quelle'?: ISorterExpression;
  'bericht.jahr'?: ISorterExpression;
  'bericht.quartal'?: ISorterExpression;
  'bericht.monat'?: ISorterExpression;
  'bericht.kommentar'?: ISorterExpression;
  'zeilen.id'?: ISorterExpression;
  'zeilen.bericht'?: ISorterExpression;
  'zeilen.land'?: ISorterExpression;
  'zeilen.bundesland'?: ISorterExpression;
  'zeilen.plz'?: ISorterExpression;
  'zeilen.region'?: ISorterExpression;
  'zeilen.kreisStadt'?: ISorterExpression;
  'zeilen.gemeindeBezirk'?: ISorterExpression;
  'zeilen.ortsStadtTeil'?: ISorterExpression;
  'zeilen.gemeindeschluessel'?: ISorterExpression;
  'zeilen.teilmarkt'?: ISorterExpression;
  'zeilen.bereiche'?: ISorterExpression;
  'zeilen.assetklasse'?: ISorterExpression;
  'zeilen.objektart'?: ISorterExpression;
  'zeilen.zustand'?: ISorterExpression;
  'zeilen.vertrag'?: ISorterExpression;
  'zeilen.wohnlage'?: ISorterExpression;
  'zeilen.wflVon'?: ISorterExpression;
  'zeilen.wflBis'?: ISorterExpression;
  'zeilen.wflMittel'?: ISorterExpression;
  'zeilen.bjVon'?: ISorterExpression;
  'zeilen.bjBis'?: ISorterExpression;
  'zeilen.wohnNutzWert'?: ISorterExpression;
  'zeilen.ausstattung'?: ISorterExpression;
  'zeilen.artDesWertes'?: ISorterExpression;
  'zeilen.einheit'?: ISorterExpression;
  'zeilen.bezug'?: ISorterExpression;
  'zeilen.durchschnitt'?: ISorterExpression;
  'zeilen.minimum'?: ISorterExpression;
  'zeilen.maximum'?: ISorterExpression;
  'zeilen.spitzenwerte'?: ISorterExpression;
  'zeilen.anzahl'?: ISorterExpression;
  'zeilen.steigerung_1'?: ISorterExpression;
  'zeilen.steigerung_2'?: ISorterExpression;
  'zeilen.steigerung_3'?: ISorterExpression;
  'zeilen.steigerung_4'?: ISorterExpression;
  'zeilen.steigerung_5'?: ISorterExpression;
  'zeilen.steigerungArt'?: ISorterExpression;
}

export const BerichtQuerySorterConfig: ISorterConfig = [
  SorterConfig('bericht.id', 'MARKT.Models.BERICHT.Propertys.ID.title'), 
  SorterConfig('bericht.excel', 'MARKT.Models.BERICHT.Propertys.EXCEL.title'), 
  SorterConfig('bericht.art', 'MARKT.Models.BERICHT.Propertys.ART.title'), 
  SorterConfig('bericht.herausgeber', 'MARKT.Models.BERICHT.Propertys.HERAUSGEBER.title'), 
  SorterConfig('bericht.quelle', 'MARKT.Models.BERICHT.Propertys.QUELLE.title'), 
  SorterConfig('bericht.jahr', 'MARKT.Models.BERICHT.Propertys.JAHR.title'), 
  SorterConfig('bericht.quartal', 'MARKT.Models.BERICHT.Propertys.QUARTAL.title'), 
  SorterConfig('bericht.monat', 'MARKT.Models.BERICHT.Propertys.MONAT.title'), 
  SorterConfig('bericht.kommentar', 'MARKT.Models.BERICHT.Propertys.KOMMENTAR.title'), 
  SorterConfig('zeilen.id', 'MARKT.Models.ZEILE.Propertys.ID.title'), 
  SorterConfig('zeilen.bericht', 'MARKT.Models.ZEILE.Propertys.BERICHT.title'), 
  SorterConfig('zeilen.land', 'MARKT.Models.ZEILE.Propertys.LAND.title'), 
  SorterConfig('zeilen.bundesland', 'MARKT.Models.ZEILE.Propertys.BUNDESLAND.title'), 
  SorterConfig('zeilen.plz', 'MARKT.Models.ZEILE.Propertys.PLZ.title'), 
  SorterConfig('zeilen.region', 'MARKT.Models.ZEILE.Propertys.REGION.title'), 
  SorterConfig('zeilen.kreisStadt', 'MARKT.Models.ZEILE.Propertys.KREIS_STADT.title'), 
  SorterConfig('zeilen.gemeindeBezirk', 'MARKT.Models.ZEILE.Propertys.GEMEINDE_BEZIRK.title'), 
  SorterConfig('zeilen.ortsStadtTeil', 'MARKT.Models.ZEILE.Propertys.ORTS_STADT_TEIL.title'), 
  SorterConfig('zeilen.gemeindeschluessel', 'MARKT.Models.ZEILE.Propertys.GEMEINDESCHLUESSEL.title'), 
  SorterConfig('zeilen.teilmarkt', 'MARKT.Models.ZEILE.Propertys.TEILMARKT.title'), 
  SorterConfig('zeilen.bereiche', 'MARKT.Models.ZEILE.Propertys.BEREICHE.title'), 
  SorterConfig('zeilen.assetklasse', 'MARKT.Models.ZEILE.Propertys.ASSETKLASSE.title'), 
  SorterConfig('zeilen.objektart', 'MARKT.Models.ZEILE.Propertys.OBJEKTART.title'), 
  SorterConfig('zeilen.zustand', 'MARKT.Models.ZEILE.Propertys.ZUSTAND.title'), 
  SorterConfig('zeilen.vertrag', 'MARKT.Models.ZEILE.Propertys.VERTRAG.title'), 
  SorterConfig('zeilen.wohnlage', 'MARKT.Models.ZEILE.Propertys.WOHNLAGE.title'), 
  SorterConfig('zeilen.wflVon', 'MARKT.Models.ZEILE.Propertys.WFL_VON.title'), 
  SorterConfig('zeilen.wflBis', 'MARKT.Models.ZEILE.Propertys.WFL_BIS.title'), 
  SorterConfig('zeilen.wflMittel', 'MARKT.Models.ZEILE.Propertys.WFL_MITTEL.title'), 
  SorterConfig('zeilen.bjVon', 'MARKT.Models.ZEILE.Propertys.BJ_VON.title'), 
  SorterConfig('zeilen.bjBis', 'MARKT.Models.ZEILE.Propertys.BJ_BIS.title'), 
  SorterConfig('zeilen.wohnNutzWert', 'MARKT.Models.ZEILE.Propertys.WOHN_NUTZ_WERT.title'), 
  SorterConfig('zeilen.ausstattung', 'MARKT.Models.ZEILE.Propertys.AUSSTATTUNG.title'), 
  SorterConfig('zeilen.artDesWertes', 'MARKT.Models.ZEILE.Propertys.ART_DES_WERTES.title'), 
  SorterConfig('zeilen.einheit', 'MARKT.Models.ZEILE.Propertys.EINHEIT.title'), 
  SorterConfig('zeilen.bezug', 'MARKT.Models.ZEILE.Propertys.BEZUG.title'), 
  SorterConfig('zeilen.durchschnitt', 'MARKT.Models.ZEILE.Propertys.DURCHSCHNITT.title'), 
  SorterConfig('zeilen.minimum', 'MARKT.Models.ZEILE.Propertys.MINIMUM.title'), 
  SorterConfig('zeilen.maximum', 'MARKT.Models.ZEILE.Propertys.MAXIMUM.title'), 
  SorterConfig('zeilen.spitzenwerte', 'MARKT.Models.ZEILE.Propertys.SPITZENWERTE.title'), 
  SorterConfig('zeilen.anzahl', 'MARKT.Models.ZEILE.Propertys.ANZAHL.title'), 
  SorterConfig('zeilen.steigerung_1', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_1.title'), 
  SorterConfig('zeilen.steigerung_2', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_2.title'), 
  SorterConfig('zeilen.steigerung_3', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_3.title'), 
  SorterConfig('zeilen.steigerung_4', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_4.title'), 
  SorterConfig('zeilen.steigerung_5', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_5.title'), 
  SorterConfig('zeilen.steigerungArt', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_ART.title'), 
];
