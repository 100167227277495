import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostKrankheitCommandShapes, IPostKrankheitRequest, IPostKrankheitResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostKrankheitCommandService extends PostCommandService<IPostKrankheitRequest, IPostKrankheitResponse, IPostKrankheitCommandShapes> {
  route = 'zeiten/krankheit';

  public override async request(payload: IPostKrankheitRequest): Promise<IPostKrankheitResponse> {
    return this.http.upload<IPostKrankheitResponse>(this.route, payload, 'krankheiten');
  }
}
