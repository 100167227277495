import { NgModule } from '@angular/core';

import { DeleteObjektNutzungCommandModule, ObjektNutzungsQueryModule, PostObjektNutzungCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostObjektNutzungCommandModule, DeleteObjektNutzungCommandModule, ObjektNutzungsQueryModule],
  exports: [PostObjektNutzungCommandModule, DeleteObjektNutzungCommandModule, ObjektNutzungsQueryModule],
})
export class ObjektNutzungsSettingsModule {}
