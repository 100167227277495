<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="mitarbeiter.search$.next($event)" [search]="mitarbeiter.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-mitarbeiter-filter [style.width]="'100%'"></fa-kt-mitarbeiter-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-mitarbeiter-sorter [style.width]="'100%'"></fa-kt-mitarbeiter-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-mitarbeiter-list [style.width]="'100%'" (select)="setMitarbeiter($event)" [loading]="$loading | async"></fa-kt-mitarbeiter-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="rechnungs-vorlagen.search$.next($event)" [search]="rechnungs-vorlagen.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-rechnungs-vorlagen-filter [style.width]="'100%'"></fa-kt-rechnungs-vorlagen-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-rechnungs-vorlagen-sorter [style.width]="'100%'"></fa-kt-rechnungs-vorlagen-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-rechnungs-vorlagen-list [style.width]="'100%'" (select)="setRechnungsVorlagen($event)" [loading]="$loading | async"></fa-kt-rechnungs-vorlagen-list>


  <fa-kt-post-rechnungs-vorlage [style.width]="'100%'" [value]="$postRechnungsVorlage | async" (finished)="finishedPostRechnungsVorlage($event)"></fa-kt-post-rechnungs-vorlage>

  <fa-kt-post-rechnung [style.width]="'100%'" [value]="$postRechnung | async" (finished)="finishedPostRechnung($event)"></fa-kt-post-rechnung>

  <fa-kt-post-rechnungen-hochzaehlen [style.width]="'100%'" [value]="$postRechnungenHochzaehlen | async" (finished)="finishedPostRechnungenHochzaehlen($event)"></fa-kt-post-rechnungen-hochzaehlen>

  <fa-kt-delete-rechnungs-vorlage [style.width]="'100%'" [value]="$deleteRechnungsVorlage | async" (finished)="finishedDeleteRechnungsVorlage($event)"></fa-kt-delete-rechnungs-vorlage>



-->
<!--- << custom    -->
<nz-list *ngIf="rechnungen" nzSize="large" [nzLoading]="$loading | async">
  <nz-list-item *ngFor="let rechnung of rechnungen" (click)="openRechnung(rechnung)">
    <nz-list-item-meta [nzDescription]="ListDescription">
      <ng-template #ListDescription> </ng-template>
      <nz-list-item-meta-title>
        <a>
          <span nz-typography nzType="secondary">{{ rechnung.bezeichnung }}</span>
          <nz-divider nzType="vertical"></nz-divider>
          {{ rechnung.nettoSumme | currency: 'EUR' : 'symbol' : '' : 'de' : '' : 'de' }} Netto
        </a>
      </nz-list-item-meta-title>
    </nz-list-item-meta>
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
  <nz-list-item>
    <button nz-button (click)="openRechnung({})" nzType="dashed" [disabled]="!projekt">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Rechnung hinzufügen
    </button>
  </nz-list-item>
</nz-list>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="openRechnung(null)" [nzVisible]="!!($postRechnung | async)">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> Rechnung </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
      <nz-page-header-extra>
        <nz-space>
          <button *nzSpaceItem nz-button nzType="dashed" type="button" (click)="download()" [nzLoading]="$loading | async" [disabled]="!($valid | async)">
            <i nz-icon nzType="download" nzTheme="outline"></i>
            Herunterladen
          </button>
          <fa-kt-delete-rechnung *nzSpaceItem [value]="$deleteRechnung | async" (finished)="finishedDeleteRechnung()"></fa-kt-delete-rechnung>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-post-rechnung
        [projekt]="projekt"
        [kunde]="kunde"
        [abteilung]="abteilung"
        [gutachtenResponse]="gutachten"
        [mitarbeiter]="(mitarbeiter.result$ | async)?.mitarbeiter"
        [standorte]="(standorte.result$ | async)?.standorts"
        [value]="$postRechnung | async"
        [herunterladen]="$download | async"
        (valid)="$valid.next($event)"
        (finished)="finishedPostRechnung($event)"
        [style.width]="'100%'"
      ></fa-kt-post-rechnung>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
    <pbc-meta [value]="($postRechnung | async)?.rechnung"></pbc-meta>
  </ng-template>
</nz-modal>
<!---    custom >> -->
