import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IHerausgebersQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   website?: ISorterExpression;
   outlookKontaktes?: ISorterExpression;
   kommentar?: ISorterExpression;
}

export const HerausgebersQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'MARKT.Models.HERAUSGEBER.Propertys.ID.title'),
   SorterConfig('name', 'MARKT.Models.HERAUSGEBER.Propertys.NAME.title'),
   SorterConfig('website', 'MARKT.Models.HERAUSGEBER.Propertys.WEBSITE.title'),
   SorterConfig('outlookKontaktes', 'MARKT.Models.HERAUSGEBER.Propertys.OUTLOOK_KONTAKTES.title'),
   SorterConfig('kommentar', 'MARKT.Models.HERAUSGEBER.Propertys.KOMMENTAR.title'),
];
