<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="krankheit">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="bescheinigt" />
      <input class="cdk-visually-hidden" formControlName="bescheinigung" />
      <input class="cdk-visually-hidden" formControlName="outlook" />

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">Mitarbeiter/-in</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
          <ng-template #MitarbeiterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Mitarbeiter/-in auswählen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="meta.users$ | async" placeholder="Mitarbeiter/-in" cdkFocusInitial required="true"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="von">Von & Bis</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
          <ng-template #DatumErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Von & Bis pflegen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-input-group>
            <nz-date-picker formControlName="von" id="von" nzFormat="dd.MM.yyyy"></nz-date-picker>
            <span nz-typography> - </span>
            <nz-date-picker formControlName="bis" id="bis" nzFormat="dd.MM.yyyy"></nz-date-picker>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">Kommentar</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <nz-textarea-count [nzMaxCharacterCount]="100">
            <textarea rows="2" formControlName="kommentar" id="kommentar" [placeholder]="'Kommentar'" nz-input></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>

      <!-- <nz-form-item style="width: 100%">
        <pbc-file-upload style="width: 100%" formControlName="bescheinigung" id="bescheinigung" icon="file" text="Bescheinigung" hint="Bitte Bescheinigung hinterlegen"></pbc-file-upload>
      </nz-form-item> -->
    </div>

    <nz-form-item>
      <nz-form-control>
        <nz-space class="save-button">
          <button *nzSpaceItem nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
            Speichern
            <ng-template #SubmitTooltip> Krankheit speichern </ng-template>
          </button>

          <ng-container *ngIf="(auth.$isOM | async) && ($initial | async)?.krankheit?.id && !($initial | async)?.krankheit?.bescheinigt">
            <button *nzSpaceItem nz-button type="button" (click)="confirm()" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
              <span nz-icon nzType="check-circle" nzTheme="outline"></span> Bescheinigen
            </button>
          </ng-container>
        </nz-space>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
