import { NgModule } from '@angular/core';

import {
  PostObjektCommandServiceModule,
  PostObjektCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostObjektCommandServiceModule,
    PostObjektCommandComponentModule
  ],
  exports: [
    PostObjektCommandServiceModule,
    PostObjektCommandComponentModule
  ]
})
export class PostObjektCommandModule { }
