import { NgModule } from '@angular/core';

import {
  DeleteRolleCommandServiceModule,
  DeleteRolleCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteRolleCommandServiceModule,
    DeleteRolleCommandComponentModule
  ],
  exports: [
    DeleteRolleCommandServiceModule,
    DeleteRolleCommandComponentModule
  ]
})
export class DeleteRolleCommandModule { }
