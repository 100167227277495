import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektStatiServiceModule } from '..';
import { ProjektStatiListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektStatiServiceModule
  ],
  declarations: [ProjektStatiListComponent],
  exports: [ProjektStatiListComponent],

})
export class ProjektStatiListComponentModule { }
