import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IDashboardRequest, IDashboardResponse, ISollIstRequest, ISollIstResponse } from 'fa-kt.types';
import { SollIstService } from '../../../auswertungen';
import { DashboardService } from '../../querys';

export interface IDashboardPageResolverResult {
  dashboard?: IDashboardResponse | void;
  sollIst?: ISollIstResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardPageResolver implements Resolve<IDashboardPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private dashboard: DashboardService,
    private sollIst: SollIstService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IDashboardPageResolverResult> {
    this.loading.resolving();
    const requests = {
      dashboard: {} as IDashboardRequest,
      sollIst: {} as ISollIstRequest,
    };
    /* << custom    */
    requests.dashboard.mitarbeiter = this.auth.$id.getValue() as string;
    requests.sollIst.mitarbeiter = this.sollIst.payload$.getValue()?.mitarbeiter || (this.auth.$id.getValue() as string);
    /*    custom >> */
    const log = (error: any) => console.error(error);
    const [dashboard, sollIst] = await Promise.all([this.dashboard.request(requests.dashboard).catch(log), this.sollIst.request(requests.sollIst).catch(log)]);
    this.loading.resolved();
    return {
      dashboard,
      sollIst,
    };
  }
}
