import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FeldKategoriesServiceModule } from '..';
import { FeldKategoriesFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldKategoriesServiceModule
  ],
  declarations: [FeldKategoriesFilterComponent],
  exports: [FeldKategoriesFilterComponent],

})
export class FeldKategoriesFilterComponentModule { }
