import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DashboardServiceModule } from '..';
import { DashboardDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DashboardServiceModule
  ],
  declarations: [DashboardDetailComponent],
  exports: [DashboardDetailComponent],

})
export class DashboardDetailComponentModule { }
