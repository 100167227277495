import { Component } from '@angular/core';
import { FeldService } from '../service';

@Component({
  selector: 'fa-kt-saved-feld-filters',
  templateUrl: './saved-feld-filters.component.html',
  styleUrls: ['./saved-feld-filters.component.css'],
})
export class SavedFeldFiltersComponent {
  constructor(public feld: FeldService) {}
}
