import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IBewertungsAnlasssQueryFilter, IBewertungsAnlasssQueryShapes, IBewertungsAnlasssQuerySorter, IBewertungsAnlasssRequest, IBewertungsAnlasssResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class BewertungsAnlasssService extends SettingsQueryService<
  IBewertungsAnlasssRequest,
  IBewertungsAnlasssResponse,
  IBewertungsAnlasssQueryShapes,
  IBewertungsAnlasssQueryFilter,
  IBewertungsAnlasssQuerySorter
> {
  contentKey: keyof IBewertungsAnlasssResponse = 'bewertungsAnlasss';
  route = 'gutachten/settings/bewertungs-anlasss';
  override dependencies = ['gutachten/written-bewertungs-anlass', 'gutachten/removed-bewertungs-anlass'];
}
