import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { orderBy } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import {
  IDeleteFeldOptionRequest,
  IDeleteFeldOptionResponse,
  IDeleteFeldRequest,
  IDeleteFeldResponse,
  IFeld,
  IFeldOption,
  IPostFeldOptionRequest,
  IPostFeldOptionResponse,
  IPostFeldRequest,
  IPostFeldResponse,
  IPostOrdneFeldOptionenRequest,
  IPostOrdneFeldOptionenResponse,
} from 'fa-kt.types';
import { IFeldPageResolverResult } from '.';
import { DeleteFeldCommandService, DeleteFeldOptionCommandService, PostFeldCommandService, PostFeldOptionCommandService, PostOrdneFeldOptionenCommandService } from '../../commands';
import { FeldService } from '../../querys';

@Component({
  selector: 'fa-kt-feld-page',
  templateUrl: './feld.page.html',
  styleUrls: ['./feld.page.css'],
})
export class FeldPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postFeldOption: BehaviorSubject<IPostFeldOptionRequest | null> = new BehaviorSubject<IPostFeldOptionRequest | null>(null);
  public readonly $deleteFeld: BehaviorSubject<IDeleteFeldRequest | null> = new BehaviorSubject<IDeleteFeldRequest | null>(null);
  public readonly $postOrdneFeldOptionen: BehaviorSubject<IPostOrdneFeldOptionenRequest | null> = new BehaviorSubject<IPostOrdneFeldOptionenRequest | null>(null);
  public readonly $postFeld: BehaviorSubject<IPostFeldRequest | null> = new BehaviorSubject<IPostFeldRequest | null>(null);
  public readonly $deleteFeldOption: BehaviorSubject<IDeleteFeldOptionRequest | null> = new BehaviorSubject<IDeleteFeldOptionRequest | null>(null);

  public _feld: ISitemapPage;
  public _felder: ISitemapPage;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public feld: FeldService,
    public postFeldOption: PostFeldOptionCommandService,
    public deleteFeld: DeleteFeldCommandService,
    public postOrdneFeldOptionen: PostOrdneFeldOptionenCommandService,
    public postFeld: PostFeldCommandService,
    public deleteFeldOption: DeleteFeldOptionCommandService,
  ) {
    this.page = this.sitemap.FELDER.Pages.FELD;
    this._feld = sitemap.FELDER.Pages.FELD;
    this._felder = sitemap.FELDER.Pages.FELDER;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IFeldPageResolverResult = data.querys;
          /* << resolver    */
          if (querys && querys.feld && querys.feld.feld) {
            this.$postFeld.next({ feld: querys.feld.feld });
            if (querys.feld.feld.id && querys.feld.feld.id !== 'new') {
              this.$deleteFeld.next({ id: querys.feld.feld.id });
            } else {
              this.$deleteFeld.next({ id: undefined });
            }
          } else {
            await this.navigateToFelder();
          }
          /*    resolver >> */
        }),
        /* << subscriptions    */
        this.feld.result$.subscribe((result) => {
          if (!result) return;
          this.$postFeld.next({ feld: result.feld });
          if (result.feld.id && result.feld.id !== 'new') {
            this.$deleteFeld.next({ id: result.feld.id });
          } else {
            this.$deleteFeld.next({ id: undefined });
          }
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setFeld(id: string) {
    /* << set-feld    */
    /*    set-feld >> */
  }

  async finishedPostFeldOption(response?: IPostFeldOptionResponse) {
    /* << after post-feld-option    */
    this.openFeldOption(null);
    /*    after post-feld-option >> */
  }

  async finishedDeleteFeld(response?: IDeleteFeldResponse) {
    /* << after delete-feld    */
    this.feld.payload$.next(undefined);
    this.feld.result$.next(undefined);
    await this.navigateToFelder();
    /*    after delete-feld >> */
  }

  async finishedPostOrdneFeldOptionen(response?: IPostOrdneFeldOptionenResponse) {
    /* << after post-ordne-feld-optionen    */
    /*    after post-ordne-feld-optionen >> */
  }

  async finishedPostFeld(response?: IPostFeldResponse) {
    await this.feld.request({ id: response.feld.id });
    this.navigateToFeld({ id: response.feld.id });
  }

  async finishedDeleteFeldOption(response?: IDeleteFeldOptionResponse) {
    /* << after delete-feld-option    */
    this.openFeldOption(null);
    /*    after delete-feld-option >> */
  }

  public async navigateToFelder(queryParams: Params = {}) {
    await this.router.navigate(this._felder.url, { queryParams });
  }
  public async navigateToFeld(queryParams: Params = {}) {
    await this.router.navigate(this._feld.url, { queryParams });
  }

  back() {
    this.navigateToFelder();
  }

  /* << methods    */
  feldHasOptionen(feld: IFeld) {
    return feld && feld.id && (feld.art === 'option' || feld.art === 'optionPlus' || feld.art === 'mehrfachauswahlPlus');
  }

  openFeldOption(feldOption: IFeldOption | null | any) {
    if (!feldOption) {
      this.$postFeldOption.next(null);
      this.$deleteFeldOption.next(null);
      return;
    }
    if (this.feld.result$.getValue()?.feld.id) feldOption.feld = this.feld.result$.getValue()?.feld.id;
    if (!feldOption.order) feldOption.order = this.feld.result$.getValue()?.feldOptionen?.length + 1 || 1;
    this.$postFeldOption.next({ feldOption });
    if (feldOption && feldOption.id) {
      this.$deleteFeldOption.next({ id: feldOption.id });
    } else {
      this.$deleteFeldOption.next(null);
    }
  }

  async drop(event: CdkDragDrop<string[]>) {
    let $optionen = this.feld.result$.getValue()?.feldOptionen;
    $optionen = orderBy($optionen, 'order');
    moveItemInArray($optionen, event.previousIndex, event.currentIndex);
    const feldOptionen = $optionen.filter((b) => b.option).map((option, order) => ({ ...option, order: order + 1 }));
    await this.submitPostOrdneFeldOptionen({ feldOptionen });
  }
  /*    methods >> */

  public async submitPostFeldOption(request?: IPostFeldOptionRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postFeldOption.getValue();
    if (payload) {
      try {
        const response = await this.postFeldOption.request(payload);
        await this.finishedPostFeldOption(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteFeld(request?: IDeleteFeldRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteFeld.getValue();
    if (payload) {
      try {
        const response = await this.deleteFeld.request(payload);
        await this.finishedDeleteFeld(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostOrdneFeldOptionen(request?: IPostOrdneFeldOptionenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postOrdneFeldOptionen.getValue();
    if (payload) {
      try {
        const response = await this.postOrdneFeldOptionen.request(payload);
        await this.finishedPostOrdneFeldOptionen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostFeld(request?: IPostFeldRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postFeld.getValue();
    if (payload) {
      try {
        const response = await this.postFeld.request(payload);
        await this.finishedPostFeld(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteFeldOption(request?: IDeleteFeldOptionRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteFeldOption.getValue();
    if (payload) {
      try {
        const response = await this.deleteFeldOption.request(payload);
        await this.finishedDeleteFeldOption(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
