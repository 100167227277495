import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { DeleteFondCommandModule, PostFondCommandModule } from '../../commands';

import { FondsComponent } from '.';

@NgModule({
  imports: [LIB_CommonModule, PostFondCommandModule, DeleteFondCommandModule],
  declarations: [FondsComponent],
  exports: [FondsComponent],
})
export class FondsComponentModule {}
