import { NgModule } from '@angular/core';

import {
  DeleteGutachtenStatusCommandServiceModule,
  DeleteGutachtenStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteGutachtenStatusCommandServiceModule,
    DeleteGutachtenStatusCommandComponentModule
  ],
  exports: [
    DeleteGutachtenStatusCommandServiceModule,
    DeleteGutachtenStatusCommandComponentModule
  ]
})
export class DeleteGutachtenStatusCommandModule { }
