import { NgModule } from '@angular/core';
import {
  TourStatiFilterComponentModule,
  TourStatiSorterComponentModule,
  TourStatiListComponentModule
} from '.';

@NgModule({
  imports: [
    TourStatiFilterComponentModule,
    TourStatiSorterComponentModule,
    TourStatiListComponentModule
  ],
  exports: [
    TourStatiFilterComponentModule,
    TourStatiSorterComponentModule,
    TourStatiListComponentModule
  ],
})
export class TourStatiQueryModule { }