import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBereichCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostBereichCommandService],
  exports: []
})
export class PostBereichCommandServiceModule { }
