<nz-row style="width: 100%; margin-top: 16px; margin-bottom: 16px" cdkDropList (cdkDropListDropped)="reorder($event)" cdkDropListOrientation="horizontal">
  <nz-col *ngFor="let sorter of sorter$ | async; let i = index" nzFlex cdkDrag class="sorter-item-container">
    <nz-input-group class="sorter-item" [nzAddOnBefore]="addOnBeforeTemplate" [nzAddOnAfter]="addOnAfterTemplate" nzCompact>
      <ng-template #addOnBeforeTemplate>
        <span>{{ sorter.title }}</span>
      </ng-template>
      <button [disabled]="sorter.readonly" nz-button class="sorter-item-selection" style="width: 100%; padding-left: 6px; padding-right: 6px" (click)="toggleAscending(i)">
        <ng-container [ngSwitch]="_sorter[i].ascending">
          <i *ngSwitchCase="true" nz-icon nzType="arrow-down" nzTheme="outline"></i>
          <i *ngSwitchDefault nz-icon nzType="arrow-up" nzTheme="outline"></i>
        </ng-container>
      </button>
      <ng-template #addOnAfterTemplate>
        <a *ngIf="!sorter.readonly" (click)="removeSorter(sorter.key)"><i nz-icon nzType="close-circle" nzTheme="outline" [style.color]="'red'"></i></a>
      </ng-template>
    </nz-input-group>
  </nz-col>
  <nz-col *ngIf="(($unused | async)?.length || 0) > 0" nzFlex>
    <nz-select class="sorter-selection" nzShowSearch nzAllowClear nzPlaceHolder="Neue Sortierung" [ngModel]="$newSorterField | async" (ngModelChange)="$newSorterField.next($event)">
      <nz-option *ngFor="let sorter of $unused | async" [nzLabel]="sorter.label!" [nzValue]="sorter.value"></nz-option>
    </nz-select>
  </nz-col>
</nz-row>
