import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ActionService, cleanObject, CustomFormatter, CustomValidators, ISitemap, ISitemapCommand, ISitemapModel, SITEMAP } from 'pbc.angular';
import { toInitials } from 'pbc.functions';
import { ISelection } from 'pbc.types';

import { IObjektArt, IPostObjektArtResponse } from 'fa-kt.types';
import { ErforderlichesDokumentsService } from '../../../../../../dokumente';
import { ObjektArtsService } from '../../../query';
import { PostObjektArtCommandService } from '../service';

interface IFormValue {
  objektArt?: IObjektArt;
}

@Component({
  selector: 'fa-kt-post-objekt-art',
  templateUrl: './post-objekt-art.component.html',
  styleUrls: ['./post-objekt-art.component.css'],
})
export class PostObjektArtCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;
  model!: ISitemapModel;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IFormValue) {
    this.patch(value);
  }
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostObjektArtResponse> = new EventEmitter<IPostObjektArtResponse>();

  $dokumente = new BehaviorSubject<ISelection[]>([]);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private actions: ActionService,
    private message: NzMessageService,
    public objektArts: ObjektArtsService,
    public postObjektArt: PostObjektArtCommandService,
    public erforderlicheDokumente: ErforderlichesDokumentsService /* << dependencies    */ /*    dependencies >> */,
  ) {
    this.command = sitemap.OBJEKTE.Commands.POST_OBJEKT_ART;
    this.model = sitemap.OBJEKTE.Models.OBJEKT_ART;
    /* << constructor    */
    /*    constructor >> */
    this.form = this.fb.group({
      objektArt: this.fb.group({
        id: [null, []],
        objektNutzung: [null, [Validators.required]],
        name: [null, [Validators.required]],
        initialen: [null, [Validators.required]],
        besichtigungsZeit: [null, []],
        erforderlicheDokumente: [[], []],
        beschreibung: [null, []],
        color: [null, []],
        icon: [null, []],
        order: [null, []],
      }),
    });
    this.initialen?.setValidators([Validators.required, CustomValidators.isUnique(this.objektArts.initialenIsUnique(this.id))]);
    this.patch();
  }

  ngOnInit() {
    this.erforderlicheDokumente.request({}).catch(console.error);
    this.subscriptions.push(
      this.erforderlicheDokumente.result$.subscribe((result) => {
        if (!result) {
          return;
        }
        this.$dokumente.next(
          result.erforderlichesDokuments.map((dokument) => ({
            value: dokument.id,
            label: dokument.name,
          })),
        );
      }),
      this.form.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.form.valid);
        if (this.form.valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.id?.valueChanges.subscribe(async (id) => {
        /* << on-id-change    */
        /*    on-id-change >> */
      }) as Subscription,
      this.objektNutzung?.valueChanges.subscribe(async (objektNutzung) => {
        /* << on-objekt-nutzung-change    */
        /*    on-objekt-nutzung-change >> */
      }) as Subscription,
      this.name?.valueChanges.subscribe(async (name) => {
        /* << on-name-change    */
        /*    on-name-change >> */
      }) as Subscription,
      this.initialen?.valueChanges.subscribe(async (initialen) => {
        /* << on-initialen-change    */
        /*    on-initialen-change >> */
      }) as Subscription,
      this.besichtigungsZeit?.valueChanges.subscribe(async (besichtigungsZeit) => {
        /* << on-besichtigungs-zeit-change    */
        /*    on-besichtigungs-zeit-change >> */
      }) as Subscription,
      this.beschreibung?.valueChanges.subscribe(async (beschreibung) => {
        /* << on-beschreibung-change    */
        /*    on-beschreibung-change >> */
      }) as Subscription,
      /* << subscibe    */
      this.form.controls.objektArt
        ?.get('name')
        ?.valueChanges.pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((name: string) => {
          const initialen = this.form.controls.objektArt?.get('initialen');
          if (initialen && (!initialen.touched || !initialen.value)) {
            this.form.patchValue({ objektArt: { initialen: toInitials(name) } });
          }
        }) as Subscription,
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IFormValue) {
    let objektArt = {
      id: null,
      objektNutzung: null,
      name: null,
      initialen: null,
      besichtigungsZeit: null,
      beschreibung: null,
      order: 0,
      color: '',
      icon: '',
    };
    if (value && value.objektArt) {
      objektArt = {
        ...objektArt,
        ...cleanObject(value.objektArt),
      };
    }
    this.form.patchValue({ objektArt });
    /* << custom-stuff    */
    /*    custom-stuff >> */
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload && payload.objektArt) {
        payload.objektArt = cleanObject(payload.objektArt);
      }
      const response: IPostObjektArtResponse | undefined = await this.postObjektArt.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls.objektArt.get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get id() {
    return this.form.controls.objektArt.get('id');
  }

  get objektNutzung() {
    return this.form.controls.objektArt.get('objektNutzung');
  }

  get name() {
    return this.form.controls.objektArt.get('name');
  }

  get initialen() {
    return this.form.controls.objektArt.get('initialen');
  }

  get besichtigungsZeit() {
    return this.form.controls.objektArt.get('besichtigungsZeit');
  }

  get beschreibung() {
    return this.form.controls.objektArt.get('beschreibung');
  }
}
