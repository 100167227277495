import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { SicherheitscheckComponent } from '.';
import { UmkreissucheQueryModule } from '../../querys';

@NgModule({
  imports: [LIB_CommonModule, UmkreissucheQueryModule],
  declarations: [SicherheitscheckComponent],
  exports: [SicherheitscheckComponent],
})
export class SicherheitscheckComponentModule {}
