import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  DateFilterConfig,
  GeoRangeFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IDateFilter,
  IFilter,
  IFilterConfig,
  IGeoRangeFilter,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IMitarbeiter, ITourStatus } from 'fa-kt.types';
import { IDashboardResponse } from '.';

export interface IDashboardQueryFilter extends IFilter<IDashboardResponse> {
  'erinnerungen.am'?: IDateFilter;
  'erinnerungen.mitarbeiter'?: IArrayReferenceFilter<IMitarbeiter>;
  'erinnerungen.verfasser'?: ISingleReferenceFilter<IMitarbeiter>;
  'tour.tourStatus'?: ISingleReferenceFilter<ITourStatus>;
  'tour.mitarbeiter'?: ISingleReferenceFilter<IMitarbeiter>;
  'tour.am'?: IDateFilter;
  'tour.startIstPrivatAdresse'?: IBooleanFilter;
  'tour.endeIstPrivatAdresse'?: IBooleanFilter;
  'tour.start'?: IGeoRangeFilter;
  'tour.ende'?: IGeoRangeFilter;
  'tour.strecke'?: INumberFilter;
  'tour.kosten'?: INumberFilter;
}

export const DashboardQueryFilterConfig: IFilterConfig<IDashboardResponse> = [
  DateFilterConfig('erinnerungen.am', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.AM.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('erinnerungen.mitarbeiter', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.MITARBEITER.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('erinnerungen.verfasser', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.VERFASSER.title'),
  SingleReferenceFilterConfig<ITourStatus>('tour.tourStatus', 'BESICHTIGUNGEN.Models.TOUR.Propertys.TOUR_STATUS.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('tour.mitarbeiter', 'BESICHTIGUNGEN.Models.TOUR.Propertys.MITARBEITER.title'),
  DateFilterConfig('tour.am', 'BESICHTIGUNGEN.Models.TOUR.Propertys.AM.title'),
  BooleanFilterConfig('tour.startIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START_IST_PRIVAT_ADRESSE.title'),
  BooleanFilterConfig('tour.endeIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE_IST_PRIVAT_ADRESSE.title'),
  GeoRangeFilterConfig('tour.start', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START.title'),
  GeoRangeFilterConfig('tour.ende', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE.title'),
  NumberFilterConfig('tour.strecke', 'BESICHTIGUNGEN.Models.TOUR.Propertys.STRECKE.title'),
  NumberFilterConfig('tour.kosten', 'BESICHTIGUNGEN.Models.TOUR.Propertys.KOSTEN.title'),
];

export interface IDashboardQueryShapes extends IShape {
  'erinnerungen.mitarbeiter': ISelection[];
  'erinnerungen.verfasser': ISelection[];
  'tour.tourStatus': ISelection[];
  'tour.mitarbeiter': ISelection[];
}

export type IDashboardQueryField =
  | 'erinnerungen.id'
  | 'erinnerungen.projekt'
  | 'erinnerungen.gutachten'
  | 'erinnerungen.kunde'
  | 'erinnerungen.kommentar'
  | 'erinnerungen.am'
  | 'erinnerungen.mitarbeiter'
  | 'erinnerungen.verfasser'
  | 'tour.id'
  | 'tour.tourStatus'
  | 'tour.mitarbeiter'
  | 'tour.am'
  | 'tour.startIstPrivatAdresse'
  | 'tour.endeIstPrivatAdresse'
  | 'tour.start'
  | 'tour.ende'
  | 'tour.strecke'
  | 'tour.kosten'
  | 'tour.abgerechnet'
  | 'tour.kommentar'
  | '';
