<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="zeit.search$.next($event)" [search]="zeit.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-zeit-filter [style.width]="'100%'"></fa-kt-zeit-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-zeit-sorter [style.width]="'100%'"></fa-kt-zeit-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-zeit-list [style.width]="'100%'" (select)="setZeit($event)" [loading]="$loading | async"></fa-kt-zeit-list>


  <fa-kt-post-zeit [style.width]="'100%'" [value]="$postZeit | async" (finished)="finishedPostZeit($event)"></fa-kt-post-zeit>

  <fa-kt-delete-zeit [style.width]="'100%'" [value]="$deleteZeit | async" (finished)="finishedDeleteZeit($event)"></fa-kt-delete-zeit>



-->
<!--- << custom    -->
<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="openZeit()" [nzVisible]="!!($postZeit | async)">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> Zeiterfassung </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-delete-zeit [style.width]="'100%'" [value]="$deleteZeit | async" (finished)="finishedDeleteZeit()"></fa-kt-delete-zeit>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <nz-col nzSpan="24">
        <fa-kt-post-zeit [style.width]="'100%'" [value]="$postZeit | async" (finished)="finishedPostZeit($event)"></fa-kt-post-zeit>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
    <pbc-meta [value]="($postZeit | async)?.zeit"></pbc-meta>
  </ng-template>
</nz-modal>
<!---    custom >> -->
