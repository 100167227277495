import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IFeldKategoriesResponse } from '.';

export interface IFeldKategoriesQueryFilter extends IFilter<IFeldKategoriesResponse> {}

export const FeldKategoriesQueryFilterConfig: IFilterConfig<IFeldKategoriesResponse> = [];

export interface IFeldKategoriesQueryShapes extends IShape {}

export type IFeldKategoriesQueryField = 'feldKategories.id' | 'feldKategories.name' | 'feldKategories.initialen' | 'feldKategories.kommentar' | '';
