import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostGutachtenStatusCommandShapes, IPostGutachtenStatusRequest, IPostGutachtenStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostGutachtenStatusCommandService extends PostCommandService<IPostGutachtenStatusRequest, IPostGutachtenStatusResponse, IPostGutachtenStatusCommandShapes> {
  readonly route = 'gutachten/settings/gutachten-status';
}
