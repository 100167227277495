<nz-collapse nzGhost style="width: 100%; margin-top: 32px; margin-bottom: 32px">
 <nz-collapse-panel
     nzHeader="Zugriff"
     [nzActive]="accessGroupPanelActive">
     <nz-spin style="width: 100%;" nzTip="Speichern..." [nzSpinning]="$savingMemberships | async">
      <form style="width: 100%;" nz-form>
         <nz-form-item style="width: 100%;">
           <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24">Gruppen</nz-form-label>
           <nz-form-control #GroupTag [nzSm]="12" [nzXs]="24">
             <nz-tag
               *ngFor="let group of auth.$ADgroups | async"
               [nzMode]="($readonly | async) ? 'default' : 'checkable'"
               [nzChecked]="group.isMember"
               [nzColor]="group.isMember ? 'processing' : group.emailOrSecurity ? 'warning' : 'default'"
               (nzCheckedChange)="handleGroupChange($event, group)">
               <i *ngIf="group.emailOrSecurity" nz-icon nzType="stop" nzTheme="outline" style="margin-right: 6px"></i>
                {{ group.displayName }}
             </nz-tag>
           </nz-form-control>
         </nz-form-item>
      </form>
     </nz-spin>
   </nz-collapse-panel>
  <nz-collapse-panel *ngIf="!($readonly | async) || ($id | async) === (auth.$id | async)"
                     nzHeader="Passwort"
                     [nzActive]="passwordPanelActive">
    <nz-row>
      <nz-col [nzOffset]="8">
        <button nz-button nzType="primary" [nzLoading]='$savingPassword | async' >
          <i nz-icon nzType='check-circle'></i>
          Zurücksetzen
        </button>
      </nz-col>
    </nz-row>
  </nz-collapse-panel>
</nz-collapse>
