import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { ZahlungseingaengeComponent } from '.';
import { DeleteZahlungseingangCommandModule, PostZahlungseingangCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostZahlungseingangCommandModule, DeleteZahlungseingangCommandModule],
  declarations: [ZahlungseingaengeComponent],
  exports: [ZahlungseingaengeComponent],
})
export class ZahlungseingaengeComponentModule {}
