import { NgModule } from '@angular/core';

import {
  PostVorlageKopierenCommandServiceModule,
  PostVorlageKopierenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostVorlageKopierenCommandServiceModule,
    PostVorlageKopierenCommandComponentModule
  ],
  exports: [
    PostVorlageKopierenCommandServiceModule,
    PostVorlageKopierenCommandComponentModule
  ]
})
export class PostVorlageKopierenCommandModule { }
