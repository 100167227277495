import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostReisekostenabrechnungStatusCommandShapes, IPostReisekostenabrechnungStatusRequest, IPostReisekostenabrechnungStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostReisekostenabrechnungStatusCommandService extends PostCommandService<
  IPostReisekostenabrechnungStatusRequest,
  IPostReisekostenabrechnungStatusResponse,
  IPostReisekostenabrechnungStatusCommandShapes
> {
  readonly route = 'finanzen/settings/reisekostenabrechnung-status';
}
