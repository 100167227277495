import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteTourStatusRequest, IDeleteTourStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteTourStatusCommandService extends DeleteCommandService<IDeleteTourStatusRequest, IDeleteTourStatusResponse> {
  readonly route = 'besichtigungen/settings/tour-status';
}
