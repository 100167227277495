import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';

import { HTTPS_METHOD, SyncableTask } from 'pbc.types';
import { HttpService } from '../http';

import Dexie from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class OfflineStoreService extends Dexie {
  cache: { [key: string]: HttpResponse<any> } = {};

  readonly schema = {
    queries: 'url, body',
    commands: '++id, url, method, body',
    wipes: 'date, url',
  };
  get queries() {
    return this.table('queries');
  }
  get commands() {
    return this.table('commands');
  }
  get wipes() {
    return this.table('wipes');
  }

  readonly commands$ = new BehaviorSubject<SyncableTask[]>([]);
  readonly loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private messages: NzMessageService,
    private modal: NzModalService,
    public http: HttpService,
  ) {
    super('fa-kt-apps');
    this.version(1).stores(this.schema);
    this.commands$.subscribe(async (tasks) => await this.commands.bulkPut(tasks));
  }

  async init() {
    await this.open();
    for (const { date: dateString, url } of await this.wipes.toArray()) {
      if (new Date(dateString) <= new Date()) await Promise.all([await this.queries.delete(url), await this.wipes.delete(dateString)]);
    }
    this.commands$.next((await this.commands.toArray()) || []);
  }

  async set(url: string, body: object, wipe?: Date) {
    if (wipe) await this.wipes.put({ wipe, url });
    await this.queries.put({ url, body });
  }

  async get(url: string): Promise<object> {
    return this.queries.get(url);
  }

  public addSyncableTask(syncableTask: SyncableTask | undefined) {
    if (syncableTask) {
      let commands = this.commands$.getValue();
      commands = commands.filter((st) => !this.matches(st, syncableTask)).concat([syncableTask]);
      this.messages.error('Keine Internetverbindung. Anfrage wurde im Speicher hinterlegt und kann synchronisiert werden');
      this.commands$.next(commands);
    }
  }

  private removeSyncableTask(syncableTask: SyncableTask) {
    let commands = this.commands$.getValue();
    commands = commands.filter((st) => !this.matches(st, syncableTask));
    this.commands$.next(commands);
  }

  async sync(): Promise<void> {
    let commands = this.commands$.getValue();
    let count = commands.length;
    if (count === 0) return;
    this.loading$.next(true);
    this.messages.info('Speicher wird synchronisiert...');
    let failed = 0;
    const sync = await Promise.all(commands.map(async (task: SyncableTask) => await this.syncTask(task, false, () => failed++)));
    while (sync.length) {
      await Promise.all(sync.splice(0, 1).map((sync) => sync()));
    }
    const success = count - failed;
    this.messages.success(
      `${success} Aufgabe${success === 1 ? '' : 'n'} wurde${success === 1 ? '' : 'n'} synchronisiert. ${
        failed > 0 ? failed + ' Aufgabe' + (failed === 1 ? '' : 'n') + ' ' + (failed === 1 ? 'muss' : 'müssen') + ' wiederholt werden.' : ''
      }`,
    );
    this.loading$.next(false);
  }

  private matches(syncableTask1: SyncableTask, syncableTask2: SyncableTask): boolean {
    return syncableTask1.method === syncableTask2.method && syncableTask1.url === syncableTask2.url && JSON.stringify(syncableTask1.body) === JSON.stringify(syncableTask2.body);
  }

  public clear() {
    const count = this.commands$.getValue().length;
    this.modal.confirm({
      nzTitle: `Wirklich löschen?`,
      nzContent: `<p>Es ${count === 1 ? 'wird' : 'werden'} ${count} noch nicht-synchronisierte Befehl${count === 1 ? '' : 'e'} aus dem Speicher gelöscht</p>`,
      nzOkText: 'Ja, restlos entfernen',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: async () => await this.commands.clear(),
      nzCancelText: 'Abbrechen',
    });
  }

  public async clearCache() {
    this.modal.confirm({
      nzTitle: `Wirklich löschen?`,
      nzContent: `<p>Der Speicher beinhaltet alle bereits geladene Anfragen <mark>der letzten Woche</mark>.</p>`,
      nzOkText: 'Ja, restlos entfernen',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: async () => {
        await Promise.all([this.commands.clear(), this.queries.clear(), this.wipes.clear()]);
        this.messages.success('Der Speicher wurde gelöscht');
      },
      nzCancelText: 'Abbrechen',
    });
  }

  async syncTask(
    task: SyncableTask,
    execute = false,
    fail: () => void = () => {
      return;
    },
  ) {
    let output;
    switch (task.method) {
      case HTTPS_METHOD.DELETE:
        output = async () => {
          try {
            await this.http.delete(task.url);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      case HTTPS_METHOD.PUT:
        output = async () => {
          try {
            await this.http.put(task.url, task.body);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      case HTTPS_METHOD.PATCH:
        output = async () => {
          try {
            await this.http.patch(task.url, task.body);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      case HTTPS_METHOD.POST:
        output = async () => {
          try {
            await this.http.post(task.url, task.body);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      default:
        output = async () => {
          this.removeSyncableTask(task);
        };
    }
    if (execute) {
      await output();
    }
    return output;
  }
}
