import { ArrayReferenceFilterConfig, GeoRangeFilterConfig, IArrayReferenceFilter, IFilter, IFilterConfig, IGeoRangeFilter, ISelection, IShape } from 'pbc.types';

import { IBereich } from 'fa-kt.types';
import { IObjekteResponse } from '.';

export interface IObjekteQueryFilter extends IFilter<IObjekteResponse> {
  'objekt.addresse'?: IGeoRangeFilter;
  'objekt.bereiche'?: IArrayReferenceFilter<IBereich>;
}

export const ObjekteQueryFilterConfig: IFilterConfig<IObjekteResponse> = [
  GeoRangeFilterConfig('objekt.addresse', 'OBJEKTE.Models.OBJEKT.Propertys.ADDRESSE.title'),
  ArrayReferenceFilterConfig<IBereich>('objekt.bereiche', 'OBJEKTE.Models.OBJEKT.Propertys.BEREICHE.title'),
];

export interface IObjekteQueryShapes extends IShape {
  'objekt.bereiche': ISelection[];
}

export type IObjekteQueryField = 'objekt.id' | 'objekt.name' | 'objekt.addresse' | 'objekt.bereiche' | 'objekt.anspracheOutlookKontakts' | 'objekt.klingelschild' | 'objekt.kommentar' | '';
