import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IBesichtigungsStatiResponse } from './';

export interface IBesichtigungsStatiQueryFilter extends IFilter<IBesichtigungsStatiResponse> {}

export const BesichtigungsStatiQueryFilterConfig: IFilterConfig<IBesichtigungsStatiResponse> = [];

export interface IBesichtigungsStatiQueryShapes extends IShape {}

export type IBesichtigungsStatiQueryField = 'besichtigungsStati.id' | 'besichtigungsStati.name' | 'besichtigungsStati.beschreibung' | '';
