import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, ISitemapModel, SITEMAP } from 'pbc.angular';

import { IErforderlichesDokument, IPostErforderlichesDokumentResponse } from 'fa-kt.types';
import { ErforderlichesDokumentsService } from '../../../query';
import { PostErforderlichesDokumentCommandService } from '../service';

interface IFormValue {
  erforderlichesDokument?: IErforderlichesDokument;
}

@Component({
  selector: 'fa-kt-post-erforderliches-dokument',
  templateUrl: './post-erforderliches-dokument.component.html',
  styleUrls: ['./post-erforderliches-dokument.component.css'],
})
export class PostErforderlichesDokumentCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;
  model!: ISitemapModel;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IFormValue) {
    this.patch(value);
  }
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostErforderlichesDokumentResponse> = new EventEmitter<IPostErforderlichesDokumentResponse>();

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private actions: ActionService,
    private message: NzMessageService,
    public erforderlichesDokuments: ErforderlichesDokumentsService,
    public postErforderlichesDokument: PostErforderlichesDokumentCommandService /* << dependencies    */ /*    dependencies >> */,
  ) {
    this.command = sitemap.DOKUMENTE.Commands.POST_ERFORDERLICHES_DOKUMENT;
    this.model = sitemap.DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT;
    /* << constructor    */
    /*    constructor >> */
    this.form = this.fb.group({
      erforderlichesDokument: this.fb.group({
        id: [null, []],
        original: [null, []],
        gutachten: [null, []],
        name: [null, [Validators.required]],
        erforderlich: [null, []],
        fortschritt: [null, []],
        kommentar: [null, []],
        order: [null, []],
      }),
    });
    this.patch();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.form.valid);
        if (this.form.valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.id?.valueChanges.subscribe(async (id) => {
        /* << on-id-change    */
        /*    on-id-change >> */
      }) as Subscription,
      this.original?.valueChanges.subscribe(async (original) => {
        /* << on-original-change    */
        /*    on-original-change >> */
      }) as Subscription,
      this.gutachten?.valueChanges.subscribe(async (gutachten) => {
        /* << on-gutachten-change    */
        /*    on-gutachten-change >> */
      }) as Subscription,
      this.name?.valueChanges.subscribe(async (name) => {
        /* << on-name-change    */
        /*    on-name-change >> */
      }) as Subscription,
      this.erforderlich?.valueChanges.subscribe(async (erforderlich) => {
        /* << on-erforderlich-change    */
        /*    on-erforderlich-change >> */
      }) as Subscription,
      this.fortschritt?.valueChanges.subscribe(async (fortschritt) => {
        /* << on-fortschritt-change    */
        /*    on-fortschritt-change >> */
      }) as Subscription,
      this.kommentar?.valueChanges.subscribe(async (kommentar) => {
        /* << on-kommentar-change    */
        /*    on-kommentar-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IFormValue) {
    let erforderlichesDokument = {
      id: null,
      original: null,
      gutachten: null,
      name: null,
      erforderlich: null,
      fortschritt: null,
      kommentar: null,
      order: 0,
    };
    if (value && value.erforderlichesDokument) {
      erforderlichesDokument = {
        ...erforderlichesDokument,
        ...cleanObject(value.erforderlichesDokument),
      };
    }
    this.form.patchValue({ erforderlichesDokument });
    /* << custom-stuff    */
    /*    custom-stuff >> */
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload && payload.erforderlichesDokument) {
        payload.erforderlichesDokument = cleanObject(payload.erforderlichesDokument);
      }
      const response: IPostErforderlichesDokumentResponse | undefined = await this.postErforderlichesDokument.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls.objektArt.get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get id() {
    return this.form.controls.erforderlichesDokument.get('id');
  }

  get original() {
    return this.form.controls.erforderlichesDokument.get('original');
  }

  get gutachten() {
    return this.form.controls.erforderlichesDokument.get('gutachten');
  }

  get name() {
    return this.form.controls.erforderlichesDokument.get('name');
  }

  get erforderlich() {
    return this.form.controls.erforderlichesDokument.get('erforderlich');
  }

  get fortschritt() {
    return this.form.controls.erforderlichesDokument.get('fortschritt');
  }

  get kommentar() {
    return this.form.controls.erforderlichesDokument.get('kommentar');
  }
}
