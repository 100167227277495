import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IBesichtigungsStatiResponse } from 'fa-kt.types';
import { PostBesichtigungsStatusCommandService } from '../commands';
import { BesichtigungsStatiService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class BesichtigungsStatiPageResolver implements Resolve<IBesichtigungsStatiResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private besichtigungsStati: BesichtigungsStatiService,
    private postBesichtigungsStatus: PostBesichtigungsStatusCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IBesichtigungsStatiResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [besichtigungsStati] = await Promise.all([this.besichtigungsStati.request({}).catch(log), this.postBesichtigungsStatus.prepare(true).catch(log)]);
    this.loading.resolved();
    return besichtigungsStati;
  }
}
