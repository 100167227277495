import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostErforderlichesDokumentCommandShapes, IPostErforderlichesDokumentRequest, IPostErforderlichesDokumentResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostErforderlichesDokumentCommandService extends PostCommandService<IPostErforderlichesDokumentRequest, IPostErforderlichesDokumentResponse, IPostErforderlichesDokumentCommandShapes> {
  readonly route = 'dokumente/settings/erforderliches-dokument';

  async getErforderlichesDokumentsGutachten(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.erforderlichesDokumentsGutachten.pipe(first()).subscribe((erforderlichesDokumentsGutachten) => resolve(erforderlichesDokumentsGutachten.find((a) => a.value === value)));
    });
  }

  get erforderlichesDokumentsGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erforderlichesDokuments.gutachten'] as ISelection[]) : [])));
  }
}
