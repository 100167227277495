import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IDateiSorter extends ISorter {
  id?: ISorterExpression;
  projekt?: ISorterExpression;
  gutachten?: ISorterExpression;
  dateiArt?: ISorterExpression;
  datei?: ISorterExpression;
  aktuell?: ISorterExpression;
}

export const DateiSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('dateiArt', 'Datei Art'),
  SorterConfig('datei', 'Datei'),
  SorterConfig('aktuell', 'Aktuell')
];
