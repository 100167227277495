import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostVorlageKopierenCommandShapes, IPostVorlageKopierenRequest, IPostVorlageKopierenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostVorlageKopierenCommandService extends PostCommandService<IPostVorlageKopierenRequest, IPostVorlageKopierenResponse, IPostVorlageKopierenCommandShapes> {
  readonly route = 'texte/vorlage-kopieren';
}
