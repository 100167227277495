import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { RechnungszaehlerComponent } from '.';
import { PostRechnungenHochzaehlenCommandModule, PostRechnungRunterzaehlenCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostRechnungRunterzaehlenCommandModule, PostRechnungenHochzaehlenCommandModule],
  declarations: [RechnungszaehlerComponent],
  exports: [RechnungszaehlerComponent],
})
export class RechnungszaehlerComponentModule {}
