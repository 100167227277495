import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostProjekteHochzaehlenCommandShapes, IPostProjekteHochzaehlenRequest, IPostProjekteHochzaehlenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostProjekteHochzaehlenCommandService extends PostCommandService<IPostProjekteHochzaehlenRequest, IPostProjekteHochzaehlenResponse, IPostProjekteHochzaehlenCommandShapes> {
  readonly route = 'projekte/projekte-hochzaehlen';
}
