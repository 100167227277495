import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostDateiArtCommandServiceModule } from '..';
import { PostDateiArtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostDateiArtCommandServiceModule
  ],
  declarations: [PostDateiArtCommandComponent],
  exports: [PostDateiArtCommandComponent],

})
export class PostDateiArtCommandComponentModule { }
