import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

export interface IAuswertungenPageResolverResult {}

@Injectable({
  providedIn: 'root',
})
export class AuswertungenPageResolver implements Resolve<IAuswertungenPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IAuswertungenPageResolverResult> {
    this.loading.resolving();
    const requests = {};

    const log = (error: any) => console.error(error);
    const [] = await Promise.all([]);
    this.loading.resolved();
    return {};
  }
}
