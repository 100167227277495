import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ObjektService } from '../service';

@Component({
  selector: 'fa-kt-objekt-detail',
  templateUrl: './objekt-detail.component.html',
  styleUrls: ['./objekt-detail.component.css'],
})
export class ObjektDetailComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  constructor(public objekt: ObjektService) {}
}
