import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IObjektArtsQuerySorter extends ISorter {
   id?: ISorterExpression;
   objektNutzung?: ISorterExpression;
   name?: ISorterExpression;
   initialen?: ISorterExpression;
   besichtigungsZeit?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const ObjektArtsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'OBJEKTE.Models.OBJEKT_ART.Propertys.ID.title'),
   SorterConfig('objektNutzung', 'OBJEKTE.Models.OBJEKT_ART.Propertys.OBJEKT_NUTZUNG.title'),
   SorterConfig('name', 'OBJEKTE.Models.OBJEKT_ART.Propertys.NAME.title'),
   SorterConfig('initialen', 'OBJEKTE.Models.OBJEKT_ART.Propertys.INITIALEN.title'),
   SorterConfig('besichtigungsZeit', 'OBJEKTE.Models.OBJEKT_ART.Propertys.BESICHTIGUNGS_ZEIT.title'),
   SorterConfig('beschreibung', 'OBJEKTE.Models.OBJEKT_ART.Propertys.BESCHREIBUNG.title'),
];
