<nz-row>
  <nz-col nzSpan="10">
    <nz-row>
      <nz-col nzSpan="24">
        <nz-space>
          <ng-container *ngIf="($select | async) !== 'preview'">
            <button *nzSpaceItem nz-button type="button" (click)="$search.next(''); $select.next('preview')" nzType="dashed">
              <span nz-icon nzType="file-text" nzTheme="outline"></span>Vorschau anzeigen
            </button>
          </ng-container>
          <ng-container *ngIf="($select | async) !== 'placeholder'">
            <button *nzSpaceItem nz-button type="button" (click)="$select.next('placeholder')" nzType="dashed"><span nz-icon nzType="info" nzTheme="outline"></span>Neuer Platzhalter</button>
          </ng-container>
          <ng-container *ngIf="($select | async) !== 'if'">
            <button *nzSpaceItem nz-button type="button" (click)="$select.next('if')" nzType="dashed"><span nz-icon nzType="question" nzTheme="outline"></span> Neue Bedingung</button>
          </ng-container>
          <ng-container *ngIf="($select | async) !== 'tree'">
            <button *nzSpaceItem nz-button type="button" (click)="$select.next('tree')" nzType="dashed"><span nz-icon nzType="question-circle" nzTheme="outline"></span> Baum anzeigen</button>
          </ng-container>
        </nz-space>
      </nz-col>
    </nz-row>
    <nz-row style="margin-bottom: 16px; margin-top: 16px" [ngSwitch]="$select | async" style="padding-right: 16px">
      <nz-col nzSpan="24" *ngSwitchCase="'preview'">
        <p *ngFor="let error of errors">
          <nz-alert nzType="error" [nzMessage]="error">
            <ng-template #error>
              <code>{{ error | json }}</code>
            </ng-template>
          </nz-alert>
        </p>
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'preview'">
        <br />
        <br />
        <div style="min-height: 400px" [innerHTML]="$rendered | async | safe"></div>
      </nz-col>

      <nz-col nzSpan="24">
        <br />
        <pbc-search-bar *ngIf="($select | async) !== 'preview'" [search]="$search | async" (searchChange)="$search.next($event)"></pbc-search-bar>
      </nz-col>

      <nz-col nzSpan="24" *ngSwitchCase="'tree'">
        <br />
        <div style="height: 400px; overflow: auto">
          <div id="baumstruktur"></div>
        </div>
      </nz-col>

      <nz-col nzSpan="24" *ngSwitchCase="'placeholder'">
        <br />
        <ul nz-menu>
          <cdk-virtual-scroll-viewport cdkScrollable [itemSize]="30" style="height: 400px">
            <li nz-menu-item *cdkVirtualFor="let placeholder of $placeholders | async | search: ($search | async)" tabindex="-1">
              <nz-list-item-meta>
                <nz-list-item-meta-title>
                  <span
                    (click)="insert('placeholder', placeholder)"
                    nz-tooltip
                    [nzTooltipTitle]="placeholder.tooltip"
                    style="margin-right: 8px"
                    nz-icon
                    [nzType]="placeholder.icon"
                    nzTheme="outline"
                  ></span>
                  <span nz-typography (click)="insert('placeholder', placeholder)" style="color: #40a9ff">
                    <strong>
                      {{ placeholder.name }}
                    </strong>
                    <span *ngIf="placeholder.name !== placeholder.schluessel" nz-typography nzType="secondary">{{ placeholder.schluessel }}</span>
                    <span style="margin-left: 8px" nz-icon nzType="arrow-right" nzTheme="outline"></span>
                  </span>
                  <span
                    style="margin-left: 8px"
                    nz-typography
                    *ngIf="hasFeldAccess && placeholder.id !== 'object'"
                    (click)="navigateToFeld(placeholder.id)"
                    nz-icon
                    nzType="edit"
                    nzTheme="outline"
                  ></span>
                </nz-list-item-meta-title>
              </nz-list-item-meta>
            </li>
          </cdk-virtual-scroll-viewport>
        </ul>
      </nz-col>

      <nz-col nzSpan="24" *ngSwitchCase="'if'">
        <br />
        <ul nz-menu>
          <cdk-virtual-scroll-viewport cdkScrollable [itemSize]="30" style="height: 400px">
            <li nz-menu-item *cdkVirtualFor="let condition of $conditions | async | search: ($search | async)" tabindex="-1">
              <nz-list-item-meta>
                <nz-list-item-meta-title>
                  <span (click)="insert('condition', condition)" nz-tooltip [nzTooltipTitle]="condition.tooltip" style="margin-right: 8px" nz-icon [nzType]="condition.icon" nzTheme="outline"></span>
                  <span nz-typography (click)="insert('condition', condition)" style="color: #40a9ff">
                    <strong>
                      {{ condition.name }}
                    </strong>
                    <span *ngIf="condition.name !== condition.schluessel" nz-typography nzType="secondary"> {{ condition.schluessel }} </span>
                    <span style="margin-left: 8px" nz-icon nzType="arrow-right" nzTheme="outline"></span>
                  </span>
                  <span style="margin-left: 8px" nz-typography *ngIf="hasFeldAccess && condition.id !== 'object'" (click)="navigateToFeld(condition.id)" nz-icon nzType="edit" nzTheme="outline"></span>
                </nz-list-item-meta-title>
              </nz-list-item-meta>
            </li>
          </cdk-virtual-scroll-viewport>
        </ul>
      </nz-col>
    </nz-row>
  </nz-col>
  <nz-col nzSpan="14">
    <nz-spin [nzSpinning]="initializing$ | async">
      <div id="myeditorjs" style="border: solid 0.2px #bfbfbf; border-radius: 2px; padding: 10px; width: 100%; height: 100%"></div>
    </nz-spin>
  </nz-col>
</nz-row>
