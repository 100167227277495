import { NgModule } from '@angular/core';

import { DeleteZeitUnterartCommandModule, PostZeitUnterartCommandModule, ZeitUnterartsQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostZeitUnterartCommandModule, DeleteZeitUnterartCommandModule, ZeitUnterartsQueryModule],
  exports: [PostZeitUnterartCommandModule, DeleteZeitUnterartCommandModule, ZeitUnterartsQueryModule],
})
export class ZeitUnterartsSettingsModule {}
