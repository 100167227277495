import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteOrtKategorieRequest, IDeleteOrtKategorieResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteOrtKategorieCommandService extends DeleteCommandService<IDeleteOrtKategorieRequest, IDeleteOrtKategorieResponse> {
  readonly route = 'geo/settings/ort-kategorie';
}
