import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostOrtKategorieCommandServiceModule } from '..';
import { PostOrtKategorieCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostOrtKategorieCommandServiceModule
  ],
  declarations: [PostOrtKategorieCommandComponent],
  exports: [PostOrtKategorieCommandComponent],

})
export class PostOrtKategorieCommandComponentModule { }
