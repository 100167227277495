import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ArchivServiceModule } from '..';
import { ArchivSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ArchivServiceModule
  ],
  declarations: [ArchivSorterComponent],
  exports: [ArchivSorterComponent],

})
export class ArchivSorterComponentModule { }
