import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IRahmenAnpassungenQueryFilter, IRahmenAnpassungenQueryShapes, IRahmenAnpassungenQuerySorter, IRahmenAnpassungenRequest, IRahmenAnpassungenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class RahmenAnpassungenService extends ListQueryService<
  IRahmenAnpassungenRequest,
  IRahmenAnpassungenResponse,
  IRahmenAnpassungenQueryShapes,
  IRahmenAnpassungenQueryFilter,
  IRahmenAnpassungenQuerySorter
> {
  contentKey: keyof IRahmenAnpassungenResponse = 'rahmenAnpassungen';
  override dependencies = ['zeiten/rahmen-anpassung-gespeichert', 'zeiten/rahmen-anpassung-entfernt'];

  route = 'zeiten/rahmen-anpassungen';
}
