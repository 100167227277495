import { Injectable } from '@angular/core';

import { IDeleteUrlaubRequest, IDeleteUrlaubResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteUrlaubCommandService extends DeleteCommandService<IDeleteUrlaubRequest, IDeleteUrlaubResponse> {
  readonly route = 'zeiten/urlaub';
}
