<!--

  <fa-kt-post-abschnitt [style.width]="'100%'" [value]="$postAbschnitt | async" (finished)="finishedPostAbschnitt($event)"></fa-kt-post-abschnitt>

  <fa-kt-delete-abschnitt [style.width]="'100%'" [value]="$deleteAbschnitt | async" (finished)="finishedDeleteAbschnitt($event)"></fa-kt-delete-abschnitt>

  <fa-kt-post-sortiere-abschnitte [style.width]="'100%'" [value]="$postSortiereAbschnitte | async" (finished)="finishedPostSortiereAbschnitte($event)"></fa-kt-post-sortiere-abschnitte>



-->
<!--- << custom    -->
<nz-list nzSize="large" [nzLoading]="$loading | async" cdkDropList (cdkDropListDropped)="reorder($event)">
  <nz-list-item *ngFor="let abschnitt of abschnitte | order: 'order'" (click)="openAbschnitt(abschnitt)" cdkDrag>
    <nz-list-item-meta [nzDescription]="ListDescription">
      <ng-template #ListDescription> </ng-template>
      <nz-list-item-meta-title>
        <a>
          {{ abschnitt.name }}
          <nz-divider nzType="vertical"></nz-divider>
          <span nz-typography nzType="secondary">{{ '{{' }}{{ abschnitt.platzhalter }}{{"}}"}} </span>
        </a>
      </nz-list-item-meta-title>
    </nz-list-item-meta>
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
  <nz-list-item>
    <button nz-button (click)="openAbschnitt({})" nzType="dashed" [disabled]="!vorlage">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Abschnitt hinzufügen
    </button>
  </nz-list-item>
</nz-list>

<!---    custom >> -->
