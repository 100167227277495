import { NgModule } from '@angular/core';

import {
  PostEintragCommandServiceModule,
  PostEintragCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostEintragCommandServiceModule,
    PostEintragCommandComponentModule
  ],
  exports: [
    PostEintragCommandServiceModule,
    PostEintragCommandComponentModule
  ]
})
export class PostEintragCommandModule { }
