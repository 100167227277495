<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="kunde.search$.next($event)" [search]="kunde.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-kunde-filter [style.width]="'100%'"></fa-kt-kunde-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-kunde-sorter [style.width]="'100%'"></fa-kt-kunde-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-kunde-list [style.width]="'100%'" (select)="setKunde($event)" [loading]="$loading | async"></fa-kt-kunde-list>


  <fa-kt-post-kunde [style.width]="'100%'"  [value]="$postKunde | async" (finished)="finishedPostKunde($event)"></fa-kt-post-kunde>

  <fa-kt-delete-kunde [style.width]="'100%'"  [value]="$deleteKunde | async" (finished)="finishedDeleteKunde($event)"></fa-kt-delete-kunde>

  <fa-kt-post-kunden-finanzen [style.width]="'100%'"  [value]="$postKundenFinanzen | async" (finished)="finishedPostKundenFinanzen($event)"></fa-kt-post-kunden-finanzen>


  <fa-kt-abteilungen [style.width]="'100%'" ></fa-kt-abteilungen>

  <fa-kt-kommunikation [style.width]="'100%'" ></fa-kt-kommunikation>

  <fa-kt-fonds [style.width]="'100%'" ></fa-kt-fonds>

  <fa-kt-kommunikation-badge [style.width]="'100%'" ></fa-kt-kommunikation-badge>

  <fa-kt-honorar-vereinbarungen [style.width]="'100%'" ></fa-kt-honorar-vereinbarungen>

  <fa-kt-extra-vereinbarungen [style.width]="'100%'" ></fa-kt-extra-vereinbarungen>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="kunden" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space style="min-width: 260px">
      <button *nzSpaceItem nz-button nzType="dashed" (click)="setProjekte()" [disabled]="$creating | async">📗 Projekte anzeigen <span nz-icon nzType="arrow-right" nzTheme="outline"></span></button>
      <fa-kt-delete-kunde *nzSpaceItem [style.width]="'100%'" [value]="$deleteKunde | async" (finished)="finishedDeleteKunde()"></fa-kt-delete-kunde>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <ul nz-menu nzMode="horizontal" nzSize="large" nzCentered>
      <li nz-menu-item [nzSelected]="($tab | async) === 'kunde'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'kunde' }" queryParamsHandling="merge" replaceUrl="true">
          <span nz-icon nzType="ordered-list" nzTheme="outline" style="margin-right: 8px"></span>
          Allgemein
        </a>
      </li>
      <li nz-menu-item [nzDisabled]="$creating | async" [nzSelected]="($tab | async) === 'abteilungen'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'abteilungen' }" queryParamsHandling="merge" replaceUrl="true">
          <span nz-icon nzType="info-circle" nzTheme="outline" style="margin-right: 8px"></span>
          {{ (kunde.result$ | async)?.abteilungen?.length || 0 }} Abteilung{{( (kunde.result$ | async)?.abteilungen?.length !== 1) ? 'en' : '' }}
        </a>
      </li>
      <li nz-menu-item [nzDisabled]="$creating | async" [nzSelected]="($tab | async) === 'fonds'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'fonds' }" queryParamsHandling="merge" replaceUrl="true">
          <span nz-icon nzType="info-circle" nzTheme="outline" style="margin-right: 8px"></span>
          {{ (kunde.result$ | async)?.fonds?.length || 0 }} Fond{{ ((kunde.result$ | async)?.fonds?.length !== 1) ? 's' : '' }}
        </a>
      </li>
      <li nz-menu-item *ngIf="auth.$isFinanzen | async" [nzDisabled]="$creating | async" [nzSelected]="($tab | async) === 'honorar-vereinbarungen'">
        <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'honorar-vereinbarungen' }" queryParamsHandling="merge" replaceUrl="true">
          <span nz-icon nzType="euro" nzTheme="outline" style="margin-right: 8px"></span>
          {{ ((kunde.result$ | async)?.honorarVereinbarungen?.length || 0) + ((kunde.result$ | async)?.extraVereinbarungen?.length || 0) }} Honorar{{ ((kunde.result$ |
          async)?.honorarVereinbarungen?.length || 0) + ((kunde.result$ | async)?.extraVereinbarungen?.length || 0) === 1 ? '' : 'e'}}
        </a>
      </li>
      <li nz-menu-item [nzDisabled]="$creating | async" [nzSelected]="($tab | async) === 'kommunikation'">
        <fa-kt-kommunikation-badge [vermerke]="(kunde.result$ | async)?.vermerke" [erinnerungen]="(kunde.result$ | async)?.erinnerungen" [style.width]="'100%'">
          <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'kommunikation' }" queryParamsHandling="merge" replaceUrl="true">
            <i nz-icon nzType="wechat" nzTheme="outline" style="margin-right: 8px"></i>
            Kommunikation
          </a>
        </fa-kt-kommunikation-badge>
      </li>
    </ul>
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->

    <ng-container [ngSwitch]="($tab | async)">
      <nz-col nzSpan="24" *ngSwitchCase="'kunde'">
        <fa-kt-post-kunde [style.width]="'100%'" [value]="$postKunde | async" (finished)="finishedPostKunde($event)"></fa-kt-post-kunde>
        <pbc-meta [value]="($postKunde | async)?.kunde"></pbc-meta>
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'abteilungen'">
        <fa-kt-abteilungen
          [kunde]="$id | async"
          [disabled]="!($id | async)"
          [abteilungen]="(kunde.result$ | async)?.abteilungen"
          [style.width]="'100%'"
          [businessHomePage]="$website | async"
          [companyName]="$name | async"
        ></fa-kt-abteilungen>
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'fonds'">
        <fa-kt-fonds [kunde]="$id | async" [disabled]="!($id | async)" [fonds]="(kunde.result$ | async)?.fonds" [style.width]="'100%'"></fa-kt-fonds>
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'honorar-vereinbarungen'">
        <fa-kt-honorar-vereinbarungen
          [kunde]="$id | async"
          [disabled]="!($id | async)"
          [honorarVereinbarungen]="(kunde.result$ | async)?.honorarVereinbarungen"
          [style.width]="'100%'"
        ></fa-kt-honorar-vereinbarungen>
        <fa-kt-extra-vereinbarungen
          [kunde]="$id | async"
          [disabled]="!($id | async)"
          [extraVereinbarungen]="(kunde.result$ | async)?.extraVereinbarungen"
          [style.width]="'100%'"
        ></fa-kt-extra-vereinbarungen>
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'kommunikation'">
        <fa-kt-kommunikation
          [vermerke]="(kunde.result$ | async)?.vermerke"
          [erinnerungen]="(kunde.result$ | async)?.erinnerungen"
          [kunde]="(kunde.result$ | async)?.kunde"
          [disabled]="!($id | async)"
          [style.width]="'100%'"
        ></fa-kt-kommunikation>
      </nz-col>
    </ng-container>

    <!---    page-content >> -->
  </nz-row>
</nz-content>
