import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { FormulareQuerySorterConfig } from 'fa-kt.types';
import { FormulareService } from '../service';

@Component({
  selector: 'fa-kt-formulare-sorter',
  templateUrl: './formulare-sorter.component.html',
  styleUrls: ['./formulare-sorter.component.css'],
})
export class FormulareSorterComponent {
  public FormulareSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public formulare: FormulareService,
  ) {
    this.FormulareSorterConfig = FormulareQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    /*    update >> */
  }
}
