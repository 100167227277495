import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBesichtigungsStatusCommandServiceModule } from '..';
import { PostBesichtigungsStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBesichtigungsStatusCommandServiceModule
  ],
  declarations: [PostBesichtigungsStatusCommandComponent],
  exports: [PostBesichtigungsStatusCommandComponent],

})
export class PostBesichtigungsStatusCommandComponentModule { }
