import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostVorlageKopierenCommandServiceModule } from '..';
import { PostVorlageKopierenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostVorlageKopierenCommandServiceModule
  ],
  declarations: [PostVorlageKopierenCommandComponent],
  exports: [PostVorlageKopierenCommandComponent],

})
export class PostVorlageKopierenCommandComponentModule { }
