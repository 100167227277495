import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IBerichteQueryFilter, IBerichteQueryShapes, IBerichteQuerySorter, IBerichteRequest, IBerichteResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class BerichteService extends ListQueryService<IBerichteRequest, IBerichteResponse, IBerichteQueryShapes, IBerichteQueryFilter, IBerichteQuerySorter> {
  contentKey: keyof IBerichteResponse = 'berichte';
  route = 'markt/berichte';
  override dependencies = ['markt/written-herausgeber', 'markt/removed-herausgeber', 'markt/bericht-entfernt', 'markt/bericht-gespeichert', 'markt/bericht-hochgeladen', 'markt/zeile-korrigiert'];

  getBerichtHerausgeber(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['bericht.herausgeber']?.find((v) => v.value === value);
  }

  get berichtHerausgeber(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['bericht.herausgeber'] as ISelection[]) : [])));
  }
}
