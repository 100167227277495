import { NgModule } from '@angular/core';
import {
  BereichsFilterComponentModule,
  BereichsSorterComponentModule,
  BereichsListComponentModule
} from '.';

@NgModule({
  imports: [
    BereichsFilterComponentModule,
    BereichsSorterComponentModule,
    BereichsListComponentModule
  ],
  exports: [
    BereichsFilterComponentModule,
    BereichsSorterComponentModule,
    BereichsListComponentModule
  ],
})
export class BereichsQueryModule { }