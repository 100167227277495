import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostObjektNutzungCommandShapes, IPostObjektNutzungRequest, IPostObjektNutzungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostObjektNutzungCommandService extends PostCommandService<IPostObjektNutzungRequest, IPostObjektNutzungResponse, IPostObjektNutzungCommandShapes> {
  readonly route = 'objekte/settings/objekt-nutzung';
}
