import { IFilter, IFilterConfig, ISelection, IShape, ISingleReferenceFilter, SingleReferenceFilterConfig } from 'pbc.types';

import { IZeitArt } from 'fa-kt.types';
import { IZeitUnterartsResponse } from '.';

export interface IZeitUnterartsQueryFilter extends IFilter<IZeitUnterartsResponse> {
  'zeitUnterarts.zeitArt'?: ISingleReferenceFilter<IZeitArt>;
}

export const ZeitUnterartsQueryFilterConfig: IFilterConfig<IZeitUnterartsResponse> = [
  SingleReferenceFilterConfig<IZeitArt>('zeitUnterarts.zeitArt', 'ZEITEN.Models.ZEIT_UNTERART.Propertys.ZEIT_ART.title'),
];

export interface IZeitUnterartsQueryShapes extends IShape {
  'zeitUnterarts.zeitArt': ISelection[];
}

export type IZeitUnterartsQueryField = 'zeitUnterarts.id' | 'zeitUnterarts.zeitArt' | 'zeitUnterarts.name' | 'zeitUnterarts.beschreibung' | '';
