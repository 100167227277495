import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteBesichtigungsStatusRequest, IDeleteBesichtigungsStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteBesichtigungsStatusCommandService extends DeleteCommandService<IDeleteBesichtigungsStatusRequest, IDeleteBesichtigungsStatusResponse> {
  readonly route = 'besichtigungen/settings/besichtigungs-status';
}
