import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TemplatesServiceModule } from '..';
import { TemplatesListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    TemplatesServiceModule
  ],
  declarations: [TemplatesListComponent],
  exports: [TemplatesListComponent],

})
export class TemplatesListComponentModule { }
