import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitSorterComponent } from '.';
import { ZeitServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitServiceModule
  ],
  declarations: [ZeitSorterComponent],
  exports: [ZeitSorterComponent],

})
export class ZeitSorterComponentModule { }
