import { NgModule } from '@angular/core';

import { DeleteFeldUnterkategorieCommandModule, FeldUnterkategoriesQueryModule, PostFeldUnterkategorieCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostFeldUnterkategorieCommandModule, DeleteFeldUnterkategorieCommandModule, FeldUnterkategoriesQueryModule],
  exports: [PostFeldUnterkategorieCommandModule, DeleteFeldUnterkategorieCommandModule, FeldUnterkategoriesQueryModule],
})
export class FeldUnterkategoriesSettingsModule {}
