import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';

import { HttpInterceptor, HttpService, OfflineCacheComponent, OfflineStoreService } from '.';
import { LIB_FilesModule } from '../';

@NgModule({
  imports: [CommonModule, LIB_FilesModule, HttpClientModule, NzSpinModule, NzGridModule, NzStatisticModule, NzButtonModule, NzCollapseModule],
  declarations: [OfflineCacheComponent],
  exports: [OfflineCacheComponent],
})
export class LIB_HttpsModule {
  static forRoot(): ModuleWithProviders<LIB_HttpsModule> {
    return {
      ngModule: LIB_HttpsModule,
      providers: [
        HttpService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptor,
          multi: true,
        },
        OfflineStoreService,
      ],
    };
  }
}
