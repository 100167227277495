import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';

import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

import { BaseComponent, ISitemapPage } from 'pbc.angular';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IUmkreissucheRequest } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { UmkreissucheService } from '../../querys';
import { IAzureMapsPosition } from 'pbc.types';

@Component({
  selector: 'fa-kt-sicherheitscheck',
  templateUrl: './sicherheitscheck.component.html',
  styleUrls: ['./sicherheitscheck.component.css'],
})
export class SicherheitscheckComponent extends BaseComponent implements OnInit, OnDestroy {
  public _projekt: ISitemapPage;

  distance = 10;
  payload$ = new BehaviorSubject<IUmkreissucheRequest | undefined>(undefined);
  coordinates$ = new BehaviorSubject<Array<IAzureMapsPosition>>([]);

  constructor(
    @Inject(NZ_MODAL_DATA) payload: IUmkreissucheRequest,
    public umkreissuche: UmkreissucheService,
  ) {
    super();
    this._projekt = this.sitemap['PROJEKTE'].Pages['PROJEKT'];
    this.payload$.next(payload);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.umkreissuche.result$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((umkreissuche) => {
      if (!umkreissuche?.umkreissuche?.length) return;
      this.coordinates$.next(
        umkreissuche.umkreissuche
          .map(({ objekt }) => ({
            lat: objekt?.addresse?.latitude as number,
            lon: objekt?.addresse?.longitude as number,
          }))
          .filter(({ lat, lon }) => !!lat && !!lon),
      );
    });
    this.payload$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (payload) => {
      if (!payload) return;
      this.loading$.next(true);
      await this.umkreissuche.request(payload);
      this.loading$.next(false);
    });
  }

  public changedDistance(distance: number) {
    this.payload$.next({
      ...this.payload$.getValue(),
      distance,
    });
  }

  public async openProjekt(id: string) {
    if (!id) return;
    await this.navigateToProjekt({ id, tab: 'projekt' });
  }

  public async navigateToProjekt(queryParams: Params = {}) {
    this.modalRef.destroy(false);
    await this.router.navigate(this._projekt.url, { queryParams });
  }

  confirm() {
    this.modalRef.destroy(true);
  }
}
