import { Pipe, PipeTransform } from '@angular/core';

import get from 'lodash/get';

import { isDate, isObject } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { isDateString } from 'pbc.functions';
import { Filter, IDateValue, IFilter, IModel, IQueryResponse, IValue } from 'pbc.types';
import { PipeLoadingService } from '../services';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  constructor(private loading: PipeLoadingService) {}

  transform<T>(items?: Array<T>, filter?: IFilter<any>, cut = false): any {
    if (!items || items.length === 0) return [];
    if (!filter) return items;
    this.loading.loading();
    Object.entries(filter).forEach(([key, filter]) => {
      if (cut) {
        const keys = key.split('.');
        keys.shift();
        key = keys.join('.');
      }
      items = (items ? items : []).filter((item) => item && key && this.resolve(item, key, filter, typeof filter));
    });
    this.loading.loaded();
    return items;
  }

  getValues<T extends IModel | IQueryResponse>(filter: Filter<T>) {
    const value: IValue = filter.value;
    let min: any;
    let max: any;
    if (value) {
      min = (value as IDateValue).min || typeof (value as IDateValue).min === 'number' ? (value as IDateValue).min : null;
      max = (value as IDateValue).max || typeof (value as IDateValue).max === 'number' ? (value as IDateValue).max : null;
      // lat = (value as IGeoRangeValue).lat ? (value as IGeoRangeValue).lat : null;
      // lon = (value as IGeoRangeValue).lon ? (value as IGeoRangeValue).lon : null;
      // distance = (value as IGeoRangeValue).distance ? (value as IGeoRangeValue).distance : null;
    }
    if (isDate(min) || isDateString(min)) {
      min = moment(new Date(min)).startOf('day').toDate();
    }
    if (isDate(max) || isDateString(max)) {
      max = moment(new Date(max)).endOf('day').toDate();
    }
    return { value, min, max };
  }

  private resolve<T>(item: any, key: string, filter?: Filter<T>, type: 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function' = 'string'): boolean {
    if (!key || !item || !filter) return false;

    let check = get(item, key);
    if (isDate(check) || isDateString(check)) check = new Date(check);

    if (check === undefined) {
      if (key === 'besichtigungen.von') {
        let besichtigungenVon = key;
        const fromBesichtigungenVonPaths = besichtigungenVon.split('.');
        const fromBesichtigungenVonItems = get(item, fromBesichtigungenVonPaths.shift() as string);
        besichtigungenVon = fromBesichtigungenVonPaths.join('.');
        if (!fromBesichtigungenVonItems || !fromBesichtigungenVonItems.map) return filter.operator === 'nicht' || filter.operator === 'not in';
        const fromBesichtigungenVonResults = fromBesichtigungenVonItems.map((item: any) => this.resolve(item, besichtigungenVon, filter, type));

        let gutachtenBesichtigungDatum = 'gutachten.datum_besichtigung';
        const fromBesichtigungDatumPaths = gutachtenBesichtigungDatum.split('.');
        const fromBesichtigungDatumItems = get(item, fromBesichtigungDatumPaths.shift() as string);
        gutachtenBesichtigungDatum = fromBesichtigungDatumPaths.join('.');
        if (!fromBesichtigungDatumItems || !fromBesichtigungDatumItems.map) {
          return filter.operator === 'nicht' || filter.operator === 'not in';
        }
        const fromgutachtenBesichtigungDatumResults = fromBesichtigungDatumItems.map((item: any) => this.resolve(item, gutachtenBesichtigungDatum, filter, type));

        return fromBesichtigungenVonResults.concat(fromgutachtenBesichtigungDatumResults).some((r: boolean) => r);
      } else {
        const paths = key.split('.');
        const items = get(item, paths.shift() as string);
        if (items && items[0] && isObject(items[0])) {
          if (!items || !items.map) return filter.operator === 'nicht' || filter.operator === 'not in';
          const results = items.map((item: any) => this.resolve(item, paths.join('.'), filter, type));
          return results.some((r: boolean) => r);
        }
      }
    }
    let { value, min, max } = this.getValues(filter);
    switch (filter.operator) {
      case 'in':
        if (Array.isArray(check)) {
          return check.some((v) => v >= min && v <= max);
        } else {
          if (check === null || check === undefined) check = 0;
          if ((min as Date)?.toISOString && (max as Date)?.toISOString) {
            return moment(check).isBetween(moment(min), moment(max));
          }
          return (check as number) >= (min as number) && (check as number) <= (max as number);
        }
      case 'not in':
        if (Array.isArray(check)) {
          return !check.some((v) => v >= min && v <= max);
        } else {
          if (check === null || check === undefined) {
            check = 0;
          }
          return !((check as number) >= (min as number) && (check as number) <= (max as number));
        }
      case '>':
        if (Array.isArray(check)) {
          return check.some((v) => Number(v) > Number(value));
        } else {
          if (check === null || check === undefined) {
            check = 0;
          }
          return (check as number) > (value as number);
        }
      case '>=':
        if (Array.isArray(check)) {
          return check.some((v) => Number(v) >= Number(value));
        } else {
          if (check === null || check === undefined) {
            check = 0;
          }
          return (check as number) >= (value as number);
        }
      case '=':
        if (Array.isArray(check)) {
          return check.some((v) => v === value);
        } else {
          if (check === null || check === undefined) {
            check = 0;
          }
          return (check as number) === (value as number);
        }
      case '<':
        if (Array.isArray(check)) {
          return check.some((v) => Number(v) < Number(value));
        } else {
          if (check === null || check === undefined) {
            check = 0;
          }
          return (check as number) < (value as number);
        }
      case '<=':
        if (Array.isArray(check)) {
          return check.some((v) => Number(v) <= Number(value));
        } else {
          if (check === null || check === undefined) {
            check = 0;
          }
          return (check as number) <= (value as number);
        }
      case 'hat':
        // console.log('hat', {bez: item.projekt.bezeichnung, key, check, value});
        if (Array.isArray(value)) {
          if (Array.isArray(check)) {
            if (value.filter((c) => !!c).length === 0) {
              return check.filter((c) => !!c).length === 0;
            }
            return value.some((v) => check.includes(v));
          } else {
            if (value.filter((c) => !!c).length === 0) {
              return !check;
            }
            return value.includes(check);
          }
        } else if (Array.isArray(check)) {
          // console.log('Array', item.projekt.bezeichnung, check, value, !value)
          if (check.filter((c) => !!c).length === 0) {
            return !value;
          }
          return check.includes(value);
        } else {
          if (!check) check = null;
          if (!value) value = null;
          return check === value;
        }
      case 'alle':
      case 'ist':
        if (Array.isArray(check)) {
          if (Array.isArray(value)) {
            return value.every((v) => check.includes(v));
          } else {
            return check.includes(value);
          }
        } else if (Array.isArray(value)) {
          return value.includes(check);
        } else {
          if (!check) check = null;
          if (!value) value = null;
          return type === 'boolean' ? (check && (value as boolean)) || (!check && (!value as boolean)) : check === value;
        }
      case 'nicht':
        if (Array.isArray(check)) {
          if (Array.isArray(value)) {
            return !value.some((v) => check.includes(v));
          } else {
            return !check.includes(value);
          }
        } else if (Array.isArray(value)) {
          return !value.includes(check);
        } else {
          return check !== value;
        }
      default:
        return false;
    }
  }
}
