import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { AtlasService, BasePage, ISitemapPage } from 'pbc.angular';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { blue, cyan, geekblue, gold, green, grey, lime, magenta, purple, red, volcano, yellow } from '@ant-design/colors';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  IBesichtigung,
  IDeleteBesichtigungRequest,
  IDeleteBesichtigungResponse,
  IDeleteTourRequest,
  IDeleteTourResponse,
  IDurchgang,
  IGutachten,
  IPostBesichtigungRequest,
  IPostBesichtigungResponse,
  IPostDurchgangRequest,
  IPostDurchgangResponse,
  IPostOrdneBesichtigungenRequest,
  IPostOrdneBesichtigungenResponse,
  IPostTourRequest,
  IPostTourResponse,
  IProjekt,
  ITour,
} from 'fa-kt.types';
import orderBy from 'lodash/orderBy';
import { IAddress, IAzureMapsPosition, IAzureMapsRoute } from 'pbc.types';
import { FormulareService, PostDurchgangCommandService } from '../../../formulare';
import { KundenService } from '../../../kunden';
import { ObjektArtsService } from '../../../objekte';
import { ProjektArtsService } from '../../../projekte';
import { DeleteBesichtigungCommandService, DeleteTourCommandService, PostBesichtigungCommandService, PostOrdneBesichtigungenCommandService, PostTourCommandService } from '../../commands';
import { BesichtigungComponent } from '../../components';
import { OffeneGutachtenService, TourService } from '../../querys';
interface Besichtigung {
  color: string;
  besichtigung: IBesichtigung;
  projekt: IProjekt;
  gutachten: IGutachten;
  durchgang?: IDurchgang;
}

@Component({
  selector: 'fa-kt-tour-page',
  templateUrl: './tour.page.html',
  styleUrls: ['./tour.page.css'],
})
export class TourPage extends BasePage implements OnInit, OnDestroy {
  description = { context: 'BESICHTIGUNGEN', page: 'TOUR' };

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $tour = new BehaviorSubject<ITour | undefined>(undefined);

  public readonly $deleteTour: BehaviorSubject<IDeleteTourRequest | null> = new BehaviorSubject<IDeleteTourRequest | null>(null);
  public readonly $postOrdneBesichtigungen: BehaviorSubject<IPostOrdneBesichtigungenRequest | null> = new BehaviorSubject<IPostOrdneBesichtigungenRequest | null>(null);
  public readonly $deleteBesichtigung: BehaviorSubject<IDeleteBesichtigungRequest | null> = new BehaviorSubject<IDeleteBesichtigungRequest | null>(null);
  public readonly $postTour: BehaviorSubject<IPostTourRequest | null> = new BehaviorSubject<IPostTourRequest | null>(null);
  public readonly $postDurchgang: BehaviorSubject<IPostDurchgangRequest | null> = new BehaviorSubject<IPostDurchgangRequest | null>(null);
  public readonly $postBesichtigung: BehaviorSubject<IPostBesichtigungRequest | null> = new BehaviorSubject<IPostBesichtigungRequest | null>(null);

  public _touren: ISitemapPage;
  public _tour: ISitemapPage;
  public _durchgang: ISitemapPage;

  colors = [red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey];
  $besichtigungen = new BehaviorSubject<Besichtigung[]>([]);
  $coordinates = new BehaviorSubject<IAzureMapsPosition[]>([]);
  $routes = new BehaviorSubject<IAzureMapsRoute[]>([]);

  constructor(
    public tour: TourService,
    public offeneGutachten: OffeneGutachtenService,
    public formulare: FormulareService,
    public deleteTour: DeleteTourCommandService,
    public postOrdneBesichtigungen: PostOrdneBesichtigungenCommandService,
    public deleteBesichtigung: DeleteBesichtigungCommandService,
    public postTour: PostTourCommandService,
    public postDurchgang: PostDurchgangCommandService,
    public postBesichtigung: PostBesichtigungCommandService,
    public objektArten: ObjektArtsService,
    public projektArten: ProjektArtsService,
    public kunden: KundenService,
    public atlas: AtlasService,
  ) {
    super();
    this._touren = this.sitemap['BESICHTIGUNGEN'].Pages['TOUREN'];
    this._tour = this.sitemap['BESICHTIGUNGEN'].Pages['TOUR'];
    this._durchgang = this.sitemap['FORMULARE'].Pages['DURCHGANG'];
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.tour.result$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (result) => {
      this.$tour.next(result?.tour);
      this.$postTour.next({ tour: result?.tour as ITour });
      this.$deleteTour.next({ id: result?.tour.id as string });
      const besichtigungen = orderBy(result?.besichtigungen || [], 'order').map((row, index) => ({ ...row, color: this.colors[index % 11].primary })) as Besichtigung[];
      const coordinates = [];

      if (besichtigungen.length > 0) {
        coordinates.push({
          lat: besichtigungen[0].besichtigung.start?.latitude as number,
          lon: besichtigungen[0].besichtigung?.start?.longitude as number,
          object: {},
          color: besichtigungen[0].color,
          type: 'home',
        });
      }
      coordinates.push(
        ...(besichtigungen || [])
          .filter(({ besichtigung }) => besichtigung.ende)
          .map((besichtigung) => ({
            lat: besichtigung.besichtigung.ende.latitude!,
            lon: besichtigung.besichtigung.ende.longitude!,
            object: !besichtigung.besichtigung.rueckfahrt ? { ...besichtigung, type: 'besichtigung' } : {},
            color: besichtigung.color,
            type: besichtigung.besichtigung.rueckfahrt ? 'home' : 'default',
          })),
      ),
        this.$coordinates.next(coordinates);
      this.$routes.next(
        await this.atlas.getRoutesInBatch(
          besichtigungen.map(({ besichtigung, color }) => {
            return {
              route: besichtigung.route,
              color: color,
              object: { type: 'tour', ...(result?.tour || {}) },
            };
          }),
        ),
      );
      this.$besichtigungen.next(besichtigungen || []);
    });
  }

  public async navigateToTouren(queryParams: Params = {}) {
    await this.router.navigate(this._touren.url, { queryParams });
  }
  public async navigateToTour(queryParams: Params = {}) {
    await this.router.navigate(this._tour.url, { queryParams });
  }
  public async navigateToDurchgang(queryParams: Params = {}) {
    await this.router.navigate(this._durchgang.url, { queryParams });
  }

  back() {
    this.navigateToTouren();
  }

  async drop(event: CdkDragDrop<string[]>) {
    const besichtigungen = this.$besichtigungen.getValue();
    try {
      this.loading$.next(true);
      moveItemInArray(besichtigungen, event.previousIndex, event.currentIndex);
      this.$besichtigungen.next(besichtigungen);
      await this.postOrdneBesichtigungen.request({
        besichtigungen: besichtigungen
          .filter((b) => b.besichtigung)
          .map((b) => b.besichtigung)
          .map((besichtigung, order) => ({ ...besichtigung, order: order + 1 })),
      });
    } catch (error) {
      this.monitoring.logException(error);
      this.message.warning('Etwas ist schief gelaufen');
    }
    this.loading$.next(false);
  }

  editBesichtigung(row: Besichtigung) {
    if (!row?.besichtigung?.id || row?.besichtigung?.rueckfahrt) return;
    this.modal.create({
      nzContent: BesichtigungComponent,
      nzData: { besichtigung: row, tour: this.$tour.getValue() },
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: [],
    });
  }

  trackByIndex(_: number, data: Besichtigung): string {
    return data.besichtigung?.id as string;
  }

  openAddress(addresse?: IAddress) {
    if (!addresse) return;
    const url = `https://www.google.com/maps/place/${addresse?.latitude},${addresse?.longitude}`;
    window.open(url, '_blank')?.focus();
  }

  async finishedDeleteTour(response?: IDeleteTourResponse) {
    this.tour.payload$.next(undefined);
    await this.navigateToTouren();
  }

  async finishedPostOrdneBesichtigungen(response?: IPostOrdneBesichtigungenResponse) {}

  async finishedDeleteBesichtigung(response?: IDeleteBesichtigungResponse) {}

  async finishedPostTour(response?: IPostTourResponse) {}

  async finishedPostDurchgang(response?: IPostDurchgangResponse) {}

  async finishedPostBesichtigung(response?: IPostBesichtigungResponse) {}
}
