import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteBesichtigungRequest, IDeleteBesichtigungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteBesichtigungCommandService extends DeleteCommandService<IDeleteBesichtigungRequest, IDeleteBesichtigungResponse> {
  readonly route = 'besichtigungen/besichtigung';
}
