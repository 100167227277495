import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IReisekostenabrechnungStatus } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { ReisekostenabrechnungStatiService } from '../service';

@Component({
  selector: 'fa-kt-reisekostenabrechnung-stati-list',
  templateUrl: './reisekostenabrechnung-stati-list.component.html',
  styleUrls: ['./reisekostenabrechnung-stati-list.component.css'],
})
export class ReisekostenabrechnungStatiListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IReisekostenabrechnungStatus> = new EventEmitter<IReisekostenabrechnungStatus>();

  constructor(public reisekostenabrechnungStati: ReisekostenabrechnungStatiService) {}

  ngOnDestroy() {}
}
