import { NgModule } from '@angular/core';

import {
  DeleteFeldKategorieCommandServiceModule,
  DeleteFeldKategorieCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteFeldKategorieCommandServiceModule,
    DeleteFeldKategorieCommandComponentModule
  ],
  exports: [
    DeleteFeldKategorieCommandServiceModule,
    DeleteFeldKategorieCommandComponentModule
  ]
})
export class DeleteFeldKategorieCommandModule { }
