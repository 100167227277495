import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BasePostCommandComponent } from 'pbc.angular';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IPostRahmenAnpassungRequest, IPostRahmenAnpassungResponse } from 'fa-kt.types';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { DeleteRahmenAnpassungCommandService, PostRahmenAnpassungCommandService } from '..';

@Component({
  selector: 'fa-kt-rahmen-anpassung',
  templateUrl: './rahmen-anpassung.component.html',
  styleUrls: ['./rahmen-anpassung.component.css'],
})
export class RahmenAnpassungCommandComponent extends BasePostCommandComponent<IPostRahmenAnpassungRequest, IPostRahmenAnpassungResponse> implements OnInit, OnDestroy {
  description = { context: 'ZEITEN', command: 'POST_RAHMEN_ANPASSUNG' };
  contentKey: keyof IPostRahmenAnpassungRequest = 'rahmenAnpassung';

  async prepare(): Promise<void> {
    await Promise.all([this.postRahmenAnpassung.prepare()]);
  }

  async request(payload: IPostRahmenAnpassungRequest): Promise<IPostRahmenAnpassungResponse> {
    return this.postRahmenAnpassung.request(payload);
  }

  constructor(
    public postRahmenAnpassung: PostRahmenAnpassungCommandService,
    public deleteRahmenAnpassung: DeleteRahmenAnpassungCommandService,
  ) {
    super();
    this.form = this.fb.group({
      rahmenAnpassung: this.fb.group({
        id: [null, []],

        mitarbeiter: [null, [Validators.required]],

        stunden: [null, []],
        gültigBis: [null, []],

        abgelehnt: [null, []],
        angenommen: [null, []],

        kommentar: [null, []],
      }),
    });
    this.patch();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // if (!this.environment.production) return;
    combineLatest([this.auth.$isOM, this.auth.$isPartner, this.$initial])
      .pipe(takeUntilDestroyed(this.destroyedRef), distinctUntilChanged())
      .subscribe(([om, partner, value]) => {
        if (value?.rahmenAnpassung?.angenommen) {
          this.form.controls.rahmenAnpassung?.get('mitarbeiter')?.disable();
          this.form.controls.rahmenAnpassung?.get('stunden')?.disable();
          this.form.controls.rahmenAnpassung?.get('gültigBis')?.disable();
        } else if (value?.rahmenAnpassung?.abgelehnt) {
          this.form.controls.rahmenAnpassung?.get('mitarbeiter')?.enable();
          this.form.controls.rahmenAnpassung?.get('stunden')?.enable();
          this.form.controls.rahmenAnpassung?.get('gültigBis')?.enable();
        } else {
          if (om || partner) this.form.controls.rahmenAnpassung?.get('mitarbeiter')?.enable();
          else this.form.controls.rahmenAnpassung?.get('mitarbeiter')?.disable();
          this.form.controls.rahmenAnpassung?.get('stunden')?.enable();
          this.form.controls.rahmenAnpassung?.get('gültigBis')?.enable();
        }
      });
  }

  async confirm() {
    this.form.controls.rahmenAnpassung.patchValue({ abgelehnt: false, angenommen: true });
    await this.submit();
  }

  async decline() {
    this.form.controls.rahmenAnpassung.patchValue({ angenommen: false, abgelehnt: true });
    await this.submit();
  }
}
