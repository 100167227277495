import { Component } from '@angular/core';
import { ProjekteService } from '../service';

@Component({
  selector: 'fa-kt-saved-projekte-filters',
  templateUrl: './saved-projekte-filters.component.html',
  styleUrls: ['./saved-projekte-filters.component.css'],
})
export class SavedProjekteFiltersComponent {
  constructor(public projekte: ProjekteService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
