import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { cloneDeep, get, uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, debounceTime, Subscription } from 'rxjs';

import EditorJS, { OutputBlockData, OutputData } from '@editorjs/editorjs';
import Paragraph from '@editorjs/paragraph';
import DragDrop from 'editorjs-drag-drop';
import edjsHTML from 'editorjs-html';
import createGenericInlineTool from 'editorjs-inline-tool';
import ColorPlugin from 'editorjs-text-color-plugin';
import Undo from 'editorjs-undo';
import { decode } from 'html-entities';

import { ConditionTool, ElseConditionTool, ElseTool, EndTool, PlaceholderTool } from './editor';

import { BigJsonViewerDom } from 'big-json-viewer';

import { trimUndefinedRecursively } from 'compress-json';
import { compileWithNunjucks, isDateString, propertiesToArray } from 'pbc.functions';
import { BaseComponent } from '../../../base.components';
import { ISitemapPage } from '../../types';

export interface FeldOption {
  id?: string;
  feld: string;
  option: any;
  standard?: boolean;
  order: number;
}

export interface Feld {
  id?: string;
  format?: string;
  voreinstellung?: string;
  schluessel: string;
  name?: string;
  art: 'datum' | 'haken' | 'zahl' | 'text' | 'option' | 'optionPlus' | 'mehrfachauswahlPlus';
  feldOptionen: FeldOption[];
}

interface JSON {
  type?: string;
  tagName?: string;
  attributes?: Attribute[];
  content?: string;
  children?: JSON[];
}
interface Attribute {
  key?: string;
  value?: string;
}

Object.defineProperty(Paragraph, 'sanitize', {
  get() {
    return {
      mark: (el: HTMLElement) => {
        return {
          class: el.className,
          style: el.style.cssText,
          'data-id': el.getAttribute('data-id'),
          'data-type': el.getAttribute('data-type'),
          'data-placeholder': el.getAttribute('data-placeholder'),
          'data-pipe': el.getAttribute('data-pipe'),
          'data-default-format': el.getAttribute('data-default-format'),
          'data-format': el.getAttribute('data-format'),
          'data-path': el.getAttribute('data-path'),
          'data-operator': el.getAttribute('data-operator'),
          'data-condition': el.getAttribute('data-condition'),
          'data-inverse': el.getAttribute('data-inverse'),
        };
      },
      span: (el: HTMLElement) => {
        return {
          style: el.style.cssText,
        };
      },
      u: (el: HTMLElement) => {
        return {
          style: el.style.cssText,
        };
      },
      i: (el: HTMLElement) => {
        return {
          style: el.style.cssText,
        };
      },
      em: (el: HTMLElement) => {
        return {
          style: el.style.cssText,
        };
      },
      strong: (el: HTMLElement) => {
        return {
          style: el.style.cssText,
        };
      },
      bold: (el: HTMLElement) => {
        return {
          style: el.style.cssText,
        };
      },
      font: (el: HTMLElement) => {
        return {
          style: el.style.cssText,
          color: el.getAttribute('color'),
        };
      },
    };
  },
});

export const ItalicInlineTool = createGenericInlineTool({
  sanitize: {
    em: {},
  },
  shortcut: 'CMD+I',
  tagName: 'EM',
  toolboxIcon:
    '<svg width="20" height="18" viewBox="64 64 896 896" focusable="false"><path d="M798 160H366c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h181.2l-156 544H229c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h432c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8H474.4l156-544H798c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" /></svg>',
});

export const StrongInlineTool = createGenericInlineTool({
  sanitize: {
    strong: {},
  },
  shortcut: 'CMD+B',
  tagName: 'STRONG',
  toolboxIcon:
    '<svg width="20" height="18" viewBox="64 64 896 896" focusable="false"><path d="M697.8 481.4c33.6-35 54.2-82.3 54.2-134.3v-10.2C752 229.3 663.9 142 555.3 142H259.4c-15.1 0-27.4 12.3-27.4 27.4v679.1c0 16.3 13.2 29.5 29.5 29.5h318.7c117 0 211.8-94.2 211.8-210.5v-11c0-73-37.4-137.3-94.2-175.1zM328 238h224.7c57.1 0 103.3 44.4 103.3 99.3v9.5c0 54.8-46.3 99.3-103.3 99.3H328V238zm366.6 429.4c0 62.9-51.7 113.9-115.5 113.9H328V542.7h251.1c63.8 0 115.5 51 115.5 113.9v10.8z" /></svg>',
});

export const UnderlineInlineTool = createGenericInlineTool({
  sanitize: {
    u: {},
  },
  tagName: 'U',
  toolboxIcon:
    '<svg width="20" height="18"  viewBox="64 64 896 896" focusable="false"><path d="M824 804H200c-4.4 0-8 3.4-8 7.6v60.8c0 4.2 3.6 7.6 8 7.6h624c4.4 0 8-3.4 8-7.6v-60.8c0-4.2-3.6-7.6-8-7.6zm-312-76c69.4 0 134.6-27.1 183.8-76.2C745 602.7 772 537.4 772 468V156c0-6.6-5.4-12-12-12h-60c-6.6 0-12 5.4-12 12v312c0 97-79 176-176 176s-176-79-176-176V156c0-6.6-5.4-12-12-12h-60c-6.6 0-12 5.4-12 12v312c0 69.4 27.1 134.6 76.2 183.8C377.3 701 442.6 728 512 728z" /></svg>',
});

Object.defineProperty(Paragraph, 'pasteConfig', {
  get() {
    return {
      tags: ['MARK'],
    };
  },
});

@Component({
  selector: 'pbc-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextEditorComponent,
    },
  ],
})
export class TextEditorComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  private readonly subscriptions: Subscription[] = [];

  _feld: ISitemapPage;

  onChange = (value: string) => {};
  onTouched = () => {};
  touched = false;

  @Input() disabled = false;
  @Input() placeholder = '';

  editor: EditorJS;
  undo: Undo;
  dragdrop: DragDrop;
  parser = edjsHTML();
  api: any;
  position = {
    block: 0,
    position: 0,
    text: '',
  };
  viewer: BigJsonViewerDom;
  @Input() nunjucks: any = {};

  initializing$ = new BehaviorSubject<boolean>(true);
  changing$ = new BehaviorSubject<boolean>(true);
  changed$ = new BehaviorSubject<boolean>(false);
  @Output() textChange = new EventEmitter<string>();

  $select = new BehaviorSubject<'preview' | 'tree' | 'placeholder' | 'if'>('preview');
  $search = new BehaviorSubject<string>('');
  errors: any[] = [];
  $rendered = new BehaviorSubject<string>('');
  @Output() results = new EventEmitter<string>();

  @Input() set object(obj: any) {
    this.$object.next(obj);
  }
  $object = new BehaviorSubject<any>({});
  @Input() set virtual(obj: any) {
    this.$virtual.next(obj);
  }
  $virtual = new BehaviorSubject<any>({});

  @Input() set felder(felder: Feld[]) {
    this.$felder.next(felder);
  }
  $felder = new BehaviorSubject<Feld[]>([]);

  @Input() set selected(felder: string[]) {
    this.$selected.next(felder);
  }
  $selected = new BehaviorSubject<string[]>([]);

  $all = new BehaviorSubject<Feld[]>([]);
  $placeholders = new BehaviorSubject<Feld[]>([]);
  $conditions = new BehaviorSubject<Feld[]>([]);

  constructor() {
    super();
    this._feld = this.sitemap['FELDER'].Pages['FELD'];
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.editor = new EditorJS({
      holder: 'myeditorjs',
      tools: {
        placeholder: {
          class: PlaceholderTool,
          config: {
            placeholders: this.$all,
          },
        },
        condition: {
          class: ConditionTool,
          config: {
            conditions: this.$all,
          },
        },
        elseCondition: {
          class: ElseConditionTool,
          config: {
            conditions: this.$all,
          },
        },
        else: ElseTool,
        end: EndTool,
        bold: {
          class: StrongInlineTool,
          shortcut: 'CMD+SHIFT+B',
        },
        italic: {
          class: ItalicInlineTool,
          shortcut: 'CMD+SHIFT+I',
        },
        underline: {
          class: UnderlineInlineTool,
          shortcut: 'CMD+SHIFT+U',
        },
        color: {
          class: ColorPlugin,
          config: {
            defaultColor: '#FF1300',
            type: 'text',
            customPicker: true,
            colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
            icon: `<svg width="20" height="18" viewBox="64 64 896 896" focusable="false"><path d="M904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8zm-650.3-80h85c4.2 0 8-2.7 9.3-6.8l53.7-166h219.2l53.2 166c1.3 4 5 6.8 9.3 6.8h89.1c1.1 0 2.2-.2 3.2-.5a9.7 9.7 0 006-12.4L573.6 118.6a9.9 9.9 0 00-9.2-6.6H462.1c-4.2 0-7.9 2.6-9.2 6.6L244.5 723.1c-.4 1-.5 2.1-.5 3.2-.1 5.3 4.3 9.7 9.7 9.7zm255.9-516.1h4.1l83.8 263.8H424.9l84.7-263.8z" /></svg>`,
          },
          shortcut: 'CMD+SHIFT+C',
        },
        marker: {
          class: ColorPlugin,
          config: {
            defaultColor: '#FFBF00',
            type: 'marker',
            customPicker: true,
            colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
            icon: `<svg width="20" height="18" viewBox="64 64 896 896" focusable="false"><path d="M766.4 744.3c43.7 0 79.4-36.2 79.4-80.5 0-53.5-79.4-140.8-79.4-140.8S687 610.3 687 663.8c0 44.3 35.7 80.5 79.4 80.5zm-377.1-44.1c7.1 7.1 18.6 7.1 25.6 0l256.1-256c7.1-7.1 7.1-18.6 0-25.6l-256-256c-.6-.6-1.3-1.2-2-1.7l-78.2-78.2a9.11 9.11 0 00-12.8 0l-48 48a9.11 9.11 0 000 12.8l67.2 67.2-207.8 207.9c-7.1 7.1-7.1 18.6 0 25.6l255.9 256zm12.9-448.6l178.9 178.9H223.4l178.8-178.9zM904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8z" /></svg>`,
          },
          shortcut: 'CMD+SHIFT+M',
        },
        paragraph: { class: Paragraph, inlineToolbar: ['placeholder', 'condition', 'elseCondition', 'else', 'end', 'bold', 'italic', 'underline', 'color', 'marker'] },
      },
      onReady: () => {
        this.undo = new Undo({ editor: this.editor, onUpdate: async () => await this.save() });
        this.dragdrop = new DragDrop(this.editor);
        this.initializing$.next(false);
      },
      onChange: (api, block) => {
        this.track();
        this.changing$.next(true);
      },
    });

    this.subscriptions.push(
      ...[
        this.changing$.pipe(debounceTime(200)).subscribe(async () => await this.save()),
        combineLatest([this.$felder, this.$virtual]).subscribe(([felder, obj]) => {
          if (!felder?.length || !obj) return;
          this.$all.next([
            ...uniqBy(felder, 'schluessel'),
            ...propertiesToArray(obj)
              .filter((feld: string) => feld && feld[feld?.length - 1] !== '.' && feld[feld?.length - 1] !== '_' && !feld.includes('._'))
              .map((name) => ({
                id: 'object',
                name: name,
                schluessel: name,
                voreinstellung: get(obj, name),
                art: this.getFeldArt(get(obj, name)),
                feldKategorie: '',
                feldUnterkategorie: '',
                feldOptionen: [],
              }))
              .map((feld) => {
                let icon;
                let tooltip;
                switch (feld.art) {
                  case 'datum':
                    icon = 'field-time';
                    tooltip = 'Datum';
                    break;
                  case 'haken':
                    icon = 'check-circle';
                    tooltip = 'Haken';
                    break;
                  case 'text':
                    icon = 'field-string';
                    tooltip = 'Text';
                    break;
                  case 'zahl':
                    icon = 'field-number';
                    tooltip = 'Zahl';
                    break;
                  case 'option':
                    icon = 'check-square';
                    tooltip = 'Option';
                    break;
                  case 'optionPlus':
                    icon = 'check-square';
                    tooltip = 'Option Plus';
                    break;
                  case 'mehrfachauswahlPlus':
                    icon = 'select';
                    tooltip = 'Mehrfachauswahl';
                    break;
                }
                return {
                  ...feld,
                  format: feld.art === 'datum' ? 'fullDate' : undefined,
                  icon,
                  tooltip,
                };
              }),
          ]);
        }),
        combineLatest([this.$all, this.$selected]).subscribe(([combined, selected]) => {
          if (!combined?.length) return;
          const next = combined.filter(({ id }) => id === 'object' || !selected?.length || selected.includes(id));
          this.$placeholders.next(next.filter(({ art }) => art !== 'haken'));
          this.$conditions.next(next);
        }),
        combineLatest([this.$select, this.$object]).subscribe(async ([select, object]) => {
          if (select !== 'tree' || !object) {
            this.viewer?.destroy();
            this.viewer = undefined;
            return;
          }
          const view = cloneDeep(object);
          trimUndefinedRecursively(view);
          this.viewer = await BigJsonViewerDom.fromObject(view, { objectNodesLimit: 2000, linkLabelCopyPath: 'Einsetzen', linkLabelExpandAll: 'Alles aufklappen' });
          const node = this.viewer.getRootElement();
          document.getElementById('baumstruktur')?.replaceChildren(node);
          await node.openAll(1);
          node.addEventListener('copyPath', async (e: any) => {
            const feld = this.$all.getValue().find(({ schluessel }) => schluessel === e.target.jsonNode.path?.join('.'));
            await this.insert('placeholder', feld);
          });
        }),
        combineLatest([this.$search, this.$object]).subscribe(async ([search, object]) => {
          if (!object || !search || !this.viewer) return;
          await this.viewer.getRootElement().closeNode();
          await this.viewer.openBySearch(new RegExp(search), Infinity);
        }),
        combineLatest([this.textChange, this.$object]).subscribe(([text, obj]) => {
          this.errors = [];
          try {
            this.$rendered.next(compileWithNunjucks(text, obj, this.nunjucks));
          } catch (error: any) {
            this.errors.push(error);
            console.error(error);
          }
        }),
        this.$rendered.subscribe((text) => this.results.emit(text)),
      ],
    );
  }

  ngAfterViewInit(): void {
    document.getElementById('myeditorjs').addEventListener('click', (e: any) => {
      if (e.type === 'click') this.track();
    });
    document.getElementById('myeditorjs').addEventListener('keydown', (e: any) => this.track());
    // document.getElementById('myeditorjs').addEventListener('paste', (e) => this.paste(e));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.editor.destroy();
    this.editor = undefined;
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  getFeldArt(value: any): 'datum' | 'haken' | 'zahl' | 'text' | 'option' | 'optionPlus' | 'mehrfachauswahlPlus' {
    const typeOf = typeof value;
    switch (typeOf) {
      case 'number':
        return 'zahl';
      case 'boolean':
        return 'haken';
    }
    if (value instanceof Date || isDateString(value as string)) return 'datum';
    return 'text';
  }

  get hasFeldAccess() {
    return this.auth.access(this._feld.url.join('/'));
  }

  async navigateToFeld(id: string) {
    if (!id) return;
    if (this.changed$.getValue()) {
      await this.modal.confirm({
        nzTitle: 'Der Abschnitt ist nicht gespeichert. Wenn Sie zum Feld navigieren, gehen die Änderungen verloren.',
        nzOkText: 'Trotzdem navigieren',
        nzOnOk: () => this.router.navigate(this._feld.url, { queryParams: { id } }),
        nzCancelText: 'Bleiben',
      });
    } else this.router.navigate(this._feld.url, { queryParams: { id } });
  }

  track() {
    let selection = document.getSelection();
    this.position.block = this.editor.blocks.getCurrentBlockIndex() || 0;
    this.position.position = selection.anchorOffset || 0;
    this.position.text = decode(selection?.anchorNode?.textContent) || '';
  }

  async paste(e: ClipboardEvent) {
    if (!e) return;
    this.track();
    let text = await navigator.clipboard.readText();
    e.preventDefault();
  }

  async insert(type: 'placeholder' | 'condition', feld?: Feld) {
    if (!feld) return;
    let value;
    switch (type) {
      case 'placeholder':
        value = PlaceholderTool.create(feld.schluessel, ['datum', 'zahl'].includes(feld.art) ? feld.art : undefined, feld.format, feld.format).outerHTML.toString();
        break;
      case 'condition':
        const condition = ConditionTool.create(feld.schluessel);
        const { id } = ConditionTool.get(condition);
        value = `${condition.outerHTML.toString()}${ElseTool.create(id).outerHTML.toString()}${EndTool.create(id).outerHTML.toString()} `;
        break;
    }

    const data = await this.editor?.save();
    const previous = decode(data.blocks[this.position.block]?.data?.text || '');
    const text = previous.replace(
      this.position.text,
      this.position.text.substring(0, this.position.position) + value + this.position.text.substring(this.position.position, this.position.text.length),
    );
    this.editor.blocks.insert('paragraph', { text }, {}, this.position.block, false, true);
    this.$select.next('preview');
    await this.save();
  }

  async save() {
    if (!this.editor?.save) return;
    const data = await this.editor?.save();
    if (!data) return;
    const text = this.parser.parse(data).join('');
    // console.log(text);
    this.onChange(text);
    this.textChange.emit(text);
    const blockHTML = this.parser.validate(data);
    if (blockHTML?.length) console.warn(blockHTML);
    this.changed$.next(true);
  }

  async writeValue(html: string) {
    const blocks = await this.parseHTML(html);
    // console.log('blocks', blocks);
    if (this.editor) {
      await this.editor.isReady;
      this.editor.blocks.render(blocks);
      this.undo.initialize(blocks);
    }
  }

  async parseHTML(html: string): Promise<OutputData> {
    const { parse } = (window as unknown as { himalaya: { parse } }).himalaya;
    return {
      time: new Date().valueOf(),
      version: '2.28.2',
      blocks: (html
        ? this.parse(parse(html))
        : [
            {
              type: 'paragraph',
              data: { text: '' },
            },
          ]) as OutputBlockData<string, any>[],
    };
  }

  private parse(children?: JSON[]): (OutputBlockData<string, any> | string)[] {
    if (!children?.length) return [];
    return children.map((obj) => {
      switch (obj.type) {
        case 'element': {
          const getAttr = (attr?: string): string | undefined => obj.attributes.find(({ key }) => attr === key)?.value;
          switch (obj.tagName) {
            case 'mark': {
              if (getAttr('data-type') === 'placeholder')
                return PlaceholderTool.create(getAttr('data-placeholder'), getAttr('data-pipe'), getAttr('data-default-format'), getAttr('data-format'), getAttr('data-id'))?.outerHTML.toString();
              else if (getAttr('data-type') === 'condition')
                return ConditionTool.create(getAttr('data-path'), getAttr('data-operator'), getAttr('data-condition'), getAttr('data-inverse') === 'true', getAttr('data-id'))?.outerHTML.toString();
              else if (getAttr('data-type') === 'else-condition')
                return ElseConditionTool.create(
                  getAttr('data-path'),
                  getAttr('data-operator'),
                  getAttr('data-condition'),
                  getAttr('data-inverse') === 'true',
                  getAttr('data-id'),
                )?.outerHTML.toString();
              else if (getAttr('data-type') === 'else') return ElseTool.create(getAttr('data-id'))?.outerHTML.toString();
              else if (getAttr('data-type') === 'end') return EndTool.create(getAttr('data-id'))?.outerHTML.toString();
              else if (getAttr('style')) return `<mark style="${getAttr('style')}">${this.parse(obj.children)}</mark>`;
              return this.parse(obj.children).join('');
            }
            case 'span': {
              return obj.children.reduce((current, child) => {
                const getAttr = (attr?: string): string | undefined => obj.attributes.find(({ key }) => attr === key)?.value;
                if (!child?.content) current += this.parse(child.children).join('');
                else {
                  const replaced = this.parseText(child.content);
                  if (replaced) current += replaced;
                  else if (getAttr('style')) current += `<span style="${getAttr('style')}">${child.content}</span>`;
                  else current += child.content;
                }
                return current;
              }, '');
            }
            case 'font': {
              const color = obj.attributes.find(({ key }) => 'color' === key)?.value || '';
              const style = obj.attributes.find(({ key }) => 'style' === key)?.value || '';
              return `<font color="${color}" style="${style}">${this.parse(obj.children).join('')}</font>`;
            }
            case 'b': {
              const style = obj.attributes.find(({ key }) => 'style' === key)?.value || '';
              return `<b style="${style}">${this.parse(obj.children).join('')}</b>`;
            }
            case 'u': {
              const style = obj.attributes.find(({ key }) => 'style' === key)?.value || '';
              return `<u style="${style}">${this.parse(obj.children).join('')}</u>`;
            }
            case 'strong': {
              const style = obj.attributes.find(({ key }) => 'style' === key)?.value || '';
              return `<strong style="${style}">${this.parse(obj.children).join('')}</strong>`;
            }
            case 'em': {
              const style = obj.attributes.find(({ key }) => 'style' === key)?.value || '';
              return `<em style="${style}">${this.parse(obj.children).join('')}</em>`;
            }
            case 'i': {
              const style = obj.attributes.find(({ key }) => 'style' === key)?.value || '';
              return `<i style="${style}">${this.parse(obj.children).join('')}</i>`;
            }
            case 'p': {
              const text = this.parse(obj.children).join('');
              return {
                type: 'paragraph',
                data: { text },
              };
            }
            default:
              return this.parse(obj.children || []).join('');
          }
        }
        case 'text': {
          const replaced = this.parseText(obj.content);
          return replaced || obj.content;
        }
        default:
          return '';
      }
    });
  }

  parseText(text?: string): string {
    if (!text?.length) return undefined;
    if (text.startsWith('{%') && text.endsWith('%}')) {
      let content = text.replace('{%', '').replace('%}', '').trim();
      if (content === 'endif') return EndTool.create().outerHTML.toString();
      else if (content === 'else') return ElseTool.create().outerHTML.toString();
      else if (content.startsWith('if ')) {
        content = content.replace('if ', '').trim();
        const splits = content.split(' === ');
        const path = splits.shift();
        const condition = splits.shift()?.split("'").join('');
        return ConditionTool.create(path, condition ? 'hat' : 'gesetzt', condition).outerHTML.toString();
      }
    } else if (text.startsWith('{{') && text.endsWith('}}')) {
      const content = text.replace('{{', '').replace('}}', '').trim();
      const feld = this.$felder.getValue().find(({ schluessel }) => schluessel === content);
      return PlaceholderTool.create(
        content,
        ['datum', 'zahl'].includes(feld?.art) ? feld.art : undefined,
        feld?.format || (feld?.art === 'datum' ? 'fullDate' : undefined),
        feld?.format || (feld?.art === 'datum' ? 'fullDate' : undefined),
      ).outerHTML.toString();
    }
    return undefined;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
