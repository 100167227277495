import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IZeitUnterart } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { ZeitUnterartsService } from '../service';

@Component({
  selector: 'fa-kt-zeit-unterarts-list',
  templateUrl: './zeit-unterarts-list.component.html',
  styleUrls: ['./zeit-unterarts-list.component.css'],
})
export class ZeitUnterartsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IZeitUnterart> = new EventEmitter<IZeitUnterart>();

  constructor(public zeitUnterarts: ZeitUnterartsService) {}

  ngOnDestroy() {}
}
