import { NgModule } from '@angular/core';
import {
  FelderComponentModule
 } from '.';

@NgModule({
  imports: [
    FelderComponentModule
  ],
  exports: [
    FelderComponentModule
  ]
})
export class FelderComponentsModule { }
