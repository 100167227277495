import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDateiArt } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { DateiArtsService } from '../service';

@Component({
  selector: 'fa-kt-datei-arts-list',
  templateUrl: './datei-arts-list.component.html',
  styleUrls: ['./datei-arts-list.component.css'],
})
export class DateiArtsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IDateiArt> = new EventEmitter<IDateiArt>();

  constructor(public dateiArts: DateiArtsService) {}

  ngOnDestroy() {}
}
