<!---
  <div formGroupName="erforderlichesDokument">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="original">{{ command.Inputs.ORIGINAL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OriginalErrorTip">
        <ng-template #OriginalErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ORIGINAL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="original" id="original" [shapes]="postDokumentHinterlegen.erforderlichesDokumentOriginal | async" [placeholder]="command.Inputs.ORIGINAL.placeholder" cdkFocusInitial ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachten">{{ command.Inputs.GUTACHTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
        <ng-template #GutachtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GUTACHTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="gutachten" id="gutachten" [shapes]="postDokumentHinterlegen.erforderlichesDokumentGutachten | async" [placeholder]="command.Inputs.GUTACHTEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="erforderlich">{{ command.Inputs.ERFORDERLICH.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ErforderlichErrorTip">
        <ng-template #ErforderlichErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ERFORDERLICH.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="erforderlich" id="erforderlich"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fortschritt">{{ command.Inputs.FORTSCHRITT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FortschrittErrorTip">
        <ng-template #FortschrittErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FORTSCHRITT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="fortschritt" id="fortschritt" [nzPlaceHolder]="command.Inputs.FORTSCHRITT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
 {{ command.translation }}
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
