import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FotoKategorienListComponent } from '.';
import { FotoKategorienServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    FotoKategorienServiceModule
  ],
  declarations: [FotoKategorienListComponent],
  exports: [FotoKategorienListComponent],

})
export class FotoKategorienListComponentModule { }
