import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostGutachtenCommandServiceModule } from '..';
import { PostGutachtenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostGutachtenCommandServiceModule
  ],
  declarations: [PostGutachtenCommandComponent],
  exports: [PostGutachtenCommandComponent],

})
export class PostGutachtenCommandComponentModule { }
