import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IZeitResponse } from '.';

export interface IZeitQueryFilter extends IFilter<IZeitResponse> {}

export const ZeitQueryFilterConfig: IFilterConfig<IZeitResponse> = [];

export interface IZeitQueryShapes extends IShape {}

export type IZeitQueryField = '';
