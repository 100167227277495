import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IKundenGruppesQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const KundenGruppesQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'KUNDEN.Models.KUNDEN_GRUPPE.Propertys.ID.title'),
   SorterConfig('name', 'KUNDEN.Models.KUNDEN_GRUPPE.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'KUNDEN.Models.KUNDEN_GRUPPE.Propertys.BESCHREIBUNG.title'),
];
