import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';

import { AuthService, MetaService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { ITag, ITageResponseRow } from 'fa-kt.types';
import { toInitials } from 'pbc.functions';
import { ProjekteSelectService } from '../../../../projekte';
import { TageService } from '../service';

@Component({
  selector: 'fa-kt-tage-list',
  templateUrl: './tage-list.component.html',
  styleUrls: ['./tage-list.component.css'],
})
export class TageListComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  /* << props    */
  tage$ = new BehaviorSubject<Array<ITag>>([]);
  /*    props >> */

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public tage: TageService,
    public projekte: ProjekteSelectService,
    public auth: AuthService,
    public meta: MetaService,
  ) {}

  public set(tage: ITageResponseRow) {
    /* << custom    */
    this.select.emit(tage.tag.id);
    /*    custom >> */
  }

  /* << functions    */
  ngOnInit() {
    this.subscriptions.push(
      ...[
        combineLatest([this.meta.users$, this.tage.result$]).subscribe(([users, tage]) => {
          if (!tage?.tage) return;
          this.tage$.next(
            tage?.tage
              ?.filter(
                ({ tag: { mitarbeiter } }) =>
                  users.find(({ value }) => value === mitarbeiter)?.aktiv &&
                  !['cbe8a646-d91d-4120-be25-9e5dc23c7df7', 'f55a7362-a259-4fdf-bc25-c674dd7e5b12', 'c281b9f5-cc7a-4230-99b4-134813818c4d'].includes(mitarbeiter),
              )
              .map(({ tag }) => tag),
          );
        }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  initialen(str: string): string {
    return toInitials(str);
  }
  /*    functions >> */
}
