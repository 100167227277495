import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostZahlungseingangCommandServiceModule } from '..';
import { PostZahlungseingangCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostZahlungseingangCommandServiceModule
  ],
  declarations: [PostZahlungseingangCommandComponent],
  exports: [PostZahlungseingangCommandComponent],

})
export class PostZahlungseingangCommandComponentModule { }
