import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { UmkreissucheServiceModule } from '..';
import { UmkreissucheFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    UmkreissucheServiceModule
  ],
  declarations: [UmkreissucheFilterComponent],
  exports: [UmkreissucheFilterComponent],

})
export class UmkreissucheFilterComponentModule { }
