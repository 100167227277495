import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IOrtKategoriesQueryFilter, IOrtKategoriesQueryShapes, IOrtKategoriesQuerySorter, IOrtKategoriesRequest, IOrtKategoriesResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class OrtKategoriesService extends SettingsQueryService<IOrtKategoriesRequest, IOrtKategoriesResponse, IOrtKategoriesQueryShapes, IOrtKategoriesQueryFilter, IOrtKategoriesQuerySorter> {
  contentKey: keyof IOrtKategoriesResponse = 'ortKategories';
  route = 'geo/settings/ort-kategories';
  override dependencies = ['geo/written-ort-kategorie', 'geo/removed-ort-kategorie'];
}
