import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFeldUnterkategoriesQuerySorter extends ISorter {
   id?: ISorterExpression;
   feldKategorie?: ISorterExpression;
   name?: ISorterExpression;
   initialen?: ISorterExpression;
   kommentar?: ISorterExpression;
}

export const FeldUnterkategoriesQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.ID.title'),
   SorterConfig('feldKategorie', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.FELD_KATEGORIE.title'),
   SorterConfig('name', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.NAME.title'),
   SorterConfig('initialen', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.INITIALEN.title'),
   SorterConfig('kommentar', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.KOMMENTAR.title'),
];
