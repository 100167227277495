import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IDateiArtsResponse } from '.';

export interface IDateiArtsQueryFilter extends IFilter<IDateiArtsResponse> {}

export const DateiArtsQueryFilterConfig: IFilterConfig<IDateiArtsResponse> = [];

export interface IDateiArtsQueryShapes extends IShape {}

export type IDateiArtsQueryField = 'dateiArts.id' | 'dateiArts.name' | 'dateiArts.beschreibung' | '';
