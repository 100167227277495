import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBerichteResponseRow } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { BerichteService } from '../service';

@Component({
  selector: 'fa-kt-berichte-list',
  templateUrl: './berichte-list.component.html',
  styleUrls: ['./berichte-list.component.css'],
})
export class BerichteListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public berichte: BerichteService) {}

  public set(berichte: IBerichteResponseRow) {
    /* << custom    */
    if (berichte && berichte.bericht) {
      this.select.emit(berichte.bericht.id);
    }
    /*    custom >> */
  }
}
