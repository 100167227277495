import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IDurchgangSorter extends ISorter {
  id?: ISorterExpression;
  formular?: ISorterExpression;
  gutachten?: ISorterExpression;
  projekt?: ISorterExpression;
  besichtigung?: ISorterExpression;
}

export const DurchgangSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('formular', 'Formular'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('besichtigung', 'Besichtigung')
];
