import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostMitarbeiterCommandShapes, IPostMitarbeiterRequest, IPostMitarbeiterResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostMitarbeiterCommandService extends PostCommandService<IPostMitarbeiterRequest, IPostMitarbeiterResponse, IPostMitarbeiterCommandShapes> {
  readonly route = 'personen/mitarbeiter';
  override dependencies = ['personen/written-rolle', 'personen/removed-rolle', 'personen/written-standort', 'personen/removed-standort'];

  getMitarbeiterRollen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['mitarbeiter.rollen']?.find((v) => v.value === value);
  }

  get mitarbeiterRollen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['mitarbeiter.rollen'] as ISelection[]) : [])));
  }

  getMitarbeiterStandort(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['mitarbeiter.standort']?.find((v) => v.value === value);
  }

  get mitarbeiterStandort(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['mitarbeiter.standort'] as ISelection[]) : [])));
  }
}
