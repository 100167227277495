import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IBerichtsVorlageSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  kommentar?: ISorterExpression;
  datei?: ISorterExpression;
}

export const BerichtsVorlageSorterConfig: ISorterConfig = [SorterConfig('id', 'Id'), SorterConfig('name', 'Name'), SorterConfig('kommentar', 'Kommentar'), SorterConfig('datei', 'Datei')];
