import { CommonModule } from '@angular/common';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { DisconnectOutline } from '@ant-design/icons-angular/icons';
import { de } from 'date-fns/locale';
import { de_DE, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { ActionService, AzureInsightsErrorHandler, BroadcastService, DeviceConfigService, HealthService, MetaService, MonitoringService, ResolverLoadingService, SecretService } from './services';

import {
  ActionSelectorComponent,
  BreadcrumbComponent,
  ColorIconComponent,
  ColorPickerComponent,
  DeviceConfigComponent,
  FileUploadComponent,
  HealthComponent,
  IconPickerComponent,
  icons,
  LoadingSpinnerComponent,
  MetaComponent,
  NavigationComponent,
  TextEditorComponent,
} from './components';
import { DevicePage, NotFoundPage, UnauthorizedPage, WelcomePage } from './pages';
import { ActionSearchFilterPipe } from './pipes';

import { LIB_AuthModule, LIB_ContactsModule, LIB_FilterModule, LIB_GeoModule, LIB_HttpsModule } from '..';
import { PreventDoubleClickDirective } from './directives';

const routes: Routes = [
  { path: 'willkommen', component: WelcomePage },
  { path: '404', component: NotFoundPage },
  { path: '401', component: UnauthorizedPage },
  { path: 'device', component: DevicePage },
];

@NgModule({
  imports: [
    LIB_AuthModule,
    LIB_HttpsModule,
    LIB_FilterModule,
    LIB_GeoModule,
    LIB_ContactsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    DragDropModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    CKEditorModule,
    NzIconModule.forRoot([DisconnectOutline, ...icons]),
    NzMessageModule,
    NzMessageModule,
    NzNotificationModule,
    NzNotificationModule,
    NzLayoutModule,
    NzTypographyModule,
    NzModalModule,
    NzCardModule,
    NzSpaceModule,
    NzEmptyModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzDividerModule,
    NzProgressModule,
    NzAffixModule,
    NzAlertModule,
    NzAnchorModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzGridModule,
    NzCollapseModule,
    NzInputNumberModule,
    NzBadgeModule,
    NzAvatarModule,
    NzFormModule,
    NzButtonModule,
    NzTableModule,
    NzSelectModule,
    NzInputModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTreeModule,
    NzTreeViewModule,
    NzTreeSelectModule,
    NzCheckboxModule,
    NzDescriptionsModule,
    NzSwitchModule,
    NzSpinModule,
    NzDropDownModule,
    NzDrawerModule,
    NzListModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzMenuModule,
    NzRadioModule,
    NzBreadCrumbModule,
    NzSliderModule,
    NzStatisticModule,
    NzTabsModule,
    NzTagModule,
    NzPipesModule,
    NzPageHeaderModule,
    NzStepsModule,
    NzSpaceModule,
    NzTimelineModule,
    NzCommentModule,
    NzCarouselModule,
    NzMentionModule,
    NzInputNumberModule,
    NzResizableModule,
    NzUploadModule,
    NgxChartsModule,
    // NzCodeEditorComponent,
  ],
  declarations: [
    WelcomePage,
    NotFoundPage,
    UnauthorizedPage,
    DevicePage,
    IconPickerComponent,
    ColorPickerComponent,
    ColorIconComponent,
    LoadingSpinnerComponent,
    NavigationComponent,
    BreadcrumbComponent,
    ActionSelectorComponent,
    TextEditorComponent,
    FileUploadComponent,
    HealthComponent,
    DeviceConfigComponent,
    ActionSearchFilterPipe,
    MetaComponent,
    PreventDoubleClickDirective,
  ],
  exports: [
    LIB_AuthModule,
    LIB_HttpsModule,
    LIB_FilterModule,
    LIB_GeoModule,
    LIB_ContactsModule,
    WelcomePage,
    NotFoundPage,
    UnauthorizedPage,
    DevicePage,
    IconPickerComponent,
    ColorPickerComponent,
    ColorIconComponent,
    LoadingSpinnerComponent,
    NavigationComponent,
    BreadcrumbComponent,
    ActionSelectorComponent,
    HealthComponent,
    DeviceConfigComponent,
    TextEditorComponent,
    FileUploadComponent,
    MetaComponent,
    ActionSearchFilterPipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    CKEditorModule,
    NzMessageModule,
    NzMessageModule,
    NzNotificationModule,
    NzNotificationModule,
    NzLayoutModule,
    NzTypographyModule,
    NzModalModule,
    NzCardModule,
    NzSpaceModule,
    NzEmptyModule,
    NzIconModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzDividerModule,
    NzProgressModule,
    NzAffixModule,
    NzAlertModule,
    NzAnchorModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzGridModule,
    NzCollapseModule,
    NzBadgeModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzFormModule,
    NzButtonModule,
    NzTableModule,
    NzSelectModule,
    NzInputModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTreeModule,
    NzTreeViewModule,
    NzTreeSelectModule,
    NzCheckboxModule,
    NzDescriptionsModule,
    NzSwitchModule,
    NzSpinModule,
    NzDropDownModule,
    NzDrawerModule,
    NzListModule,
    NzMenuModule,
    NzRadioModule,
    NzBreadCrumbModule,
    NzSliderModule,
    NzStatisticModule,
    NzTabsModule,
    NzTagModule,
    NzPipesModule,
    NzPageHeaderModule,
    NzStepsModule,
    NzSpaceModule,
    NzTimelineModule,
    NzCommentModule,
    NzCarouselModule,
    NzMentionModule,
    NzInputNumberModule,
    NzResizableModule,
    NzUploadModule,
    // NzCodeEditorComponent,
    NgxChartsModule,
    PreventDoubleClickDirective,
  ],
})
export class LIB_CommonModule {
  static forRoot(): ModuleWithProviders<LIB_CommonModule> {
    return {
      ngModule: LIB_CommonModule,
      providers: [
        { provide: NZ_I18N, useValue: de_DE },
        { provide: NZ_DATE_LOCALE, useValue: de },
        ActionService,
        BroadcastService,
        SecretService,
        HealthService,
        MonitoringService,
        DeviceConfigService,
        ResolverLoadingService,
        MetaService,
        { provide: ErrorHandler, useClass: AzureInsightsErrorHandler },
      ],
    };
  }
}
