import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TourStatiServiceModule } from '..';
import { TourStatiFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourStatiServiceModule
  ],
  declarations: [TourStatiFilterComponent],
  exports: [TourStatiFilterComponent],

})
export class TourStatiFilterComponentModule { }
