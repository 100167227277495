import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { GutachtenStatiQuerySorterConfig } from 'fa-kt.types';
import { GutachtenStatiService } from '../service';

@Component({
  selector: 'fa-kt-gutachten-stati-sorter',
  templateUrl: './gutachten-stati-sorter.component.html',
  styleUrls: ['./gutachten-stati-sorter.component.css'],
})
export class GutachtenStatiSorterComponent {
  GutachtenStatiSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public gutachtenStati: GutachtenStatiService,
  ) {
    this.GutachtenStatiSorterConfig = GutachtenStatiQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    /*    update >> */
  }
}
