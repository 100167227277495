import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IUrlaubeQueryFilter, IUrlaubeQueryShapes, IUrlaubeQuerySorter, IUrlaubeRequest, IUrlaubeResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class UrlaubeService extends ListQueryService<IUrlaubeRequest, IUrlaubeResponse, IUrlaubeQueryShapes, IUrlaubeQueryFilter, IUrlaubeQuerySorter> {
  contentKey: keyof IUrlaubeResponse = 'urlaube';
  override dependencies = ['zeiten/urlaub-gespeichert', 'zeiten/urlaub-entfernt'];

  route = 'zeiten/urlaube';
}
