import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { MarktCommandsModule } from './commands';
import { MarktComponentsModule } from './components';
import { MarktBerichtePage, MarktBerichtePageResolver, MarktBerichtPage, MarktBerichtPageResolver, RecherchePage, RecherchePageResolver } from './pages';
import { BerichteService, BerichtService, DurchsuchenService, MarktQuerysModule } from './querys';
import {
  HerausgebersPage,
  HerausgebersPageResolver,
  HerausgebersService,
  HerausgebersServiceModule,
  MarktSettingsModule,
  TemplatesPage,
  TemplatesPageResolver,
  TemplatesService,
  TemplatesServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'markt',
    children: [
      {
        path: 'markt-berichte',
        component: MarktBerichtePage,
        resolve: {
          querys: MarktBerichtePageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['markt'],
            roles: ['GET_BERICHTE', 'POST_HOCHLADEN'],
          },
        },
      },
      {
        path: 'markt-bericht',
        component: MarktBerichtPage,
        resolve: {
          querys: MarktBerichtPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['markt'],
            roles: ['GET_BERICHT', 'DELETE_BERICHT', 'POST_BERICHT'],
          },
        },
      },
      {
        path: 'recherche',
        component: RecherchePage,
        resolve: {
          querys: RecherchePageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['markt'],
            roles: ['GET_DURCHSUCHEN'],
          },
        },
      },
    ],
  },
  {
    path: 'markt',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'templates',
            component: TemplatesPage,
            resolve: {
              querys: TemplatesPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_TEMPLATES'],
                  write: ['POST_TEMPLATE', 'DELETE_TEMPLATE'],
                },
              },
            },
          },
          {
            path: 'herausgebers',
            component: HerausgebersPage,
            resolve: {
              querys: HerausgebersPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_HERAUSGEBERS'],
                  write: ['POST_HERAUSGEBER', 'DELETE_HERAUSGEBER'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [MarktBerichtePage, MarktBerichtPage, RecherchePage, TemplatesPage, HerausgebersPage],
  imports: [
    LibsCommonAngularModule,
    MarktSettingsModule,
    MarktComponentsModule,
    MarktCommandsModule,
    MarktQuerysModule,
    HerausgebersServiceModule,
    TemplatesServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [MarktSettingsModule, MarktComponentsModule, MarktCommandsModule, MarktQuerysModule, HerausgebersServiceModule, TemplatesServiceModule],
})
export class MarktModule {
  static forRoot(): ModuleWithProviders<MarktModule> {
    return {
      ngModule: MarktModule,
      providers: [BerichtService, BerichteService, DurchsuchenService, HerausgebersService, TemplatesService],
    };
  }
}
