import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IRechnungsVorlageSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  kommentar?: ISorterExpression;
  datei?: ISorterExpression;
}

export const RechnungsVorlageSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('datei', 'Datei')
];
