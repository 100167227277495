import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BerichtsVorlagenListComponent } from '.';
import { BerichtsVorlagenServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, BerichtsVorlagenServiceModule],
  declarations: [BerichtsVorlagenListComponent],
  exports: [BerichtsVorlagenListComponent],
})
export class BerichtsVorlagenListComponentModule {}
