import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IBesichtigungsStatiQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const BesichtigungsStatiQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.Propertys.ID.title'),
   SorterConfig('name', 'BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.Propertys.BESCHREIBUNG.title'),
];
