import { NgModule } from '@angular/core';

import {
  PostErforderlichesDokumentCommandServiceModule,
  PostErforderlichesDokumentCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostErforderlichesDokumentCommandServiceModule,
    PostErforderlichesDokumentCommandComponentModule
  ],
  exports: [
    PostErforderlichesDokumentCommandServiceModule,
    PostErforderlichesDokumentCommandComponentModule
  ]
})
export class PostErforderlichesDokumentCommandModule { }
