import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektServiceModule } from '..';
import { ObjektDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektServiceModule
  ],
  declarations: [ObjektDetailComponent],
  exports: [ObjektDetailComponent],

})
export class ObjektDetailComponentModule { }
