import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostTourStatusCommandShapes, IPostTourStatusRequest, IPostTourStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostTourStatusCommandService extends PostCommandService<IPostTourStatusRequest, IPostTourStatusResponse, IPostTourStatusCommandShapes> {
  readonly route = 'besichtigungen/settings/tour-status';
}
