import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IBesichtigungSorter extends ISorter {
  id?: ISorterExpression;
  projekt?: ISorterExpression;
  tour?: ISorterExpression;
  gutachten?: ISorterExpression;
  objekt?: ISorterExpression;
  besichtigungsStatus?: ISorterExpression;
  mitarbeiter?: ISorterExpression;
  kommentar?: ISorterExpression;
  rueckfahrt?: ISorterExpression;
  von?: ISorterExpression;
  bis?: ISorterExpression;
  start?: ISorterExpression;
  ende?: ISorterExpression;
  aufenthalt?: ISorterExpression;
  fahrtzeit?: ISorterExpression;
  fahrtstrecke?: ISorterExpression;
  fahrtzeitOhneVerkehr?: ISorterExpression;
  kosten?: ISorterExpression;
  abgerechnet?: ISorterExpression;
  route?: ISorterExpression;
}

export const BesichtigungSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('tour', 'Tour'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('objekt', 'Objekt'),
  SorterConfig('besichtigungsStatus', 'Besichtigungs Status'),
  SorterConfig('mitarbeiter', 'Mitarbeiter'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('rueckfahrt', 'Rueckfahrt'),
  SorterConfig('von', 'Von'),
  SorterConfig('bis', 'Bis'),
  SorterConfig('start', 'Start'),
  SorterConfig('ende', 'Ende'),
  SorterConfig('aufenthalt', 'Aufenthalt'),
  SorterConfig('fahrtzeit', 'Fahrtzeit'),
  SorterConfig('fahrtstrecke', 'Fahrtstrecke'),
  SorterConfig('fahrtzeitOhneVerkehr', 'Fahrtzeit Ohne Verkehr'),
  SorterConfig('kosten', 'Kosten'),
  SorterConfig('abgerechnet', 'Abgerechnet'),
  SorterConfig('route', 'Route')
];
