import { IFilter, IFilterConfig } from "pbc.types";

import { ITourStatus } from './';

export interface ITourStatusFilter extends IFilter<ITourStatus> {}

export const TourStatusFilterConfig: IFilterConfig<ITourStatus> = [];

export type ITourStatusField =
  'tourStatus.id'|
  'tourStatus.name'|
  'tourStatus.beschreibung'
;
