import { NgModule } from '@angular/core';

import { DeleteTourStatusCommandModule, PostTourStatusCommandModule } from './commands';
import { TourStatiQueryModule } from './query';

@NgModule({
  imports: [PostTourStatusCommandModule, DeleteTourStatusCommandModule, TourStatiQueryModule],
  exports: [PostTourStatusCommandModule, DeleteTourStatusCommandModule, TourStatiQueryModule],
})
export class TourStatiSettingsModule {}
