import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektArtsServiceModule } from '..';
import { ObjektArtsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektArtsServiceModule
  ],
  declarations: [ObjektArtsListComponent],
  exports: [ObjektArtsListComponent],

})
export class ObjektArtsListComponentModule { }
