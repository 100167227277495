import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostRechnungenHochzaehlenCommandServiceModule } from '..';
import { PostRechnungenHochzaehlenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostRechnungenHochzaehlenCommandServiceModule
  ],
  declarations: [PostRechnungenHochzaehlenCommandComponent],
  exports: [PostRechnungenHochzaehlenCommandComponent],

})
export class PostRechnungenHochzaehlenCommandComponentModule { }
