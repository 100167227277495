import { NgModule } from '@angular/core';
import {
  ImportComponentModule
 } from '.';

@NgModule({
  imports: [
    ImportComponentModule
  ],
  exports: [
    ImportComponentModule
  ]
})
export class ProjekteComponentsModule { }
