import { NgModule } from '@angular/core';

import {
  PostReisekostenabrechnungStatusCommandServiceModule,
  PostReisekostenabrechnungStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostReisekostenabrechnungStatusCommandServiceModule,
    PostReisekostenabrechnungStatusCommandComponentModule
  ],
  exports: [
    PostReisekostenabrechnungStatusCommandServiceModule,
    PostReisekostenabrechnungStatusCommandComponentModule
  ]
})
export class PostReisekostenabrechnungStatusCommandModule { }
