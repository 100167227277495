<!---
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kundenGruppe">{{ model.Propertys.KUNDEN_GRUPPE.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenGruppeErrorTip">
      <ng-template #KundenGruppeErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.KUNDEN_GRUPPE.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <pbc-select-value required=true mode="default" formControlName="kundenGruppe" id="kundenGruppe" [shapes]="postKundenArt.kundenArtskundenGruppe | async" [placeholder]="model.Propertys.KUNDEN_GRUPPE.placeholder" cdkFocusInitial ></pbc-select-value>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ model.Propertys.NAME.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
      <ng-template #NameErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.NAME.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="model.Propertys.NAME.placeholder"/>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="beschreibung">{{ model.Propertys.BESCHREIBUNG.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BeschreibungErrorTip">
      <ng-template #BeschreibungErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.BESCHREIBUNG.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <textarea nz-input formControlName="beschreibung" id="beschreibung" [placeholder]="model.Propertys.BESCHREIBUNG.placeholder"></textarea>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="color">Farbe</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" >
      <pbc-color-picker formControlName="color" id="color"></pbc-color-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="icon">Icon</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" >
      <pbc-icon-picker formControlName="icon" id="icon"></pbc-icon-picker>
    </nz-form-control>
  </nz-form-item>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="kundenArt">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="order" id="order" />
      <!--- << content    -->
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kundenGruppe">{{ model.Propertys.KUNDEN_GRUPPE.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenGruppeErrorTip">
          <ng-template #KundenGruppeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.KUNDEN_GRUPPE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="kundenGruppe"
            id="kundenGruppe"
            [shapes]="(postKundenArt.shapes$ | async)?.['kundenArts.kundenGruppe']"
            [placeholder]="model.Propertys.KUNDEN_GRUPPE.placeholder"
            cdkFocusInitial
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ model.Propertys.NAME.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="name" id="name" [placeholder]="model.Propertys.NAME.placeholder" cdkFocusInitial />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="beschreibung">{{ model.Propertys.BESCHREIBUNG.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BeschreibungErrorTip">
          <ng-template #BeschreibungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.BESCHREIBUNG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="beschreibung" id="beschreibung" [placeholder]="model.Propertys.BESCHREIBUNG.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="color">Farbe</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <pbc-color-picker formControlName="color" id="color"></pbc-color-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="icon">Icon</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <pbc-icon-picker formControlName="icon" id="icon"></pbc-icon-picker>
        </nz-form-control>
      </nz-form-item>
      <!---    content >> -->
      <nz-form-item>
        <nz-form-control [nzOffset]="16">
          <button nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
            <i nz-icon nzType="check-circle" nzTheme="outline"></i>
            Speichern
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
  <!--- << extra    -->
  <!---    extra >> -->
</nz-spin>
