import { IFilter, IFilterConfig } from "pbc.types";

import { IDatei } from '.';

export interface IDateiFilter extends IFilter<IDatei> {}

export const DateiFilterConfig: IFilterConfig<IDatei> = [];

export type IDateiField =
  'datei.id'|
  'datei.projekt'|
  'datei.gutachten'|
  'datei.dateiArt'|
  'datei.datei'|
  'datei.aktuell'
;
