import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { MitarbeiterServiceModule } from '..';
import { MitarbeiterFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    MitarbeiterServiceModule
  ],
  declarations: [MitarbeiterFilterComponent],
  exports: [MitarbeiterFilterComponent],

})
export class MitarbeiterFilterComponentModule { }
