import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteReisekostenabrechnungStatusRequest, IDeleteReisekostenabrechnungStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteReisekostenabrechnungStatusCommandService extends DeleteCommandService<IDeleteReisekostenabrechnungStatusRequest, IDeleteReisekostenabrechnungStatusResponse> {
  readonly route = 'finanzen/settings/reisekostenabrechnung-status';
}
