import { NgModule } from '@angular/core';
import {
  FormulareFilterComponentModule,
  FormulareListComponentModule,
  FormulareSorterComponentModule,
  SavedFormulareFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    FormulareFilterComponentModule,
    FormulareListComponentModule,
    FormulareSorterComponentModule,
    SavedFormulareFiltersComponentModule
  ],
  exports: [
    FormulareFilterComponentModule,
    FormulareListComponentModule,
    FormulareSorterComponentModule,
    SavedFormulareFiltersComponentModule
  ],
})
export class FormulareQueryModule { }
