import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostZeitArtCommandComponent } from '.';
import { PostZeitArtCommandServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostZeitArtCommandServiceModule
  ],
  declarations: [PostZeitArtCommandComponent],
  exports: [PostZeitArtCommandComponent],

})
export class PostZeitArtCommandComponentModule { }
