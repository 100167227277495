import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFeldKategoriesQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   initialen?: ISorterExpression;
   kommentar?: ISorterExpression;
}

export const FeldKategoriesQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'FELDER.Models.FELD_KATEGORIE.Propertys.ID.title'),
   SorterConfig('name', 'FELDER.Models.FELD_KATEGORIE.Propertys.NAME.title'),
   SorterConfig('initialen', 'FELDER.Models.FELD_KATEGORIE.Propertys.INITIALEN.title'),
   SorterConfig('kommentar', 'FELDER.Models.FELD_KATEGORIE.Propertys.KOMMENTAR.title'),
];
