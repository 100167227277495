import { IFilter, IFilterConfig } from "pbc.types";

import { IOrt } from '.';

export interface IOrtFilter extends IFilter<IOrt> {}

export const OrtFilterConfig: IFilterConfig<IOrt> = [];

export type IOrtField =
  'bereich.id'|
  'bereich.name'|
  'bereich.kommentar'|
  'bereich.areal'
;
