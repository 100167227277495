import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostZeileCommandShapes, IPostZeileRequest, IPostZeileResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostZeileCommandService extends PostCommandService<IPostZeileRequest, IPostZeileResponse, IPostZeileCommandShapes> {
  readonly route = 'markt/zeile';
  override dependencies = ['geo/written-bereich', 'geo/removed-bereich'];

  getZeileBereiche(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeile.bereiche']?.find((v) => v.value === value);
  }

  get zeileBereiche(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeile.bereiche'] as ISelection[]) : [])));
  }
}
