import { IFilter, IFilterConfig } from "pbc.types";

import { IGutachtenStatus } from '.';

export interface IGutachtenStatusFilter extends IFilter<IGutachtenStatus> {}

export const GutachtenStatusFilterConfig: IFilterConfig<IGutachtenStatus> = [];

export type IGutachtenStatusField =
  'gutachtenStatus.id'|
  'gutachtenStatus.name'|
  'gutachtenStatus.beschreibung'
;
