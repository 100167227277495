import { IFilter, IFilterConfig } from "pbc.types";

import { IProjektArt } from '.';

export interface IProjektArtFilter extends IFilter<IProjektArt> {}

export const ProjektArtFilterConfig: IFilterConfig<IProjektArt> = [];

export type IProjektArtField =
  'projektArt.id'|
  'projektArt.name'|
  'projektArt.initialen'|
  'projektArt.beschreibung'
;
