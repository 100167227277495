import { NgModule } from '@angular/core';
import { 
  PostExtraVereinbarungCommandModule,
  DeleteExtraVereinbarungCommandModule,
  DeleteHonorarVereinbarungCommandModule,
  PostHonorarVereinbarungCommandModule,
  PostRechnungCommandModule,
  DeleteRechnungCommandModule,
  PostRechnungRunterzaehlenCommandModule,
  PostRechnungenHochzaehlenCommandModule,
  PostRechnungsVorlageCommandModule,
  DeleteRechnungsVorlageCommandModule,
  DeleteReisekostenabrechnungCommandModule,
  PostReisekostenabrechnungCommandModule,
  PostZahlungseingangCommandModule,
  DeleteZahlungseingangCommandModule
} from '.';

@NgModule({
  declarations: [],
  imports: [
    PostExtraVereinbarungCommandModule,
    DeleteExtraVereinbarungCommandModule,
    DeleteHonorarVereinbarungCommandModule,
    PostHonorarVereinbarungCommandModule,
    PostRechnungCommandModule,
    DeleteRechnungCommandModule,
    PostRechnungRunterzaehlenCommandModule,
    PostRechnungenHochzaehlenCommandModule,
    PostRechnungsVorlageCommandModule,
    DeleteRechnungsVorlageCommandModule,
    DeleteReisekostenabrechnungCommandModule,
    PostReisekostenabrechnungCommandModule,
    PostZahlungseingangCommandModule,
    DeleteZahlungseingangCommandModule
  ],
  exports: [
    PostExtraVereinbarungCommandModule,
    DeleteExtraVereinbarungCommandModule,
    DeleteHonorarVereinbarungCommandModule,
    PostHonorarVereinbarungCommandModule,
    PostRechnungCommandModule,
    DeleteRechnungCommandModule,
    PostRechnungRunterzaehlenCommandModule,
    PostRechnungenHochzaehlenCommandModule,
    PostRechnungsVorlageCommandModule,
    DeleteRechnungsVorlageCommandModule,
    DeleteReisekostenabrechnungCommandModule,
    PostReisekostenabrechnungCommandModule,
    PostZahlungseingangCommandModule,
    DeleteZahlungseingangCommandModule
  ]
})
export class FinanzenCommandsModule { }
