import { NgModule } from '@angular/core';

import {
  PostZeitUnterartCommandComponentModule, PostZeitUnterartCommandServiceModule
} from '.';

@NgModule({
  imports: [
    PostZeitUnterartCommandServiceModule,
    PostZeitUnterartCommandComponentModule
  ],
  exports: [
    PostZeitUnterartCommandServiceModule,
    PostZeitUnterartCommandComponentModule
  ]
})
export class PostZeitUnterartCommandModule { }
