import { NgModule } from '@angular/core';
import { 
  DeleteFeldCommandModule,
  PostFeldCommandModule,
  PostFeldOptionCommandModule,
  DeleteFeldOptionCommandModule,
  PostOrdneFeldOptionenCommandModule,
  PostReferenzenHochladenCommandModule
} from '.';

@NgModule({
  declarations: [],
  imports: [
    DeleteFeldCommandModule,
    PostFeldCommandModule,
    PostFeldOptionCommandModule,
    DeleteFeldOptionCommandModule,
    PostOrdneFeldOptionenCommandModule,
    PostReferenzenHochladenCommandModule
  ],
  exports: [
    DeleteFeldCommandModule,
    PostFeldCommandModule,
    PostFeldOptionCommandModule,
    DeleteFeldOptionCommandModule,
    PostOrdneFeldOptionenCommandModule,
    PostReferenzenHochladenCommandModule
  ]
})
export class FelderCommandsModule { }
