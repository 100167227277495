import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostHonorarVereinbarungCommandServiceModule } from '..';
import { PostHonorarVereinbarungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostHonorarVereinbarungCommandServiceModule
  ],
  declarations: [PostHonorarVereinbarungCommandComponent],
  exports: [PostHonorarVereinbarungCommandComponent],

})
export class PostHonorarVereinbarungCommandComponentModule { }
