import { Component } from '@angular/core';
import { ZeitService } from '../service';

@Component({
  selector: 'fa-kt-saved-zeit-filters',
  templateUrl: './saved-zeit-filters.component.html',
  styleUrls: ['./saved-zeit-filters.component.css'],
})
export class SavedZeitFiltersComponent {
  constructor(public zeit: ZeitService) {}
}
