import { ArrayReferenceFilterConfig, IArrayReferenceFilter, IFilter, IFilterConfig, ISelection, IShape } from 'pbc.types';

import { IBewertungsAnlass, IKunde, IKundenArt, IKundenGruppe, IObjektArt } from 'fa-kt.types';
import { IVorlagenResponse } from '.';

export interface IVorlagenQueryFilter extends IFilter<IVorlagenResponse> {
  'vorlage.fuerKunden'?: IArrayReferenceFilter<IKunde>;
  'vorlage.fuerKundenGruppen'?: IArrayReferenceFilter<IKundenGruppe>;
  'vorlage.fuerKundenArten'?: IArrayReferenceFilter<IKundenArt>;
  'vorlage.fuerObjektArten'?: IArrayReferenceFilter<IObjektArt>;
  'vorlage.fuerBewertungsAnlaesse'?: IArrayReferenceFilter<IBewertungsAnlass>;
}

export const VorlagenQueryFilterConfig: IFilterConfig<IVorlagenResponse> = [
  ArrayReferenceFilterConfig<IKunde>('vorlage.fuerKunden', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN.title'),
  ArrayReferenceFilterConfig<IKundenGruppe>('vorlage.fuerKundenGruppen', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN_GRUPPEN.title'),
  ArrayReferenceFilterConfig<IKundenArt>('vorlage.fuerKundenArten', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN_ARTEN.title'),
  ArrayReferenceFilterConfig<IObjektArt>('vorlage.fuerObjektArten', 'TEXTE.Models.VORLAGE.Propertys.FUER_OBJEKT_ARTEN.title'),
  ArrayReferenceFilterConfig<IBewertungsAnlass>('vorlage.fuerBewertungsAnlaesse', 'TEXTE.Models.VORLAGE.Propertys.FUER_BEWERTUNGS_ANLAESSE.title'),
];

export interface IVorlagenQueryShapes extends IShape {
  'vorlage.fuerKunden': ISelection[];
  'vorlage.fuerKundenGruppen': ISelection[];
  'vorlage.fuerKundenArten': ISelection[];
  'vorlage.fuerObjektArten': ISelection[];
  'vorlage.fuerBewertungsAnlaesse': ISelection[];
}

export type IVorlagenQueryField =
  | 'vorlage.id'
  | 'vorlage.name'
  | 'vorlage.initialen'
  | 'vorlage.fuerKunden'
  | 'vorlage.fuerKundenGruppen'
  | 'vorlage.fuerKundenArten'
  | 'vorlage.fuerObjektArten'
  | 'vorlage.fuerBewertungsAnlaesse'
  | 'vorlage.angewendet'
  | '';
