import { IFilter, IFilterConfig } from "pbc.types";

import { IZahlungseingang } from '.';

export interface IZahlungseingangFilter extends IFilter<IZahlungseingang> {}

export const ZahlungseingangFilterConfig: IFilterConfig<IZahlungseingang> = [];

export type IZahlungseingangField =
  'zahlungseingang.id'|
  'zahlungseingang.projekt'|
  'zahlungseingang.kunde'|
  'zahlungseingang.rechnung'|
  'zahlungseingang.summe'|
  'zahlungseingang.datum'
;
