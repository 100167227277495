import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostBesichtigungCommandShapes, IPostBesichtigungRequest, IPostBesichtigungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostBesichtigungCommandService extends PostCommandService<IPostBesichtigungRequest, IPostBesichtigungResponse, IPostBesichtigungCommandShapes> {
  route = 'besichtigungen/besichtigung';
  override dependencies = ['besichtigungen/written-besichtigungs-status', 'besichtigungen/removed-besichtigungs-status'];

  getBesichtigungenBesichtigungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigungen.besichtigungsStatus']?.find((v) => v.value === value);
  }

  get besichtigungenBesichtigungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigungen.besichtigungsStatus'] as ISelection[]) : [])));
  }

  getBesichtigungenMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigungen.mitarbeiter']?.find((v) => v.value === value);
  }

  get besichtigungenMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigungen.mitarbeiter'] as ISelection[]) : [])));
  }
}
