import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { GutachtenServiceModule } from '..';
import { GutachtenSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenServiceModule
  ],
  declarations: [GutachtenSorterComponent],
  exports: [GutachtenSorterComponent],

})
export class GutachtenSorterComponentModule { }
