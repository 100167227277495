import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenGruppesServiceModule } from '..';
import { KundenGruppesListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenGruppesServiceModule
  ],
  declarations: [KundenGruppesListComponent],
  exports: [KundenGruppesListComponent],

})
export class KundenGruppesListComponentModule { }
