<!--

  <fa-kt-post-import [style.width]="'100%'" [value]="$postImport | async" (finished)="finishedPostImport($event)"></fa-kt-post-import>
    


-->
<!--- << custom    -->
<fa-kt-post-import [style.width]="'100%'" [value]="$postImport | async" (finished)="finishedPostImport($event)"></fa-kt-post-import>
<!---    custom >> -->
