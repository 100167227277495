import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteAbteilungRequest, IDeleteAbteilungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteAbteilungCommandService extends DeleteCommandService<IDeleteAbteilungRequest, IDeleteAbteilungResponse> {
  readonly route = 'kunden/abteilung';
}
