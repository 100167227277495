import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IZeitUnterartSorter extends ISorter {
  id?: ISorterExpression;
  zeitArt?: ISorterExpression;
  name?: ISorterExpression;
  beschreibung?: ISorterExpression;
}

export const ZeitUnterartSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('zeitArt', 'Zeit Art'),
  SorterConfig('name', 'Name'),
  SorterConfig('beschreibung', 'Beschreibung')
];
