import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IVorlageQuerySorter extends ISorter {
  'vorlage.id'?: ISorterExpression;
  'vorlage.name'?: ISorterExpression;
  'vorlage.initialen'?: ISorterExpression;
  'vorlage.fuerKunden'?: ISorterExpression;
  'vorlage.fuerKundenGruppen'?: ISorterExpression;
  'vorlage.fuerKundenArten'?: ISorterExpression;
  'vorlage.fuerObjektArten'?: ISorterExpression;
  'vorlage.fuerBewertungsAnlaesse'?: ISorterExpression;
  'vorlage.angewendet'?: ISorterExpression;
  'abschnitte.id'?: ISorterExpression;
  'abschnitte.vorlage'?: ISorterExpression;
  'abschnitte.platzhalter'?: ISorterExpression;
  'abschnitte.name'?: ISorterExpression;
  'abschnitte.text'?: ISorterExpression;
}

export const VorlageQuerySorterConfig: ISorterConfig = [
  SorterConfig('vorlage.id', 'TEXTE.Models.VORLAGE.Propertys.ID.title'), 
  SorterConfig('vorlage.name', 'TEXTE.Models.VORLAGE.Propertys.NAME.title'), 
  SorterConfig('vorlage.initialen', 'TEXTE.Models.VORLAGE.Propertys.INITIALEN.title'), 
  SorterConfig('vorlage.fuerKunden', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN.title'), 
  SorterConfig('vorlage.fuerKundenGruppen', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN_GRUPPEN.title'), 
  SorterConfig('vorlage.fuerKundenArten', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN_ARTEN.title'), 
  SorterConfig('vorlage.fuerObjektArten', 'TEXTE.Models.VORLAGE.Propertys.FUER_OBJEKT_ARTEN.title'), 
  SorterConfig('vorlage.fuerBewertungsAnlaesse', 'TEXTE.Models.VORLAGE.Propertys.FUER_BEWERTUNGS_ANLAESSE.title'), 
  SorterConfig('vorlage.angewendet', 'TEXTE.Models.VORLAGE.Propertys.ANGEWENDET.title'), 
  SorterConfig('abschnitte.id', 'TEXTE.Models.ABSCHNITT.Propertys.ID.title'), 
  SorterConfig('abschnitte.vorlage', 'TEXTE.Models.ABSCHNITT.Propertys.VORLAGE.title'), 
  SorterConfig('abschnitte.platzhalter', 'TEXTE.Models.ABSCHNITT.Propertys.PLATZHALTER.title'), 
  SorterConfig('abschnitte.name', 'TEXTE.Models.ABSCHNITT.Propertys.NAME.title'), 
  SorterConfig('abschnitte.text', 'TEXTE.Models.ABSCHNITT.Propertys.TEXT.title'), 
];
