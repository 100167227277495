import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { OffeneGutachtenServiceModule } from '..';
import { OffeneGutachtenFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    OffeneGutachtenServiceModule
  ],
  declarations: [OffeneGutachtenFilterComponent],
  exports: [OffeneGutachtenFilterComponent],

})
export class OffeneGutachtenFilterComponentModule { }
