import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IZeitenRequest, IZeitenResponse } from 'fa-kt.types';
import { MitarbeiterService } from '../../../personen';
import { ProjekteSelectService } from '../../../projekte';
import { KrankheitenService, RahmenAnpassungenService, UrlaubeService, ZeitenService, TageService } from '../../querys';

export interface IZeitenPageResolverResult {
  zeiten?: IZeitenResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class ZeitenPageResolver implements Resolve<IZeitenPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private projekte: ProjekteSelectService,
    public mitarbeiter: MitarbeiterService,
    private zeiten: ZeitenService,
    public krankheiten: KrankheitenService,
    public rahmenAnpassungen: RahmenAnpassungenService,
    public urlaube: UrlaubeService,
    public tage: TageService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IZeitenPageResolverResult> {
    this.loading.resolving();
    const requests = {
      zeiten: {} as IZeitenRequest,
    };
    requests.zeiten.jahr = new Date().getFullYear();
    if (!this.auth.$isFinanzen.getValue()) requests.zeiten.person = this.auth.$id.getValue()!;
    const log = (error: any) => console.error(error);
    const [zeiten] = await Promise.all([
      this.zeiten.request(requests.zeiten).catch(log),
      this.krankheiten.request(requests.zeiten).catch(log),
      this.urlaube.request(requests.zeiten).catch(log),
      this.rahmenAnpassungen.request(requests.zeiten).catch(log),
      this.tage.request(requests.zeiten).catch(log),
      this.projekte.request({}).catch(log),
      this.mitarbeiter.request({}).catch(log),
    ]);
    this.loading.resolved();
    return {
      zeiten,
    };
  }
}
