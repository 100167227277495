<!--

  <fa-kt-post-projekte-hochzaehlen [style.width]="'100%'"  [value]="$postProjekteHochzaehlen | async" (finished)="finishedPostProjekteHochzaehlen($event)"></fa-kt-post-projekte-hochzaehlen>

  <fa-kt-post-rechnungen-hochzaehlen [style.width]="'100%'"  [value]="$postRechnungenHochzaehlen | async" (finished)="finishedPostRechnungenHochzaehlen($event)"></fa-kt-post-rechnungen-hochzaehlen>


  <fa-kt-rechnungszaehler [style.width]="'100%'" ></fa-kt-rechnungszaehler>


-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <nz-row [style.width]="'100%'">
        <nz-col nzSpan="24">
          <pbc-search-bar [style.width]="'100%'" (searchChange)="search$.next($event)" [search]="(search$ | async)" placeholder="Suche nach Einstellungen"></pbc-search-bar>
        </nz-col>
      </nz-row>
      <nz-row [style.width]="'100%'" style="padding-top: 24px">
        <nz-col nzSpan="24">
          <nz-collapse nzGhost class="panel">
            <ng-container *ngFor="let panel of filtered$ | async">
              <nz-collapse-panel *ngIf="panel.pages.length > 0" [nzHeader]="panel.context.emoji + ' ' + panel.context.title" nzActive="true">
                <ul nz-list [nzDataSource]="panel.pages" nzSize="large" class="panel-list">
                  <li nz-list-item *ngFor="let page of panel.pages" nzNoFlex [routerLink]="page.url">
                    <ul nz-list-item-actions>
                      <nz-list-item-action>
                        <a [routerLink]="page.url"><i nz-icon nzType="arrow-right" nzTheme="outline"></i></a>
                      </nz-list-item-action>
                    </ul>
                    {{ page.emoji }} {{ page.title }}
                  </li>
                </ul>
              </nz-collapse-panel>
            </ng-container>
          </nz-collapse>
        </nz-col>
      </nz-row>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
