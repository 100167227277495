import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "pbc.angular";

import { KundeService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    KundeService,
  ],
  exports: []
})
export class KundeServiceModule { }
