import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';
import { ObjekteCommandsModule } from './commands';
import { ObjekteComponentsModule } from './components';
import { ObjekteQuerysModule, ObjekteService, ObjektService } from './querys';
import {
  ObjektArtsPage,
  ObjektArtsPageResolver,
  ObjektArtsService,
  ObjektArtsServiceModule,
  ObjekteSettingsModule,
  ObjektNutzungsPage,
  ObjektNutzungsPageResolver,
  ObjektNutzungsService,
  ObjektNutzungsServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'objekte',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'objekt-nutzungs',
            component: ObjektNutzungsPage,
            resolve: {
              querys: ObjektNutzungsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_OBJEKT_NUTZUNGS'],
                  write: ['POST_OBJEKT_NUTZUNG', 'DELETE_OBJEKT_NUTZUNG'],
                },
              },
            },
          },
          {
            path: 'objekt-arts',
            component: ObjektArtsPage,
            resolve: {
              querys: ObjektArtsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_OBJEKT_ARTS'],
                  write: ['POST_OBJEKT_ART', 'DELETE_OBJEKT_ART'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [ObjektNutzungsPage, ObjektArtsPage],
  imports: [
    LibsCommonAngularModule,
    ObjekteSettingsModule,
    ObjekteComponentsModule,
    ObjekteCommandsModule,
    ObjekteQuerysModule,
    ObjektArtsServiceModule,
    ObjektNutzungsServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [ObjekteSettingsModule, ObjekteComponentsModule, ObjekteCommandsModule, ObjekteQuerysModule, ObjektArtsServiceModule, ObjektNutzungsServiceModule],
})
export class ObjekteModule {
  static forRoot(): ModuleWithProviders<ObjekteModule> {
    return {
      ngModule: ObjekteModule,
      providers: [ObjektService, ObjekteService, ObjektArtsService, ObjektNutzungsService],
    };
  }
}
