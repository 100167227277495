import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjekteServiceModule } from '..';
import { ProjekteListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjekteServiceModule
  ],
  declarations: [ProjekteListComponent],
  exports: [ProjekteListComponent],

})
export class ProjekteListComponentModule { }
