import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BerichtServiceModule } from '..';
import { SavedBerichtFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtServiceModule
  ],
  declarations: [SavedBerichtFiltersComponent],
  exports: [SavedBerichtFiltersComponent],

})
export class SavedBerichtFiltersComponentModule { }
