import { IFilter, IFilterConfig } from "pbc.types";

import { IZeitUnterart } from '.';

export interface IZeitUnterartFilter extends IFilter<IZeitUnterart> {}

export const ZeitUnterartFilterConfig: IFilterConfig<IZeitUnterart> = [];

export type IZeitUnterartField =
  'zeitUnterart.id'|
  'zeitUnterart.zeitArt'|
  'zeitUnterart.name'|
  'zeitUnterart.beschreibung'
;
