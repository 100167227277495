import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFeldCommandServiceModule } from '..';
import { DeleteFeldCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFeldCommandServiceModule
  ],
  declarations: [DeleteFeldCommandComponent],
  exports: [DeleteFeldCommandComponent],

})
export class DeleteFeldCommandComponentModule { }
