import { Pipe, PipeTransform } from '@angular/core';
import { ISelection } from 'pbc.types';

@Pipe({
  name: 'type',
})
export class TypePipe implements PipeTransform {
  constructor() {}

  transform(value: string | string[] | any | any[], shapes: ISelection[] | null, field: keyof ISelection | '*' | any = 'label', shorten = true): string {
    if (!shapes) {
      const map = (value: string): string => (value && value[field] ? value[field] : value);
      return Array.isArray(value) ? this.join(value.map((v) => map(v))) : map(value);
    }
    const scope: any = {};
    shapes.forEach((selection) => {
      scope[selection.value!] = field === '*' ? selection : selection[field as keyof ISelection];
    });
    const map = (id: string): string => (scope[id] ? (shorten ? (scope[id]?.includes('|') ? scope[id]?.split('|')?.[0] : scope[id]?.split('(')?.[0]) : scope[id]) : null);
    return Array.isArray(value) ? this.join(value.map(map)) : map(value);
  }

  join(arr: string[]): string {
    arr = arr.filter((e) => e?.length > 0);
    if (!arr?.length) return '';
    if (arr.length === 1) return arr[0];
    const last = arr.pop();
    return arr.join(', ') + ' & ' + last;
  }
}
