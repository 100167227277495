import { IFilter, IFilterConfig } from "pbc.types";

import { IKundenArt } from '.';

export interface IKundenArtFilter extends IFilter<IKundenArt> {}

export const KundenArtFilterConfig: IFilterConfig<IKundenArt> = [];

export type IKundenArtField =
  'kundenArt.id'|
  'kundenArt.kundenGruppe'|
  'kundenArt.name'|
  'kundenArt.beschreibung'
;
