import { IFilter, IFilterConfig } from "pbc.types";

import { IZeit } from '.';

export interface IZeitFilter extends IFilter<IZeit> {}

export const ZeitFilterConfig: IFilterConfig<IZeit> = [];

export type IZeitField =
  'zeit.id'|
  'zeit.mitarbeiter'|
  'zeit.projekt'|
  'zeit.gutachten'|
  'zeit.kunde'|
  'zeit.zeitArt'|
  'zeit.zeitUnterart'|
  'zeit.datum'|
  'zeit.stunden'|
  'zeit.minuten'|
  'zeit.kommentar'|
  'zeit.kosten'
;
