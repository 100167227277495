import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { UmkreissucheServiceModule } from '..';
import { UmkreissucheListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    UmkreissucheServiceModule
  ],
  declarations: [UmkreissucheListComponent],
  exports: [UmkreissucheListComponent],

})
export class UmkreissucheListComponentModule { }
