import { IFilter, IFilterConfig } from "pbc.types";

import { IHerausgeber } from '.';

export interface IHerausgeberFilter extends IFilter<IHerausgeber> {}

export const HerausgeberFilterConfig: IFilterConfig<IHerausgeber> = [];

export type IHerausgeberField =
  'herausgeber.id'|
  'herausgeber.name'|
  'herausgeber.website'|
  'herausgeber.outlookKontaktes'|
  'herausgeber.kommentar'
;
