import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { IAddress, IAzureMapsPosition, IAzureMapsSuggestion } from 'pbc.types';
import { AtlasService } from '../../';

@Component({
  selector: 'pbc-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
  ],
})
export class AddressInputComponent implements OnInit, ControlValueAccessor {
  subscriptions: Subscription[] = [];

  form: FormGroup;
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $suggestions: BehaviorSubject<IAzureMapsSuggestion[]> = new BehaviorSubject<IAzureMapsSuggestion[]>([]);
  $coordinates = new BehaviorSubject<IAzureMapsPosition | undefined>(undefined);
  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() set value(value: IAddress | undefined) {
    this.onTouched();
    if (!value) {
      return;
    }
    if (!isEqual(value, this.value)) this.form.patchValue(value);
  }
  get value(): IAddress {
    return this.form.value;
  }

  @Input() placeholder = '';
  @Input() set required(required: boolean) {
    const validators = Boolean(required) ? [Validators.required] : [];
    this.form.get('land')?.setValidators(validators);
    this.form.get('plz')?.setValidators([...validators, Validators.pattern(this.atlas.plzPattern)]);
    this.form.get('strasse')?.setValidators(validators);
    this.form.get('latitude')?.setValidators(validators);
    this.form.get('longitude')?.setValidators(validators);
    this.form.setErrors(null);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.nzRequired = required;
  }
  public nzRequired = false;

  constructor(
    private formBuilder: FormBuilder,
    public atlas: AtlasService,
  ) {
    this.form = this.formBuilder.group({
      land: [''],
      bundesland: ['', []],
      kreis: ['', []],
      gemeinde_stadt: ['', []],
      ort_stadt_teil: ['', []],
      plz: ['', [Validators.pattern(this.atlas.plzPattern)]],
      strasse: [''],
      extra: [''],
      regierungsbezirk: ['', []],
      verband: ['', []],
      regionalSchluessel: ['', []],
      bundeslandKennzahl: ['', [Validators.maxLength(this.atlas.bundeslandKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      regierungsbezirkKennzahl: ['', [Validators.maxLength(this.atlas.regierungsbezirkKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      kreisKennzahl: ['', [Validators.maxLength(this.atlas.kreisKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      verbandKennzahl: ['', [Validators.maxLength(this.atlas.verbandKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      gemeindeKennzahl: ['', [Validators.maxLength(this.atlas.gemeindeKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      latitude: [null],
      longitude: [null],
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((address: IAddress) => {
        this.onChange(address);
        this.onTouched();
        this.form.markAsDirty();
        this.form.markAllAsTouched();
        this.$coordinates.next(address.latitude && address.longitude ? { lat: +address.latitude, lon: +address.longitude, draggable: true } : undefined);
      }),
      combineLatest([
        this.form.get('strasse')!.valueChanges.pipe(distinctUntilChanged()),
        this.form.get('plz')!.valueChanges.pipe(distinctUntilChanged()),
        this.form.get('land')!.valueChanges.pipe(distinctUntilChanged()),
      ]).subscribe(([strasse, plz, land]) => {
        this.search$.next(`${strasse ? strasse + ', ' : ''}${plz ? plz + ' ' : ''}${land ? land : ''}`);
      }),
      combineLatest([
        this.form.get('bundeslandKennzahl')?.valueChanges.pipe(distinctUntilChanged()),
        this.form.get('regierungsbezirkKennzahl')?.valueChanges.pipe(distinctUntilChanged()),
        this.form.get('kreisKennzahl')?.valueChanges.pipe(distinctUntilChanged()),
        this.form.get('verbandKennzahl')?.valueChanges.pipe(distinctUntilChanged()),
        this.form.get('gemeindeKennzahl')?.valueChanges.pipe(distinctUntilChanged()),
      ]).subscribe((state) => {
        this.form.patchValue({ regionalSchluessel: state.filter((v) => v).join(' ') });
      }),
      this.search$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(async (term: string) => {
        const suggestions = await this.atlas.suggestByTerm(term);
        this.$suggestions.next(suggestions);
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async moveCoordinates($event: IAzureMapsPosition) {
    this.form.patchValue({
      latitude: $event.lat,
      longitude: $event.lon,
    });
    // const suggestions = await this.atlas.suggestByCoordinates($event.lon, $event.lat);
    // if (suggestions[0] && suggestions[0].position.lat && suggestions[0].position.lon) {
    //   await this.setSuggestion(suggestions[0], false);
    // }
  }

  async setSuggestion(suggestion: IAzureMapsSuggestion, latAndLon = true) {
    if (suggestion) {
      this.form.patchValue({
        land: suggestion.address.country ? suggestion.address.country : this.form.value.land,
        bundesland: suggestion.address.countrySubdivision ? suggestion.address.countrySubdivision : this.form.value.bundesland,
        kreis: suggestion.address.countrySecondarySubdivision ? suggestion.address.countrySecondarySubdivision : this.form.value.bundesland,
        gemeinde_stadt: suggestion.address.municipality ? suggestion.address.municipality : this.form.value.kreis,
        ort_stadt_teil: suggestion.address.municipalitySubdivision ? suggestion.address.municipalitySubdivision : this.form.value.ort_stadt_teil,
        plz: suggestion.address.postalCode ? suggestion.address.postalCode : this.form.value.plz,
        strasse: suggestion.address.streetAddress ? suggestion.address.streetAddress : this.form.value.strasse,
      });
      if (latAndLon) {
        this.form.patchValue({
          latitude: suggestion.position?.lat ? suggestion.position.lat : this.form.value.latitude,
          longitude: suggestion.position?.lon ? suggestion.position.lon : this.form.value.longitude,
        });
      }
    }
    this.atlas.suggestRegionalSchluessel(suggestion.address.freeformAddress).then((schluessel: any) => {
      if (schluessel) {
        this.form.patchValue({
          ...schluessel,
        });
      }
    });
  }

  get regionalSchluessel(): FormControl {
    return this.form.get('regionalSchluessel') as FormControl;
  }

  next($event: KeyboardEvent, current: HTMLInputElement, expected: number, next: HTMLInputElement) {
    if ($event.which !== 16 && $event.which !== 9 && current && current.value && current.value.length >= expected) {
      next.focus();
    }
  }

  fill(field: string, value: string, length: number) {
    while (value.length < length) {
      value = '0' + value;
    }
    // this.form.patchValue({ [field]: value})
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: IAddress | null) {
    if (value) {
      this.value = value;
    }
    if (value === null) {
      this.form.reset();
    }
  }

  validate(fc: FormControl) {
    return this.form.valid ? null : { address: { valid: false } };
  }

  setDisabledState(isDisabled: boolean): void {}
}
