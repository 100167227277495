import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IOrtsResponse } from '.';

export interface IOrtsQueryFilter extends IFilter<IOrtsResponse> {}

export const OrtsQueryFilterConfig: IFilterConfig<IOrtsResponse> = [];

export interface IOrtsQueryShapes extends IShape {}

export type IOrtsQueryField = 'bereichs.id' | 'bereichs.name' | 'bereichs.kommentar' | 'bereichs.areal' | '';
