import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IReisekostenabrechnungStatiQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   kommentar?: ISorterExpression;
}

export const ReisekostenabrechnungStatiQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'FINANZEN.Models.REISEKOSTENABRECHNUNG_STATUS.Propertys.ID.title'),
   SorterConfig('name', 'FINANZEN.Models.REISEKOSTENABRECHNUNG_STATUS.Propertys.NAME.title'),
   SorterConfig('kommentar', 'FINANZEN.Models.REISEKOSTENABRECHNUNG_STATUS.Propertys.KOMMENTAR.title'),
];
