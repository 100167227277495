import { NgModule } from '@angular/core';

import {
  DeleteFondCommandServiceModule,
  DeleteFondCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteFondCommandServiceModule,
    DeleteFondCommandComponentModule
  ],
  exports: [
    DeleteFondCommandServiceModule,
    DeleteFondCommandComponentModule
  ]
})
export class DeleteFondCommandModule { }
