import { NgModule } from '@angular/core';
import {
  DeleteProjektCommandModule,
  PostArchivierenCommandModule,
  PostImportCommandModule,
  PostKopierenCommandModule,
  PostProjektCommandModule,
  PostProjekteHochzaehlenCommandModule,
  PostReaktivierenCommandModule,
} from '.';

@NgModule({
  declarations: [],
  imports: [
    PostArchivierenCommandModule,
    PostImportCommandModule,
    PostKopierenCommandModule,
    DeleteProjektCommandModule,
    PostProjektCommandModule,
    PostProjekteHochzaehlenCommandModule,
    PostReaktivierenCommandModule,
  ],
  exports: [
    PostArchivierenCommandModule,
    PostImportCommandModule,
    PostKopierenCommandModule,
    DeleteProjektCommandModule,
    PostProjektCommandModule,
    PostProjekteHochzaehlenCommandModule,
    PostReaktivierenCommandModule,
  ],
})
export class ProjekteCommandsModule {}
