import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFeldKategorieCommandServiceModule } from '..';
import { DeleteFeldKategorieCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFeldKategorieCommandServiceModule
  ],
  declarations: [DeleteFeldKategorieCommandComponent],
  exports: [DeleteFeldKategorieCommandComponent],

})
export class DeleteFeldKategorieCommandComponentModule { }
