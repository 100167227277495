import { IDateFilter, IFilter, IFilterConfig } from "pbc.types";

import { IRechnung } from '.';

export interface IRechnungFilter extends IFilter<IRechnung> {
  'datum'?: IDateFilter;
}

export const RechnungFilterConfig: IFilterConfig<IRechnung> = [];

export type IRechnungField =
  'rechnung.id'|
  'rechnung.projekt'|
  'rechnung.kunde'|
  'rechnung.bezeichnung'|
  'rechnung.rechnungZaehler'|
  'rechnung.datum'|
  'rechnung.honorarVereinbarung'|
  'rechnung.extraVereinbarungs'|
  'rechnung.stunden'|
  'rechnung.anschlaege'|
  'rechnung.seiten'|
  'rechnung.fotos'|
  'rechnung.strecke'|
  'rechnung.fahrtzeit'|
  'rechnung.besichtigungen'|
  'rechnung.mwstSatz'|
  'rechnung.nachlass'|
  'rechnung.summe'|
  'rechnung.datei'
;
