import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BasePostCommandComponent } from 'pbc.angular';

import { IPostBerichtsVorlageRequest, IPostBerichtsVorlageResponse } from 'fa-kt.types';
import { ISelection } from 'pbc.types';
import { VorlagenService } from '../../../querys';
import { PostBerichtsVorlageCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-berichts-vorlage',
  templateUrl: './post-berichts-vorlage.component.html',
  styleUrls: ['./post-berichts-vorlage.component.css'],
})
export class PostBerichtsVorlageCommandComponent extends BasePostCommandComponent<IPostBerichtsVorlageRequest, IPostBerichtsVorlageResponse> implements OnInit, OnDestroy {
  description = { context: 'TEXTE', command: 'POST_BERICHTS_VORLAGE' };
  contentKey: keyof IPostBerichtsVorlageRequest = 'berichtsVorlage';

  async prepare(): Promise<void> {
    return;
  }
  async request(payload: IPostBerichtsVorlageRequest): Promise<IPostBerichtsVorlageResponse> {
    return await this.postBerichtsVorlage.request(payload);
  }

  @Input() shapes: ISelection[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    public postBerichtsVorlage: PostBerichtsVorlageCommandService,
    public vorlagen: VorlagenService,
  ) {
    super();
    this.form = this.fb.group({
      berichtsVorlage: this.fb.group({
        id: [null, []],
        vorlagen: [[], []],
        name: [null, [Validators.required]],
        kommentar: [null, []],
        datei: [null, [Validators.required]],
      }),
    });
    this.patch();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostBerichtsVorlageRequest) => {
        this.valueChange.emit(value);
        this.valid.emit(this.form.valid);
      }),
    );
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }
}
