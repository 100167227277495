import { NgModule } from '@angular/core';

import { DeleteHerausgeberCommandModule, HerausgebersQueryModule, PostHerausgeberCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostHerausgeberCommandModule, DeleteHerausgeberCommandModule, HerausgebersQueryModule],
  exports: [PostHerausgeberCommandModule, DeleteHerausgeberCommandModule, HerausgebersQueryModule],
})
export class HerausgebersSettingsModule {}
