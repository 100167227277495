import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { MitarbeiterServiceModule } from '..';
import { MitarbeiterSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    MitarbeiterServiceModule
  ],
  declarations: [MitarbeiterSorterComponent],
  exports: [MitarbeiterSorterComponent],

})
export class MitarbeiterSorterComponentModule { }
