import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DurchsuchenServiceModule } from '..';
import { DurchsuchenSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DurchsuchenServiceModule
  ],
  declarations: [DurchsuchenSorterComponent],
  exports: [DurchsuchenSorterComponent],

})
export class DurchsuchenSorterComponentModule { }
