import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostStandortCommandShapes, IPostStandortRequest, IPostStandortResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostStandortCommandService extends PostCommandService<IPostStandortRequest, IPostStandortResponse, IPostStandortCommandShapes> {
  readonly route = 'personen/settings/standort';
}
