import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostRechnungCommandShapes, IPostRechnungRequest, IPostRechnungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostRechnungCommandService extends PostCommandService<IPostRechnungRequest, IPostRechnungResponse, IPostRechnungCommandShapes> {
  readonly route = 'finanzen/rechnung';
  override dependencies = [
    'finanzen/extra-vereinbarung-entfernt',
    'finanzen/extra-vereinbarung-gespeichert',
    'finanzen/honorar-vereinbarung-entfernt',
    'finanzen/honorar-vereinbarung-gespeichert',
    'kunden/kunde-entfernt',
    'kunden/kunde-gespeichert',
    'kunden/kunden-finanzen-gespeichert',
  ];

  getRechnungBesichtigungen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['rechnung.besichtigungen']?.find((v) => v.value === value);
  }

  get rechnungBesichtigungen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['rechnung.besichtigungen'] as ISelection[]) : [])));
  }

  getRechnungExtraVereinbarungs(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['rechnung.extraVereinbarungs']?.find((v) => v.value === value);
  }

  get rechnungExtraVereinbarungs(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['rechnung.extraVereinbarungs'] as ISelection[]) : [])));
  }

  getRechnungHonorarVereinbarung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['rechnung.honorarVereinbarung']?.find((v) => v.value === value);
  }

  get rechnungHonorarVereinbarung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['rechnung.honorarVereinbarung'] as ISelection[]) : [])));
  }
}
