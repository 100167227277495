import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { EintraegeComponent } from '.';
import { FelderQueryModule } from '../../../felder';
import { PostEintragCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, FelderQueryModule, PostEintragCommandModule],
  declarations: [EintraegeComponent],
  exports: [EintraegeComponent],
})
export class EintraegeComponentModule {}
