import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IRollesQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
   finanzen?: ISorterExpression;
   isAdmin?: ISorterExpression;
}

export const RollesQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'PERSONEN.Models.ROLLE.Propertys.ID.title'),
   SorterConfig('name', 'PERSONEN.Models.ROLLE.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'PERSONEN.Models.ROLLE.Propertys.BESCHREIBUNG.title'),
   SorterConfig('finanzen', 'PERSONEN.Models.ROLLE.Propertys.FINANZEN.title'),
   SorterConfig('isAdmin', 'Administrator'),
];
