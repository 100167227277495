import { NgModule } from '@angular/core';

import {
  PostFotoKategorieCommandComponentModule, PostFotoKategorieCommandServiceModule
} from '.';

@NgModule({
  imports: [
    PostFotoKategorieCommandServiceModule,
    PostFotoKategorieCommandComponentModule
  ],
  exports: [
    PostFotoKategorieCommandServiceModule,
    PostFotoKategorieCommandComponentModule
  ]
})
export class PostFotoKategorieCommandModule { }
