import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AuthService, MetaService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IZeitenResponseRow } from 'fa-kt.types';
import { toInitials } from 'pbc.functions';
import { ProjekteSelectService } from '../../../../projekte';
import { ZeitenService } from '../service';

@Component({
  selector: 'fa-kt-zeiten-list',
  templateUrl: './zeiten-list.component.html',
  styleUrls: ['./zeiten-list.component.css'],
})
export class ZeitenListComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  /* << props    */
  $projekte = new BehaviorSubject<ISelection[]>([]);
  /*    props >> */

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public zeiten: ZeitenService,
    public projekte: ProjekteSelectService,
    public auth: AuthService,
    public meta: MetaService,
  ) {}

  public set(zeiten: IZeitenResponseRow) {
    /* << custom    */
    this.select.emit(zeiten.zeit.id);
    /*    custom >> */
  }

  /* << functions    */
  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  initialen(str: string): string {
    return toInitials(str);
  }
  /*    functions >> */
}
