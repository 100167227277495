import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteProjektArtRequest, IDeleteProjektArtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteProjektArtCommandService extends DeleteCommandService<IDeleteProjektArtRequest, IDeleteProjektArtResponse> {
  readonly route = 'projekte/settings/projekt-art';
}
