import { NgModule } from '@angular/core';
import {
  AbschnitteComponentModule
 } from '.';

@NgModule({
  imports: [
    AbschnitteComponentModule
  ],
  exports: [
    AbschnitteComponentModule
  ]
})
export class TexteComponentsModule { }
