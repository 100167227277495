import { NgModule } from '@angular/core';

import {
  PostKonstanteCommandServiceModule,
  PostKonstanteCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostKonstanteCommandServiceModule,
    PostKonstanteCommandComponentModule
  ],
  exports: [
    PostKonstanteCommandServiceModule,
    PostKonstanteCommandComponentModule
  ]
})
export class PostKonstanteCommandModule { }
