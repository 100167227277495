import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "pbc.angular";

import { ZeitService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    ZeitService,
  ],
  exports: []
})
export class ZeitServiceModule { }
