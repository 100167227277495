import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { FinanzenComponentsModule } from '../finanzen';
import { KommunikationComponentsModule } from '../kommunikation';

import { KundenCommandsModule } from './commands';
import { KundenComponentsModule } from './components';
import { KundenPage, KundenPageResolver, KundePage, KundePageResolver } from './pages';
import { KundenQuerysModule, KundenService, KundeService } from './querys';
import {
  KundenArtsPage,
  KundenArtsPageResolver,
  KundenArtsService,
  KundenArtsServiceModule,
  KundenGruppesPage,
  KundenGruppesPageResolver,
  KundenGruppesService,
  KundenGruppesServiceModule,
  KundenSettingsModule,
} from './settings';

const routes: Routes = [
  {
    path: 'kunden',
    children: [
      {
        path: 'kunden',
        component: KundenPage,
        resolve: {
          querys: KundenPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['kunden'],
            roles: ['GET_KUNDEN'],
          },
        },
      },

      {
        path: 'kunde',
        component: KundePage,
        resolve: {
          querys: KundePageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['kunden'],
            roles: ['GET_KUNDE', 'DELETE_KUNDE', 'POST_KUNDE', 'POST_KUNDEN_FINANZEN'],
          },
        },
      },
    ],
  },
  {
    path: 'kunden',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'kunden-arts',
            component: KundenArtsPage,
            resolve: {
              querys: KundenArtsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_KUNDEN_ARTS'],
                  write: ['POST_KUNDEN_ART', 'DELETE_KUNDEN_ART'],
                },
              },
            },
          },

          {
            path: 'kunden-gruppes',
            component: KundenGruppesPage,
            resolve: {
              querys: KundenGruppesPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_KUNDEN_GRUPPES'],
                  write: ['POST_KUNDEN_GRUPPE', 'DELETE_KUNDEN_GRUPPE'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [KundePage, KundenPage, KundenArtsPage, KundenGruppesPage],
  imports: [
    LibsCommonAngularModule,
    FinanzenComponentsModule,
    KommunikationComponentsModule,
    KundenSettingsModule,
    KundenComponentsModule,
    KundenCommandsModule,
    KundenQuerysModule,
    KundenArtsServiceModule,
    KundenGruppesServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [KundenSettingsModule, KundenComponentsModule, KundenCommandsModule, KundenQuerysModule, KundenArtsServiceModule, KundenGruppesServiceModule],
})
export class KundenModule {
  static forRoot(): ModuleWithProviders<KundenModule> {
    return {
      ngModule: KundenModule,
      providers: [KundeService, KundenService, KundenArtsService, KundenGruppesService],
    };
  }
}
