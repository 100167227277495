import { InjectionToken } from '@angular/core';
export const APP_CONFIG = new InjectionToken('environment');
export const APP_TITLE = new InjectionToken('title');
export const APP_VERSION = new InjectionToken('version');
export const APP_BUILD = new InjectionToken('build');

export interface AppEnvironment {
  production: boolean;
  stage: string | 'production' | 'development';
  serverURL: string;
  blobs: {
    baseUrl: string;
    driveID: string;
    account: string;
  };
  appInsights: {
    instrumentationKey: string;
  };
  auth: {
    principal: string;
    clientId: string;
    authority: string;
    baseURL: string;
    scopes: string[];
  };
  shared: string;
  admin?: boolean;
  no401?: boolean;
}
