<!---
  <div formGroupName="zeile">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bericht">{{ command.Inputs.BERICHT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BerichtErrorTip">
        <ng-template #BerichtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BERICHT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="bericht" id="bericht" [shapes]="postZeile.zeileBericht | async" [placeholder]="command.Inputs.BERICHT.placeholder" cdkFocusInitial required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="land">{{ command.Inputs.LAND.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LandErrorTip">
        <ng-template #LandErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LAND.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="land" id="land" [placeholder]="command.Inputs.LAND.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bundesland">{{ command.Inputs.BUNDESLAND.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BundeslandErrorTip">
        <ng-template #BundeslandErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BUNDESLAND.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bundesland" id="bundesland" [placeholder]="command.Inputs.BUNDESLAND.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="plz">{{ command.Inputs.PLZ.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PlzErrorTip">
        <ng-template #PlzErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PLZ.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="plz" id="plz" [placeholder]="command.Inputs.PLZ.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="region">{{ command.Inputs.REGION.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RegionErrorTip">
        <ng-template #RegionErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.REGION.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="region" id="region" [placeholder]="command.Inputs.REGION.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kreisStadt">{{ command.Inputs.KREIS_STADT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KreisStadtErrorTip">
        <ng-template #KreisStadtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREIS_STADT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="kreisStadt" id="kreisStadt" [placeholder]="command.Inputs.KREIS_STADT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gemeindeBezirk">{{ command.Inputs.GEMEINDE_BEZIRK.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GemeindeBezirkErrorTip">
        <ng-template #GemeindeBezirkErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GEMEINDE_BEZIRK.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="gemeindeBezirk" id="gemeindeBezirk" [placeholder]="command.Inputs.GEMEINDE_BEZIRK.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="ortsStadtTeil">{{ command.Inputs.ORTS_STADT_TEIL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OrtsStadtTeilErrorTip">
        <ng-template #OrtsStadtTeilErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ORTS_STADT_TEIL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="ortsStadtTeil" id="ortsStadtTeil" [placeholder]="command.Inputs.ORTS_STADT_TEIL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gemeindeschluessel">{{ command.Inputs.GEMEINDESCHLUESSEL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GemeindeschluesselErrorTip">
        <ng-template #GemeindeschluesselErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GEMEINDESCHLUESSEL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="gemeindeschluessel" id="gemeindeschluessel" [placeholder]="command.Inputs.GEMEINDESCHLUESSEL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="teilmarkt">{{ command.Inputs.TEILMARKT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TeilmarktErrorTip">
        <ng-template #TeilmarktErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TEILMARKT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="teilmarkt" id="teilmarkt" [placeholder]="command.Inputs.TEILMARKT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bereiche">{{ command.Inputs.BEREICHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BereicheErrorTip">
        <ng-template #BereicheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEREICHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="bereiche" id="bereiche" [shapes]="postZeile.zeileBereiche | async" [placeholder]="command.Inputs.BEREICHE.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="assetklasse">{{ command.Inputs.ASSETKLASSE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AssetklasseErrorTip">
        <ng-template #AssetklasseErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ASSETKLASSE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="assetklasse" id="assetklasse" [placeholder]="command.Inputs.ASSETKLASSE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="objektart">{{ command.Inputs.OBJEKTART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektartErrorTip">
        <ng-template #ObjektartErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKTART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="objektart" id="objektart" [placeholder]="command.Inputs.OBJEKTART.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zustand">{{ command.Inputs.ZUSTAND.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ZustandErrorTip">
        <ng-template #ZustandErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ZUSTAND.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="zustand" id="zustand" [placeholder]="command.Inputs.ZUSTAND.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="vertrag">{{ command.Inputs.VERTRAG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VertragErrorTip">
        <ng-template #VertragErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VERTRAG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="vertrag" id="vertrag" [placeholder]="command.Inputs.VERTRAG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wohnlage">{{ command.Inputs.WOHNLAGE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WohnlageErrorTip">
        <ng-template #WohnlageErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WOHNLAGE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="wohnlage" id="wohnlage" [placeholder]="command.Inputs.WOHNLAGE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wflVon">{{ command.Inputs.WFL_VON.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WflVonErrorTip">
        <ng-template #WflVonErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WFL_VON.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="wflVon" id="wflVon" [placeholder]="command.Inputs.WFL_VON.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wflBis">{{ command.Inputs.WFL_BIS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WflBisErrorTip">
        <ng-template #WflBisErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WFL_BIS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="wflBis" id="wflBis" [placeholder]="command.Inputs.WFL_BIS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wflMittel">{{ command.Inputs.WFL_MITTEL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WflMittelErrorTip">
        <ng-template #WflMittelErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WFL_MITTEL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="wflMittel" id="wflMittel" [placeholder]="command.Inputs.WFL_MITTEL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bjVon">{{ command.Inputs.BJ_VON.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BjVonErrorTip">
        <ng-template #BjVonErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BJ_VON.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bjVon" id="bjVon" [placeholder]="command.Inputs.BJ_VON.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bjBis">{{ command.Inputs.BJ_BIS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BjBisErrorTip">
        <ng-template #BjBisErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BJ_BIS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bjBis" id="bjBis" [placeholder]="command.Inputs.BJ_BIS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wohnNutzWert">{{ command.Inputs.WOHN_NUTZ_WERT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WohnNutzWertErrorTip">
        <ng-template #WohnNutzWertErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WOHN_NUTZ_WERT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="wohnNutzWert" id="wohnNutzWert" [placeholder]="command.Inputs.WOHN_NUTZ_WERT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="ausstattung">{{ command.Inputs.AUSSTATTUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AusstattungErrorTip">
        <ng-template #AusstattungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AUSSTATTUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="ausstattung" id="ausstattung" [placeholder]="command.Inputs.AUSSTATTUNG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="artDesWertes">{{ command.Inputs.ART_DES_WERTES.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ArtDesWertesErrorTip">
        <ng-template #ArtDesWertesErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ART_DES_WERTES.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="artDesWertes" id="artDesWertes" [placeholder]="command.Inputs.ART_DES_WERTES.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="einheit">{{ command.Inputs.EINHEIT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="EinheitErrorTip">
        <ng-template #EinheitErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.EINHEIT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="einheit" id="einheit" [placeholder]="command.Inputs.EINHEIT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bezug">{{ command.Inputs.BEZUG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BezugErrorTip">
        <ng-template #BezugErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEZUG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bezug" id="bezug" [placeholder]="command.Inputs.BEZUG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="durchschnitt">{{ command.Inputs.DURCHSCHNITT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DurchschnittErrorTip">
        <ng-template #DurchschnittErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DURCHSCHNITT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="durchschnitt" id="durchschnitt" [nzPlaceHolder]="command.Inputs.DURCHSCHNITT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="minimum">{{ command.Inputs.MINIMUM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MinimumErrorTip">
        <ng-template #MinimumErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MINIMUM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="minimum" id="minimum" [nzPlaceHolder]="command.Inputs.MINIMUM.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="maximum">{{ command.Inputs.MAXIMUM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MaximumErrorTip">
        <ng-template #MaximumErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MAXIMUM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="maximum" id="maximum" [nzPlaceHolder]="command.Inputs.MAXIMUM.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="spitzenwerte">{{ command.Inputs.SPITZENWERTE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SpitzenwerteErrorTip">
        <ng-template #SpitzenwerteErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SPITZENWERTE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="spitzenwerte" id="spitzenwerte" [nzPlaceHolder]="command.Inputs.SPITZENWERTE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anzahl">{{ command.Inputs.ANZAHL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnzahlErrorTip">
        <ng-template #AnzahlErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANZAHL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="anzahl" id="anzahl" [nzPlaceHolder]="command.Inputs.ANZAHL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steigerung_1">{{ command.Inputs.STEIGERUNG_1.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="Steigerung_1ErrorTip">
        <ng-template #Steigerung_1ErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEIGERUNG_1.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="steigerung_1" id="steigerung_1" [nzPlaceHolder]="command.Inputs.STEIGERUNG_1.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steigerung_2">{{ command.Inputs.STEIGERUNG_2.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="Steigerung_2ErrorTip">
        <ng-template #Steigerung_2ErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEIGERUNG_2.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="steigerung_2" id="steigerung_2" [nzPlaceHolder]="command.Inputs.STEIGERUNG_2.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steigerung_3">{{ command.Inputs.STEIGERUNG_3.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="Steigerung_3ErrorTip">
        <ng-template #Steigerung_3ErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEIGERUNG_3.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="steigerung_3" id="steigerung_3" [nzPlaceHolder]="command.Inputs.STEIGERUNG_3.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steigerung_4">{{ command.Inputs.STEIGERUNG_4.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="Steigerung_4ErrorTip">
        <ng-template #Steigerung_4ErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEIGERUNG_4.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="steigerung_4" id="steigerung_4" [nzPlaceHolder]="command.Inputs.STEIGERUNG_4.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steigerung_5">{{ command.Inputs.STEIGERUNG_5.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="Steigerung_5ErrorTip">
        <ng-template #Steigerung_5ErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEIGERUNG_5.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="steigerung_5" id="steigerung_5" [nzPlaceHolder]="command.Inputs.STEIGERUNG_5.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steigerungArt">{{ command.Inputs.STEIGERUNG_ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SteigerungArtErrorTip">
        <ng-template #SteigerungArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEIGERUNG_ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="steigerungArt" id="steigerungArt" [shapes]="postZeile.zeileSteigerungArt | async" [placeholder]="command.Inputs.STEIGERUNG_ART.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
 {{ command.translation }}
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
