import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteErforderlichesDokumentRequest, IDeleteErforderlichesDokumentResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteErforderlichesDokumentCommandService extends DeleteCommandService<IDeleteErforderlichesDokumentRequest, IDeleteErforderlichesDokumentResponse> {
  readonly route = 'dokumente/settings/erforderliches-dokument';
}
