import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostStartTourCommandShapes, IPostStartTourRequest, IPostStartTourResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostStartTourCommandService extends PostCommandService<IPostStartTourRequest, IPostStartTourResponse, IPostStartTourCommandShapes> {
  readonly route = 'besichtigungen/start-tour';
  override dependencies = ['besichtigungen/written-besichtigungs-status', 'besichtigungen/removed-besichtigungs-status', 'besichtigungen/written-tour-status', 'besichtigungen/removed-tour-status'];

  getBesichtigungBesichtigungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigung.besichtigungsStatus']?.find((v) => v.value === value);
  }

  get besichtigungBesichtigungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigung.besichtigungsStatus'] as ISelection[]) : [])));
  }

  getTourMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.mitarbeiter']?.find((v) => v.value === value);
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.mitarbeiter'] as ISelection[]) : [])));
  }

  getBesichtigungMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigung.mitarbeiter']?.find((v) => v.value === value);
  }

  get besichtigungMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigung.mitarbeiter'] as ISelection[]) : [])));
  }

  getTourTourStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.tourStatus']?.find((v) => v.value === value);
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.tourStatus'] as ISelection[]) : [])));
  }
}
