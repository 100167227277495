<cdk-virtual-scroll-viewport *ngIf="zeiten.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (zeiten.result$ | async).zeiten | reverse" (click)="set(entry)">
      <nz-list-item-meta>
        <nz-list-item-meta-avatar>
          <nz-avatar
            [nzSrc]="entry.zeit.mitarbeiter | type: (meta.users$ | async) : 'avatar' | safe: 'url'"
            [nzText]="entry.zeit.mitarbeiter | type: (meta.users$ | async) : 'initialen'"
            nz-tooltip
            [nzTooltipTitle]="entry.zeit.mitarbeiter | type: (meta.users$ | async) : 'label'"
          >
          </nz-avatar>
        </nz-list-item-meta-avatar>
        <nz-list-item-meta-title>
          <a>
            {{ entry.zeit.datum | date: 'dd.MM.yyyy HH:mm' }}
            <nz-divider nzType="vertical"></nz-divider>
            <span *ngIf="entry.zeit.stunden">{{ entry.zeit.stunden || 0 }} Std. </span><span *ngIf="entry.zeit.minuten">{{ entry.zeit.minuten || 0 }} Min.</span>
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-typography nzType="secondary">{{ entry.zeit.zeitArt | type: (zeiten.zeitZeitArt | async) }}</span>
            <span nz-typography nzType="secondary" *ngIf="entry.zeit.zeitUnterart">{{ entry.zeit.zeitUnterart | type: (zeiten.zeitZeitUnterart | async) }}</span>
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-typography nzType="secondary">{{ entry.zeit.projekt | type: (projekte.selection$ | async) }}</span>
            <ng-container *ngIf="auth.$isFinanzen | async">
              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography nzType="secondary">{{ entry.zeit.kosten | number: '1.0-2' : 'de' }} € </span>
            </ng-container>
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <!--- << extra    -->
          <!---    extra >> -->
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
