import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISelection } from 'pbc.types';

import { IDurchgangQueryShapes, IDurchgangRequest, IDurchgangResponse } from 'fa-kt.types';
import { DetailQueryService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DurchgangService extends DetailQueryService<IDurchgangRequest, IDurchgangResponse, IDurchgangQueryShapes> {
  route = 'formulare/durchgang';
  override dependencies = [
    'felder/written-feld-kategorie',
    'felder/removed-feld-kategorie',
    'felder/written-feld-unterkategorie',
    'felder/removed-feld-unterkategorie',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',

    'felder/bewertet',
    'felder/feld-entfernt',
    'felder/feld-gespeichert',
    'felder/feld-option-entfernt',
    'felder/feld-option-gespeichert',
    'felder/feld-optionen-geordnet',
    'formulare/durchgang-gestartet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert',
  ];

  getFormularBewertungsAnlaesse(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.bewertungsAnlaesse']?.find((v) => v.value === value);
  }

  get formularBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.bewertungsAnlaesse'] as ISelection[]) : [])));
  }

  getFormularOhneFelder(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.ohneFelder']?.find((v) => v.value === value);
  }

  get formularOhneFelder(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.ohneFelder'] as ISelection[]) : [])));
  }

  getEintraegeFeld(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['eintraege.feld']?.find((v) => v.value === value);
  }

  get eintraegeFeld(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['eintraege.feld'] as ISelection[]) : [])));
  }

  getFeldUnterkategorienFeldKategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['feldUnterkategorien.feldKategorie']?.find((v) => v.value === value);
  }

  get feldUnterkategorienFeldKategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feldUnterkategorien.feldKategorie'] as ISelection[]) : [])));
  }

  getFelderFeldKategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['felder.feldKategorie']?.find((v) => v.value === value);
  }

  get felderFeldKategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['felder.feldKategorie'] as ISelection[]) : [])));
  }

  getFelderFeldUnterkategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['felder.feldUnterkategorie']?.find((v) => v.value === value);
  }

  get felderFeldUnterkategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['felder.feldUnterkategorie'] as ISelection[]) : [])));
  }

  getEintraegeGutachten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['eintraege.gutachten']?.find((v) => v.value === value);
  }

  get eintraegeGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['eintraege.gutachten'] as ISelection[]) : [])));
  }

  getFormularKunden(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.kunden']?.find((v) => v.value === value);
  }

  get formularKunden(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.kunden'] as ISelection[]) : [])));
  }

  getFormularKundenArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.kundenArten']?.find((v) => v.value === value);
  }

  get formularKundenArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.kundenArten'] as ISelection[]) : [])));
  }

  getFormularObjektArten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['formular.objektArten']?.find((v) => v.value === value);
  }

  get formularObjektArten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['formular.objektArten'] as ISelection[]) : [])));
  }
}
