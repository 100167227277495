import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ReisekostenabrechnungenServiceModule } from '..';
import { ReisekostenabrechnungenFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungenServiceModule
  ],
  declarations: [ReisekostenabrechnungenFilterComponent],
  exports: [ReisekostenabrechnungenFilterComponent],

})
export class ReisekostenabrechnungenFilterComponentModule { }
