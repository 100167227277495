import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostExtraVereinbarungCommandServiceModule } from '..';
import { PostExtraVereinbarungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostExtraVereinbarungCommandServiceModule
  ],
  declarations: [PostExtraVereinbarungCommandComponent],
  exports: [PostExtraVereinbarungCommandComponent],

})
export class PostExtraVereinbarungCommandComponentModule { }
