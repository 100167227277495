import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ISecrets } from 'pbc.types';

@Injectable({
  providedIn: 'root',
})
export class SecretService {
  readonly $secrets = new BehaviorSubject<ISecrets | undefined>(undefined);

  constructor() {}
}
