import { NgModule } from '@angular/core';

import { LibsCommonAngularModule } from 'pbc.angular';

import { BesichtigungenCommandsModule } from '../commands';
import { BesichtigungComponent } from './';

@NgModule({
  declarations: [BesichtigungComponent],
  imports: [LibsCommonAngularModule, BesichtigungenCommandsModule],
  exports: [],
})
export class BesichtigungenComponentsModule {}
