<button
  type="button"
  nz-button
  nzType="default"
  nzShape="circle"
  [style.backgroundColor]="_color"
  [style.color]="_color"
  nz-popover
  nzPopoverTrigger="click"
  [nzPopoverTitle]="undefined"
  [nzPopoverContent]="ColorPickerContent"
  [nzPopoverVisible]="visible && !disabled"
  (nzPopoverVisibleChange)="group = undefined; visible = $event; markAsTouched()"
>
  >
  <label [style.backgroundColor]="_color" [style.color]="_color"> </label>
</button>

<ng-template #ColorPickerContent>
  <div [ngSwitch]="group === undefined" class="selection-popover">
    <ng-container *ngSwitchCase="true">
      <button
        nz-button
        nzType="default"
        nzShape="circle"
        *ngFor="let group of groups"
        (click)="setGroup(group)"
        [style.backgroundColor]="group.preview"
        [style.color]="group.preview"
        style="margin: 6px"
      >
        <label [style.backgroundColor]="group.preview" [style.color]="group.preview"> </label>
      </button>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <button nz-button nzType="dashed" nzShape="circle" (click)="group = undefined" style="margin: 6px">
        <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
      </button>
      <button nz-button nzType="default" nzShape="circle" *ngFor="let color of group?.colors" (click)="setColor(color)" [style.backgroundColor]="color" [style.color]="color" style="margin: 6px">
        <label [style.backgroundColor]="color" [style.color]="color"> </label>
      </button>
    </ng-container>
    <br />
    <nz-input-group nzAddOnBefore="HEX" style="margin-top: 12px">
      <input nz-input [(ngModel)]="_color" (ngModelChange)="setColor($event, true)" />
    </nz-input-group>
  </div>
</ng-template>
