import { NgModule } from '@angular/core';

import {
  DeleteObjektNutzungCommandServiceModule,
  DeleteObjektNutzungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteObjektNutzungCommandServiceModule,
    DeleteObjektNutzungCommandComponentModule
  ],
  exports: [
    DeleteObjektNutzungCommandServiceModule,
    DeleteObjektNutzungCommandComponentModule
  ]
})
export class DeleteObjektNutzungCommandModule { }
