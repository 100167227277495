import { NgModule } from '@angular/core';

import { HerausgebersSettingsModule, TemplatesSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [HerausgebersSettingsModule, TemplatesSettingsModule],
  exports: [HerausgebersSettingsModule, TemplatesSettingsModule],
})
export class MarktSettingsModule {}
