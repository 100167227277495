import { Component, OnInit } from '@angular/core';
import { HTTPS_METHOD } from 'pbc.types';
import { OfflineStoreService } from '../../services';

@Component({
  selector: 'pbc-offline-cache',
  templateUrl: './offline-cache.component.html',
  styleUrls: ['./offline-cache.component.css'],
})
export class OfflineCacheComponent implements OnInit {
  public HTTPS_METHOD = HTTPS_METHOD;

  constructor(public offlineCache: OfflineStoreService) {}

  ngOnInit(): void {}
}
