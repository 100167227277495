import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IProjekteRequest, IProjekteResponse, IUmkreissucheRequest, IUmkreissucheResponse } from 'fa-kt.types';
import { ProjekteService } from '../../../projekte';
import { UmkreissucheService } from '../../querys';

export interface IUmkreissuchePageResolverResult {
  umkreissuche?: IUmkreissucheResponse | void;
  projekte?: IProjekteResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class UmkreissuchePageResolver implements Resolve<IUmkreissuchePageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private projekte: ProjekteService,
    private umkreissuche: UmkreissucheService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IUmkreissuchePageResolverResult> {
    this.loading.resolving();
    const requests = {
      umkreissuche: {} as IUmkreissucheRequest,
      projekte: {} as IProjekteRequest,
    };
    const { distance, latitude, longitude, inklusiveInaktive } = route.queryParams;
    requests.umkreissuche = {
      distance: Number(distance) || 0,
      latitude,
      longitude,
      inklusiveInaktive: inklusiveInaktive === 'true',
    };
    const log = (error: any) => console.error(error);
    const [umkreissuche, projekte] = await Promise.all([this.umkreissuche.request(requests.umkreissuche, true).catch(log), this.projekte.request(this.projekte.payload$.getValue() || {}).catch(log)]);
    this.loading.resolved();
    return {
      umkreissuche,
      projekte,
    };
  }
}
