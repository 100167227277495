import { IFilter, IFilterConfig } from "pbc.types";

import { IKalkulation } from '.';

export interface IKalkulationFilter extends IFilter<IKalkulation> {}

export const KalkulationFilterConfig: IFilterConfig<IKalkulation> = [];

export type IKalkulationField =
  'kalkulation.id'|
  'kalkulation.gutachten'|
  'kalkulation.datei'
;
