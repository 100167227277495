import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IObjekteResponseRow } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { ObjekteService } from '../service';

@Component({
  selector: 'fa-kt-objekte-list',
  templateUrl: './objekte-list.component.html',
  styleUrls: ['./objekte-list.component.css'],
})
export class ObjekteListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public objekte: ObjekteService) {}

  public set(objekte: IObjekteResponseRow) {
    /* << custom    */
    this.select.emit(objekte.objekt.id);
    /*    custom >> */
  }
}
