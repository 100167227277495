import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[noDoubleClick]',
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
  @Input()
  throttleTime = 500;

  @Output()
  tClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription;

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.subscription = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe((e) => this.emitThrottledClick(e));
  }

  emitThrottledClick(e) {
    this.tClick.emit(e);
    if (this.elRef?.nativeElement) this.elRef.nativeElement.disabled = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.elRef?.nativeElement) this.elRef.nativeElement.disabled = false;
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
    if (this.elRef?.nativeElement) this.elRef.nativeElement.disabled = true;
  }
}
