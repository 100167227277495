import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KrankheitCommandComponent } from '.';
import { DeleteKrankheitCommandService } from '..';
import { PostKrankheitCommandService } from '../post-krankheit.service';

@NgModule({
  imports: [LIB_CommonModule],
  declarations: [KrankheitCommandComponent],
  providers: [PostKrankheitCommandService, DeleteKrankheitCommandService],
  exports: [KrankheitCommandComponent],
})
export class KrankheitCommandComponentModule {}
