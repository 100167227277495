import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { StandortsServiceModule } from '..';
import { StandortsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    StandortsServiceModule
  ],
  declarations: [StandortsListComponent],
  exports: [StandortsListComponent],

})
export class StandortsListComponentModule { }
