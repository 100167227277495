import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { VorlagenServiceModule } from '..';
import { VorlagenSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlagenServiceModule
  ],
  declarations: [VorlagenSorterComponent],
  exports: [VorlagenSorterComponent],

})
export class VorlagenSorterComponentModule { }
