import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IOrtKategoriesResponse } from '.';

export interface IOrtKategoriesQueryFilter extends IFilter<IOrtKategoriesResponse> {}

export const OrtKategoriesQueryFilterConfig: IFilterConfig<IOrtKategoriesResponse> = [];

export interface IOrtKategoriesQueryShapes extends IShape {}

export type IOrtKategoriesQueryField = 'bereichs.id' | 'bereichs.name' | 'bereichs.kommentar' | 'bereichs.areal' | '';
