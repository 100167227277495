import { Component } from '@angular/core';
import { TageService } from '../service';

@Component({
  selector: 'fa-kt-saved-tage-filters',
  templateUrl: './saved-tage-filters.component.html',
  styleUrls: ['./saved-tage-filters.component.css'],
})
export class SavedTageFiltersComponent {
  constructor(public tage: TageService) {}
}
