import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IKonstantesResponse } from '.';

export interface IKonstantesQueryFilter extends IFilter<IKonstantesResponse> {}

export const KonstantesQueryFilterConfig: IFilterConfig<IKonstantesResponse> = [];

export interface IKonstantesQueryShapes extends IShape {}

export type IKonstantesQueryField = 'konstantes.id' | 'konstantes.art' | 'konstantes.inhalt' | 'konstantes.kommentar' | '';
