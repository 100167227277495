import { NgModule } from '@angular/core';

import { DeleteBesichtigungsStatusCommandModule, PostBesichtigungsStatusCommandModule } from './commands';
import { BesichtigungsStatiQueryModule } from './query';

@NgModule({
  imports: [PostBesichtigungsStatusCommandModule, DeleteBesichtigungsStatusCommandModule, BesichtigungsStatiQueryModule],
  exports: [PostBesichtigungsStatusCommandModule, DeleteBesichtigungsStatusCommandModule, BesichtigungsStatiQueryModule],
})
export class BesichtigungsStatiSettingsModule {}
