import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteGutachtenRequest, IDeleteGutachtenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteGutachtenCommandService extends DeleteCommandService<IDeleteGutachtenRequest, IDeleteGutachtenResponse> {
  readonly route = 'gutachten/gutachten';
}
