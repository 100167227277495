import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IKundenQueryFilter, IKundenQueryShapes, IKundenQuerySorter, IKundenRequest, IKundenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class KundenService extends ListQueryService<IKundenRequest, IKundenResponse, IKundenQueryShapes, IKundenQueryFilter, IKundenQuerySorter> {
  contentKey: keyof IKundenResponse = 'kunden';
  route = 'kunden/kunden';
  override dependencies = [
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',
    'kunden/kunde-entfernt',
    'kunden/kunde-gespeichert',
  ];

  static instance: KundenService;

  constructor() {
    super();
    if (!KundenService.instance) KundenService.instance = this;
    return KundenService.instance;
  }

  getKundeKundenArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenArt']?.find((v) => v.value === value);
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenArt'] as ISelection[]) : [])));
  }

  getKundeKundenGruppe(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenGruppe']?.find((v) => v.value === value);
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenGruppe'] as ISelection[]) : [])));
  }
}
