import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FelderServiceModule } from '..';
import { FelderSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FelderServiceModule
  ],
  declarations: [FelderSorterComponent],
  exports: [FelderSorterComponent],

})
export class FelderSorterComponentModule { }
