import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteTemplateCommandServiceModule } from '..';
import { DeleteTemplateCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteTemplateCommandServiceModule
  ],
  declarations: [DeleteTemplateCommandComponent],
  exports: [DeleteTemplateCommandComponent],

})
export class DeleteTemplateCommandComponentModule { }
