import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteRolleRequest, IDeleteRolleResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteRolleCommandService extends DeleteCommandService<IDeleteRolleRequest, IDeleteRolleResponse> {
  readonly route = 'personen/settings/rolle';
}
