import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektNutzungsServiceModule } from '..';
import { ObjektNutzungsFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektNutzungsServiceModule
  ],
  declarations: [ObjektNutzungsFilterComponent],
  exports: [ObjektNutzungsFilterComponent],

})
export class ObjektNutzungsFilterComponentModule { }
