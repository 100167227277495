import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { format } from 'date-fns';
import { IDeleteZahlungseingangRequest, IDeleteZahlungseingangResponse, IPostZahlungseingangRequest, IPostZahlungseingangResponse, IZahlungseingang } from 'fa-kt.types';
import { round } from 'lodash';
import { ISelection } from 'pbc.types';
import { ProjektService } from '../../../projekte';
import { DeleteZahlungseingangCommandService, PostZahlungseingangCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-zahlungseingaenge',
  templateUrl: './zahlungseingaenge.component.html',
  styleUrls: ['./zahlungseingaenge.component.css'],
})
export class ZahlungseingaengeComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postZahlungseingang: BehaviorSubject<IPostZahlungseingangRequest | null> = new BehaviorSubject<IPostZahlungseingangRequest | null>(null);
  public readonly $deleteZahlungseingang: BehaviorSubject<IDeleteZahlungseingangRequest | null> = new BehaviorSubject<IDeleteZahlungseingangRequest | null>(null);

  @Input() disabled = false;
  @Input() projekt: string = '';
  @Input() kunde: string = '';
  @Input() zahlungseingaenge: IZahlungseingang[] = [];

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  selection$ = new BehaviorSubject<ISelection[]>([]);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postZahlungseingang: PostZahlungseingangCommandService,
    public deleteZahlungseingang: DeleteZahlungseingangCommandService,
    public projektService: ProjektService,
  ) {}

  async ngOnInit() {
    if (this.projekt) this.projektService.request({ id: this.projekt });
    /* << init    */
    this.postZahlungseingang.prepare().catch((e) => console.error);
    /*    init >> */
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),

        this.projektService.result$.subscribe((result) => {
          if (!result) return;
          this.selection$.next(
            result.rechnungen.map(({ bezeichnung, datum, finaleSumme, nettoSumme, id: value }) => ({
              label: `${bezeichnung} vom ${format(new Date(datum), 'dd.MM.yyyy')} (N: ${round(nettoSumme, 2)}€, B: ${round(finaleSumme, 2)}€)`,
              value,
            })),
          );
        }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostZahlungseingang(response?: IPostZahlungseingangResponse) {
    /* << after post-zahlungseingang    */
    this.openZahlungseingang(null);
    /*    after post-zahlungseingang >> */
  }

  async finishedDeleteZahlungseingang(response?: IDeleteZahlungseingangResponse) {
    /* << after delete-zahlungseingang    */
    this.openZahlungseingang(null);
    /*    after delete-zahlungseingang >> */
  }

  /* << methods    */
  public openZahlungseingang(zahlungseingang: IZahlungseingang | null | any) {
    if (!zahlungseingang) {
      this.$postZahlungseingang.next(null);
      this.$deleteZahlungseingang.next(null);
      return;
    }
    if (!zahlungseingang.projekt) {
      zahlungseingang.projekt = this.projekt;
      zahlungseingang.kunde = this.kunde;
    }
    this.$postZahlungseingang.next({ zahlungseingang });
    this.$deleteZahlungseingang.next({ zahlungseingang: zahlungseingang.id });
  }
  /*    methods >> */

  public async submitPostZahlungseingang(request?: IPostZahlungseingangRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postZahlungseingang.getValue();
    if (payload) {
      try {
        const response = await this.postZahlungseingang.request(payload);
        await this.finishedPostZahlungseingang(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteZahlungseingang(request?: IDeleteZahlungseingangRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteZahlungseingang.getValue();
    if (payload) {
      try {
        const response = await this.deleteZahlungseingang.request(payload);
        await this.finishedDeleteZahlungseingang(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
