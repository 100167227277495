import { Injectable } from '@angular/core';

import { SelectionService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class ProjekteSelectService extends SelectionService {
  override readonly route = 'projekte/projekte';
  override readonly dependencies = ['projekte/importiert', 'projekte/projekt-entfernt', 'projekte/projekt-gespeichert', 'projekte/projekt-kopiert'];
  override compressed: boolean = true;

  static instance: ProjekteSelectService;

  constructor() {
    super();
    if (!ProjekteSelectService.instance) ProjekteSelectService.instance = this;
    return ProjekteSelectService.instance;
  }
}
