import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { IDeleteExtraVereinbarungRequest, IDeleteExtraVereinbarungResponse, IExtraVereinbarung, IPostExtraVereinbarungRequest, IPostExtraVereinbarungResponse } from 'fa-kt.types';
import { getCapitalizedText } from 'pbc.functions';
import { DeleteExtraVereinbarungCommandService, PostExtraVereinbarungCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-extra-vereinbarungen',
  templateUrl: './extra-vereinbarungen.component.html',
  styleUrls: ['./extra-vereinbarungen.component.css'],
})
export class ExtraVereinbarungenComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postExtraVereinbarung: BehaviorSubject<IPostExtraVereinbarungRequest | null> = new BehaviorSubject<IPostExtraVereinbarungRequest | null>(null);
  public readonly $deleteExtraVereinbarung: BehaviorSubject<IDeleteExtraVereinbarungRequest | null> = new BehaviorSubject<IDeleteExtraVereinbarungRequest | null>(null);

  @Input() disabled = false;
  @Input() kunde: string = '';
  @Input() extraVereinbarungen: IExtraVereinbarung[] = [];

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  getCapitalizedText(value?: string) {
    return getCapitalizedText(value);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postExtraVereinbarung: PostExtraVereinbarungCommandService,
    public deleteExtraVereinbarung: DeleteExtraVereinbarungCommandService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostExtraVereinbarung(response?: IPostExtraVereinbarungResponse) {
    /* << after post-extra-vereinbarung    */
    this.openExtraVereinbarung(null);
    /*    after post-extra-vereinbarung >> */
  }

  async finishedDeleteExtraVereinbarung(response?: IDeleteExtraVereinbarungResponse) {
    /* << after delete-extra-vereinbarung    */
    this.openExtraVereinbarung(null);
    /*    after delete-extra-vereinbarung >> */
  }

  /* << methods    */
  public openExtraVereinbarung(extraVereinbarung: IExtraVereinbarung | null | any) {
    if (!extraVereinbarung) {
      this.$postExtraVereinbarung.next(null);
      this.$deleteExtraVereinbarung.next(null);
      return;
    }
    if (!extraVereinbarung.id) {
      extraVereinbarung.kunde = this.kunde;
    }
    this.$postExtraVereinbarung.next({ extraVereinbarung });
    if (extraVereinbarung && extraVereinbarung.id) {
      this.$deleteExtraVereinbarung.next({ extraVereinbarung: extraVereinbarung.id });
    } else {
      this.$deleteExtraVereinbarung.next(null);
    }
  }
  /*    methods >> */

  public async submitPostExtraVereinbarung(request?: IPostExtraVereinbarungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postExtraVereinbarung.getValue();
    if (payload) {
      try {
        const response = await this.postExtraVereinbarung.request(payload);
        await this.finishedPostExtraVereinbarung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteExtraVereinbarung(request?: IDeleteExtraVereinbarungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteExtraVereinbarung.getValue();
    if (payload) {
      try {
        const response = await this.deleteExtraVereinbarung.request(payload);
        await this.finishedDeleteExtraVereinbarung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
