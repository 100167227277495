import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IZeitArt } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { ZeitArtsService } from '../service';

@Component({
  selector: 'fa-kt-zeit-arts-list',
  templateUrl: './zeit-arts-list.component.html',
  styleUrls: ['./zeit-arts-list.component.css'],
})
export class ZeitArtsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IZeitArt> = new EventEmitter<IZeitArt>();

  constructor(public zeitArts: ZeitArtsService) {}

  ngOnDestroy() {}
}
