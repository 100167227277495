<p *ngFor="let check of health.$checks | async">
  <span nz-typography [nzType]="check.success ? 'success' : 'warning'"
    >{{ check.name }}
    <i *ngIf="check.secrets" nz-icon nzType="unlock" nzTheme="outline"></i>
    <ng-container [ngSwitch]="check.success">
      <i *ngSwitchCase="true" nz-icon nzType="check-circle" nzTheme="outline"></i>
      <i *ngSwitchDefault nz-icon nzType="warning" nzTheme="outline"></i>
    </ng-container>
  </span>
</p>
