import { Injectable } from '@angular/core';

import { IDeleteZeitRequest, IDeleteZeitResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteZeitCommandService extends DeleteCommandService<IDeleteZeitRequest, IDeleteZeitResponse> {
  readonly route = 'zeiten/zeit';
}
