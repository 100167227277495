import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { PersonenCommandsModule } from './commands';
import { PersonenComponentsModule } from './components';
import { IchPage, IchPageResolver, MitarbeiterPage, MitarbeiterPageResolver, ProfilPage, ProfilPageResolver } from './pages';
import { IchService, MitarbeiterService, PersonenQuerysModule, ProfilService } from './querys';
import { PersonenSettingsModule, RollesPage, RollesPageResolver, RollesService, RollesServiceModule, StandortsPage, StandortsPageResolver, StandortsService, StandortsServiceModule } from './settings';

const routes: Routes = [
  {
    path: 'personen',
    children: [
      {
        path: 'mitarbeiter',
        component: MitarbeiterPage,
        resolve: {
          querys: MitarbeiterPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['personen'],
            roles: ['GET_MITARBEITER', 'POST_SYNC_AZURE_ACTIVE_DIRECTORY'],
          },
        },
      },
      {
        path: 'profil',
        component: ProfilPage,
        resolve: {
          querys: ProfilPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['personen'],
            roles: ['GET_PROFIL', 'POST_MITARBEITER'],
          },
        },
      },
      {
        path: 'ich',
        component: IchPage,
        resolve: {
          querys: IchPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['personen'],
            roles: ['GET_PROFIL', 'POST_MITARBEITER'],
          },
        },
      },
    ],
  },
  {
    path: 'personen',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'rolles',
            component: RollesPage,
            resolve: {
              querys: RollesPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_ROLLES'],
                  write: ['POST_ROLLE', 'DELETE_ROLLE'],
                },
              },
            },
          },
          {
            path: 'standorts',
            component: StandortsPage,
            resolve: {
              querys: StandortsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_STANDORTS'],
                  write: ['POST_STANDORT', 'DELETE_STANDORT'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [MitarbeiterPage, ProfilPage, IchPage, RollesPage, StandortsPage],
  imports: [
    LibsCommonAngularModule,
    PersonenSettingsModule,
    PersonenComponentsModule,
    PersonenCommandsModule,
    PersonenQuerysModule,
    RollesServiceModule,
    StandortsServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [PersonenSettingsModule, PersonenComponentsModule, PersonenCommandsModule, PersonenQuerysModule, RollesServiceModule, StandortsServiceModule],
})
export class PersonenModule {
  static forRoot(): ModuleWithProviders<PersonenModule> {
    return {
      ngModule: PersonenModule,
      providers: [IchService, MitarbeiterService, ProfilService, RollesService, StandortsService],
    };
  }
}
