import { compile } from 'pbc.functions';

import { ISitemap, ISitemapContext, ISitemapEvent, ISitemapPage } from './types';

export function translateSitemap(sitemap: ISitemap) {
  return prepare(prepare(sitemap));
}

function prepare(sitemap: ISitemap): ISitemap {
  return JSON.parse(compile(JSON.stringify(sitemap), sitemap));
}

export function getEntryFromSitemap(sitemap: ISitemap, key: string): ISitemapPage | ISitemapContext {
  key = key.toUpperCase();
  key = key === 'SETTINGS' ? 'EINSTELLUNGEN' : key;
  return Object.entries(sitemap)
    .filter(([entry, value]) => entry === key || value.Pages[key] || value.Settings[key])
    .map(([entry, value]) => (value.Pages[key] ? value.Pages[key] : value.Settings[key] ? value.Settings[key] : value))[0];
}

export function getEventFromSitemap(sitemap: ISitemap, eventName: string): ISitemapEvent | undefined {
  if (!eventName) return undefined;
  const [context, event] = eventName.split('/');
  return sitemap[context.toUpperCase()] ? sitemap[context.toUpperCase()].Events[event] : undefined;
}

export function cleanObject<T>(obj: T, value: any = null): T {
  if (!obj) {
    return obj;
  }
  Object.entries(obj)
    .filter(([key, value]) => typeof value !== 'boolean' && typeof value !== 'number' && !value)
    .forEach(([key]) => delete obj[key as keyof typeof obj]);
  return obj;
}

export function checkSpecialBrowser(): boolean {
  return (
    window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1 || /iPad/i.test(window.navigator.userAgent) || /iPhone/i.test(window.navigator.userAgent)
  );
}

export function toConstant(text: string): string {
  if (!text) return '';
  return text
    .split(' ')
    .map((s) => s.toUpperCase())
    .join('_');
}

export function pushFiles(data: any, files: File[] = [], path = ''): File[] {
  if (!files) {
    files = [];
  }
  if (!data) {
    return files;
  } else if (Array.isArray(data)) {
    data.forEach((nested, i) => (files = pushFiles(nested, files, path + i)));
  } else if (typeof data === 'object' && !(data instanceof Date) && !isFile(data)) {
    Object.entries(data).forEach(([key, nested]) => {
      files = pushFiles(nested, files, path ? `${path}.${key}` : key);
    });
  } else if (isFile(data)) {
    files.push(data);
  }
  return files;
}

export function prepareCommand(data: any | object, path?: string, fileContainerName?: string) {
  if (!data) {
    return data;
  } else if (isFile(data)) {
    data = fileContainerName + '/' + data.name;
  } else if (data.latitude && data.longitude) {
    data.point = {
      type: 'Point',
      coordinates: [data.latitude, data.longitude],
    };
  } else if (Array.isArray(data)) {
    data = data.map((nested) => prepareCommand(nested, path, fileContainerName));
  } else if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.entries(data).forEach(([key, nested]) => {
      data[key] = prepareCommand(nested, path ? `${path}.${key}` : key, fileContainerName);
    });
  }
  return data;
}

function isFile(possibleFile: any) {
  return possibleFile instanceof File || (possibleFile.name && possibleFile.size);
}
