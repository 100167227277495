import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import {
  IDeleteFormularRequest,
  IDeleteFormularResponse,
  IFormularResponse,
  IPostDurchgangRequest,
  IPostDurchgangResponse,
  IPostFormularKopierenRequest,
  IPostFormularKopierenResponse,
  IPostFormularRequest,
  IPostFormularResponse,
} from 'fa-kt.types';
import { IFormularPageResolverResult } from '.';
import { DeleteFormularCommandService, PostDurchgangCommandService, PostFormularCommandService, PostFormularKopierenCommandService } from '../../commands';
import { FormularService } from '../../querys';

@Component({
  selector: 'fa-kt-formular-page',
  templateUrl: './formular.page.html',
  styleUrls: ['./formular.page.css'],
})
export class FormularPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $deleteFormular: BehaviorSubject<IDeleteFormularRequest | null> = new BehaviorSubject<IDeleteFormularRequest | null>(null);
  public readonly $postFormularKopieren: BehaviorSubject<IPostFormularKopierenRequest | null> = new BehaviorSubject<IPostFormularKopierenRequest | null>(null);
  public readonly $postFormular: BehaviorSubject<IPostFormularRequest | null> = new BehaviorSubject<IPostFormularRequest | null>(null);
  public readonly $postDurchgang: BehaviorSubject<IPostDurchgangRequest | null> = new BehaviorSubject<IPostDurchgangRequest | null>(null);

  public _felder: ISitemapPage;
  public _formulare: ISitemapPage;
  public _feld: ISitemapPage;
  public _formular: ISitemapPage;
  public _durchgang: ISitemapPage;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public formular: FormularService,
    public deleteFormular: DeleteFormularCommandService,
    public postFormularKopieren: PostFormularKopierenCommandService,
    public postFormular: PostFormularCommandService,
    public postDurchgang: PostDurchgangCommandService,
  ) {
    this.page = this.sitemap.FORMULARE.Pages.FORMULAR;
    this._felder = sitemap.FELDER.Pages.FELDER;
    this._formulare = sitemap.FORMULARE.Pages.FORMULARE;
    this._feld = sitemap.FELDER.Pages.FELD;
    this._formular = sitemap.FORMULARE.Pages.FORMULAR;
    this._durchgang = sitemap.FORMULARE.Pages.DURCHGANG;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IFormularPageResolverResult = data.querys;
          /* << resolver    */
          const formular = (querys.formular as IFormularResponse).formular;
          const id = formular?.id;
          this.$postFormular.next({ formular });
          if (id && id !== 'new') {
            this.$postFormularKopieren.next({ formular: id });
            this.$deleteFormular.next({ formular: id });
          }
          /*    resolver >> */
        }),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setFormular(id: string) {
    /* << set-formular    */
    /*    set-formular >> */
  }

  async finishedDeleteFormular(response?: IDeleteFormularResponse) {
    /* << after delete-formular    */
    await this.navigateToFormulare();
    /*    after delete-formular >> */
  }

  async finishedPostFormularKopieren(response?: IPostFormularKopierenResponse) {
    /* << after post-formular-kopieren    */
    await this.navigateToFormulare();
    await this.navigateToFormular({ id: (response as any).kopie });
    /*    after post-formular-kopieren >> */
  }

  async finishedPostFormular(response?: IPostFormularResponse) {
    /* << after post-formular    */
    await this.navigateToFormulare();
    /*    after post-formular >> */
  }

  async finishedPostDurchgang(response?: IPostDurchgangResponse) {
    /* << after post-durchgang    */
    await this.navigateToDurchgang(response);
    /*    after post-durchgang >> */
  }

  public async navigateToFelder(queryParams: Params = {}) {
    await this.router.navigate(this._felder.url, { queryParams });
  }
  public async navigateToFormulare(queryParams: Params = {}) {
    await this.router.navigate(this._formulare.url, { queryParams });
  }
  public async navigateToFeld(queryParams: Params = {}) {
    await this.router.navigate(this._feld.url, { queryParams });
  }
  public async navigateToFormular(queryParams: Params = {}) {
    await this.router.navigate(this._formular.url, { queryParams });
  }
  public async navigateToDurchgang(queryParams: Params = {}) {
    await this.router.navigate(this._durchgang.url, { queryParams });
  }

  back() {
    this.navigateToFormulare();
  }

  public async submitDeleteFormular(request?: IDeleteFormularRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteFormular.getValue();
    if (payload) {
      try {
        const response = await this.deleteFormular.request(payload);
        await this.finishedDeleteFormular(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostFormularKopieren(request?: IPostFormularKopierenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postFormularKopieren.getValue();
    if (payload) {
      try {
        const response = await this.postFormularKopieren.request(payload);
        await this.finishedPostFormularKopieren(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostFormular(request?: IPostFormularRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postFormular.getValue();
    if (payload) {
      try {
        const response = await this.postFormular.request(payload);
        await this.finishedPostFormular(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostDurchgang(request?: IPostDurchgangRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postDurchgang.getValue();
    if (payload) {
      try {
        const response = await this.postDurchgang.request(payload);
        await this.finishedPostDurchgang(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
