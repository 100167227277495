import { NgModule } from '@angular/core';

import {
  PostRechnungCommandServiceModule,
  PostRechnungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostRechnungCommandServiceModule,
    PostRechnungCommandComponentModule
  ],
  exports: [
    PostRechnungCommandServiceModule,
    PostRechnungCommandComponentModule
  ]
})
export class PostRechnungCommandModule { }
