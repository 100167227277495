<cdk-virtual-scroll-viewport *ngIf="formulare.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (formulare.result$ | async).formulare" (click)="set(entry)">
      <nz-list-item-meta [nzDescription]="ListDescription">
        <ng-template #ListDescription>
          <!--- << description    -->
          <!---    description >> -->
        </ng-template>
        <nz-list-item-meta-title>
          <a>
            <!--- << content    -->
            <b>{{ entry.formular.initialen }}</b>
            <nz-divider nzType="vertical"></nz-divider>
            {{ entry.formular.name }}
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-typography nzType="secondary"> {{ entry.formular.felder?.length }} Feld{{ entry.formular.felder?.length === 1 ? '' : 'er' }}</span>
            <!---    content >> -->
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <!--- << extra    -->
          <!---    extra >> -->
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
