import { NgModule } from '@angular/core';
import { 
  PostObjektCommandModule,
  DeleteObjektCommandModule
} from '.';

@NgModule({
  declarations: [],
  imports: [
    PostObjektCommandModule,
    DeleteObjektCommandModule
  ],
  exports: [
    PostObjektCommandModule,
    DeleteObjektCommandModule
  ]
})
export class ObjekteCommandsModule { }
