<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="urlaub">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="angenommen" />
      <input class="cdk-visually-hidden" formControlName="abgelehnt" />
      <input class="cdk-visually-hidden" formControlName="outlook" />

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">Mitarbeiter/-in</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
          <ng-template #MitarbeiterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Mitarbeiter/-in auswählen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="meta.users$ | async" placeholder="Mitarbeiter/-in" cdkFocusInitial required="true"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="art">Art des Urlaubs</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ArtErrorTip">
          <ng-template #ArtErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Art des Urlaubs auswählen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="art" id="art" [shapes]="arten" placeholder="Art des Urlaubs" cdkFocusInitial required="true"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="von">Von & Bis</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
          <ng-template #DatumErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Von & Bis pflegen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-input-group>
            <nz-date-picker formControlName="von" id="von" nzFormat="dd.MM.yyyy"></nz-date-picker>
            <span nz-typography> - </span>
            <nz-date-picker formControlName="bis" id="bis" nzFormat="dd.MM.yyyy"></nz-date-picker>
            <span style="padding-left: 6px" nz-typography nzType="secondary" nz-tooltip nzTooltipTitle="Die hier angezeigte Arbeitstagesanzahl berücksichtigt nicht das jeweilige Bundesland.">
              {{ $arbeitstage | async }} Tage
              <span nz-icon nzType="info-circle" nzTheme="outline"></span>
            </span>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">Kommentar</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <nz-textarea-count [nzMaxCharacterCount]="100">
            <textarea rows="2" formControlName="kommentar" id="kommentar" [placeholder]="'Kommentar'" nz-input></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item>
      <nz-form-control>
        <nz-space class="save-button">
          <button *nzSpaceItem nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
            Speichern
            <ng-template #SubmitTooltip> Urlaub speichern </ng-template>
          </button>

          <ng-container *ngIf="(auth.$isUrlaubsAdmin | async) && ($initial | async)?.urlaub?.id && !($initial | async)?.urlaub?.angenommen">
            <button *nzSpaceItem nz-button type="button" (click)="confirm()" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
              <span nz-icon nzType="check-circle" nzTheme="outline"></span> Annehmen
            </button>
          </ng-container>

          <ng-container *ngIf="(auth.$isUrlaubsAdmin | async) && ($initial | async)?.urlaub?.id && !($initial | async)?.urlaub?.abgelehnt && !($initial | async)?.urlaub?.angenommen">
            <button *nzSpaceItem nz-button type="button" (click)="decline()" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
              <span nz-icon nzType="close-circle" nzTheme="outline"></span> Ablehnen
            </button>
          </ng-container>
        </nz-space>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
