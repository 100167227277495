import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IVermerkSorter extends ISorter {
  id?: ISorterExpression;
  projekt?: ISorterExpression;
  gutachten?: ISorterExpression;
  kunde?: ISorterExpression;
  antwortAuf?: ISorterExpression;
  verfasser?: ISorterExpression;
  kommentar?: ISorterExpression;
  externSichtbar?: ISorterExpression;
}

export const VermerkSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('antwortAuf', 'Antwort Auf'),
  SorterConfig('verfasser', 'Verfasser'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('externSichtbar', 'Extern Sichtbar')
];
