import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { IPostTourRequest, IPostTourResponse } from 'fa-kt.types';
import { MitarbeiterService, StandortsService } from '../../../../personen';
import { PostTourCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-tour',
  templateUrl: './post-tour.component.html',
  styleUrls: ['./post-tour.component.css'],
})
export class PostTourCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostTourRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostTourRequest> = new EventEmitter<IPostTourRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostTourResponse> = new EventEmitter<IPostTourResponse>();

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  mitarbeiterAdresse = '';
  standortAdresse = '';

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postTour: PostTourCommandService,
    public mitarbeiter: MitarbeiterService,
    public standorts: StandortsService,
  ) {
    this.command = sitemap.BESICHTIGUNGEN.Commands.POST_TOUR;
    this.form = this.fb.group({
      tour: this.fb.group({
        id: [null, []],
        tourStatus: [null, [Validators.required]],
        mitarbeiter: [null, [Validators.required]],
        verantwortlich: [null, []],
        am: [null, [Validators.required]],
        startIstPrivatAdresse: [null, []],
        endeIstPrivatAdresse: [null, []],
        start: [],
        ende: [],
        strecke: [null, []],
        kosten: [null, []],
        abgerechnet: [null, []],
        kommentar: [null, []],
      }),
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postTour.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostTourRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      combineLatest([this.tourMitarbeiter?.valueChanges, this.mitarbeiter.response$, this.standorts.response$]).subscribe(([mitarbeiter, mitarbeiters, standorts]) => {
        const ma = mitarbeiters?.mitarbeiter.find((m) => m.mitarbeiter.id === mitarbeiter)?.mitarbeiter;
        this.mitarbeiterAdresse = ma?.anzeigename + ': ' + (ma?.addresse ? `"${ma?.addresse?.strasse}, ${ma?.addresse?.plz}"` : 'unbekannt');
        const so = standorts?.standorts.find((s) => s.id === ma?.standort);
        this.standortAdresse = so?.name + ': ' + (so?.addresse ? `"${so?.addresse?.strasse}, ${so?.addresse?.plz}"` : 'unbekannt');
      }) as Subscription,
    );
    this.tourMitarbeiter?.patchValue(this.tourMitarbeiter?.value);
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostTourRequest) {
    if (value) {
      this.form.reset();
      value = cleanObject(value);
      if (value.tour) {
        value.tour = cleanObject(value.tour);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.tour) {
        payload.tour = cleanObject(payload.tour);
      }
      const response: IPostTourResponse = await this.postTour.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  /* << methods    */

  /*    methods >> */

  get tourId() {
    return this.form.controls.tour.get('id');
  }
  get tourMitarbeiter() {
    return this.form.controls.tour.get('mitarbeiter');
  }
  get tourAm() {
    return this.form.controls.tour.get('am');
  }
  get tourStartIstPrivatAdresse() {
    return this.form.controls.tour.get('startIstPrivatAdresse');
  }
  get tourEndeIstPrivatAdresse() {
    return this.form.controls.tour.get('endeIstPrivatAdresse');
  }
  get tourStart() {
    return this.form.controls.tour.get('start');
  }
  get tourEnde() {
    return this.form.controls.tour.get('ende');
  }
  get tourStrecke() {
    return this.form.controls.tour.get('strecke');
  }
  get tourKosten() {
    return this.form.controls.tour.get('kosten');
  }
  get tourAbgerechnet() {
    return this.form.controls.tour.get('abgerechnet');
  }
  get tourKommentar() {
    return this.form.controls.tour.get('kommentar');
  }
}
