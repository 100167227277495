import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteBereichCommandServiceModule } from '..';
import { DeleteBereichCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteBereichCommandServiceModule
  ],
  declarations: [DeleteBereichCommandComponent],
  exports: [DeleteBereichCommandComponent],

})
export class DeleteBereichCommandComponentModule { }
