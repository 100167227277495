import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { HerausgebersServiceModule } from '..';
import { HerausgebersFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    HerausgebersServiceModule
  ],
  declarations: [HerausgebersFilterComponent],
  exports: [HerausgebersFilterComponent],

})
export class HerausgebersFilterComponentModule { }
