<!--

  <fa-kt-post-zeile [style.width]="'100%'" [value]="$postZeile | async" (finished)="finishedPostZeile($event)"></fa-kt-post-zeile>



-->
<!--- << custom    -->
<nz-table #virtualTable
          [nzBordered]="true"
          [nzVirtualItemSize]="54"
          [nzVirtualForTrackBy]="trackByIndex"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          [nzScroll]="{ x: '100%', y: '550px' }"
          nzBordered
          [nzData]="zeilen" nzTableLayout="fixed">
  <thead>
  <tr>
    <th nzLeft nzWidth="150px">Bereiche</th>
    <th nzWidth="120px">Land</th>
    <th nzWidth="120px">Bundesland</th>
    <th nzWidth="120px">Kreis / Stadt</th>
    <th nzWidth="150px">Gemeinde / Bezirk</th>
    <th nzWidth="140px">Orts-/Stadtteil</th>
    <th nzWidth="100px">Region</th>
    <th nzWidth="100px">Schlüssel</th>
    <th nzWidth="100px">PLZ</th>
    <th nzWidth="100px">Assetklasse</th>
    <th nzWidth="100px">Objektart</th>
    <th nzWidth="100px">Zustand</th>
    <th nzWidth="100px">Vertrag</th>
    <th nzWidth="100px">Teilmarkt</th>
    <th nzWidth="120px">Lagequalität</th>
    <th nzWidth="120px">Wfl/Nf von</th>
    <th nzWidth="120px">Wfl/Nf bis</th>
    <th nzWidth="120px">Wfl/Nf Mittel</th>
    <th nzWidth="100px">BJ von</th>
    <th nzWidth="100px">BJ bis</th>
    <th nzWidth="145px">Wohn./Nutzwert</th>
    <th nzWidth="120px">Ausstattung</th>
    <th nzWidth="140px">Art des Wertes</th>
    <th nzWidth="100px">Einheit</th>
    <th nzWidth="100px">Bezug</th>
    <th nzWidth="120px">Durchschnitt</th>
    <th nzWidth="100px">Minimum</th>
    <th nzWidth="100px">Maximum</th>
    <th nzWidth="125px">Spitzenwerte</th>
    <th nzWidth="100px">Anzahl</th>
<!--    <th nzWidth="150px">Steigerung 1J/Q</th>
    <th nzWidth="150px">Steigerung 2J/Q</th>
    <th nzWidth="150px">Steigerung 3J/Q</th>
    <th nzWidth="150px">Steigerung 4J/Q</th>
    <th nzWidth="150px">Steigerung 5J/Q</th>
    <th nzWidth="170px">Steigerung Q/J</th>-->
    <th nzWidth="140px" nzRight></th>
  </tr>
  </thead>
  <tbody>
    <ng-template nz-virtual-scroll let-zeile let-index="index">
    <tr (click)="startEdit(zeile.id)">
      <ng-container *ngIf="(!editCache[zeile.id] || !editCache[zeile.id].edit); else editTemplate">
        <td>{{ zeile.bereiche | type:bereiche }}</td>
        <td>{{ zeile.land }}</td>
        <td>{{ zeile.bundesland }}</td>
        <td>{{ zeile.kreisStadt }}</td>
        <td>{{ zeile.gemeindeBezirk }}</td>
        <td>{{ zeile.ortsStadtTeil }}</td>
        <td>{{ zeile.region }}</td>
        <td>{{ zeile.gemeindeschluessel }}</td>
        <td>{{ zeile.plz }}</td>
        <td>{{ zeile.assetklasse }}</td>
        <td>{{ zeile.objektart }}</td>
        <td>{{ zeile.zustand }}</td>
        <td>{{ zeile.vertrag }}</td>
        <td>{{ zeile.teilmarkt }}</td>
        <td>{{ zeile.wohnlage }}</td>
        <td>{{ zeile.wflVon }}</td>
        <td>{{ zeile.wflMittel }}</td>
        <td>{{ zeile.wflBis }}</td>
        <td>{{ zeile.bjVon }}</td>
        <td>{{ zeile.bjBis }}</td>
        <td>{{ zeile.wohnNutzWert }}</td>
        <td>{{ zeile.ausstattung }}</td>
        <td>{{ zeile.artDesWertes }}</td>
        <td>{{ zeile.einheit }}</td>
        <td>{{ zeile.bezug }}</td>
        <td>{{ zeile.durchschnitt }}</td>
        <td>{{ zeile.minimum }}</td>
        <td>{{ zeile.maximum }}</td>
        <td>{{ zeile.spitzenwerte }}</td>
        <td>{{ zeile.anzahl }}</td>
<!--        <td>Steigerung 1J/Q</td>
        <td>Steigerung 2J/Q</td>
        <td>Steigerung 3J/Q</td>
        <td>Steigerung 4J/Q</td>
        <td>Steigerung 5J/Q</td>
        <td>Steigerung Q/J</td>-->
        <td nzRight>
          <a>
            <i nz-icon nzType="edit" nzTheme="outline"></i>
          </a>
        </td>
      </ng-container>
      <ng-template #editTemplate>
        <td>
          <pbc-select-value width="110px" [(value)]="editCache[zeile.id].zeile.bereiche" [shapes]="bereiche" mode="multiple"></pbc-select-value>
        </td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.land" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.bundesland" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.kreisStadt" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.gemeindeBezirk" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.ortsStadtTeil" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.region" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.gemeindeschluessel" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.plz" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.assetklasse" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.objektart" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.zustand" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.vertrag" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.teilmarkt" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.wohnlage" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.wflVon" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.wflMittel" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.wflBis" /></td>
        <td><nz-date-picker [(ngModel)]="editCache[zeile.id].zeile.bjVon" nzFormat="dd.MM.yyyy"></nz-date-picker></td>
        <td><nz-date-picker [(ngModel)]="editCache[zeile.id].zeile.bjBis" nzFormat="dd.MM.yyyy"></nz-date-picker></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.wohnNutzWert" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.ausstattung" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.artDesWertes" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.einheit" /></td>
        <td><input type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.bezug" /></td>
        <td><input nz-input type="number" style="width: 150px" type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.durchschnitt"/></td>
        <td><input nz-input type="number" style="width: 150px" type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.minimum"/></td>
        <td><input nz-input type="number" style="width: 150px" type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.maximum"/></td>
        <td><input nz-input type="number" style="width: 150px" type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.spitzenwerte"/></td>
        <td><input nz-input type="number" style="width: 150px" type="text" nz-input [(ngModel)]="editCache[zeile.id].zeile.anzahl"/></td>
     <!--   <td>Steigerung 1J/Q</td>
        <td>Steigerung 2J/Q</td>
        <td>Steigerung 3J/Q</td>
        <td>Steigerung 4J/Q</td>
        <td>Steigerung 5J/Q</td>
        <td>Steigerung Q/J</td>-->
        <td nzRight>
          <a (click)="saveEdit(zeile.id)" class="save">
            <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          </a>
          <a style="margin-left: 6px" nz-popconfirm nzPopconfirmTitle="Sicher abbrechen?" (nzOnConfirm)="cancelEdit(zeile.id)">
            <i nz-icon nzType="close-square" nzTheme="outline"></i>
          </a>
        </td>
      </ng-template>
    </tr>
    </ng-template>
  </tbody>
</nz-table>
<!---    custom >> -->
