import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { DeleteObjektCommandModule, PostObjektCommandModule } from '../../commands';

import { ObjektComponent } from '.';

@NgModule({
  imports: [LIB_CommonModule, PostObjektCommandModule, DeleteObjektCommandModule],
  declarations: [ObjektComponent],
  exports: [ObjektComponent],
})
export class ObjektComponentModule {}
