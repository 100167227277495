import { NgModule } from '@angular/core';

import {
  DeleteGutachtenCommandServiceModule,
  DeleteGutachtenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteGutachtenCommandServiceModule,
    DeleteGutachtenCommandComponentModule
  ],
  exports: [
    DeleteGutachtenCommandServiceModule,
    DeleteGutachtenCommandComponentModule
  ]
})
export class DeleteGutachtenCommandModule { }
