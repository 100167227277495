import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjekteServiceModule } from '..';
import { ObjekteSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjekteServiceModule
  ],
  declarations: [ObjekteSorterComponent],
  exports: [ObjekteSorterComponent],

})
export class ObjekteSorterComponentModule { }
