import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { IchServiceModule } from '..';
import { IchFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    IchServiceModule
  ],
  declarations: [IchFilterComponent],
  exports: [IchFilterComponent],

})
export class IchFilterComponentModule { }
