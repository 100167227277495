import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteTourStatusCommandServiceModule } from '..';
import { DeleteTourStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteTourStatusCommandServiceModule
  ],
  declarations: [DeleteTourStatusCommandComponent],
  exports: [DeleteTourStatusCommandComponent],

})
export class DeleteTourStatusCommandComponentModule { }
