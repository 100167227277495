import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { PostRechnungenHochzaehlenCommandService } from '../../../finanzen';
import { PostProjekteHochzaehlenCommandService } from '../../../projekte';

export interface IEinstellungenPageResolverResult {}

@Injectable({
  providedIn: 'root',
})
export class EinstellungenPageResolver implements Resolve<IEinstellungenPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private postProjekteHochzaehlen: PostProjekteHochzaehlenCommandService,
    private postRechnungenHochzaehlen: PostRechnungenHochzaehlenCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IEinstellungenPageResolverResult> {
    this.loading.resolving();
    const requests = {};

    const log = (error: any) => console.error(error);
    const [] = await Promise.all([]);
    this.loading.resolved();
    return {};
  }
}
