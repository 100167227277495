import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFotoKategorienQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const FotoKategorienQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.Propertys.ID.title'),
   SorterConfig('name', 'BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.Propertys.BESCHREIBUNG.title'),
];
