import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { DeleteAbteilungCommandModule, PostAbteilungCommandModule } from '../../commands';

import { AbteilungenComponent } from '.';

@NgModule({
  imports: [LIB_CommonModule, DeleteAbteilungCommandModule, PostAbteilungCommandModule],
  declarations: [AbteilungenComponent],
  exports: [AbteilungenComponent],
})
export class AbteilungenComponentModule {}
