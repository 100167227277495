import { Component, Inject } from '@angular/core';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { HerausgebersQueryFilterConfig, IHerausgebersQueryShapes } from 'fa-kt.types';
import { HerausgebersService } from '../service';

@Component({
  selector: 'fa-kt-herausgebers-filter',
  templateUrl: './herausgebers-filter.component.html',
  styleUrls: ['./herausgebers-filter.component.css'],
})
export class HerausgebersFilterComponent {
  readonly subscriptions: Subscription[] = [];

  HerausgebersFilterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public herausgebers: HerausgebersService,
  ) {
    this.HerausgebersFilterConfig = HerausgebersQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.herausgebers.shapes$.subscribe((shapes: IHerausgebersQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.HerausgebersFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.HerausgebersFilterConfig[index].selections = shapes;
  }
}
