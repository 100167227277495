import { Component } from '@angular/core';
import { IchService } from '../service';

@Component({
  selector: 'fa-kt-saved-ich-filters',
  templateUrl: './saved-ich-filters.component.html',
  styleUrls: ['./saved-ich-filters.component.css'],
})
export class SavedIchFiltersComponent {
  constructor(public ich: IchService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
