import { NgModule } from '@angular/core';
import { 
  ArchivQueryModule,
  DashboardQueryModule,
  ProjektQueryModule,
  ProjekteQueryModule
} from '.';

@NgModule({
  declarations: [],
  imports: [
    ArchivQueryModule,
    DashboardQueryModule,
    ProjektQueryModule,
    ProjekteQueryModule
  ],
  exports: [
    ArchivQueryModule,
    DashboardQueryModule,
    ProjektQueryModule,
    ProjekteQueryModule
  ]
})
export class ProjekteQuerysModule { }
