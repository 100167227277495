import { Injectable } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';
import { ActionSelectorComponent } from '../../components';
import { IAction } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  get $actions(): BehaviorSubject<Array<IAction>> {
    return this._$actions;
  }
  private _$actions: BehaviorSubject<Array<IAction>> = new BehaviorSubject<Array<IAction>>([]);
  private selector!: NzModalRef<ActionSelectorComponent>;
  constructor(private modal: NzModalService) {}

  subscribe(action: IAction) {
    const actions: IAction[] = this._$actions.getValue().filter((a) => a.key !== action.key) || [];
    this._$actions.next([action].concat(actions));
  }

  unsubscribe(actionKey: string) {
    const actions: IAction[] = this._$actions.getValue() || [];
    this._$actions.next(actions.filter((action) => action.key !== actionKey));
  }

  call(action: IAction) {
    if (!action || (!action.action && this._$actions.getValue().filter((a) => a.key !== action.key).length === 0)) {
      return;
    }
    action.action();
    this.selector.close();
  }

  open() {
    if (this.selector && this.selector.state === 0) {
      return;
    }
    this.selector = this.modal.info({
      nzContent: ActionSelectorComponent,
      nzFooter: null,
      nzOkText: 'Schließen',
      nzCloseIcon: '',
      nzWidth: '750px',
    });
  }
}
