import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IObjektNutzungsResponse } from 'fa-kt.types';
import { PostObjektNutzungCommandService } from '../commands';
import { ObjektNutzungsService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class ObjektNutzungsPageResolver implements Resolve<IObjektNutzungsResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private objektNutzungs: ObjektNutzungsService,
    private postObjektNutzung: PostObjektNutzungCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IObjektNutzungsResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [objektNutzungs] = await Promise.all([this.objektNutzungs.request({}).catch(log), this.postObjektNutzung.prepare(true).catch(log)]);
    this.loading.resolved();
    return objektNutzungs;
  }
}
