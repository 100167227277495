import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';


import { KommunikationBadgeComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  declarations: [KommunikationBadgeComponent],
  exports: [KommunikationBadgeComponent],

})
export class KommunikationBadgeComponentModule { }
