import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBerichtCommandServiceModule } from '..';
import { PostBerichtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBerichtCommandServiceModule
  ],
  declarations: [PostBerichtCommandComponent],
  exports: [PostBerichtCommandComponent],

})
export class PostBerichtCommandComponentModule { }
