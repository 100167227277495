import { NgModule } from '@angular/core';
import {
  FotoKategorienFilterComponentModule, FotoKategorienListComponentModule, FotoKategorienSorterComponentModule
} from '.';

@NgModule({
  imports: [
    FotoKategorienFilterComponentModule,
    FotoKategorienSorterComponentModule,
    FotoKategorienListComponentModule
  ],
  exports: [
    FotoKategorienFilterComponentModule,
    FotoKategorienSorterComponentModule,
    FotoKategorienListComponentModule
  ],
})
export class FotoKategorienQueryModule { }