import { NgModule } from '@angular/core';
import {
  KundenArtsFilterComponentModule,
  KundenArtsSorterComponentModule,
  KundenArtsListComponentModule
} from '.';

@NgModule({
  imports: [
    KundenArtsFilterComponentModule,
    KundenArtsSorterComponentModule,
    KundenArtsListComponentModule
  ],
  exports: [
    KundenArtsFilterComponentModule,
    KundenArtsSorterComponentModule,
    KundenArtsListComponentModule
  ],
})
export class KundenArtsQueryModule { }