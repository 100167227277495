import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FormulareServiceModule } from '..';
import { FormulareListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormulareServiceModule
  ],
  declarations: [FormulareListComponent],
  exports: [FormulareListComponent],

})
export class FormulareListComponentModule { }
