import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { TourenQuerySorterConfig } from 'fa-kt.types';
import { TourenService } from '../service';

@Component({
  selector: 'fa-kt-touren-sorter',
  templateUrl: './touren-sorter.component.html',
  styleUrls: ['./touren-sorter.component.css'],
})
export class TourenSorterComponent {
  public TourenSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public touren: TourenService,
  ) {
    this.TourenSorterConfig = TourenQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }
}
