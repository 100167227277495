<!---
  <div formGroupName="objekt">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <pbc-address-input formControlName="addresse" id="addresse" required="true" ></pbc-address-input>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bereiche">{{ command.Inputs.BEREICHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BereicheErrorTip">
        <ng-template #BereicheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEREICHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="bereiche" id="bereiche" [shapes]="postObjekt.objektBereiche | async" [placeholder]="command.Inputs.BEREICHE.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anspracheOutlookKontakts">{{ command.Inputs.ANSPRACHE_OUTLOOK_KONTAKTS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnspracheOutlookKontaktsErrorTip">
        <ng-template #AnspracheOutlookKontaktsErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANSPRACHE_OUTLOOK_KONTAKTS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="anspracheOutlookKontakts" id="anspracheOutlookKontakts" [placeholder]="command.Inputs.ANSPRACHE_OUTLOOK_KONTAKTS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="klingelschild">{{ command.Inputs.KLINGELSCHILD.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KlingelschildErrorTip">
        <ng-template #KlingelschildErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KLINGELSCHILD.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="klingelschild" id="klingelschild" [placeholder]="command.Inputs.KLINGELSCHILD.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="objekt">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="anspracheOutlookKontakts" id="anspracheOutlookKontakts"/>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
        </nz-form-control>
      </nz-form-item>
      <pbc-address-input formControlName="addresse" id="addresse" required="true" [placeholder]="command.Inputs.ADDRESSE.placeholder" [value]="$addresse | async"></pbc-address-input>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="klingelschild">{{ command.Inputs.KLINGELSCHILD.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KlingelschildErrorTip">
          <ng-template #KlingelschildErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KLINGELSCHILD.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="klingelschild" id="klingelschild" [placeholder]="command.Inputs.KLINGELSCHILD.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Objekt Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Objekt hinzufügen
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
