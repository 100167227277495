import { NgModule } from '@angular/core';

import { ZeitArtsSettingsModule, ZeitUnterartsSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [ZeitArtsSettingsModule, ZeitUnterartsSettingsModule],
  exports: [ZeitArtsSettingsModule, ZeitUnterartsSettingsModule],
})
export class ZeitenSettingsModule {}
