import { NgModule } from '@angular/core';

import {
  DeleteFotoKategorieCommandComponentModule, DeleteFotoKategorieCommandServiceModule
} from '.';

@NgModule({
  imports: [
    DeleteFotoKategorieCommandServiceModule,
    DeleteFotoKategorieCommandComponentModule
  ],
  exports: [
    DeleteFotoKategorieCommandServiceModule,
    DeleteFotoKategorieCommandComponentModule
  ]
})
export class DeleteFotoKategorieCommandModule { }
