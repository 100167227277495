import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FeldServiceModule } from '..';
import { FeldFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldServiceModule
  ],
  declarations: [FeldFilterComponent],
  exports: [FeldFilterComponent],

})
export class FeldFilterComponentModule { }
