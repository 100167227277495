import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { IReisekostenabrechnung, IReisekostenabrechnungenResponseRow } from 'fa-kt.types';
import { ISelection } from 'pbc.types';

import { MetaService } from 'pbc.angular';
import { toInitials } from 'pbc.functions';
import { TourenSelectService } from '../../../../besichtigungen';
import { ReisekostenabrechnungenService } from '../service';

@Component({
  selector: 'fa-kt-reisekostenabrechnungen-list',
  templateUrl: './reisekostenabrechnungen-list.component.html',
  styleUrls: ['./reisekostenabrechnungen-list.component.css'],
})
export class ReisekostenabrechnungenListComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  /* << props    */
  $touren = new BehaviorSubject<ISelection[]>([]);

  /*    props >> */

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public reisekostenabrechnungen: ReisekostenabrechnungenService,
    public touren: TourenSelectService,
    public meta: MetaService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public set(reisekostenabrechnungen: IReisekostenabrechnungenResponseRow) {
    /* << custom    */
    this.select.emit(reisekostenabrechnungen.reisekostenabrechnung.id);
    /*    custom >> */
  }

  /* << functions    */
  isVeranstaltung(rka: IReisekostenabrechnung) {
    return rka.isVeranstaltung;
  }

  initialen(str: string): string {
    return toInitials(str);
  }
  /*    functions >> */
}
