<!--



  <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="marktBericht" type="dashed"></fa-kt-navigation>

-->
<!--- << custom    -->
<p> berichte works!</p> 
<!---    custom >> -->
