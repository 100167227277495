import { NgModule } from '@angular/core';

import { RollesSettingsModule, StandortsSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [RollesSettingsModule, StandortsSettingsModule],
  exports: [RollesSettingsModule, StandortsSettingsModule],
})
export class PersonenSettingsModule {}
