<!---
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ model.Propertys.NAME.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
      <ng-template #NameErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.NAME.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="model.Propertys.NAME.placeholder" cdkFocusInitial/>
    </nz-form-control>
  </nz-form-item>
  <pbc-address-input formControlName="addresse" id="addresse" [placeholder]="model.Propertys.ADDRESSE.placeholder"></pbc-address-input>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dateipfad">{{ model.Propertys.DATEIPFAD.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateipfadErrorTip">
      <ng-template #DateipfadErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.DATEIPFAD.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <input nz-input formControlName="dateipfad" id="dateipfad" [placeholder]="model.Propertys.DATEIPFAD.placeholder"/>
    </nz-form-control>
  </nz-form-item>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="standort">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
<!--- << content    -->
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ model.Propertys.NAME.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="model.Propertys.NAME.hint">
          <input nz-input formControlName="name" id="name" [placeholder]="model.Propertys.NAME.placeholder" cdkFocusInitial/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dateipfad">{{ model.Propertys.DATEIPFAD?.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateipfadErrorTip">
          <ng-template #DateipfadErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.DATEIPFAD?.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="dateipfad" id="dateipfad" [placeholder]="model.Propertys.DATEIPFAD?.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <pbc-address-input formControlName="addresse" id="addresse" [placeholder]="model.Propertys.ADDRESSE.placeholder" required="true"></pbc-address-input>
<!---    content >> -->
      <nz-form-item>
        <nz-form-control [nzOffset]="16">
          <button nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
            <i nz-icon nzType="check-circle" nzTheme="outline"></i>
            Speichern
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
<!--- << extra    -->
<!---    extra >> -->
</nz-spin>
