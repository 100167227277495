import { NgModule } from '@angular/core';

import {
  DeleteProjektCommandServiceModule,
  DeleteProjektCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteProjektCommandServiceModule,
    DeleteProjektCommandComponentModule
  ],
  exports: [
    DeleteProjektCommandServiceModule,
    DeleteProjektCommandComponentModule
  ]
})
export class DeleteProjektCommandModule { }
