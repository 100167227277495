import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  DateFilterConfig,
  GeoRangeFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IDateFilter,
  IFilter,
  IFilterConfig,
  IGeoRangeFilter,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IAbteilung, IBereich, IBewertungsAnlass, IFond, IGutachtenStatus, IKunde, IMitarbeiter, IObjekt, IObjektArt, IObjektNutzung, IProjektArt, IProjektStatus, IStandort } from 'fa-kt.types';
import { IUmkreissucheResponse } from '.';

export interface IUmkreissucheQueryFilter extends IFilter<IUmkreissucheResponse> {
  'objekt.addresse'?: IGeoRangeFilter;
  'objekt.bereiche'?: IArrayReferenceFilter<IBereich>;
  'gutachten.objekt'?: ISingleReferenceFilter<IObjekt>;
  'projekt.bewertungsAnlass'?: ISingleReferenceFilter<IBewertungsAnlass>;
  'gutachten.bewertungsStatus'?: ISingleReferenceFilter<IGutachtenStatus>;
  'gutachten.objektNutzung'?: ISingleReferenceFilter<IObjektNutzung>;
  'gutachten.objektArt'?: ISingleReferenceFilter<IObjektArt>;
  'gutachten.abgabeDraft'?: IDateFilter;
  'gutachten.abgabeFinal'?: IDateFilter;
  'gutachten.erbbaurecht'?: IBooleanFilter;
  'gutachten.wohnenJRoE'?: INumberFilter;
  'gutachten.wohnenJRoEFlaeche'?: INumberFilter;
  'gutachten.gewerbeJRoE'?: INumberFilter;
  'gutachten.gewerbeJRoEFlaeche'?: INumberFilter;
  'gutachten.bueroJRoE'?: INumberFilter;
  'gutachten.bueroJRoEFlaeche'?: INumberFilter;
  'gutachten.handelJRoE'?: INumberFilter;
  'gutachten.handelJRoEFlaeche'?: INumberFilter;
  'gutachten.lagerJRoE'?: INumberFilter;
  'gutachten.lagerJRoEFlaeche'?: INumberFilter;
  'gutachten.sonstigesJRoE'?: INumberFilter;
  'gutachten.sonstigesJRoEFlaeche'?: INumberFilter;
  'gutachten.baujahr'?: INumberFilter;
  'gutachten.modernisierung'?: INumberFilter;
  'gutachten.marktwert'?: INumberFilter;
  'gutachten.stichtagMarktwert'?: IDateFilter;
  'gutachten.kaufpreis'?: INumberFilter;
  'gutachten.stichtagKaufpreis'?: IDateFilter;
  'gutachten.fortschritt'?: INumberFilter;
  'gutachten.aktiv'?: IBooleanFilter;
  'gutachten.tourenplanung'?: IBooleanFilter;
  'projekt.projektArt'?: ISingleReferenceFilter<IProjektArt>;
  'projekt.projektStatus'?: ISingleReferenceFilter<IProjektStatus>;
  'projekt.standort'?: ISingleReferenceFilter<IStandort>;
  'projekt.beauftragung'?: IDateFilter;
  'projekt.deadline'?: IDateFilter;
  'projekt.keineBesichtigungErforderlich'?: IBooleanFilter;
  'projekt.besichtigungen'?: IArrayReferenceFilter<IMitarbeiter>;
  'projekt.gutachten'?: IArrayReferenceFilter<IMitarbeiter>;
  'projekt.pruefung'?: ISingleReferenceFilter<IMitarbeiter>;
  'projekt.kunde'?: ISingleReferenceFilter<IKunde>;
  'projekt.abteilung'?: ISingleReferenceFilter<IAbteilung>;
  'projekt.fond'?: ISingleReferenceFilter<IFond>;
  'projekt.bank'?: ISingleReferenceFilter<IKunde>;
  'projekt.fortschritt'?: INumberFilter;
  // 'projekt.strecke'?: INumberFilter;
  // 'projekt.kalkulierteStrecke'?: INumberFilter;
  // 'projekt.fahrtzeit'?: INumberFilter;
  // 'projekt.kalkulierteFahrtzeit'?: INumberFilter;
  'projekt.stunden'?: INumberFilter;
  'projekt.kostenNachStunden'?: INumberFilter;
  'projekt.verhandeltesHonorar'?: INumberFilter;
  'projekt.kosten'?: INumberFilter;
  'projekt.kostenBeglichen'?: INumberFilter;
  'projekt.aktiv'?: IBooleanFilter;
}

export const UmkreissucheQueryFilterConfig: IFilterConfig<IUmkreissucheResponse> = [
  GeoRangeFilterConfig('objekt.addresse', 'OBJEKTE.Models.OBJEKT.Propertys.ADDRESSE.title'),
  ArrayReferenceFilterConfig<IBereich>('objekt.bereiche', 'OBJEKTE.Models.OBJEKT.Propertys.BEREICHE.title'),
  SingleReferenceFilterConfig<IObjekt>('gutachten.objekt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT.title'),
  SingleReferenceFilterConfig<IBewertungsAnlass>('projekt.bewertungsAnlass', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_ANLASS.title'),
  SingleReferenceFilterConfig<IGutachtenStatus>('gutachten.bewertungsStatus', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_STATUS.title'),
  SingleReferenceFilterConfig<IObjektNutzung>('gutachten.objektNutzung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_NUTZUNG.title'),
  SingleReferenceFilterConfig<IObjektArt>('gutachten.objektArt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_ART.title'),
  DateFilterConfig('gutachten.abgabeDraft', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_DRAFT.title'),
  DateFilterConfig('gutachten.abgabeFinal', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_FINAL.title'),
  BooleanFilterConfig('gutachten.erbbaurecht', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ERBBAURECHT.title'),
  NumberFilterConfig('gutachten.wohnenJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E.title'),
  NumberFilterConfig('gutachten.wohnenJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.gewerbeJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E.title'),
  NumberFilterConfig('gutachten.gewerbeJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.bueroJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E.title'),
  NumberFilterConfig('gutachten.bueroJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.handelJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E.title'),
  NumberFilterConfig('gutachten.handelJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.lagerJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E.title'),
  NumberFilterConfig('gutachten.lagerJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.sonstigesJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E.title'),
  NumberFilterConfig('gutachten.sonstigesJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.baujahr', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BAUJAHR.title'),
  NumberFilterConfig('gutachten.modernisierung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MODERNISIERUNG.title'),
  NumberFilterConfig('gutachten.marktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MARKTWERT.title'),
  DateFilterConfig('gutachten.stichtagMarktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_MARKTWERT.title'),
  NumberFilterConfig('gutachten.kaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.KAUFPREIS.title'),
  DateFilterConfig('gutachten.stichtagKaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_KAUFPREIS.title'),
  NumberFilterConfig('gutachten.fortschritt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.FORTSCHRITT.title'),
  BooleanFilterConfig('gutachten.aktiv', 'GUTACHTEN.Models.GUTACHTEN.Propertys.AKTIV.title'),
  BooleanFilterConfig('gutachten.tourenplanung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.TOURENPLANUNG.title'),
  SingleReferenceFilterConfig<IProjektArt>('projekt.projektArt', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ART.title'),
  SingleReferenceFilterConfig<IProjektStatus>('projekt.projektStatus', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_STATUS.title'),
  SingleReferenceFilterConfig<IStandort>('projekt.standort', 'PROJEKTE.Models.PROJEKT.Propertys.STANDORT.title'),
  DateFilterConfig('projekt.beauftragung', 'PROJEKTE.Models.PROJEKT.Propertys.BEAUFTRAGUNG.title'),
  DateFilterConfig('projekt.deadline', 'PROJEKTE.Models.PROJEKT.Propertys.DEADLINE.title'),
  BooleanFilterConfig('projekt.keineBesichtigungErforderlich', 'PROJEKTE.Models.PROJEKT.Propertys.KEINE_BESICHTIGUNG_ERFORDERLICH.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('projekt.besichtigungen', 'PROJEKTE.Models.PROJEKT.Propertys.BESICHTIGUNGEN.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('projekt.gutachten', 'PROJEKTE.Models.PROJEKT.Propertys.GUTACHTEN.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('projekt.pruefung', 'PROJEKTE.Models.PROJEKT.Propertys.PRUEFUNG.title'),
  SingleReferenceFilterConfig<IKunde>('projekt.kunde', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDE.title'),
  SingleReferenceFilterConfig<IAbteilung>('projekt.abteilung', 'PROJEKTE.Models.PROJEKT.Propertys.ABTEILUNG.title'),
  SingleReferenceFilterConfig<IFond>('projekt.fond', 'PROJEKTE.Models.PROJEKT.Propertys.FOND.title'),
  SingleReferenceFilterConfig<IKunde>('projekt.bank', 'PROJEKTE.Models.PROJEKT.Propertys.BANK.title'),
  NumberFilterConfig('projekt.fortschritt', 'PROJEKTE.Models.PROJEKT.Propertys.FORTSCHRITT.title'),
  // NumberFilterConfig('projekt.strecke', 'PROJEKTE.Models.PROJEKT.Propertys.STRECKE.title'),
  // NumberFilterConfig('projekt.kalkulierteStrecke', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_STRECKE.title'),
  // NumberFilterConfig('projekt.fahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.FAHRTZEIT.title'),
  // NumberFilterConfig('projekt.kalkulierteFahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_FAHRTZEIT.title'),
  NumberFilterConfig('projekt.stunden', 'PROJEKTE.Models.PROJEKT.Propertys.STUNDEN.title'),
  NumberFilterConfig('projekt.kostenNachStunden', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_NACH_STUNDEN.title'),
  NumberFilterConfig('projekt.verhandeltesHonorar', 'PROJEKTE.Models.PROJEKT.Propertys.VERHANDELTES_HONORAR.title'),
  NumberFilterConfig('projekt.kosten', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN.title'),
  NumberFilterConfig('projekt.kostenBeglichen', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_BEGLICHEN.title'),
  BooleanFilterConfig('projekt.aktiv', 'PROJEKTE.Models.PROJEKT.Propertys.AKTIV.title'),
];

export interface IUmkreissucheQueryShapes extends IShape {
  'objekt.bereiche': ISelection[];
  'gutachten.objekt': ISelection[];
  'projekt.bewertungsAnlass': ISelection[];
  'gutachten.bewertungsStatus': ISelection[];
  'gutachten.objektNutzung': ISelection[];
  'gutachten.objektArt': ISelection[];
  'projekt.projektArt': ISelection[];
  'projekt.projektStatus': ISelection[];
  'projekt.standort': ISelection[];
  'projekt.besichtigungen': ISelection[];
  'projekt.gutachten': ISelection[];
  'projekt.pruefung': ISelection[];
  'projekt.kunde': ISelection[];
  'projekt.abteilung': ISelection[];
  'projekt.fond': ISelection[];
  'projekt.bank': ISelection[];
  'projekt.objektArt': ISelection[];
}

export type IUmkreissucheQueryField =
  | 'objekt.id'
  | 'objekt.name'
  | 'objekt.addresse'
  | 'objekt.bereiche'
  | 'objekt.anspracheOutlookKontakts'
  | 'objekt.klingelschild'
  | 'objekt.kommentar'
  | 'gutachten.id'
  | 'gutachten.projekt'
  | 'gutachten.objekt'
  | 'projekt.bewertungsAnlass'
  | 'gutachten.bewertungsStatus'
  | 'gutachten.objektNutzung'
  | 'gutachten.objektArt'
  | 'gutachten.sicherheitscheck'
  | 'gutachten.abgabeDraft'
  | 'gutachten.abgabeFinal'
  | 'gutachten.erbbaurecht'
  | 'gutachten.wohnenJRoE'
  | 'gutachten.wohnenJRoEFlaeche'
  | 'gutachten.gewerbeJRoE'
  | 'gutachten.gewerbeJRoEFlaeche'
  | 'gutachten.bueroJRoE'
  | 'gutachten.bueroJRoEFlaeche'
  | 'gutachten.handelJRoE'
  | 'gutachten.handelJRoEFlaeche'
  | 'gutachten.lagerJRoE'
  | 'gutachten.lagerJRoEFlaeche'
  | 'gutachten.sonstigesJRoE'
  | 'gutachten.sonstigesJRoEFlaeche'
  | 'gutachten.baujahr'
  | 'gutachten.modernisierung'
  | 'gutachten.marktwert'
  | 'gutachten.stichtagMarktwert'
  | 'gutachten.kaufpreis'
  | 'gutachten.stichtagKaufpreis'
  | 'gutachten.fortschritt'
  | 'gutachten.aktiv'
  | 'gutachten.kommentar'
  | 'gutachten.tourenplanung'
  | 'projekt.id'
  | 'projekt.bezeichnung'
  | 'projekt.nummer'
  | 'projekt.projektZaehler'
  | 'projekt.projektArt'
  | 'projekt.projektStatus'
  | 'projekt.standort'
  | 'projekt.beauftragung'
  | 'projekt.deadline'
  | 'projekt.kundenAnfertigung'
  | 'projekt.keineBesichtigungErforderlich'
  | 'projekt.besichtigungen'
  | 'projekt.gutachten'
  | 'projekt.pruefung'
  | 'projekt.kunde'
  | 'projekt.abteilung'
  | 'projekt.fond'
  | 'projekt.bank'
  | 'projekt.objektArt'
  | 'projekt.nachlass'
  | 'projekt.kundenZeichen'
  | 'projekt.kontakteInOutlook'
  | 'projekt.dateiVerzeichnis'
  | 'projekt.kommentar'
  | 'projekt.notizenFuerRechnung'
  | 'projekt.fortschritt'
  // | 'projekt.strecke'
  // | 'projekt.kalkulierteStrecke'
  // | 'projekt.fahrtzeit'
  // | 'projekt.kalkulierteFahrtzeit'
  | 'projekt.stunden'
  | 'projekt.kostenNachStunden'
  | 'projekt.verhandeltesHonorar'
  | 'projekt.kosten'
  | 'projekt.kostenBeglichen'
  | 'projekt.aktiv'
  | 'projekt.darlehensnehmer'
  | 'projekt.notizenBesichtigung'
  | 'projekt.kreditsachbearbeiterVorname'
  | 'projekt.kreditsachbearbeiterName'
  | 'projekt.kreditsachbearbeiterTelefon'
  | 'projekt.kreditsachbearbeiterEMail'
  | '';
