import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { OffeneGutachtenServiceModule } from '..';
import { OffeneGutachtenSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    OffeneGutachtenServiceModule
  ],
  declarations: [OffeneGutachtenSorterComponent],
  exports: [OffeneGutachtenSorterComponent],

})
export class OffeneGutachtenSorterComponentModule { }
