import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IBerichtSorter extends ISorter {
  id?: ISorterExpression;
  excel?: ISorterExpression;
  art?: ISorterExpression;
  herausgeber?: ISorterExpression;
  quelle?: ISorterExpression;
  jahr?: ISorterExpression;
  quartal?: ISorterExpression;
  monat?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const BerichtSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('excel', 'Excel'),
  SorterConfig('art', 'Art'),
  SorterConfig('herausgeber', 'Herausgeber'),
  SorterConfig('quelle', 'Quelle'),
  SorterConfig('jahr', 'Jahr'),
  SorterConfig('quartal', 'Quartal'),
  SorterConfig('monat', 'Monat'),
  SorterConfig('kommentar', 'Kommentar')
];
