import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteTourRequest, IDeleteTourResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteTourCommandService extends DeleteCommandService<IDeleteTourRequest, IDeleteTourResponse> {
  readonly route = 'besichtigungen/tour';
}
