import { IFilter, IFilterConfig } from "pbc.types";

import { IFeld } from '.';

export interface IFeldFilter extends IFilter<IFeld> {}

export const FeldFilterConfig: IFilterConfig<IFeld> = [];

export type IFeldField =
  'feld.id'|
  'feld.schluessel'|
  'feld.name'|
  'feld.kommentar'|
  'feld.feldKategorie'|
  'feld.feldUnterkategorie'|
  'feld.art'|
  'feld.format'|
  'feld.voreinstellung'
;
