import { IFilter, IFilterConfig } from "pbc.types";

import { IOrtKategorie } from '.';

export interface IOrtKategorieFilter extends IFilter<IOrtKategorie> {}

export const OrtKategorieFilterConfig: IFilterConfig<IOrtKategorie> = [];

export type IOrtKategorieField =
  'bereich.id'|
  'bereich.name'|
  'bereich.kommentar'
;
