import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundeServiceModule } from '..';
import { KundeDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundeServiceModule
  ],
  declarations: [KundeDetailComponent],
  exports: [KundeDetailComponent],

})
export class KundeDetailComponentModule { }
