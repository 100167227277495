import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IKundeQuerySorter extends ISorter {
  'kunde.id'?: ISorterExpression;
  'kunde.name'?: ISorterExpression;
  'kunde.initialen'?: ISorterExpression;
  'kunde.kundenGruppe'?: ISorterExpression;
  'kunde.kundenArt'?: ISorterExpression;
  'kunde.addresse'?: ISorterExpression;
  'kunde.website'?: ISorterExpression;
  'kunde.outlookKontakte'?: ISorterExpression;
  'kunde.kontaktNotizen'?: ISorterExpression;
  'kunde.anforderungenNotizen'?: ISorterExpression;
  'kunde.rechnungNotizen'?: ISorterExpression;
  'kunde.fahrtkostenAbrechnung'?: ISorterExpression;
  'kunde.steuerId'?: ISorterExpression;
  'kunde.fahrtkostenAbRadius'?: ISorterExpression;
  'kunde.fahrtkostenProKm'?: ISorterExpression;
  'kunde.fahrtkostenProStunde'?: ISorterExpression;
  'kunde.skonto'?: ISorterExpression;
  'kunde.zahlungsziel'?: ISorterExpression;
  'kunde.tageOhneSkonto'?: ISorterExpression;
  'kunde.aktiv'?: ISorterExpression;
  'kunde.dateipfad'?: ISorterExpression;
  'kunde.loraName'?: ISorterExpression;
  'kunde.unterlagenEmail'?: ISorterExpression;
  'abteilungen.id'?: ISorterExpression;
  'abteilungen.kunde'?: ISorterExpression;
  'abteilungen.name'?: ISorterExpression;
  'abteilungen.ansprache'?: ISorterExpression;
  'abteilungen.outlookKontakte'?: ISorterExpression;
  'abteilungen.website'?: ISorterExpression;
  'abteilungen.adresse'?: ISorterExpression;
  'abteilungen.aktiv'?: ISorterExpression;
  'fonds.id'?: ISorterExpression;
  'fonds.kunde'?: ISorterExpression;
  'fonds.name'?: ISorterExpression;
  'fonds.aktiv'?: ISorterExpression;
  'honorarVereinbarungen.id'?: ISorterExpression;
  'honorarVereinbarungen.kunde'?: ISorterExpression;
  'honorarVereinbarungen.bisMarktwert'?: ISorterExpression;
  'honorarVereinbarungen.lohnWohnen'?: ISorterExpression;
  'honorarVereinbarungen.lohnGewerbe'?: ISorterExpression;
  'honorarVereinbarungen.lohnSpezial'?: ISorterExpression;
  'extraVereinbarungen.id'?: ISorterExpression;
  'extraVereinbarungen.kunde'?: ISorterExpression;
  'extraVereinbarungen.name'?: ISorterExpression;
  'extraVereinbarungen.beschreibung'?: ISorterExpression;
  'extraVereinbarungen.pro'?: ISorterExpression;
  'extraVereinbarungen.kosten'?: ISorterExpression;
  'vermerke.id'?: ISorterExpression;
  'vermerke.projekt'?: ISorterExpression;
  'vermerke.gutachten'?: ISorterExpression;
  'vermerke.kunde'?: ISorterExpression;
  'vermerke.antwortAuf'?: ISorterExpression;
  'vermerke.verfasser'?: ISorterExpression;
  'vermerke.kommentar'?: ISorterExpression;
  'vermerke.externSichtbar'?: ISorterExpression;
  'erinnerungen.id'?: ISorterExpression;
  'erinnerungen.projekt'?: ISorterExpression;
  'erinnerungen.gutachten'?: ISorterExpression;
  'erinnerungen.kunde'?: ISorterExpression;
  'erinnerungen.kommentar'?: ISorterExpression;
  'erinnerungen.am'?: ISorterExpression;
  'erinnerungen.mitarbeiter'?: ISorterExpression;
  'erinnerungen.verfasser'?: ISorterExpression;
}

export const KundeQuerySorterConfig: ISorterConfig = [
  SorterConfig('kunde.id', 'KUNDEN.Models.KUNDE.Propertys.ID.title'), 
  SorterConfig('kunde.name', 'KUNDEN.Models.KUNDE.Propertys.NAME.title'), 
  SorterConfig('kunde.initialen', 'KUNDEN.Models.KUNDE.Propertys.INITIALEN.title'), 
  SorterConfig('kunde.kundenGruppe', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_GRUPPE.title'), 
  SorterConfig('kunde.kundenArt', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.title'), 
  SorterConfig('kunde.addresse', 'KUNDEN.Models.KUNDE.Propertys.ADDRESSE.title'), 
  SorterConfig('kunde.website', 'KUNDEN.Models.KUNDE.Propertys.WEBSITE.title'), 
  SorterConfig('kunde.outlookKontakte', 'KUNDEN.Models.KUNDE.Propertys.OUTLOOK_KONTAKTE.title'), 
  SorterConfig('kunde.kontaktNotizen', 'KUNDEN.Models.KUNDE.Propertys.KONTAKT_NOTIZEN.title'), 
  SorterConfig('kunde.anforderungenNotizen', 'KUNDEN.Models.KUNDE.Propertys.ANFORDERUNGEN_NOTIZEN.title'), 
  SorterConfig('kunde.rechnungNotizen', 'KUNDEN.Models.KUNDE.Propertys.RECHNUNG_NOTIZEN.title'), 
  SorterConfig('kunde.fahrtkostenAbrechnung', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_ABRECHNUNG.title'), 
  SorterConfig('kunde.steuerId', 'KUNDEN.Models.KUNDE.Propertys.STEUER_ID.title'), 
  SorterConfig('kunde.fahrtkostenAbRadius', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.title'), 
  SorterConfig('kunde.fahrtkostenProKm', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_KM.title'), 
  SorterConfig('kunde.fahrtkostenProStunde', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.title'), 
  SorterConfig('kunde.skonto', 'KUNDEN.Models.KUNDE.Propertys.SKONTO.title'), 
  SorterConfig('kunde.zahlungsziel', 'KUNDEN.Models.KUNDE.Propertys.ZAHLUNGSZIEL.title'), 
  SorterConfig('kunde.tageOhneSkonto', 'KUNDEN.Models.KUNDE.Propertys.TAGE_OHNE_SKONTO.title'), 
  SorterConfig('kunde.aktiv', 'KUNDEN.Models.KUNDE.Propertys.AKTIV.title'), 
  SorterConfig('kunde.dateipfad', 'KUNDEN.Models.KUNDE.Propertys.DATEIPFAD.title'), 
  SorterConfig('kunde.loraName', 'KUNDEN.Models.KUNDE.Propertys.LORA_NAME.title'), 
  SorterConfig('kunde.unterlagenEmail', 'KUNDEN.Models.KUNDE.Propertys.UNTERLAGEN_EMAIL.title'), 
  SorterConfig('abteilungen.id', 'KUNDEN.Models.ABTEILUNG.Propertys.ID.title'), 
  SorterConfig('abteilungen.kunde', 'KUNDEN.Models.ABTEILUNG.Propertys.KUNDE.title'), 
  SorterConfig('abteilungen.name', 'KUNDEN.Models.ABTEILUNG.Propertys.NAME.title'), 
  SorterConfig('abteilungen.ansprache', 'KUNDEN.Models.ABTEILUNG.Propertys.ANSPRACHE.title'), 
  SorterConfig('abteilungen.outlookKontakte', 'KUNDEN.Models.ABTEILUNG.Propertys.OUTLOOK_KONTAKTE.title'), 
  SorterConfig('abteilungen.website', 'KUNDEN.Models.ABTEILUNG.Propertys.WEBSITE.title'), 
  SorterConfig('abteilungen.adresse', 'KUNDEN.Models.ABTEILUNG.Propertys.ADRESSE.title'), 
  SorterConfig('abteilungen.aktiv', 'KUNDEN.Models.ABTEILUNG.Propertys.AKTIV.title'), 
  SorterConfig('fonds.id', 'KUNDEN.Models.FOND.Propertys.ID.title'), 
  SorterConfig('fonds.kunde', 'KUNDEN.Models.FOND.Propertys.KUNDE.title'), 
  SorterConfig('fonds.name', 'KUNDEN.Models.FOND.Propertys.NAME.title'), 
  SorterConfig('fonds.aktiv', 'KUNDEN.Models.FOND.Propertys.AKTIV.title'), 
  SorterConfig('honorarVereinbarungen.id', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.ID.title'), 
  SorterConfig('honorarVereinbarungen.kunde', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.KUNDE.title'), 
  SorterConfig('honorarVereinbarungen.bisMarktwert', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.BIS_MARKTWERT.title'), 
  SorterConfig('honorarVereinbarungen.lohnWohnen', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.LOHN_WOHNEN.title'), 
  SorterConfig('honorarVereinbarungen.lohnGewerbe', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.LOHN_GEWERBE.title'), 
  SorterConfig('honorarVereinbarungen.lohnSpezial', 'FINANZEN.Models.HONORAR_VEREINBARUNG.Propertys.LOHN_SPEZIAL.title'), 
  SorterConfig('extraVereinbarungen.id', 'FINANZEN.Models.EXTRA_VEREINBARUNG.Propertys.ID.title'), 
  SorterConfig('extraVereinbarungen.kunde', 'FINANZEN.Models.EXTRA_VEREINBARUNG.Propertys.KUNDE.title'), 
  SorterConfig('extraVereinbarungen.name', 'FINANZEN.Models.EXTRA_VEREINBARUNG.Propertys.NAME.title'), 
  SorterConfig('extraVereinbarungen.beschreibung', 'FINANZEN.Models.EXTRA_VEREINBARUNG.Propertys.BESCHREIBUNG.title'), 
  SorterConfig('extraVereinbarungen.pro', 'FINANZEN.Models.EXTRA_VEREINBARUNG.Propertys.PRO.title'), 
  SorterConfig('extraVereinbarungen.kosten', 'FINANZEN.Models.EXTRA_VEREINBARUNG.Propertys.KOSTEN.title'), 
  SorterConfig('vermerke.id', 'KOMMUNIKATION.Models.VERMERK.Propertys.ID.title'), 
  SorterConfig('vermerke.projekt', 'KOMMUNIKATION.Models.VERMERK.Propertys.PROJEKT.title'), 
  SorterConfig('vermerke.gutachten', 'KOMMUNIKATION.Models.VERMERK.Propertys.GUTACHTEN.title'), 
  SorterConfig('vermerke.kunde', 'KOMMUNIKATION.Models.VERMERK.Propertys.KUNDE.title'), 
  SorterConfig('vermerke.antwortAuf', 'KOMMUNIKATION.Models.VERMERK.Propertys.ANTWORT_AUF.title'), 
  SorterConfig('vermerke.verfasser', 'KOMMUNIKATION.Models.VERMERK.Propertys.VERFASSER.title'), 
  SorterConfig('vermerke.kommentar', 'KOMMUNIKATION.Models.VERMERK.Propertys.KOMMENTAR.title'), 
  SorterConfig('vermerke.externSichtbar', 'KOMMUNIKATION.Models.VERMERK.Propertys.EXTERN_SICHTBAR.title'), 
  SorterConfig('erinnerungen.id', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.ID.title'), 
  SorterConfig('erinnerungen.projekt', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.PROJEKT.title'), 
  SorterConfig('erinnerungen.gutachten', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.GUTACHTEN.title'), 
  SorterConfig('erinnerungen.kunde', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.KUNDE.title'), 
  SorterConfig('erinnerungen.kommentar', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.KOMMENTAR.title'), 
  SorterConfig('erinnerungen.am', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.AM.title'), 
  SorterConfig('erinnerungen.mitarbeiter', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.MITARBEITER.title'), 
  SorterConfig('erinnerungen.verfasser', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.VERFASSER.title'), 
];
