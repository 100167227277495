import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitArtsListComponent } from '.';
import { ZeitArtsServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitArtsServiceModule
  ],
  declarations: [ZeitArtsListComponent],
  exports: [ZeitArtsListComponent],

})
export class ZeitArtsListComponentModule { }
