import { NgModule } from '@angular/core';

import { DeleteReisekostenabrechnungStatusCommandModule, PostReisekostenabrechnungStatusCommandModule, ReisekostenabrechnungStatiQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostReisekostenabrechnungStatusCommandModule, DeleteReisekostenabrechnungStatusCommandModule, ReisekostenabrechnungStatiQueryModule],
  exports: [PostReisekostenabrechnungStatusCommandModule, DeleteReisekostenabrechnungStatusCommandModule, ReisekostenabrechnungStatiQueryModule],
})
export class ReisekostenabrechnungStatiSettingsModule {}
