import { IFilter, IFilterConfig } from "pbc.types";

import { IHonorarVereinbarung } from '.';

export interface IHonorarVereinbarungFilter extends IFilter<IHonorarVereinbarung> {}

export const HonorarVereinbarungFilterConfig: IFilterConfig<IHonorarVereinbarung> = [];

export type IHonorarVereinbarungField =
  'honorarVereinbarung.id'|
  'honorarVereinbarung.kunde'|
  'honorarVereinbarung.bisMarktwert'|
  'honorarVereinbarung.lohnWohnen'|
  'honorarVereinbarung.lohnGewerbe'|
  'honorarVereinbarung.lohnSpezial'
;
