import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteRolleCommandServiceModule } from '..';
import { DeleteRolleCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteRolleCommandServiceModule
  ],
  declarations: [DeleteRolleCommandComponent],
  exports: [DeleteRolleCommandComponent],

})
export class DeleteRolleCommandComponentModule { }
