interface ISorterExpressionConfig {
  readonly?: boolean;
  key: string;
  title: string;
  index: number;
  ascending: boolean;
}
export function SorterConfig(key: string, title: string): ISorterExpressionConfig {
  return { key, title, ascending: true, index: 0 };
}

export type ISorterConfig = Array<ISorterExpressionConfig>;

export interface ISorterExpression {
  ascending: boolean;
  order: number;
  readonly?: boolean;
}

export type ISorter = {
  [path: string]: ISorterExpression | undefined;
};
