import { IFilter, IFilterConfig, ISelection, IShape, ISingleReferenceFilter, SingleReferenceFilterConfig } from 'pbc.types';

import { IFeldKategorie, IFeldUnterkategorie } from 'fa-kt.types';
import { IFeldResponse } from '.';

export interface IFeldQueryFilter extends IFilter<IFeldResponse> {
  'feld.feldKategorie'?: ISingleReferenceFilter<IFeldKategorie>;
  'feld.feldUnterkategorie'?: ISingleReferenceFilter<IFeldUnterkategorie>;
}

export const FeldQueryFilterConfig: IFilterConfig<IFeldResponse> = [
  SingleReferenceFilterConfig<IFeldKategorie>('feld.feldKategorie', 'FELDER.Models.FELD.Propertys.FELD_KATEGORIE.title'),
  SingleReferenceFilterConfig<IFeldUnterkategorie>('feld.feldUnterkategorie', 'FELDER.Models.FELD.Propertys.FELD_UNTERKATEGORIE.title'),
];

export interface IFeldQueryShapes extends IShape {
  'feld.feldKategorie': ISelection[];
  'feld.feldUnterkategorie': ISelection[];
  'feld.art': ISelection[];
}

export type IFeldQueryField =
  | 'feld.id'
  | 'feld.schluessel'
  | 'feld.name'
  | 'feld.kommentar'
  | 'feld.feldKategorie'
  | 'feld.feldUnterkategorie'
  | 'feld.art'
  | 'feld.format'
  | 'feld.voreinstellung'
  | 'feldOptionen.id'
  | 'feldOptionen.feld'
  | 'feldOptionen.option'
  | 'feldOptionen.akkusativ'
  | 'feldOptionen.nominativ'
  | 'feldOptionen.dativ'
  | 'feldOptionen.genitiv'
  | 'feldOptionen.standard'
  | '';
