import { NgModule } from '@angular/core';
import {
  ErinnerungenComponentModule,
  KommunikationComponentModule,
  KommunikationBadgeComponentModule,
  VermerkeComponentModule
 } from '.';

@NgModule({
  imports: [
    ErinnerungenComponentModule,
    KommunikationComponentModule,
    KommunikationBadgeComponentModule,
    VermerkeComponentModule
  ],
  exports: [
    ErinnerungenComponentModule,
    KommunikationComponentModule,
    KommunikationBadgeComponentModule,
    VermerkeComponentModule
  ]
})
export class KommunikationComponentsModule { }
