import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DirectoryNode, IGraphOneDriveItem } from 'pbc.types';
import { firstValueFrom } from 'rxjs';
import { AppEnvironment, APP_CONFIG, SecretService } from '../../common';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  client: BlobServiceClient;

  environment = inject(APP_CONFIG) as AppEnvironment;
  message = inject(NzMessageService);

  constructor(
    private http: HttpClient,
    private secrets: SecretService,
  ) {
    const account = this.environment.blobs.account;
    this.secrets.$secrets.subscribe((secrets) => {
      if (!secrets?.blobs?.key) return;
      this.client = new BlobServiceClient(`https://${account}.blob.core.windows.net${secrets.blobs.key}`);
    });
  }

  getFileUrl(path: string) {
    if (!this.client) throw this.uninizalized();
    if (!path) return path;
    const paths = path.split('/');
    const fileName = paths.pop();
    path = paths.join('/');
    const containerClient = this.client.getContainerClient(path);
    const excelClient = containerClient.getBlobClient(fileName as string);
    return excelClient.url;
  }
  uninizalized() {
    return 'Dateien sind noch nicht initialisiert. Bitte versuchen Sie es in ein paar Sekunden noch einmal.';
  }

  async get(path: string): Promise<void | Blob> {
    if (!this.client) throw this.uninizalized();
    if (!path) return;
    const url = this.getFileUrl(path) as string;
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  download(path: string) {
    if (!this.client) throw this.uninizalized();
    if (path) window.open(this.getFileUrl(path) as string, '_blank');
  }

  downloadBlob(file: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }

  readFileAsync(file: File) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  async upload(containerName: string, files: File[] | File) {
    if (!this.client) throw this.uninizalized();
    const containerClient = this.client.getContainerClient(containerName);
    const uploads: any[] = [];
    for (const file of Array.isArray(files) ? files : [files]) {
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);
      uploads.push(blockBlobClient.uploadBrowserData(file));
    }
    await Promise.all(uploads);
  }

  async delete(paths: string | string[]) {
    if (!this.client) throw this.uninizalized();
    for (let path of (Array.isArray(paths) ? paths : [paths]).filter((p) => p)) {
      const paths = path.split('/');
      const fileName = paths.pop();
      path = paths.join('/');
      const containerClient = this.client.getContainerClient(path);
      if (!(await containerClient.exists())) {
        await containerClient.create();
      }
      const blob = containerClient.getBlockBlobClient(fileName as string);
      await blob.delete();
    }
  }

  async children(search: string, token: string, parent: string = '01VGRNSONUN5KUJ3BBKZLJZ4ACN73M6A5T'): Promise<DirectoryNode[]> {
    const result = await firstValueFrom(
      this.http.get<IGraphOneDriveItem[]>(`${this.environment.blobs.baseUrl}/drives/${this.environment.blobs.driveID}/items/${parent}/children`, {
        headers: {
          Authorization: 'bearer ' + token,
        },
      }),
    );
    const nodes: DirectoryNode[] = await Promise.all(
      (result || [])
        .filter((driveItem) => search.includes(driveItem.name as string))
        .map(async (driveItem: IGraphOneDriveItem) => ({
          title: driveItem.name as string,
          key: driveItem.id as string,
          webUrl: driveItem.webUrl,
          children: await this.children(search, token, driveItem.id as string),
        })),
    );
    return nodes;
  }

  async openDirectory(path: string, token: string) {
    const directories = await this.children(path, token);
    let parent: DirectoryNode = directories[0];
    while (parent?.children?.length) {
      parent = parent.children[0];
    }
    if (parent?.webUrl) window.open(parent.webUrl, '_blank');
    return true;
  }
}
