import { Component } from '@angular/core';
import { BaseComponent } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { combineLatest, Subscription } from 'rxjs';

import { IUrlaubeResponse, UrlaubeQueryFilterConfig } from 'fa-kt.types';
import { UrlaubeService } from '.';

@Component({
  selector: 'fa-kt-urlaube-filter',
  template: `<pbc-filter [config]="UrlaubeFilterConfig" [filter]="urlaube.filter$ | async" (filterChange)="urlaube.filter$.next($event)"></pbc-filter>`,
})
export class UrlaubeFilterComponent extends BaseComponent {
  readonly subscriptions: Subscription[] = [];

  public UrlaubeFilterConfig: IFilterConfig<IUrlaubeResponse>;

  constructor(public urlaube: UrlaubeService) {
    super();
    this.UrlaubeFilterConfig = UrlaubeQueryFilterConfig.map((filter) => ({ ...filter, title: get(this.sitemap, filter.title, filter.title) as string }));
  }

  override ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(
      combineLatest([this.urlaube.shapes$, this.meta.users$]).subscribe(([shapes, users]) => {
        if (!shapes) return;
        this.shape('urlaub.mitarbeiter', users);
      }),
    );
  }

  override ngOnDestroy() {
    super.ngOnInit();
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.UrlaubeFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.UrlaubeFilterConfig[index].selections = shapes;
  }
}
