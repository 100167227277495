import { NgModule } from '@angular/core';

import { DeleteKundenArtCommandModule, KundenArtsQueryModule, PostKundenArtCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostKundenArtCommandModule, DeleteKundenArtCommandModule, KundenArtsQueryModule],
  exports: [PostKundenArtCommandModule, DeleteKundenArtCommandModule, KundenArtsQueryModule],
})
export class KundenArtsSettingsModule {}
