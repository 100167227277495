import { NgModule } from '@angular/core';
import {
  BesichtigungsStatiFilterComponentModule,
  BesichtigungsStatiSorterComponentModule,
  BesichtigungsStatiListComponentModule
} from '.';

@NgModule({
  imports: [
    BesichtigungsStatiFilterComponentModule,
    BesichtigungsStatiSorterComponentModule,
    BesichtigungsStatiListComponentModule
  ],
  exports: [
    BesichtigungsStatiFilterComponentModule,
    BesichtigungsStatiSorterComponentModule,
    BesichtigungsStatiListComponentModule
  ],
})
export class BesichtigungsStatiQueryModule { }