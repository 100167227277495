import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ActionService, AuthService, cleanObject, CustomFormatter, Feld, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { IPostAbschnittRequest, IPostAbschnittResponse } from 'fa-kt.types';

import { PostAbschnittCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-abschnitt',
  templateUrl: './post-abschnitt.component.html',
  styleUrls: ['./post-abschnitt.component.css'],
})
export class PostAbschnittCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostAbschnittRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostAbschnittRequest> = new EventEmitter<IPostAbschnittRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostAbschnittResponse> = new EventEmitter<IPostAbschnittResponse>();

  /* << fields    */
  @Input() nunjucks: any = {};
  @Input() object: any = {};
  @Input() virtual: any = {};
  @Input() alleFelder: Feld[] = [];
  @Input() felder: Feld[] = [];

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postAbschnitt: PostAbschnittCommandService /* << more    */ /*    more >> */,
  ) {
    this.command = sitemap.TEXTE.Commands.POST_ABSCHNITT;
    this.form = this.fb.group({
      abschnitt: this.fb.group({
        id: [null, []],
        vorlage: [null, [Validators.required]],
        platzhalter: [null, [Validators.required]],
        name: [null, [Validators.required]],
        text: [null, [Validators.required]],
        order: [null, []],
      }),
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postAbschnitt.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostAbschnittRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.abschnittId?.valueChanges.subscribe(async (id) => {
        /* << on-abschnitt-id-change    */
        /*    on-abschnitt-id-change >> */
      }) as Subscription,
      this.abschnittVorlage?.valueChanges.subscribe(async (vorlage) => {
        /* << on-abschnitt-vorlage-change    */
        /*    on-abschnitt-vorlage-change >> */
      }) as Subscription,
      this.abschnittPlatzhalter?.valueChanges.subscribe(async (platzhalter) => {
        /* << on-abschnitt-platzhalter-change    */
        /*    on-abschnitt-platzhalter-change >> */
      }) as Subscription,
      this.abschnittName?.valueChanges.subscribe(async (name) => {
        /* << on-abschnitt-name-change    */
        /*    on-abschnitt-name-change >> */
      }) as Subscription,
      this.abschnittText?.valueChanges.subscribe(async (text) => {
        /* << on-abschnitt-text-change    */
        /*    on-abschnitt-text-change >> */
      }) as Subscription,
      /* << subscibe    */
      this.abschnittName?.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((name) => {
        if (!this.abschnittPlatzhalter?.touched || !this.abschnittPlatzhalter?.value) {
          this.abschnittPlatzhalter?.patchValue(name.toLowerCase().split(' ').join('_'));
        }
      }) as Subscription,
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostAbschnittRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.abschnitt) {
        value.abschnitt = cleanObject(value.abschnitt);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.abschnitt) {
        payload.abschnitt = cleanObject(payload.abschnitt);
      }
      const response: IPostAbschnittResponse = await this.postAbschnitt.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get abschnittId() {
    return this.form.controls.abschnitt.get('id');
  }
  get abschnittVorlage() {
    return this.form.controls.abschnitt.get('vorlage');
  }
  get abschnittPlatzhalter() {
    return this.form.controls.abschnitt.get('platzhalter');
  }
  get abschnittName() {
    return this.form.controls.abschnitt.get('name');
  }
  get abschnittText() {
    return this.form.controls.abschnitt.get('text');
  }
}
