import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { UrlaubCommandComponent } from '.';
import { DeleteUrlaubCommandService, PostUrlaubCommandService } from '..';

@NgModule({
  imports: [LIB_CommonModule],
  declarations: [UrlaubCommandComponent],
  providers: [PostUrlaubCommandService, DeleteUrlaubCommandService],
  exports: [UrlaubCommandComponent],
})
export class UrlaubCommandComponentModule {}
