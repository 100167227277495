import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IGutachtenQuerySorter extends ISorter {
  'gutachten.id'?: ISorterExpression;
  'gutachten.projekt'?: ISorterExpression;
  'gutachten.objekt'?: ISorterExpression;
  'gutachten.bewertungsStatus'?: ISorterExpression;
  'gutachten.objektNutzung'?: ISorterExpression;
  'gutachten.objektArt'?: ISorterExpression;
  'gutachten.sicherheitscheck'?: ISorterExpression;
  'gutachten.abgabeDraft'?: ISorterExpression;
  'gutachten.abgabeFinal'?: ISorterExpression;
  'gutachten.erbbaurecht'?: ISorterExpression;
  'gutachten.wohnenJRoE'?: ISorterExpression;
  'gutachten.wohnenJRoEFlaeche'?: ISorterExpression;
  'gutachten.gewerbeJRoE'?: ISorterExpression;
  'gutachten.gewerbeJRoEFlaeche'?: ISorterExpression;
  'gutachten.bueroJRoE'?: ISorterExpression;
  'gutachten.bueroJRoEFlaeche'?: ISorterExpression;
  'gutachten.handelJRoE'?: ISorterExpression;
  'gutachten.handelJRoEFlaeche'?: ISorterExpression;
  'gutachten.lagerJRoE'?: ISorterExpression;
  'gutachten.lagerJRoEFlaeche'?: ISorterExpression;
  'gutachten.sonstigesJRoE'?: ISorterExpression;
  'gutachten.sonstigesJRoEFlaeche'?: ISorterExpression;
  'gutachten.baujahr'?: ISorterExpression;
  'gutachten.modernisierung'?: ISorterExpression;
  'gutachten.marktwert'?: ISorterExpression;
  'gutachten.stichtagMarktwert'?: ISorterExpression;
  'gutachten.kaufpreis'?: ISorterExpression;
  'gutachten.stichtagKaufpreis'?: ISorterExpression;
  'gutachten.fortschritt'?: ISorterExpression;
  'gutachten.aktiv'?: ISorterExpression;
  'gutachten.kommentar'?: ISorterExpression;
  'gutachten.tourenplanung'?: ISorterExpression;
  'objekt.id'?: ISorterExpression;
  'objekt.name'?: ISorterExpression;
  'objekt.addresse'?: ISorterExpression;
  'objekt.bereiche'?: ISorterExpression;
  'objekt.anspracheOutlookKontakts'?: ISorterExpression;
  'objekt.klingelschild'?: ISorterExpression;
  'objekt.kommentar'?: ISorterExpression;
  'erforderlicheDokumente.id'?: ISorterExpression;
  'erforderlicheDokumente.original'?: ISorterExpression;
  'erforderlicheDokumente.gutachten'?: ISorterExpression;
  'erforderlicheDokumente.name'?: ISorterExpression;
  'erforderlicheDokumente.erforderlich'?: ISorterExpression;
  'erforderlicheDokumente.fortschritt'?: ISorterExpression;
  'erforderlicheDokumente.kommentar'?: ISorterExpression;
  'kalkulationen.id'?: ISorterExpression;
  'kalkulationen.gutachten'?: ISorterExpression;
  'kalkulationen.datei'?: ISorterExpression;
  'besichtigung.id'?: ISorterExpression;
  'besichtigung.projekt'?: ISorterExpression;
  'besichtigung.tour'?: ISorterExpression;
  'besichtigung.gutachten'?: ISorterExpression;
  'besichtigung.objekt'?: ISorterExpression;
  'besichtigung.besichtigungsStatus'?: ISorterExpression;
  'besichtigung.mitarbeiter'?: ISorterExpression;
  'besichtigung.kommentar'?: ISorterExpression;
  'besichtigung.rueckfahrt'?: ISorterExpression;
  'besichtigung.von'?: ISorterExpression;
  'besichtigung.bis'?: ISorterExpression;
  'besichtigung.start'?: ISorterExpression;
  'besichtigung.ende'?: ISorterExpression;
  'besichtigung.aufenthalt'?: ISorterExpression;
  'besichtigung.fahrtzeit'?: ISorterExpression;
  'besichtigung.fahrtstrecke'?: ISorterExpression;
  'besichtigung.fahrtzeitOhneVerkehr'?: ISorterExpression;
  // 'besichtigung.kosten'?: ISorterExpression;
  'besichtigung.abgerechnet'?: ISorterExpression;
  'besichtigung.route'?: ISorterExpression;
  'durchgaenge.id'?: ISorterExpression;
  'durchgaenge.formular'?: ISorterExpression;
  'durchgaenge.gutachten'?: ISorterExpression;
  'durchgaenge.projekt'?: ISorterExpression;
  'durchgaenge.besichtigung'?: ISorterExpression;
  'eintraege.id'?: ISorterExpression;
  'eintraege.gutachten'?: ISorterExpression;
  'eintraege.feld'?: ISorterExpression;
  'eintraege.wert'?: ISorterExpression;
  'eintraege.wertExtra'?: ISorterExpression;
  'eintraege.akkusativ'?: ISorterExpression;
  'eintraege.nominativ'?: ISorterExpression;
  'eintraege.dativ'?: ISorterExpression;
  'eintraege.genitiv'?: ISorterExpression;
  'eintraege.achtung'?: ISorterExpression;
  'berichte.id'?: ISorterExpression;
  'berichte.excel'?: ISorterExpression;
  'berichte.art'?: ISorterExpression;
  'berichte.herausgeber'?: ISorterExpression;
  'berichte.quelle'?: ISorterExpression;
  'berichte.jahr'?: ISorterExpression;
  'berichte.quartal'?: ISorterExpression;
  'berichte.monat'?: ISorterExpression;
  'berichte.kommentar'?: ISorterExpression;
}

export const GutachtenQuerySorterConfig: ISorterConfig = [
  SorterConfig('gutachten.id', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ID.title'),
  SorterConfig('gutachten.projekt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.PROJEKT.title'),
  SorterConfig('gutachten.objekt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT.title'),
  SorterConfig('gutachten.bewertungsStatus', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_STATUS.title'),
  SorterConfig('gutachten.objektNutzung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_NUTZUNG.title'),
  SorterConfig('gutachten.objektArt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_ART.title'),
  SorterConfig('gutachten.sicherheitscheck', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SICHERHEITSCHECK.title'),
  SorterConfig('gutachten.abgabeDraft', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_DRAFT.title'),
  SorterConfig('gutachten.abgabeFinal', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_FINAL.title'),
  SorterConfig('gutachten.erbbaurecht', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ERBBAURECHT.title'),
  SorterConfig('gutachten.wohnenJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E.title'),
  SorterConfig('gutachten.wohnenJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.gewerbeJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E.title'),
  SorterConfig('gutachten.gewerbeJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.bueroJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E.title'),
  SorterConfig('gutachten.bueroJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.handelJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E.title'),
  SorterConfig('gutachten.handelJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.lagerJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E.title'),
  SorterConfig('gutachten.lagerJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.sonstigesJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E.title'),
  SorterConfig('gutachten.sonstigesJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.baujahr', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BAUJAHR.title'),
  SorterConfig('gutachten.modernisierung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MODERNISIERUNG.title'),
  SorterConfig('gutachten.marktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MARKTWERT.title'),
  SorterConfig('gutachten.stichtagMarktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_MARKTWERT.title'),
  SorterConfig('gutachten.kaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.KAUFPREIS.title'),
  SorterConfig('gutachten.stichtagKaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_KAUFPREIS.title'),
  SorterConfig('gutachten.fortschritt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.FORTSCHRITT.title'),
  SorterConfig('gutachten.aktiv', 'GUTACHTEN.Models.GUTACHTEN.Propertys.AKTIV.title'),
  SorterConfig('gutachten.kommentar', 'GUTACHTEN.Models.GUTACHTEN.Propertys.KOMMENTAR.title'),
  SorterConfig('gutachten.tourenplanung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.TOURENPLANUNG.title'),
  SorterConfig('objekt.id', 'OBJEKTE.Models.OBJEKT.Propertys.ID.title'),
  SorterConfig('objekt.name', 'OBJEKTE.Models.OBJEKT.Propertys.NAME.title'),
  SorterConfig('objekt.addresse', 'OBJEKTE.Models.OBJEKT.Propertys.ADDRESSE.title'),
  SorterConfig('objekt.bereiche', 'OBJEKTE.Models.OBJEKT.Propertys.BEREICHE.title'),
  SorterConfig('objekt.anspracheOutlookKontakts', 'OBJEKTE.Models.OBJEKT.Propertys.ANSPRACHE_OUTLOOK_KONTAKTS.title'),
  SorterConfig('objekt.klingelschild', 'OBJEKTE.Models.OBJEKT.Propertys.KLINGELSCHILD.title'),
  SorterConfig('objekt.kommentar', 'OBJEKTE.Models.OBJEKT.Propertys.KOMMENTAR.title'),
  SorterConfig('erforderlicheDokumente.id', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ID.title'),
  SorterConfig('erforderlicheDokumente.original', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ORIGINAL.title'),
  SorterConfig('erforderlicheDokumente.gutachten', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.GUTACHTEN.title'),
  SorterConfig('erforderlicheDokumente.name', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.NAME.title'),
  SorterConfig('erforderlicheDokumente.erforderlich', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ERFORDERLICH.title'),
  SorterConfig('erforderlicheDokumente.fortschritt', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.FORTSCHRITT.title'),
  SorterConfig('erforderlicheDokumente.kommentar', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.KOMMENTAR.title'),
  SorterConfig('kalkulationen.id', 'GUTACHTEN.Models.KALKULATION.Propertys.ID.title'),
  SorterConfig('kalkulationen.gutachten', 'GUTACHTEN.Models.KALKULATION.Propertys.GUTACHTEN.title'),
  SorterConfig('kalkulationen.datei', 'GUTACHTEN.Models.KALKULATION.Propertys.DATEI.title'),
  SorterConfig('besichtigung.id', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ID.title'),
  SorterConfig('besichtigung.projekt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.PROJEKT.title'),
  SorterConfig('besichtigung.tour', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.TOUR.title'),
  SorterConfig('besichtigung.gutachten', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.GUTACHTEN.title'),
  SorterConfig('besichtigung.objekt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.OBJEKT.title'),
  SorterConfig('besichtigung.besichtigungsStatus', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BESICHTIGUNGS_STATUS.title'),
  SorterConfig('besichtigung.mitarbeiter', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.MITARBEITER.title'),
  SorterConfig('besichtigung.kommentar', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.KOMMENTAR.title'),
  SorterConfig('besichtigung.rueckfahrt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.RUECKFAHRT.title'),
  SorterConfig('besichtigung.von', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.VON.title'),
  SorterConfig('besichtigung.bis', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BIS.title'),
  SorterConfig('besichtigung.start', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.START.title'),
  SorterConfig('besichtigung.ende', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ENDE.title'),
  SorterConfig('besichtigung.aufenthalt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.AUFENTHALT.title'),
  SorterConfig('besichtigung.fahrtzeit', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT.title'),
  SorterConfig('besichtigung.fahrtstrecke', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTSTRECKE.title'),
  SorterConfig('besichtigung.fahrtzeitOhneVerkehr', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT_OHNE_VERKEHR.title'),
  // SorterConfig('besichtigung.kosten', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.KOSTEN.title'),
  SorterConfig('besichtigung.abgerechnet', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ABGERECHNET.title'),
  SorterConfig('besichtigung.route', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ROUTE.title'),
  SorterConfig('durchgaenge.id', 'FORMULARE.Models.DURCHGANG.Propertys.ID.title'),
  SorterConfig('durchgaenge.formular', 'FORMULARE.Models.DURCHGANG.Propertys.FORMULAR.title'),
  SorterConfig('durchgaenge.gutachten', 'FORMULARE.Models.DURCHGANG.Propertys.GUTACHTEN.title'),
  SorterConfig('durchgaenge.projekt', 'FORMULARE.Models.DURCHGANG.Propertys.PROJEKT.title'),
  SorterConfig('durchgaenge.besichtigung', 'FORMULARE.Models.DURCHGANG.Propertys.BESICHTIGUNG.title'),
  SorterConfig('eintraege.id', 'GUTACHTEN.Models.EINTRAG.Propertys.ID.title'),
  SorterConfig('eintraege.gutachten', 'GUTACHTEN.Models.EINTRAG.Propertys.GUTACHTEN.title'),
  SorterConfig('eintraege.feld', 'GUTACHTEN.Models.EINTRAG.Propertys.FELD.title'),
  SorterConfig('eintraege.wert', 'GUTACHTEN.Models.EINTRAG.Propertys.WERT.title'),
  SorterConfig('eintraege.wertExtra', 'GUTACHTEN.Models.EINTRAG.Propertys.WERT_EXTRA.title'),
  SorterConfig('eintraege.akkusativ', 'GUTACHTEN.Models.EINTRAG.Propertys.AKKUSATIV.title'),
  SorterConfig('eintraege.nominativ', 'GUTACHTEN.Models.EINTRAG.Propertys.NOMINATIV.title'),
  SorterConfig('eintraege.dativ', 'GUTACHTEN.Models.EINTRAG.Propertys.DATIV.title'),
  SorterConfig('eintraege.genitiv', 'GUTACHTEN.Models.EINTRAG.Propertys.GENITIV.title'),
  SorterConfig('eintraege.achtung', 'GUTACHTEN.Models.EINTRAG.Propertys.ACHTUNG.title'),
  SorterConfig('berichte.id', 'MARKT.Models.BERICHT.Propertys.ID.title'),
  SorterConfig('berichte.excel', 'MARKT.Models.BERICHT.Propertys.EXCEL.title'),
  SorterConfig('berichte.art', 'MARKT.Models.BERICHT.Propertys.ART.title'),
  SorterConfig('berichte.herausgeber', 'MARKT.Models.BERICHT.Propertys.HERAUSGEBER.title'),
  SorterConfig('berichte.quelle', 'MARKT.Models.BERICHT.Propertys.QUELLE.title'),
  SorterConfig('berichte.jahr', 'MARKT.Models.BERICHT.Propertys.JAHR.title'),
  SorterConfig('berichte.quartal', 'MARKT.Models.BERICHT.Propertys.QUARTAL.title'),
  SorterConfig('berichte.monat', 'MARKT.Models.BERICHT.Propertys.MONAT.title'),
  SorterConfig('berichte.kommentar', 'MARKT.Models.BERICHT.Propertys.KOMMENTAR.title'),
];
