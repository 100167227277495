import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteVorlageCommandServiceModule } from '..';
import { DeleteVorlageCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteVorlageCommandServiceModule
  ],
  declarations: [DeleteVorlageCommandComponent],
  exports: [DeleteVorlageCommandComponent],

})
export class DeleteVorlageCommandComponentModule { }
