import { NgModule } from '@angular/core';
import {
  BerichteFilterComponentModule,
  BerichteListComponentModule,
  BerichteSorterComponentModule,
  SavedBerichteFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    BerichteFilterComponentModule,
    BerichteListComponentModule,
    BerichteSorterComponentModule,
    SavedBerichteFiltersComponentModule
  ],
  exports: [
    BerichteFilterComponentModule,
    BerichteListComponentModule,
    BerichteSorterComponentModule,
    SavedBerichteFiltersComponentModule
  ],
})
export class BerichteQueryModule { }
