import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitenSorterComponent } from '.';
import { ZeitenServiceModule } from '../..';

@NgModule({
  imports: [LIB_CommonModule, ZeitenServiceModule],
  declarations: [ZeitenSorterComponent],
  exports: [ZeitenSorterComponent],
})
export class ZeitenSorterComponentModule {}
