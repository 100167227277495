import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IReisekostenabrechnungStatiResponse } from 'fa-kt.types';
import { PostReisekostenabrechnungStatusCommandService } from '../commands';
import { ReisekostenabrechnungStatiService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class ReisekostenabrechnungStatiPageResolver implements Resolve<IReisekostenabrechnungStatiResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private reisekostenabrechnungStati: ReisekostenabrechnungStatiService,
    private postReisekostenabrechnungStatus: PostReisekostenabrechnungStatusCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IReisekostenabrechnungStatiResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [reisekostenabrechnungStati] = await Promise.all([this.reisekostenabrechnungStati.request({}).catch(log), this.postReisekostenabrechnungStatus.prepare(true).catch(log)]);
    this.loading.resolved();
    return reisekostenabrechnungStati;
  }
}
