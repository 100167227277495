import {
  ArrayReferenceFilterConfig,
  IArrayReferenceFilter,
  IFilter,
  IFilterConfig,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IMitarbeiter, IReisekostenabrechnungStatus, ITour } from 'fa-kt.types';
import { IReisekostenabrechnungResponse } from '.';

export interface IReisekostenabrechnungQueryFilter extends IFilter<IReisekostenabrechnungResponse> {
  'reisekostenabrechnung.mitarbeiter'?: ISingleReferenceFilter<IMitarbeiter>;
  'reisekostenabrechnung.touren'?: IArrayReferenceFilter<ITour>;
  'reisekostenabrechnung.reisekostenabrechnungStatus'?: ISingleReferenceFilter<IReisekostenabrechnungStatus>;
  'reisekostenabrechnung.tagesgeldAcht'?: INumberFilter;
  'reisekostenabrechnung.tagesgeldVierundzwanzig'?: INumberFilter;
  'reisekostenabrechnung.anUndAbreisetag'?: INumberFilter;
  'reisekostenabrechnung.fruehstuecke'?: INumberFilter;
  'reisekostenabrechnung.mittagessen'?: INumberFilter;
  'reisekostenabrechnung.abendessen'?: INumberFilter;
  'reisekostenabrechnung.uebernachtungspauschale'?: INumberFilter;
  'reisekostenabrechnung.normaleKm'?: INumberFilter;
  'reisekostenabrechnung.mitnahmeentschaedigung'?: INumberFilter;
  'reisekostenabrechnung.sonstigeSumme'?: INumberFilter;
}

export const ReisekostenabrechnungQueryFilterConfig: IFilterConfig<IReisekostenabrechnungResponse> = [
  SingleReferenceFilterConfig<IMitarbeiter>('reisekostenabrechnung.mitarbeiter', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITARBEITER.title'),
  ArrayReferenceFilterConfig<ITour>('reisekostenabrechnung.touren', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TOUREN.title'),
  SingleReferenceFilterConfig<IReisekostenabrechnungStatus>('reisekostenabrechnung.reisekostenabrechnungStatus', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.REISEKOSTENABRECHNUNG_STATUS.title'),
  NumberFilterConfig('reisekostenabrechnung.tagesgeldAcht', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_ACHT.title'),
  NumberFilterConfig('reisekostenabrechnung.tagesgeldVierundzwanzig', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_VIERUNDZWANZIG.title'),
  NumberFilterConfig('reisekostenabrechnung.anUndAbreisetag', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.AN_UND_ABREISETAG.title'),
  NumberFilterConfig('reisekostenabrechnung.fruehstuecke', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.FRUEHSTUECKE.title'),
  NumberFilterConfig('reisekostenabrechnung.mittagessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITTAGESSEN.title'),
  NumberFilterConfig('reisekostenabrechnung.abendessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ABENDESSEN.title'),
  NumberFilterConfig('reisekostenabrechnung.uebernachtungspauschale', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.UEBERNACHTUNGSPAUSCHALE.title'),
  NumberFilterConfig('reisekostenabrechnung.normaleKm', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.NORMALE_KM.title'),
  NumberFilterConfig('reisekostenabrechnung.mitnahmeentschaedigung', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITNAHMEENTSCHAEDIGUNG.title'),
  NumberFilterConfig('reisekostenabrechnung.sonstigeSumme', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SONSTIGE_SUMME.title'),
];

export interface IReisekostenabrechnungQueryShapes extends IShape {
  'reisekostenabrechnung.mitarbeiter': ISelection[];
  'reisekostenabrechnung.touren': ISelection[];
  'reisekostenabrechnung.reisekostenabrechnungStatus': ISelection[];
}

export type IReisekostenabrechnungQueryField =
  | 'reisekostenabrechnung.id'
  | 'reisekostenabrechnung.mitarbeiter'
  | 'reisekostenabrechnung.touren'
  | 'reisekostenabrechnung.reisekostenabrechnungStatus'
  | 'reisekostenabrechnung.tagesgeldAcht'
  | 'reisekostenabrechnung.tagesgeldVierundzwanzig'
  | 'reisekostenabrechnung.anUndAbreisetag'
  | 'reisekostenabrechnung.fruehstuecke'
  | 'reisekostenabrechnung.mittagessen'
  | 'reisekostenabrechnung.abendessen'
  | 'reisekostenabrechnung.uebernachtungspauschale'
  | 'reisekostenabrechnung.normaleKm'
  | 'reisekostenabrechnung.mitnahmeentschaedigung'
  | 'reisekostenabrechnung.sonstigePositionen'
  | 'reisekostenabrechnung.sonstigeSumme'
  | 'reisekostenabrechnung.summe'
  | 'reisekostenabrechnung.antrag'
  | '';
