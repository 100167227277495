import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IKalkulationSorter extends ISorter {
  id?: ISorterExpression;
  gutachten?: ISorterExpression;
  datei?: ISorterExpression;
}

export const KalkulationSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('datei', 'Datei')
];
