<cdk-virtual-scroll-viewport *ngIf="vorlagen.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (vorlagen.result$ | async).vorlagen" (click)="set(entry)">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <a>
            <strong>{{ entry.vorlage.initialen }}</strong>
            <nz-divider nzType="vertical"></nz-divider>
            {{ entry.vorlage.name }}
          </a>
        </nz-list-item-meta-title>
        <nz-list-item-meta-description>
          <span nz-typography nzType="secondary" *ngIf="entry.vorlage.fuerBewertungsAnlaesse?.length">
            {{ entry.vorlage.fuerBewertungsAnlaesse | type: (vorlagen.vorlageFuerBewertungsAnlaesse | async) }}
            <nz-divider *ngIf="hasDivider(entry.vorlage)" nzType="vertical"></nz-divider>
          </span>
          <span nz-typography nzType="secondary" *ngIf="entry.vorlage.fuerObjektArten?.length">
            {{ entry.vorlage.fuerObjektArten | type: (vorlagen.vorlageFuerObjektArten | async) }}
            <nz-divider *ngIf="hasDivider(entry.vorlage)" nzType="vertical"></nz-divider>
          </span>
          <span nz-typography nzType="secondary" *ngIf="entry.vorlage.fuerKundenGruppen?.length">
            {{ entry.vorlage.fuerKundenGruppen | type: (vorlagen.vorlageFuerKundenGruppen | async) }}
            <nz-divider *ngIf="hasDivider(entry.vorlage)" nzType="vertical"></nz-divider>
          </span>
          <span nz-typography nzType="secondary" *ngIf="entry.vorlage.fuerKundenArten?.length">
            {{ entry.vorlage.fuerKundenArten | type: (vorlagen.vorlageFuerKundenArten | async) }}
            <nz-divider *ngIf="hasDivider(entry.vorlage)" nzType="vertical"></nz-divider>
          </span>
          <span nz-typography nzType="secondary" *ngIf="entry.vorlage.fuerKunden?.length">
            {{ entry.vorlage.fuerKunden | type: (vorlagen.vorlageFuerKunden | async) }}
          </span>
        </nz-list-item-meta-description>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
