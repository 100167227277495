import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { icons } from '.';

@Component({
  selector: 'pbc-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: IconPickerComponent
    }
  ]
})
export class IconPickerComponent implements OnInit, OnDestroy, ControlValueAccessor  {

  onChange = (icon: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  term = '';
  visible = false;
  readonly icons: { icon: string, _search: string }[] = [
  ]

  @Input() set icon(icon: string|null) {
    this._icon = icon ? icon : '';
  }
  _icon = '';

  @Output()
  iconChange: EventEmitter<string> = new EventEmitter()


  constructor() {
    this.icons = icons.map((icon) => ({ ...icon, _search: icon.name }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public setIcon(iconName: string): void {
    this.icon = iconName;
    this.iconChange.emit(this.icon);
    this.onChange(iconName);
    this.markAsTouched();
    this.visible = false;
  }


  writeValue(icon: string) {
    this.icon = icon;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
