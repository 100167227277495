import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IBerichteRequest, IBerichteResponse } from 'fa-kt.types';
import { PostHochladenCommandService } from '../../commands';
import { BerichteService } from '../../querys';

export interface IMarktBerichtePageResolverResult {
  berichte?: IBerichteResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class MarktBerichtePageResolver implements Resolve<IMarktBerichtePageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private berichte: BerichteService,
    private postHochladen: PostHochladenCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IMarktBerichtePageResolverResult> {
    this.loading.resolving();
    const requests = {
      berichte: {} as IBerichteRequest,
    };

    const log = (error: any) => console.error(error);
    const [berichte] = await Promise.all([this.berichte.request(requests.berichte).catch(log)]);
    this.loading.resolved();
    return {
      berichte,
    };
  }
}
