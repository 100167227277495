import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitArtsSorterComponent } from '.';
import { ZeitArtsServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitArtsServiceModule
  ],
  declarations: [ZeitArtsSorterComponent],
  exports: [ZeitArtsSorterComponent],

})
export class ZeitArtsSorterComponentModule { }
