import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostFeldOptionCommandShapes, IPostFeldOptionRequest, IPostFeldOptionResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostFeldOptionCommandService extends PostCommandService<IPostFeldOptionRequest, IPostFeldOptionResponse, IPostFeldOptionCommandShapes> {
  readonly route = 'felder/feld-option';
}
