import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteExtraVereinbarungCommandServiceModule } from '..';
import { DeleteExtraVereinbarungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteExtraVereinbarungCommandServiceModule
  ],
  declarations: [DeleteExtraVereinbarungCommandComponent],
  exports: [DeleteExtraVereinbarungCommandComponent],

})
export class DeleteExtraVereinbarungCommandComponentModule { }
