import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IObjektResponse } from '.';

export interface IObjektQueryFilter extends IFilter<IObjektResponse> {}

export const ObjektQueryFilterConfig: IFilterConfig<IObjektResponse> = [];

export interface IObjektQueryShapes extends IShape {}

export type IObjektQueryField = '';
