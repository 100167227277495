import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IBesichtigungsStatiQueryFilter, IBesichtigungsStatiQueryShapes, IBesichtigungsStatiQuerySorter, IBesichtigungsStatiRequest, IBesichtigungsStatiResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class BesichtigungsStatiService extends SettingsQueryService<
  IBesichtigungsStatiRequest,
  IBesichtigungsStatiResponse,
  IBesichtigungsStatiQueryShapes,
  IBesichtigungsStatiQueryFilter,
  IBesichtigungsStatiQuerySorter
> {
  contentKey: keyof IBesichtigungsStatiResponse = 'besichtigungsStati';
  route = 'besichtigungen/settings/besichtigungs-stati';
  override dependencies = ['besichtigungen/written-besichtigungs-status', 'besichtigungen/removed-besichtigungs-status'];
}
