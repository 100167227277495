import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IZeitUnterartsQuerySorter extends ISorter {
   id?: ISorterExpression;
   zeitArt?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const ZeitUnterartsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'ZEITEN.Models.ZEIT_UNTERART.Propertys.ID.title'),
   SorterConfig('zeitArt', 'ZEITEN.Models.ZEIT_UNTERART.Propertys.ZEIT_ART.title'),
   SorterConfig('name', 'ZEITEN.Models.ZEIT_UNTERART.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'ZEITEN.Models.ZEIT_UNTERART.Propertys.BESCHREIBUNG.title'),
];
