import { IFilter, IFilterConfig } from "pbc.types";

import { IFormular } from '.';

export interface IFormularFilter extends IFilter<IFormular> {}

export const FormularFilterConfig: IFilterConfig<IFormular> = [];

export type IFormularField =
  'formular.id'|
  'formular.name'|
  'formular.initialen'|
  'formular.objektArten'|
  'formular.bewertungsAnlaesse'|
  'formular.kundenArten'|
  'formular.kunden'|
  'formular.ohneFelder'|
  'formular.kommentar'
;
