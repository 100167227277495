import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BesichtigungsStatiServiceModule } from '..';
import { BesichtigungsStatiFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BesichtigungsStatiServiceModule
  ],
  declarations: [BesichtigungsStatiFilterComponent],
  exports: [BesichtigungsStatiFilterComponent],

})
export class BesichtigungsStatiFilterComponentModule { }
