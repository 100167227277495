import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { DurchsuchenComponent } from '.';
import { DurchsuchenQueryModule } from '../../querys';

@NgModule({
  imports: [LIB_CommonModule, DurchsuchenQueryModule],
  declarations: [DurchsuchenComponent],
  exports: [DurchsuchenComponent],
})
export class DurchsuchenComponentModule {}
