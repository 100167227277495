import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IGutachtenStatus } from 'fa-kt.types';
import { PostGutachtenStatusCommandService } from '../../commands';
import { GutachtenStatiService } from '../service';

@Component({
  selector: 'fa-kt-gutachten-stati-list',
  templateUrl: './gutachten-stati-list.component.html',
  styleUrls: ['./gutachten-stati-list.component.css'],
})
export class GutachtenStatiListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IGutachtenStatus> = new EventEmitter<IGutachtenStatus>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public gutachtenStati: GutachtenStatiService,
    public gutachtenStatusPost: PostGutachtenStatusCommandService,
  ) {
    this.sortable$ = this.gutachtenStati.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const gutachtenStati = this.gutachtenStati.result$.getValue();
    if (gutachtenStati && gutachtenStati.gutachtenStati) {
      if ($event) {
        moveItemInArray(gutachtenStati.gutachtenStati, $event.previousIndex, $event.currentIndex);
      }
      gutachtenStati.gutachtenStati = gutachtenStati.gutachtenStati.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.gutachtenStatusPost.request({ gutachtenStatus: gutachtenStati.gutachtenStati });
    }
  }
}
