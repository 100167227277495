import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IRollesResponse } from 'fa-kt.types';
import { PostRolleCommandService } from '../commands';
import { RollesService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class RollesPageResolver implements Resolve<IRollesResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private rolles: RollesService,
    private postRolle: PostRolleCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IRollesResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [rolles] = await Promise.all([this.rolles.request({}).catch(log), this.postRolle.prepare(true).catch(log)]);
    this.loading.resolved();
    return rolles;
  }
}
