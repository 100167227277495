import { NgModule } from '@angular/core';

import {
  PostDokumentHinterlegenCommandServiceModule,
  PostDokumentHinterlegenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostDokumentHinterlegenCommandServiceModule,
    PostDokumentHinterlegenCommandComponentModule
  ],
  exports: [
    PostDokumentHinterlegenCommandServiceModule,
    PostDokumentHinterlegenCommandComponentModule
  ]
})
export class PostDokumentHinterlegenCommandModule { }
