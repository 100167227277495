import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostImportCommandServiceModule } from '..';
import { PostImportCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostImportCommandServiceModule
  ],
  declarations: [PostImportCommandComponent],
  exports: [PostImportCommandComponent],

})
export class PostImportCommandComponentModule { }
