import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BerichteServiceModule } from '..';
import { SavedBerichteFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichteServiceModule
  ],
  declarations: [SavedBerichteFiltersComponent],
  exports: [SavedBerichteFiltersComponent],

})
export class SavedBerichteFiltersComponentModule { }
