import { Component } from '@angular/core';
import { ReisekostenabrechnungenService } from '../service';

@Component({
  selector: 'fa-kt-saved-reisekostenabrechnungen-filters',
  templateUrl: './saved-reisekostenabrechnungen-filters.component.html',
  styleUrls: ['./saved-reisekostenabrechnungen-filters.component.css'],
})
export class SavedReisekostenabrechnungenFiltersComponent {
  constructor(public reisekostenabrechnungen: ReisekostenabrechnungenService) {}
}
