import { IFilter, IFilterConfig } from "pbc.types";

import { IFond } from '.';

export interface IFondFilter extends IFilter<IFond> {}

export const FondFilterConfig: IFilterConfig<IFond> = [];

export type IFondField =
  'fond.id'|
  'fond.kunde'|
  'fond.name'|
  'fond.aktiv'
;
