import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "pbc.angular";

import { TourService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    TourService,
  ],
  exports: []
})
export class TourServiceModule { }
