import { NgModule } from '@angular/core';

import { DeleteProjektStatusCommandModule, PostProjektStatusCommandModule, ProjektStatiQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostProjektStatusCommandModule, DeleteProjektStatusCommandModule, ProjektStatiQueryModule],
  exports: [PostProjektStatusCommandModule, DeleteProjektStatusCommandModule, ProjektStatiQueryModule],
})
export class ProjektStatiSettingsModule {}
