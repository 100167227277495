import { NgModule } from '@angular/core';

import { FilterPipe, LIB_CommonModule, SearchPipe, SorterPipe } from 'pbc.angular';

import { KundenService } from '.';

@NgModule({
  imports: [LIB_CommonModule],
  providers: [KundenService, SearchPipe, FilterPipe, SorterPipe],
  exports: [],
})
export class KundenServiceModule {}
