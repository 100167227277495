import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteObjektNutzungRequest, IDeleteObjektNutzungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteObjektNutzungCommandService extends DeleteCommandService<IDeleteObjektNutzungRequest, IDeleteObjektNutzungResponse> {
  readonly route = 'objekte/settings/objekt-nutzung';
}
