import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFotoKategorie } from 'fa-kt.types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PostFotoKategorieCommandService } from '../../commands';
import { FotoKategorienService } from '../service';

@Component({
  selector: 'fa-kt-foto-kategorien-list',
  templateUrl: './foto-kategorien-list.component.html',
  styleUrls: ['./foto-kategorien-list.component.css'],
})
export class FotoKategorienListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IFotoKategorie> = new EventEmitter<IFotoKategorie>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public fotoKategorien: FotoKategorienService,
    public fotoKategoriePost: PostFotoKategorieCommandService,
  ) {
    this.sortable$ = this.fotoKategorien.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const fotoKategorien = this.fotoKategorien.result$.getValue();
    if (fotoKategorien && fotoKategorien.fotoKategorien) {
      if ($event) {
        moveItemInArray(fotoKategorien.fotoKategorien, $event.previousIndex, $event.currentIndex);
      }
      fotoKategorien.fotoKategorien = fotoKategorien.fotoKategorien.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.fotoKategoriePost.request({ fotoKategorie: fotoKategorien.fotoKategorien });
    }
  }
}
