import Tagify from '@yaireo/tagify';
import { IFeld } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { checkUndefined, ConditionClasses, cssStyle, destroy, getFormattedDefaults, init, Option, parse, setValue, show, toggleVisibility, toggleVisibilityTagify, toOption } from '.';

export class PlaceholderTool {
  public static isInline = true;
  public static title = 'Platzhalter';
  public static shortcut = 'CMD+P';
  static delimiters = {
    start: '{{ ',
    end: ' }}',
  };

  static tag = 'MARK';
  static class = 'fakt-placeholder';
  static frame = 'placeholder-frame';

  api: EditorJS.API;
  _state: boolean;

  frame: HTMLDivElement;

  placeholders$: BehaviorSubject<IFeld[]>;

  button: HTMLButtonElement;
  placeholder: { element?: HTMLInputElement; tagify?: Tagify } = {};
  format: { element?: HTMLInputElement; tagify?: Tagify } = {};

  constructor({ api, config }) {
    this.api = api;
    this._state = false;
    this.placeholders$ = config.placeholders;
  }

  static get sanitize() {
    return {
      mark: (el: HTMLElement) => {
        return {
          class: el.className,
          style: el.style.cssText,
          'data-id': el.getAttribute('data-id'),
          'data-type': el.getAttribute('data-type'),
          'data-placeholder': el.getAttribute('data-placeholder'),
          'data-pipe': el.getAttribute('data-format'),
          'data-default-format': el.getAttribute('data-default-format'),
          'data-format': el.getAttribute('data-format'),
        };
      },
    };
  }

  static create(placeholder = '', pipe?: string, defaultFormat?: string, format?: string, id?: string) {
    const mark = document.createElement(this.tag);
    mark.classList.add(this.class);
    mark.contentEditable = 'false';
    mark.style.cssText = cssStyle;
    mark.setAttribute('data-id', id || uuidv4());
    mark.setAttribute('data-type', 'placeholder');
    mark.setAttribute('data-placeholder', placeholder);
    mark.setAttribute('data-pipe', pipe);
    mark.setAttribute('data-default-format', defaultFormat);
    mark.setAttribute('data-format', format);
    return this.update(mark);
  }

  static get(mark: HTMLElement) {
    const defaultFormat = checkUndefined(mark.getAttribute('data-default-format'), undefined);
    return {
      id: mark.getAttribute('data-id'),
      placeholder: checkUndefined(mark.getAttribute('data-placeholder'), 'PLATZHALTER'),
      pipe: checkUndefined(mark.getAttribute('data-pipe'), undefined),
      defaultFormat,
      format: checkUndefined(mark.getAttribute('data-format'), defaultFormat),
    };
  }

  static update(mark: HTMLElement): HTMLElement {
    const { placeholder, pipe, defaultFormat, format } = PlaceholderTool.get(mark);
    mark.innerText = this.delimiters.start + `${placeholder}${pipe && (format || defaultFormat) ? ` | ${pipe}('${format || defaultFormat}')` : ''}` + this.delimiters.end;
    return mark;
  }

  checkState() {
    const mark = this.api.selection.findParentTag(PlaceholderTool.tag, PlaceholderTool.class);
    const others = ConditionClasses.map((conditionClass) => this.api.selection.findParentTag(PlaceholderTool.tag, conditionClass)).filter((el) => !!el);
    this.state = !!mark;
    this.button = toggleVisibility(this.button, others.length === 0);
    setTimeout(() => {
      if (this.state) this.showActions(mark);
      else this.hideActions();
    }, 100);
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.innerHTML =
      '<svg width="20" height="18" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" /><path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z" /></svg>';
    this.button.classList.add(this.api.styles.inlineToolButton);
    return this.button;
  }

  surround(range) {
    if (this.state) this.unwrap(range);
    else if (range.endContainer.id !== PlaceholderTool.frame) this.wrap(range);
  }

  wrap(range) {
    const selectedText: DocumentFragment = range.extractContents();
    range.insertNode(selectedText);
    const mark = PlaceholderTool.create(selectedText?.textContent?.trim());
    range.insertNode(mark);
    this.api.selection.expandToTag(mark);
  }

  unwrap(range) {
    const me = this.api.selection.findParentTag(PlaceholderTool.tag, PlaceholderTool.class);
    if (me) me.remove();
  }

  renderActions() {
    this.frame = document.createElement('div');
    this.frame.id = PlaceholderTool.frame;
    this.frame.style.cssText = 'margin-bottom: 16px; margin-left: 16px; margin-right: 16px; min-width: 300px';

    this.placeholder.element = toggleVisibility(document.createElement('input'), false);
    this.placeholder.element.id = 'placeholder-input';
    this.placeholder.element.className = this.api.styles.input;
    this.frame.appendChild(this.placeholder.element);

    this.format.element = toggleVisibility(document.createElement('input'), false);
    this.format.element.id = 'format-input';
    this.format.element.className = this.api.styles.input;
    this.frame.appendChild(this.format.element);

    return toggleVisibility(this.frame, false);
  }

  showActions(mark) {
    const whitelist: Option[] =
      this.placeholders$.getValue()?.map(({ name, schluessel, art, format, _search }) => ({ label: name, value: schluessel, art, format, optionen: [], searchBy: _search })) || [];
    let { id, placeholder, pipe, defaultFormat, format } = PlaceholderTool.get(mark);
    let currentPlaceholder = whitelist.find(({ value }) => value === placeholder) || whitelist[0];
    let currentFormat = toOption(checkUndefined(format, defaultFormat));

    this.format = init(
      this.format,
      id + '-format',
      currentFormat,
      (value) => {
        const last = currentFormat?.value;
        currentFormat = parse(value)?.shift() || toOption(defaultFormat);
        if (currentFormat?.value === last) return;
        mark.setAttribute('data-format', currentFormat?.value);
        mark = PlaceholderTool.update(mark);
        setValue(this.format, currentFormat);
      },
      getFormattedDefaults(pipe),
      false,
    );
    this.format.tagify = toggleVisibilityTagify(this.format.tagify, !!pipe);

    this.placeholder = init(
      this.placeholder,
      id + '-placeholder',
      currentPlaceholder,
      (value) => {
        const last = currentPlaceholder?.value;
        currentPlaceholder = parse(value)?.shift() || currentPlaceholder;
        if (currentPlaceholder?.value === last) return;
        mark.setAttribute('data-placeholder', currentPlaceholder?.value);
        switch (currentPlaceholder.art) {
          case 'zahl':
          case 'datum':
            pipe = currentPlaceholder.art;
            format = currentPlaceholder.format;
            defaultFormat = currentPlaceholder.format;
            break;
          default:
            pipe = undefined;
            format = undefined;
            defaultFormat = undefined;
        }
        mark.setAttribute('data-pipe', pipe);
        mark.setAttribute('data-default-format', defaultFormat);
        mark.setAttribute('data-format', format);
        mark = PlaceholderTool.update(mark);
        currentFormat = toOption(format);
        if (pipe) this.format = show(this.format, currentFormat, getFormattedDefaults(pipe));
        else this.format.tagify = toggleVisibilityTagify(this.format.tagify, false);
      },
      whitelist,
      true,
    );

    this.frame = toggleVisibility(this.frame, true);
  }

  hideActions() {
    [this.placeholder, this.format] = [this.placeholder, this.format].map(destroy);
    this.frame = toggleVisibility(this.frame, false);
  }

  clear() {
    this.hideActions();
  }
}
