import { NgModule } from '@angular/core';

import {
  DeleteRechnungsVorlageCommandServiceModule,
  DeleteRechnungsVorlageCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteRechnungsVorlageCommandServiceModule,
    DeleteRechnungsVorlageCommandComponentModule
  ],
  exports: [
    DeleteRechnungsVorlageCommandServiceModule,
    DeleteRechnungsVorlageCommandComponentModule
  ]
})
export class DeleteRechnungsVorlageCommandModule { }
