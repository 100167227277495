import { Injectable } from '@angular/core';

import { ISollIstRequest, ISollIstResponse } from 'fa-kt.types';
import { DetailQueryService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class SollIstService extends DetailQueryService<ISollIstRequest, ISollIstResponse, {}> {
  static instance: SollIstService;

  route = 'auswertungen/soll-ist';
  override dependencies = [
    'finanzen/rechnung-erstellt',
    'finanzen/rechnung-geloescht',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'objekte/objekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert',
  ];

  constructor() {
    super();
    if (!SollIstService.instance) {
      SollIstService.instance = this;
    }
    return SollIstService.instance;
  }
}
