import { Component, Inject } from '@angular/core';
import { ISitemap, MetaService, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { combineLatest, Subscription } from 'rxjs';

import { IZeitenResponse, ZeitenQueryFilterConfig } from 'fa-kt.types';
import { ProjekteSelectService } from '../../../../projekte';
import { ZeitenService } from '../service';

@Component({
  selector: 'fa-kt-zeiten-filter',
  templateUrl: './zeiten-filter.component.html',
  styleUrls: ['./zeiten-filter.component.css'],
})
export class ZeitenFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public ZeitenFilterConfig: IFilterConfig<IZeitenResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public meta: MetaService,
    public zeiten: ZeitenService,
    public projectSelection: ProjekteSelectService,
  ) {
    this.ZeitenFilterConfig = ZeitenQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([this.zeiten.shapes$, this.meta.users$]).subscribe(([shapes, selection]) => {
        if (!shapes) return;
        this.shape('zeit.mitarbeiter', selection);
      }),
      combineLatest([this.zeiten.shapes$, this.projectSelection.selection$]).subscribe(([shapes, selection]) => {
        if (!shapes) return;
        this.shape('zeit.projekt', selection);
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.ZeitenFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.ZeitenFilterConfig[index].selections = shapes;
  }
}
