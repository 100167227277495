import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IVorlagenRequest, IVorlagenResponse } from 'fa-kt.types';
import { VorlagenService } from '../../querys';

export interface IVorlagenPageResolverResult {
  vorlagen?: IVorlagenResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class VorlagenPageResolver implements Resolve<IVorlagenPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private vorlagen: VorlagenService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IVorlagenPageResolverResult> {
    this.loading.resolving();
    const requests = {
      vorlagen: {} as IVorlagenRequest,
    };

    const log = (error: any) => console.error(error);
    const [vorlagen] = await Promise.all([this.vorlagen.request(requests.vorlagen).catch(log)]);
    this.loading.resolved();
    return {
      vorlagen,
    };
  }
}
