import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISelection } from 'pbc.types';

import { IProjektQueryShapes, IProjektRequest, IProjektResponse } from 'fa-kt.types';
import { DetailQueryService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class ProjektService extends DetailQueryService<IProjektRequest, IProjektResponse, IProjektQueryShapes> {
  route = 'projekte/projekt';
  override dependencies = [
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',

    'finanzen/rechnung-erstellt',
    'finanzen/rechnung-geloescht',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'personen/mitarbeiter-aktualisiert',
    'projekte/projekt-archiviert',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert',
  ];
  static instance: ProjektService;

  constructor() {
    super();
    if (!ProjektService.instance) ProjektService.instance = this;
    return ProjektService.instance;
  }

  getProjektAbteilung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.abteilung']?.find((v) => v.value === value);
  }

  get projektAbteilung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.abteilung'] as ISelection[]) : [])));
  }

  getRechnungenBesichtigungen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['rechnungen.besichtigungen']?.find((v) => v.value === value);
  }

  get rechnungenBesichtigungen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['rechnungen.besichtigungen'] as ISelection[]) : [])));
  }

  getRechnungenExtraVereinbarungs(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['rechnungen.extraVereinbarungs']?.find((v) => v.value === value);
  }

  get rechnungenExtraVereinbarungs(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['rechnungen.extraVereinbarungs'] as ISelection[]) : [])));
  }

  getProjektFond(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.fond']?.find((v) => v.value === value);
  }

  get projektFond(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.fond'] as ISelection[]) : [])));
  }

  getRechnungenHonorarVereinbarung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['rechnungen.honorarVereinbarung']?.find((v) => v.value === value);
  }

  get rechnungenHonorarVereinbarung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['rechnungen.honorarVereinbarung'] as ISelection[]) : [])));
  }

  getProjektKunde(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.kunde']?.find((v) => v.value === value);
  }

  get projektKunde(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.kunde'] as ISelection[]) : [])));
  }

  getProjektBank(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.bank']?.find((v) => v.value === value);
  }

  get projektBank(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.bank'] as ISelection[]) : [])));
  }

  getKundeKundenArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenArt']?.find((v) => v.value === value);
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenArt'] as ISelection[]) : [])));
  }

  getKundeKundenGruppe(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenGruppe']?.find((v) => v.value === value);
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenGruppe'] as ISelection[]) : [])));
  }

  getProjektBesichtigungen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.besichtigungen']?.find((v) => v.value === value);
  }

  get projektBesichtigungen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.besichtigungen'] as ISelection[]) : [])));
  }

  getProjektGutachten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.gutachten']?.find((v) => v.value === value);
  }

  get projektGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.gutachten'] as ISelection[]) : [])));
  }

  getProjektPruefung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.pruefung']?.find((v) => v.value === value);
  }

  get projektPruefung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.pruefung'] as ISelection[]) : [])));
  }

  getVermerkeVerfasser(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vermerke.verfasser']?.find((v) => v.value === value);
  }

  get vermerkeVerfasser(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vermerke.verfasser'] as ISelection[]) : [])));
  }

  getErinnerungenMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerungen.mitarbeiter']?.find((v) => v.value === value);
  }

  get erinnerungenMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerungen.mitarbeiter'] as ISelection[]) : [])));
  }

  getErinnerungenVerfasser(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerungen.verfasser']?.find((v) => v.value === value);
  }

  get erinnerungenVerfasser(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerungen.verfasser'] as ISelection[]) : [])));
  }

  getProjektProjektArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.projektArt']?.find((v) => v.value === value);
  }

  get projektProjektArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.projektArt'] as ISelection[]) : [])));
  }

  getProjektProjektStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.projektStatus']?.find((v) => v.value === value);
  }

  get projektProjektStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.projektStatus'] as ISelection[]) : [])));
  }

  getZahlungseingaengeRechnung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zahlungseingaenge.rechnung']?.find((v) => v.value === value);
  }

  get zahlungseingaengeRechnung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zahlungseingaenge.rechnung'] as ISelection[]) : [])));
  }

  getProjektStandort(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.standort']?.find((v) => v.value === value);
  }

  get projektStandort(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.standort'] as ISelection[]) : [])));
  }
}
