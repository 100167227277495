import { ArrayReferenceFilterConfig, IArrayReferenceFilter, IFilter, IFilterConfig, ISelection, IShape, ISingleReferenceFilter, SingleReferenceFilterConfig } from 'pbc.types';

import { IBewertungsAnlass, IKunde, IKundenArt, IKundenGruppe, IObjektArt, IVorlage } from 'fa-kt.types';
import { IVorlageResponse } from '.';

export interface IVorlageQueryFilter extends IFilter<IVorlageResponse> {
  'vorlage.fuerKunden'?: IArrayReferenceFilter<IKunde>;
  'vorlage.fuerKundenGruppen'?: IArrayReferenceFilter<IKundenGruppe>;
  'vorlage.fuerKundenArten'?: IArrayReferenceFilter<IKundenArt>;
  'vorlage.fuerObjektArten'?: IArrayReferenceFilter<IObjektArt>;
  'vorlage.fuerBewertungsAnlaesse'?: IArrayReferenceFilter<IBewertungsAnlass>;
  'abschnitte.vorlage'?: ISingleReferenceFilter<IVorlage>;
}

export const VorlageQueryFilterConfig: IFilterConfig<IVorlageResponse> = [
  ArrayReferenceFilterConfig<IKunde>('vorlage.fuerKunden', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN.title'),
  ArrayReferenceFilterConfig<IKundenGruppe>('vorlage.fuerKundenGruppen', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN_GRUPPEN.title'),
  ArrayReferenceFilterConfig<IKundenArt>('vorlage.fuerKundenArten', 'TEXTE.Models.VORLAGE.Propertys.FUER_KUNDEN_ARTEN.title'),
  ArrayReferenceFilterConfig<IObjektArt>('vorlage.fuerObjektArten', 'TEXTE.Models.VORLAGE.Propertys.FUER_OBJEKT_ARTEN.title'),
  ArrayReferenceFilterConfig<IBewertungsAnlass>('vorlage.fuerBewertungsAnlaesse', 'TEXTE.Models.VORLAGE.Propertys.FUER_BEWERTUNGS_ANLAESSE.title'),
  SingleReferenceFilterConfig<IVorlage>('abschnitte.vorlage', 'TEXTE.Models.ABSCHNITT.Propertys.VORLAGE.title'),
];

export interface IVorlageQueryShapes extends IShape {
  'vorlage.fuerKunden': ISelection[];
  'vorlage.fuerKundenGruppen': ISelection[];
  'vorlage.fuerKundenArten': ISelection[];
  'vorlage.fuerObjektArten': ISelection[];
  'vorlage.fuerBewertungsAnlaesse': ISelection[];
  'abschnitte.vorlage': ISelection[];
}

export type IVorlageQueryField =
  | 'vorlage.id'
  | 'vorlage.name'
  | 'vorlage.initialen'
  | 'vorlage.fuerKunden'
  | 'vorlage.fuerKundenGruppen'
  | 'vorlage.fuerKundenArten'
  | 'vorlage.fuerObjektArten'
  | 'vorlage.fuerBewertungsAnlaesse'
  | 'vorlage.angewendet'
  | 'abschnitte.id'
  | 'abschnitte.vorlage'
  | 'abschnitte.platzhalter'
  | 'abschnitte.name'
  | 'abschnitte.text'
  | '';
