import { Component } from '@angular/core';
import { ReisekostenabrechnungService } from '../service';

@Component({
  selector: 'fa-kt-saved-reisekostenabrechnung-filters',
  templateUrl: './saved-reisekostenabrechnung-filters.component.html',
  styleUrls: ['./saved-reisekostenabrechnung-filters.component.css'],
})
export class SavedReisekostenabrechnungFiltersComponent {
  constructor(public reisekostenabrechnung: ReisekostenabrechnungService) {}
}
