import { NgModule } from '@angular/core';

import { BewertungsAnlasssSettingsModule, GutachtenStatiSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [BewertungsAnlasssSettingsModule, GutachtenStatiSettingsModule],
  exports: [BewertungsAnlasssSettingsModule, GutachtenStatiSettingsModule],
})
export class GutachtenSettingsModule {}
