import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostImportCommandShapes, IPostImportRequest, IPostImportResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostImportCommandService extends PostCommandService<IPostImportRequest, IPostImportResponse, IPostImportCommandShapes> {
  readonly route = 'projekte/import';

  override async request(payload: IPostImportRequest): Promise<IPostImportResponse> {
    return (await this.http.upload<IPostImportResponse>(this.route, payload, 'imports')) as unknown as IPostImportResponse;
  }
}
