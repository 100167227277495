import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { UrlaubeQuerySorterConfig } from 'fa-kt.types';
import { UrlaubeService } from '.';

@Component({
  selector: 'fa-kt-urlaube-sorter',
  template: `<pbc-sorter [config]="UrlaubeSorterConfig" [sorter]="urlaube.sorter$ | async" (sorterChange)="urlaube.sorter$.next($event)"></pbc-sorter>`,
})
export class UrlaubeSorterComponent {
  public UrlaubeSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public urlaube: UrlaubeService,
  ) {
    this.UrlaubeSorterConfig = UrlaubeQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }
}
