import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IProjektArt } from 'fa-kt.types';
import { PostProjektArtCommandService } from '../../commands';
import { ProjektArtsService } from '../service';

@Component({
  selector: 'fa-kt-projekt-arts-list',
  templateUrl: './projekt-arts-list.component.html',
  styleUrls: ['./projekt-arts-list.component.css'],
})
export class ProjektArtsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IProjektArt> = new EventEmitter<IProjektArt>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public projektArts: ProjektArtsService,
    public projektArtPost: PostProjektArtCommandService,
  ) {
    this.sortable$ = this.projektArts.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const projektArts = this.projektArts.result$.getValue();
    if (projektArts && projektArts.projektArts) {
      if ($event) {
        moveItemInArray(projektArts.projektArts, $event.previousIndex, $event.currentIndex);
      }
      projektArts.projektArts = projektArts.projektArts.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.projektArtPost.request({ projektArt: projektArts.projektArts });
    }
  }
}
