import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteGutachtenStatusCommandServiceModule } from '..';
import { DeleteGutachtenStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteGutachtenStatusCommandServiceModule
  ],
  declarations: [DeleteGutachtenStatusCommandComponent],
  exports: [DeleteGutachtenStatusCommandComponent],

})
export class DeleteGutachtenStatusCommandComponentModule { }
