import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { ITemplatesResponse } from '.';

export interface ITemplatesQueryFilter extends IFilter<ITemplatesResponse> {}

export const TemplatesQueryFilterConfig: IFilterConfig<ITemplatesResponse> = [];

export interface ITemplatesQueryShapes extends IShape {}

export type ITemplatesQueryField = 'templates.id' | 'templates.datei' | 'templates.name' | 'templates.kommentar' | '';
