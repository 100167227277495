import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IDurchsuchenQueryFilter, IDurchsuchenQueryShapes, IDurchsuchenQuerySorter, IDurchsuchenRequest, IDurchsuchenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DurchsuchenService extends ListQueryService<IDurchsuchenRequest, IDurchsuchenResponse, IDurchsuchenQueryShapes, IDurchsuchenQueryFilter, IDurchsuchenQuerySorter> {
  contentKey: keyof IDurchsuchenResponse = 'durchsuchen';
  route = 'markt/durchsuchen';
  override dependencies = [
    'geo/written-bereich',
    'geo/removed-bereich',
    'markt/written-herausgeber',
    'markt/removed-herausgeber',
    'markt/bericht-entfernt',
    'markt/bericht-gespeichert',
    'markt/bericht-hochgeladen',
    'markt/zeile-korrigiert',
  ];

  getZeileBereiche(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeile.bereiche']?.find((v) => v.value === value);
  }

  get zeileBereiche(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeile.bereiche'] as ISelection[]) : [])));
  }
}
