import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IRechnungSorter extends ISorter {
  id?: ISorterExpression;
  projekt?: ISorterExpression;
  kunde?: ISorterExpression;
  bezeichnung?: ISorterExpression;
  rechnungZaehler?: ISorterExpression;
  datum?: ISorterExpression;
  honorarVereinbarung?: ISorterExpression;
  extraVereinbarungs?: ISorterExpression;
  stunden?: ISorterExpression;
  anschlaege?: ISorterExpression;
  seiten?: ISorterExpression;
  fotos?: ISorterExpression;
  strecke?: ISorterExpression;
  fahrtzeit?: ISorterExpression;
  besichtigungen?: ISorterExpression;
  mwstSatz?: ISorterExpression;
  nachlass?: ISorterExpression;
  summe?: ISorterExpression;
  datei?: ISorterExpression;
}

export const RechnungSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('bezeichnung', 'Bezeichnung'),
  SorterConfig('rechnungZaehler', 'Rechnung Zaehler'),
  SorterConfig('datum', 'Datum'),
  SorterConfig('honorarVereinbarung', 'Honorar Vereinbarung'),
  SorterConfig('extraVereinbarungs', 'Extra Vereinbarungs'),
  SorterConfig('stunden', 'Stunden'),
  SorterConfig('anschlaege', 'Anschlaege'),
  SorterConfig('seiten', 'Seiten'),
  SorterConfig('fotos', 'Fotos'),
  SorterConfig('strecke', 'Strecke'),
  SorterConfig('fahrtzeit', 'Fahrtzeit'),
  SorterConfig('besichtigungen', 'Besichtigungen'),
  SorterConfig('mwstSatz', 'Mwst Satz'),
  SorterConfig('nachlass', 'Nachlass'),
  SorterConfig('summe', 'Summe'),
  SorterConfig('datei', 'Datei')
];
