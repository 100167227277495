import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IStandortsQueryFilter, IStandortsQueryShapes, IStandortsQuerySorter, IStandortsRequest, IStandortsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class StandortsService extends ListQueryService<IStandortsRequest, IStandortsResponse, IStandortsQueryShapes, IStandortsQueryFilter, IStandortsQuerySorter> {
  contentKey: keyof IStandortsResponse = 'standorts';
  route = 'personen/settings/standorts';
  override dependencies = ['personen/written-standort', 'personen/removed-standort'];

  static instance: StandortsService;

  constructor() {
    super();
    if (!StandortsService.instance) StandortsService.instance = this;
    return StandortsService.instance;
  }
}
