import { NgModule } from '@angular/core';

import { DeleteRolleCommandModule, PostRolleCommandModule, RollesQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostRolleCommandModule, DeleteRolleCommandModule, RollesQueryModule],
  exports: [PostRolleCommandModule, DeleteRolleCommandModule, RollesQueryModule],
})
export class RollesSettingsModule {}
