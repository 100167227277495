import { NgModule } from '@angular/core';

import {
  PostOrtKategorieCommandServiceModule,
  PostOrtKategorieCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostOrtKategorieCommandServiceModule,
    PostOrtKategorieCommandComponentModule
  ],
  exports: [
    PostOrtKategorieCommandServiceModule,
    PostOrtKategorieCommandComponentModule
  ]
})
export class PostOrtKategorieCommandModule { }
