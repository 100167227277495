<nz-layout>
  <!--- << app    -->
  <nz-sider nzTheme="light" nzCollapsible [(nzCollapsed)]="isCollapsed" nzWidth="264px" style="position: fixed; height: 100vh; z-index: 112">
    <img src="assets/fakt.png" class="logo" (click)="actions.open()" alt="FA-KT Logo" style="cursor: pointer" />
    <ul nz-menu nzTheme="light" nzMode="inline">
      <ng-container *ngFor="let section of $menu | async" [ngSwitch]="section.children.length">
        <li *ngSwitchCase="0" nz-menu-item>
          <ng-container [ngSwitch]="section.url && section.url.length > 0">
            <a title *ngSwitchCase="true" [routerLink]="section.url" nz-tooltip [nzTooltipTitle]="section.title" nzTooltipPlacement="right" nzTooltipColor="#004D7B" nzTooltipMouseEnterDelay="0.75">
              {{ section.emoji }}
              <span nz-typography *ngIf="!isCollapsed">{{ section.title }}</span>
            </a>
            <span title *ngSwitchDefault>
              {{ section.emoji }}
              <span nz-typography *ngIf="!isCollapsed">{{ section.title }}</span>
            </span>
          </ng-container>
        </li>
        <li *ngSwitchDefault nz-submenu [nzOpen]="!isCollapsed">
          <span title>
            {{ section.emoji }}
            <span nz-typography *ngIf="!isCollapsed">{{ section.title }}</span>
          </span>
          <ul>
            <li *ngFor="let entry of section.children" nz-menu-item [routerLink]="entry.url">
              <span title>
                {{ entry.emoji }}
                <span nz-typography>{{ entry.title }}</span>
              </span>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout (document:keydown.control.space)="actions.open()">
    <nz-header
      [style.background]="'#fff'"
      style="position: fixed; height: 64px; left: 264px; z-index: 111"
      [style.width]="isCollapsed ? 'calc(100vw - 80px)' : 'calc(100vw - 264px)'"
      [style.left]="isCollapsed ? '80px' : '264px'"
    >
      <ul nz-menu nzTheme="light" nzMode="horizontal">
        <li nz-menu-item (click)="actions.open()">
          <i nz-icon nzType="thunderbolt" nzTheme="outline"></i>
        </li>
        <li nz-menu-item style="float: right; margin-right: 16px">
          <pbc-user-popup-menu></pbc-user-popup-menu>
        </li>

        <li nz-menu-item style="float: right" *ngIf="!!(auth.$email | async)" nz-popover [nzPopoverContent]="TimeMenu" [(nzPopoverVisible)]="timeMenu" [nzPopoverTrigger]="'click'">
          <span nz-typography *ngIf="!!(tag$ | async)?.zugeordnet">
            <i nz-icon nzType="check-circle" nzTheme="outline"></i>
            Abgeschlossen
          </span>
          <ng-container *ngIf="!!(tag$ | async)?.id && !(tag$ | async)?.zugeordnet" [ngSwitch]="pausiert$ | async">
            <span nz-typography *ngSwitchCase="true">
              <i nz-icon nzType="rest" nzTheme="outline"></i>
              {{ pausing$ | async }}
            </span>

            <span nz-typography *ngSwitchCase="false">
              <i nz-icon nzType="field-time" nzTheme="outline"></i>
              {{ working$ | async }}
            </span>
          </ng-container>
          <span nz-icon nzType="more" nzTheme="outline"></span>

          <ng-template #TimeMenu>
            <ul nz-menu nzTheme="light" nzMode="inline">
              <li nz-menu-item [nzDisabled]="true">
                <h3 nz-title style="padding: 82px">
                  Zeiterfassung
                  <nz-tag
                    *ngIf="!(heute.response$ | async)?.minuten?.konto"
                    style="margin-left: 16px; margin-right: 16px"
                    [nzBordered]="false"
                    nzColor="orange"
                    nz-tooltip
                    nzTooltipTitle="Zeitkonto noch nicht berechnet. Tag beginnen, Urlaub einreichen, etc. zum berechnen."
                  >
                    -
                  </nz-tag>
                  <nz-tag
                    *ngIf="(heute.response$ | async)?.minuten?.konto"
                    style="margin-left: 16px; margin-right: 16px"
                    [nzBordered]="false"
                    [nzColor]="(heute.response$ | async)?.minuten?.['drüber'] > 0 ? 'red' : (heute.response$ | async)?.minuten?.['drunter'] < 0 ? 'red' : 'green'"
                    nz-tooltip
                    [nzTooltipTitle]="Konto"
                    [nzTooltipColor]="(heute.response$ | async)?.minuten?.['drüber'] > 0 ? 'red' : (heute.response$ | async)?.minuten?.['drunter'] < 0 ? 'red' : 'green'"
                  >
                    {{ ((heute.response$ | async).minuten?.konto || 0) > 0 ? '' : '-' }}{{ (heute.response$ | async).minuten?.konto | formatHours: true }} auf dem Konto
                  </nz-tag>
                  <ng-template #Konto>
                    <span>
                      {{ (heute.response$ | async)?.minuten?.erwartet || 0 | formatHours }} erwartet<br />
                      - {{ (heute.response$ | async)?.minuten?.gearbeitet || 0 | formatHours }} eingetragen <br />
                      {{ ((heute.response$ | async).minuten?.konto || 0) > 0 ? '-' : '+' }} {{ (heute.response$ | async).minuten?.rahmen | formatHours: true }}
                      {{ ((heute.response$ | async).minuten?.konto || 0) > 0 ? 'Über' : 'Unter' }}stunden-Rahmen <br />
                      <span *ngIf="(heute.response$ | async)?.minuten?.['drüber']">
                        = {{ ((heute.response$ | async)?.minuten['drüber'] < 0 ? (heute.response$ | async)?.minuten['drüber'] * -1 : (heute.response$ | async)?.minuten['drüber']) | formatHours }}
                        {{ (heute.response$ | async)?.minuten['drüber'] > 0 ? 'zu viel gearbeitet' : 'bis Überschreitung' }}
                      </span>
                      <span *ngIf="(heute.response$ | async)?.minuten?.drunter">
                        = {{ ((heute.response$ | async)?.minuten?.drunter < 0 ? (heute.response$ | async)?.minuten?.drunter * -1 : (heute.response$ | async)?.minuten?.drunter) | formatHours }}
                        {{ (heute.response$ | async)?.minuten?.drunter < 0 ? 'zu wenig gearbeitet' : 'bis Unterschreitung' }}
                      </span>
                    </span>
                  </ng-template>
                  <nz-date-picker [ngModel]="date" (ngModelChange)="selectedDate($event)" nzFormat="dd.MM.yyyy"></nz-date-picker>
                </h3>
              </li>
              <ng-container *ngIf="!(tag$ | async)?.id || !(tag$ | async)?.zugeordnet">
                <li nz-menu-divider></li>
                <li nz-menu-item (click)="start(true)">
                  <i nz-icon nzType="hourglass" nzTheme="outline"></i>
                  Zeit erfassen
                </li>
              </ng-container>
              <li nz-menu-divider *ngIf="((heute.response$ | async)?.zeiten || [])?.length"></li>
              <li nz-menu-item *ngFor="let entry of (heute.response$ | async)?.zeiten || []" (click)="zeit$.next(entry.zeit.id)">
                <span *ngIf="entry.zeit.stunden">{{ entry.zeit.stunden || 0 }}h</span><span *ngIf="entry.zeit.minuten">{{ entry.zeit.minuten || 0 }}m</span>
                <nz-divider nzType="vertical"></nz-divider>
                {{ entry.zeit.datum | date: 'HH:mm' }}
                <nz-divider nzType="vertical"></nz-divider>
                <span nz-typography nzType="secondary" *ngIf="!entry.zeit.zeitUnterart">{{ entry.zeit.zeitArt | type: (postZeit.zeitZeitArt | async) }}</span>
                <span nz-typography nzType="secondary" *ngIf="entry.zeit.zeitUnterart">{{ entry.zeit.zeitUnterart | type: (postZeit.zeitZeitUnterart | async) }}</span>
                <nz-divider nzType="vertical"></nz-divider>
                <span nz-typography nzType="secondary">{{ entry.zeit.projekt | type: (projekteSelect.selection$ | async) }}</span>
              </li>
            </ul>
          </ng-template>
          <fa-kt-zeit [id]="zeit$ | async" (changed)="zeit$.next($event)" [style.width]="'100%'"></fa-kt-zeit>
        </li>

        <li nz-menu-item style="float: right; margin-right: -26px; padding-left: 12px" *ngIf="!!(auth?.$email | async) && !(tag$ | async)?.id" noDoubleClick (tClick)="start(!isToday)">
          <button nz-button nzType="link" [nzLoading]="loading$ | async" style="width: 100%">
            <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
            Tag {{ isToday ? 'beginnen' : 'erfassen' }}
          </button>
        </li>

        <li nz-menu-item style="float: right; margin-right: -36px; padding-left: 12px" *ngIf="!!(tag$ | async)?.id && !(tag$ | async)?.zugeordnet" noDoubleClick (tClick)="assign()">
          <button nz-button nzType="link" [nzLoading]="loading$ | async">
            <i nz-icon nzType="hourglass" nzTheme="outline"></i>
            Tag zuordnen
          </button>
        </li>

        <li
          nz-menu-item
          style="float: right; margin-right: -36px; padding-left: 12px"
          *ngIf="!!(tag$ | async)?.id && !(tag$ | async)?.zugeordnet && (pausiert$ | async)"
          noDoubleClick
          (tClick)="resume()"
        >
          <button nz-button nzType="link" [nzLoading]="loading$ | async">
            <i nz-icon nzType="field-time" nzTheme="outline"></i>
            Fortsetzen
          </button>
        </li>

        <li
          nz-menu-item
          style="float: right; margin-right: -36px; padding-left: 12px"
          *ngIf="!!(tag$ | async)?.id && !(tag$ | async)?.zugeordnet && !(pausiert$ | async)"
          noDoubleClick
          (tClick)="pause()"
        >
          <button nz-button nzType="link" [nzLoading]="loading$ | async">
            <i nz-icon nzType="rest" nzTheme="outline"></i>
            Pausieren
          </button>
        </li>

        <li
          *ngIf="offlineCache.http.$offline | async"
          style="float: right; margin-right: 16px"
          nz-menu-item
          nz-tooltip
          nzTooltipTitle="Das Gerät ist offline"
          nzTooltipPlacement="left"
          nzTooltipColor="blue"
        >
          <i nz-icon nzType="disconnect" nzTheme="outline"></i>
        </li>
      </ul>
    </nz-header>
    <nz-content class="outer-content" style="margin-top: 64px" [style.marginLeft]="isCollapsed ? '80px' : '264px'">
      <nz-layout class="inner-layout">
        <nz-content class="inner-content">
          <div *ngIf="resolverLoader.$resolving | async">
            <pbc-loading-spinner></pbc-loading-spinner>
          </div>
          <router-outlet></router-outlet>
        </nz-content>
      </nz-layout>
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-modal [(nzVisible)]="zuordnung" [nzTitle]="Zuordnung" (nzOnCancel)="cancel()" nzWidth="612">
  <ng-template #Zuordnung>
    <h3 nz-title>
      <nz-row>
        <nz-col nzSpan="7">Tages-Zuordnung</nz-col>
        <nz-col nzSpan="3">
          <nz-tag nzColor="blue" [nzBordered]="false">{{ (tag$ | async)?.start | date: 'dd.MM.yyyy' }}</nz-tag>
        </nz-col>
        <nz-col>
          <nz-input-group style="margin-right: 12px">
            <nz-input-group style="width: 150px" nzPrefix="Start">
              <nz-time-picker
                [disabled]="(tag$ | async)?.zugeordnet"
                nzBorderless
                [nzAllowEmpty]="false"
                nzFormat="HH:mm"
                [nzMinuteStep]="15"
                [ngModel]="(tag$ | async)?.start"
                (ngModelChange)="setStart($event)"
              ></nz-time-picker>
            </nz-input-group>
            <nz-input-group style="width: 150px" nzPrefix="Ende">
              <nz-time-picker
                [disabled]="(tag$ | async)?.zugeordnet"
                nzBorderless
                [nzAllowEmpty]="false"
                nzFormat="HH:mm"
                [nzMinuteStep]="15"
                [ngModel]="(tag$ | async)?.ende"
                (ngModelChange)="setEnde($event)"
              ></nz-time-picker>
            </nz-input-group>
          </nz-input-group>
        </nz-col>
        <nz-col nzSpan="3">
          <nz-tag [nzColor]="(stundenProTag$ | async) - ((tag$ | async)?.minuten?.arbeit || 0) > 0 ? 'orange' : 'green'" [nzBordered]="false"
            >{{ (tag$ | async)?.minuten?.arbeit || 0 | formatHours }} von {{ stundenProTag$ | async }} h</nz-tag
          >
        </nz-col>
      </nz-row>
    </h3>
  </ng-template>
  <ng-container *nzModalContent>
    <nz-spin style="width: 100%" [nzSpinning]="loading$ | async">
      <nz-row *ngIf="!(tag$ | async)?.zugeordnet">
        <nz-col nzSpan="24">
          <h5 nz-title style="display: inline-block">Besichtigungen</h5>
          <span style="display: inline-block">
            <nz-button-group *ngFor="let besichtigung of besichtigungen$ | async; index as index" style="margin-right: 12px; margin-left: 12px; margin-bottom: 4px">
              <button nz-button (click)="addBesichtigung(besichtigung)">
                {{ besichtigung.fahrtVon | date: 'HH:mm' }} - {{ besichtigung.bis | date: 'HH:mm' }} {{ besichtigung.start?.strasse }}, {{ besichtigung.start?.plz }} {{ ' ⇥ ' }}
                {{ besichtigung.ende?.strasse }}, {{ besichtigung.ende?.plz }}: {{ formatRange(besichtigung.fahrtVon, besichtigung.bis) }}
                <i nz-icon nzType="plus-circle" nzShape="circle" nzTheme="outline"></i>
              </button>
            </nz-button-group>
          </span>
          <br />
          <br />
        </nz-col>
      </nz-row>
      <nz-row style="height: 650px">
        <nz-col nzSpan="12">
          <ngx-charts-bar-vertical-stacked
            [results]="assignments$ | async"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [yAxisTickFormatting]="yAxisTickFormatting"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [animations]="animations"
            [customColors]="zeitColors"
            (select)="onSelect($event)"
          >
            <ng-template #tooltipTemplate let-model="model">
              <h4 nz-title style="color: white">{{ formatMinutes(model.value) }}</h4>
              <p nz-typography style="color: white">{{ model.name }}</p>
            </ng-template>
          </ngx-charts-bar-vertical-stacked>
        </nz-col>
        <nz-col nzSpan="12">
          <nz-row>
            <nz-col nzSpan="1"><span nz-typography style="line-height: 34px">🕰️</span></nz-col>
            <nz-col nzSpan="23">
              <pbc-select-value [(ngModel)]="zeitArt" [shapes]="zeitArten$ | async" placeholder="Art" [required]="true" (valueChange)="setUnterartByArt($event)"></pbc-select-value>
            </nz-col>
            <nz-col nzSpan="1"><span nz-typography style="line-height: 34px">⏱️</span></nz-col>
            <nz-col nzSpan="23"
              ><pbc-select-value
                [ngModel]="zeitUnterart$ | async"
                (ngModelChange)="zeitUnterart$.next($event)"
                [filter]="zeitArt || '*'"
                [shapes]="(postZeit.shapes$ | async)?.['zeit.zeitUnterart']"
                placeholder="Aktivität"
                [required]="true"
              ></pbc-select-value>
            </nz-col>
          </nz-row>

          <br />

          <nz-row *ngIf="zeitArt === 1 || zeitArt === '1' || hasKundenEintragung(zeitUnterart)">
            <nz-col *ngIf="zeitArt === 1 || zeitArt === '1'" nzSpan="1"><span nz-typography style="line-height: 34px">📖</span></nz-col>
            <nz-col *ngIf="zeitArt === 1 || zeitArt === '1'" nzSpan="23">
              <pbc-select-value [(ngModel)]="projekt" [filter]="kunde || '*'" [shapes]="projekte$ | async" placeholder="Projekt" (valueChange)="setKundeByProjekt($event)"></pbc-select-value>
            </nz-col>
            <nz-col nzSpan="1"><span nz-typography style="line-height: 34px">👔</span></nz-col>
            <nz-col nzSpan="23"><pbc-select-value [(ngModel)]="kunde" [shapes]="(postZeit.shapes$ | async)?.['zeit.kunde']" placeholder="Kunde"></pbc-select-value> </nz-col>
          </nz-row>

          <br />

          <nz-row>
            <nz-col nzSpan="1"><span nz-typography style="line-height: 34px">📝</span></nz-col>
            <nz-col nzSpan="23">
              <textarea rows="2" [(ngModel)]="kommentar" placeholder="Kommentar" nz-input></textarea>
            </nz-col>
          </nz-row>

          <br />

          <nz-row>
            <nz-col nzSpan="12">
              <ul nz-menu>
                <li nz-menu-item *ngFor="let time of selectsByMinuten" (click)="addZeit(time.minuten)" [nzDisabled]="time.disabled">
                  <span nz-icon nzType="plus-square" nzTheme="outline"></span>
                  {{ time.name }}
                </li>
                <li nz-menu-item>
                  <button nz-button (click)="addZeit(this.rest)" [disabled]="rest === 0">
                    <span nz-icon nzType="plus-square" nzTheme="outline"></span>
                    Als Rest
                  </button>
                </li>
              </ul>
            </nz-col>
            <nz-col nzSpan="12">
              <ul nz-menu>
                <li nz-menu-item *ngFor="let time of selectsByStunden" (click)="addZeit(time.minuten)" [nzDisabled]="time.disabled">
                  <span nz-icon nzType="plus-square" nzTheme="outline"></span>
                  {{ time.name }}
                </li>
                <li nz-menu-item>
                  <nz-input-group [nzAddOnAfter]="AddCustom" [nzCompact]="true">
                    <nz-input-group style="width: 50%" nzSuffix="S" [nzCompact]="true">
                      <input nz-input type="number" [(ngModel)]="addStunden" placeholder="Stunden" max="23" min="0" [disabled]="rest === 0" />
                    </nz-input-group>
                    <nz-input-group style="width: 50%" nzSuffix="M" [nzCompact]="true">
                      <input nz-input type="number" [(ngModel)]="addMinuten" placeholder="Minuten" max="59" min="0" step="5" [disabled]="rest === 0" />
                    </nz-input-group>
                    <ng-template #AddCustom>
                      <button nz-button nzType="link" (click)="addCustom()" nz-tooltip nzTooltipTitle="Stunden & Minuten hinzufügen" [disabled]="rest === 0">
                        <span nz-icon nzType="plus-square"></span>
                      </button>
                    </ng-template>
                  </nz-input-group>
                </li>
              </ul>
            </nz-col>
          </nz-row>
        </nz-col>
      </nz-row>
      <nz-row>
        <nz-col nzSpan="18" [ngSwitch]="!!pausen">
          <ng-container *ngSwitchCase="false">
            <h5 nz-title style="display: inline-block">Pausen</h5>
            <span style="display: inline-block">
              <nz-button-group *ngFor="let pause of (tag$ | async).pausen || []; index as index" style="margin-right: 12px; margin-left: 12px; margin-bottom: 4px">
                <button [disabled]="(tag$ | async)?.zugeordnet" nz-button (click)="setPause(index, pause)">
                  {{ pause.start | date: 'HH:mm' }} - {{ pause.ende || (tag$ | async).ende | date: 'HH:mm' }}: {{ formatRange(pause.start, pause.ende || (tag$ | async).ende) }}
                  <span nz-icon nzType="edit" nzTheme="outline"></span>
                </button>
                <button [disabled]="(tag$ | async)?.zugeordnet" nz-button nzDanger nz-popconfirm nzPopconfirmTitle="Wirklich entfernen?" [nzIcon]="DeleteIcon" (nzOnConfirm)="removePause(index)">
                  <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
                  <ng-template #DeleteIcon>
                    <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
                  </ng-template>
                </button>
              </nz-button-group>
              <button [disabled]="(tag$ | async)?.zugeordnet" nz-button style="margin-left: 12px" (click)="addPause()">
                <i nz-icon nzType="plus-circle" nzShape="circle" nzTheme="outline"></i>
              </button>
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="true">
            <h5 nz-title>Pause {{ pausen.index !== undefined ? 'bearbeiten' : 'hinzufügen' }}</h5>
            <nz-row>
              <nz-col nzSpan="14">
                <nz-input-group style="width: 150px" nzPrefix="Start">
                  <nz-time-picker nzBorderless [nzAllowEmpty]="false" nzFormat="HH:mm" [nzMinuteStep]="5" [(ngModel)]="pausen.start"></nz-time-picker>
                </nz-input-group>
                <nz-input-group style="width: 150px" nzPrefix="Ende">
                  <nz-time-picker nzBorderless [nzAllowEmpty]="false" nzFormat="HH:mm" [nzMinuteStep]="5" [(ngModel)]="pausen.ende"></nz-time-picker>
                </nz-input-group>
              </nz-col>
              <nz-col nzSpan="10">
                <nz-button-group>
                  <button nz-button (click)="savePause()">
                    <span nz-icon [nzType]="pausen.index !== undefined ? 'save' : 'plus-circle'" nzTheme="outline"></span>
                    {{ pausen.index !== undefined ? 'speichern' : 'hinzufügen' }}
                  </button>
                  <button *ngIf="pausen.index !== undefined" nz-button nzDanger nz-popconfirm nzPopconfirmTitle="Wirklich entfernen?" [nzIcon]="DeleteIcon" (nzOnConfirm)="removePause(pausen.index)">
                    <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
                    <ng-template #DeleteIcon>
                      <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
                    </ng-template>
                  </button>
                  <button nz-button (click)="pausen = undefined" nzType="dashed">
                    <span nz-icon nzType="close" nzTheme="outline"></span>
                  </button>
                </nz-button-group>
              </nz-col>
            </nz-row>
          </ng-container>
          <br />
        </nz-col>
        <nz-col nzSpan="6">
          <h5 nz-title>Ort(e)</h5>
          <nz-select [disabled]="(tag$ | async)?.zugeordnet" style="width: 90%" [ngModel]="(tag$ | async)?.ort || []" (ngModelChange)="setOrt($event)" nzMode="multiple">
            <nz-option nzValue="Büro" nzLabel="Im Büro"></nz-option>
            <nz-option nzValue="HomeOffice" nzLabel="Home-Office"></nz-option>
            <nz-option nzValue="Unterwegs" nzLabel="Unterwegs"></nz-option>
          </nz-select>
        </nz-col>
      </nz-row>
    </nz-spin>
  </ng-container>
  <ng-container *nzModalFooter>
    <nz-space>
      <button
        *nzSpaceItem
        nz-button
        (click)="adapt()"
        [disabled]="(tag$ | async)?.zugeordnet || !(eingetragen$ | async) || rest === 0"
        nz-tooltip
        nzTooltipTitle="Stunden & Minuten des Tages auf die eingetragenen Zeiten anpassen"
      >
        <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
        Stunden & Minuten anpassen
      </button>
      <button
        *nzSpaceItem
        nz-button
        (click)="save()"
        [nzLoading]="loading$ | async"
        [disabled]="(tag$ | async)?.zugeordnet || rest < 0"
        nz-tooltip
        [nzTooltipColor]="rest > 0 ? 'red' : ''"
        [nzTooltipTitle]="rest <= 0 ? 'Tag & Zeiten speichern' : rest > 0 ? 'Der Tag ist nicht vollständig gefüllt.' : 'Der Tag ist zu voll.'"
      >
        <span nz-icon nzType="check-circle" nzTheme="outline"></span>
        Tag zwischenspeichern
      </button>
      <button
        *nzSpaceItem
        nz-button
        nzType="primary"
        (click)="submit()"
        [nzLoading]="loading$ | async"
        [disabled]="(tag$ | async)?.zugeordnet || !(eingetragen$ | async) || rest !== 0"
        nz-tooltip
        [nzTooltipColor]="rest !== 0 ? 'red' : ''"
        [nzTooltipTitle]="rest === 0 ? 'Tag & Zeiten speichern' : rest > 0 ? 'Der Tag ist nicht vollständig gefüllt.' : 'Der Tag ist zu voll.'"
      >
        <span nz-icon nzType="check-circle" nzTheme="outline"></span>
        Tag abschließen
      </button>
    </nz-space>
  </ng-container>
</nz-modal>
