import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IObjektNutzungsResponse } from '.';

export interface IObjektNutzungsQueryFilter extends IFilter<IObjektNutzungsResponse> {}

export const ObjektNutzungsQueryFilterConfig: IFilterConfig<IObjektNutzungsResponse> = [];

export interface IObjektNutzungsQueryShapes extends IShape {}

export type IObjektNutzungsQueryField = 'objektNutzungs.id' | 'objektNutzungs.name' | 'objektNutzungs.beschreibung' | '';
