import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostObjektCommandServiceModule } from '..';
import { PostObjektCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostObjektCommandServiceModule
  ],
  declarations: [PostObjektCommandComponent],
  exports: [PostObjektCommandComponent],

})
export class PostObjektCommandComponentModule { }
