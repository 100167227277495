import { NgModule } from '@angular/core';

import {
  PostFeldCommandServiceModule,
  PostFeldCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostFeldCommandServiceModule,
    PostFeldCommandComponentModule
  ],
  exports: [
    PostFeldCommandServiceModule,
    PostFeldCommandComponentModule
  ]
})
export class PostFeldCommandModule { }
