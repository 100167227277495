import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { HonorarVereinbarungenComponent } from '.';
import { DeleteHonorarVereinbarungCommandModule, PostHonorarVereinbarungCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, DeleteHonorarVereinbarungCommandModule, PostHonorarVereinbarungCommandModule],
  declarations: [HonorarVereinbarungenComponent],
  exports: [HonorarVereinbarungenComponent],
})
export class HonorarVereinbarungenComponentModule {}
