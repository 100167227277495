import { Component } from '@angular/core';
import { BaseComponent } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { combineLatest, Subscription } from 'rxjs';

import { IRahmenAnpassungenResponse, RahmenAnpassungenQueryFilterConfig } from 'fa-kt.types';
import { RahmenAnpassungenService } from '.';

@Component({
  selector: 'fa-kt-rahmen-anpassungen-filter',
  template: `<pbc-filter [config]="RahmenAnpassungenFilterConfig" [filter]="rahmenAnpassungen.filter$ | async" (filterChange)="rahmenAnpassungen.filter$.next($event)"></pbc-filter>`,
})
export class RahmenAnpassungenFilterComponent extends BaseComponent {
  readonly subscriptions: Subscription[] = [];

  public RahmenAnpassungenFilterConfig: IFilterConfig<IRahmenAnpassungenResponse>;

  constructor(public rahmenAnpassungen: RahmenAnpassungenService) {
    super();
    this.RahmenAnpassungenFilterConfig = RahmenAnpassungenQueryFilterConfig.map((filter) => ({ ...filter, title: get(this.sitemap, filter.title, filter.title) as string }));
  }

  override ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(
      combineLatest([this.rahmenAnpassungen.shapes$, this.meta.users$]).subscribe(([shapes, users]) => {
        if (!shapes) return;
        this.shape('rahmenAnpassung.mitarbeiter', users);
      }),
    );
  }

  override ngOnDestroy() {
    super.ngOnInit();
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.RahmenAnpassungenFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.RahmenAnpassungenFilterConfig[index].selections = shapes;
  }
}
