import { BooleanFilterConfig, IBooleanFilter, IFilter, IFilterConfig, INumberFilter, ISelection, IShape, ISingleReferenceFilter, NumberFilterConfig, SingleReferenceFilterConfig } from 'pbc.types';

import { IErforderlichesDokument, IGutachten } from 'fa-kt.types';
import { IErforderlichesDokumentsResponse } from '.';

export interface IErforderlichesDokumentsQueryFilter extends IFilter<IErforderlichesDokumentsResponse> {
  'erforderlichesDokuments.original'?: ISingleReferenceFilter<IErforderlichesDokument>;
  'erforderlichesDokuments.gutachten'?: ISingleReferenceFilter<IGutachten>;
  'erforderlichesDokuments.erforderlich'?: IBooleanFilter;
  'erforderlichesDokuments.fortschritt'?: INumberFilter;
}

export const ErforderlichesDokumentsQueryFilterConfig: IFilterConfig<IErforderlichesDokumentsResponse> = [
  SingleReferenceFilterConfig<IGutachten>('erforderlichesDokuments.gutachten', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.GUTACHTEN.title'),
  BooleanFilterConfig('erforderlichesDokuments.erforderlich', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ERFORDERLICH.title'),
  NumberFilterConfig('erforderlichesDokuments.fortschritt', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.FORTSCHRITT.title'),
];

export interface IErforderlichesDokumentsQueryShapes extends IShape {
  'erforderlichesDokuments.gutachten': ISelection[];
}

export type IErforderlichesDokumentsQueryField =
  | 'erforderlichesDokuments.id'
  | 'erforderlichesDokuments.original'
  | 'erforderlichesDokuments.gutachten'
  | 'erforderlichesDokuments.name'
  | 'erforderlichesDokuments.erforderlich'
  | 'erforderlichesDokuments.fortschritt'
  | 'erforderlichesDokuments.kommentar'
  | '';
