<nz-spin [nzSpinning]="loading$ | async">
  <nz-row [style.width]="'100%'">
    <nz-col nzSpan="24">
      <pbc-search-bar [style.width]="'100%'" (searchChange)="search$.next($event)" [search]="search$ | async" placeholder="Suche nach Feldern"></pbc-search-bar>
    </nz-col>
  </nz-row>

  <nz-row [style.width]="'100%'" style="margin-top: 32px; height: 450px;">
    <cdk-virtual-scroll-viewport autosize style="height: 450px; width: 100%" cdkDropList #UnterkategorieList="cdkDropList" (cdkDropListDropped)="dropUnterkategorie($event)" class="unterkategorie-list">
      <nz-col nzSpan="24" *ngFor="let unterkategorie of filtered$ | async; let idxU = index" cdkDrag cdkDragBoundary=".unterkategorie-list">
        <div style="border-bottom: 1px solid rgb(233, 233, 233)" >
          <span style="margin-right: 8px;" (click)="unterkategorie.collapsed = !unterkategorie.collapsed" nz-icon [nzType]="unterkategorie.collapsed ? 'caret-right' : 'caret-down'" nzTheme="outline"></span>
              <label
            nz-checkbox
            [(ngModel)]="unterkategorie.checked"
            [nzIndeterminate]="unterkategorie.indeterminate"
            (ngModelChange)="updateUnterkategorie(unterkategorie, $event)"
            [nzDisabled]="!!(search$ | async)"
          >
            <h4 nz-title [style.color]="unterkategorie.color">
              <span [style.color]="unterkategorie.kategorie.color">
                <span nz-icon [nzType]="unterkategorie.kategorie.icon" nzTheme="outline"></span>
                {{ unterkategorie.kategorie.title }} -
              </span>
              <span nz-icon [nzType]="unterkategorie.icon" nzTheme="outline"></span>
              {{ unterkategorie.title }}
              <span *ngIf="unterkategorie.mobile" nz-icon nzType="mobile" nzTheme="outline" style="margin-left: 12px; margin-top: 4px;"></span>
            </h4>
          </label>
          <span *ngIf="unterkategorie.draggable && (unterkategorie.indeterminate || unterkategorie.checked)" cdkDragHandle style="position: absolute; right: 4px; cursor: grab" nz-icon nzType="menu" nzTheme="outline"></span>
        </div>
        <br />
        <div *ngIf="!unterkategorie.collapsed" cdkDropList #FelderList="cdkDropList" class="felder-list"  (cdkDropListDropped)="dropFeld(idxU, $event)">
          <div *ngFor="let feld of unterkategorie.felder" cdkDrag cdkDragBoundary=".felder-list">
          <label nz-checkbox [(ngModel)]="feld.checked" (ngModelChange)="updateFeld(unterkategorie, feld, $event)">
            <span nz-tooltip [nzTooltipTitle]="feld.tooltip" style="margin-right: 8px" nz-icon [nzType]="feld.icon" nzTheme="outline"></span>
            <span nz-typography>
              {{ feld.label }}
            </span>
          </label>
          <span *ngIf="feld.checked" cdkDragHandle style="position: absolute; right: 12px; cursor: grab" nz-icon nzType="menu" nzTheme="outline"></span>
        </div>
        <br />
        </div>
    </nz-col>
    </cdk-virtual-scroll-viewport>
  </nz-row>
</nz-spin>
