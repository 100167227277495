import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IKundenArtsQuerySorter extends ISorter {
   id?: ISorterExpression;
   kundenGruppe?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const KundenArtsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'KUNDEN.Models.KUNDEN_ART.Propertys.ID.title'),
   SorterConfig('kundenGruppe', 'KUNDEN.Models.KUNDEN_ART.Propertys.KUNDEN_GRUPPE.title'),
   SorterConfig('name', 'KUNDEN.Models.KUNDEN_ART.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'KUNDEN.Models.KUNDEN_ART.Propertys.BESCHREIBUNG.title'),
];
