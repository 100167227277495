import { NgModule } from '@angular/core';
import {
  ZeitComponentModule
} from '.';

@NgModule({
  imports: [
    ZeitComponentModule
  ],
  exports: [
    ZeitComponentModule
  ]
})
export class ZeitenComponentsModule { }
