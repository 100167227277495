import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ErforderlichesDokumentsServiceModule } from '..';
import { ErforderlichesDokumentsFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ErforderlichesDokumentsServiceModule
  ],
  declarations: [ErforderlichesDokumentsFilterComponent],
  exports: [ErforderlichesDokumentsFilterComponent],

})
export class ErforderlichesDokumentsFilterComponentModule { }
