import { Component, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { BaseModelPage } from 'pbc.angular';

import { IPostTourStatusRequest, ITourStatiRequest, ITourStatiResponse, ITourStatus } from 'fa-kt.types';
import { TourStatiService } from '../query';

@Component({
  selector: 'fa-kt-tour-stati',
  templateUrl: './tour-stati.page.html',
  styleUrls: ['./tour-stati.page.css'],
})
export class TourStatiPage extends BaseModelPage<ITourStatus, ITourStatiRequest, ITourStatiResponse, IPostTourStatusRequest> implements OnInit, OnDestroy {
  description: { context: string; page: string; model: string } = {
    context: 'BESICHTIGUNGEN',
    page: 'TOUR_STATI',
    model: 'TOUR_STATUS',
  };
  response$: BehaviorSubject<ITourStatiResponse | undefined>;
  contentKey: keyof ITourStatiResponse = 'tourStati';
  valueKey: keyof IPostTourStatusRequest = 'tourStatus';

  constructor(public tourStati: TourStatiService) {
    super();
    this.response$ = this.tourStati.response$;
  }
}
