import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IDurchsuchenRequest, IDurchsuchenResponse } from 'fa-kt.types';
import { DurchsuchenService } from '../../querys';

export interface IRecherchePageResolverResult {
  durchsuchen?: IDurchsuchenResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class RecherchePageResolver implements Resolve<IRecherchePageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private durchsuchen: DurchsuchenService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IRecherchePageResolverResult> {
    this.loading.resolving();
    const requests = {
      durchsuchen: {} as IDurchsuchenRequest,
    };
    /* << custom    */
    requests.durchsuchen = route.params as unknown as IDurchsuchenRequest;
    /*    custom >> */
    const log = (error: any) => console.error(error);
    const [durchsuchen] = await Promise.all([this.durchsuchen.request(requests.durchsuchen).catch(log)]);
    this.loading.resolved();
    return {
      durchsuchen,
    };
  }
}
