import { NgModule } from '@angular/core';

import { DeleteStandortCommandModule, PostStandortCommandModule, StandortsQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostStandortCommandModule, DeleteStandortCommandModule, StandortsQueryModule],
  exports: [PostStandortCommandModule, DeleteStandortCommandModule, StandortsQueryModule],
})
export class StandortsSettingsModule {}
