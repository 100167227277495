import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FeldServiceModule } from '..';
import { FeldDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldServiceModule
  ],
  declarations: [FeldDetailComponent],
  exports: [FeldDetailComponent],

})
export class FeldDetailComponentModule { }
