import { Component } from '@angular/core';
import { MitarbeiterService } from '../service';

@Component({
  selector: 'fa-kt-saved-mitarbeiter-filters',
  templateUrl: './saved-mitarbeiter-filters.component.html',
  styleUrls: ['./saved-mitarbeiter-filters.component.css'],
})
export class SavedMitarbeiterFiltersComponent {
  constructor(public mitarbeiter: MitarbeiterService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
