import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteGutachtenCommandServiceModule } from '..';
import { DeleteGutachtenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteGutachtenCommandServiceModule
  ],
  declarations: [DeleteGutachtenCommandComponent],
  exports: [DeleteGutachtenCommandComponent],

})
export class DeleteGutachtenCommandComponentModule { }
