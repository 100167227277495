import { NgModule } from '@angular/core';

import {
  DeleteZeitUnterartCommandComponentModule, DeleteZeitUnterartCommandServiceModule
} from '.';

@NgModule({
  imports: [
    DeleteZeitUnterartCommandServiceModule,
    DeleteZeitUnterartCommandComponentModule
  ],
  exports: [
    DeleteZeitUnterartCommandServiceModule,
    DeleteZeitUnterartCommandComponentModule
  ]
})
export class DeleteZeitUnterartCommandModule { }
