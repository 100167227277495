import { IFilter, IFilterConfig } from "pbc.types";

import { IObjektNutzung } from '.';

export interface IObjektNutzungFilter extends IFilter<IObjektNutzung> {}

export const ObjektNutzungFilterConfig: IFilterConfig<IObjektNutzung> = [];

export type IObjektNutzungField =
  'objektNutzung.id'|
  'objektNutzung.name'|
  'objektNutzung.beschreibung'
;
