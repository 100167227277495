import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IAbteilungSorter extends ISorter {
  id?: ISorterExpression;
  kunde?: ISorterExpression;
  name?: ISorterExpression;
  ansprache?: ISorterExpression;
  outlookKontakte?: ISorterExpression;
  website?: ISorterExpression;
  adresse?: ISorterExpression;
  aktiv?: ISorterExpression;
}

export const AbteilungSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('name', 'Name'),
  SorterConfig('ansprache', 'Ansprache'),
  SorterConfig('outlookKontakte', 'Outlook Kontakte'),
  SorterConfig('website', 'Website'),
  SorterConfig('adresse', 'Adresse'),
  SorterConfig('aktiv', 'Aktiv')
];
