import { NgModule } from '@angular/core';

import { DeleteProjektArtCommandModule, PostProjektArtCommandModule, ProjektArtsQueryModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostProjektArtCommandModule, DeleteProjektArtCommandModule, ProjektArtsQueryModule],
  exports: [PostProjektArtCommandModule, DeleteProjektArtCommandModule, ProjektArtsQueryModule],
})
export class ProjektArtsSettingsModule {}
