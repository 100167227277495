import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IStandortSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  addresse?: ISorterExpression;
  dateipfad?: ISorterExpression;
}

export const StandortSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('addresse', 'Addresse'),
  SorterConfig('dateipfad', 'Dateipfad')
];
