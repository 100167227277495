import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { IReisekostenabrechnungenQueryShapes, IReisekostenabrechnungenResponse, ReisekostenabrechnungenQueryFilterConfig } from 'fa-kt.types';

import { ReisekostenabrechnungenService } from '../service';

@Component({
  selector: 'fa-kt-reisekostenabrechnungen-filter',
  templateUrl: './reisekostenabrechnungen-filter.component.html',
  styleUrls: ['./reisekostenabrechnungen-filter.component.css'],
})
export class ReisekostenabrechnungenFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public ReisekostenabrechnungenFilterConfig: IFilterConfig<IReisekostenabrechnungenResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public reisekostenabrechnungen: ReisekostenabrechnungenService,
  ) {
    this.ReisekostenabrechnungenFilterConfig = ReisekostenabrechnungenQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.reisekostenabrechnungen.shapes$.subscribe((shapes: IReisekostenabrechnungenQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.ReisekostenabrechnungenFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.ReisekostenabrechnungenFilterConfig[index].selections = shapes;
  }
}
