import { IFilter, IFilterConfig } from "pbc.types";

import { IObjekt } from '.';

export interface IObjektFilter extends IFilter<IObjekt> {}

export const ObjektFilterConfig: IFilterConfig<IObjekt> = [];

export type IObjektField =
  'objekt.id'|
  'objekt.name'|
  'objekt.addresse'|
  'objekt.bereiche'|
  'objekt.anspracheOutlookKontakts'|
  'objekt.klingelschild'|
  'objekt.kommentar'
;
