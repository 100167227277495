import { NgModule } from '@angular/core';

import { DeleteOrtCommandModule, OrtsQueryModule, PostOrtCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostOrtCommandModule, DeleteOrtCommandModule, OrtsQueryModule],
  exports: [PostOrtCommandModule, DeleteOrtCommandModule, OrtsQueryModule],
})
export class OrtsSettingsModule {}
