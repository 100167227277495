import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISelection } from 'pbc.types';

import { IPostTourCommandShapes, IPostTourRequest, IPostTourResponse } from 'fa-kt.types';
import { PostCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class PostTourCommandService extends PostCommandService<IPostTourRequest, IPostTourResponse, IPostTourCommandShapes> {
  readonly route = 'besichtigungen/tour';
  override dependencies = ['besichtigungen/written-tour-status', 'besichtigungen/removed-tour-status'];

  getTourMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.mitarbeiter']?.find((v) => v.value === value);
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.mitarbeiter'] as ISelection[]) : [])));
  }

  getTourTourStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['tour.tourStatus']?.find((v) => v.value === value);
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['tour.tourStatus'] as ISelection[]) : [])));
  }
}
