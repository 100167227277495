import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

  transform(values: unknown[] = [], ...args: unknown[]): unknown[] {
    return values.reverse();
  }

}
