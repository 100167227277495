<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="durchgang.search$.next($event)" [search]="durchgang.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-durchgang-filter [style.width]="'100%'"></fa-kt-durchgang-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-durchgang-sorter [style.width]="'100%'"></fa-kt-durchgang-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-durchgang-list [style.width]="'100%'" (select)="setDurchgang($event)" [loading]="$loading | async"></fa-kt-durchgang-list>


  <fa-kt-post-eintrag [style.width]="'100%'"  [value]="$postEintrag | async" (finished)="finishedPostEintrag($event)"></fa-kt-post-eintrag>

  <fa-kt-post-durchgang [style.width]="'100%'"  [value]="$postDurchgang | async" (finished)="finishedPostDurchgang($event)"></fa-kt-post-durchgang>



    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="tour" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle>
    {{ page.emoji }} {{ (durchgang.result$ | async).objekt?.addresse.land }} {{ (durchgang.result$ | async).objekt?.addresse.plz }}, {{ (durchgang.result$ | async).objekt?.addresse.strasse }}
  </ng-template>
  <ng-template #PageSubtitle> {{ page.title }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <button [disabled]="!($durchgang | async)" nz-button type="button" (click)="clearCache()" nzType="dashed" nz-tooltip nzTooltipTitle="Hinterlegte Antworten aus dem lokalen Speicher entfernen">
      <i nz-icon nzType="check-circle" nzTheme="outline"></i>
      Abschließen
    </button>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-spin [nzSpinning]="$loading | async" style="width: 100%">
      <nz-tabset>
        <nz-tab *ngFor="let kategorie of $kategorien | async | order:'order'; let indexK = index" [nzTitle]="kategorieName" nzCentered nzSize="large">
          <ng-template #kategorieName>
            <h2 nz-title [style.color]="kategorie.color">
              <i nz-icon [nzType]="kategorie.icon" nzTheme="outline"></i>
              {{ kategorie.name }}
            </h2>
          </ng-template>
          <nz-row>
            <nz-col nzSpan="24">
              <nz-tabset>
                <nz-tab *ngFor="let unterkategorie of kategorie.unterkategorien | order:'order'; let indexU = index" [nzTitle]="unterkategorieName" nzCentered>
                  <ng-template #unterkategorieName>
                    <h4 nz-title [style.color]="unterkategorie.color">
                      <i nz-icon [nzType]="unterkategorie.icon" nzTheme="outline"></i>
                      {{ unterkategorie.name }}
                    </h4>
                  </ng-template>
                  <nz-row>
                    <nz-col nzSpan="24" *ngFor="let feld of unterkategorie.felder; let indexF = index">
                      <nz-row *ngIf="!feld.skipped" [style.backgroundColor]="indexF % 2 === 0 ? 'gainsboro' : ''">
                        <nz-col nzSpan="10">
                          <span style="padding: 8px">
                            {{ feld.name }}
                            <i nz-icon *ngIf="feld.answered" nzType="check-circle" nzTheme="outline"></i>
                          </span>
                          <br />
                          <br />
                        </nz-col>
                        <nz-col nzSpan="14" style="padding: 8px">
                          <ng-container [ngSwitch]="feld.art">
                            <ng-container *ngSwitchCase="'option'">
                              <button
                                nz-button
                                *ngFor="let option of feld.feldOptionen | order:'order'"
                                [disabled]="feld.skipped"
                                [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                                type="button"
                                nzShape="round"
                                (click)="submitEintrag(feld, option.option)"
                              >
                                {{ option.option }}
                              </button>
                            </ng-container>
                            <ng-container *ngSwitchCase="'optionPlus'">
                              <button
                                nz-button
                                *ngFor="let option of feld.feldOptionen | order:'order'"
                                [disabled]="feld.skipped"
                                [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                                type="button"
                                nzShape="round"
                                (click)="submitEintrag(feld, option.option)"
                              >
                                {{ option.option }}
                              </button>
                              <pbc-multi-value width="124px" [disabled]="feld.skipped" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                            </ng-container>
                            <ng-container *ngSwitchCase="'mehrfachauswahlPlus'">
                              <button
                                nz-button
                                *ngFor="let option of feld.feldOptionen | order:'order'"
                                [disabled]="feld.skipped"
                                [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'dashed'"
                                type="button"
                                nzShape="round"
                                (click)="toggleValues(feld, option)"
                              >
                                {{ option.option }}
                              </button>
                              <pbc-multi-value [value]="feld.eintrag?.wertExtra" (blurred)="submitExtraEintrag(feld, $event)" width="124px" [disabled]="feld.skipped"></pbc-multi-value>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                              <pbc-multi-value [disabled]="feld.skipped" [mode]="feld.art" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                            </ng-container>
                          </ng-container>
                        </nz-col>
                      </nz-row>
                    </nz-col>
                  </nz-row>
                </nz-tab>
              </nz-tabset>
            </nz-col>
          </nz-row>
        </nz-tab>
      </nz-tabset>
      <ng-container *ngIf="($unzugeordnete | async).length > 0">
        <nz-divider nzText="Sonstige"></nz-divider>
        <nz-col nzSpan="24" *ngFor="let feld of $unzugeordnete | async | order:'schluessel'">
          <nz-row *ngIf="!feld.skipped">
            <nz-col nzSpan="10">
              <span>
                {{ feld.name }}
                <i nz-icon *ngIf="feld.answered" nzType="check-circle" nzTheme="outline"></i>
              </span>
              <br />
              <br />
            </nz-col>
            <nz-col nzSpan="14">
              <ng-container [ngSwitch]="feld.art">
                <ng-container *ngSwitchCase="'option'">
                  <button
                    nz-button
                    *ngFor="let option of feld.feldOptionen | order:'order'"
                    [disabled]="feld.skipped"
                    [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                    type="button"
                    nzShape="round"
                    (click)="submitEintrag(feld, option.option)"
                  >
                    {{ option.option }}
                  </button>
                </ng-container>
                <ng-container *ngSwitchCase="'optionPlus'">
                  <button
                    nz-button
                    *ngFor="let option of feld.feldOptionen | order:'order'"
                    [disabled]="feld.skipped"
                    [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'default'"
                    type="button"
                    nzShape="round"
                    (click)="submitEintrag(feld, option.option)"
                  >
                    {{ option.option }}
                  </button>
                  <pbc-multi-value width="124px" [disabled]="feld.skipped" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                </ng-container>
                <ng-container *ngSwitchCase="'mehrfachauswahlPlus'">
                  <button
                    nz-button
                    *ngFor="let option of feld.feldOptionen | order:'order'"
                    [disabled]="feld.skipped"
                    [nzType]="hasValue(feld.eintrag, option) ? 'primary' : 'dashed'"
                    type="button"
                    nzShape="round"
                    (click)="toggleValues(feld, option)"
                  >
                    {{ option.option }}
                  </button>
                  <pbc-multi-value [value]="feld.eintrag?.wertExtra" (blurred)="submitExtraEintrag(feld, $event)" width="124px" [disabled]="feld.skipped"></pbc-multi-value>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <pbc-multi-value [disabled]="feld.skipped" [mode]="feld.art" [value]="feld.eintrag?.wert" (blurred)="submitEintrag(feld, $event)"></pbc-multi-value>
                </ng-container>
              </ng-container>
            </nz-col>
          </nz-row>
        </nz-col>
      </ng-container>
    </nz-spin>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
