import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostSortiereAbschnitteCommandShapes, IPostSortiereAbschnitteRequest, IPostSortiereAbschnitteResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostSortiereAbschnitteCommandService extends PostCommandService<IPostSortiereAbschnitteRequest, IPostSortiereAbschnitteResponse, IPostSortiereAbschnitteCommandShapes> {
  readonly route = 'texte/sortiere-abschnitte';

  getAbschnitteVorlage(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['abschnitte.vorlage']?.find((v) => v.value === value);
  }

  get abschnitteVorlage(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['abschnitte.vorlage'] as ISelection[]) : [])));
  }
}
