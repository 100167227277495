<!---
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="berichtExcel">{{ command.Inputs.BERICHT_EXCEL.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.BERICHT_EXCEL.hint">
      <pbc-select-value formControlName="berichtExcel" id="berichtExcel" [shapes]="postHochladen.berichtExcel | async" [placeholder]="command.Inputs.BERICHT_EXCEL.placeholder" ></pbc-select-value>
    </nz-form-control>
  </nz-form-item>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <nz-form-item style="width: 100%;">
      <nz-form-control [nzSm]="24" [nzXs]="24" [nzErrorTip]="ExcelErrorTip">
        <ng-template #ExcelErrorTip let-control>
          <ng-container *ngIf="control.hasError('requiredFileType')">Bitte korrekten Dateityp auswählen</ng-container>
        </ng-template>
        <pbc-file-upload style="width: 100%;" formControlName="berichtExcel" id="berichtExcel" (uploaded)="submit()"
                               [icon]="'file-excel'" [text]="command.Inputs.BERICHT_EXCEL.title" [hint]="command.Inputs.BERICHT_EXCEL.hint"></pbc-file-upload>
     </nz-form-control>
    </nz-form-item>
<!---    custom >> -->
<!---
<button nz-button nzType="primary"
  type="submit"
  [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
  nz-tooltip [nzTooltipTitle]="SubmitTooltip">
    {{ command.translation }}
  <ng-template #SubmitTooltip>
  </ng-template>
</button>
 -->
  </form>
</nz-spin>
