import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostAbmeldenCommandShapes, IPostAbmeldenRequest, IPostAbmeldenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostAbmeldenCommandService extends PostCommandService<IPostAbmeldenRequest, IPostAbmeldenResponse, IPostAbmeldenCommandShapes> {
  readonly route = 'kommunikation/abmelden';
}
