import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IKundenGruppe } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { KundenGruppesService } from '../service';

@Component({
  selector: 'fa-kt-kunden-gruppes-list',
  templateUrl: './kunden-gruppes-list.component.html',
  styleUrls: ['./kunden-gruppes-list.component.css'],
})
export class KundenGruppesListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IKundenGruppe> = new EventEmitter<IKundenGruppe>();

  constructor(public kundenGruppes: KundenGruppesService) {}

  ngOnDestroy() {}
}
