import { IFilter, IFilterConfig } from "pbc.types";

import { IAbteilung } from '.';

export interface IAbteilungFilter extends IFilter<IAbteilung> {}

export const AbteilungFilterConfig: IFilterConfig<IAbteilung> = [];

export type IAbteilungField =
  'abteilung.id'|
  'abteilung.kunde'|
  'abteilung.name'|
  'abteilung.ansprache'|
  'abteilung.outlookKontakte'|
  'abteilung.website'|
  'abteilung.adresse'|
  'abteilung.aktiv'
;
