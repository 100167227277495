import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { ITemplatesResponse } from 'fa-kt.types';
import { PostTemplateCommandService } from '../commands';
import { TemplatesService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class TemplatesPageResolver implements Resolve<ITemplatesResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private templates: TemplatesService,
    private postTemplate: PostTemplateCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ITemplatesResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [templates] = await Promise.all([this.templates.request({}).catch(log), this.postTemplate.prepare(true).catch(log)]);
    this.loading.resolved();
    return templates;
  }
}
