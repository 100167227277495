import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteZeitArtCommandComponent } from '.';
import { DeleteZeitArtCommandServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteZeitArtCommandServiceModule
  ],
  declarations: [DeleteZeitArtCommandComponent],
  exports: [DeleteZeitArtCommandComponent],

})
export class DeleteZeitArtCommandComponentModule { }
