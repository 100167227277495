import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TourenServiceModule } from '..';
import { SavedTourenFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourenServiceModule
  ],
  declarations: [SavedTourenFiltersComponent],
  exports: [SavedTourenFiltersComponent],

})
export class SavedTourenFiltersComponentModule { }
