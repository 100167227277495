import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { IPostKalkulationHochladenRequest, IPostKalkulationHochladenResponse } from 'fa-kt.types';
import { PostKalkulationHochladenCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-kalkulationen',
  templateUrl: './kalkulationen.component.html',
  styleUrls: ['./kalkulationen.component.css'],
})
export class KalkulationenComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postKalkulationHochladen: BehaviorSubject<IPostKalkulationHochladenRequest | null> = new BehaviorSubject<IPostKalkulationHochladenRequest | null>(null);

  @Input() set gutachten(gutachten: string) {
    this.$postKalkulationHochladen.next({ kalkulation: { id: '', datei: null, gutachten } });
  }

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postKalkulationHochladen: PostKalkulationHochladenCommandService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostKalkulationHochladen(response?: IPostKalkulationHochladenResponse) {
    /* << after post-kalkulation-hochladen    */
    /*    after post-kalkulation-hochladen >> */
  }

  public async submitPostKalkulationHochladen(request?: IPostKalkulationHochladenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postKalkulationHochladen.getValue();
    if (payload) {
      try {
        const response = await this.postKalkulationHochladen.request(payload);
        await this.finishedPostKalkulationHochladen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
