import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { IKundenQueryShapes, IKundenResponse, KundenQueryFilterConfig } from 'fa-kt.types';

import { KundenService } from '../service';

@Component({
  selector: 'fa-kt-kunden-filter',
  templateUrl: './kunden-filter.component.html',
  styleUrls: ['./kunden-filter.component.css'],
})
export class KundenFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public KundenFilterConfig: IFilterConfig<IKundenResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public kunden: KundenService,
  ) {
    this.KundenFilterConfig = KundenQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    this.KundenFilterConfig = this.KundenFilterConfig.filter((f) => f.key !== 'kunde.addresse');
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.kunden.shapes$.subscribe((shapes: IKundenQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.KundenFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.KundenFilterConfig[index].selections = shapes;
  }
}
