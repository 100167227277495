import { NgModule } from '@angular/core';

import { DeleteGutachtenStatusCommandModule, GutachtenStatiQueryModule, PostGutachtenStatusCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostGutachtenStatusCommandModule, DeleteGutachtenStatusCommandModule, GutachtenStatiQueryModule],
  exports: [PostGutachtenStatusCommandModule, DeleteGutachtenStatusCommandModule, GutachtenStatiQueryModule],
})
export class GutachtenStatiSettingsModule {}
