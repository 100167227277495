import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IReisekostenabrechnungQuerySorter extends ISorter {
  'reisekostenabrechnung.id'?: ISorterExpression;
  'reisekostenabrechnung.mitarbeiter'?: ISorterExpression;
  'reisekostenabrechnung.touren'?: ISorterExpression;
  'reisekostenabrechnung.reisekostenabrechnungStatus'?: ISorterExpression;
  'reisekostenabrechnung.tagesgeldAcht'?: ISorterExpression;
  'reisekostenabrechnung.tagesgeldVierundzwanzig'?: ISorterExpression;
  'reisekostenabrechnung.anUndAbreisetag'?: ISorterExpression;
  'reisekostenabrechnung.fruehstuecke'?: ISorterExpression;
  'reisekostenabrechnung.mittagessen'?: ISorterExpression;
  'reisekostenabrechnung.abendessen'?: ISorterExpression;
  'reisekostenabrechnung.uebernachtungspauschale'?: ISorterExpression;
  'reisekostenabrechnung.normaleKm'?: ISorterExpression;
  'reisekostenabrechnung.mitnahmeentschaedigung'?: ISorterExpression;
  'reisekostenabrechnung.sonstigePositionen'?: ISorterExpression;
  'reisekostenabrechnung.sonstigeSumme'?: ISorterExpression;
  'reisekostenabrechnung.summe'?: ISorterExpression;
  'reisekostenabrechnung.antrag'?: ISorterExpression;
}

export const ReisekostenabrechnungQuerySorterConfig: ISorterConfig = [
  SorterConfig('reisekostenabrechnung.id', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ID.title'), 
  SorterConfig('reisekostenabrechnung.mitarbeiter', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITARBEITER.title'), 
  SorterConfig('reisekostenabrechnung.touren', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TOUREN.title'), 
  SorterConfig('reisekostenabrechnung.reisekostenabrechnungStatus', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.REISEKOSTENABRECHNUNG_STATUS.title'), 
  SorterConfig('reisekostenabrechnung.tagesgeldAcht', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_ACHT.title'), 
  SorterConfig('reisekostenabrechnung.tagesgeldVierundzwanzig', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_VIERUNDZWANZIG.title'), 
  SorterConfig('reisekostenabrechnung.anUndAbreisetag', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.AN_UND_ABREISETAG.title'), 
  SorterConfig('reisekostenabrechnung.fruehstuecke', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.FRUEHSTUECKE.title'), 
  SorterConfig('reisekostenabrechnung.mittagessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITTAGESSEN.title'), 
  SorterConfig('reisekostenabrechnung.abendessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ABENDESSEN.title'), 
  SorterConfig('reisekostenabrechnung.uebernachtungspauschale', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.UEBERNACHTUNGSPAUSCHALE.title'), 
  SorterConfig('reisekostenabrechnung.normaleKm', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.NORMALE_KM.title'), 
  SorterConfig('reisekostenabrechnung.mitnahmeentschaedigung', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITNAHMEENTSCHAEDIGUNG.title'), 
  SorterConfig('reisekostenabrechnung.sonstigePositionen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SONSTIGE_POSITIONEN.title'), 
  SorterConfig('reisekostenabrechnung.sonstigeSumme', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SONSTIGE_SUMME.title'), 
  SorterConfig('reisekostenabrechnung.summe', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SUMME.title'), 
  SorterConfig('reisekostenabrechnung.antrag', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ANTRAG.title'), 
];
