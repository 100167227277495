import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IRechnungsVorlagenResponse } from '.';

export interface IRechnungsVorlagenQueryFilter extends IFilter<IRechnungsVorlagenResponse> {}

export const RechnungsVorlagenQueryFilterConfig: IFilterConfig<IRechnungsVorlagenResponse> = [];

export interface IRechnungsVorlagenQueryShapes extends IShape {}

export type IRechnungsVorlagenQueryField = 'rechnungsVorlage.id' | 'rechnungsVorlage.name' | 'rechnungsVorlage.kommentar' | 'rechnungsVorlage.datei' | '';
