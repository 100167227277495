import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { UmkreissucheServiceModule } from '..';
import { SavedUmkreissucheFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    UmkreissucheServiceModule
  ],
  declarations: [SavedUmkreissucheFiltersComponent],
  exports: [SavedUmkreissucheFiltersComponent],

})
export class SavedUmkreissucheFiltersComponentModule { }
