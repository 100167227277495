import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteVermerkCommandServiceModule } from '..';
import { DeleteVermerkCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteVermerkCommandServiceModule
  ],
  declarations: [DeleteVermerkCommandComponent],
  exports: [DeleteVermerkCommandComponent],

})
export class DeleteVermerkCommandComponentModule { }
