import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ITemplate } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { TemplatesService } from '../service';

@Component({
  selector: 'fa-kt-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.css'],
})
export class TemplatesListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<ITemplate> = new EventEmitter<ITemplate>();

  constructor(public templates: TemplatesService) {}

  ngOnDestroy() {}
}
