import { IFilter, IFilterConfig } from "pbc.types";

import { IRechnungsVorlage } from '.';

export interface IRechnungsVorlageFilter extends IFilter<IRechnungsVorlage> {}

export const RechnungsVorlageFilterConfig: IFilterConfig<IRechnungsVorlage> = [];

export type IRechnungsVorlageField =
  'rechnungsVorlage.id'|
  'rechnungsVorlage.name'|
  'rechnungsVorlage.kommentar'|
  'rechnungsVorlage.datei'
;
