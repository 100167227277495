import { NgModule } from '@angular/core';

import { FilterPipe, LIB_CommonModule, SearchPipe, SorterPipe } from 'pbc.angular';

import { VorlagenService } from '.';

@NgModule({
  imports: [LIB_CommonModule],
  providers: [VorlagenService, SearchPipe, FilterPipe, SorterPipe],
  exports: [],
})
export class VorlagenServiceModule {}
