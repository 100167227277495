import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostObjektNutzungCommandServiceModule } from '..';
import { PostObjektNutzungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostObjektNutzungCommandServiceModule
  ],
  declarations: [PostObjektNutzungCommandComponent],
  exports: [PostObjektNutzungCommandComponent],

})
export class PostObjektNutzungCommandComponentModule { }
