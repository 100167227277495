import { NgModule } from '@angular/core';

import {
  DeleteFormularCommandServiceModule,
  DeleteFormularCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteFormularCommandServiceModule,
    DeleteFormularCommandComponentModule
  ],
  exports: [
    DeleteFormularCommandServiceModule,
    DeleteFormularCommandComponentModule
  ]
})
export class DeleteFormularCommandModule { }
