import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { ZeilenComponent } from '.';
import { PostZeileCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostZeileCommandModule],
  declarations: [ZeilenComponent],
  exports: [ZeilenComponent],
})
export class ZeilenComponentModule {}
