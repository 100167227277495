import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { RahmenAnpassungenFilterComponent } from './rahmen-anpassungen-filter.component';
import { RahmenAnpassungenPresetsComponent } from './rahmen-anpassungen-presets.component';
import { RahmenAnpassungenSorterComponent } from './rahmen-anpassungen-sorter.component';
import { RahmenAnpassungenService } from './rahmen-anpassungen.service';

@NgModule({
  imports: [LIB_CommonModule],
  declarations: [RahmenAnpassungenPresetsComponent, RahmenAnpassungenSorterComponent, RahmenAnpassungenFilterComponent],
  providers: [RahmenAnpassungenService],
  exports: [RahmenAnpassungenPresetsComponent, RahmenAnpassungenSorterComponent, RahmenAnpassungenFilterComponent],
})
export class RahmenAnpassungenQueryModule {}
