<nz-form-item style="width: 100%" style="padding: 8px">
  <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="outlookKontaktes">
    <button
      (click)="startContact()"
      [disabled]="!(outlook.$contacts | async)"
      nz-button
      nzShape="circle"
      nzType="dashed"
      type="button"
      nz-tooltip
      nzTooltipTitle="Neuen Kontakt erstellen"
      [nzDanger]="required && ($values | async)?.length === 0"
    >
      <i nz-icon nzTheme="outline" nzType="plus"></i>
    </button>

    <button
      style="margin-left: 6px"
      (click)="openBook()"
      [disabled]="!(outlook.$contacts | async)"
      nz-button
      nzShape="round"
      nzType="default"
      nz-tooltip
      nzTooltipTitle="Kontaktbuch durchsuchen"
      [nzDanger]="required && ($values | async)?.length === 0"
      type="button"
    >
      <i nz-icon nzTheme="outline" nzType="contacts"></i>
    </button>
  </nz-form-label>
  <nz-form-control [nzSm]="12" [nzXs]="24">
    <nz-row>
      <nz-col nzSpan="24">
        <pbc-search-bar (searchChange)="search$.next($event)" [search]="search$ | async" (focused)="focused()" [placeholder]="placeholder" [style.width]="'100%'"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="24">
        <nz-list nzItemLayout="horizontal">
          <nz-list-item *ngFor="let contact of $contacts | async | search: (search$ | async) : 'displayName'">
            <nz-list-item-meta [nzAvatar]="contact.photo">
              <nz-list-item-meta-title>
                <a (click)="openContact(contact)">
                  {{ contact.displayName }}
                </a>
              </nz-list-item-meta-title>
            </nz-list-item-meta>

            <nz-space>
              <ng-container *ngIf="contact.mobilePhone || contact.homePhones.length > 0 || contact.businessPhones.length > 0">
                <a nz-dropdown *nzSpaceItem [nzDropdownMenu]="callMenu" [href]="'tel:' + contact.mobilePhone" target="_blank">
                  <i nz-icon nzType="phone"></i>
                </a>
                <nz-dropdown-menu #callMenu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item *ngIf="contact.mobilePhone">
                      <a [href]="'tel:' + contact.mobilePhone" target="_blank"><i nz-icon nzType="phone"></i> Handy {{ contact.mobilePhone }} </a>
                    </li>
                    <li nz-menu-item *ngFor="let homePhone of contact.homePhones">
                      <a [href]="'tel:' + homePhone" target="_blank"><i nz-icon nzType="phone"></i> Privat {{ homePhones }} </a>
                    </li>
                    <li nz-menu-item *ngFor="let businessPhone of contact.businessPhones">
                      <a [href]="'tel:' + businessPhone" target="_blank"><i nz-icon nzType="phone"></i> Geschäftlich {{ businessPhone }} </a>
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </ng-container>

              <ng-container *ngIf="contact.emailAddresses.length > 0">
                <a nz-dropdown *nzSpaceItem [nzDropdownMenu]="emailMenu" [href]="'mailto:' + contact.emailAddresses[0].address" target="_blank">
                  <i nz-icon nzType="mail"></i>
                </a>
                <nz-dropdown-menu #emailMenu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item *ngFor="let emailAddress of contact.emailAddresses">
                      <a [href]="'mailto:' + emailAddress.address" target="_blank"><i nz-icon nzType="mail"></i> {{ emailAddress.name }} {{ emailAddress.address }} </a>
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </ng-container>
            </nz-space>

            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a (click)="removeContact(contact.id)">
                  <i nz-icon nzType="delete"></i>
                </a>
              </nz-list-item-action>

              <nz-list-item-action>
                <a (click)="openContact(contact)">
                  <i nz-icon nzType="edit"></i>
                </a>
              </nz-list-item-action>
            </ul>
          </nz-list-item>
        </nz-list>
      </nz-col>
    </nz-row>
    <ng-container *ngIf=""> </ng-container>
  </nz-form-control>
</nz-form-item>

<nz-modal (nzOnCancel)="closeBook()" [nzClosable]="true" [nzContent]="ContactBookContent" [nzFooter]="ContactBookFooter" [nzTitle]="ContactBookTitle" [nzVisible]="!!($book | async)">
  <ng-template #ContactBookTitle>
    <nz-page-header [nzTitle]="ContactBookHeaderTitle">
      <ng-template #ContactBookHeaderTitle>
        <i nz-icon nzTheme="outline" nzType="contacts"></i>
        Mein Kontaktbuch
      </ng-template>
      <nz-page-header-extra>
        <button (click)="startContact()" nz-button nzType="dashed" type="button">
          <i nz-icon nzType="plus"></i>
          Kontakt hinzufügen
        </button>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ContactBookContent>
    <nz-row>
      <nz-col nzSpan="24">
        <pbc-search-bar (searchChange)="search$.next($event)" [search]="search$ | async" [style.width]="'100%'"></pbc-search-bar>
      </nz-col>
      <nz-col nzSpan="24">
        <nz-list nzItemLayout="horizontal">
          <nz-list-item *ngFor="let contact of $book | async | search: (search$ | async) : 'displayName'">
            <nz-list-item-meta [nzAvatar]="contact.photo">
              <nz-list-item-meta-title>
                <a *ngIf="!contact.disabled" (click)="addContact(contact.id)">
                  <i style="margin-right: 4px" nz-icon nzType="plus-circle"></i>
                  {{ contact.displayName }}
                </a>
                <ng-container *ngIf="contact.disabled">
                  <a (click)="removeContact(contact.id)" style="margin-right: 6px">
                    <i nz-icon nzType="minus-circle"></i>
                  </a>
                  <span nz-typography nzDisabled>{{ contact.displayName }}</span>
                </ng-container>
              </nz-list-item-meta-title>
            </nz-list-item-meta>
            <ul nz-list-item-actions>
              <nz-list-item-action
                ><a (click)="openContact(contact)"><i nz-icon nzType="edit"></i></a>
              </nz-list-item-action>
            </ul>
          </nz-list-item>
        </nz-list>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #ContactBookFooter> </ng-template>
</nz-modal>

<nz-modal (nzOnCancel)="cancelContact()" [nzClosable]="true" [nzContent]="ContactContent" [nzFooter]="ContactFooter" [nzTitle]="ContactTitle" [nzVisible]="!!($contact | async)">
  <ng-template #ContactTitle>
    <nz-page-header [nzTitle]="ContactHeaderTitle">
      <ng-template #ContactHeaderTitle>
        <i nz-icon nzTheme="outline" nzType="user"></i>
        "{{ ($contact | async)?.displayName }}"
      </ng-template>
      <nz-page-header-extra>
        <button (nzOnConfirm)="deleteContact()" [disabled]="!($contact | async)?.id" [nzIcon]="DeleteIcon" nz-button nz-popconfirm nzPopconfirmTitle="Wirklich löschen?" nzDanger>
          <i nz-icon nzTheme="outline" nzType="delete" style="color: red"></i>
          Löschen
          <ng-template #DeleteIcon>
            <i nz-icon nzTheme="outline" nzType="delete" style="color: red"></i>
          </ng-template>
        </button>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ContactContent>
    <nz-row>
      <nz-col nzSpan="24">
        <form [formGroup]="form" nz-form>
          <nz-row>
            <nz-col [nzXl]="{ span: 24 }" [nzLg]="{ span: 24 }" style="margin-bottom: 12px">
              <nz-form-item style="width: 100%; margin-bottom: 12px">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="photo">Bild</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <pbc-image-upload formControlName="photo" id="photo"></pbc-image-upload>
                </nz-form-control>
              </nz-form-item>

              <h3 nz-title>Anrede</h3>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="displayName" nzRequired>Anzeigename </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="Bitte angeben">
                  <input cdkFocusInitial formControlName="displayName" id="displayName" nz-input placeholder="Anzeigename" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="givenName">Name</nz-form-label>
                <nz-form-control [nzSm]="3" [nzXs]="24">
                  <input formControlName="title" id="title" nz-input placeholder="Titel" />
                </nz-form-control>
                <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="givenNameTip">
                  <input formControlName="givenName" id="givenName" nz-input placeholder="Vorname" />
                  <ng-template #givenNameTip>
                    <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="color: red"></i>
                  </ng-template>
                </nz-form-control>
                <nz-form-control [nzSm]="3" [nzXs]="24">
                  <input formControlName="middleName" id="middleName" nz-input placeholder="Zweitname" />
                </nz-form-control>
                <nz-form-control [nzSm]="3" [nzXs]="24">
                  <input formControlName="surname" id="surname" nz-input placeholder="Nachname" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nickName">Spitzname</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="nickName" id="nickName" nz-input placeholder="Spitzname" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nickName">Initialen</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="initials" id="initials" nz-input placeholder="Initialen" />
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col [nzXl]="{ span: 24 }" [nzLg]="{ span: 24 }" style="margin-bottom: 12px">
              <h3 nz-title>Erreichbarkeit</h3>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mobilePhone">Handy</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <nz-input-group [nzAddOnAfter]="CallMobilePhone">
                    <input type="phone" formControlName="mobilePhone" id="mobilePhone" nz-input placeholder="Handynummer" />
                    <ng-template #CallMobilePhone>
                      <a [href]="'tel:' + mobilePhone?.value" target="_blank">
                        <i nz-icon nzType="phone" nzTheme="outline"></i>
                      </a>
                    </ng-template>
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="homePhones">Privat</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" formArrayName="homePhones" nzErrorTip="Richtige Nummern angeben">
                  <nz-row>
                    <nz-col *ngFor="let control of homePhones.controls; index as i" nzSpan="24" style="margin-bottom: 4px">
                      <nz-input-group [nzAddOnBefore]="DeleteHomePhone" [nzAddOnAfter]="CallHomePhone">
                        <input type="phone" [formControl]="homePhones.controls[i]" nz-input placeholder="Privatnummer" />
                      </nz-input-group>
                      <ng-template #DeleteHomePhone>
                        <a (click)="removeHomePhone(i)">
                          <i style="color: red" nz-icon nzType="delete"></i>
                        </a>
                      </ng-template>
                      <ng-template #CallHomePhone>
                        <a [href]="'tel:' + homePhone(i)" target="_blank">
                          <i nz-icon nzType="phone" nzTheme="outline"></i>
                        </a>
                      </ng-template>
                    </nz-col>
                    <nz-col nzSpan="24">
                      <button (click)="addHomePhone()" nz-button nzType="dashed" type="button">
                        <i nz-icon nzType="plus"></i>
                        Privatnummer hinzufügen
                      </button>
                    </nz-col>
                  </nz-row>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="">Geschäftlich</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" formArrayName="businessPhones" nzErrorTip="Richtige Nummern angeben">
                  <nz-row>
                    <nz-col *ngFor="let control of businessPhones.controls; index as i" nzSpan="24" style="margin-bottom: 4px">
                      <nz-input-group [nzAddOnBefore]="DeleteBusinessPhone" [nzAddOnAfter]="CallBusinessPhone">
                        <input type="phone" [formControl]="businessPhones.controls[i]" nz-input placeholder="Geschäftstelefon" />
                      </nz-input-group>
                      <ng-template #DeleteBusinessPhone>
                        <a (click)="removeBusinessPhone(i)"> <i style="color: red" nz-icon nzType="delete"></i></a>
                      </ng-template>
                      <ng-template #CallBusinessPhone>
                        <a [href]="'tel:' + businessPhone(i)" target="_blank">
                          <i nz-icon nzType="phone" nzTheme="outline"></i>
                        </a>
                      </ng-template>
                    </nz-col>
                    <nz-col nzSpan="24">
                      <button (click)="addBusinessPhone()" nz-button nzType="dashed" type="button">
                        <i nz-icon nzType="plus"></i>
                        Geschäftsnummer hinzufügen
                      </button>
                    </nz-col>
                  </nz-row>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="">E-Mails</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" formArrayName="emailAddresses">
                  <nz-row>
                    <nz-col *ngFor="let control of emailAddresses.controls; index as i" nzSpan="24" style="margin-bottom: 4px">
                      <div [formGroup]="emailAddresses.controls[i]">
                        <nz-form-control>
                          <nz-input-group [nzAddOnBefore]="DeleteEmailAddress" [nzAddOnAfter]="EmailEmailAddress">
                            <input formControlName="name" nz-input placeholder="Art" style="width: 30%" type="text" />
                            <input type="email" formControlName="address" nz-input placeholder="Adresse" style="width: 70%" />
                            <ng-template #DeleteEmailAddress>
                              <a (click)="removeEmailAddress(i)">
                                <i style="color: red" nz-icon nzType="delete"></i>
                              </a>
                            </ng-template>
                            <ng-template #EmailEmailAddress>
                              <a [href]="'mailto:' + emailAddress(i)" target="_blank">
                                <i nz-icon nzType="mail" nzTheme="outline"></i>
                              </a>
                            </ng-template>
                          </nz-input-group>
                        </nz-form-control>
                      </div>
                    </nz-col>
                    <nz-col nzSpan="24">
                      <button (click)="addEmailAddress()" nz-button nzType="dashed" type="button">
                        <i nz-icon nzType="plus"></i>
                        E-Mail-Adresse hinzufügen
                      </button>
                    </nz-col>
                  </nz-row>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="">Messenger</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" formArrayName="imAddresses">
                  <nz-row>
                    <nz-col *ngFor="let control of imAddresses.controls; index as i" nzSpan="24" style="margin-bottom: 4px">
                      <nz-input-group [nzAddOnBefore]="DeleteIMAdress" [nzAddOnAfter]="OpenIMAdress">
                        <input [formControl]="imAddresses.controls[i]" nz-input placeholder="IM-Addresse" type="text" />
                      </nz-input-group>
                      <ng-template #DeleteIMAdress>
                        <a (click)="removeIMAddress(i)">
                          <i i style="color: red" nz-icon nzType="delete"></i>
                        </a>
                      </ng-template>
                      <ng-template #OpenIMAdress>
                        <a [href]="imAddress(i)" target="_blank">
                          <i nz-icon nzType="global" nzTheme="outline"></i>
                        </a>
                      </ng-template>
                    </nz-col>
                    <nz-col nzSpan="24">
                      <button (click)="addIMAddress()" nz-button nzType="dashed" type="button">
                        <i nz-icon nzType="plus"></i>
                        Messenger hinzufügen
                      </button>
                    </nz-col>
                  </nz-row>
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col [nzXl]="{ span: 12 }" [nzLg]="{ span: 24 }" style="margin-bottom: 12px">
              <h3 nz-title>Job</h3>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="companyName">Unternehmen</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="companyName" id="companyName" nz-input placeholder="Unternehmen" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="department">Abteilung</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="department" id="department" nz-input placeholder="Abteilung" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="businessHomePage">Website</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <nz-input-group [nzAddOnAfter]="OpenWebsite">
                    <input type="url" nz-input formControlName="businessHomePage" id="businessHomePage" placeholder="Website" />
                    <ng-template #OpenWebsite>
                      <a [href]="_businessHomePage?.value" target="_blank">
                        <i nz-icon nzType="global" nzTheme="outline"></i>
                      </a>
                    </ng-template>
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="profession">Beruf</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="profession" id="profession" nz-input placeholder="Standort" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="jobTitle">Job</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="jobTitle" id="jobTitle" nz-input placeholder="Job" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="officeLocation">Standort</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="officeLocation" id="officeLocation" nz-input placeholder="Standort" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="manager">Vorgesetzte:r</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="manager" id="manager" nz-input placeholder="Vorgesetzte:r" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="assistantName">Assistenz</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="assistantName" id="assistantName" nz-input placeholder="Assistenz" />
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col [nzXl]="{ span: 12 }" [nzLg]="{ span: 24 }" style="margin-bottom: 12px">
              <h3 nz-title>Privat</h3>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="birthday">Geburtstag</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <nz-date-picker formControlName="birthday" id="birthday" nzFormat="dd.MM.yyyy"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="generation">Generation</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="generation" id="generation" nz-input placeholder="Generation" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="spouseName">Ehegatt:in</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input formControlName="spouseName" id="spouseName" nz-input placeholder="Ehegatt:in" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="">Kinder</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <nz-row formArrayName="children">
                    <nz-col *ngFor="let control of children.controls; index as i" nzSpan="24">
                      <nz-input-group [nzAddOnBefore]="DeleteChild">
                        <input [formControl]="children.controls[i]" nz-input placeholder="Name" type="text" />
                      </nz-input-group>
                      <ng-template #DeleteChild>
                        <button (click)="removeChild(i)" nz-button nzShape="circle" nzType="dashed" type="button">
                          <i i style="color: red" nz-icon nzType="delete"></i>
                        </button>
                      </ng-template>
                    </nz-col>
                    <nz-col nzSpan="24">
                      <button (click)="addChild()" nz-button nzType="dashed" type="button"><i nz-icon nzType="plus"></i>Weiteres Kind</button>
                    </nz-col>
                  </nz-row>
                </nz-form-control>
              </nz-form-item>
            </nz-col>

            <nz-col [nzXl]="{ span: 24 }" [nzLg]="{ span: 24 }">
              <h3 nz-title>Sonstiges</h3>

              <nz-form-item style="width: 100%">
                <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="personalNotes">Notizen</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <textarea formControlName="personalNotes" id="personalNotes" nz-input placeholder="Notizen"></textarea>
                </nz-form-control>
              </nz-form-item>
            </nz-col>
          </nz-row>

          <nz-form-item>
            <nz-form-control [nzOffset]="16">
              <button type="button" (click)="saveContact()" [disabled]="!form.valid" nz-button nzType="primary">
                <i nz-icon nzTheme="outline" nzType="save"></i>
                Speichern
              </button>
            </nz-form-control>
          </nz-form-item>
        </form>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #ContactFooter> </ng-template>
</nz-modal>
