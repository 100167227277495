import { NgModule } from '@angular/core';
import {
  KonstantesFilterComponentModule,
  KonstantesSorterComponentModule,
  KonstantesListComponentModule
} from '.';

@NgModule({
  imports: [
    KonstantesFilterComponentModule,
    KonstantesSorterComponentModule,
    KonstantesListComponentModule
  ],
  exports: [
    KonstantesFilterComponentModule,
    KonstantesSorterComponentModule,
    KonstantesListComponentModule
  ],
})
export class KonstantesQueryModule { }