import { Component, Inject } from '@angular/core';
import { ISitemap, MetaService, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription, combineLatest } from 'rxjs';

import { IProjekteResponse, ProjekteQueryFilterConfig } from 'fa-kt.types';

import { orderBy } from 'lodash';
import { ProjekteService } from '../service';

@Component({
  selector: 'fa-kt-projekte-filter',
  templateUrl: './projekte-filter.component.html',
  styleUrls: ['./projekte-filter.component.css'],
})
export class ProjekteFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public ProjekteFilterConfig: IFilterConfig<IProjekteResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public projekte: ProjekteService,
    public meta: MetaService,
  ) {
    this.ProjekteFilterConfig = ProjekteQueryFilterConfig.filter((filter) =>
      [
        'projekt.projektArt',
        'projekt.projektStatus',
        'projekt.standort',
        'projekt.beauftragung',
        'projekt.deadline',
        'projekt.keineBesichtigungErforderlich',
        'projekt.besichtigungen',
        'projekt.gutachten',
        'projekt.pruefung',
        'projekt.partnerInnen',
        'projekt.kunde',
        'projekt.abteilung',
        'projekt.fond',
        'projekt.bank',
        'projekt.fortschritt',
        'gutachten.objektArt',
        'gutachten.bewertungsStatus',
        'projekt.bewertungsAnlass',
        'besichtigungen.besichtigungsStatus',
        'besichtigungen.von',
        'projekt.kostenNachStunden',
        'projekt.kosten',
        'projekt.kostenBeglichen',
      ].includes(filter.key),
    );

    this.ProjekteFilterConfig = this.ProjekteFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    this.ProjekteFilterConfig = orderBy(this.ProjekteFilterConfig, 'title');
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([this.projekte.shapes$, this.meta.users$]).subscribe(([shapes, users]) => {
        if (!shapes) return;
        this.projekte.replacements.forEach((r) => {
          Object.entries(r)
            .filter(([key]) => shapes[key])
            .forEach(([key, value]) => (shapes[value] = shapes[key]));
        });
        shapes['projekt.gutachten'] = users;
        shapes['projekt.prefung'] = users;
        shapes['projekt.gutachten'] = users;
        shapes['projekt.partnerInnen'] = users.filter(({ rolle }) => rolle.includes('1e19f5e5-20f4-4ed0-9a72-42701c006f49'));
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.ProjekteFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.ProjekteFilterConfig[index].selections = shapes;
  }
}
