import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostExtraVereinbarungCommandShapes, IPostExtraVereinbarungRequest, IPostExtraVereinbarungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostExtraVereinbarungCommandService extends PostCommandService<IPostExtraVereinbarungRequest, IPostExtraVereinbarungResponse, IPostExtraVereinbarungCommandShapes> {
  readonly route = 'finanzen/extra-vereinbarung';
}
