import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteStandortCommandServiceModule } from '..';
import { DeleteStandortCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteStandortCommandServiceModule
  ],
  declarations: [DeleteStandortCommandComponent],
  exports: [DeleteStandortCommandComponent],

})
export class DeleteStandortCommandComponentModule { }
