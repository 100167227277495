import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { ITourStatiQueryFilter, ITourStatiQueryShapes, ITourStatiQuerySorter, ITourStatiRequest, ITourStatiResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class TourStatiService extends SettingsQueryService<ITourStatiRequest, ITourStatiResponse, ITourStatiQueryShapes, ITourStatiQueryFilter, ITourStatiQuerySorter> {
  contentKey: keyof ITourStatiResponse = 'tourStati';
  route = 'besichtigungen/settings/tour-stati';
  override dependencies = ['besichtigungen/written-tour-status', 'besichtigungen/removed-tour-status'];

  constructor() {
    super();
  }
}
