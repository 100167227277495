import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { groupBy } from 'lodash';

import * as atlas from 'azure-maps-control';
import * as atlasDrawing from 'azure-maps-drawing-tools';

import { AuthenticationType, ControlPosition, ControlStyle } from 'azure-maps-control';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { IAzureMapsCircle, IAzureMapsPosition, IAzureMapsRoute, IAzureMapsSuggestion } from 'pbc.types';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MonitoringService, SecretService } from '../../..';
import { AtlasService } from '../../services';

@Component({
  selector: 'pbc-atlas-map',
  templateUrl: './atlas-map.component.html',
  styleUrls: ['./atlas-map.component.css'],
})
export class AtlasMapComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  public readonly $initializing = new BehaviorSubject<boolean>(true);
  private subscriptionKey: string | undefined = undefined;

  @Input() width: string = '0';
  @Input() height: string = '0';

  @ViewChild('AddressMap') mapRef: ElementRef<HTMLDivElement> | undefined;
  map: atlas.Map | undefined;
  creating = false;

  circleDataSource: atlas.source.DataSource | undefined;
  bounds?: atlas.data.BoundingBox;
  layers: string[] = [];
  zoom: number | undefined = undefined;

  @Input() mode: 'address' | 'addresses' | 'draw' | 'routes' = 'address';
  @Input() set resize(date: Date) {
    if (this.map) {
      this.bounds = undefined;
      this.map.resize();
      this.$initializing.next(false);
    }
  }

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() moved: EventEmitter<IAzureMapsPosition> = new EventEmitter<IAzureMapsPosition>();
  @Output() drew: EventEmitter<atlas.Shape> = new EventEmitter<atlas.Shape>();

  @Input() set coordinateArray(coordinateArray: 'clear' | IAzureMapsPosition[]) {
    this.createMap();
    this.$coordinateArray.next(coordinateArray);
  }
  get coordinateArray(): IAzureMapsPosition[] | 'clear' {
    return this.$coordinateArray.getValue();
  }
  $coordinateArray = new BehaviorSubject<IAzureMapsPosition[] | 'clear'>([]);

  @Input() set coordinates(coordinates: IAzureMapsPosition | undefined | null) {
    this.createMap();
    if (coordinates)
      this.$coordinates.next({
        draggable: false,
        ...(coordinates as IAzureMapsPosition),
      });
    else this.$coordinates.next(undefined);
  }
  get coordinates() {
    return this.$coordinates.getValue() as IAzureMapsPosition;
  }
  $coordinates = new BehaviorSubject<IAzureMapsPosition | undefined>(undefined);

  $routes = new BehaviorSubject<'clear' | IAzureMapsRoute[]>([]);
  routesInMap: string[] = [];
  @Input() set routes(routes: 'clear' | IAzureMapsRoute[]) {
    this.createMap();
    this.$routes.next(routes);
  }
  get routes(): 'clear' | IAzureMapsRoute[] {
    return this.$routes.getValue();
  }

  @Input() set circle(circle: 'clear' | IAzureMapsCircle) {
    this.createMap();
    this.$circle.next(circle);
  }
  get circle(): 'clear' | IAzureMapsCircle {
    return this.$circle.getValue() as IAzureMapsCircle;
  }
  $circle = new BehaviorSubject<'clear' | IAzureMapsCircle | undefined>(undefined);

  drawingManager: atlasDrawing.drawing.DrawingManager | undefined;
  drawing = false;
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $suggestions: BehaviorSubject<IAzureMapsSuggestion[]> = new BehaviorSubject<IAzureMapsSuggestion[]>([]);

  $geometry = new BehaviorSubject<any>(undefined);
  @Input() set geometry(geometry: any) {
    this.createMap();
    this.$geometry.next(geometry);
  }
  get geometry() {
    return this.$geometry.getValue();
  }

  constructor(
    private secrets: SecretService,
    private atlas: AtlasService,
    private monitoring: MonitoringService,
  ) {
    this.secrets.$secrets.subscribe((secrets) => {
      if (secrets && secrets.maps) {
        this.subscriptionKey = secrets.maps.key;
        this.createMap();
      }
    });
  }

  ngOnInit(): void {
    this.bounds = undefined;
    this.subscriptions.push(
      ...[
        combineLatest([this.$initializing, this.$coordinates, this.$coordinateArray]).subscribe(([init, coordinate, coordinates]) => {
          this.map?.resize();
          this.setZoom(init, coordinate, coordinates);
        }),
        this.search$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(async (term: string) => {
          const suggestions = await this.atlas.suggestByTerm(term, []);
          if (suggestions && suggestions.length > 0) this.setSuggestion(suggestions[0]);
          this.$suggestions.next(suggestions);
        }),
        this.$coordinates.subscribe((coordinates) => this.setPoint(coordinates)),
        this.$coordinateArray.subscribe(async (coordinateArray) => this.setPoints(coordinateArray)),
        this.$circle.subscribe((circle) => this.setCircle(circle)),
        this.$routes.subscribe((routes) => this.setRoutes(routes)),
      ],
    );
  }

  ngAfterViewInit() {
    this.createMap();
  }

  ngOnDestroy(): void {
    this.bounds = undefined;
    this.map?.dispose();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private createMap() {
    if (!this.subscriptionKey) {
      console.error('No Subscription Key for Azure Maps');
      return;
    }
    if (this.map || this.creating) return;
    if (this.mapRef) {
      this.creating = true;
      const map = new atlas.Map(this.mapRef.nativeElement, {
        center: [0, 0],
        view: 'Auto',
        language: 'de-DE',
        showLogo: false,
        showFeedbackLink: false,
        showBuildingModels: true,
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: this.subscriptionKey,
        },
      });

      map.events.add('zoomend', ($event) => {
        if (this.map) {
          this.zoom = this.map.getCamera().zoom as number;
        }
      });

      map.events.add('ready', async () => {
        map.controls.add(
          new atlas.control.ZoomControl({
            style: ControlStyle.auto,
            zoomDelta: 1,
          }),
          {
            position: ControlPosition.BottomLeft,
          },
        );
        map.controls.add(new atlas.control.CompassControl(), {
          position: ControlPosition.BottomLeft,
        });
        map.setCamera({
          duration: 1000,
          type: 'fly',
        });
        switch (this.mode) {
          case 'address':
          case 'addresses':
          case 'routes':
            map.controls.add(
              new atlas.control.StyleControl({
                mapStyles: ['road', 'grayscale_dark', 'night', 'road_shaded_relief', 'satellite', 'satellite_road_labels'],
              }),
              {
                position: ControlPosition.TopRight,
              },
            );
            map.controls.add(new atlas.control.PitchControl(), {
              position: ControlPosition.BottomLeft,
            });
            break;
          case 'draw':
            this.drawingManager = new atlasDrawing.drawing.DrawingManager(
              map as any,
              {
                mode: 'idle',
                interactionType: 'freehand',
              } as any,
            );
            (map as atlas.Map).events.add('drawingcomplete' as any, (evt) => {
              this.toggleDrawing();
            });
            break;
        }
        this.map = map;

        if (this.coordinates) this.setPoint(this.coordinates);
        if (this.coordinateArray) this.setPoints(this.coordinateArray);
        if (this.routes) this.setRoutes(this.routes);
        if (this.circle) this.setCircle(this.circle);
        if (this.geometry) this.setGeometry(this.geometry);
        this.$initializing.next(false);
      });
    }
  }
  setZoom(init: boolean, coordinate: IAzureMapsPosition | undefined, coordinates: IAzureMapsPosition[] | 'clear') {
    if (init || !this.map) return;
    if (coordinate && coordinate.lon && coordinate.lat) {
      this.map?.setCamera({
        zoom: 13,
        center: [coordinate.lon, coordinate.lat],
      });
      return;
    } else if (coordinates !== 'clear' && coordinates.length === 1) {
      this.map?.setCamera({
        zoom: 13,
        center: [coordinates[0].lon, coordinates[0].lat],
      });
      return;
    } else if (!this.bounds && coordinates !== 'clear') {
      const boundsPositions: Array<{ lng: number; lat: number }> = [];
      coordinates
        .filter((coordinate) => coordinate && coordinate.lon && coordinate.lat)
        .forEach((coordinate) => {
          boundsPositions.push({
            lng: coordinate.lon!,
            lat: coordinate.lat!,
          });
        });
      this.bounds = atlas.data.BoundingBox.fromLatLngs(boundsPositions);
      this.map.setCamera({ bounds: this.bounds, padding: 50 });
    }
  }

  setPoint(coordinate?: IAzureMapsPosition) {
    if (!this.map) return;
    this.map.markers.clear();
    if (!coordinate) {
      return;
    }
    let position = [0, 0];
    if (coordinate && coordinate.lon && coordinate.lat && !isNaN(coordinate.lon) && !isNaN(coordinate.lat)) {
      position = [coordinate.lon, coordinate.lat];
      const marker = new atlas.HtmlMarker({
        draggable: coordinate.draggable,
        position,
      });
      this.map.events.add('dragend', marker, async (e) => {
        const position = marker.getOptions().position;
        if (position) {
          const [lon, lat] = position;
          this.moved.emit({ lat, lon });
        }
      });
      this.map?.events.add('click', marker, (e) => {
        this.clicked.emit(coordinate.object);
      });
      this.map.markers.add(marker);
    }
  }

  setPoints(coordinates: 'clear' | IAzureMapsPosition[]) {
    if (!this.map) return;

    this.layers.forEach((layer) => {
      try {
        this.map?.layers.remove(layer);
        this.layers = this.layers.filter((f) => f !== layer);
      } catch (error) {
        console.error('AtlasMapComponent', error);
      }
    });
    this.layers = [];

    if (coordinates === 'clear') return;

    coordinates = coordinates.map((c) => ({ ...c, color: c.color || '#000' }));

    ['default', 'home', 'selected', 'open'].forEach((icon) => {
      Object.entries(
        groupBy(
          (coordinates as IAzureMapsPosition[]).filter(({ type }) => type === icon || (icon === 'default' && !type)),
          (item) => {
            return [item.color, item.secondaryColor];
          },
        ),
      )
        .filter(([, coordinates]) => coordinates?.length)
        .forEach(async ([colors, coordinates]) => {
          try {
            let [color, secondaryColor] = colors.split(',');
            if (!secondaryColor || secondaryColor === 'undefined') secondaryColor = '#FFFFFF';
            const image = icon + ' - icon-' + color + ' - ' + secondaryColor;
            try {
              let type = 'marker';
              switch (icon) {
                case 'home':
                  type = 'flag-triangle';
                  break;
                case 'selected':
                  type = 'marker-ball-pin';
                  break;
                case 'open':
                  type = 'pin-round';
                  break;
              }
              if (!this.map?.imageSprite.hasImage(image)) await this.map?.imageSprite.createFromTemplate(image, type, color, secondaryColor, icon === 'home' ? 0.5 : 0.8);
            } catch (error) {
              console.error('AtlasMapComponent', error);
            }
            const dataSource = new atlas.source.DataSource();
            this.map?.sources.add(dataSource);
            const symbolLayer = new atlas.layer.SymbolLayer(dataSource, image, {
              iconOptions: {
                allowOverlap: true,
                image,
              },
            });
            dataSource.add(
              coordinates
                .filter(({ lon, lat }) => !!lon && !!lat)
                .map(({ lon, lat, object }) => new atlas.Shape(new atlas.data.Point([lon!, lat!]), undefined, object || {}))
                .filter((p) => !!p),
            );
            if (icon !== 'selected') {
              this.map?.events.add('click', symbolLayer, (e) => {
                this.clicked.emit((<any>e.shapes?.[0])?.data.properties);
              });
            }
            this.map?.layers.add(symbolLayer);
            this.layers.push(image);
          } catch (error) {
            console.error('AtlasMapComponent', error);
          }
        });
    });
  }

  private setRoutes(routes: IAzureMapsRoute[] | 'clear') {
    this.routesInMap.forEach((id) => {
      try {
        this.map?.layers.remove(id);
      } catch (err) {
        this.monitoring.logException(err);
      }
    });
    this.routesInMap = [];

    if (!this.map || routes === 'clear') return;

    for (const route of routes) {
      const dataSource = new atlas.source.DataSource();
      this.map.sources.add(dataSource);
      let coordinates: any[] = [];
      for (const leg of route.legs ? route.legs : []) {
        if (leg.points) {
          coordinates = coordinates.concat(
            leg.points.map((point) => {
              return [point.longitude, point.latitude];
            }),
          );
        }
      }
      const feature = new atlas.data.Feature(new atlas.data.LineString(coordinates));
      dataSource.add(feature as any);
      const id = 'LineLayer' + route.summary?.lengthInMeters;
      const layer = new atlas.layer.LineLayer(dataSource, id, {
        strokeColor: route.color,
        strokeWidth: 3,
      });
      this.map?.events.add('click', layer, (e) => {
        this.clicked.emit(route.object);
      });
      this.map.layers.add(layer);
      this.routesInMap.push(id);
    }
  }

  private setCircle(circle?: 'clear' | IAzureMapsCircle) {
    if (!this.map || !circle) return;
    if (!this.circleDataSource) {
      this.circleDataSource = new atlas.source.DataSource();
      this.map?.sources.add(this.circleDataSource);
      this.map?.layers.add(
        new atlas.layer.PolygonLayer(this.circleDataSource as atlas.source.DataSource, 'circleLayer', {
          fillColor: circle && circle !== 'clear' ? circle.color : 'rgba(0, 200, 200, 0.5)',
        }),
      );
    } else {
      this.circleDataSource.clear();
    }

    if (circle === 'clear') return;

    this.circleDataSource?.add(
      new atlas.data.Feature(new atlas.data.Point([circle.lon, circle.lat]), {
        subType: 'Circle',
        radius: circle.radius,
      }),
    );
    this.bounds = undefined;
    this.map?.setCamera({ center: [circle.lon, circle.lat] });
  }

  private setGeometry(geometry: any) {
    this.drawingManager?.edit(
      new atlas.Shape({
        type: 'Feature',
        geometry,
      }) as any,
    );
  }

  toggleDrawing() {
    this.drawing = !this.drawing;
    if (this.drawingManager && this.drawing) {
      const source = (this.drawingManager as atlasDrawing.drawing.DrawingManager).getSource();
      source.clear();
      this.drawingManager.setOptions({ mode: 'draw-polygon' } as any);
    } else if (this.drawingManager) {
      this.drawingManager.setOptions({ mode: 'idle' } as any);
      this.drawing = false;
      const source = (this.drawingManager as atlasDrawing.drawing.DrawingManager).getSource();
      const geometry = source['shapes'][0].data.geometry;
      this.drew.emit(JSON.parse(JSON.stringify(geometry)));
      source.clear();
      this.setGeometry(geometry);
    }
  }

  setSuggestion(suggestion: IAzureMapsSuggestion) {
    const center = [suggestion.position.lon, suggestion.position.lat];
    this.map?.setCamera({ zoom: 16, center });
  }

  reset() {
    if (this.map) {
      this.map.clear();
      this.map.dispose();
    }
    this.map = undefined;
    this.creating = false;
    this.createMap();
  }
}
