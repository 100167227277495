import { Injectable } from '@angular/core';

import { IDeleteBerichtRequest, IDeleteBerichtResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteBerichtCommandService extends DeleteCommandService<IDeleteBerichtRequest, IDeleteBerichtResponse> {
  readonly route = 'markt/bericht';
}
