import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IProjektArtsQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   initialen?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const ProjektArtsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'PROJEKTE.Models.PROJEKT_ART.Propertys.ID.title'),
   SorterConfig('name', 'PROJEKTE.Models.PROJEKT_ART.Propertys.NAME.title'),
   SorterConfig('initialen', 'PROJEKTE.Models.PROJEKT_ART.Propertys.INITIALEN.title'),
   SorterConfig('beschreibung', 'PROJEKTE.Models.PROJEKT_ART.Propertys.BESCHREIBUNG.title'),
];
