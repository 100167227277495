<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="formular.search$.next($event)" [search]="formular.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-formular-filter [style.width]="'100%'"></fa-kt-formular-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-formular-sorter [style.width]="'100%'"></fa-kt-formular-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-formular-list [style.width]="'100%'" (select)="setFormular($event)" [loading]="$loading | async"></fa-kt-formular-list>


  <fa-kt-delete-formular [style.width]="'100%'"  [value]="$deleteFormular | async" (finished)="finishedDeleteFormular($event)"></fa-kt-delete-formular>

  <fa-kt-post-formular-kopieren [style.width]="'100%'"  [value]="$postFormularKopieren | async" (finished)="finishedPostFormularKopieren($event)"></fa-kt-post-formular-kopieren>

  <fa-kt-post-formular [style.width]="'100%'"  [value]="$postFormular | async" (finished)="finishedPostFormular($event)"></fa-kt-post-formular>

  <fa-kt-post-durchgang [style.width]="'100%'"  [value]="$postDurchgang | async" (finished)="finishedPostDurchgang($event)"></fa-kt-post-durchgang>



    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="felder" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="formulare" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="feld" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="formular" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="durchgang" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <div *nzSpaceItem>
        <button nz-button nzType="default" (click)="submitPostFormularKopieren($postFormularKopieren.getValue())">
          <i nz-icon nzType="copy" nzTheme="outline"></i>
          Kopieren
        </button>
      </div>
      <div *nzSpaceItem>
        <fa-kt-delete-formular [style.width]="'100%'" [value]="$deleteFormular | async" (finished)="finishedDeleteFormular()"></fa-kt-delete-formular>
      </div>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <fa-kt-post-formular [style.width]="'100%'" [value]="$postFormular | async" (finished)="finishedPostFormular()"></fa-kt-post-formular>
      <pbc-meta [value]="($postFormular | async)?.formular"></pbc-meta>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
