import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProjekteResponseRow } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { ProjekteService } from '../service';

@Component({
  selector: 'fa-kt-projekte-list',
  templateUrl: './projekte-list.component.html',
  styleUrls: ['./projekte-list.component.css'],
})
export class ProjekteListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  /* << props    */
  @Input() isFinanzen = false;
  /*    props >> */

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public projekte: ProjekteService) {}

  public set(projekte: IProjekteResponseRow) {
    /* << custom    */
    this.select.emit(projekte.projekt.id);
    /*    custom >> */
  }

  /* << functions    */
  /*    functions >> */
}
