import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { GutachtenServiceModule } from '..';
import { SavedGutachtenFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenServiceModule
  ],
  declarations: [SavedGutachtenFiltersComponent],
  exports: [SavedGutachtenFiltersComponent],

})
export class SavedGutachtenFiltersComponentModule { }
