import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { ErforderlichesDokumentsQuerySorterConfig } from 'fa-kt.types';
import { ErforderlichesDokumentsService } from '../service';

@Component({
  selector: 'fa-kt-erforderliches-dokuments-sorter',
  templateUrl: './erforderliches-dokuments-sorter.component.html',
  styleUrls: ['./erforderliches-dokuments-sorter.component.css'],
})
export class ErforderlichesDokumentsSorterComponent {
  ErforderlichesDokumentsSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public erforderlichesDokuments: ErforderlichesDokumentsService,
  ) {
    this.ErforderlichesDokumentsSorterConfig = ErforderlichesDokumentsQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    /*    update >> */
  }
}
