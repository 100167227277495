import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IGutachtenStatiQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const GutachtenStatiQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'GUTACHTEN.Models.GUTACHTEN_STATUS.Propertys.ID.title'),
   SorterConfig('name', 'GUTACHTEN.Models.GUTACHTEN_STATUS.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'GUTACHTEN.Models.GUTACHTEN_STATUS.Propertys.BESCHREIBUNG.title'),
];
