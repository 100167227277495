import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IObjektSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  addresse?: ISorterExpression;
  bereiche?: ISorterExpression;
  anspracheOutlookKontakts?: ISorterExpression;
  klingelschild?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const ObjektSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('addresse', 'Addresse'),
  SorterConfig('bereiche', 'Bereiche'),
  SorterConfig('anspracheOutlookKontakts', 'Ansprache Outlook Kontakts'),
  SorterConfig('klingelschild', 'Klingelschild'),
  SorterConfig('kommentar', 'Kommentar')
];
