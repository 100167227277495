import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteBerichtsVorlageRequest, IDeleteBerichtsVorlageResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteBerichtsVorlageCommandService extends DeleteCommandService<IDeleteBerichtsVorlageRequest, IDeleteBerichtsVorlageResponse> {
  readonly route = 'texte/berichts-vorlage';
}
