import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostKalkulationHochladenCommandShapes, IPostKalkulationHochladenRequest, IPostKalkulationHochladenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostKalkulationHochladenCommandService extends PostCommandService<IPostKalkulationHochladenRequest, IPostKalkulationHochladenResponse, IPostKalkulationHochladenCommandShapes> {
  readonly route = 'gutachten/kalkulation-hochladen';

  override async request(payload: IPostKalkulationHochladenRequest): Promise<IPostKalkulationHochladenResponse> {
    return (await this.http.upload<IPostKalkulationHochladenResponse>(this.route, payload, 'kalkulationen')) as unknown as IPostKalkulationHochladenResponse;
  }
}
