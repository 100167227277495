import { NgModule } from '@angular/core';

import {
  DeleteHerausgeberCommandServiceModule,
  DeleteHerausgeberCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteHerausgeberCommandServiceModule,
    DeleteHerausgeberCommandComponentModule
  ],
  exports: [
    DeleteHerausgeberCommandServiceModule,
    DeleteHerausgeberCommandComponentModule
  ]
})
export class DeleteHerausgeberCommandModule { }
