import { NgModule } from '@angular/core';

import {
  PostGutachtenStatusCommandServiceModule,
  PostGutachtenStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostGutachtenStatusCommandServiceModule,
    PostGutachtenStatusCommandComponentModule
  ],
  exports: [
    PostGutachtenStatusCommandServiceModule,
    PostGutachtenStatusCommandComponentModule
  ]
})
export class PostGutachtenStatusCommandModule { }
