import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostProjektStatusCommandServiceModule } from '..';
import { PostProjektStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostProjektStatusCommandServiceModule
  ],
  declarations: [PostProjektStatusCommandComponent],
  exports: [PostProjektStatusCommandComponent],

})
export class PostProjektStatusCommandComponentModule { }
