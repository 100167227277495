import { Injectable } from '@angular/core';

import { DetailQueryService } from 'pbc.angular';

import { IUrlaubQueryShapes, IUrlaubRequest, IUrlaubResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class UrlaubService extends DetailQueryService<IUrlaubRequest, IUrlaubResponse, IUrlaubQueryShapes> {
  route = 'zeiten/urlaub';
  override dependencies = ['zeiten/urlaub-gespeichert', 'zeiten/urlaub-entfernt'];
}
