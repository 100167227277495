import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  DateFilterConfig,
  GeoRangeFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IDateFilter,
  IFilter,
  IFilterConfig,
  IGeoRangeFilter,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IAbteilung, IBesichtigung, IExtraVereinbarung, IFond, IHonorarVereinbarung, IKunde, IMitarbeiter, IProjektArt, IProjektStatus, IRechnung, IStandort } from 'fa-kt.types';
import { IProjektResponse } from '.';

export interface IProjektQueryFilter extends IFilter<IProjektResponse> {
  'projekt.projektArt'?: ISingleReferenceFilter<IProjektArt>;
  'projekt.projektStatus'?: ISingleReferenceFilter<IProjektStatus>;
  'projekt.standort'?: ISingleReferenceFilter<IStandort>;
  'projekt.beauftragung'?: IDateFilter;
  'projekt.deadline'?: IDateFilter;
  'projekt.keineBesichtigungErforderlich'?: IBooleanFilter;
  'projekt.besichtigungen'?: IArrayReferenceFilter<IMitarbeiter>;
  'projekt.gutachten'?: IArrayReferenceFilter<IMitarbeiter>;
  'projekt.pruefung'?: ISingleReferenceFilter<IMitarbeiter>;
  'projekt.kunde'?: ISingleReferenceFilter<IKunde>;
  'projekt.abteilung'?: ISingleReferenceFilter<IAbteilung>;
  'projekt.fond'?: ISingleReferenceFilter<IFond>;
  'projekt.bank'?: ISingleReferenceFilter<IKunde>;
  'projekt.fortschritt'?: INumberFilter;
  // 'projekt.strecke'?: INumberFilter;
  // 'projekt.kalkulierteStrecke'?: INumberFilter;
  // 'projekt.fahrtzeit'?: INumberFilter;
  // 'projekt.kalkulierteFahrtzeit'?: INumberFilter;
  'projekt.stunden'?: INumberFilter;
  'projekt.kostenNachStunden'?: INumberFilter;
  'projekt.verhandeltesHonorar'?: INumberFilter;
  'projekt.kosten'?: INumberFilter;
  'projekt.kostenBeglichen'?: INumberFilter;
  'projekt.aktiv'?: IBooleanFilter;
  'standort.addresse'?: IGeoRangeFilter;
  'vermerke.verfasser'?: ISingleReferenceFilter<IMitarbeiter>;
  'vermerke.externSichtbar'?: IBooleanFilter;
  'erinnerungen.am'?: IDateFilter;
  'erinnerungen.mitarbeiter'?: IArrayReferenceFilter<IMitarbeiter>;
  'erinnerungen.verfasser'?: ISingleReferenceFilter<IMitarbeiter>;
  'rechnungen.rechnungZaehler'?: INumberFilter;
  'rechnungen.datum'?: IDateFilter;
  'rechnungen.honorarVereinbarung'?: ISingleReferenceFilter<IHonorarVereinbarung>;
  'rechnungen.extraVereinbarungs'?: IArrayReferenceFilter<IExtraVereinbarung>;
  'rechnungen.stunden'?: INumberFilter;
  'rechnungen.anschlaege'?: INumberFilter;
  'rechnungen.seiten'?: INumberFilter;
  'rechnungen.fotos'?: INumberFilter;
  'rechnungen.strecke'?: INumberFilter;
  'rechnungen.fahrtzeit'?: INumberFilter;
  'rechnungen.besichtigungen'?: IArrayReferenceFilter<IBesichtigung>;
  'rechnungen.mwstSatz'?: INumberFilter;
  'rechnungen.summe'?: INumberFilter;
  'zahlungseingaenge.rechnung'?: ISingleReferenceFilter<IRechnung>;
  'zahlungseingaenge.summe'?: INumberFilter;
  'zahlungseingaenge.datum'?: IDateFilter;
}

export const ProjektQueryFilterConfig: IFilterConfig<IProjektResponse> = [
  SingleReferenceFilterConfig<IProjektArt>('projekt.projektArt', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ART.title'),
  SingleReferenceFilterConfig<IProjektStatus>('projekt.projektStatus', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_STATUS.title'),
  SingleReferenceFilterConfig<IStandort>('projekt.standort', 'PROJEKTE.Models.PROJEKT.Propertys.STANDORT.title'),
  DateFilterConfig('projekt.beauftragung', 'PROJEKTE.Models.PROJEKT.Propertys.BEAUFTRAGUNG.title'),
  DateFilterConfig('projekt.deadline', 'PROJEKTE.Models.PROJEKT.Propertys.DEADLINE.title'),
  BooleanFilterConfig('projekt.keineBesichtigungErforderlich', 'PROJEKTE.Models.PROJEKT.Propertys.KEINE_BESICHTIGUNG_ERFORDERLICH.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('projekt.besichtigungen', 'PROJEKTE.Models.PROJEKT.Propertys.BESICHTIGUNGEN.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('projekt.gutachten', 'PROJEKTE.Models.PROJEKT.Propertys.GUTACHTEN.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('projekt.pruefung', 'PROJEKTE.Models.PROJEKT.Propertys.PRUEFUNG.title'),
  SingleReferenceFilterConfig<IKunde>('projekt.kunde', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDE.title'),
  SingleReferenceFilterConfig<IAbteilung>('projekt.abteilung', 'PROJEKTE.Models.PROJEKT.Propertys.ABTEILUNG.title'),
  SingleReferenceFilterConfig<IFond>('projekt.fond', 'PROJEKTE.Models.PROJEKT.Propertys.FOND.title'),
  SingleReferenceFilterConfig<IKunde>('projekt.bank', 'PROJEKTE.Models.PROJEKT.Propertys.BANK.title'),
  NumberFilterConfig('projekt.fortschritt', 'PROJEKTE.Models.PROJEKT.Propertys.FORTSCHRITT.title'),
  // NumberFilterConfig('projekt.strecke', 'PROJEKTE.Models.PROJEKT.Propertys.STRECKE.title'),
  // NumberFilterConfig('projekt.kalkulierteStrecke', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_STRECKE.title'),
  // NumberFilterConfig('projekt.fahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.FAHRTZEIT.title'),
  // NumberFilterConfig('projekt.kalkulierteFahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_FAHRTZEIT.title'),
  NumberFilterConfig('projekt.stunden', 'PROJEKTE.Models.PROJEKT.Propertys.STUNDEN.title'),
  NumberFilterConfig('projekt.kostenNachStunden', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_NACH_STUNDEN.title'),
  NumberFilterConfig('projekt.verhandeltesHonorar', 'PROJEKTE.Models.PROJEKT.Propertys.VERHANDELTES_HONORAR.title'),
  NumberFilterConfig('projekt.kosten', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN.title'),
  NumberFilterConfig('projekt.kostenBeglichen', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_BEGLICHEN.title'),
  BooleanFilterConfig('projekt.aktiv', 'PROJEKTE.Models.PROJEKT.Propertys.AKTIV.title'),
  GeoRangeFilterConfig('standort.addresse', 'PERSONEN.Models.STANDORT.Propertys.ADDRESSE.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('vermerke.verfasser', 'KOMMUNIKATION.Models.VERMERK.Propertys.VERFASSER.title'),
  BooleanFilterConfig('vermerke.externSichtbar', 'KOMMUNIKATION.Models.VERMERK.Propertys.EXTERN_SICHTBAR.title'),
  DateFilterConfig('erinnerungen.am', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.AM.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('erinnerungen.mitarbeiter', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.MITARBEITER.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('erinnerungen.verfasser', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.VERFASSER.title'),
  NumberFilterConfig('rechnungen.rechnungZaehler', 'FINANZEN.Models.RECHNUNG.Propertys.RECHNUNG_ZAEHLER.title'),
  DateFilterConfig('rechnungen.datum', 'FINANZEN.Models.RECHNUNG.Propertys.DATUM.title'),
  SingleReferenceFilterConfig<IHonorarVereinbarung>('rechnungen.honorarVereinbarung', 'FINANZEN.Models.RECHNUNG.Propertys.HONORAR_VEREINBARUNG.title'),
  ArrayReferenceFilterConfig<IExtraVereinbarung>('rechnungen.extraVereinbarungs', 'FINANZEN.Models.RECHNUNG.Propertys.EXTRA_VEREINBARUNGS.title'),
  NumberFilterConfig('rechnungen.stunden', 'FINANZEN.Models.RECHNUNG.Propertys.STUNDEN.title'),
  NumberFilterConfig('rechnungen.anschlaege', 'FINANZEN.Models.RECHNUNG.Propertys.ANSCHLAEGE.title'),
  NumberFilterConfig('rechnungen.seiten', 'FINANZEN.Models.RECHNUNG.Propertys.SEITEN.title'),
  NumberFilterConfig('rechnungen.fotos', 'FINANZEN.Models.RECHNUNG.Propertys.FOTOS.title'),
  NumberFilterConfig('rechnungen.strecke', 'FINANZEN.Models.RECHNUNG.Propertys.STRECKE.title'),
  NumberFilterConfig('rechnungen.fahrtzeit', 'FINANZEN.Models.RECHNUNG.Propertys.FAHRTZEIT.title'),
  ArrayReferenceFilterConfig<IBesichtigung>('rechnungen.besichtigungen', 'FINANZEN.Models.RECHNUNG.Propertys.BESICHTIGUNGEN.title'),
  NumberFilterConfig('rechnungen.mwstSatz', 'FINANZEN.Models.RECHNUNG.Propertys.MWST_SATZ.title'),
  NumberFilterConfig('rechnungen.summe', 'FINANZEN.Models.RECHNUNG.Propertys.SUMME.title'),
  SingleReferenceFilterConfig<IRechnung>('zahlungseingaenge.rechnung', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.RECHNUNG.title'),
  NumberFilterConfig('zahlungseingaenge.summe', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.SUMME.title'),
  DateFilterConfig('zahlungseingaenge.datum', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.DATUM.title'),
];

export interface IProjektQueryShapes extends IShape {
  'projekt.projektArt': ISelection[];
  'projekt.projektStatus': ISelection[];
  'projekt.standort': ISelection[];
  'projekt.besichtigungen': ISelection[];
  'projekt.gutachten': ISelection[];
  'projekt.pruefung': ISelection[];
  'projekt.kunde': ISelection[];
  'projekt.abteilung': ISelection[];
  'projekt.fond': ISelection[];
  'projekt.bank': ISelection[];
  'projekt.objektArt': ISelection[];
  'vermerke.verfasser': ISelection[];
  'erinnerungen.mitarbeiter': ISelection[];
  'erinnerungen.verfasser': ISelection[];
  'rechnungen.honorarVereinbarung': ISelection[];
  'rechnungen.extraVereinbarungs': ISelection[];
  'rechnungen.besichtigungen': ISelection[];
  'zahlungseingaenge.rechnung': ISelection[];
}

export type IProjektQueryField =
  | 'projekt.id'
  | 'projekt.bezeichnung'
  | 'projekt.nummer'
  | 'projekt.projektZaehler'
  | 'projekt.projektArt'
  | 'projekt.projektStatus'
  | 'projekt.standort'
  | 'projekt.beauftragung'
  | 'projekt.deadline'
  | 'projekt.kundenAnfertigung'
  | 'projekt.keineBesichtigungErforderlich'
  | 'projekt.besichtigungen'
  | 'projekt.gutachten'
  | 'projekt.pruefung'
  | 'projekt.kunde'
  | 'projekt.abteilung'
  | 'projekt.fond'
  | 'projekt.bank'
  | 'projekt.objektArt'
  | 'projekt.nachlass'
  | 'projekt.kundenZeichen'
  | 'projekt.kontakteInOutlook'
  | 'projekt.dateiVerzeichnis'
  | 'projekt.kommentar'
  | 'projekt.notizenFuerRechnung'
  | 'projekt.fortschritt'
  // | 'projekt.strecke'
  // | 'projekt.kalkulierteStrecke'
  // | 'projekt.fahrtzeit'
  // | 'projekt.kalkulierteFahrtzeit'
  | 'projekt.stunden'
  | 'projekt.kostenNachStunden'
  | 'projekt.verhandeltesHonorar'
  | 'projekt.kosten'
  | 'projekt.kostenBeglichen'
  | 'projekt.aktiv'
  | 'projekt.darlehensnehmer'
  | 'projekt.notizenBesichtigung'
  | 'projekt.kreditsachbearbeiterVorname'
  | 'projekt.kreditsachbearbeiterName'
  | 'projekt.kreditsachbearbeiterTelefon'
  | 'projekt.kreditsachbearbeiterEMail'
  | 'standort.id'
  | 'standort.name'
  | 'standort.addresse'
  | 'standort.dateipfad'
  | 'abteilung.id'
  | 'abteilung.kunde'
  | 'abteilung.name'
  | 'abteilung.ansprache'
  | 'abteilung.outlookKontakte'
  | 'abteilung.website'
  | 'abteilung.adresse'
  | 'abteilung.aktiv'
  | 'vermerke.id'
  | 'vermerke.projekt'
  | 'vermerke.gutachten'
  | 'vermerke.kunde'
  | 'vermerke.antwortAuf'
  | 'vermerke.verfasser'
  | 'vermerke.kommentar'
  | 'vermerke.externSichtbar'
  | 'erinnerungen.id'
  | 'erinnerungen.projekt'
  | 'erinnerungen.gutachten'
  | 'erinnerungen.kunde'
  | 'erinnerungen.kommentar'
  | 'erinnerungen.am'
  | 'erinnerungen.mitarbeiter'
  | 'erinnerungen.verfasser'
  | 'rechnungen.id'
  | 'rechnungen.projekt'
  | 'rechnungen.kunde'
  | 'rechnungen.bezeichnung'
  | 'rechnungen.rechnungZaehler'
  | 'rechnungen.datum'
  | 'rechnungen.honorarVereinbarung'
  | 'rechnungen.extraVereinbarungs'
  | 'rechnungen.stunden'
  | 'rechnungen.anschlaege'
  | 'rechnungen.seiten'
  | 'rechnungen.fotos'
  | 'rechnungen.strecke'
  | 'rechnungen.fahrtzeit'
  | 'rechnungen.besichtigungen'
  | 'rechnungen.mwstSatz'
  | 'rechnungen.nachlass'
  | 'rechnungen.summe'
  | 'rechnungen.datei'
  | 'zahlungseingaenge.id'
  | 'zahlungseingaenge.projekt'
  | 'zahlungseingaenge.kunde'
  | 'zahlungseingaenge.rechnung'
  | 'zahlungseingaenge.summe'
  | 'zahlungseingaenge.datum'
  | '';
