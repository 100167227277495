import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { IUmkreissucheQueryShapes, IUmkreissucheResponse, UmkreissucheQueryFilterConfig } from 'fa-kt.types';

import { UmkreissucheService } from '../service';

@Component({
  selector: 'fa-kt-umkreissuche-filter',
  templateUrl: './umkreissuche-filter.component.html',
  styleUrls: ['./umkreissuche-filter.component.css'],
})
export class UmkreissucheFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public UmkreissucheFilterConfig: IFilterConfig<IUmkreissucheResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public umkreissuche: UmkreissucheService,
  ) {
    this.UmkreissucheFilterConfig = UmkreissucheQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.umkreissuche.shapes$.subscribe((shapes: IUmkreissucheQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        this.umkreissuche.replacements.forEach((r) => {
          Object.entries(r)
            .filter(([key]) => shapes[key])
            .forEach(([key, value]) => (shapes[value] = shapes[key]));
        });
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.UmkreissucheFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.UmkreissucheFilterConfig[index].selections = shapes;
  }
}
