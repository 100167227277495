import { NgModule } from '@angular/core';
import {
  ProfilFilterComponentModule,
  ProfilDetailComponentModule,
  ProfilSorterComponentModule,
  SavedProfilFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    ProfilFilterComponentModule,
    ProfilDetailComponentModule,
    ProfilSorterComponentModule,
    SavedProfilFiltersComponentModule
  ],
  exports: [
    ProfilFilterComponentModule,
    ProfilDetailComponentModule,
    ProfilSorterComponentModule,
    SavedProfilFiltersComponentModule
  ],
})
export class ProfilQueryModule { }
