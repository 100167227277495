import { Component, Inject } from '@angular/core';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { FotoKategorienQueryFilterConfig, IFotoKategorienQueryShapes } from 'fa-kt.types';
import { FotoKategorienService } from '../service';

@Component({
  selector: 'fa-kt-foto-kategorien-filter',
  templateUrl: './foto-kategorien-filter.component.html',
  styleUrls: ['./foto-kategorien-filter.component.css'],
})
export class FotoKategorienFilterComponent {
  readonly subscriptions: Subscription[] = [];

  FotoKategorienFilterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public fotoKategorien: FotoKategorienService,
  ) {
    this.FotoKategorienFilterConfig = FotoKategorienQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.fotoKategorien.shapes$.subscribe((shapes: IFotoKategorienQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.FotoKategorienFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.FotoKategorienFilterConfig[index].selections = shapes;
  }
}
