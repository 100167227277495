import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteZeitUnterartRequest, IDeleteZeitUnterartResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteZeitUnterartCommandService extends DeleteCommandService<IDeleteZeitUnterartRequest, IDeleteZeitUnterartResponse> {
  readonly route = 'zeiten/settings/zeit-unterart';
}
