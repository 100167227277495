import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IKonstantesResponse } from 'fa-kt.types';
import { PostKonstanteCommandService } from '../commands';
import { KonstantesService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class KonstantesPageResolver implements Resolve<IKonstantesResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private konstantes: KonstantesService,
    private postKonstante: PostKonstanteCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IKonstantesResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [konstantes] = await Promise.all([this.konstantes.request({}).catch(log), this.postKonstante.prepare(true).catch(log)]);
    this.loading.resolved();
    return konstantes;
  }
}
