import { IFilter, IFilterConfig, INumberFilter, ISelection, IShape, ISingleReferenceFilter, NumberFilterConfig, SingleReferenceFilterConfig } from 'pbc.types';

import { IHerausgeber } from 'fa-kt.types';
import { IBerichteResponse } from '.';

export interface IBerichteQueryFilter extends IFilter<IBerichteResponse> {
  'bericht.herausgeber'?: ISingleReferenceFilter<IHerausgeber>;
  'bericht.jahr'?: INumberFilter;
  'bericht.monat'?: INumberFilter;
}

export const BerichteQueryFilterConfig: IFilterConfig<IBerichteResponse> = [
  SingleReferenceFilterConfig<IHerausgeber>('bericht.herausgeber', 'MARKT.Models.BERICHT.Propertys.HERAUSGEBER.title'),
  NumberFilterConfig('bericht.jahr', 'MARKT.Models.BERICHT.Propertys.JAHR.title'),
  NumberFilterConfig('bericht.monat', 'MARKT.Models.BERICHT.Propertys.MONAT.title'),
];

export interface IBerichteQueryShapes extends IShape {
  'bericht.art': ISelection[];
  'bericht.herausgeber': ISelection[];
}

export type IBerichteQueryField =
  | 'bericht.id'
  | 'bericht.excel'
  | 'bericht.art'
  | 'bericht.herausgeber'
  | 'bericht.quelle'
  | 'bericht.jahr'
  | 'bericht.quartal'
  | 'bericht.monat'
  | 'bericht.kommentar'
  | '';
