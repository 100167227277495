import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DurchgangService } from '../service';

@Component({
  selector: 'fa-kt-durchgang-detail',
  templateUrl: './durchgang-detail.component.html',
  styleUrls: ['./durchgang-detail.component.css'],
})
export class DurchgangDetailComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  constructor(public durchgang: DurchgangService) {}
}
