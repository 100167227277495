import { NgModule } from '@angular/core';

import {
  PostObjektArtCommandServiceModule,
  PostObjektArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostObjektArtCommandServiceModule,
    PostObjektArtCommandComponentModule
  ],
  exports: [
    PostObjektArtCommandServiceModule,
    PostObjektArtCommandComponentModule
  ]
})
export class PostObjektArtCommandModule { }
