import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostSyncAzureActiveDirectoryCommandServiceModule } from '..';
import { PostSyncAzureActiveDirectoryCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostSyncAzureActiveDirectoryCommandServiceModule
  ],
  declarations: [PostSyncAzureActiveDirectoryCommandComponent],
  exports: [PostSyncAzureActiveDirectoryCommandComponent],

})
export class PostSyncAzureActiveDirectoryCommandComponentModule { }
