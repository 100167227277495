import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IFotoKategorienResponse } from 'fa-kt.types';
import { PostFotoKategorieCommandService } from '../commands';
import { FotoKategorienService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class FotoKategorienPageResolver implements Resolve<IFotoKategorienResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private fotoKategorien: FotoKategorienService,
    private postFotoKategorie: PostFotoKategorieCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IFotoKategorienResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [fotoKategorien] = await Promise.all([this.fotoKategorien.request({}).catch(log), this.postFotoKategorie.prepare(true).catch(log)]);
    this.loading.resolved();
    return fotoKategorien;
  }
}
