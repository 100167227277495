import { NgModule } from '@angular/core';

import { BesichtigungsStatiSettingsModule, FotoKategorienSettingsModule, TourStatiSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [BesichtigungsStatiSettingsModule, TourStatiSettingsModule, FotoKategorienSettingsModule],
  exports: [BesichtigungsStatiSettingsModule, TourStatiSettingsModule, FotoKategorienSettingsModule],
})
export class BesichtigungenSettingsModule {}
