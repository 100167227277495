import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostGutachtenCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostGutachtenCommandService],
  exports: []
})
export class PostGutachtenCommandServiceModule { }
