import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IVorlageSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  initialen?: ISorterExpression;
  fuerKunden?: ISorterExpression;
  fuerKundenGruppen?: ISorterExpression;
  fuerKundenArten?: ISorterExpression;
  fuerObjektArten?: ISorterExpression;
  fuerBewertungsAnlaesse?: ISorterExpression;
  angewendet?: ISorterExpression;
}

export const VorlageSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('initialen', 'Initialen'),
  SorterConfig('fuerKunden', 'Fuer Kunden'),
  SorterConfig('fuerKundenGruppen', 'Fuer Kunden Gruppen'),
  SorterConfig('fuerKundenArten', 'Fuer Kunden Arten'),
  SorterConfig('fuerObjektArten', 'Fuer Objekt Arten'),
  SorterConfig('fuerBewertungsAnlaesse', 'Fuer Bewertungs Anlaesse'),
  SorterConfig('angewendet', 'Angewendet')
];
