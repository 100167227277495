import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektNutzungsServiceModule } from '..';
import { ObjektNutzungsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektNutzungsServiceModule
  ],
  declarations: [ObjektNutzungsSorterComponent],
  exports: [ObjektNutzungsSorterComponent],

})
export class ObjektNutzungsSorterComponentModule { }
