import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IUmkreissucheQueryFilter, IUmkreissucheQueryShapes, IUmkreissucheQuerySorter, IUmkreissucheRequest, IUmkreissucheResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class UmkreissucheService extends ListQueryService<IUmkreissucheRequest, IUmkreissucheResponse, IUmkreissucheQueryShapes, IUmkreissucheQueryFilter, IUmkreissucheQuerySorter> {
  contentKey: keyof IUmkreissucheResponse = 'umkreissuche';
  route = 'gutachten/umkreissuche';
  static instance: UmkreissucheService;
  override replacements: { [key: string]: string }[] = [
    {
      'gutachten.bewertungsAnlass': 'projekt.bewertungsAnlass',
    },
  ];

  constructor() {
    super();
    if (!UmkreissucheService.instance) UmkreissucheService.instance = this;
    return UmkreissucheService.instance;
  }

  getProjektAbteilung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.abteilung']?.find((v) => v.value === value);
  }

  get projektAbteilung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.abteilung'] as ISelection[]) : [])));
  }

  getObjektBereiche(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['objekt.bereiche']?.find((v) => v.value === value);
  }

  get objektBereiche(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['objekt.bereiche'] as ISelection[]) : [])));
  }

  getGutachtenBewertungsAnlass(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.bewertungsAnlass']?.find((v) => v.value === value);
  }

  get gutachtenBewertungsAnlass(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.bewertungsAnlass'] as ISelection[]) : [])));
  }

  getProjektFond(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.fond']?.find((v) => v.value === value);
  }

  get projektFond(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.fond'] as ISelection[]) : [])));
  }

  getGutachtenBewertungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.bewertungsStatus']?.find((v) => v.value === value);
  }

  get gutachtenBewertungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.bewertungsStatus'] as ISelection[]) : [])));
  }

  getProjektKunde(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.kunde']?.find((v) => v.value === value);
  }

  get projektKunde(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.kunde'] as ISelection[]) : [])));
  }

  getProjektBank(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.bank']?.find((v) => v.value === value);
  }

  get projektBank(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.bank'] as ISelection[]) : [])));
  }

  getProjektBesichtigungen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.besichtigungen']?.find((v) => v.value === value);
  }

  get projektBesichtigungen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.besichtigungen'] as ISelection[]) : [])));
  }

  getProjektGutachten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.gutachten']?.find((v) => v.value === value);
  }

  get projektGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.gutachten'] as ISelection[]) : [])));
  }

  getProjektPruefung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.pruefung']?.find((v) => v.value === value);
  }

  get projektPruefung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.pruefung'] as ISelection[]) : [])));
  }

  getGutachtenObjekt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objekt']?.find((v) => v.value === value);
  }

  get gutachtenObjekt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objekt'] as ISelection[]) : [])));
  }

  getGutachtenObjektArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objektArt']?.find((v) => v.value === value);
  }

  get gutachtenObjektArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objektArt'] as ISelection[]) : [])));
  }

  getGutachtenObjektNutzung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objektNutzung']?.find((v) => v.value === value);
  }

  get gutachtenObjektNutzung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objektNutzung'] as ISelection[]) : [])));
  }

  getProjektProjektArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.projektArt']?.find((v) => v.value === value);
  }

  get projektProjektArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.projektArt'] as ISelection[]) : [])));
  }

  getProjektProjektStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.projektStatus']?.find((v) => v.value === value);
  }

  get projektProjektStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.projektStatus'] as ISelection[]) : [])));
  }

  getProjektStandort(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.standort']?.find((v) => v.value === value);
  }

  get projektStandort(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.standort'] as ISelection[]) : [])));
  }
}
