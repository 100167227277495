<!---
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="erinnerung">{{ command.Inputs.ERINNERUNG.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.ERINNERUNG.hint">
      <pbc-select-value formControlName="erinnerung" id="erinnerung" [shapes]="postAbmelden.erinnerung | async" [placeholder]="command.Inputs.ERINNERUNG.placeholder" ></pbc-select-value>
    </nz-form-control>
  </nz-form-item>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
 {{ command.translation }}
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
