import { Injectable, Injector } from "@angular/core";

import unionBy from "lodash/unionBy";
import { BehaviorSubject } from "rxjs";

import { AuthService } from "../../../auth";
import { HttpService } from "../../../https";
import { SecretService } from "../secrets";


export interface IHealthCheck {
  name: string;
  url: string;
  authenticated: boolean;
  secrets: boolean;
  success?: boolean;
  error?: Error
}

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  checks: IHealthCheck[] = [
    {
      name: 'FA|KT Server',
      url: 'health',
      authenticated: false,
      secrets: false
    }
  ]
  $checks = new BehaviorSubject<IHealthCheck[]>([])

  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(private injector: Injector, private http: HttpService, private secrets: SecretService) {
    this.open().then();
    this.auth.$id.subscribe(async (id) => {
      if (id) {
        await this.authenticated();
      }
    });
    this.secrets.$secrets.subscribe(async (secrets) => {
      if (secrets) {
        await this.secretly();
      }
    })
  }

  private async check(check: IHealthCheck): Promise<IHealthCheck> {
    try {
      await this.http.get(check.url);
      return { ...check, success: true }
    } catch (error: any) {
      console.error(error);
      return { ...check, success: false, error }
    };
  }

  async open() {
    const checks = await Promise.all(this.checks.filter(check => !check.authenticated && !check.secrets).map((check) => this.check(check)));
    this.$checks.next(unionBy(checks, this.$checks.getValue(), 'name'));
  }

  async authenticated() {
    const checks = await Promise.all(this.checks.filter(check => !check.secrets && check.authenticated).map((check) => this.check(check)));
    this.$checks.next(unionBy(checks, this.$checks.getValue(), 'name'));
  }

  async secretly() {
    const checks = await Promise.all(this.checks.filter(check => check.secrets).map((check) => this.check(check)));
    this.$checks.next(unionBy(checks, this.$checks.getValue(), 'name'));
  }

}


