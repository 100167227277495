import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DateiArtsServiceModule } from '..';
import { DateiArtsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DateiArtsServiceModule
  ],
  declarations: [DateiArtsListComponent],
  exports: [DateiArtsListComponent],

})
export class DateiArtsListComponentModule { }
