import {
  Component, Input
} from '@angular/core';
import { NzButtonSize } from "ng-zorro-antd/button";
import { ISelection } from "pbc.types";

interface ColorGroup { preview?: string, colors: string[] }

@Component({
  selector: 'pbc-color-icon',
  templateUrl: './color-icon.component.html',
  styleUrls: ['./color-icon.component.css']
})
export class ColorIconComponent  {

  @Input() size: NzButtonSize = "small";

  @Input() set selection(selection: ISelection) {
    this.color = selection?.color || '#FFF';
    this.icon = selection.icon || undefined;
  }

  @Input() set color(color: string|undefined) {
    this._color = color || '#FFF';
  }
  _color = '#FFF';

  @Input() set icon(icon: string|undefined) {
    this._icon = icon || undefined;
  }
  _icon: string|undefined = undefined;


}
