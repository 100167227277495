import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { GutachtenStatiServiceModule } from '..';
import { GutachtenStatiSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenStatiServiceModule
  ],
  declarations: [GutachtenStatiSorterComponent],
  exports: [GutachtenStatiSorterComponent],

})
export class GutachtenStatiSorterComponentModule { }
