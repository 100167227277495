import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  DateFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IDateFilter,
  IFilter,
  IFilterConfig,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IAbteilung, IFond, IKunde, IMitarbeiter, IProjektArt, IProjektStatus, IStandort } from 'fa-kt.types';
import { IArchivResponse } from '.';

export interface IArchivQueryFilter extends IFilter<IArchivResponse> {
  'projekt.projektArt'?: ISingleReferenceFilter<IProjektArt>;
  'projekt.projektStatus'?: ISingleReferenceFilter<IProjektStatus>;
  'projekt.standort'?: ISingleReferenceFilter<IStandort>;
  'projekt.beauftragung'?: IDateFilter;
  'projekt.deadline'?: IDateFilter;
  'projekt.keineBesichtigungErforderlich'?: IBooleanFilter;
  'projekt.besichtigungen'?: IArrayReferenceFilter<IMitarbeiter>;
  'projekt.gutachten'?: IArrayReferenceFilter<IMitarbeiter>;
  'projekt.pruefung'?: ISingleReferenceFilter<IMitarbeiter>;
  'projekt.kunde'?: ISingleReferenceFilter<IKunde>;
  'projekt.abteilung'?: ISingleReferenceFilter<IAbteilung>;
  'projekt.fond'?: ISingleReferenceFilter<IFond>;
  'projekt.bank'?: ISingleReferenceFilter<IKunde>;
  'projekt.fortschritt'?: INumberFilter;
  // 'projekt.strecke'?: INumberFilter;
  // 'projekt.kalkulierteStrecke'?: INumberFilter;
  // 'projekt.fahrtzeit'?: INumberFilter;
  // 'projekt.kalkulierteFahrtzeit'?: INumberFilter;
  'projekt.stunden'?: INumberFilter;
  'projekt.kostenNachStunden'?: INumberFilter;
  'projekt.verhandeltesHonorar'?: INumberFilter;
  'projekt.kosten'?: INumberFilter;
  'projekt.kostenBeglichen'?: INumberFilter;
  'projekt.aktiv'?: IBooleanFilter;
}

export const ArchivQueryFilterConfig: IFilterConfig<IArchivResponse> = [
  SingleReferenceFilterConfig<IProjektArt>('projekt.projektArt', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ART.title'),
  SingleReferenceFilterConfig<IProjektStatus>('projekt.projektStatus', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_STATUS.title'),
  SingleReferenceFilterConfig<IStandort>('projekt.standort', 'PROJEKTE.Models.PROJEKT.Propertys.STANDORT.title'),
  DateFilterConfig('projekt.beauftragung', 'PROJEKTE.Models.PROJEKT.Propertys.BEAUFTRAGUNG.title'),
  DateFilterConfig('projekt.deadline', 'PROJEKTE.Models.PROJEKT.Propertys.DEADLINE.title'),
  BooleanFilterConfig('projekt.keineBesichtigungErforderlich', 'PROJEKTE.Models.PROJEKT.Propertys.KEINE_BESICHTIGUNG_ERFORDERLICH.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('projekt.besichtigungen', 'PROJEKTE.Models.PROJEKT.Propertys.BESICHTIGUNGEN.title'),
  ArrayReferenceFilterConfig<IMitarbeiter>('projekt.gutachten', 'PROJEKTE.Models.PROJEKT.Propertys.GUTACHTEN.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('projekt.pruefung', 'PROJEKTE.Models.PROJEKT.Propertys.PRUEFUNG.title'),
  SingleReferenceFilterConfig<IKunde>('projekt.kunde', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDE.title'),
  SingleReferenceFilterConfig<IAbteilung>('projekt.abteilung', 'PROJEKTE.Models.PROJEKT.Propertys.ABTEILUNG.title'),
  SingleReferenceFilterConfig<IFond>('projekt.fond', 'PROJEKTE.Models.PROJEKT.Propertys.FOND.title'),
  SingleReferenceFilterConfig<IKunde>('projekt.bank', 'PROJEKTE.Models.PROJEKT.Propertys.BANK.title'),
  NumberFilterConfig('projekt.fortschritt', 'PROJEKTE.Models.PROJEKT.Propertys.FORTSCHRITT.title'),
  // NumberFilterConfig('projekt.strecke', 'PROJEKTE.Models.PROJEKT.Propertys.STRECKE.title'),
  // NumberFilterConfig('projekt.kalkulierteStrecke', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_STRECKE.title'),
  // NumberFilterConfig('projekt.fahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.FAHRTZEIT.title'),
  // NumberFilterConfig('projekt.kalkulierteFahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_FAHRTZEIT.title'),
  NumberFilterConfig('projekt.stunden', 'PROJEKTE.Models.PROJEKT.Propertys.STUNDEN.title'),
  NumberFilterConfig('projekt.kostenNachStunden', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_NACH_STUNDEN.title'),
  NumberFilterConfig('projekt.verhandeltesHonorar', 'PROJEKTE.Models.PROJEKT.Propertys.VERHANDELTES_HONORAR.title'),
  NumberFilterConfig('projekt.kosten', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN.title'),
  NumberFilterConfig('projekt.kostenBeglichen', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_BEGLICHEN.title'),
  BooleanFilterConfig('projekt.aktiv', 'PROJEKTE.Models.PROJEKT.Propertys.AKTIV.title'),
];

export interface IArchivQueryShapes extends IShape {
  'projekt.projektArt': ISelection[];
  'projekt.projektStatus': ISelection[];
  'projekt.standort': ISelection[];
  'projekt.besichtigungen': ISelection[];
  'projekt.gutachten': ISelection[];
  'projekt.pruefung': ISelection[];
  'projekt.kunde': ISelection[];
  'projekt.abteilung': ISelection[];
  'projekt.fond': ISelection[];
  'projekt.bank': ISelection[];
  'projekt.objektArt': ISelection[];
}

export type IArchivQueryField =
  | 'projekt.id'
  | 'projekt.bezeichnung'
  | 'projekt.nummer'
  | 'projekt.projektZaehler'
  | 'projekt.projektArt'
  | 'projekt.projektStatus'
  | 'projekt.standort'
  | 'projekt.beauftragung'
  | 'projekt.deadline'
  | 'projekt.kundenAnfertigung'
  | 'projekt.keineBesichtigungErforderlich'
  | 'projekt.besichtigungen'
  | 'projekt.gutachten'
  | 'projekt.pruefung'
  | 'projekt.kunde'
  | 'projekt.abteilung'
  | 'projekt.fond'
  | 'projekt.bank'
  | 'projekt.objektArt'
  | 'projekt.nachlass'
  | 'projekt.kundenZeichen'
  | 'projekt.kontakteInOutlook'
  | 'projekt.dateiVerzeichnis'
  | 'projekt.kommentar'
  | 'projekt.notizenFuerRechnung'
  | 'projekt.fortschritt'
  // | 'projekt.strecke'
  // | 'projekt.kalkulierteStrecke'
  // | 'projekt.fahrtzeit'
  // | 'projekt.kalkulierteFahrtzeit'
  | 'projekt.stunden'
  | 'projekt.kostenNachStunden'
  | 'projekt.verhandeltesHonorar'
  | 'projekt.kosten'
  | 'projekt.kostenBeglichen'
  | 'projekt.aktiv'
  | 'projekt.darlehensnehmer'
  | 'projekt.notizenBesichtigung'
  | 'projekt.kreditsachbearbeiterVorname'
  | 'projekt.kreditsachbearbeiterName'
  | 'projekt.kreditsachbearbeiterTelefon'
  | 'projekt.kreditsachbearbeiterEMail'
  | '';
