import { NgModule } from '@angular/core';
import {
  ZeitUnterartsFilterComponentModule, ZeitUnterartsListComponentModule, ZeitUnterartsSorterComponentModule
} from '.';

@NgModule({
  imports: [
    ZeitUnterartsFilterComponentModule,
    ZeitUnterartsSorterComponentModule,
    ZeitUnterartsListComponentModule
  ],
  exports: [
    ZeitUnterartsFilterComponentModule,
    ZeitUnterartsSorterComponentModule,
    ZeitUnterartsListComponentModule
  ],
})
export class ZeitUnterartsQueryModule { }