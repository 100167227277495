import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostVermerkCommandShapes, IPostVermerkRequest, IPostVermerkResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostVermerkCommandService extends PostCommandService<IPostVermerkRequest, IPostVermerkResponse, IPostVermerkCommandShapes> {
  readonly route = 'kommunikation/vermerk';
  override dependencies = ['personen/mitarbeiter-aktualisiert', 'personen/azure-active-directory-synchronisiert'];

  getVermerkVerfasser(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vermerk.verfasser']?.find((v) => v.value === value);
  }

  get vermerkVerfasser(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vermerk.verfasser'] as ISelection[]) : [])));
  }
}
