import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { VorlageServiceModule } from '..';
import { SavedVorlageFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlageServiceModule
  ],
  declarations: [SavedVorlageFiltersComponent],
  exports: [SavedVorlageFiltersComponent],

})
export class SavedVorlageFiltersComponentModule { }
