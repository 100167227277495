import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IFeldRequest, IFeldResponse } from 'fa-kt.types';
import { PostFeldCommandService, PostFeldOptionCommandService } from '../../commands';
import { FeldService } from '../../querys';

export interface IFeldPageResolverResult {
  feld?: IFeldResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class FeldPageResolver implements Resolve<IFeldPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private feld: FeldService,
    private postFeld: PostFeldCommandService,
    private postFeldOption: PostFeldOptionCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IFeldPageResolverResult> {
    this.loading.resolving();
    const requests = {
      feld: {} as IFeldRequest,
    };
    const log = (error: any) => console.error(error);
    requests.feld.id = route.queryParams['id'];
    if (requests.feld.id === 'new') {
      this.feld.payload$.next(undefined);
      this.feld.result$.next(undefined);
      this.loading.resolved();
      await Promise.all([this.postFeld.prepare().catch(log), this.postFeldOption.prepare().catch(log)]);
      const feld: IFeldResponse = {
        feld: {},
        feldOptionen: [],
      } as unknown as IFeldResponse;
      return {
        feld,
      };
    }
    const [feld] = await Promise.all([this.feld.request(requests.feld).catch(log), this.postFeld.prepare().catch(log), this.postFeldOption.prepare().catch(log)]);
    this.loading.resolved();
    return {
      feld,
    };
  }
}
