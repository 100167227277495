import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TourStatiServiceModule } from '..';
import { TourStatiSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourStatiServiceModule
  ],
  declarations: [TourStatiSorterComponent],
  exports: [TourStatiSorterComponent],

})
export class TourStatiSorterComponentModule { }
