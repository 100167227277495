import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, OfflineStoreService, SITEMAP } from 'pbc.angular';

import { IDurchgang, IEintrag, IFeld, IFeldKategorie, IFeldOption, IFeldUnterkategorie, IPostDurchgangRequest, IPostDurchgangResponse, IPostEintragRequest, IPostEintragResponse } from 'fa-kt.types';
import { IDurchgangPageResolverResult } from '.';
import { PostEintragCommandService } from '../../../gutachten';
import { PostDurchgangCommandService } from '../../commands';
import { DurchgangService } from '../../querys';

interface Kategorie extends IFeldKategorie {
  unterkategorien: Unterkategorie[];
}
interface Unterkategorie extends IFeldUnterkategorie {
  felder: Feld[];
}
interface Feld extends IFeld {
  feldOptionen: Array<IFeldOption>;
  eintrag: IEintrag;
  skipped: boolean;
  answered: boolean;
}
@Component({
  selector: 'fa-kt-durchgang-page',
  templateUrl: './durchgang.page.html',
  styleUrls: ['./durchgang.page.css'],
})
export class DurchgangPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postEintrag: BehaviorSubject<IPostEintragRequest | null> = new BehaviorSubject<IPostEintragRequest | null>(null);
  public readonly $postDurchgang: BehaviorSubject<IPostDurchgangRequest | null> = new BehaviorSubject<IPostDurchgangRequest | null>(null);

  public _tour: ISitemapPage;

  $durchgang = new BehaviorSubject<IDurchgang | undefined>(undefined);
  $kategorien = new BehaviorSubject<Kategorie[]>([]);
  $unzugeordnete = new BehaviorSubject<Feld[]>([]);
  FELDER_STORAGE_KEY = 'felder';
  set state(state: { [key: string]: any }) {
    localStorage.setItem(this.FELDER_STORAGE_KEY + this.$durchgang.getValue()?.id, JSON.stringify(state));
  }
  get state(): { [key: string]: any } {
    const state = localStorage.getItem(this.FELDER_STORAGE_KEY + this.$durchgang.getValue()?.id);
    return JSON.parse(state ? state : '{}') as string[];
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public durchgang: DurchgangService,
    public postEintrag: PostEintragCommandService,
    public postDurchgang: PostDurchgangCommandService,
    private cache: OfflineStoreService,
  ) {
    this.page = this.sitemap.FORMULARE.Pages.DURCHGANG;
    this._tour = sitemap.BESICHTIGUNGEN.Pages.TOUR;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IDurchgangPageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        this.durchgang.result$.subscribe((result) => {
          this.$loading.next(true);
          console.time('durchgang');
          if (result) {
            this.$durchgang.next(result.durchgang);
            const state = this.state;
            const kategorien: Kategorie[] = (result.feldKategorien as IFeldKategorie[])
              .map((kategorie) => ({
                ...kategorie,
                unterkategorien: (result.feldUnterkategorien as IFeldUnterkategorie[])
                  .filter((f) => f.feldKategorie === kategorie.id)
                  .map((unterkategorie) => ({
                    ...unterkategorie,
                    felder: (result.felder as IFeld[])
                      .filter((f) => f.feldUnterkategorie === unterkategorie.id)
                      .map((feld) => ({
                        ...feld,
                        answered: !!state[feld.id],
                        skipped: !result.formular.felder?.includes(feld.id) as boolean,
                        feldOptionen: (result.feldOptionen as IFeldOption[]).filter((fo) => fo.feld === feld.id),
                        eintrag: (result.eintraege as IEintrag[]).find((fo) => fo.feld === feld.id) || {
                          id: '',
                          feld: feld.id,
                          gutachten: result.durchgang.gutachten as string,
                          wert: feld.voreinstellung,
                        },
                      }))
                      .filter((f) => !f.skipped),
                  }))
                  .filter((uk) => uk.felder.length > 0),
              }))
              .filter((uk) => uk.unterkategorien.length > 0);
            this.$kategorien.next(kategorien);
            this.$unzugeordnete.next(
              (result.felder as IFeld[])
                .filter((feld) => !feld.feldKategorie || !feld.feldUnterkategorie)
                .map((feld) => ({
                  ...feld,
                  answered: !!state[feld.id],
                  skipped: !result.formular.felder?.includes(feld.id) as boolean,
                  feldOptionen: (result.feldOptionen as IFeldOption[]).filter((fo) => fo.feld === feld.id),
                  eintrag: (result.eintraege as IEintrag[]).find((fo) => fo.feld === feld.id) || {
                    id: '',
                    feld: feld.id,
                    gutachten: result.durchgang.gutachten as string,
                    wert: feld.voreinstellung,
                  },
                })),
            );
          } else {
            this.$kategorien.next([]);
          }
          this.$loading.next(false);
          console.timeEnd('durchgang');
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setDurchgang(id: string) {
    /* << set-durchgang    */
    /*    set-durchgang >> */
  }

  async finishedPostEintrag(response?: IPostEintragResponse) {
    /* << after post-eintrag    */
    /*    after post-eintrag >> */
  }

  async finishedPostDurchgang(response?: IPostDurchgangResponse) {
    /* << after post-durchgang    */
    /*    after post-durchgang >> */
  }

  public async navigateToTour(queryParams: Params = {}) {
    await this.router.navigate(this._tour.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  /* << methods    */

  async clearCache() {
    await this.cache.sync();
    this.state = {};
    this.message.success('Cache erfolgreich geleert');
    this.durchgang.result$.next(this.durchgang.result$.getValue());
  }

  hasValue(eintrag: IEintrag, option: IFeldOption): boolean {
    if (!eintrag) {
      return false;
    }
    if (Array.isArray(eintrag.wert)) {
      return eintrag.wert.includes(option.option);
    } else {
      return eintrag.wert === option.option;
    }
  }

  async toggleValues(feld: Feld, option: IFeldOption): Promise<void> {
    this.$loading.next(true);
    let wert = feld.eintrag?.wert || '';
    wert = Array.isArray(wert) ? wert : [wert];
    wert = wert.includes(option.option) ? wert.filter((s: any) => s !== option.option) : [...wert, option.option];
    feld.eintrag.wert = wert;
    await this.submitPostEintrag({ eintrag: feld.eintrag });
    this.writeToCache(feld.eintrag);
    this.$loading.next(false);
  }

  async submitEintrag(feld: Feld, wert: string) {
    if (typeof wert !== 'number' && typeof wert !== 'boolean' && !wert) {
      return;
    }
    this.$loading.next(true);
    feld.eintrag.wert = wert;
    this.writeToCache(feld.eintrag);
    await this.submitPostEintrag({ eintrag: feld.eintrag });
    this.$loading.next(false);
  }

  async submitExtraEintrag(feld: Feld, wertExtra: string) {
    if (!wertExtra) {
      return;
    }
    this.$loading.next(true);
    feld.eintrag.wertExtra = wertExtra;
    this.writeToCache(feld.eintrag);
    await this.submitPostEintrag({ eintrag: feld.eintrag });
    this.$loading.next(false);
  }

  writeToCache(eintrag: IEintrag) {
    const state = this.state;
    state[eintrag.feld] = eintrag;
    this.state = state;
  }
  /*    methods >> */

  public async submitPostEintrag(request?: IPostEintragRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postEintrag.getValue();
    if (payload) {
      try {
        const response = await this.postEintrag.request(payload);
        await this.finishedPostEintrag(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostDurchgang(request?: IPostDurchgangRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postDurchgang.getValue();
    if (payload) {
      try {
        const response = await this.postDurchgang.request(payload);
        await this.finishedPostDurchgang(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
