import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { StandortsServiceModule } from '..';
import { StandortsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    StandortsServiceModule
  ],
  declarations: [StandortsSorterComponent],
  exports: [StandortsSorterComponent],

})
export class StandortsSorterComponentModule { }
