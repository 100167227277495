<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="rahmenAnpassung">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="angenommen" />
      <input class="cdk-visually-hidden" formControlName="abgelehnt" />

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">Mitarbeiter/-in</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
          <ng-template #MitarbeiterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Mitarbeiter/-in auswählen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="meta.users$ | async" placeholder="Mitarbeiter/-in" cdkFocusInitial required="true"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="von">Stundenanpassung</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
          <ng-template #DatumErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Stunden pflegen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 180px" formControlName="stunden" id="stunden" placeholder="Stunden" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gültigBis">Gültig Bis</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
          <ng-template #DatumErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Gültig Bis pflegen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="gültigBis" id="gültigBis" nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">Kommentar</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <nz-textarea-count [nzMaxCharacterCount]="100">
            <textarea rows="2" formControlName="kommentar" id="kommentar" [placeholder]="'Kommentar'" nz-input></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item>
      <nz-form-control>
        <nz-space class="save-button">
          <button *nzSpaceItem nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
            Speichern
            <ng-template #SubmitTooltip> Rahmen-Anpassung speichern </ng-template>
          </button>

          <ng-container *ngIf="((auth.$isOM | async) || (auth.$isPartner | async)) && ($initial | async)?.rahmenAnpassung?.id && !($initial | async)?.rahmenAnpassung?.angenommen">
            <button *nzSpaceItem nz-button type="button" (click)="confirm()" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
              <span nz-icon nzType="check-circle" nzTheme="outline"></span> Annehmen
            </button>
          </ng-container>

          <ng-container *ngIf="((auth.$isOM | async) || (auth.$isPartner | async)) && ($initial | async)?.rahmenAnpassung?.id && !($initial | async)?.rahmenAnpassung?.abgelehnt">
            <button *nzSpaceItem nz-button type="button" (click)="decline()" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
              <span nz-icon nzType="close-circle" nzTheme="outline"></span> Ablehnen
            </button>
          </ng-container>
        </nz-space>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
