import { NgModule } from '@angular/core';

import { ObjektArtsSettingsModule, ObjektNutzungsSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [ObjektArtsSettingsModule, ObjektNutzungsSettingsModule],
  exports: [ObjektArtsSettingsModule, ObjektNutzungsSettingsModule],
})
export class ObjekteSettingsModule {}
