import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FormulareServiceModule } from '..';
import { FormulareFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormulareServiceModule
  ],
  declarations: [FormulareFilterComponent],
  exports: [FormulareFilterComponent],

})
export class FormulareFilterComponentModule { }
