import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektArtsServiceModule } from '..';
import { ProjektArtsFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektArtsServiceModule
  ],
  declarations: [ProjektArtsFilterComponent],
  exports: [ProjektArtsFilterComponent],

})
export class ProjektArtsFilterComponentModule { }
