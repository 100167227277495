<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <input class="cdk-visually-hidden" formControlName="vorlage" id="vorlage" />
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="default" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <i nz-icon nzType="copy" nzTheme="outline"></i>
          Kopieren
          <ng-template #SubmitTooltip> Vorlage Kopieren </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
