import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LIB_AuthModule } from './auth';
import { LIB_CommonModule } from './common';
import { LIB_ContactsModule } from './contacts';
import { LIB_FilesModule } from './files';
import { LIB_FilterModule } from './filter';
import { LIB_GeoModule } from './geo';
import { LIB_HttpsModule } from './https';

@NgModule({
  imports: [CommonModule, LIB_AuthModule.forRoot(), LIB_CommonModule.forRoot(), LIB_ContactsModule.forRoot(), LIB_FilesModule, LIB_FilterModule, LIB_HttpsModule.forRoot(), LIB_GeoModule.forRoot()],
  exports: [LIB_AuthModule, LIB_CommonModule, LIB_ContactsModule, LIB_FilesModule, LIB_FilterModule, LIB_HttpsModule, LIB_GeoModule],
})
export class LibsCommonAngularModule {}
