import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenArtsServiceModule } from '..';
import { KundenArtsFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenArtsServiceModule
  ],
  declarations: [KundenArtsFilterComponent],
  exports: [KundenArtsFilterComponent],

})
export class KundenArtsFilterComponentModule { }
