import { NgModule } from '@angular/core';

import { FeldKategoriesSettingsModule, FeldUnterkategoriesSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [FeldKategoriesSettingsModule, FeldUnterkategoriesSettingsModule],
  exports: [FeldKategoriesSettingsModule, FeldUnterkategoriesSettingsModule],
})
export class FelderSettingsModule {}
