<nz-row style="min-height: 650px; min-width: 350px">
  <nz-col nzSpan="24">
    <ngx-charts-bar-vertical-stacked
      [results]="assignments$ | async"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [yAxisTickFormatting]="yAxisTickFormatting"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [animations]="animations"
      [customColors]="zeitColors"
    >
      <ng-template #tooltipTemplate let-model="model">
        <h4 nz-title style="color: white">{{ model.value | formatHours }}</h4>
        <p nz-typography style="color: white">{{ model.name }} von {{ model.extra.start | date: 'HH:mm' }} bis {{ model.extra.ende | date: 'HH:mm' }}</p>
      </ng-template>
    </ngx-charts-bar-vertical-stacked>
  </nz-col>
</nz-row>
