<cdk-virtual-scroll-viewport *ngIf="berichte.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (berichte.result$ | async).berichte" (click)="set(entry)">
      <nz-list-item-meta [nzDescription]="ListDescription">
        <ng-template #ListDescription>
          <!--- << description    -->
          <!---    description >> -->
        </ng-template>
        <nz-list-item-meta-title>
          <a>
            <!--- << content    -->
            <a>
              {{ entry?.bericht?.herausgeber | type: (berichte.shapes$ | async)?.['bericht.herausgeber'] }}
              <nz-divider nzType="vertical"></nz-divider>
              {{ entry?.bericht?.quelle }}
              <nz-divider nzType="vertical"></nz-divider>
              {{ entry?.bericht?.jahr }}{{ entry?.bericht?.monat ? '.' + entry?.bericht?.monat : entry?.bericht?.quartal ? ' Quartal ' + entry?.bericht?.quartal : '' }}
            </a>
            <!---    content >> -->
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <!--- << extra    -->
          <!---    extra >> -->
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
