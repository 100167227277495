import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteRechnungsVorlageRequest, IDeleteRechnungsVorlageResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteRechnungsVorlageCommandService extends DeleteCommandService<IDeleteRechnungsVorlageRequest, IDeleteRechnungsVorlageResponse> {
  readonly route = 'finanzen/rechnungs-vorlage';
}
