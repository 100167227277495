import { Injectable } from '@angular/core';

import { IPostArchivierenCommandShapes, IPostArchivierenRequest, IPostArchivierenResponse } from 'fa-kt.types';
import { PostCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class PostArchivierenCommandService extends PostCommandService<IPostArchivierenRequest, IPostArchivierenResponse, IPostArchivierenCommandShapes> {
  readonly route = 'projekte/archivieren';
}
