import { NgModule } from '@angular/core';
import {
  MitarbeiterFilterComponentModule,
  MitarbeiterListComponentModule,
  MitarbeiterSorterComponentModule,
  SavedMitarbeiterFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    MitarbeiterFilterComponentModule,
    MitarbeiterListComponentModule,
    MitarbeiterSorterComponentModule,
    SavedMitarbeiterFiltersComponentModule
  ],
  exports: [
    MitarbeiterFilterComponentModule,
    MitarbeiterListComponentModule,
    MitarbeiterSorterComponentModule,
    SavedMitarbeiterFiltersComponentModule
  ],
})
export class MitarbeiterQueryModule { }
