import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteKundenGruppeCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteKundenGruppeCommandService],
  exports: []
})
export class DeleteKundenGruppeCommandServiceModule { }
