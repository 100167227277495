import { IFilter, IFilterConfig } from "pbc.types";

import { IExtraVereinbarung } from '.';

export interface IExtraVereinbarungFilter extends IFilter<IExtraVereinbarung> {}

export const ExtraVereinbarungFilterConfig: IFilterConfig<IExtraVereinbarung> = [];

export type IExtraVereinbarungField =
  'extraVereinbarung.id'|
  'extraVereinbarung.kunde'|
  'extraVereinbarung.name'|
  'extraVereinbarung.beschreibung'|
  'extraVereinbarung.pro'|
  'extraVereinbarung.kosten'
;
