import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IBerichtsVorlagenQueryFilter, IBerichtsVorlagenQueryShapes, IBerichtsVorlagenQuerySorter, IBerichtsVorlagenRequest, IBerichtsVorlagenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class BerichtsVorlagenService extends ListQueryService<
  IBerichtsVorlagenRequest,
  IBerichtsVorlagenResponse,
  IBerichtsVorlagenQueryShapes,
  IBerichtsVorlagenQueryFilter,
  IBerichtsVorlagenQuerySorter
> {
  contentKey: keyof IBerichtsVorlagenResponse = 'berichtsVorlagen';
  route = 'texte/berichts-vorlagen';
  override dependencies = ['texte/berichts-vorlage-gespeichert', 'texte/removed-berichts-vorlage'];
}
