import { NgModule } from '@angular/core';

import { BewertungsAnlasssQueryModule, DeleteBewertungsAnlassCommandModule, PostBewertungsAnlassCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostBewertungsAnlassCommandModule, DeleteBewertungsAnlassCommandModule, BewertungsAnlasssQueryModule],
  exports: [PostBewertungsAnlassCommandModule, DeleteBewertungsAnlassCommandModule, BewertungsAnlasssQueryModule],
})
export class BewertungsAnlasssSettingsModule {}
