import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFondCommandServiceModule } from '..';
import { DeleteFondCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFondCommandServiceModule
  ],
  declarations: [DeleteFondCommandComponent],
  exports: [DeleteFondCommandComponent],

})
export class DeleteFondCommandComponentModule { }
