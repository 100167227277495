import { Pipe, PipeTransform, Directive, Input, HostBinding } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value?: string | null, type = 'html') {
    if (!value) return '';
    if (type === 'url') return this.sanitized.bypassSecurityTrustUrl(value);
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Directive({
  selector: 'img[default]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
export class ImagePreloadDirective {
  @Input() src: string;
  @Input() default: string;
  @HostBinding('class') className;

  updateUrl() {
    this.src = this.default;
  }
  load() {
    this.className = 'image-loaded';
  }
}
