import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IReisekostenabrechnungStatiResponse } from '.';

export interface IReisekostenabrechnungStatiQueryFilter extends IFilter<IReisekostenabrechnungStatiResponse> {}

export const ReisekostenabrechnungStatiQueryFilterConfig: IFilterConfig<IReisekostenabrechnungStatiResponse> = [];

export interface IReisekostenabrechnungStatiQueryShapes extends IShape {}

export type IReisekostenabrechnungStatiQueryField = 'reisekostenabrechnungStati.id' | 'reisekostenabrechnungStati.name' | 'reisekostenabrechnungStati.kommentar' | '';
