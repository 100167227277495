import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProfilServiceModule } from '..';
import { ProfilFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProfilServiceModule
  ],
  declarations: [ProfilFilterComponent],
  exports: [ProfilFilterComponent],

})
export class ProfilFilterComponentModule { }
