import { NgModule } from '@angular/core';
import {
  EintraegeComponentModule,
  GutachtenComponentModule,
  KalkulationenComponentModule,
  SicherheitscheckComponentModule
 } from '.';

@NgModule({
  imports: [
    EintraegeComponentModule,
    GutachtenComponentModule,
    KalkulationenComponentModule,
    SicherheitscheckComponentModule
  ],
  exports: [
    EintraegeComponentModule,
    GutachtenComponentModule,
    KalkulationenComponentModule,
    SicherheitscheckComponentModule
  ]
})
export class GutachtenComponentsModule { }
