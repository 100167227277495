import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface ITemplatesQuerySorter extends ISorter {
   id?: ISorterExpression;
   datei?: ISorterExpression;
   name?: ISorterExpression;
   kommentar?: ISorterExpression;
}

export const TemplatesQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'MARKT.Models.TEMPLATE.Propertys.ID.title'),
   SorterConfig('datei', 'MARKT.Models.TEMPLATE.Propertys.DATEI.title'),
   SorterConfig('name', 'MARKT.Models.TEMPLATE.Propertys.NAME.title'),
   SorterConfig('kommentar', 'MARKT.Models.TEMPLATE.Propertys.KOMMENTAR.title'),
];
