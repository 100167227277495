import { NgModule } from '@angular/core';

import {
  PostProjektStatusCommandServiceModule,
  PostProjektStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostProjektStatusCommandServiceModule,
    PostProjektStatusCommandComponentModule
  ],
  exports: [
    PostProjektStatusCommandServiceModule,
    PostProjektStatusCommandComponentModule
  ]
})
export class PostProjektStatusCommandModule { }
