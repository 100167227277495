import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenServiceModule } from '..';
import { KundenFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenServiceModule
  ],
  declarations: [KundenFilterComponent],
  exports: [KundenFilterComponent],

})
export class KundenFilterComponentModule { }
