import { NgModule } from '@angular/core';

import {
  PostKundenGruppeCommandServiceModule,
  PostKundenGruppeCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostKundenGruppeCommandServiceModule,
    PostKundenGruppeCommandComponentModule
  ],
  exports: [
    PostKundenGruppeCommandServiceModule,
    PostKundenGruppeCommandComponentModule
  ]
})
export class PostKundenGruppeCommandModule { }
