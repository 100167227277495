import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";

import { NzFormControlComponent } from "ng-zorro-antd/form";
import { NzMessageService } from "ng-zorro-antd/message";
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService } from "../../..";
import { AuthService } from "../../services";

@Component({
  selector: 'pbc-ad-settings',
  templateUrl: './ad-settings.component.html',
  styleUrls: ['./ad-settings.component.css']
})
export class AdSettingsComponent implements OnInit {
  subscriptions: Subscription[] = [];

  @Input() set id(id: string) {
    this.$id.next(id)
  }
  $id = new BehaviorSubject<string>('');
  @Input() set readonly(readonly: boolean) {
    this.$readonly.next(readonly)
  }
  $readonly = new BehaviorSubject<boolean>(true);

  @ViewChild('NewPassword') newPasswordField!: ElementRef<HTMLInputElement>;
  passwordPanelActive = false;
  $savingPassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('GroupTag') departmentsTag!: ElementRef<NzFormControlComponent>;
  accessGroupPanelActive = true;
  $savingMemberships: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  get actions(): ActionService {
    return this.injector.get(ActionService);
  }


  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private messages: NzMessageService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(...[
      this.$id.subscribe(id => this.auth.refreshMemberships(id))
    ]);
    this.actions.subscribe({
      key: 'Passwort ändern', action: () => {
        this.accessGroupPanelActive = false;
        this.passwordPanelActive = true;
        this.newPasswordField.nativeElement.focus()
      }
    })
    this.actions.subscribe({
      key: 'Gruppen verwalten', action: () => {
        this.accessGroupPanelActive = true;
        this.passwordPanelActive = true;
      }
    })
  }

  ngOnDestroy() {
    this.$savingPassword.next(false);
    this.$savingMemberships.next(false);
    this.subscriptions.forEach($ => $.unsubscribe());
    this.actions.unsubscribe('Gruppen verwalten');
    this.actions.unsubscribe('Passwort ändern');
  }

  async submitPassword() {
    this.$savingPassword.next(true);
    await this.auth.updatePassword(this.$id.getValue());
    this.$savingPassword.next(false);
  }

  async handleGroupChange(checked: boolean, group: any): Promise<void> {
    if (group.emailOrSecurity) {
      this.messages.warning(`Die Gruppe "${group.displayName }" ist eine E-Mail-/Sicherheitsliste. Änderungen können nur im Administrator-Portal vorgenommen werden`)
      return;
    }
    this.$savingMemberships.next(true);
    if (checked) {
      await this.auth.addMemberToGroup(group.id, this.$id.getValue());
    } else {
      await this.auth.removeMemberFromGroup(group.id, this.$id.getValue());
    }
    this.$savingMemberships.next(false);
  }

}
