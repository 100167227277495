import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IObjektNutzung } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { ObjektNutzungsService } from '../service';

@Component({
  selector: 'fa-kt-objekt-nutzungs-list',
  templateUrl: './objekt-nutzungs-list.component.html',
  styleUrls: ['./objekt-nutzungs-list.component.css'],
})
export class ObjektNutzungsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IObjektNutzung> = new EventEmitter<IObjektNutzung>();

  constructor(public objektNutzungs: ObjektNutzungsService) {}

  ngOnDestroy() {}
}
