import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenGruppesServiceModule } from '..';
import { KundenGruppesSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenGruppesServiceModule
  ],
  declarations: [KundenGruppesSorterComponent],
  exports: [KundenGruppesSorterComponent],

})
export class KundenGruppesSorterComponentModule { }
