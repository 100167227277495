import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IOffeneGutachtenQuerySorter extends ISorter {
  'gutachten.id'?: ISorterExpression;
  'gutachten.projekt'?: ISorterExpression;
  'gutachten.objekt'?: ISorterExpression;
  'projekt.bewertungsAnlass'?: ISorterExpression;
  'gutachten.bewertungsStatus'?: ISorterExpression;
  'gutachten.objektNutzung'?: ISorterExpression;
  'gutachten.objektArt'?: ISorterExpression;
  'gutachten.sicherheitscheck'?: ISorterExpression;
  'gutachten.abgabeDraft'?: ISorterExpression;
  'gutachten.abgabeFinal'?: ISorterExpression;
  'gutachten.erbbaurecht'?: ISorterExpression;
  'gutachten.wohnenJRoE'?: ISorterExpression;
  'gutachten.wohnenJRoEFlaeche'?: ISorterExpression;
  'gutachten.gewerbeJRoE'?: ISorterExpression;
  'gutachten.gewerbeJRoEFlaeche'?: ISorterExpression;
  'gutachten.bueroJRoE'?: ISorterExpression;
  'gutachten.bueroJRoEFlaeche'?: ISorterExpression;
  'gutachten.handelJRoE'?: ISorterExpression;
  'gutachten.handelJRoEFlaeche'?: ISorterExpression;
  'gutachten.lagerJRoE'?: ISorterExpression;
  'gutachten.lagerJRoEFlaeche'?: ISorterExpression;
  'gutachten.sonstigesJRoE'?: ISorterExpression;
  'gutachten.sonstigesJRoEFlaeche'?: ISorterExpression;
  'gutachten.baujahr'?: ISorterExpression;
  'gutachten.modernisierung'?: ISorterExpression;
  'gutachten.marktwert'?: ISorterExpression;
  'gutachten.stichtagMarktwert'?: ISorterExpression;
  'gutachten.kaufpreis'?: ISorterExpression;
  'gutachten.stichtagKaufpreis'?: ISorterExpression;
  'gutachten.fortschritt'?: ISorterExpression;
  'gutachten.aktiv'?: ISorterExpression;
  'gutachten.kommentar'?: ISorterExpression;
  'gutachten.tourenplanung'?: ISorterExpression;
  'projekt.id'?: ISorterExpression;
  'projekt.bezeichnung'?: ISorterExpression;
  'projekt.nummer'?: ISorterExpression;
  'projekt.projektZaehler'?: ISorterExpression;
  'projekt.projektArt'?: ISorterExpression;
  'projekt.projektStatus'?: ISorterExpression;
  'projekt.standort'?: ISorterExpression;
  'projekt.beauftragung'?: ISorterExpression;
  'projekt.deadline'?: ISorterExpression;
  'projekt.kundenAnfertigung'?: ISorterExpression;
  'projekt.keineBesichtigungErforderlich'?: ISorterExpression;
  'projekt.besichtigungen'?: ISorterExpression;
  'projekt.gutachten'?: ISorterExpression;
  'projekt.pruefung'?: ISorterExpression;
  'projekt.kunde'?: ISorterExpression;
  'projekt.abteilung'?: ISorterExpression;
  'projekt.fond'?: ISorterExpression;
  'projekt.bank'?: ISorterExpression;
  'projekt.objektArt'?: ISorterExpression;
  'projekt.nachlass'?: ISorterExpression;
  'projekt.kundenZeichen'?: ISorterExpression;
  'projekt.kontakteInOutlook'?: ISorterExpression;
  'projekt.dateiVerzeichnis'?: ISorterExpression;
  'projekt.kommentar'?: ISorterExpression;
  'projekt.notizenFuerRechnung'?: ISorterExpression;
  'projekt.fortschritt'?: ISorterExpression;
  // 'projekt.strecke'?: ISorterExpression;
  // 'projekt.kalkulierteStrecke'?: ISorterExpression;
  // 'projekt.fahrtzeit'?: ISorterExpression;
  // 'projekt.kalkulierteFahrtzeit'?: ISorterExpression;
  'projekt.stunden'?: ISorterExpression;
  'projekt.kostenNachStunden'?: ISorterExpression;
  'projekt.verhandeltesHonorar'?: ISorterExpression;
  'projekt.kosten'?: ISorterExpression;
  'projekt.kostenBeglichen'?: ISorterExpression;
  'projekt.aktiv'?: ISorterExpression;
  'projekt.darlehensnehmer'?: ISorterExpression;
  'projekt.notizenBesichtigung'?: ISorterExpression;
  'projekt.kreditsachbearbeiterVorname'?: ISorterExpression;
  'projekt.kreditsachbearbeiterName'?: ISorterExpression;
  'projekt.kreditsachbearbeiterTelefon'?: ISorterExpression;
  'projekt.kreditsachbearbeiterEMail'?: ISorterExpression;
  'objekt.id'?: ISorterExpression;
  'objekt.name'?: ISorterExpression;
  'objekt.addresse'?: ISorterExpression;
  'objekt.bereiche'?: ISorterExpression;
  'objekt.anspracheOutlookKontakts'?: ISorterExpression;
  'objekt.klingelschild'?: ISorterExpression;
  'objekt.kommentar'?: ISorterExpression;
  'kunde.id'?: ISorterExpression;
  'kunde.name'?: ISorterExpression;
  'kunde.initialen'?: ISorterExpression;
  'kunde.kundenGruppe'?: ISorterExpression;
  'kunde.kundenArt'?: ISorterExpression;
  'kunde.addresse'?: ISorterExpression;
  'kunde.website'?: ISorterExpression;
  'kunde.outlookKontakte'?: ISorterExpression;
  'kunde.kontaktNotizen'?: ISorterExpression;
  'kunde.anforderungenNotizen'?: ISorterExpression;
  'kunde.rechnungNotizen'?: ISorterExpression;
  'kunde.fahrtkostenAbrechnung'?: ISorterExpression;
  'kunde.steuerId'?: ISorterExpression;
  'kunde.fahrtkostenAbRadius'?: ISorterExpression;
  'kunde.fahrtkostenProKm'?: ISorterExpression;
  'kunde.fahrtkostenProStunde'?: ISorterExpression;
  'kunde.skonto'?: ISorterExpression;
  'kunde.zahlungsziel'?: ISorterExpression;
  'kunde.tageOhneSkonto'?: ISorterExpression;
  'kunde.aktiv'?: ISorterExpression;
  'kunde.dateipfad'?: ISorterExpression;
  'kunde.loraName'?: ISorterExpression;
  'kunde.unterlagenEmail'?: ISorterExpression;
  'besichtigung.id'?: ISorterExpression;
  'besichtigung.projekt'?: ISorterExpression;
  'besichtigung.tour'?: ISorterExpression;
  'besichtigung.gutachten'?: ISorterExpression;
  'besichtigung.objekt'?: ISorterExpression;
  'besichtigung.besichtigungsStatus'?: ISorterExpression;
  'besichtigung.mitarbeiter'?: ISorterExpression;
  'besichtigung.kommentar'?: ISorterExpression;
  'besichtigung.rueckfahrt'?: ISorterExpression;
  'besichtigung.von'?: ISorterExpression;
  'besichtigung.bis'?: ISorterExpression;
  'besichtigung.start'?: ISorterExpression;
  'besichtigung.ende'?: ISorterExpression;
  'besichtigung.aufenthalt'?: ISorterExpression;
  'besichtigung.fahrtzeit'?: ISorterExpression;
  'besichtigung.fahrtstrecke'?: ISorterExpression;
  'besichtigung.fahrtzeitOhneVerkehr'?: ISorterExpression;
  // 'besichtigung.kosten'?: ISorterExpression;
  'besichtigung.abgerechnet'?: ISorterExpression;
  'besichtigung.route'?: ISorterExpression;
}

export const OffeneGutachtenQuerySorterConfig: ISorterConfig = [
  SorterConfig('gutachten.id', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ID.title'),
  SorterConfig('gutachten.projekt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.PROJEKT.title'),
  SorterConfig('gutachten.objekt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT.title'),
  SorterConfig('projekt.bewertungsAnlass', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_ANLASS.title'),
  SorterConfig('gutachten.bewertungsStatus', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_STATUS.title'),
  SorterConfig('gutachten.objektNutzung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_NUTZUNG.title'),
  SorterConfig('gutachten.objektArt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_ART.title'),
  SorterConfig('gutachten.sicherheitscheck', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SICHERHEITSCHECK.title'),
  SorterConfig('gutachten.abgabeDraft', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_DRAFT.title'),
  SorterConfig('gutachten.abgabeFinal', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_FINAL.title'),
  SorterConfig('gutachten.erbbaurecht', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ERBBAURECHT.title'),
  SorterConfig('gutachten.wohnenJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E.title'),
  SorterConfig('gutachten.wohnenJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.gewerbeJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E.title'),
  SorterConfig('gutachten.gewerbeJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.bueroJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E.title'),
  SorterConfig('gutachten.bueroJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.handelJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E.title'),
  SorterConfig('gutachten.handelJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.lagerJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E.title'),
  SorterConfig('gutachten.lagerJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.sonstigesJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E.title'),
  SorterConfig('gutachten.sonstigesJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E_FLAECHE.title'),
  SorterConfig('gutachten.baujahr', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BAUJAHR.title'),
  SorterConfig('gutachten.modernisierung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MODERNISIERUNG.title'),
  SorterConfig('gutachten.marktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MARKTWERT.title'),
  SorterConfig('gutachten.stichtagMarktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_MARKTWERT.title'),
  SorterConfig('gutachten.kaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.KAUFPREIS.title'),
  SorterConfig('gutachten.stichtagKaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_KAUFPREIS.title'),
  SorterConfig('gutachten.fortschritt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.FORTSCHRITT.title'),
  SorterConfig('gutachten.aktiv', 'GUTACHTEN.Models.GUTACHTEN.Propertys.AKTIV.title'),
  SorterConfig('gutachten.kommentar', 'GUTACHTEN.Models.GUTACHTEN.Propertys.KOMMENTAR.title'),
  SorterConfig('gutachten.tourenplanung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.TOURENPLANUNG.title'),
  SorterConfig('projekt.id', 'PROJEKTE.Models.PROJEKT.Propertys.ID.title'),
  SorterConfig('projekt.bezeichnung', 'PROJEKTE.Models.PROJEKT.Propertys.BEZEICHNUNG.title'),
  SorterConfig('projekt.nummer', 'PROJEKTE.Models.PROJEKT.Propertys.NUMMER.title'),
  SorterConfig('projekt.projektZaehler', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ZAEHLER.title'),
  SorterConfig('projekt.projektArt', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ART.title'),
  SorterConfig('projekt.projektStatus', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_STATUS.title'),
  SorterConfig('projekt.standort', 'PROJEKTE.Models.PROJEKT.Propertys.STANDORT.title'),
  SorterConfig('projekt.beauftragung', 'PROJEKTE.Models.PROJEKT.Propertys.BEAUFTRAGUNG.title'),
  SorterConfig('projekt.deadline', 'PROJEKTE.Models.PROJEKT.Propertys.DEADLINE.title'),
  SorterConfig('projekt.kundenAnfertigung', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDEN_ANFERTIGUNG.title'),
  SorterConfig('projekt.keineBesichtigungErforderlich', 'PROJEKTE.Models.PROJEKT.Propertys.KEINE_BESICHTIGUNG_ERFORDERLICH.title'),
  SorterConfig('projekt.besichtigungen', 'PROJEKTE.Models.PROJEKT.Propertys.BESICHTIGUNGEN.title'),
  SorterConfig('projekt.gutachten', 'PROJEKTE.Models.PROJEKT.Propertys.GUTACHTEN.title'),
  SorterConfig('projekt.pruefung', 'PROJEKTE.Models.PROJEKT.Propertys.PRUEFUNG.title'),
  SorterConfig('projekt.kunde', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDE.title'),
  SorterConfig('projekt.abteilung', 'PROJEKTE.Models.PROJEKT.Propertys.ABTEILUNG.title'),
  SorterConfig('projekt.fond', 'PROJEKTE.Models.PROJEKT.Propertys.FOND.title'),
  SorterConfig('projekt.bank', 'PROJEKTE.Models.PROJEKT.Propertys.BANK.title'),
  SorterConfig('projekt.objektArt', 'PROJEKTE.Models.PROJEKT.Propertys.OBJEKT_ART.title'),
  SorterConfig('projekt.nachlass', 'PROJEKTE.Models.PROJEKT.Propertys.NACHLASS.title'),
  SorterConfig('projekt.kundenZeichen', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDEN_ZEICHEN.title'),
  SorterConfig('projekt.kontakteInOutlook', 'PROJEKTE.Models.PROJEKT.Propertys.KONTAKTE_IN_OUTLOOK.title'),
  SorterConfig('projekt.dateiVerzeichnis', 'PROJEKTE.Models.PROJEKT.Propertys.DATEI_VERZEICHNIS.title'),
  SorterConfig('projekt.kommentar', 'PROJEKTE.Models.PROJEKT.Propertys.KOMMENTAR.title'),
  SorterConfig('projekt.notizenFuerRechnung', 'PROJEKTE.Models.PROJEKT.Propertys.NOTIZEN_FUER_RECHNUNG.title'),
  SorterConfig('projekt.fortschritt', 'PROJEKTE.Models.PROJEKT.Propertys.FORTSCHRITT.title'),
  // SorterConfig('projekt.strecke', 'PROJEKTE.Models.PROJEKT.Propertys.STRECKE.title'),
  // SorterConfig('projekt.kalkulierteStrecke', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_STRECKE.title'),
  // SorterConfig('projekt.fahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.FAHRTZEIT.title'),
  // SorterConfig('projekt.kalkulierteFahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_FAHRTZEIT.title'),
  SorterConfig('projekt.stunden', 'PROJEKTE.Models.PROJEKT.Propertys.STUNDEN.title'),
  SorterConfig('projekt.kostenNachStunden', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_NACH_STUNDEN.title'),
  SorterConfig('projekt.verhandeltesHonorar', 'PROJEKTE.Models.PROJEKT.Propertys.VERHANDELTES_HONORAR.title'),
  SorterConfig('projekt.kosten', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN.title'),
  SorterConfig('projekt.kostenBeglichen', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_BEGLICHEN.title'),
  SorterConfig('projekt.aktiv', 'PROJEKTE.Models.PROJEKT.Propertys.AKTIV.title'),
  SorterConfig('projekt.darlehensnehmer', 'PROJEKTE.Models.PROJEKT.Propertys.DARLEHENSNEHMER.title'),
  SorterConfig('projekt.notizenBesichtigung', 'PROJEKTE.Models.PROJEKT.Propertys.NOTIZEN_BESICHTIGUNG.title'),
  SorterConfig('projekt.kreditsachbearbeiterVorname', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_VORNAME.title'),
  SorterConfig('projekt.kreditsachbearbeiterName', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_NAME.title'),
  SorterConfig('projekt.kreditsachbearbeiterTelefon', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_TELEFON.title'),
  SorterConfig('projekt.kreditsachbearbeiterEMail', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_E_MAIL.title'),
  SorterConfig('objekt.id', 'OBJEKTE.Models.OBJEKT.Propertys.ID.title'),
  SorterConfig('objekt.name', 'OBJEKTE.Models.OBJEKT.Propertys.NAME.title'),
  SorterConfig('objekt.addresse', 'OBJEKTE.Models.OBJEKT.Propertys.ADDRESSE.title'),
  SorterConfig('objekt.bereiche', 'OBJEKTE.Models.OBJEKT.Propertys.BEREICHE.title'),
  SorterConfig('objekt.anspracheOutlookKontakts', 'OBJEKTE.Models.OBJEKT.Propertys.ANSPRACHE_OUTLOOK_KONTAKTS.title'),
  SorterConfig('objekt.klingelschild', 'OBJEKTE.Models.OBJEKT.Propertys.KLINGELSCHILD.title'),
  SorterConfig('objekt.kommentar', 'OBJEKTE.Models.OBJEKT.Propertys.KOMMENTAR.title'),
  SorterConfig('kunde.id', 'KUNDEN.Models.KUNDE.Propertys.ID.title'),
  SorterConfig('kunde.name', 'KUNDEN.Models.KUNDE.Propertys.NAME.title'),
  SorterConfig('kunde.initialen', 'KUNDEN.Models.KUNDE.Propertys.INITIALEN.title'),
  SorterConfig('kunde.kundenGruppe', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_GRUPPE.title'),
  SorterConfig('kunde.kundenArt', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.title'),
  SorterConfig('kunde.addresse', 'KUNDEN.Models.KUNDE.Propertys.ADDRESSE.title'),
  SorterConfig('kunde.website', 'KUNDEN.Models.KUNDE.Propertys.WEBSITE.title'),
  SorterConfig('kunde.outlookKontakte', 'KUNDEN.Models.KUNDE.Propertys.OUTLOOK_KONTAKTE.title'),
  SorterConfig('kunde.kontaktNotizen', 'KUNDEN.Models.KUNDE.Propertys.KONTAKT_NOTIZEN.title'),
  SorterConfig('kunde.anforderungenNotizen', 'KUNDEN.Models.KUNDE.Propertys.ANFORDERUNGEN_NOTIZEN.title'),
  SorterConfig('kunde.rechnungNotizen', 'KUNDEN.Models.KUNDE.Propertys.RECHNUNG_NOTIZEN.title'),
  SorterConfig('kunde.fahrtkostenAbrechnung', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_ABRECHNUNG.title'),
  SorterConfig('kunde.steuerId', 'KUNDEN.Models.KUNDE.Propertys.STEUER_ID.title'),
  SorterConfig('kunde.fahrtkostenAbRadius', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.title'),
  SorterConfig('kunde.fahrtkostenProKm', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_KM.title'),
  SorterConfig('kunde.fahrtkostenProStunde', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.title'),
  SorterConfig('kunde.skonto', 'KUNDEN.Models.KUNDE.Propertys.SKONTO.title'),
  SorterConfig('kunde.zahlungsziel', 'KUNDEN.Models.KUNDE.Propertys.ZAHLUNGSZIEL.title'),
  SorterConfig('kunde.tageOhneSkonto', 'KUNDEN.Models.KUNDE.Propertys.TAGE_OHNE_SKONTO.title'),
  SorterConfig('kunde.aktiv', 'KUNDEN.Models.KUNDE.Propertys.AKTIV.title'),
  SorterConfig('kunde.dateipfad', 'KUNDEN.Models.KUNDE.Propertys.DATEIPFAD.title'),
  SorterConfig('kunde.loraName', 'KUNDEN.Models.KUNDE.Propertys.LORA_NAME.title'),
  SorterConfig('kunde.unterlagenEmail', 'KUNDEN.Models.KUNDE.Propertys.UNTERLAGEN_EMAIL.title'),
  SorterConfig('besichtigung.id', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ID.title'),
  SorterConfig('besichtigung.projekt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.PROJEKT.title'),
  SorterConfig('besichtigung.tour', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.TOUR.title'),
  SorterConfig('besichtigung.gutachten', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.GUTACHTEN.title'),
  SorterConfig('besichtigung.objekt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.OBJEKT.title'),
  SorterConfig('besichtigung.besichtigungsStatus', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BESICHTIGUNGS_STATUS.title'),
  SorterConfig('besichtigung.mitarbeiter', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.MITARBEITER.title'),
  SorterConfig('besichtigung.kommentar', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.KOMMENTAR.title'),
  SorterConfig('besichtigung.rueckfahrt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.RUECKFAHRT.title'),
  SorterConfig('besichtigung.von', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.VON.title'),
  SorterConfig('besichtigung.bis', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BIS.title'),
  SorterConfig('besichtigung.start', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.START.title'),
  SorterConfig('besichtigung.ende', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ENDE.title'),
  SorterConfig('besichtigung.aufenthalt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.AUFENTHALT.title'),
  SorterConfig('besichtigung.fahrtzeit', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT.title'),
  SorterConfig('besichtigung.fahrtstrecke', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTSTRECKE.title'),
  SorterConfig('besichtigung.fahrtzeitOhneVerkehr', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT_OHNE_VERKEHR.title'),
  // SorterConfig('besichtigung.kosten', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.KOSTEN.title'),
  SorterConfig('besichtigung.abgerechnet', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ABGERECHNET.title'),
  SorterConfig('besichtigung.route', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ROUTE.title'),
];
