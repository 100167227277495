import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IFormularRequest, IFormularResponse } from 'fa-kt.types';
import { FeldKategoriesService, FeldUnterkategoriesService } from '../../../felder';
import { PostFormularCommandService } from '../../commands';
import { FormularService } from '../../querys';

export interface IFormularPageResolverResult {
  formular?: IFormularResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class FormularPageResolver implements Resolve<IFormularPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private formular: FormularService,
    private postFormular: PostFormularCommandService,
    private kategorien: FeldKategoriesService,
    private unterkategorien: FeldUnterkategoriesService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IFormularPageResolverResult> {
    this.loading.resolving();
    const requests = {
      formular: {} as IFormularRequest,
    };
    const log = (error: any) => console.error(error);
    requests.formular.formular = route.queryParams['id'];
    if (requests.formular.formular === 'new') {
      await Promise.all([this.postFormular.prepare().catch(log), this.kategorien.request({}).catch(log), this.unterkategorien.request({}).catch(log)]);
      this.loading.resolved();
      return {
        formular: {
          formular: {
            id: '',
            name: '',
          },
        } as IFormularResponse,
      };
    }
    const [formular] = await Promise.all([
      this.formular.request(requests.formular).catch(log),
      this.postFormular.prepare().catch(log),
      this.kategorien.request({}).catch(log),
      this.unterkategorien.request({}).catch(log),
    ]);
    this.loading.resolved();
    return {
      formular,
    };
  }
}
