import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IExtraVereinbarungSorter extends ISorter {
  id?: ISorterExpression;
  kunde?: ISorterExpression;
  name?: ISorterExpression;
  beschreibung?: ISorterExpression;
  pro?: ISorterExpression;
  kosten?: ISorterExpression;
}

export const ExtraVereinbarungSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('name', 'Name'),
  SorterConfig('beschreibung', 'Beschreibung'),
  SorterConfig('pro', 'Pro'),
  SorterConfig('kosten', 'Kosten')
];
