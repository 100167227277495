import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { OffeneGutachtenServiceModule } from '..';
import { SavedOffeneGutachtenFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    OffeneGutachtenServiceModule
  ],
  declarations: [SavedOffeneGutachtenFiltersComponent],
  exports: [SavedOffeneGutachtenFiltersComponent],

})
export class SavedOffeneGutachtenFiltersComponentModule { }
