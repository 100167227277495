import { NgModule } from '@angular/core';

import {
  PostFeldKategorieCommandServiceModule,
  PostFeldKategorieCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostFeldKategorieCommandServiceModule,
    PostFeldKategorieCommandComponentModule
  ],
  exports: [
    PostFeldKategorieCommandServiceModule,
    PostFeldKategorieCommandComponentModule
  ]
})
export class PostFeldKategorieCommandModule { }
