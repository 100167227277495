import { NgModule } from '@angular/core';

import { DeleteFeldKategorieCommandModule, FeldKategoriesQueryModule, PostFeldKategorieCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostFeldKategorieCommandModule, DeleteFeldKategorieCommandModule, FeldKategoriesQueryModule],
  exports: [PostFeldKategorieCommandModule, DeleteFeldKategorieCommandModule, FeldKategoriesQueryModule],
})
export class FeldKategoriesSettingsModule {}
