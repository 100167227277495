import { NgModule } from '@angular/core';

import {
  PostObjektNutzungCommandServiceModule,
  PostObjektNutzungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostObjektNutzungCommandServiceModule,
    PostObjektNutzungCommandComponentModule
  ],
  exports: [
    PostObjektNutzungCommandServiceModule,
    PostObjektNutzungCommandComponentModule
  ]
})
export class PostObjektNutzungCommandModule { }
