import { NgModule } from '@angular/core';

import {
  DeleteObjektArtCommandServiceModule,
  DeleteObjektArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteObjektArtCommandServiceModule,
    DeleteObjektArtCommandComponentModule
  ],
  exports: [
    DeleteObjektArtCommandServiceModule,
    DeleteObjektArtCommandComponentModule
  ]
})
export class DeleteObjektArtCommandModule { }
