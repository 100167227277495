import { Injectable } from '@angular/core';

import { IDeleteFormularRequest, IDeleteFormularResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteFormularCommandService extends DeleteCommandService<IDeleteFormularRequest, IDeleteFormularResponse> {
  readonly route = 'formulare/formular';
}
