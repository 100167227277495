import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { IOffeneGutachtenQueryShapes, IOffeneGutachtenResponse, OffeneGutachtenQueryFilterConfig } from 'fa-kt.types';
import { OffeneGutachtenService } from '../service';

@Component({
  selector: 'fa-kt-offene-gutachten-filter',
  templateUrl: './offene-gutachten-filter.component.html',
  styleUrls: ['./offene-gutachten-filter.component.css'],
})
export class OffeneGutachtenFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public OffeneGutachtenFilterConfig: IFilterConfig<IOffeneGutachtenResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public offeneGutachten: OffeneGutachtenService,
  ) {
    this.OffeneGutachtenFilterConfig = OffeneGutachtenQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.offeneGutachten.shapes$.subscribe((shapes: IOffeneGutachtenQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        this.offeneGutachten.replacements.forEach((r) => {
          Object.entries(r)
            .filter(([key]) => shapes[key])
            .forEach(([key, value]) => (shapes[value] = shapes[key]));
        });
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.OffeneGutachtenFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.OffeneGutachtenFilterConfig[index].selections = shapes;
  }
}
