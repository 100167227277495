import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ReisekostenabrechnungStatiServiceModule } from '..';
import { ReisekostenabrechnungStatiFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungStatiServiceModule
  ],
  declarations: [ReisekostenabrechnungStatiFilterComponent],
  exports: [ReisekostenabrechnungStatiFilterComponent],

})
export class ReisekostenabrechnungStatiFilterComponentModule { }
