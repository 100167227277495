import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteHerausgeberRequest, IDeleteHerausgeberResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteHerausgeberCommandService extends DeleteCommandService<IDeleteHerausgeberRequest, IDeleteHerausgeberResponse> {
  readonly route = 'markt/settings/herausgeber';
}
