import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';
import { FinanzenComponentsModule } from '../finanzen';

import { GutachtenComponentsModule } from '../gutachten';
import { KommunikationComponentsModule } from '../kommunikation';
import { ZeitenComponentsModule } from '../zeiten';

import { ProjekteCommandsModule } from './commands';
import { ProjekteComponentsModule } from './components';
import { DashboardPage, DashboardPageResolver, ErinnerungPipe, MinePipe, ProjekteFilterService, ProjektePage, ProjektePageResolver, ProjektPage, ProjektPageResolver, UnassignedPipe } from './pages';
import { ArchivService, DashboardService, ProjekteQuerysModule, ProjekteSelectService, ProjekteService, ProjektService } from './querys';
import {
  ProjektArtsPage,
  ProjektArtsPageResolver,
  ProjektArtsService,
  ProjektArtsServiceModule,
  ProjekteSettingsModule,
  ProjektStatiPage,
  ProjektStatiPageResolver,
  ProjektStatiService,
  ProjektStatiServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'projekte',
    children: [
      {
        path: 'dashboard',
        component: DashboardPage,
        resolve: {
          querys: DashboardPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['projekte'],
            roles: ['GET_DASHBOARD'],
          },
        },
      },
      {
        path: 'projekte',
        component: ProjektePage,
        resolve: {
          querys: ProjektePageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['projekte'],
            roles: ['GET_PROJEKTE'],
          },
        },
      },
      {
        path: 'projekt',
        component: ProjektPage,
        resolve: {
          querys: ProjektPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['projekte'],
            roles: ['GET_PROJEKTE'],
          },
        },
      },
    ],
  },
  {
    path: 'projekte',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'projekt-stati',
            component: ProjektStatiPage,
            resolve: {
              querys: ProjektStatiPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_PROJEKT_STATI'],
                  write: ['POST_PROJEKT_STATUS', 'DELETE_PROJEKT_STATUS'],
                },
              },
            },
          },
          {
            path: 'projekt-arts',
            component: ProjektArtsPage,
            resolve: {
              querys: ProjektArtsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_PROJEKT_ARTS'],
                  write: ['POST_PROJEKT_ART', 'DELETE_PROJEKT_ART'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [DashboardPage, ProjektePage, ProjektPage, ProjektStatiPage, ProjektArtsPage, MinePipe, UnassignedPipe, ErinnerungPipe],
  providers: [MinePipe, UnassignedPipe, ErinnerungPipe],
  imports: [
    LibsCommonAngularModule,
    KommunikationComponentsModule,
    GutachtenComponentsModule,
    FinanzenComponentsModule,
    ZeitenComponentsModule,
    ProjekteSettingsModule,
    ProjekteComponentsModule,
    ProjekteCommandsModule,
    ProjekteQuerysModule,
    ProjektArtsServiceModule,
    ProjektStatiServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [ProjekteSettingsModule, ProjekteComponentsModule, ProjekteCommandsModule, ProjekteQuerysModule, ProjektArtsServiceModule, ProjektStatiServiceModule],
})
export class ProjekteModule {
  static forRoot(): ModuleWithProviders<ProjekteModule> {
    return {
      ngModule: ProjekteModule,
      providers: [ArchivService, DashboardService, ProjektService, ProjekteService, ProjekteSelectService, ProjekteFilterService, ProjektArtsService, ProjektStatiService],
    };
  }
}
