<div [formGroup]="form" [style.paddingTop]="'16px'">
  <nz-form-item>
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24">
      <i nz-icon nzType="search" nzTheme="outline" style="margin-right: 6px"></i>
      {{ placeholder }}
    </nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24">
      <input
        nz-input
        [ngModel]="search$ | async"
        (ngModelChange)="search$.next($event)"
        [ngModelOptions]="{ standalone: true }"
        placeholder="Direkte Suche nach Adressen"
        name="xyz123"
        id="xyz123"
        autocomplete="xyz123"
        [nzAutocomplete]="AddressSuggestions"
        cdkFocusInitial
      />
      <nz-autocomplete #AddressSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
        <nz-auto-option
          *ngFor="let suggestion of $suggestions | async"
          [nzValue]="suggestion.address.freeformAddress"
          [nzLabel]="suggestion.address.freeformAddress"
          (click)="setSuggestion(suggestion)"
        >
          <span nz-typography nzType="success">{{ suggestion.address.freeformAddress }}</span>
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" [nzRequired]="nzRequired" nzFor="land">Adresszeile 1</nz-form-label>
    <nz-form-control [nzSm]="7" [nzXs]="24" [nzErrorTip]="'Land angeben'">
      <input nz-input name="xyz123" id="xyz1234" autocomplete="xyz123" formControlName="land" id="land" [placeholder]="'Land'" autocomplete="off" [nzAutocomplete]="CountryNameSuggestions" />
      <nz-autocomplete #CountryNameSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
        <nz-auto-option *ngFor="let suggestion of $suggestions | async" [nzValue]="suggestion.address.country" [nzLabel]="suggestion.address.country" (click)="setSuggestion(suggestion)">
          <span nz-typography nzType="success">{{ suggestion.address.country }}</span
          ><span sryle="margin-left: 16px" nz-typography nzType="secondary">{{ suggestion.address.freeformAddress }}</span>
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>

    <nz-form-control [nzOffset]="1" [nzSm]="4" [nzXs]="24" [nzErrorTip]="'PLZ angeben'">
      <input nz-input name="xyz123" id="xyz1235" autocomplete="xyz123" formControlName="plz" id="plz" [placeholder]="'PLZ'" autocomplete="off" [nzAutocomplete]="PostalCodeSuggestions" type="number" />
      <nz-autocomplete #PostalCodeSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
        <nz-auto-option *ngFor="let suggestion of $suggestions | async" [nzValue]="suggestion.address.postalCode" [nzLabel]="suggestion.address.postalCode" (click)="setSuggestion(suggestion)">
          <span nz-typography nzType="success">{{ suggestion.address.postalCode }}</span
          ><span sryle="margin-left: 16px" nz-typography nzType="secondary">{{ suggestion.address.freeformAddress }}</span>
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" [nzRequired]="nzRequired" nzFor="strasse">Adresszeile 2</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="'Straße mit Hausnummer angeben'">
      <input
        nz-input
        name="xyz123"
        id="xyz1236"
        autocomplete="xyz123"
        formControlName="strasse"
        id="strasse"
        [placeholder]="'Straße mit Hausnummer'"
        autocomplete="off"
        [nzAutocomplete]="StreetAddressSuggestions"
      />
      <nz-autocomplete #StreetAddressSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
        <nz-auto-option *ngFor="let suggestion of $suggestions | async" [nzValue]="suggestion.address.streetAddress" [nzLabel]="suggestion.address.streetAddress" (click)="setSuggestion(suggestion)">
          <span nz-typography nzType="success">{{ suggestion.address.streetAddress }}</span
          ><span sryle="margin-left: 16px" nz-typography nzType="secondary">{{ suggestion.address.freeformAddress }}</span>
        </nz-auto-option>
      </nz-autocomplete>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item style="width: 100%">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="extra">Adresszeile 3</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24">
      <nz-textarea-count [nzMaxCharacterCount]="100">
        <textarea rows="2" formControlName="extra" id="extra" [placeholder]="'Extra'" nz-input></textarea>
      </nz-textarea-count>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzRequired]="nzRequired" [nzXs]="24">Längengrad</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24">
      <input nz-input name="xyz123" id="xyz1238" autocomplete="xyz123" formControlName="longitude" id="longitude" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzRequired]="nzRequired" [nzXs]="24">Breitengrad</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24">
      <input nz-input name="xyz123" id="xyz1237" autocomplete="xyz123" formControlName="latitude" id="latitude" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24">Karte</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24">
      <pbc-atlas-map
        [coordinates]="$coordinates | async"
        (moved)="moveCoordinates($event)"
        [height]="($coordinates | async) ? '300px' : '0'"
        [width]="($coordinates | async) ? '100%' : '0'"
      ></pbc-atlas-map>
      <span *ngIf="!($coordinates | async)" nz-typography nzDanger>-</span>
    </nz-form-control>
  </nz-form-item>

  <nz-row>
    <nz-col nzOffset="2" nzSpan="22">
      <nz-collapse nzGhost style="margin-bottom: 32px">
        <nz-collapse-panel nzHeader="Noch mehr..." nzActive="false">
          <nz-form-item>
            <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="bundesland">Bundesland</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input
                nz-input
                name="xyz123"
                id="xyz1239"
                autocomplete="xyz123"
                formControlName="bundesland"
                id="bundesland"
                [placeholder]="'Bundesland'"
                autocomplete="off"
                [nzAutocomplete]="CountrySubdivisionSuggestions"
              />
              <nz-autocomplete #CountrySubdivisionSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
                <nz-auto-option
                  *ngFor="let suggestion of $suggestions | async"
                  [nzValue]="suggestion.address.countrySubdivision"
                  [nzLabel]="suggestion.address.countrySubdivision"
                  (click)="setSuggestion(suggestion)"
                >
                  <span nz-typography nzType="success">{{ suggestion.address.countrySubdivision }}</span
                  ><span sryle="margin-left: 16px" nz-typography nzType="secondary">{{ suggestion.address.freeformAddress }}</span>
                </nz-auto-option>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="kreis">Kreis</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input
                nz-input
                name="xyz123"
                id="xyz12313"
                autocomplete="xyz123"
                formControlName="kreis"
                id="kreis"
                [placeholder]="'Kreis'"
                autocomplete="off"
                [nzAutocomplete]="CountrySubdivisionSuggestions"
              />
              <nz-autocomplete #CountrySubdivisionSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
                <nz-auto-option
                  *ngFor="let suggestion of $suggestions | async"
                  [nzValue]="suggestion.address.countrySecondarySubdivision"
                  [nzLabel]="suggestion.address.countrySecondarySubdivision"
                  (click)="setSuggestion(suggestion)"
                >
                  <span nz-typography nzType="success">{{ suggestion.address.countrySecondarySubdivision }}</span
                  ><span sryle="margin-left: 16px" nz-typography nzType="secondary">{{ suggestion.address.freeformAddress }}</span>
                </nz-auto-option>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="gemeinde_stadt">Gemeinde/Stadt</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input
                nz-input
                name="xyz123"
                id="xyz12310"
                autocomplete="xyz123"
                formControlName="gemeinde_stadt"
                id="gemeinde_stadt"
                [placeholder]="'Gemeinde/Stadt'"
                autocomplete="off"
                [nzAutocomplete]="MunicipalitySuggestions"
              />
              <nz-autocomplete #MunicipalitySuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
                <nz-auto-option
                  *ngFor="let suggestion of $suggestions | async"
                  [nzValue]="suggestion.address.municipality"
                  [nzLabel]="suggestion.address.municipality"
                  (click)="setSuggestion(suggestion)"
                >
                  <span nz-typography nzType="success">{{ suggestion.address.municipality }}</span
                  ><span sryle="margin-left: 16px" nz-typography nzType="secondary">{{ suggestion.address.freeformAddress }}</span>
                </nz-auto-option>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="ort_stadt_teil">Orts-/Stadtteil</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input
                nz-input
                name="xyz123"
                id="xyz12311"
                autocomplete="xyz123"
                formControlName="ort_stadt_teil"
                id="stadtteil"
                [placeholder]="'Orts-/Stadtteil'"
                autocomplete="off"
                [nzAutocomplete]="MunicipalitySubdivisionSuggestions"
              />
              <nz-autocomplete #MunicipalitySubdivisionSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
                <nz-auto-option
                  *ngFor="let suggestion of $suggestions | async"
                  [nzValue]="suggestion.address.municipalitySubdivision"
                  [nzLabel]="suggestion.address.municipalitySubdivision"
                  (click)="setSuggestion(suggestion)"
                >
                  <span nz-typography nzType="success">{{ suggestion.address.municipalitySubdivision }}</span
                  ><span sryle="margin-left: 16px" nz-typography nzType="secondary">{{ suggestion.address.freeformAddress }}</span>
                </nz-auto-option>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="verband">Verband</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input nz-input name="xyz123" id="xyz1231234" autocomplete="xyz123" formControlName="verband" id="verband" [placeholder]="'Verband'" autocomplete="off" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="regierungsbezirk">Regierungsbezirk</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input nz-input name="xyz123" id="xyz12312" autocomplete="xyz123" formControlName="regierungsbezirk" id="regierungsbezirk" [placeholder]="'Regierungsbezirk'" autocomplete="off" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label
              [nzSm]="7"
              [nzXs]="24"
              nzFor="bundeslandKennzahl"
              [nzTooltipTitle]="
                'Bundesland (' +
                atlas.bundeslandKennzahlLength +
                ') - Regierungsbezirk (' +
                atlas.regierungsbezirkKennzahlLength +
                ') - Kreis (' +
                atlas.kreisKennzahlLength +
                ') - Verband (' +
                atlas.verbandKennzahlLength +
                ') - Gemeinde (' +
                atlas.gemeindeKennzahlLength +
                ')'
              "
            >
              Gemeindeschlüssel
            </nz-form-label>
            <nz-form-control [nzSm]="atlas.bundeslandKennzahlLength" [nzXs]="24" [nzErrorTip]="atlas.bundeslandKennzahlLength + ' Ziffern'">
              <input
                nz-input
                formControlName="bundeslandKennzahl"
                id="bundeslandKennzahl"
                [placeholder]="'BuLa'"
                autocomplete="off"
                (input)="fill('bundeslandKennzahl', bundeslandKennzahl.value, atlas.bundeslandKennzahlLength)"
                #bundeslandKennzahl
                (keyup)="next($event, bundeslandKennzahl, atlas.bundeslandKennzahlLength, regierungsbezirkKennzahl)"
              />
            </nz-form-control>
            <nz-form-control [nzSm]="atlas.regierungsbezirkKennzahlLength + 1" [nzXs]="24" [nzErrorTip]="atlas.regierungsbezirkKennzahlLength + ' Ziffern'">
              <input
                nz-input
                formControlName="regierungsbezirkKennzahl"
                id="regierungsbezirkKennzahl"
                [placeholder]="'ReB'"
                autocomplete="off"
                (input)="fill('regierungsbezirkKennzahl', regierungsbezirkKennzahl.value, atlas.regierungsbezirkKennzahlLength)"
                #regierungsbezirkKennzahl
                (keyup)="next($event, regierungsbezirkKennzahl, atlas.regierungsbezirkKennzahlLength, kreisKennzahl)"
              />
            </nz-form-control>
            <nz-form-control [nzSm]="atlas.kreisKennzahlLength" [nzXs]="24" [nzErrorTip]="atlas.kreisKennzahlLength + ' Ziffern'">
              <input
                nz-input
                formControlName="kreisKennzahl"
                id="kreisKennzahl"
                [placeholder]="'Kreis'"
                autocomplete="off"
                (input)="fill('kreisKennzahl', kreisKennzahl.value, atlas.kreisKennzahlLength)"
                #kreisKennzahl
                (keyup)="next($event, kreisKennzahl, atlas.kreisKennzahlLength, verbandKennzahl)"
              />
            </nz-form-control>
            <nz-form-control [nzSm]="atlas.verbandKennzahlLength" [nzXs]="24" [nzErrorTip]="atlas.verbandKennzahlLength + ' Ziffern'">
              <input
                nz-input
                formControlName="verbandKennzahl"
                id="verbandKennzahl"
                [placeholder]="'Verband'"
                autocomplete="off"
                (input)="fill('verbandKennzahl', verbandKennzahl.value, atlas.verbandKennzahlLength)"
                #verbandKennzahl
                (keyup)="next($event, verbandKennzahl, atlas.verbandKennzahlLength, gemeindeKennzahl)"
              />
            </nz-form-control>
            <nz-form-control [nzSm]="atlas.gemeindeKennzahlLength" [nzXs]="24" [nzErrorTip]="atlas.gemeindeKennzahlLength + ' Ziffern'">
              <input
                nz-input
                formControlName="gemeindeKennzahl"
                id="gemeindeKennzahl"
                [placeholder]="'Gem'"
                autocomplete="off"
                #gemeindeKennzahl
                (input)="fill('gemeindeKennzahl', gemeindeKennzahl.value, atlas.gemeindeKennzahlLength)"
              />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="regionalSchluessel">RegionalSchluessel</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input
                nz-input
                name="xyz123"
                id="xyz12312wef"
                autocomplete="xyz123"
                formControlName="regionalSchluessel"
                id="regionalSchluessel"
                [placeholder]="'RegionalSchluessel'"
                autocomplete="off"
              />
            </nz-form-control>
          </nz-form-item>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-col>
  </nz-row>
</div>
