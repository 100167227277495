import { NgModule } from '@angular/core';

import { DeleteFotoKategorieCommandModule, PostFotoKategorieCommandModule } from './commands';
import { FotoKategorienQueryModule } from './query';

@NgModule({
  imports: [PostFotoKategorieCommandModule, DeleteFotoKategorieCommandModule, FotoKategorienQueryModule],
  exports: [PostFotoKategorieCommandModule, DeleteFotoKategorieCommandModule, FotoKategorienQueryModule],
})
export class FotoKategorienSettingsModule {}
