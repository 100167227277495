import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SearchPipe, SITEMAP } from 'pbc.angular';

import { IEintrag, IFeld, IFeldKategorie, IFeldOption, IFormular, IGutachtenResponse, IPostEintragRequest, IPostEintragResponse } from 'fa-kt.types';
import { FelderService } from '../../../felder';
import { FormulareService } from '../../../formulare';
import { ProjektService } from '../../../projekte';
import { PostEintragCommandService } from '../../commands';
import { GutachtenService } from '../../querys';
import { groupBy } from 'lodash';

@Component({
  selector: 'fa-kt-eintraege',
  templateUrl: './eintraege.component.html',
  styleUrls: ['./eintraege.component.css'],
})
export class EintraegeComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postEintrag: BehaviorSubject<IPostEintragRequest | null> = new BehaviorSubject<IPostEintragRequest | null>(null);

  readonly localStorageFormular = 'SELECTED_FORMULAR_IN_GUTACHTEN';

  @Input() disabled = true;
  $formular = new BehaviorSubject<IFormular | undefined>(undefined);
  $formulare = new BehaviorSubject<IFormular[]>([]);
  @Input() set gutachten(gutachten: string | undefined) {
    this.$gutachten.next(gutachten);
  }
  $gutachten = new BehaviorSubject<string | undefined>(undefined);
  $search = new BehaviorSubject<string>('');

  $kategorien = new BehaviorSubject<IFeldKategorie[]>([]);
  $unzugeordnete = new BehaviorSubject<IFeld[]>([]);

  @Input() eintraege: IEintrag[] = [];
  @Output() eintraegeChange = new EventEmitter<IEintrag[]>();

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public felder: FelderService,
    public projekt: ProjektService,
    public postEintrag: PostEintragCommandService,
    public gutachtenService: GutachtenService,
    public formulareService: FormulareService,
    private searchPipe: SearchPipe,
  ) {}

  async ngOnInit() {
    /* << init    */
    this.formulareService.request({}).catch(console.error);
    /*    init >> */
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        this.eintraegeChange.subscribe((eintraege) => {
          this.eintraege = eintraege;
        }),
        combineLatest([this.projekt.result$, this.gutachtenService.result$, this.$gutachten, this.formulareService.response$]).subscribe(([result, results, id, formulare]) => {
          if (!result || !formulare?.formulare || !id || !results?.gutachten) return;
          const { projekt, kunde } = result;
          const { gutachten } = results;
          this.$formulare.next(
            formulare?.formulare
              .map(({ formular }) => formular)
              .filter(
                ({ bewertungsAnlaesse, kundenArten, kunden, objektArten }) =>
                  (!objektArten?.length || objektArten.includes(gutachten?.objektArt)) &&
                  (!kunden?.length || kunden.includes(projekt?.kunde) || kunden.includes(projekt?.bank)) &&
                  (!bewertungsAnlaesse?.length || bewertungsAnlaesse.includes(projekt?.bewertungsAnlass)) &&
                  (!kundenArten?.length || kundenArten.includes(kunde?.kundenArt)),
              ),
          );
        }),
        this.$formulare.subscribe((formulare) => {
          if (!formulare?.length || this.$formular.getValue()?.id) return;
          const storage = localStorage.getItem(this.localStorageFormular);
          this.$formular.next(formulare.find(({ id }) => id === storage) || formulare[0]);
        }),
        combineLatest([this.gutachtenService.result$, this.$gutachten, this.$formular, this.$search]).subscribe(([response, gutachten, formular, search]) => {
          if (!response || !gutachten) return;

          this.$loading.next(true);

          const selected = formular?.felder;
          let kategorien = response.kategorien;
          let unterkategorien = kategorien.map(({ unterkategorien }) => unterkategorien).flat();
          let felder = unterkategorien.map(({ felder }) => felder).flat();

          if (selected?.length) {
            felder = selected.map((v) => felder.find(({ id }) => v === id)).filter((v) => !!v);

            const kategories = Object.keys(groupBy(felder, ({ feldKategorie }) => feldKategorie));
            if (kategories?.length) kategorien = kategories.map((v) => kategorien.find(({ id }) => v === id)).filter((v) => !!v);

            const unterkategories = Object.keys(groupBy(felder, ({ feldUnterkategorie }) => feldUnterkategorie));
            if (unterkategories?.length) unterkategorien = unterkategories.map((v) => unterkategorien.find(({ id }) => v === id)).filter((v) => !!v);
          }

          this.$kategorien.next(
            kategorien
              .map((kategorie) => ({
                ...kategorie,
                unterkategorien: (unterkategorien || [])
                  .filter(({ feldKategorie }) => feldKategorie === kategorie.id)
                  .map((unterkategorie) => ({
                    ...unterkategorie,
                    felder: this.searchPipe.transform(
                      (felder || [])
                        .filter(({ feldUnterkategorie }) => feldUnterkategorie === unterkategorie.id)
                        .map((feld) => ({
                          ...feld,
                          eintrag: this.eintraege.find((e) => e.feld === feld.id) || feld.eintrag,
                        }))
                        .map((feld) => ({ ...feld, _search: (feld._search + feld.eintrag?.wert + feld.eintrag?.wertExtra).toLocaleLowerCase() })),
                      search,
                    ),
                  }))
                  .filter(({ felder }) => felder.length > 0),
              }))
              .filter(({ unterkategorien }) => unterkategorien.length > 0),
          );
          this.$unzugeordnete.next(
            this.searchPipe.transform(
              response.unzugeordnete
                .map((feld) => ({
                  ...feld,
                  eintrag: this.eintraege.find((e) => e.feld === feld.id) || feld.eintrag,
                  skipped: formular && !selected?.includes(feld.id),
                }))
                .filter(({ skipped }) => !skipped)
                .map((feld) => ({ ...feld, _search: (feld._search + feld.eintrag?.wert + feld.eintrag?.wertExtra).toLocaleLowerCase() })),
              search,
            ),
          );
          this.$loading.next(false);
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setFelder(id: string) {
    /* << set-felder    */
    /*    set-felder >> */
  }

  async finishedPostEintrag(response?: IPostEintragResponse) {
    /* << after post-eintrag    */
    /*    after post-eintrag >> */
  }

  /* << methods    */

  hasValue(eintrag: IEintrag, option: IFeldOption): boolean {
    if (!eintrag) {
      return false;
    }
    if (Array.isArray(eintrag.wert)) {
      return eintrag.wert.includes(option.option);
    } else {
      return eintrag.wert === option.option;
    }
  }
  async toggleValues(feld: IFeld, option: IFeldOption): Promise<void> {
    let wert = feld.eintrag?.wert || '';
    wert = Array.isArray(wert) ? wert : [wert];
    wert = wert.includes(option.option) ? wert.filter((s: any) => s !== option.option) : [...wert, option.option];
    feld.eintrag.wert = wert;
    this.saveToEintraege(feld.eintrag);
  }

  async submitEintrag(feld: IFeld, wert: string) {
    if (typeof wert !== 'number' && typeof wert !== 'boolean' && !wert) return;
    feld.eintrag.wert = wert;
    this.saveToEintraege(feld.eintrag);
  }

  async submitExtraEintrag(feld: IFeld, wertExtra: string) {
    if (!wertExtra) {
      return;
    }
    feld.eintrag.wertExtra = wertExtra;
    this.saveToEintraege(feld.eintrag);
  }

  saveToEintraege(eintrag: IEintrag) {
    const eintraege = this.eintraege.filter((e) => e.feld !== eintrag.feld);
    eintraege.push(eintrag);
    this.eintraegeChange.emit(eintraege);
  }

  async saveEintraege() {
    this.$loading.next(true);
    await this.submitPostEintrag({ eintrag: this.eintraege });
    this.eintraegeChange.emit([]);
    this.$loading.next(true);
  }

  /*    methods >> */

  public async submitPostEintrag(request?: IPostEintragRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postEintrag.getValue();
    if (payload) {
      try {
        const response = await this.postEintrag.request(payload);
        await this.finishedPostEintrag(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  changeFormular($event: IFormular) {
    this.$formular.next($event);
    if ($event?.id) localStorage.setItem(this.localStorageFormular, $event.id);
  }
}
