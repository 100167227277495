import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { blue, cyan, geekblue, gold, green, grey, lime, magenta, purple, red, volcano, yellow } from '@ant-design/colors';
import { Workbook } from 'exceljs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, skip } from 'rxjs/operators';

import { ActionService, APP_TITLE, AtlasService, AuthService, CustomFormatter, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';
import { IAddress, IAzureMapsCircle, IAzureMapsPosition, IAzureMapsSuggestion, ISelection } from 'pbc.types';

import { IBesichtigung, IGutachten, IKunde, IObjekt, IProjekt, IUmkreissucheRequest, IUmkreissucheResponseRow } from 'fa-kt.types';
import { isDate, round } from 'lodash';
import { isDateString, join } from 'pbc.functions';
import typia from 'typia';
import { KundenService } from '../../../kunden';
import { ProjekteService } from '../../../projekte';
import { UmkreissucheService } from '../../querys';

interface AVG {
  wohnenJRoE: number;
  wohnenJRoEFlaeche: number;
  gewerbeJRoE: number;
  gewerbeJRoEFlaeche: number;
  bueroJRoE: number;
  bueroJRoEFlaeche: number;
  handelJRoE: number;
  handelJRoEFlaeche: number;
  lagerJRoE: number;
  lagerJRoEFlaeche: number;
  sonstigesJRoE: number;
  sonstigesJRoEFlaeche: number;
  jahresrohertrag: number;
  flaeche: number;
  vervielfaeltiger: number;
  gebaeudefaktor: number;
  baujahr: number;
  modernisierung: number;
  marktwert: number;
  kaufpreis: number;
}

@Component({
  selector: 'fa-kt-umkreissuche-page',
  templateUrl: './umkreissuche.page.html',
  styleUrls: ['./umkreissuche.page.css'],
})
export class UmkreissuchePage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public _projekt: ISitemapPage;
  public _archiv: ISitemapPage;

  CustomFormatter = CustomFormatter;
  $input = new BehaviorSubject<string>('');
  $suggestions = new BehaviorSubject<IAzureMapsSuggestion[]>([]);
  $adresse = new BehaviorSubject<Partial<IAddress> | undefined>(undefined);
  search$ = new BehaviorSubject<IUmkreissucheRequest>({
    distance: 100,
    inklusiveInaktive: true,
    latitude: '0',
    longitude: '0',
  });
  $circle = new BehaviorSubject<IAzureMapsCircle | undefined>(undefined);
  $coordinateArray = new BehaviorSubject<IAzureMapsPosition[]>([]);
  $coordinateArrayWithSelected = new BehaviorSubject<IAzureMapsPosition[]>([]);
  avg$ = new BehaviorSubject<AVG | undefined>(undefined);
  popup: { gutachten: IGutachten; projekt: IProjekt; objekt: IObjekt } | undefined;
  colors = [red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey];

  $gutachtenObjektArts = new BehaviorSubject<ISelection[]>([]);
  $resize = new BehaviorSubject<Date>(new Date());
  // colors = [red, volcano, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey];

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public umkreissuche: UmkreissucheService,
    public projekte: ProjekteService,
    public kunden: KundenService,
    public atlas: AtlasService,
  ) {
    this.page = this.sitemap.GUTACHTEN.Pages.UMKREISSUCHE;
    this._projekt = sitemap.PROJEKTE.Pages.PROJEKT;
    this._archiv = sitemap.PROJEKTE.Pages.ARCHIV;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.search$.pipe(skip(1), debounceTime(1000)).subscribe(async (search) => {
          if (search) {
            await this.router.navigate(['.'], {
              relativeTo: this.route,
              queryParams: search,
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          }
        }),
        this.route.queryParams.subscribe(async (params: Params) => {
          const state = this.search$.getValue();
          const search = {
            ...state,
            ...params,
            inklusiveInaktive: params.inklusiveInaktive === 'true' || false,
          };
          this.search$.next(search);
          this.$loading.next(true);
          try {
            await this.umkreissuche.request(search, true);
          } catch (error) {
            console.error(error);
          }
          this.$loading.next(false);
          this.$circle.next({
            lat: Number(search.latitude) || 0,
            lon: Number(search.longitude) || 0,
            radius: Number(search.distance) || 1,
          });
        }),
        this.$input.pipe(debounceTime(200), distinctUntilChanged()).subscribe(async (term: string) => {
          const suggestions = await this.atlas.suggestByTerm(term);
          if (suggestions && suggestions.length > 0) {
          }
          this.$suggestions.next(suggestions);
        }),
        this.$adresse.subscribe((adresse) => {
          if (!adresse) return;
          const current = this.search$.getValue();
          this.search$.next({
            ...current,
            latitude: adresse.latitude?.toString(),
            longitude: adresse.longitude?.toString(),
          });
        }),
        this.umkreissuche.result$.subscribe(async (result) => {
          if (result) {
            let gutachtenObjektArts: any[] = await this.umkreissuche.gutachtenObjektArt.pipe(first()).toPromise();
            gutachtenObjektArts = gutachtenObjektArts.filter(
              (goa) => result.umkreissuche.filter((o) => o.projekt && o.objekt?.addresse.latitude && o.objekt?.addresse.longitude && o.gutachten?.objektArt === goa.value).length > 0,
            );
            gutachtenObjektArts = gutachtenObjektArts.map((g, i) => ({ ...g, color: this.colors[i % 11][5] }));
            const toGutachtenObjektArt = (gutachtenObjektArt: string) => gutachtenObjektArts.find((g) => g.value === gutachtenObjektArt);
            this.setAVG(result.umkreissuche);

            const objects: IAzureMapsPosition[] = result.umkreissuche
              .filter((row) => row.objekt && row.projekt)
              .map((row) => ({
                lat: row.objekt?.addresse.latitude as number,
                lon: row.objekt?.addresse.longitude as number,
                color: toGutachtenObjektArt(row.gutachten?.objektArt as string)?.color,
                object: { ...row },
              }));
            this.$coordinateArray.next(objects);
            this.$gutachtenObjektArts.next(gutachtenObjektArts);
            this.showProjekt(this.popup);
          }
        }),
        /*    subscriptions >> */
      ],
    );
  }

  distanceStep(n: number) {
    if (n < 1000) return 100;
    if (n < 5000) return 500;
    return 1000;
  }

  setAVG(result: IUmkreissucheResponseRow[]) {
    const obj = typia.random<AVG>();
    this.avg$.next(
      Object.keys(obj).reduce((avg, key) => {
        if (key === 'jahresrohertrag') {
          const values = result.filter(({ gutachten }) => !!gutachten[key]).map(({ gutachten }) => gutachten[key] / gutachten['flaeche']);
          avg = {
            [key + 'NachFlaeche']: values.reduce((n, m) => n + (!m || isNaN(m) ? 0 : m), 0) / values.length || 0,
            [key + 'NachFlaecheCount']: values.length,
            ...avg,
          };
        }
        if (obj[key + 'Flaeche']) {
          const values = result.filter(({ gutachten }) => !!gutachten[key]).map(({ gutachten }) => gutachten[key] / gutachten[key + 'Flaeche']);
          avg = {
            [key + 'NachFlaeche']: values.reduce((n, m) => n + (!m || isNaN(m) ? 0 : m), 0) / values.length || 0,
            [key + 'NachFlaecheCount']: values.length,
            ...avg,
          };
        }

        const values = result.filter(({ gutachten }) => !!gutachten[key]).map(({ gutachten }) => gutachten[key]);
        avg = {
          [key]: round(values.reduce((n, m) => n + (!m || isNaN(m) ? 0 : m), 0) / values.length || 0),
          [key + 'Count']: values.length,
          ...avg,
        };
        return avg;
      }, {} as AVG),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setUmkreissuche(id: string) {
    /* << set-umkreissuche    */
    /*    set-umkreissuche >> */
  }

  public async navigateToProjekt(queryParams: Params = {}) {
    await this.router.navigate(this._projekt.url, { queryParams });
  }
  public async navigateToArchiv(queryParams: Params = {}) {
    await this.router.navigate(this._archiv.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  formatterMeter = (value: number): string => `${value} m`;
  parserMeter = (value: string): string => value.replace(' m', '');

  /* << methods    */
  changedDistance($event: number) {
    this.search$.next({ ...(this.search$.getValue() as IUmkreissucheRequest), distance: $event });
  }

  changedInklusiveInaktive($event: boolean) {
    this.search$.next({ ...(this.search$.getValue() as IUmkreissucheRequest), inklusiveInaktive: $event });
  }

  get inklusiveInaktive(): boolean {
    return Boolean(this.search$.getValue().inklusiveInaktive);
  }

  setObjektArt(value?: string) {
    if (value) this.umkreissuche.filter$.next({ ...(this.umkreissuche.filter$.getValue() || {}), 'gutachten.objektArt': { operator: 'ist', value: [value] } });
  }

  async navigate(id: string) {
    await this.navigateToProjekt({ id });
  }

  async export() {
    this.$loading.next(true);

    const [blob] = await Promise.all([this.files.get('fa-kt-apps/GF_Gutachten.xlsx'), this.kunden.request({})]);
    if (!blob) {
      this.$loading.next(false);
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Gutachten';
    const worksheet = workbook.getWorksheet(sheetName);
    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
    let i = 6;

    const gutachten: {
      gutachten: IGutachten;
      objekt?: IObjekt;
      projekt: IProjekt;
      kunde: IKunde;
      besichtigung?: IBesichtigung;
    }[] = [];
    this.umkreissuche.result$.getValue()?.umkreissuche?.forEach((row) => {
      const besichtigungen =
        row.projekt?.besichtigungen && !row.projekt?.keineBesichtigungErforderlich
          ? join(row.projekt?.besichtigungen.map((besichtigung) => this.projekte.getProjektBesichtigungen(besichtigung)?.label as string))
          : '';
      const gutachten = row.projekt?.gutachten ? join(row.projekt?.gutachten.map((besichtigung) => this.projekte.getProjektGutachten(besichtigung)?.label as string)) : '';
      const pruefung = row.projekt?.pruefung ? (this.projekte.getProjektPruefung(row.projekt?.pruefung)?.label as string) : '';

      worksheet.getCell('B' + i).value = row.gutachten?.id;
      worksheet.getCell('C' + i).value = new Date(row.gutachten?._createdAt as Date);
      worksheet.getCell('D' + i).value = this.projekte.getProjektGutachten(row.gutachten?._createdBy as string)?.label
        ? this.projekte.getProjektGutachten(row.gutachten?._createdBy as string)?.label
        : row.gutachten?._createdBy;
      worksheet.getCell('E' + i).value = new Date(row.gutachten?._updatedAt as Date);
      worksheet.getCell('F' + i).value = this.projekte.getProjektGutachten(row.gutachten?._updatedBy as string)?.label
        ? this.projekte.getProjektGutachten(row.gutachten?._updatedBy as string)?.label
        : row.gutachten?._updatedBy;

      worksheet.getCell('G' + i).value = row.objekt?.addresse.plz + ' ' + row.objekt?.addresse.gemeinde_stadt + ', ' + row.objekt?.addresse.strasse + ' (' + row.objekt?.addresse.bundesland + ')';
      worksheet.getCell('H' + i).value = row.objekt?.addresse.bundesland;
      worksheet.getCell('I' + i).value = row.objekt?.addresse.plz;
      worksheet.getCell('J' + i).value = row.objekt?.addresse.gemeinde_stadt;
      worksheet.getCell('K' + i).value = '';
      worksheet.getCell('L' + i).value = row.objekt?.addresse.strasse;
      worksheet.getCell('M' + i).value = row.objekt?.addresse.extra;
      worksheet.getCell('O' + i).value = row.objekt?.addresse.latitude;
      worksheet.getCell('P' + i).value = row.objekt?.addresse.longitude;
      worksheet.getCell('Q' + i).value = row.objekt?.addresse.land;

      worksheet.getCell('R' + i).value = row.projekt?.nummer;
      worksheet.getCell('S' + i).value = row.projekt?.projektZaehler;
      worksheet.getCell('T' + i).value = new Date(row.projekt?._createdAt as Date).getFullYear();
      worksheet.getCell('U' + i).value = row.projekt?.nummer + ' | ' + row.projekt?.bezeichnung;

      worksheet.getCell('V' + i).value = this.projekte.getProjektProjektArt(row.projekt?.projektArt as string)?.label;
      worksheet.getCell('W' + i).value = this.projekte.getProjektProjektStatus(row.projekt?.projektStatus as string)?.label;
      worksheet.getCell('X' + i).value = this.projekte.getGutachtenBewertungsAnlass(row.projekt?.bewertungsAnlass as string)?.label;

      worksheet.getCell('Y' + i).value = pruefung;
      worksheet.getCell('Z' + i).value = gutachten;
      worksheet.getCell('AA' + i).value = besichtigungen;

      worksheet.getCell('AB' + i).value = this.projekte.getProjektBank(row.projekt?.kunde as string)?.label;
      worksheet.getCell('AC' + i).value = row.projekt?.kunde;
      worksheet.getCell('AD' + i).value = this.projekte.getProjektBank(row.projekt?.bank as string)?.label;
      worksheet.getCell('AE' + i).value = 'k.A.'; // this.kunden.getKundeKundenArt(row.kunde?.kundenArt as string)?.label;

      worksheet.getCell('AF' + i).value = this.projekte.getGutachtenBewertungsStatus(row.gutachten?.bewertungsStatus as string)?.label;

      worksheet.getCell('AG' + i).value = 'k.A.'; // row.besichtigung ? new Date(row.besichtigung.von as Date).toLocaleDateString() : '';
      worksheet.getCell('AH' + i).value = row.gutachten?.besichtigung_kontakt;
      worksheet.getCell('AI' + i).value = row.gutachten?.besichtigung_email;
      worksheet.getCell('AJ' + i).value = row.gutachten?.besichtigung_festnetz;
      worksheet.getCell('AK' + i).value = row.gutachten?.besichtigung_mobil;
      worksheet.getCell('AL' + i).value = 'k.A.'; // row.besichtigung ? row.besichtigung.kommentar : '';

      worksheet.getCell('AM' + i).value = this.isDate(row.gutachten.abgabeDraft) ? new Date(row.gutachten.abgabeDraft as Date) : null;
      worksheet.getCell('AN' + i).value = this.isDate(row.gutachten.abgabeFinal) ? new Date(row.gutachten.abgabeFinal as Date) : null;

      worksheet.getCell('AO' + i).value = this.projekte.getGutachtenObjektNutzung(row.gutachten?.objektNutzung as string)?.label;
      worksheet.getCell('AP' + i).value = this.projekte.getGutachtenObjektArt(row.gutachten?.objektArt as string)?.label;

      worksheet.getCell('AQ' + i).value = row.gutachten?.baujahr;
      worksheet.getCell('AR' + i).value = row.gutachten?.modernisierung;
      worksheet.getCell('AS' + i).value = row.gutachten?.erbbaurecht ? 'Ja' : 'Nein';
      worksheet.getCell('AT' + i).value = row.gutachten?.stichtagMarktwert ? new Date(row.gutachten?.stichtagMarktwert as Date) : null;
      worksheet.getCell('AU' + i).value = row.gutachten?.marktwert;
      worksheet.getCell('AV' + i).value = row.gutachten?.flaeche;
      worksheet.getCell('AW' + i).value = row.gutachten?.wohnenJRoEFlaeche;
      worksheet.getCell('AX' + i).value = row.gutachten?.gewerbeJRoEFlaeche;
      worksheet.getCell('AY' + i).value = row.gutachten?.sonstigesJRoEFlaeche;
      worksheet.getCell('AZ' + i).value = row.gutachten?.jahresrohertrag;
      worksheet.getCell('BA' + i).value = row.gutachten?.wohnenJRoE;
      worksheet.getCell('BB' + i).value = row.gutachten?.gewerbeJRoE;
      worksheet.getCell('BC' + i).value = row.gutachten?.sonstigesJRoE;

      i++;
    });

    await this.files.downloadBlob(new Blob([await workbook.xlsx.writeBuffer()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Export der Projektauswahl.xlsx');

    this.$loading.next(false);
  }

  isDate(dt): boolean {
    return !!dt && (isDate(dt) || isDateString(dt));
  }

  showProjekt(popup?: { gutachten: IGutachten; projekt: IProjekt; objekt: IObjekt } | undefined) {
    if (popup) {
      this.$coordinateArrayWithSelected.next(
        this.$coordinateArray.getValue().map((coordinate) => ({ ...coordinate, type: coordinate.object.gutachten.id === popup.gutachten.id ? 'selected' : 'default' })),
      );
    } else {
      this.$coordinateArrayWithSelected.next(this.$coordinateArray.getValue());
    }
    this.popup = popup;
  }

  setSuggestion(suggestion: IAzureMapsSuggestion) {
    this.$adresse.next({
      latitude: suggestion.position ? suggestion.position.lat : null,
      longitude: suggestion.position ? suggestion.position.lon : null,
    });
  }
  /*    methods >> */
}
