import { IFilter, IFilterConfig } from "pbc.types";

import { ITemplate } from '.';

export interface ITemplateFilter extends IFilter<ITemplate> {}

export const TemplateFilterConfig: IFilterConfig<ITemplate> = [];

export type ITemplateField =
  'template.id'|
  'template.datei'|
  'template.name'|
  'template.kommentar'
;
