import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IMitarbeiterQuerySorter extends ISorter {
  'mitarbeiter.id'?: ISorterExpression;
  'mitarbeiter.email'?: ISorterExpression;
  'mitarbeiter.anzeigename'?: ISorterExpression;
  'mitarbeiter.bild'?: ISorterExpression;
  'mitarbeiter.addresse'?: ISorterExpression;
  'mitarbeiter.vorname'?: ISorterExpression;
  'mitarbeiter.nachname'?: ISorterExpression;
  'mitarbeiter.telefon'?: ISorterExpression;
  'mitarbeiter.standort'?: ISorterExpression;
  'mitarbeiter.rollen'?: ISorterExpression;
  'mitarbeiter.stundenlohn'?: ISorterExpression;
  'mitarbeiter.inaktiv'?: ISorterExpression;
  'rollen.id'?: ISorterExpression;
  'rollen.name'?: ISorterExpression;
  'rollen.beschreibung'?: ISorterExpression;
  'rollen.finanzen'?: ISorterExpression;
  'mitarbeiter.isAdmin'?: ISorterExpression;
  'rollen.isAdmin'?: ISorterExpression;
}

export const MitarbeiterQuerySorterConfig: ISorterConfig = [
  SorterConfig('mitarbeiter.id', 'PERSONEN.Models.MITARBEITER.Propertys.ID.title'), 
  SorterConfig('mitarbeiter.email', 'PERSONEN.Models.MITARBEITER.Propertys.EMAIL.title'), 
  SorterConfig('mitarbeiter.anzeigename', 'PERSONEN.Models.MITARBEITER.Propertys.ANZEIGENAME.title'), 
  SorterConfig('mitarbeiter.bild', 'PERSONEN.Models.MITARBEITER.Propertys.BILD.title'), 
  SorterConfig('mitarbeiter.addresse', 'PERSONEN.Models.MITARBEITER.Propertys.ADDRESSE.title'), 
  SorterConfig('mitarbeiter.vorname', 'PERSONEN.Models.MITARBEITER.Propertys.VORNAME.title'), 
  SorterConfig('mitarbeiter.nachname', 'PERSONEN.Models.MITARBEITER.Propertys.NACHNAME.title'), 
  SorterConfig('mitarbeiter.telefon', 'PERSONEN.Models.MITARBEITER.Propertys.TELEFON.title'), 
  SorterConfig('mitarbeiter.standort', 'PERSONEN.Models.MITARBEITER.Propertys.STANDORT.title'), 
  SorterConfig('mitarbeiter.rollen', 'PERSONEN.Models.MITARBEITER.Propertys.ROLLEN.title'), 
  SorterConfig('mitarbeiter.stundenlohn', 'PERSONEN.Models.MITARBEITER.Propertys.STUNDENLOHN.title'), 
  SorterConfig('mitarbeiter.inaktiv', 'PERSONEN.Models.MITARBEITER.Propertys.INAKTIV.title'), 
  SorterConfig('rollen.id', 'PERSONEN.Models.ROLLE.Propertys.ID.title'), 
  SorterConfig('rollen.name', 'PERSONEN.Models.ROLLE.Propertys.NAME.title'), 
  SorterConfig('rollen.beschreibung', 'PERSONEN.Models.ROLLE.Propertys.BESCHREIBUNG.title'), 
  SorterConfig('rollen.finanzen', 'PERSONEN.Models.ROLLE.Propertys.FINANZEN.title'), 
  SorterConfig('mitarbeiter.isAdmin', 'Administrator'),
  SorterConfig('rollen.isAdmin', 'Administrator'),
];
