import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteBesichtigungsStatusCommandServiceModule } from '..';
import { DeleteBesichtigungsStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteBesichtigungsStatusCommandServiceModule
  ],
  declarations: [DeleteBesichtigungsStatusCommandComponent],
  exports: [DeleteBesichtigungsStatusCommandComponent],

})
export class DeleteBesichtigungsStatusCommandComponentModule { }
