import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FormularServiceModule } from '..';
import { FormularDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormularServiceModule
  ],
  declarations: [FormularDetailComponent],
  exports: [FormularDetailComponent],

})
export class FormularDetailComponentModule { }
