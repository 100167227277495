<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="mitarbeiter.search$.next($event)" [search]="mitarbeiter.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-mitarbeiter-filter [style.width]="'100%'"></fa-kt-mitarbeiter-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-mitarbeiter-sorter [style.width]="'100%'"></fa-kt-mitarbeiter-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-mitarbeiter-list [style.width]="'100%'" (select)="setMitarbeiter($event)" [loading]="$loading | async"></fa-kt-mitarbeiter-list>


  <fa-kt-post-sync-azure-active-directory [style.width]="'100%'"  [value]="$postSyncAzureActiveDirectory | async" (finished)="finishedPostSyncAzureActiveDirectory($event)"></fa-kt-post-sync-azure-active-directory>
    


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="profil" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <div *nzSpaceItem style="width: 100%">
        <fa-kt-saved-mitarbeiter-filters style="width: 100%"></fa-kt-saved-mitarbeiter-filters>
      </div>
      <fa-kt-post-sync-azure-active-directory
        *nzSpaceItem
        style="min-width: 160px"
        [value]="$postSyncAzureActiveDirectory | async"
        (finished)="finishedPostSyncAzureActiveDirectory()"
      ></fa-kt-post-sync-azure-active-directory>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <pbc-search-bar [style.width]="'100%'" (searchChange)="mitarbeiter.search$.next($event)" [search]="mitarbeiter.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
      <fa-kt-mitarbeiter-filter [style.width]="'100%'"></fa-kt-mitarbeiter-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
      <fa-kt-mitarbeiter-sorter [style.width]="'100%'"></fa-kt-mitarbeiter-sorter>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan="24">
      <fa-kt-mitarbeiter-list [style.width]="'100%'" (select)="setMitarbeiter($event)" [loading]="$loading | async"></fa-kt-mitarbeiter-list>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
