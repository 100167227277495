import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostMitarbeiterCommandServiceModule } from '..';
import { PostMitarbeiterCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostMitarbeiterCommandServiceModule
  ],
  declarations: [PostMitarbeiterCommandComponent],
  exports: [PostMitarbeiterCommandComponent],

})
export class PostMitarbeiterCommandComponentModule { }
