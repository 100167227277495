import { Injectable } from '@angular/core';

import { SelectionService } from 'pbc.angular';

import { IVorlageProjektSelectionRequest } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ScopeSelectionService extends SelectionService<IVorlageProjektSelectionRequest> {
  route = 'texte/scope';
  override dependencies = [
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',
    'finanzen/rechnung-erstellt',
    'finanzen/rechnung-geloescht',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'personen/mitarbeiter-aktualisiert',
    'projekte/projekt-archiviert',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-gespeichert',

    'texte/vorlage-gespeichert',

    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'dokumente/written-erforderliches-dokument',
    'dokumente/removed-erforderliches-dokument',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
    'personen/written-standort',
    'personen/removed-standort',
    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'dokumente/datei-gespeichert',
    'dokumente/dokument-hinterlegt',
    'einstellungen/kalkulation-hochgeladen',
    'felder/bewertet',
    'gutachten/gutachten-gespeichert',
  ];
}
