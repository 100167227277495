import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitDetailComponent } from '.';
import { ZeitServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitServiceModule
  ],
  declarations: [ZeitDetailComponent],
  exports: [ZeitDetailComponent],

})
export class ZeitDetailComponentModule { }
