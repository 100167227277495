import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektArtsServiceModule } from '..';
import { ProjektArtsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektArtsServiceModule
  ],
  declarations: [ProjektArtsSorterComponent],
  exports: [ProjektArtsSorterComponent],

})
export class ProjektArtsSorterComponentModule { }
