import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostFeldCommandShapes, IPostFeldRequest, IPostFeldResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostFeldCommandService extends PostCommandService<IPostFeldRequest, IPostFeldResponse, IPostFeldCommandShapes> {
  readonly route = 'felder/feld';
  override dependencies = ['felder/written-feld-kategorie', 'felder/removed-feld-kategorie', 'felder/written-feld-unterkategorie', 'felder/removed-feld-unterkategorie'];

  getFeldFeldKategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['feld.feldKategorie']?.find((v) => v.value === value);
  }

  get feldFeldKategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feld.feldKategorie'] as ISelection[]) : [])));
  }

  getFeldFeldUnterkategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['feld.feldUnterkategorie']?.find((v) => v.value === value);
  }

  get feldFeldUnterkategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feld.feldUnterkategorie'] as ISelection[]) : [])));
  }
}
