<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="reisekostenabrechnungen.search$.next($event)" [search]="reisekostenabrechnungen.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-reisekostenabrechnungen-filter [style.width]="'100%'"></fa-kt-reisekostenabrechnungen-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-reisekostenabrechnungen-sorter [style.width]="'100%'"></fa-kt-reisekostenabrechnungen-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-reisekostenabrechnungen-list [style.width]="'100%'" (select)="setReisekostenabrechnungen($event)" [loading]="$loading | async"></fa-kt-reisekostenabrechnungen-list>



  <fa-kt-rechnungszaehler [style.width]="'100%'" ></fa-kt-rechnungszaehler>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="reisekostenabrechnung" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <div *nzSpaceItem style="width: 100%">
        <fa-kt-saved-reisekostenabrechnungen-filters style="width: 100%"></fa-kt-saved-reisekostenabrechnungen-filters>
      </div>
      <button *nzSpaceItem nz-button nzType="primary" (click)="navigateToReisekostenabrechnung({ id: 'new' })">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Reisekostenabrechnung hinzufügen
      </button>
      <nz-select *nzSpaceItem [(ngModel)]="jahr" (ngModelChange)="loadReisekostenabrechnungen($event)">
        <nz-option *ngFor="let jahr of jahre" [nzValue]="jahr" [nzLabel]="jahr"></nz-option>
      </nz-select>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <nz-row style="width: 100%">
      <nz-col nzSpan="24">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="reisekostenabrechnungen.search$.next($event)" [search]="reisekostenabrechnungen.search$ | async"></pbc-search-bar>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-reisekostenabrechnungen-filter [style.width]="'100%'"></fa-kt-reisekostenabrechnungen-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-reisekostenabrechnungen-sorter [style.width]="'100%'"></fa-kt-reisekostenabrechnungen-sorter>
      </nz-col>
    </nz-row>
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-row style="width: 100%">
      <nz-col nzSpan="24">
        <fa-kt-reisekostenabrechnungen-list [style.width]="'100%'" (select)="setReisekostenabrechnungen($event)" [loading]="loading$ | async"></fa-kt-reisekostenabrechnungen-list>
      </nz-col>
    </nz-row>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
