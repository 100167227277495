import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { IProjektStatus } from 'fa-kt.types';
import { PostProjektStatusCommandService } from '../../commands';
import { ProjektStatiService } from '../service';

@Component({
  selector: 'fa-kt-projekt-stati-list',
  templateUrl: './projekt-stati-list.component.html',
  styleUrls: ['./projekt-stati-list.component.css'],
})
export class ProjektStatiListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IProjektStatus> = new EventEmitter<IProjektStatus>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public projektStati: ProjektStatiService,
    public projektStatusPost: PostProjektStatusCommandService,
  ) {
    this.sortable$ = this.projektStati.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const projektStati = this.projektStati.result$.getValue();
    if (projektStati && projektStati.projektStati) {
      if ($event) {
        moveItemInArray(projektStati.projektStati, $event.previousIndex, $event.currentIndex);
      }
      projektStati.projektStati = projektStati.projektStati.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.projektStatusPost.request({ projektStatus: projektStati.projektStati });
    }
  }
}
