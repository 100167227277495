import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ZeitService } from '../service';

@Component({
  selector: 'fa-kt-zeit-detail',
  templateUrl: './zeit-detail.component.html',
  styleUrls: ['./zeit-detail.component.css'],
})
export class ZeitDetailComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  constructor(public zeit: ZeitService) {}
}
