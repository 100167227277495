import { NgModule } from '@angular/core';
import {
  ProjektFilterComponentModule,
  ProjektDetailComponentModule,
  ProjektSorterComponentModule,
  SavedProjektFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    ProjektFilterComponentModule,
    ProjektDetailComponentModule,
    ProjektSorterComponentModule,
    SavedProjektFiltersComponentModule
  ],
  exports: [
    ProjektFilterComponentModule,
    ProjektDetailComponentModule,
    ProjektSorterComponentModule,
    SavedProjektFiltersComponentModule
  ],
})
export class ProjektQueryModule { }
