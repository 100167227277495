import { IFilter, IFilterConfig } from "pbc.types";

import { IAbschnitt } from '.';

export interface IAbschnittFilter extends IFilter<IAbschnitt> {}

export const AbschnittFilterConfig: IFilterConfig<IAbschnitt> = [];

export type IAbschnittField =
  'abschnitt.id'|
  'abschnitt.vorlage'|
  'abschnitt.platzhalter'|
  'abschnitt.name'|
  'abschnitt.text'
;
