import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';
import { EinstellungenCommandsModule } from './commands';
import { EinstellungenComponentsModule } from './components';
import { EinstellungenPage, EinstellungenPageResolver } from './pages';
import { EinstellungenQuerysModule } from './querys';
import { EinstellungenSettingsModule, KonstantesPage, KonstantesPageResolver, KonstantesService, KonstantesServiceModule } from './settings';

const routes: Routes = [
  {
    path: 'einstellungen',
    children: [
      {
        path: 'einstellungen',
        component: EinstellungenPage,
        resolve: {
          querys: EinstellungenPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['einstellungen'],
            roles: ['POST_PROJEKTE_HOCHZAEHLEN', 'POST_RECHNUNGEN_HOCHZAEHLEN'],
          },
        },
      },
    ],
  },
  {
    path: 'einstellungen',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'konstantes',
            component: KonstantesPage,
            resolve: {
              querys: KonstantesPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_KONSTANTES'],
                  write: ['POST_KONSTANTE', 'DELETE_KONSTANTE'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [EinstellungenPage, KonstantesPage],
  imports: [
    LibsCommonAngularModule,
    EinstellungenSettingsModule,
    EinstellungenComponentsModule,
    EinstellungenCommandsModule,
    EinstellungenQuerysModule,
    KonstantesServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [EinstellungenComponentsModule, EinstellungenSettingsModule, EinstellungenCommandsModule, EinstellungenQuerysModule, KonstantesServiceModule],
})
export class EinstellungenModule {
  static forRoot(): ModuleWithProviders<EinstellungenModule> {
    return {
      ngModule: EinstellungenModule,
      providers: [KonstantesService],
    };
  }
}
