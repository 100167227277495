import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IOrtsResponse } from 'fa-kt.types';
import { PostOrtCommandService } from '../commands';
import { OrtsService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class OrtsPageResolver implements Resolve<IOrtsResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private orts: OrtsService,
    private postOrt: PostOrtCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IOrtsResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [orts] = await Promise.all([this.orts.request({}).catch(log), this.postOrt.prepare(true).catch(log)]);
    this.loading.resolved();
    return orts;
  }
}
