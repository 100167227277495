import { Injectable } from '@angular/core';

import { IDeleteKonstanteRequest, IDeleteKonstanteResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteKonstanteCommandService extends DeleteCommandService<IDeleteKonstanteRequest, IDeleteKonstanteResponse> {
  readonly route = 'einstellungen/settings/konstante';
}
