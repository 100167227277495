import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteTemplateCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteTemplateCommandService],
  exports: []
})
export class DeleteTemplateCommandServiceModule { }
