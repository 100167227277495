import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IBerichtsVorlagenResponse } from '.';

export interface IBerichtsVorlagenQueryFilter extends IFilter<IBerichtsVorlagenResponse> {}

export const BerichtsVorlagenQueryFilterConfig: IFilterConfig<IBerichtsVorlagenResponse> = [];

export interface IBerichtsVorlagenQueryShapes extends IShape {}

export type IBerichtsVorlagenQueryField = 'berichtsVorlage.id' | 'berichtsVorlage.name' | 'berichtsVorlage.kommentar' | 'berichtsVorlage.datei' | '';
