import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostTourCommandServiceModule } from '..';
import { PostTourCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostTourCommandServiceModule
  ],
  declarations: [PostTourCommandComponent],
  exports: [PostTourCommandComponent],

})
export class PostTourCommandComponentModule { }
