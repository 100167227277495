import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { KommunikationComponent } from '.';
import { ErinnerungenComponentModule } from '../erinnerungen';
import { VermerkeComponentModule } from '../vermerke';

@NgModule({
  imports: [LIB_CommonModule, ErinnerungenComponentModule, VermerkeComponentModule],
  declarations: [KommunikationComponent],
  exports: [KommunikationComponent],
})
export class KommunikationComponentModule {}
