import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IMitarbeiterRequest, IMitarbeiterResponse } from 'fa-kt.types';
import { MitarbeiterService, PostMitarbeiterCommandService } from '../../..';

export interface IMitarbeiterPageResolverResult {
  mitarbeiter?: IMitarbeiterResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class MitarbeiterPageResolver implements Resolve<IMitarbeiterPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private mitarbeiter: MitarbeiterService,
    private postMitarbeiter: PostMitarbeiterCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IMitarbeiterPageResolverResult> {
    this.loading.resolving();
    const requests = {
      mitarbeiter: {} as IMitarbeiterRequest,
    };

    const log = (error: any) => console.error(error);
    const [mitarbeiter] = await Promise.all([this.mitarbeiter.request(requests.mitarbeiter).catch(log), this.postMitarbeiter.prepare().catch(log)]);
    this.loading.resolved();
    return {
      mitarbeiter,
    };
  }
}
