import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitArtsFilterComponent } from '.';
import { ZeitArtsServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitArtsServiceModule
  ],
  declarations: [ZeitArtsFilterComponent],
  exports: [ZeitArtsFilterComponent],

})
export class ZeitArtsFilterComponentModule { }
