import { NgModule } from '@angular/core';
import { 
  PostAbmeldenCommandModule,
  PostErinnerungCommandModule,
  PostVermerkCommandModule,
  DeleteVermerkCommandModule
} from '.';

@NgModule({
  declarations: [],
  imports: [
    PostAbmeldenCommandModule,
    PostErinnerungCommandModule,
    PostVermerkCommandModule,
    DeleteVermerkCommandModule
  ],
  exports: [
    PostAbmeldenCommandModule,
    PostErinnerungCommandModule,
    PostVermerkCommandModule,
    DeleteVermerkCommandModule
  ]
})
export class KommunikationCommandsModule { }
