<nz-row nzJustify="end">
  <nz-col *ngIf="!($sortable | async)" nzFlex>
    <button nz-button nzType="primary" (click)="reorder()">
      <i nz-icon nzType="save" nzTheme="outline"></i>
      Reihenfolge übernehmen
    </button>
  </nz-col>
</nz-row>
<nz-row>
  <nz-col nzSpan="24">
    <nz-list *ngIf="feldUnterkategories.result$ | async" nzSize="large" [nzLoading]="$loading | async" cdkDropList (cdkDropListDropped)="reorder($event)">
      <nz-list-item *ngFor="let feldUnterkategorie of (feldUnterkategories.result$ | async).feldUnterkategories" (click)="select.emit(feldUnterkategorie)" cdkDrag class="drag-container">
        <nz-list-item-meta [nzDescription]="ListDescription">
          <ng-template #ListDescription>
            <!--- << description    -->
            <!---    description >> -->
          </ng-template>
          <nz-list-item-meta-title>
            <a>
              <!--- << content    -->
              <pbc-color-icon [color]="feldUnterkategorie.color" [icon]="feldUnterkategorie.icon"></pbc-color-icon>
              <strong>{{ feldUnterkategorie.initialen }}</strong>
              <nz-divider nzType="vertical"></nz-divider>
              {{ feldUnterkategorie.name }}
              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography nzType="secondary">
                {{ feldUnterkategorie.feldKategorie | type: (feldUnterkategories.feldUnterkategoriesFeldKategorie | async) : 'label' : false }}
              </span>
              <!---    content >> -->
            </a>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
            <a style="margin-left: 16px" *ngIf="$sortable | async" cdkDragHandle><i nz-icon nzType="menu" nzTheme="outline"></i></a>
          </nz-list-item-action>
        </ul>
      </nz-list-item>
    </nz-list>
  </nz-col>
</nz-row>
