import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IBewertungsAnlasssResponse } from '.';

export interface IBewertungsAnlasssQueryFilter extends IFilter<IBewertungsAnlasssResponse> {}

export const BewertungsAnlasssQueryFilterConfig: IFilterConfig<IBewertungsAnlasssResponse> = [];

export interface IBewertungsAnlasssQueryShapes extends IShape {}

export type IBewertungsAnlasssQueryField = 'bewertungsAnlasss.id' | 'bewertungsAnlasss.name' | 'bewertungsAnlasss.beschreibung' | '';
