import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjekteServiceModule } from '..';
import { SavedProjekteFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjekteServiceModule
  ],
  declarations: [SavedProjekteFiltersComponent],
  exports: [SavedProjekteFiltersComponent],

})
export class SavedProjekteFiltersComponentModule { }
