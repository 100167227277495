import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IOrtsQueryFilter, IOrtsQueryShapes, IOrtsQuerySorter, IOrtsRequest, IOrtsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class OrtsService extends SettingsQueryService<IOrtsRequest, IOrtsResponse, IOrtsQueryShapes, IOrtsQueryFilter, IOrtsQuerySorter> {
  contentKey: keyof IOrtsResponse = 'orts';
  route = 'geo/settings/orts';
  override dependencies = ['geo/written-ort', 'geo/removed-ort'];
}
