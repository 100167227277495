import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IPostHochladenRequest, IPostHochladenResponse } from 'fa-kt.types';
import { IMarktBerichtePageResolverResult } from '.';
import { PostHochladenCommandService } from '../../commands';
import { BerichteService } from '../../querys';

@Component({
  selector: 'fa-kt-markt-berichte-page',
  templateUrl: './markt-berichte.page.html',
  styleUrls: ['./markt-berichte.page.css'],
})
export class MarktBerichtePage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postHochladen: BehaviorSubject<IPostHochladenRequest | null> = new BehaviorSubject<IPostHochladenRequest | null>(null);

  public _marktBericht: ISitemapPage;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public berichte: BerichteService,
    public postHochladen: PostHochladenCommandService,
  ) {
    this.page = this.sitemap.MARKT.Pages.MARKT_BERICHTE;
    this._marktBericht = sitemap.MARKT.Pages.MARKT_BERICHT;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IMarktBerichtePageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setBerichte(id: string) {
    /* << set-berichte    */
    await this.navigateToMarktBericht({ id });
    /*    set-berichte >> */
  }

  async finishedPostHochladen(response?: IPostHochladenResponse) {
    /* << after post-hochladen    */
    /*    after post-hochladen >> */
  }

  public async navigateToMarktBericht(queryParams: Params = {}) {
    await this.router.navigate(this._marktBericht.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  /* << methods    */

  downloadTemplate() {
    this.files.download('fa-kt-apps/Marktdatenbank Vorlage.xlsx');
  }
  /*    methods >> */

  public async submitPostHochladen(request?: IPostHochladenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postHochladen.getValue();
    if (payload) {
      try {
        const response = await this.postHochladen.request(payload);
        await this.finishedPostHochladen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
