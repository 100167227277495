import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostFeldUnterkategorieCommandShapes, IPostFeldUnterkategorieRequest, IPostFeldUnterkategorieResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostFeldUnterkategorieCommandService extends PostCommandService<IPostFeldUnterkategorieRequest, IPostFeldUnterkategorieResponse, IPostFeldUnterkategorieCommandShapes> {
  readonly route = 'felder/settings/feld-unterkategorie';
  override dependencies = ['felder/written-feld-kategorie', 'felder/removed-feld-kategorie'];

  async getFeldUnterkategoriesFeldKategorie(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.feldUnterkategoriesFeldKategorie.pipe(first()).subscribe((feldUnterkategoriesFeldKategorie) => resolve(feldUnterkategoriesFeldKategorie.find((a) => a.value === value)));
    });
  }

  get feldUnterkategoriesFeldKategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feldUnterkategories.feldKategorie'] as ISelection[]) : [])));
  }
}
