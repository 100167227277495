import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IDurchsuchenQuerySorter extends ISorter {
  'herausgeber.id'?: ISorterExpression;
  'herausgeber.name'?: ISorterExpression;
  'herausgeber.website'?: ISorterExpression;
  'herausgeber.outlookKontaktes'?: ISorterExpression;
  'herausgeber.kommentar'?: ISorterExpression;
  'zeile.id'?: ISorterExpression;
  'zeile.bericht'?: ISorterExpression;
  'zeile.land'?: ISorterExpression;
  'zeile.bundesland'?: ISorterExpression;
  'zeile.plz'?: ISorterExpression;
  'zeile.region'?: ISorterExpression;
  'zeile.kreisStadt'?: ISorterExpression;
  'zeile.gemeindeBezirk'?: ISorterExpression;
  'zeile.ortsStadtTeil'?: ISorterExpression;
  'zeile.gemeindeschluessel'?: ISorterExpression;
  'zeile.teilmarkt'?: ISorterExpression;
  'zeile.bereiche'?: ISorterExpression;
  'zeile.assetklasse'?: ISorterExpression;
  'zeile.objektart'?: ISorterExpression;
  'zeile.zustand'?: ISorterExpression;
  'zeile.vertrag'?: ISorterExpression;
  'zeile.wohnlage'?: ISorterExpression;
  'zeile.wflVon'?: ISorterExpression;
  'zeile.wflBis'?: ISorterExpression;
  'zeile.wflMittel'?: ISorterExpression;
  'zeile.bjVon'?: ISorterExpression;
  'zeile.bjBis'?: ISorterExpression;
  'zeile.wohnNutzWert'?: ISorterExpression;
  'zeile.ausstattung'?: ISorterExpression;
  'zeile.artDesWertes'?: ISorterExpression;
  'zeile.einheit'?: ISorterExpression;
  'zeile.bezug'?: ISorterExpression;
  'zeile.durchschnitt'?: ISorterExpression;
  'zeile.minimum'?: ISorterExpression;
  'zeile.maximum'?: ISorterExpression;
  'zeile.spitzenwerte'?: ISorterExpression;
  'zeile.anzahl'?: ISorterExpression;
  'zeile.steigerung_1'?: ISorterExpression;
  'zeile.steigerung_2'?: ISorterExpression;
  'zeile.steigerung_3'?: ISorterExpression;
  'zeile.steigerung_4'?: ISorterExpression;
  'zeile.steigerung_5'?: ISorterExpression;
  'zeile.steigerungArt'?: ISorterExpression;
}

export const DurchsuchenQuerySorterConfig: ISorterConfig = [
  SorterConfig('herausgeber.id', 'MARKT.Models.HERAUSGEBER.Propertys.ID.title'), 
  SorterConfig('herausgeber.name', 'MARKT.Models.HERAUSGEBER.Propertys.NAME.title'), 
  SorterConfig('herausgeber.website', 'MARKT.Models.HERAUSGEBER.Propertys.WEBSITE.title'), 
  SorterConfig('herausgeber.outlookKontaktes', 'MARKT.Models.HERAUSGEBER.Propertys.OUTLOOK_KONTAKTES.title'), 
  SorterConfig('herausgeber.kommentar', 'MARKT.Models.HERAUSGEBER.Propertys.KOMMENTAR.title'), 
  SorterConfig('zeile.id', 'MARKT.Models.ZEILE.Propertys.ID.title'), 
  SorterConfig('zeile.bericht', 'MARKT.Models.ZEILE.Propertys.BERICHT.title'), 
  SorterConfig('zeile.land', 'MARKT.Models.ZEILE.Propertys.LAND.title'), 
  SorterConfig('zeile.bundesland', 'MARKT.Models.ZEILE.Propertys.BUNDESLAND.title'), 
  SorterConfig('zeile.plz', 'MARKT.Models.ZEILE.Propertys.PLZ.title'), 
  SorterConfig('zeile.region', 'MARKT.Models.ZEILE.Propertys.REGION.title'), 
  SorterConfig('zeile.kreisStadt', 'MARKT.Models.ZEILE.Propertys.KREIS_STADT.title'), 
  SorterConfig('zeile.gemeindeBezirk', 'MARKT.Models.ZEILE.Propertys.GEMEINDE_BEZIRK.title'), 
  SorterConfig('zeile.ortsStadtTeil', 'MARKT.Models.ZEILE.Propertys.ORTS_STADT_TEIL.title'), 
  SorterConfig('zeile.gemeindeschluessel', 'MARKT.Models.ZEILE.Propertys.GEMEINDESCHLUESSEL.title'), 
  SorterConfig('zeile.teilmarkt', 'MARKT.Models.ZEILE.Propertys.TEILMARKT.title'), 
  SorterConfig('zeile.bereiche', 'MARKT.Models.ZEILE.Propertys.BEREICHE.title'), 
  SorterConfig('zeile.assetklasse', 'MARKT.Models.ZEILE.Propertys.ASSETKLASSE.title'), 
  SorterConfig('zeile.objektart', 'MARKT.Models.ZEILE.Propertys.OBJEKTART.title'), 
  SorterConfig('zeile.zustand', 'MARKT.Models.ZEILE.Propertys.ZUSTAND.title'), 
  SorterConfig('zeile.vertrag', 'MARKT.Models.ZEILE.Propertys.VERTRAG.title'), 
  SorterConfig('zeile.wohnlage', 'MARKT.Models.ZEILE.Propertys.WOHNLAGE.title'), 
  SorterConfig('zeile.wflVon', 'MARKT.Models.ZEILE.Propertys.WFL_VON.title'), 
  SorterConfig('zeile.wflBis', 'MARKT.Models.ZEILE.Propertys.WFL_BIS.title'), 
  SorterConfig('zeile.wflMittel', 'MARKT.Models.ZEILE.Propertys.WFL_MITTEL.title'), 
  SorterConfig('zeile.bjVon', 'MARKT.Models.ZEILE.Propertys.BJ_VON.title'), 
  SorterConfig('zeile.bjBis', 'MARKT.Models.ZEILE.Propertys.BJ_BIS.title'), 
  SorterConfig('zeile.wohnNutzWert', 'MARKT.Models.ZEILE.Propertys.WOHN_NUTZ_WERT.title'), 
  SorterConfig('zeile.ausstattung', 'MARKT.Models.ZEILE.Propertys.AUSSTATTUNG.title'), 
  SorterConfig('zeile.artDesWertes', 'MARKT.Models.ZEILE.Propertys.ART_DES_WERTES.title'), 
  SorterConfig('zeile.einheit', 'MARKT.Models.ZEILE.Propertys.EINHEIT.title'), 
  SorterConfig('zeile.bezug', 'MARKT.Models.ZEILE.Propertys.BEZUG.title'), 
  SorterConfig('zeile.durchschnitt', 'MARKT.Models.ZEILE.Propertys.DURCHSCHNITT.title'), 
  SorterConfig('zeile.minimum', 'MARKT.Models.ZEILE.Propertys.MINIMUM.title'), 
  SorterConfig('zeile.maximum', 'MARKT.Models.ZEILE.Propertys.MAXIMUM.title'), 
  SorterConfig('zeile.spitzenwerte', 'MARKT.Models.ZEILE.Propertys.SPITZENWERTE.title'), 
  SorterConfig('zeile.anzahl', 'MARKT.Models.ZEILE.Propertys.ANZAHL.title'), 
  SorterConfig('zeile.steigerung_1', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_1.title'), 
  SorterConfig('zeile.steigerung_2', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_2.title'), 
  SorterConfig('zeile.steigerung_3', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_3.title'), 
  SorterConfig('zeile.steigerung_4', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_4.title'), 
  SorterConfig('zeile.steigerung_5', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_5.title'), 
  SorterConfig('zeile.steigerungArt', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_ART.title'), 
];
