import { Injectable } from '@angular/core';

import { IDeleteExtraVereinbarungRequest, IDeleteExtraVereinbarungResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteExtraVereinbarungCommandService extends DeleteCommandService<IDeleteExtraVereinbarungRequest, IDeleteExtraVereinbarungResponse> {
  readonly route = 'finanzen/extra-vereinbarung';
}
