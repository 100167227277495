import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFeldUnterkategorieCommandServiceModule } from '..';
import { DeleteFeldUnterkategorieCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFeldUnterkategorieCommandServiceModule
  ],
  declarations: [DeleteFeldUnterkategorieCommandComponent],
  exports: [DeleteFeldUnterkategorieCommandComponent],

})
export class DeleteFeldUnterkategorieCommandComponentModule { }
