import { NgModule } from '@angular/core';

import {
  DeleteKundenArtCommandServiceModule,
  DeleteKundenArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteKundenArtCommandServiceModule,
    DeleteKundenArtCommandComponentModule
  ],
  exports: [
    DeleteKundenArtCommandServiceModule,
    DeleteKundenArtCommandComponentModule
  ]
})
export class DeleteKundenArtCommandModule { }
