import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { DurchgangQueryFilterConfig, IDurchgangResponse } from 'fa-kt.types';

import { DurchgangService } from '../service';

@Component({
  selector: 'fa-kt-durchgang-filter',
  templateUrl: './durchgang-filter.component.html',
  styleUrls: ['./durchgang-filter.component.css'],
})
export class DurchgangFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public DurchgangFilterConfig: IFilterConfig<IDurchgangResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public durchgang: DurchgangService,
  ) {
    this.DurchgangFilterConfig = DurchgangQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.DurchgangFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.DurchgangFilterConfig[index].selections = shapes;
  }
}
