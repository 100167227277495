import { NgModule } from '@angular/core';

import {
  PostBewertungsAnlassCommandServiceModule,
  PostBewertungsAnlassCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostBewertungsAnlassCommandServiceModule,
    PostBewertungsAnlassCommandComponentModule
  ],
  exports: [
    PostBewertungsAnlassCommandServiceModule,
    PostBewertungsAnlassCommandComponentModule
  ]
})
export class PostBewertungsAnlassCommandModule { }
