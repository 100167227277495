import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { addDays, formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { IErinnerung, IKunde, IPostAbmeldenRequest, IPostAbmeldenResponse, IPostErinnerungRequest, IPostErinnerungResponse, IProjekt } from 'fa-kt.types';
import { toInitials } from 'pbc.functions';
import { PostAbmeldenCommandService, PostErinnerungCommandService } from '../../commands';

interface Erinnerung extends IErinnerung {
  datetime: string;
  editable: boolean;
  affected: boolean;
}

@Component({
  selector: 'fa-kt-erinnerungen',
  templateUrl: './erinnerungen.component.html',
  styleUrls: ['./erinnerungen.component.css'],
})
export class ErinnerungenComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postErinnerung: BehaviorSubject<IPostErinnerungRequest | null> = new BehaviorSubject<IPostErinnerungRequest | null>(null);
  public readonly $postAbmelden: BehaviorSubject<IPostAbmeldenRequest | null> = new BehaviorSubject<IPostAbmeldenRequest | null>(null);

  @Input() title = 'Erinnerungen';
  @Input() kunde: IKunde | undefined = undefined;
  @Input() projekt: IProjekt | undefined = undefined;
  @Input() disabled = false;
  @Input() set erinnerungen(erinnerungen: IErinnerung[]) {
    const me = this.auth.$id.getValue();
    this._erinnerungen = erinnerungen.map((erinnerung) => ({
      ...erinnerung,
      editable: !erinnerung.verfasser || erinnerung.verfasser === me,
      affected: erinnerung.mitarbeiter?.includes(me as string) as boolean,
      datetime: formatDistanceToNow(new Date(erinnerung._createdAt as Date), { includeSeconds: false, locale: de }),
    }));
  }
  _erinnerungen: Erinnerung[] = [];

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postErinnerung: PostErinnerungCommandService,
    public postAbmelden: PostAbmeldenCommandService,
  ) {}

  async ngOnInit() {
    /* << init    */
    this.openErinnerung({});
    /*    init >> */
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        this.auth.$id.subscribe((id) => {
          if (id) {
            this.openErinnerung({});
          }
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostErinnerung(response?: IPostErinnerungResponse) {
    /* << after post-erinnerung    */
    this.openErinnerung({});
    /*    after post-erinnerung >> */
  }

  async finishedPostAbmelden(response?: IPostAbmeldenResponse) {
    /* << after post-abmelden    */
    this.openErinnerung({});
    /*    after post-abmelden >> */
  }

  /* << methods    */

  public openErinnerung(erinnerung: IErinnerung | null | any) {
    if (!erinnerung || (erinnerung.id && !erinnerung.affected)) {
      this.$postErinnerung.next(null);
      return;
    }
    if (!erinnerung.id) {
      const me = this.auth.$id.getValue();
      if (this.kunde) {
        erinnerung.kunde = this.kunde.id;
        erinnerung.kundeName = this.kunde.name;
      }
      if (this.projekt) {
        erinnerung.projekt = this.projekt.id;
        erinnerung.projektName = this.projekt.nummer + ' | ' + this.projekt.bezeichnung;
      }
      erinnerung.am = addDays(new Date(), 7);
      erinnerung.verfasser = me;
      erinnerung.mitarbeiter = [me];
    }
    this.$postErinnerung.next({ erinnerung });
  }
  /*    methods >> */

  public async submitPostErinnerung(request?: IPostErinnerungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postErinnerung.getValue();
    if (payload) {
      try {
        const response = await this.postErinnerung.request(payload);
        await this.finishedPostErinnerung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostAbmelden(request?: IPostAbmeldenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postAbmelden.getValue();
    if (payload) {
      try {
        const response = await this.postAbmelden.request(payload);
        await this.finishedPostAbmelden(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  getMitarbeiterText(label: string) {
    if (!label) return '';
    return toInitials(label.split('(')[0]);
  }
}
