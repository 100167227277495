import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitUnterartsSorterComponent } from '.';
import { ZeitUnterartsServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitUnterartsServiceModule
  ],
  declarations: [ZeitUnterartsSorterComponent],
  exports: [ZeitUnterartsSorterComponent],

})
export class ZeitUnterartsSorterComponentModule { }
