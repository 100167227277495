import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProfilServiceModule } from '..';
import { ProfilSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProfilServiceModule
  ],
  declarations: [ProfilSorterComponent],
  exports: [ProfilSorterComponent],

})
export class ProfilSorterComponentModule { }
