import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteTemplateRequest, IDeleteTemplateResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteTemplateCommandService extends DeleteCommandService<IDeleteTemplateRequest, IDeleteTemplateResponse> {
  readonly route = 'markt/settings/template';
}
