import {
  BooleanFilterConfig,
  DateFilterConfig,
  ICommandRequest,
  ICommandResponse,
  IFilter,
  IFilterConfig,
  IModel,
  IPersistedFilterList,
  IQueryRequest,
  IQueryResponse,
  ISelection,
  IShape,
  ISorter,
  ISorterConfig,
  SingleReferenceFilterConfig,
  SorterConfig,
} from 'pbc.types';
import { IMitarbeiter } from '../../personen';

export type UrlaubsArt = 'Urlaub' | 'Unbezahlter Urlaub' | 'Fortbildung' | 'Elternzeit' | 'Studienzeit';
export interface IUrlaub extends IModel {
  id?: string;
  mitarbeiter: string;
  von: Date;
  bis: Date;
  art: UrlaubsArt;
  kommentar?: string;
  abgelehnt?: boolean;
  angenommen?: boolean;
  outlook?: { personal?: string; admin?: string };
}

export interface IUrlaubFilter extends IFilter<IUrlaub> {}
export type IUrlaubField = 'urlaub.id' | 'urlaub.mitarbeiter' | 'urlaub.von' | 'urlaub.bis' | 'urlaub.abgelehnt' | 'urlaub.angenommen';
export const UrlaubFilterConfig: IFilterConfig<IUrlaub> = [
  SingleReferenceFilterConfig<IMitarbeiter>('urlaub.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  DateFilterConfig('urlaub.von', 'Von'),
  DateFilterConfig('urlaub.bis', 'Bis'),
  BooleanFilterConfig('urlaub.abgelehnt', 'Abgelehnt'),
  BooleanFilterConfig('urlaub.angenommen', 'Angenommen'),
];
export const UrlaubeSorterConfig: ISorterConfig = [
  SorterConfig('urlaub.id', 'Urlaub'),
  SorterConfig('urlaub.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  SorterConfig('urlaub.von', 'Von'),
  SorterConfig('urlaub.bis', 'Bis'),
  SorterConfig('urlaub.abgelehnt', 'Abgelehnt'),
  SorterConfig('urlaub.angenommen', 'Angenommen'),
];
export interface IUrlaubeShapes extends IShape {
  'urlaub.mitarbeiter': ISelection[];
}
export interface IUrlaubeRequest extends IQueryRequest {
  jahr: number;
  person?: string;
}

export interface IUrlaubeResponse extends IQueryResponse {
  urlaube: IUrlaubeResponseRow[];
}

export interface IUrlaubeResponseRow extends IQueryResponse {
  urlaub: IUrlaub;
}

export interface IUrlaubeResponseGroup {
  response: IUrlaubeResponse;
  shapes: IUrlaubeQueryShapes;
  filter: IPersistedFilterList<IUrlaubeQueryFilter>;
}

export type IUrlaubeQueryField = IUrlaubField;
export interface IUrlaubeQuerySorter extends ISorter {}
export interface IUrlaubeQueryFilter extends IFilter<IUrlaubeResponse> {}
export const UrlaubeQuerySorterConfig: ISorterConfig = UrlaubeSorterConfig;
export const UrlaubeQueryFilterConfig: IFilterConfig<IUrlaubeResponse> = UrlaubFilterConfig;
export type IUrlaubeQueryShapes = IUrlaubeShapes;

export interface IUrlaubRequest extends IQueryRequest {
  urlaub: string;
}

export interface IUrlaubResponse extends IQueryResponse {
  urlaub: IUrlaub;
}

export interface IUrlaubResponseGroup {
  response: IUrlaubResponse;
  shapes: IUrlaubQueryShapes;
}

export type IUrlaubQueryField = IUrlaubField;
export interface IUrlaubQuerySorter extends ISorter {}
export interface IUrlaubQueryFilter extends IFilter<IUrlaubeResponse> {}
export type IUrlaubQueryShapes = IUrlaubeShapes;
export const UrlaubQuerySorterConfig: ISorterConfig = UrlaubeSorterConfig;
export const UrlaubQueryFilterConfig: IFilterConfig<IUrlaubeResponse> = UrlaubFilterConfig;

export interface IPostUrlaubRequest extends ICommandRequest {
  urlaub: IUrlaub;
}
export interface IPostUrlaubResponse extends ICommandResponse {
  urlaub: IUrlaub;
}
export interface IPostUrlaubCommandShapes extends IShape {
  'urlaub.mitarbeiter': ISelection[];
}

export interface IDeleteUrlaubRequest extends ICommandRequest {
  id: string;
}
export interface IDeleteUrlaubResponse extends ICommandResponse {}
export interface IDeleteUrlaubCommandShapes extends IShape {}
