import { Injectable } from '@angular/core';

import { IDeleteVorlageRequest, IDeleteVorlageResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteVorlageCommandService extends DeleteCommandService<IDeleteVorlageRequest, IDeleteVorlageResponse> {
  readonly route = 'texte/vorlage';
}
