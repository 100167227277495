import { NgModule } from '@angular/core';

import {
  PostStandortCommandServiceModule,
  PostStandortCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostStandortCommandServiceModule,
    PostStandortCommandComponentModule
  ],
  exports: [
    PostStandortCommandServiceModule,
    PostStandortCommandComponentModule
  ]
})
export class PostStandortCommandModule { }
