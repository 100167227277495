import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IDeleteBerichtRequest, IDeleteBerichtResponse, IPostBerichtRequest, IPostBerichtResponse } from 'fa-kt.types';
import { IMarktBerichtPageResolverResult } from '.';
import { DeleteBerichtCommandService, PostBerichtCommandService } from '../../commands';
import { BerichtService } from '../../querys';

@Component({
  selector: 'fa-kt-markt-bericht-page',
  templateUrl: './markt-bericht.page.html',
  styleUrls: ['./markt-bericht.page.css'],
})
export class MarktBerichtPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $deleteBericht: BehaviorSubject<IDeleteBerichtRequest | null> = new BehaviorSubject<IDeleteBerichtRequest | null>(null);
  public readonly $postBericht: BehaviorSubject<IPostBerichtRequest | null> = new BehaviorSubject<IPostBerichtRequest | null>(null);

  public _marktBerichte: ISitemapPage;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public bericht: BerichtService,
    public deleteBericht: DeleteBerichtCommandService,
    public postBericht: PostBerichtCommandService,
  ) {
    this.page = this.sitemap.MARKT.Pages.MARKT_BERICHT;
    this._marktBerichte = sitemap.MARKT.Pages.MARKT_BERICHTE;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IMarktBerichtPageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        this.bericht.result$.subscribe((result) => {
          if (result?.bericht) {
            this.$postBericht.next({ bericht: result?.bericht });
            this.$deleteBericht.next({ bericht: result?.bericht?.id as string });
          }
          this.page.title = result?.bericht ? 'Bericht: ' + result.bericht.quelle : 'Bericht';
          this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setBericht(id: string) {
    /* << set-bericht    */
    /*    set-bericht >> */
  }

  async finishedDeleteBericht(response?: IDeleteBerichtResponse) {
    /* << after delete-bericht    */
    await this.navigateToMarktBerichte();
    /*    after delete-bericht >> */
  }

  async finishedPostBericht(response?: IPostBerichtResponse) {
    /* << after post-bericht    */
    /*    after post-bericht >> */
  }

  public async navigateToMarktBerichte(queryParams: Params = {}) {
    await this.router.navigate(this._marktBerichte.url, { queryParams });
  }

  back() {
    this.navigateToMarktBerichte();
  }

  /* << methods    */

  download() {
    const excel = this.bericht.result$.getValue()?.bericht.excel;
    this.files.download(excel as string);
  }
  /*    methods >> */

  public async submitDeleteBericht(request?: IDeleteBerichtRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteBericht.getValue();
    if (payload) {
      try {
        const response = await this.deleteBericht.request(payload);
        await this.finishedDeleteBericht(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostBericht(request?: IPostBerichtRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postBericht.getValue();
    if (payload) {
      try {
        const response = await this.postBericht.request(payload);
        await this.finishedPostBericht(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
