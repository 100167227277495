import { GeoRangeFilterConfig, IFilter, IFilterConfig, IGeoRangeFilter, IShape } from 'pbc.types';

import { IStandortsResponse } from '.';

export interface IStandortsQueryFilter extends IFilter<IStandortsResponse> {
  'standorts.addresse'?: IGeoRangeFilter;
}

export const StandortsQueryFilterConfig: IFilterConfig<IStandortsResponse> = [GeoRangeFilterConfig('standorts.addresse', 'PERSONEN.Models.STANDORT.Propertys.ADDRESSE.title')];

export interface IStandortsQueryShapes extends IShape {}

export type IStandortsQueryField = 'standorts.id' | 'standorts.name' | 'standorts.addresse' | 'standorts.dateipfad' | '';
