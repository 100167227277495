<!--


  <fa-kt-vermerke [style.width]="'100%'" ></fa-kt-vermerke>

  <fa-kt-erinnerungen [style.width]="'100%'" ></fa-kt-erinnerungen>


-->
<!--- << custom    -->

<nz-row>
  <nz-col nzSpan='24'>
    <fa-kt-vermerke [kunde]="kunde" [projekt]="projekt" [disabled]="disabled" [vermerke]="vermerke" [style.width]="'100%'" ></fa-kt-vermerke>
  </nz-col>
  <nz-col nzSpan='24'>
    <fa-kt-erinnerungen [kunde]="kunde" [projekt]="projekt" [disabled]="disabled" [erinnerungen]="erinnerungen" [style.width]="'100%'" ></fa-kt-erinnerungen>
  </nz-col>
</nz-row>



<!---    custom >> -->
