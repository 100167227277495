import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';
import { ZeitenCommandsModule } from './commands';
import { ZeitenComponentsModule } from './components';
import { HeuteService } from './heute.service';
import { ZeitenPage, ZeitenPageResolver } from './pages';
import { ZeitenQuerysModule, ZeitenService, ZeitService } from './querys';
import {
  ZeitArtsPage,
  ZeitArtsPageResolver,
  ZeitArtsService,
  ZeitArtsServiceModule,
  ZeitenSettingsModule,
  ZeitUnterartsPage,
  ZeitUnterartsPageResolver,
  ZeitUnterartsService,
  ZeitUnterartsServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'zeiten',
    children: [
      {
        path: 'zeiten',
        component: ZeitenPage,
        resolve: {
          querys: ZeitenPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['zeiten'],
            roles: ['GET_ZEITEN', 'DELETE_ZEIT', 'POST_ZEIT'],
          },
        },
      },
    ],
  },
  {
    path: 'zeiten',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'zeit-arts',
            component: ZeitArtsPage,
            resolve: {
              querys: ZeitArtsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_ZEIT_ARTS'],
                  write: ['POST_ZEIT_ART', 'DELETE_ZEIT_ART'],
                },
              },
            },
          },
          {
            path: 'zeit-unterarts',
            component: ZeitUnterartsPage,
            resolve: {
              querys: ZeitUnterartsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_ZEIT_UNTERARTS'],
                  write: ['POST_ZEIT_UNTERART', 'DELETE_ZEIT_UNTERART'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [ZeitenPage, ZeitArtsPage, ZeitUnterartsPage],
  imports: [
    LibsCommonAngularModule,
    ZeitenSettingsModule,
    ZeitenComponentsModule,
    ZeitenCommandsModule,
    ZeitenQuerysModule,
    ZeitArtsServiceModule,
    ZeitUnterartsServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [ZeitenSettingsModule, ZeitenComponentsModule, ZeitenCommandsModule, ZeitenQuerysModule, ZeitArtsServiceModule, ZeitUnterartsServiceModule],
})
export class ZeitenModule {
  static forRoot(): ModuleWithProviders<ZeitenModule> {
    return {
      ngModule: ZeitenModule,
      providers: [ZeitService, ZeitenService, ZeitArtsService, ZeitUnterartsService, HeuteService],
    };
  }
}
