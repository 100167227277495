import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostZeileCommandServiceModule } from '..';
import { PostZeileCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostZeileCommandServiceModule
  ],
  declarations: [PostZeileCommandComponent],
  exports: [PostZeileCommandComponent],

})
export class PostZeileCommandComponentModule { }
