<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="abschnitt">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="vorlage" id="vorlage" />
      <input class="cdk-visually-hidden" formControlName="order" id="order" />

      <nz-form-item style="width: 100%">
        <nz-form-label [nzSm]="2" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title }}</nz-form-label>
        <nz-form-control [nzSm]="11" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input nzSize="large" formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" />
        </nz-form-control>
        <nz-form-label [nzSm]="2" [nzXs]="24" nzRequired nzFor="platzhalter">{{ command.Inputs.PLATZHALTER?.title }}</nz-form-label>
        <nz-form-control [nzSm]="8" [nzXs]="24" [nzErrorTip]="PlatzhalterErrorTip">
          <ng-template #PlatzhalterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PLATZHALTER?.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-input-group [nzPrefix]="'{{'" [nzSuffix]="Suffix">
            <ng-template #Suffix>
              {{ "}}" }}
              <a nz-typography nzCopyable [nzCopyText]="'{{' + abschnittPlatzhalter?.value + '}}'"></a>
            </ng-template>
            <input nz-input formControlName="platzhalter" id="platzhalter" [placeholder]="command.Inputs.PLATZHALTER?.placeholder" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <br />
      <br />

      <nz-form-item style="width: 100%">
        <nz-form-label [nzSm]="2" [nzXs]="24" nzFor="text" nzRequired>{{ command.Inputs.TEXT?.title }}</nz-form-label>
        <nz-form-control [nzSm]="21" [nzXs]="24" [nzErrorTip]="TextErrorTip">
          <ng-template #TextErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TEXT?.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-text-editor
            [felder]="alleFelder"
            [selected]="felder"
            [object]="object"
            [virtual]="virtual"
            [nunjucks]="nunjucks"
            formControlName="text"
            id="text"
            [placeholder]="command.Inputs.TEXT.placeholder"
          ></pbc-text-editor>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          <ng-template #SubmitTooltip> Abschnitt speichern </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
