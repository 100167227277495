import {
  BooleanFilterConfig,
  DateFilterConfig,
  ICommandRequest,
  ICommandResponse,
  IFilter,
  IFilterConfig,
  IModel,
  IPersistedFilterList,
  IQueryRequest,
  IQueryResponse,
  ISelection,
  IShape,
  ISorter,
  ISorterConfig,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
  SorterConfig,
} from 'pbc.types';
import { IMitarbeiter } from '../../personen';

export interface ITag extends IModel {
  id?: string;
  mitarbeiter: string;
  start: Date;
  ort?: Array<'HomeOffice' | 'Büro' | 'Unterwegs'>;
  ende?: Date;
  pausen?: Array<{ start: Date; ende?: Date }>;
  minuten?: { arbeit: number; pause: number };
  zugeordnet?: boolean;
  keineGesetzlichePause?: boolean;
}

export interface ITagFilter extends IFilter<ITag> {}
export type ITagField = 'tag.id' | 'tag.mitarbeiter' | 'tag.start' | 'tag.pause' | 'tag.zugeordnet';
export const TagFilterConfig: IFilterConfig<ITag> = [
  SingleReferenceFilterConfig<IMitarbeiter>('tag.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  DateFilterConfig('tag.start', 'Datum'),
  NumberFilterConfig('tag.minuten.arbeit', 'Gearbeitete Minuten'),
  NumberFilterConfig('tag.minuten.pause', 'Pausierte Minuten'),
  BooleanFilterConfig('tag.zugeordnet', 'Zugeordnet'),
  BooleanFilterConfig('tag.keineGesetzlichePause', 'Keine gesetzliche Pause'),
];
export const TageSorterConfig: ISorterConfig = [
  SorterConfig('tag.id', 'Tag'),
  SorterConfig('tag.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  SorterConfig('tag.minuten.arbeit', 'Gearbeitete Minuten'),
  SorterConfig('tag.minuten.pause', 'Pausierte Minuten'),
  SorterConfig('tag.zugeordnet', 'Zugeordnet'),
  SorterConfig('tag.keineGesetzlichePause', 'Keine gesetzliche Pause'),
];
export interface ITageShapes extends IShape {
  'tag.mitarbeiter': ISelection[];
}
export interface ITageRequest extends IQueryRequest {
  jahr: number;
  person?: string;
}

export interface ITageResponse extends IQueryResponse {
  tage: ITageResponseRow[];
}

export interface ITageResponseRow extends IQueryResponse {
  tag: ITag;
}

export interface ITageResponseGroup {
  response: ITageResponse;
  shapes: ITageQueryShapes;
  filter: IPersistedFilterList<ITageQueryFilter>;
}

export type ITageQueryField = ITagField;
export interface ITageQuerySorter extends ISorter {}
export interface ITageQueryFilter extends IFilter<ITageResponse> {}
export const TageQuerySorterConfig: ISorterConfig = TageSorterConfig;
export const TageQueryFilterConfig: IFilterConfig<ITageResponse> = TagFilterConfig;
export type ITageQueryShapes = ITageShapes;

export interface ITagRequest extends IQueryRequest {
  tag: string;
}

export interface ITagResponse extends IQueryResponse {
  tag: ITag;
}

export interface ITagResponseGroup {
  response: ITagResponse;
  shapes: ITagQueryShapes;
}

export type ITagQueryField = ITagField;
export interface ITagQuerySorter extends ISorter {}
export interface ITagQueryFilter extends IFilter<ITageResponse> {}
export type ITagQueryShapes = ITageShapes;
export const TagQuerySorterConfig: ISorterConfig = TageSorterConfig;
export const TagQueryFilterConfig: IFilterConfig<ITageResponse> = TagFilterConfig;

export interface IPostTagRequest extends ICommandRequest {
  tag: ITag;
}
export interface IPostTagResponse extends ICommandResponse {
  tag: ITag;
}
export interface IPostTagCommandShapes extends IShape {
  'tag.mitarbeiter': ISelection[];
}

export interface IDeleteTagRequest extends ICommandRequest {
  id: string;
}
export interface IDeleteTagResponse extends ICommandResponse {}
export interface IDeleteTagCommandShapes extends IShape {}
