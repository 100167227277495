import { Component } from '@angular/core';
import { RechnungsVorlagenService } from '../service';

@Component({
  selector: 'fa-kt-saved-rechnungs-vorlagen-filters',
  templateUrl: './saved-rechnungs-vorlagen-filters.component.html',
  styleUrls: ['./saved-rechnungs-vorlagen-filters.component.css'],
})
export class SavedRechnungsVorlagenFiltersComponent {
  constructor(public rechnungsVorlagen: RechnungsVorlagenService) {}
}
