import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { isDate } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, fromEvent, merge, TimeoutError } from 'rxjs';
import { map } from 'rxjs/operators';

import { dateFormat, decompressJSON } from 'pbc.functions';

import { FileService, prepareCommand, pushFiles, SecretService } from '../../..';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  readonly $offline = new BehaviorSubject<boolean>(false);
  public readonly $Authorization: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private messages: NzMessageService,
    private files: FileService,
    private secrets: SecretService,
  ) {
    merge(fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => !navigator.onLine))
      .subscribe((offline) => {
        if (offline !== this.$offline.getValue()) {
          this.messages.create(offline ? 'warning' : 'success', offline ? 'Das Gerät ist offline' : 'Das Gerät ist online');
        }
        this.$offline.next(offline);
      });
  }

  async get<I>(route: string, options: any = {}, compressed = false): Promise<I> {
    console.debug('GET', route);
    let result: any = await this.http.get<I>(route, options).toPromise();
    if (!compressed) return result;
    else return decompressJSON<I & object>(result);
  }

  async post<I>(route: string, payload: any, options: any = {}): Promise<I> {
    payload = prepareCommand(payload);
    console.debug('POST', route, payload);
    const response = (await this.http.post<I>(route, payload, options).toPromise()) as unknown as I;
    return response;
  }

  async upload<I>(route: string, payload: any, containerName: string, options: any = {}): Promise<I> {
    containerName = 'fa-kt-apps/' + containerName + '/' + moment().format('DD-MM-yyyy-HH-mm');
    const files = pushFiles(payload);
    payload = prepareCommand(payload, '', containerName);
    route = route + this.serialize(payload);
    console.debug('UPLOAD', route, files, payload);
    return Promise.all([this.http.post<I>(route, payload, options).toPromise(), this.files.upload(containerName, files)]).then((r) => r[0] as unknown as I);
  }

  async patch<I>(route: string, payload: any, options: any = {}): Promise<I> {
    payload = prepareCommand(payload);
    console.debug('PATCH', route, payload);
    const response = (await this.http.patch<I>(route, payload, options).toPromise()) as unknown as I;
    return response;
  }

  async put<I>(route: string, payload: any, options: any = {}): Promise<I> {
    payload = prepareCommand(payload);
    console.debug('put', route, payload);
    const response = (await this.http.put<I>(route, payload, options).toPromise()) as unknown as I;
    return response;
  }

  async delete<I>(route: string, options: any = {}): Promise<I> {
    console.debug('DELETE', route);
    const response = (await this.http.delete<I>(route, options).toPromise()) as unknown as I;
    return response;
  }

  public isOfflineOrBadConnectionError(err: HttpErrorResponse): boolean {
    return err instanceof TimeoutError || err.error instanceof ErrorEvent || this.$offline.getValue() || !window.navigator.onLine;
  }

  public serialize(obj: any): string {
    if (!obj) return '';
    return (
      '?' +
      Object.entries(obj)
        .filter(([, value]) => value)
        .map(([key, value]) => `${key}=${encodeURIComponent(this.convert(value))}`)
        .join('&')
    );
  }
  convert(value: unknown): string | number | boolean {
    if (!value) return undefined;
    if ((value as { name: string }).name) return (value as { name: string }).name;
    if (isDate(value)) return moment(value).format(dateFormat);
    return value as string;
  }
}
