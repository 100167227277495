<!---
 -->
<form nz-form [formGroup]="form" (ngSubmit)="submit()">
  <input autocomplete="one-time-code" class="cdk-visually-hidden" formControlName="id" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
  <button
    nz-button
    nzDanger
    [nzLoading]="$loading | async"
    [disabled]="!form.valid || ($loading | async)"
    nz-popconfirm
    nzPopconfirmTitle="Vorlage wirklich löschen?"
    [nzIcon]="DeleteIcon"
    (nzOnConfirm)="submit()"
  >
    <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
    Löschen
    <ng-template #DeleteIcon>
      <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
    </ng-template>
  </button>
</form>
