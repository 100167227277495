import { NgModule } from '@angular/core';
import {
  SavedZeitFiltersComponentModule, ZeitDetailComponentModule, ZeitFilterComponentModule, ZeitSorterComponentModule
} from '.';

@NgModule({
  imports: [
    ZeitFilterComponentModule,
    ZeitDetailComponentModule,
    ZeitSorterComponentModule,
    SavedZeitFiltersComponentModule
  ],
  exports: [
    ZeitFilterComponentModule,
    ZeitDetailComponentModule,
    ZeitSorterComponentModule,
    SavedZeitFiltersComponentModule
  ],
})
export class ZeitQueryModule { }
