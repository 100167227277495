import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IBewertungsAnlasssQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const BewertungsAnlasssQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'GUTACHTEN.Models.BEWERTUNGS_ANLASS.Propertys.ID.title'),
   SorterConfig('name', 'GUTACHTEN.Models.BEWERTUNGS_ANLASS.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'GUTACHTEN.Models.BEWERTUNGS_ANLASS.Propertys.BESCHREIBUNG.title'),
];
