import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostReisekostenabrechnungCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostReisekostenabrechnungCommandService],
  exports: []
})
export class PostReisekostenabrechnungCommandServiceModule { }
