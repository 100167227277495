import { NgModule } from '@angular/core';
import {
  HerausgebersFilterComponentModule,
  HerausgebersSorterComponentModule,
  HerausgebersListComponentModule
} from '.';

@NgModule({
  imports: [
    HerausgebersFilterComponentModule,
    HerausgebersSorterComponentModule,
    HerausgebersListComponentModule
  ],
  exports: [
    HerausgebersFilterComponentModule,
    HerausgebersSorterComponentModule,
    HerausgebersListComponentModule
  ],
})
export class HerausgebersQueryModule { }