import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ITourStatus } from 'fa-kt.types';

import { PostTourStatusCommandService } from '../../commands';
import { TourStatiService } from '../service';

@Component({
  selector: 'fa-kt-tour-stati-list',
  templateUrl: './tour-stati-list.component.html',
  styleUrls: ['./tour-stati-list.component.css'],
})
export class TourStatiListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<ITourStatus> = new EventEmitter<ITourStatus>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public tourStati: TourStatiService,
    public tourStatusPost: PostTourStatusCommandService,
  ) {
    this.sortable$ = this.tourStati.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const tourStati = this.tourStati.result$.getValue();
    if (tourStati && tourStati.tourStati) {
      if ($event) {
        moveItemInArray(tourStati.tourStati, $event.previousIndex, $event.currentIndex);
      }
      tourStati.tourStati = tourStati.tourStati.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.tourStatusPost.request({ tourStatus: tourStati.tourStati });
    }
  }
}
