import { NgModule } from '@angular/core';

import {
  PostFeldUnterkategorieCommandServiceModule,
  PostFeldUnterkategorieCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostFeldUnterkategorieCommandServiceModule,
    PostFeldUnterkategorieCommandComponentModule
  ],
  exports: [
    PostFeldUnterkategorieCommandServiceModule,
    PostFeldUnterkategorieCommandComponentModule
  ]
})
export class PostFeldUnterkategorieCommandModule { }
