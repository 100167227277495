import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import {
  IReisekostenabrechnungStatiQueryFilter,
  IReisekostenabrechnungStatiQueryShapes,
  IReisekostenabrechnungStatiQuerySorter,
  IReisekostenabrechnungStatiRequest,
  IReisekostenabrechnungStatiResponse,
} from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ReisekostenabrechnungStatiService extends SettingsQueryService<
  IReisekostenabrechnungStatiRequest,
  IReisekostenabrechnungStatiResponse,
  IReisekostenabrechnungStatiQueryShapes,
  IReisekostenabrechnungStatiQueryFilter,
  IReisekostenabrechnungStatiQuerySorter
> {
  contentKey: keyof IReisekostenabrechnungStatiResponse = 'reisekostenabrechnungStati';
  route = 'finanzen/settings/reisekostenabrechnung-stati';
  override dependencies = ['finanzen/written-reisekostenabrechnung-status', 'finanzen/removed-reisekostenabrechnung-status'];
}
