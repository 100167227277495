import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostOrtCommandServiceModule } from '..';
import { PostOrtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostOrtCommandServiceModule
  ],
  declarations: [PostOrtCommandComponent],
  exports: [PostOrtCommandComponent],

})
export class PostOrtCommandComponentModule { }
