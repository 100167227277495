import { NgModule } from '@angular/core';

import {
  PostRolleCommandServiceModule,
  PostRolleCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostRolleCommandServiceModule,
    PostRolleCommandComponentModule
  ],
  exports: [
    PostRolleCommandServiceModule,
    PostRolleCommandComponentModule
  ]
})
export class PostRolleCommandModule { }
