export * from './auswertungen';
export * from './besichtigungen';
export * from './dokumente';
export * from './einstellungen';
export * from './felder';
export * from './finanzen';
export * from './formulare';
export * from './geo';
export * from './gutachten';
export * from './kommunikation';
export * from './kunden';
export * from './markt';
export * from './objekte';
export * from './personen';
export * from './projekte';
export * from './texte';
export * from './zeiten';

export type TimeConflict =
  | false
  | { noTourMatch?: boolean; kommuniziertVonIsBeforeFahrtBis?: boolean; kommuniziertBisIsBeforeKommuniziertVon?: boolean; bisIsBeforeKommuniziertBis?: boolean; negativerAufenthalt: boolean };
