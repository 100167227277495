import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteOrtRequest, IDeleteOrtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteOrtCommandService extends DeleteCommandService<IDeleteOrtRequest, IDeleteOrtResponse> {
  readonly route = 'geo/settings/ort';
}
