import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteProjektArtCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteProjektArtCommandService],
  exports: []
})
export class DeleteProjektArtCommandServiceModule { }
