import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { PostModelCommandComponent } from 'pbc.angular';

import { IPostTourStatusRequest, IPostTourStatusResponse } from 'fa-kt.types';
import { TourStatiService } from '../../../query';
import { PostTourStatusCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-tour-status',
  templateUrl: './post-tour-status.component.html',
  styleUrls: ['./post-tour-status.component.css'],
})
export class PostTourStatusCommandComponent extends PostModelCommandComponent<IPostTourStatusRequest, IPostTourStatusResponse> implements OnInit, OnDestroy {
  override description: { context: string; command: string; model: string } = {
    context: 'BESICHTIGUNGEN',
    command: 'POST_TOUR_STATUS',
    model: 'TOUR_STATUS',
  };

  constructor(
    public tourStati: TourStatiService,
    public postTourStatus: PostTourStatusCommandService,
  ) {
    super();
    this.form = this.fb.group({
      tourStatus: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        beschreibung: [null, []],
        color: [null, []],
        icon: [null, []],
        order: [null, []],
      }),
    });
  }

  request(payload: IPostTourStatusRequest): Promise<IPostTourStatusResponse | undefined> {
    return this.postTourStatus.request(payload);
  }
}
