import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BasePostCommandComponent } from 'pbc.angular';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IPostKrankheitRequest, IPostKrankheitResponse } from 'fa-kt.types';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { DeleteKrankheitCommandService, PostKrankheitCommandService } from '..';

@Component({
  selector: 'fa-kt-krankheit',
  templateUrl: './krankheit.component.html',
  styleUrls: ['./krankheit.component.css'],
})
export class KrankheitCommandComponent extends BasePostCommandComponent<IPostKrankheitRequest, IPostKrankheitResponse> implements OnInit, OnDestroy {
  description = { context: 'ZEITEN', command: 'POST_KRANKHEIT' };
  contentKey: keyof IPostKrankheitRequest = 'krankheit';

  async prepare(): Promise<void> {
    await Promise.all([this.postKrankheit.prepare()]);
  }

  async request(payload: IPostKrankheitRequest): Promise<IPostKrankheitResponse> {
    return this.postKrankheit.request(payload);
  }

  constructor(
    public postKrankheit: PostKrankheitCommandService,
    public deleteKrankheit: DeleteKrankheitCommandService,
  ) {
    super();
    this.form = this.fb.group({
      krankheit: this.fb.group({
        id: [null, []],

        mitarbeiter: [null, [Validators.required]],
        von: [null, [Validators.required]],
        bis: [null, [Validators.required]],

        bescheinigung: [null, []],
        kommentar: [null, []],
        bescheinigt: [null, []],
        outlook: [null, []],
      }),
    });
    this.patch();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // if (!this.environment.production) return;
    combineLatest([this.auth.$isOM, this.$initial])
      .pipe(takeUntilDestroyed(this.destroyedRef), distinctUntilChanged())
      .subscribe(([om, value]) => {
        if (value?.krankheit?.bescheinigt) {
          this.form.controls.krankheit?.get('mitarbeiter')?.disable();
          this.form.controls.krankheit?.get('von')?.disable();
          this.form.controls.krankheit?.get('bis')?.disable();
        } else {
          if (om) this.form.controls.krankheit?.get('mitarbeiter')?.enable();
          else this.form.controls.krankheit?.get('mitarbeiter')?.disable();
          this.form.controls.krankheit?.get('von')?.enable();
          this.form.controls.krankheit?.get('bis')?.enable();
        }
      });
  }

  async confirm() {
    this.form.controls.krankheit.patchValue({ bescheinigt: true });
    await this.submit();
  }
}
