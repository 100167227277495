import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { SavedTageFiltersComponent } from '.';
import { TageServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, TageServiceModule],
  declarations: [SavedTageFiltersComponent],
  exports: [SavedTageFiltersComponent],
})
export class SavedTageFiltersComponentModule {}
