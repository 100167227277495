import { NgModule } from '@angular/core';

import { KundenArtsSettingsModule, KundenGruppesSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [KundenArtsSettingsModule, KundenGruppesSettingsModule],
  exports: [KundenArtsSettingsModule, KundenGruppesSettingsModule],
})
export class KundenSettingsModule {}
