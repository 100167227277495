<!---
  <div formGroupName="reisekostenabrechnung">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
        <ng-template #MitarbeiterErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="postReisekostenabrechnung.reisekostenabrechnungMitarbeiter | async" [placeholder]="command.Inputs.MITARBEITER.placeholder" cdkFocusInitial required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="touren">{{ command.Inputs.TOUREN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourenErrorTip">
        <ng-template #TourenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOUREN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="touren" id="touren" [shapes]="postReisekostenabrechnung.reisekostenabrechnungTouren | async" [placeholder]="command.Inputs.TOUREN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="reisekostenabrechnungStatus">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ReisekostenabrechnungStatusErrorTip">
        <ng-template #ReisekostenabrechnungStatusErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="reisekostenabrechnungStatus" id="reisekostenabrechnungStatus" [shapes]="postReisekostenabrechnung.reisekostenabrechnungReisekostenabrechnungStatus | async" [placeholder]="command.Inputs.REISEKOSTENABRECHNUNG_STATUS.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldAcht">{{ command.Inputs.TAGESGELD_ACHT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldAchtErrorTip">
        <ng-template #TagesgeldAchtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_ACHT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="tagesgeldAcht" id="tagesgeldAcht" [nzPlaceHolder]="command.Inputs.TAGESGELD_ACHT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldVierundzwanzig">{{ command.Inputs.TAGESGELD_VIERUNDZWANZIG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldVierundzwanzigErrorTip">
        <ng-template #TagesgeldVierundzwanzigErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_VIERUNDZWANZIG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="tagesgeldVierundzwanzig" id="tagesgeldVierundzwanzig" [nzPlaceHolder]="command.Inputs.TAGESGELD_VIERUNDZWANZIG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anUndAbreisetag">{{ command.Inputs.AN_UND_ABREISETAG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnUndAbreisetagErrorTip">
        <ng-template #AnUndAbreisetagErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AN_UND_ABREISETAG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="anUndAbreisetag" id="anUndAbreisetag" [nzPlaceHolder]="command.Inputs.AN_UND_ABREISETAG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fruehstuecke">{{ command.Inputs.FRUEHSTUECKE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FruehstueckeErrorTip">
        <ng-template #FruehstueckeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FRUEHSTUECKE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="fruehstuecke" id="fruehstuecke" [nzPlaceHolder]="command.Inputs.FRUEHSTUECKE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mittagessen">{{ command.Inputs.MITTAGESSEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MittagessenErrorTip">
        <ng-template #MittagessenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITTAGESSEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="mittagessen" id="mittagessen" [nzPlaceHolder]="command.Inputs.MITTAGESSEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abendessen">{{ command.Inputs.ABENDESSEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbendessenErrorTip">
        <ng-template #AbendessenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABENDESSEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="abendessen" id="abendessen" [nzPlaceHolder]="command.Inputs.ABENDESSEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="uebernachtungspauschale">{{ command.Inputs.UEBERNACHTUNGSPAUSCHALE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UebernachtungspauschaleErrorTip">
        <ng-template #UebernachtungspauschaleErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.UEBERNACHTUNGSPAUSCHALE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="uebernachtungspauschale" id="uebernachtungspauschale" [nzPlaceHolder]="command.Inputs.UEBERNACHTUNGSPAUSCHALE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="normaleKm">{{ command.Inputs.NORMALE_KM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NormaleKmErrorTip">
        <ng-template #NormaleKmErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NORMALE_KM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="normaleKm" id="normaleKm" [nzPlaceHolder]="command.Inputs.NORMALE_KM.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mitnahmeentschaedigung">{{ command.Inputs.MITNAHMEENTSCHAEDIGUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitnahmeentschaedigungErrorTip">
        <ng-template #MitnahmeentschaedigungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITNAHMEENTSCHAEDIGUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="mitnahmeentschaedigung" id="mitnahmeentschaedigung" [nzPlaceHolder]="command.Inputs.MITNAHMEENTSCHAEDIGUNG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigePositionen">{{ command.Inputs.SONSTIGE_POSITIONEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigePositionenErrorTip">
        <ng-template #SonstigePositionenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGE_POSITIONEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="sonstigePositionen" id="sonstigePositionen" [placeholder]="command.Inputs.SONSTIGE_POSITIONEN.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigeSumme">{{ command.Inputs.SONSTIGE_SUMME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigeSummeErrorTip">
        <ng-template #SonstigeSummeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGE_SUMME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="sonstigeSumme" id="sonstigeSumme" [nzPlaceHolder]="command.Inputs.SONSTIGE_SUMME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="summe">{{ command.Inputs.SUMME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SummeErrorTip">
        <ng-template #SummeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SUMME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="summe" id="summe" [nzPlaceHolder]="command.Inputs.SUMME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="antrag">{{ command.Inputs.ANTRAG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AntragErrorTip">
        <ng-template #AntragErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANTRAG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="antrag" id="antrag" [shapes]="postReisekostenabrechnung.reisekostenabrechnungAntrag | async" [placeholder]="command.Inputs.ANTRAG.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <div formGroupName="reisekostenabrechnung">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="antrag" id="antrag" />
      <input class="cdk-visually-hidden" formControlName="summe" id="summe" />
      <input class="cdk-visually-hidden" formControlName="_createdAt" id="_createdAt" />

      <nz-divider nzText="Allgemein"></nz-divider>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
          <ng-template #MitarbeiterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="mitarbeiter"
            id="mitarbeiter"
            [shapes]="postReisekostenabrechnung.reisekostenabrechnungMitarbeiter | async"
            [placeholder]="command.Inputs.MITARBEITER.placeholder"
            cdkFocusInitial
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="reisekostenabrechnungStatus">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ReisekostenabrechnungStatusErrorTip">
          <ng-template #ReisekostenabrechnungStatusErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="reisekostenabrechnungStatus"
            id="reisekostenabrechnungStatus"
            [shapes]="postReisekostenabrechnung.reisekostenabrechnungReisekostenabrechnungStatus | async"
            [placeholder]="command.Inputs.REISEKOSTENABRECHNUNG_STATUS.placeholder"
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="isVeranstaltung">Veranstaltung</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="IsVeranstaltungErrorTip">
          <ng-template #IsVeranstaltungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte angeben ob es sich um eine Veranstaltung handelt</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="isVeranstaltung" id="isVeranstaltung"></label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%" *ngIf="!reisekostenabrechnungIsVeranstaltung?.value">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="touren">Touren</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourenErrorTip">
          <ng-template #TourenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOUREN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            mode="multiple"
            formControlName="touren"
            id="touren"
            [override]="false"
            [shapes]="selection$ | async"
            [filter]="reisekostenabrechnungMitarbeiter?.value"
            [placeholder]="command.Inputs.TOUREN.placeholder"
          ></pbc-select-value
          ><br />
          <nz-row>
            <nz-col nzSpan="24" *ngFor="let id of reisekostenabrechnungTouren?.value">
              <a (click)="navigateToTour({ id })">
                <span nz-icon nzType="right-circle" nzTheme="outline"></span>
                {{ getTour(id)?.label }}
              </a>
            </nz-col>
          </nz-row>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%" *ngIf="reisekostenabrechnungIsVeranstaltung?.value">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="von">Antritt</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VeranstaltungErrorTip">
          <ng-template #VeranstaltungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Veranstaltung beschreiben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="dd.MM.yyyy HH:mm" formControlName="von" id="von"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%" *ngIf="reisekostenabrechnungIsVeranstaltung?.value">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bis">Ende</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VeranstaltungErrorTip">
          <ng-template #VeranstaltungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Veranstaltung beschreiben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzShowTime nzDisabledSeconds nzFormat="dd.MM.yyyy HH:mm" formControlName="bis" id="bis"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%" *ngIf="reisekostenabrechnungIsVeranstaltung?.value">
        <nz-form-label nzTooltipTitle="Bitte Ort und Art der Veranstaltung angeben" [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="veranstaltung">Veranstaltung</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VeranstaltungErrorTip">
          <ng-template #VeranstaltungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Veranstaltung beschreiben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="veranstaltung" id="veranstaltung" placeholder="Veranstaltung"></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bezeichnung">Bezeichnung</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BezeichnungErrorTip">
          <ng-template #BezeichnungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Bezeichnung angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="bezeichnung" id="bezeichnung" placeholder="Bezeichnung" />
        </nz-form-control>
      </nz-form-item>

      <nz-divider nzText="Kilometergelderstattung"></nz-divider>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="normaleKm" [nzTooltipTitle]="'x 0,30 €/km'">Normale KM</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NormaleKmErrorTip">
          <ng-template #NormaleKmErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte normale KM angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="normaleKm" id="normaleKm" placeholder="Normale KM" /> km
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mitnahme" [nzTooltipTitle]="'Normale KM x 0,02 €/km'">Mitnahmeentschädigung</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitnahmeentschaedigungErrorTip">
          <ng-template #MitnahmeentschaedigungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')"></ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="mitnahme" id="mitnahme"></label>
        </nz-form-control>
      </nz-form-item>

      <nz-divider nzText="Tagesgelder"></nz-divider>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldAcht" [nzTooltipTitle]="'Tagegeld für	> 8 Std  < 24 Std'">{{ command.Inputs.TAGESGELD_ACHT.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldAchtErrorTip">
          <ng-template #TagesgeldAchtErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_ACHT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="tagesgeldAcht" id="tagesgeldAcht" [placeholder]="command.Inputs.TAGESGELD_ACHT.placeholder" />
          {{ reisekostenabrechnungTagesgeldAcht?.value * 14 }} €
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldVierundzwanzig" [nzTooltipTitle]="'Tagegeld für	>= 24 Std'">{{
          command.Inputs.TAGESGELD_VIERUNDZWANZIG.title
        }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldVierundzwanzigErrorTip">
          <ng-template #TagesgeldVierundzwanzigErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_VIERUNDZWANZIG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input
            nz-input
            type="number"
            style="width: 150px"
            formControlName="tagesgeldVierundzwanzig"
            id="tagesgeldVierundzwanzig"
            [placeholder]="command.Inputs.TAGESGELD_VIERUNDZWANZIG.placeholder"
          />
          {{ reisekostenabrechnungTagesgeldVierundzwanzig?.value * 28 }} €
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label
          [nzOffset]="2"
          [nzSm]="6"
          [nzXs]="24"
          nzFor="anUndAbreisetag"
          [nzTooltipTitle]="'jeweils für An- und Abreisetag bei einer mehrtätigen Reise unabhängig von der Abwesenheitsdauer 14 €'"
          >{{ command.Inputs.AN_UND_ABREISETAG.title }}</nz-form-label
        >
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnUndAbreisetagErrorTip">
          <ng-template #AnUndAbreisetagErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AN_UND_ABREISETAG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="anUndAbreisetag" id="anUndAbreisetag" [placeholder]="command.Inputs.AN_UND_ABREISETAG.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-control [nzOffset]="8" [nzSm]="12" [nzXs]="24">
          <br />
          <br />
          <nz-alert
            nzShowIcon
            nzType="info"
            nzMessage="
          Kürzung des Tagegeldes wegen unentgeltlicher Gewährung bei Arbeitnehmern.
          Wird nur im Zusammenhang zzgl. Reisekostenaufwendungen gekürzt"
          ></nz-alert>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fruehstuecke">{{ command.Inputs.FRUEHSTUECKE.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FruehstueckeErrorTip">
          <ng-template #FruehstueckeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FRUEHSTUECKE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="fruehstuecke" id="fruehstuecke" [placeholder]="command.Inputs.FRUEHSTUECKE.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mittagessen">{{ command.Inputs.MITTAGESSEN.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MittagessenErrorTip">
          <ng-template #MittagessenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITTAGESSEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="mittagessen" id="mittagessen" [placeholder]="command.Inputs.MITTAGESSEN.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abendessen">{{ command.Inputs.ABENDESSEN.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbendessenErrorTip">
          <ng-template #AbendessenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABENDESSEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="abendessen" id="abendessen" [placeholder]="command.Inputs.ABENDESSEN.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-control [nzOffset]="8" [nzSm]="12" [nzXs]="24">
          <nz-alert
            nzShowIcon
            nzType="info"
            nzMessage="Wird das Frühstück in der Rechnung gesondert ausgewiesen, so werden nur die Übernachtungskosten gezahlt. Die Frühstückskosten sind bereits durch das Tagegeld abgegolten. Ist das Frühstück nicht auf der Hotelrechnung ausgewiesen, muss der Rechnungsbetrag um  5,60 € gekürzt werden."
          ></nz-alert>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="uebernachtungspauschale" [nzTooltipTitle]="'Tage zu € 20,00 (statt Nachweis)'">{{
          command.Inputs.UEBERNACHTUNGSPAUSCHALE.title
        }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UebernachtungspauschaleErrorTip">
          <ng-template #UebernachtungspauschaleErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.UEBERNACHTUNGSPAUSCHALE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input
            nz-input
            type="number"
            style="width: 150px"
            formControlName="uebernachtungspauschale"
            id="uebernachtungspauschale"
            [placeholder]="command.Inputs.UEBERNACHTUNGSPAUSCHALE.placeholder"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-divider nzText="Reisenebenkosten lt. Belege"></nz-divider>

      <ng-container formArrayName="sonstigePositionen">
        <nz-form-item>
          <nz-form-control nzOffset="8">
            <button nz-button nzShape="round" (click)="addSonstigePosition()">
              <span nz-icon nzType="plus"></span>
              Neue Position
            </button>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngFor="let _ of sonstigePositionen.controls; index as i">
          <nz-form-control nzOffset="8" [formGroupName]="i">
            <nz-space>
              <span *nzSpaceItem [style.width]="'20px !important'" [style.display]="'block'" class="line-span"></span>

              <input *nzSpaceItem nz-input placeholder="Beschreibung" formControlName="beschreibung" />

              <span class="line-span" *nzSpaceItem></span>

              <nz-input-number-group *nzSpaceItem nzSuffix="€">
                <input type="number" [style.width]="'90px'" [style.marginLeft]="'-12px'" nz-input placeholder="Summe" formControlName="summe" step="10" min="0" />
              </nz-input-number-group>

              <span *nzSpaceItem> </span>
              <button *nzSpaceItem nz-button nzType="dashed" nzShape="circle" (click)="removeSonstigePosition(i)">
                <span nz-icon nzType="close"></span>
              </button>
            </nz-space>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigeSumme">{{ command.Inputs.SONSTIGE_SUMME.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigeSummeErrorTip">
          <ng-template #SonstigeSummeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGE_SUMME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="sonstigeSumme" id="sonstigeSumme" [placeholder]="command.Inputs.SONSTIGE_SUMME.placeholder" /> €
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-divider></nz-divider>

    <nz-form-item>
      <nz-form-control nzOffset="8">
        <nz-space nzAlign="baseline">
          <span *nzSpaceItem>Ich versichere die Richtigkeit der Angaben</span>
        </nz-space>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-control nzOffset="8">
        <button class="save-button" nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            {{ command.translation }}
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
