import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IOffeneGutachtenResponseRow } from 'fa-kt.types';
import { OffeneGutachtenService } from '../service';

@Component({
  selector: 'fa-kt-offene-gutachten-list',
  templateUrl: './offene-gutachten-list.component.html',
  styleUrls: ['./offene-gutachten-list.component.css'],
})
export class OffeneGutachtenListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  /* << props    */
  /*    props >> */

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public offeneGutachten: OffeneGutachtenService) {}

  public set(offeneGutachten: IOffeneGutachtenResponseRow) {}
}
