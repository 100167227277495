import { IFilter, IFilterConfig } from "pbc.types";

import { IRechnungZaehler } from '.';

export interface IRechnungZaehlerFilter extends IFilter<IRechnungZaehler> {}

export const RechnungZaehlerFilterConfig: IFilterConfig<IRechnungZaehler> = [];

export type IRechnungZaehlerField =
  'rechnungZaehler.id'|
  'rechnungZaehler.zaehler'
;
