import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteBesichtigungCommandServiceModule } from '..';
import { DeleteBesichtigungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteBesichtigungCommandServiceModule
  ],
  declarations: [DeleteBesichtigungCommandComponent],
  exports: [DeleteBesichtigungCommandComponent],

})
export class DeleteBesichtigungCommandComponentModule { }
