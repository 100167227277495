import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostKonstanteCommandServiceModule } from '..';
import { PostKonstanteCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostKonstanteCommandServiceModule
  ],
  declarations: [PostKonstanteCommandComponent],
  exports: [PostKonstanteCommandComponent],

})
export class PostKonstanteCommandComponentModule { }
