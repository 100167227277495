import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IKonstante } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { KonstantesService } from '../service';

@Component({
  selector: 'fa-kt-konstantes-list',
  templateUrl: './konstantes-list.component.html',
  styleUrls: ['./konstantes-list.component.css'],
})
export class KonstantesListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IKonstante> = new EventEmitter<IKonstante>();

  constructor(public konstantes: KonstantesService) {}

  ngOnDestroy() {}
}
