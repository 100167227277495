import { Component } from '@angular/core';
import { ObjekteService } from '../service';

@Component({
  selector: 'fa-kt-saved-objekte-filters',
  templateUrl: './saved-objekte-filters.component.html',
  styleUrls: ['./saved-objekte-filters.component.css'],
})
export class SavedObjekteFiltersComponent {
  constructor(public objekte: ObjekteService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
