import { IFilter, IFilterConfig } from "pbc.types";

import { IProjektStatus } from '.';

export interface IProjektStatusFilter extends IFilter<IProjektStatus> {}

export const ProjektStatusFilterConfig: IFilterConfig<IProjektStatus> = [];

export type IProjektStatusField =
  'projektStatus.id'|
  'projektStatus.name'|
  'projektStatus.beschreibung'
;
