import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostZeitUnterartCommandComponent } from '.';
import { PostZeitUnterartCommandServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostZeitUnterartCommandServiceModule
  ],
  declarations: [PostZeitUnterartCommandComponent],
  exports: [PostZeitUnterartCommandComponent],

})
export class PostZeitUnterartCommandComponentModule { }
