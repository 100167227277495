import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFeldKategorieSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  initialen?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const FeldKategorieSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('initialen', 'Initialen'),
  SorterConfig('kommentar', 'Kommentar')
];
