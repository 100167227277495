import { NgModule } from '@angular/core';

import {
  DeleteReisekostenabrechnungCommandServiceModule,
  DeleteReisekostenabrechnungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteReisekostenabrechnungCommandServiceModule,
    DeleteReisekostenabrechnungCommandComponentModule
  ],
  exports: [
    DeleteReisekostenabrechnungCommandServiceModule,
    DeleteReisekostenabrechnungCommandComponentModule
  ]
})
export class DeleteReisekostenabrechnungCommandModule { }
