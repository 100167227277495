<!---
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="cloud-sync" nzTheme="outline"></i>
          AAD Synchronisieren
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            <i nz-icon nzType="windows" nzTheme="outline"></i>
            Azure Active Directory Synchronisieren
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
