import { IFilter, IFilterConfig } from 'pbc.types';

import { IProjekt } from '.';

export interface IProjektFilter extends IFilter<IProjekt> {}

export const ProjektFilterConfig: IFilterConfig<IProjekt> = [];

export type IProjektField =
  | 'projekt.id'
  | 'projekt.bezeichnung'
  | 'projekt.nummer'
  | 'projekt.projektZaehler'
  | 'projekt.projektArt'
  | 'projekt.projektStatus'
  | 'projekt.standort'
  | 'projekt.beauftragung'
  | 'projekt.deadline'
  | 'projekt.kundenAnfertigung'
  | 'projekt.keineBesichtigungErforderlich'
  | 'projekt.besichtigungen'
  | 'projekt.gutachten'
  | 'projekt.pruefung'
  | 'projekt.kunde'
  | 'projekt.abteilung'
  | 'projekt.fond'
  | 'projekt.bank'
  | 'projekt.objektArt'
  | 'projekt.nachlass'
  | 'projekt.kundenZeichen'
  | 'projekt.kontakteInOutlook'
  | 'projekt.dateiVerzeichnis'
  | 'projekt.kommentar'
  | 'projekt.notizenFuerRechnung'
  | 'projekt.fortschritt'
  // 'projekt.strecke'|
  // 'projekt.kalkulierteStrecke'|
  // 'projekt.fahrtzeit'|
  // 'projekt.kalkulierteFahrtzeit'|
  | 'projekt.stunden'
  | 'projekt.kostenNachStunden'
  | 'projekt.verhandeltesHonorar'
  | 'projekt.kosten'
  | 'projekt.kostenBeglichen'
  | 'projekt.aktiv'
  | 'projekt.darlehensnehmer'
  | 'projekt.notizenBesichtigung'
  | 'projekt.kreditsachbearbeiterVorname'
  | 'projekt.kreditsachbearbeiterName'
  | 'projekt.kreditsachbearbeiterTelefon'
  | 'projekt.kreditsachbearbeiterEMail';
