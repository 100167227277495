import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostRahmenAnpassungCommandShapes, IPostRahmenAnpassungRequest, IPostRahmenAnpassungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostRahmenAnpassungCommandService extends PostCommandService<IPostRahmenAnpassungRequest, IPostRahmenAnpassungResponse, IPostRahmenAnpassungCommandShapes> {
  route = 'zeiten/rahmen-anpassung';
}
