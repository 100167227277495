import { NgModule } from '@angular/core';
import { 
  PostEintragCommandModule,
  PostGutachtenCommandModule,
  DeleteGutachtenCommandModule,
  PostKalkulationHochladenCommandModule
} from '.';

@NgModule({
  declarations: [],
  imports: [
    PostEintragCommandModule,
    PostGutachtenCommandModule,
    DeleteGutachtenCommandModule,
    PostKalkulationHochladenCommandModule
  ],
  exports: [
    PostEintragCommandModule,
    PostGutachtenCommandModule,
    DeleteGutachtenCommandModule,
    PostKalkulationHochladenCommandModule
  ]
})
export class GutachtenCommandsModule { }
