import { Injectable } from '@angular/core';

import { IDeleteObjektArtRequest, IDeleteObjektArtResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteObjektArtCommandService extends DeleteCommandService<IDeleteObjektArtRequest, IDeleteObjektArtResponse> {
  readonly route = 'objekte/settings/objekt-art';
}
