import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IHonorarVereinbarungSorter extends ISorter {
  id?: ISorterExpression;
  kunde?: ISorterExpression;
  bisMarktwert?: ISorterExpression;
  lohnWohnen?: ISorterExpression;
  lohnGewerbe?: ISorterExpression;
  lohnSpezial?: ISorterExpression;
}

export const HonorarVereinbarungSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('bisMarktwert', 'Bis Marktwert'),
  SorterConfig('lohnWohnen', 'Lohn Wohnen'),
  SorterConfig('lohnGewerbe', 'Lohn Gewerbe'),
  SorterConfig('lohnSpezial', 'Lohn Spezial')
];
