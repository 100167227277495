import { NgModule } from '@angular/core';

import {
  DeleteReisekostenabrechnungStatusCommandServiceModule,
  DeleteReisekostenabrechnungStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteReisekostenabrechnungStatusCommandServiceModule,
    DeleteReisekostenabrechnungStatusCommandComponentModule
  ],
  exports: [
    DeleteReisekostenabrechnungStatusCommandServiceModule,
    DeleteReisekostenabrechnungStatusCommandComponentModule
  ]
})
export class DeleteReisekostenabrechnungStatusCommandModule { }
