import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IHerausgebersQueryFilter, IHerausgebersQueryShapes, IHerausgebersQuerySorter, IHerausgebersRequest, IHerausgebersResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class HerausgebersService extends SettingsQueryService<IHerausgebersRequest, IHerausgebersResponse, IHerausgebersQueryShapes, IHerausgebersQueryFilter, IHerausgebersQuerySorter> {
  contentKey: keyof IHerausgebersResponse = 'herausgebers';
  route = 'markt/settings/herausgebers';
  override dependencies = ['markt/written-herausgeber', 'markt/removed-herausgeber'];
}
