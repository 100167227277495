import { Injectable } from '@angular/core';

import { IDeleteHonorarVereinbarungRequest, IDeleteHonorarVereinbarungResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteHonorarVereinbarungCommandService extends DeleteCommandService<IDeleteHonorarVereinbarungRequest, IDeleteHonorarVereinbarungResponse> {
  readonly route = 'finanzen/honorar-vereinbarung';
}
