import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  DateFilterConfig,
  DateTimeFilterConfig,
  GeoRangeFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IDateFilter,
  IDateTimeFilter,
  IFilter,
  IFilterConfig,
  IGeoRangeFilter,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IBereich, IBesichtigungsStatus, IFeld, IGutachten, IGutachtenStatus, IHerausgeber, IMitarbeiter, IObjekt, IObjektArt, IObjektNutzung } from 'fa-kt.types';
import { IGutachtenResponse } from '.';

export interface IGutachtenQueryFilter extends IFilter<IGutachtenResponse> {
  'gutachten.objekt'?: ISingleReferenceFilter<IObjekt>;
  'gutachten.bewertungsStatus'?: ISingleReferenceFilter<IGutachtenStatus>;
  'gutachten.objektNutzung'?: ISingleReferenceFilter<IObjektNutzung>;
  'gutachten.objektArt'?: ISingleReferenceFilter<IObjektArt>;
  'gutachten.abgabeDraft'?: IDateFilter;
  'gutachten.abgabeFinal'?: IDateFilter;
  'gutachten.erbbaurecht'?: IBooleanFilter;
  'gutachten.wohnenJRoE'?: INumberFilter;
  'gutachten.wohnenJRoEFlaeche'?: INumberFilter;
  'gutachten.gewerbeJRoE'?: INumberFilter;
  'gutachten.gewerbeJRoEFlaeche'?: INumberFilter;
  'gutachten.bueroJRoE'?: INumberFilter;
  'gutachten.bueroJRoEFlaeche'?: INumberFilter;
  'gutachten.handelJRoE'?: INumberFilter;
  'gutachten.handelJRoEFlaeche'?: INumberFilter;
  'gutachten.lagerJRoE'?: INumberFilter;
  'gutachten.lagerJRoEFlaeche'?: INumberFilter;
  'gutachten.sonstigesJRoE'?: INumberFilter;
  'gutachten.sonstigesJRoEFlaeche'?: INumberFilter;
  'gutachten.baujahr'?: INumberFilter;
  'gutachten.modernisierung'?: INumberFilter;
  'gutachten.marktwert'?: INumberFilter;
  'gutachten.stichtagMarktwert'?: IDateFilter;
  'gutachten.kaufpreis'?: INumberFilter;
  'gutachten.stichtagKaufpreis'?: IDateFilter;
  'gutachten.fortschritt'?: INumberFilter;
  'gutachten.aktiv'?: IBooleanFilter;
  'gutachten.tourenplanung'?: IBooleanFilter;
  'objekt.addresse'?: IGeoRangeFilter;
  'objekt.bereiche'?: IArrayReferenceFilter<IBereich>;
  'erforderlicheDokumente.erforderlich'?: IBooleanFilter;
  'erforderlicheDokumente.fortschritt'?: INumberFilter;
  'besichtigung.besichtigungsStatus'?: ISingleReferenceFilter<IBesichtigungsStatus>;
  'besichtigung.mitarbeiter'?: ISingleReferenceFilter<IMitarbeiter>;
  'besichtigung.rueckfahrt'?: IBooleanFilter;
  'besichtigung.von'?: IDateTimeFilter;
  'besichtigung.bis'?: IDateTimeFilter;
  'besichtigung.start'?: IGeoRangeFilter;
  'besichtigung.ende'?: IGeoRangeFilter;
  'besichtigung.aufenthalt'?: INumberFilter;
  'besichtigung.fahrtzeit'?: INumberFilter;
  'besichtigung.fahrtzeitOhneVerkehr'?: INumberFilter;
  'besichtigung.abgerechnet'?: IBooleanFilter;
  'eintraege.gutachten'?: ISingleReferenceFilter<IGutachten>;
  'eintraege.feld'?: ISingleReferenceFilter<IFeld>;
  'eintraege.achtung'?: IBooleanFilter;
  'berichte.herausgeber'?: ISingleReferenceFilter<IHerausgeber>;
  'berichte.jahr'?: INumberFilter;
  'berichte.monat'?: INumberFilter;
}

export const GutachtenQueryFilterConfig: IFilterConfig<IGutachtenResponse> = [
  SingleReferenceFilterConfig<IObjekt>('gutachten.objekt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT.title'),
  SingleReferenceFilterConfig<IGutachtenStatus>('gutachten.bewertungsStatus', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_STATUS.title'),
  SingleReferenceFilterConfig<IObjektNutzung>('gutachten.objektNutzung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_NUTZUNG.title'),
  SingleReferenceFilterConfig<IObjektArt>('gutachten.objektArt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.OBJEKT_ART.title'),
  DateFilterConfig('gutachten.abgabeDraft', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_DRAFT.title'),
  DateFilterConfig('gutachten.abgabeFinal', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ABGABE_FINAL.title'),
  BooleanFilterConfig('gutachten.erbbaurecht', 'GUTACHTEN.Models.GUTACHTEN.Propertys.ERBBAURECHT.title'),
  NumberFilterConfig('gutachten.wohnenJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E.title'),
  NumberFilterConfig('gutachten.wohnenJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.WOHNEN_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.gewerbeJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E.title'),
  NumberFilterConfig('gutachten.gewerbeJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.GEWERBE_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.bueroJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E.title'),
  NumberFilterConfig('gutachten.bueroJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BUERO_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.handelJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E.title'),
  NumberFilterConfig('gutachten.handelJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.HANDEL_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.lagerJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E.title'),
  NumberFilterConfig('gutachten.lagerJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.LAGER_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.sonstigesJRoE', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E.title'),
  NumberFilterConfig('gutachten.sonstigesJRoEFlaeche', 'GUTACHTEN.Models.GUTACHTEN.Propertys.SONSTIGES_J_RO_E_FLAECHE.title'),
  NumberFilterConfig('gutachten.baujahr', 'GUTACHTEN.Models.GUTACHTEN.Propertys.BAUJAHR.title'),
  NumberFilterConfig('gutachten.modernisierung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MODERNISIERUNG.title'),
  NumberFilterConfig('gutachten.marktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.MARKTWERT.title'),
  DateFilterConfig('gutachten.stichtagMarktwert', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_MARKTWERT.title'),
  NumberFilterConfig('gutachten.kaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.KAUFPREIS.title'),
  DateFilterConfig('gutachten.stichtagKaufpreis', 'GUTACHTEN.Models.GUTACHTEN.Propertys.STICHTAG_KAUFPREIS.title'),
  NumberFilterConfig('gutachten.fortschritt', 'GUTACHTEN.Models.GUTACHTEN.Propertys.FORTSCHRITT.title'),
  BooleanFilterConfig('gutachten.aktiv', 'GUTACHTEN.Models.GUTACHTEN.Propertys.AKTIV.title'),
  BooleanFilterConfig('gutachten.tourenplanung', 'GUTACHTEN.Models.GUTACHTEN.Propertys.TOURENPLANUNG.title'),
  GeoRangeFilterConfig('objekt.addresse', 'OBJEKTE.Models.OBJEKT.Propertys.ADDRESSE.title'),
  ArrayReferenceFilterConfig<IBereich>('objekt.bereiche', 'OBJEKTE.Models.OBJEKT.Propertys.BEREICHE.title'),
  BooleanFilterConfig('erforderlicheDokumente.erforderlich', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ERFORDERLICH.title'),
  NumberFilterConfig('erforderlicheDokumente.fortschritt', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.FORTSCHRITT.title'),
  SingleReferenceFilterConfig<IBesichtigungsStatus>('besichtigung.besichtigungsStatus', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BESICHTIGUNGS_STATUS.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('besichtigung.mitarbeiter', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.MITARBEITER.title'),
  BooleanFilterConfig('besichtigung.rueckfahrt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.RUECKFAHRT.title'),
  DateTimeFilterConfig('besichtigung.von', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.VON.title'),
  DateTimeFilterConfig('besichtigung.bis', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BIS.title'),
  GeoRangeFilterConfig('besichtigung.start', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.START.title'),
  GeoRangeFilterConfig('besichtigung.ende', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ENDE.title'),
  NumberFilterConfig('besichtigung.aufenthalt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.AUFENTHALT.title'),
  NumberFilterConfig('besichtigung.fahrtzeit', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT.title'),
  NumberFilterConfig('besichtigung.fahrtzeitOhneVerkehr', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT_OHNE_VERKEHR.title'),
  BooleanFilterConfig('besichtigung.abgerechnet', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ABGERECHNET.title'),
  SingleReferenceFilterConfig<IGutachten>('eintraege.gutachten', 'GUTACHTEN.Models.EINTRAG.Propertys.GUTACHTEN.title'),
  SingleReferenceFilterConfig<IFeld>('eintraege.feld', 'GUTACHTEN.Models.EINTRAG.Propertys.FELD.title'),
  BooleanFilterConfig('eintraege.achtung', 'GUTACHTEN.Models.EINTRAG.Propertys.ACHTUNG.title'),
  SingleReferenceFilterConfig<IHerausgeber>('berichte.herausgeber', 'MARKT.Models.BERICHT.Propertys.HERAUSGEBER.title'),
  NumberFilterConfig('berichte.jahr', 'MARKT.Models.BERICHT.Propertys.JAHR.title'),
  NumberFilterConfig('berichte.monat', 'MARKT.Models.BERICHT.Propertys.MONAT.title'),
];

export interface IGutachtenQueryShapes extends IShape {
  'gutachten.objekt': ISelection[];
  'gutachten.bewertungsStatus': ISelection[];
  'gutachten.objektNutzung': ISelection[];
  'gutachten.objektArt': ISelection[];
  'objekt.bereiche': ISelection[];
  'besichtigung.besichtigungsStatus': ISelection[];
  'besichtigung.mitarbeiter': ISelection[];
  'eintraege.gutachten': ISelection[];
  'eintraege.feld': ISelection[];
  'berichte.art': ISelection[];
  'berichte.herausgeber': ISelection[];
}

export type IGutachtenQueryField =
  | 'gutachten.id'
  | 'gutachten.projekt'
  | 'gutachten.objekt'
  | 'gutachten.bewertungsStatus'
  | 'gutachten.objektNutzung'
  | 'gutachten.objektArt'
  | 'gutachten.sicherheitscheck'
  | 'gutachten.abgabeDraft'
  | 'gutachten.abgabeFinal'
  | 'gutachten.erbbaurecht'
  | 'gutachten.wohnenJRoE'
  | 'gutachten.wohnenJRoEFlaeche'
  | 'gutachten.gewerbeJRoE'
  | 'gutachten.gewerbeJRoEFlaeche'
  | 'gutachten.bueroJRoE'
  | 'gutachten.bueroJRoEFlaeche'
  | 'gutachten.handelJRoE'
  | 'gutachten.handelJRoEFlaeche'
  | 'gutachten.lagerJRoE'
  | 'gutachten.lagerJRoEFlaeche'
  | 'gutachten.sonstigesJRoE'
  | 'gutachten.sonstigesJRoEFlaeche'
  | 'gutachten.baujahr'
  | 'gutachten.modernisierung'
  | 'gutachten.marktwert'
  | 'gutachten.stichtagMarktwert'
  | 'gutachten.kaufpreis'
  | 'gutachten.stichtagKaufpreis'
  | 'gutachten.fortschritt'
  | 'gutachten.aktiv'
  | 'gutachten.kommentar'
  | 'gutachten.tourenplanung'
  | 'objekt.id'
  | 'objekt.name'
  | 'objekt.addresse'
  | 'objekt.bereiche'
  | 'objekt.anspracheOutlookKontakts'
  | 'objekt.klingelschild'
  | 'objekt.kommentar'
  | 'erforderlicheDokumente.id'
  | 'erforderlicheDokumente.original'
  | 'erforderlicheDokumente.gutachten'
  | 'erforderlicheDokumente.name'
  | 'erforderlicheDokumente.erforderlich'
  | 'erforderlicheDokumente.fortschritt'
  | 'erforderlicheDokumente.kommentar'
  | 'kalkulationen.id'
  | 'kalkulationen.gutachten'
  | 'kalkulationen.datei'
  | 'besichtigung.id'
  | 'besichtigung.projekt'
  | 'besichtigung.tour'
  | 'besichtigung.gutachten'
  | 'besichtigung.objekt'
  | 'besichtigung.besichtigungsStatus'
  | 'besichtigung.mitarbeiter'
  | 'besichtigung.kommentar'
  | 'besichtigung.rueckfahrt'
  | 'besichtigung.von'
  | 'besichtigung.bis'
  | 'besichtigung.start'
  | 'besichtigung.ende'
  | 'besichtigung.aufenthalt'
  | 'besichtigung.fahrtzeit'
  | 'besichtigung.fahrtstrecke'
  | 'besichtigung.fahrtzeitOhneVerkehr'
  // | 'besichtigung.kosten'
  | 'besichtigung.abgerechnet'
  | 'besichtigung.route'
  | 'durchgaenge.id'
  | 'durchgaenge.formular'
  | 'durchgaenge.gutachten'
  | 'durchgaenge.projekt'
  | 'durchgaenge.besichtigung'
  | 'eintraege.id'
  | 'eintraege.gutachten'
  | 'eintraege.feld'
  | 'eintraege.wert'
  | 'eintraege.wertExtra'
  | 'eintraege.akkusativ'
  | 'eintraege.nominativ'
  | 'eintraege.dativ'
  | 'eintraege.genitiv'
  | 'eintraege.achtung'
  | 'berichte.id'
  | 'berichte.excel'
  | 'berichte.art'
  | 'berichte.herausgeber'
  | 'berichte.quelle'
  | 'berichte.jahr'
  | 'berichte.quartal'
  | 'berichte.monat'
  | 'berichte.kommentar'
  | '';
