import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IOrtsQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   kommentar?: ISorterExpression;
   areal?: ISorterExpression;
}

export const OrtsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'GEO.Models.BEREICH.Propertys.ID.title'),
   SorterConfig('name', 'GEO.Models.BEREICH.Propertys.NAME.title'),
   SorterConfig('kommentar', 'GEO.Models.BEREICH.Propertys.KOMMENTAR.title'),
   SorterConfig('areal', 'GEO.Models.BEREICH.Propertys.AREAL.title'),
];
