import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { ITageQueryFilter, ITageQueryShapes, ITageQuerySorter, ITageRequest, ITageResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class TageService extends ListQueryService<ITageRequest, ITageResponse, ITageQueryShapes, ITageQueryFilter, ITageQuerySorter> {
  contentKey: keyof ITageResponse = 'tage';
  override readonly route = 'zeiten/tage';
  override readonly dependencies = ['zeiten/tag-gespeichert'];

  static instance: TageService;

  constructor() {
    super();
    if (!TageService.instance) TageService.instance = this;
    return TageService.instance;
  }
}
