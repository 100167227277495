import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { FelderComponent } from '.';
import { KalkulationenComponentModule } from '../../../gutachten';
import { DeleteFeldCommandModule, DeleteFeldOptionCommandModule, PostFeldCommandModule, PostFeldOptionCommandModule, PostOrdneFeldOptionenCommandModule } from '../../commands';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFeldCommandModule,
    PostFeldCommandModule,
    PostFeldOptionCommandModule,
    DeleteFeldOptionCommandModule,
    PostOrdneFeldOptionenCommandModule,
    KalkulationenComponentModule,
  ],
  declarations: [FelderComponent],
  exports: [FelderComponent],
})
export class FelderComponentModule {}
