import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FelderService, FeldKategoriesService, FeldUnterkategoriesService } from '../../../felder';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { groupBy } from 'lodash';

interface IFeld {
  label: string;
  value: string;
  checked: boolean;
  _search: string;
  icon: string;
  tooltip: string;
}

interface IUnterkategorie {
  title: string;
  checked: boolean;
  indeterminate: boolean;
  felder: IFeld[];
  collapsed: boolean;
  mobile: boolean;
  draggable: boolean;
}

@Component({
  selector: 'fa-kt-felder-selection',
  templateUrl: './felder-selection.component.html',
  styleUrls: ['./felder-selection.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FelderSelectionComponent,
    },
  ],
})
export class FelderSelectionComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private readonly subscriptions: Subscription[] = [];

  @Input() set felder(felder: string[]) {
    this.$selected.next(felder || []);
  }
  @Output() felderChange = new EventEmitter<string[]>();
  touched = false;
  disabled = false;

  readonly $selected = new BehaviorSubject<string[]>([]);
  public $felder = new BehaviorSubject<IUnterkategorie[]>([]);
  readonly search$ = new BehaviorSubject<string>('');
  public filtered$ = new BehaviorSubject<IUnterkategorie[]>([]);

  onChange = (value: string[]) => {};
  onTouched = () => {};

  loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    public felderService: FelderService,
    public feldKategorienService: FeldKategoriesService,
    public feldUnterkategorienService: FeldUnterkategoriesService,
  ) {}

  async ngOnInit() {
    this.loading$.next(true);
    this.subscriptions.push(
      ...[
        combineLatest([this.felderService.result$, this.feldKategorienService.result$, this.feldUnterkategorienService.result$, this.$selected]).subscribe(
          ([felder, kategorien, unterkategorien, selected]) => {
            if (!felder?.felder?.length || !kategorien?.feldKategories?.length || !unterkategorien?.feldUnterkategories?.length || !selected) return;
            this.loading$.next(true);

            felder.felder = selected?.length
              ? [...selected.map((v) => felder.felder.find(({ feld: { id } }) => v === id)), ...felder.felder.filter(({ feld: { id } }) => !selected.includes(id))].filter((v) => !!v)
              : felder.felder;

            const kategories = Object.keys(groupBy(felder.felder, ({ feld: { feldKategorie } }) => feldKategorie));
            kategorien.feldKategories = kategories?.length
              ? [...kategories.map((v) => kategorien.feldKategories.find(({ id }) => v === id)), ...kategorien.feldKategories.filter(({ id }) => !kategories.includes(id))].filter((v) => !!v)
              : kategorien.feldKategories;

            const unterkategories = Object.keys(groupBy(felder.felder, ({ feld: { feldUnterkategorie } }) => feldUnterkategorie));
            unterkategorien.feldUnterkategories = unterkategories?.length
              ? [
                  ...unterkategories.map((v) => unterkategorien?.feldUnterkategories.find(({ id }) => v === id)),
                  ...unterkategorien?.feldUnterkategories.filter(({ id }) => !unterkategories.includes(id)),
                ].filter((v) => !!v)
              : unterkategorien?.feldUnterkategories;

            const next = kategorien.feldKategories
              .map((kategorie) => {
                return (unterkategorien?.feldUnterkategories || [])
                  .filter(({ feldKategorie }) => kategorie.id === feldKategorie)
                  .map(({ name, id, icon, color, _search }) => {
                    return {
                      draggable: true,
                      kategorie: {
                        title: kategorie.name,
                        icon: kategorie.icon,
                        color: kategorie.color,
                      },
                      title: name,
                      mobile: kategorie.inApp,
                      icon,
                      color,
                      checked: false,
                      indeterminate: false,
                      collapsed: false,
                      felder: (felder?.felder || [])
                        .filter(({ feld }) => feld.feldUnterkategorie === id)
                        .map(({ feld, feldOptionen }) => {
                          let icon;
                          let tooltip;
                          switch (feld.art) {
                            case 'datum':
                              icon = 'field-time';
                              tooltip = 'Datum';
                              break;
                            case 'haken':
                              icon = 'check-circle';
                              tooltip = 'Haken';
                              break;
                            case 'text':
                              icon = 'field-string';
                              tooltip = 'Text';
                              break;
                            case 'zahl':
                              icon = 'field-number';
                              tooltip = 'Zahl';
                              break;
                            case 'option':
                              icon = 'check-square';
                              tooltip = 'Option';
                              break;
                            case 'optionPlus':
                              icon = 'check-square';
                              tooltip = 'Option Plus';
                              break;
                            case 'mehrfachauswahlPlus':
                              icon = 'select';
                              tooltip = 'Mehrfachauswahl';
                              break;
                          }
                          return {
                            label: (feld.schluessel + ' | ' + feld.name) as string,
                            value: feld.id,
                            checked: false,
                            icon,
                            tooltip,
                            _search: ((feld._search || '') + (feldOptionen?.map(({ option }) => option).join('') || '') + (kategorie._search || '') + (_search || '')).toLowerCase(),
                          };
                        }),
                    };
                  });
              })
              .flat();

            const zugeordnet = next.map(({ felder }) => felder.map(({ value }) => value)).flat();
            next.push({
              draggable: false,
              kategorie: {
                title: 'Keine Kategorie',
                icon: '',
                color: 'red',
              },
              title: 'Keine Unterkategorie',
              mobile: false,
              icon: '',
              color: 'red',
              checked: false,
              indeterminate: false,
              collapsed: false,
              felder: felder?.felder
                .filter(({ feld: { id } }) => !zugeordnet.includes(id))
                .map(({ feld, feldOptionen }) => {
                  let icon;
                  let tooltip;
                  switch (feld.art) {
                    case 'datum':
                      icon = 'field-time';
                      tooltip = 'Datum';
                      break;
                    case 'haken':
                      icon = 'check-circle';
                      tooltip = 'Haken';
                      break;
                    case 'text':
                      icon = 'field-string';
                      tooltip = 'Text';
                      break;
                    case 'zahl':
                      icon = 'field-number';
                      tooltip = 'Zahl';
                      break;
                    case 'option':
                      icon = 'check-square';
                      tooltip = 'Option';
                      break;
                    case 'optionPlus':
                      icon = 'check-square';
                      tooltip = 'Option Plus';
                      break;
                    case 'mehrfachauswahlPlus':
                      icon = 'select';
                      tooltip = 'Mehrfachauswahl';
                      break;
                  }
                  return {
                    label: (feld.schluessel + ' | ' + feld.name) as string,
                    value: feld.id,
                    checked: false,
                    icon,
                    tooltip,
                    _search: ((feld._search || '') + (feldOptionen?.map(({ option }) => option).join('') || '') + 'Keine Kategorie Unterkategorie').toLowerCase(),
                  };
                }),
            });

            this.$felder.next(next);
            this.checkFelder(selected);
            this.loading$.next(false);
          },
        ),
        this.$felder.subscribe((felder) => {
          if (!felder || felder.length === 0) return;
          const result = ([] as IFeld[]).concat(...felder.map((unterkategorie) => [...unterkategorie.felder].filter((p) => p.checked))).map((p) => p.value);
          this.felderChange.emit(result);
          this.onChange(result);
        }),
        combineLatest([this.$felder, this.search$.pipe(debounceTime(200), distinctUntilChanged())]).subscribe(([unterkategorien, search]) => {
          if (!search?.length) this.filtered$.next(unterkategorien);
          else {
            search = search.toLowerCase();
            this.filtered$.next(
              [...unterkategorien]
                .map((unterkategorie) => ({
                  ...unterkategorie,
                  felder: ([...unterkategorie.felder] || []).filter(({ _search }) => _search.indexOf(search) >= 0),
                }))
                .filter((unterkategorie) => unterkategorie.felder.length > 0),
            );
          }
        }),
      ],
    );
    await Promise.all([this.felderService.request({}), this.feldUnterkategorienService.request({})]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  updateUnterkategorie(unterkategorie: IUnterkategorie, $event: boolean): void {
    unterkategorie.felder = unterkategorie.felder.map((item) => ({
      ...item,
      checked: $event,
    }));
    unterkategorie.checked = $event;
    unterkategorie.indeterminate = false;
    this.$felder.next(this.$felder.getValue().map((ctx) => (ctx.title !== unterkategorie.title ? ctx : unterkategorie)));
    this.onTouched();
  }

  updateFeld(unterkategorie: IUnterkategorie, feld: IFeld, $event: boolean): void {
    feld.checked = $event;
    unterkategorie = this.$felder.getValue().find((untktgr) => untktgr.title === unterkategorie.title) as IUnterkategorie;
    unterkategorie.felder = unterkategorie.felder.map((p) => (p.value !== feld.value ? p : feld));
    if (unterkategorie.felder.every((item) => !item.checked)) {
      unterkategorie.checked = false;
      unterkategorie.indeterminate = false;
    } else if (unterkategorie.felder.every((item) => item.checked)) {
      unterkategorie.checked = true;
      unterkategorie.indeterminate = false;
    } else {
      unterkategorie.indeterminate = true;
    }
    this.$felder.next(this.$felder.getValue().map((untktgr) => (untktgr.title === unterkategorie.title ? unterkategorie : untktgr)));
    this.onTouched();
  }

  private checkFelder(felder: string[]) {
    this.loading$.next(true);
    const unterkategorien = this.$felder.getValue().map((unterkategorie) => ({ ...unterkategorie, felder: unterkategorie.felder.map((p) => ({ ...p, checked: felder.includes(p.value) }) as IFeld) }));
    this.$felder.next(
      unterkategorien.map((unterkategorie) => {
        if (!unterkategorie.felder.some(({ checked }) => checked)) {
          unterkategorie.checked = false;
          unterkategorie.indeterminate = false;
        } else if (!unterkategorie.felder.some(({ checked }) => !checked)) {
          unterkategorie.checked = true;
          unterkategorie.indeterminate = false;
        } else unterkategorie.indeterminate = true;
        return unterkategorie;
      }),
    );
    this.loading$.next(false);
  }

  writeValue(value: string[]) {
    this.felder = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  dropUnterkategorie(event: CdkDragDrop<IUnterkategorie>) {
    const filtered = this.filtered$.getValue();
    const fPrevious = filtered[event.previousIndex];
    const fNext = filtered[event.currentIndex];

    const felder = this.$felder.getValue();
    const nPrevious = felder.find(({ title }) => title === fPrevious.title);
    const nPIDX = felder.indexOf(nPrevious);
    const nNext = felder.find(({ title }) => title === fNext.title);
    const nNIDX = felder.indexOf(nNext);

    moveItemInArray(felder, nPIDX, nNIDX);
    this.$felder.next(felder);
  }

  dropFeld(idx: number, event: CdkDragDrop<IFeld>) {
    const filtered = this.filtered$.getValue();
    const fKat = filtered[idx].title;
    const fPrevious = filtered[idx].felder[event.previousIndex];
    const fNext = filtered[idx].felder[event.currentIndex];

    const felder = this.$felder.getValue();
    const nKat = felder.find(({ title }) => title === fKat);
    const nKIDX = felder.indexOf(nKat);
    const nPrevious = nKat.felder.find(({ label }) => label === fPrevious.label);
    const nPIDX = nKat.felder.indexOf(nPrevious);
    const nNext = nKat.felder.find(({ label }) => label === fNext.label);
    const nNIDX = nKat.felder.indexOf(nNext);

    moveItemInArray(felder[nKIDX].felder, nPIDX, nNIDX);
    this.$felder.next(felder);
  }
}
