import { NgModule } from '@angular/core';

import {
  PostKopierenCommandServiceModule,
  PostKopierenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostKopierenCommandServiceModule,
    PostKopierenCommandComponentModule
  ],
  exports: [
    PostKopierenCommandServiceModule,
    PostKopierenCommandComponentModule
  ]
})
export class PostKopierenCommandModule { }
