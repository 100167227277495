import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostReferenzenHochladenCommandShapes, IPostReferenzenHochladenRequest, IPostReferenzenHochladenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostReferenzenHochladenCommandService extends PostCommandService<IPostReferenzenHochladenRequest, IPostReferenzenHochladenResponse, IPostReferenzenHochladenCommandShapes> {
  readonly route = 'felder/referenzen-hochladen';

  override async request(payload: IPostReferenzenHochladenRequest): Promise<IPostReferenzenHochladenResponse> {
    return (await this.http.upload<IPostReferenzenHochladenResponse>(this.route, payload, 'feld-referenzen')) as unknown as IPostReferenzenHochladenResponse;
  }
}
