import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { FelderCommandsModule } from './commands';
import { FelderComponentsModule } from './components';
import { FelderPage, FelderPageResolver, FeldPage, FeldPageResolver } from './pages';
import { FelderQuerysModule, FelderService, FeldService } from './querys';
import {
  FelderSettingsModule,
  FeldKategoriesPage,
  FeldKategoriesPageResolver,
  FeldKategoriesService,
  FeldKategoriesServiceModule,
  FeldUnterkategoriesPage,
  FeldUnterkategoriesPageResolver,
  FeldUnterkategoriesService,
  FeldUnterkategoriesServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'felder',
    children: [
      {
        path: 'felder',
        component: FelderPage,
        resolve: {
          querys: FelderPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['felder'],
            roles: ['GET_FELDER', 'POST_REFERENZEN_HOCHLADEN'],
          },
        },
      },
      {
        path: 'feld',
        component: FeldPage,
        resolve: {
          querys: FeldPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['felder'],
            roles: ['GET_FELD', 'DELETE_FELD', 'DELETE_FELD_OPTION', 'POST_FELD', 'POST_FELD_OPTION', 'POST_ORDNE_FELD_OPTIONEN'],
          },
        },
      },
    ],
  },
  {
    path: 'felder',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'feld-kategories',
            component: FeldKategoriesPage,
            resolve: {
              querys: FeldKategoriesPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_FELD_KATEGORIES'],
                  write: ['POST_FELD_KATEGORIE', 'DELETE_FELD_KATEGORIE'],
                },
              },
            },
          },
          {
            path: 'feld-unterkategories',
            component: FeldUnterkategoriesPage,
            resolve: {
              querys: FeldUnterkategoriesPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_FELD_UNTERKATEGORIES'],
                  write: ['POST_FELD_UNTERKATEGORIE', 'DELETE_FELD_UNTERKATEGORIE'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [FelderPage, FeldPage, FeldKategoriesPage, FeldUnterkategoriesPage],
  imports: [
    LibsCommonAngularModule,
    FelderSettingsModule,
    FelderComponentsModule,
    FelderCommandsModule,
    FelderQuerysModule,
    FeldKategoriesServiceModule,
    FeldUnterkategoriesServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [FelderSettingsModule, FelderComponentsModule, FelderCommandsModule, FelderQuerysModule, FeldKategoriesServiceModule, FeldUnterkategoriesServiceModule],
})
export class FelderModule {
  static forRoot(): ModuleWithProviders<FelderModule> {
    return {
      ngModule: FelderModule,
      providers: [FeldService, FelderService, FeldKategoriesService, FeldUnterkategoriesService],
    };
  }
}
