import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostUrlaubCommandShapes, IPostUrlaubRequest, IPostUrlaubResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostUrlaubCommandService extends PostCommandService<IPostUrlaubRequest, IPostUrlaubResponse, IPostUrlaubCommandShapes> {
  route = 'zeiten/urlaub';
}
