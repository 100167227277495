<!--



-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <nz-list nzGrid style="width: 100%" [nzLoading]="!!($loading | async)">
        <nz-row [nzGutter]="16">
          <nz-col [nzSpan]="4" *ngFor="let item of data">
            <nz-list-item>
              <nz-card [nzTitle]="item.title" (click)="item.click()" style="cursor: pointer"> </nz-card>
            </nz-list-item>
          </nz-col>
        </nz-row>
      </nz-list>
    </nz-col>
    <nz-col nzSpan="24" *ngIf="!!($loading | async)">
      <span>{{ $loading | async }}...</span>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
