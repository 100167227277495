import { NgModule } from '@angular/core';

import { DateiArtsSettingsModule, ErforderlichesDokumentsSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [DateiArtsSettingsModule, ErforderlichesDokumentsSettingsModule],
  exports: [DateiArtsSettingsModule, ErforderlichesDokumentsSettingsModule],
})
export class DokumenteSettingsModule {}
