import { Component } from '@angular/core';
import { UrlaubeService } from '.';

@Component({
  selector: 'fa-kt-urlaube-presets',
  template: `
    <pbc-filter-preset
      [filter]="urlaube.preset$ | async"
      [filters]="urlaube.presets$ | async"
      [name]="urlaube.search$ | async"
      (select)="urlaube.setFilter($event)"
      (save)="urlaube.writeFilter($event)"
      (delete)="urlaube.deleteFilter($event)"
    ></pbc-filter-preset>
  `,
})
export class UrlaubePresetsComponent {
  constructor(public urlaube: UrlaubeService) {}
}
