import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { VorlageServiceModule } from '..';
import { VorlageSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlageServiceModule
  ],
  declarations: [VorlageSorterComponent],
  exports: [VorlageSorterComponent],

})
export class VorlageSorterComponentModule { }
