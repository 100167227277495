import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostZeitUnterartCommandShapes, IPostZeitUnterartRequest, IPostZeitUnterartResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostZeitUnterartCommandService extends PostCommandService<IPostZeitUnterartRequest, IPostZeitUnterartResponse, IPostZeitUnterartCommandShapes> {
  route = 'zeiten/settings/zeit-unterart';
  override dependencies = ['zeiten/written-zeit-art', 'zeiten/removed-zeit-art'];

  async getZeitUnterartsZeitArt(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.zeitUnterartsZeitArt.pipe(first()).subscribe((zeitUnterartsZeitArt) => resolve(zeitUnterartsZeitArt.find((a) => a.value === value)));
    });
  }

  get zeitUnterartsZeitArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeitUnterarts.zeitArt'] as ISelection[]) : [])));
  }
}
