import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostProjekteHochzaehlenCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostProjekteHochzaehlenCommandService],
  exports: []
})
export class PostProjekteHochzaehlenCommandServiceModule { }
