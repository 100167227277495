import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { BasePostCommandComponent } from 'pbc.angular';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isEqual } from 'date-fns';
import { alignDates, hasTimeConflict } from 'fa-kt.functions';
import { IBesichtigung, IPostBesichtigungRequest, IPostBesichtigungResponse, ITour } from 'fa-kt.types';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { KonstantesService } from '../../../../einstellungen';
import { PostBesichtigungCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-besichtigung',
  templateUrl: './post-besichtigung.component.html',
  styleUrls: ['./post-besichtigung.component.css'],
})
export class PostBesichtigungCommandComponent extends BasePostCommandComponent<IPostBesichtigungRequest, IPostBesichtigungResponse> implements OnInit, AfterViewInit, OnDestroy {
  description = { context: 'BESICHTIGUNGEN', command: 'POST_BESICHTIGUNG' };
  contentKey: keyof IPostBesichtigungRequest = 'besichtigung';

  @Input() minuten: number = 30;
  @Input() tour: ITour;

  async prepare(): Promise<void> {
    await Promise.all([this.konstanten.request({}), this.postBesichtigung.prepare()]);
  }

  async request(payload: IPostBesichtigungRequest): Promise<IPostBesichtigungResponse> {
    return this.postBesichtigung.request(payload);
  }

  constructor(
    public postBesichtigung: PostBesichtigungCommandService,
    public konstanten: KonstantesService,
  ) {
    super();
    this.form = this.fb.group(
      {
        besichtigung: this.fb.group({
          id: [null, [Validators.required]],
          tour: [null, [Validators.required]],
          besichtigungsStatus: [null, [Validators.required]],
          order: [null, [Validators.required]],
          mitarbeiter: [null, [Validators.required]],
          rueckfahrt: [null, []],

          projekt: [null, []],
          gutachten: [null, []],
          objekt: [null, []],

          start: [],
          fahrtVon: [null, [Validators.required]],
          fahrtBis: [null, [Validators.required]],
          von: [null, [Validators.required]],
          kommuniziertVon: [null, [Validators.required]],
          kommuniziertBis: [null, [Validators.required]],
          bis: [null, [Validators.required]],
          ende: [],

          fahrtstrecke: [null, []],
          fahrtzeit: [null, []],
          fahrtzeitOhneVerkehr: [null, []],

          abgerechnet: [null, []],
          app: [null, []],
          wiedervorlage: [null, []],
          aktion: [null, []],
          kommentar: [null, []],

          route: [null, []],

          fahrtstreckeZumStandort: [null, []],
          fahrtzeitZumStandort: [null, []],
        }),
      },
      {
        validators: [this.createTimeValidator()],
      },
    );
    this.patch();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.form
      .get('besichtigung.kommuniziertVon')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((kommuniziertVon) => {
        if (!kommuniziertVon) return;
        (document.getElementById('_kommuniziertVon') as HTMLInputElement).value = kommuniziertVon;
        const fahrtBis = this.form.get('besichtigung.fahrtBis')?.value;
        if (!fahrtBis) return;
        const aligned = alignDates(new Date(fahrtBis), new Date(kommuniziertVon));
        if (isEqual(aligned, kommuniziertVon)) return;
        this.form.get('besichtigung.kommuniziertVon').patchValue(aligned);
      });
    this.form
      .get('besichtigung.kommuniziertBis')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((kommuniziertBis) => {
        if (!kommuniziertBis) return;
        (document.getElementById('_kommuniziertBis') as HTMLInputElement).value = kommuniziertBis;
        const fahrtBis = this.form.get('besichtigung.fahrtBis')?.value;
        if (!fahrtBis) return;
        const aligned = alignDates(new Date(fahrtBis), new Date(kommuniziertBis));
        if (isEqual(aligned, kommuniziertBis)) return;
        this.form.get('besichtigung.kommuniziertBis').patchValue(aligned);
      });
    this.form
      .get('besichtigung.bis')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe((bis) => {
        if (!bis) return;
        const fahrtBis = this.form.get('besichtigung.fahrtBis')?.value;
        if (!fahrtBis) return;
        const aligned = alignDates(new Date(fahrtBis), new Date(bis));
        if (isEqual(aligned, bis)) return;
        this.form.get('besichtigung.bis').patchValue(aligned);
      });
  }

  ngAfterViewInit(): void {
    this.form.get('besichtigung.kommuniziertVon')?.patchValue(this.form.get('besichtigung.kommuniziertVon')?.value);
    this.form.get('besichtigung.kommuniziertBis')?.patchValue(this.form.get('besichtigung.kommuniziertBis')?.value);
  }

  disabledHours(field: string) {
    return () => {
      const min = (document.getElementById(field) as HTMLInputElement)?.value;
      let hours: number[] = [];
      if (!min) return hours;
      for (let h = 0; h < moment(min).hours(); h++) {
        hours.push(h);
      }
      return hours;
    };
  }

  disabledMinutes(field: string) {
    return (hour: number) => {
      const min = (document.getElementById(field) as HTMLInputElement)?.value;
      let minutes: number[] = [];
      if (!min) return minutes;
      const m = moment(min);
      if (hour === m.hours()) {
        for (let m = 0; m < moment(min).minutes(); m++) {
          minutes.push(m);
        }
      }
      return minutes;
    };
  }

  disabledSeconds(hour: number, minute: number): number[] {
    return [];
  }

  createTimeValidator(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const besichtigung = form?.get('besichtigung')?.value;
      const conflict = hasTimeConflict(besichtigung as IBesichtigung, this.tour);
      if (conflict) {
        const { kommuniziertVonIsBeforeFahrtBis, kommuniziertBisIsBeforeKommuniziertVon, bisIsBeforeKommuniziertBis, noTourMatch, negativerAufenthalt } = conflict;
        form.get('besichtigung.kommuniziertVon').setErrors(noTourMatch || kommuniziertBisIsBeforeKommuniziertVon || kommuniziertVonIsBeforeFahrtBis ? { hasTimeConflict: true } : null);
        form.get('besichtigung.kommuniziertBis').setErrors(noTourMatch || kommuniziertBisIsBeforeKommuniziertVon || bisIsBeforeKommuniziertBis ? { hasTimeConflict: true } : null);
        form.get('besichtigung.bis').setErrors(negativerAufenthalt || noTourMatch || bisIsBeforeKommuniziertBis ? { hasTimeConflict: true } : null);
        form.markAllAsTouched();
        return { hasTimeConflict: true };
      }
      form.get('besichtigung.kommuniziertVon').setErrors(null);
      form.get('besichtigung.kommuniziertBis').setErrors(null);
      form.get('besichtigung.bis').setErrors(null);
      return null;
    };
  }

  takeFahrtBis(minuten = 0) {
    const fahrtBis = this.form.get('besichtigung.fahrtBis')?.value;
    this.form.get('besichtigung.kommuniziertVon')?.patchValue(moment(new Date(fahrtBis)).add(minuten, 'minutes').toDate());
    this.update();
  }

  takeKommuniziertVon(minuten = this.minuten) {
    const kommuniziertVon = this.form.get('besichtigung.kommuniziertVon')?.value;
    this.form.get('besichtigung.kommuniziertBis')?.patchValue(moment(new Date(kommuniziertVon)).add(minuten, 'minutes').toDate());
    this.update();
  }

  takeKommuniziertBis(minuten = 0) {
    const kommuniziertBis = this.form.get('besichtigung.kommuniziertBis')?.value;
    this.form.get('besichtigung.bis')?.patchValue(moment(new Date(kommuniziertBis)).add(minuten, 'minutes').toDate());
    this.update();
  }
}
