import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KonstantesServiceModule } from '..';
import { KonstantesFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KonstantesServiceModule
  ],
  declarations: [KonstantesFilterComponent],
  exports: [KonstantesFilterComponent],

})
export class KonstantesFilterComponentModule { }
