import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BereichsServiceModule } from '..';
import { BereichsFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BereichsServiceModule
  ],
  declarations: [BereichsFilterComponent],
  exports: [BereichsFilterComponent],

})
export class BereichsFilterComponentModule { }
