import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IKundenGruppesResponse } from 'fa-kt.types';
import { PostKundenGruppeCommandService } from '../commands';
import { KundenGruppesService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class KundenGruppesPageResolver implements Resolve<IKundenGruppesResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private kundenGruppes: KundenGruppesService,
    private postKundenGruppe: PostKundenGruppeCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IKundenGruppesResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [kundenGruppes] = await Promise.all([this.kundenGruppes.request({}).catch(log), this.postKundenGruppe.prepare(true).catch(log)]);
    this.loading.resolved();
    return kundenGruppes;
  }
}
