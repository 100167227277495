import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { GutachtenStatiServiceModule } from '..';
import { GutachtenStatiListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenStatiServiceModule
  ],
  declarations: [GutachtenStatiListComponent],
  exports: [GutachtenStatiListComponent],

})
export class GutachtenStatiListComponentModule { }
