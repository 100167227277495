import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { MitarbeiterServiceModule } from '..';
import { SavedMitarbeiterFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    MitarbeiterServiceModule
  ],
  declarations: [SavedMitarbeiterFiltersComponent],
  exports: [SavedMitarbeiterFiltersComponent],

})
export class SavedMitarbeiterFiltersComponentModule { }
