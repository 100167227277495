import { IFilter, IFilterConfig, ISelection, IShape, ISingleReferenceFilter, SingleReferenceFilterConfig } from 'pbc.types';

import { IFeldKategorie } from 'fa-kt.types';
import { IFeldUnterkategoriesResponse } from '.';

export interface IFeldUnterkategoriesQueryFilter extends IFilter<IFeldUnterkategoriesResponse> {
  'feldUnterkategories.feldKategorie'?: ISingleReferenceFilter<IFeldKategorie>;
}

export const FeldUnterkategoriesQueryFilterConfig: IFilterConfig<IFeldUnterkategoriesResponse> = [
  SingleReferenceFilterConfig<IFeldKategorie>('feldUnterkategories.feldKategorie', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.FELD_KATEGORIE.title'),
];

export interface IFeldUnterkategoriesQueryShapes extends IShape {
  'feldUnterkategories.feldKategorie': ISelection[];
}

export type IFeldUnterkategoriesQueryField =
  | 'feldUnterkategories.id'
  | 'feldUnterkategories.feldKategorie'
  | 'feldUnterkategories.name'
  | 'feldUnterkategories.initialen'
  | 'feldUnterkategories.kommentar'
  | '';
