import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostTemplateCommandServiceModule } from '..';
import { PostTemplateCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostTemplateCommandServiceModule
  ],
  declarations: [PostTemplateCommandComponent],
  exports: [PostTemplateCommandComponent],

})
export class PostTemplateCommandComponentModule { }
