import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SettingsQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IZeitUnterartsQueryFilter, IZeitUnterartsQueryShapes, IZeitUnterartsQuerySorter, IZeitUnterartsRequest, IZeitUnterartsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ZeitUnterartsService extends SettingsQueryService<IZeitUnterartsRequest, IZeitUnterartsResponse, IZeitUnterartsQueryShapes, IZeitUnterartsQueryFilter, IZeitUnterartsQuerySorter> {
  contentKey: keyof IZeitUnterartsResponse = 'zeitUnterarts';
  route = 'zeiten/settings/zeit-unterarts';
  override dependencies = ['zeiten/written-zeit-unterart', 'zeiten/removed-zeit-unterart', 'zeiten/written-zeit-art', 'zeiten/removed-zeit-art'];

  static instance: ZeitUnterartsService;

  constructor() {
    super();
    if (!ZeitUnterartsService.instance) ZeitUnterartsService.instance = this;
    return ZeitUnterartsService.instance;
  }

  async getZeitUnterartsZeitArt(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.zeitUnterartsZeitArt.pipe(first()).subscribe((zeitUnterartsZeitArt) => resolve(zeitUnterartsZeitArt.find((a) => a.value === value)));
    });
  }

  get zeitUnterartsZeitArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeitUnterarts.zeitArt'] as ISelection[]) : [])));
  }
}
