<nz-spin [nzSpinning]="$loading | async">
  <nz-input-group [nzSuffix]="suffixIcon">
    <input type="text" nz-input placeholder="Suche" [(ngModel)]="searchValue" />
  </nz-input-group>
  <ng-template #suffixIcon>
    <span nz-icon nzType="search"></span>
  </ng-template>

  <nz-tree
    [style.margin]="'24px'"
    [nzData]="nodes"
    [nzSearchValue]="searchValue"
    nzShowIcon
    nzAsyncData
    (nzExpandChange)="nzEvent($event)"
    (nzClick)="open($event)"
  ></nz-tree>
</nz-spin>
