import { NgModule } from '@angular/core';

import {
  DeleteKundenGruppeCommandServiceModule,
  DeleteKundenGruppeCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteKundenGruppeCommandServiceModule,
    DeleteKundenGruppeCommandComponentModule
  ],
  exports: [
    DeleteKundenGruppeCommandServiceModule,
    DeleteKundenGruppeCommandComponentModule
  ]
})
export class DeleteKundenGruppeCommandModule { }
