import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IObjekteQuerySorter extends ISorter {
  'objekt.id'?: ISorterExpression;
  'objekt.name'?: ISorterExpression;
  'objekt.addresse'?: ISorterExpression;
  'objekt.bereiche'?: ISorterExpression;
  'objekt.anspracheOutlookKontakts'?: ISorterExpression;
  'objekt.klingelschild'?: ISorterExpression;
  'objekt.kommentar'?: ISorterExpression;
}

export const ObjekteQuerySorterConfig: ISorterConfig = [
  SorterConfig('objekt.id', 'OBJEKTE.Models.OBJEKT.Propertys.ID.title'), 
  SorterConfig('objekt.name', 'OBJEKTE.Models.OBJEKT.Propertys.NAME.title'), 
  SorterConfig('objekt.addresse', 'OBJEKTE.Models.OBJEKT.Propertys.ADDRESSE.title'), 
  SorterConfig('objekt.bereiche', 'OBJEKTE.Models.OBJEKT.Propertys.BEREICHE.title'), 
  SorterConfig('objekt.anspracheOutlookKontakts', 'OBJEKTE.Models.OBJEKT.Propertys.ANSPRACHE_OUTLOOK_KONTAKTS.title'), 
  SorterConfig('objekt.klingelschild', 'OBJEKTE.Models.OBJEKT.Propertys.KLINGELSCHILD.title'), 
  SorterConfig('objekt.kommentar', 'OBJEKTE.Models.OBJEKT.Propertys.KOMMENTAR.title'), 
];
