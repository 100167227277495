import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektServiceModule } from '..';
import { SavedObjektFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektServiceModule
  ],
  declarations: [SavedObjektFiltersComponent],
  exports: [SavedObjektFiltersComponent],

})
export class SavedObjektFiltersComponentModule { }
