import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IVorlagenPageResolverResult } from '.';
import { VorlagenService } from '../../querys';

@Component({
  selector: 'fa-kt-vorlagen-page',
  templateUrl: './vorlagen.page.html',
  styleUrls: ['./vorlagen.page.css'],
})
export class VorlagenPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public _vorlage: ISitemapPage;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public vorlagen: VorlagenService,
  ) {
    this.page = this.sitemap.TEXTE.Pages.VORLAGEN;
    this._vorlage = sitemap.TEXTE.Pages.VORLAGE;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {}),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IVorlagenPageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setVorlagen(id: string) {
    /* << set-vorlagen    */
    await this.navigateToVorlage({ id });
    /*    set-vorlagen >> */
  }

  public async navigateToVorlage(queryParams: Params = {}) {
    await this.router.navigate(this._vorlage.url, { queryParams });
  }

  back() {
    window.history.back();
  }
}
