import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { VorlagenServiceModule } from '..';
import { SavedVorlagenFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlagenServiceModule
  ],
  declarations: [SavedVorlagenFiltersComponent],
  exports: [SavedVorlagenFiltersComponent],

})
export class SavedVorlagenFiltersComponentModule { }
