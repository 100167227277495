import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'check'
})
export class CheckPipe implements PipeTransform {

  constructor() {
  }

  transform(items: any|any[], key: string, mode: 'some'|'every' = 'some'): boolean {
    if (!items || !key) { return false; }
    switch (mode) {
      case "every":
        return (Array.isArray(items) ? items : [items]).map(item => item[key]).every(result => result);
      case "some":
        return (Array.isArray(items) ? items : [items]).map(item => item[key]).some(result => result);
    }
  }

}
