import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { SavedZeitFiltersComponent } from '.';
import { ZeitServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitServiceModule
  ],
  declarations: [SavedZeitFiltersComponent],
  exports: [SavedZeitFiltersComponent],

})
export class SavedZeitFiltersComponentModule { }
