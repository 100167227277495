import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ITourenRequest } from 'fa-kt.types';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IReisekostenabrechnungenRequest, IReisekostenabrechnungenResponse } from 'fa-kt.types';
import { PostReisekostenabrechnungCommandService } from '../../..';
import { ReisekostenabrechnungenService } from '../../querys';

export interface IFinanzenPageResolverResult {
  reisekostenabrechnungen?: IReisekostenabrechnungenResponse | void;
  // touren?: ITourenResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class FinanzenPageResolver implements Resolve<IFinanzenPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private reisekostenabrechnungen: ReisekostenabrechnungenService,
    // private touren: TourenService,
    private postReisekostenabrechnung: PostReisekostenabrechnungCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IFinanzenPageResolverResult> {
    this.loading.resolving();
    const requests = {
      reisekostenabrechnungen: {} as IReisekostenabrechnungenRequest,
      touren: {} as ITourenRequest,
    };
    requests.reisekostenabrechnungen.jahr = new Date().getFullYear();
    if (!this.auth.$isAdmin.getValue()) requests.reisekostenabrechnungen.mitarbeiter = this.auth.$id.getValue();
    const log = (error: any) => console.error(error);
    const [reisekostenabrechnungen] = await Promise.all([this.reisekostenabrechnungen.request(requests.reisekostenabrechnungen).catch(log), this.postReisekostenabrechnung.prepare().catch(log)]);
    this.loading.resolved();
    return {
      reisekostenabrechnungen,
    };
  }
}
