<nz-space>
  <nz-avatar-group *nzSpaceItem style="min-width: 164px">
    <nz-avatar
      nzSize="small"
      *ngFor="let user of selected$ | async"
      [nzSrc]="user.avatar!"
      [nzText]="getMitarbeiterText(user.label, true)"
      nz-tooltip
      [nzTooltipTitle]="user.label"
      (click)="removeUser(user.value!)"
      style="cursor: no-drop"
    ></nz-avatar>
  </nz-avatar-group>
  <ng-container *ngIf="!disabled">
    <pbc-select-value *nzSpaceItem [(value)]="user" (valueChange)="addUser($event)" [shapes]="possible$ | async" [width]="'200px'" [placeholder]="placeholder" [disabled]="disabled"></pbc-select-value>
    <pbc-select-value
      *nzSpaceItem
      [(value)]="group"
      (valueChange)="addGroup($event)"
      [shapes]="meta.groups$ | async"
      [width]="'200px'"
      [placeholder]="placeholderGroups"
      [disabled]="disabled"
    ></pbc-select-value>
  </ng-container>
</nz-space>
<!-- style="display: inline-block; margin: 4px" -->
