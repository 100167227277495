import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IProjektSorter extends ISorter {
  id?: ISorterExpression;
  bezeichnung?: ISorterExpression;
  nummer?: ISorterExpression;
  projektZaehler?: ISorterExpression;
  projektArt?: ISorterExpression;
  projektStatus?: ISorterExpression;
  standort?: ISorterExpression;
  beauftragung?: ISorterExpression;
  deadline?: ISorterExpression;
  kundenAnfertigung?: ISorterExpression;
  keineBesichtigungErforderlich?: ISorterExpression;
  besichtigungen?: ISorterExpression;
  gutachten?: ISorterExpression;
  pruefung?: ISorterExpression;
  kunde?: ISorterExpression;
  abteilung?: ISorterExpression;
  fond?: ISorterExpression;
  bank?: ISorterExpression;
  objektArt?: ISorterExpression;
  nachlass?: ISorterExpression;
  kundenZeichen?: ISorterExpression;
  kontakteInOutlook?: ISorterExpression;
  dateiVerzeichnis?: ISorterExpression;
  kommentar?: ISorterExpression;
  notizenFuerRechnung?: ISorterExpression;
  fortschritt?: ISorterExpression;
  // strecke?: ISorterExpression;
  // kalkulierteStrecke?: ISorterExpression;
  // fahrtzeit?: ISorterExpression;
  // kalkulierteFahrtzeit?: ISorterExpression;
  stunden?: ISorterExpression;
  kostenNachStunden?: ISorterExpression;
  verhandeltesHonorar?: ISorterExpression;
  kosten?: ISorterExpression;
  kostenBeglichen?: ISorterExpression;
  aktiv?: ISorterExpression;
  darlehensnehmer?: ISorterExpression;
  notizenBesichtigung?: ISorterExpression;
  kreditsachbearbeiterVorname?: ISorterExpression;
  kreditsachbearbeiterName?: ISorterExpression;
  kreditsachbearbeiterTelefon?: ISorterExpression;
  kreditsachbearbeiterEMail?: ISorterExpression;
}

export const ProjektSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('bezeichnung', 'Bezeichnung'),
  SorterConfig('nummer', 'Nummer'),
  SorterConfig('projektZaehler', 'Projekt Zaehler'),
  SorterConfig('projektArt', 'Projekt Art'),
  SorterConfig('projektStatus', 'Projekt Status'),
  SorterConfig('standort', 'Standort'),
  SorterConfig('beauftragung', 'Beauftragung'),
  SorterConfig('deadline', 'Deadline'),
  SorterConfig('kundenAnfertigung', 'Kunden Anfertigung'),
  SorterConfig('keineBesichtigungErforderlich', 'Keine Besichtigung Erforderlich'),
  SorterConfig('besichtigungen', 'Besichtigungen'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('pruefung', 'Pruefung'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('abteilung', 'Abteilung'),
  SorterConfig('fond', 'Fond'),
  SorterConfig('bank', 'Bank'),
  SorterConfig('objektArt', 'Objekt Art'),
  SorterConfig('nachlass', 'Nachlass'),
  SorterConfig('kundenZeichen', 'Kunden Zeichen'),
  SorterConfig('kontakteInOutlook', 'Kontakte In Outlook'),
  SorterConfig('dateiVerzeichnis', 'Datei Verzeichnis'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('notizenFuerRechnung', 'Notizen Fuer Rechnung'),
  SorterConfig('fortschritt', 'Fortschritt'),
  // SorterConfig('strecke', 'Strecke'),
  // SorterConfig('kalkulierteStrecke', 'Kalkulierte Strecke'),
  // SorterConfig('fahrtzeit', 'Fahrtzeit'),
  // SorterConfig('kalkulierteFahrtzeit', 'Kalkulierte Fahrtzeit'),
  SorterConfig('stunden', 'Stunden'),
  SorterConfig('kostenNachStunden', 'Kosten Nach Stunden'),
  SorterConfig('verhandeltesHonorar', 'Verhandeltes Honorar'),
  SorterConfig('kosten', 'Kosten'),
  SorterConfig('kostenBeglichen', 'Kosten Beglichen'),
  SorterConfig('aktiv', 'Aktiv'),
  SorterConfig('darlehensnehmer', 'Darlehensnehmer'),
  SorterConfig('notizenBesichtigung', 'Notizen Besichtigung'),
  SorterConfig('kreditsachbearbeiterVorname', 'Kreditsachbearbeiter Vorname'),
  SorterConfig('kreditsachbearbeiterName', 'Kreditsachbearbeiter Name'),
  SorterConfig('kreditsachbearbeiterTelefon', 'Kreditsachbearbeiter Telefon'),
  SorterConfig('kreditsachbearbeiterEMail', 'Kreditsachbearbeiter E Mail'),
];
