import { NgModule } from '@angular/core';
import {
} from '.';

@NgModule({
  imports: [
  ],
  exports: [
  ],
})
export class OrtKategoriesQueryModule { }