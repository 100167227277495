import { Pipe, PipeTransform } from '@angular/core';

import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { max, min } from 'lodash';
import { ISelection, ISorter } from 'pbc.types';
import { PipeLoadingService } from '../services';

@Pipe({
  name: 'sorter',
})
export class SorterPipe implements PipeTransform {
  constructor(private loading: PipeLoadingService) {}

  transform(items?: Array<any>, sorter?: ISorter, shapes: any | null = null): any {
    if (!items || items.length === 0) {
      return [];
    }
    if (!sorter) {
      return items;
    }
    this.loading.loading();
    for (const [key, value] of Object.entries(sorter).reverse()) {
      if (value) {
        if (key === 'besichtigungen.von')
          items = sortBy(items, [(item) => this.resolve(key, item, shapes, value.ascending) || this.resolve('gutachten.datum_besichtigung', item, shapes, value.ascending)]);
        else items = sortBy(items, [(item) => (key !== 'id' ? this.resolve(key, item, shapes, value.ascending) : item[key])]);
        if (!value.ascending) items = items.reverse();
      }
    }
    this.loading.loaded();
    return items;
  }

  private resolve(key: string, item: string, shapes: any, ascending: boolean): string | number | null | undefined {
    if (!item || !key) {
      return item;
    }
    const value = get(item, key, null);
    if (!value) {
      const paths = key.split('.');
      const items = get(item, paths.shift() as string);
      key = paths.join('.');
      const results = (items && items.map ? items : []).map((item: any) => this.resolve(key, item, shapes, ascending));
      if (ascending) {
        return max(results) as number;
      } else {
        return min(results) as number;
      }
    }
    // console.debug(key, value, ascending);
    const selection = get(shapes, key, null);
    if (!selection) {
      return value;
    }
    const shaped: ISelection = selection.find((shape: ISelection) => shape.value === value);
    if (!shaped) {
      return value;
    }
    if (shaped.order || shaped.order === 0) {
      return shaped.order;
    }
    return shaped.label;
  }
}
