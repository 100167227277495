import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ZeitenListComponent } from '.';
import { ZeitenServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitenServiceModule
  ],
  declarations: [ZeitenListComponent],
  exports: [ZeitenListComponent],

})
export class ZeitenListComponentModule { }
