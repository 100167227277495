import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DurchsuchenServiceModule } from '..';
import { DurchsuchenListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DurchsuchenServiceModule
  ],
  declarations: [DurchsuchenListComponent],
  exports: [DurchsuchenListComponent],

})
export class DurchsuchenListComponentModule { }
