import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface ITourSorter extends ISorter {
  id?: ISorterExpression;
  tourStatus?: ISorterExpression;
  mitarbeiter?: ISorterExpression;
  am?: ISorterExpression;
  startIstPrivatAdresse?: ISorterExpression;
  endeIstPrivatAdresse?: ISorterExpression;
  start?: ISorterExpression;
  ende?: ISorterExpression;
  strecke?: ISorterExpression;
  kosten?: ISorterExpression;
  abgerechnet?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const TourSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('tourStatus', 'Tour Status'),
  SorterConfig('mitarbeiter', 'Mitarbeiter'),
  SorterConfig('am', 'Am'),
  SorterConfig('startIstPrivatAdresse', 'Start Ist Privat Adresse'),
  SorterConfig('endeIstPrivatAdresse', 'Ende Ist Privat Adresse'),
  SorterConfig('start', 'Start'),
  SorterConfig('ende', 'Ende'),
  SorterConfig('strecke', 'Strecke'),
  SorterConfig('kosten', 'Kosten'),
  SorterConfig('abgerechnet', 'Abgerechnet'),
  SorterConfig('kommentar', 'Kommentar')
];
