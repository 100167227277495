import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, cleanObject, CustomFormatter, CustomValidators, ISitemap, ISitemapCommand, ISitemapModel, SITEMAP } from 'pbc.angular';

import { IKonstante, IPostKonstanteResponse } from 'fa-kt.types';
import { KonstantesService } from '../../../query';
import { PostKonstanteCommandService } from '../service';

interface IFormValue {
  konstante?: IKonstante;
}

@Component({
  selector: 'fa-kt-post-konstante',
  templateUrl: './post-konstante.component.html',
  styleUrls: ['./post-konstante.component.css'],
})
export class PostKonstanteCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;
  model!: ISitemapModel;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IFormValue) {
    this.patch(value);
  }
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostKonstanteResponse> = new EventEmitter<IPostKonstanteResponse>();

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private actions: ActionService,
    private message: NzMessageService,
    public konstantes: KonstantesService,
    public postKonstante: PostKonstanteCommandService /* << dependencies    */ /*    dependencies >> */,
  ) {
    this.command = sitemap.EINSTELLUNGEN.Commands.POST_KONSTANTE;
    this.model = sitemap.EINSTELLUNGEN.Models.KONSTANTE;
    /* << constructor    */
    /*    constructor >> */
    this.form = this.fb.group({
      konstante: this.fb.group({
        id: [null, []],
        art: [null, [Validators.required]],
        inhalt: [null, [Validators.required]],
        kommentar: [null, []],
      }),
    });
    this.art?.setValidators([Validators.required, CustomValidators.isUnique(this.konstantes.artIsUnique(this.id))]);
    this.patch();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.form.valid);
        if (this.form.valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.id?.valueChanges.subscribe(async (id) => {
        /* << on-id-change    */
        /*    on-id-change >> */
      }) as Subscription,
      this.art?.valueChanges.subscribe(async (art) => {
        /* << on-art-change    */
        /*    on-art-change >> */
      }) as Subscription,
      this.inhalt?.valueChanges.subscribe(async (inhalt) => {
        /* << on-inhalt-change    */
        /*    on-inhalt-change >> */
      }) as Subscription,
      this.kommentar?.valueChanges.subscribe(async (kommentar) => {
        /* << on-kommentar-change    */
        /*    on-kommentar-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IFormValue) {
    let konstante = {
      id: null,
      art: null,
      inhalt: null,
      kommentar: null,
    };
    if (value && value.konstante) {
      konstante = {
        ...konstante,
        ...cleanObject(value.konstante),
      };
    }
    this.form.patchValue({ konstante });
    if (konstante.id) this.art?.disable();
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload && payload.konstante) {
        payload.konstante = cleanObject(payload.konstante);
      }
      const response: IPostKonstanteResponse | undefined = await this.postKonstante.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls.objektArt.get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get id() {
    return this.form.controls.konstante.get('id');
  }

  get art() {
    return this.form.controls.konstante.get('art');
  }

  get inhalt() {
    return this.form.controls.konstante.get('inhalt');
  }

  get kommentar() {
    return this.form.controls.konstante.get('kommentar');
  }
}
