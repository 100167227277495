<cdk-virtual-scroll-viewport *ngIf="reisekostenabrechnungen.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (reisekostenabrechnungen.result$ | async).reisekostenabrechnungen" (click)="set(entry)">
      <nz-list-item-meta>
        <nz-list-item-meta-avatar>
          <nz-avatar
            [nzSrc]="entry.reisekostenabrechnung.mitarbeiter | type: (meta.users$ | async) : 'avatar' | safe: 'url'"
            [nzText]="entry.reisekostenabrechnung.mitarbeiter | type: (meta.users$ | async) : 'initialen'"
            nz-tooltip
            [nzTooltipTitle]="entry.reisekostenabrechnung.mitarbeiter | type: (meta.users$ | async) : 'label'"
          ></nz-avatar>
        </nz-list-item-meta-avatar>
        <nz-list-item-meta-title>
          <a>
            {{ entry.reisekostenabrechnung.bezeichnung }}
            <nz-divider nzType="vertical"></nz-divider>
            {{ entry.reisekostenabrechnung.reisekostenabrechnungStatus | type: (reisekostenabrechnungen.reisekostenabrechnungReisekostenabrechnungStatus | async) }}
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-typography nzType="secondary">
              {{ isVeranstaltung(entry.reisekostenabrechnung) ? 'Veranstaltung' : (entry.reisekostenabrechnung.normaleKm | number: '1.0-2' : 'de') + ' km' }}
            </span>
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <!--- << extra    -->
          <!---    extra >> -->
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
