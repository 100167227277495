<!--

  <fa-kt-post-vermerk [style.width]="'100%'" [value]="$postVermerk | async" (finished)="finishedPostVermerk($event)"></fa-kt-post-vermerk>

  <fa-kt-delete-vermerk [style.width]="'100%'" [value]="$deleteVermerk | async" (finished)="finishedDeleteVermerk($event)"></fa-kt-delete-vermerk>



-->
<!--- << custom    -->
<nz-divider id="vermerke" nzText="Vermerke" nzOrientation="left"></nz-divider>
<nz-list *ngIf="_vermerke" nzSize="large" [nzLoading]="$loading | async">
  <nz-list-item *ngIf="!disabled && !($postVermerk | async)?.vermerk?.id">
    <fa-kt-post-vermerk [style.width]="'100%'" [value]="$postVermerk | async" (finished)="finishedPostVermerk($event)"></fa-kt-post-vermerk>
  </nz-list-item>
  <nz-list-item *ngFor="let vermerk of _vermerke" [ngSwitch]="($postVermerk | async)?.vermerk?.id === vermerk.id">
    <nz-row *ngSwitchCase="true">
      <nz-col nzOffset="2">
        <nz-button-group>
          <button type="button" nz-button nzType="dashed" (click)="openVermerk({})">
            <i nz-icon nzType="close-circle" nzTheme="outline"></i>
            Abbrechen
          </button>
          <fa-kt-delete-vermerk [style.width]="'100%'" [value]="{ id: vermerk.id }" (finished)="finishedDeleteVermerk($event)"></fa-kt-delete-vermerk>
        </nz-button-group>
      </nz-col>
      <nz-col nzSpan="24">
        <fa-kt-post-vermerk [style.width]="'100%'" [value]="$postVermerk | async" (finished)="finishedPostVermerk($event)"></fa-kt-post-vermerk>
      </nz-col>
    </nz-row>
    <ng-container *ngSwitchDefault>
      <nz-comment [style.cursor]="vermerk.editable ? 'pointer' : '' " (click)="openVermerk(vermerk)" [nzAuthor]="postVermerk.getVermerkVerfasser(vermerk.verfasser)?.label" [nzDatetime]="vermerk.datetime + ' (' + (vermerk._createdAt | date:'dd.MM.yyyy')+ ')'">
        <nz-avatar nz-comment-avatar [nzText]="getMitarbeiterText(postVermerk.getVermerkVerfasser(vermerk.verfasser)?.label)"  [nzSrc]="vermerk.verfasser | type:(postVermerk.vermerkVerfasser | async):'avatar'" nz-tooltip [nzTooltipTitle]="postVermerk.getVermerkVerfasser(vermerk.verfasser)?.label"></nz-avatar>
        <nz-comment-content>
          <p>
            {{ vermerk.kommentar }}
          </p>
        </nz-comment-content>
        <nz-comment-action>
          <label nz-checkbox [ngModel]="vermerk.externSichtbar" nzDisabled>Extern Sichtbar</label>
        </nz-comment-action>
      </nz-comment>
    </ng-container>
  </nz-list-item>
</nz-list>
<!---    custom >> -->
