import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  GeoRangeFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IFilter,
  IFilterConfig,
  IGeoRangeFilter,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IRolle, IStandort } from 'fa-kt.types';
import { IProfilResponse } from '.';

export interface IProfilQueryFilter extends IFilter<IProfilResponse> {
  'mitarbeiter.addresse'?: IGeoRangeFilter;
  'mitarbeiter.standort'?: ISingleReferenceFilter<IStandort>;
  'mitarbeiter.rollen'?: IArrayReferenceFilter<IRolle>;
  'mitarbeiter.stundenlohn'?: INumberFilter;
  'mitarbeiter.inaktiv'?: IBooleanFilter;
  'mitarbeiter.isAdmin'?: IBooleanFilter;
}

export const ProfilQueryFilterConfig: IFilterConfig<IProfilResponse> = [
  GeoRangeFilterConfig('mitarbeiter.addresse', 'PERSONEN.Models.MITARBEITER.Propertys.ADDRESSE.title'),
  SingleReferenceFilterConfig<IStandort>('mitarbeiter.standort', 'PERSONEN.Models.MITARBEITER.Propertys.STANDORT.title'),
  ArrayReferenceFilterConfig<IRolle>('mitarbeiter.rollen', 'PERSONEN.Models.MITARBEITER.Propertys.ROLLEN.title'),
  NumberFilterConfig('mitarbeiter.stundenlohn', 'PERSONEN.Models.MITARBEITER.Propertys.STUNDENLOHN.title'),
  BooleanFilterConfig('mitarbeiter.inaktiv', 'PERSONEN.Models.MITARBEITER.Propertys.INAKTIV.title'),
  BooleanFilterConfig('mitarbeiter.isAdmin', 'Administrator'),
];

export interface IProfilQueryShapes extends IShape {
  'mitarbeiter.standort': ISelection[];
  'mitarbeiter.rollen': ISelection[];
}

export type IProfilQueryField =
  | 'mitarbeiter.id'
  | 'mitarbeiter.email'
  | 'mitarbeiter.anzeigename'
  | 'mitarbeiter.bild'
  | 'mitarbeiter.addresse'
  | 'mitarbeiter.vorname'
  | 'mitarbeiter.nachname'
  | 'mitarbeiter.telefon'
  | 'mitarbeiter.standort'
  | 'mitarbeiter.rollen'
  | 'mitarbeiter.stundenlohn'
  | 'mitarbeiter.inaktiv'
  | '';
