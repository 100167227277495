import { NgModule } from '@angular/core';

import { FilterPipe, LIB_CommonModule, SearchPipe, SorterPipe } from 'pbc.angular';

import { ObjektArtsService } from '.';

@NgModule({
  imports: [LIB_CommonModule],
  providers: [ObjektArtsService, SearchPipe, FilterPipe, SorterPipe],
  exports: [],
})
export class ObjektArtsServiceModule {}
