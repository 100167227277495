import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IRechnungsVorlagenQuerySorter extends ISorter {
  'rechnungsVorlage.id'?: ISorterExpression;
  'rechnungsVorlage.name'?: ISorterExpression;
  'rechnungsVorlage.kommentar'?: ISorterExpression;
  'rechnungsVorlage.datei'?: ISorterExpression;
}

export const RechnungsVorlagenQuerySorterConfig: ISorterConfig = [
  SorterConfig('rechnungsVorlage.id', 'FINANZEN.Models.RECHNUNGS_VORLAGE.Propertys.ID.title'),
  SorterConfig('rechnungsVorlage.name', 'FINANZEN.Models.RECHNUNGS_VORLAGE.Propertys.NAME.title'),
  SorterConfig('rechnungsVorlage.kommentar', 'FINANZEN.Models.RECHNUNGS_VORLAGE.Propertys.KOMMENTAR.title'),
  SorterConfig('rechnungsVorlage.datei', 'FINANZEN.Models.RECHNUNGS_VORLAGE.Propertys.DATEI.title'),
];
