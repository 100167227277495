import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteAbschnittRequest, IDeleteAbschnittResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteAbschnittCommandService extends DeleteCommandService<IDeleteAbschnittRequest, IDeleteAbschnittResponse> {
  readonly route = 'texte/abschnitt';
}
