import { NgModule } from '@angular/core';

import { ProjektArtsSettingsModule, ProjektStatiSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [ProjektArtsSettingsModule, ProjektStatiSettingsModule],
  exports: [ProjektArtsSettingsModule, ProjektStatiSettingsModule],
})
export class ProjekteSettingsModule {}
