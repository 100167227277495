import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostEintragCommandShapes, IPostEintragRequest, IPostEintragResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostEintragCommandService extends PostCommandService<IPostEintragRequest, IPostEintragResponse, IPostEintragCommandShapes> {
  readonly route = 'gutachten/eintrag';

  getEintragFeld(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['eintrag.feld']?.find((v) => v.value === value);
  }

  get eintragFeld(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['eintrag.feld'] as ISelection[]) : [])));
  }

  getEintragGutachten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['eintrag.gutachten']?.find((v) => v.value === value);
  }

  get eintragGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['eintrag.gutachten'] as ISelection[]) : [])));
  }
}
