import { Injectable } from '@angular/core';

import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject } from 'rxjs';

import { DevicePerformance, IDeviceConfig } from '../../types';

@Injectable({
  providedIn: 'root',
})
export class DeviceConfigService {
  readonly debounceTimes = { S: 500, M: 250, L: 10 };
  private readonly DEVICE_CONFIG_KEY = 'deviceConfig';
  readonly fallbackDeviceConfig: IDeviceConfig = { devicePerformance: DevicePerformance.L, alwaysRefresh: false };
  readonly $deviceConfig: BehaviorSubject<IDeviceConfig | null> = new BehaviorSubject<IDeviceConfig>(this.fallbackDeviceConfig);
  readonly $saving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private messages: NzMessageService) {
    this.readExistingDeviceConfig();
  }

  public get deviceConfigPerformance(): DevicePerformance {
    const devicePerformance = this.$deviceConfig.getValue()?.devicePerformance;
    return devicePerformance ? devicePerformance : this.fallbackDeviceConfig.devicePerformance;
  }

  get debounceTime(): number {
    const deviceConfig = this.$deviceConfig.getValue();
    if (!deviceConfig || !DevicePerformance[deviceConfig.devicePerformance]) return 0;
    return this.debounceTimes[DevicePerformance[deviceConfig.devicePerformance] as 'S' | 'M' | 'L'] as number;
  }

  get alwaysRefresh(): boolean {
    const deviceConfig = this.$deviceConfig.getValue();
    if (!deviceConfig) return true;
    return deviceConfig.alwaysRefresh;
  }

  private readExistingDeviceConfig(): IDeviceConfig {
    const result = localStorage.getItem(this.DEVICE_CONFIG_KEY);
    const deviceConfig: IDeviceConfig = result ? (JSON.parse(result) as IDeviceConfig) : this.fallbackDeviceConfig;
    this.$deviceConfig.next(deviceConfig);
    return deviceConfig;
  }

  private writeDeviceConfig(deviceConfig: IDeviceConfig) {
    this.$saving.next(true);
    localStorage.setItem(this.DEVICE_CONFIG_KEY, JSON.stringify(deviceConfig));
    this.$deviceConfig.next(deviceConfig);
    this.$saving.next(false);
  }

  public writeDeviceConfigPerformance(devicePerformance: DevicePerformance): void {
    this.$saving.next(true);
    let deviceConfig = this.$deviceConfig.getValue();
    deviceConfig = deviceConfig ? deviceConfig : this.fallbackDeviceConfig;
    deviceConfig.devicePerformance = devicePerformance;
    this.writeDeviceConfig(deviceConfig);
    this.messages.success(`Geräte-Performance nutzt jetzt ${DevicePerformance[devicePerformance]}`);
    this.$saving.next(false);
  }

  public writeDeviceConfigAlwaysRefresh(alwaysRefresh: boolean): void {
    this.$saving.next(true);
    let deviceConfig = this.$deviceConfig.getValue();
    deviceConfig = deviceConfig ? deviceConfig : this.fallbackDeviceConfig;
    deviceConfig.alwaysRefresh = alwaysRefresh;
    this.writeDeviceConfig(deviceConfig);
    this.messages.success(`Aktualisierungen werden ${alwaysRefresh ? 'aktuell' : 'effizient'} geladen`);
    this.$saving.next(false);
  }
}
