import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IObjektArt } from 'fa-kt.types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PostObjektArtCommandService } from '../../commands';
import { ObjektArtsService } from '../service';

@Component({
  selector: 'fa-kt-objekt-arts-list',
  templateUrl: './objekt-arts-list.component.html',
  styleUrls: ['./objekt-arts-list.component.css'],
})
export class ObjektArtsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IObjektArt> = new EventEmitter<IObjektArt>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public objektArts: ObjektArtsService,
    public objektArtPost: PostObjektArtCommandService,
  ) {
    this.sortable$ = this.objektArts.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const objektArts = this.objektArts.result$.getValue();
    if (objektArts && objektArts.objektArts) {
      if ($event) {
        moveItemInArray(objektArts.objektArts, $event.previousIndex, $event.currentIndex);
      }
      objektArts.objektArts = objektArts.objektArts.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.objektArtPost.request({ objektArt: objektArts.objektArts });
    }
  }
}
