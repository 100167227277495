import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import {
  IReisekostenabrechnungenQueryFilter,
  IReisekostenabrechnungenQueryShapes,
  IReisekostenabrechnungenQuerySorter,
  IReisekostenabrechnungenRequest,
  IReisekostenabrechnungenResponse,
} from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ReisekostenabrechnungenService extends ListQueryService<
  IReisekostenabrechnungenRequest,
  IReisekostenabrechnungenResponse,
  IReisekostenabrechnungenQueryShapes,
  IReisekostenabrechnungenQueryFilter,
  IReisekostenabrechnungenQuerySorter
> {
  contentKey: keyof IReisekostenabrechnungenResponse = 'reisekostenabrechnungen';
  route = 'finanzen/reisekostenabrechnungen';
  override dependencies = [
    'finanzen/written-reisekostenabrechnung-status',
    'finanzen/removed-reisekostenabrechnung-status',

    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
  ];

  static instance: ReisekostenabrechnungenService;

  constructor() {
    super();
    if (!ReisekostenabrechnungenService.instance) ReisekostenabrechnungenService.instance = this;
    return ReisekostenabrechnungenService.instance;
  }

  getReisekostenabrechnungMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenabrechnung.mitarbeiter']?.find((v) => v.value === value);
  }

  get reisekostenabrechnungMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenabrechnung.mitarbeiter'] as ISelection[]) : [])));
  }

  getReisekostenabrechnungReisekostenabrechnungStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenabrechnung.reisekostenabrechnungStatus']?.find((v) => v.value === value);
  }

  get reisekostenabrechnungReisekostenabrechnungStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenabrechnung.reisekostenabrechnungStatus'] as ISelection[]) : [])));
  }

  getReisekostenabrechnungTouren(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['reisekostenabrechnung.touren']?.find((v) => v.value === value);
  }

  get reisekostenabrechnungTouren(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['reisekostenabrechnung.touren'] as ISelection[]) : [])));
  }
}
