import { IFilter, IFilterConfig } from "pbc.types";

import { IFotoKategorie } from './';

export interface IFotoKategorieFilter extends IFilter<IFotoKategorie> {}

export const FotoKategorieFilterConfig: IFilterConfig<IFotoKategorie> = [];

export type IFotoKategorieField =
  'tourStatus.id'|
  'tourStatus.name'|
  'tourStatus.beschreibung'
;
