import { NgModule } from '@angular/core';
import {
  AbteilungenComponentModule,
  FondsComponentModule
 } from '.';

@NgModule({
  imports: [
    AbteilungenComponentModule,
    FondsComponentModule
  ],
  exports: [
    AbteilungenComponentModule,
    FondsComponentModule
  ]
})
export class KundenComponentsModule { }
