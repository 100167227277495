import { IFilter, IFilterConfig } from "pbc.types";

import { ITour } from './';

export interface ITourFilter extends IFilter<ITour> {}

export const TourFilterConfig: IFilterConfig<ITour> = [];

export type ITourField =
  'tour.id'|
  'tour.tourStatus'|
  'tour.mitarbeiter'|
  'tour.am'|
  'tour.startIstPrivatAdresse'|
  'tour.endeIstPrivatAdresse'|
  'tour.start'|
  'tour.ende'|
  'tour.strecke'|
  'tour.kosten'|
  'tour.abgerechnet'|
  'tour.kommentar'
;
