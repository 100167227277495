import { NgModule } from '@angular/core';
import {
  ReisekostenabrechnungStatiFilterComponentModule,
  ReisekostenabrechnungStatiSorterComponentModule,
  ReisekostenabrechnungStatiListComponentModule
} from '.';

@NgModule({
  imports: [
    ReisekostenabrechnungStatiFilterComponentModule,
    ReisekostenabrechnungStatiSorterComponentModule,
    ReisekostenabrechnungStatiListComponentModule
  ],
  exports: [
    ReisekostenabrechnungStatiFilterComponentModule,
    ReisekostenabrechnungStatiSorterComponentModule,
    ReisekostenabrechnungStatiListComponentModule
  ],
})
export class ReisekostenabrechnungStatiQueryModule { }