import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostZeitCommandComponent } from '.';
import { PostZeitCommandServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostZeitCommandServiceModule
  ],
  declarations: [PostZeitCommandComponent],
  exports: [PostZeitCommandComponent],

})
export class PostZeitCommandComponentModule { }
