import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { RollesServiceModule } from '..';
import { RollesFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    RollesServiceModule
  ],
  declarations: [RollesFilterComponent],
  exports: [RollesFilterComponent],

})
export class RollesFilterComponentModule { }
