import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBewertungsAnlass } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { BewertungsAnlasssService } from '../service';

@Component({
  selector: 'fa-kt-bewertungs-anlasss-list',
  templateUrl: './bewertungs-anlasss-list.component.html',
  styleUrls: ['./bewertungs-anlasss-list.component.css'],
})
export class BewertungsAnlasssListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IBewertungsAnlass> = new EventEmitter<IBewertungsAnlass>();

  constructor(public bewertungsAnlasss: BewertungsAnlasssService) {}

  ngOnDestroy() {}
}
