import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IKundenGruppesQueryFilter, IKundenGruppesQueryShapes, IKundenGruppesQuerySorter, IKundenGruppesRequest, IKundenGruppesResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class KundenGruppesService extends SettingsQueryService<IKundenGruppesRequest, IKundenGruppesResponse, IKundenGruppesQueryShapes, IKundenGruppesQueryFilter, IKundenGruppesQuerySorter> {
  contentKey: keyof IKundenGruppesResponse = 'kundenGruppes';
  route = 'kunden/settings/kunden-gruppes';
  override dependencies = ['kunden/written-kunden-gruppe', 'kunden/removed-kunden-gruppe'];
}
