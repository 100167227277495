import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjektArtsServiceModule } from '..';
import { ProjektArtsListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektArtsServiceModule
  ],
  declarations: [ProjektArtsListComponent],
  exports: [ProjektArtsListComponent],

})
export class ProjektArtsListComponentModule { }
