import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ReisekostenabrechnungStatiServiceModule } from '..';
import { ReisekostenabrechnungStatiListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungStatiServiceModule
  ],
  declarations: [ReisekostenabrechnungStatiListComponent],
  exports: [ReisekostenabrechnungStatiListComponent],

})
export class ReisekostenabrechnungStatiListComponentModule { }
