<!--

  <fa-kt-post-erinnerung [style.width]="'100%'" [value]="$postErinnerung | async" (finished)="finishedPostErinnerung($event)"></fa-kt-post-erinnerung>

  <fa-kt-post-abmelden [style.width]="'100%'" [value]="$postAbmelden | async" (finished)="finishedPostAbmelden($event)"></fa-kt-post-abmelden>



-->
<!--- << custom    -->
<nz-divider id="erinnerungen" [nzText]="title" nzOrientation="left"></nz-divider>
<nz-list *ngIf="_erinnerungen" nzSize="large" [nzLoading]="$loading | async">
  <nz-list-item *ngFor="let erinnerung of _erinnerungen" [ngSwitch]="($postErinnerung | async)?.erinnerung?.id === erinnerung.id">
    <nz-row *ngSwitchCase="true">
      <nz-col>
        <button type="button" nz-button nzType="dashed" (click)="openErinnerung({})">
          <i nz-icon nzType="close-circle" nzTheme="outline"></i>
          Abbrechen
        </button>
      </nz-col>
      <nz-col nzSpan="24">
        <fa-kt-post-erinnerung [style.width]="'100%'" [value]="$postErinnerung | async" (finished)="finishedPostErinnerung($event)"></fa-kt-post-erinnerung>
      </nz-col>
    </nz-row>
    <ng-container *ngSwitchDefault>
      <nz-comment [style.cursor]="erinnerung.editable ? 'pointer' : '' " (click)="openErinnerung(erinnerung)" [nzAuthor]="erinnerung.verfasser | type:(postErinnerung.erinnerungVerfasser | async)" [nzDatetime]="erinnerung.datetime + ' (' + (erinnerung._createdAt | date:'dd.MM.yyyy')+ ')'">
        <nz-avatar nz-comment-avatar [nzText]="getMitarbeiterText(erinnerung.verfasser | type:(postErinnerung.erinnerungVerfasser | async):'label')" [nzSrc]="erinnerung.verfasser | type:(postErinnerung.erinnerungVerfasser | async):'avatar'" nz-tooltip [nzTooltipTitle]="erinnerung.verfasser | type:(postErinnerung.erinnerungVerfasser | async)"></nz-avatar>
        <nz-comment-content>
          <p>
            {{ erinnerung.kommentar }}
          </p>
        </nz-comment-content>
        <nz-comment-action>
          <nz-date-picker [ngModel]="erinnerung.am" nzDisabled  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-comment-action>
        <nz-comment-action *ngIf="erinnerung.affected">
          <i nz-icon nzType="calendar" nzTheme="outline" style="color: red"></i>
        </nz-comment-action>
        <nz-comment-action>
          <pbc-user-selection [users]="erinnerung.mitarbeiter" [disabled]="true" ></pbc-user-selection>
        </nz-comment-action>
      </nz-comment>
    </ng-container>
  </nz-list-item>
  <nz-list-item *ngIf="!disabled && !($postErinnerung | async)?.erinnerung?.id">
    <fa-kt-post-erinnerung [style.width]="'100%'" [value]="$postErinnerung | async" (finished)="finishedPostErinnerung($event)"></fa-kt-post-erinnerung>
  </nz-list-item>
</nz-list>

<!---    custom >> -->
