<!--

  <fa-kt-post-kalkulation-hochladen [style.width]="'100%'" [value]="$postKalkulationHochladen | async" (finished)="finishedPostKalkulationHochladen($event)"></fa-kt-post-kalkulation-hochladen>
    


-->
<!--- << custom    -->
<fa-kt-post-kalkulation-hochladen [style.width]="'100%'" [value]="$postKalkulationHochladen | async" (finished)="finishedPostKalkulationHochladen($event)"></fa-kt-post-kalkulation-hochladen>
<!---    custom >> -->
