import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  public readonly $events: BehaviorSubject<string> = new BehaviorSubject('start');

  public next(eventName: string): void {
    this.$events.next(eventName);
  }

  public on(eventNames: string[]): Function {
    return (callback: (event: string) => Promise<void>) => this.$events.pipe(filter((event) => eventNames.indexOf(event) >= 0)).subscribe(callback);
  }
}
