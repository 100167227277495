import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IGutachtenStatusSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  beschreibung?: ISorterExpression;
}

export const GutachtenStatusSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('beschreibung', 'Beschreibung')
];
