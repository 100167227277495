import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FeldKategoriesServiceModule } from '..';
import { FeldKategoriesSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldKategoriesServiceModule
  ],
  declarations: [FeldKategoriesSorterComponent],
  exports: [FeldKategoriesSorterComponent],

})
export class FeldKategoriesSorterComponentModule { }
