<!---
  <div formGroupName="berichtsVorlage">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datei">{{ command.Inputs.DATEI.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateiErrorTip">
        <ng-template #DateiErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEI.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="datei" id="datei" [shapes]="postBerichtsVorlage.berichtsVorlageDatei | async" [placeholder]="command.Inputs.DATEI.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="berichtsVorlage">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <!-- <input class="cdk-visually-hidden" formControlName="datei" id="datei" /> -->
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="vorlagen">Vorlagen</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateiErrorTip">
          <ng-template #DateiErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEI.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="vorlagen" id="vorlagen" [shapes]="shapes" [placeholder]="'Vorlagen'" mode="multiple"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datei">{{ command.Inputs.DATEI.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateiErrorTip">
          <ng-template #DateiErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEI.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-file-upload formControlName="datei" id="datei" text="Word Vorlage hochladen" hint="Word Vorlage mit Platzhaltern auswählen"></pbc-file-upload>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item>
      <nz-form-control nzOffset="8">
        <button nz-button nzType="primary" type="submit" class="save-button" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          Speichern
          <ng-template #SubmitTooltip> Vorlage speichern und später verwenden </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
