import { NgModule } from '@angular/core';
import {
  FelderFilterComponentModule,
  FelderListComponentModule,
  FelderSorterComponentModule,
  SavedFelderFiltersComponentModule
} from '.';

@NgModule({
  imports: [
    FelderFilterComponentModule,
    FelderListComponentModule,
    FelderSorterComponentModule,
    SavedFelderFiltersComponentModule
  ],
  exports: [
    FelderFilterComponentModule,
    FelderListComponentModule,
    FelderSorterComponentModule,
    SavedFelderFiltersComponentModule
  ],
})
export class FelderQueryModule { }
