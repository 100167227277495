import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IDateiArtsQuerySorter extends ISorter {
   id?: ISorterExpression;
   name?: ISorterExpression;
   beschreibung?: ISorterExpression;
}

export const DateiArtsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'DOKUMENTE.Models.DATEI_ART.Propertys.ID.title'),
   SorterConfig('name', 'DOKUMENTE.Models.DATEI_ART.Propertys.NAME.title'),
   SorterConfig('beschreibung', 'DOKUMENTE.Models.DATEI_ART.Propertys.BESCHREIBUNG.title'),
];
