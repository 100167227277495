import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { combineLatest, Subscription } from 'rxjs';

import { FormulareQueryFilterConfig, IFormulareResponse } from 'fa-kt.types';

import { FelderService } from '../../../../felder';
import { FormulareService } from '../service';

@Component({
  selector: 'fa-kt-formulare-filter',
  templateUrl: './formulare-filter.component.html',
  styleUrls: ['./formulare-filter.component.css'],
})
export class FormulareFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public FormulareFilterConfig: IFilterConfig<IFormulareResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public formulare: FormulareService,
    public felder: FelderService,
  ) {
    this.FormulareFilterConfig = FormulareQueryFilterConfig.filter((filter) => filter.key !== 'formular.ohneFelder').map((filter) => ({
      ...filter,
      title: get(sitemap, filter.title, filter.title) as string,
    }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([this.formulare.shapes$, this.felder.response$]).subscribe(([shapes, felder]) => {
        if (!shapes) {
          return;
        }
        shapes['formular.felder'] = felder?.felder.map(({ feld }) => ({ value: feld.id, label: feld.name })) || [];
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.FormulareFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.FormulareFilterConfig[index].selections = shapes;
  }
}
