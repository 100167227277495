import { NgModule } from '@angular/core';

import { FilterPipe, LIB_CommonModule, SearchPipe, SorterPipe } from 'pbc.angular';

import { MitarbeiterService } from '.';

@NgModule({
  imports: [LIB_CommonModule],
  providers: [MitarbeiterService, SearchPipe, FilterPipe, SorterPipe],
  exports: [],
})
export class MitarbeiterServiceModule {}
