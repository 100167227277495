import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { VorlageServiceModule } from '..';
import { VorlageDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlageServiceModule
  ],
  declarations: [VorlageDetailComponent],
  exports: [VorlageDetailComponent],

})
export class VorlageDetailComponentModule { }
