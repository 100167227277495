import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IObjektArtSorter extends ISorter {
  id?: ISorterExpression;
  objektNutzung?: ISorterExpression;
  name?: ISorterExpression;
  initialen?: ISorterExpression;
  besichtigungsZeit?: ISorterExpression;
  beschreibung?: ISorterExpression;
}

export const ObjektArtSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('objektNutzung', 'Objekt Nutzung'),
  SorterConfig('name', 'Name'),
  SorterConfig('initialen', 'Initialen'),
  SorterConfig('besichtigungsZeit', 'Besichtigungs Zeit'),
  SorterConfig('beschreibung', 'Beschreibung')
];
