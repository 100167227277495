import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { IDeleteHonorarVereinbarungRequest, IDeleteHonorarVereinbarungResponse, IHonorarVereinbarung, IPostHonorarVereinbarungRequest, IPostHonorarVereinbarungResponse } from 'fa-kt.types';
import { DeleteHonorarVereinbarungCommandService, PostHonorarVereinbarungCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-honorar-vereinbarungen',
  templateUrl: './honorar-vereinbarungen.component.html',
  styleUrls: ['./honorar-vereinbarungen.component.css'],
})
export class HonorarVereinbarungenComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $deleteHonorarVereinbarung: BehaviorSubject<IDeleteHonorarVereinbarungRequest | null> = new BehaviorSubject<IDeleteHonorarVereinbarungRequest | null>(null);
  public readonly $postHonorarVereinbarung: BehaviorSubject<IPostHonorarVereinbarungRequest | null> = new BehaviorSubject<IPostHonorarVereinbarungRequest | null>(null);

  @Input() disabled = false;
  @Input() kunde: string = '';
  @Input() honorarVereinbarungen: IHonorarVereinbarung[] = [];

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public deleteHonorarVereinbarung: DeleteHonorarVereinbarungCommandService,
    public postHonorarVereinbarung: PostHonorarVereinbarungCommandService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedDeleteHonorarVereinbarung(response?: IDeleteHonorarVereinbarungResponse) {
    /* << after delete-honorar-vereinbarung    */
    this.openHonorarVereinbarung(null);
    /*    after delete-honorar-vereinbarung >> */
  }

  async finishedPostHonorarVereinbarung(response?: IPostHonorarVereinbarungResponse) {
    /* << after post-honorar-vereinbarung    */
    this.openHonorarVereinbarung(null);
    /*    after post-honorar-vereinbarung >> */
  }

  /* << methods    */
  public openHonorarVereinbarung(honorarVereinbarung: IHonorarVereinbarung | null | any) {
    if (!honorarVereinbarung) {
      this.$postHonorarVereinbarung.next(null);
      this.$deleteHonorarVereinbarung.next(null);
      return;
    }
    if (!honorarVereinbarung.id) {
      honorarVereinbarung.kunde = this.kunde;
    }
    this.$postHonorarVereinbarung.next({ honorarVereinbarung });
    if (honorarVereinbarung && honorarVereinbarung.id) {
      this.$deleteHonorarVereinbarung.next({ honorarVereinbarung: honorarVereinbarung.id });
    } else {
      this.$deleteHonorarVereinbarung.next(null);
    }
  }
  /*    methods >> */

  public async submitDeleteHonorarVereinbarung(request?: IDeleteHonorarVereinbarungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteHonorarVereinbarung.getValue();
    if (payload) {
      try {
        const response = await this.deleteHonorarVereinbarung.request(payload);
        await this.finishedDeleteHonorarVereinbarung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostHonorarVereinbarung(request?: IPostHonorarVereinbarungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postHonorarVereinbarung.getValue();
    if (payload) {
      try {
        const response = await this.postHonorarVereinbarung.request(payload);
        await this.finishedPostHonorarVereinbarung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
