import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';
import { toInitials } from 'pbc.functions';

import { IDeleteVermerkRequest, IDeleteVermerkResponse, IKunde, IPostVermerkRequest, IPostVermerkResponse, IProjekt, IVermerk } from 'fa-kt.types';
import { DeleteVermerkCommandService, PostVermerkCommandService } from '../../commands';

interface Vermerk extends IVermerk {
  datetime: string;
  editable: boolean;
}

@Component({
  selector: 'fa-kt-vermerke',
  templateUrl: './vermerke.component.html',
  styleUrls: ['./vermerke.component.css'],
})
export class VermerkeComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postVermerk: BehaviorSubject<IPostVermerkRequest | null> = new BehaviorSubject<IPostVermerkRequest | null>(null);
  public readonly $deleteVermerk: BehaviorSubject<IDeleteVermerkRequest | null> = new BehaviorSubject<IDeleteVermerkRequest | null>(null);

  @Input() kunde: IKunde | undefined;
  @Input() projekt: IProjekt | undefined;
  @Input() disabled = false;
  @Input() set vermerke(vermerke: IVermerk[]) {
    // @ts-ignore
    this._vermerke = vermerke.map((vermerk) => ({
      ...vermerk,
      editable: !vermerk.verfasser || vermerk.verfasser === this.auth.$id.getValue(),
      datetime: formatDistanceToNow(new Date(vermerk._createdAt), { includeSeconds: false, locale: de }),
    }));
  }
  _vermerke: Vermerk[] = [];

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postVermerk: PostVermerkCommandService,
    public deleteVermerk: DeleteVermerkCommandService,
  ) {}

  async ngOnInit() {
    /* << init    */
    this.openVermerk({});
    /*    init >> */
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        this.auth.$id.subscribe((id) => {
          if (id) {
            this.openVermerk({});
          }
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostVermerk(response?: IPostVermerkResponse) {
    this.openVermerk({});
  }

  async finishedDeleteVermerk(response?: IDeleteVermerkResponse) {
    this.openVermerk({});
  }

  public openVermerk(vermerk: Vermerk | null | any) {
    if (!vermerk || (vermerk.id && !vermerk.editable)) {
      this.$postVermerk.next(null);
      this.$deleteVermerk.next(null);
      return;
    }
    if (!vermerk.id) {
      const me = this.auth.$id.getValue();
      vermerk.verfasser = me;
      vermerk.kunde = this.kunde?.id;
      vermerk.projekt = this.projekt?.id;
      vermerk.order = this._vermerke.length + 1;
    }
    this.$postVermerk.next({ vermerk });
    if (vermerk && vermerk.id) {
      this.$deleteVermerk.next({ id: vermerk.id });
    } else {
      this.$deleteVermerk.next(null);
    }
  }
  /*    methods >> */

  public async submitPostVermerk(request?: IPostVermerkRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postVermerk.getValue();
    if (payload) {
      try {
        const response = await this.postVermerk.request(payload);
        await this.finishedPostVermerk(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteVermerk(request?: IDeleteVermerkRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteVermerk.getValue();
    if (payload) {
      try {
        const response = await this.deleteVermerk.request(payload);
        await this.finishedDeleteVermerk(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  getMitarbeiterText(label: string) {
    if (!label) return '';
    return toInitials(label.split('(')[0]);
  }
}
