import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostProjektStatusCommandShapes, IPostProjektStatusRequest, IPostProjektStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostProjektStatusCommandService extends PostCommandService<IPostProjektStatusRequest, IPostProjektStatusResponse, IPostProjektStatusCommandShapes> {
  readonly route = 'projekte/settings/projekt-status';
}
