import { NgModule } from '@angular/core';

import {
  PostProjektArtCommandServiceModule,
  PostProjektArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostProjektArtCommandServiceModule,
    PostProjektArtCommandComponentModule
  ],
  exports: [
    PostProjektArtCommandServiceModule,
    PostProjektArtCommandComponentModule
  ]
})
export class PostProjektArtCommandModule { }
