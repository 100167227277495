import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostBereichCommandShapes, IPostBereichRequest, IPostBereichResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostBereichCommandService extends PostCommandService<IPostBereichRequest, IPostBereichResponse, IPostBereichCommandShapes> {
  readonly route = 'geo/settings/bereich';
}
