import { Component } from '@angular/core';
import { DurchgangService } from '../service';

@Component({
  selector: 'fa-kt-saved-durchgang-filters',
  templateUrl: './saved-durchgang-filters.component.html',
  styleUrls: ['./saved-durchgang-filters.component.css'],
})
export class SavedDurchgangFiltersComponent {
  constructor(public durchgang: DurchgangService) {}
}
