import { Component, Inject } from '@angular/core';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { BesichtigungsStatiQueryFilterConfig, IBesichtigungsStatiQueryShapes } from 'fa-kt.types';
import { BesichtigungsStatiService } from '../service';

@Component({
  selector: 'fa-kt-besichtigungs-stati-filter',
  templateUrl: './besichtigungs-stati-filter.component.html',
  styleUrls: ['./besichtigungs-stati-filter.component.css'],
})
export class BesichtigungsStatiFilterComponent {
  readonly subscriptions: Subscription[] = [];

  BesichtigungsStatiFilterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public besichtigungsStati: BesichtigungsStatiService,
  ) {
    this.BesichtigungsStatiFilterConfig = BesichtigungsStatiQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.besichtigungsStati.shapes$.subscribe((shapes: IBesichtigungsStatiQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.BesichtigungsStatiFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.BesichtigungsStatiFilterConfig[index].selections = shapes;
  }
}
