import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostKundeCommandShapes, IPostKundeRequest, IPostKundeResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostKundeCommandService extends PostCommandService<IPostKundeRequest, IPostKundeResponse, IPostKundeCommandShapes> {
  readonly route = 'kunden/kunde';
  override dependencies = ['kunden/written-kunden-art', 'kunden/removed-kunden-art', 'kunden/written-kunden-gruppe', 'kunden/removed-kunden-gruppe'];

  getKundeKundenArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenArt']?.find((v) => v.value === value);
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenArt'] as ISelection[]) : [])));
  }

  getKundeKundenGruppe(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenGruppe']?.find((v) => v.value === value);
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenGruppe'] as ISelection[]) : [])));
  }
}
