import { Component } from '@angular/core';
import { KundenService } from '../service';

@Component({
  selector: 'fa-kt-saved-kunden-filters',
  templateUrl: './saved-kunden-filters.component.html',
  styleUrls: ['./saved-kunden-filters.component.css'],
})
export class SavedKundenFiltersComponent {
  constructor(public kunden: KundenService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
