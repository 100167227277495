import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjekteServiceModule } from '..';
import { ObjekteFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjekteServiceModule
  ],
  declarations: [ObjekteFilterComponent],
  exports: [ObjekteFilterComponent],

})
export class ObjekteFilterComponentModule { }
