import { NgModule } from '@angular/core';

import {
  PostTourStatusCommandServiceModule,
  PostTourStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostTourStatusCommandServiceModule,
    PostTourStatusCommandComponentModule
  ],
  exports: [
    PostTourStatusCommandServiceModule,
    PostTourStatusCommandComponentModule
  ]
})
export class PostTourStatusCommandModule { }
