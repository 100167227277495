import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostHochladenCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostHochladenCommandService],
  exports: []
})
export class PostHochladenCommandServiceModule { }
