import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBesichtigungsStatusCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostBesichtigungsStatusCommandService],
  exports: []
})
export class PostBesichtigungsStatusCommandServiceModule { }
