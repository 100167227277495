<nz-row>
  <nz-col nzSpan="24">
    <nz-input-group [nzPrefix]="prefixIconSearch">
      <ng-template #prefixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
      <input
        nz-input
        type="text"
        [ngModel]="search$ | async"
        (ngModelChange)="searched($event)"
        (keydown.enter)="submit()"
        (keydown.arrowup)="up()"
        (keydown.arrowleft)="up()"
        (keydown.arrowdown)="down()"
        (keydown.arrowright)="down()"
        placeholder="Was soll geschehen? Suche nach Shortcuts ⌨💨️"
        cdkFocusInitial
      />
    </nz-input-group>
  </nz-col>
  <nz-col nzSpan="24">
    <ul nz-menu style="max-height: 500px; overflow-y: auto">
      <li nz-menu-item *ngFor="let action of $actions | async; trackBy: actionTracker" (click)="actions.call(action)" tabindex="-1" [ngClass]="{ 'selected-action': action.selected }">
        <span nz-typography>{{ action.key }}</span>
      </li>
    </ul>
  </nz-col>
</nz-row>
