import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { FelderSelectionComponent } from '.';

@NgModule({
  imports: [
    CommonModule,
    LIB_CommonModule,
  ],
  declarations: [
    FelderSelectionComponent
  ],
  exports: [
    FelderSelectionComponent
  ]
})
export class FormulareComponentsModule { }
