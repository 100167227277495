import { NgModule } from '@angular/core';
import {
  TemplatesFilterComponentModule,
  TemplatesSorterComponentModule,
  TemplatesListComponentModule
} from '.';

@NgModule({
  imports: [
    TemplatesFilterComponentModule,
    TemplatesSorterComponentModule,
    TemplatesListComponentModule
  ],
  exports: [
    TemplatesFilterComponentModule,
    TemplatesSorterComponentModule,
    TemplatesListComponentModule
  ],
})
export class TemplatesQueryModule { }