import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostFormularKopierenCommandShapes, IPostFormularKopierenRequest, IPostFormularKopierenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostFormularKopierenCommandService extends PostCommandService<IPostFormularKopierenRequest, IPostFormularKopierenResponse, IPostFormularKopierenCommandShapes> {
  readonly route = 'formulare/formular-kopieren';
}
