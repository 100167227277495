import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IZeileSorter extends ISorter {
  id?: ISorterExpression;
  bericht?: ISorterExpression;
  land?: ISorterExpression;
  bundesland?: ISorterExpression;
  plz?: ISorterExpression;
  region?: ISorterExpression;
  kreisStadt?: ISorterExpression;
  gemeindeBezirk?: ISorterExpression;
  ortsStadtTeil?: ISorterExpression;
  gemeindeschluessel?: ISorterExpression;
  teilmarkt?: ISorterExpression;
  bereiche?: ISorterExpression;
  assetklasse?: ISorterExpression;
  objektart?: ISorterExpression;
  zustand?: ISorterExpression;
  vertrag?: ISorterExpression;
  wohnlage?: ISorterExpression;
  wflVon?: ISorterExpression;
  wflBis?: ISorterExpression;
  wflMittel?: ISorterExpression;
  bjVon?: ISorterExpression;
  bjBis?: ISorterExpression;
  wohnNutzWert?: ISorterExpression;
  ausstattung?: ISorterExpression;
  artDesWertes?: ISorterExpression;
  einheit?: ISorterExpression;
  bezug?: ISorterExpression;
  durchschnitt?: ISorterExpression;
  minimum?: ISorterExpression;
  maximum?: ISorterExpression;
  spitzenwerte?: ISorterExpression;
  anzahl?: ISorterExpression;
  steigerung_1?: ISorterExpression;
  steigerung_2?: ISorterExpression;
  steigerung_3?: ISorterExpression;
  steigerung_4?: ISorterExpression;
  steigerung_5?: ISorterExpression;
  steigerungArt?: ISorterExpression;
}

export const ZeileSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('bericht', 'Bericht'),
  SorterConfig('land', 'Land'),
  SorterConfig('bundesland', 'Bundesland'),
  SorterConfig('plz', 'Plz'),
  SorterConfig('region', 'Region'),
  SorterConfig('kreisStadt', 'Kreis Stadt'),
  SorterConfig('gemeindeBezirk', 'Gemeinde Bezirk'),
  SorterConfig('ortsStadtTeil', 'Orts Stadt Teil'),
  SorterConfig('gemeindeschluessel', 'Gemeindeschluessel'),
  SorterConfig('teilmarkt', 'Teilmarkt'),
  SorterConfig('bereiche', 'Bereiche'),
  SorterConfig('assetklasse', 'Assetklasse'),
  SorterConfig('objektart', 'Objektart'),
  SorterConfig('zustand', 'Zustand'),
  SorterConfig('vertrag', 'Vertrag'),
  SorterConfig('wohnlage', 'Wohnlage'),
  SorterConfig('wflVon', 'Wfl Von'),
  SorterConfig('wflBis', 'Wfl Bis'),
  SorterConfig('wflMittel', 'Wfl Mittel'),
  SorterConfig('bjVon', 'Bj Von'),
  SorterConfig('bjBis', 'Bj Bis'),
  SorterConfig('wohnNutzWert', 'Wohn Nutz Wert'),
  SorterConfig('ausstattung', 'Ausstattung'),
  SorterConfig('artDesWertes', 'Art Des Wertes'),
  SorterConfig('einheit', 'Einheit'),
  SorterConfig('bezug', 'Bezug'),
  SorterConfig('durchschnitt', 'Durchschnitt'),
  SorterConfig('minimum', 'Minimum'),
  SorterConfig('maximum', 'Maximum'),
  SorterConfig('spitzenwerte', 'Spitzenwerte'),
  SorterConfig('anzahl', 'Anzahl'),
  SorterConfig('steigerung_1', 'Steigerung 1'),
  SorterConfig('steigerung_2', 'Steigerung 2'),
  SorterConfig('steigerung_3', 'Steigerung 3'),
  SorterConfig('steigerung_4', 'Steigerung 4'),
  SorterConfig('steigerung_5', 'Steigerung 5'),
  SorterConfig('steigerungArt', 'Steigerung Art')
];
