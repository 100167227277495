import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IProjektArtsResponse } from '.';

export interface IProjektArtsQueryFilter extends IFilter<IProjektArtsResponse> {}

export const ProjektArtsQueryFilterConfig: IFilterConfig<IProjektArtsResponse> = [];

export interface IProjektArtsQueryShapes extends IShape {}

export type IProjektArtsQueryField = 'projektArts.id' | 'projektArts.name' | 'projektArts.initialen' | 'projektArts.beschreibung' | '';
