import { Injectable } from '@angular/core';

import { ITemplatesQueryFilter, ITemplatesQueryShapes, ITemplatesQuerySorter, ITemplatesRequest, ITemplatesResponse } from 'fa-kt.types';
import { SettingsQueryService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService extends SettingsQueryService<ITemplatesRequest, ITemplatesResponse, ITemplatesQueryShapes, ITemplatesQueryFilter, ITemplatesQuerySorter> {
  contentKey: keyof ITemplatesResponse = 'templates';
  route = 'markt/settings/templates';
  override dependencies = ['markt/written-template', 'markt/removed-template'];
}
