import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostFeldKategorieCommandServiceModule } from '..';
import { PostFeldKategorieCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFeldKategorieCommandServiceModule
  ],
  declarations: [PostFeldKategorieCommandComponent],
  exports: [PostFeldKategorieCommandComponent],

})
export class PostFeldKategorieCommandComponentModule { }
