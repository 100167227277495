import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteTourCommandServiceModule } from '..';
import { DeleteTourCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteTourCommandServiceModule
  ],
  declarations: [DeleteTourCommandComponent],
  exports: [DeleteTourCommandComponent],

})
export class DeleteTourCommandComponentModule { }
