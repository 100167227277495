import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { BaseComponent } from 'pbc.angular';

import { IDeleteObjektRequest, IDeleteObjektResponse, IObjekt, IPostObjektRequest, IPostObjektResponse } from 'fa-kt.types';
import { DeleteObjektCommandService, PostObjektCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-objekt',
  templateUrl: './objekt.component.html',
  styleUrls: ['./objekt.component.css'],
})
export class ObjektComponent extends BaseComponent implements OnInit, OnDestroy {
  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postObjekt: BehaviorSubject<IPostObjektRequest | null> = new BehaviorSubject<IPostObjektRequest | null>(null);
  public readonly $deleteObjekt: BehaviorSubject<IDeleteObjektRequest | null> = new BehaviorSubject<IDeleteObjektRequest | null>(null);

  @Input() set objekt(objekt: IObjekt) {
    if (objekt) {
      this.$postObjekt.next({ objekt });
      this.$deleteObjekt.next({ id: objekt.id });
    } else {
      this.$postObjekt.next(null);
      this.$deleteObjekt.next(null);
    }
  }
  @Output() selected = new EventEmitter<IObjekt | undefined>();
  @Output() closed = new EventEmitter();

  constructor(
    public postObjekt: PostObjektCommandService,
    public deleteObjekt: DeleteObjektCommandService,
  ) {
    super();
  }

  async finishedPostObjekt(response?: IPostObjektResponse) {
    this.selected.emit(response.objekt);
  }

  async finishedDeleteObjekt(response?: IDeleteObjektResponse) {
    this.selected.emit(undefined);
  }

  public async submitPostObjekt(request?: IPostObjektRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postObjekt.getValue();
    if (payload) {
      try {
        const response = await this.postObjekt.request(payload);
        await this.finishedPostObjekt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteObjekt(request?: IDeleteObjektRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteObjekt.getValue();
    if (payload) {
      try {
        const response = await this.deleteObjekt.request(payload);
        await this.finishedDeleteObjekt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
