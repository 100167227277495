import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DetailQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IKundeQueryShapes, IKundeRequest, IKundeResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class KundeService extends DetailQueryService<IKundeRequest, IKundeResponse, IKundeQueryShapes> {
  route = 'kunden/kunde';
  override dependencies = [
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',

    'finanzen/extra-vereinbarung-entfernt',
    'finanzen/extra-vereinbarung-gespeichert',
    'finanzen/honorar-vereinbarung-entfernt',
    'finanzen/honorar-vereinbarung-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'kunden/abteilung-entfernt',
    'kunden/abteilung-gespeichert',
    'kunden/fond-entfernt',
    'kunden/fond-gespeichert',
    'kunden/kunde-gespeichert',
    'kunden/kunden-finanzen-gespeichert',
  ];

  static instance: KundeService;

  constructor() {
    super();
    if (!KundeService.instance) KundeService.instance = this;
    return KundeService.instance;
  }

  getKundeKundenArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenArt']?.find((v) => v.value === value);
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenArt'] as ISelection[]) : [])));
  }

  getKundeKundenGruppe(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['kunde.kundenGruppe']?.find((v) => v.value === value);
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['kunde.kundenGruppe'] as ISelection[]) : [])));
  }

  getVermerkeVerfasser(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['vermerke.verfasser']?.find((v) => v.value === value);
  }

  get vermerkeVerfasser(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['vermerke.verfasser'] as ISelection[]) : [])));
  }

  getErinnerungenMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerungen.mitarbeiter']?.find((v) => v.value === value);
  }

  get erinnerungenMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerungen.mitarbeiter'] as ISelection[]) : [])));
  }

  getErinnerungenVerfasser(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['erinnerungen.verfasser']?.find((v) => v.value === value);
  }

  get erinnerungenVerfasser(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['erinnerungen.verfasser'] as ISelection[]) : [])));
  }
}
