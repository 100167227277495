import { IFilter, IFilterConfig } from "pbc.types";

import { IDateiArt } from '.';

export interface IDateiArtFilter extends IFilter<IDateiArt> {}

export const DateiArtFilterConfig: IFilterConfig<IDateiArt> = [];

export type IDateiArtField =
  'dateiArt.id'|
  'dateiArt.name'|
  'dateiArt.beschreibung'
;
