import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';


import { BerichteComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  declarations: [BerichteComponent],
  exports: [BerichteComponent],

})
export class BerichteComponentModule { }
