import { NgModule } from '@angular/core';
import {
  ErforderlichesDokumentsFilterComponentModule,
  ErforderlichesDokumentsSorterComponentModule,
  ErforderlichesDokumentsListComponentModule
} from '.';

@NgModule({
  imports: [
    ErforderlichesDokumentsFilterComponentModule,
    ErforderlichesDokumentsSorterComponentModule,
    ErforderlichesDokumentsListComponentModule
  ],
  exports: [
    ErforderlichesDokumentsFilterComponentModule,
    ErforderlichesDokumentsSorterComponentModule,
    ErforderlichesDokumentsListComponentModule
  ],
})
export class ErforderlichesDokumentsQueryModule { }