import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProfilServiceModule } from '..';
import { ProfilDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProfilServiceModule
  ],
  declarations: [ProfilDetailComponent],
  exports: [ProfilDetailComponent],

})
export class ProfilDetailComponentModule { }
