import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { IPostZeileRequest, IPostZeileResponse } from 'fa-kt.types';
import { PostZeileCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-zeile',
  templateUrl: './post-zeile.component.html',
  styleUrls: ['./post-zeile.component.css'],
})
export class PostZeileCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostZeileRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostZeileRequest> = new EventEmitter<IPostZeileRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostZeileResponse> = new EventEmitter<IPostZeileResponse>();

  /* << fields    */
  /*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postZeile: PostZeileCommandService /* << more    */,
  ) /*    more >> */
  {
    this.command = sitemap.MARKT.Commands.POST_ZEILE;
    this.form = this.fb.group({
      zeile: this.fb.group({
        id: [null, []],
        bericht: [null, [Validators.required]],
        land: [null, []],
        bundesland: [null, []],
        plz: [null, []],
        region: [null, []],
        kreisStadt: [null, []],
        gemeindeBezirk: [null, []],
        ortsStadtTeil: [null, []],
        gemeindeschluessel: [null, []],
        teilmarkt: [null, []],
        bereiche: [[], []],
        assetklasse: [null, []],
        objektart: [null, []],
        zustand: [null, []],
        vertrag: [null, []],
        wohnlage: [null, []],
        wflVon: [null, []],
        wflBis: [null, []],
        wflMittel: [null, []],
        bjVon: [null, []],
        bjBis: [null, []],
        wohnNutzWert: [null, []],
        ausstattung: [null, []],
        artDesWertes: [null, []],
        einheit: [null, []],
        bezug: [null, []],
        durchschnitt: [null, []],
        minimum: [null, []],
        maximum: [null, []],
        spitzenwerte: [null, []],
        anzahl: [null, []],
        steigerung_1: [null, []],
        steigerung_2: [null, []],
        steigerung_3: [null, []],
        steigerung_4: [null, []],
        steigerung_5: [null, []],
        steigerungArt: [null, []],
      }),
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postZeile.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostZeileRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.zeileId?.valueChanges.subscribe(async (id) => {
        /* << on-zeile-id-change    */
        /*    on-zeile-id-change >> */
      }) as Subscription,
      this.zeileBericht?.valueChanges.subscribe(async (bericht) => {
        /* << on-zeile-bericht-change    */
        /*    on-zeile-bericht-change >> */
      }) as Subscription,
      this.zeileLand?.valueChanges.subscribe(async (land) => {
        /* << on-zeile-land-change    */
        /*    on-zeile-land-change >> */
      }) as Subscription,
      this.zeileBundesland?.valueChanges.subscribe(async (bundesland) => {
        /* << on-zeile-bundesland-change    */
        /*    on-zeile-bundesland-change >> */
      }) as Subscription,
      this.zeilePlz?.valueChanges.subscribe(async (plz) => {
        /* << on-zeile-plz-change    */
        /*    on-zeile-plz-change >> */
      }) as Subscription,
      this.zeileRegion?.valueChanges.subscribe(async (region) => {
        /* << on-zeile-region-change    */
        /*    on-zeile-region-change >> */
      }) as Subscription,
      this.zeileKreisStadt?.valueChanges.subscribe(async (kreisStadt) => {
        /* << on-zeile-kreis-stadt-change    */
        /*    on-zeile-kreis-stadt-change >> */
      }) as Subscription,
      this.zeileGemeindeBezirk?.valueChanges.subscribe(async (gemeindeBezirk) => {
        /* << on-zeile-gemeinde-bezirk-change    */
        /*    on-zeile-gemeinde-bezirk-change >> */
      }) as Subscription,
      this.zeileOrtsStadtTeil?.valueChanges.subscribe(async (ortsStadtTeil) => {
        /* << on-zeile-orts-stadt-teil-change    */
        /*    on-zeile-orts-stadt-teil-change >> */
      }) as Subscription,
      this.zeileGemeindeschluessel?.valueChanges.subscribe(async (gemeindeschluessel) => {
        /* << on-zeile-gemeindeschluessel-change    */
        /*    on-zeile-gemeindeschluessel-change >> */
      }) as Subscription,
      this.zeileTeilmarkt?.valueChanges.subscribe(async (teilmarkt) => {
        /* << on-zeile-teilmarkt-change    */
        /*    on-zeile-teilmarkt-change >> */
      }) as Subscription,
      this.zeileBereiche?.valueChanges.subscribe(async (bereiche) => {
        /* << on-zeile-bereiche-change    */
        /*    on-zeile-bereiche-change >> */
      }) as Subscription,
      this.zeileAssetklasse?.valueChanges.subscribe(async (assetklasse) => {
        /* << on-zeile-assetklasse-change    */
        /*    on-zeile-assetklasse-change >> */
      }) as Subscription,
      this.zeileObjektart?.valueChanges.subscribe(async (objektart) => {
        /* << on-zeile-objektart-change    */
        /*    on-zeile-objektart-change >> */
      }) as Subscription,
      this.zeileZustand?.valueChanges.subscribe(async (zustand) => {
        /* << on-zeile-zustand-change    */
        /*    on-zeile-zustand-change >> */
      }) as Subscription,
      this.zeileVertrag?.valueChanges.subscribe(async (vertrag) => {
        /* << on-zeile-vertrag-change    */
        /*    on-zeile-vertrag-change >> */
      }) as Subscription,
      this.zeileWohnlage?.valueChanges.subscribe(async (wohnlage) => {
        /* << on-zeile-wohnlage-change    */
        /*    on-zeile-wohnlage-change >> */
      }) as Subscription,
      this.zeileWflVon?.valueChanges.subscribe(async (wflVon) => {
        /* << on-zeile-wfl-von-change    */
        /*    on-zeile-wfl-von-change >> */
      }) as Subscription,
      this.zeileWflBis?.valueChanges.subscribe(async (wflBis) => {
        /* << on-zeile-wfl-bis-change    */
        /*    on-zeile-wfl-bis-change >> */
      }) as Subscription,
      this.zeileWflMittel?.valueChanges.subscribe(async (wflMittel) => {
        /* << on-zeile-wfl-mittel-change    */
        /*    on-zeile-wfl-mittel-change >> */
      }) as Subscription,
      this.zeileBjVon?.valueChanges.subscribe(async (bjVon) => {
        /* << on-zeile-bj-von-change    */
        /*    on-zeile-bj-von-change >> */
      }) as Subscription,
      this.zeileBjBis?.valueChanges.subscribe(async (bjBis) => {
        /* << on-zeile-bj-bis-change    */
        /*    on-zeile-bj-bis-change >> */
      }) as Subscription,
      this.zeileWohnNutzWert?.valueChanges.subscribe(async (wohnNutzWert) => {
        /* << on-zeile-wohn-nutz-wert-change    */
        /*    on-zeile-wohn-nutz-wert-change >> */
      }) as Subscription,
      this.zeileAusstattung?.valueChanges.subscribe(async (ausstattung) => {
        /* << on-zeile-ausstattung-change    */
        /*    on-zeile-ausstattung-change >> */
      }) as Subscription,
      this.zeileArtDesWertes?.valueChanges.subscribe(async (artDesWertes) => {
        /* << on-zeile-art-des-wertes-change    */
        /*    on-zeile-art-des-wertes-change >> */
      }) as Subscription,
      this.zeileEinheit?.valueChanges.subscribe(async (einheit) => {
        /* << on-zeile-einheit-change    */
        /*    on-zeile-einheit-change >> */
      }) as Subscription,
      this.zeileBezug?.valueChanges.subscribe(async (bezug) => {
        /* << on-zeile-bezug-change    */
        /*    on-zeile-bezug-change >> */
      }) as Subscription,
      this.zeileDurchschnitt?.valueChanges.subscribe(async (durchschnitt) => {
        /* << on-zeile-durchschnitt-change    */
        /*    on-zeile-durchschnitt-change >> */
      }) as Subscription,
      this.zeileMinimum?.valueChanges.subscribe(async (minimum) => {
        /* << on-zeile-minimum-change    */
        /*    on-zeile-minimum-change >> */
      }) as Subscription,
      this.zeileMaximum?.valueChanges.subscribe(async (maximum) => {
        /* << on-zeile-maximum-change    */
        /*    on-zeile-maximum-change >> */
      }) as Subscription,
      this.zeileSpitzenwerte?.valueChanges.subscribe(async (spitzenwerte) => {
        /* << on-zeile-spitzenwerte-change    */
        /*    on-zeile-spitzenwerte-change >> */
      }) as Subscription,
      this.zeileAnzahl?.valueChanges.subscribe(async (anzahl) => {
        /* << on-zeile-anzahl-change    */
        /*    on-zeile-anzahl-change >> */
      }) as Subscription,
      this.zeileSteigerung_1?.valueChanges.subscribe(async (steigerung_1) => {
        /* << on-zeile-steigerung--1-change    */
        /*    on-zeile-steigerung--1-change >> */
      }) as Subscription,
      this.zeileSteigerung_2?.valueChanges.subscribe(async (steigerung_2) => {
        /* << on-zeile-steigerung-2-change    */
        /*    on-zeile-steigerung-2-change >> */
      }) as Subscription,
      this.zeileSteigerung_3?.valueChanges.subscribe(async (steigerung_3) => {
        /* << on-zeile-steigerung-3-change    */
        /*    on-zeile-steigerung-3-change >> */
      }) as Subscription,
      this.zeileSteigerung_4?.valueChanges.subscribe(async (steigerung_4) => {
        /* << on-zeile-steigerung-4-change    */
        /*    on-zeile-steigerung-4-change >> */
      }) as Subscription,
      this.zeileSteigerung_5?.valueChanges.subscribe(async (steigerung_5) => {
        /* << on-zeile-steigerung-5-change    */
        /*    on-zeile-steigerung-5-change >> */
      }) as Subscription,
      this.zeileSteigerungArt?.valueChanges.subscribe(async (steigerungArt) => {
        /* << on-zeile-steigerung-art-change    */
        /*    on-zeile-steigerung-art-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostZeileRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.zeile) {
        value.zeile = cleanObject(value.zeile);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.zeile) {
        payload.zeile = cleanObject(payload.zeile);
      }
      const response: IPostZeileResponse = await this.postZeile.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get zeileId() {
    return this.form.controls.zeile.get('id');
  }
  get zeileBericht() {
    return this.form.controls.zeile.get('bericht');
  }
  get zeileLand() {
    return this.form.controls.zeile.get('land');
  }
  get zeileBundesland() {
    return this.form.controls.zeile.get('bundesland');
  }
  get zeilePlz() {
    return this.form.controls.zeile.get('plz');
  }
  get zeileRegion() {
    return this.form.controls.zeile.get('region');
  }
  get zeileKreisStadt() {
    return this.form.controls.zeile.get('kreisStadt');
  }
  get zeileGemeindeBezirk() {
    return this.form.controls.zeile.get('gemeindeBezirk');
  }
  get zeileOrtsStadtTeil() {
    return this.form.controls.zeile.get('ortsStadtTeil');
  }
  get zeileGemeindeschluessel() {
    return this.form.controls.zeile.get('gemeindeschluessel');
  }
  get zeileTeilmarkt() {
    return this.form.controls.zeile.get('teilmarkt');
  }
  get zeileBereiche() {
    return this.form.controls.zeile.get('bereiche');
  }
  get zeileAssetklasse() {
    return this.form.controls.zeile.get('assetklasse');
  }
  get zeileObjektart() {
    return this.form.controls.zeile.get('objektart');
  }
  get zeileZustand() {
    return this.form.controls.zeile.get('zustand');
  }
  get zeileVertrag() {
    return this.form.controls.zeile.get('vertrag');
  }
  get zeileWohnlage() {
    return this.form.controls.zeile.get('wohnlage');
  }
  get zeileWflVon() {
    return this.form.controls.zeile.get('wflVon');
  }
  get zeileWflBis() {
    return this.form.controls.zeile.get('wflBis');
  }
  get zeileWflMittel() {
    return this.form.controls.zeile.get('wflMittel');
  }
  get zeileBjVon() {
    return this.form.controls.zeile.get('bjVon');
  }
  get zeileBjBis() {
    return this.form.controls.zeile.get('bjBis');
  }
  get zeileWohnNutzWert() {
    return this.form.controls.zeile.get('wohnNutzWert');
  }
  get zeileAusstattung() {
    return this.form.controls.zeile.get('ausstattung');
  }
  get zeileArtDesWertes() {
    return this.form.controls.zeile.get('artDesWertes');
  }
  get zeileEinheit() {
    return this.form.controls.zeile.get('einheit');
  }
  get zeileBezug() {
    return this.form.controls.zeile.get('bezug');
  }
  get zeileDurchschnitt() {
    return this.form.controls.zeile.get('durchschnitt');
  }
  get zeileMinimum() {
    return this.form.controls.zeile.get('minimum');
  }
  get zeileMaximum() {
    return this.form.controls.zeile.get('maximum');
  }
  get zeileSpitzenwerte() {
    return this.form.controls.zeile.get('spitzenwerte');
  }
  get zeileAnzahl() {
    return this.form.controls.zeile.get('anzahl');
  }
  get zeileSteigerung_1() {
    return this.form.controls.zeile.get('steigerung_1');
  }
  get zeileSteigerung_2() {
    return this.form.controls.zeile.get('steigerung_2');
  }
  get zeileSteigerung_3() {
    return this.form.controls.zeile.get('steigerung_3');
  }
  get zeileSteigerung_4() {
    return this.form.controls.zeile.get('steigerung_4');
  }
  get zeileSteigerung_5() {
    return this.form.controls.zeile.get('steigerung_5');
  }
  get zeileSteigerungArt() {
    return this.form.controls.zeile.get('steigerungArt');
  }
}
