import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AuthService } from '../../..';

@Component({
  selector: 'pbc-unauthorized',
  templateUrl: './unauthorized.page.html',
  styleUrls: ['./unauthorized.page.css'],
})
export class UnauthorizedPage implements OnDestroy, OnInit {
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.auth.$routes.subscribe(async (routes) => {
        if (!routes?.length) return;
        const visitedRoute = this.auth.$visitedRoute.getValue();
        if (!visitedRoute) return;
        this.auth.$visitedRoute.next(undefined);
        await this.router.navigateByUrl(visitedRoute);
      }),
    );
  }

  ngOnDestroy() {}
}
