<!---
  <div formGroupName="vorlage">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="initialen">{{ command.Inputs.INITIALEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="InitialenErrorTip">
        <ng-template #InitialenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INITIALEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="initialen" id="initialen" [placeholder]="command.Inputs.INITIALEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerKunden">{{ command.Inputs.FUER_KUNDEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerKundenErrorTip">
        <ng-template #FuerKundenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_KUNDEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="fuerKunden" id="fuerKunden" [shapes]="postVorlage.vorlageFuerKunden | async" [placeholder]="command.Inputs.FUER_KUNDEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerKundenGruppen">{{ command.Inputs.FUER_KUNDEN_GRUPPEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerKundenGruppenErrorTip">
        <ng-template #FuerKundenGruppenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_KUNDEN_GRUPPEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="fuerKundenGruppen" id="fuerKundenGruppen" [shapes]="postVorlage.vorlageFuerKundenGruppen | async" [placeholder]="command.Inputs.FUER_KUNDEN_GRUPPEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerKundenArten">{{ command.Inputs.FUER_KUNDEN_ARTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerKundenArtenErrorTip">
        <ng-template #FuerKundenArtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_KUNDEN_ARTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="fuerKundenArten" id="fuerKundenArten" [shapes]="postVorlage.vorlageFuerKundenArten | async" [placeholder]="command.Inputs.FUER_KUNDEN_ARTEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerObjektArten">{{ command.Inputs.FUER_OBJEKT_ARTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerObjektArtenErrorTip">
        <ng-template #FuerObjektArtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_OBJEKT_ARTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="fuerObjektArten" id="fuerObjektArten" [shapes]="postVorlage.vorlageFuerObjektArten | async" [placeholder]="command.Inputs.FUER_OBJEKT_ARTEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerBewertungsAnlaesse">{{ command.Inputs.FUER_BEWERTUNGS_ANLAESSE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerBewertungsAnlaesseErrorTip">
        <ng-template #FuerBewertungsAnlaesseErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_BEWERTUNGS_ANLAESSE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="fuerBewertungsAnlaesse" id="fuerBewertungsAnlaesse" [shapes]="postVorlage.vorlageFuerBewertungsAnlaesse | async" [placeholder]="command.Inputs.FUER_BEWERTUNGS_ANLAESSE.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="angewendet">{{ command.Inputs.ANGEWENDET.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AngewendetErrorTip">
        <ng-template #AngewendetErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANGEWENDET.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="angewendet" id="angewendet" [nzPlaceHolder]="command.Inputs.ANGEWENDET.placeholder"/>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <div formGroupName="vorlage">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="angewendet" id="angewendet" />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title }}</nz-form-label>
        <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial />
        </nz-form-control>
        <nz-form-control nzOffset="1" [nzSm]="2" [nzXs]="24" [nzErrorTip]="InitialenErrorTip">
          <ng-template #InitialenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INITIALEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="initialen" id="initialen" [placeholder]="command.Inputs.INITIALEN.placeholder" />
        </nz-form-control>
      </nz-form-item>

      <nz-divider id="bedingungen" nzText="Anwendungen" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerBewertungsAnlaesse">{{ command.Inputs.FUER_BEWERTUNGS_ANLAESSE.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerBewertungsAnlaesseErrorTip">
          <ng-template #FuerBewertungsAnlaesseErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_BEWERTUNGS_ANLAESSE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            mode="multiple"
            formControlName="fuerBewertungsAnlaesse"
            id="fuerBewertungsAnlaesse"
            [shapes]="postVorlage.vorlageFuerBewertungsAnlaesse | async"
            [placeholder]="'Alle Bewertungsanlässe'"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerObjektArten">{{ command.Inputs.FUER_OBJEKT_ARTEN.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerObjektArtenErrorTip">
          <ng-template #FuerObjektArtenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_OBJEKT_ARTEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            mode="multiple"
            formControlName="fuerObjektArten"
            id="fuerObjektArten"
            [shapes]="postVorlage.vorlageFuerObjektArten | async"
            [placeholder]="'Alle Objektarten'"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerKundenGruppen">{{ command.Inputs.FUER_KUNDEN_GRUPPEN.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerKundenGruppenErrorTip">
          <ng-template #FuerKundenGruppenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_KUNDEN_GRUPPEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            mode="multiple"
            formControlName="fuerKundenGruppen"
            id="fuerKundenGruppen"
            [shapes]="postVorlage.vorlageFuerKundenGruppen | async"
            [placeholder]="'Alle Kundengruppen'"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerKundenArten">{{ command.Inputs.FUER_KUNDEN_ARTEN.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerKundenArtenErrorTip">
          <ng-template #FuerKundenArtenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_KUNDEN_ARTEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            mode="multiple"
            formControlName="fuerKundenArten"
            id="fuerKundenArten"
            [shapes]="postVorlage.vorlageFuerKundenArten | async"
            [placeholder]="'Alle Kundenarten'"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fuerKunden">{{ command.Inputs.FUER_KUNDEN.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FuerKundenErrorTip">
          <ng-template #FuerKundenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FUER_KUNDEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value mode="multiple" formControlName="fuerKunden" id="fuerKunden" [shapes]="postVorlage.vorlageFuerKunden | async" [placeholder]="'Alle Kunden'"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            Vorlage Speichern
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
