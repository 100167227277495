import { AfterContentChecked, Component, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { IModel, ISelection } from 'pbc.types';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { BaseComponent } from '../../../base.components';

@Component({
  selector: 'pbc-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.css'],
})
export class MetaComponent extends BaseComponent implements OnInit, AfterContentChecked {
  @Input() set value(model: IModel) {
    this.$value.next(model);
  }
  $value = new BehaviorSubject<IModel | undefined>(undefined);
  @Input() margin = true;

  extended = false;

  $creator = new BehaviorSubject<ISelection | undefined>(undefined);
  $updator = new BehaviorSubject<ISelection | undefined>(undefined);

  @ViewChild('update', { static: false }) avatar!: NzAvatarComponent;

  override ngOnInit(): void {
    super.ngOnInit();
    combineLatest([this.meta.users$, this.$value])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([users, value]) => {
        if (!users?.length || !value) return;
        this.$updator.next(users?.find(({ value: id }) => id === value._updatedBy));
        this.$creator.next(users?.find(({ value: id }) => id === value._createdBy));
      });
  }

  ngAfterContentChecked(): void {
    if (!this.$updator.getValue()) return;
    this.avatar?.ngOnChanges();
    this.changeRef.detectChanges();
  }

  get debug(): string {
    const value = this.$value.getValue();
    return JSON.stringify({ id: value?.id, key: value?._partitionKey });
  }
}
