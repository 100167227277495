import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { FormularQueryFilterConfig, IFormularResponse } from 'fa-kt.types';

import { FormularService } from '../service';

@Component({
  selector: 'fa-kt-formular-filter',
  templateUrl: './formular-filter.component.html',
  styleUrls: ['./formular-filter.component.css'],
})
export class FormularFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public FormularFilterConfig: IFilterConfig<IFormularResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public formular: FormularService,
  ) {
    this.FormularFilterConfig = FormularQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.FormularFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.FormularFilterConfig[index].selections = shapes;
  }
}
