<cdk-virtual-scroll-viewport *ngIf="felder.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (felder.result$ | async).felder" (click)="set(entry)">
      <nz-list-item-meta [nzDescription]="ListDescription">
        <ng-template #ListDescription>
          <!--- << description    -->
          <!---    description >> -->
        </ng-template>
        <nz-list-item-meta-title>
          <a>
            <!--- << content    -->
            <strong>{{ entry.feld?.name }}</strong>
            <nz-divider nzType="vertical"></nz-divider>
            {{ entry.feld?.schluessel }}
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-typography nzType="secondary">{{ entry.feld?.art }}</span>
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-typography nzType="secondary">{{ entry.feld?.feldKategorie | type: (felder.feldFeldKategorie | async) }}</span>
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-typography nzType="secondary">{{ entry.feld?.feldUnterkategorie | type: (felder.feldFeldUnterkategorie | async) }}</span>
            <ng-container *ngIf="['option', 'optionPlus', 'mehrfachauswahlPlus'].includes(entry.feld?.art)">
              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography nzType="secondary">{{ entry.feldOptionen?.length }} Feld{{ entry.feldOptionen?.length === 1 ? '' : 'er' }}</span>
            </ng-container>
            <!---    content >> -->
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <!--- << extra    -->
          <!---    extra >> -->
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
