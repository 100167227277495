import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { SavedZeitenFiltersComponent } from '.';
import { ZeitenServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitenServiceModule
  ],
  declarations: [SavedZeitenFiltersComponent],
  exports: [SavedZeitenFiltersComponent],

})
export class SavedZeitenFiltersComponentModule { }
