import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBerichtsVorlagenResponseRow } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { BerichtsVorlagenService } from '../service';

@Component({
  selector: 'fa-kt-berichts-vorlagen-list',
  templateUrl: './berichts-vorlagen-list.component.html',
  styleUrls: ['./berichts-vorlagen-list.component.css'],
})
export class BerichtsVorlagenListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  constructor(public berichtsVorlagen: BerichtsVorlagenService) {}

  public set(berichtsVorlagen: IBerichtsVorlagenResponseRow) {}
}
