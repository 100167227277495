import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IDurchgangQuerySorter extends ISorter {
  'durchgang.id'?: ISorterExpression;
  'durchgang.formular'?: ISorterExpression;
  'durchgang.gutachten'?: ISorterExpression;
  'durchgang.projekt'?: ISorterExpression;
  'durchgang.besichtigung'?: ISorterExpression;
  'formular.id'?: ISorterExpression;
  'formular.name'?: ISorterExpression;
  'formular.initialen'?: ISorterExpression;
  'formular.objektArten'?: ISorterExpression;
  'formular.bewertungsAnlaesse'?: ISorterExpression;
  'formular.kundenArten'?: ISorterExpression;
  'formular.kunden'?: ISorterExpression;
  'formular.ohneFelder'?: ISorterExpression;
  'formular.kommentar'?: ISorterExpression;
  'feldKategorien.id'?: ISorterExpression;
  'feldKategorien.name'?: ISorterExpression;
  'feldKategorien.initialen'?: ISorterExpression;
  'feldKategorien.kommentar'?: ISorterExpression;
  'feldUnterkategorien.id'?: ISorterExpression;
  'feldUnterkategorien.feldKategorie'?: ISorterExpression;
  'feldUnterkategorien.name'?: ISorterExpression;
  'feldUnterkategorien.initialen'?: ISorterExpression;
  'feldUnterkategorien.kommentar'?: ISorterExpression;
  'felder.id'?: ISorterExpression;
  'felder.schluessel'?: ISorterExpression;
  'felder.name'?: ISorterExpression;
  'felder.kommentar'?: ISorterExpression;
  'felder.feldKategorie'?: ISorterExpression;
  'felder.feldUnterkategorie'?: ISorterExpression;
  'felder.art'?: ISorterExpression;
  'felder.format'?: ISorterExpression;
  'felder.voreinstellung'?: ISorterExpression;
  'feldOptionen.id'?: ISorterExpression;
  'feldOptionen.feld'?: ISorterExpression;
  'feldOptionen.option'?: ISorterExpression;
  'feldOptionen.akkusativ'?: ISorterExpression;
  'feldOptionen.nominativ'?: ISorterExpression;
  'feldOptionen.dativ'?: ISorterExpression;
  'feldOptionen.genitiv'?: ISorterExpression;
  'feldOptionen.standard'?: ISorterExpression;
  'eintraege.id'?: ISorterExpression;
  'eintraege.gutachten'?: ISorterExpression;
  'eintraege.feld'?: ISorterExpression;
  'eintraege.wert'?: ISorterExpression;
  'eintraege.wertExtra'?: ISorterExpression;
  'eintraege.akkusativ'?: ISorterExpression;
  'eintraege.nominativ'?: ISorterExpression;
  'eintraege.dativ'?: ISorterExpression;
  'eintraege.genitiv'?: ISorterExpression;
  'eintraege.achtung'?: ISorterExpression;
}

export const DurchgangQuerySorterConfig: ISorterConfig = [
  SorterConfig('durchgang.id', 'FORMULARE.Models.DURCHGANG.Propertys.ID.title'), 
  SorterConfig('durchgang.formular', 'FORMULARE.Models.DURCHGANG.Propertys.FORMULAR.title'), 
  SorterConfig('durchgang.gutachten', 'FORMULARE.Models.DURCHGANG.Propertys.GUTACHTEN.title'), 
  SorterConfig('durchgang.projekt', 'FORMULARE.Models.DURCHGANG.Propertys.PROJEKT.title'), 
  SorterConfig('durchgang.besichtigung', 'FORMULARE.Models.DURCHGANG.Propertys.BESICHTIGUNG.title'), 
  SorterConfig('formular.id', 'FORMULARE.Models.FORMULAR.Propertys.ID.title'), 
  SorterConfig('formular.name', 'FORMULARE.Models.FORMULAR.Propertys.NAME.title'), 
  SorterConfig('formular.initialen', 'FORMULARE.Models.FORMULAR.Propertys.INITIALEN.title'), 
  SorterConfig('formular.objektArten', 'FORMULARE.Models.FORMULAR.Propertys.OBJEKT_ARTEN.title'), 
  SorterConfig('formular.bewertungsAnlaesse', 'FORMULARE.Models.FORMULAR.Propertys.BEWERTUNGS_ANLAESSE.title'), 
  SorterConfig('formular.kundenArten', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN_ARTEN.title'), 
  SorterConfig('formular.kunden', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN.title'), 
  SorterConfig('formular.ohneFelder', 'FORMULARE.Models.FORMULAR.Propertys.OHNE_FELDER.title'), 
  SorterConfig('formular.kommentar', 'FORMULARE.Models.FORMULAR.Propertys.KOMMENTAR.title'), 
  SorterConfig('feldKategorien.id', 'FELDER.Models.FELD_KATEGORIE.Propertys.ID.title'), 
  SorterConfig('feldKategorien.name', 'FELDER.Models.FELD_KATEGORIE.Propertys.NAME.title'), 
  SorterConfig('feldKategorien.initialen', 'FELDER.Models.FELD_KATEGORIE.Propertys.INITIALEN.title'), 
  SorterConfig('feldKategorien.kommentar', 'FELDER.Models.FELD_KATEGORIE.Propertys.KOMMENTAR.title'), 
  SorterConfig('feldUnterkategorien.id', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.ID.title'), 
  SorterConfig('feldUnterkategorien.feldKategorie', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.FELD_KATEGORIE.title'), 
  SorterConfig('feldUnterkategorien.name', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.NAME.title'), 
  SorterConfig('feldUnterkategorien.initialen', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.INITIALEN.title'), 
  SorterConfig('feldUnterkategorien.kommentar', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.KOMMENTAR.title'), 
  SorterConfig('felder.id', 'FELDER.Models.FELD.Propertys.ID.title'), 
  SorterConfig('felder.schluessel', 'FELDER.Models.FELD.Propertys.SCHLUESSEL.title'), 
  SorterConfig('felder.name', 'FELDER.Models.FELD.Propertys.NAME.title'), 
  SorterConfig('felder.kommentar', 'FELDER.Models.FELD.Propertys.KOMMENTAR.title'), 
  SorterConfig('felder.feldKategorie', 'FELDER.Models.FELD.Propertys.FELD_KATEGORIE.title'), 
  SorterConfig('felder.feldUnterkategorie', 'FELDER.Models.FELD.Propertys.FELD_UNTERKATEGORIE.title'), 
  SorterConfig('felder.art', 'FELDER.Models.FELD.Propertys.ART.title'), 
  SorterConfig('felder.format', 'FELDER.Models.FELD.Propertys.FORMAT.title'), 
  SorterConfig('felder.voreinstellung', 'FELDER.Models.FELD.Propertys.VOREINSTELLUNG.title'), 
  SorterConfig('feldOptionen.id', 'FELDER.Models.FELD_OPTION.Propertys.ID.title'), 
  SorterConfig('feldOptionen.feld', 'FELDER.Models.FELD_OPTION.Propertys.FELD.title'), 
  SorterConfig('feldOptionen.option', 'FELDER.Models.FELD_OPTION.Propertys.OPTION.title'), 
  SorterConfig('feldOptionen.akkusativ', 'FELDER.Models.FELD_OPTION.Propertys.AKKUSATIV.title'), 
  SorterConfig('feldOptionen.nominativ', 'FELDER.Models.FELD_OPTION.Propertys.NOMINATIV.title'), 
  SorterConfig('feldOptionen.dativ', 'FELDER.Models.FELD_OPTION.Propertys.DATIV.title'), 
  SorterConfig('feldOptionen.genitiv', 'FELDER.Models.FELD_OPTION.Propertys.GENITIV.title'), 
  SorterConfig('feldOptionen.standard', 'FELDER.Models.FELD_OPTION.Propertys.STANDARD.title'), 
  SorterConfig('eintraege.id', 'GUTACHTEN.Models.EINTRAG.Propertys.ID.title'), 
  SorterConfig('eintraege.gutachten', 'GUTACHTEN.Models.EINTRAG.Propertys.GUTACHTEN.title'), 
  SorterConfig('eintraege.feld', 'GUTACHTEN.Models.EINTRAG.Propertys.FELD.title'), 
  SorterConfig('eintraege.wert', 'GUTACHTEN.Models.EINTRAG.Propertys.WERT.title'), 
  SorterConfig('eintraege.wertExtra', 'GUTACHTEN.Models.EINTRAG.Propertys.WERT_EXTRA.title'), 
  SorterConfig('eintraege.akkusativ', 'GUTACHTEN.Models.EINTRAG.Propertys.AKKUSATIV.title'), 
  SorterConfig('eintraege.nominativ', 'GUTACHTEN.Models.EINTRAG.Propertys.NOMINATIV.title'), 
  SorterConfig('eintraege.dativ', 'GUTACHTEN.Models.EINTRAG.Propertys.DATIV.title'), 
  SorterConfig('eintraege.genitiv', 'GUTACHTEN.Models.EINTRAG.Propertys.GENITIV.title'), 
  SorterConfig('eintraege.achtung', 'GUTACHTEN.Models.EINTRAG.Propertys.ACHTUNG.title'), 
];
