import { IFilter, IFilterConfig } from "pbc.types";

import { IVermerk } from '.';

export interface IVermerkFilter extends IFilter<IVermerk> {}

export const VermerkFilterConfig: IFilterConfig<IVermerk> = [];

export type IVermerkField =
  'vermerk.id'|
  'vermerk.projekt'|
  'vermerk.gutachten'|
  'vermerk.kunde'|
  'vermerk.antwortAuf'|
  'vermerk.verfasser'|
  'vermerk.kommentar'|
  'vermerk.externSichtbar'
;
