import { NgModule } from '@angular/core';

import {
  PostRechnungsVorlageCommandServiceModule,
  PostRechnungsVorlageCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostRechnungsVorlageCommandServiceModule,
    PostRechnungsVorlageCommandComponentModule
  ],
  exports: [
    PostRechnungsVorlageCommandServiceModule,
    PostRechnungsVorlageCommandComponentModule
  ]
})
export class PostRechnungsVorlageCommandModule { }
