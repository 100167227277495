<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="profil.search$.next($event)" [search]="profil.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-profil-filter [style.width]="'100%'"></fa-kt-profil-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-profil-sorter [style.width]="'100%'"></fa-kt-profil-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-profil-list [style.width]="'100%'" (select)="setProfil($event)" [loading]="$loading | async"></fa-kt-profil-list>


  <fa-kt-post-mitarbeiter [style.width]="'100%'"  [value]="$postMitarbeiter | async" (finished)="finishedPostMitarbeiter($event)"></fa-kt-post-mitarbeiter>
    


-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <fa-kt-post-mitarbeiter [style.width]="'100%'" [value]="$postMitarbeiter | async" (finished)="finishedPostMitarbeiter()"></fa-kt-post-mitarbeiter>
    </nz-col>
    <nz-col nzSpan="24">
      <pbc-ad-settings [id]="auth.$id | async" [readonly]="!(auth.$isAdmin | async)"></pbc-ad-settings>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
