import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostOrdneBesichtigungenCommandShapes, IPostOrdneBesichtigungenRequest, IPostOrdneBesichtigungenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostOrdneBesichtigungenCommandService extends PostCommandService<IPostOrdneBesichtigungenRequest, IPostOrdneBesichtigungenResponse, IPostOrdneBesichtigungenCommandShapes> {
  readonly route = 'besichtigungen/ordne-besichtigungen';
  override dependencies = [];

  getBesichtigungenBesichtigungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigungen.besichtigungsStatus']?.find((v) => v.value === value);
  }

  get besichtigungenBesichtigungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigungen.besichtigungsStatus'] as ISelection[]) : [])));
  }

  getBesichtigungenMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['besichtigungen.mitarbeiter']?.find((v) => v.value === value);
  }

  get besichtigungenMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['besichtigungen.mitarbeiter'] as ISelection[]) : [])));
  }
}
