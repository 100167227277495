import { NgModule } from '@angular/core';

import {
  PostOrdneFeldOptionenCommandServiceModule,
  PostOrdneFeldOptionenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostOrdneFeldOptionenCommandServiceModule,
    PostOrdneFeldOptionenCommandComponentModule
  ],
  exports: [
    PostOrdneFeldOptionenCommandServiceModule,
    PostOrdneFeldOptionenCommandComponentModule
  ]
})
export class PostOrdneFeldOptionenCommandModule { }
