import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { ITourStatiResponse } from '.';

export interface ITourStatiQueryFilter extends IFilter<ITourStatiResponse> {}

export const TourStatiQueryFilterConfig: IFilterConfig<ITourStatiResponse> = [];

export interface ITourStatiQueryShapes extends IShape {}

export type ITourStatiQueryField = 'tourStati.id' | 'tourStati.name' | 'tourStati.beschreibung' | '';
