import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostKopierenCommandServiceModule } from '..';
import { PostKopierenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostKopierenCommandServiceModule
  ],
  declarations: [PostKopierenCommandComponent],
  exports: [PostKopierenCommandComponent],

})
export class PostKopierenCommandComponentModule { }
