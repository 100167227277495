import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, ISitemapModel, SITEMAP } from 'pbc.angular';

import { IBereich, IPostBereichResponse } from 'fa-kt.types';
import { BereichsService } from '../../../query';
import { PostBereichCommandService } from '../service';

interface IFormValue {
  bereich?: IBereich;
}

@Component({
  selector: 'fa-kt-post-bereich',
  templateUrl: './post-bereich.component.html',
  styleUrls: ['./post-bereich.component.css'],
})
export class PostBereichCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;
  model!: ISitemapModel;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IFormValue) {
    this.patch(value);
  }
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostBereichResponse> = new EventEmitter<IPostBereichResponse>();

  $areal = new BehaviorSubject<any>(undefined);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private actions: ActionService,
    private message: NzMessageService,
    public bereichs: BereichsService,
    public postBereich: PostBereichCommandService /* << dependencies    */ /*    dependencies >> */,
  ) {
    this.command = sitemap.GEO.Commands.POST_BEREICH;
    this.model = sitemap.GEO.Models.BEREICH;
    /* << constructor    */
    /*    constructor >> */
    this.form = this.fb.group({
      bereich: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        kommentar: [null, []],
        areal: [null, []],
      }),
    });
    this.patch();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.form.valid);
        if (this.form.valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.id?.valueChanges.subscribe(async (id) => {
        /* << on-id-change    */
        /*    on-id-change >> */
      }) as Subscription,
      this.name?.valueChanges.subscribe(async (name) => {
        /* << on-name-change    */
        /*    on-name-change >> */
      }) as Subscription,
      this.kommentar?.valueChanges.subscribe(async (kommentar) => {
        /* << on-kommentar-change    */
        /*    on-kommentar-change >> */
      }) as Subscription,
      this.areal?.valueChanges.subscribe(async (areal) => {
        /* << on-areal-change    */
        this.$areal.next(areal);
        /*    on-areal-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IFormValue) {
    let bereich = {
      id: null,
      name: null,
      kommentar: null,
      areal: null,
    };
    if (value && value.bereich) {
      bereich = {
        ...bereich,
        ...cleanObject(value.bereich),
      };
    }
    this.form.patchValue({ bereich });
    /* << custom-stuff    */
    this.$areal.next(bereich.areal);
    /*    custom-stuff >> */
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload && payload.bereich) {
        payload.bereich = cleanObject(payload.bereich);
      }
      const response: IPostBereichResponse | undefined = await this.postBereich.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls.objektArt.get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get id() {
    return this.form.controls.bereich.get('id');
  }

  get name() {
    return this.form.controls.bereich.get('name');
  }

  get kommentar() {
    return this.form.controls.bereich.get('kommentar');
  }

  get areal() {
    return this.form.controls.bereich.get('areal');
  }

  /* << methods    */
  setAreal(shape: any) {
    this.areal?.patchValue(shape);
    this.$areal.next(shape);
  }
  /*    methods >> */
}
