import { ArrayReferenceFilterConfig, IArrayReferenceFilter, IFilter, IFilterConfig, INumberFilter, ISelection, IShape, NumberFilterConfig } from 'pbc.types';

import { IBereich } from 'fa-kt.types';
import { IDurchsuchenResponse } from '.';

export interface IDurchsuchenQueryFilter extends IFilter<IDurchsuchenResponse> {
  'zeile.bereiche'?: IArrayReferenceFilter<IBereich>;
  'zeile.steigerung_1'?: INumberFilter;
  'zeile.steigerung_2'?: INumberFilter;
  'zeile.steigerung_3'?: INumberFilter;
  'zeile.steigerung_4'?: INumberFilter;
  'zeile.steigerung_5'?: INumberFilter;
}

export const DurchsuchenQueryFilterConfig: IFilterConfig<IDurchsuchenResponse> = [
  ArrayReferenceFilterConfig<IBereich>('zeile.bereiche', 'MARKT.Models.ZEILE.Propertys.BEREICHE.title'),
  NumberFilterConfig('zeile.steigerung_1', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_1.title'),
  NumberFilterConfig('zeile.steigerung_2', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_2.title'),
  NumberFilterConfig('zeile.steigerung_3', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_3.title'),
  NumberFilterConfig('zeile.steigerung_4', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_4.title'),
  NumberFilterConfig('zeile.steigerung_5', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_5.title'),
];

export interface IDurchsuchenQueryShapes extends IShape {
  'zeile.bereiche': ISelection[];
  'zeile.steigerungArt': ISelection[];
}

export type IDurchsuchenQueryField =
  | 'herausgeber.id'
  | 'herausgeber.name'
  | 'herausgeber.website'
  | 'herausgeber.outlookKontaktes'
  | 'herausgeber.kommentar'
  | 'zeile.id'
  | 'zeile.bericht'
  | 'zeile.land'
  | 'zeile.bundesland'
  | 'zeile.plz'
  | 'zeile.region'
  | 'zeile.kreisStadt'
  | 'zeile.gemeindeBezirk'
  | 'zeile.ortsStadtTeil'
  | 'zeile.gemeindeschluessel'
  | 'zeile.teilmarkt'
  | 'zeile.bereiche'
  | 'zeile.assetklasse'
  | 'zeile.objektart'
  | 'zeile.zustand'
  | 'zeile.vertrag'
  | 'zeile.wohnlage'
  | 'zeile.wflVon'
  | 'zeile.wflBis'
  | 'zeile.wflMittel'
  | 'zeile.bjVon'
  | 'zeile.bjBis'
  | 'zeile.wohnNutzWert'
  | 'zeile.ausstattung'
  | 'zeile.artDesWertes'
  | 'zeile.einheit'
  | 'zeile.bezug'
  | 'zeile.durchschnitt'
  | 'zeile.minimum'
  | 'zeile.maximum'
  | 'zeile.spitzenwerte'
  | 'zeile.anzahl'
  | 'zeile.steigerung_1'
  | 'zeile.steigerung_2'
  | 'zeile.steigerung_3'
  | 'zeile.steigerung_4'
  | 'zeile.steigerung_5'
  | 'zeile.steigerungArt'
  | '';
