import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IFelderRequest, IFelderResponse } from 'fa-kt.types';
import { PostReferenzenHochladenCommandService } from '../../commands';
import { FelderService } from '../../querys';

export interface IFelderPageResolverResult {
  felder?: IFelderResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class FelderPageResolver implements Resolve<IFelderPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private felder: FelderService,
    private postReferenzenHochladen: PostReferenzenHochladenCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IFelderPageResolverResult> {
    this.loading.resolving();
    const requests = {
      felder: {} as IFelderRequest,
    };

    const log = (error: any) => console.error(error);
    const [felder] = await Promise.all([this.felder.request(requests.felder).catch(log)]);
    this.loading.resolved();
    return {
      felder,
    };
  }
}
