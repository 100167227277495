import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteOrtKategorieCommandServiceModule } from '..';
import { DeleteOrtKategorieCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteOrtKategorieCommandServiceModule
  ],
  declarations: [DeleteOrtKategorieCommandComponent],
  exports: [DeleteOrtKategorieCommandComponent],

})
export class DeleteOrtKategorieCommandComponentModule { }
