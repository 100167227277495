import { NgModule } from '@angular/core';
import {
  BewertungsAnlasssFilterComponentModule,
  BewertungsAnlasssSorterComponentModule,
  BewertungsAnlasssListComponentModule
} from '.';

@NgModule({
  imports: [
    BewertungsAnlasssFilterComponentModule,
    BewertungsAnlasssSorterComponentModule,
    BewertungsAnlasssListComponentModule
  ],
  exports: [
    BewertungsAnlasssFilterComponentModule,
    BewertungsAnlasssSorterComponentModule,
    BewertungsAnlasssListComponentModule
  ],
})
export class BewertungsAnlasssQueryModule { }