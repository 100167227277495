<!--

  <fa-kt-post-dokument-hinterlegen [style.width]="'100%'" [value]="$postDokumentHinterlegen | async" (finished)="finishedPostDokumentHinterlegen($event)"></fa-kt-post-dokument-hinterlegen>



-->
<!--- << custom    -->
<nz-row>
  <nz-col nzSpan="18">
    <nz-alert *ngIf="kunde && kunde.anforderungenNotizen" nzType="info" [nzMessage]="kunde.anforderungenNotizen" nzShowIcon></nz-alert>
  </nz-col>
  <nz-col nzSpan="6">
    <nz-space style="margin: 4px; float: right">
      <button *nzSpaceItem nz-button nzType="dashed" (click)="download()">
        <span nz-icon nzType="download" nzTheme="outline"></span>
        Herunterladen
      </button>
      <button *nzSpaceItem nz-button [disabled]="!gespeicherte.length" nzType="primary" (click)="submitPostDokumentHinterlegen({ erforderlichesDokument: gespeicherte })">
        {{ gespeicherte.length }} Dokumente speichern
        <i nz-icon nzType="save" nzTheme="outline"></i>
      </button>
    </nz-space>
  </nz-col>
  <nz-col nzSpan="9"> </nz-col>
  <nz-col nzSpan="3">
    <span>Erhalten Am</span>
  </nz-col>
  <nz-col nzSpan="3">
    <span>Dokument Vom</span>
  </nz-col>
  <nz-col nzSpan="3">
    <label nz-checkbox [(ngModel)]="erforderlich"> Nur erforderliche </label>
  </nz-col>
</nz-row>
<nz-list *ngIf="dokumentenAnforderungen" nzSize="large" [nzLoading]="$loading | async">
  <nz-list-item
    *ngFor="let erforderlichesDokument of dokumentenAnforderungen | order: 'order' | nurErforderliche: erforderlich; let i = index"
    [style.backgroundColor]="i % 2 === 0 ? 'gainsboro' : ''"
  >
    <nz-row style="width: 100%">
      <nz-col nzSpan="9">
        <a>
          <strong *ngIf="erforderlichesDokument.erforderlich">
            {{ erforderlichesDokument.name }}
          </strong>
          <span *ngIf="!erforderlichesDokument.erforderlich" style="font-weight: 300">
            {{ erforderlichesDokument.name }}
          </span>
        </a>
      </nz-col>
      <nz-col nzSpan="3">
        <nz-date-picker [(ngModel)]="erforderlichesDokument.erhaltenAm" nzFormat="dd.MM.yyyy" (ngModelChange)="save(erforderlichesDokument)"></nz-date-picker>
      </nz-col>
      <nz-col nzSpan="3">
        <nz-date-picker [(ngModel)]="erforderlichesDokument.dokumentVom" nzFormat="dd.MM.yyyy" (ngModelChange)="save(erforderlichesDokument)"></nz-date-picker>
      </nz-col>
      <nz-col nzSpan="3">
        <nz-space>
          <label nz-checkbox *nzSpaceItem [(ngModel)]="erforderlichesDokument.erforderlich" (ngModelChange)="save(erforderlichesDokument)">
            <span nz-typography nzType="secondary">
              {{ erforderlichesDokument.erforderlich ? 'erforderlich' : 'nicht erforderlich' }}
            </span>
          </label>
        </nz-space>
      </nz-col>
      <nz-col nzSpan="6">
        <nz-space>
          <nz-radio-group *nzSpaceItem [(ngModel)]="erforderlichesDokument.fortschritt" (ngModelChange)="save(erforderlichesDokument)" nzButtonStyle="solid">
            <label nz-radio-button [nzValue]="0" [style.color]="'red'">Nicht vorhanden</label>
            <label nz-radio-button [nzValue]="50" [style.color]="'orange'">Unvollständig</label>
            <label nz-radio-button [nzValue]="100" [style.color]="'green'">Vorhanden</label>
          </nz-radio-group>
        </nz-space>
      </nz-col>
    </nz-row>
  </nz-list-item>
</nz-list>
<!---    custom >> -->
