import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { BesichtigungenCommandsModule } from './commands';
import { BesichtigungenComponentsModule } from './components';
import { TourenPage, TourenPageResolver, TourPage, TourPageResolver } from './pages';
import { BesichtigungenQuerysModule, OffeneGutachtenService, TourenSelectService, BesichtigungenSelectService, TourenService, TourService } from './querys';
import {
  BesichtigungenSettingsModule,
  BesichtigungsStatiPage,
  BesichtigungsStatiPageResolver,
  BesichtigungsStatiService,
  BesichtigungsStatiServiceModule,
  FotoKategorienPage,
  FotoKategorienPageResolver,
  TourStatiPage,
  TourStatiPageResolver,
  TourStatiService,
  TourStatiServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'besichtigungen',
    children: [
      {
        path: 'touren',
        component: TourenPage,
        resolve: {
          querys: TourenPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['besichtigungen'],
            roles: [
              'GET_FORMULARE',
              'GET_MITARBEITER',
              'GET_OFFENE_GUTACHTEN',
              'GET_TOUR',
              'GET_TOUREN',
              'DELETE_BESICHTIGUNG',
              'DELETE_TOUR',
              'POST_BESICHTIGUNG',
              'POST_DURCHGANG',
              'POST_ORDNE_BESICHTIGUNGEN',
              'POST_START_TOUR',
              'POST_TOUR',
            ],
          },
        },
      },
      {
        path: 'tour',
        component: TourPage,
        resolve: {
          querys: TourPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['besichtigungen'],
            roles: ['GET_FORMULARE', 'GET_OFFENE_GUTACHTEN', 'GET_TOUR', 'DELETE_BESICHTIGUNG', 'DELETE_TOUR', 'POST_BESICHTIGUNG', 'POST_DURCHGANG', 'POST_ORDNE_BESICHTIGUNGEN', 'POST_TOUR'],
          },
        },
      },
    ],
  },
  {
    path: 'besichtigungen',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'besichtigungs-stati',
            component: BesichtigungsStatiPage,
            resolve: {
              querys: BesichtigungsStatiPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_BESICHTIGUNGS_STATI'],
                  write: ['POST_BESICHTIGUNGS_STATUS', 'DELETE_BESICHTIGUNGS_STATUS'],
                },
              },
            },
          },
          {
            path: 'foto-kategorien',
            component: FotoKategorienPage,
            resolve: {
              querys: FotoKategorienPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_BESICHTIGUNGS_STATI'],
                  write: ['POST_BESICHTIGUNGS_STATUS', 'DELETE_BESICHTIGUNGS_STATUS'],
                },
              },
            },
          },
          {
            path: 'tour-stati',
            component: TourStatiPage,
            resolve: {
              querys: TourStatiPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_TOUR_STATI'],
                  write: ['POST_TOUR_STATUS', 'DELETE_TOUR_STATUS'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [TourenPage, TourPage, BesichtigungsStatiPage, TourStatiPage, FotoKategorienPage],
  imports: [
    LibsCommonAngularModule,
    BesichtigungenSettingsModule,
    BesichtigungenComponentsModule,
    BesichtigungenCommandsModule,
    BesichtigungenQuerysModule,
    BesichtigungsStatiServiceModule,
    TourStatiServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [BesichtigungenComponentsModule, BesichtigungenSettingsModule, BesichtigungenCommandsModule, BesichtigungenQuerysModule, BesichtigungsStatiServiceModule, TourStatiServiceModule],
})
export class BesichtigungenModule {
  static forRoot(): ModuleWithProviders<BesichtigungenModule> {
    return {
      ngModule: BesichtigungenModule,
      providers: [OffeneGutachtenService, TourService, TourenService, TourenSelectService, BesichtigungenSelectService, BesichtigungsStatiService, TourStatiService],
    };
  }
}
