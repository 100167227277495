import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteRechnungCommandServiceModule } from '..';
import { DeleteRechnungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteRechnungCommandServiceModule
  ],
  declarations: [DeleteRechnungCommandComponent],
  exports: [DeleteRechnungCommandComponent],

})
export class DeleteRechnungCommandComponentModule { }
