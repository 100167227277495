import { IFilter, IFilterConfig } from "pbc.types";

import { IZeitArt } from '.';

export interface IZeitArtFilter extends IFilter<IZeitArt> {}

export const ZeitArtFilterConfig: IFilterConfig<IZeitArt> = [];

export type IZeitArtField =
  'zeitArt.id'|
  'zeitArt.name'|
  'zeitArt.beschreibung'
;
