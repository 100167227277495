import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LibsCommonAngularModule } from 'pbc.angular';

import { KommunikationCommandsModule } from './commands';
import { KommunikationComponentsModule } from './components';
import { KommunikationQuerysModule } from './querys';

const routes: Routes = [];

@NgModule({
  imports: [LibsCommonAngularModule, KommunikationComponentsModule, KommunikationCommandsModule, KommunikationQuerysModule, RouterModule.forChild(routes)],
  exports: [KommunikationComponentsModule, KommunikationCommandsModule, KommunikationQuerysModule],
})
export class KommunikationModule {
  static forRoot(): ModuleWithProviders<KommunikationModule> {
    return {
      ngModule: KommunikationModule,
      providers: [],
    };
  }
}
