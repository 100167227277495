<!--

  <fa-kt-post-rechnung-runterzaehlen [style.width]="'100%'" [value]="$postRechnungRunterzaehlen | async" (finished)="finishedPostRechnungRunterzaehlen($event)"></fa-kt-post-rechnung-runterzaehlen>
    
  <fa-kt-post-rechnungen-hochzaehlen [style.width]="'100%'" [value]="$postRechnungenHochzaehlen | async" (finished)="finishedPostRechnungenHochzaehlen($event)"></fa-kt-post-rechnungen-hochzaehlen>
    


-->
<!--- << custom    -->
<nz-row [nzGutter]="16" *ngIf="auth.$isFinanzen | async">
  <nz-col>
    <button nz-button nzType="dashed" (click)="countDown()" [nzLoading]="$loading | async"><i nz-icon nzType="down" nzTheme="outline"></i></button>
  </nz-col>
  <nz-col>
    <label style="line-height: 32px;">Aktueller Rechnungszähler: {{ zaehler ? zaehler : 'Neue Nummer erzeugen' }}</label>
  </nz-col>
  <nz-col>
    <button nz-button nzType="dashed" (click)="countUp()" [nzLoading]="$loading | async"><i nz-icon nzType="up" nzTheme="outline"></i></button>
  </nz-col>
</nz-row>
<!---    custom >> -->
