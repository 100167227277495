import { NgModule } from '@angular/core';

import { DeleteKonstanteCommandModule, KonstantesQueryModule, PostKonstanteCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostKonstanteCommandModule, DeleteKonstanteCommandModule, KonstantesQueryModule],
  exports: [PostKonstanteCommandModule, DeleteKonstanteCommandModule, KonstantesQueryModule],
})
export class KonstantesSettingsModule {}
