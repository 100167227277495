import { IFilter, IFilterConfig, ISelection, IShape, ISingleReferenceFilter, NumberFilterConfig, SingleReferenceFilterConfig } from 'pbc.types';

import { IObjektArt, IObjektNutzung } from 'fa-kt.types';
import { IObjektArtsResponse } from '.';

export interface IObjektArtsQueryFilter extends IFilter<IObjektArtsResponse> {
  'objektArts.objektNutzung'?: ISingleReferenceFilter<IObjektNutzung>;
  'objektArts.besichtigungsZeit'?: ISingleReferenceFilter<IObjektArt>;
}

export const ObjektArtsQueryFilterConfig: IFilterConfig<IObjektArtsResponse> = [
  SingleReferenceFilterConfig<IObjektNutzung>('objektArts.objektNutzung', 'OBJEKTE.Models.OBJEKT_ART.Propertys.OBJEKT_NUTZUNG.title'),
  NumberFilterConfig('objektArts.besichtigungsZeit', 'OBJEKTE.Models.OBJEKT_ART.Propertys.BESICHTIGUNGS_ZEIT.title'),
];

export interface IObjektArtsQueryShapes extends IShape {
  'objektArts.objektNutzung': ISelection[];
}

export type IObjektArtsQueryField = 'objektArts.id' | 'objektArts.objektNutzung' | 'objektArts.name' | 'objektArts.initialen' | 'objektArts.besichtigungsZeit' | 'objektArts.beschreibung' | '';
