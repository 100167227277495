import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IFeldKategoriesResponse } from 'fa-kt.types';
import { PostFeldKategorieCommandService } from '../commands';
import { FeldKategoriesService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class FeldKategoriesPageResolver implements Resolve<IFeldKategoriesResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private feldKategories: FeldKategoriesService,
    private postFeldKategorie: PostFeldKategorieCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IFeldKategoriesResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [feldKategories] = await Promise.all([this.feldKategories.request({}).catch(log), this.postFeldKategorie.prepare(true).catch(log)]);
    this.loading.resolved();
    return feldKategories;
  }
}
