import { IFilter, IFilterConfig } from "pbc.types";

import { IMitarbeiter } from '.';

export interface IMitarbeiterFilter extends IFilter<IMitarbeiter> {}

export const MitarbeiterFilterConfig: IFilterConfig<IMitarbeiter> = [];

export type IMitarbeiterField =
  'mitarbeiter.id'|
  'mitarbeiter.email'|
  'mitarbeiter.anzeigename'|
  'mitarbeiter.bild'|
  'mitarbeiter.addresse'|
  'mitarbeiter.vorname'|
  'mitarbeiter.nachname'|
  'mitarbeiter.telefon'|
  'mitarbeiter.standort'|
  'mitarbeiter.rollen'|
  'mitarbeiter.stundenlohn'|
  'mitarbeiter.inaktiv'
;
