import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostAbteilungCommandServiceModule } from '..';
import { PostAbteilungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostAbteilungCommandServiceModule
  ],
  declarations: [PostAbteilungCommandComponent],
  exports: [PostAbteilungCommandComponent],

})
export class PostAbteilungCommandComponentModule { }
