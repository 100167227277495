import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { VorlagenServiceModule } from '..';
import { VorlagenListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlagenServiceModule
  ],
  declarations: [VorlagenListComponent],
  exports: [VorlagenListComponent],

})
export class VorlagenListComponentModule { }
