import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { VermerkeComponent } from '.';
import { DeleteVermerkCommandModule, PostVermerkCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostVermerkCommandModule, DeleteVermerkCommandModule],
  declarations: [VermerkeComponent],
  exports: [VermerkeComponent],
})
export class VermerkeComponentModule {}
