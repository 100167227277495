<!--

  <fa-kt-delete-honorar-vereinbarung [style.width]="'100%'" [value]="$deleteHonorarVereinbarung | async" (finished)="finishedDeleteHonorarVereinbarung($event)"></fa-kt-delete-honorar-vereinbarung>

  <fa-kt-post-honorar-vereinbarung [style.width]="'100%'" [value]="$postHonorarVereinbarung | async" (finished)="finishedPostHonorarVereinbarung($event)"></fa-kt-post-honorar-vereinbarung>



-->
<!--- << custom    -->
<nz-divider nzText="Honorar-Vereinbarungen" nzOrientation="left"></nz-divider>
<nz-list *ngIf="honorarVereinbarungen" nzSize="large" [nzLoading]="$loading | async">
  <nz-list-item *ngFor="let honorarVereinbarung of honorarVereinbarungen | order: 'abMarktwert'" (click)="openHonorarVereinbarung(honorarVereinbarung)">
    <nz-list-item-meta [nzDescription]="ListDescription">
      <ng-template #ListDescription> </ng-template>
      <nz-list-item-meta-title>
        <a>
          {{ honorarVereinbarung.bisMarktwert ? 'Bis Marktwert ' + (honorarVereinbarung.bisMarktwert | currency: 'EUR') : '' }}
          <nz-divider nzType="vertical"></nz-divider>
          <span nz-typography nzType="secondary" nz-tooltip nzTooltipTitle="Wohnen">
            {{ honorarVereinbarung.lohnWohnen | currency: 'EUR' }}
          </span>
          <nz-divider nzType="vertical"></nz-divider>
          <span nz-typography nzType="secondary" nz-tooltip nzTooltipTitle="Gewerbe">
            {{ honorarVereinbarung.lohnGewerbe | currency: 'EUR' }}
          </span>
          <nz-divider nzType="vertical"></nz-divider>
          <span nz-typography nzType="secondary" nz-tooltip nzTooltipTitle="Spezial">
            {{ honorarVereinbarung.lohnSpezial | currency: 'EUR' }}
          </span>
        </a>
      </nz-list-item-meta-title>
    </nz-list-item-meta>
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
  <nz-list-item>
    <button nz-button (click)="openHonorarVereinbarung({})" nzType="dashed" [disabled]="disabled">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Honorar-Vereinbarung hinzufügen
    </button>
  </nz-list-item>
</nz-list>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="openHonorarVereinbarung(null)" [nzVisible]="!!($postHonorarVereinbarung | async)">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> HonorarVereinbarung </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-delete-honorar-vereinbarung [style.width]="'100%'" [value]="$deleteHonorarVereinbarung | async" (finished)="finishedDeleteHonorarVereinbarung()"></fa-kt-delete-honorar-vereinbarung>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-post-honorar-vereinbarung [style.width]="'100%'" [value]="$postHonorarVereinbarung | async" (finished)="finishedPostHonorarVereinbarung()"></fa-kt-post-honorar-vereinbarung>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
    <pbc-meta [value]="($postHonorarVereinbarung | async)?.honorarVereinbarung"></pbc-meta>
  </ng-template>
</nz-modal>
<!---    custom >> -->
