<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="gutachten.search$.next($event)" [search]="gutachten.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-gutachten-filter [style.width]="'100%'"></fa-kt-gutachten-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-gutachten-sorter [style.width]="'100%'"></fa-kt-gutachten-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-gutachten-list [style.width]="'100%'" (select)="setGutachten($event)" [loading]="loading$ | async"></fa-kt-gutachten-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="kunde.search$.next($event)" [search]="kunde.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-kunde-filter [style.width]="'100%'"></fa-kt-kunde-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-kunde-sorter [style.width]="'100%'"></fa-kt-kunde-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-kunde-list [style.width]="'100%'" (select)="setKunde($event)" [loading]="loading$ | async"></fa-kt-kunde-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="objekte.search$.next($event)" [search]="objekte.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-objekte-filter [style.width]="'100%'"></fa-kt-objekte-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-objekte-sorter [style.width]="'100%'"></fa-kt-objekte-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-objekte-list [style.width]="'100%'" (select)="setObjekte($event)" [loading]="loading$ | async"></fa-kt-objekte-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="projekt.search$.next($event)" [search]="projekt.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-projekt-filter [style.width]="'100%'"></fa-kt-projekt-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-projekt-sorter [style.width]="'100%'"></fa-kt-projekt-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-projekt-list [style.width]="'100%'" (select)="setProjekt($event)" [loading]="loading$ | async"></fa-kt-projekt-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="projekte.search$.next($event)" [search]="projekte.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-projekte-filter [style.width]="'100%'"></fa-kt-projekte-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-projekte-sorter [style.width]="'100%'"></fa-kt-projekte-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-projekte-list [style.width]="'100%'" (select)="setProjekte($event)" [loading]="loading$ | async"></fa-kt-projekte-list>


  <fa-kt-post-archivieren [style.width]="'100%'"  [value]="$postArchivieren | async" (finished)="finishedPostArchivieren($event)"></fa-kt-post-archivieren>

  <fa-kt-post-kopieren [style.width]="'100%'"  [value]="$postKopieren | async" (finished)="finishedPostKopieren($event)"></fa-kt-post-kopieren>

  <fa-kt-delete-projekt [style.width]="'100%'"  [value]="$deleteProjekt | async" (finished)="finishedDeleteProjekt($event)"></fa-kt-delete-projekt>

  <fa-kt-post-projekt [style.width]="'100%'"  [value]="$postProjekt | async" (finished)="finishedPostProjekt($event)"></fa-kt-post-projekt>

  <fa-kt-post-projekte-hochzaehlen [style.width]="'100%'"  [value]="$postProjekteHochzaehlen | async" (finished)="finishedPostProjekteHochzaehlen($event)"></fa-kt-post-projekte-hochzaehlen>

  <fa-kt-post-reaktivieren [style.width]="'100%'"  [value]="$postReaktivieren | async" (finished)="finishedPostReaktivieren($event)"></fa-kt-post-reaktivieren>


  <fa-kt-kommunikation [style.width]="'100%'" ></fa-kt-kommunikation>

  <fa-kt-kommunikation-badge [style.width]="'100%'" ></fa-kt-kommunikation-badge>

  <fa-kt-dateien [style.width]="'100%'" ></fa-kt-dateien>

  <fa-kt-zahlungseingaenge [style.width]="'100%'" ></fa-kt-zahlungseingaenge>

  <fa-kt-rechnungen [style.width]="'100%'" ></fa-kt-rechnungen>

  <fa-kt-gutachten [style.width]="'100%'" ></fa-kt-gutachten>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="projekt" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="archiv" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="umkreissuche" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="projekte" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" [nzBackIcon]="!($valid | async)">
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-tags *ngIf="!hideSollIst && (sollIst.result$ | async)?.projekt">
    <nz-tag [nzColor]="'green'" nz-tooltip nzTooltipTitle="Meine gebuchten Stunden" (click)="openZeiten()" style="cursor: pointer">
      <span nz-icon nzType="hourglass" nzTheme="outline"></span>
      {{ (sollIst.result$ | async)?.projekt?.nachStunden || 0 | number: '0.0-0' : 'de' }} €</nz-tag
    >
    <ng-container *ngIf="(sollIst.result$ | async)?.projekt?.anteil">
      <nz-tag
        [nzColor]="(sollIst.result$ | async)?.projekt?.verhandeltedHonorar ? 'cyan' : 'blue'"
        nz-tooltip
        [nzTooltipTitle]="'Projektanteil ' + ((sollIst.result$ | async)?.projekt?.verhandeltedHonorar ? 'nach verhandeltem Honorar' : 'nach Zahlungseingängen')"
      >
        <span nz-icon nzType="euro" nzTheme="outline"></span>
        {{ (sollIst.result$ | async)?.projekt?.anteil || 0 | number: '0.0-0' : 'de' }} €
        <span nz-icon [nzType]="(sollIst.result$ | async)?.projekt?.verhandeltedHonorar ? 'question-circle' : 'check-circle'" nzTheme="outline"> </span>
      </nz-tag>
      <nz-tag [nzColor]="getErfolgColor((sollIst.result$ | async)?.projekt)" nz-tooltip nzTooltipTitle="Erfolg"
        >{{ (((sollIst.result$ | async)?.projekt?.nachStunden || 0) / ((sollIst.result$ | async)?.projekt?.anteil || 0)) * 100 || 0 | number: '0.0-0' : 'de' }} %
      </nz-tag>
    </ng-container>
  </nz-page-header-tags>

  <!-- 
    
   
    <nz-form-item style="width: 100%" *ngIf="">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24">Mein Anteil</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24">
      
    </nz-form-control>
  </nz-form-item> -->

  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <ng-container>
        <!-- <button nz-button *nzSpaceItem (click)="test()">Log Verteilung</button> -->
        <nz-input-group *nzSpaceItem [nzAddOnBefore]="PageLeft" [nzAddOnAfter]="PageRight">
          <ng-template #PageLeft>
            <a (click)="navigate(-1)">
              <i nz-icon nzType="left" nzTheme="outline"></i>
            </a>
          </ng-template>
          <nz-select style="width: 358px" nzShowSearch [ngModel]="$id | async" (ngModelChange)="setProjekt($event)">
            <nz-option *ngFor="let selection of (projekteSelect.selection$ | async)" [nzValue]="selection.value" [nzLabel]="selection.label"></nz-option>
          </nz-select>
          <ng-template #PageRight>
            <a (click)="navigate(1)">
              <i nz-icon nzType="right" nzTheme="outline"></i>
            </a>
          </ng-template>
        </nz-input-group>
      </ng-container>
      <nz-button-group *nzSpaceItem>
        <button
          *ngIf="!($isArchiv | async)"
          nz-button
          [nzType]="($creating | async) ? 'default' : 'primary'"
          (click)="submitPostArchivieren({ id: $id.getValue()})"
          [nzLoading]="loading$ | async"
          [disabled]="$creating | async"
          nz-tooltip
          [nzTooltipTitle]="ArchivierenTooltip"
        >
          <i nz-icon nzType="inbox" nzTheme="outline"></i>
          Archivieren
          <ng-template #ArchivierenTooltip> Projekt wird in das Archiv verschoben. </ng-template>
        </button>
        <button
          *ngIf="$isArchiv | async"
          nz-button
          [nzType]="($creating | async) ? 'default' : 'primary'"
          (click)="submitPostReaktivieren({ id: $id.getValue()})"
          [nzLoading]="loading$ | async"
          [disabled]="$creating | async"
          nz-tooltip
          [nzTooltipTitle]="ReaktivierenTooltip"
        >
          <i nz-icon nzType="inbox" nzTheme="outline"></i>
          Reaktivieren
          <ng-template #ReaktivierenTooltip> Projekt wird wieder in die Liste der aktiven Projekte aufgenommen. </ng-template>
        </button>
        <button nz-button nz-dropdown [nzDropdownMenu]="more" nzPlacement="bottomRight" [nzType]="($creating | async) ? 'default' : 'primary'" [disabled]="$creating | async">
          <i nz-icon nzType="ellipsis"></i>
        </button>
        <nz-dropdown-menu #more="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="reload()" [nzDisabled]="!($valid | async) || ($creating | async)">
              <i nz-icon nzType="reload" nzTheme="outline" style="margin-right: 8px"></i>
              Neu laden
            </li>
            <li nz-menu-item [nzDisabled]="!($valid | async) || ($creating | async)" (click)="submitPostKopieren({ id: $id.getValue()})">
              <i nz-icon nzType="copy" nzTheme="outline" style="margin-right: 8px"></i>
              Kopieren
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-group>
              <span nz-typography nzType="secondary"> {{ ($gutachten | async)?.objekt?.name }} </span>
            </li>
            <li nz-menu-item [nzDisabled]="!($valid | async) || ($creating | async) || !($gutachten | async)?.gutachten?.objekt" (click)="searchUmkreis()">
              {{ _umkreissuche.emoji }} Zur {{ _umkreissuche.title }}
            </li>
            <li nz-menu-item [nzDisabled]="!($valid | async) || ($creating | async) || !($gutachten | async)?.gutachten?.objekt" (click)="recherche()">
              {{ _recherche.emoji }} Zur {{ _recherche.title }}
            </li>
            <ng-container *ngFor="let vorlage of $vorlagen | async">
              <li nz-submenu [nzDisabled]="!($valid | async) || ($creating | async)" [nzTitle]="VorlageTitle">
                <ng-template #VorlageTitle> 📃 {{ vorlage.name }} </ng-template>
                <ul>
                  <li nz-menu-item *ngFor="let berichtsVorlage of vorlage.berichtsVorlagen" (click)="run(vorlage, berichtsVorlage)" [nzDisabled]="!($valid | async) || ($creating | async)">
                    <span nz-typography nzType="primary">
                      <i nz-icon nzType="download" nzTheme="outline"></i>
                      {{ berichtsVorlage.name }}
                    </span>
                  </li>
                  <li nz-menu-item (click)="openVorlage(vorlage.id)" [nzDisabled]="!($valid | async) || ($creating | async)">
                    <a><span nz-icon nzType="arrow-right" nzTheme="outline"></span></a>
                    Bearbeiten
                  </li>
                </ul>
              </li>
            </ng-container>

            <li nz-menu-divider></li>
            <li
              nz-menu-item
              [nzDisabled]="$creating | async"
              nz-popconfirm
              [nzPopconfirmTitle]="'Projekt wirklich löschen?'"
              [nzIcon]="DeleteIcon"
              (nzOnConfirm)="submitDeleteProjekt({ id: $id.getValue() })"
            >
              <i nz-icon nzType="delete" nzTheme="outline" style="color: red; margin-right: 8px"></i>
              Löschen
              <ng-template #DeleteIcon>
                <i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
              </ng-template>
            </li>
          </ul>
        </nz-dropdown-menu>
      </nz-button-group>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->

    <nz-row>
      <nz-col nzSpan="24">
        <nz-alert *ngIf="$isArchiv | async" nzType="info" nzMessage="Archiviertes Projekt" nzDescription="Dieses Projekt liegt im Archiv." nzShowIcon></nz-alert>
      </nz-col>
      <nz-col nzSpan="24">
        <ul nz-menu nzMode="horizontal" nzSize="large" nzCentered>
          <li nz-menu-item [nzSelected]="($tab | async) === 'projekt'" [nzDisabled]="!($valid | async)">
            <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'projekt' }" queryParamsHandling="merge" [replaceUrl]="true">
              <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Projekt
            </a>
          </li>
          <li nz-menu-item [nzSelected]="($tab | async) === 'gutachten'" [nzDisabled]="$creating | async">
            <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'gutachten' }" queryParamsHandling="merge" [replaceUrl]="true">
              <i nz-icon nzType="book" nzTheme="outline"></i> {{ (gutachtens.result$ | async)?.gutachten?.length || 0 }} Gutachten
            </a>
          </li>
          <!-- <li nz-menu-item nzMatchRouter [nzDisabled]="$creating | async">
            <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'dateien' }" queryParamsHandling="merge" [replaceUrl]="true">
              <i nz-icon nzType="file" nzTheme="outline"></i> OneDrive
            </a>
          </li> -->
          <li *ngIf="showFinanzen(projekt.result$ | async)" nz-menu-item [nzSelected]="($tab | async) === 'finanzen'" [nzDisabled]="!($valid | async) || ($creating | async)">
            <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'finanzen' }" queryParamsHandling="merge" [replaceUrl]="true">
              <i nz-icon nzType="euro" nzTheme="outline"></i> Finanzen {{ calculateSumme((projekt.result$ | async)?.rechnungen, (projekt.result$ | async)?.zahlungseingaenge) |
              currency:'EUR':'symbol':'':'de' }}
            </a>
          </li>
          <li nz-menu-item [nzSelected]="($tab | async) === 'kommunikation'" [nzDisabled]="!($valid | async) || ($creating | async)">
            <fa-kt-kommunikation-badge [vermerke]="(projekt.result$ | async)?.vermerke" [erinnerungen]="(projekt.result$ | async)?.erinnerungen" [style.width]="'100%'">
              <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'kommunikation' }" queryParamsHandling="merge" [replaceUrl]="true">
                <i nz-icon nzType="wechat" nzTheme="outline"></i> Kommunikation
              </a>
            </fa-kt-kommunikation-badge>
          </li>
          <!-- <li nz-menu-item nzMatchRouterExact [nzDisabled]="$creating | async">
            <a [routerLink]="['.']" [relativeTo]="route" [queryParams]="{ tab: 'e-mails' }" queryParamsHandling="merge" replaceUrl="true" skipLocationChange="true">
              <i nz-icon nzType="mail" nzTheme="outline"></i>
              E-Mails
            </a>
          </li> -->
        </ul>
      </nz-col>
    </nz-row>
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <ng-container [ngSwitch]="$tab | async">
      <nz-col nzSpan="24" *ngSwitchCase="'projekt'">
        <fa-kt-post-projekt [style.width]="'100%'" [value]="$postProjekt | async" (finished)="finishedPostProjekt($event)" [row]="row$ | async"></fa-kt-post-projekt>
        <!-- (kundeChanged)="setKunde($event)"
          (abteilungChanged)="setAbteilung($event)" -->
        <pbc-meta [value]="($postProjekt | async)?.projekt"></pbc-meta>
        <br />
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'gutachten'">
        <fa-kt-gutachten
          [project]="(projekt.result$ | async)?.projekt"
          [kunde]="(projekt.result$ | async)?.kunde"
          (gutachtenSelected)="$gutachten.next($event)"
          (valid)="$valid.next($event)"
          [style.width]="'100%'"
        ></fa-kt-gutachten>
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'finanzen'">
        <ng-container *ngIf="(projekt.result$ | async)?.projekt?.verhandeltesHonorar">
          <nz-divider [nzText]="'Offenes Honorar'" nzOrientation="left"></nz-divider>
          <div style="padding: 32px">
            <h4 nz-title>{{ (projekt.result$ | async)?.projekt?.verhandeltesHonorar | currency:'EUR':'symbol':'':'de' }} Verhandeltes Honorar</h4>
            <h4 nz-title>{{ (calculateSumme((projekt.result$ | async)?.rechnungen, [], 0) | currency:'EUR':'symbol':'':'de')}} Rechnungen</h4>
            <h3 nz-title>= {{ calculateSumme((projekt.result$ | async)?.rechnungen, [], (projekt.result$ | async)?.projekt?.verhandeltesHonorar) | currency:'EUR':'symbol':'':'de' }}</h3>
          </div>
        </ng-container>
        <nz-divider [nzText]="'Rechnungen (' + (calculateSumme((projekt.result$ | async)?.rechnungen, [])  | currency:'EUR':'symbol':'':'de') + ')'" nzOrientation="left"></nz-divider>
        <fa-kt-rechnungen
          [rechnungen]="(projekt.result$ | async)?.rechnungen"
          [projekt]="(projekt.result$ | async)?.projekt"
          [kunde]="(projekt.result$ | async)?.kunde"
          [abteilung]="(projekt.result$ | async)?.abteilung"
          [gutachten]="(gutachtens.result$ | async).gutachten"
          [style.width]="'100%'"
        ></fa-kt-rechnungen>
        <nz-divider [nzText]="'Zahlungseingänge (' + (calculateSumme([], (projekt.result$ | async)?.zahlungseingaenge)  | currency:'EUR':'symbol':'':'de') + ')'" nzOrientation="left"></nz-divider>
        <fa-kt-zahlungseingaenge
          *ngIf="(projekt.result$ | async)?.projekt "
          [zahlungseingaenge]="(projekt.result$ | async)?.zahlungseingaenge"
          [projekt]="$id | async"
          [kunde]="(projekt.result$ | async)?.projekt.kunde"
          [disabled]="!($id | async)"
          [style.width]="'100%'"
        ></fa-kt-zahlungseingaenge>
        <nz-divider nzText="Offene Rechnungssumme" nzOrientation="left"></nz-divider>
        <div style="padding: 32px">
          <h3 nz-title>= {{ calculateSumme((projekt.result$ | async)?.rechnungen, (projekt.result$ | async)?.zahlungseingaenge) | currency:'EUR':'symbol':'':'de' }}</h3>
        </div>
      </nz-col>
      <nz-col nzSpan="24" *ngSwitchCase="'kommunikation'">
        <fa-kt-kommunikation
          [vermerke]="(projekt.result$ | async)?.vermerke"
          [erinnerungen]="(projekt.result$ | async)?.erinnerungen"
          [projekt]="(projekt.result$ | async)?.projekt"
          [disabled]="!($id | async)"
          [style.width]="'100%'"
        ></fa-kt-kommunikation>
      </nz-col>
      <!-- <nz-col nzSpan="24" *ngSwitchCase="'e-mails'">
        <ul nz-list [nzDataSource]="$emails | async" nzSize="large">
          <li nz-list-item *ngFor="let email of $emails | async" nzNoFlex>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <a [href]="email.resource.webLink" target="_blank">open</a>
              </nz-list-item-action>
            </ul>
            <a> {{ email.from.emailAddress.address }} </a>
            {{ email.summary }}
          </li>
        </ul>
        <br>
      </nz-col> -->
      <nz-col nzSpan="24" *ngSwitchCase="'dateien'">
        <fa-kt-dateien [search]="search$ | async" [standortPfad]="$standortPfad | async" [standort]="$standort | async" [kundePfad]="$kundePfad | async" [kunde]="$kunde | async"></fa-kt-dateien>
      </nz-col>
    </ng-container>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
