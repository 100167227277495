import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostGutachtenCommandShapes, IPostGutachtenRequest, IPostGutachtenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostGutachtenCommandService extends PostCommandService<IPostGutachtenRequest, IPostGutachtenResponse, IPostGutachtenCommandShapes> {
  readonly route = 'gutachten/gutachten';
  override dependencies = [
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
  ];

  getGutachtenBewertungsStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.bewertungsStatus']?.find((v) => v.value === value);
  }

  get gutachtenBewertungsStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.bewertungsStatus'] as ISelection[]) : [])));
  }

  getGutachtenObjekt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objekt']?.find((v) => v.value === value);
  }

  get gutachtenObjekt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objekt'] as ISelection[]) : [])));
  }

  getGutachtenObjektArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objektArt']?.find((v) => v.value === value);
  }

  get gutachtenObjektArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objektArt'] as ISelection[]) : [])));
  }

  getGutachtenObjektNutzung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['gutachten.objektNutzung']?.find((v) => v.value === value);
  }

  get gutachtenObjektNutzung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['gutachten.objektNutzung'] as ISelection[]) : [])));
  }
}
