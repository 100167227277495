import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IErforderlichesDokumentsQuerySorter extends ISorter {
   id?: ISorterExpression;
   original?: ISorterExpression;
   gutachten?: ISorterExpression;
   name?: ISorterExpression;
   erforderlich?: ISorterExpression;
   fortschritt?: ISorterExpression;
   kommentar?: ISorterExpression;
}

export const ErforderlichesDokumentsQuerySorterConfig: ISorterConfig = [
   SorterConfig('id', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ID.title'),
   SorterConfig('original', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ORIGINAL.title'),
   SorterConfig('gutachten', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.GUTACHTEN.title'),
   SorterConfig('name', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.NAME.title'),
   SorterConfig('erforderlich', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.ERFORDERLICH.title'),
   SorterConfig('fortschritt', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.FORTSCHRITT.title'),
   SorterConfig('kommentar', 'DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.Propertys.KOMMENTAR.title'),
];
