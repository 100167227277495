import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BewertungsAnlasssServiceModule } from '..';
import { BewertungsAnlasssListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BewertungsAnlasssServiceModule
  ],
  declarations: [BewertungsAnlasssListComponent],
  exports: [BewertungsAnlasssListComponent],

})
export class BewertungsAnlasssListComponentModule { }
