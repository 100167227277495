import { formatDate, formatNumber } from '@angular/common';
import Tagify from '@yaireo/tagify';

export * from './condition';
export * from './placeholder';

export function checkUndefined(value?: string | string[], fallback?: any) {
  if (!value) return fallback;
  if (Array.isArray(value)) return value.length === 0 ? fallback : value;
  if ([undefined, 'undefined', ''].includes(value)) return fallback;
  return value;
}

export function toggleVisibility<T extends HTMLElement>(el: T, show = false): T {
  el.style.display = show ? '' : 'none';
  return el;
}

export function toggleVisibilityTagify(t: Tagify, show = false): Tagify {
  t.DOM.input = toggleVisibility(t.DOM.input, show);
  t.DOM.scope = toggleVisibility(t.DOM.scope, show);
  return t;
}

export function init(
  { element, tagify }: { element?: HTMLInputElement; tagify?: Tagify },
  id: string,
  value: undefined | Option | Option[],
  onChange: (value) => void,
  whitelist: Option[],
  required,
  select = true,
): { element?: HTMLInputElement; tagify?: Tagify } {
  const hooks: { beforeRemoveTag?: (tags) => Promise<void> } = {};
  if (select) {
    hooks.beforeRemoveTag = function (tags) {
      setValue({ element, tagify }, undefined);
      return new Promise<void>((resolve, reject) => resolve());
    };
  }
  tagify = new Tagify(element, {
    mode: select ? 'select' : undefined,
    required,
    whitelist,
    enforceWhitelist: required,
    dropdown: { enabled: 0, searchKeys: ['value', 'searchBy', 'label'], highlightFirst: true },
    hooks,
    templates: {
      dropdownItem: dropdownItem,
    },
  });
  setValue({ element, tagify }, value);
  element.addEventListener('change', (e) => onChange(checkUndefined(element.value, undefined)), { passive: false });
  element = toggleVisibility(element, true);
  return { element, tagify };
}

export function show({ element, tagify }: { element?: HTMLInputElement; tagify?: Tagify }, value: Option, whitelist: Option[]): { element?: HTMLInputElement; tagify?: Tagify } {
  tagify.whitelist = whitelist;
  setValue({ element, tagify }, value);
  tagify = toggleVisibilityTagify(tagify, true);
  return { element, tagify };
}

export function setValue({ element, tagify }: { element?: HTMLInputElement; tagify?: Tagify }, option?: Option | Option[]) {
  if (Array.isArray(option)) {
    tagify.removeAllTags();
    if (option.length) tagify.addTags(option);
  } else if (option) tagify.addTags([option]);
  else tagify.addEmptyTag();
}

export function destroy({ element, tagify }: { element?: HTMLInputElement; tagify?: Tagify }): { element?: HTMLInputElement; tagify?: Tagify } {
  element.onchange = null;
  element = toggleVisibility(element, false);
  if (tagify) tagify.destroy();
  tagify = undefined;
  return { element, tagify };
}

export function toOption(value?: string): Option | undefined {
  if (!value) return undefined;
  return { value };
}

export function parse(value?: string): Option[] {
  if (!value) return [];
  try {
    return JSON.parse(value);
  } catch (e) {
    console.warn(e);
    return [];
  }
}

export function dropdownItem(tag: Option) {
  return `
      <div ${this.getAttributes(tag)}
          class='tagify__dropdown__item'
          tabindex="0"
          role="option">
          <span>${tag.label || tag.value}</span>
          ${tag.preview ? `<span style="margin-left: 16px; font-weight: 100">${tag.preview}</span>` : ''}
      </div>
  `;
}

export interface Option {
  value: string;
  art?: 'datum' | 'haken' | 'zahl' | 'text' | 'option' | 'optionPlus' | 'mehrfachauswahlPlus';
  searchBy?: string;
  label?: string;
  preview?: string;
  format?: string;
  optionen?: Option[];
  editable?: boolean;
}

export const cssStyle = 'color: #20262E; border-radius: 2px;';

export const defaults: {
  datum: Option[];
  haken: Option[];
  zahl: Option[];
  text: Option[];
  option: Option[];
  optionPlus: Option[];
  mehrfachauswahlPlus: Option[];
  pipes: { datum: Option[]; zahl: Option[] };
} = {
  pipes: {
    zahl: [
      { value: '0.0-0', label: 'Ganzzahl', searchBy: 'Ganzzahl' },
      { value: '0.0-2', label: 'Variables Komma', searchBy: 'Variables Komma' },
      { value: '0.2-2', label: 'Zwei Kommas', searchBy: 'Zwei Kommas' },
    ],
    datum: [
      { value: 'short', label: 'Kurz', searchBy: 'kurz' },
      { value: 'medium', label: 'Normal', searchBy: 'normal' },
      { value: 'long', label: 'Lang', searchBy: 'lang' },
      { value: 'full', label: 'Voll', searchBy: 'voll' },
      { value: 'shortDate', label: 'Kurzes Datum', searchBy: 'Kurzes Datum' },
      { value: 'mediumDate', label: 'Normales Datum', searchBy: 'Normales Datum' },
      { value: 'longDate', label: 'Langes Datum', searchBy: 'Langes Datum' },
      { value: 'fullDate', label: 'Volles Datum', searchBy: 'Volles Datum' },
      { value: 'shortTime', label: 'Kurze Zeit', searchBy: 'Kurze Zeit' },
      { value: 'mediumTime', label: 'Normale Zeit', searchBy: 'Normale Zeit' },
      { value: 'longTime', label: 'Lange Zeit', searchBy: 'Lange Zeit' },
      { value: 'fullTime', label: 'Volle Zeit', searchBy: 'Volle Zeit' },
      { value: 'MMM', label: 'Monat kurz', searchBy: 'Monat kurz' },
      { value: 'MMMM', label: 'Monat lang', searchBy: 'Monat lang' },
      { value: 'w', label: 'Kalenderwoche', searchBy: 'Kalenderwoche' },
      { value: 'E', label: 'Wochentag Kurz', searchBy: 'Wochentag Kurz' },
      { value: 'EEEE', label: 'Wochentag', searchBy: 'Wochentag' },
    ],
  },
  datum: [{ value: 'gesetzt', label: 'gesetzt', searchBy: 'gesetzt' }],
  haken: [{ value: 'gesetzt', label: 'gesetzt', searchBy: 'gesetzt' }],
  text: [{ value: 'gesetzt', label: 'gesetzt', searchBy: 'gesetzt' }],
  zahl: [{ value: 'gesetzt', label: 'gesetzt', searchBy: 'gesetzt' }],
  option: [
    { value: 'gesetzt', label: 'gesetzt', searchBy: 'gesetzt' },
    { value: 'hat', label: 'ist eins aus', searchBy: 'hat' },
  ],
  optionPlus: [
    { value: 'gesetzt', label: 'gesetzt', searchBy: 'gesetzt' },
    { value: 'hat', label: 'ist eins aus', searchBy: 'hat' },
  ],
  mehrfachauswahlPlus: [
    { value: 'gesetzt', label: 'gesetzt', searchBy: 'gesetzt' },
    { value: 'hat', label: 'hat einen aus', searchBy: 'hat' },
    { value: 'alle', label: 'hat alle aus', searchBy: 'alle' },
  ],
};

export function getFormattedDefaults(pipe: 'datum' | 'haken' | 'zahl' | 'text' | 'option' | 'optionPlus' | 'mehrfachauswahlPlus') {
  let formats: Option[] = [];
  if (pipe) {
    switch (pipe) {
      case 'zahl':
        formats = defaults.pipes[pipe].map((o) => ({ ...o, preview: formatNumber(Math.PI, 'de-DE', o.value) }));
        break;
      case 'datum':
        formats = defaults.pipes[pipe].map((o) => ({ ...o, preview: formatDate(new Date(), o.value, 'de-DE') }));
        break;
    }
  }
  return formats;
}
