import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostKundenGruppeCommandShapes, IPostKundenGruppeRequest, IPostKundenGruppeResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostKundenGruppeCommandService extends PostCommandService<IPostKundenGruppeRequest, IPostKundenGruppeResponse, IPostKundenGruppeCommandShapes> {
  readonly route = 'kunden/settings/kunden-gruppe';
}
