<nz-content style="padding: 64px">
  <nz-row>
    <nz-col nzSpan="24">
      <nz-breadcrumb>
        <nz-breadcrumb-item> <span nz-typography nzType="secondary">👋</span></nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-col>
    <nz-col nzSpan="24">
      <h1 nz-title>Willkommen,</h1>
      <h4 nz-title>bei den Apps für <a href="https://www.fa-kt-valuation.de/kontakt.html">FA|KT Valuation Chartered Surveyors</a></h4>
    </nz-col>
    <ng-container *ngIf="!(auth.$id | async)">
      <nz-col nzSpan="24" style="padding-top: 36px">
        <button nz-button nzType="primary" (click)="auth.login()">Anmelden</button>
      </nz-col>
      <nz-col nzSpan="24">
        <span nz-typography nzType="secondary" (click)="auth.login()">
          Bitte mit Ihrem Windows-Account anmelden. <br />
          Dazu sollten Pop-Ups für diese Seite erlaubt werden.</span
        >
      </nz-col>
    </ng-container>
    <nz-col nzSpan="24">
      <pbc-health></pbc-health>
    </nz-col>
    <nz-col nzSpan="24" style="height: calc(100vh - 425px)">
      <div
        style="height: 600px; width: 600px; position: relative; top: -100px; left: calc(100vw - 800px)"
        class="fs-3dglobe-container"
        fs-3dglobe-element="container"
        fs-3dglobe-img="assets/globe.jpg"
      ></div>
    </nz-col>
  </nz-row>
</nz-content>
