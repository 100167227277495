import mustache from 'mustache';
import nunjucks from 'nunjucks';

export function compile(content: string, context: unknown): string {
  return mustache.render(content, context);
}

export interface INunjucksOptions {
  options?: any;
  filters?: { [key: string]: (...args: any[]) => any };
}

export function compileWithNunjucks(content: string, context: object, env: INunjucksOptions = {}): string {
  let n = nunjucks.configure(env.options || {});
  for (const [name, filter] of Object.entries(env.filters || {})) {
    n = n.addFilter(name, (...args) => filter(...args));
  }
  return n.renderString(content, context);
}
