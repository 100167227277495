import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteOrtKategorieCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteOrtKategorieCommandService],
  exports: []
})
export class DeleteOrtKategorieCommandServiceModule { }
