import { NgModule } from '@angular/core';

import {
  PostBesichtigungsStatusCommandServiceModule,
  PostBesichtigungsStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostBesichtigungsStatusCommandServiceModule,
    PostBesichtigungsStatusCommandComponentModule
  ],
  exports: [
    PostBesichtigungsStatusCommandServiceModule,
    PostBesichtigungsStatusCommandComponentModule
  ]
})
export class PostBesichtigungsStatusCommandModule { }
