import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteKundenGruppeCommandServiceModule } from '..';
import { DeleteKundenGruppeCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteKundenGruppeCommandServiceModule
  ],
  declarations: [DeleteKundenGruppeCommandComponent],
  exports: [DeleteKundenGruppeCommandComponent],

})
export class DeleteKundenGruppeCommandComponentModule { }
