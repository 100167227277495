<!--
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="durchsuchen.search$.next($event)" [search]="durchsuchen.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-durchsuchen-filter [style.width]="'100%'"></fa-kt-durchsuchen-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-durchsuchen-sorter [style.width]="'100%'"></fa-kt-durchsuchen-sorter>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan='24'>
      <fa-kt-durchsuchen-list [style.width]="'100%'" (select)="setDurchsuchen($event)" [loading]="$loading | async"></fa-kt-durchsuchen-list>
    </nz-col>




-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <nz-spin [nzSpinning]="$loading | async">
      <nz-collapse [nzBordered]="false">
        <nz-collapse-panel [nzHeader]="'Abfrage'" [nzActive]="true">
          <form nz-form [formGroup]="form" (ngSubmit)="submit()">
            <nz-divider id="stichtag-" nzText="Stichtag" nzOrientation="left"></nz-divider>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="stichtag">Stichtag</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <nz-date-picker formControlName="stichtag" id="stichtag" nzFormat="dd.MM.yyyy"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>

            <nz-divider id="ort-" nzText="Ort" nzOrientation="left"></nz-divider>
            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileLand">Land</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileLand" id="zeileLand" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileBundesland">Bundesland</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileBundesland" id="zeileBundesland" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileKreisStadt">Kreis/Stadt</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileKreisStadt" id="zeileKreisStadt" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileGemeindeBezirk">Gemeinde/Bezirk</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileGemeindeBezirk" id="zeileGemeindeBezirk" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileOrtsStadtTeil">Orts-/Stadtteil</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileOrtsStadtTeil" id="zeileOrtsStadtTeil" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileGemeindeschluessel">Gemeindeschluessel</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileGemeindeschluessel" id="zeileGemeindeschluessel" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeilePlz">PLZ</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeilePlz" id="zeilePlz" />
              </nz-form-control>
            </nz-form-item>

            <nz-divider id="sonstiges" nzText="Sonstiges" nzOrientation="left"></nz-divider>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileAssetklasse">Assetklasse</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileAssetklasse" id="zeileAssetklasse" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileAssetklasse">Objektart</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileObjektart" id="zeileObjektart" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeileZustand">Zustand</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input formControlName="zeileZustand" id="zeileZustand" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="baujahr">Baujahr</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input type="number" style="width: 150px" formControlName="baujahr" id="baujahr" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="flaeche">Fläche</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24">
                <input nz-input type="number" style="width: 150px" formControlName="flaeche" id="flaeche" />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control>
                <button
                  nz-button
                  nzType="primary"
                  class="save-button"
                  type="submit"
                  [nzLoading]="$loading | async"
                  [disabled]="!form.valid || ($loading | async)"
                  nz-tooltip
                  [nzTooltipTitle]="SubmitTooltip"
                >
                  <i nz-icon nzType="file-search" nzTheme="outline"></i>
                  Laden
                  <ng-template #SubmitTooltip> Marktdaten durchsuchen </ng-template>
                </button>
              </nz-form-control>
            </nz-form-item>
          </form>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-spin>
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->

    <nz-col nzSpan="24" *ngIf="(durchsuchen.result$ | async)?.durchsuchen?.length === 0">
      <h1>Keine Ergebnisse</h1>
    </nz-col>
    <nz-col nzSpan="24" *ngFor="let row of result$ | async">
      <h1>{{ row.artDesWertes }}</h1>
      <table style="width: 100%">
        <tr>
          <th>Berichte</th>
          <th>Kriterien</th>
          <th>Spanne</th>
          <th>Durchschnitt</th>
        </tr>
        <tr *ngFor="let entry of row.recherches">
          <td>{{ entry.herausgeber.name }}: {{ entry.bericht.quelle }}</td>
          <td>{{ kriterien(durchsuchen.$payload | async) }}</td>
          <td>{{ toNumber(entry.minimum) | number:'1.0-2':'de' }} {{ entry.einheit }} - {{ toNumber(entry.maximum) | number:'1.0-2':'de' }} {{ entry.einheit }}</td>
          <td>{{ toNumber(entry.durchschnitt) | number:'1.0-2':'de' }} {{ entry.einheit }}</td>
        </tr>
      </table>
      <br />
      <br />
      <br />
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
