import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteAbschnittCommandServiceModule } from '..';
import { DeleteAbschnittCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteAbschnittCommandServiceModule
  ],
  declarations: [DeleteAbschnittCommandComponent],
  exports: [DeleteAbschnittCommandComponent],

})
export class DeleteAbschnittCommandComponentModule { }
