import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { AbschnitteComponent } from '.';

import { DeleteAbschnittCommandModule, PostAbschnittCommandModule, PostSortiereAbschnitteCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostAbschnittCommandModule, DeleteAbschnittCommandModule, PostSortiereAbschnitteCommandModule],
  declarations: [AbschnitteComponent],
  exports: [AbschnitteComponent],
})
export class AbschnitteComponentModule {}
