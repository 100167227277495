import { Injectable } from '@angular/core';
import { ISelection } from 'pbc.types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  public users$ = new BehaviorSubject<ISelection[]>([]);
  public select$ = new BehaviorSubject<ISelection[]>([]);
  public groups$ = new BehaviorSubject<ISelection[]>([]);

  constructor() {
    this.users$.subscribe((users) => this.select$.next((users || []).filter((u) => u.aktiv)));
  }
}
