import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ObjektServiceModule } from '..';
import { ObjektSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektServiceModule
  ],
  declarations: [ObjektSorterComponent],
  exports: [ObjektSorterComponent],

})
export class ObjektSorterComponentModule { }
