import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFeldSorter extends ISorter {
  id?: ISorterExpression;
  schluessel?: ISorterExpression;
  name?: ISorterExpression;
  kommentar?: ISorterExpression;
  feldKategorie?: ISorterExpression;
  feldUnterkategorie?: ISorterExpression;
  art?: ISorterExpression;
  format?: ISorterExpression;
  voreinstellung?: ISorterExpression;
}

export const FeldSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('schluessel', 'Schluessel'),
  SorterConfig('name', 'Name'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('feldKategorie', 'Feld Kategorie'),
  SorterConfig('feldUnterkategorie', 'Feld Unterkategorie'),
  SorterConfig('art', 'Art'),
  SorterConfig('format', 'Format'),
  SorterConfig('voreinstellung', 'Voreinstellung')
];
