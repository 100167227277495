import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { ZeitComponent } from '.';
import { DeleteZeitCommandModule, PostZeitCommandModule } from '../../commands';
import { ZeitQueryModule } from '../../querys';

@NgModule({
  imports: [LIB_CommonModule, ZeitQueryModule, PostZeitCommandModule, DeleteZeitCommandModule],
  declarations: [ZeitComponent],
  exports: [ZeitComponent],
})
export class ZeitComponentModule {}
