import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IBerichtsVorlagenQuerySorter extends ISorter {
  'berichtsVorlage.id'?: ISorterExpression;
  'berichtsVorlage.name'?: ISorterExpression;
  'berichtsVorlage.kommentar'?: ISorterExpression;
  'berichtsVorlage.datei'?: ISorterExpression;
}

export const BerichtsVorlagenSorterConfig: ISorterConfig = [
  SorterConfig('berichtsVorlage.id', 'TEXTE.Models.BERICHTS_VORLAGE.Propertys.ID.title'),
  SorterConfig('berichtsVorlage.name', 'TEXTE.Models.BERICHTS_VORLAGE.Propertys.NAME.title'),
  SorterConfig('berichtsVorlage.kommentar', 'TEXTE.Models.BERICHTS_VORLAGE.Propertys.KOMMENTAR.title'),
  SorterConfig('berichtsVorlage.datei', 'TEXTE.Models.BERICHTS_VORLAGE.Propertys.DATEI.title'),
];
