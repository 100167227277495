import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReisekostenabrechnungService } from '../service';

@Component({
  selector: 'fa-kt-reisekostenabrechnung-detail',
  templateUrl: './reisekostenabrechnung-detail.component.html',
  styleUrls: ['./reisekostenabrechnung-detail.component.css'],
})
export class ReisekostenabrechnungDetailComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  constructor(public reisekostenabrechnung: ReisekostenabrechnungService) {}
}
