import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IFotoKategorienQueryFilter, IFotoKategorienQueryShapes, IFotoKategorienQuerySorter, IFotoKategorienRequest, IFotoKategorienResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class FotoKategorienService extends SettingsQueryService<IFotoKategorienRequest, IFotoKategorienResponse, IFotoKategorienQueryShapes, IFotoKategorienQueryFilter, IFotoKategorienQuerySorter> {
  contentKey: keyof IFotoKategorienResponse = 'fotoKategorien';
  route = 'besichtigungen/settings/foto-kategorien';
  override dependencies = ['besichtigungen/written-foto-kategorie', 'besichtigungen/removed-foto-kategorie'];
}
