import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IHeuteRequest, IHeuteResponse, IZeitenQueryFilter, IZeitenQueryShapes, IZeitenQuerySorter } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class HeuteService extends ListQueryService<IHeuteRequest, IHeuteResponse, IZeitenQueryShapes, IZeitenQueryFilter, IZeitenQuerySorter> {
  contentKey: keyof IHeuteResponse = 'zeiten';
  override readonly route = 'zeiten/heute';
  override readonly dependencies = [
    'personen/mitarbeiter-aktualisiert',
    'zeiten/written-zeit-art',
    'zeiten/removed-zeit-art',
    'zeiten/written-zeit-unterart',
    'zeiten/removed-zeit-unterart',
    'zeiten/tag-gespeichert',
    'zeiten/urlaub-gespeichert',
    'zeiten/urlaub-entfernt',
    'zeiten/krankheit-gespeichert',
    'zeiten/krankheit-entfernt',
    'zeiten/rahmen-anpassung-gespeichert',
    'zeiten/rahmen-anpassung-entfernt',
    'zeiten/zeit-gespeichert',
    'zeiten/zeit-entfernt',
  ];

  static instance: HeuteService;

  constructor() {
    super();
    if (!HeuteService.instance) HeuteService.instance = this;
    return HeuteService.instance;
  }
}
