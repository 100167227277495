import { IFilter, IFilterConfig } from "pbc.types";

import { IErinnerung } from '.';

export interface IErinnerungFilter extends IFilter<IErinnerung> {}

export const ErinnerungFilterConfig: IFilterConfig<IErinnerung> = [];

export type IErinnerungField =
  'erinnerung.id'|
  'erinnerung.projekt'|
  'erinnerung.gutachten'|
  'erinnerung.kunde'|
  'erinnerung.kommentar'|
  'erinnerung.am'|
  'erinnerung.mitarbeiter'|
  'erinnerung.verfasser'
;
