import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteKundenArtRequest, IDeleteKundenArtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteKundenArtCommandService extends DeleteCommandService<IDeleteKundenArtRequest, IDeleteKundenArtResponse> {
  readonly route = 'kunden/settings/kunden-art';
}
