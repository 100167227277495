import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBewertungsAnlassCommandServiceModule } from '..';
import { PostBewertungsAnlassCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBewertungsAnlassCommandServiceModule
  ],
  declarations: [PostBewertungsAnlassCommandComponent],
  exports: [PostBewertungsAnlassCommandComponent],

})
export class PostBewertungsAnlassCommandComponentModule { }
