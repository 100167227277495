import { NgModule } from '@angular/core';
import {
  ObjektComponentModule,
  ObjekteComponentModule
 } from '.';

@NgModule({
  imports: [
    ObjektComponentModule,
    ObjekteComponentModule
  ],
  exports: [
    ObjektComponentModule,
    ObjekteComponentModule
  ]
})
export class ObjekteComponentsModule { }
