import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IKrankheitenQueryFilter, IKrankheitenQueryShapes, IKrankheitenQuerySorter, IKrankheitenRequest, IKrankheitenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class KrankheitenService extends ListQueryService<IKrankheitenRequest, IKrankheitenResponse, IKrankheitenQueryShapes, IKrankheitenQueryFilter, IKrankheitenQuerySorter> {
  contentKey: keyof IKrankheitenResponse = 'krankheiten';
  override dependencies = ['zeiten/krankheit-gespeichert', 'zeiten/krankheit-entfernt'];

  route = 'zeiten/krankheiten';
}
