import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TemplatesServiceModule } from '..';
import { TemplatesSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    TemplatesServiceModule
  ],
  declarations: [TemplatesSorterComponent],
  exports: [TemplatesSorterComponent],

})
export class TemplatesSorterComponentModule { }
