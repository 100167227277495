import { Injectable } from '@angular/core';

import { IObjektNutzungsQueryFilter, IObjektNutzungsQueryShapes, IObjektNutzungsQuerySorter, IObjektNutzungsRequest, IObjektNutzungsResponse } from 'fa-kt.types';
import { SettingsQueryService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class ObjektNutzungsService extends SettingsQueryService<IObjektNutzungsRequest, IObjektNutzungsResponse, IObjektNutzungsQueryShapes, IObjektNutzungsQueryFilter, IObjektNutzungsQuerySorter> {
  contentKey: keyof IObjektNutzungsResponse = 'objektNutzungs';
  route = 'objekte/settings/objekt-nutzungs';
  override dependencies = ['objekte/written-objekt-nutzung', 'objekte/removed-objekt-nutzung'];
}
