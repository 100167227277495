import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IKundenArtsResponse } from 'fa-kt.types';
import { PostKundenArtCommandService } from '../commands';
import { KundenArtsService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class KundenArtsPageResolver implements Resolve<IKundenArtsResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private kundenArts: KundenArtsService,
    private postKundenArt: PostKundenArtCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IKundenArtsResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [kundenArts] = await Promise.all([this.kundenArts.request({}).catch(log), this.postKundenArt.prepare(true).catch(log)]);
    this.loading.resolved();
    return kundenArts;
  }
}
