import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "pbc.angular";

import { BerichtService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    BerichtService,
  ],
  exports: []
})
export class BerichtServiceModule { }
