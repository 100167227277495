import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostDateiHochladenCommandShapes, IPostDateiHochladenRequest, IPostDateiHochladenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostDateiHochladenCommandService extends PostCommandService<IPostDateiHochladenRequest, IPostDateiHochladenResponse, IPostDateiHochladenCommandShapes> {
  readonly route = 'dokumente/datei-hochladen';
  override dependencies = ['dokumente/written-datei-art', 'dokumente/removed-datei-art'];

  override async request(payload: IPostDateiHochladenRequest): Promise<IPostDateiHochladenResponse> {
    return (await this.http.upload<IPostDateiHochladenResponse>(this.route, payload, 'dokumente')) as unknown as IPostDateiHochladenResponse;
  }

  getDateiDateiArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['datei.dateiArt']?.find((v) => v.value === value);
  }

  get dateiDateiArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['datei.dateiArt'] as ISelection[]) : [])));
  }
}
