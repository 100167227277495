import { Injectable } from '@angular/core';

import { IBesichtigungenSelectionRequest } from 'fa-kt.types';
import { SelectionService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class BesichtigungenSelectService extends SelectionService<IBesichtigungenSelectionRequest> {
  route = 'besichtigungen/besichtigungen';
  override dependencies = [
    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
  ];

  static instance: BesichtigungenSelectService;

  constructor() {
    super();
    if (!BesichtigungenSelectService.instance) BesichtigungenSelectService.instance = this;
    return BesichtigungenSelectService.instance;
  }
}
