import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IFeldKategorie } from 'fa-kt.types';
import { PostFeldKategorieCommandService } from '../../commands';
import { FeldKategoriesService } from '../service';

@Component({
  selector: 'fa-kt-feld-kategories-list',
  templateUrl: './feld-kategories-list.component.html',
  styleUrls: ['./feld-kategories-list.component.css'],
})
export class FeldKategoriesListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IFeldKategorie> = new EventEmitter<IFeldKategorie>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public feldKategories: FeldKategoriesService,
    public feldKategoriePost: PostFeldKategorieCommandService,
  ) {
    this.sortable$ = this.feldKategories.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const feldKategories = this.feldKategories.result$.getValue();
    if (feldKategories && feldKategories.feldKategories) {
      if ($event) {
        moveItemInArray(feldKategories.feldKategories, $event.previousIndex, $event.currentIndex);
      }
      feldKategories.feldKategories = feldKategories.feldKategories.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.feldKategoriePost.request({ feldKategorie: feldKategories.feldKategories });
    }
  }
}
