import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostFotoKategorieCommandComponent } from '.';
import { PostFotoKategorieCommandServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFotoKategorieCommandServiceModule
  ],
  declarations: [PostFotoKategorieCommandComponent],
  exports: [PostFotoKategorieCommandComponent],

})
export class PostFotoKategorieCommandComponentModule { }
