<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="durchsuchen.search$.next($event)" [search]="durchsuchen.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-durchsuchen-filter [style.width]="'100%'"></fa-kt-durchsuchen-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-durchsuchen-sorter [style.width]="'100%'"></fa-kt-durchsuchen-sorter>
    </nz-col>
  </nz-row>
  
  <fa-kt-durchsuchen-list [style.width]="'100%'" (select)="setDurchsuchen($event)" [loading]="$loading | async"></fa-kt-durchsuchen-list>




-->
<!--- << custom    -->
<p>durchsuchen works!</p>
<!---    custom >> -->
