import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { combineLatest, Subscription } from 'rxjs';

import { GutachtenQueryFilterConfig, IGutachtenResponse } from 'fa-kt.types';

import { FelderService } from '../../../../felder';
import { GutachtensService } from '../service';

@Component({
  selector: 'fa-kt-gutachten-filter',
  templateUrl: './gutachten-filter.component.html',
  styleUrls: ['./gutachten-filter.component.css'],
})
export class GutachtenFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public GutachtenFilterConfig: IFilterConfig<IGutachtenResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public gutachtens: GutachtensService,
    private felder: FelderService,
  ) {
    this.GutachtenFilterConfig = GutachtenQueryFilterConfig.filter((f) => f.key !== 'gutachten.objekt').map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      combineLatest([this.gutachtens.shapes$, this.felder.response$]).subscribe(([shapes, felder]) => {
        if (!shapes) {
          return;
        }
        this.gutachtens.replacements.forEach((r) => {
          Object.entries(r)
            .filter(([key]) => shapes[key])
            .forEach(([key, value]) => (shapes[value] = shapes[key]));
        });
        shapes['eintraege.feld'] = shapes['formular.felder'] = felder?.felder.map(({ feld }) => ({ value: feld.id, label: feld.name })) || [];
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.GutachtenFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.GutachtenFilterConfig[index].selections = shapes;
  }
}
