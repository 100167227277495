import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { SettingsQueryService } from 'pbc.angular';

import { IProjektArtsQueryFilter, IProjektArtsQueryShapes, IProjektArtsQuerySorter, IProjektArtsRequest, IProjektArtsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ProjektArtsService extends SettingsQueryService<IProjektArtsRequest, IProjektArtsResponse, IProjektArtsQueryShapes, IProjektArtsQueryFilter, IProjektArtsQuerySorter> {
  contentKey: keyof IProjektArtsResponse = 'projektArts';
  route = 'projekte/settings/projekt-arts';
  override dependencies = ['projekte/written-projekt-art', 'projekte/removed-projekt-art'];

  public initialenIsUnique(control: AbstractControl | null): (initialen: string) => boolean {
    return (initialen: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (initialen) {
        const response = this.response$.getValue();
        if (response && response.projektArts) {
          isUnique = response.projektArts.filter((pa) => pa.id !== id && pa.initialen === initialen).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    };
  }
}
