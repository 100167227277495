<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="kunden.search$.next($event)" [search]="kunden.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-kunden-filter [style.width]="'100%'"></fa-kt-kunden-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-kunden-sorter [style.width]="'100%'"></fa-kt-kunden-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-kunden-list [style.width]="'100%'" (select)="setKunden($event)" [loading]="$loading | async"></fa-kt-kunden-list>




    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="kunde" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <span *nzSpaceItem style="line-height: 32px"> {{ (kunden.result$ | async)?.kunden.length }} / {{ (kunden.response$ | async)?.kunden.length }} </span>
      <div *nzSpaceItem style="width: 100%">
        <fa-kt-saved-kunden-filters style="width: 100%"></fa-kt-saved-kunden-filters>
      </div>
      <button *nzSpaceItem nz-button nzType="primary" (click)="setKunden('new')">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Kunde hinzufügen
      </button>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <pbc-search-bar [style.width]="'100%'" (searchChange)="kunden.search$.next($event)" [search]="kunden.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
      <fa-kt-kunden-filter [style.width]="'100%'"></fa-kt-kunden-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
      <fa-kt-kunden-sorter [style.width]="'100%'"></fa-kt-kunden-sorter>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan="24">
      <fa-kt-kunden-list [style.width]="'100%'" (select)="setKunden($event)" [loading]="$loading | async"></fa-kt-kunden-list>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
