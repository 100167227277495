import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import {
  IDeleteFeldOptionRequest,
  IDeleteFeldOptionResponse,
  IDeleteFeldRequest,
  IDeleteFeldResponse,
  IPostFeldOptionRequest,
  IPostFeldOptionResponse,
  IPostFeldRequest,
  IPostFeldResponse,
  IPostOrdneFeldOptionenRequest,
  IPostOrdneFeldOptionenResponse,
} from 'fa-kt.types';
import { DeleteFeldCommandService, DeleteFeldOptionCommandService, PostFeldCommandService, PostFeldOptionCommandService, PostOrdneFeldOptionenCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-felder',
  templateUrl: './felder.component.html',
  styleUrls: ['./felder.component.css'],
})
export class FelderComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postFeldOption: BehaviorSubject<IPostFeldOptionRequest | null> = new BehaviorSubject<IPostFeldOptionRequest | null>(null);
  public readonly $deleteFeld: BehaviorSubject<IDeleteFeldRequest | null> = new BehaviorSubject<IDeleteFeldRequest | null>(null);
  public readonly $postOrdneFeldOptionen: BehaviorSubject<IPostOrdneFeldOptionenRequest | null> = new BehaviorSubject<IPostOrdneFeldOptionenRequest | null>(null);
  public readonly $postFeld: BehaviorSubject<IPostFeldRequest | null> = new BehaviorSubject<IPostFeldRequest | null>(null);
  public readonly $deleteFeldOption: BehaviorSubject<IDeleteFeldOptionRequest | null> = new BehaviorSubject<IDeleteFeldOptionRequest | null>(null);

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postFeldOption: PostFeldOptionCommandService,
    public deleteFeld: DeleteFeldCommandService,
    public postOrdneFeldOptionen: PostOrdneFeldOptionenCommandService,
    public postFeld: PostFeldCommandService,
    public deleteFeldOption: DeleteFeldOptionCommandService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostFeldOption(response?: IPostFeldOptionResponse) {
    /* << after post-feld-option    */
    /*    after post-feld-option >> */
  }

  async finishedDeleteFeld(response?: IDeleteFeldResponse) {
    /* << after delete-feld    */
    /*    after delete-feld >> */
  }

  async finishedPostOrdneFeldOptionen(response?: IPostOrdneFeldOptionenResponse) {
    /* << after post-ordne-feld-optionen    */
    /*    after post-ordne-feld-optionen >> */
  }

  async finishedPostFeld(response?: IPostFeldResponse) {
    /* << after post-feld    */
    /*    after post-feld >> */
  }

  async finishedDeleteFeldOption(response?: IDeleteFeldOptionResponse) {
    /* << after delete-feld-option    */
    /*    after delete-feld-option >> */
  }

  public async submitPostFeldOption(request?: IPostFeldOptionRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postFeldOption.getValue();
    if (payload) {
      try {
        const response = await this.postFeldOption.request(payload);
        await this.finishedPostFeldOption(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteFeld(request?: IDeleteFeldRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteFeld.getValue();
    if (payload) {
      try {
        const response = await this.deleteFeld.request(payload);
        await this.finishedDeleteFeld(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostOrdneFeldOptionen(request?: IPostOrdneFeldOptionenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postOrdneFeldOptionen.getValue();
    if (payload) {
      try {
        const response = await this.postOrdneFeldOptionen.request(payload);
        await this.finishedPostOrdneFeldOptionen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostFeld(request?: IPostFeldRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postFeld.getValue();
    if (payload) {
      try {
        const response = await this.postFeld.request(payload);
        await this.finishedPostFeld(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteFeldOption(request?: IDeleteFeldOptionRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteFeldOption.getValue();
    if (payload) {
      try {
        const response = await this.deleteFeldOption.request(payload);
        await this.finishedDeleteFeldOption(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
