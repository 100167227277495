import { NgModule } from '@angular/core';

import {
  DeleteFeldUnterkategorieCommandServiceModule,
  DeleteFeldUnterkategorieCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteFeldUnterkategorieCommandServiceModule,
    DeleteFeldUnterkategorieCommandComponentModule
  ],
  exports: [
    DeleteFeldUnterkategorieCommandServiceModule,
    DeleteFeldUnterkategorieCommandComponentModule
  ]
})
export class DeleteFeldUnterkategorieCommandModule { }
