import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BereichsServiceModule } from '..';
import { BereichsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BereichsServiceModule
  ],
  declarations: [BereichsSorterComponent],
  exports: [BereichsSorterComponent],

})
export class BereichsSorterComponentModule { }
