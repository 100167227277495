import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IBereichsResponse } from '.';

export interface IBereichsQueryFilter extends IFilter<IBereichsResponse> {}

export const BereichsQueryFilterConfig: IFilterConfig<IBereichsResponse> = [];

export interface IBereichsQueryShapes extends IShape {}

export type IBereichsQueryField = 'bereichs.id' | 'bereichs.name' | 'bereichs.kommentar' | 'bereichs.areal' | '';
