import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { TourQuerySorterConfig } from 'fa-kt.types';
import { TourService } from '../service';

@Component({
  selector: 'fa-kt-tour-sorter',
  templateUrl: './tour-sorter.component.html',
  styleUrls: ['./tour-sorter.component.css'],
})
export class TourSorterComponent {
  public TourSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public tour: TourService,
  ) {
    this.TourSorterConfig = TourQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }
}
