import { NgModule } from '@angular/core';

import {
  DeleteBereichCommandServiceModule,
  DeleteBereichCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteBereichCommandServiceModule,
    DeleteBereichCommandComponentModule
  ],
  exports: [
    DeleteBereichCommandServiceModule,
    DeleteBereichCommandComponentModule
  ]
})
export class DeleteBereichCommandModule { }
