import { IFilter, IFilterConfig } from "pbc.types";

import { IEintrag } from '.';

export interface IEintragFilter extends IFilter<IEintrag> {}

export const EintragFilterConfig: IFilterConfig<IEintrag> = [];

export type IEintragField =
  'eintrag.id'|
  'eintrag.gutachten'|
  'eintrag.feld'|
  'eintrag.wert'|
  'eintrag.wertExtra'|
  'eintrag.akkusativ'|
  'eintrag.nominativ'|
  'eintrag.dativ'|
  'eintrag.genitiv'|
  'eintrag.achtung'
;
