import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteVermerkRequest, IDeleteVermerkResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteVermerkCommandService extends DeleteCommandService<IDeleteVermerkRequest, IDeleteVermerkResponse> {
  readonly route = 'kommunikation/vermerk';
}
