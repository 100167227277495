import { Component, Input } from '@angular/core';

import moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { orderBy } from 'lodash';

import { ITag } from 'fa-kt.types';

@Component({
  selector: 'fa-kt-tages-graph',
  templateUrl: './tages-graph.component.html',
  styleUrls: ['./tages-graph.component.css'],
})
export class TagesGraphComponent {
  assignments$ = new BehaviorSubject<any[]>([]);
  tag$ = new BehaviorSubject<ITag>(undefined);

  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxis: boolean = true;
  yAxisTickFormatting = (val: number) => {
    const start = moment(this.tag$.getValue()?.start);
    return start.add(val, 'minutes').format('HH:mm');
  };
  showXAxisLabel: boolean = false;
  xAxisLabel: string = '';
  showYAxis: boolean = true;
  showYAxisLabel: boolean = false;
  yAxisLabel: string = '';
  animations: boolean = true;
  customColors: { [name: string]: string } = {};
  zeitColors = (name) => {
    return (
      {
        ...this.customColors,
        Arbeit: '#52c41a',
        Pause: '#b37feb',
      }[name] || '#74E291'
    );
  };

  @Input() set tag(tag: ITag) {
    if (!tag) return;

    tag.pausen = tag.pausen || [];

    this.tag$.next(tag);

    const today = moment(tag.start);
    const now = moment().set('year', today.year()).set('month', today.month()).set('date', today.date()).toDate();
    tag.ende = tag.ende || now;

    this.assignments$.next([
      {
        id: 'day',
        name: moment(tag.start).format('DD.MM.yy'),
        series: orderBy(
          [
            {
              id: 'arbeit-0',
              name: `Arbeit`, // von ${moment(tag.start).format('HH:mm')} bis ${moment(tag.pausen[0]?.start || tag.ende).format('HH:mm')}`,
              value: moment(tag.pausen[0]?.start || tag.ende).diff(tag.start, 'minutes'),
              extra: {
                start: new Date(tag.start),
                ende: new Date(tag.pausen[0]?.start || tag.ende),
              },
            },
            ...tag.pausen
              .map(({ start, ende }, index) =>
                [
                  {
                    id: 'pause-' + (index + 1),
                    name: `Pause`, // von ${moment(start).format('HH:mm')} bis ${moment(ende || now).format('HH:mm')}`,
                    value: moment(ende || now).diff(start, 'minutes'),
                    extra: {
                      index,
                      start: new Date(start),
                      ende: new Date(ende),
                    },
                  },
                  ende
                    ? {
                        id: 'arbeit-' + (index + 1),
                        name: `Arbeit`, // von ${moment(ende).format('HH:mm')} bis ${moment(tag.pausen[index + 1]?.start || tag.ende).format('HH:mm')}`,
                        value: moment(tag.pausen[index + 1]?.start || tag.ende).diff(ende, 'minutes'),
                        extra: {
                          start: new Date(ende),
                          ende: new Date(tag.pausen[index + 1]?.start || tag.ende),
                        },
                      }
                    : undefined,
                ].filter((v) => !!v),
              )
              .flat(),
          ],
          (e) => e.extra.start.getTime(),
        ),
        extra: {},
      },
    ]);
  }
}
