import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IProjektStatiResponse } from 'fa-kt.types';
import { PostProjektStatusCommandService } from '../commands';
import { ProjektStatiService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class ProjektStatiPageResolver implements Resolve<IProjektStatiResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private projektStati: ProjektStatiService,
    private postProjektStatus: PostProjektStatusCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IProjektStatiResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [projektStati] = await Promise.all([this.projektStati.request({}).catch(log), this.postProjektStatus.prepare(true).catch(log)]);
    this.loading.resolved();
    return projektStati;
  }
}
