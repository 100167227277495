import { NgModule } from '@angular/core';
import {
  RollesFilterComponentModule,
  RollesSorterComponentModule,
  RollesListComponentModule
} from '.';

@NgModule({
  imports: [
    RollesFilterComponentModule,
    RollesSorterComponentModule,
    RollesListComponentModule
  ],
  exports: [
    RollesFilterComponentModule,
    RollesSorterComponentModule,
    RollesListComponentModule
  ],
})
export class RollesQueryModule { }