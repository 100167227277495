import { Injectable } from '@angular/core';

import { IDeleteProjektRequest, IDeleteProjektResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteProjektCommandService extends DeleteCommandService<IDeleteProjektRequest, IDeleteProjektResponse> {
  readonly route = 'projekte/projekt';
}
