import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { SollIstQueryModule } from './soll-ist'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SollIstQueryModule
  ],
  exports: [
  ]
})
export class AuswertungenQuerysModule { }
