import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ProjekteServiceModule } from '..';
import { ProjekteFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjekteServiceModule
  ],
  declarations: [ProjekteFilterComponent],
  exports: [ProjekteFilterComponent],

})
export class ProjekteFilterComponentModule { }
