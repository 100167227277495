import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IArchivQuerySorter extends ISorter {
  'projekt.id'?: ISorterExpression;
  'projekt.bezeichnung'?: ISorterExpression;
  'projekt.nummer'?: ISorterExpression;
  'projekt.projektZaehler'?: ISorterExpression;
  'projekt.projektArt'?: ISorterExpression;
  'projekt.projektStatus'?: ISorterExpression;
  'projekt.standort'?: ISorterExpression;
  'projekt.beauftragung'?: ISorterExpression;
  'projekt.deadline'?: ISorterExpression;
  'projekt.kundenAnfertigung'?: ISorterExpression;
  'projekt.keineBesichtigungErforderlich'?: ISorterExpression;
  'projekt.besichtigungen'?: ISorterExpression;
  'projekt.gutachten'?: ISorterExpression;
  'projekt.pruefung'?: ISorterExpression;
  'projekt.kunde'?: ISorterExpression;
  'projekt.abteilung'?: ISorterExpression;
  'projekt.fond'?: ISorterExpression;
  'projekt.bank'?: ISorterExpression;
  'projekt.objektArt'?: ISorterExpression;
  'projekt.nachlass'?: ISorterExpression;
  'projekt.kundenZeichen'?: ISorterExpression;
  'projekt.kontakteInOutlook'?: ISorterExpression;
  'projekt.dateiVerzeichnis'?: ISorterExpression;
  'projekt.kommentar'?: ISorterExpression;
  'projekt.notizenFuerRechnung'?: ISorterExpression;
  'projekt.fortschritt'?: ISorterExpression;
  // 'projekt.strecke'?: ISorterExpression;
  // 'projekt.kalkulierteStrecke'?: ISorterExpression;
  // 'projekt.fahrtzeit'?: ISorterExpression;
  // 'projekt.kalkulierteFahrtzeit'?: ISorterExpression;
  'projekt.stunden'?: ISorterExpression;
  'projekt.kostenNachStunden'?: ISorterExpression;
  'projekt.verhandeltesHonorar'?: ISorterExpression;
  'projekt.kosten'?: ISorterExpression;
  'projekt.kostenBeglichen'?: ISorterExpression;
  'projekt.aktiv'?: ISorterExpression;
  'projekt.darlehensnehmer'?: ISorterExpression;
  'projekt.notizenBesichtigung'?: ISorterExpression;
  'projekt.kreditsachbearbeiterVorname'?: ISorterExpression;
  'projekt.kreditsachbearbeiterName'?: ISorterExpression;
  'projekt.kreditsachbearbeiterTelefon'?: ISorterExpression;
  'projekt.kreditsachbearbeiterEMail'?: ISorterExpression;
}

export const ArchivQuerySorterConfig: ISorterConfig = [
  SorterConfig('projekt.id', 'PROJEKTE.Models.PROJEKT.Propertys.ID.title'),
  SorterConfig('projekt.bezeichnung', 'PROJEKTE.Models.PROJEKT.Propertys.BEZEICHNUNG.title'),
  SorterConfig('projekt.nummer', 'PROJEKTE.Models.PROJEKT.Propertys.NUMMER.title'),
  SorterConfig('projekt.projektZaehler', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ZAEHLER.title'),
  SorterConfig('projekt.projektArt', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ART.title'),
  SorterConfig('projekt.projektStatus', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_STATUS.title'),
  SorterConfig('projekt.standort', 'PROJEKTE.Models.PROJEKT.Propertys.STANDORT.title'),
  SorterConfig('projekt.beauftragung', 'PROJEKTE.Models.PROJEKT.Propertys.BEAUFTRAGUNG.title'),
  SorterConfig('projekt.deadline', 'PROJEKTE.Models.PROJEKT.Propertys.DEADLINE.title'),
  SorterConfig('projekt.kundenAnfertigung', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDEN_ANFERTIGUNG.title'),
  SorterConfig('projekt.keineBesichtigungErforderlich', 'PROJEKTE.Models.PROJEKT.Propertys.KEINE_BESICHTIGUNG_ERFORDERLICH.title'),
  SorterConfig('projekt.besichtigungen', 'PROJEKTE.Models.PROJEKT.Propertys.BESICHTIGUNGEN.title'),
  SorterConfig('projekt.gutachten', 'PROJEKTE.Models.PROJEKT.Propertys.GUTACHTEN.title'),
  SorterConfig('projekt.pruefung', 'PROJEKTE.Models.PROJEKT.Propertys.PRUEFUNG.title'),
  SorterConfig('projekt.kunde', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDE.title'),
  SorterConfig('projekt.abteilung', 'PROJEKTE.Models.PROJEKT.Propertys.ABTEILUNG.title'),
  SorterConfig('projekt.fond', 'PROJEKTE.Models.PROJEKT.Propertys.FOND.title'),
  SorterConfig('projekt.bank', 'PROJEKTE.Models.PROJEKT.Propertys.BANK.title'),
  SorterConfig('projekt.objektArt', 'PROJEKTE.Models.PROJEKT.Propertys.OBJEKT_ART.title'),
  SorterConfig('projekt.nachlass', 'PROJEKTE.Models.PROJEKT.Propertys.NACHLASS.title'),
  SorterConfig('projekt.kundenZeichen', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDEN_ZEICHEN.title'),
  SorterConfig('projekt.kontakteInOutlook', 'PROJEKTE.Models.PROJEKT.Propertys.KONTAKTE_IN_OUTLOOK.title'),
  SorterConfig('projekt.dateiVerzeichnis', 'PROJEKTE.Models.PROJEKT.Propertys.DATEI_VERZEICHNIS.title'),
  SorterConfig('projekt.kommentar', 'PROJEKTE.Models.PROJEKT.Propertys.KOMMENTAR.title'),
  SorterConfig('projekt.notizenFuerRechnung', 'PROJEKTE.Models.PROJEKT.Propertys.NOTIZEN_FUER_RECHNUNG.title'),
  SorterConfig('projekt.fortschritt', 'PROJEKTE.Models.PROJEKT.Propertys.FORTSCHRITT.title'),
  // SorterConfig('projekt.strecke', 'PROJEKTE.Models.PROJEKT.Propertys.STRECKE.title'),
  // SorterConfig('projekt.kalkulierteStrecke', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_STRECKE.title'),
  // SorterConfig('projekt.fahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.FAHRTZEIT.title'),
  // SorterConfig('projekt.kalkulierteFahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_FAHRTZEIT.title'),
  SorterConfig('projekt.stunden', 'PROJEKTE.Models.PROJEKT.Propertys.STUNDEN.title'),
  SorterConfig('projekt.kostenNachStunden', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_NACH_STUNDEN.title'),
  SorterConfig('projekt.verhandeltesHonorar', 'PROJEKTE.Models.PROJEKT.Propertys.VERHANDELTES_HONORAR.title'),
  SorterConfig('projekt.kosten', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN.title'),
  SorterConfig('projekt.kostenBeglichen', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_BEGLICHEN.title'),
  SorterConfig('projekt.aktiv', 'PROJEKTE.Models.PROJEKT.Propertys.AKTIV.title'),
  SorterConfig('projekt.darlehensnehmer', 'PROJEKTE.Models.PROJEKT.Propertys.DARLEHENSNEHMER.title'),
  SorterConfig('projekt.notizenBesichtigung', 'PROJEKTE.Models.PROJEKT.Propertys.NOTIZEN_BESICHTIGUNG.title'),
  SorterConfig('projekt.kreditsachbearbeiterVorname', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_VORNAME.title'),
  SorterConfig('projekt.kreditsachbearbeiterName', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_NAME.title'),
  SorterConfig('projekt.kreditsachbearbeiterTelefon', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_TELEFON.title'),
  SorterConfig('projekt.kreditsachbearbeiterEMail', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_E_MAIL.title'),
];
