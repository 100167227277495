import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostObjektCommandShapes, IPostObjektRequest, IPostObjektResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostObjektCommandService extends PostCommandService<IPostObjektRequest, IPostObjektResponse, IPostObjektCommandShapes> {
  readonly route = 'objekte/objekt';
  override dependencies = ['geo/written-bereich', 'geo/removed-bereich'];

  getObjektBereiche(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['objekt.bereiche']?.find((v) => v.value === value);
  }

  get objektBereiche(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['objekt.bereiche'] as ISelection[]) : [])));
  }
}
