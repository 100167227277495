import { NgModule } from '@angular/core';

import {
  PostAbschnittCommandServiceModule,
  PostAbschnittCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostAbschnittCommandServiceModule,
    PostAbschnittCommandComponentModule
  ],
  exports: [
    PostAbschnittCommandServiceModule,
    PostAbschnittCommandComponentModule
  ]
})
export class PostAbschnittCommandModule { }
