import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { KundenServiceModule } from '..';
import { KundenListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenServiceModule
  ],
  declarations: [KundenListComponent],
  exports: [KundenListComponent],

})
export class KundenListComponentModule { }
