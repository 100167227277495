import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { VorlageQuerySorterConfig } from 'fa-kt.types';
import { VorlageService } from '../service';

@Component({
  selector: 'fa-kt-vorlage-sorter',
  templateUrl: './vorlage-sorter.component.html',
  styleUrls: ['./vorlage-sorter.component.css'],
})
export class VorlageSorterComponent {
  public VorlageSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public vorlage: VorlageService,
  ) {
    this.VorlageSorterConfig = VorlageQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    /*    update >> */
  }
}
