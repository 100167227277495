import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { SettingsQueryService } from 'pbc.angular';

import { IRollesQueryFilter, IRollesQueryShapes, IRollesQuerySorter, IRollesRequest, IRollesResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class RollesService extends SettingsQueryService<IRollesRequest, IRollesResponse, IRollesQueryShapes, IRollesQueryFilter, IRollesQuerySorter> {
  contentKey: keyof IRollesResponse = 'rolles';
  route = 'personen/settings/rolles';
  override dependencies = ['personen/written-rolle', 'personen/removed-rolle'];

  public nameIsUnique(control: AbstractControl | null): (name: string) => boolean {
    return (name: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (name) {
        const response = this.response$.getValue();
        if (response && response.rolles) {
          isUnique = response.rolles.filter((r) => r.id !== id && r.name === name).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    };
  }
}
