import { Injectable } from '@angular/core';

import { IDeleteDateiArtRequest, IDeleteDateiArtResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteDateiArtCommandService extends DeleteCommandService<IDeleteDateiArtRequest, IDeleteDateiArtResponse> {
  readonly route = 'dokumente/settings/datei-art';
}
