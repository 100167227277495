import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, cleanObject, CustomFormatter, CustomValidators, ISitemap, ISitemapCommand, ISitemapModel, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IMitarbeiter, IPostRolleResponse, IRolle } from 'fa-kt.types';
import { PostMitarbeiterCommandService } from '../../../../../commands';
import { MitarbeiterService } from '../../../../../querys';
import { RollesService } from '../../../query';
import { PostRolleCommandService } from '../service';

interface IFormValue {
  rolle?: IRolle;
}

@Component({
  selector: 'fa-kt-post-rolle',
  templateUrl: './post-rolle.component.html',
  styleUrls: ['./post-rolle.component.css'],
})
export class PostRolleCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;
  model!: ISitemapModel;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IFormValue) {
    this.patch(value);
  }
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostRolleResponse> = new EventEmitter<IPostRolleResponse>();

  $membering = new BehaviorSubject<boolean>(false);
  $membersChanged = new BehaviorSubject<boolean>(false);
  $members = new BehaviorSubject<string[]>([]);
  $mitarbeiter = new BehaviorSubject<ISelection[]>([]);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private actions: ActionService,
    private message: NzMessageService,
    public rolles: RollesService,
    public postRolle: PostRolleCommandService,
    /* << dependencies    */
    private mitarbeiterService: MitarbeiterService,
    private postMitarbeiterService: PostMitarbeiterCommandService /*    dependencies >> */,
  ) {
    this.command = sitemap.PERSONEN.Commands.POST_ROLLE;
    this.model = sitemap.PERSONEN.Models.ROLLE;
    /* << constructor    */
    /*    constructor >> */
    this.form = this.fb.group({
      rolle: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        beschreibung: [null, []],
        finanzen: [null, []],
        isAdmin: [false, []],
        authorizedRoutes: [[], []],
      }),
    });
    this.name?.setValidators([Validators.required, CustomValidators.isUnique(this.rolles.nameIsUnique(this.id))]);
    this.patch();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((value: any) => {
        this.valid.emit(this.form.valid);
        if (this.form.valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.id?.valueChanges.subscribe(async (id) => {
        /* << on-id-change    */
        /*    on-id-change >> */
      }) as Subscription,
      this.name?.valueChanges.subscribe(async (name) => {
        /* << on-name-change    */
        /*    on-name-change >> */
      }) as Subscription,
      this.beschreibung?.valueChanges.subscribe(async (beschreibung) => {
        /* << on-beschreibung-change    */
        /*    on-beschreibung-change >> */
      }) as Subscription,
      this.finanzen?.valueChanges.subscribe(async (finanzen) => {
        /* << on-finanzen-change    */
        /*    on-finanzen-change >> */
      }) as Subscription,
      /* << subscibe    */
      this.mitarbeiterService.response$.subscribe((response) => {
        if (!response?.mitarbeiter) {
          return;
        }
        this.$members.next(this.members);
        this.$mitarbeiter.next(this.mitarbeiter);
      }),
      this.$members.subscribe((members) => {
        if (!members) {
          return;
        }
        const state = this.members;
        this.$membersChanged.next(members.length !== state.length || !state.every((m) => members.includes(m)) || !members.every((m) => state.includes(m)));
      }),
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IFormValue) {
    let rolle = {
      id: null,
      name: null,
      beschreibung: null,
      finanzen: null,
      isAdmin: false,
      authorizedRoutes: [],
    };
    if (value && value.rolle) {
      rolle = {
        ...rolle,
        ...cleanObject(value.rolle),
      };
    }
    this.form.patchValue({ rolle });
    /* << custom-stuff    */
    /*    custom-stuff >> */
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload && payload.rolle) {
        payload.rolle = cleanObject(payload.rolle);
      }
      const response: IPostRolleResponse | undefined = await this.postRolle.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls.objektArt.get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get id() {
    return this.form.controls.rolle.get('id');
  }

  get name() {
    return this.form.controls.rolle.get('name');
  }

  get beschreibung() {
    return this.form.controls.rolle.get('beschreibung');
  }

  get finanzen() {
    return this.form.controls.rolle.get('finanzen');
  }

  /* << methods    */
  get canUpdateMembers(): boolean {
    return this.id?.value !== null && this.id?.value !== undefined;
  }
  get members(): string[] {
    const mitarbeiters = this.mitarbeiterService.response$.getValue()?.mitarbeiter;
    if (!mitarbeiters || !this.canUpdateMembers) {
      return [];
    }
    return mitarbeiters
      .map((mitarbeiter) => mitarbeiter.mitarbeiter)
      .filter((mitarbeiter) => this.id && mitarbeiter && mitarbeiter.rollen.includes(this.id.value))
      .map((mitarbeiter) => mitarbeiter.id);
  }
  get mitarbeiter(): ISelection[] {
    const mitarbeiters = this.mitarbeiterService.response$.getValue()?.mitarbeiter;
    if (!mitarbeiters || !this.canUpdateMembers) {
      return [];
    }
    return mitarbeiters.map((mitarbeiter) => mitarbeiter.mitarbeiter).map((mitarbeiter) => ({ value: mitarbeiter.id, label: mitarbeiter.anzeigename, avatar: mitarbeiter.bild }));
  }
  async updateMembers() {
    if (!this.canUpdateMembers) {
      return;
    }
    this.$membering.next(true);
    const state = this.mitarbeiterService.response$.getValue();
    if (!state || !state.mitarbeiter) {
      return;
    }
    const list: IMitarbeiter[] = state.mitarbeiter.map((mitarbeiter) => mitarbeiter.mitarbeiter);
    const previously = this.members;
    const now = this.$members.getValue();
    const removables = previously.filter((m) => !now.includes(m)).map((member) => list.find((m) => m.id === member));
    const addables = now.filter((m) => !previously.includes(m)).map((member) => list.find((m) => m.id === member));
    const id = this.id?.value;
    for (const mitarbeiter of removables.filter((m) => m).map((m) => ({ ...m, rollen: (m ? m.rollen : []).filter((r) => r !== id) }) as IMitarbeiter)) {
      await this.postMitarbeiterService.request({ mitarbeiter });
    }
    for (const mitarbeiter of addables.filter((m) => m).map((m) => ({ ...m, rollen: [...(m ? m.rollen : []), id] }) as IMitarbeiter)) {
      await this.postMitarbeiterService.request({ mitarbeiter });
    }
    this.message.success((addables.length > 0 ? `${addables.length} Benutzer:innen wurden hinzugefügt.` : '') + (removables.length > 0 ? ` ${removables.length} Benutzer:innen wurden entfernt` : ''));
    this.$membering.next(false);
  }
  /*    methods >> */
}
