import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VorlageService } from '../service';

@Component({
  selector: 'fa-kt-vorlage-detail',
  templateUrl: './vorlage-detail.component.html',
  styleUrls: ['./vorlage-detail.component.css'],
})
export class VorlageDetailComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() height = 'calc(100vh - 324px)';

  constructor(public vorlage: VorlageService) {}
}
