import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostExtraVereinbarungCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostExtraVereinbarungCommandService],
  exports: []
})
export class PostExtraVereinbarungCommandServiceModule { }
