import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DetailQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IFeldQueryShapes, IFeldRequest, IFeldResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class FeldService extends DetailQueryService<IFeldRequest, IFeldResponse, IFeldQueryShapes> {
  route = 'felder/feld';
  override dependencies = [
    'felder/written-feld-kategorie',
    'felder/removed-feld-kategorie',
    'felder/written-feld-unterkategorie',
    'felder/removed-feld-unterkategorie',

    'felder/bewertet',
    'felder/feld-gespeichert',
    'felder/feld-option-entfernt',
    'felder/feld-option-gespeichert',
    'felder/feld-optionen-geordnet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert',
  ];

  getFeldFeldKategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['feld.feldKategorie']?.find((v) => v.value === value);
  }

  get feldFeldKategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feld.feldKategorie'] as ISelection[]) : [])));
  }

  getFeldFeldUnterkategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['feld.feldUnterkategorie']?.find((v) => v.value === value);
  }

  get feldFeldUnterkategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feld.feldUnterkategorie'] as ISelection[]) : [])));
  }
}
