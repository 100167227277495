import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBesichtigungCommandServiceModule } from '..';
import { PostBesichtigungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBesichtigungCommandServiceModule
  ],
  declarations: [PostBesichtigungCommandComponent],
  exports: [PostBesichtigungCommandComponent],

})
export class PostBesichtigungCommandComponentModule { }
