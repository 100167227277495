import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IStandortsResponse } from 'fa-kt.types';
import { PostStandortCommandService } from '../commands';
import { StandortsService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class StandortsPageResolver implements Resolve<IStandortsResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private standorts: StandortsService,
    private postStandort: PostStandortCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IStandortsResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [standorts] = await Promise.all([this.standorts.request({}).catch(log), this.postStandort.prepare(true).catch(log)]);
    this.loading.resolved();
    return standorts;
  }
}
