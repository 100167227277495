import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { IPostZeileRequest, IPostZeileResponse, IZeile } from 'fa-kt.types';
import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';
import { PostZeileCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-zeilen',
  templateUrl: './zeilen.component.html',
  styleUrls: ['./zeilen.component.css'],
})
export class ZeilenComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postZeile: BehaviorSubject<IPostZeileRequest | null> = new BehaviorSubject<IPostZeileRequest | null>(null);

  @Input() zeilen: IZeile[] = [];
  @Input() bereiche: ISelection[] = [];
  editCache: { [key: string]: { edit: boolean; zeile: IZeile } } = {};

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postZeile: PostZeileCommandService,
  ) {}

  async ngOnInit() {
    /* << init    */
    this.updateEditCache();
    /*    init >> */
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostZeile(response?: IPostZeileResponse) {
    /* << after post-zeile    */
    /*    after post-zeile >> */
  }

  /* << methods    */
  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.zeilen.findIndex((item) => item.id === id);
    this.editCache[id] = {
      zeile: { ...this.zeilen[index] },
      edit: false,
    };
  }

  async saveEdit(id: string): Promise<void> {
    const index = this.zeilen.findIndex((item) => item.id === id);
    const zeile = this.editCache[id].zeile;
    this.loading.resolving();
    await this.submitPostZeile({ zeile });
    this.loading.resolved();
    Object.assign(this.zeilen[index], zeile);
    this.editCache[id].edit = false;
  }

  updateEditCache(): void {
    this.zeilen.forEach((item) => {
      this.editCache[item.id] = {
        edit: false,
        zeile: { ...item },
      };
    });
  }

  trackByIndex(_: number, data: IZeile): string {
    return data.id;
  }
  /*    methods >> */

  public async submitPostZeile(request?: IPostZeileRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postZeile.getValue();
    if (payload) {
      try {
        const response = await this.postZeile.request(payload);
        await this.finishedPostZeile(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
