import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { SettingsQueryService } from 'pbc.angular';

import { IFeldKategoriesQueryFilter, IFeldKategoriesQueryShapes, IFeldKategoriesQuerySorter, IFeldKategoriesRequest, IFeldKategoriesResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class FeldKategoriesService extends SettingsQueryService<IFeldKategoriesRequest, IFeldKategoriesResponse, IFeldKategoriesQueryShapes, IFeldKategoriesQueryFilter, IFeldKategoriesQuerySorter> {
  contentKey: keyof IFeldKategoriesResponse = 'feldKategories';
  route = 'felder/settings/feld-kategories';
  override dependencies = ['felder/written-feld-kategorie', 'felder/removed-feld-kategorie'];

  public initialenIsUnique(control: AbstractControl | null): (initialen: string) => boolean {
    return (initialen: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (initialen) {
        const response = this.response$.getValue();
        if (response && response.feldKategories) {
          isUnique = response.feldKategories.filter((fk) => fk.id !== id && fk.initialen === initialen).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    };
  }
}
