import { NgModule } from '@angular/core';

import { BereichsSettingsModule, OrtKategoriesSettingsModule, OrtsSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [BereichsSettingsModule, OrtsSettingsModule, OrtKategoriesSettingsModule],
  exports: [BereichsSettingsModule, OrtsSettingsModule, OrtKategoriesSettingsModule],
})
export class GeoSettingsModule {}
