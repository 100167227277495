import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DurchgangServiceModule } from '..';
import { DurchgangDetailComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DurchgangServiceModule
  ],
  declarations: [DurchgangDetailComponent],
  exports: [DurchgangDetailComponent],

})
export class DurchgangDetailComponentModule { }
