import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { BasePageResolver } from 'pbc.angular';

import { ITourStatiResponse } from 'fa-kt.types';
import { PostTourStatusCommandService } from '../commands';
import { TourStatiService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class TourStatiPageResolver extends BasePageResolver<ITourStatiResponse | void> {
  constructor(
    private tourStati: TourStatiService,
    private postTourStatus: PostTourStatusCommandService,
  ) {
    super();
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ITourStatiResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [tourStati] = await Promise.all([this.tourStati.request({}).catch(log), this.postTourStatus.prepare(true).catch(log)]);
    this.loading.resolved();
    return tourStati;
  }
}
