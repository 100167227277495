import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IAbschnittSorter extends ISorter {
  id?: ISorterExpression;
  vorlage?: ISorterExpression;
  platzhalter?: ISorterExpression;
  name?: ISorterExpression;
  text?: ISorterExpression;
}

export const AbschnittSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('vorlage', 'Vorlage'),
  SorterConfig('platzhalter', 'Platzhalter'),
  SorterConfig('name', 'Name'),
  SorterConfig('text', 'Text')
];
