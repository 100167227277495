import { NgModule } from '@angular/core';

import {
  PostRechnungenHochzaehlenCommandServiceModule,
  PostRechnungenHochzaehlenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostRechnungenHochzaehlenCommandServiceModule,
    PostRechnungenHochzaehlenCommandComponentModule
  ],
  exports: [
    PostRechnungenHochzaehlenCommandServiceModule,
    PostRechnungenHochzaehlenCommandComponentModule
  ]
})
export class PostRechnungenHochzaehlenCommandModule { }
