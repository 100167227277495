import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { BasePageResolver } from 'pbc.angular';

import { IFormulareRequest, IOffeneGutachtenRequest, ITourRequest, ITourResponse } from 'fa-kt.types';
import { KundenService } from '../../../kunden';
import { ObjektArtsService } from '../../../objekte';
import { ProjektArtsService } from '../../../projekte';
import { PostBesichtigungCommandService, PostTourCommandService } from '../../commands';
import { TourService } from '../../querys';

export interface ITourPageResolverResult {
  tour?: ITourResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class TourPageResolver extends BasePageResolver<ITourPageResolverResult> {
  constructor(
    private tour: TourService,
    private postTour: PostTourCommandService,
    private postBesichtigung: PostBesichtigungCommandService,
    private objektArten: ObjektArtsService,
    private projektArten: ProjektArtsService,
    private kunden: KundenService,
  ) {
    super();
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ITourPageResolverResult> {
    this.loading.resolving();
    const requests = {
      tour: {} as ITourRequest,
      offeneGutachten: {} as IOffeneGutachtenRequest,
      formulare: {} as IFormulareRequest,
    };
    requests.tour.id = route.queryParams['id'];
    const log = (error: any) => console.error(error);
    const [tour, offeneGutachten, formulare] = await Promise.all([
      this.tour.request(requests.tour).catch(log),
      this.postTour.prepare().catch(log),
      this.postBesichtigung.prepare().catch(log),
      this.kunden.request({}).catch(console.error),
      this.objektArten.request({}).catch(console.error),
      this.projektArten.request({}).catch(console.error),
    ]);
    this.loading.resolved();
    return {
      tour,
    };
  }
}
