import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IProjektStatiQueryFilter, IProjektStatiQueryShapes, IProjektStatiQuerySorter, IProjektStatiRequest, IProjektStatiResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ProjektStatiService extends SettingsQueryService<IProjektStatiRequest, IProjektStatiResponse, IProjektStatiQueryShapes, IProjektStatiQueryFilter, IProjektStatiQuerySorter> {
  contentKey: keyof IProjektStatiResponse = 'projektStati';
  route = 'projekte/settings/projekt-stati';
  override dependencies = ['projekte/written-projekt-status', 'projekte/removed-projekt-status'];
}
