import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostReferenzenHochladenCommandServiceModule } from '..';
import { PostReferenzenHochladenCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostReferenzenHochladenCommandServiceModule
  ],
  declarations: [PostReferenzenHochladenCommandComponent],
  exports: [PostReferenzenHochladenCommandComponent],

})
export class PostReferenzenHochladenCommandComponentModule { }
