import {
  BooleanFilterConfig,
  DateFilterConfig,
  ICommandRequest,
  ICommandResponse,
  IFilter,
  IFilterConfig,
  IModel,
  IPersistedFilterList,
  IQueryRequest,
  IQueryResponse,
  ISelection,
  IShape,
  ISorter,
  ISorterConfig,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
  SorterConfig,
} from 'pbc.types';
import { IMitarbeiter } from '../../personen';

export interface IRahmenAnpassung extends IModel {
  id?: string;
  mitarbeiter: string;
  stunden?: number;
  gültigBis?: Date;
  kommentar?: string;
  abgelehnt?: boolean;
  angenommen?: boolean;
}

export interface IRahmenAnpassungFilter extends IFilter<IRahmenAnpassung> {}
export type IRahmenAnpassungField = 'rahmenAnpassung.id' | 'rahmenAnpassung.mitarbeiter' | 'rahmenAnpassung.von' | 'rahmenAnpassung.bis' | 'rahmenAnpassung.abgelehnt' | 'rahmenAnpassung.angenommen';
export const RahmenAnpassungFilterConfig: IFilterConfig<IRahmenAnpassung> = [
  SingleReferenceFilterConfig<IMitarbeiter>('rahmenAnpassung.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  NumberFilterConfig('rahmenAnpassung.stunden', 'Stunden'),
  DateFilterConfig('rahmenAnpassung.gültigBis', 'Gültig bis'),
  BooleanFilterConfig('rahmenAnpassung.abgelehnt', 'Abgelehnt'),
  BooleanFilterConfig('rahmenAnpassung.angenommen', 'Angenommen'),
];
export const RahmenAnpassungenSorterConfig: ISorterConfig = [
  SorterConfig('rahmenAnpassung.id', 'Rahmen-Anpassung'),
  SorterConfig('rahmenAnpassung.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  SorterConfig('rahmenAnpassung.stunden', 'Stunden'),
  SorterConfig('rahmenAnpassung.bis', 'Bis'),
  SorterConfig('rahmenAnpassung.abgelehnt', 'Abgelehnt'),
  SorterConfig('rahmenAnpassung.angenommen', 'Angenommen'),
];
export interface IRahmenAnpassungenShapes extends IShape {
  'rahmenAnpassung.mitarbeiter': ISelection[];
}
export interface IRahmenAnpassungenRequest extends IQueryRequest {
  person?: string;
}

export interface IRahmenAnpassungenResponse extends IQueryResponse {
  rahmenAnpassungen: IRahmenAnpassungenResponseRow[];
}

export interface IRahmenAnpassungenResponseRow extends IQueryResponse {
  rahmenAnpassung: IRahmenAnpassung;
}

export interface IRahmenAnpassungenResponseGroup {
  response: IRahmenAnpassungenResponse;
  shapes: IRahmenAnpassungenQueryShapes;
  filter: IPersistedFilterList<IRahmenAnpassungenQueryFilter>;
}

export type IRahmenAnpassungenQueryField = IRahmenAnpassungField;
export interface IRahmenAnpassungenQuerySorter extends ISorter {}
export interface IRahmenAnpassungenQueryFilter extends IFilter<IRahmenAnpassungenResponse> {}
export const RahmenAnpassungenQuerySorterConfig: ISorterConfig = RahmenAnpassungenSorterConfig;
export const RahmenAnpassungenQueryFilterConfig: IFilterConfig<IRahmenAnpassungenResponse> = RahmenAnpassungFilterConfig;
export type IRahmenAnpassungenQueryShapes = IRahmenAnpassungenShapes;

export interface IRahmenAnpassungRequest extends IQueryRequest {
  rahmenAnpassung: string;
}

export interface IRahmenAnpassungResponse extends IQueryResponse {
  rahmenAnpassung: IRahmenAnpassung;
}

export interface IRahmenAnpassungResponseGroup {
  response: IRahmenAnpassungResponse;
  shapes: IRahmenAnpassungQueryShapes;
}

export type IRahmenAnpassungQueryField = IRahmenAnpassungField;
export interface IRahmenAnpassungQuerySorter extends ISorter {}
export interface IRahmenAnpassungQueryFilter extends IFilter<IRahmenAnpassungenResponse> {}
export type IRahmenAnpassungQueryShapes = IRahmenAnpassungenShapes;
export const RahmenAnpassungQuerySorterConfig: ISorterConfig = RahmenAnpassungenSorterConfig;
export const RahmenAnpassungQueryFilterConfig: IFilterConfig<IRahmenAnpassungenResponse> = RahmenAnpassungFilterConfig;

export interface IPostRahmenAnpassungRequest extends ICommandRequest {
  rahmenAnpassung: IRahmenAnpassung;
}
export interface IPostRahmenAnpassungResponse extends ICommandResponse {
  rahmenAnpassung: IRahmenAnpassung;
}
export interface IPostRahmenAnpassungCommandShapes extends IShape {
  'rahmenAnpassung.mitarbeiter': ISelection[];
}

export interface IDeleteRahmenAnpassungRequest extends ICommandRequest {
  id: string;
}
export interface IDeleteRahmenAnpassungResponse extends ICommandResponse {}
export interface IDeleteRahmenAnpassungCommandShapes extends IShape {}
