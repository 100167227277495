import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { ImageCropperModule } from 'ngx-image-cropper';

import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzListModule } from "ng-zorro-antd/list";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzSpaceModule } from "ng-zorro-antd/space";

import { ImageUploadComponent, OutlookContactsComponent } from "./components";
import { OutlookContactsService } from "./services";

import { LIB_AuthModule } from "../auth";
import { LIB_FilterModule } from "../filter";
import { LIB_HttpsModule } from "../https";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ImageCropperModule,
    LIB_AuthModule,
    LIB_HttpsModule,
    LIB_FilterModule,
    NzGridModule,
    NzListModule,
    NzDropDownModule,
    NzInputModule,
    NzDatePickerModule,
    NzFormModule,
    NzButtonModule,
    NzModalModule,
    NzAvatarModule,
    NzPageHeaderModule,
    NzIconModule,
    NzPopconfirmModule,
    NzSpaceModule
  ],
  declarations: [
    ImageUploadComponent,
    OutlookContactsComponent
  ],
  exports: [
    ImageUploadComponent,
    OutlookContactsComponent,
    ImageCropperModule
  ],
})
export class LIB_ContactsModule {
  static forRoot(): ModuleWithProviders<LIB_ContactsModule> {
    return {
      ngModule: LIB_ContactsModule,
      providers: [
        OutlookContactsService
      ]
    }
  }
}
