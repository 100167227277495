import { Injectable } from '@angular/core';

import { IPostKopierenCommandShapes, IPostKopierenRequest, IPostKopierenResponse } from 'fa-kt.types';
import { PostCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class PostKopierenCommandService extends PostCommandService<IPostKopierenRequest, IPostKopierenResponse, IPostKopierenCommandShapes> {
  readonly route = 'projekte/kopieren';
}
