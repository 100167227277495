import { NgModule } from '@angular/core';

import {
  PostBereichCommandServiceModule,
  PostBereichCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostBereichCommandServiceModule,
    PostBereichCommandComponentModule
  ],
  exports: [
    PostBereichCommandServiceModule,
    PostBereichCommandComponentModule
  ]
})
export class PostBereichCommandModule { }
