import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ActionService, OfflineStoreService } from '../../..';
import { AuthService } from '../../services';

@Component({
  selector: 'pbc-user-popup-menu',
  templateUrl: './auth-popup-menu.component.html',
  styleUrls: ['./auth-popup-menu.component.css'],
})
export class AuthPopupMenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  showMenu = false;
  $settings = new BehaviorSubject<boolean>(false);

  get actions(): ActionService {
    return this.injector.get(ActionService);
  }
  get offlineCache(): OfflineStoreService {
    return this.injector.get(OfflineStoreService);
  }

  constructor(
    private injector: Injector,
    private router: Router,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      ...[
        this.auth.$id.subscribe((id) => {
          if (id) {
            this.actions.subscribe({ key: 'Zu meinem Profil', action: () => this.navigateToMe() });
            this.actions.subscribe({ key: 'Zum Gerät', action: () => this.navigateToDevice() });
          } else {
            this.actions.unsubscribe('Zum meinem Profil');
            this.actions.unsubscribe('Zum Gerät');
            this.actions.unsubscribe('Zu den Einstellungen');
          }
        }),
        combineLatest([this.auth.$isAdmin.pipe(debounceTime(10), distinctUntilChanged()), this.auth.$routes]).subscribe(([isAmin, routes]) => {
          this.$settings.next(isAmin || this.auth.access('einstellungen/', routes) || this.auth.access('/settings/', routes));
        }),
        this.$settings.subscribe((settings) => {
          if (settings) {
            this.actions.subscribe({ key: 'Zu den Einstellungen', action: () => this.navigateToSettings() });
          } else {
            this.actions.unsubscribe('Zu den Einstellungen');
          }
        }),
      ],
    );
  }

  ngOnDestroy(): void {
    this.actions.unsubscribe('Zum meinem Profil');
    this.actions.unsubscribe('Zum Gerät');
    this.actions.unsubscribe('Zu den Einstellungen');
    this.subscriptions.filter(($) => $ && !$.closed).forEach(($) => $.unsubscribe());
  }

  navigateToMe() {
    this.router.navigate(['/', 'personen', 'ich']).catch();
    this.showMenu = false;
  }
  navigateToSettings() {
    this.router.navigate(['/', 'einstellungen', 'einstellungen']).catch();
    this.showMenu = false;
  }
  navigateToDevice() {
    this.router.navigate(['/', 'device']).catch();
    this.showMenu = false;
  }
}
