import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IArchivQueryFilter, IArchivQueryShapes, IArchivQuerySorter, IArchivRequest, IArchivResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ArchivService extends ListQueryService<IArchivRequest, IArchivResponse, IArchivQueryShapes, IArchivQueryFilter, IArchivQuerySorter> {
  contentKey: keyof IArchivResponse = 'archiv';
  route = 'projekte/archiv';
  override dependencies = [
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',
    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'dokumente/datei-gespeichert',
    'finanzen/rechnung-erstellt',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'formulare/durchgang-gestartet',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert',
  ];

  getProjektAbteilung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.abteilung']?.find((v) => v.value === value);
  }

  get projektAbteilung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.abteilung'] as ISelection[]) : [])));
  }

  getProjektFond(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.fond']?.find((v) => v.value === value);
  }

  get projektFond(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.fond'] as ISelection[]) : [])));
  }

  getProjektKunde(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.kunde']?.find((v) => v.value === value);
  }

  get projektKunde(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.kunde'] as ISelection[]) : [])));
  }

  getProjektBank(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.bank']?.find((v) => v.value === value);
  }

  get projektBank(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.bank'] as ISelection[]) : [])));
  }

  getProjektBesichtigungen(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.besichtigungen']?.find((v) => v.value === value);
  }

  get projektBesichtigungen(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.besichtigungen'] as ISelection[]) : [])));
  }

  getProjektGutachten(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.gutachten']?.find((v) => v.value === value);
  }

  get projektGutachten(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.gutachten'] as ISelection[]) : [])));
  }

  getProjektPruefung(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.pruefung']?.find((v) => v.value === value);
  }

  get projektPruefung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.pruefung'] as ISelection[]) : [])));
  }

  getProjektProjektArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.projektArt']?.find((v) => v.value === value);
  }

  get projektProjektArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.projektArt'] as ISelection[]) : [])));
  }

  getProjektProjektStatus(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.projektStatus']?.find((v) => v.value === value);
  }

  get projektProjektStatus(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.projektStatus'] as ISelection[]) : [])));
  }

  getProjektStandort(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['projekt.standort']?.find((v) => v.value === value);
  }

  get projektStandort(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['projekt.standort'] as ISelection[]) : [])));
  }
}
