import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFondSorter extends ISorter {
  id?: ISorterExpression;
  kunde?: ISorterExpression;
  name?: ISorterExpression;
  aktiv?: ISorterExpression;
}

export const FondSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('name', 'Name'),
  SorterConfig('aktiv', 'Aktiv')
];
