import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { GutachtenComponent } from '.';
import { DokumentenAnforderungenComponentModule } from '../../../dokumente';
import { PostDurchgangCommandModule } from '../../../formulare';
import { KommunikationComponentModule } from '../../../kommunikation';
import { BerichteComponentModule } from '../../../markt';
import { DeleteObjektCommandModule, ObjekteComponentModule, ObjekteQueryModule, PostObjektCommandModule } from '../../../objekte';
import { ProjektQueryModule } from '../../../projekte';
import { DeleteGutachtenCommandModule, PostGutachtenCommandModule } from '../../commands';
import { GutachtenQueryModule } from '../../querys';
import { EintraegeComponentModule } from '../eintraege';
import { KalkulationenComponentModule } from '../kalkulationen';
import { SicherheitscheckComponentModule } from '../sicherheitscheck';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenQueryModule,
    ObjekteQueryModule,
    ProjektQueryModule,
    PostDurchgangCommandModule,
    PostGutachtenCommandModule,
    DeleteGutachtenCommandModule,
    PostObjektCommandModule,
    DeleteObjektCommandModule,
    BerichteComponentModule,
    DokumentenAnforderungenComponentModule,
    EintraegeComponentModule,
    KalkulationenComponentModule,
    SicherheitscheckComponentModule,
    ObjekteComponentModule,
    KommunikationComponentModule,
  ],
  declarations: [GutachtenComponent],
  exports: [GutachtenComponent],
})
export class GutachtenComponentModule {}
