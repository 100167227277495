import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { GutachtenCommandsModule } from './commands';
import { GutachtenComponentsModule } from './components';
import { UmkreissuchePage, UmkreissuchePageResolver } from './pages';
import { GutachtensService, GutachtenQuerysModule, GutachtenService, UmkreissucheService } from './querys';
import {
  BewertungsAnlasssPage,
  BewertungsAnlasssPageResolver,
  BewertungsAnlasssService,
  BewertungsAnlasssServiceModule,
  GutachtenSettingsModule,
  GutachtenStatiPage,
  GutachtenStatiPageResolver,
  GutachtenStatiService,
  GutachtenStatiServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'gutachten',
    children: [
      {
        path: 'umkreissuche',
        component: UmkreissuchePage,
        resolve: {
          querys: UmkreissuchePageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['gutachten'],
            roles: ['GET_UMKREISSUCHE'],
          },
        },
      },
    ],
  },
  {
    path: 'gutachten',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'gutachten-stati',
            component: GutachtenStatiPage,
            resolve: {
              querys: GutachtenStatiPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_GUTACHTEN_STATI'],
                  write: ['POST_GUTACHTEN_STATUS', 'DELETE_GUTACHTEN_STATUS'],
                },
              },
            },
          },
          {
            path: 'bewertungs-anlasss',
            component: BewertungsAnlasssPage,
            resolve: {
              querys: BewertungsAnlasssPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_BEWERTUNGS_ANLASSS'],
                  write: ['POST_BEWERTUNGS_ANLASS', 'DELETE_BEWERTUNGS_ANLASS'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [UmkreissuchePage, GutachtenStatiPage, BewertungsAnlasssPage],
  imports: [
    LibsCommonAngularModule,
    GutachtenSettingsModule,
    GutachtenComponentsModule,
    GutachtenCommandsModule,
    GutachtenQuerysModule,
    BewertungsAnlasssServiceModule,
    GutachtenStatiServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [GutachtenSettingsModule, GutachtenComponentsModule, GutachtenCommandsModule, GutachtenQuerysModule, BewertungsAnlasssServiceModule, GutachtenStatiServiceModule],
})
export class GutachtenModule {
  static forRoot(): ModuleWithProviders<GutachtenModule> {
    return {
      ngModule: GutachtenModule,
      providers: [GutachtensService, GutachtenService, UmkreissucheService, BewertungsAnlasssService, GutachtenStatiService],
    };
  }
}
