<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="umkreissuche.search$.next($event)" [search]="umkreissuche.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-umkreissuche-filter [style.width]="'100%'"></fa-kt-umkreissuche-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-umkreissuche-sorter [style.width]="'100%'"></fa-kt-umkreissuche-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-umkreissuche-list [style.width]="'100%'" (select)="setUmkreissuche($event)" [loading]="$loading | async"></fa-kt-umkreissuche-list>




    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="projekt" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="archiv" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <nz-space style="float: right" nzSize="large">
      <span *nzSpaceItem style="line-height: 32px" nz-popover nzPopoverTitle="Ø Durchschnitte" [nzPopoverContent]="AVG">
        <a>Ø für</a> {{ (umkreissuche.result$ | async)?.umkreissuche.length }} / {{ (umkreissuche.response$ | async)?.umkreissuche.length }}</span
      >
      <ng-template #AVG>
        <nz-row>
          <nz-col nzSpan="16">
            <nz-statistic [nzValue]="(avg$ | async).marktwert | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Marktwert ' + ((avg$ | async).marktwertCount | number) + 'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic [nzValue]="(avg$ | async).kaufpreis | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Kaufpreis ' + ((avg$ | async).kaufpreisCount | number) + 'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="24">
            <br />
          </nz-col>
          <nz-col nzSpan="16">
            <nz-statistic [nzValue]="(avg$ | async).vervielfaeltiger | number:'0.0-0':'de-DE'" [nzTitle]="'Verfielfältiger ' + ((avg$ | async).vervielfaeltigerCount | number) + 'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic [nzValue]="(avg$ | async).gebaeudefaktor | number:'0.0-0':'de-DE'" [nzTitle]="'Gebäudefaktor ' + ((avg$ | async).gebaeudefaktorCount | number) + 'x'"></nz-statistic>
          </nz-col>

          <nz-col nzSpan="24">
            <br />
          </nz-col>
          <nz-col nzSpan="16">
            <nz-statistic [nzValue]="(avg$ | async).baujahr" [nzTitle]="'Baujahr ' + ((avg$ | async).baujahrCount | number) + 'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic [nzValue]="(avg$ | async).modernisierung" [nzTitle]="'Modernisierung ' + ((avg$ | async).modernisierungCount | number) + 'x'"></nz-statistic>
          </nz-col>

          <nz-col nzSpan="24">
            <br />
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic [nzValue]="(avg$ | async).jahresrohertrag | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Jahresrohertrag ' + (avg$ | async).jahresrohertragCount +  'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic [nzValue]="(avg$ | async).flaeche | number:'0.0-0':'de-DE'" nzSuffix="m2" [nzTitle]="'Fläche ' + ((avg$ | async).flaecheCount | number) + 'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="8">
            <nz-statistic
              [nzValue]="(avg$ | async).jahresrohertragNachFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag pro m2 ' + (avg$ | async).jahresrohertragNachFlaecheCount +  'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="24">
            <br />
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic [nzValue]="(avg$ | async).wohnenJRoE | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Jahresrohertrag Wohnen ' + (avg$ | async).wohnenJRoECount +  'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).wohnenJRoEFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="m2"
              [nzTitle]="'Fläche Wohnen ' + ((avg$ | async).wohnenJRoEFlaecheCount | number) + 'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).wohnenJRoENachFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag Wohnen pro m2 ' + (avg$ | async).wohnenJRoENachFlaecheCount +  'x'"
            ></nz-statistic>
          </nz-col>

          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic [nzValue]="(avg$ | async).gewerbeJRoE | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Jahresrohertrag Gewerbe ' + (avg$ | async).gewerbeJRoECount +  'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).gewerbeJRoEFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="m2"
              [nzTitle]="'Fläche Gewerbe ' + ((avg$ | async).gewerbeJRoEFlaecheCount | number) + 'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).gewerbeJRoENachFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag Gewerbe pro m2 ' + (avg$ | async).gewerbeJRoENachFlaecheCount +  'x'"
            ></nz-statistic>
          </nz-col>

          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic [nzValue]="(avg$ | async).bueroJRoE | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Jahresrohertrag Büro ' + (avg$ | async).bueroJRoECount +  'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).bueroJRoEFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="m2"
              [nzTitle]="'Fläche Büro ' + ((avg$ | async).bueroJRoEFlaecheCount | number) + 'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).bueroJRoENachFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag Büro pro m2 ' + (avg$ | async).bueroJRoENachFlaecheCount +  'x'"
            ></nz-statistic>
          </nz-col>

          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic [nzValue]="(avg$ | async).handelJRoE | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Jahresrohertrag Handel ' + (avg$ | async).handelJRoECount +  'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).handelJRoEFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="m2"
              [nzTitle]="'Fläche Handel ' + ((avg$ | async).handelJRoEFlaecheCount | number) + 'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).handelJRoENachFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag Handel pro m2 ' + (avg$ | async).handelJRoENachFlaecheCount +  'x'"
            ></nz-statistic>
          </nz-col>

          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic [nzValue]="(avg$ | async).lagerJRoE | number:'0.0-0':'de-DE'" nzSuffix="€" [nzTitle]="'Jahresrohertrag Lager ' + (avg$ | async).lagerJRoECount +  'x'"></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).lagerJRoEFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="m2"
              [nzTitle]="'Fläche Lager ' + ((avg$ | async).lagerJRoEFlaecheCount | number) + 'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).lagerJRoENachFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag Lager pro m2 ' + (avg$ | async).lagerJRoENachFlaecheCount +  'x'"
            ></nz-statistic>
          </nz-col>

          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).sonstigesJRoE | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag Sonstiges ' + (avg$ | async).sonstigesJRoECount +  'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).sonstigesJRoEFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="m2"
              [nzTitle]="'Fläche Sonstiges ' + ((avg$ | async).sonstigesJRoEFlaecheCount | number) + 'x'"
            ></nz-statistic>
          </nz-col>
          <nz-col nzSpan="2"> </nz-col>
          <nz-col nzSpan="6">
            <nz-statistic
              [nzValue]="(avg$ | async).sonstigesJRoENachFlaeche | number:'0.0-0':'de-DE'"
              nzSuffix="€"
              [nzTitle]="'Jahresrohertrag Sonstiges pro m2 ' + (avg$ | async).sonstigesJRoENachFlaecheCount +  'x'"
            ></nz-statistic>
          </nz-col>

          <!-- 
              JRoE: number;
              bueroJRoEFlaeche: number;
              handelJRoE: number;
              handelJRoEFlaeche: number;
              lagerJRoE: number;
              lagerJRoEFlaeche: number;
              sonstigesJRoE: number;
              sonstigesJRoEFlaeche: number;
              -->
        </nz-row>
      </ng-template>
      <button *nzSpaceItem nz-button nzType="dashed" (click)="export()">
        <i nz-icon nzType="file-excel" nzTheme="outline" style="padding-right: 6px"></i>
        Auswahl exportieren
      </button>
      <div *nzSpaceItem style="width: 100%">
        <fa-kt-saved-umkreissuche-filters style="width: 100%"></fa-kt-saved-umkreissuche-filters>
      </div>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <nz-spin [nzSpinning]="$loading | async">
      <nz-row>
        <nz-col nzSpan="8">
          <input
            nz-input
            [ngModel]="$input | async"
            (ngModelChange)="$input.next($event)"
            placeholder="Direkte Suche nach Adressen"
            name="xyz123"
            id="xyz123"
            autocomplete="xyz123"
            [nzAutocomplete]="AddressSuggestions"
            cdkFocusInitial
          />
          <nz-autocomplete #AddressSuggestions nzBackfill nzDefaultActiveFirstOption="true" [nzWidth]="364">
            <nz-auto-option
              *ngFor="let suggestion of $suggestions | async"
              [nzValue]="suggestion.address.freeformAddress"
              [nzLabel]="suggestion.address.freeformAddress"
              (click)="setSuggestion(suggestion)"
            >
              <span nz-typography nzType="success">{{ suggestion.address.freeformAddress }}</span>
            </nz-auto-option>
          </nz-autocomplete>
        </nz-col>
        <nz-col nzSpan="16">
          <nz-input-number
            style="margin-left: 8px"
            [nzStep]="distanceStep(search$.getValue().distance)"
            [nzMin]="1"
            [ngModel]="(search$ | async).distance"
            (ngModelChange)="changedDistance($event)"
            [nzFormatter]="formatterMeter"
            [nzParser]="parserMeter"
          ></nz-input-number>
          <label style="margin-left: 8px" nz-checkbox [ngModel]="inklusiveInaktive" (ngModelChange)="changedInklusiveInaktive($event)">
            <i nz-icon nzType="inbox" nzTheme="outline"></i>
            Archiv
          </label>
        </nz-col>
      </nz-row>
      <nz-row>
        <nz-col nzSpan="24">
          <label
            style="border-radius: 5px; margin: 4px; padding: 2px; font-size: 12px"
            *ngFor="let gutachtenObjektArt of $gutachtenObjektArts | async"
            [style.backgroundColor]="gutachtenObjektArt.color"
            (click)="setObjektArt(gutachtenObjektArt.value)"
          >
            {{ gutachtenObjektArt.label }}
          </label>
        </nz-col>
      </nz-row>
      <nz-row>
        <nz-col nzSpan="24">
          <br />
          <pbc-search-bar [style.width]="'100%'" (searchChange)="umkreissuche.search$.next($event)" [search]="umkreissuche.search$ | async"></pbc-search-bar>
        </nz-col>
      </nz-row>
      <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
        <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
          <fa-kt-umkreissuche-filter [style.width]="'100%'"></fa-kt-umkreissuche-filter>
        </nz-col>
      </nz-row>
    </nz-spin>
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <nz-spin [nzSpinning]="$loading | async">
        <nz-row>
          <nz-col [nzSpan]="popup ? 16 : 24">
            <pbc-atlas-map
              height="calc(100vh - 382px)"
              width="100%"
              [circle]="$circle | async"
              [coordinateArray]="$coordinateArrayWithSelected | async"
              mode="addresses"
              [resize]="$resize | async"
              (clicked)="showProjekt($event)"
            ></pbc-atlas-map>
          </nz-col>
          <nz-col *ngIf="popup" nzSpan="8" style="padding: 24px">
            <nz-space style="float: right">
              <a *nzSpaceItem nz-button nzType="link" [routerLink]="_projekt.url" [queryParams]="{ id: popup.projekt.id }">Öffnen <span nz-icon nzType="arrow-right" nzTheme="outline"></span></a>
              <button *nzSpaceItem nz-button nzType="primary" nzShape="circle" (click)="showProjekt()"><span nz-icon nzType="close"></span></button>
            </nz-space>
            <br />
            <h4>{{popup.projekt.nummer}} | {{popup.projekt.bezeichnung}}</h4>
            <h2>{{popup.objekt.name}}</h2>
            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.projekt.kunde | type: (projekte.projektKunde | async)" [nzTitle]="'Kunde'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.objektNutzung | type: (projekte.gutachtenObjektNutzung | async)" [nzTitle]="'Nutzung'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.objektArt | type: (projekte.gutachtenObjektArt | async)" [nzTitle]="'Nutzung'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.baujahr || '-'" [nzTitle]="'Baujahr'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.marktwert | number:'0.2-2':'de') || '-'" [nzTitle]="'Marktwert'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.stichtagMarktwert | date:'dd.MM.yyyy') || '-'" [nzTitle]="'Stichtag'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.flaeche | number:'0.2-2':'de') || '-'" [nzTitle]="'Flächen'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.jahresrohertrag | number:'0.2-2':'de') || '-'" [nzTitle]="'JRoE'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.vervielfaeltiger | number:'0.0-3':'de') || '-'" [nzTitle]="'Rohertragsvervielfältiger'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="(popup.gutachten.gebaeudefaktor | number:'0.2-2':'de') || '-'" [nzTitle]="'Marktwert pro m²'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.gutachten.erbbaurecht ? '✅' : '⛔️'" [nzTitle]="'Erbbaurecht'"></nz-statistic>
              </nz-col>
              <nz-col [nzSpan]="12">
                <nz-statistic [nzValue]="popup.projekt.aktiv ? '✅' : '⛔️'" [nzTitle]="'Aktiv'"></nz-statistic>
              </nz-col>
            </nz-row>
          </nz-col>
        </nz-row>
      </nz-spin>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
