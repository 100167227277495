import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostDurchgangCommandShapes, IPostDurchgangRequest, IPostDurchgangResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostDurchgangCommandService extends PostCommandService<IPostDurchgangRequest, IPostDurchgangResponse, IPostDurchgangCommandShapes> {
  readonly route = 'formulare/durchgang';
}
