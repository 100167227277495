import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NzSelectModeType } from 'ng-zorro-antd/select';
import { NzSelectSizeType } from 'ng-zorro-antd/select/select.component';
import { BehaviorSubject, combineLatest } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { toInitials } from 'pbc.functions';
import { ISelection } from 'pbc.types';
import { BaseComponent } from '../../../base.components';

@Component({
  selector: 'pbc-select-value',
  templateUrl: './select-value.component.html',
  styleUrls: ['./select-value.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectValueComponent,
    },
  ],
})
export class SelectValueComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  onChange = (value: string | string[] | undefined | null) => {};
  onTouched = () => {};
  touched = false;

  @Input() mode: NzSelectModeType = 'default';
  @Input() override = true;
  @Input() disabled = false;

  @Input() set shapes(shapes: ISelection[] | undefined | null) {
    this.shapes$.next(shapes || []);
  }
  shapes$ = new BehaviorSubject<ISelection[]>([]);
  filtered$ = new BehaviorSubject<ISelection[]>([]);

  @Input() set filter(filter: string | string[]) {
    this.filter$.next(filter || []);
  }
  filter$ = new BehaviorSubject<string | string[] | undefined>(undefined);

  @Input() set rollen(rollen: string | string[]) {
    this.rollen$.next(rollen || []);
  }
  rollen$ = new BehaviorSubject<string | string[] | undefined>(undefined);

  _value: string | string[] | undefined;
  @Input() set value(value: string | string[] | null | undefined) {
    this._value = value ? value : undefined;
    this.onChange(value);
  }
  @Output()
  valueChange: EventEmitter<string | string[]> = new EventEmitter();

  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() width: string = '';
  @Input() size: NzSelectSizeType = 'default';
  $avatar = new BehaviorSubject<boolean>(false);

  creating: string | null = null;
  @Input() creatable = false;
  @Output() created = new EventEmitter<string>();

  override ngOnInit(): void {
    super.ngOnInit();
    const toArray = (value: string | string[]) => (Array.isArray(value) ? value : [value]);
    combineLatest([this.shapes$, this.filter$, this.rollen$])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(([shapes, filter, rollen]) => {
        if (!shapes) return;
        if (filter && !filter.includes('*')) {
          filter = toArray(filter);
          shapes = shapes.filter((value) => !value.filter || toArray(value.filter).some((item) => filter?.includes(item)));
        }
        if (rollen) {
          filter = toArray(filter);
          shapes = shapes.filter((value) => !value.rolle || value.rolle.some((item) => rollen?.includes(item)));
        }
        this.filtered$.next(shapes);
      });
    this.filtered$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((filtered) => {
      if (!this._value && filtered.length === 1) this.setValue(filtered[0].value);
      if (this.override && this._value && Array.isArray(this._value)) this.setValue(this._value.filter((v) => filtered.map((f) => f.value).includes(v)));
      else if (this.override && this._value) this.setValue(filtered.map((f) => f.value).includes(this._value as string) ? this._value : undefined);
      this.$avatar.next(filtered.some((s) => s.avatar));
    });
  }

  public setValue(value: string | string[] | null): void {
    this.value = value;
    this.valueChange.emit(this._value);
    this.onChange(value);
    this.markAsTouched();
  }

  writeValue(value: string | string[] | null) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  search(term: string) {
    if (!this.creatable || !term) {
      this.creating = null;
      return;
    }
    if (!this.filtered$.getValue().find((s) => s.label!.includes(term) || term.includes(s.label!))) {
      this.creating = term.length > 3 ? term : null;
    }
  }

  create() {
    if (this.creatable && this.creating && this.creating.length > 3) {
      this.created.emit(this.creating as string);
    }
  }

  getMitarbeiterText(mitarbeiter?: string | null) {
    return toInitials(mitarbeiter?.split('(')[0]);
  }
}
