import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { MitarbeiterServiceModule } from '..';
import { MitarbeiterListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    MitarbeiterServiceModule
  ],
  declarations: [MitarbeiterListComponent],
  exports: [MitarbeiterListComponent],

})
export class MitarbeiterListComponentModule { }
