import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFormularSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  initialen?: ISorterExpression;
  objektArten?: ISorterExpression;
  bewertungsAnlaesse?: ISorterExpression;
  kundenArten?: ISorterExpression;
  kunden?: ISorterExpression;
  ohneFelder?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const FormularSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('initialen', 'Initialen'),
  SorterConfig('objektArten', 'Objekt Arten'),
  SorterConfig('bewertungsAnlaesse', 'Bewertungs Anlaesse'),
  SorterConfig('kundenArten', 'Kunden Arten'),
  SorterConfig('kunden', 'Kunden'),
  SorterConfig('ohneFelder', 'Ohne Felder'),
  SorterConfig('kommentar', 'Kommentar')
];
