import { Component, Inject } from '@angular/core';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { GutachtenStatiQueryFilterConfig, IGutachtenStatiQueryShapes } from 'fa-kt.types';
import { GutachtenStatiService } from '../service';

@Component({
  selector: 'fa-kt-gutachten-stati-filter',
  templateUrl: './gutachten-stati-filter.component.html',
  styleUrls: ['./gutachten-stati-filter.component.css'],
})
export class GutachtenStatiFilterComponent {
  readonly subscriptions: Subscription[] = [];

  GutachtenStatiFilterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public gutachtenStati: GutachtenStatiService,
  ) {
    this.GutachtenStatiFilterConfig = GutachtenStatiQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push(
      this.gutachtenStati.shapes$.subscribe((shapes: IGutachtenStatiQueryShapes | undefined) => {
        if (!shapes) {
          return;
        }
        Object.entries(shapes)
          .filter(([key]) => key !== 'id' && (shapes as any)[key])
          .forEach(([key]) => this.shape(key, (shapes as any)[key]));
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.GutachtenStatiFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.GutachtenStatiFilterConfig[index].selections = shapes;
  }
}
