import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostErinnerungCommandServiceModule } from '..';
import { PostErinnerungCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostErinnerungCommandServiceModule
  ],
  declarations: [PostErinnerungCommandComponent],
  exports: [PostErinnerungCommandComponent],

})
export class PostErinnerungCommandComponentModule { }
