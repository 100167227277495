import {
  BooleanFilterConfig,
  DateFilterConfig,
  ICommandRequest,
  ICommandResponse,
  IFilter,
  IFilterConfig,
  IModel,
  IPersistedFilterList,
  IQueryRequest,
  IQueryResponse,
  ISelection,
  IShape,
  ISorter,
  ISorterConfig,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
  SorterConfig,
} from 'pbc.types';
import { IMitarbeiter } from '../../personen';

export interface IKrankheit extends IModel {
  id?: string;
  mitarbeiter: string;
  von: Date;
  bis: Date;
  bescheinigung?: File | string;
  kommentar?: string;
  bescheinigt?: boolean;
  outlook?: { personal?: string; admin?: string };
}

export interface IKrankheitFilter extends IFilter<IKrankheit> {}
export type IKrankheitField = 'krankheit.id' | 'krankheit.mitarbeiter' | 'krankheit.von' | 'krankheit.bis' | 'krankheit.bescheinigung' | 'krankheit.kommentar' | 'krankheit.bescheinigt';
export const KrankheitFilterConfig: IFilterConfig<IKrankheit> = [
  SingleReferenceFilterConfig<IMitarbeiter>('krankheit.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  NumberFilterConfig('krankheit.stunden', 'Stunden'),
  DateFilterConfig('krankheit.von', 'Von'),
  DateFilterConfig('krankheit.bis', 'Bis'),
  BooleanFilterConfig('krankheit.bescheinigt', 'Bescheinigt'),
];
export const KrankheitenSorterConfig: ISorterConfig = [
  SorterConfig('krankheit.id', 'Krankheit'),
  SorterConfig('krankheit.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  SorterConfig('krankheit.von', 'Von'),
  SorterConfig('krankheit.bis', 'Bis'),
  SorterConfig('krankheit.bescheinigt', 'Bescheinigt'),
];
export interface IKrankheitenShapes extends IShape {
  'krankheit.mitarbeiter': ISelection[];
}
export interface IKrankheitenRequest extends IQueryRequest {
  jahr: number;
  person?: string;
}

export interface IKrankheitenResponse extends IQueryResponse {
  krankheiten: IKrankheitenResponseRow[];
}

export interface IKrankheitenResponseRow extends IQueryResponse {
  krankheit: IKrankheit;
}

export interface IKrankheitenResponseGroup {
  response: IKrankheitenResponse;
  shapes: IKrankheitenQueryShapes;
  filter: IPersistedFilterList<IKrankheitenQueryFilter>;
}

export type IKrankheitenQueryField = IKrankheitField;
export interface IKrankheitenQuerySorter extends ISorter {}
export interface IKrankheitenQueryFilter extends IFilter<IKrankheitenResponse> {}
export const KrankheitenQuerySorterConfig: ISorterConfig = KrankheitenSorterConfig;
export const KrankheitenQueryFilterConfig: IFilterConfig<IKrankheitenResponse> = KrankheitFilterConfig;
export type IKrankheitenQueryShapes = IKrankheitenShapes;

export interface IKrankheitRequest extends IQueryRequest {
  krankheit: string;
}

export interface IKrankheitResponse extends IQueryResponse {
  krankheit: IKrankheit;
}

export interface IKrankheitResponseGroup {
  response: IKrankheitResponse;
  shapes: IKrankheitQueryShapes;
}

export type IKrankheitQueryField = IKrankheitField;
export interface IKrankheitQuerySorter extends ISorter {}
export interface IKrankheitQueryFilter extends IFilter<IKrankheitenResponse> {}
export type IKrankheitQueryShapes = IKrankheitenShapes;
export const KrankheitQuerySorterConfig: ISorterConfig = KrankheitenSorterConfig;
export const KrankheitQueryFilterConfig: IFilterConfig<IKrankheitenResponse> = KrankheitFilterConfig;

export interface IPostKrankheitRequest extends ICommandRequest {
  krankheit: IKrankheit;
}
export interface IPostKrankheitResponse extends ICommandResponse {
  krankheit: IKrankheit;
}
export interface IPostKrankheitCommandShapes extends IShape {
  'krankheit.mitarbeiter': ISelection[];
}

export interface IDeleteKrankheitRequest extends ICommandRequest {
  id: string;
}
export interface IDeleteKrankheitResponse extends ICommandResponse {}
export interface IDeleteKrankheitCommandShapes extends IShape {}
