import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';

import { BasePostCommandComponent } from 'pbc.angular';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IPostUrlaubRequest, IPostUrlaubResponse } from 'fa-kt.types';
import { ISelection, getArbeitstage } from 'pbc.types';
import { BehaviorSubject, combineLatest, distinctUntilChanged } from 'rxjs';
import { DeleteUrlaubCommandService, PostUrlaubCommandService } from '..';

@Component({
  selector: 'fa-kt-urlaub',
  templateUrl: './urlaub.component.html',
  styleUrls: ['./urlaub.component.css'],
})
export class UrlaubCommandComponent extends BasePostCommandComponent<IPostUrlaubRequest, IPostUrlaubResponse> implements OnInit, OnDestroy {
  description = { context: 'ZEITEN', command: 'POST_URLAUB' };
  contentKey: keyof IPostUrlaubRequest = 'urlaub';

  public arten: Array<ISelection> = [
    { value: 'Urlaub', label: 'Urlaub' },
    { value: 'Unbezahlter Urlaub', label: 'Unbezahlter Urlaub' },
    { value: 'Fortbildung', label: 'Fortbildung' },
    { value: 'Elternzeit', label: 'Elternzeit' },
    { value: 'Studienzeit', label: 'Studienzeit' },
  ];

  $arbeitstage = new BehaviorSubject<number>(0);

  async prepare(): Promise<void> {
    await Promise.all([this.postUrlaub.prepare()]);
  }

  async request(payload: IPostUrlaubRequest): Promise<IPostUrlaubResponse> {
    return this.postUrlaub.request(payload);
  }

  constructor(
    public postUrlaub: PostUrlaubCommandService,
    public deleteUrlaub: DeleteUrlaubCommandService,
  ) {
    super();
    this.form = this.fb.group({
      urlaub: this.fb.group({
        id: [null, []],

        mitarbeiter: [null, [Validators.required]],
        von: [null, [Validators.required]],
        bis: [null, [Validators.required]],

        art: [null, [Validators.required]],

        abgelehnt: [null, []],
        angenommen: [null, []],

        kommentar: [null, []],
        outlook: [null, []],
      }),
    });
    this.patch();
  }

  override patch(value?: Partial<IPostUrlaubRequest>): void {
    super.patch(value);
    if (!value?.urlaub?.von || !value?.urlaub?.bis) return;
    this.$arbeitstage.next(getArbeitstage(moment(value.urlaub.von).toDate(), moment(value.urlaub.bis).toDate()));
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // if (!this.environment.production) return;
    combineLatest([this.auth.$isUrlaubsAdmin, this.$initial])
      .pipe(takeUntilDestroyed(this.destroyedRef), distinctUntilChanged())
      .subscribe(([isUrlaubsAdmin, value]) => {
        if (value?.urlaub?.angenommen) {
          this.form.controls.urlaub?.get('mitarbeiter')?.disable();
          this.form.controls.urlaub?.get('von')?.disable();
          this.form.controls.urlaub?.get('bis')?.disable();
          this.form.controls.urlaub?.get('art')?.disable();
        } else if (value?.urlaub?.abgelehnt) {
          this.form.controls.urlaub?.get('mitarbeiter')?.disable();
          this.form.controls.urlaub?.get('von')?.enable();
          this.form.controls.urlaub?.get('bis')?.enable();
          this.form.controls.urlaub?.get('art')?.enable();
        } else {
          if (isUrlaubsAdmin) this.form.controls.urlaub?.get('mitarbeiter')?.enable();
          else this.form.controls.urlaub?.get('mitarbeiter')?.disable();
          this.form.controls.urlaub?.get('von')?.enable();
          this.form.controls.urlaub?.get('bis')?.enable();
          this.form.controls.urlaub?.get('art')?.enable();
        }
      });
    combineLatest([this.form.valueChanges])
      .pipe(takeUntilDestroyed(this.destroyedRef), distinctUntilChanged())
      .subscribe(([value]) => {
        if (!value?.urlaub?.von || !value?.urlaub?.bis) return;
        this.$arbeitstage.next(getArbeitstage(moment(value.urlaub.von).toDate(), moment(value.urlaub.bis).toDate()));
      });
  }

  async confirm() {
    this.form.controls.urlaub.patchValue({ abgelehnt: false, angenommen: true });
    await this.submit();
  }

  async decline() {
    this.form.controls.urlaub.patchValue({ angenommen: false, abgelehnt: true });
    await this.submit();
  }
}
