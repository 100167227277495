import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { GutachtenStatiServiceModule } from '..';
import { GutachtenStatiFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenStatiServiceModule
  ],
  declarations: [GutachtenStatiFilterComponent],
  exports: [GutachtenStatiFilterComponent],

})
export class GutachtenStatiFilterComponentModule { }
