import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteObjektArtCommandServiceModule } from '..';
import { DeleteObjektArtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteObjektArtCommandServiceModule
  ],
  declarations: [DeleteObjektArtCommandComponent],
  exports: [DeleteObjektArtCommandComponent],

})
export class DeleteObjektArtCommandComponentModule { }
