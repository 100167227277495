import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IErforderlichesDokumentSorter extends ISorter {
  id?: ISorterExpression;
  original?: ISorterExpression;
  gutachten?: ISorterExpression;
  name?: ISorterExpression;
  erforderlich?: ISorterExpression;
  fortschritt?: ISorterExpression;
  kommentar?: ISorterExpression;
}

export const ErforderlichesDokumentSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('original', 'Original'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('name', 'Name'),
  SorterConfig('erforderlich', 'Erforderlich'),
  SorterConfig('fortschritt', 'Fortschritt'),
  SorterConfig('kommentar', 'Kommentar')
];
