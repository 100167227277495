import { IFilter, IFilterConfig } from "pbc.types";

import { IKundenGruppe } from '.';

export interface IKundenGruppeFilter extends IFilter<IKundenGruppe> {}

export const KundenGruppeFilterConfig: IFilterConfig<IKundenGruppe> = [];

export type IKundenGruppeField =
  'kundenGruppe.id'|
  'kundenGruppe.name'|
  'kundenGruppe.beschreibung'
;
