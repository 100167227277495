import { NgModule } from '@angular/core';

import {
  DeleteBewertungsAnlassCommandServiceModule,
  DeleteBewertungsAnlassCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteBewertungsAnlassCommandServiceModule,
    DeleteBewertungsAnlassCommandComponentModule
  ],
  exports: [
    DeleteBewertungsAnlassCommandServiceModule,
    DeleteBewertungsAnlassCommandComponentModule
  ]
})
export class DeleteBewertungsAnlassCommandModule { }
