import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { HerausgebersServiceModule } from '..';
import { HerausgebersListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    HerausgebersServiceModule
  ],
  declarations: [HerausgebersListComponent],
  exports: [HerausgebersListComponent],

})
export class HerausgebersListComponentModule { }
