import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { RahmenAnpassungCommandComponent } from '.';
import { DeleteRahmenAnpassungCommandService, PostRahmenAnpassungCommandService } from '..';

@NgModule({
  imports: [LIB_CommonModule],
  declarations: [RahmenAnpassungCommandComponent],
  providers: [PostRahmenAnpassungCommandService, DeleteRahmenAnpassungCommandService],
  exports: [RahmenAnpassungCommandComponent],
})
export class RahmenAnpassungCommandComponentModule {}
