import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { RollesServiceModule } from '..';
import { RollesSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    RollesServiceModule
  ],
  declarations: [RollesSorterComponent],
  exports: [RollesSorterComponent],

})
export class RollesSorterComponentModule { }
