import { NgModule } from '@angular/core';

import {
  DeleteHonorarVereinbarungCommandServiceModule,
  DeleteHonorarVereinbarungCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteHonorarVereinbarungCommandServiceModule,
    DeleteHonorarVereinbarungCommandComponentModule
  ],
  exports: [
    DeleteHonorarVereinbarungCommandServiceModule,
    DeleteHonorarVereinbarungCommandComponentModule
  ]
})
export class DeleteHonorarVereinbarungCommandModule { }
