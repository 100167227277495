import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IReisekostenabrechnungSorter extends ISorter {
  id?: ISorterExpression;
  mitarbeiter?: ISorterExpression;
  touren?: ISorterExpression;
  reisekostenabrechnungStatus?: ISorterExpression;
  tagesgeldAcht?: ISorterExpression;
  tagesgeldVierundzwanzig?: ISorterExpression;
  anUndAbreisetag?: ISorterExpression;
  fruehstuecke?: ISorterExpression;
  mittagessen?: ISorterExpression;
  abendessen?: ISorterExpression;
  uebernachtungspauschale?: ISorterExpression;
  normaleKm?: ISorterExpression;
  mitnahmeentschaedigung?: ISorterExpression;
  sonstigePositionen?: ISorterExpression;
  sonstigeSumme?: ISorterExpression;
  summe?: ISorterExpression;
  antrag?: ISorterExpression;
}

export const ReisekostenabrechnungSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('mitarbeiter', 'Mitarbeiter'),
  SorterConfig('touren', 'Touren'),
  SorterConfig('reisekostenabrechnungStatus', 'Reisekostenabrechnung Status'),
  SorterConfig('tagesgeldAcht', 'Tagesgeld Acht'),
  SorterConfig('tagesgeldVierundzwanzig', 'Tagesgeld Vierundzwanzig'),
  SorterConfig('anUndAbreisetag', 'An Und Abreisetag'),
  SorterConfig('fruehstuecke', 'Fruehstuecke'),
  SorterConfig('mittagessen', 'Mittagessen'),
  SorterConfig('abendessen', 'Abendessen'),
  SorterConfig('uebernachtungspauschale', 'Uebernachtungspauschale'),
  SorterConfig('normaleKm', 'Normale Km'),
  SorterConfig('mitnahmeentschaedigung', 'Mitnahmeentschaedigung'),
  SorterConfig('sonstigePositionen', 'Sonstige Positionen'),
  SorterConfig('sonstigeSumme', 'Sonstige Summe'),
  SorterConfig('summe', 'Summe'),
  SorterConfig('antrag', 'Antrag')
];
