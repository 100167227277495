<nz-row [style.width]="'100%'">
  <nz-col nzSpan="24">
    <pbc-search-bar [style.width]="'100%'" (searchChange)="search$.next($event)" [search]="search$ | async" placeholder="Suche nach Einstellungen ⌨💨️"></pbc-search-bar>
  </nz-col>
</nz-row>
<nz-row [style.width]="'100%'" style="padding-top: 32px">
  <nz-col nzSpan="24">
    <ng-container *ngFor="let ctx of filtered$ | async">
      <div style="border-bottom: 1px solid rgb(233, 233, 233)">
        <label nz-checkbox [(ngModel)]="ctx.checked" [nzIndeterminate]="ctx.indeterminate" (ngModelChange)="updateContext(ctx, $event)" [nzDisabled]="!!(search$ | async)">
          <h4 nz-title>
            {{ ctx.title }}
          </h4>
        </label>
      </div>
      <br />
      <ng-container *ngFor="let page of ctx.pages">
        <label nz-checkbox [(ngModel)]="page.checked" (ngModelChange)="updatePage(ctx, page, $event)">
          <span> {{ page.label }} </span><br />
          <a nz-typography nzDisabled>
            {{ page.value }}
          </a>
        </label>
        <br />
        <br />
      </ng-container>
      <ng-container *ngFor="let page of ctx.settings">
        <label nz-checkbox [(ngModel)]="page.checked" (ngModelChange)="updatePage(ctx, page, $event)">
          <span> {{ page.label }} </span><br />
          <a nz-typography nzDisabled>
            {{ page.value }}
          </a>
        </label>
        <br />
        <br />
      </ng-container>
      <br />
      <br />
    </ng-container>
  </nz-col>
</nz-row>
