import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IReisekostenabrechnungRequest, IReisekostenabrechnungResponse } from 'fa-kt.types';
import { TourenSelectService } from '../../../besichtigungen';
import { PostReisekostenabrechnungCommandService } from '../../commands';
import { ReisekostenabrechnungService } from '../../querys';

export interface IReisekostenabrechnungPageResolverResult {
  reisekostenabrechnung?: IReisekostenabrechnungResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class ReisekostenabrechnungPageResolver implements Resolve<IReisekostenabrechnungPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private tourenSelect: TourenSelectService,
    private reisekostenabrechnung: ReisekostenabrechnungService,
    private postReisekostenabrechnung: PostReisekostenabrechnungCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IReisekostenabrechnungPageResolverResult> {
    this.loading.resolving();
    const requests = {
      reisekostenabrechnung: {} as IReisekostenabrechnungRequest,
    };
    const log = (error: any) => console.error(error);
    const mitarbeiter = this.auth.$id.getValue();
    const id = route.queryParams['id'];
    requests.reisekostenabrechnung.id = id;
    if (requests.reisekostenabrechnung.id === 'new') {
      this.reisekostenabrechnung.result$.next(undefined);
      const [shapes] = await Promise.all([this.postReisekostenabrechnung.prepare(), this.tourenSelect.request({ mitarbeiter }).catch(log)]);
      this.loading.resolved();
      return {
        reisekostenabrechnung: {
          reisekostenabrechnung: {
            mitarbeiter: this.auth.$id.getValue(),
            reisekostenabrechnungStatus: shapes?.['reisekostenabrechnung.reisekostenabrechnungStatus']?.[0]?.value,
          },
        } as IReisekostenabrechnungResponse,
      };
    }
    const [reisekostenabrechnung] = await Promise.all([
      this.reisekostenabrechnung.request(requests.reisekostenabrechnung).catch(log),
      this.postReisekostenabrechnung.prepare().catch(log),
      this.tourenSelect.request({ mitarbeiter }).catch(log),
    ]);
    this.loading.resolved();
    return {
      reisekostenabrechnung,
    };
  }
}
