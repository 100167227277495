import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from '@angular/router';

import { getEntryFromSitemap } from "../../functions";
import { ActionService } from "../../services";
import { ISitemap, ISitemapContext, ISitemapPage, SITEMAP } from "../../types";

@Component({
  selector: 'pbc-breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnDestroy, OnInit {
  public sites: (ISitemapPage|ISitemapContext)[] = [];

 @Input() set pages(pages: string[]) {
   this.sites = pages.map(page => getEntryFromSitemap(this.sitemap, page)).filter(page => page);
   this.sites.filter(entry => (entry as ISitemapPage).url).filter(page => page && page.url && page.url.length > 0).forEach(page => this.actions.subscribe({ key: `Zu ${ page.title }`, action: async () => await this.router.navigate((page as ISitemapPage).url) }));
 }
  @Input() extra = ''

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public router: Router,
    public actions: ActionService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sites.forEach(page => this.actions.unsubscribe(`Zu ${ page.title }`));
  }

}
