import { Injectable } from '@angular/core';

import { IDeleteFeldUnterkategorieRequest, IDeleteFeldUnterkategorieResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteFeldUnterkategorieCommandService extends DeleteCommandService<IDeleteFeldUnterkategorieRequest, IDeleteFeldUnterkategorieResponse> {
  readonly route = 'felder/settings/feld-unterkategorie';
}
