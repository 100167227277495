import { DateFilterConfig, IDateFilter, IFilter, IFilterConfig, INumberFilter, ISelection, IShape, ISingleReferenceFilter, NumberFilterConfig, SingleReferenceFilterConfig } from 'pbc.types';

import { IKunde, IMitarbeiter, IProjekt, IZeitArt, IZeitUnterart } from 'fa-kt.types';
import { IZeitenResponse } from '.';

export interface IZeitenQueryFilter extends IFilter<IZeitenResponse> {
  'zeit.mitarbeiter'?: ISingleReferenceFilter<IMitarbeiter>;
  'zeit.projekt'?: ISingleReferenceFilter<IProjekt>;
  'zeit.kunde'?: ISingleReferenceFilter<IKunde>;
  'zeit.zeitArt'?: ISingleReferenceFilter<IZeitArt>;
  'zeit.zeitUnterart'?: ISingleReferenceFilter<IZeitUnterart>;
  'zeit.kosten'?: INumberFilter;
  'zeit.datum'?: IDateFilter;
  'zeit.minuten'?: INumberFilter;
  'zeit.stunden'?: INumberFilter;
}

export const ZeitenQueryFilterConfig: IFilterConfig<IZeitenResponse> = [
  SingleReferenceFilterConfig<IMitarbeiter>('zeit.mitarbeiter', 'ZEITEN.Models.ZEIT.Propertys.MITARBEITER.title'),
  SingleReferenceFilterConfig<IProjekt>('zeit.projekt', 'ZEITEN.Models.ZEIT.Propertys.PROJEKT.title'),
  SingleReferenceFilterConfig<IKunde>('zeit.kunde', 'ZEITEN.Models.ZEIT.Propertys.KUNDE.title'),
  SingleReferenceFilterConfig<IZeitArt>('zeit.zeitArt', 'ZEITEN.Models.ZEIT.Propertys.ZEIT_ART.title'),
  SingleReferenceFilterConfig<IZeitUnterart>('zeit.zeitUnterart', 'ZEITEN.Models.ZEIT.Propertys.ZEIT_UNTERART.title'),
  DateFilterConfig('zeit.datum', 'ZEITEN.Models.ZEIT.Propertys.DATUM.title'),
  NumberFilterConfig('zeit.stunden', 'Stunden'),
  NumberFilterConfig('zeit.minuten', 'Minuten'),
];

export interface IZeitenQueryShapes extends IShape {
  'zeit.mitarbeiter': ISelection[];
  'zeit.projekt': ISelection[];
  'zeit.kunde': ISelection[];
  'zeit.zeitArt': ISelection[];
  'zeit.zeitUnterart': ISelection[];
  'zeit.datum': ISelection[];
}

export type IZeitenQueryField =
  | 'zeit.id'
  | 'zeit.mitarbeiter'
  | 'zeit.projekt'
  | 'zeit.gutachten'
  | 'zeit.kunde'
  | 'zeit.zeitArt'
  | 'zeit.zeitUnterart'
  | 'zeit.datum'
  | 'zeit.stunden'
  | 'zeit.minuten'
  | 'zeit.kommentar'
  | 'zeit.kosten'
  | '';
