import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { MitarbeiterQuerySorterConfig } from 'fa-kt.types';
import { MitarbeiterService } from '../service';

@Component({
  selector: 'fa-kt-mitarbeiter-sorter',
  templateUrl: './mitarbeiter-sorter.component.html',
  styleUrls: ['./mitarbeiter-sorter.component.css'],
})
export class MitarbeiterSorterComponent {
  public MitarbeiterSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public mitarbeiter: MitarbeiterService,
  ) {
    this.MitarbeiterSorterConfig = MitarbeiterQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update    */
    this.MitarbeiterSorterConfig = this.MitarbeiterSorterConfig.map((sorter) =>
      sorter.key === 'mitarbeiter.isAdmin' ? { ...sorter, title: 'Administrator (Mitarbeiter)' } : sorter.key === 'rollen.isAdmin' ? { ...sorter, title: 'Administrator (Rolle)' } : sorter,
    );
    /*    update >> */
  }
}
