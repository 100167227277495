import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { groupBy } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IDurchsuchenRequest, IDurchsuchenResponseRow } from 'fa-kt.types';
import { IRecherchePageResolverResult } from '.';
import { DurchsuchenService } from '../../querys';

interface IResult {
  artDesWertes: string;
  recherches: IDurchsuchenResponseRow[];
}

@Component({
  selector: 'fa-kt-recherche-page',
  templateUrl: './recherche.page.html',
  styleUrls: ['./recherche.page.css'],
})
export class RecherchePage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  form!: FormGroup;
  result$ = new BehaviorSubject<IResult[]>([]);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public durchsuchen: DurchsuchenService,
    private fb: FormBuilder,
  ) {
    this.page = this.sitemap.MARKT.Pages.RECHERCHE;
    /* << constructor    */
    this.form = this.fb.group({
      stichtag: [null, [Validators.required]],
      zeileLand: [null, []],
      zeileBundesland: [null, []],
      zeileKreisStadt: [null, []],
      zeileGemeindeBezirk: [null, []],
      zeileOrtsStadtTeil: [null, []],
      zeileGemeindeschluessel: [null, []],
      zeilePlz: [null, []],
      zeileAssetklasse: [null, []],
      zeileObjektart: [null, []],
      zeileZustand: [null, []],
      flaeche: [null, []],
      baujahr: [null, []],
    });
    /*    constructor >> */
  }

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {
          this.form.patchValue(params);
        }),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IRecherchePageResolverResult = data.querys;
          /* << resolver    */
          /*    resolver >> */
        }),
        /* << subscriptions    */
        this.durchsuchen.result$.subscribe((result) => {
          if (!result) {
            return;
          }
          this.result$.next(Object.entries(groupBy(result.durchsuchen, 'artDesWertes')).map(([artDesWertes, recherches]) => ({ artDesWertes, recherches })));
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setDurchsuchen(id: string) {
    /* << set-durchsuchen    */
    /*    set-durchsuchen >> */
  }

  back() {
    window.history.back();
  }

  /* << methods    */
  async submit() {
    this.$loading.next(true);
    const request = this.form.value;
    request.stichtag = new Date(request.stichtag).toISOString();
    await this.durchsuchen.request(request);
    this.$loading.next(false);
  }
  /*    methods >> */
  toNumber(value: string): number {
    return Number(value);
  }

  kriterien(request: IDurchsuchenRequest) {
    let kriterien = `Stichtag: ${new Date(request.stichtag).toLocaleDateString()}`;
    if (request.zeileLand) {
      kriterien += `, Land: ${request.zeileLand}`;
    }
    if (request.zeileBundesland) {
      kriterien += `, Bundesland: ${request.zeileBundesland}`;
    }
    if (request.zeileKreisStadt) {
      kriterien += `, Kreis/Stadt: ${request.zeileKreisStadt}`;
    }
    if (request.zeileGemeindeBezirk) {
      kriterien += `, Gemeinde/Bezirk: ${request.zeileGemeindeBezirk}`;
    }
    if (request.zeileOrtsStadtTeil) {
      kriterien += `, Orts-/Stadtteil: ${request.zeileOrtsStadtTeil}`;
    }
    if (request.zeileGemeindeschluessel) {
      kriterien += `, Gemeindeschlüssel: ${request.zeileGemeindeschluessel}`;
    }
    if (request.zeilePlz) {
      kriterien += `, PLZ: ${request.zeilePlz}`;
    }
    if (request.zeileAssetklasse) {
      kriterien += `, Assetklasse: ${request.zeileAssetklasse}`;
    }
    if (request.zeileObjektart) {
      kriterien += `, Objektart: ${request.zeileObjektart}`;
    }
    if (request.zeileZustand) {
      kriterien += `, Zustand: ${request.zeileZustand}`;
    }
    if (request.baujahr) {
      kriterien += `, Baujahr: ${request.baujahr}`;
    }
    if (request.flaeche) {
      kriterien += `, Fläche: ${request.flaeche} m²`;
    }
    return kriterien;
  }
}
