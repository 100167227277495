import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IGutachtenSorter extends ISorter {
  id?: ISorterExpression;
  projekt?: ISorterExpression;
  objekt?: ISorterExpression;
  bewertungsAnlass?: ISorterExpression;
  bewertungsStatus?: ISorterExpression;
  objektNutzung?: ISorterExpression;
  objektArt?: ISorterExpression;
  sicherheitscheck?: ISorterExpression;
  abgabeDraft?: ISorterExpression;
  abgabeFinal?: ISorterExpression;
  erbbaurecht?: ISorterExpression;
  wohnenJRoE?: ISorterExpression;
  wohnenJRoEFlaeche?: ISorterExpression;
  gewerbeJRoE?: ISorterExpression;
  gewerbeJRoEFlaeche?: ISorterExpression;
  bueroJRoE?: ISorterExpression;
  bueroJRoEFlaeche?: ISorterExpression;
  handelJRoE?: ISorterExpression;
  handelJRoEFlaeche?: ISorterExpression;
  lagerJRoE?: ISorterExpression;
  lagerJRoEFlaeche?: ISorterExpression;
  sonstigesJRoE?: ISorterExpression;
  sonstigesJRoEFlaeche?: ISorterExpression;
  baujahr?: ISorterExpression;
  modernisierung?: ISorterExpression;
  marktwert?: ISorterExpression;
  stichtagMarktwert?: ISorterExpression;
  kaufpreis?: ISorterExpression;
  stichtagKaufpreis?: ISorterExpression;
  fortschritt?: ISorterExpression;
  aktiv?: ISorterExpression;
  kommentar?: ISorterExpression;
  tourenplanung?: ISorterExpression;
}

export const GutachtenSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('objekt', 'Objekt'),
  SorterConfig('bewertungsAnlass', 'Bewertungs Anlass'),
  SorterConfig('bewertungsStatus', 'Bewertungs Status'),
  SorterConfig('objektNutzung', 'Objekt Nutzung'),
  SorterConfig('objektArt', 'Objekt Art'),
  SorterConfig('sicherheitscheck', 'Sicherheitscheck'),
  SorterConfig('abgabeDraft', 'Abgabe Draft'),
  SorterConfig('abgabeFinal', 'Abgabe Final'),
  SorterConfig('erbbaurecht', 'Erbbaurecht'),
  SorterConfig('wohnenJRoE', 'Wohnen J Ro E'),
  SorterConfig('wohnenJRoEFlaeche', 'Wohnen J Ro E Flaeche'),
  SorterConfig('gewerbeJRoE', 'Gewerbe J Ro E'),
  SorterConfig('gewerbeJRoEFlaeche', 'Gewerbe J Ro E Flaeche'),
  SorterConfig('bueroJRoE', 'Buero J Ro E'),
  SorterConfig('bueroJRoEFlaeche', 'Buero J Ro E Flaeche'),
  SorterConfig('handelJRoE', 'Handel J Ro E'),
  SorterConfig('handelJRoEFlaeche', 'Handel J Ro E Flaeche'),
  SorterConfig('lagerJRoE', 'Lager J Ro E'),
  SorterConfig('lagerJRoEFlaeche', 'Lager J Ro E Flaeche'),
  SorterConfig('sonstigesJRoE', 'Sonstiges J Ro E'),
  SorterConfig('sonstigesJRoEFlaeche', 'Sonstiges J Ro E Flaeche'),
  SorterConfig('baujahr', 'Baujahr'),
  SorterConfig('modernisierung', 'Modernisierung'),
  SorterConfig('marktwert', 'Marktwert'),
  SorterConfig('stichtagMarktwert', 'Stichtag Marktwert'),
  SorterConfig('kaufpreis', 'Kaufpreis'),
  SorterConfig('stichtagKaufpreis', 'Stichtag Kaufpreis'),
  SorterConfig('fortschritt', 'Fortschritt'),
  SorterConfig('aktiv', 'Aktiv'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('tourenplanung', 'Tourenplanung')
];
