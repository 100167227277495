import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { IPostStartTourRequest, IPostStartTourResponse } from 'fa-kt.types';
import { PostStartTourCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-start-tour',
  templateUrl: './post-start-tour.component.html',
  styleUrls: ['./post-start-tour.component.css'],
})
export class PostStartTourCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostStartTourRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostStartTourRequest> = new EventEmitter<IPostStartTourRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostStartTourResponse> = new EventEmitter<IPostStartTourResponse>();

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postStartTour: PostStartTourCommandService /* << more    */ /*    more >> */,
  ) {
    this.command = sitemap.BESICHTIGUNGEN.Commands.POST_START_TOUR;
    this.form = this.fb.group({
      tour: this.fb.group({
        id: [null, []],
        tourStatus: [null, [Validators.required]],
        mitarbeiter: [null, [Validators.required]],
        am: [null, [Validators.required]],
        startIstPrivatAdresse: [null, []],
        endeIstPrivatAdresse: [null, []],
        start: [],
        ende: [],
        strecke: [null, []],
        kosten: [null, []],
        abgerechnet: [null, []],
        kommentar: [null, []],
      }),
      besichtigung: this.fb.group({
        id: [null, []],
        projekt: [null, []],
        tour: [null, []],
        gutachten: [null, []],
        objekt: [null, []],
        besichtigungsStatus: [null, [Validators.required]],
        mitarbeiter: [null, []],
        kommentar: [null, []],
        rueckfahrt: [null, []],
        von: [null, []],
        bis: [null, []],
        start: [],
        ende: [],
        aufenthalt: [null, []],
        fahrtzeit: [null, []],
        fahrtstrecke: [null, []],
        fahrtzeitOhneVerkehr: [null, []],
        kosten: [null, []],
        abgerechnet: [null, []],
        route: [null, []],
      }),
    });
    /* << constructor    */
    this.form = this.fb.group({
      tour: this.fb.group({
        id: [null, []],
        tourStatus: [null, [Validators.required]],
        mitarbeiter: [[], [Validators.required]],
        am: [null, [Validators.required]],
        start: [],
        ende: [],
        kosten: [null, []],
        abgerechnet: [null, []],
      }),
    });
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postStartTour.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostStartTourRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.tourId?.valueChanges.subscribe(async (id) => {
        /* << on-tour-id-change    */
        /*    on-tour-id-change >> */
      }) as Subscription,
      this.tourTourStatus?.valueChanges.subscribe(async (tourStatus) => {
        /* << on-tour-tour-status-change    */
        /*    on-tour-tour-status-change >> */
      }) as Subscription,
      this.tourMitarbeiter?.valueChanges.subscribe(async (mitarbeiter) => {
        /* << on-tour-mitarbeiter-change    */
        /*    on-tour-mitarbeiter-change >> */
      }) as Subscription,
      this.tourAm?.valueChanges.subscribe(async (am) => {
        /* << on-tour-am-change    */
        /*    on-tour-am-change >> */
      }) as Subscription,
      this.tourStartIstPrivatAdresse?.valueChanges.subscribe(async (startIstPrivatAdresse) => {
        /* << on-tour-start-ist-privat-adresse-change    */
        /*    on-tour-start-ist-privat-adresse-change >> */
      }) as Subscription,
      this.tourEndeIstPrivatAdresse?.valueChanges.subscribe(async (endeIstPrivatAdresse) => {
        /* << on-tour-ende-ist-privat-adresse-change    */
        /*    on-tour-ende-ist-privat-adresse-change >> */
      }) as Subscription,
      this.tourStart?.valueChanges.subscribe(async (start) => {
        /* << on-tour-start-change    */
        /*    on-tour-start-change >> */
      }) as Subscription,
      this.tourEnde?.valueChanges.subscribe(async (ende) => {
        /* << on-tour-ende-change    */
        /*    on-tour-ende-change >> */
      }) as Subscription,
      this.tourStrecke?.valueChanges.subscribe(async (strecke) => {
        /* << on-tour-strecke-change    */
        /*    on-tour-strecke-change >> */
      }) as Subscription,
      this.tourKosten?.valueChanges.subscribe(async (kosten) => {
        /* << on-tour-kosten-change    */
        /*    on-tour-kosten-change >> */
      }) as Subscription,
      this.tourAbgerechnet?.valueChanges.subscribe(async (abgerechnet) => {
        /* << on-tour-abgerechnet-change    */
        /*    on-tour-abgerechnet-change >> */
      }) as Subscription,
      this.tourKommentar?.valueChanges.subscribe(async (kommentar) => {
        /* << on-tour-kommentar-change    */
        /*    on-tour-kommentar-change >> */
      }) as Subscription,
      this.besichtigungId?.valueChanges.subscribe(async (id) => {
        /* << on-besichtigung-id-change    */
        /*    on-besichtigung-id-change >> */
      }) as Subscription,
      this.besichtigungProjekt?.valueChanges.subscribe(async (projekt) => {
        /* << on-besichtigung-projekt-change    */
        /*    on-besichtigung-projekt-change >> */
      }) as Subscription,
      this.besichtigungTour?.valueChanges.subscribe(async (tour) => {
        /* << on-besichtigung-tour-change    */
        /*    on-besichtigung-tour-change >> */
      }) as Subscription,
      this.besichtigungGutachten?.valueChanges.subscribe(async (gutachten) => {
        /* << on-besichtigung-gutachten-change    */
        /*    on-besichtigung-gutachten-change >> */
      }) as Subscription,
      this.besichtigungObjekt?.valueChanges.subscribe(async (objekt) => {
        /* << on-besichtigung-objekt-change    */
        /*    on-besichtigung-objekt-change >> */
      }) as Subscription,
      this.besichtigungBesichtigungsStatus?.valueChanges.subscribe(async (besichtigungsStatus) => {
        /* << on-besichtigung-besichtigungs-status-change    */
        /*    on-besichtigung-besichtigungs-status-change >> */
      }) as Subscription,
      this.besichtigungMitarbeiter?.valueChanges.subscribe(async (mitarbeiter) => {
        /* << on-besichtigung-mitarbeiter-change    */
        /*    on-besichtigung-mitarbeiter-change >> */
      }) as Subscription,
      this.besichtigungKommentar?.valueChanges.subscribe(async (kommentar) => {
        /* << on-besichtigung-kommentar-change    */
        /*    on-besichtigung-kommentar-change >> */
      }) as Subscription,
      this.besichtigungRueckfahrt?.valueChanges.subscribe(async (rueckfahrt) => {
        /* << on-besichtigung-rueckfahrt-change    */
        /*    on-besichtigung-rueckfahrt-change >> */
      }) as Subscription,
      this.besichtigungVon?.valueChanges.subscribe(async (von) => {
        /* << on-besichtigung-von-change    */
        /*    on-besichtigung-von-change >> */
      }) as Subscription,
      this.besichtigungBis?.valueChanges.subscribe(async (bis) => {
        /* << on-besichtigung-bis-change    */
        /*    on-besichtigung-bis-change >> */
      }) as Subscription,
      this.besichtigungStart?.valueChanges.subscribe(async (start) => {
        /* << on-besichtigung-start-change    */
        /*    on-besichtigung-start-change >> */
      }) as Subscription,
      this.besichtigungEnde?.valueChanges.subscribe(async (ende) => {
        /* << on-besichtigung-ende-change    */
        /*    on-besichtigung-ende-change >> */
      }) as Subscription,
      this.besichtigungAufenthalt?.valueChanges.subscribe(async (aufenthalt) => {
        /* << on-besichtigung-aufenthalt-change    */
        /*    on-besichtigung-aufenthalt-change >> */
      }) as Subscription,
      this.besichtigungFahrtzeit?.valueChanges.subscribe(async (fahrtzeit) => {
        /* << on-besichtigung-fahrtzeit-change    */
        /*    on-besichtigung-fahrtzeit-change >> */
      }) as Subscription,
      this.besichtigungFahrtstrecke?.valueChanges.subscribe(async (fahrtstrecke) => {
        /* << on-besichtigung-fahrtstrecke-change    */
        /*    on-besichtigung-fahrtstrecke-change >> */
      }) as Subscription,
      this.besichtigungFahrtzeitOhneVerkehr?.valueChanges.subscribe(async (fahrtzeitOhneVerkehr) => {
        /* << on-besichtigung-fahrtzeit-ohne-verkehr-change    */
        /*    on-besichtigung-fahrtzeit-ohne-verkehr-change >> */
      }) as Subscription,
      this.besichtigungKosten?.valueChanges.subscribe(async (kosten) => {
        /* << on-besichtigung-kosten-change    */
        /*    on-besichtigung-kosten-change >> */
      }) as Subscription,
      this.besichtigungAbgerechnet?.valueChanges.subscribe(async (abgerechnet) => {
        /* << on-besichtigung-abgerechnet-change    */
        /*    on-besichtigung-abgerechnet-change >> */
      }) as Subscription,
      this.besichtigungRoute?.valueChanges.subscribe(async (route) => {
        /* << on-besichtigung-route-change    */
        /*    on-besichtigung-route-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostStartTourRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.tour) {
        value.tour = cleanObject(value.tour);
      }
      if (value.besichtigung) {
        value.besichtigung = cleanObject(value.besichtigung);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.tour) {
        payload.tour = cleanObject(payload.tour);
      }
      if (payload.besichtigung) {
        payload.besichtigung = cleanObject(payload.besichtigung);
      }
      const response: IPostStartTourResponse = await this.postStartTour.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get tourId() {
    return this.form.controls.tour.get('id');
  }
  get tourTourStatus() {
    return this.form.controls.tour.get('tourStatus');
  }
  get tourMitarbeiter() {
    return this.form.controls.tour.get('mitarbeiter');
  }
  get tourAm() {
    return this.form.controls.tour.get('am');
  }
  get tourStartIstPrivatAdresse() {
    return this.form.controls.tour.get('startIstPrivatAdresse');
  }
  get tourEndeIstPrivatAdresse() {
    return this.form.controls.tour.get('endeIstPrivatAdresse');
  }
  get tourStart() {
    return this.form.controls.tour.get('start');
  }
  get tourEnde() {
    return this.form.controls.tour.get('ende');
  }
  get tourStrecke() {
    return this.form.controls.tour.get('strecke');
  }
  get tourKosten() {
    return this.form.controls.tour.get('kosten');
  }
  get tourAbgerechnet() {
    return this.form.controls.tour.get('abgerechnet');
  }
  get tourKommentar() {
    return this.form.controls.tour.get('kommentar');
  }
  get besichtigungId() {
    return this.form.controls.besichtigung.get('id');
  }
  get besichtigungProjekt() {
    return this.form.controls.besichtigung.get('projekt');
  }
  get besichtigungTour() {
    return this.form.controls.besichtigung.get('tour');
  }
  get besichtigungGutachten() {
    return this.form.controls.besichtigung.get('gutachten');
  }
  get besichtigungObjekt() {
    return this.form.controls.besichtigung.get('objekt');
  }
  get besichtigungBesichtigungsStatus() {
    return this.form.controls.besichtigung.get('besichtigungsStatus');
  }
  get besichtigungMitarbeiter() {
    return this.form.controls.besichtigung.get('mitarbeiter');
  }
  get besichtigungKommentar() {
    return this.form.controls.besichtigung.get('kommentar');
  }
  get besichtigungRueckfahrt() {
    return this.form.controls.besichtigung.get('rueckfahrt');
  }
  get besichtigungVon() {
    return this.form.controls.besichtigung.get('von');
  }
  get besichtigungBis() {
    return this.form.controls.besichtigung.get('bis');
  }
  get besichtigungStart() {
    return this.form.controls.besichtigung.get('start');
  }
  get besichtigungEnde() {
    return this.form.controls.besichtigung.get('ende');
  }
  get besichtigungAufenthalt() {
    return this.form.controls.besichtigung.get('aufenthalt');
  }
  get besichtigungFahrtzeit() {
    return this.form.controls.besichtigung.get('fahrtzeit');
  }
  get besichtigungFahrtstrecke() {
    return this.form.controls.besichtigung.get('fahrtstrecke');
  }
  get besichtigungFahrtzeitOhneVerkehr() {
    return this.form.controls.besichtigung.get('fahrtzeitOhneVerkehr');
  }
  get besichtigungKosten() {
    return this.form.controls.besichtigung.get('kosten');
  }
  get besichtigungAbgerechnet() {
    return this.form.controls.besichtigung.get('abgerechnet');
  }
  get besichtigungRoute() {
    return this.form.controls.besichtigung.get('route');
  }
}
