import { Component } from '@angular/core';
import { ZeitenService } from '../service';

@Component({
  selector: 'fa-kt-saved-zeiten-filters',
  templateUrl: './saved-zeiten-filters.component.html',
  styleUrls: ['./saved-zeiten-filters.component.css'],
})
export class SavedZeitenFiltersComponent {
  constructor(public zeiten: ZeitenService) {}
}
