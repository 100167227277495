import { NgModule } from '@angular/core';

import {
  DeleteKonstanteCommandServiceModule,
  DeleteKonstanteCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteKonstanteCommandServiceModule,
    DeleteKonstanteCommandComponentModule
  ],
  exports: [
    DeleteKonstanteCommandServiceModule,
    DeleteKonstanteCommandComponentModule
  ]
})
export class DeleteKonstanteCommandModule { }
