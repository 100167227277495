import { IFilter, IFilterConfig } from "pbc.types";
import { IBesichtigungsStatus } from './';

export interface IBesichtigungsStatusFilter extends IFilter<IBesichtigungsStatus> {}

export const BesichtigungsStatusFilterConfig: IFilterConfig<IBesichtigungsStatus> = [];

export type IBesichtigungsStatusField =
  'besichtigungsStatus.id'|
  'besichtigungsStatus.name'|
  'besichtigungsStatus.beschreibung'
;
