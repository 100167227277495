import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { orderBy } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import {
  IAbteilung,
  IDeleteRechnungRequest,
  IDeleteRechnungResponse,
  IDeleteRechnungsVorlageRequest,
  IDeleteRechnungsVorlageResponse,
  IGutachtensResponseRow,
  IKunde,
  IPostRechnungenHochzaehlenRequest,
  IPostRechnungenHochzaehlenResponse,
  IPostRechnungRequest,
  IPostRechnungResponse,
  IPostRechnungsVorlageRequest,
  IPostRechnungsVorlageResponse,
  IProjekt,
  IRechnung,
} from 'fa-kt.types';
import { MitarbeiterService, StandortsService } from '../../../personen';
import { DeleteRechnungsVorlageCommandService, PostRechnungCommandService, PostRechnungenHochzaehlenCommandService, PostRechnungsVorlageCommandService } from '../../commands';
import { RechnungsVorlagenService } from '../../querys';

@Component({
  selector: 'fa-kt-rechnungen',
  templateUrl: './rechnungen.component.html',
  styleUrls: ['./rechnungen.component.css'],
})
export class RechnungenComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postRechnung: BehaviorSubject<IPostRechnungRequest | null> = new BehaviorSubject<IPostRechnungRequest | null>(null);
  public readonly $deleteRechnung: BehaviorSubject<IDeleteRechnungRequest | null> = new BehaviorSubject<IDeleteRechnungRequest | null>(null);
  public readonly $postRechnungenHochzaehlen: BehaviorSubject<IPostRechnungenHochzaehlenRequest | null> = new BehaviorSubject<IPostRechnungenHochzaehlenRequest | null>(null);
  public readonly $postRechnungsVorlage: BehaviorSubject<IPostRechnungsVorlageRequest | null> = new BehaviorSubject<IPostRechnungsVorlageRequest | null>(null);
  public readonly $deleteRechnungsVorlage: BehaviorSubject<IDeleteRechnungsVorlageRequest | null> = new BehaviorSubject<IDeleteRechnungsVorlageRequest | null>(null);

  @Input() projekt: IProjekt | undefined = undefined;
  @Input() gutachten: IGutachtensResponseRow[] = [];
  @Input() kunde: IKunde | undefined = undefined;
  @Input() abteilung: IAbteilung | undefined = undefined;
  @Input() rechnungen: any[] = [];

  $valid = new BehaviorSubject<boolean>(false);
  $download = new BehaviorSubject<Date | undefined>(undefined);

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public mitarbeiter: MitarbeiterService,
    public standorte: StandortsService,
    public rechnungsVorlagen: RechnungsVorlagenService,
    public postRechnung: PostRechnungCommandService,
    public postRechnungenHochzaehlen: PostRechnungenHochzaehlenCommandService,
    public postRechnungsVorlage: PostRechnungsVorlageCommandService,
    public deleteRechnungsVorlage: DeleteRechnungsVorlageCommandService,
  ) {}

  async ngOnInit() {
    /* << init    */
    Promise.all([this.postRechnung.prepare()]).catch(console.error);
    /*    init >> */
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setMitarbeiter(id: string) {
    /* << set-mitarbeiter    */
    /*    set-mitarbeiter >> */
  }

  public async setrechnungsVorlagen(id: string) {
    /* << set-rechnungs-vorlagen    */
    /*    set-rechnungs-vorlagen >> */
  }

  async finishedPostRechnung(response?: IPostRechnungResponse) {
    /* << after post-rechnung    */
    this.openRechnung(response.rechnung);
    /*    after post-rechnung >> */
  }

  async finishedDeleteRechnung(response?: IDeleteRechnungResponse) {
    /* << after post-rechnung    */
    this.openRechnung(null);
    /*    after post-rechnung >> */
  }

  download() {
    this.$download.next(new Date());
  }

  async finishedPostRechnungenHochzaehlen(response?: IPostRechnungenHochzaehlenResponse) {
    /* << after post-rechnungen-hochzaehlen    */
    const post = this.$postRechnung.getValue();
    if (!post) {
      return;
    }
    post.rechnung.rechnungZaehler = response.zaehler;
    const nummer = this.projekt?.nummer.split('-');
    if (nummer.length === 3) {
      nummer?.shift();
      nummer?.shift();
    }
    post.rechnung.bezeichnung = nummer?.join('-') + '-' + (response as any).zaehler;
    this.$postRechnung.next(post);
    /*    after post-rechnungen-hochzaehlen >> */
  }

  async finishedPostRechnungsVorlage(response?: IPostRechnungsVorlageResponse) {
    /* << after post-rechnungs-vorlage    */
    /*    after post-rechnungs-vorlage >> */
  }

  async finishedDeleteRechnungsVorlage(response?: IDeleteRechnungsVorlageResponse) {
    /* << after delete-rechnungs-vorlage    */
    /*    after delete-rechnungs-vorlage >> */
  }

  /* << methods    */
  public async openRechnung(rechnung: IRechnung | null) {
    if (!rechnung) {
      this.$postRechnung.next(null);
      return;
    }
    if (!rechnung.projekt && this.projekt) {
      rechnung.datum = new Date();
      rechnung.projekt = this.projekt.id;
      rechnung.kunde = this.projekt.kunde;
      rechnung.nachlass = this.projekt.nachlass || 0;
      rechnung.stunden = this.projekt.stunden;
      if (this.kunde) {
        rechnung.ansprechpartner = this.kunde.name;
        rechnung.plz = this.kunde.addresse?.plz;
        rechnung.ort = this.kunde.addresse?.gemeinde_stadt;
        rechnung.addresse = this.kunde.addresse?.strasse;
      }
      if (this.abteilung) {
        rechnung.abteilung = this.abteilung?.id;
        rechnung.ansprechpartner = this.abteilung.ansprache || this.abteilung.name;
        rechnung.plz = this.abteilung.adresse?.plz;
        rechnung.ort = this.abteilung.adresse?.gemeinde_stadt;
        rechnung.addresse = this.abteilung.adresse?.strasse;
      }
      rechnung.gutachten = this.gutachten.length;
      let bewertung = 0;
      this.gutachten.filter((gutachten) => gutachten.gutachten.marktwert).forEach((gutachten) => (bewertung = bewertung + (gutachten.gutachten.marktwert as number)));
      let vereinbarungen = await this.postRechnung.rechnungHonorarVereinbarung.pipe(first()).toPromise();
      vereinbarungen = vereinbarungen.filter((v) => v.filter === this.kunde?.id);
      const vereinbarung = orderBy(vereinbarungen, 'marktwert').find((v) => v.marktwert >= bewertung);
      rechnung.honorarVereinbarung = vereinbarung?.value;
      rechnung.mwstSatz = 19;
      rechnung.nettoSumme = 0;
      rechnung.bruttoSumme = 0;
      this.$postRechnung.next({ rechnung });
      await this.submitPostRechnungenHochzaehlen({});
    }
    this.$postRechnung.next({ rechnung });
    if (rechnung.id) {
      this.$deleteRechnung.next({ rechnung: rechnung.id });
    }
  }
  /*    methods >> */

  public async submitPostRechnung(request?: IPostRechnungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postRechnung.getValue();
    if (payload) {
      try {
        const response = await this.postRechnung.request(payload);
        await this.finishedPostRechnung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostRechnungenHochzaehlen(request?: IPostRechnungenHochzaehlenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postRechnungenHochzaehlen.getValue();
    if (payload) {
      try {
        const response = await this.postRechnungenHochzaehlen.request(payload);
        await this.finishedPostRechnungenHochzaehlen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostRechnungsVorlage(request?: IPostRechnungsVorlageRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postRechnungsVorlage.getValue();
    if (payload) {
      try {
        const response = await this.postRechnungsVorlage.request(payload);
        await this.finishedPostRechnungsVorlage(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitDeleteRechnungsVorlage(request?: IDeleteRechnungsVorlageRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteRechnungsVorlage.getValue();
    if (payload) {
      try {
        const response = await this.deleteRechnungsVorlage.request(payload);
        await this.finishedDeleteRechnungsVorlage(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
