import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { AuswertungenCommandsModule } from './commands';
import { AuswertungenComponentsModule } from './components';
import { AuswertungenPage, AuswertungenPageResolver } from './pages';
import { AuswertungenQuerysModule } from './querys';

const routes: Routes = [
  {
    path: 'auswertungen',
    children: [
      {
        path: 'auswertungen',
        component: AuswertungenPage,
        resolve: {
          querys: AuswertungenPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['auswertungen'],
            roles: [],
          },
        },
      },
    ],
  },
];

@NgModule({
  declarations: [AuswertungenPage],
  imports: [LibsCommonAngularModule, AuswertungenComponentsModule, AuswertungenCommandsModule, AuswertungenQuerysModule, RouterModule.forChild(routes)],
  exports: [AuswertungenComponentsModule, AuswertungenCommandsModule, AuswertungenQuerysModule],
})
export class AuswertungenModule {
  static forRoot(): ModuleWithProviders<AuswertungenModule> {
    return {
      ngModule: AuswertungenModule,
      providers: [],
    };
  }
}
