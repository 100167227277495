import { IFilter, IFilterConfig } from "pbc.types";

import { IFeldOption } from '.';

export interface IFeldOptionFilter extends IFilter<IFeldOption> {}

export const FeldOptionFilterConfig: IFilterConfig<IFeldOption> = [];

export type IFeldOptionField =
  'feldOption.id'|
  'feldOption.feld'|
  'feldOption.option'|
  'feldOption.akkusativ'|
  'feldOption.nominativ'|
  'feldOption.dativ'|
  'feldOption.genitiv'|
  'feldOption.standard'
;
