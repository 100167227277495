import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostDurchgangCommandServiceModule } from '..';
import { PostDurchgangCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostDurchgangCommandServiceModule
  ],
  declarations: [PostDurchgangCommandComponent],
  exports: [PostDurchgangCommandComponent],

})
export class PostDurchgangCommandComponentModule { }
