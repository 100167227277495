import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IDateiArtsResponse } from 'fa-kt.types';
import { PostDateiArtCommandService } from '../commands';
import { DateiArtsService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class DateiArtsPageResolver implements Resolve<IDateiArtsResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private dateiArts: DateiArtsService,
    private postDateiArt: PostDateiArtCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IDateiArtsResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [dateiArts] = await Promise.all([this.dateiArts.request({}).catch(log), this.postDateiArt.prepare(true).catch(log)]);
    this.loading.resolved();
    return dateiArts;
  }
}
