import { Component, EventEmitter, Input, isDevMode, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IPersistedFilter, ISelection } from 'pbc.types';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

import { AuthService } from '../../../auth';
import { MetaService } from '../../../common';

@Component({
  selector: 'pbc-filter-preset',
  templateUrl: './filter-preset.component.html',
  styleUrls: ['./filter-preset.component.css'],
})
export class FilterPresetComponent implements OnInit {
  subscriptions: Subscription[] = [];

  @Input() set filters(filters: IPersistedFilter<any>[]) {
    let result: IPersistedFilter<any>[] = [];
    if (filters) {
      result = filters.filter(
        (f: IPersistedFilter<any>) =>
          isDevMode() || (f.groups.length === 0 && f.users.length === 0) || f.groups.some((g) => this.auth.$groups.getValue().includes(g)) || f.users.includes(this.auth.$id.getValue() as string),
      );
    }
    this.filter$s.next(result);
  }
  private filter$s: BehaviorSubject<IPersistedFilter<any>[]> = new BehaviorSubject<IPersistedFilter<any>[]>([]);
  presets$: BehaviorSubject<IPersistedFilter<any>[]> = new BehaviorSubject<IPersistedFilter<any>[]>([]);

  filtersVisible = false;

  @Input() set filter(filter: IPersistedFilter<any> | undefined) {
    this.filter$.next(filter);
  }
  filter$ = new BehaviorSubject<IPersistedFilter<any> | undefined>(undefined);
  @Input() name = '';

  $edit = new BehaviorSubject<boolean>(false);
  form!: FormGroup;

  @Output() select: EventEmitter<IPersistedFilter<any>> = new EventEmitter<IPersistedFilter<any>>();
  @Output() save: EventEmitter<IPersistedFilter<any>> = new EventEmitter<IPersistedFilter<any>>();
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();

  $users = new BehaviorSubject<ISelection[]>([]);
  $groups = new BehaviorSubject<ISelection[]>([]);

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private meta: MetaService,
  ) {
    this.$users = this.meta.select$;
    this.$groups = this.meta.groups$;
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      groups: [null, []],
      users: [null, []],
    });
  }

  async ngOnInit() {
    this.subscriptions.push(
      combineLatest([this.filter$s, this.filter$]).subscribe(([filters, filter]) => {
        filters = filters ? filters : [];
        this.presets$.next(filter ? filters.filter((f) => f.id !== filter.id) : filters);
      }),
      this.filter$.pipe(skip(1)).subscribe((filter) => {
        this.select.emit(filter);
      }),
    );
  }

  ngOnDestroy() {
    this.$edit.next(false);
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private get state() {
    const filter: IPersistedFilter<any> = { ...this.filter$.getValue(), ...this.form.value };
    const me = this.auth.$id.getValue() as string;
    filter.users = (filter && filter.users ? filter.users : []).filter((u) => u !== me).concat([me]);
    return filter;
  }

  set(id?: string) {
    const filter = this.presets$.getValue().find((f) => f.id === id);
    this.filter$.next(filter);
  }

  open() {
    let filter = this.filter$.getValue();
    const me = this.auth.$id.getValue() as string;
    const groups = this.auth.$groups.getValue();
    filter = filter ? filter : { name: this.name, groups, users: [me] };
    filter.users = (filter && filter.users ? filter.users : []).filter((u) => u !== me).concat([me]);
    this.form.patchValue({ ...this.form.value, ...filter });
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$edit.next(true);
  }

  persist() {
    this.save.emit(this.state);
    this.$edit.next(false);
  }

  remove() {
    this.delete.emit(this.state.id);
    this.close();
  }

  close() {
    this.form.reset();
    this.$edit.next(false);
    this.filter = undefined;
  }
}
