<nz-spin [nzSpinning]="deviceConfigService.$saving | async">
  <nz-row>
    <nz-col [nzSpan]="24">
      <h3 nz-title>Einstellungen</h3>
    </nz-col>
    <nz-col [nzSpan]="24" style="padding-top: 16px">
      <h4 nz-title>Performance</h4>
      <div class="icon-wrapper test-class" style="margin: 16px">
        <i nz-icon nzType="laptop" [class.icon-highlight]="nextHighLight"></i>
        <nz-slider
          [nzMarks]="marks"
          [ngModel]="(deviceConfigService.$deviceConfig | async)?.devicePerformance"
          (ngModelChange)="setDeviceConfigPerformance($event)"
          [nzMin]="min"
          [nzMax]="max"
          nzReverse="true"
          [nzTipFormatter]="nzDevicePerformanceTipFormatter"
          nzTooltipVisible="default"
        >
        </nz-slider>
        <i nz-icon nzType="mobile" [class.icon-highlight]="preHighLight"></i>
      </div>
    </nz-col>
    <nz-col [nzSpan]="24" style="padding-top: 32px">
      <h4 nz-title>Aktualisierungen</h4>
      <nz-radio-group [ngModel]="(deviceConfigService.$deviceConfig | async)?.alwaysRefresh" (ngModelChange)="setDeviceConfigAlwaysRefresh($event)">
        <label nz-radio-button [nzValue]="false" nz-tooltip nzTooltipTitle="Abfragen werden über Seitenwechsel gespeichert">Effizient</label>
        <label nz-radio-button [nzValue]="true" nz-tooltip nzTooltipTitle="Abfragen werden bei jeder Anzeige neu geladen">Aktuell</label>
      </nz-radio-group>
    </nz-col>
  </nz-row>
</nz-spin>
