import { IFilter, IFilterConfig } from "pbc.types";

import { IDurchgang } from '.';

export interface IDurchgangFilter extends IFilter<IDurchgang> {}

export const DurchgangFilterConfig: IFilterConfig<IDurchgang> = [];

export type IDurchgangField =
  'durchgang.id'|
  'durchgang.formular'|
  'durchgang.gutachten'|
  'durchgang.projekt'|
  'durchgang.besichtigung'
;
