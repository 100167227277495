import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IDeleteReisekostenabrechnungRequest, IDeleteReisekostenabrechnungResponse, IPostReisekostenabrechnungRequest, IPostReisekostenabrechnungResponse } from 'fa-kt.types';
import { IReisekostenabrechnungPageResolverResult } from '.';
import { TourenService, TourService } from '../../../besichtigungen';
import { ProjektService } from '../../../projekte';
import { DeleteReisekostenabrechnungCommandService, PostReisekostenabrechnungCommandService } from '../../commands';
import { ReisekostenabrechnungService } from '../../querys';

@Component({
  selector: 'fa-kt-reisekostenabrechnung-page',
  templateUrl: './reisekostenabrechnung.page.html',
  styleUrls: ['./reisekostenabrechnung.page.css'],
})
export class ReisekostenabrechnungPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $deleteReisekostenabrechnung: BehaviorSubject<IDeleteReisekostenabrechnungRequest | null> = new BehaviorSubject<IDeleteReisekostenabrechnungRequest | null>(null);
  public readonly $postReisekostenabrechnung: BehaviorSubject<IPostReisekostenabrechnungRequest | null> = new BehaviorSubject<IPostReisekostenabrechnungRequest | null>(null);

  public _finanzen: ISitemapPage;
  public _reisekostenabrechnung: ISitemapPage;

  $valid = new BehaviorSubject<boolean>(false);
  $download = new BehaviorSubject<Date | undefined>(undefined);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public tour: TourService,
    public reisekostenabrechnung: ReisekostenabrechnungService,
    public projekt: ProjektService,
    public deleteReisekostenabrechnung: DeleteReisekostenabrechnungCommandService,
    public postReisekostenabrechnung: PostReisekostenabrechnungCommandService,
    private touren: TourenService,
  ) {
    this.page = this.sitemap.FINANZEN.Pages.REISEKOSTENABRECHNUNG;
    this._finanzen = sitemap.FINANZEN.Pages.FINANZEN;
    this._reisekostenabrechnung = sitemap.FINANZEN.Pages.REISEKOSTENABRECHNUNG;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {
          const id = params.id;
          if (id && id !== 'new') this.reisekostenabrechnung.request({ id });
        }),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IReisekostenabrechnungPageResolverResult = data.querys;
          const { reisekostenabrechnung } = querys;
          if (reisekostenabrechnung && reisekostenabrechnung.reisekostenabrechnung) {
            this.$postReisekostenabrechnung.next({ reisekostenabrechnung: reisekostenabrechnung.reisekostenabrechnung });
            this.$deleteReisekostenabrechnung.next({ reisekostenabrechnung: reisekostenabrechnung.reisekostenabrechnung.id });
          } else this.navigateToFinanzen();
        }),
        this.reisekostenabrechnung.result$.subscribe((result) => {
          if (!result) return;
          this.$postReisekostenabrechnung.next({ reisekostenabrechnung: result.reisekostenabrechnung });
          this.$deleteReisekostenabrechnung.next({ reisekostenabrechnung: result.reisekostenabrechnung.id });
        }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setTour(id: string) {
    /* << set-tour    */
    /*    set-tour >> */
  }

  public async setReisekostenabrechnung(id: string) {
    /* << set-reisekostenabrechnung    */
    /*    set-reisekostenabrechnung >> */
  }

  public async setProjekt(id: string) {
    /* << set-projekt    */
    /*    set-projekt >> */
  }

  async finishedDeleteReisekostenabrechnung(response?: IDeleteReisekostenabrechnungResponse) {
    this.reisekostenabrechnung.payload$.next(undefined);
    await this.navigateToFinanzen();
  }

  async finishedPostReisekostenabrechnung(response?: IPostReisekostenabrechnungResponse) {
    await this.navigateToReisekostenabrechnung({ id: response.reisekostenabrechnung.id });
  }

  public async navigateToFinanzen(queryParams: Params = {}) {
    await this.router.navigate(this._finanzen.url, { queryParams });
  }

  public async navigateToReisekostenabrechnung(queryParams: Params = {}) {
    await this.router.navigate(this._reisekostenabrechnung.url, { queryParams });
  }

  back() {
    this.navigateToFinanzen();
  }

  download() {
    this.$download.next(new Date());
  }

  public async submitDeleteReisekostenabrechnung(request?: IDeleteReisekostenabrechnungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteReisekostenabrechnung.getValue();
    if (payload) {
      try {
        const response = await this.deleteReisekostenabrechnung.request(payload);
        await this.finishedDeleteReisekostenabrechnung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostReisekostenabrechnung(request?: IPostReisekostenabrechnungRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postReisekostenabrechnung.getValue();
    if (payload) {
      try {
        const response = await this.postReisekostenabrechnung.request(payload);
        await this.finishedPostReisekostenabrechnung(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
