import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IKundeSorter extends ISorter {
  id?: ISorterExpression;
  name?: ISorterExpression;
  initialen?: ISorterExpression;
  kundenGruppe?: ISorterExpression;
  kundenArt?: ISorterExpression;
  addresse?: ISorterExpression;
  website?: ISorterExpression;
  outlookKontakte?: ISorterExpression;
  kontaktNotizen?: ISorterExpression;
  anforderungenNotizen?: ISorterExpression;
  rechnungNotizen?: ISorterExpression;
  fahrtkostenAbrechnung?: ISorterExpression;
  steuerId?: ISorterExpression;
  fahrtkostenAbRadius?: ISorterExpression;
  fahrtkostenProKm?: ISorterExpression;
  fahrtkostenProStunde?: ISorterExpression;
  skonto?: ISorterExpression;
  zahlungsziel?: ISorterExpression;
  tageOhneSkonto?: ISorterExpression;
  aktiv?: ISorterExpression;
  dateipfad?: ISorterExpression;
  loraName?: ISorterExpression;
  unterlagenEmail?: ISorterExpression;
}

export const KundeSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('name', 'Name'),
  SorterConfig('initialen', 'Initialen'),
  SorterConfig('kundenGruppe', 'Kunden Gruppe'),
  SorterConfig('kundenArt', 'Kunden Art'),
  SorterConfig('addresse', 'Addresse'),
  SorterConfig('website', 'Website'),
  SorterConfig('outlookKontakte', 'Outlook Kontakte'),
  SorterConfig('kontaktNotizen', 'Kontakt Notizen'),
  SorterConfig('anforderungenNotizen', 'Anforderungen Notizen'),
  SorterConfig('rechnungNotizen', 'Rechnung Notizen'),
  SorterConfig('fahrtkostenAbrechnung', 'Fahrtkosten Abrechnung'),
  SorterConfig('steuerId', 'Steuer Id'),
  SorterConfig('fahrtkostenAbRadius', 'Fahrtkosten ab Radius'),
  SorterConfig('fahrtkostenProKm', 'Fahrtkosten pro KM'),
  SorterConfig('fahrtkostenProStunde', 'Fahrtkosten Pro Stunde'),
  SorterConfig('skonto', 'Skonto'),
  SorterConfig('zahlungsziel', 'Zahlungsziel'),
  SorterConfig('tageOhneSkonto', 'Tage Ohne Skonto'),
  SorterConfig('aktiv', 'Aktiv'),
  SorterConfig('dateipfad', 'Dateipfad'),
  SorterConfig('loraName', 'Lora Name'),
  SorterConfig('unterlagenEmail', 'Unterlagen Email'),
];
