import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostBesichtigungsStatusCommandShapes, IPostBesichtigungsStatusRequest, IPostBesichtigungsStatusResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostBesichtigungsStatusCommandService extends PostCommandService<IPostBesichtigungsStatusRequest, IPostBesichtigungsStatusResponse, IPostBesichtigungsStatusCommandShapes> {
  readonly route = 'besichtigungen/settings/besichtigungs-status';
  override dependencies = [];
}
