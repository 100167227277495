import { compress, Compressed, decompress, trimUndefinedRecursively } from 'compress-json';
import { addDays } from 'date-fns';
import { isDate, round } from 'lodash';
import moment from 'moment-timezone';
import typia from 'typia';

import { IAddress } from 'pbc.types';

export function handleValidation<T>({ success, errors }: typia.IValidation<T>): void {
  if (!success) throw { action: 'validate', status: 400, message: 'Befehl ist nicht gültig.', errors: errors?.map((e) => ({ ...e, path: e.path.replace('$input.', '') })) || [] };
}

export function castStringObject(o?: { [key: string]: string }): { [key: string]: number | boolean | string | Date } {
  if (!o) return {};
  const result = {} as { [key: string]: number | boolean | string | Date };
  for (const [key, value] of Object.entries(o)) {
    if (value) {
      if (isDateString(value)) result[key] = moment(value, dateFormat, true).toDate();
      else
        result[key] = /^(true|false|null|undefined)$/.test(value)
          ? (JSON.parse(value as string) as boolean)
          : ['jahr', 'distance', 'baujahr', 'flaeche', 'anzahl'].includes(key) // 'latidude', 'longitude',
          ? Number(value)
          : value || value;
    }
  }
  return result;
}
export const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export function castObject(o?: unknown | { [key: string]: unknown }):
  | undefined
  | {
      [key: string]: undefined | object | Date | number | boolean | string;
    }
  | Date
  | number
  | boolean
  | string
  | unknown[] {
  if (o === undefined || o === null) return undefined;
  if (Array.isArray(o)) return o.map((v) => castObject(v as unknown));
  if (typeof o !== 'object') {
    if (isDateString(o as string)) return new Date(o as string);
    else return o as number | boolean | string;
  }
  const result = {} as {
    [key: string]: undefined | object | Date | number | boolean | string;
  };
  for (const [key, value] of Object.entries(o as {})) {
    if (Array.isArray(value)) result[key] = value.map((v) => castObject(v as unknown));
    if (typeof value === 'object') result[key] = castObject(value as {});
    else if (isDateString(value as string)) result[key] = new Date(value as string);
    else result[key] = value as number | boolean | string;
    if (result[key] === undefined || result[key] === null) delete result[key];
  }
  return result;
}

export function isDateString(value: string) {
  return moment(value, dateFormat, true).isValid();
}

export function isValue(val: unknown): boolean {
  return !!val && val.toString().toLowerCase() !== 'null' && val.toString() !== '1900-01-01 00:00:00';
}

export function fromJahrOrDateToDate(value?: number | Date): Date | undefined {
  if (!value) {
    return undefined;
  }
  if (value.toString().includes('.')) {
    return new Date(value);
  } else {
    return new Date('01.01.' + value);
  }
}

export function isMatch(a?: string, b?: string): boolean {
  return (!!a && a === b) || (!!a && !!b && a?.toLowerCase().includes(b?.toLowerCase())) || (!!a && !!b && b?.toLowerCase().includes(a?.toLowerCase()));
}

export function cleanRequest<T>(req: T): T {
  if (req) {
    Object.entries(req)
      .filter(([key, value]) => key.startsWith('_') || (!value && typeof value !== 'boolean' && typeof value !== 'number'))
      .forEach(([key]) => delete req[key as keyof typeof req]);
  }
  return req;
}

// export function makeRequestID(request: object): string {
//   return JSON.stringify(orderProperties(request));
// }

// function orderProperties(obj: object) {
//   const result: object = {};
//   Object.keys(obj)
//     .sort()
//     .forEach((key) => (result[key] = obj[key]));
//   return result;
// }

export function prepareSearch(object: object, excludedFields: string[] = []): string {
  if (!object || excludedFields?.includes('*')) return '';
  return Object.entries(object)
    .filter(([key, value]) => value && key[0] !== '_' && !isBase64String(value.toString()) && !excludedFields.some((f) => key.includes(f)))
    .map(([, value]) => appendValues(value, excludedFields))
    .join('');
}

export function appendValues(object: any, excludedFields: string[], stack = ''): string {
  if (!object || stack[0] === '_' || excludedFields?.some((f) => stack.includes(f))) return '';
  try {
    if (Array.isArray(object)) return (object as []).map((entry) => appendValues(entry, excludedFields, stack)).join('');
    if (typeof object === 'object')
      return Object.entries(object)
        .filter(([key, value]) => value && key[0] !== '_' && !excludedFields?.some((f) => ((stack ? stack + '.' : '') + key).includes(f)))
        .map(([key, value]) => appendValues(value, excludedFields, (stack ? stack + '.' : '') + key))
        .join('');
    return object.toString().toLowerCase().split(' ').join('').replaceAll('\\', '/');
  } catch (e) {
    return '';
  }
}

export function extractNameFromEmail(email: string): string {
  const name = email.match(/^([^@]*)@/);
  return name ? name[1] : email;
}

export function toConstant(text: string): string {
  if (!text) return '';
  return text
    .split(' ')
    .map((s) => s.toUpperCase())
    .join('_');
}

export function toRegularExpression(value?: string): undefined | RegExp {
  if (!value) return undefined;
  const main = value.match(/\/(.+)\/.*/)?.[1];
  const options = value.match(/\/.+\/(.*)/)?.[1];
  if (!main) return undefined;
  return new RegExp(main, options);
}

export function toInitials(term?: string, extended = false): string {
  if (!term) return '';
  if (!extended)
    return (
      term
        .split('(')
        .shift()
        ?.replace(/[^a-zA-Z ]+/g, '')
        .split(' ')
        .filter((s) => s)
        .map((p) => p[0].toUpperCase())
        .join('') || ''
    );
  const begin = term.substring(0, 3).toUpperCase();
  const rest = term
    .substring(3)
    .split('(')
    .shift()
    ?.replace(/[^a-zA-Z ]+/g, '')
    .split(' ')
    .filter((s) => s);
  rest?.shift();
  const ending = rest?.map((p) => p[0].toUpperCase()).join('') || '';
  return begin + ending;
}

export function isBase64String(base64?: string): boolean {
  if (!base64 || base64.trim() === '') {
    return false;
  }
  return base64.startsWith('data:') || base64.includes(';base64,');
}

export function isNotNullOrUndefined(object?: unknown | null) {
  return object !== null && object !== undefined;
}

export function isNullOrUndefined(object?: unknown | null) {
  return object === null || object === undefined;
}

// export function toLookup(array: unknown[] = [], key = 'id') {
//   // eslint-disable-next-line typescript-eslint/no-explicit-any
//   const lookup: { [key:string|number]: unknown } = {};
//   for (let i = 0, len = array.length; i < len; i++) {
//     const value = array[i];
//     const value =
//     const key: string|number = ( as { [key]: string|number})?.[key] as string|number;
//     lookup[key] = array[i];
//   }
//   return lookup;
// }

export function getLetters(str: string): string {
  return str?.replace(/[^a-z]/gi, '') || '';
}

export function getWeekDates(startDate: Date, stopDate: Date): Date[] {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      dateArray.push(currentDate);
    }
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export function toDate(dt: Date | string): Date {
  if (typeof dt === 'string') {
    dt = new Date(dt);
  }
  if (dt && dt.setUTCHours) {
    dt.setUTCHours(0, 0, 0, 0);
  }
  return dt;
}

export function numberToColumn(n: number): string {
  const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[n % 26];
  return n >= 26 ? numberToColumn(Math.floor(n / 26) - 1) + res : res;
}

export function roundTimeQuarterHour(date?: Date): Date {
  date = date ? new Date(date) : new Date();
  const rounded = Math.ceil(moment(date).utc().minute() / 15) * 15;
  return moment(date).utc().minute(rounded).second(0).utc().toDate();
}

export function toFormattedString(value?: number, unit = ''): string | undefined {
  if (!value) return '';
  return (
    new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value) + (unit ? ' ' + unit : '')
  );
}

export function formatHourDuration(h: number) {
  const minutes = moment.duration(h, 'hours').asMinutes() % 60;
  const hours = Math.floor(h);
  return `${hours ? hours + 'Std.' : ''}${minutes ? round(minutes) + 'Min.' : ''}`;
}

export function formatAddress(address?: IAddress): string {
  if (!address) return '';
  return (address?.plz || '') + ' ' + (address?.gemeinde_stadt || '') + ', ' + (address?.strasse || '') + ' ' + (address?.extra && address?.extra?.toLowerCase() !== 'null' ? address?.extra : '');
}

export function getCapitalizedText(text?: string): string {
  if (!text) return '';
  return text[0].toUpperCase() + text.substring(1);
}

export function calculateDistance(point1: { latitude: number; longitude: number }, point2: { latitude: number; longitude: number }): number {
  const R = 6371; // km
  var dLat = toRad(point2.latitude - point1.latitude);
  var dLon = toRad(point2.longitude - point1.longitude);
  var lat1 = toRad(point1.latitude);
  var lat2 = toRad(point2.latitude);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return round(d, 2);
}

function toRad(value: number) {
  return (value * Math.PI) / 180;
}

export function propertiesToArray(obj: any): string[] {
  const isDateOrDateString = (val: any): boolean => !!val && (isDate(val) || isDateString(val));
  const isObject = (val: any): boolean => val && typeof val === 'object' && !Array.isArray(val);

  const addDelimiter = (a: any, b: any) => (a ? `${a}.${b}` : b);

  const paths: any = (obj = {}, head = '') => {
    return Object.entries(obj)
      .filter((e) => e[0] && e[0] !== '_')
      .reduce((product, [key, value]) => {
        let fullPath = addDelimiter(head, key);
        return isDateOrDateString(value)
          ? product.concat(fullPath)
          : isObject(value)
          ? product.concat(paths(value, fullPath))
          : Array.isArray(value)
          ? product.concat(addDelimiter(key, propertiesToArray(value)))
          : product.concat(fullPath);
      }, []);
  };

  return paths(obj);
}

export function join(arr: string[]): string {
  arr = arr?.filter((a) => a?.length) || [];
  if (!arr?.length) return '';
  if (arr.length === 1) return arr[0];
  const last = arr.pop();
  return arr.join(', ') + ' & ' + last;
}

export function strToNumber(value: number | string): number {
  if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) return Number(value);
  if (typeof value !== 'number') throw new Error(`${value} is not a number`);
  return value;
}

export function cleanVirtuals(obj: any): any {
  if (!obj) return obj;
  if (typeof obj !== 'object') return obj;
  if (obj[':virtual']) return undefined;
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (Array.isArray(obj[property])) obj[property] = obj[property].map((o: any) => cleanVirtuals(o));
      else if (typeof obj[property] == 'object') obj[property] = cleanVirtuals(obj[property]);
    }
  }
  return obj;
}

export function compressJSON<T extends object>(value: T): Compressed {
  trimUndefinedRecursively(value);
  return compress(value);
}

export function decompressJSON<T extends object>(value: Compressed): T {
  return decompress(value) as T;
}

export type CompressedObject = Compressed;
