import { NgModule } from '@angular/core';

import {
  PostGutachtenCommandServiceModule,
  PostGutachtenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostGutachtenCommandServiceModule,
    PostGutachtenCommandComponentModule
  ],
  exports: [
    PostGutachtenCommandServiceModule,
    PostGutachtenCommandComponentModule
  ]
})
export class PostGutachtenCommandModule { }
