import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteKundeRequest, IDeleteKundeResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteKundeCommandService extends DeleteCommandService<IDeleteKundeRequest, IDeleteKundeResponse> {
  readonly route = 'kunden/kunde';
}
