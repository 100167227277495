import { NgModule } from '@angular/core';
import {
  ProjektStatiFilterComponentModule,
  ProjektStatiSorterComponentModule,
  ProjektStatiListComponentModule
} from '.';

@NgModule({
  imports: [
    ProjektStatiFilterComponentModule,
    ProjektStatiSorterComponentModule,
    ProjektStatiListComponentModule
  ],
  exports: [
    ProjektStatiFilterComponentModule,
    ProjektStatiSorterComponentModule,
    ProjektStatiListComponentModule
  ],
})
export class ProjektStatiQueryModule { }