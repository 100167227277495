import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BerichteServiceModule } from '..';
import { BerichteSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichteServiceModule
  ],
  declarations: [BerichteSorterComponent],
  exports: [BerichteSorterComponent],

})
export class BerichteSorterComponentModule { }
