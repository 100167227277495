import { Injectable } from '@angular/core';

import { DetailQueryService } from 'pbc.angular';

import { IIchQueryShapes, IIchRequest, IIchResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class IchService extends DetailQueryService<IIchRequest, IIchResponse, IIchQueryShapes> {
  route = 'personen/ich';
  override dependencies = [
    'personen/written-rolle',
    'personen/removed-rolle',
    'personen/written-standort',
    'personen/removed-standort',
    'personen/mitarbeiter-aktualisiert',
    'personen/azure-active-directory-synchronisiert',
  ];
}
