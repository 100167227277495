import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostFeldUnterkategorieCommandServiceModule } from '..';
import { PostFeldUnterkategorieCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFeldUnterkategorieCommandServiceModule
  ],
  declarations: [PostFeldUnterkategorieCommandComponent],
  exports: [PostFeldUnterkategorieCommandComponent],

})
export class PostFeldUnterkategorieCommandComponentModule { }
