import { Component } from '@angular/core';
import { FormulareService } from '../service';

@Component({
  selector: 'fa-kt-saved-formulare-filters',
  templateUrl: './saved-formulare-filters.component.html',
  styleUrls: ['./saved-formulare-filters.component.css'],
})
export class SavedFormulareFiltersComponent {
  constructor(public formulare: FormulareService) {}
}
