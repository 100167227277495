import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { KrankheitenFilterComponent } from './krankheiten-filter.component';
import { KrankheitenPresetsComponent } from './krankheiten-presets.component';
import { KrankheitenSorterComponent } from './krankheiten-sorter.component';
import { KrankheitenService } from './krankheiten.service';

@NgModule({
  imports: [LIB_CommonModule],
  declarations: [KrankheitenPresetsComponent, KrankheitenSorterComponent, KrankheitenFilterComponent],
  providers: [KrankheitenService],
  exports: [KrankheitenPresetsComponent, KrankheitenSorterComponent, KrankheitenFilterComponent],
})
export class KrankheitenQueryModule {}
