import { Component } from '@angular/core';
import { ObjektService } from '../service';

@Component({
  selector: 'fa-kt-saved-objekt-filters',
  templateUrl: './saved-objekt-filters.component.html',
  styleUrls: ['./saved-objekt-filters.component.css'],
})
export class SavedObjektFiltersComponent {
  constructor(public objekt: ObjektService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
