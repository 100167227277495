<!--

  <fa-kt-post-extra-vereinbarung [style.width]="'100%'" [value]="$postExtraVereinbarung | async" (finished)="finishedPostExtraVereinbarung($event)"></fa-kt-post-extra-vereinbarung>

  <fa-kt-delete-extra-vereinbarung [style.width]="'100%'" [value]="$deleteExtraVereinbarung | async" (finished)="finishedDeleteExtraVereinbarung($event)"></fa-kt-delete-extra-vereinbarung>



-->
<!--- << custom    -->
<nz-divider nzText="Extra-Vereinbarungen" nzOrientation="left"></nz-divider>

<nz-list *ngIf="extraVereinbarungen" nzSize="large" [nzLoading]="$loading | async">
  <nz-list-item *ngFor="let extraVereinbarung of extraVereinbarungen" (click)="openExtraVereinbarung(extraVereinbarung)">
    <nz-list-item-meta [nzDescription]="ListDescription">
      <ng-template #ListDescription> </ng-template>
      <nz-list-item-meta-title>
        <a>
          Pro {{ getCapitalizedText(extraVereinbarung.pro) }}
          <nz-divider nzType="vertical"></nz-divider>
          {{ extraVereinbarung.name }}
          <nz-divider nzType="vertical"></nz-divider>
          <span nz-typography nzType="secondary">€{{ extraVereinbarung.kosten | number: '1.0-2' : 'de' }} </span>
        </a>
      </nz-list-item-meta-title>
    </nz-list-item-meta>
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
  <nz-list-item>
    <button nz-button (click)="openExtraVereinbarung({})" nzType="dashed" [disabled]="disabled">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Extra-Vereinbarung hinzufügen
    </button>
  </nz-list-item>
</nz-list>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="openExtraVereinbarung(null)" [nzVisible]="!!($postExtraVereinbarung | async)">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> ExtraVereinbarung </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-delete-extra-vereinbarung [style.width]="'100%'" [value]="$deleteExtraVereinbarung | async" (finished)="finishedDeleteExtraVereinbarung()"></fa-kt-delete-extra-vereinbarung>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-post-extra-vereinbarung [style.width]="'100%'" [value]="$postExtraVereinbarung | async" (finished)="finishedPostExtraVereinbarung()"></fa-kt-post-extra-vereinbarung>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
    <pbc-meta [value]="($postExtraVereinbarung | async)?.extraVereinbarung"></pbc-meta>
  </ng-template>
</nz-modal>
<!---    custom >> -->
