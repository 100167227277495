<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'datum'">
    <nz-date-picker [nzDisabled]="disabled" [ngModel]="value" (ngModelChange)="writeValue($event); blurred.emit(value)" [nzPlaceHolder]="placeholder" nzFormat="dd.MM.yyyy"></nz-date-picker>
  </ng-container>
  <ng-container *ngSwitchCase="'haken'">
    <label nz-checkbox [nzDisabled]="disabled" [ngModel]="value" (ngModelChange)="writeValue($event); blurred.emit($event)">{{ placeholder }}</label>
  </ng-container>
  <ng-container *ngSwitchCase="'zahl'">
    <input nz-input type="number" style="width: 150px" [disabled]="disabled" [ngModel]="value" (ngModelChange)="writeValue($event)" (blur)="blurred.emit(value)" [placeholder]="placeholder"/>
  </ng-container>
  <ng-container *ngSwitchCase="'option'">
    <span>Siehe unten (Bitte vorher speichern)</span>
  </ng-container>
  <ng-container *ngSwitchCase="'optionPlus'">
    <span>Siehe unten (Bitte vorher speichern)</span>
  </ng-container>
  <ng-container *ngSwitchCase="'mehrfachauswahlPlus'">
    <span>Siehe unten (Bitte vorher speichern)</span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <input nz-input [style.width]="width" [disabled]="disabled" [ngModel]="value" (ngModelChange)="writeValue($event)" (blur)="blurred.emit(value)" [placeholder]="placeholder"/>
  </ng-container>
</ng-container>
