import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteKonstanteCommandServiceModule } from '..';
import { DeleteKonstanteCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteKonstanteCommandServiceModule
  ],
  declarations: [DeleteKonstanteCommandComponent],
  exports: [DeleteKonstanteCommandComponent],

})
export class DeleteKonstanteCommandComponentModule { }
