import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { format } from 'date-fns';
import { IPostZahlungseingangRequest, IPostZahlungseingangResponse } from 'fa-kt.types';
import { round } from 'lodash';
import { ISelection } from 'pbc.types';
import { ProjektService } from '../../../../projekte';
import { PostZahlungseingangCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-zahlungseingang',
  templateUrl: './post-zahlungseingang.component.html',
  styleUrls: ['./post-zahlungseingang.component.css'],
})
export class PostZahlungseingangCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostZahlungseingangRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostZahlungseingangRequest> = new EventEmitter<IPostZahlungseingangRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostZahlungseingangResponse> = new EventEmitter<IPostZahlungseingangResponse>();

  @Input() projekt: string = '';

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  selection$ = new BehaviorSubject<ISelection[]>([]);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public projektService: ProjektService,
    public postZahlungseingang: PostZahlungseingangCommandService /* << more    */ /*    more >> */,
  ) {
    this.command = sitemap.FINANZEN.Commands.POST_ZAHLUNGSEINGANG;
    this.form = this.fb.group({
      zahlungseingang: this.fb.group({
        id: [null, []],
        projekt: [null, [Validators.required]],
        kunde: [null, []],
        rechnung: [null, [Validators.required]],
        summe: [null, [Validators.required]],
        datum: [null, [Validators.required]],
      }),
    });
    this.patch();
  }

  ngOnInit() {
    if (this.projekt) this.projektService.request({ id: this.projekt });
    this.postZahlungseingang.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostZahlungseingangRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.projektService.result$.subscribe((result) => {
        if (!result) return;
        this.selection$.next(
          result.rechnungen.map(({ bezeichnung, datum, nettoSumme, finaleSumme, id: value }) => ({
            summe: finaleSumme,
            label: `${bezeichnung} vom ${format(new Date(datum), 'dd.MM.yyyy')} (N: ${round(nettoSumme, 2)}€, B: ${round(finaleSumme, 2)}€)`,
            value,
          })),
        );
      }),
      this.zahlungseingangId?.valueChanges.subscribe(async (id) => {
        /* << on-zahlungseingang-id-change    */
        /*    on-zahlungseingang-id-change >> */
      }) as Subscription,
      this.zahlungseingangProjekt?.valueChanges.subscribe(async (projekt) => {
        /* << on-zahlungseingang-projekt-change    */
        /*    on-zahlungseingang-projekt-change >> */
      }) as Subscription,
      this.zahlungseingangKunde?.valueChanges.subscribe(async (kunde) => {
        /* << on-zahlungseingang-kunde-change    */
        /*    on-zahlungseingang-kunde-change >> */
      }) as Subscription,
      this.zahlungseingangRechnung?.valueChanges.subscribe(async (rechnung) => {
        /* << on-zahlungseingang-rechnung-change    */
        const selection = this.selection$.getValue()?.find(({ value }) => value === rechnung);
        if (selection && this.zahlungseingangSumme) {
          this.zahlungseingangSumme.patchValue(selection?.summe);
        }
        /*    on-zahlungseingang-rechnung-change >> */
      }) as Subscription,
      this.zahlungseingangSumme?.valueChanges.subscribe(async (summe) => {
        /* << on-zahlungseingang-summe-change    */
        /*    on-zahlungseingang-summe-change >> */
      }) as Subscription,
      this.zahlungseingangDatum?.valueChanges.subscribe(async (datum) => {
        /* << on-zahlungseingang-datum-change    */
        /*    on-zahlungseingang-datum-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostZahlungseingangRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.zahlungseingang) {
        value.zahlungseingang = cleanObject(value.zahlungseingang);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.zahlungseingang) {
        payload.zahlungseingang = cleanObject(payload.zahlungseingang);
      }
      const response: IPostZahlungseingangResponse = await this.postZahlungseingang.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get zahlungseingangId() {
    return this.form.controls.zahlungseingang.get('id');
  }
  get zahlungseingangProjekt() {
    return this.form.controls.zahlungseingang.get('projekt');
  }
  get zahlungseingangKunde() {
    return this.form.controls.zahlungseingang.get('kunde');
  }
  get zahlungseingangRechnung() {
    return this.form.controls.zahlungseingang.get('rechnung');
  }
  get zahlungseingangSumme() {
    return this.form.controls.zahlungseingang.get('summe');
  }
  get zahlungseingangDatum() {
    return this.form.controls.zahlungseingang.get('datum');
  }
}
