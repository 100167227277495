import { NgModule } from '@angular/core';

import {
  DeleteOrtCommandServiceModule,
  DeleteOrtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteOrtCommandServiceModule,
    DeleteOrtCommandComponentModule
  ],
  exports: [
    DeleteOrtCommandServiceModule,
    DeleteOrtCommandComponentModule
  ]
})
export class DeleteOrtCommandModule { }
