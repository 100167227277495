import { CdkScrollable } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, Injectable, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Params } from '@angular/router';

import { Workbook } from 'exceljs';
import { BehaviorSubject, Subscription } from 'rxjs';

import { BasePage, ISitemapPage } from 'pbc.angular';
import { isDateString, join, toInitials } from 'pbc.functions';
import { IAzureMapsPosition, ISelection } from 'pbc.types';

import { IBesichtigung, IGutachten, IKunde, IObjekt, IProjekt, IProjekteResponseRow } from 'fa-kt.types';
import { isDate } from 'lodash';
import { KundenService } from '../../../kunden';
import { ZeitenService } from '../../../zeiten/querys';
import { ZeitArtsService, ZeitUnterartsService } from '../../../zeiten/settings';
import { ImportComponent } from '../../components';
import { ProjekteService } from '../../querys';

@Injectable({
  providedIn: 'root',
})
export class ProjekteFilterService {
  map = false;
  unassigned = false;
  mine = false;
  erinnerungen = false;
  finanzen = false;
  archiv = false;

  setUnassigned($event: any) {
    if ($event) {
      this.erinnerungen = false;
      this.mine = false;
    }
  }

  setMine($event: any) {
    if ($event) {
      this.erinnerungen = false;
      this.unassigned = false;
    }
  }

  setErinnerungen($event: any) {
    if ($event) {
      this.unassigned = false;
      this.mine = false;
    }
  }
}

@Pipe({ name: 'mine' })
export class MinePipe implements PipeTransform {
  transform(value: IProjekteResponseRow[], id: string): unknown[] {
    value = value.filter((row) => (!row.projekt.keineBesichtigungErforderlich && row.projekt.besichtigungen?.includes(id)) || row.projekt.gutachten?.includes(id) || row.projekt.pruefung === id);
    value = value.map((a) => ({
      ...a,
      projekt: {
        order: a.projekt.fortschritt === 0 ? 0 : a.projekt.fortschritt === 100 ? 1 : 2,
        ...a.projekt,
      },
    }));
    return value;
  }
}

@Pipe({ name: 'unassigned' })
export class UnassignedPipe extends Pipe implements PipeTransform {
  transform(value: IProjekteResponseRow[]): IProjekteResponseRow[] {
    return value.filter(
      (row) =>
        (!row.projekt.keineBesichtigungErforderlich && row.projekt.besichtigungen?.filter((b) => b)?.length === 0) || row.projekt.gutachten?.filter((g) => g)?.length === 0 || !row.projekt.pruefung,
    );
  }
}

@Pipe({ name: 'erinnerungen' })
export class ErinnerungPipe implements PipeTransform {
  transform(value: IProjekteResponseRow[]): IProjekteResponseRow[] {
    return value.filter((row) => row.erinnerungen && row.erinnerungen.length > 0);
  }
}

@Component({
  selector: 'fa-kt-projekte-page',
  templateUrl: './projekte.page.html',
  styleUrls: ['./projekte.page.css'],
})
export class ProjektePage extends BasePage implements OnInit, AfterViewInit, OnDestroy {
  description = { context: 'PROJEKTE', page: 'PROJEKTE' };
  private readonly subscriptions: Subscription[] = [];

  public _projekt: ISitemapPage;
  public _umkreissuche: ISitemapPage;
  public _zeiten: ISitemapPage;

  result$: BehaviorSubject<IProjekteResponseRow[]> = new BehaviorSubject<IProjekteResponseRow[]>([]);
  $projekt = new BehaviorSubject<IProjekt | undefined>(undefined);

  $coordinateArray = new BehaviorSubject<IAzureMapsPosition[]>([]);
  $coordinateArrayWithSelected = new BehaviorSubject<IAzureMapsPosition[]>([]);
  popup: { gutachten: IGutachten; projekt: IProjekt; objekt: IObjekt } | undefined;
  $resize = new BehaviorSubject<Date>(new Date());

  $zeitArts = new BehaviorSubject<ISelection[]>([]);
  $zeitUnterarts = new BehaviorSubject<ISelection[]>([]);

  @ViewChild(CdkScrollable) cdkScrollable: CdkScrollable;
  itemSize = 39;

  constructor(
    public projekte: ProjekteService,
    public kunden: KundenService,
    public filter: ProjekteFilterService,
    public zeiten: ZeitenService,
    private zeitArts: ZeitArtsService,
    private zeitUnterarts: ZeitUnterartsService,
  ) {
    super();
    this._projekt = this.sitemap.PROJEKTE.Pages.PROJEKT;
    this._umkreissuche = this.sitemap.GUTACHTEN.Pages.UMKREISSUCHE;
    this._zeiten = this.sitemap.ZEITEN.Pages.ZEITEN;
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.subscriptions.push(
      ...[
        this.zeitArts.result$.subscribe((zeitArts) => {
          this.$zeitArts.next(zeitArts?.zeitArts.map((zeitArt) => ({ value: zeitArt.id, label: zeitArt.name })));
        }),
        this.zeitUnterarts.result$.subscribe((zeitUnterarts) => {
          this.$zeitUnterarts.next(zeitUnterarts?.zeitUnterarts.map((zeitUnterart) => ({ value: zeitUnterart.id, label: zeitUnterart.name })));
        }),
        this.projekte.result$.subscribe((result) => {
          this.result$.next(
            result?.projekte
              .filter(({ projekt, gutachten }) => !!projekt && !!gutachten)
              .map((entry) => {
                if (entry.zeit) return entry;
                const stunden = entry.zeiten?.reduce((n, { stunden }) => n + (!stunden || isNaN(stunden) ? 0 : stunden), 0);
                const minuten = stunden * 60 + entry.zeiten?.reduce((n, { minuten }) => n + (!minuten || isNaN(minuten) ? 0 : minuten), 0);
                const zeit = `${Math.floor(minuten / 60)} Std. ${minuten % 60} Min.`;
                return {
                  ...entry,
                  entries: entry.gutachten
                    .filter(({ id, objekt }) => !!id && !!objekt)
                    .map((gutachten) => ({
                      gutachten,
                      objekt: entry.objekte?.find(({ id }) => id === gutachten.objekt),
                      besichtigung: entry.besichtigungen?.find(({ gutachten: id }) => id === gutachten.id),
                    })),
                  zeit,
                };
              }),
          );
        }),
        this.result$.subscribe((result) => {
          if (!result) this.$coordinateArray.next([]);
          else
            this.$coordinateArray.next(
              result
                .map((row) => {
                  return row.entries
                    .filter(({ objekt }) => objekt?.addresse.latitude && !isNaN(objekt?.addresse.latitude) && objekt?.addresse.longitude && !isNaN(objekt?.addresse.longitude))
                    .map(({ objekt, gutachten }) => ({
                      lat: objekt.addresse.latitude as number,
                      lon: objekt.addresse.longitude as number,
                      color: this.projekte.getProjektProjektStatus(row.projekt?.projektStatus as string)?.color,
                      object: { projekt: row.projekt, gutachten, objekt },
                    }));
                })
                .flat(),
            );
          this.$resize.next(new Date());
        }),
        this.$coordinateArray.subscribe((coordinates) => this.showProjekt(this.popup)),
      ],
    );
  }
  ngAfterViewInit(): void {
    this.$resize.next(new Date());
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setProjekte(id: string) {
    /* << set-projekte    */
    await this.navigateToProjekt({ id });
    /*    set-projekte >> */
  }

  public async navigateToProjekt(queryParams: Params = {}) {
    await this.router.navigate(this._projekt.url, { queryParams });
  }
  public async navigateToUmkreissuche(queryParams: Params = {}) {
    await this.router.navigate(this._umkreissuche.url, { queryParams });
  }
  public async navigateToZeiten(queryParams: Params = {}) {
    await this.router.navigate(this._zeiten.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  /* << methods    */
  startUpload() {
    this.modal.create({
      nzTitle: 'Lora Export Hochladen',
      nzContent: ImportComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: [],
    });
  }

  async openProjekt(id: any) {
    await this.navigateToProjekt({ id });
  }

  async exportAuswahl() {
    this.loading$.next(true);

    const [blob] = await Promise.all([this.files.get('fa-kt-apps/GF_Gutachten.xlsx'), this.kunden.request({})]);
    if (!blob) {
      this.loading$.next(false);
      return;
    }

    const arrayBuffer = await blob.arrayBuffer();
    const workbook = new Workbook();
    await workbook.xlsx.load(arrayBuffer);
    const sheetName = 'Gutachten';
    const worksheet = workbook.getWorksheet(sheetName);
    worksheet.state = 'visible';

    worksheet.getCell('B1').value = 'Stand ' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
    let i = 6;

    const gutachten: {
      gutachten: IGutachten;
      objekt?: IObjekt;
      projekt: IProjekt;
      kunde: IKunde;
      besichtigung?: IBesichtigung;
    }[] = [];
    this.projekte.result$.getValue()?.projekte.forEach((row) => {
      row.gutachten
        .map((gutachten) => ({
          gutachten,
          objekt: row.objekte?.find((o) => o && gutachten.objekt === o.id),
        }))
        .forEach((gutachtenObjekt) => {
          gutachten.push({
            ...gutachtenObjekt,
            projekt: row.projekt,
            kunde: row.kunde,
            besichtigung: row.besichtigungen?.shift(),
          });
        });
    });
    gutachten?.forEach((row) => {
      const besichtigungen =
        row.projekt.besichtigungen && !row.projekt.keineBesichtigungErforderlich
          ? join(row.projekt.besichtigungen.map((besichtigung) => this.projekte.getProjektBesichtigungen(besichtigung)?.label as string))
          : '';
      const gutachten = row.projekt.gutachten ? join(row.projekt.gutachten.map((besichtigung) => this.projekte.getProjektGutachten(besichtigung)?.label as string)) : '';
      const pruefung = row.projekt?.pruefung ? (this.projekte.getProjektPruefung(row.projekt?.pruefung)?.label as string) : '';

      worksheet.getCell('B' + i).value = row.gutachten?.id;
      worksheet.getCell('C' + i).value = new Date(row.gutachten?._createdAt as Date);
      worksheet.getCell('D' + i).value = this.projekte.getProjektGutachten(row.gutachten?._createdBy as string)?.label
        ? this.projekte.getProjektGutachten(row.gutachten?._createdBy as string)?.label
        : row.gutachten?._createdBy;
      worksheet.getCell('E' + i).value = new Date(row.gutachten?._updatedAt as Date);
      worksheet.getCell('F' + i).value = this.projekte.getProjektGutachten(row.gutachten?._updatedBy as string)?.label
        ? this.projekte.getProjektGutachten(row.gutachten?._updatedBy as string)?.label
        : row.gutachten?._updatedBy;

      worksheet.getCell('G' + i).value = row.objekt?.addresse.plz + ' ' + row.objekt?.addresse.gemeinde_stadt + ', ' + row.objekt?.addresse.strasse;
      worksheet.getCell('H' + i).value = row.objekt?.addresse.bundesland !== 'NULL' ? row.objekt?.addresse.bundesland : '';
      worksheet.getCell('I' + i).value = row.objekt?.addresse.plz;
      worksheet.getCell('J' + i).value = row.objekt?.addresse.gemeinde_stadt !== 'NULL' ? row.objekt?.addresse.gemeinde_stadt : '';
      worksheet.getCell('K' + i).value = row.objekt?.addresse.ort_stadt_teil !== 'NULL' ? row.objekt?.addresse.ort_stadt_teil : '';
      worksheet.getCell('L' + i).value = row.objekt?.addresse.strasse;
      worksheet.getCell('M' + i).value = row.objekt?.addresse.extra !== 'NULL' ? row.objekt?.addresse.extra : '';
      worksheet.getCell('N' + i).value = row.objekt?.addresse.latitude && row.objekt?.addresse.longitude ? 'Vorhanden' : 'Nicht vorhanden';
      worksheet.getCell('O' + i).value = row.objekt?.addresse.latitude;
      worksheet.getCell('P' + i).value = row.objekt?.addresse.longitude;
      worksheet.getCell('Q' + i).value = row.objekt?.addresse.land;

      worksheet.getCell('R' + i).value = row.projekt.nummer;
      worksheet.getCell('S' + i).value = row.projekt.projektZaehler;
      worksheet.getCell('T' + i).value = new Date(row.projekt._createdAt as Date).getFullYear();
      worksheet.getCell('U' + i).value = row.projekt.nummer + ' | ' + row.projekt.bezeichnung;

      worksheet.getCell('V' + i).value = this.projekte.getProjektProjektArt(row.projekt?.projektArt as string)?.label;
      worksheet.getCell('W' + i).value = this.projekte.getProjektProjektStatus(row.projekt?.projektStatus as string)?.label;
      worksheet.getCell('X' + i).value = this.projekte.getGutachtenBewertungsAnlass(row.projekt.bewertungsAnlass as string)?.label;

      worksheet.getCell('Y' + i).value = pruefung;
      worksheet.getCell('Z' + i).value = gutachten;
      worksheet.getCell('AA' + i).value = besichtigungen;

      worksheet.getCell('AB' + i).value = row.kunde?.name;
      worksheet.getCell('AC' + i).value = row.kunde?.id;
      worksheet.getCell('AD' + i).value = this.projekte.getProjektBank(row.projekt?.bank as string)?.label;
      worksheet.getCell('AE' + i).value = this.kunden.getKundeKundenArt(row.kunde?.kundenArt as string)?.label;

      worksheet.getCell('AF' + i).value = this.projekte.getGutachtenBewertungsStatus(row.gutachten.bewertungsStatus)?.label;

      worksheet.getCell('AG' + i).value = row.gutachten.datum_besichtigung ? new Date(row.gutachten.datum_besichtigung as Date) : row.besichtigung ? new Date(row.besichtigung.von as Date) : null;
      worksheet.getCell('AH' + i).value = row.gutachten.besichtigung_kontakt;
      worksheet.getCell('AI' + i).value = row.gutachten.besichtigung_email;
      worksheet.getCell('AJ' + i).value = row.gutachten.besichtigung_festnetz;
      worksheet.getCell('AK' + i).value = row.gutachten.besichtigung_mobil;
      worksheet.getCell('AL' + i).value = row.besichtigung ? row.besichtigung.kommentar : '';

      worksheet.getCell('AM' + i).value = this.isDate(row.gutachten.abgabeDraft) ? new Date(row.gutachten.abgabeDraft as Date) : '';
      worksheet.getCell('AN' + i).value = this.isDate(row.gutachten.abgabeFinal) ? new Date(row.gutachten.abgabeFinal as Date) : '';

      worksheet.getCell('AO' + i).value = this.projekte.getGutachtenObjektNutzung(row.gutachten.objektNutzung as string)?.label;
      worksheet.getCell('AP' + i).value = this.projekte.getGutachtenObjektArt(row.gutachten.objektArt as string)?.label;

      worksheet.getCell('AQ' + i).value = row.gutachten.baujahr;
      worksheet.getCell('AR' + i).value = row.gutachten.modernisierung;
      worksheet.getCell('AS' + i).value = row.gutachten.erbbaurecht ? 'Ja' : 'Nein';
      worksheet.getCell('AT' + i).value = row.gutachten.stichtagMarktwert ? new Date(row.gutachten.stichtagMarktwert as Date) : '';
      worksheet.getCell('AU' + i).value = row.gutachten.marktwert;
      worksheet.getCell('AV' + i).value = row.gutachten.flaeche;
      worksheet.getCell('AW' + i).value = row.gutachten.wohnenJRoEFlaeche;
      worksheet.getCell('AX' + i).value = row.gutachten.gewerbeJRoEFlaeche;
      worksheet.getCell('AY' + i).value = row.gutachten.sonstigesJRoEFlaeche;
      worksheet.getCell('AZ' + i).value = row.gutachten.jahresrohertrag;
      worksheet.getCell('BA' + i).value = row.gutachten.wohnenJRoE;
      worksheet.getCell('BB' + i).value = row.gutachten.gewerbeJRoE;
      worksheet.getCell('BC' + i).value = row.gutachten.sonstigesJRoE;

      i++;
    });

    await this.files.downloadBlob(
      new Blob([await workbook.xlsx.writeBuffer()], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      'Export der Projektauswahl.xlsx',
    );

    this.loading$.next(false);
  }

  isDate(dt): boolean {
    return !!dt && (isDate(dt) || isDateString(dt));
  }

  getColorByFortschritt(fortschritt: number): string {
    if (!fortschritt || fortschritt === 0) {
      return 'red';
    } else if (fortschritt !== 100) {
      return 'orange';
    } else {
      return 'green';
    }
  }

  getProjektKostenColor(kosten: number) {
    if (!kosten || kosten === 0) {
      return 'gray';
    } else {
      return 'green';
    }
  }

  getProjektKostenBeglichenColor(kosten: number, kostenBeglichen: number) {
    if (!kostenBeglichen || !kostenBeglichen) {
      return 'gray';
    } else if (kostenBeglichen >= kosten) {
      return 'green';
    } else {
      return 'red';
    }
  }

  getMitarbeiterText(mitarbeiter: string, asInitialen = false) {
    const label = this.projekte.getProjektGutachten(mitarbeiter)?.label;
    if (!asInitialen || !label) return label;
    return toInitials(label.split('(')[0]);
  }

  async openZeiten(projekt: string, id?: string) {
    this.zeiten.filter$.next({ ...(this.zeiten.filter$.getValue() || {}), 'zeit.projekt': { operator: 'ist', value: [projekt] } });
    await this.navigateToZeiten({ id });
  }

  async refresh() {
    this.loading$.next(true);
    await this.projekte.request({ ...this.projekte.payload$.getValue(), inklusiveInaktive: this.filter.archiv }, true);
    this.loading$.next(false);
  }

  scrollUp() {
    const top = this.cdkScrollable.measureScrollOffset('top');
    const height = this.cdkScrollable.getElementRef().nativeElement.offsetHeight;
    this.cdkScrollable.scrollTo({ top: top - height + this.itemSize });
  }

  scrollDown() {
    const top = this.cdkScrollable.measureScrollOffset('top');
    const height = this.cdkScrollable.getElementRef().nativeElement.offsetHeight;
    this.cdkScrollable.scrollTo({ top: top + height - this.itemSize });
  }

  showProjekt(popup?: { gutachten: IGutachten; projekt: IProjekt; objekt: IObjekt } | undefined) {
    if (popup)
      this.$coordinateArrayWithSelected.next(
        this.$coordinateArray.getValue().map((coordinate) => ({ ...coordinate, type: coordinate.object.gutachten.id === popup.gutachten.id ? 'selected' : 'default' })),
      );
    else this.$coordinateArrayWithSelected.next(this.$coordinateArray.getValue());
    this.popup = popup;
  }

  showKommentar(projekt: IProjekt): boolean {
    const until = new Date();
    until.setFullYear(2023, 1, 17);
    return new Date(projekt.beauftragung) < until;
  }

  showKosten(entry: IProjekteResponseRow) {
    const user = this.auth.$id.getValue();
    const finanzen = this.auth.$isFinanzen.getValue();
    return finanzen || entry.projekt.gutachten?.includes(user); // entry.projekt.besichtigungen?.includes(user) ||  || entry.projekt.pruefung === user;
  }

  editProjekt(projekt?: IProjekt, event?: Event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.$projekt.next(projekt);
  }
}
