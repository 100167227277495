import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostRechnungenHochzaehlenCommandShapes, IPostRechnungenHochzaehlenRequest, IPostRechnungenHochzaehlenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostRechnungenHochzaehlenCommandService extends PostCommandService<IPostRechnungenHochzaehlenRequest, IPostRechnungenHochzaehlenResponse, IPostRechnungenHochzaehlenCommandShapes> {
  readonly route = 'finanzen/rechnungen-hochzaehlen';
}
