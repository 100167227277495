import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IErforderlichesDokument } from 'fa-kt.types';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PostErforderlichesDokumentCommandService } from '../../commands';
import { ErforderlichesDokumentsService } from '../service';

@Component({
  selector: 'fa-kt-erforderliches-dokuments-list',
  templateUrl: './erforderliches-dokuments-list.component.html',
  styleUrls: ['./erforderliches-dokuments-list.component.css'],
})
export class ErforderlichesDokumentsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IErforderlichesDokument> = new EventEmitter<IErforderlichesDokument>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public erforderlichesDokuments: ErforderlichesDokumentsService,
    public erforderlichesDokumentPost: PostErforderlichesDokumentCommandService,
  ) {
    this.sortable$ = this.erforderlichesDokuments.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const erforderlichesDokuments = this.erforderlichesDokuments.result$.getValue();
    if (erforderlichesDokuments && erforderlichesDokuments.erforderlichesDokuments) {
      if ($event) {
        moveItemInArray(erforderlichesDokuments.erforderlichesDokuments, $event.previousIndex, $event.currentIndex);
      }
      erforderlichesDokuments.erforderlichesDokuments = erforderlichesDokuments.erforderlichesDokuments.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.erforderlichesDokumentPost.request({ erforderlichesDokument: erforderlichesDokuments.erforderlichesDokuments });
    }
  }
}
