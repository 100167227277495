import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IProjektStatiResponse } from '.';

export interface IProjektStatiQueryFilter extends IFilter<IProjektStatiResponse> {}

export const ProjektStatiQueryFilterConfig: IFilterConfig<IProjektStatiResponse> = [];

export interface IProjektStatiQueryShapes extends IShape {}

export type IProjektStatiQueryField = 'projektStati.id' | 'projektStati.name' | 'projektStati.beschreibung' | '';
