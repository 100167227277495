import { NgModule } from '@angular/core';

import {
  DeleteProjektArtCommandServiceModule,
  DeleteProjektArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteProjektArtCommandServiceModule,
    DeleteProjektArtCommandComponentModule
  ],
  exports: [
    DeleteProjektArtCommandServiceModule,
    DeleteProjektArtCommandComponentModule
  ]
})
export class DeleteProjektArtCommandModule { }
