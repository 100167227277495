import { IFilter, IFilterConfig } from "pbc.types";

import { IObjektArt } from '.';

export interface IObjektArtFilter extends IFilter<IObjektArt> {}

export const ObjektArtFilterConfig: IFilterConfig<IObjektArt> = [];

export type IObjektArtField =
  'objektArt.id'|
  'objektArt.objektNutzung'|
  'objektArt.name'|
  'objektArt.initialen'|
  'objektArt.besichtigungsZeit'|
  'objektArt.beschreibung'
;
