import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostProjektArtCommandShapes, IPostProjektArtRequest, IPostProjektArtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostProjektArtCommandService extends PostCommandService<IPostProjektArtRequest, IPostProjektArtResponse, IPostProjektArtCommandShapes> {
  readonly route = 'projekte/settings/projekt-art';
}
