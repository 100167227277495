import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteProjektStatusCommandServiceModule } from '..';
import { DeleteProjektStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteProjektStatusCommandServiceModule
  ],
  declarations: [DeleteProjektStatusCommandComponent],
  exports: [DeleteProjektStatusCommandComponent],

})
export class DeleteProjektStatusCommandComponentModule { }
