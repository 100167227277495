import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SettingsQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IFeldUnterkategoriesQueryFilter, IFeldUnterkategoriesQueryShapes, IFeldUnterkategoriesQuerySorter, IFeldUnterkategoriesRequest, IFeldUnterkategoriesResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class FeldUnterkategoriesService extends SettingsQueryService<
  IFeldUnterkategoriesRequest,
  IFeldUnterkategoriesResponse,
  IFeldUnterkategoriesQueryShapes,
  IFeldUnterkategoriesQueryFilter,
  IFeldUnterkategoriesQuerySorter
> {
  contentKey: keyof IFeldUnterkategoriesResponse = 'feldUnterkategories';
  route = 'felder/settings/feld-unterkategories';
  override dependencies = ['felder/written-feld-unterkategorie', 'felder/removed-feld-unterkategorie', 'felder/written-feld-kategorie', 'felder/removed-feld-kategorie'];

  public initialenIsUnique(control: AbstractControl | null): (initialen: string) => boolean {
    return (initialen: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (initialen) {
        const response = this.response$.getValue();
        if (response && response.feldUnterkategories) {
          isUnique = response.feldUnterkategories.filter((fu) => fu.id !== id && fu.initialen === initialen).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    };
  }

  async getFeldUnterkategoriesFeldKategorie(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.feldUnterkategoriesFeldKategorie.pipe(first()).subscribe((feldUnterkategoriesFeldKategorie) => resolve(feldUnterkategoriesFeldKategorie.find((a) => a.value === value)));
    });
  }

  get feldUnterkategoriesFeldKategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feldUnterkategories.feldKategorie'] as ISelection[]) : [])));
  }
}
