import { NgModule } from '@angular/core';

import {
  PostZeitArtCommandComponentModule, PostZeitArtCommandServiceModule
} from '.';

@NgModule({
  imports: [
    PostZeitArtCommandServiceModule,
    PostZeitArtCommandComponentModule
  ],
  exports: [
    PostZeitArtCommandServiceModule,
    PostZeitArtCommandComponentModule
  ]
})
export class PostZeitArtCommandModule { }
