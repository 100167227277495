import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IZeitSorter extends ISorter {
  id?: ISorterExpression;
  mitarbeiter?: ISorterExpression;
  projekt?: ISorterExpression;
  gutachten?: ISorterExpression;
  kunde?: ISorterExpression;
  zeitArt?: ISorterExpression;
  zeitUnterart?: ISorterExpression;
  datum?: ISorterExpression;
  stunden?: ISorterExpression;
  minuten?: ISorterExpression;
  kommentar?: ISorterExpression;
  kosten?: ISorterExpression;
}

export const ZeitSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('mitarbeiter', 'Mitarbeiter'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('zeitArt', 'Zeit Art'),
  SorterConfig('zeitUnterart', 'Zeit Unterart'),
  SorterConfig('datum', 'Datum'),
  SorterConfig('stunden', 'Stunden'),
  SorterConfig('minuten', 'Minuten'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('kosten', 'Kosten')
];
