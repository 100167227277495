import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IDurchgangRequest, IDurchgangResponse } from 'fa-kt.types';
import { PostEintragCommandService } from '../../../gutachten';
import { PostDurchgangCommandService } from '../../commands';
import { DurchgangService } from '../../querys';

export interface IDurchgangPageResolverResult {
  durchgang?: IDurchgangResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class DurchgangPageResolver implements Resolve<IDurchgangPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private durchgang: DurchgangService,
    private postEintrag: PostEintragCommandService,
    private postDurchgang: PostDurchgangCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IDurchgangPageResolverResult> {
    this.loading.resolving();
    const requests = {
      durchgang: {} as IDurchgangRequest,
    };
    /* << custom    */
    requests.durchgang.id = route.queryParams['id'];
    /*    custom >> */
    const log = (error: any) => console.error(error);
    const [durchgang] = await Promise.all([this.durchgang.request(requests.durchgang).catch(log)]);
    this.loading.resolved();
    return {
      durchgang,
    };
  }
}
