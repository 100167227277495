import { Injectable } from '@angular/core';

import { DeleteCommandService } from 'pbc.angular';

import { IDeleteStandortRequest, IDeleteStandortResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DeleteStandortCommandService extends DeleteCommandService<IDeleteStandortRequest, IDeleteStandortResponse> {
  readonly route = 'personen/settings/standort';
}
