import { NgModule } from '@angular/core';

import {
  PostDateiArtCommandServiceModule,
  PostDateiArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostDateiArtCommandServiceModule,
    PostDateiArtCommandComponentModule
  ],
  exports: [
    PostDateiArtCommandServiceModule,
    PostDateiArtCommandComponentModule
  ]
})
export class PostDateiArtCommandModule { }
