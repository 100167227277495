<!---
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="original">{{ model.Propertys.ORIGINAL.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OriginalErrorTip">
      <ng-template #OriginalErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.ORIGINAL.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <input nz-input formControlName="original" id="original" [nzPlaceHolder]="model.Propertys.ORIGINAL.placeholder" cdkFocusInitial/>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachten">{{ model.Propertys.GUTACHTEN.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
      <ng-template #GutachtenErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.GUTACHTEN.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <pbc-select-value mode="default" formControlName="gutachten" id="gutachten" [shapes]="postErforderlichesDokument.erforderlichesDokumentsgutachten | async" [placeholder]="model.Propertys.GUTACHTEN.placeholder" ></pbc-select-value>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ model.Propertys.NAME.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
      <ng-template #NameErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.NAME.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="model.Propertys.NAME.placeholder"/>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="erforderlich">{{ model.Propertys.ERFORDERLICH.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ErforderlichErrorTip">
      <ng-template #ErforderlichErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.ERFORDERLICH.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <label nz-checkbox formControlName="erforderlich" id="erforderlich"></label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fortschritt">{{ model.Propertys.FORTSCHRITT.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FortschrittErrorTip">
      <ng-template #FortschrittErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.FORTSCHRITT.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="fortschritt" id="fortschritt" [placeholder]="model.Propertys.FORTSCHRITT.placeholder"/>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ model.Propertys.KOMMENTAR.title}}</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
      <ng-template #KommentarErrorTip let-control>
        <ng-container *ngIf="control.hasError('required')">{{ model.Propertys.KOMMENTAR.hint }}</ng-container>
        <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
        <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
      </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="model.Propertys.KOMMENTAR.placeholder"></textarea>
    </nz-form-control>
  </nz-form-item>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div formGroupName="erforderlichesDokument">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="order" id="order"/>
<!--- << content    -->
      <input class="cdk-visually-hidden" formControlName="fortschritt" id="fortschritt"/>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ model.Propertys.NAME.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="model.Propertys.NAME.hint">
          <input nz-input formControlName="name" id="name" [placeholder]="model.Propertys.NAME.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="erforderlich">{{ model.Propertys.ERFORDERLICH.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" >
          <label nz-checkbox formControlName="erforderlich" id="erforderlich"></label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ model.Propertys.KOMMENTAR.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" >
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="model.Propertys.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
<!---    content >> -->
      <nz-form-item>
        <nz-form-control [nzOffset]="16">
          <button nz-button nzType="primary" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
            <i nz-icon nzType="check-circle" nzTheme="outline"></i>
            Speichern
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
<!--- << extra    -->
<!---    extra >> -->
</nz-spin>
