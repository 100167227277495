import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFotoKategorieCommandComponent } from '.';
import { DeleteFotoKategorieCommandServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteFotoKategorieCommandServiceModule
  ],
  declarations: [DeleteFotoKategorieCommandComponent],
  exports: [DeleteFotoKategorieCommandComponent],

})
export class DeleteFotoKategorieCommandComponentModule { }
