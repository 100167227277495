import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostBerichtsVorlageCommandShapes, IPostBerichtsVorlageRequest, IPostBerichtsVorlageResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostBerichtsVorlageCommandService extends PostCommandService<IPostBerichtsVorlageRequest, IPostBerichtsVorlageResponse, IPostBerichtsVorlageCommandShapes> {
  readonly route = 'texte/berichts-vorlage';

  override async request(payload: IPostBerichtsVorlageRequest): Promise<IPostBerichtsVorlageResponse> {
    return (await this.http.upload<IPostBerichtsVorlageResponse>(this.route, payload, 'berichts-vorlagen')) as unknown as IPostBerichtsVorlageResponse;
  }
}
