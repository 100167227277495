<cdk-virtual-scroll-viewport *ngIf="umkreissuche.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (umkreissuche.result$ | async).umkreissuche" (click)="set(entry)">
      <nz-list-item-meta [nzDescription]="ListDescription">
        <ng-template #ListDescription>
          <!--- << description    -->
          <!---    description >> -->
        </ng-template>
        <nz-list-item-meta-title>
          <a>
            <!--- << content    -->
            {{ entry.objekt.addresse.strasse }}, {{ entry.objekt.addresse.plz }},
            <nz-divider nzType="vertical"></nz-divider>
            {{ entry.gutachten.abgabeDraft | date: 'dd.MM.yyyy' }}
            <!---    content >> -->
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <!--- << extra    -->
          <!---    extra >> -->
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
