<!---
  <div formGroupName="feldOption">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="feld">{{ command.Inputs.FELD.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FeldErrorTip">
        <ng-template #FeldErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FELD.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="feld" id="feld" [shapes]="postFeldOption.feldOptionFeld | async" [placeholder]="command.Inputs.FELD.placeholder" cdkFocusInitial required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="option">{{ command.Inputs.OPTION.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OptionErrorTip">
        <ng-template #OptionErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OPTION.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="option" id="option" [shapes]="postFeldOption.feldOptionOption | async" [placeholder]="command.Inputs.OPTION.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="akkusativ">{{ command.Inputs.AKKUSATIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AkkusativErrorTip">
        <ng-template #AkkusativErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKKUSATIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="akkusativ" id="akkusativ" [placeholder]="command.Inputs.AKKUSATIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nominativ">{{ command.Inputs.NOMINATIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NominativErrorTip">
        <ng-template #NominativErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOMINATIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="nominativ" id="nominativ" [placeholder]="command.Inputs.NOMINATIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dativ">{{ command.Inputs.DATIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DativErrorTip">
        <ng-template #DativErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="dativ" id="dativ" [placeholder]="command.Inputs.DATIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="genitiv">{{ command.Inputs.GENITIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GenitivErrorTip">
        <ng-template #GenitivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GENITIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="genitiv" id="genitiv" [placeholder]="command.Inputs.GENITIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="standard">{{ command.Inputs.STANDARD.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StandardErrorTip">
        <ng-template #StandardErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STANDARD.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="standard" id="standard"></label>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <div formGroupName="feldOption">
      <input class="cdk-visually-hidden" formControlName="id" id="id" />
      <input class="cdk-visually-hidden" formControlName="feld" id="feld" />
      <input class="cdk-visually-hidden" formControlName="order" id="order" />
      <input class="cdk-visually-hidden" formControlName="standard" id="standard" />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="option">{{ command.Inputs.OPTION.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OptionErrorTip">
          <ng-template #OptionErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OPTION.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="option" id="option" [placeholder]="command.Inputs.OPTION.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="plural">Plural</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PluralErrorTip">
          <ng-template #PluralErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">'Plural'</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="plural" id="plural" [placeholder]="'Plural'" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="akkusativ">{{ command.Inputs.AKKUSATIV.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AkkusativErrorTip">
          <ng-template #AkkusativErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKKUSATIV.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="akkusativ" id="akkusativ" [placeholder]="command.Inputs.AKKUSATIV.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nominativ">{{ command.Inputs.NOMINATIV.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NominativErrorTip">
          <ng-template #NominativErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOMINATIV.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="nominativ" id="nominativ" [placeholder]="command.Inputs.NOMINATIV.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dativ">{{ command.Inputs.DATIV.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DativErrorTip">
          <ng-template #DativErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATIV.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="dativ" id="dativ" [placeholder]="command.Inputs.DATIV.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="genitiv">{{ command.Inputs.GENITIV.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GenitivErrorTip">
          <ng-template #GenitivErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GENITIV.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="genitiv" id="genitiv" [placeholder]="command.Inputs.GENITIV.placeholder" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            Feld-Option Speichern
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
