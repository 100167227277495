import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IZeitenQueryFilter, IZeitenQueryShapes, IZeitenQuerySorter, IZeitenRequest, IZeitenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class ZeitenService extends ListQueryService<IZeitenRequest, IZeitenResponse, IZeitenQueryShapes, IZeitenQueryFilter, IZeitenQuerySorter> {
  contentKey: keyof IZeitenResponse = 'zeiten';
  override readonly route = 'zeiten/zeiten';
  override readonly dependencies = [
    'zeiten/written-zeit-art',
    'zeiten/removed-zeit-art',
    'zeiten/written-zeit-unterart',
    'zeiten/removed-zeit-unterart',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert',
  ];

  static instance: ZeitenService;

  constructor() {
    super();
    if (!ZeitenService.instance) ZeitenService.instance = this;
    return ZeitenService.instance;
  }

  getZeitKunde(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeit.kunde']?.find((v) => v.value === value);
  }

  get zeitKunde(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeit.kunde'] as ISelection[]) : [])));
  }

  getZeitMitarbeiter(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeit.mitarbeiter']?.find((v) => v.value === value);
  }

  get zeitMitarbeiter(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeit.mitarbeiter'] as ISelection[]) : [])));
  }

  getZeitProjekt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeit.projekt']?.find((v) => v.value === value);
  }

  get zeitProjekt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeit.projekt'] as ISelection[]) : [])));
  }

  getZeitZeitArt(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeit.zeitArt']?.find((v) => v.value === value);
  }

  get zeitZeitArt(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeit.zeitArt'] as ISelection[]) : [])));
  }

  getZeitZeitUnterart(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['zeit.zeitUnterart']?.find((v) => v.value === value);
  }

  get zeitZeitUnterart(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['zeit.zeitUnterart'] as ISelection[]) : [])));
  }
}
