import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';

import { FilterComponent, FilterPresetComponent, MultiValueComponent, SearchBarComponent, SelectValueComponent, SorterComponent } from './components';
import { CheckPipe, FilterPipe, OrderPipe, ReversePipe, SafePipe, ImagePreloadDirective, SearchPipe, SorterPipe, TypePipe, HourFormatPipe } from './pipes';
import { PipeLoadingService } from './services';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzDropDownModule,
    NzModalModule,
    NzFormModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    NzAvatarModule,
    NzIconModule,
    NzEmptyModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzInputNumberModule,
    NzPopconfirmModule,
  ],
  declarations: [
    FilterComponent,
    SorterComponent,
    FilterPresetComponent,
    SearchBarComponent,
    SelectValueComponent,
    MultiValueComponent,
    FilterPipe,
    SorterPipe,
    SearchPipe,
    TypePipe,
    CheckPipe,
    OrderPipe,
    HourFormatPipe,
    SafePipe,
    ReversePipe,
    ImagePreloadDirective,
  ],
  providers: [PipeLoadingService, PipeLoadingService],
  exports: [
    FilterComponent,
    SorterComponent,
    FilterPresetComponent,
    SearchBarComponent,
    SelectValueComponent,
    MultiValueComponent,
    FilterPipe,
    SorterPipe,
    SearchPipe,
    TypePipe,
    CheckPipe,
    OrderPipe,
    HourFormatPipe,
    SafePipe,
    ReversePipe,
    ImagePreloadDirective,
  ],
})
export class LIB_FilterModule {}
