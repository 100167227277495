import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IBerichtRequest, IBerichtResponse } from 'fa-kt.types';
import { DeleteBerichtCommandService, PostBerichtCommandService } from '../../commands';
import { BerichtService } from '../../querys';

export interface IMarktBerichtPageResolverResult {
  bericht?: IBerichtResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class MarktBerichtPageResolver implements Resolve<IMarktBerichtPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private bericht: BerichtService,
    private deleteBericht: DeleteBerichtCommandService,
    private postBericht: PostBerichtCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IMarktBerichtPageResolverResult> {
    this.loading.resolving();
    const requests = {
      bericht: {} as IBerichtRequest,
    };
    /* << custom    */
    requests.bericht.id = route.queryParams['id'];
    /*    custom >> */
    const log = (error: any) => console.error(error);
    const [bericht] = await Promise.all([this.bericht.request(requests.bericht).catch(log)]);
    this.loading.resolved();
    return {
      bericht,
    };
  }
}
