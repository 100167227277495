import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IMitarbeiterSorter extends ISorter {
  id?: ISorterExpression;
  email?: ISorterExpression;
  anzeigename?: ISorterExpression;
  bild?: ISorterExpression;
  addresse?: ISorterExpression;
  vorname?: ISorterExpression;
  nachname?: ISorterExpression;
  telefon?: ISorterExpression;
  standort?: ISorterExpression;
  rollen?: ISorterExpression;
  stundenlohn?: ISorterExpression;
  inaktiv?: ISorterExpression;
}

export const MitarbeiterSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('email', 'Email'),
  SorterConfig('anzeigename', 'Anzeigename'),
  SorterConfig('bild', 'Bild'),
  SorterConfig('addresse', 'Addresse'),
  SorterConfig('vorname', 'Vorname'),
  SorterConfig('nachname', 'Nachname'),
  SorterConfig('telefon', 'Telefon'),
  SorterConfig('standort', 'Standort'),
  SorterConfig('rollen', 'Rollen'),
  SorterConfig('stundenlohn', 'Stundenlohn'),
  SorterConfig('inaktiv', 'Inaktiv')
];
