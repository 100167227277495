import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IFotoKategorienResponse } from './';

export interface IFotoKategorienQueryFilter extends IFilter<IFotoKategorienResponse> {}

export const FotoKategorienQueryFilterConfig: IFilterConfig<IFotoKategorienResponse> = [];

export interface IFotoKategorienQueryShapes extends IShape {}

export type IFotoKategorienQueryField = 'besichtigungsStati.id' | 'besichtigungsStati.name' | 'besichtigungsStati.beschreibung' | '';
