import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BerichtsVorlagenSorterComponent } from '.';
import { BerichtsVorlagenServiceModule } from '..';

@NgModule({
  imports: [LIB_CommonModule, BerichtsVorlagenServiceModule],
  declarations: [BerichtsVorlagenSorterComponent],
  exports: [BerichtsVorlagenSorterComponent],
})
export class BerichtsVorlagenSorterComponentModule {}
