import {
  ArrayReferenceFilterConfig,
  IArrayReferenceFilter,
  IFilter,
  IFilterConfig,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IBereich, IHerausgeber } from 'fa-kt.types';
import { IBerichtResponse } from '.';

export interface IBerichtQueryFilter extends IFilter<IBerichtResponse> {
  'bericht.herausgeber'?: ISingleReferenceFilter<IHerausgeber>;
  'bericht.jahr'?: INumberFilter;
  'bericht.monat'?: INumberFilter;
  'zeilen.bereiche'?: IArrayReferenceFilter<IBereich>;
  'zeilen.steigerung_1'?: INumberFilter;
  'zeilen.steigerung_2'?: INumberFilter;
  'zeilen.steigerung_3'?: INumberFilter;
  'zeilen.steigerung_4'?: INumberFilter;
  'zeilen.steigerung_5'?: INumberFilter;
}

export const BerichtQueryFilterConfig: IFilterConfig<IBerichtResponse> = [
  SingleReferenceFilterConfig<IHerausgeber>('bericht.herausgeber', 'MARKT.Models.BERICHT.Propertys.HERAUSGEBER.title'),
  NumberFilterConfig('bericht.jahr', 'MARKT.Models.BERICHT.Propertys.JAHR.title'),
  NumberFilterConfig('bericht.monat', 'MARKT.Models.BERICHT.Propertys.MONAT.title'),
  ArrayReferenceFilterConfig<IBereich>('zeilen.bereiche', 'MARKT.Models.ZEILE.Propertys.BEREICHE.title'),
  NumberFilterConfig('zeilen.steigerung_1', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_1.title'),
  NumberFilterConfig('zeilen.steigerung_2', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_2.title'),
  NumberFilterConfig('zeilen.steigerung_3', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_3.title'),
  NumberFilterConfig('zeilen.steigerung_4', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_4.title'),
  NumberFilterConfig('zeilen.steigerung_5', 'MARKT.Models.ZEILE.Propertys.STEIGERUNG_5.title'),
];

export interface IBerichtQueryShapes extends IShape {
  'bericht.art': ISelection[];
  'bericht.herausgeber': ISelection[];
  'zeilen.bereiche': ISelection[];
  'zeilen.steigerungArt': ISelection[];
}

export type IBerichtQueryField =
  | 'bericht.id'
  | 'bericht.excel'
  | 'bericht.art'
  | 'bericht.herausgeber'
  | 'bericht.quelle'
  | 'bericht.jahr'
  | 'bericht.quartal'
  | 'bericht.monat'
  | 'bericht.kommentar'
  | 'zeilen.id'
  | 'zeilen.bericht'
  | 'zeilen.land'
  | 'zeilen.bundesland'
  | 'zeilen.plz'
  | 'zeilen.region'
  | 'zeilen.kreisStadt'
  | 'zeilen.gemeindeBezirk'
  | 'zeilen.ortsStadtTeil'
  | 'zeilen.gemeindeschluessel'
  | 'zeilen.teilmarkt'
  | 'zeilen.bereiche'
  | 'zeilen.assetklasse'
  | 'zeilen.objektart'
  | 'zeilen.zustand'
  | 'zeilen.vertrag'
  | 'zeilen.wohnlage'
  | 'zeilen.wflVon'
  | 'zeilen.wflBis'
  | 'zeilen.wflMittel'
  | 'zeilen.bjVon'
  | 'zeilen.bjBis'
  | 'zeilen.wohnNutzWert'
  | 'zeilen.ausstattung'
  | 'zeilen.artDesWertes'
  | 'zeilen.einheit'
  | 'zeilen.bezug'
  | 'zeilen.durchschnitt'
  | 'zeilen.minimum'
  | 'zeilen.maximum'
  | 'zeilen.spitzenwerte'
  | 'zeilen.anzahl'
  | 'zeilen.steigerung_1'
  | 'zeilen.steigerung_2'
  | 'zeilen.steigerung_3'
  | 'zeilen.steigerung_4'
  | 'zeilen.steigerung_5'
  | 'zeilen.steigerungArt'
  | '';
