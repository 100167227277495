import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostHochladenCommandShapes, IPostHochladenRequest, IPostHochladenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostHochladenCommandService extends PostCommandService<IPostHochladenRequest, IPostHochladenResponse, IPostHochladenCommandShapes> {
  readonly route = 'markt/hochladen';
  override dependencies = ['markt/written-herausgeber', 'markt/removed-herausgeber'];

  override async request(payload: IPostHochladenRequest): Promise<IPostHochladenResponse> {
    return (await this.http.upload<IPostHochladenResponse>(this.route, payload, 'markt-berichte')) as unknown as IPostHochladenResponse;
  }
}
