import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ISitemapPage, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { IDeleteZeitRequest, IDeleteZeitResponse, IPostZeitRequest, IZeit } from 'fa-kt.types';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { GutachtenService } from '../../../gutachten';
import { KundeService } from '../../../kunden';
import { ProjekteService, ProjektService } from '../../../projekte';
import { DeleteZeitCommandService, PostZeitCommandService } from '../../commands';
import { ZeitService } from '../../querys';

@Component({
  selector: 'fa-kt-zeit',
  templateUrl: './zeit.component.html',
  styleUrls: ['./zeit.component.css'],
})
export class ZeitComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postZeit: BehaviorSubject<IPostZeitRequest | null> = new BehaviorSubject<IPostZeitRequest | null>(null);
  public readonly $deleteZeit: BehaviorSubject<IDeleteZeitRequest | null> = new BehaviorSubject<IDeleteZeitRequest | null>(null);
  _projekt: ISitemapPage;
  _umkreissuche: ISitemapPage;
  _vorlage: ISitemapPage;

  @Input() set id(id: string) {
    if (id) this.openZeit(id);
  }
  @Input() datum = new Date();
  @Input() set seconds(seconds: number) {
    if (seconds) {
      const minuten = Math.ceil(seconds / 60);
      const stunden = Math.floor(minuten / 60);
      this.openZeit({
        minuten: minuten % 60,
        stunden,
      });
    }
  }
  @Output() changed = new EventEmitter<string>();
  @Output() closed = new EventEmitter<boolean>();

  get projekt() {
    return this.injector.get(ProjektService);
  }
  get projekte() {
    return this.injector.get(ProjekteService);
  }
  get gutachten() {
    return this.injector.get(GutachtenService);
  }
  get kunde() {
    return this.injector.get(KundeService);
  }

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public zeit: ZeitService,
    public postZeit: PostZeitCommandService,
    public deleteZeit: DeleteZeitCommandService,
  ) {
    this._projekt = sitemap.PROJEKTE.Pages.PROJEKT;
    this._umkreissuche = sitemap.GUTACHTEN.Pages.UMKREISSUCHE;
    this._vorlage = sitemap.TEXTE.Pages.VORLAGE;
  }

  async ngOnInit() {
    await this.postZeit.prepare();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setZeit(id: string) {}

  async finishedPostZeit(zeit: IZeit) {
    await this.openZeit(null);
    this.closed.emit(true);
  }

  async finishedDeleteZeit(response?: IDeleteZeitResponse) {
    this.zeit.payload$.next(undefined);
    await this.openZeit(null);
  }

  /* << methods    */

  public async openZeit(params?: Partial<IZeit> | string) {
    if (!params) {
      this.$postZeit.next(null);
      this.$deleteZeit.next(null);
      this.changed.emit(undefined);
      this.closed.emit(true);
      return;
    }

    let zeit: IZeit = params as IZeit;
    if (typeof params === 'string') {
      if (params.length > 3) {
        const result = await this.zeit.request({ zeit: params }).catch(() => ({ zeit: {} }));
        zeit = result.zeit as IZeit;
      } else zeit = {} as IZeit;
    }
    if (!zeit.id || zeit.id === 'new') {
      zeit.id = undefined;
      if (!zeit.mitarbeiter) zeit.mitarbeiter = this.auth.$id.getValue();
      if (!zeit.datum) zeit.datum = this.datum;
      if (!zeit.ende)
        zeit.ende = moment(zeit.datum)
          .add(zeit.stunden || 0, 'hours')
          .add(zeit.minuten || 0, 'minutes')
          .toDate();
      if (!zeit.projekt && !zeit.kunde && [this._projekt.url, this._umkreissuche.url].some((url) => this.auth.access(url.join('/'), [window.location.pathname], true))) {
        zeit.projekt = this.projekt.result$.getValue()?.projekt.id;
        zeit.kunde = this.projekt.result$.getValue()?.projekt.kunde;
      }
      if (!zeit.projekt && !zeit.kunde && this.auth.access(this._vorlage.url.join('/'), [window.location.pathname], true)) {
        const row = this.projekte.response$.getValue()?.projekte?.find(({ projekt: { id } }) => id === this.projekt.payload$.getValue()?.id);
        if (row) {
          zeit.projekt = row.projekt.id;
          zeit.kunde = row.projekt.kunde;
        }
      }
    }
    this.$postZeit.next({ zeit });
    this.$deleteZeit.next({ id: zeit?.id });
    this.changed.emit(zeit.id);
  }
  /*    methods >> */
}
