import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostOrtCommandShapes, IPostOrtRequest, IPostOrtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostOrtCommandService extends PostCommandService<IPostOrtRequest, IPostOrtResponse, IPostOrtCommandShapes> {
  readonly route = 'geo/settings/ort';
}
