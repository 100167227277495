import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IFeldUnterkategorie } from 'fa-kt.types';
import { PostFeldUnterkategorieCommandService } from '../../commands';
import { FeldUnterkategoriesService } from '../service';

@Component({
  selector: 'fa-kt-feld-unterkategories-list',
  templateUrl: './feld-unterkategories-list.component.html',
  styleUrls: ['./feld-unterkategories-list.component.css'],
})
export class FeldUnterkategoriesListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IFeldUnterkategorie> = new EventEmitter<IFeldUnterkategorie>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public feldUnterkategories: FeldUnterkategoriesService,
    public feldUnterkategoriePost: PostFeldUnterkategorieCommandService,
  ) {
    this.sortable$ = this.feldUnterkategories.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const feldUnterkategories = this.feldUnterkategories.result$.getValue();
    if (feldUnterkategories && feldUnterkategories.feldUnterkategories) {
      if ($event) {
        moveItemInArray(feldUnterkategories.feldUnterkategories, $event.previousIndex, $event.currentIndex);
      }
      feldUnterkategories.feldUnterkategories = feldUnterkategories.feldUnterkategories.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.feldUnterkategoriePost.request({ feldUnterkategorie: feldUnterkategories.feldUnterkategories });
    }
  }
}
