import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../..';

declare var FsGlobe;

@Component({
  selector: 'pbc-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.css'],
})
export class WelcomePage implements OnDestroy, OnInit, AfterViewInit {
  constructor(public auth: AuthService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit(): void {
    const fsglobeminjs = document.createElement('script');
    fsglobeminjs.setAttribute('src', 'https://cdn.jsdelivr.net/npm/@finsweet/3dglobes@1/FsGlobe.min.js');
    fsglobeminjs.setAttribute('type', 'text/javascript');
    document.body.appendChild(fsglobeminjs);
    // fsglobeminjs.addEventListener("load", () => FsGlobe(););
  }
}
