import { IFilter, IFilterConfig } from "pbc.types";

import { IVorlage } from '.';

export interface IVorlageFilter extends IFilter<IVorlage> {}

export const VorlageFilterConfig: IFilterConfig<IVorlage> = [];

export type IVorlageField =
  'vorlage.id'|
  'vorlage.name'|
  'vorlage.initialen'|
  'vorlage.fuerKunden'|
  'vorlage.fuerKundenGruppen'|
  'vorlage.fuerKundenArten'|
  'vorlage.fuerObjektArten'|
  'vorlage.fuerBewertungsAnlaesse'|
  'vorlage.angewendet'
;
