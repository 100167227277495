import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, first } from 'rxjs/operators';

import { BaseComponent, ISitemapPage } from 'pbc.angular';
import { IAzureMapsPosition, ISelection } from 'pbc.types';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { addDays } from 'date-fns';
import {
  IBesichtigung,
  IDeleteGutachtenRequest,
  IDeleteGutachtenResponse,
  IDeleteObjektRequest,
  IDeleteObjektResponse,
  IEintrag,
  IErforderlichesDokument,
  IGutachten,
  IGutachtensResponseRow,
  IGutachtenResponse,
  IKunde,
  IObjekt,
  IPostDurchgangRequest,
  IPostDurchgangResponse,
  IPostGutachtenRequest,
  IPostGutachtenResponse,
  IPostObjektRequest,
  IPostObjektResponse,
  IProjekt,
  IUmkreissucheRequest,
} from 'fa-kt.types';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { TourenService } from '../../../besichtigungen';
import { KonstantesService } from '../../../einstellungen';
import { PostDurchgangCommandService } from '../../../formulare';
import { DeleteObjektCommandService, ObjekteComponent, ObjekteService, PostObjektCommandService } from '../../../objekte';
import { ProjektService } from '../../../projekte';
import { DeleteGutachtenCommandService, PostGutachtenCommandService } from '../../commands';
import { GutachtenService, GutachtensService } from '../../querys';
import { SicherheitscheckComponent } from '../sicherheitscheck';

@Component({
  selector: 'fa-kt-gutachten',
  templateUrl: './gutachten.component.html',
  styleUrls: ['./gutachten.component.css'],
})
export class GutachtenComponent extends BaseComponent implements OnInit, OnDestroy {
  public readonly $postDurchgang: BehaviorSubject<IPostDurchgangRequest | null> = new BehaviorSubject<IPostDurchgangRequest | null>(null);
  public readonly $postGutachten: BehaviorSubject<IPostGutachtenRequest | null> = new BehaviorSubject<IPostGutachtenRequest | null>(null);
  public readonly $patchGutachten = new BehaviorSubject<Partial<IGutachten> | undefined>(undefined);
  public readonly $deleteGutachten: BehaviorSubject<IDeleteGutachtenRequest | null> = new BehaviorSubject<IDeleteGutachtenRequest | null>(null);
  public readonly $postObjekt: BehaviorSubject<IPostObjektRequest | null> = new BehaviorSubject<IPostObjektRequest | null>(null);
  public readonly $deleteObjekt: BehaviorSubject<IDeleteObjektRequest | null> = new BehaviorSubject<IDeleteObjektRequest | null>(null);

  public _objekte: ISitemapPage;
  public _umkreissuche: ISitemapPage;
  public _durchgang: ISitemapPage;

  @Input() project: IProjekt | undefined = undefined;
  @Input() kunde: IKunde | undefined = undefined;
  public readonly $coordinateArray = new BehaviorSubject<IAzureMapsPosition[]>([]);
  public readonly $row = new BehaviorSubject<IGutachtenResponse | undefined>(undefined);
  public readonly $objekte = new BehaviorSubject<ISelection[]>([]);
  public readonly $gutachten = new BehaviorSubject<string | undefined>(undefined);
  public readonly $besichtigung = new BehaviorSubject<IBesichtigung | undefined>(undefined);
  public readonly $detail = new BehaviorSubject<'gutachten' | 'kommunikation' | 'dokumenten-anforderungen' | 'eintraege' | 'besichtigung'>('gutachten');
  public readonly $coordinates = new BehaviorSubject<IAzureMapsPosition | undefined>(undefined);
  public readonly $fortschritt = new BehaviorSubject<number>(0);
  eintraege: IEintrag[] = [];
  dokumente: IErforderlichesDokument[] = [];

  @Output() valid = new EventEmitter<boolean>();
  @Output() gutachtenSelected = new EventEmitter<IGutachtenResponse>();

  constructor(
    public gutachtens: GutachtensService,
    public gutachten: GutachtenService,
    public objekte: ObjekteService,
    public projekt: ProjektService,
    public postDurchgang: PostDurchgangCommandService,
    public postGutachten: PostGutachtenCommandService,
    public deleteGutachten: DeleteGutachtenCommandService,
    public postObjekt: PostObjektCommandService,
    public deleteObjekt: DeleteObjektCommandService,
    public touren: TourenService,
    public konstanten: KonstantesService,
  ) {
    super();
    this._objekte = this.sitemap.OBJEKTE.Pages.OBJEKTE;
    this._umkreissuche = this.sitemap.GUTACHTEN.Pages.UMKREISSUCHE;
    this._durchgang = this.sitemap.FORMULARE.Pages.DURCHGANG;
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.objekte.request({}).catch();
    if (!this.router.routerState.snapshot.root.queryParams.detail) {
      await this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: { detail: 'gutachten' },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (params: Params) => {
      this.$gutachten.next(params.gutachten);
      this.$detail.next(params.detail);
    });
    this.objekte.response$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((result) => {
      if (!result) return;
      const objekte = result.objekte
        .map((row) => row.objekt)
        .map((objekt) => ({
          label: `${objekt.addresse.strasse ? objekt.addresse.strasse + ', ' : ''}${objekt.addresse.plz ? objekt.addresse.plz + ' ' : ''}${objekt.addresse.land ? objekt.addresse.land : ''}`,
          value: objekt.id,
        }));
      this.$objekte.next(objekte);
    });
    this.gutachtens.result$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe(async (result) => {
      if (!result?.gutachten) return;
      this.$coordinateArray.next(
        result?.gutachten
          .filter(({ objekt, gutachten }) => !!objekt?.addresse && !!gutachten)
          .map(({ objekt, gutachten }) => ({
            lat: objekt.addresse.latitude as number,
            lon: objekt.addresse.longitude as number,
            object: gutachten,
            color: this.getColorByFortschritt(gutachten?.fortschritt),
          })) || [],
      );
    });
    combineLatest([this.gutachtens.response$, this.$gutachten.pipe(distinctUntilChanged())])
      .pipe(takeUntilDestroyed(this.destroyedRef))
      .subscribe(async ([gutachten, id]) => {
        if (!id) {
          this.$postGutachten.next(null);
          this.$patchGutachten.next(undefined);
          this.$deleteGutachten.next(null);
          this.$besichtigung.next(undefined);
          this.$coordinates.next(undefined);
          this.$row.next(undefined);
          this.eintraege = [];
          this.dokumente = [];
          this.valid.emit(true);
          this.$fortschritt.next(0);
        } else if (id === 'new') {
          this.$postGutachten.next({
            gutachten: {
              objekt: undefined,
              projekt: this.project?.id,
              aktiv: true,
              bewertungsStatus: (await this.postGutachten.gutachtenBewertungsStatus.pipe(first()).toPromise())?.[0]?.value,
              abgabeFinal: addDays(new Date(), this.konstanten.gutachten_dauer),
            },
          } as IPostGutachtenRequest);
          this.$patchGutachten.next(undefined);
          this.$deleteGutachten.next(undefined);
          this.$besichtigung.next(undefined);
          this.$coordinates.next(undefined);
          this.$row.next(undefined);
          this.eintraege = [];
          this.dokumente = [];
          this.valid.emit(true);
          this.$fortschritt.next(0);
        } else {
          const row = await this.gutachten.request({ gutachten: id });
          this.$postGutachten.next({ gutachten: row?.gutachten });
          this.$deleteGutachten.next({ id: row?.gutachten?.id });
          this.$coordinates.next({
            lat: row?.objekt?.addresse?.latitude as number,
            lon: row?.objekt?.addresse?.longitude as number,
          });
          this.$besichtigung.next(row?.besichtigung);
          this.$row.next(row);
          this.gutachtenSelected.emit(row);
          this.eintraege = this.eintraege.filter(({ gutachten }) => gutachten === id);
          this.dokumente = this.dokumente.filter(({ gutachten }) => gutachten === id);
          this.valid.emit(this.dokumente.length + this.eintraege.length === 0);
          this.$fortschritt.next(row.gutachten.fortschritt);
        }
      });
  }

  public async setGutachten(gutachten?: string) {
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { gutachten },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  public async setObjekte(id: string) {}

  public async setProjekt(id: string) {}

  async finishedPostDurchgang(response?: IPostDurchgangResponse) {}

  async finishedPostGutachten(response?: IPostGutachtenResponse) {
    this.setGutachten(response?.gutachten?.id);
  }

  async finishedDeleteGutachten(response?: IDeleteGutachtenResponse) {
    this.setGutachten(undefined);
  }

  async finishedPostObjekt(response?: IPostObjektResponse) {}

  async finishedDeleteObjekt(response?: IDeleteObjektResponse) {
    await this.setObjektForGutachten(undefined);
  }

  public async navigateToObjekte(queryParams: Params = {}) {
    await this.router.navigate(this._objekte.url, { queryParams });
  }

  public async navigateToUmkreissuche(queryParams: Params = {}) {
    await this.router.navigate(this._umkreissuche.url, { queryParams });
  }

  public async navigateToDurchgang(queryParams: Params = {}) {
    await this.router.navigate(this._durchgang.url, { queryParams });
  }

  public async navigateToTour(id: string | undefined) {
    if (!id) return;
    await this.router.navigate(['besichtigungen', 'tour'], { queryParams: { id } });
  }

  async close() {
    if (this.eintraege.length || this.dokumente.length) {
      await this.modal.confirm({
        nzTitle: 'Es sind nicht gespeicherte Bewertungen/Dokumente im Speicher. Wenn das Gutachten geschlossen wird, gehen diese verloren.',
        nzOkText: 'Bleiben',
        nzOnOk: async () =>
          await this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: { detail: this.eintraege.length ? 'eintraege' : 'dokumenten-anforderungen' },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          }),
        nzCancelText: 'Trotzdem schließen',
        nzOnCancel: () => this.setGutachten(undefined),
      });
    } else this.setGutachten(undefined);
  }

  async createGutachten() {
    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { gutachten: 'new' },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  openObjekte(id?) {
    let objekt: IObjekt | undefined;
    if (id) objekt = this.objekte.response$.getValue()?.objekte.find((o) => o.objekt.id === id)?.objekt;
    this.modal
      .create({
        nzTitle: 'Objekt auswählen oder neues hinzufügen',
        nzContent: ObjekteComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzData: objekt,
        nzFooter: [],
      })
      .afterClose.pipe(first())
      .subscribe(async (objekt) => await this.setObjektForGutachten(objekt));
  }

  async setObjektForGutachten(objekt?: IObjekt) {
    this.$patchGutachten.next({ objekt: objekt?.id });
    if (objekt?.addresse?.latitude && objekt?.addresse?.longitude)
      this.$coordinates.next({
        lat: objekt?.addresse?.latitude as number,
        lon: objekt?.addresse?.longitude as number,
      });
    else this.$coordinates.next(undefined);
  }

  sicherheitscheck(id: string) {
    const objekte = this.objekte.response$.getValue();
    const objekt = (objekte?.objekte || []).find((o) => o.objekt.id === id)?.objekt;
    if (!objekt) return;
    const request: IUmkreissucheRequest = {
      latitude: objekt.addresse.latitude?.toString() || '0',
      longitude: objekt.addresse.longitude?.toString() || '0',
      distance: 10,
      inklusiveInaktive: true,
      excludeGutachten: this.$postGutachten.getValue()?.gutachten.id,
    };
    this.modal
      .create({
        nzTitle: 'Sicherheitscheck - Mögliche Projekt-Konflikte im Umkreis prüfen',
        nzContent: SicherheitscheckComponent,
        nzData: request,
        nzViewContainerRef: this.viewContainerRef,
        nzOnCancel: () => this.setSicherheitsCheck(false),
        nzFooter: [],
      })
      .afterClose.pipe(first())
      .subscribe((checked) => this.setSicherheitsCheck(checked));
  }

  setSicherheitsCheck(checked: boolean) {
    let sicherheitscheck = undefined;
    if (checked) sicherheitscheck = this.auth.$displayName.getValue() + ' am ' + moment(new Date()).format('DD.MM.yy');
    this.$patchGutachten.next({ sicherheitscheck });
  }

  getColorByFortschritt(fortschritt: number): string {
    if (!fortschritt || fortschritt === 0) {
      return 'red';
    } else if (fortschritt !== 100) {
      return 'orange';
    } else {
      return 'green';
    }
  }
  /*    methods >> */

  public async submitPostDurchgang(request?: IPostDurchgangRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postDurchgang.getValue();
    if (payload) {
      try {
        const response = await this.postDurchgang.request(payload);
        await this.finishedPostDurchgang(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitPostGutachten(request?: IPostGutachtenRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postGutachten.getValue();
    if (payload) {
      try {
        const response = await this.postGutachten.request(payload);
        await this.finishedPostGutachten(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitDeleteGutachten(request?: IDeleteGutachtenRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$deleteGutachten.getValue();
    if (payload) {
      try {
        const response = await this.deleteGutachten.request(payload);
        await this.finishedDeleteGutachten(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitPostObjekt(request?: IPostObjektRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$postObjekt.getValue();
    if (payload) {
      try {
        const response = await this.postObjekt.request(payload);
        await this.finishedPostObjekt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  public async submitDeleteObjekt(request?: IDeleteObjektRequest) {
    this.loading$.next(true);
    const payload = request ? request : this.$deleteObjekt.getValue();
    if (payload) {
      try {
        const response = await this.deleteObjekt.request(payload);
        await this.finishedDeleteObjekt(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.loading$.next(false);
  }

  openAddress() {
    const objekt = this.objekte.result$.getValue()?.objekte.find((r) => r.objekt.id === this.$postGutachten.getValue()?.gutachten?.objekt)?.objekt;
    const url = `https://www.google.com/maps/place/${objekt?.addresse?.latitude},${objekt?.addresse?.longitude}`;
    window.open(url, '_blank')?.focus();
  }

  setFortschritt(gespeicherte: IErforderlichesDokument[] = []) {
    const row = this.$row.getValue();
    if (!row) return this.$fortschritt.next(0);
    if (!gespeicherte?.length) return this.$fortschritt.next(row.gutachten?.fortschritt);
    const dokumente = row.erforderlicheDokumente.filter(({ erforderlich }) => erforderlich).map((d) => gespeicherte.find((s) => s.id === d.id) || d);
    const fortschritt = dokumente.reduce((n, { fortschritt }) => n + (!fortschritt || isNaN(fortschritt) ? 0 : fortschritt), 0);
    this.$fortschritt.next(fortschritt && !isNaN(fortschritt) ? fortschritt / dokumente.length : 0);
  }
}
