import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IBewertungsAnlasssResponse } from 'fa-kt.types';
import { PostBewertungsAnlassCommandService } from '../commands';
import { BewertungsAnlasssService } from '../query';

@Injectable({
  providedIn: 'root',
})
export class BewertungsAnlasssPageResolver implements Resolve<IBewertungsAnlasssResponse | void> {
  constructor(
    private loading: ResolverLoadingService,
    private authService: AuthService,
    private bewertungsAnlasss: BewertungsAnlasssService,
    private postBewertungsAnlass: PostBewertungsAnlassCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IBewertungsAnlasssResponse | void> {
    this.loading.resolving();
    const log = (error: any) => console.error(error);
    const [bewertungsAnlasss] = await Promise.all([this.bewertungsAnlasss.request({}).catch(log), this.postBewertungsAnlass.prepare(true).catch(log)]);
    this.loading.resolved();
    return bewertungsAnlasss;
  }
}
