import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, combineLatest, skip, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostZeitRequest, IPostZeitResponse, IZeit } from 'fa-kt.types';
import { NzValidateStatus } from 'ng-zorro-antd/core/types';
import { ProjekteSelectService } from '../../../../projekte';
import { PostZeitCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-zeit',
  templateUrl: './post-zeit.component.html',
  styleUrls: ['./post-zeit.component.css'],
})
export class PostZeitCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostZeitRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostZeitRequest> = new EventEmitter<IPostZeitRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostZeitResponse> = new EventEmitter<IPostZeitResponse>();

  suggestedProjekt$ = new BehaviorSubject<NzValidateStatus | undefined>(undefined);
  suggestedKunde$ = new BehaviorSubject<NzValidateStatus | undefined>(undefined);
  $projekte = new BehaviorSubject<ISelection[]>([]);

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postZeit: PostZeitCommandService,
    public projekte: ProjekteSelectService,
  ) {
    this.command = sitemap.ZEITEN.Commands.POST_ZEIT;
    this.form = this.fb.group({
      zeit: this.fb.group({
        id: [null, []],
        mitarbeiter: [null, [Validators.required]],
        projekt: [null, []],
        gutachten: [[], []],
        kunde: [null, []],
        zeitArt: [null, [Validators.required]],
        zeitUnterart: [null, []],
        datum: [null, [Validators.required]],
        ende: [null, [Validators.required]],
        stunden: [null, []],
        minuten: [null, []],
        kommentar: [null, []],
        kosten: [null, []],
      }),
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postZeit.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      combineLatest([this.projekte.selection$, this.zeitMitarbeiter?.valueChanges]).subscribe(([selection, mitarbeiter]) => {
        if (!selection) return;
        this.$projekte.next(selection.filter((s) => !mitarbeiter || s.mitarbeiter?.includes(mitarbeiter)));
      }),
      this.$projekte.pipe(skip(1)).subscribe((projekte) => {
        const projekt = this.zeitProjekt?.value;
        if (projekt && !projekte.map(({ value }) => value).includes(projekt)) this.zeitProjekt.patchValue(undefined);
      }),
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostZeitRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.zeitId?.valueChanges.subscribe(async (id) => {
        /* << on-zeit-id-change    */
        /*    on-zeit-id-change >> */
      }) as Subscription,
      this.zeitMitarbeiter?.valueChanges.subscribe(async (mitarbeiter) => {
        /* << on-zeit-mitarbeiter-change    */
        /*    on-zeit-mitarbeiter-change >> */
      }) as Subscription,
      this.zeitProjekt?.valueChanges.subscribe(async (projekt) => {
        this.suggestedProjekt$.next(undefined);
        const entry = this.$projekte.getValue()?.find((p) => p.value === projekt);
        if (entry?.filter) this.zeitKunde?.patchValue(entry?.filter);
        /*    on-zeit-projekt-change >> */
      }) as Subscription,
      this.zeitGutachten?.valueChanges.subscribe(async (gutachten) => {
        /* << on-zeit-gutachten-change    */
        /*    on-zeit-gutachten-change >> */
      }) as Subscription,
      this.zeitKunde?.valueChanges.subscribe(async (kunde) => {
        this.suggestedKunde$.next(undefined);
        /* << on-zeit-kunde-change    */
        /*    on-zeit-kunde-change >> */
      }) as Subscription,
      this.zeitZeitArt?.valueChanges.subscribe(async (zeitArt) => {
        /* << on-zeit-zeit-art-change    */
        /*    on-zeit-zeit-art-change >> */
      }) as Subscription,
      this.zeitZeitUnterart?.valueChanges.subscribe(async (zeitUnterart) => {
        /* << on-zeit-zeit-unterart-change    */
        /*    on-zeit-zeit-unterart-change >> */
      }) as Subscription,
      this.zeitDatum?.valueChanges.subscribe(async (datum) => {
        /* << on-zeit-datum-change    */
        const stunden = this.zeitStunden?.value;
        const minuten = this.zeitMinuten?.value;
        if (!stunden && !minuten) return;
        this.zeitEnde?.patchValue(moment(new Date(datum)).add(stunden, 'hours').add(minuten, 'minutes').toDate());
        /*    on-zeit-datum-change >> */
      }) as Subscription,
      this.zeitStunden?.valueChanges.subscribe(async (stunden) => {
        /* << on-zeit-stunden-change    */
        this.zeitEnde?.patchValue(
          moment(new Date(this.zeitDatum?.value))
            .add(this.zeitStunden?.value, 'hours')
            .add(this.zeitMinuten?.value, 'minutes')
            .toDate(),
        );
        /*    on-zeit-stunden-change >> */
      }) as Subscription,
      this.zeitMinuten?.valueChanges.subscribe(async (minuten) => {
        /* << on-zeit-minuten-change    */
        this.zeitEnde?.patchValue(
          moment(new Date(this.zeitDatum?.value))
            .add(this.zeitStunden?.value, 'hours')
            .add(this.zeitMinuten?.value, 'minutes')
            .toDate(),
        );
        /*    on-zeit-minuten-change >> */
      }) as Subscription,
      this.zeitEnde?.valueChanges.subscribe(async (ende) => {
        /* << on-zeit-minuten-change    */
        let start = this.zeitDatum?.value;
        if (!start || !ende) return;
        start = new Date(start);
        ende = new Date(ende);
        const hours = Math.floor(moment(ende).diff(start, 'hours'));
        if (hours !== this.zeitStunden?.value) this.zeitStunden?.patchValue(hours);
        const minutes = moment(ende).diff(start, 'minutes') % 60;
        if (minutes !== this.zeitMinuten?.value) this.zeitMinuten?.patchValue(minutes);
        /*    on-zeit-minuten-change >> */
      }) as Subscription,
      this.zeitKommentar?.valueChanges.subscribe(async (kommentar) => {
        /* << on-zeit-kommentar-change    */
        /*    on-zeit-kommentar-change >> */
      }) as Subscription,
      this.zeitKosten?.valueChanges.subscribe(async (kosten) => {
        /* << on-zeit-kosten-change    */
        /*    on-zeit-kosten-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    this.zeitMitarbeiter?.patchValue(this.zeitMitarbeiter?.value);
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostZeitRequest) {
    if (value) {
      if (!(value.zeit as IZeit).id) {
        if ((value.zeit as IZeit).projekt) this.suggestedProjekt$.next('warning');
        if ((value.zeit as IZeit).kunde) this.suggestedKunde$.next('warning');
        this.form.controls.zeit?.get('id').patchValue(undefined);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.zeit) {
        payload.zeit = cleanObject(payload.zeit);
      }
      const response: IPostZeitResponse = await this.postZeit.request(payload);
      this.finished.next(payload.zeit);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get zeitId() {
    return this.form.controls.zeit.get('id');
  }
  get zeitMitarbeiter() {
    return this.form.controls.zeit.get('mitarbeiter');
  }
  get zeitProjekt() {
    return this.form.controls.zeit.get('projekt');
  }
  get zeitGutachten() {
    return this.form.controls.zeit.get('gutachten');
  }
  get zeitKunde() {
    return this.form.controls.zeit.get('kunde');
  }
  get zeitZeitArt() {
    return this.form.controls.zeit.get('zeitArt');
  }
  get zeitZeitUnterart() {
    return this.form.controls.zeit.get('zeitUnterart');
  }
  get zeitDatum() {
    return this.form.controls.zeit.get('datum');
  }
  get zeitEnde() {
    return this.form.controls.zeit.get('ende');
  }
  get zeitStunden() {
    return this.form.controls.zeit.get('stunden');
  }
  get zeitMinuten() {
    return this.form.controls.zeit.get('minuten');
  }
  get zeitKommentar() {
    return this.form.controls.zeit.get('kommentar');
  }
  get zeitKosten() {
    return this.form.controls.zeit.get('kosten');
  }
}
