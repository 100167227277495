import { Injectable } from '@angular/core';

import { ListQueryService } from 'pbc.angular';

import { IBesichtigungenQueryFilter, IBesichtigungenQueryShapes, IBesichtigungenQuerySorter, IBesichtigungenRequest, IBesichtigungenResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class BesichtigungenService extends ListQueryService<IBesichtigungenRequest, IBesichtigungenResponse, IBesichtigungenQueryShapes, IBesichtigungenQueryFilter, IBesichtigungenQuerySorter> {
  static instance: BesichtigungenService;

  contentKey: keyof IBesichtigungenResponse = 'besichtigungen';
  route = 'besichtigungen/besichtigungen';
  override dependencies = [
    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'besichtigungen/written-tour-status',
    'besichtigungen/removed-tour-status',
    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
  ];

  constructor() {
    super();
    if (!BesichtigungenService.instance) BesichtigungenService.instance = this;
    return BesichtigungenService.instance;
  }
}
