import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IRolle } from 'fa-kt.types';
import { BehaviorSubject } from 'rxjs';
import { RollesService } from '../service';

@Component({
  selector: 'fa-kt-rolles-list',
  templateUrl: './rolles-list.component.html',
  styleUrls: ['./rolles-list.component.css'],
})
export class RollesListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IRolle> = new EventEmitter<IRolle>();

  constructor(public rolles: RollesService) {}

  ngOnDestroy() {}
}
