<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <button nz-button nzType="primary" (click)="create()" [nzLoading]="$loading | async" [disabled]="$loading | async">
      <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
      Neu
    </button>
  </nz-page-header-extra>
</nz-page-header>
<nz-content>
  <nz-row>
    <nz-col nzSpan="24">
      <pbc-search-bar [style.width]="'100%'" (searchChange)="templates.search$.next($event)" [search]="templates.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
      <fa-kt-templates-filter [style.width]="'100%'"></fa-kt-templates-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
      <fa-kt-templates-sorter [style.width]="'100%'"></fa-kt-templates-sorter>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col nzSpan="24">
      <fa-kt-templates-list [style.width]="'100%'" (select)="set($event.id)" [loading]="$loading | async"></fa-kt-templates-list>
    </nz-col>
  </nz-row>
</nz-content>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="reset()" [nzVisible]="$template | async">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> 📝 Bearbeitung </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb>
        <pbc-breadcrumbs [pages]="page.breadcrumbs" [extra]="model.emoji + ' ' + model.single"></pbc-breadcrumbs>
      </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-delete-template [value]="$template | async" [loading]="$loading | async" (finished)="reset()"></fa-kt-delete-template>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-post-template [value]="$template | async" [loading]="$loading | async" (finished)="reset()" [style.width]="'100%'"></fa-kt-post-template>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter> </ng-template>
</nz-modal>
