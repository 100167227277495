import { NgModule } from '@angular/core';

import {
  PostKundenArtCommandServiceModule,
  PostKundenArtCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostKundenArtCommandServiceModule,
    PostKundenArtCommandComponentModule
  ],
  exports: [
    PostKundenArtCommandServiceModule,
    PostKundenArtCommandComponentModule
  ]
})
export class PostKundenArtCommandModule { }
