import { Component } from '@angular/core';
import { ProfilService } from '../service';

@Component({
  selector: 'fa-kt-saved-profil-filters',
  templateUrl: './saved-profil-filters.component.html',
  styleUrls: ['./saved-profil-filters.component.css'],
})
export class SavedProfilFiltersComponent {
  constructor(public profil: ProfilService) {}
  /* << fields   */

  /*   fields >> */
  /* << methods   */

  /*   methods >> */
}
