import { IFilter, IFilterConfig } from "pbc.types";

import { IFeldKategorie } from '.';

export interface IFeldKategorieFilter extends IFilter<IFeldKategorie> {}

export const FeldKategorieFilterConfig: IFilterConfig<IFeldKategorie> = [];

export type IFeldKategorieField =
  'feldKategorie.id'|
  'feldKategorie.name'|
  'feldKategorie.initialen'|
  'feldKategorie.kommentar'
;
