<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="umkreissuche.search$.next($event)" [search]="umkreissuche.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-umkreissuche-filter [style.width]="'100%'"></fa-kt-umkreissuche-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-umkreissuche-sorter [style.width]="'100%'"></fa-kt-umkreissuche-sorter>
    </nz-col>
  </nz-row>
  
  <fa-kt-umkreissuche-list [style.width]="'100%'" (select)="setUmkreissuche($event)" [loading]="$loading | async"></fa-kt-umkreissuche-list>




  <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="projekt" type="dashed"></fa-kt-navigation>

-->
<!--- << custom    -->

<nz-row>
  <nz-col nzSpan="6"> <nz-input-number [nzStep]="10" [nzMin]="1" [nzMax]="100000" [(ngModel)]="distance" (ngModelChange)="changedDistance($event)"></nz-input-number> Meter </nz-col>
  <nz-col nzSpan="18">
    <pbc-search-bar [style.width]="'100%'" (searchChange)="umkreissuche.search$.next($event)" [search]="umkreissuche.search$ | async"></pbc-search-bar>
  </nz-col>
</nz-row>
<nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
  <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
    <fa-kt-umkreissuche-filter [style.width]="'100%'"></fa-kt-umkreissuche-filter>
  </nz-col>
  <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
    <fa-kt-umkreissuche-sorter [style.width]="'100%'"></fa-kt-umkreissuche-sorter>
  </nz-col>
</nz-row>
<nz-row>
  <nz-col nzSpan="24">
    <nz-list *ngIf="!!(umkreissuche.result$ | async)" nzSize="large" [nzLoading]="loading$ | async">
      <nz-list-item *ngFor="let entry of (umkreissuche.result$ | async)?.umkreissuche">
        <nz-list-item-meta [nzDescription]="ListDescription">
          <ng-template #ListDescription> </ng-template>
          <nz-list-item-meta-title>
            <a>
              {{ entry.projekt.nummer }} | {{ entry.projekt.bezeichnung }}
              <nz-divider nzType="vertical"></nz-divider>
              {{ entry.objekt.addresse.strasse }}, {{ entry.objekt.addresse.plz }}
              <nz-divider nzType="vertical"></nz-divider>
              {{ entry.gutachten.abgabeFinal | date: 'dd.MM.yyyy' }}
            </a>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a nz-button nzType="link" (click)="openProjekt(entry.projekt.id)"><span nz-icon nzType="arrow-right" nzTheme="outline"></span></a>
          </nz-list-item-action>
        </ul>
      </nz-list-item>
    </nz-list>
  </nz-col>

  <nz-col nzSpan="24" *ngIf="(coordinates$ | async)?.length"  style="padding-top: 32px; padding-bottom: 32px">
    <pbc-atlas-map
    [coordinateArray]="coordinates$ | async"
    height="300px"
    width="100%"
  ></pbc-atlas-map>
  </nz-col>
</nz-row>
<nz-row>
  <nz-col nzSpan="24">
    <button nz-button nzType="default" type="button" (click)="confirm()" [nzBlock]="true">
      Konfliktlosigkeit bestätigen <span style="color: green" nz-icon nzType="check-circle" nzTheme="outline"></span>
    </button>
  </nz-col>
</nz-row>
<!---    custom >> -->
