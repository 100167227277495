import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IProfilRequest, IProfilResponse } from 'fa-kt.types';
import { PostMitarbeiterCommandService } from '../../commands';
import { ProfilService } from '../../querys';

export interface IProfilPageResolverResult {
  profil?: IProfilResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class ProfilPageResolver implements Resolve<IProfilPageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private profil: ProfilService,
    private postMitarbeiter: PostMitarbeiterCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IProfilPageResolverResult> {
    this.loading.resolving();
    const requests = {
      profil: {} as IProfilRequest,
    };
    const id = route.queryParams['id'];
    requests.profil = { id };
    const log = (error: any) => console.error(error);
    const [profil] = await Promise.all([this.profil.request(requests.profil).catch(log), this.postMitarbeiter.prepare().catch(log)]);
    this.loading.resolved();
    return {
      profil,
    };
  }
}
