<!--

  <fa-kt-post-zahlungseingang [style.width]="'100%'" [value]="$postZahlungseingang | async" (finished)="finishedPostZahlungseingang($event)"></fa-kt-post-zahlungseingang>

  <fa-kt-delete-zahlungseingang [style.width]="'100%'" [value]="$deleteZahlungseingang | async" (finished)="finishedDeleteZahlungseingang($event)"></fa-kt-delete-zahlungseingang>



-->
<!--- << custom    -->
<nz-list *ngIf="zahlungseingaenge" nzSize="large" [nzLoading]="$loading | async">
  <nz-list-item *ngFor="let zahlungseingang of zahlungseingaenge" (click)="openZahlungseingang(zahlungseingang)">
    <nz-list-item-meta [nzDescription]="ListDescription">
      <ng-template #ListDescription> </ng-template>
      <nz-list-item-meta-title>
        <a>
          <span nz-typography nzType="secondary">{{ zahlungseingang.rechnung | type: (selection$ | async) }}</span>
          <nz-divider nzType="vertical"></nz-divider>
          {{ zahlungseingang.summe | currency: 'EUR' : 'symbol' : '' : 'de' : '' : 'de' }}
        </a>
      </nz-list-item-meta-title>
    </nz-list-item-meta>
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
  <nz-list-item>
    <button nz-button (click)="openZahlungseingang({})" nzType="dashed" [disabled]="disabled">
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Zahlungseingang hinzufügen
    </button>
  </nz-list-item>
</nz-list>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="openZahlungseingang(null)" [nzVisible]="!!($postZahlungseingang | async)">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> Zahlungseingang </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-delete-zahlungseingang [style.width]="'100%'" [value]="$deleteZahlungseingang | async" (finished)="finishedDeleteZahlungseingang($event)"></fa-kt-delete-zahlungseingang>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-post-zahlungseingang [projekt]="projekt" [style.width]="'100%'" [value]="$postZahlungseingang | async" (finished)="finishedPostZahlungseingang()"></fa-kt-post-zahlungseingang>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
    <pbc-meta [value]="($postZahlungseingang | async)?.zahlungseingang"></pbc-meta>
  </ng-template>
</nz-modal>
<!---    custom >> -->
