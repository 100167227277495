import { IFilter, IFilterConfig } from "pbc.types";

import { IZeile } from '.';

export interface IZeileFilter extends IFilter<IZeile> {}

export const ZeileFilterConfig: IFilterConfig<IZeile> = [];

export type IZeileField =
  'zeile.id'|
  'zeile.bericht'|
  'zeile.land'|
  'zeile.bundesland'|
  'zeile.plz'|
  'zeile.region'|
  'zeile.kreisStadt'|
  'zeile.gemeindeBezirk'|
  'zeile.ortsStadtTeil'|
  'zeile.gemeindeschluessel'|
  'zeile.teilmarkt'|
  'zeile.bereiche'|
  'zeile.assetklasse'|
  'zeile.objektart'|
  'zeile.zustand'|
  'zeile.vertrag'|
  'zeile.wohnlage'|
  'zeile.wflVon'|
  'zeile.wflBis'|
  'zeile.wflMittel'|
  'zeile.bjVon'|
  'zeile.bjBis'|
  'zeile.wohnNutzWert'|
  'zeile.ausstattung'|
  'zeile.artDesWertes'|
  'zeile.einheit'|
  'zeile.bezug'|
  'zeile.durchschnitt'|
  'zeile.minimum'|
  'zeile.maximum'|
  'zeile.spitzenwerte'|
  'zeile.anzahl'|
  'zeile.steigerung_1'|
  'zeile.steigerung_2'|
  'zeile.steigerung_3'|
  'zeile.steigerung_4'|
  'zeile.steigerung_5'|
  'zeile.steigerungArt'
;
