import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from 'pbc.types';

export interface IProjektQuerySorter extends ISorter {
  'projekt.id'?: ISorterExpression;
  'projekt.bezeichnung'?: ISorterExpression;
  'projekt.nummer'?: ISorterExpression;
  'projekt.projektZaehler'?: ISorterExpression;
  'projekt.projektArt'?: ISorterExpression;
  'projekt.projektStatus'?: ISorterExpression;
  'projekt.standort'?: ISorterExpression;
  'projekt.beauftragung'?: ISorterExpression;
  'projekt.deadline'?: ISorterExpression;
  'projekt.kundenAnfertigung'?: ISorterExpression;
  'projekt.keineBesichtigungErforderlich'?: ISorterExpression;
  'projekt.besichtigungen'?: ISorterExpression;
  'projekt.gutachten'?: ISorterExpression;
  'projekt.pruefung'?: ISorterExpression;
  'projekt.kunde'?: ISorterExpression;
  'projekt.abteilung'?: ISorterExpression;
  'projekt.fond'?: ISorterExpression;
  'projekt.bank'?: ISorterExpression;
  'projekt.objektArt'?: ISorterExpression;
  'projekt.nachlass'?: ISorterExpression;
  'projekt.kundenZeichen'?: ISorterExpression;
  'projekt.kontakteInOutlook'?: ISorterExpression;
  'projekt.dateiVerzeichnis'?: ISorterExpression;
  'projekt.kommentar'?: ISorterExpression;
  'projekt.notizenFuerRechnung'?: ISorterExpression;
  'projekt.fortschritt'?: ISorterExpression;
  // 'projekt.strecke'?: ISorterExpression;
  // 'projekt.kalkulierteStrecke'?: ISorterExpression;
  // 'projekt.fahrtzeit'?: ISorterExpression;
  // 'projekt.kalkulierteFahrtzeit'?: ISorterExpression;
  'projekt.stunden'?: ISorterExpression;
  'projekt.kostenNachStunden'?: ISorterExpression;
  'projekt.verhandeltesHonorar'?: ISorterExpression;
  'projekt.kosten'?: ISorterExpression;
  'projekt.kostenBeglichen'?: ISorterExpression;
  'projekt.aktiv'?: ISorterExpression;
  'projekt.darlehensnehmer'?: ISorterExpression;
  'projekt.notizenBesichtigung'?: ISorterExpression;
  'projekt.kreditsachbearbeiterVorname'?: ISorterExpression;
  'projekt.kreditsachbearbeiterName'?: ISorterExpression;
  'projekt.kreditsachbearbeiterTelefon'?: ISorterExpression;
  'projekt.kreditsachbearbeiterEMail'?: ISorterExpression;
  'standort.id'?: ISorterExpression;
  'standort.name'?: ISorterExpression;
  'standort.addresse'?: ISorterExpression;
  'standort.dateipfad'?: ISorterExpression;
  'kunde.id'?: ISorterExpression;
  'kunde.name'?: ISorterExpression;
  'kunde.initialen'?: ISorterExpression;
  'kunde.kundenGruppe'?: ISorterExpression;
  'kunde.kundenArt'?: ISorterExpression;
  'kunde.addresse'?: ISorterExpression;
  'kunde.website'?: ISorterExpression;
  'kunde.outlookKontakte'?: ISorterExpression;
  'kunde.kontaktNotizen'?: ISorterExpression;
  'kunde.anforderungenNotizen'?: ISorterExpression;
  'kunde.rechnungNotizen'?: ISorterExpression;
  'kunde.fahrtkostenAbrechnung'?: ISorterExpression;
  'kunde.steuerId'?: ISorterExpression;
  'kunde.fahrtkostenAbRadius'?: ISorterExpression;
  'kunde.fahrtkostenProKm'?: ISorterExpression;
  'kunde.fahrtkostenProStunde'?: ISorterExpression;
  'kunde.skonto'?: ISorterExpression;
  'kunde.zahlungsziel'?: ISorterExpression;
  'kunde.tageOhneSkonto'?: ISorterExpression;
  'kunde.aktiv'?: ISorterExpression;
  'kunde.dateipfad'?: ISorterExpression;
  'kunde.loraName'?: ISorterExpression;
  'kunde.unterlagenEmail'?: ISorterExpression;
  'abteilung.id'?: ISorterExpression;
  'abteilung.kunde'?: ISorterExpression;
  'abteilung.name'?: ISorterExpression;
  'abteilung.ansprache'?: ISorterExpression;
  'abteilung.outlookKontakte'?: ISorterExpression;
  'abteilung.website'?: ISorterExpression;
  'abteilung.adresse'?: ISorterExpression;
  'abteilung.aktiv'?: ISorterExpression;
  'fond.id'?: ISorterExpression;
  'fond.kunde'?: ISorterExpression;
  'fond.name'?: ISorterExpression;
  'fond.aktiv'?: ISorterExpression;
  'vermerke.id'?: ISorterExpression;
  'vermerke.projekt'?: ISorterExpression;
  'vermerke.gutachten'?: ISorterExpression;
  'vermerke.kunde'?: ISorterExpression;
  'vermerke.antwortAuf'?: ISorterExpression;
  'vermerke.verfasser'?: ISorterExpression;
  'vermerke.kommentar'?: ISorterExpression;
  'vermerke.externSichtbar'?: ISorterExpression;
  'erinnerungen.id'?: ISorterExpression;
  'erinnerungen.projekt'?: ISorterExpression;
  'erinnerungen.gutachten'?: ISorterExpression;
  'erinnerungen.kunde'?: ISorterExpression;
  'erinnerungen.kommentar'?: ISorterExpression;
  'erinnerungen.am'?: ISorterExpression;
  'erinnerungen.mitarbeiter'?: ISorterExpression;
  'erinnerungen.verfasser'?: ISorterExpression;
  'rechnungen.id'?: ISorterExpression;
  'rechnungen.projekt'?: ISorterExpression;
  'rechnungen.kunde'?: ISorterExpression;
  'rechnungen.bezeichnung'?: ISorterExpression;
  'rechnungen.rechnungZaehler'?: ISorterExpression;
  'rechnungen.datum'?: ISorterExpression;
  'rechnungen.honorarVereinbarung'?: ISorterExpression;
  'rechnungen.extraVereinbarungs'?: ISorterExpression;
  'rechnungen.stunden'?: ISorterExpression;
  'rechnungen.anschlaege'?: ISorterExpression;
  'rechnungen.seiten'?: ISorterExpression;
  'rechnungen.fotos'?: ISorterExpression;
  'rechnungen.strecke'?: ISorterExpression;
  'rechnungen.fahrtzeit'?: ISorterExpression;
  'rechnungen.besichtigungen'?: ISorterExpression;
  'rechnungen.mwstSatz'?: ISorterExpression;
  'rechnungen.nachlass'?: ISorterExpression;
  'rechnungen.summe'?: ISorterExpression;
  'rechnungen.datei'?: ISorterExpression;
  'zahlungseingaenge.id'?: ISorterExpression;
  'zahlungseingaenge.projekt'?: ISorterExpression;
  'zahlungseingaenge.kunde'?: ISorterExpression;
  'zahlungseingaenge.rechnung'?: ISorterExpression;
  'zahlungseingaenge.summe'?: ISorterExpression;
  'zahlungseingaenge.datum'?: ISorterExpression;
}

export const ProjektQuerySorterConfig: ISorterConfig = [
  SorterConfig('projekt.id', 'PROJEKTE.Models.PROJEKT.Propertys.ID.title'),
  SorterConfig('projekt.bezeichnung', 'PROJEKTE.Models.PROJEKT.Propertys.BEZEICHNUNG.title'),
  SorterConfig('projekt.nummer', 'PROJEKTE.Models.PROJEKT.Propertys.NUMMER.title'),
  SorterConfig('projekt.projektZaehler', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ZAEHLER.title'),
  SorterConfig('projekt.projektArt', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ART.title'),
  SorterConfig('projekt.projektStatus', 'PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_STATUS.title'),
  SorterConfig('projekt.standort', 'PROJEKTE.Models.PROJEKT.Propertys.STANDORT.title'),
  SorterConfig('projekt.beauftragung', 'PROJEKTE.Models.PROJEKT.Propertys.BEAUFTRAGUNG.title'),
  SorterConfig('projekt.deadline', 'PROJEKTE.Models.PROJEKT.Propertys.DEADLINE.title'),
  SorterConfig('projekt.kundenAnfertigung', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDEN_ANFERTIGUNG.title'),
  SorterConfig('projekt.keineBesichtigungErforderlich', 'PROJEKTE.Models.PROJEKT.Propertys.KEINE_BESICHTIGUNG_ERFORDERLICH.title'),
  SorterConfig('projekt.besichtigungen', 'PROJEKTE.Models.PROJEKT.Propertys.BESICHTIGUNGEN.title'),
  SorterConfig('projekt.gutachten', 'PROJEKTE.Models.PROJEKT.Propertys.GUTACHTEN.title'),
  SorterConfig('projekt.pruefung', 'PROJEKTE.Models.PROJEKT.Propertys.PRUEFUNG.title'),
  SorterConfig('projekt.kunde', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDE.title'),
  SorterConfig('projekt.abteilung', 'PROJEKTE.Models.PROJEKT.Propertys.ABTEILUNG.title'),
  SorterConfig('projekt.fond', 'PROJEKTE.Models.PROJEKT.Propertys.FOND.title'),
  SorterConfig('projekt.bank', 'PROJEKTE.Models.PROJEKT.Propertys.BANK.title'),
  SorterConfig('projekt.objektArt', 'PROJEKTE.Models.PROJEKT.Propertys.OBJEKT_ART.title'),
  SorterConfig('projekt.nachlass', 'PROJEKTE.Models.PROJEKT.Propertys.NACHLASS.title'),
  SorterConfig('projekt.kundenZeichen', 'PROJEKTE.Models.PROJEKT.Propertys.KUNDEN_ZEICHEN.title'),
  SorterConfig('projekt.kontakteInOutlook', 'PROJEKTE.Models.PROJEKT.Propertys.KONTAKTE_IN_OUTLOOK.title'),
  SorterConfig('projekt.dateiVerzeichnis', 'PROJEKTE.Models.PROJEKT.Propertys.DATEI_VERZEICHNIS.title'),
  SorterConfig('projekt.kommentar', 'PROJEKTE.Models.PROJEKT.Propertys.KOMMENTAR.title'),
  SorterConfig('projekt.notizenFuerRechnung', 'PROJEKTE.Models.PROJEKT.Propertys.NOTIZEN_FUER_RECHNUNG.title'),
  SorterConfig('projekt.fortschritt', 'PROJEKTE.Models.PROJEKT.Propertys.FORTSCHRITT.title'),
  // SorterConfig('projekt.strecke', 'PROJEKTE.Models.PROJEKT.Propertys.STRECKE.title'),
  // SorterConfig('projekt.kalkulierteStrecke', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_STRECKE.title'),
  // SorterConfig('projekt.fahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.FAHRTZEIT.title'),
  // SorterConfig('projekt.kalkulierteFahrtzeit', 'PROJEKTE.Models.PROJEKT.Propertys.KALKULIERTE_FAHRTZEIT.title'),
  SorterConfig('projekt.stunden', 'PROJEKTE.Models.PROJEKT.Propertys.STUNDEN.title'),
  SorterConfig('projekt.kostenNachStunden', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_NACH_STUNDEN.title'),
  SorterConfig('projekt.verhandeltesHonorar', 'PROJEKTE.Models.PROJEKT.Propertys.VERHANDELTES_HONORAR.title'),
  SorterConfig('projekt.kosten', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN.title'),
  SorterConfig('projekt.kostenBeglichen', 'PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_BEGLICHEN.title'),
  SorterConfig('projekt.aktiv', 'PROJEKTE.Models.PROJEKT.Propertys.AKTIV.title'),
  SorterConfig('projekt.darlehensnehmer', 'PROJEKTE.Models.PROJEKT.Propertys.DARLEHENSNEHMER.title'),
  SorterConfig('projekt.notizenBesichtigung', 'PROJEKTE.Models.PROJEKT.Propertys.NOTIZEN_BESICHTIGUNG.title'),
  SorterConfig('projekt.kreditsachbearbeiterVorname', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_VORNAME.title'),
  SorterConfig('projekt.kreditsachbearbeiterName', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_NAME.title'),
  SorterConfig('projekt.kreditsachbearbeiterTelefon', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_TELEFON.title'),
  SorterConfig('projekt.kreditsachbearbeiterEMail', 'PROJEKTE.Models.PROJEKT.Propertys.KREDITSACHBEARBEITER_E_MAIL.title'),
  SorterConfig('standort.id', 'PERSONEN.Models.STANDORT.Propertys.ID.title'),
  SorterConfig('standort.name', 'PERSONEN.Models.STANDORT.Propertys.NAME.title'),
  SorterConfig('standort.addresse', 'PERSONEN.Models.STANDORT.Propertys.ADDRESSE.title'),
  SorterConfig('standort.dateipfad', 'PERSONEN.Models.STANDORT.Propertys.DATEIPFAD.title'),
  SorterConfig('kunde.id', 'KUNDEN.Models.KUNDE.Propertys.ID.title'),
  SorterConfig('kunde.name', 'KUNDEN.Models.KUNDE.Propertys.NAME.title'),
  SorterConfig('kunde.initialen', 'KUNDEN.Models.KUNDE.Propertys.INITIALEN.title'),
  SorterConfig('kunde.kundenGruppe', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_GRUPPE.title'),
  SorterConfig('kunde.kundenArt', 'KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.title'),
  SorterConfig('kunde.addresse', 'KUNDEN.Models.KUNDE.Propertys.ADDRESSE.title'),
  SorterConfig('kunde.website', 'KUNDEN.Models.KUNDE.Propertys.WEBSITE.title'),
  SorterConfig('kunde.outlookKontakte', 'KUNDEN.Models.KUNDE.Propertys.OUTLOOK_KONTAKTE.title'),
  SorterConfig('kunde.kontaktNotizen', 'KUNDEN.Models.KUNDE.Propertys.KONTAKT_NOTIZEN.title'),
  SorterConfig('kunde.anforderungenNotizen', 'KUNDEN.Models.KUNDE.Propertys.ANFORDERUNGEN_NOTIZEN.title'),
  SorterConfig('kunde.rechnungNotizen', 'KUNDEN.Models.KUNDE.Propertys.RECHNUNG_NOTIZEN.title'),
  SorterConfig('kunde.fahrtkostenAbrechnung', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_ABRECHNUNG.title'),
  SorterConfig('kunde.steuerId', 'KUNDEN.Models.KUNDE.Propertys.STEUER_ID.title'),
  SorterConfig('kunde.fahrtkostenAbRadius', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.title'),
  SorterConfig('kunde.fahrtkostenProKm', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_KM.title'),
  SorterConfig('kunde.fahrtkostenProStunde', 'KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.title'),
  SorterConfig('kunde.skonto', 'KUNDEN.Models.KUNDE.Propertys.SKONTO.title'),
  SorterConfig('kunde.zahlungsziel', 'KUNDEN.Models.KUNDE.Propertys.ZAHLUNGSZIEL.title'),
  SorterConfig('kunde.tageOhneSkonto', 'KUNDEN.Models.KUNDE.Propertys.TAGE_OHNE_SKONTO.title'),
  SorterConfig('kunde.aktiv', 'KUNDEN.Models.KUNDE.Propertys.AKTIV.title'),
  SorterConfig('kunde.dateipfad', 'KUNDEN.Models.KUNDE.Propertys.DATEIPFAD.title'),
  SorterConfig('kunde.loraName', 'KUNDEN.Models.KUNDE.Propertys.LORA_NAME.title'),
  SorterConfig('kunde.unterlagenEmail', 'KUNDEN.Models.KUNDE.Propertys.UNTERLAGEN_EMAIL.title'),
  SorterConfig('abteilung.id', 'KUNDEN.Models.ABTEILUNG.Propertys.ID.title'),
  SorterConfig('abteilung.kunde', 'KUNDEN.Models.ABTEILUNG.Propertys.KUNDE.title'),
  SorterConfig('abteilung.name', 'KUNDEN.Models.ABTEILUNG.Propertys.NAME.title'),
  SorterConfig('abteilung.ansprache', 'KUNDEN.Models.ABTEILUNG.Propertys.ANSPRACHE.title'),
  SorterConfig('abteilung.outlookKontakte', 'KUNDEN.Models.ABTEILUNG.Propertys.OUTLOOK_KONTAKTE.title'),
  SorterConfig('abteilung.website', 'KUNDEN.Models.ABTEILUNG.Propertys.WEBSITE.title'),
  SorterConfig('abteilung.adresse', 'KUNDEN.Models.ABTEILUNG.Propertys.ADRESSE.title'),
  SorterConfig('abteilung.aktiv', 'KUNDEN.Models.ABTEILUNG.Propertys.AKTIV.title'),
  SorterConfig('fond.id', 'KUNDEN.Models.FOND.Propertys.ID.title'),
  SorterConfig('fond.kunde', 'KUNDEN.Models.FOND.Propertys.KUNDE.title'),
  SorterConfig('fond.name', 'KUNDEN.Models.FOND.Propertys.NAME.title'),
  SorterConfig('fond.aktiv', 'KUNDEN.Models.FOND.Propertys.AKTIV.title'),
  SorterConfig('vermerke.id', 'KOMMUNIKATION.Models.VERMERK.Propertys.ID.title'),
  SorterConfig('vermerke.projekt', 'KOMMUNIKATION.Models.VERMERK.Propertys.PROJEKT.title'),
  SorterConfig('vermerke.gutachten', 'KOMMUNIKATION.Models.VERMERK.Propertys.GUTACHTEN.title'),
  SorterConfig('vermerke.kunde', 'KOMMUNIKATION.Models.VERMERK.Propertys.KUNDE.title'),
  SorterConfig('vermerke.antwortAuf', 'KOMMUNIKATION.Models.VERMERK.Propertys.ANTWORT_AUF.title'),
  SorterConfig('vermerke.verfasser', 'KOMMUNIKATION.Models.VERMERK.Propertys.VERFASSER.title'),
  SorterConfig('vermerke.kommentar', 'KOMMUNIKATION.Models.VERMERK.Propertys.KOMMENTAR.title'),
  SorterConfig('vermerke.externSichtbar', 'KOMMUNIKATION.Models.VERMERK.Propertys.EXTERN_SICHTBAR.title'),
  SorterConfig('erinnerungen.id', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.ID.title'),
  SorterConfig('erinnerungen.projekt', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.PROJEKT.title'),
  SorterConfig('erinnerungen.gutachten', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.GUTACHTEN.title'),
  SorterConfig('erinnerungen.kunde', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.KUNDE.title'),
  SorterConfig('erinnerungen.kommentar', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.KOMMENTAR.title'),
  SorterConfig('erinnerungen.am', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.AM.title'),
  SorterConfig('erinnerungen.mitarbeiter', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.MITARBEITER.title'),
  SorterConfig('erinnerungen.verfasser', 'KOMMUNIKATION.Models.ERINNERUNG.Propertys.VERFASSER.title'),
  SorterConfig('rechnungen.id', 'FINANZEN.Models.RECHNUNG.Propertys.ID.title'),
  SorterConfig('rechnungen.projekt', 'FINANZEN.Models.RECHNUNG.Propertys.PROJEKT.title'),
  SorterConfig('rechnungen.kunde', 'FINANZEN.Models.RECHNUNG.Propertys.KUNDE.title'),
  SorterConfig('rechnungen.bezeichnung', 'FINANZEN.Models.RECHNUNG.Propertys.BEZEICHNUNG.title'),
  SorterConfig('rechnungen.rechnungZaehler', 'FINANZEN.Models.RECHNUNG.Propertys.RECHNUNG_ZAEHLER.title'),
  SorterConfig('rechnungen.datum', 'FINANZEN.Models.RECHNUNG.Propertys.DATUM.title'),
  SorterConfig('rechnungen.honorarVereinbarung', 'FINANZEN.Models.RECHNUNG.Propertys.HONORAR_VEREINBARUNG.title'),
  SorterConfig('rechnungen.extraVereinbarungs', 'FINANZEN.Models.RECHNUNG.Propertys.EXTRA_VEREINBARUNGS.title'),
  SorterConfig('rechnungen.stunden', 'FINANZEN.Models.RECHNUNG.Propertys.STUNDEN.title'),
  SorterConfig('rechnungen.anschlaege', 'FINANZEN.Models.RECHNUNG.Propertys.ANSCHLAEGE.title'),
  SorterConfig('rechnungen.seiten', 'FINANZEN.Models.RECHNUNG.Propertys.SEITEN.title'),
  SorterConfig('rechnungen.fotos', 'FINANZEN.Models.RECHNUNG.Propertys.FOTOS.title'),
  SorterConfig('rechnungen.strecke', 'FINANZEN.Models.RECHNUNG.Propertys.STRECKE.title'),
  SorterConfig('rechnungen.fahrtzeit', 'FINANZEN.Models.RECHNUNG.Propertys.FAHRTZEIT.title'),
  SorterConfig('rechnungen.besichtigungen', 'FINANZEN.Models.RECHNUNG.Propertys.BESICHTIGUNGEN.title'),
  SorterConfig('rechnungen.mwstSatz', 'FINANZEN.Models.RECHNUNG.Propertys.MWST_SATZ.title'),
  SorterConfig('rechnungen.nachlass', 'FINANZEN.Models.RECHNUNG.Propertys.NACHLASS.title'),
  SorterConfig('rechnungen.summe', 'FINANZEN.Models.RECHNUNG.Propertys.SUMME.title'),
  SorterConfig('rechnungen.datei', 'FINANZEN.Models.RECHNUNG.Propertys.DATEI.title'),
  SorterConfig('zahlungseingaenge.id', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.ID.title'),
  SorterConfig('zahlungseingaenge.projekt', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.PROJEKT.title'),
  SorterConfig('zahlungseingaenge.kunde', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.KUNDE.title'),
  SorterConfig('zahlungseingaenge.rechnung', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.RECHNUNG.title'),
  SorterConfig('zahlungseingaenge.summe', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.SUMME.title'),
  SorterConfig('zahlungseingaenge.datum', 'FINANZEN.Models.ZAHLUNGSEINGANG.Propertys.DATUM.title'),
];
