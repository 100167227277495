import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteZeitUnterartCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteZeitUnterartCommandService],
  exports: []
})
export class DeleteZeitUnterartCommandServiceModule { }
