import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostBerichtsVorlageCommandServiceModule } from '..';
import { PostBerichtsVorlageCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBerichtsVorlageCommandServiceModule
  ],
  declarations: [PostBerichtsVorlageCommandComponent],
  exports: [PostBerichtsVorlageCommandComponent],

})
export class PostBerichtsVorlageCommandComponentModule { }
