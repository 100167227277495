import { Injectable } from '@angular/core';

import { IPostFeldKategorieCommandShapes, IPostFeldKategorieRequest, IPostFeldKategorieResponse } from 'fa-kt.types';
import { PostCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class PostFeldKategorieCommandService extends PostCommandService<IPostFeldKategorieRequest, IPostFeldKategorieResponse, IPostFeldKategorieCommandShapes> {
  readonly route = 'felder/settings/feld-kategorie';
}
