import { Injectable } from '@angular/core';

import { IDeleteFondRequest, IDeleteFondResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteFondCommandService extends DeleteCommandService<IDeleteFondRequest, IDeleteFondResponse> {
  readonly route = 'kunden/fond';
}
