import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { PostTagCommandService } from '.';

@NgModule({
  imports: [LIB_CommonModule],
  declarations: [],
  providers: [PostTagCommandService],
  exports: [],
})
export class TagCommandComponentModule {}
