import { NgModule } from '@angular/core';

import {
  DeleteFeldOptionCommandServiceModule,
  DeleteFeldOptionCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteFeldOptionCommandServiceModule,
    DeleteFeldOptionCommandComponentModule
  ],
  exports: [
    DeleteFeldOptionCommandServiceModule,
    DeleteFeldOptionCommandComponentModule
  ]
})
export class DeleteFeldOptionCommandModule { }
