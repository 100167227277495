import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IKundeRequest, IKundeResponse } from 'fa-kt.types';
import { DeleteKundeCommandService, PostKundeCommandService, PostKundenFinanzenCommandService } from '../../commands';
import { KundeService } from '../../querys';

export interface IKundePageResolverResult {
  kunde?: IKundeResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class KundePageResolver implements Resolve<IKundePageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private kunde: KundeService,
    private postKunde: PostKundeCommandService,
    private deleteKunde: DeleteKundeCommandService,
    private postKundenFinanzen: PostKundenFinanzenCommandService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IKundePageResolverResult> {
    this.loading.resolving();
    const requests = {
      kunde: {} as IKundeRequest,
    };
    /* << custom    */
    requests.kunde.id = route.queryParams['id'];
    const log = (error: any) => console.error(error);
    if (!requests.kunde.id || requests.kunde.id === 'new') {
      this.kunde.payload$.next(undefined);
      this.kunde.result$.next(undefined);
      await this.postKunde.prepare().catch(log);
      this.loading.resolved();
      return {
        kunde: {
          kunde: {
            fahrtkostenAbrechnung: 'keine',
          },
        } as IKundeResponse,
      };
    }
    /*    custom >> */
    const [kunde] = await Promise.all([this.kunde.request(requests.kunde).catch(log), this.postKunde.prepare().catch(log)]);
    this.loading.resolved();
    return {
      kunde,
    };
  }
}
