import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { ActionService, APP_TITLE, AuthService, FileService, ISitemap, ISitemapPage, SITEMAP } from 'pbc.angular';

import { IPostMitarbeiterRequest, IPostMitarbeiterResponse } from 'fa-kt.types';
import { IProfilPageResolverResult } from '.';
import { PostMitarbeiterCommandService, ProfilService } from '../../..';

@Component({
  selector: 'fa-kt-profil-page',
  templateUrl: './profil.page.html',
  styleUrls: ['./profil.page.css'],
})
export class ProfilPage implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public readonly page: ISitemapPage;

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postMitarbeiter: BehaviorSubject<IPostMitarbeiterRequest | null> = new BehaviorSubject<IPostMitarbeiterRequest | null>(null);

  public _mitarbeiter: ISitemapPage;

  $id = new BehaviorSubject<string>('');
  $readonly = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_TITLE) private title: string,
    private titleRef: Title,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public files: FileService,
    public profil: ProfilService,
    public postMitarbeiter: PostMitarbeiterCommandService,
  ) {
    this.page = this.sitemap.PERSONEN.Pages.PROFIL;
    this._mitarbeiter = sitemap.PERSONEN.Pages.MITARBEITER;
  }

  async ngOnInit() {
    this.titleRef.setTitle(this.title + ' - ' + this.page.emoji + ' ' + this.page.title);
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe((params: Params) => {
          if (!params['id'] && !this.$postMitarbeiter.getValue()) {
            this.navigateToMitarbeiter();
          }
        }),
        this.route.data.subscribe(async (data: Data) => {
          const querys: IProfilPageResolverResult = data.querys;
          /* << resolver    */
          if (querys.profil) {
            this.$postMitarbeiter.next(querys.profil);
          }
          /*    resolver >> */
        }),
        /* << subscriptions    */
        this.$postMitarbeiter.subscribe((post) => this.$id.next(post && post.mitarbeiter ? post.mitarbeiter.id : '')),
        combineLatest([this.$id, this.auth.$id]).subscribe(([id, me]) => this.$readonly.next(!this.auth.$isAdmin.getValue())), //  && !this.auth.access('personen/profil')
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  public async setProfil(id: string) {
    /* << set-profil    */
    /*    set-profil >> */
  }

  async finishedPostMitarbeiter(response?: IPostMitarbeiterResponse) {
    /* << after post-mitarbeiter    */
    this.navigateToMitarbeiter();
    /*    after post-mitarbeiter >> */
  }

  public async navigateToMitarbeiter(queryParams: Params = {}) {
    await this.router.navigate(this._mitarbeiter.url, { queryParams });
  }

  back() {
    window.history.back();
  }

  public async submitPostMitarbeiter(request?: IPostMitarbeiterRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postMitarbeiter.getValue();
    if (payload) {
      try {
        const response = await this.postMitarbeiter.request(payload);
        await this.finishedPostMitarbeiter(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
