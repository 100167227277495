import { Injectable } from '@angular/core';

import { PostCommandService } from 'pbc.angular';

import { IPostKonstanteCommandShapes, IPostKonstanteRequest, IPostKonstanteResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostKonstanteCommandService extends PostCommandService<IPostKonstanteRequest, IPostKonstanteResponse, IPostKonstanteCommandShapes> {
  readonly route = 'einstellungen/settings/konstante';
}
