import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostObjektArtCommandServiceModule } from '..';
import { PostObjektArtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostObjektArtCommandServiceModule
  ],
  declarations: [PostObjektArtCommandComponent],
  exports: [PostObjektArtCommandComponent],

})
export class PostObjektArtCommandComponentModule { }
