import { IFilter, IFilterConfig, IShape } from 'pbc.types';

import { IGutachtenStatiResponse } from '.';

export interface IGutachtenStatiQueryFilter extends IFilter<IGutachtenStatiResponse> {}

export const GutachtenStatiQueryFilterConfig: IFilterConfig<IGutachtenStatiResponse> = [];

export interface IGutachtenStatiQueryShapes extends IShape {}

export type IGutachtenStatiQueryField = 'gutachtenStati.id' | 'gutachtenStati.name' | 'gutachtenStati.beschreibung' | '';
