import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';
import { toInitials } from 'pbc.functions';

import { IPostVorlageRequest, IPostVorlageResponse } from 'fa-kt.types';
import { PostVorlageCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-vorlage',
  templateUrl: './post-vorlage.component.html',
  styleUrls: ['./post-vorlage.component.css'],
})
export class PostVorlageCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostVorlageRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostVorlageRequest> = new EventEmitter<IPostVorlageRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostVorlageResponse> = new EventEmitter<IPostVorlageResponse>();

  /* << fields    */
  /*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postVorlage: PostVorlageCommandService /* << more    */ /*    more >> */,
  ) {
    this.command = sitemap.TEXTE.Commands.POST_VORLAGE;
    this.form = this.fb.group({
      vorlage: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        initialen: [null, [Validators.required]],
        fuerKunden: [[], []],
        fuerKundenGruppen: [[], []],
        fuerKundenArten: [[], []],
        fuerObjektArten: [[], []],
        fuerBewertungsAnlaesse: [[], []],
        angewendet: [null, []],
      }),
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postVorlage.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostVorlageRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.vorlageId?.valueChanges.subscribe(async (id) => {
        /* << on-vorlage-id-change    */
        /*    on-vorlage-id-change >> */
      }) as Subscription,
      this.vorlageName?.valueChanges.subscribe(async (name) => {
        /* << on-vorlage-name-change    */
        /*    on-vorlage-name-change >> */
      }) as Subscription,
      this.vorlageInitialen?.valueChanges.subscribe(async (initialen) => {
        /* << on-vorlage-initialen-change    */
        /*    on-vorlage-initialen-change >> */
      }) as Subscription,
      this.vorlageFuerKunden?.valueChanges.subscribe(async (fuerKunden) => {
        /* << on-vorlage-fuer-kunden-change    */
        /*    on-vorlage-fuer-kunden-change >> */
      }) as Subscription,
      this.vorlageFuerKundenGruppen?.valueChanges.subscribe(async (fuerKundenGruppen) => {
        /* << on-vorlage-fuer-kunden-gruppen-change    */
        /*    on-vorlage-fuer-kunden-gruppen-change >> */
      }) as Subscription,
      this.vorlageFuerKundenArten?.valueChanges.subscribe(async (fuerKundenArten) => {
        /* << on-vorlage-fuer-kunden-arten-change    */
        /*    on-vorlage-fuer-kunden-arten-change >> */
      }) as Subscription,
      this.vorlageFuerObjektArten?.valueChanges.subscribe(async (fuerObjektArten) => {
        /* << on-vorlage-fuer-objekt-arten-change    */
        /*    on-vorlage-fuer-objekt-arten-change >> */
      }) as Subscription,
      this.vorlageFuerBewertungsAnlaesse?.valueChanges.subscribe(async (fuerBewertungsAnlaesse) => {
        /* << on-vorlage-fuer-bewertungs-anlaesse-change    */
        /*    on-vorlage-fuer-bewertungs-anlaesse-change >> */
      }) as Subscription,
      this.vorlageAngewendet?.valueChanges.subscribe(async (angewendet) => {
        /* << on-vorlage-angewendet-change    */
        /*    on-vorlage-angewendet-change >> */
      }) as Subscription,
      /* << subscibe    */
      this.form.controls.vorlage
        ?.get('name')
        ?.valueChanges.pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((name: string) => {
          const initialen = this.form.controls.vorlage?.get('initialen');
          if (initialen && (!initialen.touched || !initialen.value)) {
            this.form.patchValue({ vorlage: { initialen: toInitials(name) } });
          }
        }) as Subscription,
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostVorlageRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.vorlage) {
        value.vorlage = cleanObject(value.vorlage);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.vorlage) {
        payload.vorlage = cleanObject(payload.vorlage);
      }
      const response: IPostVorlageResponse = await this.postVorlage.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get vorlageId() {
    return this.form.controls.vorlage.get('id');
  }
  get vorlageName() {
    return this.form.controls.vorlage.get('name');
  }
  get vorlageInitialen() {
    return this.form.controls.vorlage.get('initialen');
  }
  get vorlageFuerKunden() {
    return this.form.controls.vorlage.get('fuerKunden');
  }
  get vorlageFuerKundenGruppen() {
    return this.form.controls.vorlage.get('fuerKundenGruppen');
  }
  get vorlageFuerKundenArten() {
    return this.form.controls.vorlage.get('fuerKundenArten');
  }
  get vorlageFuerObjektArten() {
    return this.form.controls.vorlage.get('fuerObjektArten');
  }
  get vorlageFuerBewertungsAnlaesse() {
    return this.form.controls.vorlage.get('fuerBewertungsAnlaesse');
  }
  get vorlageAngewendet() {
    return this.form.controls.vorlage.get('angewendet');
  }
}
