<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="objekte.search$.next($event)" [search]="objekte.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-objekte-filter [style.width]="'100%'"></fa-kt-objekte-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-objekte-sorter [style.width]="'100%'"></fa-kt-objekte-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-objekte-list [style.width]="'100%'" (select)="setObjekte($event)" [loading]="$loading | async"></fa-kt-objekte-list>



  <fa-kt-objekt [style.width]="'100%'" ></fa-kt-objekt>


-->
<!--- << custom    -->

<ng-container [ngSwitch]="!!objekt">
  <ng-container *ngSwitchCase="true">
    <nz-row>
      <nz-col nzSpan="24">
        <button nz-button nzType="primary" class="save-button" type="button" [disabled]="!objekt.id" (click)="emit(objekt)" [nzLoading]="$loading | async">
          <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
          Objekt auswählen
        </button>
        <br />
        <br />
      </nz-col>
      <nz-col nzSpan="24">
        <fa-kt-objekt [style.width]="'100%'" [objekt]="objekt" (closed)="objekt = undefined" (selected)="emit($event)"></fa-kt-objekt>
      </nz-col>
    </nz-row>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <nz-row>
      <nz-col nzSpan="20" style="padding: 16px">
        <button nz-button nzBlock type="button" [nzBlock]="true" (click)="openObjekt({})">
          <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
          Objekt hinzufügen
        </button>
      </nz-col>
      <nz-col nzSpan="4" style="padding: 16px">
        <span style="line-height: 32px"> {{ (objekte.result$ | async)?.objekte.length }} / {{ (objekte.response$ | async)?.objekte.length }} </span>
      </nz-col>
      <nz-col nzSpan="24">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="objekte.search$.next($event)" [search]="objekte.search$ | async"></pbc-search-bar>
      </nz-col>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="24">
        <fa-kt-objekte-list [height]="'564px'" [style.width]="'100%'" (select)="setObjekte($event)" [loading]="$loading | async"></fa-kt-objekte-list>
      </nz-col>
    </nz-row>
  </ng-container>
</ng-container>

<!---    custom >> -->
