import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';

import { FormulareCommandsModule } from './commands';
import { FormulareComponentsModule } from './components';
import { DurchgangPage, DurchgangPageResolver, FormularePage, FormularePageResolver, FormularPage, FormularPageResolver } from './pages';
import { DurchgangService, FormulareQuerysModule, FormulareService, FormularService } from './querys';

const routes: Routes = [
  {
    path: 'formulare',
    children: [
      {
        path: 'formulare',
        component: FormularePage,
        resolve: {
          querys: FormularePageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['formulare'],
            roles: ['GET_FORMULARE'],
          },
        },
      },
      {
        path: 'formular',
        component: FormularPage,
        resolve: {
          querys: FormularPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['formulare'],
            roles: ['GET_FORMULAR', 'DELETE_FORMULAR', 'POST_DURCHGANG', 'POST_FORMULAR', 'POST_FORMULAR_KOPIEREN'],
          },
        },
      },
      {
        path: 'durchgang',
        component: DurchgangPage,
        resolve: {
          querys: DurchgangPageResolver,
        },
        canActivate: [AuthGuard],
        data: {
          access: {
            groups: ['formulare'],
            roles: ['GET_DURCHGANG', 'POST_DURCHGANG', 'POST_EINTRAG'],
          },
        },
      },
    ],
  },
];

@NgModule({
  declarations: [FormularePage, FormularPage, DurchgangPage],
  imports: [LibsCommonAngularModule, FormulareComponentsModule, FormulareCommandsModule, FormulareQuerysModule, RouterModule.forChild(routes)],
  exports: [FormulareComponentsModule, FormulareCommandsModule, FormulareQuerysModule],
})
export class FormulareModule {
  static forRoot(): ModuleWithProviders<FormulareModule> {
    return {
      ngModule: FormulareModule,
      providers: [DurchgangService, FormularService, FormulareService],
    };
  }
}
