import { NgModule } from '@angular/core';

import {
  PostArchivierenCommandServiceModule,
  PostArchivierenCommandComponentModule
} from '.';

@NgModule({
  imports: [
    PostArchivierenCommandServiceModule,
    PostArchivierenCommandComponentModule
  ],
  exports: [
    PostArchivierenCommandServiceModule,
    PostArchivierenCommandComponentModule
  ]
})
export class PostArchivierenCommandModule { }
