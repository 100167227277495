import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { StandortsServiceModule } from '..';
import { StandortsFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    StandortsServiceModule
  ],
  declarations: [StandortsFilterComponent],
  exports: [StandortsFilterComponent],

})
export class StandortsFilterComponentModule { }
