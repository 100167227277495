<cdk-virtual-scroll-viewport *ngIf="mitarbeiter.result$ | async" [itemSize]="69" [style.height]="height">
  <nz-list nzSize="large" [nzLoading]="$loading | async">
    <nz-list-item *cdkVirtualFor="let entry of (mitarbeiter.result$ | async).mitarbeiter" (click)="set(entry)">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <a>
            <nz-avatar nzIcon="user" [nzSize]="28" [nzSrc]="entry.mitarbeiter.bild" [nzShape]="'square'" style="cursor: pointer; margin-right: 16px"> </nz-avatar>
            {{ entry.mitarbeiter.anzeigename }}
            <i *ngIf="entry.mitarbeiter.isAdmin || (entry.rollen && (entry.rollen | check: 'isAdmin'))" nz-icon nzType="unlock" nzTheme="outline"></i>
            <ng-container *ngIf="entry.mitarbeiter.email">
              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography nzType="secondary">
                {{ entry.mitarbeiter.email }}
              </span>
            </ng-container>
            <ng-container *ngIf="auth.$isAdmin | async">
              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography nzDisabled>
                {{ entry.mitarbeiter.rollen | type: (mitarbeiter.mitarbeiterRollen | async) }}
              </span>
            </ng-container>
            <ng-container *ngIf="entry.mitarbeiter.inaktiv">
              <nz-divider nzType="vertical"></nz-divider>
              <span nz-typography nzType="warning"> inaktiv </span>
            </ng-container>
          </a>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <a><i nz-icon nzType="edit" nzTheme="outline"></i></a>
        </nz-list-item-action>
      </ul>
    </nz-list-item>
  </nz-list>
</cdk-virtual-scroll-viewport>
