import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { GutachtenServiceModule } from '..';
import { GutachtenFilterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenServiceModule
  ],
  declarations: [GutachtenFilterComponent],
  exports: [GutachtenFilterComponent],

})
export class GutachtenFilterComponentModule { }
