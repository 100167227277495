import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostVermerkCommandServiceModule } from '..';
import { PostVermerkCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostVermerkCommandServiceModule
  ],
  declarations: [PostVermerkCommandComponent],
  exports: [PostVermerkCommandComponent],

})
export class PostVermerkCommandComponentModule { }
