import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IObjekteQueryFilter, IObjekteQueryShapes, IObjekteQuerySorter, IObjekteRequest, IObjekteResponse } from 'fa-kt.types';
import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

@Injectable({
  providedIn: 'root',
})
export class ObjekteService extends ListQueryService<IObjekteRequest, IObjekteResponse, IObjekteQueryShapes, IObjekteQueryFilter, IObjekteQuerySorter> {
  contentKey: keyof IObjekteResponse = 'objekte';
  route = 'objekte/objekte';
  override dependencies = ['geo/written-bereich', 'geo/removed-bereich', 'objekte/objekt-entfernt', 'objekte/objekt-gespeichert'];
  static instance: ObjekteService;

  constructor() {
    super();
    if (!ObjekteService.instance) ObjekteService.instance = this;
    return ObjekteService.instance;
  }

  getObjektBereiche(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['objekt.bereiche']?.find((v) => v.value === value);
  }

  get objektBereiche(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['objekt.bereiche'] as ISelection[]) : [])));
  }
}
