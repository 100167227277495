import { Component, Inject, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { NzContextMenuService } from 'ng-zorro-antd/dropdown';
import { NzFormatEmitEvent, NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ActionService, AuthService, FileService, HttpService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

export interface IGraphOneDriveItem {
  createdDateTime?: Date;
  eTag?: string;
  id?: string;
  lastModifiedDateTime?: Date;
  name?: string;
  webUrl?: string;
  cTag?: string;
  size?: number;
  createdBy?: EdBy;
  lastModifiedBy?: EdBy;
  parentReference?: ParentReference;
  fileSystemInfo?: FileSystemInfo;
  folder?: Folder;
  shared?: Shared;
}

export interface EdBy {
  user?: User;
}

export interface User {
  displayName?: string;
  email?: string;
  id?: string;
}

export interface FileSystemInfo {
  createdDateTime?: Date;
  lastModifiedDateTime?: Date;
}

export interface Folder {
  childCount?: number;
}

export interface ParentReference {
  driveType?: string;
  driveId?: string;
  id?: string;
  path?: string;
}

export interface Shared {
  scope?: string;
}

interface DirectoryNode extends NzTreeNodeOptions {
  title: string;
  key: string;
  webUrl?: string;
  disabled?: boolean;
  isLeaf: boolean;
  children: DirectoryNode[];
}

@Component({
  selector: 'fa-kt-dateien',
  templateUrl: './dateien.component.html',
  styleUrls: ['./dateien.component.css'],
})
export class DateienComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() set search(search: string) {
    this.search$.next(search);
  }
  search$ = new BehaviorSubject<string>('');

  @Input() set kundePfad(dateiVerzeichnis: string) {
    this.$kundePfad.next(dateiVerzeichnis);
  }
  $kundePfad = new BehaviorSubject<string>('');

  @Input() set kunde(dateiVerzeichnis: string) {
    this.$kunde.next(dateiVerzeichnis);
  }
  $kunde = new BehaviorSubject<string>('');

  @Input() set standortPfad(dateiVerzeichnis: string) {
    this.$standortPfad.next(dateiVerzeichnis);
  }
  $standortPfad = new BehaviorSubject<string>('');

  @Input() set standort(dateiVerzeichnis: string) {
    this.$standort.next(dateiVerzeichnis);
  }
  $standort = new BehaviorSubject<string>('');

  driveID = 'b!pRwsZHg1K0CDGhIUpVVmn3oNHsf9oxlNgZ23sBDDxAGf5_tE20rRT73ktMdfzwop';
  included = [
    'Kleindarlehen',
    '_Kleindarlehen',
    'Erledigte Gutachten',
    moment(new Date()).format('YYYY'),
    moment(new Date()).add(-1, 'year').format('YYYY').toString(),
    moment(new Date()).add(-2, 'year').format('YYYY').toString(),
  ];

  activatedNode?: NzTreeNode;
  searchValue = '';
  nodes: DirectoryNode[] = [];

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public http: HttpService,
    private nzContextMenuService: NzContextMenuService /* << injection    */,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        combineLatest([this.$kundePfad, this.$kunde, this.$standortPfad, this.$standort, this.search$]).subscribe(async ([kundePfad, kunde, standortPfad, standort, search]) => {
          if (!kundePfad || !kunde || !standortPfad || !standort) return;
          this.$loading.next(true);
          const kundenSplit = kunde.split(' ');
          const included = [...this.included, kundePfad, standortPfad, standort, ...kundenSplit, ...kundenSplit.map((kunde) => '_' + kunde)];
          const [gutachten] = await Promise.all([this.loadDateiVerzeichnis(included, '01VGRNSONUN5KUJ3BBKZLJZ4ACN73M6A5T')]);
          this.nodes = gutachten;
          this.searchValue = search;
          this.$loading.next(false);
        }),
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async loadDateiVerzeichnis(search: string[], itemID: string): Promise<DirectoryNode[]> {
    const driveItems = await this.http.get<IGraphOneDriveItem[]>(`https://graph.microsoft.com/v1.0/drives/${this.driveID}/items/${itemID}/children`);

    const nodes: DirectoryNode[] = await Promise.all(
      driveItems.map(async (driveItem) => {
        let children: DirectoryNode[] = [];
        if (search.includes(driveItem.name as string)) {
          children = await this.loadDateiVerzeichnis(search, driveItem.id as string);
        }
        const isLeaf = this.isFile(driveItem.name as string);
        const result: DirectoryNode = {
          webUrl: driveItem.webUrl,
          title: driveItem.name as string,
          key: driveItem.id as string,
          children,
          isLeaf,
          icon: isLeaf ? 'file' : 'folder',
        };
        return result;
      }),
    );
    return nodes;
  }

  isFile(name: string): boolean {
    return ['.docx', '.pdf', '.xlsx', '.zip'].some((ending) => name.includes(ending));
  }

  async nzEvent(event: NzFormatEmitEvent): Promise<void> {
    if (event.eventName === 'expand') {
      const node = event.node;
      if (node?.getChildren().length === 0 && node?.isExpanded) {
        const nodes = await this.loadDateiVerzeichnis([node.origin.title], node.origin.key);
        node.addChildren(nodes);
      }
    }
  }

  open(event: NzFormatEmitEvent): void {
    window.open(event.node?.origin.webUrl, '_blank');
  }
}
