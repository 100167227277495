import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IFilter,
  IFilterConfig,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IBewertungsAnlass, IFeld, IFeldKategorie, IFeldUnterkategorie, IGutachten, IKunde, IKundenArt, IObjektArt } from 'fa-kt.types';
import { IDurchgangResponse } from '.';

export interface IDurchgangQueryFilter extends IFilter<IDurchgangResponse> {
  'formular.objektArten'?: IArrayReferenceFilter<IObjektArt>;
  'formular.bewertungsAnlaesse'?: IArrayReferenceFilter<IBewertungsAnlass>;
  'formular.kundenArten'?: IArrayReferenceFilter<IKundenArt>;
  'formular.kunden'?: IArrayReferenceFilter<IKunde>;
  'formular.ohneFelder'?: IArrayReferenceFilter<IFeld>;
  'feldUnterkategorien.feldKategorie'?: ISingleReferenceFilter<IFeldKategorie>;
  'felder.feldKategorie'?: ISingleReferenceFilter<IFeldKategorie>;
  'felder.feldUnterkategorie'?: ISingleReferenceFilter<IFeldUnterkategorie>;
  'eintraege.gutachten'?: ISingleReferenceFilter<IGutachten>;
  'eintraege.feld'?: ISingleReferenceFilter<IFeld>;
  'eintraege.achtung'?: IBooleanFilter;
}

export const DurchgangQueryFilterConfig: IFilterConfig<IDurchgangResponse> = [
  ArrayReferenceFilterConfig<IObjektArt>('formular.objektArten', 'FORMULARE.Models.FORMULAR.Propertys.OBJEKT_ARTEN.title'),
  ArrayReferenceFilterConfig<IBewertungsAnlass>('formular.bewertungsAnlaesse', 'FORMULARE.Models.FORMULAR.Propertys.BEWERTUNGS_ANLAESSE.title'),
  ArrayReferenceFilterConfig<IKundenArt>('formular.kundenArten', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN_ARTEN.title'),
  ArrayReferenceFilterConfig<IKunde>('formular.kunden', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN.title'),
  ArrayReferenceFilterConfig<IFeld>('formular.ohneFelder', 'FORMULARE.Models.FORMULAR.Propertys.OHNE_FELDER.title'),
  SingleReferenceFilterConfig<IFeldKategorie>('feldUnterkategorien.feldKategorie', 'FELDER.Models.FELD_UNTERKATEGORIE.Propertys.FELD_KATEGORIE.title'),
  SingleReferenceFilterConfig<IFeldKategorie>('felder.feldKategorie', 'FELDER.Models.FELD.Propertys.FELD_KATEGORIE.title'),
  SingleReferenceFilterConfig<IFeldUnterkategorie>('felder.feldUnterkategorie', 'FELDER.Models.FELD.Propertys.FELD_UNTERKATEGORIE.title'),
  SingleReferenceFilterConfig<IGutachten>('eintraege.gutachten', 'GUTACHTEN.Models.EINTRAG.Propertys.GUTACHTEN.title'),
  SingleReferenceFilterConfig<IFeld>('eintraege.feld', 'GUTACHTEN.Models.EINTRAG.Propertys.FELD.title'),
  BooleanFilterConfig('eintraege.achtung', 'GUTACHTEN.Models.EINTRAG.Propertys.ACHTUNG.title'),
];

export interface IDurchgangQueryShapes extends IShape {
  'formular.objektArten': ISelection[];
  'formular.bewertungsAnlaesse': ISelection[];
  'formular.kundenArten': ISelection[];
  'formular.kunden': ISelection[];
  'formular.ohneFelder': ISelection[];
  'feldUnterkategorien.feldKategorie': ISelection[];
  'felder.feldKategorie': ISelection[];
  'felder.feldUnterkategorie': ISelection[];
  'felder.art': ISelection[];
  'eintraege.gutachten': ISelection[];
  'eintraege.feld': ISelection[];
}

export type IDurchgangQueryField =
  | 'durchgang.id'
  | 'durchgang.formular'
  | 'durchgang.gutachten'
  | 'durchgang.projekt'
  | 'durchgang.besichtigung'
  | 'formular.id'
  | 'formular.name'
  | 'formular.initialen'
  | 'formular.objektArten'
  | 'formular.bewertungsAnlaesse'
  | 'formular.kundenArten'
  | 'formular.kunden'
  | 'formular.ohneFelder'
  | 'formular.kommentar'
  | 'feldKategorien.id'
  | 'feldKategorien.name'
  | 'feldKategorien.initialen'
  | 'feldKategorien.kommentar'
  | 'feldUnterkategorien.id'
  | 'feldUnterkategorien.feldKategorie'
  | 'feldUnterkategorien.name'
  | 'feldUnterkategorien.initialen'
  | 'feldUnterkategorien.kommentar'
  | 'felder.id'
  | 'felder.schluessel'
  | 'felder.name'
  | 'felder.kommentar'
  | 'felder.feldKategorie'
  | 'felder.feldUnterkategorie'
  | 'felder.art'
  | 'felder.format'
  | 'felder.voreinstellung'
  | 'feldOptionen.id'
  | 'feldOptionen.feld'
  | 'feldOptionen.option'
  | 'feldOptionen.akkusativ'
  | 'feldOptionen.nominativ'
  | 'feldOptionen.dativ'
  | 'feldOptionen.genitiv'
  | 'feldOptionen.standard'
  | 'eintraege.id'
  | 'eintraege.gutachten'
  | 'eintraege.feld'
  | 'eintraege.wert'
  | 'eintraege.wertExtra'
  | 'eintraege.akkusativ'
  | 'eintraege.nominativ'
  | 'eintraege.dativ'
  | 'eintraege.genitiv'
  | 'eintraege.achtung'
  | '';
