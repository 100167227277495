import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ReisekostenabrechnungServiceModule } from '..';
import { SavedReisekostenabrechnungFiltersComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungServiceModule
  ],
  declarations: [SavedReisekostenabrechnungFiltersComponent],
  exports: [SavedReisekostenabrechnungFiltersComponent],

})
export class SavedReisekostenabrechnungFiltersComponentModule { }
