import { NgModule } from '@angular/core';

import { LIB_CommonModule } from 'pbc.angular';

import { VorlageService } from '.';

@NgModule({
  imports: [LIB_CommonModule],
  providers: [VorlageService],
  exports: [],
})
export class VorlageServiceModule {}
