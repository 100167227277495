import { inject, Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';

import { ICommandRequest, ICommandResponse, IFilter, IPersistedFilter, IPersistedFilterList, IQueryRequest, IQueryResponse, ISelection, IShape, ISorter } from 'pbc.types';

import { trimUndefinedRecursively } from 'compress-json';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/Berlin');
import { NzMessageService } from 'ng-zorro-antd/message';
import { join } from 'pbc.functions';
import { BroadcastService, DeviceConfigService, MonitoringService, prepareCommand } from './common';
import { FilterPipe, SearchPipe, SorterPipe } from './filter';
import { HttpService } from './https';

@Injectable({
  providedIn: 'root',
})
abstract class BaseAPIService {
  abstract route: string;
  dependencies: string[] = [];
  wipe: Date | undefined = moment().add(30, 'days').toDate();

  loading$ = new BehaviorSubject<boolean>(false);

  device = inject(DeviceConfigService);
  http = inject(HttpService);
  broadcast = inject(BroadcastService);
  search = inject(SearchPipe);
  filter = inject(FilterPipe);
  sorter = inject(SorterPipe);
  monitoring = inject(MonitoringService);
  message = inject(NzMessageService);
}

@Injectable({
  providedIn: 'root',
})
export abstract class DeleteCommandService<Request extends ICommandRequest, Response extends ICommandResponse> extends BaseAPIService {
  public async request(payload: Request): Promise<Response> {
    return this.http.delete<Response>(this.route + this.http.serialize(payload));
  }
}

@Injectable({
  providedIn: 'root',
})
export abstract class PostCommandService<Request extends ICommandRequest, Response extends ICommandResponse, Shapes extends IShape> extends BaseAPIService {
  readonly shapes$ = new BehaviorSubject<Shapes | undefined>(undefined);
  private listener: Function;

  constructor() {
    super();
  }

  public async prepare(force = false): Promise<Shapes> {
    this.listen();
    const state = this.shapes$.getValue();
    if (!this.device.alwaysRefresh && !force && state) return state;
    const shapes = await this.http.get<Shapes>(this.route + '/post/shapes');
    this.shapes$.next(shapes);
    return shapes;
  }

  listen() {
    if (this.listener) return;
    this.listener = this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async request(payload: Request): Promise<Response> {
    return this.http.post<Response>(this.route, prepareCommand(payload));
  }
}

@Injectable({
  providedIn: 'root',
})
export abstract class SelectionService<Request = {}> extends BaseAPIService {
  readonly payload$ = new BehaviorSubject<Request | undefined>(undefined);
  readonly selection$ = new BehaviorSubject<ISelection[]>([]);
  private listener: Function;
  compressed = false;

  listen() {
    if (this.listener) return;
    this.listener = this.broadcast.on(this.dependencies)(() => {
      const payload = this.payload$.getValue();
      if (payload) this.request(payload, true);
    });
  }

  public async request(payload: Request, force?: boolean): Promise<ISelection[]> {
    this.listen();
    let selection = this.selection$.getValue();
    if (this.device.alwaysRefresh || force || selection.length === 0 || !isEqual(payload, this.payload$.getValue()))
      selection = await this.http.get<ISelection[]>(this.route + '/select' + this.http.serialize(payload), undefined, this.compressed);
    this.payload$.next(payload);
    this.selection$.next(selection);
    return selection;
  }
}

@Injectable({
  providedIn: 'root',
})
export abstract class SettingsQueryService<
  Request extends IQueryRequest,
  Response extends IQueryResponse,
  Shapes extends IShape,
  Filter extends IFilter<Response>,
  Sorter extends ISorter,
> extends BaseAPIService {
  abstract contentKey: keyof Response;

  readonly payload$ = new BehaviorSubject<Request | undefined>(undefined);
  readonly response$ = new BehaviorSubject<Response | undefined>(undefined);
  private readonly filtered$ = new BehaviorSubject<Response | undefined>(undefined);
  private readonly searched$ = new BehaviorSubject<Response | undefined>(undefined);
  readonly result$ = new BehaviorSubject<Response | undefined>(undefined);
  readonly shapes$ = new BehaviorSubject<Shapes | undefined>(undefined);
  readonly filter$ = new BehaviorSubject<Filter>({} as Filter);
  readonly sorter$ = new BehaviorSubject<Sorter>({} as Sorter);
  readonly search$ = new BehaviorSubject<string>('');
  private listener: Function;

  constructor() {
    super();
    combineLatest([this.response$, this.filter$]).subscribe(([response, filter]) => {
      if (response && response[this.contentKey]) this.filtered$.next({ ...response, [this.contentKey]: this.filter.transform(response[this.contentKey] as unknown[], filter, true) });
      else this.filtered$.next(response);
    });
    combineLatest([this.filtered$, this.search$.pipe(debounceTime(this.device.debounceTime), distinctUntilChanged()), this.shapes$]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered[this.contentKey]) this.searched$.next({ ...filtered, [this.contentKey]: this.search.transform(filtered[this.contentKey] as unknown[], search, '_search', shapes) });
      else this.searched$.next(filtered);
    });
    combineLatest([this.searched$, this.sorter$, this.shapes$])
      .pipe(debounceTime(10))
      .subscribe(([searched, sorter, shapes]) => {
        if (searched && searched[this.contentKey]) this.result$.next({ ...searched, [this.contentKey]: this.sorter.transform(searched[this.contentKey] as unknown[], sorter, shapes) });
        else this.result$.next(searched);
      });
  }

  private listen() {
    if (this.listener) return;
    this.listener = this.broadcast.on(this.dependencies)(() => {
      const payload = this.payload$.getValue();
      if (payload) this.request(payload, true);
    });
  }

  public async request(payload: Request, force = false): Promise<Response> {
    this.listen();
    let response: Response | undefined = this.response$.getValue();
    let shapes: Shapes | undefined = undefined;
    // if (this.loading$.getValue()) return response;
    this.loading$.next(true);
    if (!this.device.alwaysRefresh && !force && isEqual(payload, this.payload$.getValue()) && response) return response;
    ({ response, shapes } = await this.http.get<{
      response: Response;
      shapes: Shapes;
    }>(this.route + this.http.serialize(payload)));
    this.loading$.next(false);
    this.shapes$.next(shapes);
    this.response$.next(response);
    this.payload$.next(payload);
    return response;
  }
}

@Injectable({
  providedIn: 'root',
})
export abstract class DetailQueryService<Request extends IQueryRequest, Response extends IQueryResponse, Shapes extends IShape> extends BaseAPIService {
  readonly payload$ = new BehaviorSubject<Request | undefined>(undefined);
  readonly result$ = new BehaviorSubject<Response | undefined>(undefined);
  readonly shapes$ = new BehaviorSubject<Shapes | undefined>(undefined);
  private listener: Function;
  replacements: Array<{ [key: string]: string }> = [];

  constructor() {
    super();
  }

  private listen() {
    if (this.listener) return;
    this.listener = this.broadcast.on(this.dependencies)(() => {
      const payload = this.payload$.getValue();
      if (payload) this.request(payload, true);
    });
  }

  public async request(payload: Request, force = false): Promise<Response> {
    this.listen();
    let response: Response | undefined = this.result$.getValue();
    if (!this.device.alwaysRefresh && !force && isEqual(payload, this.payload$.getValue()) && response) return response;
    let shapes: Shapes | undefined = undefined;
    ({ response, shapes } = await this.http.get<{
      response: Response;
      shapes: Shapes;
    }>(this.route + this.http.serialize(payload)));
    this.shapes$.next(shapes);
    this.result$.next(response);
    this.payload$.next(payload);
    return response;
  }

  translate<T extends IQueryResponse>(unit: T, excludedFields?: string[], shapes?: IShape) {
    shapes = shapes || this.shapes$.getValue();
    this.replacements.forEach((r) => {
      Object.entries(r)
        .filter(([key]) => shapes[key])
        .forEach(([key, value]) => (shapes[value] = shapes[key]));
    });
    const cloned = cloneDeep(unit);
    delete cloned._search;
    const iterated = this.iterate(cloned, shapes as Shapes, excludedFields || []);
    trimUndefinedRecursively(iterated);
    return iterated;
  }

  private iterate(obj: any, shapes: Shapes, excludedFields: string[] = [], stack = '') {
    if (!obj || stack === 'id' || stack[0] === '_' || !shapes) return obj;
    if (excludedFields?.some((f) => stack.includes(f))) return undefined;
    if (typeof obj === 'string' && (shapes[stack] as ISelection[])?.find) return (shapes[stack] as ISelection[]).find(({ value }) => value?.toString() === obj.toString())?.label || obj;
    if (typeof obj !== 'object') return obj;
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        const key = (stack ? stack + '.' : '') + property;
        if (property[0] !== '_' && !excludedFields?.some((f) => key.includes(f))) {
          if (Array.isArray(obj[property])) {
            obj[property] = obj[property].map((o: any) => this.iterate(o, shapes, excludedFields, key));
            if ((shapes[key] as ISelection[])?.find) obj[property] = join(obj[property]);
          } else if (typeof obj[property] == 'object') obj[property] = this.iterate(obj[property], shapes, excludedFields, key);
          else if ((shapes[key] as ISelection[])?.find) obj[property] = (shapes[key] as ISelection[]).find(({ value }) => value?.toString() === obj[property].toString())?.label || obj[property];
        } else {
          obj[property] = undefined;
        }
      }
    }
    return obj;
  }
}

@Injectable({
  providedIn: 'root',
})
export abstract class ListQueryService<
  Request extends IQueryRequest,
  Response extends IQueryResponse,
  Shapes extends IShape,
  Filter extends IFilter<Response>,
  Sorter extends ISorter,
> extends BaseAPIService {
  abstract contentKey: keyof Response;
  compressed = false;
  replacements: Array<{ [key: string]: string }> = [];

  readonly payload$ = new BehaviorSubject<Request | undefined>(undefined);
  readonly response$ = new BehaviorSubject<Response | undefined>(undefined);
  private readonly filtered$ = new BehaviorSubject<Response | undefined>(undefined);
  private readonly searched$ = new BehaviorSubject<Response | undefined>(undefined);
  readonly result$ = new BehaviorSubject<Response | undefined>(undefined);
  readonly shapes$ = new BehaviorSubject<Shapes | undefined>(undefined);
  readonly presets$ = new BehaviorSubject<IPersistedFilter<Filter>[]>([]);
  readonly preset$ = new BehaviorSubject<IPersistedFilter<Filter> | undefined>(undefined);
  readonly filter$ = new BehaviorSubject<Filter>({} as Filter);
  readonly sorter$ = new BehaviorSubject<Sorter>({} as Sorter);
  readonly search$ = new BehaviorSubject<string>('');
  private listener: Function;

  constructor() {
    super();
    combineLatest([this.response$, this.filter$]).subscribe(([response, filter]) => {
      if (response && response[this.contentKey]) this.filtered$.next({ ...response, [this.contentKey]: this.filter.transform(response[this.contentKey] as unknown[], filter) });
      else this.filtered$.next(response);
    });
    combineLatest([this.filtered$, this.search$.pipe(debounceTime(this.device.debounceTime), distinctUntilChanged()), this.shapes$]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered[this.contentKey]) this.searched$.next({ ...filtered, [this.contentKey]: this.search.transform(filtered[this.contentKey] as unknown[], search, '_search') });
      else this.searched$.next(filtered);
    });
    combineLatest([this.searched$, this.sorter$, this.shapes$])
      .pipe(debounceTime(10))
      .subscribe(([searched, sorter, shapes]) => {
        if (searched && searched[this.contentKey]) this.result$.next({ ...searched, [this.contentKey]: this.sorter.transform(searched[this.contentKey] as unknown[], sorter, shapes) });
        else this.result$.next(searched);
      });
  }

  private listen() {
    if (this.listener) return;
    this.listener = this.broadcast.on(this.dependencies)(() => {
      const payload = this.payload$.getValue();
      if (payload) this.request(payload, true);
    });
  }

  public async request(payload: Request, force = false, direct = false): Promise<Response> {
    this.listen();
    let response: Response | undefined = this.response$.getValue();
    if (!this.device.alwaysRefresh && !force && isEqual(payload, this.payload$.getValue()) && response) return response;
    this.loading$.next(true);
    let shapes: Shapes | undefined = undefined;
    let filter: IPersistedFilterList<Filter> | undefined;
    ({ response, shapes, filter } = await this.http.get<{
      response: Response;
      shapes: Shapes;
      filter: IPersistedFilterList<Filter>;
    }>(this.route + this.http.serialize(payload), undefined, this.compressed));
    this.loading$.next(false);
    if (direct) return response;
    this.shapes$.next(shapes);
    this.response$.next(response);
    this.presets$.next(filter?.filters || []);
    this.payload$.next(payload);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<Filter>): Promise<IPersistedFilter<Filter> | undefined> {
    try {
      const presets = await this.http.post<IPersistedFilterList<Filter>>(this.route + '/filters', {
        ...filter,
        term: this.search$.getValue(),
        sorter: this.sorter$.getValue(),
        filter: this.filter$.getValue(),
      });
      this.presets$.next(presets.filters);
      this.setFilter(presets.filters.find((f) => f.name === filter.name));
      return filter;
    } catch (error) {
      this.monitoring.logException(error);
      this.message.warning(`"${filter.name}" existiert bereits`);
    }
    return undefined;
  }

  public async deleteFilter(id: string): Promise<void> {
    await this.http.delete(this.route + '/filters?id=' + id);
    this.presets$.next(this.presets$.getValue()?.filter((p) => p.id !== id));
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<Filter>): void {
    this.filter$.next((filter?.filter as Filter) || ({} as Filter));
    this.search$.next(filter?.term || '');
    this.sorter$.next((filter?.sorter as Sorter) || ({} as Sorter));
    this.preset$.next(filter || undefined);
  }

  translate<T extends IQueryResponse>(unit: T, excludedFields?: string[]): T {
    const shapes = this.shapes$.getValue();
    this.replacements.forEach((r) => {
      Object.entries(r)
        .filter(([key]) => shapes[key])
        .forEach(([key, value]) => (shapes[value as keyof Shapes] = shapes[key as keyof Shapes]));
    });
    const cloned = cloneDeep(unit);
    delete cloned._search;
    const iterated = this.iterate(cloned, shapes, excludedFields || []);
    trimUndefinedRecursively(iterated);
    return iterated;
  }

  private iterate(obj: any, shapes: Shapes, excludedFields: string[] = [], stack = '') {
    if (!obj || stack === 'id' || stack[0] === '_' || !shapes) return obj;
    if (excludedFields?.some((f) => stack.includes(f))) return undefined;
    if (typeof obj === 'string' && (shapes[stack] as ISelection[])?.find) return (shapes[stack] as ISelection[]).find(({ value }) => value?.toString() === obj.toString())?.label || obj;
    if (typeof obj !== 'object') return obj;
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        const key = (stack ? stack + '.' : '') + property;
        if (property[0] !== '_' && !excludedFields?.some((f) => key.includes(f))) {
          if (Array.isArray(obj[property])) {
            obj[property] = obj[property].map((o: any) => this.iterate(o, shapes, excludedFields, key));
            if ((shapes[key] as ISelection[])?.find) obj[property] = join(obj[property]);
          } else if (typeof obj[property] == 'object') obj[property] = this.iterate(obj[property], shapes, excludedFields, key);
          else if ((shapes[key] as ISelection[])?.find) obj[property] = (shapes[key] as ISelection[]).find(({ value }) => value?.toString() === obj[property].toString())?.label || obj[property];
        } else {
          obj[property] = undefined;
        }
      }
    }
    return obj;
  }
}
