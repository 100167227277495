import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDeleteFeldUnterkategorieResponse, IFeldUnterkategorie } from 'fa-kt.types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ActionService, ISitemap, ISitemapCommand, ISitemapModel, SITEMAP } from 'pbc.angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DeleteFeldUnterkategorieCommandService } from '../service';

interface IFormValue {
  feldUnterkategorie?: IFeldUnterkategorie;
}

@Component({
  selector: 'fa-kt-delete-feld-unterkategorie',
  templateUrl: './delete-feld-unterkategorie.component.html',
  styleUrls: ['./delete-feld-unterkategorie.component.css'],
})
export class DeleteFeldUnterkategorieCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;
  model!: ISitemapModel;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IFormValue) {
    this.patch(value);
  }
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IDeleteFeldUnterkategorieResponse> = new EventEmitter<IDeleteFeldUnterkategorieResponse>();

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private actions: ActionService,
    private message: NzMessageService,
    public deleteFeldUnterkategorie: DeleteFeldUnterkategorieCommandService,
  ) {
    this.command = sitemap.FELDER.Commands.DELETE_FELD_UNTERKATEGORIE;
    this.model = sitemap.FELDER.Models.FELD_UNTERKATEGORIE;
    /* << constructor    */
    /*    constructor >> */
    this.form = this.fb.group({
      id: [null, [Validators.required]],
    });
    this.patch();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.$valid.subscribe((valid: boolean) => {
        this.valid.emit(valid);
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IFormValue) {
    if (value && value.feldUnterkategorie && value.feldUnterkategorie.id) {
      this.form.controls.id.patchValue(value.feldUnterkategorie.id);
    } else {
      this.form.controls.id.patchValue(null);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const response: IDeleteFeldUnterkategorieResponse = await this.deleteFeldUnterkategorie.request(this.form.value);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
    }
    this.$loading.next(false);
  }
}
