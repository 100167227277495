import { IFilter, IFilterConfig } from "pbc.types";

import { IBewertungsAnlass } from '.';

export interface IBewertungsAnlassFilter extends IFilter<IBewertungsAnlass> {}

export const BewertungsAnlassFilterConfig: IFilterConfig<IBewertungsAnlass> = [];

export type IBewertungsAnlassField =
  'bewertungsAnlass.id'|
  'bewertungsAnlass.name'|
  'bewertungsAnlass.beschreibung'
;
