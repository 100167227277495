import { IFilter, IFilterConfig } from "pbc.types";

import { IKunde } from '.';

export interface IKundeFilter extends IFilter<IKunde> {}

export const KundeFilterConfig: IFilterConfig<IKunde> = [];

export type IKundeField =
  'kunde.id'|
  'kunde.name'|
  'kunde.initialen'|
  'kunde.kundenGruppe'|
  'kunde.kundenArt'|
  'kunde.addresse'|
  'kunde.website'|
  'kunde.outlookKontakte'|
  'kunde.kontaktNotizen'|
  'kunde.anforderungenNotizen'|
  'kunde.rechnungNotizen'|
  'kunde.fahrtkostenAbrechnung'|
  'kunde.steuerId'|
  'kunde.fahrtkostenAbRadius'|
  'kunde.fahrtkostenProKm'|
  'kunde.fahrtkostenProStunde'|
  'kunde.skonto'|
  'kunde.zahlungsziel'|
  'kunde.tageOhneSkonto'|
  'kunde.aktiv'|
  'kunde.dateipfad'|
  'kunde.loraName'|
  'kunde.unterlagenEmail'
;
