<!---
  <div formGroupName="bericht">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="excel">{{ command.Inputs.EXCEL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ExcelErrorTip">
        <ng-template #ExcelErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.EXCEL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="excel" id="excel" [shapes]="postBericht.berichtExcel | async" [placeholder]="command.Inputs.EXCEL.placeholder" cdkFocusInitial ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="art">{{ command.Inputs.ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ArtErrorTip">
        <ng-template #ArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="art" id="art" [shapes]="postBericht.berichtArt | async" [placeholder]="command.Inputs.ART.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="herausgeber">{{ command.Inputs.HERAUSGEBER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="HerausgeberErrorTip">
        <ng-template #HerausgeberErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.HERAUSGEBER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="herausgeber" id="herausgeber" [shapes]="postBericht.berichtHerausgeber | async" [placeholder]="command.Inputs.HERAUSGEBER.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="quelle">{{ command.Inputs.QUELLE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="QuelleErrorTip">
        <ng-template #QuelleErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.QUELLE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="quelle" id="quelle" [placeholder]="command.Inputs.QUELLE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="jahr">{{ command.Inputs.JAHR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="JahrErrorTip">
        <ng-template #JahrErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.JAHR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="jahr" id="jahr" [nzPlaceHolder]="command.Inputs.JAHR.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="quartal">{{ command.Inputs.QUARTAL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="QuartalErrorTip">
        <ng-template #QuartalErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.QUARTAL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="quartal" id="quartal" [placeholder]="command.Inputs.QUARTAL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="monat">{{ command.Inputs.MONAT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MonatErrorTip">
        <ng-template #MonatErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MONAT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="monat" id="monat" [nzPlaceHolder]="command.Inputs.MONAT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <div formGroupName="bericht">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="vorjahr" id="vorjahr" />
      <input class="cdk-visually-hidden" formControlName="berichtExcel" id="berichtExcel" />
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="art">{{ command.Inputs.ART.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ArtErrorTip">
          <ng-template #ArtErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ART.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value formControlName="art" id="art" [shapes]="(postBericht.shapes$ | async)?.['bericht.art']" [placeholder]="command.Inputs.ART.placeholder" required="true"></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="herausgeber">{{ command.Inputs.HERAUSGEBER.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="HerausgeberErrorTip">
          <ng-template #HerausgeberErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.HERAUSGEBER.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <pbc-select-value
            formControlName="herausgeber"
            id="herausgeber"
            [shapes]="postBericht.berichtHerausgeber | async"
            [placeholder]="command.Inputs.HERAUSGEBER.placeholder"
            required="true"
          ></pbc-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="quelle">{{ command.Inputs.QUELLE.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="QuelleErrorTip">
          <ng-template #QuelleErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.QUELLE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="quelle" id="quelle" [placeholder]="command.Inputs.QUELLE.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="jahr">{{ command.Inputs.JAHR.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="JahrErrorTip">
          <ng-template #JahrErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.JAHR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="jahr" id="jahr" [placeholder]="command.Inputs.JAHR.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="monat">{{ command.Inputs.MONAT.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MonatErrorTip">
          <ng-template #MonatErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MONAT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="monat" id="monat" [placeholder]="command.Inputs.MONAT.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="quartal">{{ command.Inputs.QUARTAL.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="QuartalErrorTip">
          <ng-template #QuartalErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.QUARTAL.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" formControlName="quartal" id="quartal" [placeholder]="command.Inputs.QUARTAL.placeholder" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title }}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Bericht speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            {{ command.translation }}
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
