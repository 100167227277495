import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IErinnerungSorter extends ISorter {
  id?: ISorterExpression;
  projekt?: ISorterExpression;
  gutachten?: ISorterExpression;
  kunde?: ISorterExpression;
  kommentar?: ISorterExpression;
  am?: ISorterExpression;
  mitarbeiter?: ISorterExpression;
  verfasser?: ISorterExpression;
}

export const ErinnerungSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('gutachten', 'Gutachten'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('kommentar', 'Kommentar'),
  SorterConfig('am', 'Am'),
  SorterConfig('mitarbeiter', 'Mitarbeiter'),
  SorterConfig('verfasser', 'Verfasser')
];
