import { NgModule } from '@angular/core';

import {
  DeleteBesichtigungsStatusCommandServiceModule,
  DeleteBesichtigungsStatusCommandComponentModule
} from '.';

@NgModule({
  imports: [
    DeleteBesichtigungsStatusCommandServiceModule,
    DeleteBesichtigungsStatusCommandComponentModule
  ],
  exports: [
    DeleteBesichtigungsStatusCommandServiceModule,
    DeleteBesichtigungsStatusCommandComponentModule
  ]
})
export class DeleteBesichtigungsStatusCommandModule { }
