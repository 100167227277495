import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthService, ResolverLoadingService } from 'pbc.angular';

import { IFormulareRequest, IFormulareResponse } from 'fa-kt.types';
import { FelderService } from '../../../felder';
import { FormulareService } from '../../querys';

export interface IFormularePageResolverResult {
  formulare?: IFormulareResponse | void;
}

@Injectable({
  providedIn: 'root',
})
export class FormularePageResolver implements Resolve<IFormularePageResolverResult> {
  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    private injector: Injector,
    private loading: ResolverLoadingService,
    private formulare: FormulareService,
    private felder: FelderService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IFormularePageResolverResult> {
    this.loading.resolving();
    const requests = {
      formulare: {} as IFormulareRequest,
    };

    const log = (error: any) => console.error(error);
    const [formulare] = await Promise.all([this.formulare.request(requests.formulare).catch(log), this.felder.request({})]);
    this.loading.resolved();
    return {
      formulare,
    };
  }
}
