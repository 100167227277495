import { Injectable } from '@angular/core';

import { SettingsQueryService } from 'pbc.angular';

import { IDateiArtsQueryFilter, IDateiArtsQueryShapes, IDateiArtsQuerySorter, IDateiArtsRequest, IDateiArtsResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class DateiArtsService extends SettingsQueryService<IDateiArtsRequest, IDateiArtsResponse, IDateiArtsQueryShapes, IDateiArtsQueryFilter, IDateiArtsQuerySorter> {
  contentKey: keyof IDateiArtsResponse = 'dateiArts';
  route = 'dokumente/settings/datei-arts';
  override dependencies = ['dokumente/written-datei-art', 'dokumente/removed-datei-art'];
}
