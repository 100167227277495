import { Component } from '@angular/core';

import get from 'lodash/get';
import { BehaviorSubject } from 'rxjs';

import { BaseFilterComponent } from 'pbc.angular';

import { ITageQueryShapes, TageQueryFilterConfig } from 'fa-kt.types';
import { TageService } from '../service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'fa-kt-tage-filter',
  templateUrl: './tage-filter.component.html',
  styleUrls: ['./tage-filter.component.css'],
})
export class TageFilterComponent extends BaseFilterComponent<ITageQueryShapes> {
  shapes$: BehaviorSubject<ITageQueryShapes>;
  config;

  constructor(public tage: TageService) {
    super();
    this.shapes$ = this.tage.shapes$;
    this.config = TageQueryFilterConfig.map((filter) => ({ ...filter, title: get(this.sitemap, filter.title, filter.title) as string }));
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.meta.users$.pipe(takeUntilDestroyed(this.destroyedRef)).subscribe((users) => this.shape('tag.mitarbeiter', users));
  }
}
