import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { ExtraVereinbarungenComponent } from '.';
import { DeleteExtraVereinbarungCommandModule, PostExtraVereinbarungCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostExtraVereinbarungCommandModule, DeleteExtraVereinbarungCommandModule],
  declarations: [ExtraVereinbarungenComponent],
  exports: [ExtraVereinbarungenComponent],
})
export class ExtraVereinbarungenComponentModule {}
