import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ErforderlichesDokumentsServiceModule } from '..';
import { ErforderlichesDokumentsSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ErforderlichesDokumentsServiceModule
  ],
  declarations: [ErforderlichesDokumentsSorterComponent],
  exports: [ErforderlichesDokumentsSorterComponent],

})
export class ErforderlichesDokumentsSorterComponentModule { }
