import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { ArchivServiceModule } from '..';
import { ArchivListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    ArchivServiceModule
  ],
  declarations: [ArchivListComponent],
  exports: [ArchivListComponent],

})
export class ArchivListComponentModule { }
