<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="feld.search$.next($event)" [search]="feld.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-feld-filter [style.width]="'100%'"></fa-kt-feld-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-feld-sorter [style.width]="'100%'"></fa-kt-feld-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-feld-list [style.width]="'100%'" (select)="setFeld($event)" [loading]="$loading | async"></fa-kt-feld-list>


  <fa-kt-post-feld-option [style.width]="'100%'"  [value]="$postFeldOption | async" (finished)="finishedPostFeldOption($event)"></fa-kt-post-feld-option>

  <fa-kt-delete-feld [style.width]="'100%'"  [value]="$deleteFeld | async" (finished)="finishedDeleteFeld($event)"></fa-kt-delete-feld>

  <fa-kt-post-ordne-feld-optionen [style.width]="'100%'"  [value]="$postOrdneFeldOptionen | async" (finished)="finishedPostOrdneFeldOptionen($event)"></fa-kt-post-ordne-feld-optionen>

  <fa-kt-post-feld [style.width]="'100%'"  [value]="$postFeld | async" (finished)="finishedPostFeld($event)"></fa-kt-post-feld>

  <fa-kt-delete-feld-option [style.width]="'100%'"  [value]="$deleteFeldOption | async" (finished)="finishedDeleteFeldOption($event)"></fa-kt-delete-feld-option>


  <fa-kt-felder [style.width]="'100%'" ></fa-kt-felder>


    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="felder" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <div *nzSpaceItem>
        <fa-kt-delete-feld [style.width]="'100%'" [value]="$deleteFeld | async" (finished)="finishedDeleteFeld()"></fa-kt-delete-feld>
      </div>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <fa-kt-post-feld [style.width]="'100%'" [value]="$postFeld | async" [optionen]="(feld.result$ | async)?.feldOptionen" (finished)="finishedPostFeld($event)"></fa-kt-post-feld>
    </nz-col>
    <nz-col nzSpan="24" *ngIf="feldHasOptionen((feld.result$ | async)?.feld)">
      <nz-list nzSize="large" [nzLoading]="$loading | async" cdkDropList (cdkDropListDropped)="drop($event)">
        <nz-list-item *ngFor="let option of (feld.result$ | async)?.feldOptionen | order:'order'" cdkDrag>
          <nz-list-item-meta-title>
            <a (click)="openFeldOption(option)"> {{ option.option }} </a>
          </nz-list-item-meta-title>
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <a (click)="openFeldOption(option)"><i nz-icon nzType="edit" nzTheme="outline"></i></a>
              <a style="margin-left: 16px" cdkDragHandle><i nz-icon nzType="menu" nzTheme="outline"></i></a>
            </nz-list-item-action>
          </ul>
        </nz-list-item>
        <nz-list-item>
          <button nz-button (click)="openFeldOption({})" nzType="dashed" [disabled]="!(feld.result$ | async)?.feld.id">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            Option hinzufügen
          </button>
        </nz-list-item>
      </nz-list>
    </nz-col>
    <nz-col nzSpan="24">
      <pbc-meta [value]="($postFeld | async)?.feld"></pbc-meta>
    </nz-col>
  </nz-row>
</nz-content>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="openFeldOption(null)" [nzVisible]="!!($postFeldOption | async)">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle> Feld-Option </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb> </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-delete-feld-option [style.width]="'100%'" [value]="$deleteFeldOption | async" (finished)="finishedDeleteFeldOption()"></fa-kt-delete-feld-option>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-post-feld-option
        [style.width]="'100%'"
        [value]="$postFeldOption | async"
        (finished)="finishedPostFeldOption()"
        [art]="(feld.result$ | async)?.feld.art"
        [feld]="(feld.result$ | async)?.feld.id"
      ></fa-kt-post-feld-option>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
    <pbc-meta [value]="($postFeldOption | async)?.feldOption"></pbc-meta>
  </ng-template>
</nz-modal>
