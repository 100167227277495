import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BerichtServiceModule } from '..';
import { BerichtSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtServiceModule
  ],
  declarations: [BerichtSorterComponent],
  exports: [BerichtSorterComponent],

})
export class BerichtSorterComponentModule { }
