import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { AppEnvironment, APP_CONFIG, checkSpecialBrowser } from '../common';

const storeAuthStateInCookie = checkSpecialBrowser();

export function MSALGuardConfigFactory(environment: AppEnvironment): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.auth.scopes,
    },
  };
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
}

export function MSALInstanceFactory(environment: AppEnvironment): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      ...environment.auth,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie,
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  });
}

import { LIB_FilterModule, LIB_HttpsModule } from '..';

export function MSALInterceptorConfigFactory(environment: AppEnvironment): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [environment.serverURL + '*', ['User.Read']],
      ['https://graph.microsoft.com/v1.0/drives/*', ['Files.Read', 'Files.Read.All', 'Files.ReadWrite', 'Files.ReadWrite.All', 'Sites.Read.All', 'Sites.ReadWrite.All']],
      ['https://graph.microsoft.com/v1.0/search/query', ['Calendars.Read', 'ExternalItem.Read.All', 'Files.Read.All', 'Mail.Read', 'Sites.Read.All']],
      ['https://graph.microsoft.com/v1.0/admin/serviceAnnouncement/healthOverviews*', ['ServiceHealth.Read.All']],
      [
        'https://graph.microsoft.com/v1.0/admin/serviceAnnouncement/healthOverviews*',
        [
          'Sites.ReadWrite.All',
          'Sites.Read.All',
          'Files.Read',
          'Files.Read.All',
          'Files.ReadWrite',
          'Files.ReadWrite.All',
          'ServiceHealth.Read.All',
          'Calendars.Read',
          'ExternalItem.Read.All',
          'Mail.Read',
        ],
      ],
      [
        'https://graph.microsoft.com/v1.0/me*',
        [
          'User.Read',
          'Directory.AccessAsUser.All',
          'Directory.Read.All',
          'Directory.ReadWrite.All',
          'User.Read.All',
          'User.ReadBasic.All',
          'User.ReadWrite',
          'User.ReadWrite.All',
          'Contacts.Read',
          'Contacts.Read.Shared',
          'Contacts.ReadWrite',
          'Contacts.ReadWrite.Shared',
        ],
      ],
      ['https://graph.microsoft.com/v1.0/contacts*', ['Directory.Read.All', 'Directory.ReadWrite.All', 'OrgContact.Read.All']],
      ['https://graph.microsoft.com/v1.0/contactFolders*', ['Directory.Read.All', 'Directory.ReadWrite.All', 'OrgContact.Read.All']],
      [
        'https://graph.microsoft.com/v1.0/groups*',
        ['Directory.AccessAsUser.All', 'Directory.Read.All', 'Directory.ReadWrite.All', 'Group.ReadWrite.All', 'Group.Read.All', 'Group.ReadWrite.All', 'GroupMember.Read.All'],
      ],
      [
        'https://graph.microsoft.com/v1.0/users*',
        [
          'DeviceManagementApps.Read.All',
          'DeviceManagementApps.ReadWrite.All',
          'DeviceManagementConfiguration.Read.All',
          'DeviceManagementConfiguration.ReadWrite.All',
          'DeviceManagementManagedDevices.Read.All',
          'DeviceManagementManagedDevices.ReadWrite.All',
          'DeviceManagementServiceConfig.Read.All',
          'DeviceManagementServiceConfig.ReadWrite.All',
          'Directory.AccessAsUser.All',
          'Directory.Read.All',
          'Directory.ReadWrite.All',
          'User.Read.All',
          'User.ReadBasic.All',
          'User.ReadWrite.All',
        ],
      ],
      ['https://graph.microsoft.com/v1.0/servicePrincipals*', ['Application.Read.All', 'Application.ReadWrite.All', 'Directory.AccessAsUser.All', 'Directory.Read.All', 'Directory.ReadWrite.All']],
    ]),
  };
}

import { AdSettingsComponent, AuthPopupMenuComponent, PageAuthenticatorComponent, UserSelectionComponent } from './components';
import { AuthService } from './services';

@NgModule({
  imports: [
    LIB_HttpsModule,
    LIB_FilterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzMessageModule,
    NzNotificationModule,
    NzLayoutModule,
    NzGridModule,
    NzSpaceModule,
    NzAvatarModule,
    NzPopoverModule,
    NzSpinModule,
    RouterModule,
    MsalModule,
    NzNotificationModule,
    NzCollapseModule,
    NzTagModule,
    NzCheckboxModule,
    NzBadgeModule,
    NzButtonModule,
    NzMenuModule,
    NzIconModule,
    NzFormModule,
    NzToolTipModule,
  ],
  declarations: [PageAuthenticatorComponent, AdSettingsComponent, AuthPopupMenuComponent, UserSelectionComponent],
  exports: [PageAuthenticatorComponent, AdSettingsComponent, AuthPopupMenuComponent, UserSelectionComponent, MsalModule],
})
export class LIB_AuthModule {
  static forRoot(): ModuleWithProviders<LIB_AuthModule> {
    return {
      ngModule: LIB_AuthModule,
      providers: [
        MsalGuard,
        MsalService,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [APP_CONFIG],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [APP_CONFIG],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [APP_CONFIG],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        AuthService,
      ],
    };
  }
}
