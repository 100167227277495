import { Component, Inject, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, FileService, ISitemap, ResolverLoadingService, SITEMAP } from 'pbc.angular';

import { IPostRechnungenHochzaehlenRequest, IPostRechnungenHochzaehlenResponse, IPostRechnungRunterzaehlenRequest, IPostRechnungRunterzaehlenResponse } from 'fa-kt.types';
import { PostRechnungenHochzaehlenCommandService, PostRechnungRunterzaehlenCommandService } from '../../commands';

@Component({
  selector: 'fa-kt-rechnungszaehler',
  templateUrl: './rechnungszaehler.component.html',
  styleUrls: ['./rechnungszaehler.component.css'],
})
export class RechnungszaehlerComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postRechnungRunterzaehlen: BehaviorSubject<IPostRechnungRunterzaehlenRequest | null> = new BehaviorSubject<IPostRechnungRunterzaehlenRequest | null>(null);
  public readonly $postRechnungenHochzaehlen: BehaviorSubject<IPostRechnungenHochzaehlenRequest | null> = new BehaviorSubject<IPostRechnungenHochzaehlenRequest | null>(null);

  zaehler: number | undefined = undefined;

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public postRechnungRunterzaehlen: PostRechnungRunterzaehlenCommandService,
    public postRechnungenHochzaehlen: PostRechnungenHochzaehlenCommandService,
  ) {}

  async ngOnInit() {
    this.subscriptions.push(
      ...[
        this.route.queryParams.subscribe(async (params: Params) => {}),
        /* << subscriptions    */
        /*    subscriptions >> */
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  async finishedPostRechnungRunterzaehlen(response?: IPostRechnungRunterzaehlenResponse) {
    /* << after post-rechnung-runterzaehlen    */
    this.zaehler = (response as any).zaehler;
    /*    after post-rechnung-runterzaehlen >> */
  }

  async finishedPostRechnungenHochzaehlen(response?: IPostRechnungenHochzaehlenResponse) {
    /* << after post-rechnungen-hochzaehlen    */
    this.zaehler = (response as any).zaehler;
    /*    after post-rechnungen-hochzaehlen >> */
  }

  /* << methods    */
  async countUp() {
    await this.submitPostRechnungenHochzaehlen({});
  }
  async countDown() {
    await this.submitPostRechnungRunterzaehlen({});
  }
  /*    methods >> */

  public async submitPostRechnungRunterzaehlen(request?: IPostRechnungRunterzaehlenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postRechnungRunterzaehlen.getValue();
    if (payload) {
      try {
        const response = await this.postRechnungRunterzaehlen.request(payload);
        await this.finishedPostRechnungRunterzaehlen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }

  public async submitPostRechnungenHochzaehlen(request?: IPostRechnungenHochzaehlenRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postRechnungenHochzaehlen.getValue();
    if (payload) {
      try {
        const response = await this.postRechnungenHochzaehlen.request(payload);
        await this.finishedPostRechnungenHochzaehlen(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
}
