import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Params, Router } from "@angular/router";

import { NzButtonType } from "ng-zorro-antd/button";

import { getEntryFromSitemap } from "../../functions";
import { ActionService } from "../../services";
import { ISitemap, ISitemapContext, ISitemapPage, SITEMAP } from "../../types";

@Component({
  selector: 'pbc-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnDestroy, OnInit {

  description: string = '';
  @Input() set text(text: string) {
    this.description = text;
    if (this.description && this.site && (this.site as ISitemapPage).url) {
      this.actions.unsubscribe(`Zu ${this.site.title}`);
      this.actions.subscribe({ key: `Zu ${this.description}`, action: async () => await this.navigate() });
    }
  }
  @Input() icon = '';
  @Input() type: NzButtonType = 'default';

  @Input() set page(page: string) {
    this.site = getEntryFromSitemap(this.sitemap, page);
    if (this.site && (this.site as ISitemapPage).url && !this.description) {
      this.actions.subscribe({ key: `Zu ${this.site.title}`, action: async () => await this.navigate() });
    }
  }
  site: ISitemapPage|ISitemapContext|undefined;
  @Input()
  queryParams: Params = {};

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public router: Router,
    public actions: ActionService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.site && (this.site as ISitemapPage)) {
      this.actions.unsubscribe(`Zu ${this.description ? this.description : this.site.title}`);
    }
  }

  async navigate() {
   if (this.site && (this.site as ISitemapContext)) {
     await this.router.navigate((this.site as ISitemapPage).url, { queryParams: this.queryParams });
   }
  }

}

