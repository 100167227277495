import { NgModule } from '@angular/core';

import { DeleteDateiArtCommandModule, PostDateiArtCommandModule } from './commands';
import { DateiArtsQueryModule } from './query';

@NgModule({
  declarations: [],
  imports: [PostDateiArtCommandModule, DeleteDateiArtCommandModule, DateiArtsQueryModule],
  exports: [PostDateiArtCommandModule, DeleteDateiArtCommandModule, DateiArtsQueryModule],
})
export class DateiArtsSettingsModule {}
