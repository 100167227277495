<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="felder.search$.next($event)" [search]="felder.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-felder-filter [style.width]="'100%'"></fa-kt-felder-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-felder-sorter [style.width]="'100%'"></fa-kt-felder-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-felder-list [style.width]="'100%'" (select)="setFelder($event)" [loading]="$loading | async"></fa-kt-felder-list>


  <fa-kt-post-referenzen-hochladen [style.width]="'100%'"  [value]="$postReferenzenHochladen | async" (finished)="finishedPostReferenzenHochladen($event)"></fa-kt-post-referenzen-hochladen>



    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="feld" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubtitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-space>
      <span *nzSpaceItem style="line-height: 32px"> {{ (felder.result$ | async)?.felder.length }} / {{ (felder.response$ | async)?.felder.length }} </span>
      <button *nzSpaceItem nz-button (click)="export()">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>
        Exportieren
      </button>
      <button *nzSpaceItem nz-button nzType="primary" (click)="setFelder('new')">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
        Feld hinzufügen
      </button>
    </nz-space>
    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <nz-row>
      <nz-col nzSpan="24">
        <fa-kt-post-referenzen-hochladen [style.width]="'100%'" [value]="$postReferenzenHochladen | async" (finished)="finishedPostReferenzenHochladen($event)"></fa-kt-post-referenzen-hochladen>
        <br />
      </nz-col>
      <nz-col nzSpan="24">
        <pbc-search-bar [style.width]="'100%'" (searchChange)="felder.search$.next($event)" [search]="felder.search$ | async"></pbc-search-bar>
      </nz-col>
    </nz-row>
    <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
      <nz-col style="order: 1; flex-grow: 2; flex-basis: auto">
        <fa-kt-felder-filter [style.width]="'100%'"></fa-kt-felder-filter>
      </nz-col>
      <nz-col style="order: 2; flex-grow: 1; flex-basis: auto">
        <fa-kt-felder-sorter [style.width]="'100%'"></fa-kt-felder-sorter>
      </nz-col>
    </nz-row>
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <!--- << page-content    -->
    <nz-col nzSpan="24">
      <fa-kt-felder-list height="calc(100vh - 524px)" [style.width]="'100%'" (select)="setFelder($event)" [loading]="$loading | async"></fa-kt-felder-list>
    </nz-col>
    <!---    page-content >> -->
  </nz-row>
</nz-content>
