import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteKundenArtCommandServiceModule } from '..';
import { DeleteKundenArtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteKundenArtCommandServiceModule
  ],
  declarations: [DeleteKundenArtCommandComponent],
  exports: [DeleteKundenArtCommandComponent],

})
export class DeleteKundenArtCommandComponentModule { }
