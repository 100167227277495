import { NgModule } from '@angular/core';

import { KonstantesSettingsModule } from '.';

@NgModule({
  declarations: [],
  imports: [KonstantesSettingsModule],
  exports: [KonstantesSettingsModule],
})
export class EinstellungenSettingsModule {}
