import { Component, Inject } from '@angular/core';
import { ISitemap, SITEMAP } from 'pbc.angular';
import { IFilterConfig, ISelection } from 'pbc.types';

import get from 'lodash/get';
import { Subscription } from 'rxjs';

import { IProjektResponse, ProjektQueryFilterConfig } from 'fa-kt.types';

import { ProjektService } from '../service';

@Component({
  selector: 'fa-kt-projekt-filter',
  templateUrl: './projekt-filter.component.html',
  styleUrls: ['./projekt-filter.component.css'],
})
export class ProjektFilterComponent {
  readonly subscriptions: Subscription[] = [];

  public ProjektFilterConfig: IFilterConfig<IProjektResponse>;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public projekt: ProjektService,
  ) {
    this.ProjektFilterConfig = ProjektQueryFilterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
    /* << update mapper    */
    /*    update mapper >> */
  }

  ngOnInit() {
    this.subscriptions.push();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  private shape(field: string, shapes: ISelection[]): void {
    const index = this.ProjektFilterConfig.findIndex((f) => f.key === field);
    if (index < 0) {
      return;
    }
    this.ProjektFilterConfig[index].selections = shapes;
  }
}
