import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FormularServiceModule } from '..';
import { FormularSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormularServiceModule
  ],
  declarations: [FormularSorterComponent],
  exports: [FormularSorterComponent],

})
export class FormularSorterComponentModule { }
