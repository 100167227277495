import { ArrayReferenceFilterConfig, IArrayReferenceFilter, IFilter, IFilterConfig, ISelection, IShape } from 'pbc.types';

import { IBewertungsAnlass, IFeld, IKunde, IKundenArt, IObjektArt } from 'fa-kt.types';
import { IFormularResponse } from '.';

export interface IFormularQueryFilter extends IFilter<IFormularResponse> {
  'formular.objektArten'?: IArrayReferenceFilter<IObjektArt>;
  'formular.bewertungsAnlaesse'?: IArrayReferenceFilter<IBewertungsAnlass>;
  'formular.kundenArten'?: IArrayReferenceFilter<IKundenArt>;
  'formular.kunden'?: IArrayReferenceFilter<IKunde>;
  'formular.ohneFelder'?: IArrayReferenceFilter<IFeld>;
}

export const FormularQueryFilterConfig: IFilterConfig<IFormularResponse> = [
  ArrayReferenceFilterConfig<IObjektArt>('formular.objektArten', 'FORMULARE.Models.FORMULAR.Propertys.OBJEKT_ARTEN.title'),
  ArrayReferenceFilterConfig<IBewertungsAnlass>('formular.bewertungsAnlaesse', 'FORMULARE.Models.FORMULAR.Propertys.BEWERTUNGS_ANLAESSE.title'),
  ArrayReferenceFilterConfig<IKundenArt>('formular.kundenArten', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN_ARTEN.title'),
  ArrayReferenceFilterConfig<IKunde>('formular.kunden', 'FORMULARE.Models.FORMULAR.Propertys.KUNDEN.title'),
  ArrayReferenceFilterConfig<IFeld>('formular.ohneFelder', 'FORMULARE.Models.FORMULAR.Propertys.OHNE_FELDER.title'),
];

export interface IFormularQueryShapes extends IShape {
  'formular.objektArten': ISelection[];
  'formular.bewertungsAnlaesse': ISelection[];
  'formular.kundenArten': ISelection[];
  'formular.kunden': ISelection[];
  'formular.ohneFelder': ISelection[];
}

export type IFormularQueryField =
  | 'formular.id'
  | 'formular.name'
  | 'formular.initialen'
  | 'formular.objektArten'
  | 'formular.bewertungsAnlaesse'
  | 'formular.kundenArten'
  | 'formular.kunden'
  | 'formular.ohneFelder'
  | 'formular.kommentar'
  | '';
