import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, combineLatest, skip, Subscription } from 'rxjs';

import { isEqual } from 'lodash';
import { ISelection, ISorter, ISorterConfig } from 'pbc.types';

@Component({
  selector: 'pbc-sorter',
  templateUrl: './sorter.component.html',
  styleUrls: ['./sorter.component.css'],
})
export class SorterComponent implements OnInit {
  subscriptions: Subscription[] = [];

  @Input() set config(config: ISorterConfig | undefined) {
    if (!config) return;
    this._config = config;
    this.$config.next(config);
  }

  $unused = new BehaviorSubject<ISelection[]>([]);
  $newSorterField: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  $config: BehaviorSubject<ISorterConfig> = new BehaviorSubject<ISorterConfig>([]);
  _config: ISorterConfig | undefined;

  _sorter: ISorterConfig = [];
  _current: ISorter = {};
  sorter$: BehaviorSubject<ISorterConfig> = new BehaviorSubject<ISorterConfig>([]);
  @Output() sorterChange: EventEmitter<ISorter> = new EventEmitter<ISorter>();

  @Input() set sorter(sorter: ISorter) {
    if (this._config && sorter && !isEqual(sorter, this._current)) {
      this._sorter = Object.entries(sorter)
        .map(([key, sorter]) => ({ sorter, config: this._config.find(({ key: id }) => key === id) }))
        .filter(({ config, sorter }) => !!config && !!sorter)
        .map(({ config, sorter }) => ({ ...config, ascending: sorter.ascending, index: sorter.order, readonly: sorter.readonly }));
      this.sorter$.next(this._sorter);
      this._current = sorter;
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      ...[
        this.sorter$
          .pipe(skip(1))
          .subscribe((sorter) => this.sorterChange.emit(sorter.reduce((o, sorter) => ({ ...o, [sorter.key]: { ascending: sorter.ascending, order: sorter.index, readyonly: sorter.readonly } }), {}))),
        combineLatest([this.$config, this.sorter$]).subscribe(([config, sorter]) => {
          if (!config) return this.$unused.next([]);
          this.$unused.next(config.filter((x) => sorter.map((s) => s.key).indexOf(x.key) < 0).map((s) => ({ label: s.title, value: s.key })));
        }),
        this.$newSorterField.subscribe((key: string | null) => {
          if (!key) return;
          this.createSorter(key);
          this.$newSorterField.next(undefined);
        }),
      ],
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(($) => $.unsubscribe());
  }

  createSorter(key: string) {
    if (!this._config) return;
    this.sorter$.next(
      this.sorter$
        .getValue()
        .concat([this._config.find((s) => s.key === key) as any])
        .map((unit, index) => ({ ...unit, index })),
    );
  }

  removeSorter(field: string) {
    this.sorter$.next(
      this.sorter$
        .getValue()
        .filter((s) => s.key !== field)
        .map((unit, index) => ({ ...unit, index })),
    );
  }

  reorder(event: any | CdkDragDrop<string[]>) {
    const sorts = this.sorter$.getValue();
    moveItemInArray(sorts, event.previousIndex, event.currentIndex);
    this.sorter$.next(sorts);
  }

  emit() {
    this.sorter$.next(this._sorter);
  }

  toggleAscending(index: number) {
    this._sorter[index].ascending = !this._sorter[index].ascending;
    this.emit();
  }
}
