import { Component } from '@angular/core';
import { OffeneGutachtenService } from '../service';

@Component({
  selector: 'fa-kt-saved-offene-gutachten-filters',
  templateUrl: './saved-offene-gutachten-filters.component.html',
  styleUrls: ['./saved-offene-gutachten-filters.component.css'],
})
export class SavedOffeneGutachtenFiltersComponent {
  constructor(public offeneGutachten: OffeneGutachtenService) {}
}
