<!---
  <div formGroupName="honorarVereinbarung">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
        <ng-template #KundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="kunde" id="kunde" [shapes]="postHonorarVereinbarung.honorarVereinbarungKunde | async" [placeholder]="command.Inputs.KUNDE.placeholder" cdkFocusInitial required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bisMarktwert">{{ command.Inputs.BIS_MARKTWERT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BisMarktwertErrorTip">
        <ng-template #BisMarktwertErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BIS_MARKTWERT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="bisMarktwert" id="bisMarktwert" [placeholder]="command.Inputs.BIS_MARKTWERT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lohnWohnen">{{ command.Inputs.LOHN_WOHNEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LohnWohnenErrorTip">
        <ng-template #LohnWohnenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LOHN_WOHNEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="lohnWohnen" id="lohnWohnen" [placeholder]="command.Inputs.LOHN_WOHNEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lohnGewerbe">{{ command.Inputs.LOHN_GEWERBE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LohnGewerbeErrorTip">
        <ng-template #LohnGewerbeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LOHN_GEWERBE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="lohnGewerbe" id="lohnGewerbe" [placeholder]="command.Inputs.LOHN_GEWERBE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lohnSpezial">{{ command.Inputs.LOHN_SPEZIAL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LohnSpezialErrorTip">
        <ng-template #LohnSpezialErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LOHN_SPEZIAL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="lohnSpezial" id="lohnSpezial" [placeholder]="command.Inputs.LOHN_SPEZIAL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="honorarVereinbarung">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="kunde" id="kunde"/>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bisMarktwert">{{ command.Inputs.BIS_MARKTWERT.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BisMarktwertErrorTip">
          <ng-template #BisMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BIS_MARKTWERT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" style="width: 300px;" formControlName="bisMarktwert" id="bisMarktwert" [placeholder]="command.Inputs.BIS_MARKTWERT.placeholder"/>€
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lohnWohnen">{{ command.Inputs.LOHN_WOHNEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LohnWohnenErrorTip">
          <ng-template #LohnWohnenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LOHN_WOHNEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" style="width: 300px;" formControlName="lohnWohnen" id="lohnWohnen" [placeholder]="command.Inputs.LOHN_WOHNEN.placeholder"/>€
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lohnGewerbe">{{ command.Inputs.LOHN_GEWERBE.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LohnGewerbeErrorTip">
          <ng-template #LohnGewerbeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LOHN_GEWERBE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" style="width: 300px;" formControlName="lohnGewerbe" id="lohnGewerbe" [placeholder]="command.Inputs.LOHN_GEWERBE.placeholder"/>€
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lohnSpezial">{{ command.Inputs.LOHN_SPEZIAL.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LohnSpezialErrorTip">
          <ng-template #LohnSpezialErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LOHN_SPEZIAL.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 150px" style="width: 300px;" formControlName="lohnSpezial" id="lohnSpezial" [placeholder]="command.Inputs.LOHN_SPEZIAL.placeholder"/>€
        </nz-form-control>
      </nz-form-item>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Honorar-Vereinbarung übernehmen
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
