import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AppEnvironment, APP_BUILD, APP_CONFIG, APP_VERSION, ISitemap, SITEMAP } from '../../types';

@Component({
  selector: 'pbc-admin-page',
  templateUrl: './device.page.html',
  styleUrls: ['./device.page.css'],
})
export class DevicePage implements OnInit, OnDestroy {
  stage;

  constructor(
    @Inject(APP_CONFIG) public environment: AppEnvironment,
    @Inject(APP_VERSION) public version: String,
    @Inject(APP_BUILD) public build: String,
    @Inject(SITEMAP) public sitemap: ISitemap,
  ) {
    this.stage = environment.stage;
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
