import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { PostCommandService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IPostObjektArtCommandShapes, IPostObjektArtRequest, IPostObjektArtResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class PostObjektArtCommandService extends PostCommandService<IPostObjektArtRequest, IPostObjektArtResponse, IPostObjektArtCommandShapes> {
  readonly route = 'objekte/settings/objekt-art';
  override dependencies = ['objekte/written-objekt-nutzung', 'objekte/removed-objekt-nutzung'];

  async getObjektArtsObjektNutzung(value: string): Promise<ISelection | undefined> {
    return new Promise((resolve) => {
      this.objektArtsObjektNutzung.pipe(first()).subscribe((objektArtsObjektNutzung) => resolve(objektArtsObjektNutzung.find((a) => a.value === value)));
    });
  }

  get objektArtsObjektNutzung(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['objektArts.objektNutzung'] as ISelection[]) : [])));
  }
}
