import { Injectable } from '@angular/core';
import { ITourenSelectionRequest } from 'fa-kt.types';

import { SelectionService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class TourenSelectService extends SelectionService<ITourenSelectionRequest> {
  route = 'besichtigungen/touren';
  override dependencies = [
    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
  ];

  static instance: TourenSelectService;

  constructor() {
    super();
    if (!TourenSelectService.instance) TourenSelectService.instance = this;
    return TourenSelectService.instance;
  }
}
