import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy } from 'lodash';

import get from 'lodash/get';

import { ISelection } from 'pbc.types';
import { PipeLoadingService } from '../services';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  constructor(private loading: PipeLoadingService) {}

  transform(items?: Array<any> | null, term?: any, key = '_search', shapes?: ISelection): Array<any> {
    if (!items || items.length === 0) return [];
    if (!term || term.length === 0) return items;
    this.loading.loading();
    let results: Array<any>;
    let replacements: ISelection[] = [];
    if (key !== '_search' && shapes) {
      replacements = uniqBy(
        [].concat(
          ...Object.entries(shapes)
            .filter(([key]) => key[0] !== '_' && key !== 'id')
            .map(([key]) => get(shapes, key, 'unknown'))
            .filter((value) => value !== 'unknown'),
        ),
        'label',
      );
    }
    term = this.equalize(term, replacements, false);
    const scope = items.map((original) => ({ original, a: this.resolve(original, key, replacements) }));
    results = scope.filter((r) => this.matches(term, r.a)).map((result) => result.original);
    this.loading.loaded();
    return results;
  }

  matches(term: string, search: string): boolean {
    return term
      .trim()
      .split(' ')
      .every((t) => t && t.length > 0 && search.includes(t));
  }

  resolve(item: any, key: string, replacements: ISelection[] = []): string {
    const resolved: string = key ? get(item, key, JSON.stringify(item)) : JSON.stringify(item);
    return this.equalize(resolved, replacements);
  }

  equalize(term: string, replacements: ISelection[] = [], join = true): string {
    replacements.filter(({ label, value }) => !!value && !!label).forEach(({ label, value }) => (term = term.split(value).join(label)));
    if (join) term = term.split(' ').join('');
    term = term.toLowerCase();
    return term;
  }
}
