import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteFeldUnterkategorieCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteFeldUnterkategorieCommandService],
  exports: []
})
export class DeleteFeldUnterkategorieCommandServiceModule { }
