import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { HerausgebersServiceModule } from '..';
import { HerausgebersSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    HerausgebersServiceModule
  ],
  declarations: [HerausgebersSorterComponent],
  exports: [HerausgebersSorterComponent],

})
export class HerausgebersSorterComponentModule { }
