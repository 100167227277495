import { NgModule } from '@angular/core';

import { DeleteObjektArtCommandModule, ObjektArtsQueryModule, PostObjektArtCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostObjektArtCommandModule, DeleteObjektArtCommandModule, ObjektArtsQueryModule],
  exports: [PostObjektArtCommandModule, DeleteObjektArtCommandModule, ObjektArtsQueryModule],
})
export class ObjektArtsSettingsModule {}
