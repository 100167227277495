import { Component, Inject } from '@angular/core';

import get from 'lodash/get';

import { ISitemap, SITEMAP } from 'pbc.angular';
import { ISorterConfig } from 'pbc.types';

import { RahmenAnpassungenQuerySorterConfig } from 'fa-kt.types';
import { RahmenAnpassungenService } from '.';

@Component({
  selector: 'fa-kt-rahmen-anpassungen-sorter',
  template: `<pbc-sorter [config]="RahmenAnpassungenSorterConfig" [sorter]="rahmenAnpassungen.sorter$ | async" (sorterChange)="rahmenAnpassungen.sorter$.next($event)"></pbc-sorter>`,
})
export class RahmenAnpassungenSorterComponent {
  public RahmenAnpassungenSorterConfig: ISorterConfig;

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public rahmenAnpassungen: RahmenAnpassungenService,
  ) {
    this.RahmenAnpassungenSorterConfig = RahmenAnpassungenQuerySorterConfig.map((filter) => ({ ...filter, title: get(sitemap, filter.title, filter.title) as string }));
  }
}
