import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteZeitCommandComponent } from '.';
import { DeleteZeitCommandServiceModule } from '..';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteZeitCommandServiceModule
  ],
  declarations: [DeleteZeitCommandComponent],
  exports: [DeleteZeitCommandComponent],

})
export class DeleteZeitCommandComponentModule { }
