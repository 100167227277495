import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostRolleCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostRolleCommandService],
  exports: []
})
export class PostRolleCommandServiceModule { }
