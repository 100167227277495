import { NgModule } from '@angular/core';
import {
  KundenGruppesFilterComponentModule,
  KundenGruppesSorterComponentModule,
  KundenGruppesListComponentModule
} from '.';

@NgModule({
  imports: [
    KundenGruppesFilterComponentModule,
    KundenGruppesSorterComponentModule,
    KundenGruppesListComponentModule
  ],
  exports: [
    KundenGruppesFilterComponentModule,
    KundenGruppesSorterComponentModule,
    KundenGruppesListComponentModule
  ],
})
export class KundenGruppesQueryModule { }