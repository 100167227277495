import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LibsCommonAngularModule } from 'pbc.angular';
import { DokumenteCommandsModule } from './commands';
import { DokumenteComponentsModule } from './components';
import { DokumenteQuerysModule } from './querys';
import {
  DateiArtsPage,
  DateiArtsPageResolver,
  DateiArtsService,
  DateiArtsServiceModule,
  DokumenteSettingsModule,
  ErforderlichesDokumentsPage,
  ErforderlichesDokumentsPageResolver,
  ErforderlichesDokumentsService,
  ErforderlichesDokumentsServiceModule,
} from './settings';

const routes: Routes = [
  {
    path: 'dokumente',
    children: [
      {
        path: 'settings',
        children: [
          {
            path: 'datei-arts',
            component: DateiArtsPage,
            resolve: {
              querys: DateiArtsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_DATEI_ARTS'],
                  write: ['POST_DATEI_ART', 'DELETE_DATEI_ART'],
                },
              },
            },
          },
          {
            path: 'erforderliches-dokuments',
            component: ErforderlichesDokumentsPage,
            resolve: {
              querys: ErforderlichesDokumentsPageResolver,
            },
            canActivate: [AuthGuard],
            data: {
              access: {
                roles: {
                  read: ['GET_ERFORDERLICHES_DOKUMENTS'],
                  write: ['POST_ERFORDERLICHES_DOKUMENT', 'DELETE_ERFORDERLICHES_DOKUMENT'],
                },
              },
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  declarations: [DateiArtsPage, ErforderlichesDokumentsPage],
  imports: [
    LibsCommonAngularModule,
    DokumenteSettingsModule,
    DokumenteComponentsModule,
    DokumenteCommandsModule,
    DokumenteQuerysModule,
    DateiArtsServiceModule,
    ErforderlichesDokumentsServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [DokumenteComponentsModule, DokumenteSettingsModule, DokumenteCommandsModule, DokumenteQuerysModule, DateiArtsServiceModule, ErforderlichesDokumentsServiceModule],
})
export class DokumenteModule {
  static forRoot(): ModuleWithProviders<DokumenteModule> {
    return {
      ngModule: DokumenteModule,
      providers: [DateiArtsService, ErforderlichesDokumentsService],
    };
  }
}
