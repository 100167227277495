import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { FeldKategoriesServiceModule } from '..';
import { FeldKategoriesListComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldKategoriesServiceModule
  ],
  declarations: [FeldKategoriesListComponent],
  exports: [FeldKategoriesListComponent],

})
export class FeldKategoriesListComponentModule { }
