import { Injectable } from '@angular/core';

import { DetailQueryService } from 'pbc.angular';

import { IRahmenAnpassungQueryShapes, IRahmenAnpassungRequest, IRahmenAnpassungResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class RahmenAnpassungService extends DetailQueryService<IRahmenAnpassungRequest, IRahmenAnpassungResponse, IRahmenAnpassungQueryShapes> {
  route = 'zeiten/rahmen-anpassung';
  override dependencies = ['zeiten/rahmen-anpassung-gespeichert', 'zeiten/rahmen-anpassung-entfernt'];
}
