import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IFelderQuerySorter extends ISorter {
  'feld.id'?: ISorterExpression;
  'feld.schluessel'?: ISorterExpression;
  'feld.name'?: ISorterExpression;
  'feld.kommentar'?: ISorterExpression;
  'feld.feldKategorie'?: ISorterExpression;
  'feld.feldUnterkategorie'?: ISorterExpression;
  'feld.art'?: ISorterExpression;
  'feld.format'?: ISorterExpression;
  'feld.voreinstellung'?: ISorterExpression;
  'feldOptionen.id'?: ISorterExpression;
  'feldOptionen.feld'?: ISorterExpression;
  'feldOptionen.option'?: ISorterExpression;
  'feldOptionen.akkusativ'?: ISorterExpression;
  'feldOptionen.nominativ'?: ISorterExpression;
  'feldOptionen.dativ'?: ISorterExpression;
  'feldOptionen.genitiv'?: ISorterExpression;
  'feldOptionen.standard'?: ISorterExpression;
}

export const FelderQuerySorterConfig: ISorterConfig = [
  SorterConfig('feld.id', 'FELDER.Models.FELD.Propertys.ID.title'), 
  SorterConfig('feld.schluessel', 'FELDER.Models.FELD.Propertys.SCHLUESSEL.title'), 
  SorterConfig('feld.name', 'FELDER.Models.FELD.Propertys.NAME.title'), 
  SorterConfig('feld.kommentar', 'FELDER.Models.FELD.Propertys.KOMMENTAR.title'), 
  SorterConfig('feld.feldKategorie', 'FELDER.Models.FELD.Propertys.FELD_KATEGORIE.title'), 
  SorterConfig('feld.feldUnterkategorie', 'FELDER.Models.FELD.Propertys.FELD_UNTERKATEGORIE.title'), 
  SorterConfig('feld.art', 'FELDER.Models.FELD.Propertys.ART.title'), 
  SorterConfig('feld.format', 'FELDER.Models.FELD.Propertys.FORMAT.title'), 
  SorterConfig('feld.voreinstellung', 'FELDER.Models.FELD.Propertys.VOREINSTELLUNG.title'), 
  SorterConfig('feldOptionen.id', 'FELDER.Models.FELD_OPTION.Propertys.ID.title'), 
  SorterConfig('feldOptionen.feld', 'FELDER.Models.FELD_OPTION.Propertys.FELD.title'), 
  SorterConfig('feldOptionen.option', 'FELDER.Models.FELD_OPTION.Propertys.OPTION.title'), 
  SorterConfig('feldOptionen.akkusativ', 'FELDER.Models.FELD_OPTION.Propertys.AKKUSATIV.title'), 
  SorterConfig('feldOptionen.nominativ', 'FELDER.Models.FELD_OPTION.Propertys.NOMINATIV.title'), 
  SorterConfig('feldOptionen.dativ', 'FELDER.Models.FELD_OPTION.Propertys.DATIV.title'), 
  SorterConfig('feldOptionen.genitiv', 'FELDER.Models.FELD_OPTION.Propertys.GENITIV.title'), 
  SorterConfig('feldOptionen.standard', 'FELDER.Models.FELD_OPTION.Propertys.STANDARD.title'), 
];
