import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { DateienComponent } from '.';
import { PostDateiHochladenCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostDateiHochladenCommandModule],
  declarations: [DateienComponent],
  exports: [DateienComponent],
})
export class DateienComponentModule {}
