import { ISorter, ISorterConfig, ISorterExpression, SorterConfig } from "pbc.types";

export interface IZahlungseingangSorter extends ISorter {
  id?: ISorterExpression;
  projekt?: ISorterExpression;
  kunde?: ISorterExpression;
  rechnung?: ISorterExpression;
  summe?: ISorterExpression;
  datum?: ISorterExpression;
}

export const ZahlungseingangSorterConfig: ISorterConfig = [
  SorterConfig('id', 'Id'),
  SorterConfig('projekt', 'Projekt'),
  SorterConfig('kunde', 'Kunde'),
  SorterConfig('rechnung', 'Rechnung'),
  SorterConfig('summe', 'Summe'),
  SorterConfig('datum', 'Datum')
];
