import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { BewertungsAnlasssServiceModule } from '..';
import { BewertungsAnlasssSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    BewertungsAnlasssServiceModule
  ],
  declarations: [BewertungsAnlasssSorterComponent],
  exports: [BewertungsAnlasssSorterComponent],

})
export class BewertungsAnlasssSorterComponentModule { }
