import { Injectable } from '@angular/core';

import { IDeleteZahlungseingangRequest, IDeleteZahlungseingangResponse } from 'fa-kt.types';
import { DeleteCommandService } from 'pbc.angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteZahlungseingangCommandService extends DeleteCommandService<IDeleteZahlungseingangRequest, IDeleteZahlungseingangResponse> {
  readonly route = 'finanzen/zahlungseingang';
}
