import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteProjektArtCommandServiceModule } from '..';
import { DeleteProjektArtCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteProjektArtCommandServiceModule
  ],
  declarations: [DeleteProjektArtCommandComponent],
  exports: [DeleteProjektArtCommandComponent],

})
export class DeleteProjektArtCommandComponentModule { }
