import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';

import { KalkulationenComponent } from '.';
import { PostKalkulationHochladenCommandModule } from '../../commands';

@NgModule({
  imports: [LIB_CommonModule, PostKalkulationHochladenCommandModule],
  declarations: [KalkulationenComponent],
  exports: [KalkulationenComponent],
})
export class KalkulationenComponentModule {}
