import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListQueryService } from 'pbc.angular';
import { ISelection } from 'pbc.types';

import { IFelderQueryFilter, IFelderQueryShapes, IFelderQuerySorter, IFelderRequest, IFelderResponse } from 'fa-kt.types';

@Injectable({
  providedIn: 'root',
})
export class FelderService extends ListQueryService<IFelderRequest, IFelderResponse, IFelderQueryShapes, IFelderQueryFilter, IFelderQuerySorter> {
  contentKey: keyof IFelderResponse = 'felder';
  route = 'felder/felder';
  override dependencies = [
    'felder/referenzen-hochgeladen',
    'felder/written-feld-kategorie',
    'felder/removed-feld-kategorie',
    'felder/written-feld-unterkategorie',
    'felder/removed-feld-unterkategorie',
    'felder/feld-entfernt',
    'felder/feld-gespeichert',
    'felder/feld-option-entfernt',
    'felder/feld-option-gespeichert',
    'felder/feld-optionen-geordnet',
  ];

  getFeldFeldKategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['feld.feldKategorie']?.find((v) => v.value === value);
  }

  get feldFeldKategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feld.feldKategorie'] as ISelection[]) : [])));
  }

  getFeldFeldUnterkategorie(value: string): ISelection | undefined {
    return this.shapes$.getValue()?.['feld.feldUnterkategorie']?.find((v) => v.value === value);
  }

  get feldFeldUnterkategorie(): Observable<ISelection[]> {
    return this.shapes$.pipe(map((shapes) => (shapes ? (shapes['feld.feldUnterkategorie'] as ISelection[]) : [])));
  }
}
