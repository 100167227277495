<!---
  <div formGroupName="projekt">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bezeichnung">{{ command.Inputs.BEZEICHNUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BezeichnungErrorTip">
        <ng-template #BezeichnungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEZEICHNUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bezeichnung" id="bezeichnung" [placeholder]="command.Inputs.BEZEICHNUNG.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="nummer">{{ command.Inputs.NUMMER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NummerErrorTip">
        <ng-template #NummerErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NUMMER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="nummer" id="nummer" [placeholder]="command.Inputs.NUMMER.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="projektZaehler">{{ command.Inputs.PROJEKT_ZAEHLER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektZaehlerErrorTip">
        <ng-template #ProjektZaehlerErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT_ZAEHLER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="projektZaehler" id="projektZaehler" [nzPlaceHolder]="command.Inputs.PROJEKT_ZAEHLER.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="projektArt">{{ command.Inputs.PROJEKT_ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektArtErrorTip">
        <ng-template #ProjektArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT_ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="projektArt" id="projektArt" [shapes]="postProjekt.projektProjektArt | async" [placeholder]="command.Inputs.PROJEKT_ART.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="projektStatus">{{ command.Inputs.PROJEKT_STATUS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektStatusErrorTip">
        <ng-template #ProjektStatusErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT_STATUS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="projektStatus" id="projektStatus" [shapes]="postProjekt.projektProjektStatus | async" [placeholder]="command.Inputs.PROJEKT_STATUS.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="standort">{{ command.Inputs.STANDORT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StandortErrorTip">
        <ng-template #StandortErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STANDORT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="standort" id="standort" [shapes]="postProjekt.projektStandort | async" [placeholder]="command.Inputs.STANDORT.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="beauftragung">{{ command.Inputs.BEAUFTRAGUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BeauftragungErrorTip">
        <ng-template #BeauftragungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEAUFTRAGUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="beauftragung" id="beauftragung"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="deadline">{{ command.Inputs.DEADLINE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DeadlineErrorTip">
        <ng-template #DeadlineErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DEADLINE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="deadline" id="deadline"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kundenAnfertigung">{{ command.Inputs.KUNDEN_ANFERTIGUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenAnfertigungErrorTip">
        <ng-template #KundenAnfertigungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_ANFERTIGUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="kundenAnfertigung" id="kundenAnfertigung"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="keineBesichtigungErforderlich">{{ command.Inputs.KEINE_BESICHTIGUNG_ERFORDERLICH.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KeineBesichtigungErforderlichErrorTip">
        <ng-template #KeineBesichtigungErforderlichErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KEINE_BESICHTIGUNG_ERFORDERLICH.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="keineBesichtigungErforderlich" id="keineBesichtigungErforderlich"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="besichtigungen">{{ command.Inputs.BESICHTIGUNGEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BesichtigungenErrorTip">
        <ng-template #BesichtigungenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BESICHTIGUNGEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="besichtigungen" id="besichtigungen" [shapes]="postProjekt.projektBesichtigungen | async" [placeholder]="command.Inputs.BESICHTIGUNGEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachten">{{ command.Inputs.GUTACHTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
        <ng-template #GutachtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GUTACHTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value mode="multiple" formControlName="gutachten" id="gutachten" [shapes]="postProjekt.projektGutachten | async" [placeholder]="command.Inputs.GUTACHTEN.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="pruefung">{{ command.Inputs.PRUEFUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PruefungErrorTip">
        <ng-template #PruefungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PRUEFUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="pruefung" id="pruefung" [shapes]="postProjekt.projektPruefung | async" [placeholder]="command.Inputs.PRUEFUNG.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
        <ng-template #KundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="kunde" id="kunde" [shapes]="postProjekt.projektKunde | async" [placeholder]="command.Inputs.KUNDE.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abteilung">{{ command.Inputs.ABTEILUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbteilungErrorTip">
        <ng-template #AbteilungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABTEILUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="abteilung" id="abteilung" [shapes]="postProjekt.projektAbteilung | async" [placeholder]="command.Inputs.ABTEILUNG.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fond">{{ command.Inputs.FOND.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FondErrorTip">
        <ng-template #FondErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FOND.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="fond" id="fond" [shapes]="postProjekt.projektFond | async" [placeholder]="command.Inputs.FOND.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bank">{{ command.Inputs.BANK.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BankErrorTip">
        <ng-template #BankErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BANK.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="bank" id="bank" [shapes]="postProjekt.projektBank | async" [placeholder]="command.Inputs.BANK.placeholder" ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="objektArt">{{ command.Inputs.OBJEKT_ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektArtErrorTip">
        <ng-template #ObjektArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT_ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <pbc-select-value formControlName="objektArt" id="objektArt" [shapes]="postProjekt.projektObjektArt | async" [placeholder]="command.Inputs.OBJEKT_ART.placeholder" required=true ></pbc-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nachlass">{{ command.Inputs.NACHLASS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NachlassErrorTip">
        <ng-template #NachlassErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NACHLASS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="nachlass" id="nachlass" [nzPlaceHolder]="command.Inputs.NACHLASS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kundenZeichen">{{ command.Inputs.KUNDEN_ZEICHEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenZeichenErrorTip">
        <ng-template #KundenZeichenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_ZEICHEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="kundenZeichen" id="kundenZeichen" [placeholder]="command.Inputs.KUNDEN_ZEICHEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kontakteInOutlook">{{ command.Inputs.KONTAKTE_IN_OUTLOOK.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KontakteInOutlookErrorTip">
        <ng-template #KontakteInOutlookErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KONTAKTE_IN_OUTLOOK.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="kontakteInOutlook" id="kontakteInOutlook" [placeholder]="command.Inputs.KONTAKTE_IN_OUTLOOK.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dateiVerzeichnis">{{ command.Inputs.DATEI_VERZEICHNIS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateiVerzeichnisErrorTip">
        <ng-template #DateiVerzeichnisErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEI_VERZEICHNIS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="dateiVerzeichnis" id="dateiVerzeichnis" [placeholder]="command.Inputs.DATEI_VERZEICHNIS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="notizenFuerRechnung">{{ command.Inputs.NOTIZEN_FUER_RECHNUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NotizenFuerRechnungErrorTip">
        <ng-template #NotizenFuerRechnungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOTIZEN_FUER_RECHNUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="notizenFuerRechnung" id="notizenFuerRechnung" [placeholder]="command.Inputs.NOTIZEN_FUER_RECHNUNG.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fortschritt">{{ command.Inputs.FORTSCHRITT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FortschrittErrorTip">
        <ng-template #FortschrittErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FORTSCHRITT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="fortschritt" id="fortschritt" [nzPlaceHolder]="command.Inputs.FORTSCHRITT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="strecke">{{ command.Inputs.STRECKE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StreckeErrorTip">
        <ng-template #StreckeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STRECKE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="strecke" id="strecke" [nzPlaceHolder]="command.Inputs.STRECKE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kalkulierteStrecke">{{ command.Inputs.KALKULIERTE_STRECKE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KalkulierteStreckeErrorTip">
        <ng-template #KalkulierteStreckeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KALKULIERTE_STRECKE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="kalkulierteStrecke" id="kalkulierteStrecke" [nzPlaceHolder]="command.Inputs.KALKULIERTE_STRECKE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtzeit">{{ command.Inputs.FAHRTZEIT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtzeitErrorTip">
        <ng-template #FahrtzeitErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTZEIT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="fahrtzeit" id="fahrtzeit" [nzPlaceHolder]="command.Inputs.FAHRTZEIT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kalkulierteFahrtzeit">{{ command.Inputs.KALKULIERTE_FAHRTZEIT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KalkulierteFahrtzeitErrorTip">
        <ng-template #KalkulierteFahrtzeitErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KALKULIERTE_FAHRTZEIT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="kalkulierteFahrtzeit" id="kalkulierteFahrtzeit" [nzPlaceHolder]="command.Inputs.KALKULIERTE_FAHRTZEIT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stunden">{{ command.Inputs.STUNDEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenErrorTip">
        <ng-template #StundenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="stunden" id="stunden" [nzPlaceHolder]="command.Inputs.STUNDEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kostenNachStunden">{{ command.Inputs.KOSTEN_NACH_STUNDEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KostenNachStundenErrorTip">
        <ng-template #KostenNachStundenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOSTEN_NACH_STUNDEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="kostenNachStunden" id="kostenNachStunden" [nzPlaceHolder]="command.Inputs.KOSTEN_NACH_STUNDEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="verhandeltesHonorar">{{ command.Inputs.VERHANDELTES_HONORAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VerhandeltesHonorarErrorTip">
        <ng-template #VerhandeltesHonorarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VERHANDELTES_HONORAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="verhandeltesHonorar" id="verhandeltesHonorar" [nzPlaceHolder]="command.Inputs.VERHANDELTES_HONORAR.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kosten">{{ command.Inputs.KOSTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KostenErrorTip">
        <ng-template #KostenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOSTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="kosten" id="kosten" [nzPlaceHolder]="command.Inputs.KOSTEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kostenBeglichen">{{ command.Inputs.KOSTEN_BEGLICHEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KostenBeglichenErrorTip">
        <ng-template #KostenBeglichenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOSTEN_BEGLICHEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 150px" formControlName="kostenBeglichen" id="kostenBeglichen" [nzPlaceHolder]="command.Inputs.KOSTEN_BEGLICHEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="aktiv">{{ command.Inputs.AKTIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AktivErrorTip">
        <ng-template #AktivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKTIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="aktiv" id="aktiv"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="darlehensnehmer">{{ command.Inputs.DARLEHENSNEHMER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DarlehensnehmerErrorTip">
        <ng-template #DarlehensnehmerErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DARLEHENSNEHMER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="darlehensnehmer" id="darlehensnehmer" [placeholder]="command.Inputs.DARLEHENSNEHMER.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="notizenBesichtigung">{{ command.Inputs.NOTIZEN_BESICHTIGUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NotizenBesichtigungErrorTip">
        <ng-template #NotizenBesichtigungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOTIZEN_BESICHTIGUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="notizenBesichtigung" id="notizenBesichtigung" [placeholder]="command.Inputs.NOTIZEN_BESICHTIGUNG.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kreditsachbearbeiterVorname">{{ command.Inputs.KREDITSACHBEARBEITER_VORNAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterVornameErrorTip">
        <ng-template #KreditsachbearbeiterVornameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_VORNAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="kreditsachbearbeiterVorname" id="kreditsachbearbeiterVorname" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_VORNAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kreditsachbearbeiterName">{{ command.Inputs.KREDITSACHBEARBEITER_NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterNameErrorTip">
        <ng-template #KreditsachbearbeiterNameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="kreditsachbearbeiterName" id="kreditsachbearbeiterName" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_NAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kreditsachbearbeiterTelefon">{{ command.Inputs.KREDITSACHBEARBEITER_TELEFON.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterTelefonErrorTip">
        <ng-template #KreditsachbearbeiterTelefonErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_TELEFON.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="kreditsachbearbeiterTelefon" id="kreditsachbearbeiterTelefon" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_TELEFON.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kreditsachbearbeiterEMail">{{ command.Inputs.KREDITSACHBEARBEITER_E_MAIL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterEMailErrorTip">
        <ng-template #KreditsachbearbeiterEMailErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_E_MAIL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="kreditsachbearbeiterEMail" id="kreditsachbearbeiterEMail" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_E_MAIL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <!--- << custom    -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Projekt Speichern
          <ng-template #SubmitTooltip> Projekt in der aktuellen Liste übernehmen </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
    <div formGroupName="projekt">
      <input class="cdk-visually-hidden" formControlName="id" autocomplete="one-time-code" id="my-non-writable-field" name="somethingthatyoushouldnotfill" />
      <input class="cdk-visually-hidden" formControlName="projektZaehler" id="projektZaehler" />
      <input class="cdk-visually-hidden" formControlName="aktiv" id="aktiv" />
      <input class="cdk-visually-hidden" formControlName="fortschritt" id="fortschritt" />
      <input class="cdk-visually-hidden" formControlName="stunden" id="stunden" />
      <input class="cdk-visually-hidden" formControlName="kostenNachStunden" id="kostenNachStunden" />
      <input class="cdk-visually-hidden" formControlName="kosten" id="kosten" />
      <input class="cdk-visually-hidden" formControlName="kostenNetto" id="kostenNetto" />
      <input class="cdk-visually-hidden" formControlName="kostenNachStunden" id="kostenNachStunden" />
      <input class="cdk-visually-hidden" formControlName="kostenBeglichen" id="kostenBeglichen" />
      <input class="cdk-visually-hidden" formControlName="kontakteInOutlook" id="kontakteInOutlook" />

      <nz-row>
        <nz-col nzSpan="24">
          <nz-divider id="allgemein" nzText="Allgemein" nzOrientation="left"></nz-divider>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bezeichnung">{{ command.Inputs.NUMMER?.title }}</nz-form-label>
            <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="NummerErrorTip">
              <ng-template #NummerErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NUMMER?.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="nummer" id="nummer" [placeholder]="command.Inputs.NUMMER?.placeholder" />
            </nz-form-control>
            <nz-form-control [nzOffset]="1" [nzSm]="8" [nzXs]="24" [nzErrorTip]="NameErrorTip">
              <ng-template #NameErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEZEICHNUNG.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="bezeichnung" id="bezeichnung" [placeholder]="command.Inputs.BEZEICHNUNG.placeholder" cdkFocusInitial />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kundenZeichen">{{ command.Inputs.KUNDEN_ZEICHEN.title }}</nz-form-label>
            <nz-form-control [nzSm]="6" [nzXs]="24" [nzErrorTip]="KundenZeichenErrorTip">
              <ng-template #KundenZeichenErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_ZEICHEN.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="kundenZeichen" id="kundenZeichen" [placeholder]="command.Inputs.KUNDEN_ZEICHEN.placeholder" />
            </nz-form-control>
            <nz-form-control [nzOffset]="2" [nzSm]="4" [nzXs]="24" [nzErrorTip]="KundenAnfertigungErrorTip">
              <ng-template #KundenAnfertigungErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_ANFERTIGUNG.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <label nz-checkbox formControlName="kundenAnfertigung" id="kundenAnfertigung">{{ command.Inputs.KUNDEN_ANFERTIGUNG.title }}</label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="projektArt">{{ command.Inputs.PROJEKT_ART.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektArtErrorTip">
              <ng-template #ProjektArtErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT_ART.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                formControlName="projektArt"
                id="projektArt"
                [shapes]="postProjekt.projektProjektArt | async"
                [placeholder]="command.Inputs.PROJEKT_ART.placeholder"
                required="true"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bewertungsAnlass">Bewertungsanlass</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BewertungsAnlassErrorTip">
              <ng-template #BewertungsAnlassErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">Bitte Bewertungsanlass auswählen</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                formControlName="bewertungsAnlass"
                id="bewertungsAnlass"
                [shapes]="postProjekt.projektBewertungsAnlass | async"
                [placeholder]="'Bewertungsanlass'"
                required="true"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="objektArt">{{ command.Inputs.OBJEKT_ART.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektArtErrorTip">
              <ng-template #ObjektArtErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT_ART.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                formControlName="objektArt"
                id="objektArt"
                [shapes]="(postProjekt.shapes$ | async)?.['projekt.objektArt']"
                [placeholder]="command.Inputs.OBJEKT_ART.placeholder"
                required="true"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="24">
          <nz-divider id="abwicklung" nzText="Abwicklung" nzOrientation="left"></nz-divider>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="beauftragung">{{ command.Inputs.BEAUFTRAGUNG.title }}</nz-form-label>
            <nz-form-control [nzSm]="6" [nzXs]="24" [nzErrorTip]="BeauftragungErrorTip">
              <ng-template #BeauftragungErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEAUFTRAGUNG.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <nz-date-picker formControlName="beauftragung" id="beauftragung" nzFormat="dd.MM.yyyy"></nz-date-picker>
            </nz-form-control>
            <nz-form-label [nzSm]="2" [nzXs]="24" nzRequired nzFor="deadline">{{ command.Inputs.DEADLINE.title }}</nz-form-label>
            <nz-form-control [nzSm]="6" [nzXs]="24" [nzErrorTip]="DeadlineErrorTip">
              <ng-template #DeadlineErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DEADLINE.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <nz-date-picker formControlName="deadline" id="deadline" nzFormat="dd.MM.yyyy"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="standort">{{ command.Inputs.STANDORT.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StandortErrorTip">
              <ng-template #StandortErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STANDORT.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value formControlName="standort" id="standort" [shapes]="postProjekt.projektStandort | async" [placeholder]="command.Inputs.STANDORT.placeholder"></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="projektStatus">{{ command.Inputs.PROJEKT_STATUS.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektStatusErrorTip">
              <ng-template #ProjektStatusErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT_STATUS.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                formControlName="projektStatus"
                id="projektStatus"
                [shapes]="postProjekt.projektProjektStatus | async"
                [placeholder]="command.Inputs.PROJEKT_STATUS.placeholder"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="keineBesichtigungErforderlich">{{ command.Inputs.KEINE_BESICHTIGUNG_ERFORDERLICH.title }}</nz-form-label>
            <nz-form-control [nzSm]="1" [nzXs]="24" [nzErrorTip]="KeineBesichtigungErforderlichErrorTip">
              <ng-template #KeineBesichtigungErforderlichErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KEINE_BESICHTIGUNG_ERFORDERLICH.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <label nz-checkbox formControlName="keineBesichtigungErforderlich" id="keineBesichtigungErforderlich"></label>
            </nz-form-control>
            <ng-container [ngSwitch]="projektKeineBesichtigungErforderlich?.value || !projektKeineBesichtigungErforderlich?.touched">
              <ng-container *ngSwitchCase="false">
                <nz-form-label [nzSm]="2" [nzXs]="24" nzFor="besichtigungen">Besichtiger/-in</nz-form-label>
                <nz-form-control [nzSm]="9" [nzXs]="24" [nzErrorTip]="BesichtigungenErrorTip">
                  <ng-template #BesichtigungenErrorTip let-control>
                    <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BESICHTIGUNGEN.hint }}</ng-container>
                    <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
                  </ng-template>
                  <pbc-select-value
                    mode="multiple"
                    formControlName="besichtigungen"
                    id="besichtigungen"
                    [shapes]="postProjekt.projektBesichtigungen | async"
                    [placeholder]="'Besichtiger/-in'"
                  ></pbc-select-value>
                </nz-form-control>
              </ng-container>
              <input *ngSwitchCase="true" class="cdk-visually-hidden" formControlName="besichtigungen" id="besichtigungen-hidden" />
            </ng-container>
          </nz-form-item>

          <nz-form-item
            style="width: 100%"
            [ngClass]="{ 'cdk-visually-hidden': (auth.$isFinanzen | async) === false || projektKeineBesichtigungErforderlich?.value || (besichtigungenAnteile?.controls?.length || 0) < 1 }"
          >
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="besichtigungenAnteile">Anteile f. Besichtigungen</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BesichtigungenAnteileErrorTip">
              <ng-template #BesichtigungenAnteileErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')"></ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <ng-container formArrayName="besichtigungenAnteile">
                <ng-container *ngFor="let besichtigungenAnteil of besichtigungenAnteile?.controls; let i = index">
                  <nz-form-item [formGroupName]="i">
                    <nz-form-label [nzOffset]="1" [nzSm]="10" [nzXs]="24" nzFor="anteil">
                      {{ besichtigungenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label' : true }}
                      {{ getMinuten(besichtigungen$ | async, besichtigungenAnteil.controls.mitarbeiterIn?.value) | formatHours: true : true : true }}
                      <nz-avatar
                        [nzSize]="18"
                        style="margin-left: 8px"
                        [nzSrc]="besichtigungenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'avatar' | safe: 'url'"
                        [nzText]="besichtigungenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'initialen'"
                        nz-tooltip
                        [nzTooltipTitle]="besichtigungenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label'"
                      ></nz-avatar>
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24">
                      <input nz-input type="number" min="0" max="100" style="width: 150px" formControlName="anteil" id="anteil" placeholder="Anteil" [style.width]="'200px'" /> %
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <div
                  *ngIf="besichtigungenAnteile.controls.length > 0 && !!(gesamtBesichtigungenAnteile$ | async)"
                  [style.color]="!!(gesamtBesichtigungenAnteile$ | async) && (gesamtBesichtigungenAnteile$ | async) !== 100 ? 'red' : 'green'"
                >
                  Die Anteile summieren sich auf {{ gesamtBesichtigungenAnteile$ | async }} %
                </div>
              </ng-container>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachten">Gutachter/-in</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
              <ng-template #GutachtenErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')"></ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value mode="multiple" formControlName="gutachten" id="gutachten" [shapes]="postProjekt.projektGutachten | async" [placeholder]="'Gutachter/-in'"></pbc-select-value>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 100%" [ngClass]="{ 'cdk-visually-hidden': (auth.$isFinanzen | async) === false || (gutachtenAnteile?.controls?.length || 0) < 1 }">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachtenAnteile">Anteile f. Gutachten</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenAnteileErrorTip">
              <ng-template #GutachtenAnteileErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')"></ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <ng-container formArrayName="gutachtenAnteile">
                <ng-container *ngFor="let gutachtenAnteil of gutachtenAnteile?.controls; let i = index">
                  <nz-form-item [formGroupName]="i">
                    <nz-form-label [nzOffset]="1" [nzSm]="10" [nzXs]="24" nzFor="anteil">
                      {{ gutachtenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label' : true }}
                      {{ getMinuten(gutachten$ | async, gutachtenAnteil.controls.mitarbeiterIn?.value) | formatHours: true : true : true }}
                      <nz-avatar
                        [nzSize]="18"
                        style="margin-left: 8px"
                        [nzSrc]="gutachtenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'avatar' | safe: 'url'"
                        [nzText]="gutachtenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'initialen'"
                        nz-tooltip
                        [nzTooltipTitle]="gutachtenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label'"
                      ></nz-avatar>
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24">
                      <input nz-input type="number" min="0" max="100" style="width: 150px" formControlName="anteil" id="anteil" placeholder="Anteil" [style.width]="'200px'" /> %
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <div
                  *ngIf="gutachtenAnteile.controls.length > 0 && !!(gesamtGutachtenAnteile$ | async)"
                  [style.color]="!!(gesamtGutachtenAnteile$ | async) && (gesamtGutachtenAnteile$ | async) !== 100 ? 'red' : 'green'"
                >
                  Die Anteile summieren sich auf {{ gesamtGutachtenAnteile$ | async }} %
                </div>
              </ng-container>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="pruefung">Prüfer/-in</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PruefungErrorTip">
              <ng-template #PruefungErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PRUEFUNG.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value formControlName="pruefung" id="pruefung" [shapes]="postProjekt.projektPruefung | async" [placeholder]="'Prüfer/-in'"></pbc-select-value>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 100%" [ngClass]="{ 'cdk-visually-hidden': (auth.$isFinanzen | async) === false || (pruefungAnteile?.controls?.length || 0) < 1 }">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="pruefungAnteile">Anteile f. Prüfung</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PruefungAnteileErrorTip">
              <ng-template #PruefungAnteileErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')"></ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <ng-container formArrayName="pruefungAnteile">
                <ng-container *ngFor="let pruefungAnteil of pruefungAnteile?.controls; let i = index">
                  <nz-form-item [formGroupName]="i">
                    <nz-form-label [nzOffset]="1" [nzSm]="10" [nzXs]="24" nzFor="anteil">
                      {{ pruefungAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label' : true }}
                      {{ getMinuten(pruefung$ | async, pruefungAnteil.controls.mitarbeiterIn?.value) | formatHours: true : true : true }}
                      <nz-avatar
                        [nzSize]="18"
                        style="margin-left: 8px"
                        [nzSrc]="pruefungAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'avatar' | safe: 'url'"
                        [nzText]="pruefungAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'initialen'"
                        nz-tooltip
                        [nzTooltipTitle]="pruefungAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label'"
                      ></nz-avatar>
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24">
                      <input nz-input type="number" min="0" max="100" style="width: 150px" formControlName="anteil" id="anteil" placeholder="Anteil" [style.width]="'200px'" /> %
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <div
                  *ngIf="pruefungAnteile.controls.length > 0 && !!(gesamtPruefungAnteile$ | async)"
                  [style.color]="!!(gesamtPruefungAnteile$ | async) && (gesamtPruefungAnteile$ | async) !== 100 ? 'red' : 'green'"
                >
                  Die Anteile summieren sich auf {{ gesamtPruefungAnteile$ | async }} %
                </div>
              </ng-container>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="partnerInnen">Partner/-in</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
              <ng-template #GutachtenErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')"></ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                mode="multiple"
                formControlName="partnerInnen"
                id="partnerInnen"
                [shapes]="postProjekt.projektGutachten | async"
                [placeholder]="'Partner/-innen'"
                [rollen]="'1e19f5e5-20f4-4ed0-9a72-42701c006f49'"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <nz-form-item style="width: 100%" [ngClass]="{ 'cdk-visually-hidden': (auth.$isFinanzen | async) === false || (partnerInnenAnteile?.controls?.length || 0) < 1 }">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="partnerInnenAnteile">Anteile f. Partner/-in</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PartnerInnenAnteileErrorTip">
            <ng-template #PartnerInnenAnteileErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')"></ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <ng-container formArrayName="partnerInnenAnteile">
              <ng-container *ngFor="let partnerInnenAnteil of partnerInnenAnteile?.controls; let i = index">
                <nz-form-item [formGroupName]="i">
                  <nz-form-label [nzOffset]="1" [nzSm]="10" [nzXs]="24" nzFor="anteil">
                    {{ partnerInnenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label' : true }}
                    <nz-avatar
                      [nzSize]="18"
                      style="margin-left: 8px"
                      [nzSrc]="partnerInnenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'avatar' | safe: 'url'"
                      [nzText]="partnerInnenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'initialen'"
                      nz-tooltip
                      [nzTooltipTitle]="partnerInnenAnteil.controls.mitarbeiterIn?.value | type: (meta.users$ | async) : 'label'"
                    ></nz-avatar>
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24">
                    <input nz-input type="number" min="0" max="100" style="width: 150px" formControlName="anteil" id="anteil" placeholder="Anteil" [style.width]="'200px'" /> %
                  </nz-form-control>
                </nz-form-item>
              </ng-container>
              <div
                *ngIf="partnerInnenAnteile.controls.length > 0 && !!(gesamtPartnerInnenAnteile$ | async)"
                [style.color]="!!(gesamtPartnerInnenAnteile$ | async) && (gesamtPartnerInnenAnteile$ | async) !== 100 ? 'red' : 'green'"
              >
                Die Anteile summieren sich auf {{ gesamtPartnerInnenAnteile$ | async }} %
              </div>
            </ng-container>
          </nz-form-control>
        </nz-form-item>

        <nz-col nzSpan="24">
          <nz-divider id="auftraggeber" nzText="Auftraggeber" nzOrientation="left"></nz-divider>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kunde">{{ command.Inputs.KUNDE.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
              <ng-template #KundeErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                [creatable]="true"
                (created)="createKunde($event)"
                required="true"
                formControlName="kunde"
                id="kunde"
                [shapes]="(postProjekt.shapes$ | async)?.['projekt.kunde']"
                [placeholder]="command.Inputs.KUNDE.placeholder"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abteilung">{{ command.Inputs.ABTEILUNG.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbteilungErrorTip">
              <ng-template #AbteilungErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABTEILUNG.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                [creatable]="true"
                (created)="createAbteilung($event)"
                formControlName="abteilung"
                id="abteilung"
                [shapes]="(postProjekt.shapes$ | async)?.['projekt.abteilung']"
                [filter]="kunde?.value"
                [placeholder]="command.Inputs.ABTEILUNG.placeholder"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fond">{{ command.Inputs.FOND.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FondErrorTip">
              <ng-template #FondErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FOND.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                [creatable]="true"
                (created)="createFond($event)"
                formControlName="fond"
                id="fond"
                [shapes]="(postProjekt.shapes$ | async)?.['projekt.fond']"
                [filter]="kunde?.value"
                [placeholder]="command.Inputs.FOND.placeholder"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bank">{{ command.Inputs.BANK.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BankErrorTip">
              <ng-template #BankErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BANK.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <pbc-select-value
                [creatable]="true"
                (created)="createBank($event)"
                formControlName="bank"
                id="bank"
                [shapes]="postProjekt.projektBank | async"
                [placeholder]="command.Inputs.BANK.placeholder"
              ></pbc-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="darlehensnehmer">{{ command.Inputs.DARLEHENSNEHMER.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DarlehensnehmerErrorTip">
              <ng-template #DarlehensnehmerErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DARLEHENSNEHMER.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="darlehensnehmer" id="darlehensnehmer" [placeholder]="command.Inputs.DARLEHENSNEHMER.placeholder" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="24">
          <nz-divider id="kreditsachbearbeiter" nzText="Kreditsachbearbeiter" nzOrientation="left"></nz-divider>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kreditsachbearbeiterVorname">{{ command.Inputs.KREDITSACHBEARBEITER_VORNAME.title }}</nz-form-label>
            <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterVornameErrorTip">
              <ng-template #KreditsachbearbeiterVornameErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_VORNAME.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="kreditsachbearbeiterVorname" id="kreditsachbearbeiterVorname" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_VORNAME.placeholder" />
            </nz-form-control>
            <nz-form-control [nzOffset]="1" [nzSm]="6" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterNameErrorTip">
              <ng-template #KreditsachbearbeiterNameErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_NAME.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="kreditsachbearbeiterName" id="kreditsachbearbeiterName" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_NAME.placeholder" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kreditsachbearbeiterTelefon">{{ command.Inputs.KREDITSACHBEARBEITER_TELEFON.title }}</nz-form-label>
            <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterTelefonErrorTip">
              <ng-template #KreditsachbearbeiterTelefonErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_TELEFON.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <nz-input-group [nzAddOnAfter]="CallPhoneAddress">
                <input nz-input formControlName="kreditsachbearbeiterTelefon" id="kreditsachbearbeiterTelefon" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_TELEFON.placeholder" />
                <ng-template #CallPhoneAddress>
                  <a [href]="'tel:' + projektKreditsachbearbeiterTelefon?.value" target="_blank">
                    <i nz-icon nzType="phone" nzTheme="outline"></i>
                  </a>
                </ng-template>
              </nz-input-group>
            </nz-form-control>
            <nz-form-control [nzOffset]="1" [nzSm]="6" [nzXs]="24" [nzErrorTip]="KreditsachbearbeiterEMailErrorTip">
              <ng-template #KreditsachbearbeiterEMailErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KREDITSACHBEARBEITER_E_MAIL.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <nz-input-group [nzAddOnAfter]="EmailEmailAddress">
                <input nz-input formControlName="kreditsachbearbeiterEMail" id="kreditsachbearbeiterEMail" [placeholder]="command.Inputs.KREDITSACHBEARBEITER_E_MAIL.placeholder" />
                <ng-template #EmailEmailAddress>
                  <a [href]="'mailto:' + projektKreditsachbearbeiterEMail?.value" target="_blank">
                    <i nz-icon nzType="mail" nzTheme="outline"></i>
                  </a>
                </ng-template>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="notizenBesichtigung">{{ command.Inputs.NOTIZEN_BESICHTIGUNG.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NotizenBesichtigungErrorTip">
              <ng-template #NotizenBesichtigungErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOTIZEN_BESICHTIGUNG.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <textarea nz-input formControlName="notizenBesichtigung" id="notizenBesichtigung" [placeholder]="command.Inputs.NOTIZEN_BESICHTIGUNG.placeholder"></textarea>
            </nz-form-control>
          </nz-form-item>
        </nz-col>

        <ng-container [ngSwitch]="auth.$isFinanzen | async">
          <nz-col nzSpan="24" *ngSwitchCase="true">
            <nz-divider id="finanzen" nzText="Abrechnung" nzOrientation="left"></nz-divider>
            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="verhandeltesHonorar">{{ command.Inputs.VERHANDELTES_HONORAR.title }}</nz-form-label>
              <nz-form-control [nzSm]="8" [nzXs]="24" [nzErrorTip]="VerhandeltesHonorarErrorTip">
                <ng-template #VerhandeltesHonorarErrorTip let-control>
                  <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VERHANDELTES_HONORAR.hint }}</ng-container>
                  <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
                </ng-template>
                <input
                  nz-input
                  type="number"
                  style="width: 150px"
                  [min]="0"
                  [step]="100"
                  formControlName="verhandeltesHonorar"
                  id="verhandeltesHonorar"
                  [nzPlaceHolder]="command.Inputs.VERHANDELTES_HONORAR.placeholder"
                  [style.width]="'200px'"
                />
                €
              </nz-form-control>

              <nz-form-label [nzSm]="2" [nzXs]="24" nzFor="nachlass">{{ command.Inputs.NACHLASS.title }}</nz-form-label>
              <nz-form-control [nzSm]="4" [nzXs]="24" [nzErrorTip]="NachlassErrorTip">
                <ng-template #NachlassErrorTip let-control>
                  <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NACHLASS.hint }}</ng-container>
                  <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
                </ng-template>
                <input nz-input type="number" style="width: 100px" [max]="100" [min]="0" [step]="0.1" formControlName="nachlass" id="nachlass" [nzPlaceHolder]="command.Inputs.NACHLASS.placeholder" />
                %
              </nz-form-control>
            </nz-form-item>
            <nz-form-item style="width: 100%">
              <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="notizenFuerRechnung">{{ command.Inputs.NOTIZEN_FUER_RECHNUNG.title }}</nz-form-label>
              <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NotizenFuerRechnungErrorTip">
                <ng-template #NotizenFuerRechnungErrorTip let-control>
                  <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOTIZEN_FUER_RECHNUNG.hint }}</ng-container>
                  <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
                </ng-template>
                <textarea nz-input formControlName="notizenFuerRechnung" id="notizenFuerRechnung" [placeholder]="command.Inputs.NOTIZEN_FUER_RECHNUNG.placeholder"></textarea>
              </nz-form-control>
            </nz-form-item>
          </nz-col>
          <input *ngSwitchCase="false" class="cdk-visually-hidden" formControlName="verhandeltesHonorar" id="verhandeltesHonorar-hidden" />
          <input *ngSwitchCase="false" class="cdk-visually-hidden" formControlName="nachlass" id="nachlass-hidden" />
          <input *ngSwitchCase="false" class="cdk-visually-hidden" formControlName="notizenFuerRechnung" id="notizenFuerRechnung-hidden" />
        </ng-container>
        <nz-col nzSpan="24">
          <nz-divider id="sonstiges" nzText="Sonstiges" nzOrientation="left"></nz-divider>

          <nz-form-item style="width: 100%">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dateiVerzeichnis">{{ command.Inputs.DATEI_VERZEICHNIS.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateiVerzeichnisErrorTip">
              <ng-template #DateiVerzeichnisErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEI_VERZEICHNIS.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <nz-input-group [nzAddOnAfter]="dateiVerzeichnisField">
                <input nz-input formControlName="dateiVerzeichnis" id="dateiVerzeichnis" [placeholder]="command.Inputs.DATEI_VERZEICHNIS.placeholder" />
                <ng-template #dateiVerzeichnisField>
                  <a (click)="openDateipfad(projektDateiVerzeichnis?.value)"><span nz-icon nzType="folder-open" nzTheme="outline"></span> </a>
                  <a nz-typography nzCopyable [nzCopyText]="projektDateiVerzeichnis?.value"></a>
                </ng-template>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item style="width: 100%" [style.visibility]="showKommentar() ? '' : 'hidden'">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title }}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
              <ng-template #KommentarErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </div>
    <!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button" type="submit" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)" nz-tooltip [nzTooltipTitle]="SubmitTooltip">
          <!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Projekt Speichern
          <!---    confirm >> -->
          <ng-template #SubmitTooltip>
            <!--- << hint    -->
            Projekt in der aktuellen Liste übernehmen
            <!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
