import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteRechnungsVorlageCommandServiceModule } from '..';
import { DeleteRechnungsVorlageCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteRechnungsVorlageCommandServiceModule
  ],
  declarations: [DeleteRechnungsVorlageCommandComponent],
  exports: [DeleteRechnungsVorlageCommandComponent],

})
export class DeleteRechnungsVorlageCommandComponentModule { }
