import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { IchServiceModule } from '..';
import { IchSorterComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    IchServiceModule
  ],
  declarations: [IchSorterComponent],
  exports: [IchSorterComponent],

})
export class IchSorterComponentModule { }
