import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { DeleteReisekostenabrechnungStatusCommandServiceModule } from '..';
import { DeleteReisekostenabrechnungStatusCommandComponent } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteReisekostenabrechnungStatusCommandServiceModule
  ],
  declarations: [DeleteReisekostenabrechnungStatusCommandComponent],
  exports: [DeleteReisekostenabrechnungStatusCommandComponent],

})
export class DeleteReisekostenabrechnungStatusCommandComponentModule { }
