import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';

import { IKundenArt } from 'fa-kt.types';
import { PostKundenArtCommandService } from '../../commands';
import { KundenArtsService } from '../service';

@Component({
  selector: 'fa-kt-kunden-arts-list',
  templateUrl: './kunden-arts-list.component.html',
  styleUrls: ['./kunden-arts-list.component.css'],
})
export class KundenArtsListComponent {
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Output() select: EventEmitter<IKundenArt> = new EventEmitter<IKundenArt>();

  $sortable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sortable$: Subscription;

  constructor(
    public kundenArts: KundenArtsService,
    public kundenArtPost: PostKundenArtCommandService,
  ) {
    this.sortable$ = this.kundenArts.sorter$.subscribe((sorter) => this.$sortable.next(JSON.stringify(sorter) === '{}'));
  }

  ngOnDestroy() {
    this.sortable$.unsubscribe();
  }

  async reorder($event?: CdkDragDrop<string[]>) {
    const kundenArts = this.kundenArts.result$.getValue();
    if (kundenArts && kundenArts.kundenArts) {
      if ($event) {
        moveItemInArray(kundenArts.kundenArts, $event.previousIndex, $event.currentIndex);
      }
      kundenArts.kundenArts = kundenArts.kundenArts.map((unit, index) => ({ ...unit, order: index + 1 }));
      await this.kundenArtPost.request({ kundenArt: kundenArts.kundenArts });
    }
  }
}
