<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="touren.search$.next($event)" [search]="touren.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-touren-filter [style.width]="'100%'"></fa-kt-touren-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-touren-sorter [style.width]="'100%'"></fa-kt-touren-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-touren-list [style.width]="'100%'" (select)="setTouren($event)" [loading]="loading$ | async"></fa-kt-touren-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="tour.search$.next($event)" [search]="tour.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-tour-filter [style.width]="'100%'"></fa-kt-tour-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-tour-sorter [style.width]="'100%'"></fa-kt-tour-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-tour-list [style.width]="'100%'" (select)="setTour($event)" [loading]="loading$ | async"></fa-kt-tour-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="mitarbeiter.search$.next($event)" [search]="mitarbeiter.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-mitarbeiter-filter [style.width]="'100%'"></fa-kt-mitarbeiter-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-mitarbeiter-sorter [style.width]="'100%'"></fa-kt-mitarbeiter-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-mitarbeiter-list [style.width]="'100%'" (select)="setMitarbeiter($event)" [loading]="loading$ | async"></fa-kt-mitarbeiter-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="offene-gutachten.search$.next($event)" [search]="offene-gutachten.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-offene-gutachten-filter [style.width]="'100%'"></fa-kt-offene-gutachten-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-offene-gutachten-sorter [style.width]="'100%'"></fa-kt-offene-gutachten-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-offene-gutachten-list [style.width]="'100%'" (select)="setOffeneGutachten($event)" [loading]="loading$ | async"></fa-kt-offene-gutachten-list>

  <nz-row>
    <nz-col nzSpan='24'>
      <pbc-search-bar [style.width]="'100%'" (searchChange)="formulare.search$.next($event)" [search]="formulare.search$ | async"></pbc-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-formulare-filter [style.width]="'100%'"></fa-kt-formulare-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-formulare-sorter [style.width]="'100%'"></fa-kt-formulare-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-formulare-list [style.width]="'100%'" (select)="setFormulare($event)" [loading]="loading$ | async"></fa-kt-formulare-list>


  <fa-kt-delete-tour [style.width]="'100%'"  [value]="$deleteTour | async" (finished)="finishedDeleteTour($event)"></fa-kt-delete-tour>

  <fa-kt-post-ordne-besichtigungen [style.width]="'100%'"  [value]="$postOrdneBesichtigungen | async" (finished)="finishedPostOrdneBesichtigungen($event)"></fa-kt-post-ordne-besichtigungen>

  <fa-kt-delete-besichtigung [style.width]="'100%'"  [value]="$deleteBesichtigung | async" (finished)="finishedDeleteBesichtigung($event)"></fa-kt-delete-besichtigung>

  <fa-kt-post-tour [style.width]="'100%'"  [value]="$postTour | async" (finished)="finishedPostTour($event)"></fa-kt-post-tour>

  <fa-kt-post-durchgang [style.width]="'100%'"  [value]="$postDurchgang | async" (finished)="finishedPostDurchgang($event)"></fa-kt-post-durchgang>

  <fa-kt-post-start-tour [style.width]="'100%'"  [value]="$postStartTour | async" (finished)="finishedPostStartTour($event)"></fa-kt-post-start-tour>

  <fa-kt-post-besichtigung [style.width]="'100%'"  [value]="$postBesichtigung | async" (finished)="finishedPostBesichtigung($event)"></fa-kt-post-besichtigung>



    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="tour" type="dashed"></fa-kt-navigation>

    <fa-kt-navigation [style.width]="'100%'" *nzSpaceItem page="durchgang" type="dashed"></fa-kt-navigation>

-->

<nz-page-header [nzSubtitle]="PageSubtitle" [nzTitle]="PageTitle" (nzBack)="back()" nzBackIcon>
  <ng-template #PageTitle> {{ page.emoji }} {{ page.title }} </ng-template>
  <ng-template #PageSubtitle> {{ page.subtitle }} </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <pbc-breadcrumbs [pages]="page.breadcrumbs"></pbc-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
    <!--- << page-header-buttons    -->
    <nz-row>
      <nz-col [nzSpan]="24" style="padding: 4px">
        <nz-space>
          <ng-container *ngIf="$offenesGutachten | async">
            <nz-button-group *nzSpaceItem>
              <button
                [style.backgroundColor]="($offenesGutachten | async).color"
                (click)="startNewTour()"
                [nzLoading]="loading$ | async"
                [disabled]="!($mitarbeiter | async) || !($offenesGutachten | async)"
                [nzBlock]="true"
                nz-button
              >
                <i nz-icon nzTheme="outline" nzType="plus-circle"></i>
                Neue Tour {{ ($mitarbeiter | async) ? ('von "' + ($mitarbeiter | async)?.mitarbeiter.anzeigename) + '"' : '' }} {{ ($offenesGutachten | async) ? ('mit "' + ($offenesGutachten |
                async)?.objekt.name) + '"' : '' }}
              </button>
              <button [style.color]="($offenesGutachten | async).color" nz-button (click)="$offenesGutachten.next(undefined)">
                <i nz-icon nzTheme="outline" nzType="close-circle"></i>
              </button>
            </nz-button-group>
          </ng-container>
          <nz-avatar-group *nzSpaceItem nzSize="large" style="margin-bottom: 8px">
            <nz-avatar
              (click)="$mitarbeiter.next(mitarbeiter); $offenesGutachten.next(undefined)"
              *ngFor="let mitarbeiter of $alleMitarbeiter | async"
              [nzSize]="($mitarbeiter | async)?.mitarbeiter?.id === mitarbeiter.mitarbeiter.id ? 'large' : 'default'"
              [nzSrc]="mitarbeiter?.mitarbeiter?.bild"
              [nzText]="mitarbeiter?.mitarbeiter?.initialen"
              [nzTooltipColor]="mitarbeiter?.mitarbeiter?.color"
              [nzTooltipTitle]="mitarbeiter?.mitarbeiter?.anzeigename"
              [style.backgroundColor]="mitarbeiter.mitarbeiter.color"
              nz-tooltip
            ></nz-avatar>
            <nz-avatar
              (click)="$id.next(undefined); $tour.next(undefined); $mitarbeiter.next(undefined); $offenesGutachten.next(undefined);"
              nz-tooltip
              nzIcon="ordered-list"
              [nzSize]="!!!($mitarbeiter | async) ? 'large' : 'default'"
              nzTooltipColor="#52c41a"
              nzTooltipTitle="Alle anzeigen"
              style="background-color: #52c41a"
            ></nz-avatar>
          </nz-avatar-group>
        </nz-space>
      </nz-col>
    </nz-row>

    <!---    page-header-buttons >> -->
  </nz-page-header-extra>
  <nz-page-header-content>
    <!--- << page-header-row    -->
    <!---    page-header-row >> -->
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <nz-col *ngIf="$id | async" [nzSpan]="8" style="padding: 4px; display: flex">
      <label nz-checkbox [(ngModel)]="hasTour" (ngModelChange)="resetMap()" style="margin: auto; align-items: center">
        <span nz-icon nzType="car" nzTheme="outline"></span>
        Tour
      </label>
    </nz-col>
    <nz-col [nzSpan]="($id | async) ? 8 : 12" style="padding: 4px; display: flex">
      <label nz-checkbox [(ngModel)]="hasMap" (ngModelChange)="resetMap()" style="margin: auto; align-items: center">
        <i nz-icon nzType="pushpin" nzTheme="outline"></i>
        Karte
      </label>
    </nz-col>
    <nz-col [nzSpan]="($id | async) ? 8 : 12" style="padding: 4px; display: flex">
      <label nz-checkbox [(ngModel)]="hasList" (ngModelChange)="resetMap()" style="margin: auto; align-items: center">
        <span nz-icon nzType="unordered-list" nzTheme="outline"></span>
        Liste
      </label>
    </nz-col>
  </nz-row>
  <nz-row>
    <!--- << page-content    -->
    <nz-col *ngIf="hasTour" [nzLg]="getColumnWidth()" nzSm="24" style="padding: 4px; height: calc(100vh - 276px)">
      <nz-row *ngIf="($id | async) && ($tour | async)" [style.borderColor]="($tour | async)?.color" style="border: 3px solid; border-radius: 5px; height: 100%">
        <nz-col nzSpan="24" style="padding: 8px">
          <nz-space>
            <fa-kt-delete-tour (finished)="finishedDeleteTour($event)" *nzSpaceItem [style.width]="'100%'" [value]="$deleteTour | async"></fa-kt-delete-tour>
            <button (click)="openTour()" *nzSpaceItem nz-button nzType="dashed"><i nz-icon nzTheme="outline" nzType="close"></i>Schließen</button>
            <button (click)="setTour($id.getValue())" *nzSpaceItem nz-button nzType="dashed"><i nz-icon nzTheme="outline" nzType="arrow-right"></i></button>
          </nz-space>
          <br />
          <br />
          <fa-kt-post-tour (finished)="finishedPostTour($event)" [style.width]="'100%'" [value]="$postTour | async"></fa-kt-post-tour>
          <pbc-meta [margin]="false" [value]="($postTour | async)?.tour"></pbc-meta>
          <nz-divider id="besichtigungen" nzOrientation="left" nzText="Geplante Besichtigungen"></nz-divider>
          <nz-spin [nzSpinning]="loading$ | async">
            <cdk-virtual-scroll-viewport [itemSize]="75" style="min-height: calc((100vh - 475px) / 2); height: 100%">
              <ul nz-list (cdkDropListDropped)="drop($event)" cdkDropList>
                <li nz-list-item *ngIf="$offenesGutachten | async">
                  <button nz-button nzBlock [style.backgroundColor]="($offenesGutachten | async).color" (click)="addBesichtigung(0)">
                    <i nz-icon nzTheme="outline" nzType="plus-circle"></i>
                    "{{($offenesGutachten | async).objekt.name}}" hinzufügen
                  </button>
                </li>
                <ng-container *cdkVirtualFor="let row of $tourBesichtigungen | async; let i = index">
                  <li nz-list-item cdkDrag [cdkDragDisabled]="row.besichtigung.rueckfahrt">
                    <nz-list-item-meta style="cursor: pointer" (click)="editBesichtigung(row)">
                      <nz-list-item-meta-avatar>
                        <nz-avatar
                          [nzIcon]="row.besichtigung.rueckfahrt ? 'car' : row.besichtigung.besichtigungsStatus | type:(tour.besichtigungenBesichtigungsStatus | async):'icon'"
                          [style.backgroundColor]="row.besichtigung.rueckfahrt ? '' : row.besichtigung.besichtigungsStatus | type:(tour.besichtigungenBesichtigungsStatus | async):'color'"
                          nz-tooltip
                          [nzTooltipTitle]="row.besichtigung.rueckfahrt ? undefined : row.besichtigung.wiedervorlage ? (row.besichtigung.wiedervorlage | date:'dd.MM.yyyy') + ' - ' + row.besichtigung.aktion : row.besichtigung.besichtigungsStatus | type:(tour.besichtigungenBesichtigungsStatus | async)"
                        >
                        </nz-avatar>
                      </nz-list-item-meta-avatar>
                      <nz-list-item-meta-title>
                        <nz-row>
                          <nz-col nzSpan="24">
                            <strong> {{ row.besichtigung?.ende?.strasse }}, {{ row.besichtigung?.ende?.plz }}</strong>
                            <span *ngIf="!row.besichtigung.rueckfahrt" style="margin-left: 16px">{{ row.projekt?.kunde | type: (offeneGutachten.projektKunde | async)}}</span>
                          </nz-col>
                          <nz-col nzSpan="24">
                            <i [style.color]="row.hasTimeConflicts ? 'red' : ''"
                              >Ankunft {{ row.besichtigung?.fahrtBis | date:"HH:mm":'':'' }}
                              <ng-container *ngIf="!row.besichtigung.rueckfahrt"> - {{ row.besichtigung?.bis | date:"HH:mm":'':'' }} Abfahrt</ng-container></i
                            >
                          </nz-col>
                          <!-- <nz-col nzSpan="24" *ngIf="!row.besichtigung.rueckfahrt">
                            <i [style.color]="row.hasTimeConflicts ? 'red' : ''"
                              >Von {{ row.besichtigung?.kommuniziertVon | date:"HH:mm":'':'' }} - {{ row.besichtigung?.kommuniziertBis | date:"HH:mm":'':'' }} Bis</i
                            >
                          </nz-col> -->
                          <nz-col nzSpan="24">
                            {{ row.besichtigung?.fahrtstrecke / 1000 | number:"1.0-2":'de' }} km, {{ 60 * row.besichtigung?.fahrtzeit / 60 / 60 | number:"1.0-0":'de' }} Min.
                          </nz-col>
                        </nz-row>
                      </nz-list-item-meta-title>
                      <nz-list-item-meta-description *ngIf="!row.besichtigung.rueckfahrt">
                        <span *ngIf="row.besichtigung.wiedervorlage || row.besichtigung.aktion">
                          <i nz-icon nzTheme="outline" nzType="alert" style="color: red"></i> {{ row.besichtigung.wiedervorlage ? (row.besichtigung.wiedervorlage | date:'dd.MM.yyyy') : ''}} {{
                          row.besichtigung.aktion }}<br
                        /></span>
                        <span *ngIf="row.besichtigung.kommentar">
                          <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                          {{ row.besichtigung.kommentar }}
                        </span>
                      </nz-list-item-meta-description>
                    </nz-list-item-meta>
                    <ul nz-list-item-actions>
                      <nz-list-item-action>
                        <a (click)="openAddress(row.besichtigung.ende)"><span nz-icon nzType="compass" nzTheme="outline"></span></a>
                      </nz-list-item-action>
                    </ul>
                  </li>
                  <li nz-list-item *ngIf="($offenesGutachten | async) && !row.besichtigung.rueckfahrt">
                    <button nz-button nzBlock [style.backgroundColor]="($offenesGutachten | async).color" (click)="addBesichtigung(i + 1.5)" [nzLoading]="loading$ | async">
                      <i nz-icon nzTheme="outline" nzType="plus-circle"></i>
                      "{{($offenesGutachten | async).objekt.name}}" hinzufügen
                    </button>
                  </li>
                </ng-container>
              </ul>
            </cdk-virtual-scroll-viewport>
          </nz-spin>
        </nz-col>
      </nz-row>
    </nz-col>
    <nz-col *ngIf="hasMap" [nzLg]="getColumnWidth()" nzSm="24" style="padding: 4px">
      <pbc-atlas-map
        (clicked)="clickedMap($event)"
        [coordinateArray]="$coordinates | async"
        [routes]="$routes | async"
        [resize]="($resetMap | async)"
        height="calc(100vh - 284px)"
        width="100%"
      ></pbc-atlas-map>
    </nz-col>
    <nz-col *ngIf="hasList" [nzLg]="getColumnWidth()" nzSm="24" style="padding: 4px">
      <nz-row>
        <nz-col nzSpan="24">
          <pbc-search-bar (searchChange)="search$.next($event)" [search]="search$ | async" [style.width]="'100%'"></pbc-search-bar>
        </nz-col>
      </nz-row>
      <nz-divider [nzText]="OffeneBesichtigungenTitle" id="offene-besichtigungen" nzOrientation="left"></nz-divider>
      <ng-template #OffeneBesichtigungenTitle> {{ ($offeneGutachten | async)?.length + ' Offene Besichtigungen' }} </ng-template>
      <nz-row>
        <nz-col nzSpan="24">
          <nz-spin [nzSpinning]="loading$ | async">
            <cdk-virtual-scroll-viewport [itemSize]="50" [style.height]="'calc((100vh - 435px) / 2)'">
              <nz-row>
                <nz-col
                  (click)="openGutachten(besichtigung)"
                  *cdkVirtualFor="let besichtigung of $offeneGutachten | async"
                  [style.backgroundColor]="besichtigung.color"
                  nzSpan="24"
                  style="display: block; padding: 4px; height: 50px"
                >
                  <nz-avatar-group class="entry" nzSize="small" style="width: 60px">
                    <nz-avatar
                      *ngFor="let mitarbeiter of besichtigung.projekt?.besichtigungen"
                      nzSize="small"
                      [nzSrc]="mitarbeiter | type:(offeneGutachten.projektGutachten | async):'avatar'"
                      [nzText]="getMitarbeiterText(mitarbeiter, true)"
                      [nzTooltipTitle]="'Besichtigung: ' + getMitarbeiterText(mitarbeiter)"
                      nz-tooltip
                    ></nz-avatar>
                  </nz-avatar-group>

                  <strong class="entry"> {{ besichtigung.objekt?.name }} </strong>

                  <ng-template #ProjektBezeichnung>
                    <span [nzCopyText]="besichtigung.projekt?.nummer + ' | ' + besichtigung.projekt?.bezeichnung" nz-typography nzCopyable>
                      {{ besichtigung.projekt?.nummer }} | {{besichtigung.projekt?.bezeichnung}}
                    </span>
                  </ng-template>
                  <ng-template #ObjektArt>
                    <span [nzCopyText]="besichtigung.gutachten?.objektArt | type:(offeneGutachten.gutachtenObjektArt | async):'label':false" nz-typography nzCopyable>
                      {{ besichtigung.gutachten?.objektArt | type:(offeneGutachten.gutachtenObjektArt | async):'label':false }}
                    </span>
                  </ng-template>
                  <ng-template #Standort>
                    <span nz-typography> Standort </span>
                  </ng-template>
                  <ng-template #FinaleAbgabe>
                    <span nz-typography> Finale Abgabe </span>
                  </ng-template>
                  <ng-template #Oeffnen>
                    <span nz-typography> {{ besichtigung.projekt?.nummer }}'s Gutachten {{ besichtigung.objekt?.name }} öffnen </span>
                  </ng-template>
                  <ng-template #Kunde>
                    <span [nzCopyText]="besichtigung.kunde?.name" nz-typography nzCopyable> {{ besichtigung.kunde?.name }} </span>
                  </ng-template>

                  <ng-container [ngSwitch]="besichtigung.gutachten.id === ($offenesGutachten | async)?.gutachten.id">
                    <ng-container *ngSwitchCase="true">
                      <span class="entry">
                        <nz-divider nzType="vertical"></nz-divider>
                        <strong [nzTooltipTitle]="Standort" nz-tooltip nzTooltipColor="white">{{ besichtigung.projekt?.standort | type:(offeneGutachten.projektStandort | async) }}</strong>
                        <nz-divider nzType="vertical"></nz-divider>
                        <strong [nzTooltipTitle]="ObjektArt" nz-tooltip nzTooltipColor="white">{{ besichtigung.gutachten?.objektArt | type:(offeneGutachten.gutachtenObjektArt | async) }}</strong>
                        <nz-divider nzType="vertical"></nz-divider>
                        <strong [nzTooltipTitle]="FinaleAbgabe" nz-tooltip nzTooltipColor="white">{{ besichtigung.gutachten?.abgabeFinal | date:'dd.MM.yyyy' }}</strong>
                        <nz-divider nzType="vertical"></nz-divider>
                        <strong [nzTooltipTitle]="ProjektBezeichnung" nz-tooltip nzTooltipColor="white">{{ besichtigung.projekt?.nummer }}</strong>
                        <nz-divider nzType="vertical"></nz-divider>
                        <strong [nzTooltipTitle]="Kunde" nz-tooltip nzTooltipColor="white">{{ besichtigung.kunde?.initialen }}</strong>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                      <span class="entry">
                        <nz-divider nzType="vertical"></nz-divider>
                        <span [nzTooltipTitle]="Standort" nz-tooltip nzTooltipColor="white">{{ besichtigung.projekt?.standort | type:(offeneGutachten.projektStandort | async) }}</span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span [nzTooltipTitle]="ObjektArt" nz-tooltip nzTooltipColor="white">{{ besichtigung.gutachten?.objektArt | type:(offeneGutachten.gutachtenObjektArt | async) }}</span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span [nzTooltipTitle]="FinaleAbgabe" nz-tooltip nzTooltipColor="white">{{ besichtigung.gutachten?.abgabeFinal | date:'dd.MM.yyyy' }}</span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span [nzTooltipTitle]="ProjektBezeichnung" nz-tooltip nzTooltipColor="white">{{ besichtigung.projekt?.nummer }}</span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span [nzTooltipTitle]="Kunde" nz-tooltip nzTooltipColor="white">{{ besichtigung.kunde?.initialen }}</span>
                      </span>
                    </ng-container>
                  </ng-container>
                  <nz-divider nzType="vertical"></nz-divider>
                  <a
                    [nzTooltipTitle]="Oeffnen"
                    nz-tooltip
                    nzTooltipColor="white"
                    (click)="navigateToProjekt({ id: besichtigung.projekt.id, tab: 'gutachten', gutachten: besichtigung.gutachten.id, detail: 'gutachten' })"
                  >
                    <i nz-icon nzTheme="outline" nzType="arrow-right"></i>
                  </a>
                  <ng-container *ngIf="!besichtigung.objekt.addresse.latitude || !besichtigung.objekt.addresse.longitude">
                    <nz-divider nzType="vertical"></nz-divider>
                    <span style="color: red" nz-icon nzType="global" nzTheme="outline" nz-tooltip nzTooltipTitle="Keine Geokoordinaten hinterlegt" nzTooltipColor="red"></span>
                  </ng-container>
                </nz-col>
              </nz-row>
            </cdk-virtual-scroll-viewport>
          </nz-spin>
        </nz-col>
      </nz-row>
      <nz-divider id="alle-touren" [nzText]="'Alle ' + (($alleTouren | async)?.length || 0) + ' Touren'" nzOrientation="left"></nz-divider>
      <nz-row>
        <nz-col nzSpan="24">
          <nz-spin [nzSpinning]="loading$ | async">
            <cdk-virtual-scroll-viewport [itemSize]="70" [style.height]="'calc((100vh - 435px) / 2)'">
              <nz-row>
                <nz-col
                  (click)="openTour(tour.tour.id)"
                  *cdkVirtualFor="let tour of $alleTouren | async"
                  [style.borderColor]="tour.color"
                  style="border: 3px solid; border-radius: 5px; display: block; padding: 4px; height: auto"
                  nzSpan="24"
                >
                  <nz-avatar
                    [nzSrc]="tour.tour.mitarbeiter | type: (postStartTour.tourMitarbeiter|async):'avatar'"
                    [nzText]="getMitarbeiterText(tour.tour.mitarbeiter, true)"
                    [nzTooltipTitle]="getMitarbeiterText(tour.tour.mitarbeiter)"
                    class="entry"
                    nzSize="small"
                    nz-tooltip
                  ></nz-avatar>
                  <span class="entry">
                    <nz-divider nzType="vertical"></nz-divider>
                    <span> {{ tour.tour?.am | date:'dd.MM.yyyy' }} </span>
                    <span *ngIf="tour.hasWiedervorlage" nz-tooltip nzTooltipTitle="Wiedervorlage">
                      <nz-divider nzType="vertical"></nz-divider>
                      <i nz-icon nzTheme="outline" nzType="alert" style="color: red"></i>
                      <i nz-icon nzTheme="outline" nzType="alert" style="color: red"></i>
                      <i nz-icon nzTheme="outline" nzType="alert" style="color: red"></i>
                    </span>
                    <nz-divider nzType="vertical"></nz-divider>
                    <ng-container [ngSwitch]="tour.tour.id === ($id | async)">
                      <ng-container *ngSwitchCase="true">
                        <strong *ngIf="!tour.hasTimeConflicts">{{ tour.besichtigungen?.length - 1 }} Besichtigung{{ tour.besichtigungen?.length - 1 === 1 ? "" : "en"}}</strong>
                        <strong *ngIf="tour.hasTimeConflicts" [style.color]="'red'" nz-tooltip nzTooltipColor="red" nzTooltipTitle="Zeitkonflikte">
                          <i nz-icon nzTheme="outline" nzType="warning" style="color: red"></i>
                          {{ tour.besichtigungen?.length - 1 }} Besichtigung{{ tour.besichtigungen?.length - 1 === 1 ? "" : "en"}}
                        </strong>
                        <nz-divider nzType="vertical"></nz-divider>
                        <strong [style.color]="tour.tour?.tourStatus | type:(postStartTour.tourTourStatus | async):'color'">
                          <i [nzType]="tour.tour?.tourStatus | type:(postStartTour.tourTourStatus | async):'icon'" nz-icon nzTheme="outline"></i>
                          {{ tour.tour?.tourStatus | type:(postStartTour.tourTourStatus | async) }}
                        </strong>
                      </ng-container>
                      <ng-container *ngSwitchCase="false">
                        <span *ngIf="!tour.hasTimeConflicts"> {{ tour.besichtigungen?.length - 1 }} Besichtigung{{ tour.besichtigungen?.length - 1 === 1 ? "" : "en"}} </span>
                        <span *ngIf="tour.hasTimeConflicts" [style.color]="'red'" nz-tooltip nzTooltipColor="red" nzTooltipTitle="Zeitkonflikte">
                          <i nz-icon nzTheme="outline" nzType="warning" style="color: red"></i>
                          {{ tour.besichtigungen?.length - 1 }} Besichtigung{{ tour.besichtigungen?.length - 1 === 1 ? "" : "en"}}
                        </span>
                        <nz-divider nzType="vertical"></nz-divider>
                        <span [style.color]="tour.tour?.tourStatus | type:(postStartTour.tourTourStatus | async):'color'">
                          <i [nzType]="tour.tour?.tourStatus | type:(postStartTour.tourTourStatus | async):'icon'" nz-icon nzTheme="outline"></i>
                          {{ tour.tour?.tourStatus | type:(postStartTour.tourTourStatus | async) }}
                        </span>
                      </ng-container>
                    </ng-container>
                  </span>
                  <br />
                  <ng-container *ngIf="tour.besichtigungen.length > 1">
                    <span>{{ tour.besichtigungen[0].besichtigung.start.strasse }}, {{ tour.besichtigungen[0].besichtigung.start.plz }} {{" ⇥ "}}</span>
                    <ng-container *ngFor="let besichtigung of tour.besichtigungen">
                      <strong *ngIf="!besichtigung.besichtigung.rueckfahrt">{{ besichtigung.besichtigung.ende.strasse }}, {{ besichtigung.besichtigung.ende.plz }} ⇥ </strong>
                      <span *ngIf="besichtigung.besichtigung.rueckfahrt">{{ besichtigung.besichtigung.ende.strasse }}, {{ besichtigung.besichtigung.ende.plz }}</span>
                    </ng-container>
                  </ng-container>
                </nz-col>
              </nz-row>
            </cdk-virtual-scroll-viewport>
          </nz-spin>
        </nz-col>
      </nz-row>
    </nz-col>
  </nz-row>
</nz-content>
