import {
  ArrayReferenceFilterConfig,
  BooleanFilterConfig,
  DateFilterConfig,
  DateTimeFilterConfig,
  GeoRangeFilterConfig,
  IArrayReferenceFilter,
  IBooleanFilter,
  IDateFilter,
  IDateTimeFilter,
  IFilter,
  IFilterConfig,
  IGeoRangeFilter,
  INumberFilter,
  ISelection,
  IShape,
  ISingleReferenceFilter,
  NumberFilterConfig,
  SingleReferenceFilterConfig,
} from 'pbc.types';

import { IBesichtigungsStatus, IMitarbeiter, IReisekostenabrechnungStatus, ITour, ITourStatus } from 'fa-kt.types';
import { ITourenResponse } from './';

export interface ITourenQueryFilter extends IFilter<ITourenResponse> {
  'tour.tourStatus'?: ISingleReferenceFilter<ITourStatus>;
  'tour.mitarbeiter'?: ISingleReferenceFilter<IMitarbeiter>;
  'tour.am'?: IDateFilter;
  'tour.startIstPrivatAdresse'?: IBooleanFilter;
  'tour.endeIstPrivatAdresse'?: IBooleanFilter;
  'tour.start'?: IGeoRangeFilter;
  'tour.ende'?: IGeoRangeFilter;
  'tour.strecke'?: INumberFilter;
  'tour.kosten'?: INumberFilter;
  'besichtigungen.besichtigungsStatus'?: ISingleReferenceFilter<IBesichtigungsStatus>;
  'besichtigungen.mitarbeiter'?: ISingleReferenceFilter<IMitarbeiter>;
  'besichtigungen.rueckfahrt'?: IBooleanFilter;
  'besichtigungen.von'?: IDateTimeFilter;
  'besichtigungen.bis'?: IDateTimeFilter;
  'besichtigungen.start'?: IGeoRangeFilter;
  'besichtigungen.ende'?: IGeoRangeFilter;
  'besichtigungen.aufenthalt'?: INumberFilter;
  'besichtigungen.fahrtzeit'?: INumberFilter;
  'besichtigungen.fahrtzeitOhneVerkehr'?: INumberFilter;
  'besichtigungen.abgerechnet'?: IBooleanFilter;
  'reisekostenerstattung.mitarbeiter'?: ISingleReferenceFilter<IMitarbeiter>;
  'reisekostenerstattung.touren'?: IArrayReferenceFilter<ITour>;
  'reisekostenerstattung.reisekostenabrechnungStatus'?: ISingleReferenceFilter<IReisekostenabrechnungStatus>;
  'reisekostenerstattung.tagesgeldAcht'?: INumberFilter;
  'reisekostenerstattung.tagesgeldVierundzwanzig'?: INumberFilter;
  'reisekostenerstattung.anUndAbreisetag'?: INumberFilter;
  'reisekostenerstattung.fruehstuecke'?: INumberFilter;
  'reisekostenerstattung.mittagessen'?: INumberFilter;
  'reisekostenerstattung.abendessen'?: INumberFilter;
  'reisekostenerstattung.uebernachtungspauschale'?: INumberFilter;
  'reisekostenerstattung.normaleKm'?: INumberFilter;
  'reisekostenerstattung.mitnahmeentschaedigung'?: INumberFilter;
  'reisekostenerstattung.sonstigeSumme'?: INumberFilter;
}

export const TourenQueryFilterConfig: IFilterConfig<ITourenResponse> = [
  SingleReferenceFilterConfig<ITourStatus>('tour.tourStatus', 'BESICHTIGUNGEN.Models.TOUR.Propertys.TOUR_STATUS.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('tour.mitarbeiter', 'BESICHTIGUNGEN.Models.TOUR.Propertys.MITARBEITER.title'),
  DateFilterConfig('tour.am', 'BESICHTIGUNGEN.Models.TOUR.Propertys.AM.title'),
  BooleanFilterConfig('tour.startIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START_IST_PRIVAT_ADRESSE.title'),
  BooleanFilterConfig('tour.endeIstPrivatAdresse', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE_IST_PRIVAT_ADRESSE.title'),
  GeoRangeFilterConfig('tour.start', 'BESICHTIGUNGEN.Models.TOUR.Propertys.START.title'),
  GeoRangeFilterConfig('tour.ende', 'BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE.title'),
  NumberFilterConfig('tour.strecke', 'BESICHTIGUNGEN.Models.TOUR.Propertys.STRECKE.title'),
  NumberFilterConfig('tour.kosten', 'BESICHTIGUNGEN.Models.TOUR.Propertys.KOSTEN.title'),
  SingleReferenceFilterConfig<IBesichtigungsStatus>('besichtigungen.besichtigungsStatus', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BESICHTIGUNGS_STATUS.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('besichtigungen.mitarbeiter', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.MITARBEITER.title'),
  BooleanFilterConfig('besichtigungen.rueckfahrt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.RUECKFAHRT.title'),
  DateTimeFilterConfig('besichtigungen.von', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.VON.title'),
  DateTimeFilterConfig('besichtigungen.bis', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.BIS.title'),
  GeoRangeFilterConfig('besichtigungen.start', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.START.title'),
  GeoRangeFilterConfig('besichtigungen.ende', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ENDE.title'),
  NumberFilterConfig('besichtigungen.aufenthalt', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.AUFENTHALT.title'),
  NumberFilterConfig('besichtigungen.fahrtzeit', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT.title'),
  NumberFilterConfig('besichtigungen.fahrtzeitOhneVerkehr', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.FAHRTZEIT_OHNE_VERKEHR.title'),
  BooleanFilterConfig('besichtigungen.abgerechnet', 'BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.ABGERECHNET.title'),
  SingleReferenceFilterConfig<IMitarbeiter>('reisekostenerstattung.mitarbeiter', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITARBEITER.title'),
  ArrayReferenceFilterConfig<ITour>('reisekostenerstattung.touren', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TOUREN.title'),
  SingleReferenceFilterConfig<IReisekostenabrechnungStatus>('reisekostenerstattung.reisekostenabrechnungStatus', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.REISEKOSTENABRECHNUNG_STATUS.title'),
  NumberFilterConfig('reisekostenerstattung.tagesgeldAcht', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_ACHT.title'),
  NumberFilterConfig('reisekostenerstattung.tagesgeldVierundzwanzig', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_VIERUNDZWANZIG.title'),
  NumberFilterConfig('reisekostenerstattung.anUndAbreisetag', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.AN_UND_ABREISETAG.title'),
  NumberFilterConfig('reisekostenerstattung.fruehstuecke', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.FRUEHSTUECKE.title'),
  NumberFilterConfig('reisekostenerstattung.mittagessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITTAGESSEN.title'),
  NumberFilterConfig('reisekostenerstattung.abendessen', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.ABENDESSEN.title'),
  NumberFilterConfig('reisekostenerstattung.uebernachtungspauschale', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.UEBERNACHTUNGSPAUSCHALE.title'),
  NumberFilterConfig('reisekostenerstattung.normaleKm', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.NORMALE_KM.title'),
  NumberFilterConfig('reisekostenerstattung.mitnahmeentschaedigung', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITNAHMEENTSCHAEDIGUNG.title'),
  NumberFilterConfig('reisekostenerstattung.sonstigeSumme', 'FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SONSTIGE_SUMME.title'),
];

export interface ITourenQueryShapes extends IShape {
  'tour.tourStatus': ISelection[];
  'tour.mitarbeiter': ISelection[];
  'besichtigungen.besichtigungsStatus': ISelection[];
  'besichtigungen.mitarbeiter': ISelection[];
  'reisekostenerstattung.mitarbeiter': ISelection[];
  'reisekostenerstattung.touren': ISelection[];
  'reisekostenerstattung.reisekostenabrechnungStatus': ISelection[];
}

export type ITourenQueryField =
  | 'tour.id'
  | 'tour.tourStatus'
  | 'tour.mitarbeiter'
  | 'tour.am'
  | 'tour.startIstPrivatAdresse'
  | 'tour.endeIstPrivatAdresse'
  | 'tour.start'
  | 'tour.ende'
  | 'tour.strecke'
  | 'tour.kosten'
  | 'tour.abgerechnet'
  | 'tour.kommentar'
  | 'besichtigungen.id'
  | 'besichtigungen.projekt'
  | 'besichtigungen.tour'
  | 'besichtigungen.gutachten'
  | 'besichtigungen.objekt'
  | 'besichtigungen.besichtigungsStatus'
  | 'besichtigungen.mitarbeiter'
  | 'besichtigungen.kommentar'
  | 'besichtigungen.rueckfahrt'
  | 'besichtigungen.von'
  | 'besichtigungen.bis'
  | 'besichtigungen.start'
  | 'besichtigungen.ende'
  | 'besichtigungen.aufenthalt'
  | 'besichtigungen.fahrtzeit'
  | 'besichtigungen.fahrtstrecke'
  | 'besichtigungen.fahrtzeitOhneVerkehr'
  | 'besichtigungen.kosten'
  | 'besichtigungen.abgerechnet'
  | 'besichtigungen.route'
  | 'reisekostenerstattung.id'
  | 'reisekostenerstattung.mitarbeiter'
  | 'reisekostenerstattung.touren'
  | 'reisekostenerstattung.reisekostenabrechnungStatus'
  | 'reisekostenerstattung.tagesgeldAcht'
  | 'reisekostenerstattung.tagesgeldVierundzwanzig'
  | 'reisekostenerstattung.anUndAbreisetag'
  | 'reisekostenerstattung.fruehstuecke'
  | 'reisekostenerstattung.mittagessen'
  | 'reisekostenerstattung.abendessen'
  | 'reisekostenerstattung.uebernachtungspauschale'
  | 'reisekostenerstattung.normaleKm'
  | 'reisekostenerstattung.mitnahmeentschaedigung'
  | 'reisekostenerstattung.sonstigePositionen'
  | 'reisekostenerstattung.sonstigeSumme'
  | 'reisekostenerstattung.summe'
  | 'reisekostenerstattung.antrag'
  | '';
