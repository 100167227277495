import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { TageListComponent } from '.';
import { TageServiceModule } from '..';
import { TagesGraphComponent } from './tages-graph.component';

@NgModule({
  imports: [LIB_CommonModule, TageServiceModule],
  declarations: [TageListComponent, TagesGraphComponent],
  exports: [TageListComponent],
})
export class TageListComponentModule {}
