import { NgModule } from '@angular/core';

import { DeleteOrtKategorieCommandModule, OrtKategoriesQueryModule, PostOrtKategorieCommandModule } from '.';

@NgModule({
  declarations: [],
  imports: [PostOrtKategorieCommandModule, DeleteOrtKategorieCommandModule, OrtKategoriesQueryModule],
  exports: [PostOrtKategorieCommandModule, DeleteOrtKategorieCommandModule, OrtKategoriesQueryModule],
})
export class OrtKategoriesSettingsModule {}
