import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ActionService, AuthService, cleanObject, CustomFormatter, ISitemap, ISitemapCommand, SITEMAP } from 'pbc.angular';

import { IPostFeldOptionRequest, IPostFeldOptionResponse } from 'fa-kt.types';
import { PostFeldOptionCommandService } from '../service';

@Component({
  selector: 'fa-kt-post-feld-option',
  templateUrl: './post-feld-option.component.html',
  styleUrls: ['./post-feld-option.component.css'],
})
export class PostFeldOptionCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) {
    this.$loading.next(this.$loading.getValue() || loading);
  }
  @Input() set value(value: IPostFeldOptionRequest) {
    this.patch(value);
  }
  @Output() valueChanged: EventEmitter<IPostFeldOptionRequest> = new EventEmitter<IPostFeldOptionRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostFeldOptionResponse> = new EventEmitter<IPostFeldOptionResponse>();

  /* << fields    */
  @Input() set feld(feld: string) {
    this.feldOptionFeld?.patchValue(feld);
  }
  /*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef);
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postFeldOption: PostFeldOptionCommandService /* << more    */ /*    more >> */,
  ) {
    this.command = sitemap.FELDER.Commands.POST_FELD_OPTION;
    this.form = this.fb.group({
      feldOption: this.fb.group({
        id: [null, []],
        feld: [null, [Validators.required]],
        option: [null, [Validators.required]],
        plural: [null, []],
        akkusativ: [null, []],
        nominativ: [null, []],
        dativ: [null, []],
        genitiv: [null, []],
        standard: [null, []],
        order: [null, []],
      }),
    });
    /* << constructor    */
    /*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postFeldOption.prepare().catch((e) => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => {
        if (this.modalRef) {
          this.modalRef.destroy(result);
        }
      }),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() });
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostFeldOptionRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.feldOptionId?.valueChanges.subscribe(async (id) => {
        /* << on-feld-option-id-change    */
        /*    on-feld-option-id-change >> */
      }) as Subscription,
      this.feldOptionFeld?.valueChanges.subscribe(async (feld) => {
        /* << on-feld-option-feld-change    */
        /*    on-feld-option-feld-change >> */
      }) as Subscription,
      this.feldOptionOption?.valueChanges.subscribe(async (option) => {
        /* << on-feld-option-option-change    */
        /*    on-feld-option-option-change >> */
      }) as Subscription,
      this.feldOptionAkkusativ?.valueChanges.subscribe(async (akkusativ) => {
        /* << on-feld-option-akkusativ-change    */
        /*    on-feld-option-akkusativ-change >> */
      }) as Subscription,
      this.feldOptionNominativ?.valueChanges.subscribe(async (nominativ) => {
        /* << on-feld-option-nominativ-change    */
        /*    on-feld-option-nominativ-change >> */
      }) as Subscription,
      this.feldOptionDativ?.valueChanges.subscribe(async (dativ) => {
        /* << on-feld-option-dativ-change    */
        /*    on-feld-option-dativ-change >> */
      }) as Subscription,
      this.feldOptionGenitiv?.valueChanges.subscribe(async (genitiv) => {
        /* << on-feld-option-genitiv-change    */
        /*    on-feld-option-genitiv-change >> */
      }) as Subscription,
      this.feldOptionStandard?.valueChanges.subscribe(async (standard) => {
        /* << on-feld-option-standard-change    */
        /*    on-feld-option-standard-change >> */
      }) as Subscription,
      /* << subscibe    */
      /*    subscibe >> */
    );
    /* << init    */
    /*    init >> */
  }

  ngOnDestroy() {
    /* << end    */
    /*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostFeldOptionRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.feldOption) {
        value.feldOption = cleanObject(value.feldOption);
      }
      this.form.patchValue(value);
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.feldOption) {
        payload.feldOption = cleanObject(payload.feldOption);
      }
      const response: IPostFeldOptionResponse = await this.postFeldOption.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of error.errors ? error.errors : []) {
        for (const children of validation.children ? validation.children : []) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

  get feldOptionId() {
    return this.form.controls.feldOption.get('id');
  }
  get feldOptionFeld() {
    return this.form.controls.feldOption.get('feld');
  }
  get feldOptionOption() {
    return this.form.controls.feldOption.get('option');
  }
  get feldOptionAkkusativ() {
    return this.form.controls.feldOption.get('akkusativ');
  }
  get feldOptionNominativ() {
    return this.form.controls.feldOption.get('nominativ');
  }
  get feldOptionDativ() {
    return this.form.controls.feldOption.get('dativ');
  }
  get feldOptionGenitiv() {
    return this.form.controls.feldOption.get('genitiv');
  }
  get feldOptionStandard() {
    return this.form.controls.feldOption.get('standard');
  }
}
