import { NgModule } from '@angular/core';
import { LIB_CommonModule } from 'pbc.angular';
import { PostOrdneFeldOptionenCommandService } from '.';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostOrdneFeldOptionenCommandService],
  exports: []
})
export class PostOrdneFeldOptionenCommandServiceModule { }
